import React, { useContext, useState, useEffect, useMemo } from 'react';
import { compose } from 'recompose';

import { css } from '@emotion/react';
import withAdminRights from '@/components/Auth/withAdminRights';
import { PrimaryButton } from '@/styles/blocks';

import { ContextProvider } from './store.js';
import { Grid, Typography, createTheme, ThemeProvider } from '@material-ui/core';
import { colors } from '@/styles';

import orderStatusStrings from '@/constants/orderStatusStrings';
import { OnwardCard } from '@/components/OrderDetailsPage/Card';
import {
    ResponsiveSidebarDialog,
    ModalTitle,
    ModalContent,
    StickyModalActions,
    SecondaryButton,
} from '@/styles/blocks';
import { TextField } from '@/components/ShipmentForm/blocks';
import { useClientUser } from '@/hooks';
import { greys } from '@/styles/colors';
import styled from '@emotion/styled';

const dateShort = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
});

const timestamp = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
});

export const HR = styled.div`
    width: 100%;
    margin-top: 22px;
    margin-bottom: 22px;
    border: 1px solid #e2e2e2;
`;

const OrderStatusStyles = {
    open: {
        color: '#fc8f00',
    },
    inProgress: {
        color: '#008BD9',
    },
    complete: {
        color: '#59b863',
    },
    pending: {
        color: '#fc8f00',
    },
    claimed: {
        color: '#fc8f00',
    },
    routedActive: {
        color: '#fc8f00',
    },
    routed: {
        color: '#fc8f00',
    },
    routedInProgress: {
        color: '#fc8f00',
    },
    routedComplete: {
        color: '#59b863',
    },
    cancelled: {
        color: '#D23E3E',
    },
    rescheduled: {
        color: colors.oranges.primary,
    },
    'Complete with Exceptions': {
        color: colors.oranges.primary,
    },
};

const StatusFilter = ({ state, loading, callbacks, user_id, setModalState }) => {
    return (
        <Grid
            direction="row"
            container
            css={css`
                display: flex;
                background-color: white;
                padding-top: 15px;
                padding-bottom: 15px;
                box-shadow: 0px 1px 3px 0px #3f3f4426;
                box-shadow: 0px 0px 0px 1px #3f3f440d;
            `}
        >
            <Grid
                direction="column"
                container
                css={css`
                    flex: 0;
                    flex-basis: content;
                    justify-content: center;
                    margin-right: auto;
                    margin-left: 20px;
                `}
            >
                <PrimaryButton
                    css={css`
                        height: 40px;
                        align-self: flex-end;
                        justify-content: center;
                        text-align: center;
                    `}
                    onClick={() => callbacks.setNoteDetails(null)}
                >
                    Back
                </PrimaryButton>
            </Grid>
            <Grid
                direction="column"
                container
                css={css`
                    flex: 0;
                    flex-basis: content;
                    justify-content: center;
                    margin-right: 20px;
                    margin-left: auto;
                `}
            >
                <PrimaryButton
                    css={css`
                        height: 40px;
                        align-self: flex-end;
                        justify-content: center;
                        text-align: center;
                    `}
                    onClick={() => {
                        callbacks.acknowledgeNote({
                            note_id: state?.selectedNote?.note_id,
                            notes: [
                                {
                                    is_acknowledgement: true,
                                    order_id: state.selectedNote.order_id,
                                    source_user_type: 'Admin',
                                    source_user_id: user_id,
                                },
                            ],
                        });
                    }}
                    disabled={loading.acknowledgementLoading || state?.selectedNote?.acknowledged_by_admin}
                >
                    Acknowledge
                </PrimaryButton>
            </Grid>
            <Grid
                direction="column"
                container
                css={css`
                    flex: 0;
                    flex-basis: content;
                    justify-content: center;
                    margin-right: 20px;
                `}
            >
                <PrimaryButton
                    css={css`
                        height: 40px;
                        align-self: flex-end;
                        justify-content: center;
                        text-align: center;
                    `}
                    onClick={() =>
                        setModalState((prevState) => ({
                            ...prevState,
                            open: true,
                        }))
                    }
                    disabled={loading.addNoteLoading}
                >
                    Add Note
                </PrimaryButton>
            </Grid>
            <Grid
                direction="column"
                container
                css={css`
                    flex: 0;
                    flex-basis: content;
                    justify-content: center;
                    margin-right: 20px;
                `}
            >
                <a href={`${window.location.origin}/order/${state.selectedNote?.order_id}`} target="_blank">
                    <PrimaryButton
                        css={css`
                            height: 40px;
                            align-self: flex-end;
                            justify-content: center;
                            text-align: center;
                        `}
                    >
                        View Order
                    </PrimaryButton>
                </a>
            </Grid>
        </Grid>
    );
};

const Note = (note, isLast) => {
    return (
        <>
            <Grid item container direction="row" key={note.note_id}>
                <Grid container item direction="column" xs={4}>
                    {note?.private_to && (
                        <Grid item>
                            <Typography
                                css={css`
                                    color: orange;
                                    font-weight: 500;
                                `}
                            >
                                Private
                            </Typography>
                        </Grid>
                    )}

                    <Grid item>
                        <Typography
                            css={css`
                                font-weight: 500;
                                line-break: anywhere;
                            `}
                        >
                            {note?.source_user_type === 'Admin'
                                ? 'Onward'
                                : `(${note?.source_user_type}) ${note?.user?.username}`}
                        </Typography>
                    </Grid>
                    {note.source_user_type !== 'Admin' && (
                        <Grid item>
                            <Typography
                                css={css`
                                    color: ${greys.primary};
                                    font-weight: 500;
                                    line-break: anywhere;
                                `}
                            >
                                {note?.user?.email}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <Typography
                            css={css`
                                color: ${greys.primary};
                                font-weight: 500;
                            `}
                        >
                            {dateShort.format(new Date(note.created_at))}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            css={css`
                                color: ${greys.primary};
                                font-weight: 500;
                            `}
                        >
                            {timestamp.format(new Date(note.created_at))}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item xs={8}>
                    <Typography
                        css={css`
                            font-weight: 500;
                            ${note?.is_acknowledgement &&
                            `
                                font-style: italic;    
                            `}
                        `}
                    >
                        {note?.is_acknowledgement ? `Acknowledged by ${note?.source_user_type}` : note.note}
                    </Typography>
                </Grid>
            </Grid>

            {!isLast && <HR />}
        </>
    );
};

const initModalState = {
    open: false,
    private: false,
    notify: false,
    text: '',
};

const AdminNoteDetails = ({ state, loading, callbacks }) => {
    const order = state?.selectedNote?.order || {};
    const { user_id, accountType, isOnwardAdmin } = useClientUser();

    let orderStatus = useMemo(() => {
        if (!Object.keys(order).length) return '';
        return orderStatusStrings[order.order_status];
    }, [order]);

    const theme = createTheme({
        typography: {
            fontFamily: ['Montserrat', 'Roboto', 'Arial'].join(','),
            h1: {
                fontWeight: 800,
                fontSize: '28px !important',
            },
            h3: {
                fontSize: 25,
                fontWeight: '800',
            },
            h4: {
                color: '#4c4c4c',
                fontWeight: '900',
                fontSize: 15,
            },
            h6: {
                color: '#8d8d8d',
                fontWeight: '800',
                fontSize: 18,
            },
            body1: {
                fontWeight: '650',
                fontSize: 16,
            },
            body2: {
                fontWeight: '500',
                fontSize: 16,
            },
            subtitle1: {
                color: '#8d8d8d',
                fontWeight: '500',
                fontSize: '10',
            },
            subtitle2: {
                color: `${
                    OrderStatusStyles[orderStatus] || OrderStatusStyles[order.order_status]
                        ? OrderStatusStyles[orderStatus]?.color || OrderStatusStyles[order.order_status]?.color
                        : 'black'
                }`,
                fontWeight: '700',
                fontSize: '10',
                border: '3px solid',
                borderRadius: '5px',
                paddingLeft: '4px',
                paddingRight: '4px',
                height: '36px',
                display: 'flex',
                alignItems: 'center',
            },
            button: {
                fontWeight: '550',
                textTransform: 'capitalize',
            },
        },
        palette: {
            text: {
                secondary: '#4c4c4c',
            },
            primary: {
                main: '#59b863',
                contrastText: '#fff',
            },
            secondary: {
                main: '#4c4c4c',
                contrastText: '#fff',
            },
        },
    });

    const [modalState, setModalState] = useState(initModalState);

    const [orderNotes, setOrderNotes] = useState([]);
    useEffect(() => {
        const sortedNotes = [...(state?.selectedNote?.order?.notes || [])].sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setOrderNotes(sortedNotes);
    }, [state, state.selectedNote]);

    const accountTypeCapital = 'Admin';

    const handleSave = () => {
        callbacks.addNote({
            variables: {
                note: {
                    order_id: order.order_id,
                    source_user_type: accountTypeCapital,
                    source_user_id: user_id,
                    note: modalState.text,
                    is_acknowledgement: false,
                },
            },
        });

        setModalState(initModalState);
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                <Grid
                    direction="column"
                    container
                    css={css`
                        width: 100%;
                        padding-bottom: 10px;
                    `}
                >
                    <StatusFilter
                        state={state}
                        loading={loading}
                        callbacks={callbacks}
                        user_id={user_id}
                        setModalState={setModalState}
                    />

                    <Grid item container direction="row">
                        <Grid item xs={1}></Grid>

                        <Grid item container direction="column" xs={10}>
                            <Grid
                                item
                                className="d-flex justify-content-between align-items-center mt-4 overflow-auto"
                                css={css`
                                    margin-bottom: 25px;
                                `}
                            >
                                <Typography variant="h1">{order?.order_number || '--'}</Typography>
                                <Typography variant="subtitle2">{orderStatusStrings[order.order_status]}</Typography>
                            </Grid>

                            <OnwardCard
                                css={css`
                                    margin-bottom: 55px;
                                `}
                            >
                                <Typography style={{ fontWeight: 500 }}>PO: {order?.po_number || '--'}</Typography>
                                <Typography style={{ fontWeight: 500 }}>
                                    Customer: {order?.dropoff_name || '--'}
                                </Typography>
                                <Typography style={{ fontWeight: 500 }}>
                                    Shipper: {order?.order_shipper?.business_name || '--'}
                                </Typography>
                                <Typography style={{ fontWeight: 500 }}>
                                    Carrier: {order?.order_carrier?.business_name || '--'}
                                </Typography>
                            </OnwardCard>

                            {!orderNotes?.length ? (
                                <OnwardCard
                                    css={css`
                                        margin-bottom: 30px;
                                    `}
                                >
                                    No Notes
                                </OnwardCard>
                            ) : (
                                <OnwardCard>
                                    {!order?.notes?.length ? (
                                        <span
                                            css={css`
                                                font-style: italic;
                                            `}
                                        >
                                            No notes yet
                                        </span>
                                    ) : (
                                        (orderNotes || [])?.map((note, idx, arr) =>
                                            Note(note, arr.length - 1 === idx, accountTypeCapital)
                                        )
                                    )}
                                </OnwardCard>
                            )}
                        </Grid>

                        <Grid item xs={1}></Grid>
                    </Grid>
                </Grid>

                {modalState.open && (
                    <ResponsiveSidebarDialog
                        open={modalState.open}
                        onClose={() =>
                            setModalState((prevState) => ({
                                ...prevState,
                                open: false,
                            }))
                        }
                    >
                        <ModalTitle
                            title="Add Note"
                            onClose={() =>
                                setModalState((prevState) => ({
                                    ...prevState,
                                    open: false,
                                }))
                            }
                        />
                        <ModalContent width={700}>
                            <Grid className="mb-3" alignItems="center" container spacing={2}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <TextField
                                            variant="outlined"
                                            name="desc"
                                            fullWidth
                                            value={modalState.text || ''}
                                            onChange={(e) =>
                                                setModalState((prevState) => ({
                                                    ...prevState,
                                                    text: e.target.value,
                                                }))
                                            }
                                            multiline
                                            rows={5}
                                            css={css`
                                                textarea {
                                                    font-weight: 500;
                                                }
                                            `}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ModalContent>
                        <StickyModalActions>
                            <SecondaryButton
                                onClick={() =>
                                    setModalState((prevState) => ({
                                        ...prevState,
                                        open: false,
                                    }))
                                }
                            >
                                Cancel
                            </SecondaryButton>
                            <PrimaryButton onClick={handleSave} disabled={loading?.addNoteLoading || !modalState.text}>
                                Save
                            </PrimaryButton>
                        </StickyModalActions>
                    </ResponsiveSidebarDialog>
                )}
            </ThemeProvider>
        </>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} />
        </ContextProvider>
    );
export default compose(withAdminRights, withContext)(AdminNoteDetails);
