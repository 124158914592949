import React, { useMemo, useState, useCallback } from 'react';
import { Row, Container, Card, Col, Form } from 'react-bootstrap';
import { Button, Grid } from '@material-ui/core';
import deliveryHours from '../../../constants/deliveryHours.json';
import { InfoCircleFill } from 'react-bootstrap-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Sentry from '@sentry/react';
import { TextField, Select, FormControl, FormControlLabel, InputLabel, Checkbox, Switch } from '@material-ui/core';
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import { css } from '@emotion/react';

import { hoursOpenArray, updatedHolidayClosures } from '@/constants/hoursConstants';
import AddressAutocomplete from '../../ShipmentForm/ModifiedAddressAutocomplete';
import handleGeocode from '@/utilities/handleGeocode';
import { ADD_LOCATION, UPSERT_CLIENTS, ADD_FIRST_STORE, REMOVE_LOCATION } from '../mutations';
import { useMutation } from '@apollo/client';
import { useClientUser } from '@/hooks';
import { PrimaryButton } from '@/styles/blocks';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import { toE164, toNational } from '@/utilities/formatPhoneNumber';
import { OnwardCheckbox } from '@/components/ShipmentForm/blocks';

const REQUIRED_FIELDS = [
    'location_name',
    'business_address',
    'business_zip',
    'business_city',
    'business_state',
    'location_type',
    'location_info',
];

function Stores(props) {
    const { circles, isImposter } = useClientUser();

    const [chosenStore, setChosenStore] = useState(null);
    const [clientOverrides, setClientOverrides] = useState({});
    const [locationOverrides, setLocationOverrides] = useState([]);
    const [newStores, setNewStores] = useState([]);

    const [validationMessage, setValidationMessage] = useState('');

    const stores = useMemo(() => {
        return [
            ...(props.currentClient.locations || []).map((old, idx) => ({ ...old, ...locationOverrides[idx] })),
            ...newStores.map((old, idx) => ({
                ...old,
                ...locationOverrides[props.currentClient.locations.length + idx],
            })),
        ];
    }, [props.currentClient, newStores, locationOverrides]);

    const STORE_DEFAULTS = useMemo(() => {
        const defaultOpen = props.currentClient.default_store_hours || hoursOpenArray;

        return {
            ...updatedHolidayClosures,
            hours_open: defaultOpen,
            days_closed: defaultOpen.map((_day) => _day.day_num),
            shipper_pu_comments: props.currentClient.default_pickup_comments,
            qbo_class: '',
            ...Object.fromEntries(
                [
                    'july_fourth',
                    'xmas_eve',
                    'xmas',
                    'new_years_eve',
                    'new_years_day',
                    'labor_day',
                    'memorial_day',
                    'thanksgiving',
                ]
                    .filter((attr) => !!props.currentClient[attr])
                    .map((attr) => [attr, props.currentClient[attr]])
            ),
        };
    }, [props.currentClient]);

    const chosen = useMemo(() => {
        return {
            ...STORE_DEFAULTS,
            ...(Number.isInteger(chosenStore) ? { ...stores[chosenStore] } : { ...clientOverrides }),
        };
    }, [stores, STORE_DEFAULTS, chosenStore, clientOverrides]);

    const hasError = useMemo(() => {
        if (chosenStore) {
            return Object.fromEntries(
                REQUIRED_FIELDS.filter((field) => {
                    const isEmpty = !chosen[field] || chosen[field].length === 0;
                    switch (field) {
                        default:
                            return isEmpty;
                    }
                }).map((field) => [field, true])
            );
        }
        return {};
    }, [chosenStore, chosen]);

    const [addLocation] = useMutation(ADD_LOCATION, {
        update: (cache, { data }) => {
            cache.evict(cache.identify(props.currentClient));
        },
        onError: (error) => {
            console.error(error);
            Sentry.captureException(error);
        },
        onCompleted: (data) => {
            setNewStores([]);
        },
    });

    const [removeLocation] = useMutation(REMOVE_LOCATION, {
        update: (cache, { data }) => {
            cache.evict(cache.identify(data?.delete_locations_by_pk));
        },
        onError: (error) => {
            console.error(error);
            Sentry.captureException(error);
        },
    });

    const [updateClient] = useMutation(UPSERT_CLIENTS, {
        update: (cache, { data }) => {
            cache.evict(cache.identify(props.currentClient));
        },
        onError: (error) => {
            console.error(error);
            setValidationMessage('There was an error updating your location. Please try again.');
            Sentry.captureException(error);
        },
        onCompleted: (data) => {
            setValidationMessage('Location updated successfully!');

            setLocationOverrides([]);
            setClientOverrides({});
        },
    });

    const [addFirstStore] = useMutation(ADD_FIRST_STORE, {
        update: (cache, { data }) => {
            cache.evict(cache.identify(props.currentClient));
        },
        onError: (error) => {
            console.error(error);
            Sentry.captureException(error);
        },
        onCompleted: (data) => {
            setNewStores([]);
            setLocationOverrides([]);
            setClientOverrides({});
        },
    });

    const theme = createTheme({
        palette: {
            primary: {
                main: '#71b77a',
            },
        },
    });

    const GreenCheckbox = withStyles({
        root: {
            color: '#71b77a',
            '&$checked': {
                color: '#71b77a',
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    const setOverrides = useCallback(
        (callback) => {
            if (Number.isInteger(chosenStore)) {
                setLocationOverrides((prev) => {
                    const clone = [...prev];
                    clone[chosenStore] = callback(clone[chosenStore]);
                    return clone;
                });
            } else {
                setClientOverrides(callback);
            }
        },
        [chosenStore]
    );

    const onSubmitLocation = useCallback(() => {
        addLocation({
            variables: {
                locations_input_object: {
                    ...chosen,
                    days_closed: JSON.stringify(chosen.days_closed).replace('[', '{').replace(']', '}'),
                },
            },
        });
    }, [chosen]);

    const onSubmitFirstLocation = useCallback(() => {
        const hours = { days_closed: JSON.stringify(chosen.days_closed).replace('[', '{').replace(']', '}') };
        const locationObject = {
            client_id: props.currentClient.client_id,
            ...chosen,
            ...hours,
        };

        const clientObject = {
            client_id: props.currentClient.client_id,
            hours: clientOverrides.hours_open,
            xmas: chosen.xmas,
            xmas_eve: chosen.xmas_eve,
            new_years_eve: chosen.new_years_eve,
            new_years_day: chosen.new_years_day,
            labor_day: chosen.labor_day,
            memorial_day: chosen.memorial_day,
            thanksgiving: chosen.thanksgiving,
            july_fourth: chosen.july_fourth,
        };

        addFirstStore({
            variables: {
                locations_input_object: locationObject,
                ...clientObject,
                xmas: chosen.xmas,
            },
        });
    }, [chosen]);

    const onSaveClient = useCallback(() => {
        const { __typename, days_closed, hours_open, locations, user, teammates, partner_carriers, ...modified } = {
            ...props.currentClient,
            ...clientOverrides,
            default_store_hours: clientOverrides.hours_open,
        };

        updateClient({
            variables: {
                clients: [modified],
                locations: stores.map((store) => {
                    const { __typename, geo_point, ...modifiedStore } = store;

                    return {
                        ...modifiedStore,
                        days_closed: modifiedStore.days_closed
                            ? JSON.stringify(modifiedStore.days_closed).replace('[', '{').replace(']', '}')
                            : '{}',
                    };
                }),
            },
        });
    }, [props.currentClient, clientOverrides, chosenStore, chosen]);

    const handleAddressUpdate = async (address) => {
        if (!address) {
            return;
        }

        try {
            const { street, state, city, zip, lat, lng } = await handleGeocode({ address });
            setOverrides((prev) => {
                return {
                    ...prev,
                    business_address: street,
                    business_city: city,
                    business_state: state,
                    business_zip: zip,
                    lat: lat,
                    lng: lng,
                };
            });
        } catch (e) {
            console.error(e);
            Sentry.captureException(e);
        }
    };

    return (
        <Card className="tabCard">
            <ThemeProvider theme={theme}>
                <Card.Body className="">
                    <Container fluid>
                        {
                            <>
                                {!!stores.length && (
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item xs={3}>
                                            <Form.Group>
                                                <FormControl className="w-100" variant="outlined">
                                                    <InputLabel>Select Location</InputLabel>

                                                    <Select
                                                        native
                                                        value={Number.isInteger(chosenStore) ? chosenStore : 'default'}
                                                        label="Select Location"
                                                        onChange={(e) => {
                                                            setChosenStore(
                                                                e.target.value === 'default'
                                                                    ? null
                                                                    : parseInt(e.target.value)
                                                            );
                                                        }}
                                                    >
                                                        <option value="default" key="default">
                                                            Default Hours
                                                        </option>
                                                        {stores.length
                                                            ? Object.values(stores).map((store, idx) => (
                                                                  <option value={idx} key={store.location_id}>
                                                                      {store.location_name || '(no location name)'}
                                                                  </option>
                                                              ))
                                                            : null}
                                                    </Select>
                                                </FormControl>
                                            </Form.Group>
                                        </Grid>
                                        {!props.viewOnly && (
                                            <>
                                                <Grid
                                                    item
                                                    css={css`
                                                        margin-left: 1rem;
                                                    `}
                                                >
                                                    <PrimaryButton
                                                        onClick={() => {
                                                            setNewStores([
                                                                {
                                                                    ...STORE_DEFAULTS,
                                                                    location_name: 'New Location',
                                                                    client_id: props.currentClient.client_id,
                                                                },
                                                            ]);
                                                            setChosenStore(
                                                                (props.currentClient.locations || []).length
                                                            );
                                                        }}
                                                    >
                                                        Add a New Location
                                                    </PrimaryButton>
                                                </Grid>
                                                {chosenStore !== null ? (
                                                    <Grid
                                                        item
                                                        css={css`
                                                            margin-left: 1rem;
                                                        `}
                                                    >
                                                        <PrimaryButton
                                                            red
                                                            onClick={() => {
                                                                const location_id = stores?.[chosenStore]?.location_id;
                                                                removeLocation({ variables: { location_id } });
                                                            }}
                                                        >
                                                            Remove Location
                                                        </PrimaryButton>
                                                    </Grid>
                                                ) : null}
                                            </>
                                        )}
                                    </Grid>
                                )}
                                <Row
                                    className="d-flex flex-column align-items-start my-4"
                                    css={css`
                                        width: 66%;
                                    `}
                                >
                                    {!stores.length && !props.viewOnly && (
                                        <>
                                            <div className="text-success mb-2">
                                                {' '}
                                                Welcome to the warehouse & store location settings page! When you
                                                created your account, you set up your basic profile and billing
                                                information. This tab is where you can register your first warehouse or
                                                store location. You do not have any locations set up in our system yet,
                                                but all you have to do fill in the fields below and click save.
                                            </div>

                                            <Form id="registerFirstStore" noValidate className="text-center">
                                                <Row className="d-flex">
                                                    <Col>
                                                        <Form.Group className="my-2 w-100">
                                                            <TextField
                                                                value={chosen.location_name}
                                                                fullWidth
                                                                className=""
                                                                variant="outlined"
                                                                label={'First Location Name'}
                                                                error={hasError.location_name}
                                                                onChange={(e) =>
                                                                    setOverrides((prev) => {
                                                                        return {
                                                                            ...prev,
                                                                            location_name: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                {/* <Form.Label className="align-self-center">Address</Form.Label> */}
                                                <Row className="d-flex">
                                                    <Col>
                                                        <AddressAutocomplete
                                                            state={{
                                                                street: chosen.business_address || '',
                                                                city: chosen.business_city || '',
                                                                state: chosen.business_state || '',
                                                                zip: chosen.business_zip || '',
                                                            }}
                                                            handleAddressUpdate={handleAddressUpdate}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="d-flex">
                                                    <Col>
                                                        <Form.Group className="my-2 w-100">
                                                            <Form.Group className="my-2 w-100">
                                                                <TextField
                                                                    disabled
                                                                    fullWidth
                                                                    value={chosen.business_city}
                                                                    error={hasError.business_city}
                                                                    variant="outlined"
                                                                    label={'City'}
                                                                    onChange={(e) =>
                                                                        setOverrides((prev) => {
                                                                            return {
                                                                                ...prev,
                                                                                business_city: e.target.value,
                                                                            };
                                                                        })
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group className="my-2 w-100">
                                                            <TextField
                                                                disabled
                                                                fullWidth
                                                                value={chosen.business_state}
                                                                error={hasError.business_state}
                                                                variant="outlined"
                                                                label={'State'}
                                                                onChange={(e) =>
                                                                    setOverrides((prev) => {
                                                                        return {
                                                                            ...prev,
                                                                            business_state: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group className="my-2 w-100">
                                                            <TextField
                                                                disabled
                                                                fullWidth
                                                                type="number"
                                                                variant="outlined"
                                                                label={'Zip Code'}
                                                                value={chosen.business_zip}
                                                                error={hasError.business_zip}
                                                                onChange={(e) =>
                                                                    setOverrides((prev) => {
                                                                        return {
                                                                            ...prev,
                                                                            business_zip: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group className="my-2 w-100">
                                                            <TextField
                                                                variant="outlined"
                                                                color="primary"
                                                                label="Unit/Ste #"
                                                                name="apartmentNumber"
                                                                value={chosen.business_unit}
                                                                onChange={(e) =>
                                                                    setOverrides((prev) => {
                                                                        return {
                                                                            ...prev,
                                                                            business_unit: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                                InputLabelProps={{ shrink: !!chosen.business_unit }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="d-flex justify-content-start mt-2">
                                                    <Form.Group className="mb-2 h-50" xs={3} style={{ span: 3 }}>
                                                        <FormControl className="w-100" variant="outlined">
                                                            <InputLabel>Location type</InputLabel>
                                                            <Select
                                                                native
                                                                label="location type"
                                                                value={chosen.location_type}
                                                                disabled={props.viewOnly}
                                                                error={hasError.location_type}
                                                                onChange={(e) =>
                                                                    setOverrides((prev) => {
                                                                        return {
                                                                            ...prev,
                                                                            location_type: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            >
                                                                <option aria-label="None" value="" />
                                                                <option value={'Business'}>Business/Showroom</option>
                                                                <option value={'Residence'}>Residence</option>
                                                            </Select>
                                                        </FormControl>
                                                    </Form.Group>
                                                    {chosen.location_type === 'Business' && (
                                                        <Form.Group className="my-2">
                                                            <FormControl className="w-100" variant="outlined">
                                                                <InputLabel>Location details</InputLabel>
                                                                <Select
                                                                    native
                                                                    label="Location details"
                                                                    value={chosen.location_info}
                                                                    disabled={props.viewOnly}
                                                                    error={hasError.location_info}
                                                                    onChange={(e) =>
                                                                        setOverrides((prev) => {
                                                                            return {
                                                                                ...prev,
                                                                                location_info: e.target.value,
                                                                            };
                                                                        })
                                                                    }
                                                                >
                                                                    <option aria-label="None" value="" />
                                                                    <option value={'inStore'}>In Store Pick Up</option>
                                                                    <option value={'rollUpDoor'}>
                                                                        Warehouse/Roll Up Door
                                                                    </option>
                                                                    <option value={'dock'}>Dock Pick Up</option>
                                                                </Select>
                                                            </FormControl>
                                                        </Form.Group>
                                                    )}
                                                    {chosen.location_type === 'Residence' && (
                                                        <Form.Group className="my-2">
                                                            <FormControl className="w-100" variant="outlined">
                                                                <InputLabel>Location details</InputLabel>
                                                                <Select
                                                                    native
                                                                    label="Location details"
                                                                    value={chosen.location_info}
                                                                    disabled={props.viewOnly}
                                                                    onChange={(e) =>
                                                                        setOverrides((prev) => {
                                                                            return {
                                                                                ...prev,
                                                                                location_info: e.target.value,
                                                                            };
                                                                        })
                                                                    }
                                                                    error={hasError.location_info}
                                                                >
                                                                    <option aria-label="None" value="" />
                                                                    <option value={'whiteGlove'}>
                                                                        In Home Pick Up
                                                                    </option>
                                                                    <option value={'curbside'}>
                                                                        Curbside/Garage Pick Up
                                                                    </option>
                                                                </Select>
                                                            </FormControl>
                                                        </Form.Group>
                                                    )}
                                                </Row>
                                                <Row className="d-flex justify-content-start mt-2">
                                                    <Form.Group className="mb-2 h-50" xs={3} style={{ span: 3 }}>
                                                        <TextField
                                                            fullWidth
                                                            className=""
                                                            variant="outlined"
                                                            label="QBO Class"
                                                            value={chosen.qbo_class}
                                                            onChange={(e) =>
                                                                setOverrides((prev) => {
                                                                    return {
                                                                        ...prev,
                                                                        qbo_class: e.target.value,
                                                                    };
                                                                })
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Row>
                                            </Form>
                                        </>
                                    )}
                                    {!!stores.length && chosenStore !== null && chosenStore >= 0 && (
                                        <Form className="px-0">
                                            {isImposter ? (
                                                <Row className="d-flex px-0">
                                                    <Col className="px-0" xs={6}>
                                                        <Form.Group className="my-2 w-100 px-0">
                                                            <TextField
                                                                fullWidth
                                                                className=""
                                                                variant="outlined"
                                                                label={'Location ID'}
                                                                value={chosen.location_id || ''}
                                                                disabled={true}
                                                                error={hasError.location_id}
                                                                InputLabelProps={{ shrink: !!chosen.location_id }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            ) : null}
                                            <Row className="d-flex px-0">
                                                <Col className="px-0" xs={4}>
                                                    <Form.Group className="my-2 w-100 px-0">
                                                        <TextField
                                                            fullWidth
                                                            className=""
                                                            variant="outlined"
                                                            label={'Location Name'}
                                                            value={chosen.location_name || ''}
                                                            disabled={props.viewOnly}
                                                            error={hasError.location_name}
                                                            InputLabelProps={{ shrink: !!chosen.location_name }}
                                                            onChange={(e) =>
                                                                setOverrides((prev) => {
                                                                    return {
                                                                        ...prev,
                                                                        location_name: e.target.value,
                                                                    };
                                                                })
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col className="px-0" xs={2}>
                                                    <Form.Group className="my-2 w-100 px-0">
                                                        <TextField
                                                            fullWidth
                                                            className=""
                                                            variant="outlined"
                                                            label={'Location Code'}
                                                            value={chosen.location_code || ''}
                                                            disabled={props.viewOnly}
                                                            error={hasError.location_code}
                                                            InputLabelProps={{ shrink: !!chosen.location_code }}
                                                            onChange={(e) =>
                                                                setOverrides((prev) => {
                                                                    return {
                                                                        ...prev,
                                                                        location_code: e.target.value,
                                                                    };
                                                                })
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                {circles && circles['saas-v1'] && (
                                                    <Col
                                                        className="d-flex"
                                                        xs={6}
                                                        css={css`
                                                            padding-left: 2rem;
                                                            padding-right: 0px;
                                                            align-items: center;
                                                        `}
                                                    >
                                                        <FormControlLabel
                                                            control={<GreenCheckbox name="checkedG" />}
                                                            label={'Default End Location'}
                                                            className="align-self-center my-0"
                                                            checked={!!chosen?.default_end_location}
                                                            disabled={props.viewOnly}
                                                            onChange={(e) => {
                                                                // Change default_end_location to false for any other stores that have it as true.
                                                                const storesToChange = stores.reduce(
                                                                    (accum, s, idx) => {
                                                                        if (s?.default_end_location === true) {
                                                                            accum.push({
                                                                                ...s,
                                                                                index: idx,
                                                                            });
                                                                        }
                                                                        return accum;
                                                                    },
                                                                    []
                                                                );

                                                                setLocationOverrides((prev) => {
                                                                    const clone = [...prev];
                                                                    storesToChange.forEach((s) => {
                                                                        const storeClone = {
                                                                            ...clone[s.index],
                                                                            default_end_location: false,
                                                                        };
                                                                        clone[s.index] = storeClone;
                                                                    });

                                                                    const storeClone = {
                                                                        ...clone[chosenStore],
                                                                        default_end_location:
                                                                            !chosen?.default_end_location,
                                                                    };
                                                                    clone[chosenStore] = storeClone;

                                                                    return clone;
                                                                });
                                                            }}
                                                            css={css`
                                                                margin-right: 6px;
                                                            `}
                                                        />
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Tooltip>
                                                                    When this box is checked, routes created in Planning
                                                                    will automatically have a route end stop set to this
                                                                    location.
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <InfoCircleFill size={17} className="me-1 mb-1" />
                                                        </OverlayTrigger>
                                                    </Col>
                                                )}
                                            </Row>
                                            <Row className="d-flex">
                                                <Col className="px-0 my-2" xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        label={'Contact Name'}
                                                        value={chosen.contact_name || ''}
                                                        disabled={props.viewOnly}
                                                        error={hasError.contact_name}
                                                        InputLabelProps={{ shrink: !!chosen.contact_name }}
                                                        onChange={(e) =>
                                                            setOverrides((prev) => ({
                                                                ...prev,
                                                                contact_name: e.target.value,
                                                            }))
                                                        }
                                                    />
                                                </Col>
                                                <Col
                                                    className="d-flex"
                                                    css={css`
                                                        padding-left: 2rem;
                                                        padding-right: 0px;
                                                        align-items: center;
                                                    `}
                                                    xs={6}
                                                >
                                                    <FormControlLabel
                                                        control={<GreenCheckbox name="checkedG" />}
                                                        className="align-self-center my-0"
                                                        disabled={props.viewOnly}
                                                        label={'Preferred Inbound'}
                                                        checked={!!chosen.preferred_inbound}
                                                        onChange={() =>
                                                            setOverrides((prev) => ({
                                                                ...prev,
                                                                preferred_inbound: !chosen.preferred_inbound,
                                                            }))
                                                        }
                                                        css={css`
                                                            margin-right: 6px;
                                                        `}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="d-flex">
                                                <Col className="px-0 my-2" xs={3}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        label={'Contact Email'}
                                                        value={chosen.contact_email || ''}
                                                        disabled={props.viewOnly}
                                                        error={hasError.contact_email}
                                                        InputLabelProps={{ shrink: !!chosen.contact_email }}
                                                        onChange={(e) =>
                                                            setOverrides((prev) => ({
                                                                ...prev,
                                                                contact_email: e.target.value,
                                                            }))
                                                        }
                                                    />
                                                </Col>
                                                <Col className="px-0 my-2" xs={3}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        label={'Contact Phone'}
                                                        value={toNational(chosen.contact_phone)}
                                                        disabled={props.viewOnly}
                                                        error={hasError.contact_phone}
                                                        InputLabelProps={{ shrink: !!chosen.contact_phone }}
                                                        InputProps={{
                                                            inputComponent: PhoneNumberInput,
                                                        }}
                                                        onChange={(e) =>
                                                            setOverrides((prev) => ({
                                                                ...prev,
                                                                contact_phone: toE164(e.target.value),
                                                            }))
                                                        }
                                                    />
                                                </Col>
                                                <Col
                                                    className="d-flex"
                                                    css={css`
                                                        padding-left: 2rem;
                                                        padding-right: 0px;
                                                        align-items: center;
                                                    `}
                                                    xs={5}
                                                >
                                                    <FormControlLabel
                                                        control={<GreenCheckbox name="checkedG" />}
                                                        className="align-self-center my-0"
                                                        disabled={props.viewOnly}
                                                        label={'Enable for Warehouse Management System'}
                                                        checked={!!chosen.enable_for_wms}
                                                        onChange={() =>
                                                            setOverrides((prev) => ({
                                                                ...prev,
                                                                enable_for_wms: !chosen.enable_for_wms,
                                                            }))
                                                        }
                                                        css={css`
                                                            margin-right: 6px;
                                                        `}
                                                    />
                                                    <OverlayTrigger
                                                        overlay={
                                                            <Tooltip>
                                                                This location will be available to use in the
                                                                warehousing tab.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <InfoCircleFill size={17} className="me-1 mb-1" />
                                                    </OverlayTrigger>
                                                </Col>
                                            </Row>
                                            <Form.Label
                                                className="align-self-start"
                                                style={{ textAlign: 'initial !important', fontWeight: '500' }}
                                            >
                                                {chosen.location_name} Address
                                            </Form.Label>
                                            <Row className="d-flex">
                                                <Col className="px-0" xs={4}>
                                                    <AddressAutocomplete
                                                        state={{
                                                            street: chosen.business_address || '',
                                                            city: chosen.business_city || '',
                                                            state: chosen.business_state || '',
                                                            zip: chosen.business_zip || '',
                                                        }}
                                                        handleAddressUpdate={handleAddressUpdate}
                                                    />
                                                </Col>
                                                <Col className="px-0 ms-1" xs={2}>
                                                    <Form.Group className="w-100">
                                                        <TextField
                                                            variant="outlined"
                                                            color="primary"
                                                            label="Unit/Ste #"
                                                            name="apartmentNumber"
                                                            value={chosen.business_unit || ''}
                                                            onChange={(e) =>
                                                                setOverrides((prev) => {
                                                                    return {
                                                                        ...prev,
                                                                        business_unit: e.target.value,
                                                                    };
                                                                })
                                                            }
                                                            InputLabelProps={{ shrink: !!chosen.business_unit }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="d-flex">
                                                <Col className="px-0 mr-1" xs={3}>
                                                    <Form.Group className="my-2 w-100">
                                                        <Form.Group className="my-2 w-100">
                                                            <TextField
                                                                fullWidth
                                                                className=""
                                                                variant="outlined"
                                                                label={'City'}
                                                                value={chosen.business_city || ''}
                                                                disabled={props.viewOnly}
                                                                InputLabelProps={{ shrink: !!chosen.business_city }}
                                                                onChange={(e) =>
                                                                    setOverrides((prev) => {
                                                                        return {
                                                                            ...prev,
                                                                            business_city: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Form.Group>
                                                </Col>
                                                <Col className="px-0 mx-1" xs={2}>
                                                    <Form.Group className="my-2 w-100">
                                                        <TextField
                                                            disabled
                                                            fullWidth
                                                            value={chosen.business_state || ''}
                                                            error={hasError.business_state}
                                                            variant="outlined"
                                                            label={'State'}
                                                            InputLabelProps={{ shrink: !!chosen.business_state }}
                                                            onChange={(e) =>
                                                                setOverrides((prev) => {
                                                                    return {
                                                                        ...prev,
                                                                        business_state: e.target.value,
                                                                    };
                                                                })
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col className="px-0 ml-1" xs={1}>
                                                    <Form.Group className="my-2 w-100">
                                                        <TextField
                                                            type="number"
                                                            fullWidth
                                                            error={hasError.business_zip}
                                                            className=""
                                                            variant="outlined"
                                                            label={'Zip Code'}
                                                            value={chosen.business_zip || ''}
                                                            disabled={props.viewOnly}
                                                            InputLabelProps={{ shrink: !!chosen.business_zip }}
                                                            onChange={(e) =>
                                                                setOverrides((prev) => {
                                                                    return {
                                                                        ...prev,
                                                                        business_zip: e.target.value,
                                                                    };
                                                                })
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="px-0">
                                                <Col className="px-0" xs={3}>
                                                    <Form.Group className="my-2 px-0">
                                                        <FormControl className="w-100" variant="outlined">
                                                            <InputLabel>Location type</InputLabel>
                                                            <Select
                                                                native
                                                                label="location type"
                                                                value={chosen.location_type || ''}
                                                                disabled={props.viewOnly}
                                                                error={hasError.location_type}
                                                                onChange={(e) =>
                                                                    setOverrides((prev) => {
                                                                        return {
                                                                            ...prev,
                                                                            location_type: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            >
                                                                <option aria-label="None" value="" />
                                                                <option value={'Business'}>Business/Showroom</option>
                                                                <option value={'Residence'}>Residence</option>
                                                            </Select>
                                                        </FormControl>
                                                    </Form.Group>
                                                </Col>
                                                {chosen.location_type === 'Business' && (
                                                    <Col className="px-0" xs={3}>
                                                        <Form.Group className="my-2 px-0">
                                                            <FormControl className="mx-1 w-100" variant="outlined">
                                                                <InputLabel>Location details</InputLabel>
                                                                <Select
                                                                    native
                                                                    label="Location details"
                                                                    value={chosen.location_info || ''}
                                                                    disabled={props.viewOnly}
                                                                    onChange={(e) =>
                                                                        setOverrides((prev) => {
                                                                            return {
                                                                                ...prev,
                                                                                location_info: e.target.value,
                                                                            };
                                                                        })
                                                                    }
                                                                    error={hasError.location_info}
                                                                >
                                                                    <option aria-label="None" value="" />
                                                                    <option value={'inStore'}>In Store Pick Up</option>
                                                                    <option value={'rollUpDoor'}>
                                                                        Warehouse/Roll Up Door
                                                                    </option>
                                                                    <option value={'dock'}>Dock Pick Up</option>
                                                                </Select>
                                                            </FormControl>
                                                        </Form.Group>
                                                    </Col>
                                                )}
                                                {chosen.location_type === 'Residence' && (
                                                    <Col className="px-0" xs={3}>
                                                        <Form.Group className="my-2 px-0">
                                                            <FormControl className="mx-1 w-100" variant="outlined">
                                                                <InputLabel>Location details</InputLabel>
                                                                <Select
                                                                    native
                                                                    label="Location details"
                                                                    value={chosen.location_info || ''}
                                                                    disabled={props.viewOnly}
                                                                    onChange={(e) =>
                                                                        setOverrides((prev) => {
                                                                            return {
                                                                                ...prev,
                                                                                location_info: e.target.value,
                                                                            };
                                                                        })
                                                                    }
                                                                    error={hasError.location_info}
                                                                >
                                                                    <option aria-label="None" value="" />
                                                                    <option value={'whiteGlove'}>
                                                                        In Home Pick Up
                                                                    </option>
                                                                    <option value={'curbside'}>
                                                                        Curbside/Garage Pick Up
                                                                    </option>
                                                                </Select>
                                                            </FormControl>
                                                        </Form.Group>
                                                    </Col>
                                                )}
                                                {!chosen.location_type && (
                                                    <Col className="px-0" xs={3}>
                                                        <Form.Group className="my-2 px-0">
                                                            <FormControl className="mx-1 w-100" variant="outlined">
                                                                <InputLabel>Location details</InputLabel>
                                                                <Select
                                                                    disabled
                                                                    native
                                                                    label="Location details"
                                                                    value={chosen.location_info || ''}
                                                                    // disabled={props.viewOnly}
                                                                    onChange={(e) =>
                                                                        setOverrides((prev) => {
                                                                            return {
                                                                                ...prev,
                                                                                location_info: e.target.value,
                                                                            };
                                                                        })
                                                                    }
                                                                    error={hasError.location_info}
                                                                >
                                                                    <option aria-label="None" value="" />
                                                                    <option value={'whiteGlove'}>
                                                                        In Home Pick Up
                                                                    </option>
                                                                    <option value={'curbside'}>
                                                                        Curbside/Garage Pick Up
                                                                    </option>
                                                                </Select>
                                                            </FormControl>
                                                        </Form.Group>
                                                    </Col>
                                                )}
                                            </Row>
                                            <Row xs={6} className="d-flex justify-content-start mt-2">
                                                <Form.Group className="mb-2 h-50" xs={12} style={{ width: '50%' }}>
                                                    <TextField
                                                        fullWidth
                                                        className=""
                                                        variant="outlined"
                                                        label="QBO Class"
                                                        value={chosen.qbo_class}
                                                        onChange={(e) =>
                                                            setOverrides((prev) => {
                                                                return {
                                                                    ...prev,
                                                                    qbo_class: e.target.value,
                                                                };
                                                            })
                                                        }
                                                    />
                                                </Form.Group>
                                            </Row>

                                            {newStores.length > 0 && chosenStore === stores.length - 1 && (
                                                <Row className="d-flex justify-content-start mt-2">
                                                    <Button
                                                        className="mb-2 h-50 account-btn"
                                                        xs={3}
                                                        style={{ span: 3 }}
                                                        onClick={onSubmitLocation}
                                                        disabled={Object.keys(hasError).length > 0}
                                                        color="success"
                                                        css={css`
                                                            width: auto;
                                                            background-color: green;
                                                            color: white;
                                                        `}
                                                    >
                                                        Submit
                                                    </Button>
                                                    <Button
                                                        xs={3}
                                                        style={{ span: 3, marginLeft: '1%' }}
                                                        className="mb-2 h-50 button"
                                                        onClick={() => {
                                                            setNewStores([]);
                                                            setChosenStore(null);
                                                        }}
                                                        css={css`
                                                            width: auto;
                                                            background-color: grey;
                                                            color: white;
                                                        `}
                                                    >
                                                        Close
                                                    </Button>
                                                    <div xs={3} style={{ span: 3 }}></div>
                                                </Row>
                                            )}
                                        </Form>
                                    )}

                                    {!!stores.length && chosenStore === null && (
                                        <>
                                            <h3 className="px-0">
                                                Default Hours
                                                <OverlayTrigger
                                                    overlay={
                                                        <Tooltip>
                                                            Use the table below to set your default company-wide hours.
                                                            If you want to set location-specific hours, select one of
                                                            your locations from the dropdown above, or add a new one.{' '}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <InfoCircleFill size={17} className="me-1 mb-1" />
                                                </OverlayTrigger>
                                            </h3>
                                        </>
                                    )}
                                </Row>
                                {chosen.location_name && <h3>Hours for {chosen.location_name}</h3>}
                                <Row className="hours-table">
                                    {[
                                        {
                                            value: 'sunday',
                                            display: 'Sunday',
                                        },
                                        {
                                            value: 'monday',
                                            display: 'Monday',
                                        },
                                        {
                                            value: 'tuesday',
                                            display: 'Tuesday',
                                        },
                                        {
                                            value: 'wednesday',
                                            display: 'Wednesday',
                                        },
                                        {
                                            value: 'thursday',
                                            display: 'Thursday',
                                        },
                                        {
                                            value: 'friday',
                                            display: 'Friday',
                                        },
                                        {
                                            value: 'satuday',
                                            display: 'Saturday',
                                        },
                                    ].map(({ value, display }, idx) => {
                                        return (
                                            <Col
                                                className="me-1 px-0 d-flex flex-column align-items-center"
                                                key={value}
                                            >
                                                <FormControlLabel
                                                    control={<GreenCheckbox name="checkedG" />}
                                                    label={display}
                                                    className="align-self-center my-0"
                                                    checked={
                                                        chosen.hours_open && chosen.hours_open[idx]?.start !== 'closed'
                                                    }
                                                    disabled={props.viewOnly}
                                                    onChange={(e) =>
                                                        setOverrides((prev) => {
                                                            const clone = [...chosen.hours_open];
                                                            clone[idx] = {
                                                                ...clone[idx],
                                                                start: clone[idx].start === 'closed' ? 12 : 'closed',
                                                                close: clone[idx].close === 'closed' ? 12 : 'closed',
                                                            };
                                                            const closed = clone
                                                                .filter((day) => day.start === 'closed')
                                                                .map((_day) => _day.day_num);

                                                            return {
                                                                ...prev,
                                                                hours_open: clone,
                                                                days_closed: closed,
                                                            };
                                                        })
                                                    }
                                                />
                                                <FormControl className=" my-1 w-100" variant="outlined">
                                                    <InputLabel>Open</InputLabel>
                                                    <Select
                                                        native
                                                        label="Open"
                                                        value={chosen.hours_open && chosen.hours_open[idx].start}
                                                        disabled={props.viewOnly}
                                                        onChange={(e) =>
                                                            setOverrides((prev) => {
                                                                const clone = [...chosen.hours_open];
                                                                clone[idx] = {
                                                                    ...clone[idx],
                                                                    start: e.target.value,
                                                                };
                                                                const closed = clone
                                                                    .filter((day) => day.start === 'closed')
                                                                    .map((_day) => _day.day_num);

                                                                return {
                                                                    ...prev,
                                                                    hours_open: clone,
                                                                    days_closed: closed,
                                                                };
                                                            })
                                                        }
                                                    >
                                                        <option aria-label="None" value="" />
                                                        {deliveryHours.hours.map((hour) => (
                                                            <option key={hour.value} value={hour.value}>
                                                                {hour.text}
                                                            </option>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <FormControl className=" my-1 w-100" variant="outlined">
                                                    <InputLabel>Close</InputLabel>
                                                    <Select
                                                        native
                                                        label="Close"
                                                        value={chosen.hours_open && chosen.hours_open[idx].close}
                                                        disabled={props.viewOnly}
                                                        onChange={(e) =>
                                                            setOverrides((prev) => {
                                                                const clone = [...chosen.hours_open];
                                                                clone[idx] = {
                                                                    ...clone[idx],
                                                                    close: e.target.value,
                                                                };
                                                                clone
                                                                    .filter((day) => day.start === 'closed')
                                                                    .map((_day) => _day.day_num);

                                                                return { ...prev, hours_open: clone };
                                                            })
                                                        }
                                                    >
                                                        <option aria-label="None" value="" />
                                                        {deliveryHours.hours.map((hour) => (
                                                            <option key={hour.value} value={hour.value}>
                                                                {hour.text}
                                                            </option>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Col>
                                        );
                                    })}
                                </Row>
                                <Row className="d-flex px-0 w-100 my-2">
                                    <Row className="my-2 px-0 w-100">
                                        <Col className="my-2 px-0 w-100" style={{ marginRight: 0 }}>
                                            <h5 className="">Please select the holidays you are closed for:</h5>
                                            <Row>
                                                <Col md={5} xl={2} className="w-auto">
                                                    {[
                                                        {
                                                            value: 'xmas',
                                                            display: 'Christmas',
                                                        },
                                                        {
                                                            value: 'xmas_eve',
                                                            display: 'Christmas Eve',
                                                        },
                                                        {
                                                            value: 'new_years_eve',
                                                            display: 'New Years Eve',
                                                        },
                                                        {
                                                            value: 'new_years_day',
                                                            display: 'New Years Day',
                                                        },
                                                    ].map(({ value, display }) => {
                                                        return (
                                                            <Row key={value}>
                                                                <FormControlLabel
                                                                    control={<Switch name="checkedG" color="primary" />}
                                                                    label={display}
                                                                    className="align-self-center my-0"
                                                                    checked={chosen[value]}
                                                                    disabled={props.viewOnly}
                                                                    onChange={() =>
                                                                        setOverrides((prev) => {
                                                                            return { ...prev, [value]: !prev[value] };
                                                                        })
                                                                    }
                                                                />
                                                            </Row>
                                                        );
                                                    })}
                                                </Col>
                                                <Col md={5} xl={2} className="w-auto">
                                                    {[
                                                        {
                                                            value: 'labor_day',
                                                            display: 'Labor Day',
                                                        },
                                                        {
                                                            value: 'memorial_day',
                                                            display: 'Memorial Day',
                                                        },
                                                        {
                                                            value: 'thanksgiving',
                                                            display: 'Thanksgiving',
                                                        },
                                                        {
                                                            value: 'july_fourth',
                                                            display: 'Independence Day',
                                                        },
                                                    ].map(({ value, display }) => {
                                                        return (
                                                            <Row key={value}>
                                                                <FormControlLabel
                                                                    control={<Switch name="checkedG" color="primary" />}
                                                                    label={display}
                                                                    className="align-self-center my-0"
                                                                    checked={chosen[value]}
                                                                    disabled={props.viewOnly}
                                                                    onChange={() =>
                                                                        setOverrides((prev) => {
                                                                            return { ...prev, [value]: !prev[value] };
                                                                        })
                                                                    }
                                                                />
                                                            </Row>
                                                        );
                                                    })}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Row>

                                {!props.viewOnly && (
                                    <Row className="d-flex flex-column align-items-start">
                                        {stores.length ? (
                                            <Button
                                                className="account-btn"
                                                onClick={onSaveClient}
                                                disabled={Object.keys(hasError).length > 0}
                                                css={css`
                                                    width: auto;
                                                `}
                                            >
                                                Save Changes
                                            </Button>
                                        ) : (
                                            <Button
                                                className="account-btn"
                                                onClick={onSubmitFirstLocation}
                                                disabled={Object.keys(hasError).length > 0}
                                                css={css`
                                                    width: auto;
                                                `}
                                            >
                                                Save First Location
                                            </Button>
                                        )}
                                        {validationMessage && <div className="my-1">{validationMessage}</div>}
                                    </Row>
                                )}
                            </>
                        }
                    </Container>
                </Card.Body>
            </ThemeProvider>
        </Card>
    );
}

export default Stores;
