import { LOCATION_TYPES } from '../constants/dropoffOptions';
import { ASSEMBLY_TYPES } from '../constants/freightTypes';
import { LOCATION_TYPES as SERVICE_TYPES } from '@/constants/locationTypes';

export const generateOptions = (multiPickup = false) => {
    return [
        {
            key: 'order_type',
            display: 'Pickup or Delivery',
            required: false,
            default: 'delivery',
            parser: (string) => {
                const lower = string.toLowerCase();
                if (lower.includes('pickup') || lower.includes('return')) {
                    return 'return';
                }
                return 'delivery';
            },
            acceptableValues: ['delivery', 'return'],
        },
        {
            key: 'po_number',
            display: 'PO / Order Number',
            required: true,
            parser: (string) => string,
        },
        {
            key: 'reference_id',
            display: 'Reference Id',
            required: false,
            parser: (string) => string,
        },
        {
            key: 'manufacturer',
            display: 'Manufacturer',
            required: false,
            parser: (string) => string,
        },
        ...(multiPickup
            ? [
                  {
                      key: 'pickup_store',
                      display: 'Store',
                      required: false,
                      parser: (string) => string,
                  },
                  {
                      key: 'pickup_street',
                      display: 'Pickup Address Line 1',
                      required: false,
                      parser: (string) => string,
                  },
                  {
                      key: 'pickup_unit',
                      display: 'Pickup Address Line 2',
                      required: false,
                      parser: (string) => string,
                  },
                  {
                      key: 'pickup_city',
                      display: 'Pickup City',
                      required: false,
                      parser: (string) => string,
                  },
                  {
                      key: 'pickup_state',
                      display: 'Pickup State',
                      required: false,
                      parser: (string) => string,
                  },
                  {
                      key: 'pickup_zip',
                      display: 'Pickup Zip',
                      required: false,
                      parser: (string) => string,
                  },
                  {
                      key: 'pickup_name',
                      display: 'Pickup Name',
                      required: false,
                      parser: (string) => string,
                  },
                  {
                      key: 'pickup_phone',
                      display: 'Pickup Phone',
                      required: false,
                      parser: (string) => string,
                  },
                  {
                      key: 'pickup_email',
                      display: 'Pickup Email',
                      required: false,
                      parser: (string) => string,
                  },
                  {
                      key: 'pickuplocation',
                      display: 'Pickup Location Type',
                      required: false,
                      default: 'Residence',
                      parser: (string) => string,
                      acceptableValues: LOCATION_TYPES.map((type) => type.value),
                  },
                  {
                      key: 'pickuplocationtype',
                      display: 'Pickup Service Level',
                      required: false,
                      parser: (string) => string,
                      acceptableValues: Object.values(SERVICE_TYPES),
                  },
                  {
                      key: 'pickupcomments',
                      display: 'Pickup Comments',
                      required: false,
                      parser: (string) => string,
                  },
              ]
            : []),
        {
            key: 'customer_street',
            display: 'Customer Address Line 1',
            required: true,
            parser: (string) => string,
        },
        {
            key: 'customer_unit',
            display: 'Customer Address Line 2',
            required: false,
            parser: (string) => string,
        },
        {
            key: 'customer_city',
            display: 'Customer City',
            required: true,
            parser: (string) => string,
        },
        {
            key: 'customer_state',
            display: 'Customer State',
            required: false,
            parser: (string) => string,
        },
        {
            key: 'customer_zip',
            display: 'Customer Zip',
            required: true,
            parser: (string) => string,
        },
        {
            key: 'customer_name',
            display: 'Customer Name',
            required: true,
            parser: (string) => string,
        },
        {
            key: 'customer_phone',
            display: 'Customer Phone',
            required: true,
            parser: (string) => string,
        },
        {
            key: 'customer_email',
            display: 'Customer Email',
            required: false,
            parser: (string) => string,
        },
        {
            key: 'dropofflocation',
            display: 'Customer Location Type',
            required: false,
            default: 'Residence',
            parser: (string) => string,
            acceptableValues: LOCATION_TYPES.map((type) => type.value),
        },
        {
            key: 'service_type',
            display: 'Customer Service Level',
            required: false,
            parser: (string) => string,
            acceptableValues: Object.values(SERVICE_TYPES),
        },
        {
            key: 'del_stairs',
            display: 'Customer Stairs',
            required: false,
            default: 0,
            parser: (string) => parseInt(string),
        },
        {
            key: 'del_elev',
            display: 'Customer Elevator',
            required: false,
            default: false,
            parser: (string) => string.toLowerCase().includes('yes'),
        },
        {
            key: 'dropoffcomments',
            display: 'Customer Comments',
            required: false,
            parser: (string) => string,
        },
        // item
        {
            key: 'desc',
            display: 'Description',
            required: false,
            parser: (string) => string,
        },
        {
            key: 'order_revenue_override',
            display: 'Order Revenue',
            required: false,
            parser: (string) => parseFloat(string.replace(/[^\d.]/g, '')),
        },
        {
            key: 'qty',
            display: 'Quantity',
            required: false,
            default: 1,
            parser: (string) => parseInt(string),
        },
        {
            key: 'handling_unit_id',
            display: 'Handling Unit ID',
            required: false,
            default: 1,
            parser: (string) => parseInt(string),
        },
        {
            key: 'package_seq_id',
            display: 'Package Sequence ID',
            required: false,
            default: 0,
            parser: (string) => parseInt(string),
        },
        {
            key: 'itemCubes',
            display: 'Cubic Feet',
            required: false,
            parser: (string) => parseInt(string),
        },
        {
            key: 'length',
            display: 'Items Length',
            required: false,
            parser: (string) => parseInt(string),
        },
        {
            key: 'width',
            display: 'Items Width',
            required: false,
            parser: (string) => parseInt(string),
        },
        {
            key: 'height',
            display: 'Items Height',
            required: false,
            parser: (string) => parseInt(string),
        },
        {
            key: 'weight',
            display: 'Items Weight',
            required: false,
            default: 12,
            parser: (string) => parseInt(string),
        },
        {
            key: 'sku',
            display: 'SKU #',
            required: false,
            parser: (string) => string,
        },
        {
            key: 'assembly_type',
            display: 'Assembly Type',
            required: false,
            parser: (string) => string,
            acceptableValues: ASSEMBLY_TYPES.map((opt) => opt.display),
        },
        {
            key: 'item_service_time',
            display: 'Service Time (in minutes)',
            required: false,
            parser: (string) => (Number.isFinite(parseInt(string)) ? parseInt(string) : undefined),
        },
        {
            key: 'delivery_date',
            display: 'Scheduled Delivery Date',
            required: false,
            parser: (string) => string,
        },
        {
            key: 'preferred_delivery_date',
            display: 'Preferred Delivery Date',
            required: false,
            parser: (string) => string,
        },
        {
            key: 'estimated_delivery_range_start',
            display: 'Estimated Delivery Start Date',
            required: false,
            parser: (string) => string,
        },
        {
            key: 'estimated_delivery_range_end',
            display: 'Estimated Delivery End Date',
            required: false,
            parser: (string) => string,
        },
        {
            key: 'item_type_deets',
            display: 'Item Type',
            required: false,
            parser: (string) => string,
        },
        {
            key: 'warehouse_estimated_delivery_date',
            display: 'Estimated Warehouse Recieved Date',
            required: false,
            parser: (string) => string,
        },
        {
            key: 'warehouse_estimated_ship_date',
            display: 'Estimated Warehouse Ship Date',
            required: false,
            parser: (string) => string,
        },
        {
            key: 'warehouse_delivery_notes',
            display: 'Warehouse Delivery Notes',
            required: false,
            parser: (string) => string,
        },
        {
            key: 'warehouse_trailer_id',
            display: 'Warehouse Trailer ID',
            required: false,
            parser: (string) => string,
        },
    ];
};
