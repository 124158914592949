import React, { useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import { PrimaryButton } from '@/styles/blocks';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

const options = ['Detailed Export', 'Quickbooks Export'];

function Footer({ state, callbacks }) {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleClick = () => {
        if (selectedIndex === 0) {
            callbacks.exportSelectedDetailed();
        } else if (selectedIndex === 1) {
            callbacks.exportSelectedQuickbooks();
        }
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <Grid
                container
                css={css`
                    padding: 1rem;
                    background-color: white;
                    justify-content: center;
                    align-items: center;
                `}
            >
                {state.invoiceType === 'INTERNAL' && state.status === 'UNPAID' ? (
                    <Grid
                        item
                        css={css`
                            margin-right: 1rem;
                        `}
                    >
                        <PrimaryButton onClick={callbacks.setPaid} disabled={state.selected.length === 0}>
                            Mark as Paid
                        </PrimaryButton>
                    </Grid>
                ) : null}

                <Grid
                    item
                    css={css`
                        margin-right: 1rem;
                    `}
                >
                    <ButtonGroup variant="contained" ref={anchorRef}>
                        <PrimaryButton onClick={handleClick} disabled={state?.selected?.length === 0}>
                            {options[selectedIndex]}
                        </PrimaryButton>
                        <PrimaryButton
                            size="small"
                            onClick={handleToggle}
                            css={css`
                                min-width: 0px;
                            `}
                        >
                            <ArrowDropDownIcon />
                        </PrimaryButton>
                    </ButtonGroup>
                    <Popper
                        sx={{ zIndex: 1 }}
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu" autoFocusItem>
                                            {options.map((option, index) => (
                                                <MenuItem
                                                    key={option}
                                                    selected={index === selectedIndex}
                                                    onClick={(event) => handleMenuItemClick(event, index)}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Grid>
            </Grid>
        </>
    );
}

export default Footer;
