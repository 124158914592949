import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Checkbox, Chip, Grid, IconButton, MenuItem, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { OnwardChip } from '@/styles/blocks';
import { DeleteForeverOutlined, SaveOutlined } from '@material-ui/icons';

const ONWARD_TYPES = {
    curbside: 'Curbside',
    firstDrySpace: 'First Dry Space / Threshold',
    roomOfChoice: 'Room of Choice',
    whiteGlove: 'White Glove',
};

export const ONWARD_TAGS = {
    '2_man_team': '2 Man Team',
    '3_man_team': '3 Man Team',
    '4_man_team': '4 Man Team',
    no_contact: 'No Contact',
    assembly_required: 'Assembly Required',
};

const ServiceLevel = ({ serviceLevel, tags = [], callbacks, loading }) => {
    const [updates, setUpdates] = useState({});

    const updated = useMemo(() => {
        return {
            ...serviceLevel,
            ...updates,
        };
    }, [serviceLevel, updates]);

    const options = useMemo(() => {
        return [
            ...Object.entries(ONWARD_TAGS).map(([key, label]) => ({
                type: 'onward',
                key,
                label,
                selected: updated.onward_tags?.includes(key),
            })),
            ...tags.map((tag) => ({
                type: 'custom',
                key: tag.tag_id,
                label: tag.tag,
                selected: updated.tags?.map((mapping) => mapping.tag_id)?.includes(tag.tag_id),
            })),
        ];
    }, [updated, ONWARD_TAGS, tags]);

    return (
        <Grid
            container
            css={css`
                flex-wrap: nowrap;
            `}
        >
            <Grid
                item
                css={css`
                    width: 250px;
                `}
            >
                <TextField
                    variant="outlined"
                    label="Name"
                    value={updated.service_level || ''}
                    onChange={(e) => setUpdates((prev) => ({ ...prev, service_level: e.target.value }))}
                    fullWidth
                />
            </Grid>
            <Grid
                item
                css={css`
                    width: 250px;
                    margin-left: 2rem;
                `}
            >
                <TextField
                    select
                    variant="outlined"
                    label="Tariff Service Type"
                    value={updated.location_type || ''}
                    onChange={(e) => setUpdates((prev) => ({ ...prev, location_type: e.target.value }))}
                    fullWidth
                >
                    {Object.entries(ONWARD_TYPES).map(([value, label]) => (
                        <MenuItem value={value} key={value}>
                            {label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid
                item
                css={css`
                    width: 250px;
                    margin-left: 2rem;
                `}
            >
                <Autocomplete
                    multiple
                    value={options.filter((o) => o.selected)}
                    limitTags={1}
                    disableCloseOnSelect
                    onChange={(e, value) => {
                        console.log(value);
                        const onwardTags = value.filter((tag) => tag.type === 'onward');
                        const customTags = value.filter((tag) => tag.type === 'custom');
                        setUpdates((prev) => ({
                            ...prev,
                            onward_tags: onwardTags.map((tag) => tag.key),
                            tags: customTags.map((tag) => ({
                                tag_id: tag.key,
                                service_level_id: prev.service_level_id,
                            })),
                        }));
                    }}
                    options={options}
                    getOptionSelected={(option, value) => {
                        return option.key === value.key;
                    }}
                    getOptionLabel={(option) => option.label || option.key}
                    renderTags={(options, getTagProps) => {
                        const first = options.length ? (
                            <OnwardChip key={options[0].key} label={options[0].label} />
                        ) : (
                            ''
                        );
                        const more =
                            options.length > 1 ? (
                                <span
                                    css={css`
                                        font-weight: 500;
                                    `}
                                >
                                    {' '}
                                    +{options.length - 1}
                                </span>
                            ) : (
                                ''
                            );
                        return (
                            <span>
                                {first}
                                {more}
                            </span>
                        );
                    }}
                    renderOption={(option, { selected }) => {
                        return (
                            <li key={option.key}>
                                <Checkbox color="primary" checked={selected} />
                                {option.label || option.key}
                            </li>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField variant="outlined" {...params} label="Order Tags" placeholder="Tags" />
                    )}
                />
            </Grid>
            <Grid
                item
                css={css`
                    width: 400px;
                    margin-left: 2rem;
                `}
            >
                <TextField
                    variant="outlined"
                    label="Special Instructions"
                    value={updated.instructions || ''}
                    onChange={(e) => setUpdates((prev) => ({ ...prev, instructions: e.target.value }))}
                    fullWidth
                />
            </Grid>
            <Grid
                item
                css={css`
                    margin-left: 1rem;
                `}
            >
                <IconButton disabled={loading} onClick={() => callbacks.onSave(updated)}>
                    <SaveOutlined fontSize="large" color={loading ? 'disabled' : 'primary'} />
                </IconButton>
            </Grid>
            <Grid
                item
                css={css`
                    margin-left: 1rem;
                `}
            >
                <IconButton disabled={loading} onClick={() => callbacks.onDelete(serviceLevel.service_level_id)}>
                    <DeleteForeverOutlined fontSize="large" color={loading ? 'disabled' : 'error'} />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default ServiceLevel;
