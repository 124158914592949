import { toNational } from '@/utilities/formatPhoneNumber';
import { calcItemCount, calcItemVolume } from '@/utilities/calculateItemMetrics';
import { formatInTimeZone } from 'date-fns-tz';

const minuteString = new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'minute',
});

const dateNumeric = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
});

export const ROUTE_COLUMNS_EXT = [
    {
        header: 'Route Name',
        getValue: ({ route }) => route.route_alias || '',
    },
    {
        header: 'Route Id',
        getValue: ({ route }) => route.route_number,
    },
    {
        header: 'Order #s',
        getValue: ({ stop, orders }) => orders?.map((order) => order?.order_number),
        format: (value) => {
            return `"${value.join(', ')}"`;
        },
    },
    {
        header: 'Reference Id',
        getValue: ({ orders }) => orders?.map((order) => order?.reference_id),
        format: (value) => {
            return `"${value.join(', ')}"`;
        },
    },
    {
        header: 'Manufacturer',
        getValue: ({ orders }) => orders?.map((order) => order?.manufacturer),
        format: (value) => {
            return `"${value.join(', ')}"`;
        },
    },
    {
        header: 'Driver Name',
        getValue: ({ driver }) => driver.username,
    },
    {
        header: 'Stop #',
        getValue: ({ stop, orders }) => stop.ordering,
    },
    {
        header: 'Customer Names',
        getValue: ({ stop, orders }) => orders?.map((order) => order?.dropoff_name),
        format: (value) => {
            return `"${value.join(', ')}"`;
        },
    },
    {
        header: 'Customer Phone #s',
        getValue: ({ stop, orders }) => orders?.map((order) => toNational(order?.dropoff_phone)),
        format: (value) => {
            return `"${value.join(', ')}"`;
        },
    },
    {
        header: 'Stop Start Date',
        getValue: ({ stop, orders }) => {
            if (!stop.del_window_start) {
                return undefined;
            }
            const date = new Date(stop.del_window_start);
            return isNaN(date) ? undefined : date;
        },
        format: (value) => `"${dateNumeric.format(value)}"`,
    },
    {
        header: 'Stop Start Time',
        getValue: ({ stop, orders }) => {
            if (!stop.del_window_start) {
                return undefined;
            }
            const date = new Date(stop.del_window_start);
            return isNaN(date) ? undefined : date;
        },
        format: (value, { tz = 'America/New_York' }) => {
            return `"${formatInTimeZone(value, tz, 'HHmm')}"`;
        },
    },
    {
        header: 'Stop End Time',
        getValue: ({ stop, orders }) => {
            if (!stop.del_window_end) {
                return undefined;
            }
            const date = new Date(stop.del_window_end);
            return isNaN(date) ? undefined : date;
        },
        format: (value, { tz = 'America/New_York' }) => {
            return `"${formatInTimeZone(value, tz, 'HHmm')}"`;
        },
    },
    {
        header: 'Stop Service Time',
        getValue: ({ stop, orders }) => {
            const times = orders?.map((order) => order?.service_time);
            return times.some((time) => !!time) ? Math.max(...times) / 60 / 1000 : undefined;
        },
        format: (value) => `"${minuteString.format(value)}"`,
    },
    {
        header: 'Address 1',
        getValue: ({ stop, orders }) => {
            if (stop.ordering === 0) {
                return orders?.map((order) => order?.dropoff_street);
            }
            return orders[0]?.dropoff_street;
        },
        format: (value) => {
            return Array.isArray(value) ? `"${value.join(', ')}"` : `"${value}"`;
        },
    },
    {
        header: 'Address 2',
        getValue: ({ stop, orders }) => {
            if (stop.ordering === 0) {
                let allUnits = orders?.reduce((secondaryAddresses, order) => {
                    if (order.dropoff_unit) {
                        secondaryAddresses.push(order.dropoff_unit);
                    }
                    return secondaryAddresses;
                }, []);
                return allUnits;
            }
            return orders[0]?.dropoff_unit || '';
        },
        format: (value) => {
            return Array.isArray(value) ? `"${value.join(', ')}"` : `"${value}"`;
        },
    },
    {
        header: 'City',
        getValue: ({ stop, orders }) => orders?.map((order) => order?.dropoff_city),
        format: (value) => {
            return `"${value.join(', ')}"`;
        },
    },
    {
        header: 'State',
        getValue: ({ stop, orders }) => orders?.map((order) => order?.dropoff_state),
        format: (value) => {
            return `"${value.join(', ')}"`;
        },
    },
    {
        header: 'Zip',
        getValue: ({ stop, orders }) => orders?.map((order) => order?.dropoff_zip),
        format: (value) => {
            return `"${value.join(', ')}"`;
        },
    },
    {
        header: 'Item Quantity',
        getValue: ({ stop, orders }) => {
            return calcItemCount(orders);
        },
        format: (value) => value,
    },
    {
        header: 'Cubic Ft.',
        getValue: ({ stop, orders }) => {
            return calcItemVolume(orders);
        },
        format: (value) => parseFloat(value.toFixed(1)) || value,
    },
    {
        header: 'PO #s',
        getValue: ({ stop, orders }) => {
            const poNumbers = [];
            orders.forEach((o) => {
                o?.po_number && poNumbers.push(o.po_number);
            });
            return poNumbers;
        },
        format: (value) => {
            return `"${value.join(', ')}"`;
        },
    },
    {
        header: 'SKUs',
        getValue: ({ stop, orders }) => {
            let skus = new Set();

            orders.forEach((o) => {
                o.items && o.items.forEach((i) => i.sku && skus.add(i.sku));
                o.returns && o.returns.forEach((i) => i.sku && skus.add(i.sku));
            });

            return Array.from(skus);
        },
        format: (value) => {
            return `"${value.join(', ')}"`;
        },
    },
    {
        header: 'ItemDescs',
        getValue: ({ stop, orders }) => {
            let descs = [];

            orders.forEach((o) => {
                let sortedItems = [...(o.items || [])].sort((a, b) => (a.description > b.description ? 1 : -1));
                let sortedReturns = [...(o.returns || [])].sort((a, b) => (a.description > b.description ? 1 : -1));
                sortedItems && sortedItems.forEach((i) => i.description && descs.push(i.description));
                sortedReturns && sortedReturns.forEach((i) => i.description && descs.push(i.description));
            });

            return descs;
        },
        format: (value) => {
            return `"${value.join(', ')}"`;
        },
    },
    {
        header: 'ItemCounts',
        getValue: ({ stop, orders }) => {
            let quants = [];

            orders.forEach((o) => {
                let sortedItems = [...(o.items || [])].sort((a, b) => (a.description > b.description ? 1 : -1));
                let sortedReturns = [...(o.returns || [])].sort((a, b) => (a.description > b.description ? 1 : -1));
                sortedItems && sortedItems.forEach((i) => i.quantity && quants.push(i.quantity));
                sortedReturns && sortedReturns.forEach((i) => i.quantity && quants.push(i.quantity));
            });

            return quants;
        },
        format: (value) => {
            return `"${value.join(', ')}"`;
        },
    },
    {
        header: 'Delivery Type',
        getValue: ({ stop, orders }) => {
            return orders[0].dropoff_location_type || 'Not Specified';
        },
    },
    {
        header: 'Order Type',
        getValue: ({ stop, orders }) => {
            let stopType = 'Delivery';
            if (stop.type === 'PICKUP' && stop.returns?.length) {
                stopType = 'Return';
            } else if (stop.type === 'DROPOFF' && stop.exchanges?.length) {
                stopType = 'Exchange';
            }
            return stopType;
        },
    },
    {
        header: 'Order Status',
        getValue: ({ orders }) => orders?.map((order) => order?.order_status),
        format: (value) => {
            return `"${value.join(', ')}"`;
        },
    },
    {
        header: 'Customer Secondary Phone #s',
        getValue: ({ stop, orders }) =>
            orders?.reduce((secondaryNums, order) => {
                if (order.dropoff_secondary_phone) {
                    secondaryNums.push(order.dropoff_secondary_phone);
                }
                return secondaryNums;
            }, []),
        format: (value) => {
            return Array.isArray(value) ? `"${value.join(', ')}"` : '';
        },
    },
    {
        header: 'Customer Emails',
        getValue: ({ stop, orders }) =>
            orders?.reduce((customerEmails, order) => {
                if (order.dropoff_email) {
                    customerEmails.push(order.dropoff_email);
                }
                return customerEmails;
            }, []),
        format: (value) => {
            return Array.isArray(value) ? `"${value.join(', ')}"` : '';
        },
    },
    {
        header: 'Order Notes',
        getValue: ({ stop, orders }) => {
            return orders?.length !== 1 ? '' : orders?.[0]?.comments.join(' / ');
        },
    },
];
