import {
    MANIFEST_STATUSES,
    PALLET_STATUSES,
    RECEIVED_STATUSES,
    STAGED_STATUSES,
    STORED_STATUSES,
} from '@/constants/manifestStatuses';

export const enrichManifest = (manifest) => {
    const orders = getManifestOrders(manifest);

    const [received, staged, picked_up, total, cubes] = orders.reduce(
        ([accReceived, accStaged, accPickedUp, accTotal, accCubes], order) => {
            const [itemsReceived, itemsStaged, itemsPickedUp, itemsTotal, itemsCubes] = (
                order.manifestItems || []
            ).reduce(
                ([accItemsReceived, accItemsStaged, accItemsPickedUp, accItemsTotal, accItemsCubes], item) => {
                    const qty = item.quantity || 1;
                    const cubes = item.total_cubes || 0;
                    const isReceived = STORED_STATUSES.includes(item?.pallet?.warehouse_status);
                    const isStaged = STAGED_STATUSES.includes(item?.pallet?.warehouse_status);
                    const isPickedUp = item?.pallet?.warehouse_status === PALLET_STATUSES.PICKED_UP;
                    return [
                        accItemsReceived + (isReceived ? qty : 0),
                        accItemsStaged + (isStaged ? qty : 0),
                        accItemsPickedUp + (isPickedUp ? qty : 0),
                        accItemsTotal + qty,
                        accItemsCubes + cubes,
                    ];
                },
                [0, 0, 0, 0, 0]
            );
            return [
                accReceived + itemsReceived,
                accStaged + itemsStaged,
                accPickedUp + itemsPickedUp,
                accTotal + itemsTotal,
                accCubes + itemsCubes,
            ];
        },
        [0, 0, 0, 0, 0]
    );

    let status;
    switch (manifest?.type) {
        case 'CROSS_DOCK':
        case 'INBOUND': {
            status = MANIFEST_STATUSES.NOT_RECEIVED;
            if (received > 0) {
                status = MANIFEST_STATUSES.IN_PROGRESS;
            }
            if (received > 0 && received === total) {
                status = MANIFEST_STATUSES.RECEIVED;
            }
            break;
        }
        case 'WILL_CALL':
        case 'RETURN_TO_SENDER':
        case 'OUTBOUND': {
            status = MANIFEST_STATUSES.AWAITING_STAGING;
            if (staged > 0) {
                status = MANIFEST_STATUSES.IN_PROGRESS;
            }
            if (staged > 0 && staged === total) {
                status = MANIFEST_STATUSES.STAGED;
            }
            if (['inProgress', 'complete'].includes(manifest.route?.status) || (picked_up > 0 && picked_up === total)) {
                status = MANIFEST_STATUSES.COMPLETE;
            }
            break;
        }
    }

    return {
        ...manifest,
        orders,
        status,
        received,
        staged,
        total,
        cubes,
    };
};

export const getManifestOrders = (manifest) => {
    if (manifest?.route) {
        return (manifest.route?.orders || [])
            .filter((mapping) => {
                // Only show pickup -> crossdock leg orders for CROSS_DOCK type manifests
                const crossdock = manifest.type !== 'CROSS_DOCK' || mapping.type === 'PICKUP';
                return (
                    crossdock &&
                    !['cancelled', 'rejected', 'rescheduled', 'deleted'].includes(mapping.order.order_status)
                );
            })
            .map((mapping) => ({
                ...mapping.order,
                manifestItems: mapping.order.itemsByOrderId,
            }));
    }
    const itemsByOrderId = (manifest?.items || []).reduce((grouped, { item }) => {
        return {
            ...grouped,
            [item.order_id]: {
                ...item.order,
                manifestItems: [...(grouped[item.order_id]?.manifestItems || []), item],
            },
        };
    }, {});
    return Object.values(itemsByOrderId);
};
