import { gql } from '@apollo/client';
import { ORDER_FIELDS } from '@/graphql/queries/orders';
import { ITEM_FIELDS } from '@/graphql/queries/items';
import { WAREHOUSE_EVENT_FIELDS } from '@/graphql/queries/order_wh_events';
import { ROUTE_FIELDS } from '@/graphql/queries/routes';
import { CUSTOM_SMS } from '@/constants/apiRoutes';
import { post } from '@/utilities/onwardClient';

export const QUERY_ORDERS_BY_OD_OR_PO = gql`
    query GetOrdersByNumber($user_id: uuid!, $order_numbers: [String!] = [], $po_numbers: [String!] = []) {
        orders(
            where: {
                _and: [
                    {
                        _or: [
                            { carrier_id: { _eq: $user_id } }
                            { _and: [{ shipper_id: { _eq: $user_id } }, { carrier_id: { _is_null: true } }] }
                        ]
                    }
                    {
                        order_status: {
                            _nin: [
                                "deleted"
                                "pending_confirmation"
                                "cancelled"
                                "awaiting_payment"
                                "on_hold_awaiting_payment"
                                "processing"
                                "duplicate"
                                "incomplete"
                            ]
                        }
                    }
                    { _or: [{ order_number: { _in: $order_numbers } }, { po_number: { _in: $po_numbers } }] }
                ]
            }
        ) {
            order_id
            order_number
            po_number
        }
    }
`;

export const UPDATE_ORDERS_MANY = gql`
    ${ORDER_FIELDS}
    ${WAREHOUSE_EVENT_FIELDS}
    mutation BulkUpdateOrders($updates: [orders_updates!] = [], $events: [order_wh_events_insert_input!] = []) {
        update_orders_many(updates: $updates) {
            affected_rows
            returning {
                ...OrderFields
            }
        }
        insert_order_wh_events(objects: $events) {
            affected_rows
            returning {
                ...WarehouseEvents
            }
        }
    }
`;

export const QUERY_ORDERS_WITH_DRIVERS_BY_CARRIER_ID_OR_SHIPPER_ID = gql`
    ${ORDER_FIELDS}
    ${ITEM_FIELDS}
    ${ROUTE_FIELDS}
    query orders($limit: Int!, $offset: Int!, $where: orders_bool_exp!, $order_by: [orders_order_by!]) {
        orders_aggregate(where: $where) {
            aggregate {
                totalCount: count
            }
        }
        orders(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
            ...OrderFields
            routes(order_by: { created_at: desc }) {
                mapping_id
                route_id
                order_id
                type
                created_at
                route {
                    ...RouteFields
                    teammateByDriverId {
                        teammate_id
                        username
                    }
                }
            }
            items @client {
                ...ItemFields
            }
            returns @client {
                ...ItemFields
            }
            itemsByOrderId {
                ...ItemFields
            }
            order_shipper {
                client_id
                business_name
            }
        }
    }
`;

export const QUERY_ORDER_SHIPPERS = gql`
    query GetOrderShippers($user_id: uuid!) {
        orders(where: { carrier_id: { _eq: $user_id }, shipper_id: { _is_null: false } }, distinct_on: [shipper_id]) {
            order_id
            shipper_id
            order_shipper {
                client_id
                business_name
            }
        }
    }
`;

export const QUERY_ACTIONABLE_ORDER_AGGREGATES = gql`
    query orders($where: orders_bool_exp!) {
        orders_aggregate(where: $where) {
            aggregate {
                totalCount: count
            }
        }
    }
`;

export const QUERY_DROPOFF_LOCATIONS_WITH_DRIVERS_BY_CARRIER_ID_OR_SHIPPER_ID = gql`
    ${ORDER_FIELDS}
    ${ITEM_FIELDS}

    query orders($user_id: uuid!) {
        orders(
            where: { _or: [{ shipper_id: { _eq: $user_id } }, { carrier_id: { _eq: $user_id } }] }
            distinct_on: [dropoff_zip, pickup_zip]
        ) {
            order_id
            order_number
            dropoff_city
            dropoff_state
            dropoff_zip
            pickup_city
            pickup_state
            pickup_zip
            order_shipper {
                client_id
                business_name
            }
        }
    }
`;

export const INSERT_EVENTS = gql`
    mutation InsertEvents($events: [order_wh_events_insert_input!]!) {
        insert_order_wh_events(objects: $events) {
            affected_rows
            returning {
                event_id
            }
        }
    }
`;

export const SEND_MESSAGES = async ({ message, order_ids }) => {
    return post(CUSTOM_SMS, {
        message,
        order_ids,
    });
};
