import { gql } from '@apollo/client';

export const QUERY_USERS = gql`
    query Users {
        users {
            email
            username
            user_id
            roles
            shipper {
                approved
                test_acc
            }
            carrier {
                approved
                test_acc
            }
        }
    }
`;

export const QUERY_USERS_BY_ROLE = gql`
    query UsersByRole {
        shippers: clients(
            order_by: { client_id: asc }
            where: { deactivated: { _neq: true }, user: { roles: { _has_key: "SHIPPER" } } }
        ) {
            client_id
            approved
            test_acc
            business_name
            partnerships_enabled
            user {
                user_id
                username
                email
                roles
            }
        }
        carriers: clients(
            order_by: { client_id: asc }
            where: { deactivated: { _neq: true }, user: { roles: { _has_key: "CARRIER" } } }
        ) {
            client_id
            approved
            test_acc
            business_name
            partnerships_enabled
            user {
                user_id
                username
                email
                roles
            }
            locations {
                location_id
                business_zip
            }
        }

        teammates(order_by: { teammate_id: asc }, where: { team_leader_id: { _is_null: false } }) {
            team_leader_type
            teammate_id
            user {
                user_id
                username
                email
                roles
            }
        }
    }
`;
