import React, { useState, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { SecondaryButton } from '@/styles/blocks';
import { Grid, TextField, MenuItem } from '@material-ui/core';
import uploadPhotoAsync from '@/utilities/uploadPhotoAsync';
import { EXCEPTION_TYPES, EXCEPTION_DISPLAY } from '@onward-delivery/core';

import { Header2, Body1 } from '../../Crossdocking/blocks';

import Damaged from './Damaged';
import Attempted from './Attempted';
import Refusal from './Refusal';
import Default from './Default';
import Shortage from './Shortage';
import Overage from './Overage';

import { ResponsiveSidebarDialog, StickyModalActions, ModalContent, ModalTitle, PrimaryButton } from '@/styles/blocks';

const ExceptionModal = ({
    type,
    types = [
        EXCEPTION_TYPES.DAMAGED,
        EXCEPTION_TYPES.SHORTAGE,
        EXCEPTION_TYPES.OVERAGE,
        EXCEPTION_TYPES.REFUSAL,
        EXCEPTION_TYPES.ATTEMPTED_DELIVERY,
        EXCEPTION_TYPES.CUSTOMER_CANCELLATION,
        EXCEPTION_TYPES.NO_ROOM,
        EXCEPTION_TYPES.CARRIER_CANCELLATION,
    ],
    prev,
    order,
    loading,
    callbacks,
    options = {},
}) => {
    const [updates, setException] = useState({});

    const exception = useMemo(() => {
        return {
            ...prev,
            ...updates,
            reported_at: type,
        };
    }, [type, order, prev, updates]);

    useEffect(() => {
        setException({});
    }, [order]);

    const required = useMemo(() => {
        let requiredFields;
        switch (exception.type) {
            case EXCEPTION_TYPES.ATTEMPTED_DELIVERY:
                requiredFields = ['type', 'reason']
                if (exception.reason === 'Other') {
                    requiredFields.push('notes')
                }
                break;
            case EXCEPTION_TYPES.DAMAGED:
                requiredFields = ['type', 'notes', 'reason', 'items', 'images']
                break;
            case EXCEPTION_TYPES.REFUSAL:
                requiredFields = ['type', 'notes', 'items', 'reason']
                break;
            case EXCEPTION_TYPES.OVERAGE:
            case EXCEPTION_TYPES.SHORTAGE:
                requiredFields = ['type', 'items']
                if (exception.reason === 'Other') {
                    requiredFields.push('notes')
                }
                break;
            default:
                requiredFields = ['type']
                if (exception.reason === 'Other') {
                    requiredFields.push('notes')
                }
                break;
        }
        return requiredFields;
    }, [exception]);

    const isValid = useMemo(() => {
        return required.every((attr) => {
            switch (attr) {
                case 'images':
                    return exception?.images?.length > 0;
                case 'items':
                    return Object.values(exception.items || {}).filter((x) => x).length > 0;
                default:
                    return !!exception[attr];
            }
        });
    }, [required, exception]);

    const handleFileAdd = async (file, fileType) => {
        if (file) {
            try {
                const url = URL.createObjectURL(file);
                const path = await uploadPhotoAsync(url, 'Exception Images', fileType || file.type);

                setException((prev) => ({
                    ...prev,
                    images: [...(prev.images || []), path],
                }));
            } catch (e) {
                console.log(e);
                callbacks.onError(e);
            }
        }
    };

    let content = null;
    if (exception.type) {
        switch (exception.type) {
            case EXCEPTION_TYPES.DAMAGED:
                content = (
                    <Damaged
                        required={required}
                        exception={exception}
                        order={order}
                        callbacks={{ setException, handleFileAdd }}
                    />
                );
                break;
            case EXCEPTION_TYPES.ATTEMPTED_DELIVERY:
                content = (
                    <Attempted
                        required={required}
                        exception={exception}
                        order={order}
                        callbacks={{ setException, handleFileAdd }}
                    />
                );
                break;
            case EXCEPTION_TYPES.REFUSAL:
                content = (
                    <Refusal
                        required={required}
                        exception={exception}
                        order={order}
                        callbacks={{ setException, handleFileAdd }}
                    />
                );
                break;
            case EXCEPTION_TYPES.SHORTAGE:
                content = (
                    <Shortage
                        required={required}
                        exception={exception}
                        order={order}
                        callbacks={{ setException, handleFileAdd }}
                    />
                );
                break;
            case EXCEPTION_TYPES.OVERAGE:
                content = (
                    <Overage
                        required={required}
                        exception={exception}
                        order={order}
                        callbacks={{ setException, handleFileAdd }}
                    />
                );
                break;
            default:
                content = (
                    <Default
                        required={required}
                        exception={exception}
                        order={order}
                        callbacks={{ setException, handleFileAdd }}
                    />
                );
                break;
        }
    }

    return (
        <ResponsiveSidebarDialog open={!!type} onClose={callbacks.onClose} width="md" fullWidth={true}>
            <ModalTitle onClose={callbacks.onClose} border>
                <Header2
                    css={css`
                        color: #4c4c4c;
                    `}
                >
                    Report Exception
                </Header2>
            </ModalTitle>
            <ModalContent
                css={css`
                    width: 100%;
                `}
            >
                <Grid
                    direction="row"
                    container
                    css={css`
                        margin-bottom: 30px;
                    `}
                >
                    <TextField
                        select
                        variant="outlined"
                        label="Type"
                        value={exception.type || ''}
                        onChange={(e) => {
                            setException((prev) => ({
                                ...prev,
                                type: e.target.value,
                            }));
                        }}
                        fullWidth
                    >
                        {types.map((value) => (
                            <MenuItem key={value} value={value}>
                                {EXCEPTION_DISPLAY[value]}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Body1
                        css={css`
                            color: #8d8d8d;
                            margin-top: 8px;
                        `}
                    >
                        Different exception types need to be reported separately.
                    </Body1>
                </Grid>
                {content}
            </ModalContent>
            <StickyModalActions border>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <SecondaryButton onClick={callbacks.onClose}>Cancel</SecondaryButton>
                </Grid>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex: 0;
                    `}
                >
                    <Grid
                        direction="row"
                        container
                        css={css`
                            flex-wrap: nowrap;
                        `}
                    >
                        {!exception.exception_id ? (
                            <Grid
                                container
                                direction="column"
                                css={css`
                                    flex: 0;
                                    flex-basis: 0;
                                    margin-right: 20px;
                                `}
                            >
                                <PrimaryButton
                                    disabled={!isValid || options?.disableAddAnother}
                                    loading={loading}
                                    onClick={() => {
                                        callbacks.onSubmitCreateAnother({ type, exception }).then(() => {
                                            setException({});
                                        });
                                    }}
                                >
                                    Add Another Exception
                                </PrimaryButton>
                            </Grid>
                        ) : null}
                        <Grid
                            container
                            direction="column"
                            css={css`
                                flex: 0;
                                flex-basis: 0;
                            `}
                        >
                            <PrimaryButton
                                disabled={!isValid}
                                loading={loading}
                                onClick={() => {
                                    callbacks.onSubmit({ type, exception }).then(() => {
                                        setException({});
                                    });
                                }}
                            >
                                Done
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </StickyModalActions>
        </ResponsiveSidebarDialog>
    );
};

export default ExceptionModal;
