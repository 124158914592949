import NavResponsiveModal, { ModalContent, ModalHeader } from '@/components/Navigation/NavResponsiveModal';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import AddressAutocomplete from '@/components/ShipmentForm/ModifiedAddressAutocomplete';
import { Body, PrimaryButton } from '@/styles/blocks';
import { toE164, toNational } from '@/utilities/formatPhoneNumber';
import { useLazyQuery } from '@apollo/client';
import { Card, Grid, TextField } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { SEARCH_SHIPPERS } from '../queries';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { useClientUser } from '@/hooks';

const AddShipperModal = ({ open, callbacks }) => {
    const { test_acc } = useClientUser();
    const [networkId, setNetworkId] = useState(null);
    const [searchFields, setSearchFields] = useState({});
    const [searchAttempted, attemptSearch] = useState(false);
    const [fetchShippers, { data, loading }] = useLazyQuery(SEARCH_SHIPPERS, {
        onCompleted: () => {
            attemptSearch(true);
        },
    });
    const [selectedShipper, selectShipper] = useState(null);
    const [shipperLogo, setShipperlogo] = useState(null);

    const shippers = useMemo(() => {
        return data?.shippers || [];
    }, [data]);

    const networkIdValid = useMemo(() => {
        return /^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/.test(networkId || '');
    }, [networkId]);

    const onClose = () => {
        selectShipper(null);
        setShipperlogo(null);
        callbacks.onClose();
    };

    const searchShippers = () => {
        selectShipper(null);
        setShipperlogo(null);

        fetchShippers({
            variables: {
                search: {
                    _and: [
                        { partnerships_enabled: { _eq: true } },
                        { user: { roles: { _has_key: 'SHIPPER' } } },
                        { test_acc: { _eq: test_acc } },
                        ...(networkId && networkIdValid
                            ? [{ client_id: { _eq: networkId } }]
                            : [
                                  {
                                      _or: ['business_name', 'business_address'].reduce((acc, attr) => {
                                          if (searchFields[attr]) {
                                              return [
                                                  ...acc,
                                                  {
                                                      [attr]: {
                                                          _ilike: `%${searchFields[attr]}%`,
                                                      },
                                                  },
                                              ];
                                          }
                                          return acc;
                                      }, []),
                                  },
                              ]),
                    ],
                },
            },
        });
    };

    const createShipper = () => {
        callbacks.onCreate(searchFields).then(() => {
            setSearchFields({});
            onClose();
        });
    };

    const inviteShipper = () => {
        callbacks.onInvite(searchFields).then(() => {
            setSearchFields({});
            onClose();
        });
    };

    const connectShipper = () => {
        callbacks.onConnect(selectedShipper, shipperLogo).then(() => {
            onClose();
        });
    };

    return (
        <NavResponsiveModal open={open} onClose={onClose}>
            <ModalHeader title="Add Shipper" onClose={onClose} />
            <ModalContent
                css={css`
                    width: 600px;
                `}
            >
                <Grid container direction="column">
                    <Grid
                        item
                        css={css`
                            margin-bottom: 12px;
                        `}
                    >
                        <TextField
                            variant="outlined"
                            label="Network ID"
                            InputLabelProps={{ shrink: true }}
                            placeholder="00000000-0000-0000-0000-000000000000"
                            value={networkId || ''}
                            onChange={(e) => setNetworkId(e.target.value)}
                            error={!!networkId && !networkIdValid}
                            fullWidth
                        />
                    </Grid>
                    <Grid
                        item
                        css={css`
                            margin-bottom: 12px;
                        `}
                    >
                        <TextField
                            variant="outlined"
                            label="Name"
                            value={searchFields.business_name || ''}
                            onChange={(e) => setSearchFields((prev) => ({ ...prev, business_name: e.target.value }))}
                            fullWidth
                        />
                    </Grid>
                    <Grid
                        item
                        css={css`
                            margin-bottom: 12px;
                        `}
                    >
                        <AddressAutocomplete
                            state={{
                                street: searchFields.business_address,
                                city: searchFields.business_city,
                                state: searchFields.business_state,
                            }}
                            handleAddressUpdate={async (value, split) => {
                                if (value && split) {
                                    setSearchFields((prev) => ({
                                        ...prev,
                                        business_address: split.street,
                                        business_city: split.city,
                                        business_state: split.state,
                                    }));
                                }
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        css={css`
                            margin-bottom: 12px;
                        `}
                    >
                        <TextField
                            variant="outlined"
                            label="Address Line 2"
                            value={searchFields.business_address_unit || ''}
                            onChange={(e) =>
                                setSearchFields((prev) => ({ ...prev, business_address_unit: e.target.value }))
                            }
                            fullWidth
                            css={css`
                                margin-right: 12px;
                            `}
                        />
                    </Grid>
                    <Grid
                        item
                        css={css`
                            display: flex;
                            flex-wrap: nowrap;
                            margin-bottom: 12px;
                        `}
                    >
                        <TextField
                            variant="outlined"
                            label="City"
                            value={searchFields.business_city || ''}
                            onChange={(e) => setSearchFields((prev) => ({ ...prev, business_city: e.target.value }))}
                            fullWidth
                            css={css`
                                margin-right: 12px;
                            `}
                        />
                        <TextField
                            variant="outlined"
                            label="State"
                            value={searchFields.business_state || ''}
                            onChange={(e) => setSearchFields((prev) => ({ ...prev, business_state: e.target.value }))}
                            fullWidth
                            css={css`
                                margin-right: 12px;
                            `}
                        />
                        <TextField
                            variant="outlined"
                            label="Zip"
                            value={searchFields.business_zip || ''}
                            onChange={(e) => setSearchFields((prev) => ({ ...prev, business_zip: e.target.value }))}
                            fullWidth
                        />
                    </Grid>
                    <Grid
                        item
                        css={css`
                            display: flex;
                            flex-wrap: nowrap;
                            margin-bottom: 12px;
                        `}
                    >
                        <TextField
                            variant="outlined"
                            label="Email"
                            value={searchFields.email || ''}
                            onChange={(e) => setSearchFields((prev) => ({ ...prev, email: e.target.value }))}
                            fullWidth
                            css={css`
                                margin-right: 12px;
                            `}
                        />
                        <TextField
                            variant="outlined"
                            label="Phone"
                            InputProps={{
                                inputComponent: PhoneNumberInput,
                            }}
                            value={toNational(searchFields.business_phone || '')}
                            onChange={(e) =>
                                setSearchFields((prev) => ({ ...prev, business_phone: toE164(e.target.value) }))
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid
                        item
                        css={css`
                            margin-bottom: 12px;
                            display: flex;
                            flex-wrap: nowrap;
                        `}
                    >
                        <PrimaryButton
                            disabled={
                                !networkIdValid &&
                                !['business_name', 'business_address'].some((attr) => !!searchFields[attr])
                            }
                            onClick={searchShippers}
                            fullWidth
                            css={css`
                                margin-right: 12px;
                            `}
                        >
                            Search Shippers
                        </PrimaryButton>
                        <PrimaryButton
                            disabled={['business_name', 'business_address', 'email', 'business_phone'].some(
                                (attr) => !searchFields[attr]
                            )}
                            onClick={createShipper}
                            fullWidth
                            css={css`
                                margin-right: 12px;
                            `}
                        >
                            Create Shipper
                        </PrimaryButton>
                        <PrimaryButton
                            disabled={['business_name', 'business_address', 'email', 'business_phone'].some(
                                (attr) => !searchFields[attr]
                            )}
                            onClick={inviteShipper}
                            fullWidth
                        >
                            Invite Shipper
                        </PrimaryButton>
                    </Grid>
                </Grid>
                {searchAttempted ? (
                    <>
                        <Grid
                            item
                            css={css`
                                margin-top: 24px;
                            `}
                        >
                            <Body
                                css={css`
                                    font-weight: 700;
                                `}
                            >
                                {shippers.length} Shippers Found
                            </Body>
                        </Grid>
                        <Grid
                            item
                            css={css`
                                display: flex;
                                flex-direction: column;
                                flex-wrap: nowrap;
                                max-height: 300px;
                                overflow-y: scroll;
                            `}
                        >
                            {shippers.map((shipper) => (
                                <Grid item>
                                    <Card
                                        css={css`
                                            margin-top: 12px;
                                            display: flex;
                                            flex-direction: column;
                                            flex-wrap: nowrap;
                                            padding: 12px;
                                            border: 2px solid
                                                ${selectedShipper === shipper.client_id
                                                    ? colors.greens.primary
                                                    : colors.greys.border};
                                            cursor: pointer;
                                            :hover {
                                                background-color: ${colors.greys.selected};
                                            }
                                        `}
                                        onClick={() => {
                                            setShipperlogo({
                                                profile_img: shipper.profile_img,
                                                profile_img_color: shipper.profile_img_color,
                                            });
                                            selectShipper(shipper.client_id);
                                        }}
                                    >
                                        <Body
                                            css={css`
                                                font-size: 14px;
                                                font-weight: 700;
                                            `}
                                        >
                                            {shipper.business_name}
                                        </Body>
                                        <Body>Network ID: {shipper.client_id}</Body>
                                        {shipper.business_address ? (
                                            <Body>
                                                {shipper.business_address}
                                                {shipper.business_address_unit
                                                    ? `, ${shipper.business_address_unit}`
                                                    : ''}
                                            </Body>
                                        ) : (
                                            <Body>No Address</Body>
                                        )}
                                        {shipper.business_city ? (
                                            <Body>
                                                {shipper.business_city}, {shipper.business_state} {shipper.business_zip}
                                            </Body>
                                        ) : null}
                                        <Body>{shipper.email || 'No Email Address'}</Body>
                                        <Body>
                                            {shipper.business_phone
                                                ? toNational(shipper.business_phone)
                                                : 'No Phone Number'}
                                        </Body>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid
                            item
                            css={css`
                                margin-top: 12px;
                                margin-bottom: 12px;
                            `}
                        >
                            <PrimaryButton disabled={!selectedShipper} onClick={connectShipper} fullWidth>
                                Connect
                            </PrimaryButton>
                        </Grid>
                    </>
                ) : null}
            </ModalContent>
        </NavResponsiveModal>
    );
};

export default AddShipperModal;
