import { gql } from '@apollo/client';

export const PALLET_ITEM_FIELDS = gql`
    fragment PalletItemFields on pallet_items {
        pallet_id
        type
        pallet_number
        pallet_name
        is_pool
        warehouse_id
        warehouse_location
        warehouse_status
        logs(order_by: { created_at: desc }) {
            log_id
            created_at
            warehouse_location
            warehouse_status
        }
    }
`;
