import { gql } from '@apollo/client';

export const TAG_FIELDS = gql`
    fragment TagFields on tags {
        tag_id
        client_id
        tag
        route_tag
        accessorial_tag
        created_at
    }
`;

export const TAG_MAPPING_FIELDS = gql`
    fragment TagMappingFields on tag_mappings {
        mapping_id
        tag_id
        order_id
        service_level_id
        tag {
            tag_id
            client_id
            tag
            route_tag
            accessorial_tag
            created_at
        }
    }
`;
