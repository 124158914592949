import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useClientUser } from '@/hooks';
import { DELETE_SURVEY_QUESTION, UPSERT_SURVEY_QUESTION } from './graphql';
import { QUERY_BY_ID } from '@/graphql/queries/users';
import { TabCard } from '../blocks';
import Snackbar from '@/components/Snackbar';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { SURVEY_TYPES } from './constants';
import { PrimaryButton } from '@/styles/blocks';
import { useMutation } from '@apollo/client';
import { captureException } from '@sentry/react';
import Survey from './Survey';
import RemoveSurvey from './RemoveSurvey';
import { OnwardTab, OnwardTabContainer } from '@/components/Tabs';

const Surveys = () => {
    const { survey_questions, user_id } = useClientUser();
    const [notification, setNotification] = useState({});
    const [surveyType, setSurveyType] = useState(Object.keys(SURVEY_TYPES)[0]);
    const [removingSurvey, setRemovingSurvey] = useState(null);

    const surveys = useMemo(() => {
        return (survey_questions || []).filter((s) => s.survey_type === surveyType);
    }, [survey_questions, surveyType]);

    const [upsertSurvey, { loading: upsertLoading }] = useMutation(UPSERT_SURVEY_QUESTION, {
        onError: (e) => {
            console.error(e);
            captureException(e);
            setNotification({
                severity: 'error',
                message: 'Error creating survey question. Please try again.',
            });
        },
        update: (cache, { data: { insert_survey_questions_one } }) => {
            cache.updateQuery(
                {
                    query: QUERY_BY_ID,
                    variables: {
                        user_id,
                    },
                },
                (data) => {
                    if (!insert_survey_questions_one) return data;

                    const clone = [...(data?.user?.client?.survey_questions || [])];
                    const idx = clone.findIndex((s) => s.survey_id === insert_survey_questions_one.survey_id);
                    if (idx >= 0) {
                        clone[idx] = insert_survey_questions_one;
                    } else {
                        clone.push(insert_survey_questions_one);
                    }

                    return {
                        ...data,
                        user: {
                            ...(data?.user || {}),
                            client: {
                                ...(data?.user?.client || {}),
                                survey_questions: clone,
                            },
                        },
                    };
                }
            );
        },
    });

    const [deleteSurvey, { loading: deleteLoading }] = useMutation(DELETE_SURVEY_QUESTION, {
        onError: (e) => {
            console.error(e);
            captureException(e);
            setNotification({
                severity: 'error',
                message: 'Error deleting survey question. Please try again.',
            });
        },
        update: (cache, { data: { delete_survey_questions_by_pk } }) => {
            cache.evict(cache.identify(delete_survey_questions_by_pk));
        },
    });

    const loading = upsertLoading || deleteLoading;

    return (
        <TabCard>
            <Snackbar open={!!notification.message} handleClose={() => setNotification({})} {...notification} />
            <RemoveSurvey
                open={!!removingSurvey}
                callbacks={{
                    onClose: () => setRemovingSurvey(null),
                    onConfirm: () =>
                        deleteSurvey({ variables: { id: removingSurvey } }).then(() => setRemovingSurvey(null)),
                }}
            />
            <Grid
                direction="column"
                css={css`
                    flex-wrap: nowrap;
                `}
            >
                <OnwardTabContainer value={surveyType} onChange={(e, t) => setSurveyType(t)}>
                    {Object.entries(SURVEY_TYPES).map(([value, label]) => (
                        <OnwardTab key={value} value={value} label={label} />
                    ))}
                </OnwardTabContainer>
                {surveys.map((survey, idx) => (
                    <Survey
                        key={survey.survey_id}
                        idx={idx}
                        survey={survey}
                        loading={loading}
                        callbacks={{
                            onSave: (survey) => {
                                const { __typename, survey_responses, ...rest } = survey;
                                return upsertSurvey({ variables: { survey_question: rest } });
                            },
                            onDelete: (id) => {
                                setRemovingSurvey(id);
                            },
                        }}
                    />
                ))}
                <Grid
                    css={css`
                        margin: 1rem;
                    `}
                >
                    <PrimaryButton
                        disabled={loading}
                        onClick={() =>
                            upsertSurvey({
                                variables: {
                                    survey_question: {
                                        client_id: user_id,
                                        survey_type: surveyType,
                                        question: '',
                                        response_type: 'string',
                                        enabled: true,
                                    },
                                },
                            })
                        }
                    >
                        Add Question
                    </PrimaryButton>
                </Grid>
            </Grid>
        </TabCard>
    );
};

export default Surveys;
