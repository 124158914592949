import React, { useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography, Collapse, Box, Grid } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { Rating } from '@material-ui/lab';
import { asDateInTZ } from '@/utilities/convertToISO';
import { formatInTimeZone } from 'date-fns-tz';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { isAfter, subDays } from 'date-fns';
import CounterBids from './CounterBids';
import BidComments from './BidComments';
import { OnwardCard } from '../OrderDetailsPage/Card';
import { SectionTitle } from '@/styles/blocks';
import { UPDATE_BID_BY_ID } from '@/graphql/mutations/bids';
import { useClientUser } from '@/hooks';
import { COMBINED_RATINGS_BY_ID } from './queries';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { colors } from '@/styles';
import { css } from '@emotion/react';
import { BoldItem } from './blocks';

export default function ShipperBidTable({
    listing,
    orderTz,
    selectedBid,
    setSelectedBid,
    setNotification,
    selectedBidDetails,
    isOnwardHandledQuoteShipper,
}) {
    const { user_id, partner_carriers, isOnwardAdmin } = useClientUser();

    // Don't display pre-bids to shippers.
    const shipperBids = useMemo(() => {
        if (listing.listing_status === 'pending') {
            return [];
        }
        return listing?.bids || [];
    }, [listing]);

    const { data: ratingsData } = useQuery(COMBINED_RATINGS_BY_ID, {
        skip: !shipperBids?.length,
        variables: {
            client_ids: shipperBids?.map((bid) => bid.carrier_id),
        },
    });
    const [carrierRatings, carrierGoogleRatings] = useMemo(() => {
        if (ratingsData?.clients?.length) {
            return ratingsData.clients.reduce(
                ([owAcc, googAcc], client) => {
                    return [
                        {
                            ...owAcc,
                            [client.client_id]: client?.ratings_aggregate?.aggregate?.avg?.rating,
                        },
                        {
                            ...googAcc,
                            [client.client_id]: client?.google_rating,
                        },
                    ];
                },
                [{}, {}]
            );
        }
        return [{}, {}];
    }, [ratingsData]);

    const [updateBid] = useMutation(UPDATE_BID_BY_ID);

    const sortedBidsList = useMemo(() => {
        let _list = shipperBids.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        const winningBidIndex = _list.findIndex((bid) => bid.bid_status === 'succeeded');
        if (winningBidIndex !== -1) {
            const succeededBid = _list[winningBidIndex];
            _list = [succeededBid, ..._list.slice(0, winningBidIndex), ..._list.slice(winningBidIndex + 1)];
        }
        return _list;
    }, [shipperBids]);

    const isExpired = (dateISO) => {
        const today = asDateInTZ(new Date().toISOString(), orderTz);
        const expiredAt = asDateInTZ(dateISO, orderTz);
        return isAfter(today, expiredAt);
    };

    const toggleSelectedBid = (bid_id, hadUnread) => {
        if (bid_id === selectedBid) {
            setSelectedBid(null);
        } else {
            setSelectedBid(bid_id);
            if (hadUnread) {
                updateBid({
                    variables: {
                        bid_id: bid_id,
                        update: {
                            last_viewed_shipper: new Date().toISOString(),
                        },
                    },
                    onError: (err) => {
                        console.error(err);
                    },
                });
            }
        }
    };

    const bidHasUnreadInfo = (bid) => {
        if (!bid.last_viewed_shipper) return true;

        const hasNewCounter = bid.counter_offers.some(
            (counter) =>
                isAfter(new Date(counter.created_at), new Date(bid.last_viewed_shipper)) &&
                counter.client_id !== user_id
        );
        const hasNewComment = bid.bid_comments.some(
            (comment) =>
                isAfter(new Date(comment.created_at), new Date(bid.last_viewed_shipper)) &&
                comment.client_id !== user_id
        );

        if (hasNewComment || hasNewCounter) return true;

        return false;
    };

    const partnerCarriersById = useMemo(() => {
        if (partner_carriers?.length) {
            const entries = partner_carriers?.map((i) => [i.client_id, i]);
            return Object.fromEntries(entries);
        }
        return {};
    }, [partner_carriers]);

    return (
        <TableContainer className="mt-5" component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={1} />
                        <TableCell>
                            <Typography>Starting Bid</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>Current Offer</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>Bid Date</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>Expiration Date</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>Delivery Date</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>Carrier Rating</Typography>
                        </TableCell>
                        {!!partner_carriers?.length && (
                            <TableCell>
                                <Typography>Carrier Name</Typography>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedBidsList?.length ? (
                        sortedBidsList?.map((bid, i) => (
                            <>
                                <TableRow
                                    onClick={() => toggleSelectedBid(bid.bid_id, bidHasUnreadInfo(bid))}
                                    key={bid.bid_id}
                                    sx={{
                                        cursor: 'pointer',
                                        ':hover': { backgroundColor: '#ebebeb' },
                                        borderTop: selectedBid ? 1 : 0,
                                        borderColor: 'lightgray',
                                        backgroundColor: selectedBid === bid.bid_id ? '#ebebeb' : 'white',
                                    }}
                                >
                                    <TableCell colSpan={1}>
                                        {bidHasUnreadInfo(bid) ? (
                                            <FiberManualRecordIcon
                                                sx={{ fontSize: 13, color: '#D23E3E', margin: 0, padding: 0 }}
                                            />
                                        ) : null}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        $
                                        {isOnwardHandledQuoteShipper && isOnwardAdmin
                                            ? bid.carrier_rate.toFixed(2)
                                            : bid.shipper_rate.toFixed(2)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {bid?.counter_offers?.length
                                            ? `$${
                                                  bid.counter_offers
                                                      ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                                                      .filter((counter) => counter.client_id !== user_id)[0]
                                                      ?.[
                                                          isOnwardHandledQuoteShipper && isOnwardAdmin
                                                              ? 'carrier_rate'
                                                              : 'shipper_rate'
                                                      ].toFixed(2) || '-'
                                              }`
                                            : `-`}
                                    </TableCell>
                                    <TableCell align="left">
                                        {formatInTimeZone(
                                            asDateInTZ(bid.created_at, orderTz),
                                            orderTz,
                                            'EEE, MMM d, yyyy'
                                        )}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={
                                            isExpired(bid.expiration_date) ? { color: 'red', fontWeight: 'bold' } : {}
                                        }
                                    >
                                        {formatInTimeZone(
                                            asDateInTZ(
                                                subDays(new Date(bid.expiration_date), 1).toISOString(),
                                                orderTz
                                            ),
                                            orderTz,
                                            'EEE, MMM d, yyyy'
                                        )}
                                    </TableCell>
                                    <TableCell align="left">
                                        {bid.target_delivery_date
                                            ? formatInTimeZone(
                                                  asDateInTZ(bid.target_delivery_date, orderTz),
                                                  orderTz,
                                                  'EEE, MMM d, yyyy'
                                              )
                                            : '-'}
                                    </TableCell>
                                    <TableCell align="left">
                                        {carrierRatings[bid.carrier_id] ? (
                                            <Rating readOnly value={carrierRatings[bid.carrier_id]} precision={0.1} />
                                        ) : (
                                            // fudge google ratings to be a min of 3.0
                                            <Rating
                                                readOnly
                                                value={Math.max(carrierGoogleRatings[bid.carrier_id] || 0, 3)}
                                                precision={0.1}
                                            />
                                        )}
                                    </TableCell>
                                    {!!partner_carriers?.length && (
                                        <TableCell align="left">
                                            {partnerCarriersById?.[bid?.carrier_id] ? (
                                                <span>
                                                    <CheckCircleIcon
                                                        css={css`
                                                            color: ${colors.greens.primary};
                                                            margin-right: 5px;
                                                        `}
                                                    />
                                                    {partnerCarriersById?.[bid?.carrier_id]?.business_name}
                                                </span>
                                            ) : (
                                                <span
                                                    css={css`
                                                        font-style: italic;
                                                    `}
                                                >
                                                    Marketplace Carrier
                                                </span>
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                                <TableCell
                                    style={{
                                        paddingBottom: bid.bid_id === selectedBid ? 15 : 0,
                                        paddingTop: 0,
                                        border: 0,
                                    }}
                                    colSpan={12}
                                >
                                    <Collapse in={bid.bid_id === selectedBid} timeout="auto" unmountOnExit>
                                        {selectedBidDetails ? (
                                            <>
                                                <OnwardCard className="my-5">
                                                    {isOnwardAdmin && (
                                                        <BoldItem>Carrier Name: {bid?.carrier?.business_name}</BoldItem>
                                                    )}
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="d-flex justify-content-between align-items-center"
                                                    >
                                                        <SectionTitle>Counter Bids</SectionTitle>
                                                    </Grid>
                                                    <CounterBids
                                                        bid={selectedBidDetails}
                                                        listing={listing}
                                                        setNotification={setNotification}
                                                        isOnwardHandledQuoteShipper={isOnwardHandledQuoteShipper}
                                                    />
                                                </OnwardCard>
                                                <BidComments bidDetails={selectedBidDetails} />
                                            </>
                                        ) : (
                                            <Box style={{ height: 400 }} />
                                        )}
                                    </Collapse>
                                </TableCell>
                            </>
                        ))
                    ) : (
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                                No Bids Yet
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
