import { gql } from '@apollo/client';
import { ORDER_FIELDS } from '@/graphql/queries/orders';
import { ITEM_FIELDS } from '@/graphql/queries/items';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';

export const UNASSIGNED_ORDERS = gql`
    ${ORDER_FIELDS}
    ${ITEM_FIELDS}
    ${CLIENT_FIELDS}
    query UnassignedOrders($user_id: uuid!, $filters: orders_bool_exp!) {
        pickups: orders(
            where: {
                _and: [
                    {
                        _or: [
                            { carrier_id: { _eq: $user_id } }
                            { _and: [{ shipper_id: { _eq: $user_id } }, { oms: { _eq: true } }] }
                        ]
                    }
                    { pickup_date: { _is_null: true } }
                    { event_state: { _in: ["CD_PENDING_PO", "ROUTED_DO"] } }
                    { order_status: { _in: ["pending", "claimed", "routed", "routedActive"] } }
                    $filters
                ]
            }
            order_by: { created_at: desc_nulls_last }
        ) {
            ...OrderFields
            itemsByOrderId {
                ...ItemFields
            }
            returns @client {
                ...ItemFields
            }
            items @client {
                ...ItemFields
            }
            order_shipper {
                ...ClientFields
            }
        }
        dropoffs: orders(
            where: {
                _and: [
                    {
                        _or: [
                            { carrier_id: { _eq: $user_id } }
                            { _and: [{ shipper_id: { _eq: $user_id } }, { oms: { _eq: true } }] }
                        ]
                    }
                    { delivery_date: { _is_null: true } }
                    {
                        event_state: {
                            _in: [
                                "START"
                                "RECEIVING"
                                "PENDING"
                                "WAREHOUSE_RETURN"
                                "CD_PENDING_PO"
                                "ROUTED_PU"
                                "CD_RECEIVED_SKIP_DO"
                            ]
                        }
                    }
                    { order_status: { _in: ["pending", "claimed", "routed", "routedActive"] } }
                    $filters
                ]
            }
            order_by: { created_at: desc_nulls_last }
        ) {
            ...OrderFields
            itemsByOrderId {
                ...ItemFields
            }
            returns @client {
                ...ItemFields
            }
            items @client {
                ...ItemFields
            }
            order_shipper {
                ...ClientFields
            }
        }
    }
`;
