import React, { useContext, useMemo } from 'react';
import { css } from '@emotion/react';
import { ModalContext } from '.';
import { useClientUser } from '@/hooks';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { Row, SecondaryButton } from '@/styles/blocks';
import { PriceInput } from '../InputFields';
import UploadFile from './UploadFile';
import { AIRLINE_OPTIONS } from '../../constants';
import AdditionalDocuments from './AdditionalDocuments';

const OrderDetailsTab = ({ opt }) => {
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { isDirty, order, hasError } = modalState;
    const { shipping_partners, user_id } = useClientUser();

    const showRevenueOption = useMemo(() => {
        if (order) {
            return order.wh_events?.some((event) => event.action.includes('ADD_CD'));
        }
        return false;
    }, [order]);

    let options;
    if (order.freight_type === 'pallet') {
        options = (
            <>
                <Row>
                    <Grid css={css`flex: 1; flex-basis; margin-right: 16px;`}>
                        <TextField
                            select
                            fullWidth
                            variant="outlined"
                            label="Airline"
                            value={order.airline}
                            onChange={(e) => callbacks.modifyOrder({ airline: e.target.value })}
                        >
                            {AIRLINE_OPTIONS.map((airline) => (
                                <MenuItem key={airline} value={airline}>
                                    {airline}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid css={css`flex: 1; flex-basis;`}></Grid>
                </Row>
            </>
        );
    }

    return (
        <Grid container direction="column">
            <Row>
                <Grid
                    item
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                        margin-right: 16px;
                    `}
                >
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="PO #"
                        value={order.po_number}
                        onBlur={() => callbacks.makeDirty(['po_number'])}
                        onChange={(e) => callbacks.modifyOrder({ po_number: e.target.value })}
                        error={hasError.po_number && (opt.startDirty || isDirty.po_number)}
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                    `}
                >
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Ref #"
                        value={order.reference_id}
                        onBlur={() => callbacks.makeDirty(['reference_id'])}
                        onChange={(e) => callbacks.modifyOrder({ reference_id: e.target.value })}
                        error={hasError.reference_id && (opt.startDirty || isDirty.reference_id)}
                    />
                </Grid>
            </Row>
            <Row>
                {order.oms ? (
                    <Grid
                        item
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            margin-right: 16px;
                        `}
                    >
                        <TextField
                            select
                            variant="outlined"
                            fullWidth
                            label="Shipper"
                            value={order.shipper_id || ''}
                            onChange={(e) => {
                                const shipper_id = e.target.value;
                                callbacks.modifyOrder({
                                    shipper_id,
                                    carrier_id: shipper_id !== user_id ? user_id : null,
                                });
                            }}
                        >
                            <MenuItem value={user_id}>None</MenuItem>
                            {shipping_partners.map((shipper) => (
                                <MenuItem key={shipper.shipper_id} value={shipper.shipper_id}>
                                    {shipper.shipper.business_name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                ) : null}
                <Grid
                    item
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                    `}
                >
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Manufacturer"
                        value={order.manufacturer}
                        onBlur={() => callbacks.makeDirty(['manufacturer'])}
                        onChange={(e) => callbacks.modifyOrder({ manufacturer: e.target.value })}
                        error={hasError.manufacturer && (opt.startDirty || isDirty.manufacturer)}
                    />
                </Grid>
            </Row>
            {order.oms ? (
                <Row>
                    <Grid
                        xs={showRevenueOption ? 3 : 5}
                        item
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                        `}
                    >
                        <PriceInput label="Order Revenue" value={order.order_revenue} disabled fullWidth />
                    </Grid>
                    <Grid
                        xs={showRevenueOption ? 3 : 5}
                        item
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            margin-left: 16px;
                        `}
                    >
                        <PriceInput
                            label="Order Revenue Override"
                            value={
                                order.order_revenue_override || order.order_revenue_override === 0
                                    ? order.order_revenue_override
                                    : ''
                            }
                            onChange={(val) => callbacks.modifyOrder({ order_revenue_override: val })}
                            fullWidth
                        />
                    </Grid>
                    <Grid
                        xs={2}
                        item
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            margin-left: 16px;
                        `}
                    >
                        <SecondaryButton
                            css={css`
                                width: 100%;
                                height: 100%;
                            `}
                            onClick={() => callbacks.modifyOrder({ order_revenue_override: null })}
                        >
                            Reset
                        </SecondaryButton>
                    </Grid>
                    {showRevenueOption && (
                        <Grid
                            xs={4}
                            item
                            direction="column"
                            css={css`
                                flex: 1;
                                flex-basis: 0;
                                margin-left: 16px;
                            `}
                        >
                            <TextField
                                variant="outlined"
                                select
                                label="Order revenue applied to"
                                name="warehouse_id"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={order.show_revenue_on}
                                onChange={(e) => callbacks.modifyOrder({ show_revenue_on: e.target.value })}
                            >
                                <MenuItem key={'DROPOFF'} value={'DROPOFF'}>
                                    Delivery
                                </MenuItem>
                                <MenuItem key={'PICKUP'} value={'PICKUP'}>
                                    Pickup
                                </MenuItem>
                            </TextField>
                        </Grid>
                    )}
                </Row>
            ) : null}

            {options}

            <Row
                css={css`
                    gap: 16px;
                `}
            >
                <UploadFile order={order} type={'waybill'} />
                <UploadFile order={order} type={'pickup_notification'} />
                <UploadFile order={order} type={'bol'} />
            </Row>

            <Row>
                <AdditionalDocuments callbacks={callbacks} />
            </Row>
        </Grid>
    );
};

export default OrderDetailsTab;
