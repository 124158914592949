import { SERVICE_LEVEL_FIELDS } from '@/graphql/fragments/service_levels';
import { TAG_MAPPING_FIELDS } from '@/graphql/fragments/tags';
import { gql } from '@apollo/client';

export const UPSERT_SERVICE_LEVEL = gql`
    ${SERVICE_LEVEL_FIELDS}
    ${TAG_MAPPING_FIELDS}

    mutation UpsertServiceLevel(
        $service_level: service_levels_insert_input!
        $tag_removals: [uuid!] = []
        $tags: [tag_mappings_insert_input!] = []
    ) {
        insert_tag_mappings(
            objects: $tags
            on_conflict: { constraint: tag_service_level_mappings, update_columns: [] }
        ) {
            affected_rows
        }
        delete_tag_mappings(where: { mapping_id: { _in: $tag_removals } }) {
            affected_rows
        }
        insert_service_levels_one(
            object: $service_level
            on_conflict: {
                constraint: service_levels_pkey
                update_columns: [service_level, location_type, instructions, onward_tags]
            }
        ) {
            ...ServiceLevelFields
            tags {
                ...TagMappingFields
            }
        }
    }
`;

export const DELETE_SERVICE_LEVEL = gql`
    mutation DeleteServiceLevel($id: uuid!) {
        delete_service_levels_by_pk(service_level_id: $id) {
            service_level_id
        }
    }
`;
