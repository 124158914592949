import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { UserContext } from '../App';

export const carrierAuthCondition = (user) => {
    if (user?.roles?.CARRIER) return true;
    if (user?.roles?.TEAMMATE && user?.teammate?.roles?.operations) return true;

    return false;
};

export const shipperAuthCondition = (user) => {
    if (user?.roles?.SHIPPER) return true;
    if (user?.roles?.TEAMMATE && (user?.teammate?.roles?.operations || user?.teammate?.roles?.viewer)) return true;

    return false;
};

const withAuthorization = (authCondition) => (Component) => (props) => {
    const navigate = useNavigate();

    const { user } = useContext(UserContext);
    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
        if (authCondition(user)) {
            setIsAuthorized(true);
        } else {
            navigate('/account');
        }
    }, [user]);

    return isAuthorized ? <Component {...props} /> : null;
};

export default withAuthorization;
