import { useClientUser } from '@/hooks';
import { PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { MenuItem, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import NavResponsiveModal, { ModalActions, ModalContent, ModalHeader } from '../Navigation/NavResponsiveModal';
import { css } from '@emotion/react';

const SendMessageModal = ({ open, callbacks }) => {
    const { customer_messages } = useClientUser();

    const [message, setMessage] = useState({});

    const onConfirm = () => {
        callbacks.sendMessage(message).then(() => {
            setMessage({});
            callbacks.onClose();
        });
    };

    return (
        <NavResponsiveModal open={open} onClose={callbacks.onClose}>
            <ModalHeader title="Custom Text Message" onClose={callbacks.onClose} />
            <ModalContent
                css={css`
                    width: 600px;
                `}
            >
                <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label="Message"
                    value={message.name || ''}
                    onChange={(e) => {
                        const name = e.target.value;
                        if (customer_messages[name]) {
                            setMessage({
                                ...customer_messages[name],
                                name,
                            });
                        } else {
                            setMessage({
                                name: '',
                                body: '',
                                schedule: false,
                            });
                        }
                    }}
                >
                    <MenuItem value="">New Message</MenuItem>
                    {Object.keys(customer_messages).map((message) => (
                        <MenuItem key={message} value={message}>
                            {message}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    fullWidth
                    multiline
                    maxRows={10}
                    variant="outlined"
                    label="Body"
                    value={message.body || ''}
                    disabled={!!message.name}
                    onChange={(e) => {
                        setMessage((prev) => ({ ...prev, body: e.target.next }));
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    css={css`
                        margin-top: 1rem;
                    `}
                />
            </ModalContent>
            <ModalActions>
                <SecondaryButton onClick={callbacks.onClose}>Cancel</SecondaryButton>
                <PrimaryButton onClick={onConfirm}>Send</PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default SendMessageModal;
