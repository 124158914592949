import React from 'react';
import { css } from '@emotion/react';
import FormControl from '@material-ui/core/FormControl';
import MuiSelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';

function Select(props) {
    const {
        label = '',
        defaultLabel = 'select',
        menuItems = [],
        onChange = () => {},
        inputStyles = {},
        containerStyles = {},
        menuItemStyles = {
            fontSize: '14px',
        },
        defaultMenuItem,
        value = 'NONE',
        disabled,
        InputLabelProps,
        ...rest
    } = props;

    return (
        <div style={{ ...containerStyles }} {...rest}>
            <FormControl
                css={css`
                    fieldset {
                        top: 0;
                    }
                `}
                style={{ ...inputStyles }}
                variant="outlined"
                size="small"
            >
                <InputLabel style={{ color: 'inherit', whiteSpace: 'nowrap' }}>
                    <Typography variant="body2">{label}</Typography>
                </InputLabel>
                <MuiSelect
                    fullWidth
                    value={value}
                    name="raw-select"
                    onChange={onChange}
                    style={{ ...menuItemStyles }}
                    inputProps={InputLabelProps}
                    label={label}
                    MenuProps={{
                        disableScrollLock: true,
                        PaperProps: {
                            style: {
                                maxHeight: '300px',
                            },
                        },
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                    }}
                    disabled={disabled}
                >
                    {defaultMenuItem ? (
                        <MenuItem style={{ ...menuItemStyles }} value={defaultMenuItem.value} key="REMOVE">
                            {' '}
                            {defaultMenuItem.label}{' '}
                        </MenuItem>
                    ) : (
                        <MenuItem style={{ ...menuItemStyles }} value="NONE" key="REMOVE">
                            {' '}
                            {defaultLabel}{' '}
                        </MenuItem>
                    )}
                    {menuItems.map((menuItem) => (
                        <MenuItem
                            style={{ ...menuItemStyles }}
                            value={menuItem.key}
                            key={menuItem.key}
                            disabled={menuItem.disabled}
                        >
                            {' '}
                            {menuItem.value}{' '}
                        </MenuItem>
                    ))}
                </MuiSelect>
            </FormControl>
        </div>
    );
}

export default Select;
