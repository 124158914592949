import { compose } from 'recompose';
import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import withFeatureFlag from '../Auth/withFeatureFlag';
import { SAAS_V1 } from '@/constants/featureFlags';
import { useMutation } from '@apollo/client';
import { UPSERT_ORDERS } from '@/components/ShipmentForm/graphql/mutations';
import * as Sentry from '@sentry/react';
import EditOrderModal from '@/components/ShipmentForm/modals/EditOrderModal';
import { EDIT_ORDER_TABS } from '@/components/ShipmentForm/constants';
import { PlanningContext, withPlanningContext } from './context';
import { Grid, Snackbar, Tab, Tabs } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PlanningHeader from './PlanningHeader';
import PlanningOrders from './orders/PlanningOrders';
import PlanningRoutes from './routes/PlanningRoutes';
import { MODALS } from './constants';
import { css } from '@emotion/react';
import { FullHeightGrid, GrowingGrid } from './blocks';
import hubspotControls from '@/utilities/hubspotControls';
import PlanningStops from './stops/PlanningStops';
import OrdersToUnassigned from './modals/OrdersToUnassigned';
import DriverOverlapModal from './modals/DriverOverlapModal';
import OrdersUnassignedToOnward from './modals/OrdersUnassignedToOnward';
import RouteRemoveModal from './modals/RouteRemoveModal';
import RouteUnlockModal from './modals/RouteUnlockModal';
import RouteRescheduleModal from './modals/RouteRescheduleModal';
import StopRemoveModal from './modals/StopRemoveModal';
import CustomTimeframeModal from './modals/CustomTimeframes';
import AssignToRoute from './map/AssignToRoute';
import PlanningMap from './map/PlanningMap';
import RouteSubmitOnwardModal from './modals/RouteSubmitOnwardModal';
import RouteSubmitInternalModal from './modals/RouteSubmitInternalModal';
import OrderViewModal from './modals/OrderViewModal';
import CommunicationModal from './modals/CommunicationModal';
import RouteExportModal from './modals/RouteExportModal';
import SendToOnwardModal from './modals/SendToOnwardModal';
import { useClientUser } from '@/hooks';
import SettingsModal from './modals/SettingsModal';
import DriverLunchModal from './modals/DriverLunchModal';
import OrderClaimTCModal from '../OrderClaimTCModal';
import NotesModal from './modals/NotesModal';
import ExceededDailyTrucksModal from './modals/ExceededDailyTrucksModal';
import { useCallbacks } from './hooks';
import ClaimLoads from '../LoadBoardLTL/ClaimLoads';
import PlaceBidModal from '../LoadBoardLTL/PlaceBidModal';
import StopWindowModal from './modals/StopWindowModal';
import OrdersReschedule from './modals/OrdersReschedule';
import OrdersAwaitingCDModal from './modals/OrdersAwaitingCDModal';

const DispatchPlan = () => {
    const { user_id, circles } = useClientUser();
    const [tabIndex, setTabIndex] = useState(0);
    const [editing, setEditing] = useState(null);

    const {
        selectedOrders,
        selectedRoute,
        setSelectedRoute,
        setSelectedLoads,
        setModalOpen,
        modalOpen,
        notification,
        setError,
        setNotification,
        actionableOrder,
        bid,
        setBid,
        state: { selectedLoadBoardLoads, routes },
    } = useContext(PlanningContext);

    const { placeBid, claimNow } = useCallbacks();

    const handleTabChange = (tab) => {
        setTabIndex(tab);
        if (selectedRoute) setSelectedRoute(null);
    };

    useEffect(() => {
        hubspotControls.hide();

        return () => {
            hubspotControls.show();
        };
    }, []);

    const [submitOrder] = useMutation(UPSERT_ORDERS, {
        onCompleted: () => {
            setModalOpen(null);
        },
        update: (cache, { data: { removed } }) => {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'orders' });
        },
        onError: (e) => {
            setError('Failed to update order. Please try again.');
            Sentry.captureException(e);
        },
    });

    const onEdit = useCallback(({ errors, order: modified, items: itemsModified, itemsRemoved, tags, tagsRemoved, documents, }) => {
        if (errors?.hasMissingField?.length) {
            setNotification({
                severity: 'error',
                message: `Order has missing fields: ${errors.hasMissingField.join(', ')}.`,
            });
        } else if (errors?.phoneInvalid) {
            setNotification({ severity: 'error', message: 'Order has an invalid phone number.' });
        } else {
            if (errors?.failedPricing?.length) {
                let message = `Failed to calculate pricing for ${modified.order_number}.`;
                if (errors.failedPricing.includes('miles')) {
                    message = `${message} Cannot find distance between pickup and dropoff locations.`;
                }
                if (errors.failedPricing.includes('delivery_type')) {
                    message = `${message} ${
                        modified.order_type === 'return' ? 'Return Pickup' : 'Delivery'
                    } type not set.`;
                }
                setNotification({ severity: 'warning', message });
            }

            const {
                warehouse_estimated_delivery_date,
                warehouse_estimated_ship_date,
                notes,
                ...rest
            } = modified;

            submitOrder({
                variables: {
                    orders: [
                        {
                            ...rest,
                            ...(warehouse_estimated_delivery_date ||
                            warehouse_estimated_ship_date
                                ? {
                                      wh_events: {
                                          data: [
                                              {
                                                  action: 'START:RECEIVING',
                                                  est_received_date: warehouse_estimated_delivery_date,
                                                  est_ship_date: warehouse_estimated_ship_date,
                                              },
                                          ],
                                      },
                                  }
                                : {}),
                        },
                    ],
                    items: itemsModified,
                    removals: itemsRemoved,
                    tags: tags,
                    tag_removals: tagsRemoved,
                    documents: documents,
                },
            });
            setEditing(null);
        }
    }, []);

    return (
        <FullHeightGrid
            container
            direction="column"
            wrap="nowrap"
            css={css`
                background-color: white;
                height: 100%;
            `}
        >
            <Grid item>
                <PlanningHeader />
            </Grid>

            <GrowingGrid container>
                {/* Tabs - Orders / Routes */}
                <FullHeightGrid
                    item
                    xs={3}
                    css={css`
                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        border: 1px solid #e2e2e2;
                        min-width: 360px;
                    `}
                >
                    <Grid container justifyContent="center">
                        <Tabs
                            value={tabIndex}
                            onChange={(event, tab) => handleTabChange(tab)}
                            textColor="primary"
                            indicatorColor="primary"
                            // centered
                        >
                            <Tab label="Routes" value={0} style={{ outline: 0 }} />
                            <Tab label="Orders" value={1} style={{ outline: 0 }} />
                        </Tabs>
                    </Grid>

                    <GrowingGrid container direction="column" wrap="nowrap">
                        {tabIndex === 0 ? <PlanningRoutes /> : <PlanningOrders />}
                    </GrowingGrid>
                </FullHeightGrid>

                {/* Stops */}
                {tabIndex === 0 && selectedRoute && (
                    <FullHeightGrid
                        item
                        xs={4}
                        css={css`
                            display: flex;
                            flex-direction: column;
                            flex-wrap: nowrap;
                            border: 1px solid #e2e2e2;
                        `}
                    >
                        <PlanningStops />
                    </FullHeightGrid>
                )}

                {/* Map */}
                <FullHeightGrid item xs={tabIndex === 0 && selectedRoute ? 5 : 9}>
                    <PlanningMap />
                    {Object.values(selectedOrders).filter((x) => !!x).length > 0 && <AssignToRoute />}
                    {selectedLoadBoardLoads.length > 0 && (
                        <ClaimLoads
                            loads={selectedLoadBoardLoads}
                            callbacks={{
                                setPlaceBidModalOpen: () => setModalOpen(MODALS.PLACE_BID),
                                setTCModalOpen: () => setModalOpen(MODALS.CLAIM_TC),
                                onClose: () => setSelectedLoads({}),
                            }}
                            css={css`
                                // profile header + planning header + padd
                                top: 140px;
                            `}
                        />
                    )}
                </FullHeightGrid>
            </GrowingGrid>

            {/* Route Modals */}
            <RouteRemoveModal />
            <RouteUnlockModal />
            <RouteRescheduleModal />
            <RouteExportModal />
            <RouteSubmitOnwardModal />
            <RouteSubmitInternalModal />

            {/* Stop Modals */}
            <StopRemoveModal />
            <CommunicationModal />
            <CustomTimeframeModal />
            {/* <NotesModal /> */}
            <StopWindowModal />

            {/* Order Modals */}
            <OrdersToUnassigned />
            <OrdersUnassignedToOnward />
            <OrderViewModal />
            <OrdersReschedule />
            <EditOrderModal
                order={actionableOrder}
                open={modalOpen === MODALS.ORDER_EDIT}
                initTab={EDIT_ORDER_TABS.PICKUP}
                callbacks={{
                    onSave: onEdit,
                    onClose: () => {
                        setModalOpen(null);
                    },
                    onError: (error) => {
                        setError(error);
                    },
                }}
                opt={{
                    disableAddressEditing: actionableOrder?.routes?.length > 0,
                    readOnly: !actionableOrder?.oms && !!actionableOrder?.carrier_invoice_id,
                }}
            />

            {/* Load Board Modals */}
            <SendToOnwardModal />
            <PlaceBidModal
                load={selectedLoadBoardLoads?.[0]}
                open={modalOpen === MODALS.PLACE_BID}
                onClose={() => {
                    setModalOpen(null);
                    setBid(null);
                }}
                callbacks={{
                    placeBid: (bid) => {
                        setBid(bid);
                        setModalOpen(MODALS.CLAIM_TC);
                    },
                }}
            />
            <OrderClaimTCModal
                loads={selectedLoadBoardLoads}
                open={modalOpen === MODALS.CLAIM_TC}
                bid={bid}
                onClose={() => {
                    setModalOpen(null);
                    setBid(null);
                }}
                callbacks={{
                    claimNow: (loads, deliveryDate, targetDeliveryDate, locationId) => {
                        claimNow({
                            carrier_id: user_id,
                            order_ids: loads.map((load) => load.order_id),
                            delivery_date: deliveryDate,
                            target_delivery_date: targetDeliveryDate,
                            planning: true,
                            locationId: locationId,
                        });
                    },
                    placeBid: (bid, locationId) => {
                        placeBid({
                            carrier_id: user_id,
                            bid,
                            locationId: locationId,
                        });
                    },
                }}
            />

            {/* Misc Modals */}
            <SettingsModal />
            <DriverOverlapModal />
            <DriverLunchModal />
            <ExceededDailyTrucksModal />
            <OrdersAwaitingCDModal
                open={modalOpen === MODALS.AWAITING_CD}
                onClose={() => {
                    setModalOpen(null);
                }}
                route={routes.find((route) => route.route_id === selectedRoute)}
            />

            {/* Notification */}
            <Snackbar
                open={!!notification?.message}
                onClose={() => setNotification(null)}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                autoHideDuration={10000}
            >
                <Alert
                    onClose={() => setNotification(null)}
                    severity={notification?.severity}
                    elevation={6}
                    variant="filled"
                >
                    {notification?.message}
                    {notification?.clickable && notification.clickable}
                </Alert>
            </Snackbar>
        </FullHeightGrid>
    );
};

export default compose(withFeatureFlag(SAAS_V1), withPlanningContext)(DispatchPlan);
