import React, { useContext } from 'react';
// import { css } from '@emotion/react';
import { ModalContext } from '..';
// import { useClientUser } from '@/hooks';
import { Grid } from '@material-ui/core';
import Customer from './Customer';
import DeliveryDate from './DeliveryDate';
import DeliveryType from './DeliveryType';
import Address from './Address';

const CustomerTab = ({ opt }) => {
    // const { disableAddressEditing, disableGeocoding, startDirty } = opt;
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { isDirty, order, hasError } = modalState;
    // const { circles, shipping_partners, user_id } = useClientUser();

    const isWillCall = order.job_type === 'WILL_CALL';

    return (
        <Grid container direction="column">
            <Customer isDirty={isDirty} order={order} hasError={hasError} callbacks={callbacks} opt={opt} />
            {!isWillCall && (
                <>
                    <hr />
                    <DeliveryDate isDirty={isDirty} order={order} hasError={hasError} callbacks={callbacks} opt={opt} />
                    <hr />
                    <Address isDirty={isDirty} order={order} hasError={hasError} callbacks={callbacks} opt={opt} />
                    <hr />
                    <DeliveryType isDirty={isDirty} order={order} hasError={hasError} callbacks={callbacks} opt={opt} />
                </>
            )}
        </Grid>
    );
};

export default CustomerTab;
