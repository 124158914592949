import { gql } from '@apollo/client';
import { ONLY_ITEM_FIELDS } from '@/graphql/queries/items';
import { MANIFEST_FIELDS } from '@/graphql/queries/manifests';
import { ROUTE_MANIFEST_FIELDS } from '@/graphql/queries/routes';
import { LOCATION_FIELDS } from '@/graphql/queries/locations';
import { PALLET_ITEM_FIELDS } from '@/graphql/queries/pallet_items';
import { EXCEPTION_FIELDS } from '@/graphql/queries/db_exceptions';

export const GET_ORDERS_INVENTORY = gql`
    ${ONLY_ITEM_FIELDS}
    ${MANIFEST_FIELDS}
    ${ROUTE_MANIFEST_FIELDS}
    ${LOCATION_FIELDS}
    ${PALLET_ITEM_FIELDS}
    ${EXCEPTION_FIELDS}

    query GetInventoryOrders($where: orders_bool_exp!, $itemsWhere: items_bool_exp) {
        orders(where: $where, order_by: { created_at: desc }, limit: 30) {
            order_id
            order_number
            order_status
            created_at
            po_number
            dropoff_name
            dropoff_phone
            oms
            order_shipper {
                client_id
                business_name
            }
            itemsByOrderId(where: $itemsWhere) {
                ...OnlyItemFields
                pallet {
                    ...PalletItemFields
                    logs(order_by: { created_at: desc }) {
                        log_id
                        pallet_id
                        created_at
                        warehouse_location
                        warehouse_status
                    }
                    warehouse {
                        ...LocationFields
                    }
                }
                exceptions {
                    created_at
                    exception_type
                    event_id
                    exception {
                        ...ExceptionFields
                    }
                }
                item_exception @client {
                    ...ExceptionFields
                }
                manifests {
                    mapping_id
                    manifest_id
                    item_id
                    manifest {
                        ...ManifestFields
                        route {
                            ...RouteManifestFields
                        }
                    }
                }
            }
        }
    }
`;

export const GET_ALL_ORDER_SHIPPERS = gql`
    query GetAllOrderShippers($user_id: uuid!) {
        orders(
            where: {
                _and: [
                    { _or: [{ carrier_id: { _eq: $user_id } }, { shipper_id: { _eq: $user_id } }] }
                    {
                        order_status: {
                            _in: [
                                "pending"
                                "active"
                                "routed"
                                "claimed"
                                "inProgress"
                                "routedInProgress"
                                "complete"
                                "routedComplete"
                            ]
                        }
                    }
                    { wh_events: { action: { _ilike: "START:RECEIVING" } } }
                ]
            }
            distinct_on: [shipper_id]
        ) {
            order_id
            order_number
            shipper_id
            order_shipper {
                client_id
                business_name
            }
        }
    }
`;
