import { EXCEPTION_TYPES } from '@onward-delivery/core';

export const MODALS = {
    CREDIT_CARD: 'CREDIT_CARD',
    EDIT_ORDER: 'EDIT_ORDER',
    AUCTION_DETAILS: 'AUCTION_DETAILS',
    MARKETPLACE_TOC: 'MARKETPLACE_TOC',
    BROKER_DISCLAIMER: 'BROKER_DISCLAIMER',
};

export const EXCEPTION_REASONS = {
    [EXCEPTION_TYPES.DAMAGED]: [
        'Manufacturing damage',
        'Delivering damaged product that was notated at the time of pickup',
        "Concealed damage not seen until opened at the customer's house",
        'Damaged during travel',
        'Other',
    ],
    [EXCEPTION_TYPES.REFUSAL]: ['Damaged', 'Wrong color', 'Other',],
    [EXCEPTION_TYPES.SHORTAGE]: ['Warehouse damage', 'Not staged', 'Back ordered', 'Other',],
    [EXCEPTION_TYPES.ATTEMPTED_DELIVERY]: ['Wrong customer address', 'Customer not present to receive delivery', 'Other',],
};
