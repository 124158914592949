import React, { useEffect, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';
import helpers from '../../utilities/FTLStopHelpers';
import StopCard from './StopCard';
import LunchStopCard from './LunchStopCard';
import CarrierLocationCard from './CarrierLocationCard';
import { isOrderCancelled } from './isOrderCancelled';

const ScrollContainer = styled((props) => <Grid {...props} />)`
    flex-grow: 1;
    overflow-y: hidden;
`;

const Scroller = styled((props) => <Grid {...props} />)`
    height: 100%;
    padding: 8px 20px 8px 16px;
    overflow-y: scroll;
    align-items: flex-start;
    flex-wrap: nowrap;
`;

const RouteStops = ({ stops, orders, route, callbacks, exceptionStops, cancelledStops, stopOrderMappings }) => {
    const [sequence, setSequence] = useState([]);
    const [start, setStart] = useState(undefined);
    const [end, setEnd] = useState(undefined);

    useEffect(() => {
        getSequence();
    }, [stops]);

    const rescheduledStops = useMemo(() => {
        return stopOrderMappings.map(({ stop, orders }) => {
            const rescheduled = orders.some((order) => {
                return order.exceptions.some(
                    (exception) =>
                        exception.exception.resolution === 'RESCHEDULE' &&
                        exception.exception.route_id === route.route_id
                );
            });
            return {
                stop,
                rescheduled,
            };
        });
    }, [stopOrderMappings, route.route_id]);

    const getSequence = async () => {
        let mostRecentNonCancelledStop = null;
        if (stops) {
            const sequence = helpers.getStopSequence(stops);
            if (sequence[0].start) {
                setStart(sequence.shift());
            }
            if (sequence.length > 1 && sequence[sequence.length - 1].end) {
                setEnd(sequence.pop());
            }
            if (sequence.length > 1) {
                for (let i = 0; i < sequence.length; i++) {
                    const _stop = sequence[i];
                    const _orders = orders.filter((_o) => (_stop.orders || []).includes(_o.order_id));
                    const _allCancelled = _orders.every((_o) => isOrderCancelled(_o));
                    const needsReschedule = _orders.every((o) => o.exceptions?.length > 0);

                    if (
                        !mostRecentNonCancelledStop &&
                        !_stop.stop_completion_time &&
                        !_allCancelled &&
                        !needsReschedule &&
                        !_stop.type === 'LUNCH'
                    ) {
                        sequence[i].nextStop = true;
                        break;
                    } else if (
                        mostRecentNonCancelledStop &&
                        mostRecentNonCancelledStop.stop_completion_time &&
                        !_stop.stop_completion_time &&
                        !_allCancelled &&
                        !needsReschedule &&
                        !_stop.type === 'LUNCH'
                    ) {
                        sequence[i].nextStop = true;
                        break;
                    }

                    if (!_allCancelled || !needsReschedule) {
                        mostRecentNonCancelledStop = _stop;
                    }
                }
            }
            setSequence(sequence);
        }
    };

    return (
        <ScrollContainer container>
            <Scroller container direction="column">
                {start && (
                    <CarrierLocationCard
                        cardText="Carrier start location"
                        address={start.address}
                        iconColor="#59B863"
                    />
                )}
                {sequence.map((stop, i) =>
                    stop.type === 'LUNCH' ? (
                        <LunchStopCard
                            key={`${route.route_number}-${stop.ordering}`}
                            stopNumber={start ? stop.ordering : stop.ordering + 1}
                            route={route}
                            stop={stop}
                            orders={
                                orders ? orders.filter((order) => (stop.orders || []).includes(order.order_id)) : []
                            }
                        />
                    ) : (
                        <StopCard
                            key={`${route.route_number}-${stop.ordering}`}
                            stopNumber={start ? stop.ordering : stop.ordering + 1}
                            route={route}
                            stop={stop}
                            orders={
                                orders ? orders.filter((order) => (stop.orders || []).includes(order.order_id)) : []
                            }
                            callbacks={callbacks}
                            rescheduledStops={rescheduledStops}
                            exceptionStops={exceptionStops}
                            cancelledStops={cancelledStops}
                            stopOrderMappings={stopOrderMappings}
                        />
                    )
                )}
                {end && (
                    <CarrierLocationCard cardText="Carrier end location" address={end.address} iconColor="#D23E3E" />
                )}
            </Scroller>
        </ScrollContainer>
    );
};

export default RouteStops;
