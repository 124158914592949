import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Anchor, Body1, Header1, Step, SubStepDesktop, SubStepMobile, Tag } from './blocks';
import { css } from '@emotion/react';
import { formatInTimeZone } from 'date-fns-tz';

const defaultEvent = ({ order, before, after }) => {
    return (
        after || {
            order_id: order.order_id,
            action: `${before?.transition?.next || 'START'}:PICKING_UP`,
        }
    );
};

export default function Scheduled({
    order,
    isDesktop,
    isReceived,
    isCrossdocked,
    isLoading,
    warehouse,
    before,
    after,
    callbacks,
    tz,
    readOnly,
}) {
    const STEPS = [
        {
            description:
                order.order_type === 'return'
                    ? `Schedule return pickup and ${isCrossdocked ? 'cross-dock' : 'delivery'} dates`
                    : `Schedule ${isReceived ? 'delivery date' : 'load and delivery dates'}`,
            complete: ({ order, before, after }) => {
                return !!after?.est_pickup_date && !!after?.est_delivery_date;
            },
            ...(!readOnly ? {
                cta: isReceived ? 'Schedule Date' : 'Schedule Dates',
                callback: ({ order, before, after }) => {
                    callbacks.editScheduled({
                        event: defaultEvent({ order, before, after }),
                        isReceived,
                        isCrossdocked,
                    });
                },
            } : {})
        },
    ];

    const isComplete = useMemo(() => {
        return STEPS.map((step) => step.complete({ order, before, after }));
    });

    const inProgressIdx = useMemo(() => {
        return isComplete.findIndex((x) => !x);
    });

    return (
        <Step
            isDesktop={isDesktop}
            isComplete={isComplete.every((x) => x)}
            inProgress={!isComplete.every((x) => x)}
            label={() => {
                return (
                    <Grid
                        direction="row"
                        container
                        css={css`
                            align-items: center;
                        `}
                    >
                        <Grid
                            direction="column"
                            css={css`
                                margin-right: 8px;
                            `}
                        >
                            <Header1>Scheduled Date{isReceived ? '' : 's'}</Header1>
                        </Grid>
                        {(after?.est_pickup_date || after?.est_delivery_date) && (
                            <Grid
                                direction="column"
                                css={css`
                                    margin-right: 8px;
                                `}
                            >
                                <Tag>
                                    <Body1
                                        css={css`
                                            margin-right: 20px;
                                        `}
                                    >
                                        {`${
                                            isReceived
                                                ? ''
                                                : `Pickup: ${
                                                      after.est_pickup_date
                                                          ? formatInTimeZone(
                                                                after.est_pickup_date,
                                                                tz,
                                                                'EEE, MMM dd, yyyy'
                                                            )
                                                          : 'Pending'
                                                  }`
                                        }${isCrossdocked ? 'Cross-dock' : 'Delivery'}: ${
                                            after.est_delivery_date
                                                ? formatInTimeZone(after.est_delivery_date, tz, 'EEE, MMM dd, yyyy')
                                                : 'Pending'
                                        }`}
                                    </Body1>
                                    {!readOnly && (
                                        <Anchor
                                            onClick={() =>
                                                callbacks.editScheduled({
                                                    event: defaultEvent({ order, before, after }),
                                                    isReceived,
                                                    isCrossdocked,
                                                })
                                            }
                                        >
                                            <Header1>Edit</Header1>
                                        </Anchor>
                                    )}
                                </Tag>
                            </Grid>
                        )}
                    </Grid>
                );
            }}
        >
            {() => (
                <>
                    {STEPS.map(({ description, cta, callback }, idx) => {
                        const SubStep = isDesktop ? SubStepDesktop : SubStepMobile;
                        return (
                            <SubStep
                                isComplete={isComplete[idx]}
                                inProgress={inProgressIdx === idx}
                                isLoading={isLoading}
                                isLast={idx === STEPS.length - 1}
                                description={description}
                                cta={cta}
                                secondaryCta={null}
                                callback={callback ? () => callback({ order, before, after }) : null}
                                secondaryCallback={null}
                            />
                        );
                    })}
                </>
            )}
        </Step>
    );
}
