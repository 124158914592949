import { gql } from '@apollo/client';
import { CSV_EXPORT_TEMPLATE_FIELDS } from '../fragments/csv_export_templates';

export const QUERY_EXPORT_TEMPLATES_BY_USER = gql`
    ${CSV_EXPORT_TEMPLATE_FIELDS}

    query ExportTemplatesByUser($where: csv_export_templates_bool_exp = {}) {
        csv_export_templates(where: $where) {
            ...CsvExportTemplateFields
        }
    }
`;
