import NavResponsiveModal, {
    ModalActions,
    ModalContent,
    ModalHeader,
} from '@/components/Navigation/NavResponsiveModal';
import { PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { Grid, Popper, TextField, Tooltip } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { OnwardCheckbox } from '@/components/ShipmentForm/blocks';
import { Info } from '@material-ui/icons';
import { useLazyQuery } from '@apollo/client';
import { GET_RACKS } from '../graphql';
import { Autocomplete } from '@material-ui/lab';

const StorePallets = ({ pallets, loading, callbacks, warehouseId }) => {
    const [locations, setLocations] = useState({});
    const [dirty, setDirty] = useState({});
    const [isPool, setIsPool] = useState({});
    const [binOptions, setBinOptions] = useState({});

    // const isValid = useMemo(() => {
    //     return Object.fromEntries(
    //         Object.entries(locations).map(([pallet_id, location]) => [
    //             pallet_id,
    //             /^.*-[1-9][0-9]*-[1-9][0-9]*(-[1-9])?$/.test(location),
    //         ])
    //     );
    // }, [locations]);

    const [getBinOptions] = useLazyQuery(GET_RACKS, {
        variables: {
            location_id: warehouseId,
        },
        onCompleted: ({ location }) => {
            let _poolLocations = [];
            let regularBins = [];

            location.warehouse_racks.forEach((rack) => {
                const binLabel = rack?.customName || `${rack?.isPool ? 'Pool ' : ''}${rack.rackIdentifier}`;
                if (rack?.isPool) {
                    _poolLocations.push({
                        label: binLabel,
                        isPool: true,
                    });
                } else {
                    for (let r = 1; r <= rack.rows; r++) {
                        for (let c = 1; c <= rack.columns; c++) {
                            for (let d = 1; d <= rack.rackDepth; d++) {
                                const labelId = `${binLabel}-${r}-${c}-${d}`;
                                regularBins.push({
                                    label: labelId,
                                    isPool: false,
                                });
                            }
                        }
                    }
                }
            });
            setBinOptions({
                poolLocations: _poolLocations,
                bins: regularBins,
            });
        },
        onError: (error) => {
            console.error('Error fetching bin options:', error);
        },
    });

    useEffect(() => {
        setLocations(
            Object.fromEntries((pallets || []).map((pallet) => [pallet.pallet_id, pallet.warehouse_location || '']))
        );
        setIsPool(Object.fromEntries((pallets || []).map((pallet) => [pallet.pallet_id, pallet.is_pool || false])));

        if (warehouseId) {
            getBinOptions();
        }
    }, [pallets, warehouseId]);

    const onClose = () => {
        setLocations({});
        setDirty({});
        setIsPool({});
        callbacks.storePalletsInit(null);
    };

    const submit = () => {
        callbacks
            .storePallets(
                pallets
                    .filter(
                        (pallet) =>
                            locations[pallet.pallet_id] !== pallet.warehouse_location ||
                            isPool[pallet.pallet_id] !== pallet.is_pool
                    )
                    .map((pallet) => ({
                        ...pallet,
                        warehouse_location: locations[pallet.pallet_id],
                        is_pool: isPool[pallet.pallet_id],
                    }))
            )
            .then(() => {
                onClose();
            });
    };

    return (
        <NavResponsiveModal open={!!pallets?.length} onClose={onClose}>
            <ModalHeader title={'Store'} />
            <ModalContent>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex-wrap: nowrap;
                        height: 160px;
                    `}
                >
                    {(pallets || []).map((pallet, idx) => (
                        <React.Fragment key={pallet.pallet_id}>
                            <Autocomplete
                                freeSolo={true}
                                getOptionLabel={(option) => option?.label?.toString()}
                                options={
                                    isPool[pallet.pallet_id] ? binOptions?.poolLocations || [] : binOptions?.bins || []
                                }
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        setLocations((prev) => ({ ...prev, [pallet.pallet_id]: newValue?.label }));
                                    }
                                }}
                                onInputChange={(event, newInputValue) => {
                                    setLocations((prev) => ({ ...prev, [pallet.pallet_id]: newInputValue }));
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        value={locations[pallet.pallet_id] || ''}
                                        label="New Location"
                                    />
                                )}
                                PopperComponent={({ children, ...popperProps }) => (
                                    <Popper {...popperProps} style={{ width: '300px' }}>
                                        {children}
                                    </Popper>
                                )}
                                ListboxProps={{ style: { maxHeight: '150px' } }}
                                style={{ width: '300px' }}
                            />
                            <Grid container direction="row" alignItems="center">
                                <OnwardCheckbox
                                    label={'Floor/Pool Location'}
                                    checked={isPool[pallet.pallet_id] || false}
                                    onChange={(e) =>
                                        setIsPool((prev) => ({ ...prev, [pallet.pallet_id]: e.target.checked }))
                                    }
                                />
                                <Tooltip
                                    style={{ color: '#59B863', marginLeft: '5px' }}
                                    title={
                                        'Check this box to select/show general "floor" locations in the warehouse. This will allow you to use the same storage location name for multiple pallets.'
                                    }
                                    placement="right"
                                >
                                    <Info />
                                </Tooltip>
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
            </ModalContent>
            <ModalActions>
                <SecondaryButton disabled={loading.palletLoading} onClick={onClose}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton
                    // disabled={loading.palletLoading || Object.values(isValid).some((x) => !x)}
                    disabled={loading.palletLoading}
                    onClick={submit}
                >
                    Done
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default StorePallets;
