import React, { useContext } from 'react';
import { compose } from 'recompose';

import { css } from '@emotion/react';
import withAdminRights from '@/components/Auth/withAdminRights';
import { OnwardTab, OnwardTabContainer } from '@/components/Tabs';

import Footer from './Footer';
import { Context, ContextProvider } from './store.js';
import { FILTERS, FILTER_DISPLAY_MAPPINGS } from './constants.js';

import { H1 } from '../../Accounting/Invoice/blocks';
import {
    TextField,
    Grid,
    MenuItem,
    Checkbox,
    ListItemText,
} from '@material-ui/core';
import Table from './Table';
import { format } from 'date-fns';
import { asBrowserDate } from '@/utilities/convertToISO';
import AdminNoteDetails from './AdminNoteDetails';

const StatusFilter = ({ state, loading, callbacks }) => {
    return (
        <Grid
            direction="row"
            container
            css={css`
                display: flex;
                background-color: white;
            `}
        >
            <Grid
                direction="column"
                container
                css={css`
                    flex: 1;
                    flex-basis: 0;
                    align-content: center;
                `}
            >
                <OnwardTabContainer
                    value={state.filter.status}
                    onChange={(e, t) => {
                        callbacks.setFilter((prev) => {
                            return {
                                ...prev,
                                status: t,
                            };
                        });
                    }}
                    textColor="primary"
                    indicatorColor="primary"
                    centered
                >
                    {Object.keys(FILTERS).map((key) => {
                        return <OnwardTab label={FILTER_DISPLAY_MAPPINGS[key]} value={key} key={key} />;
                    })}
                </OnwardTabContainer>
            </Grid>
        </Grid>
    );
};

const AdminNotes = ({ context }) => {
    const { state, loading, callbacks } = useContext(context);


    if (state.noteDetails) {
        return (
            <AdminNoteDetails state={state} loading={loading} callbacks={callbacks} />
        );
    }


    return (
        <>
            <Grid
                direction="column"
                container
                css={css`
                    width: 100%;
                    height: 100%;
                `}
            >
                <StatusFilter state={state} loading={loading} callbacks={callbacks} />

                <Grid
                    container
                    item
                    direction="row"
                    css={css`
                        padding: 40px;
                        padding-bottom: 0;
                        margin-bottom: 12px;
                    `}
                >
                    <H1>Notes</H1>
                </Grid>

                <Grid
                    container
                    item
                    direction="row"
                    css={css`
                        flex-wrap: nowrap;
                        padding: 0 40px;
                        padding-bottom: 0;
                        margin-bottom: 16px;
                    `}
                >
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            margin-right: 16px;
                        `}
                    >
                        <TextField
                            type="date"
                            variant="outlined"
                            size="medium"
                            InputLabelProps={{ shrink: true }}
                            label="Start Date"
                            value={state?.filter?.start ? format(state?.filter?.start, 'yyyy-MM-dd') : ''}
                            onChange={(e) =>
                                callbacks.setFilter((prev) => {
                                    return {
                                        ...prev,
                                        start: asBrowserDate(e.target.value),
                                    };
                                })
                            }
                            css={css`
                                background-color: white;
                                width: 100%;
                                padding-right: 2px;
                                margin-top: 0.2rem;
                            `}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            margin-right: 16px;
                        `}
                    >
                        <TextField
                            type="date"
                            variant="outlined"
                            size="medium"
                            InputLabelProps={{ shrink: true }}
                            label="End Date"
                            value={state?.filter?.end ? format(state?.filter?.end, 'yyyy-MM-dd') : ''}
                            onChange={(e) =>
                                callbacks.setFilter((prev) => {
                                    return {
                                        ...prev,
                                        end: asBrowserDate(e.target.value),
                                    };
                                })
                            }
                            css={css`
                                background-color: white;
                                width: 100%;
                                padding-right: 2px;
                                margin-top: 0.2rem;
                            `}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            overflow: hidden;
                            margin-right: 16px;
                        `}
                    >
                        {state.shippers ? (
                            <TextField
                                variant="outlined"
                                select
                                fullWidth
                                label="Shipper"
                                value={state?.filter?.shippers || []}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    callbacks.setFilter((prev) => {
                                        return {
                                            ...prev,
                                            shippers: e.target.value,
                                        };
                                    });
                                }}
                                SelectProps={{
                                    multiple: true,
                                    renderValue: (selected) => {
                                        const map = Object.fromEntries(
                                            state.shippers.map(({ label, value }) => {
                                                return [value, label];
                                            })
                                        );
                                        return selected.map((opt) => map[opt]).join(', ');
                                    },
                                }}
                                css={css`
                                    background-color: white;
                                    width: 100%;
                                    padding-right: 2px;
                                    margin-top: 0.2rem;
                                `}
                            >
                                {state.shippers.map(({ label, value }) => (
                                    <MenuItem key={value} value={value}>
                                        <Checkbox
                                            color="primary"
                                            checked={(state?.filter?.shippers || []).indexOf(value) > -1}
                                        />
                                        <ListItemText primary={label} />
                                    </MenuItem>
                                ))}
                            </TextField>
                        ) : null}
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            overflow: hidden;
                        `}
                    >
                        {state.carriers ? (
                            <TextField
                                variant="outlined"
                                select
                                fullWidth
                                label="Carrier"
                                value={state?.filter?.carriers || []}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    callbacks.setFilter((prev) => {
                                        return {
                                            ...prev,
                                            carriers: e.target.value,
                                        };
                                    });
                                }}
                                SelectProps={{
                                    multiple: true,
                                    renderValue: (selected) => {
                                        const map = Object.fromEntries(
                                            state.carriers.map(({ label, value }) => {
                                                return [value, label];
                                            })
                                        );
                                        return selected.map((opt) => map[opt]).join(', ');
                                    },
                                }}
                                css={css`
                                    background-color: white;
                                    width: 100%;
                                    padding-right: 2px;
                                    margin-top: 0.2rem;
                                `}
                            >
                                {state.carriers.map(({ label, value }) => (
                                    <MenuItem key={value} value={value}>
                                        <Checkbox
                                            color="primary"
                                            checked={(state?.filter?.carriers || []).indexOf(value) > -1}
                                        />
                                        <ListItemText primary={label} />
                                    </MenuItem>
                                ))}
                            </TextField>
                        ) : null}
                    </Grid>
                    {/* Leaving search field out for now as it seems redundant */}
                    {/* <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                            overflow: hidden;
                        `}
                    >
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Search"
                            placeholder="Search invoices..."
                            value={state?.filter?.searchTerm || ''}
                            onChange={(e) => {
                                callbacks.setFilter((prev) => {
                                    return {
                                        ...prev,
                                        searchTerm: e.target.value,
                                    };
                                });
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            css={css`
                                background-color: white;
                                width: 100%;
                                margin-top: 0.2rem;
                            `}
                        />
                    </Grid> */}
                </Grid>

                <Grid
                    item
                    css={css`
                        overflow: hidden;
                        flex-grow: 1;
                        padding: 0 40px;
                    `}
                >
                    <Table state={state} loading={loading} callbacks={callbacks} />
                </Grid>

                <Footer state={state} loading={loading} callbacks={callbacks} />
            </Grid>
        </>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} context={Context} />
        </ContextProvider>
    );

export default compose(withAdminRights, withContext)(AdminNotes);
