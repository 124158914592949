import { gql } from '@apollo/client';
import { ORDER_FIELDS } from '@/graphql/queries/orders';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';
import { USER_FIELDS } from '@/graphql/queries/users';
import { RESOURCE_OVERRIDE_MAPPING_FIELDS } from '@/graphql/queries/resource_override_mappings';
import { RESOURCE_RULE_MAPPING_FIELDS } from '@/graphql/queries/resource_rule_mappings';
import { RESOURCE_FIELDS } from '@/graphql/queries/resources';
import { SUBREGION_FIELDS } from '@/graphql/queries/subregions';
import { RESOURCE_REGION_MAPPING_FIELDS } from '@/graphql/queries/resource_region_mappings';
import { RESOURCE_SHIPPING_PARTNER_MAPPING_FIELDS } from '@/graphql/fragments/resource_shipping_partner_mappings';
import { SURVEY_QUESTION_FIELDS, SURVEY_RESPONSE_FIELDS } from '@/graphql/fragments/surveys';

export const QUERY_BY_ID = gql`
    ${ORDER_FIELDS}
    ${CLIENT_FIELDS}
    ${USER_FIELDS}
    ${RESOURCE_FIELDS}
    ${RESOURCE_OVERRIDE_MAPPING_FIELDS}
    ${RESOURCE_REGION_MAPPING_FIELDS}
    ${RESOURCE_RULE_MAPPING_FIELDS}
    ${RESOURCE_SHIPPING_PARTNER_MAPPING_FIELDS}
    ${SUBREGION_FIELDS}
    ${SURVEY_QUESTION_FIELDS}
    ${SURVEY_RESPONSE_FIELDS}

    query OrdersByOrderID($order_id: uuid!, $cutoff: timestamptz) {
        default_survey: survey_questions(
            where: { client_id: { _is_null: true }, survey_type: { _eq: "PREDELIVERY_DEFAULTS" } }
        ) {
            ...SurveyQuestionFields
        }
        order: orders_by_pk(order_id: $order_id) {
            ...OrderFields
            survey_responses(
                where: { survey_question: { survey_type: { _in: ["PREDELIVERY", "PREDELIVERY_DEFAULTS"] } } }
            ) {
                ...SurveyResponseFields
            }
            order_shipper {
                ...ClientFields
                user {
                    ...UserFields
                }
                survey_questions(where: { survey_type: { _eq: "PREDELIVERY" } }) {
                    ...SurveyQuestionFields
                }
                available_resources {
                    ...ResourceFields
                    zips @client

                    rules(where: { _or: [{ rule_end: { _gte: $cutoff } }, { rule_end: { _is_null: true } }] }) {
                        ...ResourceRuleMappingFields
                    }

                    overrides(
                        where: { _or: [{ override_end: { _gte: $cutoff } }, { override_end: { _is_null: true } }] }
                    ) {
                        ...ResourceOverrideMappingFields
                    }

                    subregions {
                        ...ResourceRegionMappingFields

                        subregion {
                            ...SubregionFields

                            zips {
                                zip
                            }
                        }
                    }

                    shipping_partners(where: { enabled: { _eq: true } }) {
                        ...ResourceShippingPartnerMappingFields
                    }
                }
            }

            order_carrier {
                ...ClientFields
                user {
                    ...UserFields
                }
                survey_questions(where: { survey_type: { _eq: "PREDELIVERY" } }) {
                    ...SurveyQuestionFields
                }
                available_resources {
                    ...ResourceFields
                    zips @client

                    rules(where: { _or: [{ rule_end: { _gte: $cutoff } }, { rule_end: { _is_null: true } }] }) {
                        ...ResourceRuleMappingFields
                    }

                    overrides(
                        where: { _or: [{ override_end: { _gte: $cutoff } }, { override_end: { _is_null: true } }] }
                    ) {
                        ...ResourceOverrideMappingFields
                    }

                    subregions {
                        ...ResourceRegionMappingFields

                        subregion {
                            ...SubregionFields

                            zips {
                                zip
                            }
                        }
                    }

                    shipping_partners(where: { enabled: { _eq: true } }) {
                        ...ResourceShippingPartnerMappingFields
                    }
                }
            }
        }
    }
`;
