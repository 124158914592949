import { DRIVER_PAYMENTS } from './featureFlags';

export const IMPORT_SETTINGS = [
    {
        key: 'requireShippingPartner',
        label: 'Require Shipping Partner',
        type: 'boolean',
        default: false,
    },
    {
        key: 'defaultFreightType',
        label: 'Default Freight Type',
        type: 'string',
        default: 'household',
        options: [
            { value: 'household', label: 'Household' },
            { value: 'pallet', label: 'Pallet' },
            { value: 'container', label: 'Container' },
        ],
    },
];

export const DRIVER_SETTINGS = [
    {
        key: 'requiredDeliveryPhotos',
        label: '# Required Delivery Photos',
        type: 'number',
        default: 0,
    },
    {
        key: 'requiredDeliveryPhotosPerItem',
        label: 'Require Photos per Item',
        type: 'boolean',
        default: false,
    },
    {
        key: 'payPeriodTZ',
        label: 'Pay Period Timezone',
        type: 'string',
        default: 'America/New_York',
        options: [
            { value: 'America/Los_Angeles', label: 'America/Los_Angeles' },
            { value: 'America/Denver', label: 'America/Denver' },
            { value: 'America/Chicago', label: 'America/Chicago' },
            { value: 'America/New_York', label: 'America/New_York' },
        ],
        flag: DRIVER_PAYMENTS,
    },
    {
        key: 'payPeriodStartDOW',
        label: 'Driver Pay Period',
        type: 'number',
        default: 1,
        options: [
            { value: 0, label: 'Sunday - Saturday' },
            { value: 1, label: 'Monday - Sunday' },
            { value: 2, label: 'Tuesday - Monday' },
            { value: 3, label: 'Wednesday - Tuesday' },
            { value: 4, label: 'Thursday - Wednesday' },
            { value: 5, label: 'Friday - Thursday' },
            { value: 6, label: 'Saturday - Friday' },
        ],
        flag: DRIVER_PAYMENTS,
    },
];
