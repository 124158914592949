/**
 * Boilerplate test routes
 *  These are for testing / experimenting / implementing the new graphql systems
 *  We'll remove these before production-ization.
 */
export const ORDERS_DEMO = '/demo/orders';
export const ROUTES_DEMO = '/demo/routes';
export const ITEMS_DEMO = '/demo/items';
export const SHIPPERS_DEMO = '/demo/shippers';
export const CARRIERS_DEMO = '/demo/carriers';
export const USERS_DEMO = '/demo/users';
/**
 * General Routes
 * This includes routes accessible by any role, or routes relating to signing up or signing in
 */

export const LANDING = '/';
export const SIGNIN = '/signin';
export const ACCOUNTING_V2_INVOICE = '/:perspective/accounting/marketplace/invoice/:invoice_id';
export const ACCOUNT = '/account';
export const ACCOUNT_TARIFFS = '/account/tariff/:tariff_id';
export const RESOURCES = '/resources';
export const NOTIFICATIONS = '/resources/notifications';
export const SHIPPERS = '/shippers';
export const CARRIERS = '/carriers';
export const SUBREGION = '/subregions';
export const WAREHOUSES = '/warehouses';
export const MANIFESTS = '/manifests';
export const INVENTORY = '/inventory';
export const MANIFEST = '/manifests/:manifest_id';
export const ABOUT = '/aboutus';
export const CONTACT = '/contact';
export const AUTH_CONTACT = '/contactus';
export const CANCEL = '/cancellationpolicy';
export const PRIVACY = '/privacy';
export const BLOG = '/blog';
export const TOS = '/termsofservice';
export const RATE = '/rate';
export const PASSWORD_FORGET = '/pw-forget';
export const SHIPPER_FILTER = '/getstarted';
export const ORDER_DETAILS_PAGE = '/order/:order_id';
export const ORDER_DETAILS_PAGE_DRIVER = '/order/:order_id/driver';
export const LISTING_DETAILS_PAGE = '/listing/:listing_id';

export const IMPORT_ORDERS = '/import';
export const JOB_DETAILS = '/job/:job_id';
export const INSTANT_QUOTE = '/instant-quote';
export const EXCEPTIONS = '/exceptions';

export const CORPORATE_SIGNUP = '/corporate/signup';
export const CORPORATE_USERS = '/corporate/users';

export const TRACK_ORDER = '/trackorder';

/**
 * Customer Routes
 * These routes are intented to be accessed by external customers who do not have an account/role
 */
export const CUSTOMER = '/customer';
export const CUSTOMER_TRACK_BY_PO = '/customer/trackbypo/';
export const CUSTOMER_TRACK_PAGE = '/customer/track';
export const CUSTOMER_PREDELIVERY_SURVEY_PAGE = '/customer/predelivery';
export const CUSTOMER_AVAILABILITY_PAGE = '/customer/availability';
export const CUSTOMER_CONFIRM_TIMEFRAME_PAGE = '/customer/confirmtimeframe';

/**
 * Admin Routes
 * These routes should only be accessed by admin users
 */
export const ADMIN_SIGN_IN = '/adminsignin';
export const ADMIN_ACCOUNT = '/admin/account';
export const ADMIN_LOADS = '/admins/loadboard';
export const ADMIN_ORDERS = '/admin/orders';
export const ADMIN_SHIPMENT = '/admins/placeshipment';
export const ADMIN_SHIPPER = '/admin/shipper/:shipper_id';
export const ADMIN_CARRIER = '/admin/carrier/:carrier_id';
export const CREATE_ROUTE = '/admin/createroute';
export const ADMIN_COMMUNICATIONS = '/admin/communications';
export const ADMIN_FTL = '/admin/ftl';
export const ADMIN_EDITFTLROUTE = '/admin/editftlroute/:uid';
export const ADMIN_FINANCIALS_ORDERS = '/admin/financials/orders';
export const ADMIN_FINANCIALS_RECEIVABLES = '/admin/financials/receivables';
export const ADMIN_FINANCIALS_PAYABLES = '/admin/financials/payables';
export const ADMIN_USERS = '/admin/users';
export const ADMIN_TRACKING_FTL = '/admin/tracking/FTL';
export const ADMIN_TRACKING_LTL = '/admin/tracking/LTL';
export const ADMIN_ROUTEDETAILS = '/admin/routedetails/:routeUid';
export const ADMIN_ACCOUNT_RESET = '/admin/account-reset';
export const ADMIN_MATCHING_TOOL = '/admin/matching-tool';
export const ADMIN_TARIFFS = '/admin/tariff';
export const ADMIN_EXCEPTIONS = '/admin/exceptions';
export const ADMIN_NOTES = '/admin/notes';

/**
 * User Routes
 * These routes should be accessible by regular authenticated users
 */
export const DISPATCH_PLAN = '/dispatch/plan';
export const MARKETPLACE_LISTINGS = '/marketplace/listings';
export const MARKETPLACE_BIDS = '/marketplace/bids';

/**
 * Carrier Routes
 * These routes should only be accessed by carrier users
 */

// Generic Carrier Routes
export const CARRIER_ACCOUNT = '/carrier/account';
export const CARRIER_SIGN_UP = '/carrier/signup';
export const CARRIER_SIGN_IN = '/carrier/signin';

// Carrier LoadBoard Routes
export const CARRIER_LOADBOARD_LTL = '/carrier/loads/ltl/:order_id?';
export const CARRIER_LOADBOARD_FTL = '/carrier/loads/ftl';

// Carrier Import Routes
export const CARRIER_ORDER_SUBMITTED = '/carrier/shipmentsubmitted';

// Carrier OMS Routes
export const CARRIER_OMS_INCOMPLETE = '/carrier/oms/incomplete';
export const CARRIER_OMS_MYORDERS = '/carrier/oms/myorders';
export const CARRIER_OMS_MYROUTES = '/carrier/oms/myroutes';

// Carrier Dispatch Routes
export const CARRIER_DISPATCH_UNASSIGNED = '/dispatch/:orderType';
export const CARRIER_DISPATCH_PLAN = '/carrier/dispatch/plan';

// Carrier Tracking Routes
export const CARRIER_ROUTEDETAILS = '/carrier/routedetails/:routeUid';
export const CARRIER_TRACK = '/carrier/track';
export const CARRIER_TRACKING = '/carrier/tracking/all';

// Carrier Accounting Routes
export const CARRIER_PAY_PERIOD = '/carrier/revenue/payperiod';
export const CARRIER_ACCOUNTING_ORDERS = '/carrier/accounting/completeorders';
export const CARRIER_INVOICES = '/carrier/accounting/invoices';
export const CARRIER_INVOICE = '/carrier/accounting/invoices/:invoice_id';
export const CARRIER_ACCOUNTING_V2 = '/carrier/accounting/marketplace';

export const PASSWORD_RESET_PAGE = '/auth/password-reset';

// Auction related OMS routes
export const AUCTIONED_ORDERS_LISTINGS = '/auction/listings';
export const AUCTIONED_ORDERS_BIDS = '/auction/bids';

export const ACCESSORIALS = '/accounting/accessorials/:order_id/:perspective';
export const DRIVER_PAYMENTS = '/driver-payments';
export const DRIVER_PAYMENT = '/driver-payments/:payment_id';

/**
 * Shipper Routes
 * These routes should only be accessed by shipper users
 */

// Generic Shipper Routes
export const SHIPPER_ACCOUNT = '/shipper/account';
export const SHIPPER_SIGN_UP = '/shipper/signup';
export const SHIPPER_SIGN_IN = '/shipper/signin';

// Shipper Import Routes
export const SHIPPER_ORDER_SUBMITTED = '/shipper/shipmentsubmitted';

// Shipper OMS Routes
export const SHIPPER_OMS_INCOMPLETE = '/shipper/oms/incomplete';
export const SHIPPER_OMS_MYORDERS = '/shipper/oms/myorders';
export const SHIPPER_OMS_MYROUTES = '/shipper/oms/myroutes';

// Shipper Dispatch Routes
export const SHIPPER_DISPATCH_UNASSIGNED = '/dispatch/:orderType';
export const SHIPPER_DISPATCH_PLAN = '/shipper/dispatch/plan';

// Shipper Tracking Routes
export const SHIPPER_ROUTEDETAILS = '/shipper/routedetails/:routeUid';
export const SHIPPER_TRACK = '/shipper/track';
export const SHIPPER_TRACKING = '/shipper/tracking/all';

export const SHIPPER_INVOICE = '/shipper/accounting/invoices/:invoice_id';

/**
 * Teammate Routes
 * These routes should only be accessed by teammate users
 */
// Generic Teammate Routes
export const TEAMMATE_SIGN_UP = '/teammate/signup';
export const TEAMMATE_SIGN_IN = '/teammate/signin';
export const TEAMMATE_HOME = '/teammate/home';
export const TEAMMATE_ACCOUNT = '/teammate/account';
export const TEAMMATE_DASHBOARD = '/teammate/dashboard';

// Teammate OMS Routes
export const TEAMMATE_OMS_MYROUTES = '/teammate/oms/myroutes';

// Carrier LoadBoard Routes
export const TEAMMATE_LOADBOARD_LTL = '/teammate/loads/ltl';
export const TEAMMATE_LOADBOARD_FTL = '/teammate/loads/ftl';

/**
 * These routes should be cycled out or deprecated
 * New routes should be placed above in the correct category
 */
export const SHIPPER_BILLING = '/shipper/billing';
export const CARRIER_PAYMENT = '/carrier/payment';
export const CARRIER_REVENUE = '/carrier/revenue';
export const ADMIN_TESTING = '/admins/testing';
export const TEAMMATE_LOAD_BOARD = '/teammate/loadboard';
export const TEAMMATE_FTL = '/teammate/ftl';
export const TEAMMATE_NEWSHIPMENT = '/teammate/newshipment';
export const TEAMMATE_NEWROUTE = '/teammate/newroute';
export const TEAMMATE_MYORDERS = '/teammate/myorders/:orderType';
export const TEAMMATE_CLAIMEDLOADS = '/teammate/claimedloads/:orderType';
export const TEAMMATE_MYROUTES = '/teammates/myroutes';
export const TEAMMATE_BILLING = '/teammate/billing';
export const TEAMMATE_PAYMENT = '/teammate/payment';
export const SHIPPER_MYROUTES = '/shipper/myroutes';
export const SAAS_SHIPPER_MYROUTES = '/shipper/saas/myroutes';
export const SAAS_SHIPPER_ROUTES_CREATE = '/shipper/routes/create';
export const ADMIN_SHIPPERFTL = '/admin/shipperftl';
export const SAAS_SHIPPER_ORDERS = '/shipper/orders/:orderType';
export const SAAS_SHIPPER_ORDERS_INCOMPLETE = '/shipper/incompleteOrders';
export const SHIPPER_ACCOUNTING_INVOICES = '/shipper/accounting/invoices';
export const SHIPPER_STATEMENT_DETAILS = '/shipper/accounting/statements/:statementDateRange';
export const SHIPPER_ACCOUNTING_INVOICE_DETAILS = '/shipper/accounting/invoicedetails/:invoiceId';
export const SHIPPER_ACCOUNTING_STATEMENTS = '/shipper/accounting/statements';
export const SAAS_SHIPPER_PLANNING = '/shipper/plan';
export const SAAS_CARRIER_PLANNING = '/carrier/plan';
