import React, { useContext, useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { Grid, Button } from '@material-ui/core';
import { useClientUser } from '@/hooks';
import { PlanningContext } from '../context';
import { useMutation } from '@apollo/client';
import { SEND_TO_ONWARD, UPDATE_ORDERS } from '../graphql/mutations';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { MODALS } from '../constants';
import {
    Body2,
    Body3,
    GridItemRow,
    ModalActions,
    ModalContent,
    ModalTitle,
    PrimaryButton,
    SecondaryButton,
} from '../blocks';
import { isFinite } from 'lodash';

const SendToOnwardModal = () => {
    const {
        setModalOpen,
        modalOpen,
        selectedOrders,
        setSelectedOrders,
        setSelectedLoads,
        setNotification,
        setError,
        state: { orders },
        callbacks: { refetch },
    } = useContext(PlanningContext);
    const user = useClientUser();
    const ordersByKey = useMemo(() => {
        return Object.fromEntries(orders.map((order) => [order.order_id, order]));
    }, [orders]);
    const fullOrders = useMemo(() => {
        return Object.entries(selectedOrders)
            .map(([key, toggle]) => (ordersByKey[key] && toggle ? ordersByKey[key] : null))
            .filter((order) => order !== null);
    }, [selectedOrders, ordersByKey]);

    const [updateOrders] = useMutation(UPDATE_ORDERS, {
        update: (cache, { data }) => {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'orders' });
        },
    });

    const undoSendToMarketplace = (orderIds, delDate) => {
        updateOrders({
            variables: {
                order_ids: orderIds,
                update: {
                    delivery_date: delDate,
                    order_status: 'pending',
                    oms: true,
                    planning: true,
                },
            },
            onError: (error) => {
                console.error(error);
                setError(error, 'Error undoing action');
            },
        });
    };

    const [sendOrder, { loading }] = useMutation(SEND_TO_ONWARD, {
        variables: {
            order_ids: Object.keys(selectedOrders),
            user_id: user.user_id,
        },
        onError: (error) => {
            setSelectedLoads({});
            // setSelectedOrders({});
            setError(error, 'Error send loads to Onward!');
        },
        onCompleted: () => {
            setNotification({
                severity: 'success',
                message: `Order sent to the Onward Marketplace.`,
                clickable: (
                    <Button
                        onClick={() => undoSendToMarketplace(Object.keys(selectedOrders), fullOrders[0].delivery_date)}
                        variant="outlined"
                        style={{ color: 'white', borderColor: 'white', marginLeft: 10 }}
                    >
                        Undo
                    </Button>
                ),
            });
            setSelectedLoads({});
            setSelectedOrders({});
            setModalOpen(null);
            refetch();
        },
        // leaving this here so we can update cache directly in the future
        // update: (cache, { data }) => {
        // },
    });

    const handleModalClose = () => setModalOpen(null);

    const ratesMap = useMemo(() => {
        if (!fullOrders) return {};
        const ratesMap = {};
        fullOrders.forEach((order) => {
            if (order.admin_shipper_rate_override && order.shipper_rate) {
                ratesMap[order.order_id] = `$${order.shipper_rate.toFixed(2)}`;
            }

            if (user?.circles?.['hide-market-rates']) {
                ratesMap[order.order_id] = 'Contract Rates';
            }

            ratesMap[order.order_id] = isFinite(order.shipper_rate)
                ? `$${order.shipper_rate.toFixed(2)}`
                : 'We will contact you';
        });
        return ratesMap;
    }, [fullOrders]);

    return (
        <NavResponsiveModal open={modalOpen === MODALS.SENT_TO_ONWARD} onClose={handleModalClose}>
            <ModalTitle>Confirmation - Send Loads to Onward</ModalTitle>
            <Body2 style={{ padding: '8px 12px 12px 12px' }}>
                We're excited for you to push these loads to our marketplace!
            </Body2>
            <Body2 style={{ padding: '0px 0px 0px 12px' }}>Let's confirm the details:</Body2>
            <ModalContent>
                <GridItemRow>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Body2>
                                <u>Orders Selected:</u>
                            </Body2>
                        </Grid>
                    </Grid>
                </GridItemRow>
                {fullOrders.map((order, index) => (
                    <>
                        <GridItemRow>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <Body2>
                                        {index + 1}. {order.order_number}
                                    </Body2>
                                </Grid>
                            </Grid>
                        </GridItemRow>
                        <GridItemRow>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <Body3>PO#: {order.po_number}</Body3>
                                </Grid>
                            </Grid>
                        </GridItemRow>
                        <GridItemRow>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <Body3>{order.dropoff_name}</Body3>
                                </Grid>
                            </Grid>
                        </GridItemRow>
                        <GridItemRow>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <Body3>
                                        {order.pickup_city} to {order.dropoff_city}
                                    </Body3>
                                </Grid>
                            </Grid>
                        </GridItemRow>
                        {order.shipper_id === user.user_id && (
                            <GridItemRow>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <Body3>Onward Price: {ratesMap[order.order_id]}</Body3>
                                    </Grid>
                                </Grid>
                            </GridItemRow>
                        )}
                    </>
                ))}
                <GridItemRow
                    css={css`
                        margin-top: 18px !important;
                    `}
                >
                    <Grid container alignItems="center">
                        <Grid item>
                            <Body2>
                                All orders will be priced by an Onward representative and will be <br />
                                subject to the Onward Marketplace Terms and Conditions.
                            </Body2>
                        </Grid>
                    </Grid>
                </GridItemRow>
            </ModalContent>
            <ModalActions>
                <SecondaryButton onClick={handleModalClose}>Cancel</SecondaryButton>
                <PrimaryButton onClick={sendOrder} disabled={loading}>
                    Confirm
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default SendToOnwardModal;
