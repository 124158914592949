import { createContext, useMemo, useEffect, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom';
import { getWeek, setWeek } from 'date-fns';

import { useClientUser } from '@/hooks';
import { useLazyQuery, useMutation } from '@apollo/client';
import { captureException } from '@sentry/react';

import { useColumns } from './columns.js';
import { FILTERS } from './constants.js';
import { ADMIN_NOTES, ADMIN_ACKNOWLEDGE_NOTES, ADD_ADMIN_ORDER_NOTE } from './graphql';

export const Context = createContext();

const TODAY = new Date(new Date().setHours(0, 0, 0, 0));
const thisWeek = getWeek(TODAY, { weekStartsOn: 1 });
const lastMo = setWeek(TODAY, thisWeek - 4, { weekStartsOn: 1 });

export const ContextProvider = ({ children }) => {
    const navigate = useNavigate();
    const { user_id } = useClientUser();
    const [hasMore, setHasMore] = useState(false);
    const [selectedMap, setSelected] = useState({});
    const [noteDetails, setNoteDetails] = useState(null);
    const [filter, setFilter] = useState({
        status: FILTERS.UNACKNOWLEDGED,
        shippers: [],
        start: lastMo,
    });

    const [getAdminNotes, { data, loading: initInflight, fetchMore }] = useLazyQuery(ADMIN_NOTES, {
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
    });

    const [addNote, { loading: addNoteLoading }] = useMutation(ADD_ADMIN_ORDER_NOTE, {
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
    });

    const [acknowledgeNotes, { loading: acknowledgementLoading }] = useMutation(ADMIN_ACKNOWLEDGE_NOTES, {
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
    });

    const where = useMemo(() => {
        let status = [];
        switch (filter.status) {
            case FILTERS.ACKNOWLEDGED:
                status = [{ acknowledged_by_admin: { _eq: true } }];
                break;
            case FILTERS.UNACKNOWLEDGED:
                status = [{ acknowledged_by_admin: { _eq: false } }];
                break;
        }

        return [
            ...status,
            ...(filter?.shippers?.length > 0
                ? [{ order: { order_shipper: { client_id: { _in: filter?.shippers } } } }]
                : []),
            ...(filter?.carriers?.length > 0
                ? [{ order: { order_carrier: { client_id: { _in: filter?.carriers } } } }]
                : []),
            ...(filter.start ? [{ created_at: { _gte: filter.start.toISOString() } }] : []),
            ...(filter.end ? [{ created_at: { _lt: filter.end.toISOString() } }] : []),

            { requires_acknowledgement: { _eq: true } },
        ];
    }, [filter]);

    const adminNotes = useMemo(() => {
        return data?.notes || [];
    }, [data]);

    const [shippers, carriers] = useMemo(() => {
        const shipperMap = {};
        const carrierMap = {};
        adminNotes.forEach((note) => {
            if (note?.order?.order_shipper?.client_id) {
                shipperMap[note?.order?.order_shipper?.client_id] = note?.order?.order_shipper?.business_name;
            }
            if (note?.order?.order_carrier?.client_id) {
                carrierMap[note?.order?.order_carrier?.client_id] = note?.order?.order_carrier?.business_name;
            }
        });
        const shippersAndLabels = Object.entries(shipperMap).map(([shipperId, shipperName]) => {
            return {
                label: shipperName,
                value: shipperId,
            };
        });
        const carriersAndLabels = Object.entries(carrierMap).map(([carrierId, carrierName]) => {
            return {
                label: carrierName,
                value: carrierId,
            };
        });
        return [shippersAndLabels, carriersAndLabels];
    }, [adminNotes]);

    const selected = useMemo(() => {
        return Object.keys(selectedMap).filter((attr) => selectedMap[attr]);
    }, [selectedMap]);

    const notesMap = useMemo(() => {
        return Object.fromEntries(adminNotes.map((note) => [note.note_id, note]));
    }, [adminNotes]);

    const getAdminNotesDebounced = useMemo(
        () =>
            debounce((payload) => {
                return getAdminNotes(payload);
            }, 500),
        []
    );

    useEffect(() => {
        getAdminNotes({
            variables: {
                where: {
                    _and: where,
                },
            },
        });
    }, []);

    useEffect(() => {
        if (!initInflight) {
            getAdminNotesDebounced({
                variables: {
                    where: {
                        _and: where,
                    },
                },
            });

            // setHasMore(true);
        }
    }, [where, getAdminNotesDebounced, initInflight]);

    const selectedNote = useMemo(() => {
        if (!adminNotes || !adminNotes?.length) return;
        return adminNotes?.find((n) => n.note_id === noteDetails);
    }, [adminNotes, noteDetails]);

    // May implment the "loadMore" functionality bel'w in the future if required, but seems unlikely / unnecessary currently.
    // const loadMore = useCallback(() => {
    //     fetchMore({
    //         variables: {
    //             where: {
    //                 _and: [...where, { created_at: { _lte: cursor } }],
    //             },
    //         },
    //         updateQuery: (data, { fetchMoreResult }) => {
    //             const prev = Object.fromEntries(data.results.map((invoice) => [invoice.carrier_invoice_id, true]));
    //             const clone = [
    //                 ...data.results,
    //                 ...fetchMoreResult.results.filter((invoice) => !prev[invoice.carrier_invoice_id]),
    //             ];
    //             return {
    //                 results: clone,
    //             };
    //         },
    //     }).then((result) => {
    //         if (result.data.results.length < 100) {
    //             setHasMore(false);
    //         }
    //     });
    // }, [where, cursor, fetchMore]);

    const callbacks = {
        setFilter,
        // loadMore,
        onRowClick: (note) => {
            return navigate(`/order/${note.note_id}`);
        },
        getRowId: (note) => {
            return note.note_id;
        },
        acknowledgeOrderNotes: () => {
            acknowledgeNotes({
                variables: {
                    orderNoteIds: selected,
                    notes: selected.map((noteId) => ({
                        is_acknowledgement: true,
                        order_id: notesMap[noteId]?.order_id,
                        source_user_type: 'Admin',
                        source_user_id: user_id,
                    })),
                },
            });
        },
        selectRows: setSelected,
        hasMore,
        setNoteDetails,
        acknowledgeNote: ({ note_id, notes }) => {
            acknowledgeNotes({
                variables: {
                    orderNoteIds: [note_id],
                    notes,
                },
            });
        },
        addNote,
        adminNotes,
    };

    const COLUMNS = useColumns({ callbacks, filter });

    return (
        <Context.Provider
            value={{
                state: {
                    hasMore,
                    shippers,
                    carriers,
                    selected,
                    notes: adminNotes,
                    filter,
                    columns: COLUMNS,
                    noteDetails,
                    selectedNote,
                },
                loading: {
                    init: initInflight,
                    acknowledgementLoading,
                    addNoteLoading,
                },
                callbacks,
            }}
        >
            {children}
        </Context.Provider>
    );
};
