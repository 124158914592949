import { useClientUser } from '@/hooks';
import React, { useMemo, useState } from 'react';
import { TabCard } from '../blocks';
import { css } from '@emotion/react';
import { Autocomplete } from '@material-ui/lab';
import { OnwardChip, OnwardSwitch, PrimaryButton } from '@/styles/blocks';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { UPDATE_CLIENT } from '../CustomTags/graphql';
import { omit } from 'lodash';

const VARIABLES = {
    carrier_name: 'Carrier Name',
    shipper_name: 'Shipper Name',
    support_phone: 'Support Phone',
    manufacturer: 'Manufacturer',
    customer_name: 'Customer Name',
    customer_address: 'Customer Address',
    customer_email: 'Customer Email',
    customer_phone: 'Customer Phone',
    delivery_date: 'Delivery Date',
    order_number: 'Order Number',
    po_number: 'PO Number',
};

const CustomMessages = () => {
    const { customer_messages, user_id } = useClientUser();
    const [updates, setUpdates] = useState({});

    const [updateClient, { loading }] = useMutation(UPDATE_CLIENT);

    const messages = useMemo(() => {
        return {
            ...customer_messages,
            ...updates,
        };
    }, [customer_messages, updates]);

    const onSave = () => {
        updateClient({
            variables: {
                client_id: user_id,
                update: {
                    customer_messages: messages,
                },
            },
        });
    };

    return (
        <TabCard
            css={css`
                padding: 2rem;
            `}
        >
            <Grid container>
                <PrimaryButton onClick={() => setUpdates((prev) => ({ ...prev, 'New Message': {} }))}>
                    Add New Message
                </PrimaryButton>
                <PrimaryButton
                    onClick={onSave}
                    disabled={loading || Object.keys(updates).length === 0}
                    css={css`
                        margin-left: 2rem;
                    `}
                >
                    Save Messages
                </PrimaryButton>
            </Grid>
            <Grid
                container
                direction="column"
                css={css`
                    margin-top: 2rem;
                    flex-wrap: nowrap;
                    width: 800px;
                `}
            >
                {Object.entries(messages).map(([name, message], index) => (
                    <Grid
                        container
                        direction="column"
                        css={css`
                            margin: 1rem 0;
                        `}
                    >
                        {index > 0 ? <hr /> : null}
                        <Grid
                            container
                            css={css`
                                flex-wrap: nowrap;
                                align-items: center;
                            `}
                        >
                            <Grid
                                item
                                css={css`
                                    flex: 1;
                                    flex-basis: 0;
                                    margin-right: 1rem;
                                `}
                            >
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Message Name"
                                    value={name}
                                    onChange={(e) => {
                                        const next = e.target.value;
                                        setUpdates((prev) => ({
                                            ...omit(prev, [name]),
                                            [next]: message,
                                        }));
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                css={css`
                                    flex: 1;
                                    flex-basis: 0;
                                    margin-right: 1rem;
                                `}
                            >
                                <TextField
                                    fullWidth
                                    select
                                    variant="outlined"
                                    label="Message Variables"
                                    value={''}
                                    onChange={(e) => {
                                        const selected = e.target.value;
                                        setUpdates((prev) => ({
                                            ...prev,
                                            [name]: { ...prev[name], body: `${prev[name].body || ''}{{${selected}}}` },
                                        }));
                                    }}
                                    InputLabelProps={{
                                        shrink: false,
                                    }}
                                >
                                    {Object.entries(VARIABLES).map(([key, label]) => (
                                        <MenuItem key={key} value={key}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid
                                item
                                css={css`
                                    flex: 1;
                                    flex-basis: 0;
                                `}
                            >
                                <OnwardSwitch
                                    label={message.scheduled ? 'Scheduled' : 'Immediate'}
                                    checked={message.scheduled}
                                    onChange={() =>
                                        setUpdates((prev) => ({
                                            ...prev,
                                            [name]: { ...prev[name], scheduled: !prev[name].scheduled },
                                        }))
                                    }
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            multiline
                            maxRows={10}
                            variant="outlined"
                            label="Message Body"
                            value={message.body}
                            onChange={(e) => {
                                const next = e.target.value;
                                setUpdates((prev) => ({
                                    ...prev,
                                    [name]: { ...prev[name], body: next },
                                }));
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            css={css`
                                margin-top: 1rem;
                            `}
                        />
                    </Grid>
                ))}
            </Grid>
        </TabCard>
    );
};

export default CustomMessages;
