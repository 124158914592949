import React, { useMemo } from 'react';
import Select from './Select';
import { convertToISO } from '../../utilities/convertToISO';
import { getTimezoneOffset } from 'date-fns-tz';
import { css } from '@emotion/react';

const routeTimeZoneFormatter = (timeZone) =>
    new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        timeZone,
        timeZoneName: 'short',
    });

const localFormatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
});

function StartTimeSelect(props) {
    const { onChange, value, timeZone, deliveryDate, disabled, interval, label, styles, filter, ...rest } = props;

    const menuItems = useMemo(() => {
        const utcDate = new Date(convertToISO(deliveryDate));

        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const browserLocalDate = new Date(utcDate.getTime() - getTimezoneOffset(localTimeZone, utcDate));

        const items = [];

        const intervalList = [];
        for (let i = 0; i < 60; i += interval) {
            intervalList.push(i);
        }

        for (let hours = 0; hours < 24; hours++) {
            intervalList.forEach((minutes) => {
                browserLocalDate.setHours(hours);
                browserLocalDate.setMinutes(minutes);
                browserLocalDate.setSeconds(0);
                browserLocalDate.setMilliseconds(0);

                if (timeZone && localTimeZone !== timeZone) {
                    const routeLocalDate = new Date(
                        browserLocalDate.getTime() +
                            getTimezoneOffset(localTimeZone, utcDate) -
                            getTimezoneOffset(timeZone, utcDate)
                    );

                    if (!filter || filter({ local: browserLocalDate })) {
                        items.push({
                            key: routeLocalDate.getTime(),
                            value: `${localFormatter.format(routeLocalDate)} (${routeTimeZoneFormatter(timeZone).format(
                                routeLocalDate
                            )})`,
                        });
                    }
                } else {
                    if (!filter || filter({ local: browserLocalDate })) {
                        items.push({
                            key: browserLocalDate.getTime(),
                            value: `${localFormatter.format(browserLocalDate)}`,
                        });
                    }
                }
            });
        }

        return items;
    }, [timeZone, deliveryDate, interval, filter]);

    return (
        <Select
            defaultLabel={label ? label : 'Select arrival time...'}
            defaultMenuItem={{
                label: label ? label : 'Select arrival time ...',
                value: 0,
            }}
            menuItems={menuItems}
            onChange={onChange}
            value={value}
            label={label}
            disabled={disabled}
            containerStyles={
                styles
                    ? styles
                    : {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          color: '#212529',
                          width: '255px',
                      }
            }
            css={css`
                ${!label &&
                `& legend {
                        display: none;
                    }`}
            `}
            {...rest}
        />
    );
}

export default StartTimeSelect;
