import { gql } from '@apollo/client';
import { CSV_EXPORT_TEMPLATE_FIELDS } from '../fragments/csv_export_templates';

export const INSERT_CSV_EXPORT_TEMPLATE = gql`
    ${CSV_EXPORT_TEMPLATE_FIELDS}

    mutation InsertCsvExportTemplate($object: csv_export_templates_insert_input = {}) {
        insert_csv_export_templates_one(object: $object) {
            ...CsvExportTemplateFields
        }
    }
`;

export const DELETE_CSV_EXPORT_TEMPLATE = gql`
    ${CSV_EXPORT_TEMPLATE_FIELDS}

    mutation DeleteCsvExportTemplate($template_id: uuid = "") {
        delete_csv_export_templates_by_pk(template_id: $template_id) {
            template_id
        }
    }
`;
