/**
 * Calculates the total amount for an invoice order
 * @param {Object} order - The order object
 * @param {string} breakdown - The breakdown type (e.g., 'carrierBreakdown')
 * @param {Array} FIXED_CHARGES - Array of fixed charge objects
 * @returns {number} The total amount for the order
 */

import { calcOrderSubtotal } from './calcOrderSubtotal';

export const calcOrderInvoiceTotals = (order) => {
    const breakdown = order.oms ? 'internalBreakdown' : 'carrierBreakdown';
    const subTotal = calcOrderSubtotal(order);
    const cutoffDate = new Date('2024-10-20');
    const completiondate = new Date(order.billing_completion_time) || new Date('1970-01-01');
    const isAfterCutoff = completiondate > cutoffDate;

    const accessorials = (order?.price_breakdown?.[breakdown]?.accessorials || []).reduce((acc, { quantity, rate }) => {
        return acc + quantity * rate;
    }, 0);

    if (isAfterCutoff && order?.admin_carrier_rate_override != 0) {
        return subTotal + accessorials || 0;
    }

    const total =
        order?.admin_carrier_rate_override != null || order?.oms
            ? subTotal + accessorials
            : (subTotal > order.carrier_rate ? order.carrier_rate : subTotal) + accessorials;

    return total || 0;
};
