import { CircularProgress, Grid, TableContainer } from '@material-ui/core';
import React from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useFlexLayout, useRowSelect, useSortBy, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { IndeterminateCheckbox } from './blocks';
import BodyCell from './BodyCell';
import BodyRow from './BodyRow';
import HeaderCell from './HeaderCell';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import HeaderRow from './HeaderRow';

export const TableLoader = () => {
    return (
        <Grid
            item
            container
            css={css`
                background-color: white;
                border: 1px solid ${colors.greys.border};
                border-radius: 5px;
                align-items: center;
                justify-content: space-around;
            `}
        >
            <CircularProgress size={200} color="primary" />
        </Grid>
    );
};

const Table = ({ data, columns, options = {}, callbacks = {} }) => {
    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, state } = useTable(
        {
            data,
            columns,
            initialState: {},
            getRowId: callbacks.getRowId,
            enableRowSelection: !!options.select,
            enableMultiRowSelection: !!options.select,
            autoResetSelectedRows: !!options.select,
        },
        useSortBy,
        useRowSelect,
        useSticky,
        useFlexLayout,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                ...(options.select
                    ? [
                          {
                              id: 'selection',
                              sticky: 'left',
                              width: 50,
                              Header: ({ getToggleAllRowsSelectedProps }) => (
                                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                              ),
                              Cell: ({ row }) => (
                                  <IndeterminateCheckbox
                                      onClick={(e) => e.stopPropagation()}
                                      {...row.getToggleRowSelectedProps()}
                                  />
                              ),
                          },
                      ]
                    : []),
                ...columns,
            ]);
        }
    );

    useEffect(() => {
        if (options.select) {
            callbacks.setSelectedIds(state?.selectedRowIds || {});
        }
    }, [state?.selectedRowIds]);

    const Row = useCallback(
        ({ index, style }) => {
            //HEADERS
            if (index === 0) {
                const [group] = headerGroups;

                return (
                    <HeaderRow {...group.getHeaderGroupProps({ style })}>
                        {group.headers.reduce(
                            (acc, subgroup) => [
                                ...acc,
                                subgroup.headers.map((col) => (
                                    <HeaderCell {...col.getHeaderProps()} key={col.id} cell={col} align={col.align} />
                                )),
                            ],
                            []
                        )}
                    </HeaderRow>
                );
            }

            //BODY
            const row = rows[index - 1];
            prepareRow(row);

            return (
                <BodyRow
                    {...row.getRowProps({
                        style,
                    })}
                    row={row}
                    onClick={(e) => {
                        if (callbacks?.rowOnClick) callbacks.rowOnClick(e, row.original);
                    }}
                >
                    {row.cells.map((cell) => (
                        <BodyCell
                            {...cell.getCellProps()}
                            cell={cell}
                            align={cell.column.align}
                            callbacks={callbacks}
                        />
                    ))}
                </BodyRow>
            );
        },
        [prepareRow, rows]
    );

    return (
        <TableContainer
            css={css`
                height: 100%;
                overflow: hidden;
                background-color: white;
                border: 1px solid ${colors.greys.border};
                border-radius: 5px;
            `}
        >
            <div
                {...getTableProps()}
                css={css`
                    height: 100%;
                    display: flex;
                    flex-grow: 1;
                    flex-direction: column;
                `}
            >
                <div
                    {...getTableBodyProps()}
                    css={css`
                        display: flex;
                        flex-grow: 1;
                    `}
                >
                    <AutoSizer>
                        {({ height, width }) => (
                            <FixedSizeList height={height} itemCount={rows.length + 1} itemSize={57} width={width}>
                                {Row}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </div>
            </div>
        </TableContainer>
    );
};

export default Table;
