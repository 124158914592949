import { DOCUMENT_FIELDS } from '@/graphql/queries/documents';
import { gql } from '@apollo/client';

export const ADD_CUSTOM_DOCUMENT = gql`
    ${DOCUMENT_FIELDS}
    mutation InsertCustomDocument($object: documents_insert_input = {}) {
        insert_documents_one(object: $object) {
            ...DocumentFields
        }
    }
`;

export const DELETE_CUSTOM_DOCUMENT = gql`
    ${DOCUMENT_FIELDS}
    mutation DeleteCustomDocument($document_id: uuid!) {
        delete_documents_by_pk(document_id: $document_id) {
            ...DocumentFields
        }
    }
`;

export const DOCUMENTS_BY_CLIENT_ID = gql`
    ${DOCUMENT_FIELDS}
    query DocumentsByClient($client_id: uuid!) {
        documents(where: { client_id: { _eq: $client_id } }) {
            ...DocumentFields
        }
    }
`;
