import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import { css } from '@emotion/react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Rating } from '@material-ui/lab';
import { Card, Button, Grid, CardActionArea } from '@material-ui/core';

import { colors } from '@/styles';
import { useItemQuantity } from '@/hooks';
import { QUERY_BY_ORDER as GET_RATING } from '@/graphql/queries/ratings';
import { QUERY_BY_ID as GET_ASSOCIATED_ROUTE } from '@/graphql/queries/routes';
import { UserContext } from '@/components/App';
import CarrierReviewModal from '@/components/CarrierReviewModal';
import { OWNERSHIP_TYPES, getOrderOwnership } from './utils';
import { H2, H4, H6, Body1, Subtitle1, local, PrimaryButton } from './blocks';
import { useClientUser } from '@/hooks';
import { delWindowToFormattedDateRange } from '@/utilities/delWindowToFormattedDate';

const integerFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export default function OrderCard({ order, orderType }) {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const { user_id } = useClientUser();
    const [carrierReviewOpen, setCarrierReviewOpen] = useState(false);
    const [logo, setLogo] = useState(null);
    const [logoBackgroundColor, setLogoBackgroundColor] = useState(null);

    const { data: ratings } = useQuery(GET_RATING, {
        fetchPolicy: 'cache-only',
        nextFetchPolicy: 'cache-and-network',
        variables: { order_id: order.order_id },
    });
    const itemCount = useItemQuantity([order]);

    const canLeaveReview = !!(!order?.oms && order?.carrier_id && order?.carrier_id !== user.user_id);

    useEffect(() => {
        if (order?.shipper_rate_override && !order.oms) {
            setLogo(order?.shipper_rate_override?.assign?.profile_img || null);
            setLogoBackgroundColor(order?.shipper_rate_override?.assign?.profile_img_color || null);
        }
    }, [order]);

    const route = useMemo(() => {
        return order?.routes?.find((mapping) =>
            ['FULL', order?.order_type === 'return' ? 'PICKUP' : 'DROPOFF'].includes(mapping.type)
        )?.route;
    }, [order]);

    const subtitleMessage = useMemo(() => {
        switch (orderType) {
            case 'preTransit':
                return `Estimated Delivery: ${order.delivery_date_formatted || 'TBD'}`;
            case 'inTransit':
                return `Estimated Delivery: ${order.delivery_date_formatted || 'TBD'}`;
            case 'completed':
                return `Completed: ${order.delivery_date_formatted || 'TBD'}`;
        }
    }, [order, orderType]);

    const pickupSubtitle = useMemo(() => {
        if (!order.pickup_date_formatted) return null;

        const pickupDate = `Pickup: ${order.pickup_date_formatted}`;
        const pickupWindow =
            order.pickup_window_start && order.pickup_window_end
                ? ` (${delWindowToFormattedDateRange(
                      order.pickup_window_start,
                      order.pickup_window_end,
                      order.pickup_zip
                  )})`
                : '';

        switch (orderType) {
            case 'preTransit':
            case 'inTransit':
                return (
                    <>
                        {pickupDate}
                        {pickupWindow && <br />}
                        {pickupWindow}
                    </>
                );
            default:
                return null;
        }
    }, [order, orderType]);

    const firstReview = useMemo(() => {
        if (ratings?.results?.length > 0) {
            return ratings.results[0];
        }
    }, [ratings]);

    const { label, fragment } = useMemo(() => {
        const type = getOrderOwnership({ order, user_id });
        let label;
        let color;

        switch (type) {
            case OWNERSHIP_TYPES.INTERNAL:
                label = 'Internal';
                color = local.gold;

                break;
            case OWNERSHIP_TYPES.ONWARD:
                label = 'Onward';
                color = colors.greens.primary;
                break;
        }

        return {
            label,
            fragment: css`
                color: ${color};
            `,
        };
    }, [order]);

    return (
        <>
            <Card
                onClick={() => navigate(`/order/${order.order_id}`)}
                css={css`
                    margin: 16px 0;
                    background: ${colors.white.primary};
                `}
            >
                <CardActionArea
                    css={css`
                        padding: 16px;
                    `}
                >
                    <Grid container direction="column">
                        <Grid
                            container
                            justifyContent="space-between"
                            css={css`
                                position: relative; /* Add this */
                            `}
                        >
                            <H6
                                css={css`
                                    margin: 0;
                                    padding-right: 8rem; /* Make space for the logo/label */
                                    word-break: break-word;
                                `}
                            >
                                {order.order_number} | {order.po_number}{' '}
                            </H6>
                            {logo ? (
                                <Grid
                                    item
                                    css={css`
                                        position: absolute; /* Position absolutely */
                                        right: 0;
                                        top: 0;
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-end;
                                        background-color: ${logoBackgroundColor};
                                        max-width: 5rem; /* Reduced from 7rem */
                                        height: 2.5rem; /* Fixed height instead of max-height */
                                        border-radius: 5px;
                                        padding: 2px; /* Add some padding inside the container */
                                    `}
                                >
                                    <img
                                        src={logo}
                                        alt="Logo"
                                        css={css`
                                            max-width: 100%;
                                            max-height: 100%;
                                            object-fit: contain;
                                        `}
                                    />
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    css={css`
                                        position: absolute; /* Position absolutely */
                                        right: 0;
                                        top: 0;
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-end;
                                    `}
                                >
                                    <H4
                                        css={css`
                                            ${fragment}
                                        `}
                                    >
                                        {label}
                                    </H4>
                                </Grid>
                            )}
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <Body1>Customer: {order.dropoff_name}</Body1>
                            </Grid>
                            <Grid item xs={12}>
                                <Body1>Items: {integerFormatter.format(itemCount)}</Body1>
                            </Grid>
                            <Grid item xs={12}>
                                <Body1>
                                    Start: {order.pickup_city}, {order.pickup_state} {order.pickup_zip}
                                </Body1>
                            </Grid>
                            <Grid item xs={12}>
                                <Body1>
                                    End: {order.dropoff_city}, {order.dropoff_state}, {order.dropoff_zip}
                                </Body1>
                            </Grid>
                            <Grid item xs={12}>
                                <Subtitle1>{subtitleMessage}</Subtitle1>
                            </Grid>
                            <Grid item xs={12}>
                                <Subtitle1>{pickupSubtitle}</Subtitle1>
                            </Grid>
                            {(order.order_status === 'complete' || order.order_status === 'routedComplete') &&
                                user?.roles?.SHIPPER &&
                                canLeaveReview &&
                                (firstReview ? (
                                    <div
                                        css={css`
                                            display: flex;
                                        `}
                                    >
                                        <Rating
                                            value={firstReview.rating}
                                            readOnly
                                            style={{
                                                marginTop: 6,
                                            }}
                                        />
                                        <Button
                                            css={css`
                                                color: ${colors.greens.primary};
                                                width: 100%;
                                                margin-left: 15px;
                                            `}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setCarrierReviewOpen(true);
                                            }}
                                        >
                                            Edit Review
                                        </Button>
                                    </div>
                                ) : (
                                    <PrimaryButton
                                        variant="contained"
                                        css={css`
                                            flex: 1;
                                            margin-top: 10px;
                                        `}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setCarrierReviewOpen(true);
                                        }}
                                    >
                                        Leave Carrier Review
                                    </PrimaryButton>
                                ))}
                        </Grid>
                    </Grid>
                </CardActionArea>
            </Card>
            <CarrierReviewModal
                isOpen={carrierReviewOpen}
                callbacks={{
                    onClose: () => setCarrierReviewOpen(false),
                }}
                reviewObject={firstReview}
                orderId={order.order_id}
                routeId={route?.route_id}
                revieweeId={order.carrier_id ? order.carrier_id : order.shipper_id}
                revieweeType={order.carrier_id ? 'CARRIER' : 'SHIPPER'}
                driverId={route?.driver_id}
            />
        </>
    );
}
