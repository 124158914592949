export const SURVEY_TYPES = {
    PREDELIVERY: 'Predelivery',
    DRIVER: 'Driver',
    POSTDELIVERY: 'Postdelivery',
};

export const RESPONSE_TYPES = {
    string: 'Free Text',
    number: 'Rating 1 - 5',
    boolean: 'Yes / No',
};
