import { gql } from '@apollo/client';

import { PRICING_TARIFF_FIELDS } from '../fragments/pricing_tariffs';
import { PRICING_OVERRIDE_FIELDS } from '../fragments/pricing_overrides';
import { RATE_FIELDS } from '../fragments/rates';

export const LIST_ADMIN_TARIFFS_BY_CLIENT_ID = gql`
    ${PRICING_TARIFF_FIELDS}
    ${PRICING_OVERRIDE_FIELDS}
    ${RATE_FIELDS}

    query ListAdminTariffsByClientId($client_id: uuid!) {
        tariffs: pricing_tariffs(
            where: {
                _and: [
                    { _or: [{ client_id: { _eq: $client_id } }, { client_id: { _is_null: true } }] }
                    { tariff_type: { _eq: "ORDER" } }
                ]
            }
            order_by: { created_at: desc }
        ) {
            ...PricingTariffFields

            rates {
                ...RateFields
            }
        }

        overrides: pricing_overrides(where: { _and: [{ oms: { _eq: false } }, { client_id: { _eq: $client_id } }] }) {
            ...PricingOverrideFields
        }
    }
`;
