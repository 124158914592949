import React, { useCallback, useRef, useEffect } from 'react';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useSticky } from 'react-table-sticky';
import InfiniteLoader from 'react-window-infinite-loader';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTable, useFlexLayout, useSortBy, useRowSelect } from 'react-table';

import { TableContainer } from '@material-ui/core';
import HeaderCell from '@/components/CarrierAccountingOrders/table/HeaderCell';
import BodyCell from '@/components/CarrierAccountingOrders/table/BodyCell';
import BodyRow from '@/components/CarrierAccountingOrders/table/BodyRow';
import { IndeterminateCheckbox } from '@/components/DispatchUnassigned/table';

const Table = ({ state, loading, callbacks = {} }) => {
    const inf = useRef(null);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state: tableState,
        setSortBy,
    } = useTable(
        {
            columns: state.columns,
            data: state.notes,
            getRowId: (row) => {
                return row.note_id;
            },
            enableRowSelection: true,
            enableMultiRowSelection: true,
            disableSortBy: true,
        },
        useSortBy,
        useRowSelect,
        useSticky,
        useFlexLayout,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: 'selection',
                    sticky: 'left',
                    width: 50,
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                    ),
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox
                            onClick={(e) => e.stopPropagation()}
                            {...row.getToggleRowSelectedProps()}
                        />
                    ),
                },
                ...columns,
            ]);
        }
    );

    useEffect(() => {
        callbacks.selectRows(tableState?.selectedRowIds);
    }, [tableState?.selectedRowIds]);

    const Row = useCallback(
        ({ index, style }) => {
            //HEADERS
            if (index === 0) {
                const [group] = headerGroups;

                return (
                    <div {...group.getHeaderGroupProps({ style })}>
                        {group.headers.reduce(
                            (acc, subgroup) => [
                                ...acc,
                                subgroup.headers.map((col) => {
                                    return (
                                        <HeaderCell
                                            {...col.getHeaderProps()}
                                            key={col.id}
                                            cell={col}
                                            align={col.align}
                                        />
                                    );
                                }),
                            ],
                            []
                        )}
                    </div>
                );
            }

            //BODY
            const row = rows[index - 1];
            if (row) {
                prepareRow(row);

                return (
                    <BodyRow
                        {...row.getRowProps({
                            style,
                        })}
                        row={row}
                        onClick={() => callbacks.setNoteDetails(row.original?.note_id)}
                    >
                        {row.cells.map((cell) => (
                            <BodyCell
                                {...cell.getCellProps()}
                                cell={cell}
                                align={cell.column.align}
                                css={css`
                                    overflow: hidden;
                                `}
                            />
                        ))}
                    </BodyRow>
                );
            }

            return (
                <BodyRow
                    style={style}
                    css={css`
                        align-items: center;
                        display: flex;
                        justify-content: center;
                    `}
                >
                    <CircularProgress
                        size={24}
                        css={css`
                            color: ${colors.greys[4]};
                        `}
                    />
                </BodyRow>
            );
        },
        [prepareRow, rows]
    );

    return (
        <TableContainer
            css={css`
                height: 100%;
                overflow: hidden;
                background: #fff;
            `}
        >
            <div
                {...getTableProps()}
                css={css`
                    height: 100%;
                    display: flex;
                    flex-grow: 1;
                    flex-direction: column;
                `}
            >
                <div
                    {...getTableBodyProps()}
                    css={css`
                        display: flex;
                        flex-grow: 1;
                    `}
                >
                    <InfiniteLoader
                        ref={inf}
                        itemCount={state.hasMore ? rows.length + 2 : rows.length + 1}
                        threshold={50}
                        isItemLoaded={(idx) => {
                            return idx < state.notes?.length || 0;
                        }}
                        loadMoreItems={(start, stop) => {
                            if (!loading.init && state.hasMore) {
                                // callbacks.loadMore();
                            }
                        }}
                    >
                        {({ onItemsRendered, ref }) => {
                            return (
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <FixedSizeList
                                            ref={ref}
                                            onItemsRendered={onItemsRendered}
                                            height={height}
                                            itemCount={state.hasMore ? rows.length + 2 : rows.length + 1}
                                            itemSize={57}
                                            width={width}
                                        >
                                            {Row}
                                        </FixedSizeList>
                                    )}
                                </AutoSizer>
                            );
                        }}
                    </InfiniteLoader>
                </div>
            </div>
        </TableContainer>
    );
};

export default Table;
