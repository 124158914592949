import {
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Popover,
    Select,
    TextField,
    Typography,
    Grid,
} from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import React, { useMemo, useState } from 'react';
import { PopoverContainer, PopoverRow } from './blocks';
import { css } from '@emotion/react';
import { LOCATION_TYPES } from '@/constants/locationTypes';
import { colors } from '@/styles';
import STATES from '@/constants/states.json';
import { PrimaryButton } from '@/styles/blocks';
import { Autocomplete } from '@material-ui/lab';
import { OnwardChip } from '@/styles/blocks';

const FilterPopover = ({
    shippers,
    shipperIds,
    setShipperIds,
    carriers,
    carrierIds,
    setCarrierIds,
    states,
    setStates,
    deliveryTypes,
    setDeliveryTypes,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setCompletedStart,
    setCompletedEnd,
    completedStart,
    completedEnd,
    tab,
    paymentType,
    setPaymentType,
    warehouseStartDate,
    warehouseEndDate,
    setWarehouseEndDate,
    setWarehouseStartDate,
    schedDelStart,
    setSchedDelStart,
    schedDelEnd,
    setSchedDelEnd,
    estShipStart,
    setEstShipStart,
    estShipEnd,
    setEstShipEnd,
    actualReceivedStart,
    setActualReceivedStart,
    actualReceivedEnd,
    setActualReceivedEnd,
    warehouseStatus,
    setWarehouseStatus,
    orderType,
    setOrderType,
}) => {
    const [anchor, setAnchor] = useState(null);

    const handleDeliveryTypeFiltersChange = (val) => {
        setDeliveryTypes(typeof val === 'string' ? val.split(',') : val);
    };

    const handleStateFiltersChange = (val) => {
        setStates(typeof val === 'string' ? val.split(',') : val);
    };

    const shipperOptions = useMemo(() => {
        return shippers.map((shipper) => {
            return {
                label: shipper.business_name,
                value: shipper.client_id,
                selected: shipperIds.includes(shipper.client_id),
            };
        });
    }, [shippers, shipperIds]);

    const carrierOptions = useMemo(() => {
        return carriers.map((carrier) => {
            return {
                label: carrier.business_name,
                value: carrier.client_id,
                selected: carrierIds.includes(carrier.client_id),
            };
        });
    }, [carriers, carrierIds]);

    const clear = () => {
        setShipperIds([]);
        setCarrierIds([]);
        setDeliveryTypes([]);
        setStates([]);
        setStartDate(null);
        setEndDate(null);
        setCompletedEnd(null);
        setCompletedStart(null);
        setPaymentType(null);
        setWarehouseStatus(null);
        setOrderType(null);
        setWarehouseStartDate(null);
        setWarehouseEndDate(null);
        setSchedDelStart(null);
        setSchedDelEnd(null);
        setEstShipStart(null);
        setEstShipStart(null);
        setActualReceivedStart(null);
        setActualReceivedEnd(null);
    };

    const wh_statuses = [
        { value: 'RECEIVED', label: 'Received' },
        { value: 'NOT_DELIVERED', label: 'Not Received' },
    ];

    const order_types = [
        { value: 'delivery', label: 'Delivery' },
        { value: 'return', label: 'Return' },
        { value: 'exchange', label: 'Exchange' },
    ];

    const PopoverSelect = ({ label, value, onChange, options = [] }) => {
        return (
            <>
                <Grid item xs={12}>
                    <Typography>{label}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        select
                        style={{ width: '300px', maxHeight: '50px' }}
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                    >
                        {options.map((option) => (
                            <MenuItem key={option.label} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </>
        );
    };

    return (
        <span
            css={css`
                margin-left: 1rem;
            `}
        >
            <Button variant="contained" color="primary" onClick={(e) => setAnchor(e.currentTarget)}>
                <FilterList
                    css={css`
                        color: white;
                    `}
                />
            </Button>
            <Popover
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <PopoverContainer>
                    <PopoverRow>
                        <FormControl style={{ marginLeft: '0', marginRight: '1rem' }}>
                            <Autocomplete
                                id="shipper-id-autocomplete"
                                multiple
                                fullWidth
                                disableCloseOnSelect
                                value={shipperOptions.filter((shipper) => shipper.selected)}
                                options={shipperOptions}
                                getOptionSelected={(option, value) => {
                                    return option.value === value.value;
                                }}
                                getOptionLabel={(option) => option.label}
                                renderTags={(options, getTagProps) => {
                                    return options.map((option, index) => (
                                        <OnwardChip
                                            key={option.value}
                                            label={option.label}
                                            {...getTagProps({ index })}
                                            {...(option.disabled ? { onDelete: null } : {})}
                                            css={css`
                                                color: ${option.disabled ? '#8d8d8d' : 'black'};
                                            `}
                                        />
                                    ));
                                }}
                                renderOption={(option, { selected }) => {
                                    return (
                                        <li key={option.value}>
                                            <Checkbox color="primary" checked={selected} />
                                            {option.label}
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Shippers"
                                        onKeyDown={(e) => {
                                            if (['Backspace'].includes(e.key)) {
                                                e.stopPropagation();
                                            }
                                        }}
                                    />
                                )}
                                onChange={(e, value = []) => {
                                    const _selectedShippers = value.map((option) => option.value);
                                    setShipperIds(_selectedShippers);
                                }}
                            />
                        </FormControl>
                        <FormControl style={{ marginLeft: '0', marginRight: '1rem' }}>
                            <Autocomplete
                                id="carrier-id-autocomplete"
                                multiple
                                disableCloseOnSelect
                                value={carrierOptions.filter((carrier) => carrier.selected)}
                                options={carrierOptions}
                                getOptionSelected={(option, value) => {
                                    return option.value === value.value;
                                }}
                                getOptionLabel={(option) => option.label}
                                renderTags={(options, getTagProps) => {
                                    return options.map((option, index) => (
                                        <OnwardChip
                                            key={option.value}
                                            label={option.label}
                                            {...getTagProps({ index })}
                                            {...(option.disabled ? { onDelete: null } : {})}
                                            css={css`
                                                color: ${option.disabled ? '#8d8d8d' : 'black'};
                                            `}
                                        />
                                    ));
                                }}
                                renderOption={(option, { selected }) => {
                                    return (
                                        <li key={option.value}>
                                            <Checkbox color="primary" checked={selected} />
                                            {option.label}
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Carriers"
                                        onKeyDown={(e) => {
                                            if (['Backspace'].includes(e.key)) {
                                                e.stopPropagation();
                                            }
                                        }}
                                    />
                                )}
                                onChange={(e, value = []) => {
                                    const _selectedCarriers = value.map((option) => option.value);
                                    setCarrierIds(_selectedCarriers);
                                }}
                            />
                        </FormControl>
                    </PopoverRow>
                    <PopoverRow>
                        <FormControl>
                            <InputLabel id="delivery-type-select-label">Delivery Type(s)</InputLabel>
                            <Select
                                labelId="delivery-type-select-label"
                                id="delivery-type-select"
                                multiple
                                value={deliveryTypes}
                                onChange={(e) => handleDeliveryTypeFiltersChange(e.target.value)}
                                input={<OutlinedInput label="Delivery Type(s)" />}
                                renderValue={(selected) => selected.map((type) => LOCATION_TYPES[type]).join(', ')}
                                MenuProps={{ PaperProps: { style: { maxHeight: '50%' } } }}
                            >
                                {Object.entries(LOCATION_TYPES).map(([type, label]) => (
                                    <MenuItem
                                        key={`delivery-type-filter-${type}`}
                                        value={type}
                                        css={css`
                                            & .Mui-checked {
                                                color: ${colors.greens.primary};
                                            }
                                        `}
                                    >
                                        <Checkbox checked={deliveryTypes.indexOf(type) > -1} />
                                        <ListItemText primary={label} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            {' '}
                            <InputLabel id="state-filter-select-label">State(s)</InputLabel>
                            <Select
                                labelId="state-filter-select-label"
                                id="state-filter-select"
                                multiple
                                value={states}
                                onChange={(e) => handleStateFiltersChange(e.target.value)}
                                input={<OutlinedInput label="State(s)" />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={{ PaperProps: { style: { maxHeight: '50%' } } }}
                            >
                                {Object.entries(STATES).map(([stateCode, state]) => (
                                    <MenuItem
                                        key={`state-filter-${stateCode}`}
                                        value={stateCode}
                                        css={css`
                                            & .Mui-checked {
                                                color: ${colors.greens.primary};
                                            }
                                        `}
                                    >
                                        <Checkbox checked={states.indexOf(stateCode) > -1} />
                                        <ListItemText primary={state} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </PopoverRow>
                    <PopoverRow>
                        <Typography
                            css={css`
                                margin-left: 10px;
                            `}
                        >
                            Order Created
                        </Typography>
                    </PopoverRow>
                    <PopoverRow>
                        <TextField
                            type="date"
                            variant="outlined"
                            size="small"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                        <Typography> to </Typography>
                        <TextField
                            type="date"
                            variant="outlined"
                            size="small"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </PopoverRow>
                    <PopoverRow>
                        <Typography
                            css={css`
                                margin-left: 10px;
                            `}
                        >
                            Order Completed
                        </Typography>
                    </PopoverRow>
                    <PopoverRow>
                        <TextField
                            type="date"
                            variant="outlined"
                            size="small"
                            value={completedStart}
                            onChange={(e) => setCompletedStart(e.target.value)}
                        />
                        <Typography> to </Typography>
                        <TextField
                            type="date"
                            variant="outlined"
                            size="small"
                            value={completedEnd}
                            onChange={(e) => setCompletedEnd(e.target.value)}
                        />
                    </PopoverRow>

                    <Grid
                        container
                        xs={12}
                        className="pb-3"
                        css={css`
                            padding: 0 0.5rem;
                        `}
                        wrap="nowrap"
                    >
                        <Grid item className="me-3">
                            <PopoverSelect
                                label="Order Type"
                                value={orderType}
                                onChange={(e) => setOrderType(e.target.value)}
                                options={order_types || []}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        xs={12}
                        className="pb-3"
                        css={css`
                            padding: 0 0.5rem;
                        `}
                        justifyContent="flex-start"
                    >
                        <Grid item xs={12}>
                            <Typography>Estimated Shipment Date</Typography>
                        </Grid>
                        <Grid item className="pr-3">
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={estShipStart}
                                onChange={(e) => setEstShipStart(e.target.value)}
                            />
                        </Grid>
                        <Grid container className="pr-3 h100" style={{ width: 'auto' }} alignItems="center">
                            <Typography style={{ height: 'fit-content' }}>-</Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={estShipEnd}
                                onChange={(e) => setEstShipEnd(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        xs={12}
                        className="pb-3"
                        css={css`
                            padding: 0 0.5rem;
                        `}
                        justifyContent="flex-start"
                    >
                        <Grid item xs={12}>
                            <Typography>Estimated Warehouse Received Date</Typography>
                        </Grid>
                        <Grid item className="pr-3">
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={warehouseStartDate}
                                onChange={(e) => setWarehouseStartDate(e.target.value)}
                            />
                        </Grid>
                        <Grid container className="pr-3 h100" style={{ width: 'auto' }} alignItems="center">
                            <Typography style={{ height: 'fit-content' }}>-</Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={warehouseEndDate}
                                onChange={(e) => setWarehouseEndDate(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        xs={12}
                        className="pb-3"
                        css={css`
                            padding: 0 0.5rem;
                        `}
                        justifyContent="flex-start"
                    >
                        <Grid item xs={12}>
                            <Typography>Actual Received Date</Typography>
                        </Grid>
                        <Grid item className="pr-3">
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={actualReceivedStart}
                                onChange={(e) => setActualReceivedStart(e.target.value)}
                            />
                        </Grid>
                        <Grid container className="pr-3 h100" style={{ width: 'auto' }} alignItems="center">
                            <Typography style={{ height: 'fit-content' }}>-</Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={actualReceivedEnd}
                                onChange={(e) => setActualReceivedEnd(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        xs={12}
                        className="pb-3"
                        css={css`
                            padding: 0 0.5rem;
                        `}
                        wrap="nowrap"
                    >
                        <Grid item className="me-3">
                            <PopoverSelect
                                label="Warehouse Status"
                                value={warehouseStatus}
                                onChange={(e) => setWarehouseStatus(e.target.value)}
                                options={wh_statuses || []}
                            />
                        </Grid>
                    </Grid>
                    {tab === 10 && (
                        <>
                            <PopoverRow>
                                <FormControl>
                                    <InputLabel id="payment-type-label">Payment Type</InputLabel>
                                    <Select
                                        labelId="payment-type-label"
                                        id="payment-type"
                                        variant="outlined"
                                        value={paymentType || ''}
                                        onChange={(e) => setPaymentType(e.target.value)}
                                        MenuProps={{ PaperProps: { style: { maxHeight: '50%' } } }}
                                    >
                                        <MenuItem key="payment-type-null" value={''}>
                                            ALL
                                        </MenuItem>
                                        <MenuItem key={`payment-type-cc`} value={'Credit Card'}>
                                            Credit Card
                                        </MenuItem>
                                        <MenuItem key={`payment-type-invoice`} value={'Invoice'}>
                                            Invoice
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </PopoverRow>
                        </>
                    )}
                    <PopoverRow>
                        <PrimaryButton
                            onClick={clear}
                            css={css`
                                width: 100%;
                                margin: 0.5rem;
                            `}
                        >
                            Clear All
                        </PrimaryButton>
                    </PopoverRow>
                </PopoverContainer>
            </Popover>
        </span>
    );
};

export default FilterPopover;
