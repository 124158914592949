import React from 'react';
import { Grid } from '@material-ui/core';
import styled from '@emotion/styled';
import ExceptionCard from '../ExceptionCard';
import { css } from '@emotion/react';
import UploadItemPic from '@/components/Customer/ShipmentForm/UploadItemPic';
import { useMutation } from '@apollo/client';
import { UPDATE_ITEM_BY_ID } from '@/graphql/mutations/items';
import { PrimaryButton } from '@/styles/blocks';

const weightFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

const Wrapper = styled((props) => <Grid {...props} />)`
    &:last-child {
        border-bottom-width: 0;
    }

    padding: 10px 0;
    border-bottom: 1px solid #e2e2e2;
`;

const StyleSpan = styled.span`
    font-family: Montserrat, Roboto, Arial;
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
`;

const ItemDescription = styled.span`
    font-weight: 700;
`;
const ItemDetail = styled.span`
    font-weight: 500;
    font-size: 14px;
`;

function SingleOrderItem({ item, order, setExceptionParams, setResolveExceptionParams }) {
    let itemDesc = item?.description || 'Description Not Provided';
    let dimensions = item?.length ? `${item.length}L ${item.width}W ${item.height}H` : 'N/A';
    let weight = item?.weight ? `${weightFormatter.format(item.weight)}lbs` : 'N/A';

    const [uploadPic] = useMutation(UPDATE_ITEM_BY_ID);

    const addPic = (fileUrl) => {
        uploadPic({
            variables: {
                item_id: item.item_id,
                update: {
                    pic: fileUrl,
                },
            },
        });
    };

    return (
        <Wrapper
            container
            css={css`
                flex-direction: column;
            `}
        >
            <Grid container sm={12} direction="row" className="justify-content-around">
                <Grid item sm={12} className="d-flex align-items-center">
                    <StyleSpan variant="body2">
                        <ItemDescription>{itemDesc}</ItemDescription>
                    </StyleSpan>
                </Grid>
                <Grid item sm={12} className="d-flex align-items-center">
                    <ItemDetail>Quantity: {item.quantity}</ItemDetail>
                </Grid>
                <Grid item sm={12} className="d-flex align-items-center">
                    <ItemDetail>Total Cubes: {item.total_cubes ? item.total_cubes.toFixed(1) : 'N/A'}</ItemDetail>
                </Grid>
                <Grid item sm={12} className="d-flex align-items-center">
                    <ItemDetail>Dimensions: {dimensions}</ItemDetail>
                </Grid>
                <Grid item sm={12} className="d-flex align-items-center">
                    <ItemDetail>Weight: {weight}</ItemDetail>
                </Grid>
                {item.sku && (
                    <Grid item sm={12} className="d-flex align-items-center">
                        <ItemDetail>SKU: {item.sku}</ItemDetail>
                    </Grid>
                )}
            </Grid>
            <Grid container direction="row" md={4}>
                <UploadItemPic
                    item={item}
                    addPic={addPic}
                    css={css`
                        width: 100%;
                    `}
                />
            </Grid>

            {item?.item_exception ? (
                <ExceptionCard
                    exception={item.item_exception}
                    exceptionItem={item}
                    callbacks={{
                        setExceptionParams,
                        setResolveExceptionParams,
                    }}
                    order={order}
                    css={css`
                        margin-top: 20px;
                    `}
                />
            ) : null}
        </Wrapper>
    );
}

export default SingleOrderItem;
