import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { TextField, MenuItem, Grid } from '@material-ui/core';
import { Body1, LocationPermissionsTitleSpan, TabCard } from '../blocks';
import Snackbar from '../../Snackbar';
import { Body, PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { getTariffName } from '../Tariffs/utils';
import { OnwardToggle } from '../../ShipmentForm/blocks';
import { colors } from '@/styles';
import ProfileImageUpload from '../ProfileImageUpload';
import StickyFooter from '../../StickyFooter';

export const EditShippingPartner = ({ shipper, tariffs, overrides, callbacks, disabled, notification }) => {
    const [overrideUpdates, updateOverride] = useState({});
    const [alias, setAlias] = useState(null);
    const [partnershipProfileImageVariables, setPartnershipProfileImageVariables] = useState(null);

    const tariffsById = useMemo(() => {
        return Object.fromEntries((tariffs || []).map((tariff) => [tariff.tariff_id, tariff]));
    }, [tariffs]);

    const override = useMemo(() => {
        return (
            overrides.find(
                (o) => o.oms && o.partner_client_id === shipper.shipper_id && o.retailer_id == shipper.retailer_id
            ) || {}
        );
    }, [overrides, shipper]);

    const updatedOverride = useMemo(() => {
        return {
            tariff_id: overrideUpdates.tariff_id || override.tariff_id,
            add_fuel_surcharge: Boolean(overrideUpdates.add_fuel_surcharge ?? override.add_fuel_surcharge),
        };
    }, [override, overrideUpdates]);

    const shipperName = useMemo(() => {
        return alias || shipper.shipper_alias || shipper.shipper.business_name;
    }, [alias, shipper]);

    const tariff = useMemo(() => {
        return tariffsById[updatedOverride.tariff_id];
    }, [tariffsById, updatedOverride]);

    const modified = Object.keys(overrideUpdates).length > 0 || !!alias;

    const statusColors = {
        PENDING: colors.golds.primary,
        APPROVED: colors.greens.primary,
        DENIED: colors.reds.primary,
    };

    const statusLabels = {
        PENDING: 'Invite Pending',
        APPROVED: 'Connected',
        DENIED: 'Not Connected',
    };

    return (
        <>
            <TabCard>
                <Snackbar
                    open={notification.message}
                    handleClose={() => callbacks.setNotification({})}
                    {...notification}
                />
                <Grid
                    container
                    direction="column"
                    css={css`
                        padding: 1rem 2rem;
                        border-bottom: 1px solid ${colors.greys.border};
                    `}
                >
                    <LocationPermissionsTitleSpan>Partnership Info</LocationPermissionsTitleSpan>
                    <Grid
                        item
                        css={css`
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            margin-bottom: 4px;
                            margin-top: 1rem;
                        `}
                    >
                        <Body
                            css={css`
                                font-weight: 700;
                                font-size: 14px;
                                color: ${statusColors[shipper.status] || 'black'};
                                width: 150px;
                            `}
                        >
                            {statusLabels[shipper.status] || 'Status Unknown'}
                        </Body>
                        <Body
                            css={css`
                                font-weight: 500;
                                font-size: 14px;
                                color: #8d8d8d;
                            `}
                        >
                            Network ID: {shipper.shipper.client_id}
                            {shipper.retailer ? ` / Retailer: ${shipper.retailer.name}` : ''}
                        </Body>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            height: 74px;
                        `}
                    >
                        <Grid
                            item
                            css={css`
                                width: 400px;
                                margin-right: 12px;
                            `}
                        >
                            <TextField
                                variant="outlined"
                                label="Shipper Name"
                                value={shipperName}
                                onChange={(e) => setAlias(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Body>
                        Address:{' '}
                        {shipper?.shipper?.business_address
                            ? [
                                  shipper.shipper.business_address,
                                  shipper.shipper.business_address_unit,
                                  shipper.shipper.business_city,
                                  shipper.shipper.business_state,
                                  shipper.shipper.business_zip,
                              ]
                                  .filter(Boolean)
                                  .join(', ')
                            : 'Not provided'}
                    </Body>
                    <Body>Email: {shipper?.shipper?.email ? shipper.shipper.email : 'Not provided'}</Body>
                    <Body>
                        Phone: {shipper?.shipper?.business_phone ? shipper.shipper.business_phone : 'Not provided'}
                    </Body>
                </Grid>
            </TabCard>
            <ProfileImageUpload
                partnership={shipper}
                partnershipProfileImageVariables={partnershipProfileImageVariables}
                setPartnershipProfileImageVariables={setPartnershipProfileImageVariables}
            />
            <TabCard
                css={css`
                    margin-top: 2rem;
                `}
            >
                <Grid
                    container
                    direction="column"
                    css={css`
                        padding: 1rem 2rem;
                        border-bottom: 1px solid ${colors.greys.border};
                    `}
                >
                    <LocationPermissionsTitleSpan>Tariff</LocationPermissionsTitleSpan>
                    <Grid
                        item
                        css={css`
                            width: 300px;
                            margin-top: 1rem;
                        `}
                    >
                        <TextField
                            select
                            variant="outlined"
                            label="Tariff"
                            value={updatedOverride?.tariff_id || ''}
                            onChange={(e) => updateOverride((prev) => ({ ...prev, tariff_id: e.target.value }))}
                            fullWidth
                        >
                            {tariffs.map((tariff, idx) => (
                                <MenuItem key={tariff.tariff_id} value={tariff.tariff_id}>
                                    {getTariffName(tariff, idx)}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            display: flex;
                            align-items: center;
                            flex-wrap: nowrap;
                            justify-content: flex-start;
                            margin-top: 1rem;
                        `}
                    >
                        <OnwardToggle
                            value={updatedOverride.add_fuel_surcharge}
                            onChange={(e) =>
                                updateOverride((prev) => ({ ...prev, add_fuel_surcharge: e.target.checked }))
                            }
                        />
                        <Body1
                            css={css`
                                margin-left: 12px;
                            `}
                        >
                            Add Fuel Surcharge
                        </Body1>
                    </Grid>
                </Grid>
            </TabCard>
            <StickyFooter
                styles={{
                    paddingLeft: '-10px',
                    boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.25)',
                }}
            >
                <Grid
                    container
                    direction="row"
                    css={css`
                        justify-content: space-between;
                    `}
                >
                    <Grid item>
                        <SecondaryButton
                            css={css`
                                margin-left: 15px;
                            `}
                            onClick={() => callbacks.setEditingShipper(null)}
                        >
                            Back
                        </SecondaryButton>
                    </Grid>

                    <Grid item>
                        <PrimaryButton
                            onClick={() =>
                                callbacks
                                    .onSave(
                                        {
                                            pricing_override_id: override.pricing_override_id,
                                            partner_client_id: shipper.shipper.client_id,
                                            retailer_id: shipper.retailer_id || null,
                                            tariff_id: tariff?.tariff_id || null,
                                            algo_type: tariff?.algo_type || 'DEFAULT',
                                            add_fuel_surcharge: !!updatedOverride.add_fuel_surcharge,
                                        },
                                        {
                                            partnership_id: shipper.partnership_id,
                                            shipper_alias: shipperName,
                                            ...partnershipProfileImageVariables,
                                        }
                                    )
                                    .then(() => {
                                        updateOverride({});
                                    })
                            }
                            disabled={false}
                        >
                            Save
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </StickyFooter>
        </>
    );
};
