import React, { useState, useContext, useMemo, useEffect } from 'react';
import { useParams } from 'react-router';
import { compose } from 'recompose';
import { colors } from '@/styles';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import StickyFooter from '@/components/StickyFooter';
import { Grid, TextField, MenuItem } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { OnwardBreadcrumbActive, OnwardBreadcrumbInactive, OnwardBreadcrumbSpacer } from '@/components/Breadcrumbs';
import { PageContent, PageTitle, SectionTitle, PrimaryButton, Body } from '@/styles/blocks';

import { Context, ContextProvider } from './store';
import EditOrderModal from '../modals/EditOrderModal';
import MarketplaceModal from '../modals/MarketplaceModal';
import CreditCardModal from '../modals/CreditCardModal';
import PODuplicateConfirmationModal from '../modals/PODuplicateConfirmationModal';
import { TableHeader } from '../blocks';
import { MODALS } from '../constants';
import Loader from '../Loader';
import { useClientUser } from '@/hooks';
import { BIDDING_DISABLED } from '@/constants/featureFlags';
import AuctionsPopover from './AuctionsPopover';
import ListingModal from '../modals/ListingModal';
import Snackbar from '@/components/Snackbar';
import { isObject } from 'lodash';
import { EDIT_ORDER_TABS } from '../constants';
import { getEditMultiPickupRequiredFields } from '../utilities/requiredFields';
import { genAttributes } from '@onward-delivery/core';
import PickUpGroupCard from '../cards/PickUpGroupCard';

export const percent = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const Status = styled(({ children, ...rest }) => <Grid {...rest}>{children}</Grid>)`
    padding: 8px 16px;
    border-radius: 4px;
    border: 3px solid;

    border-color: ${(prop) => {
        switch (prop.status) {
            case 'PROCESSING':
                return '#007BFF';
            case 'FAILED':
                return '#DC3545';
            case 'COMPLETE':
                return colors.greens.primary;
            case 'PENDING':
                return colors.golds.primary;
            default:
                return colors.greys.primary;
        }
    }};

    color: ${(prop) => {
        switch (prop.status) {
            case 'PROCESSING':
                return '#007BFF';
            case 'FAILED':
                return '#DC3545';
            case 'COMPLETE':
                return colors.greens.primary;
            case 'PENDING':
                return colors.golds.primary;
            default:
                return colors.greys.primary;
        }
    }};
`;

const FILTERS = [
    {
        display: 'All',
        filter: () => true,
    },
    {
        display: 'Failed',
        filter: (o, hasError) => o.order_status === 'incomplete' || hasError(o),
    },
    {
        display: 'Duplicate PO',
        filter: (o) => o.order_status === 'duplicate',
    },
    {
        display: 'Pending Final Review',
        filter: (o) => o.order_status === 'pending_confirmation',
    },
];

const JobDetails = () => {
    const { circles } = useClientUser();
    const { state, errors, callbacks } = useContext(Context);
    const { job_id } = useParams();
    const [filter, setFilterIdx] = useState(0);

    const isInternal = useMemo(() => {
        return state.job?.orders.length > 0 && state.job.orders.every((order) => order.oms);
    }, [state.job]);

    useEffect(() => {
        callbacks.fetchJob({
            variables: {
                job_id,
            },
        });
        callbacks.setListings({});
    }, [job_id]);

    const jobStatus = useMemo(() => {
        if (state.job) {
            switch (state.job?.status) {
                case 'FAILED':
                case 'PROCESSING':
                    return state.job?.status;
                case 'COMPLETE': {
                    const confirmed = state.job.orders.every(
                        (order) =>
                            !['duplicate', 'pending_confirmation', 'processing', 'incomplete'].includes(
                                order.order_status
                            )
                    );

                    if (confirmed) {
                        return 'COMPLETE';
                    }

                    return 'PENDING';
                }
            }
        }

        return null;
    }, [state.job]);

    const isValid = useMemo(() => {
        return (
            jobStatus === 'PENDING' &&
            state.job?.orders.length > 0 &&
            state.job.orders.every((order) => {
                return (
                    order.order_status === 'pending_confirmation' ||
                    (order.order_status === 'duplicate' &&
                        !Object.values(errors.hasErrors?.[order.order_id] || {}).some((x) => x))
                );
            })
        );
    }, [state.job, jobStatus]);

    const errorCount = useMemo(() => {
        return (
            state.job?.orders?.filter(
                (order) =>
                    order.order_status === 'incomplete' ||
                    Object.values(errors.hasErrors?.[order.order_id] || {}).some((x) => x)
            )?.length || 0
        );
    }, [state.job]);

    const filtered = useMemo(() => {
        return (state.job?.orders || []).filter((o) =>
            FILTERS[filter].filter(o, (o) => Object.values(errors.hasErrors?.[o.order_id] || {}).some((x) => x))
        );
    }, [state.job, filter, errors]);

    const groupedByPickup = useMemo(() => {
        return filtered.reduce((acc, order) => {
            const { address: pu_address } = genAttributes(order, true);
            const address = order[pu_address] ? order[pu_address] : '';
            return {
                ...acc,
                [address]: [...(acc[address] || []), order],
            };
        }, {});
    }, [filtered]);

    let content = <Loader />;
    if (Object.keys(groupedByPickup).length > 0) {
        content = (
            <>
                {Object.entries(groupedByPickup).map(([pu_address, pu_orders]) => (
                    <Grid item key={pu_address}>
                        <PickUpGroupCard
                            address={pu_address}
                            orders={pu_orders}
                            overrides={state.overrides}
                            listings={state.listings}
                            clientLocations={state.clientLocations}
                            validation={{
                                hasError: (order) => {
                                    return Object.values(errors.hasErrors?.[order.order_id] || {}).some((x) => x);
                                },
                                hasErrorTab: (order, tab) => {
                                    return errors.hasErrors?.[order.order_id]?.[tab];
                                },
                            }}
                            callbacks={callbacks}
                        />
                    </Grid>
                ))}
            </>
        );
    } else if (jobStatus !== 'PROCESSING') {
        content = null;
    }

    let status = null;
    switch (jobStatus) {
        case 'PROCESSING': {
            status = (
                <span>
                    Processing
                    {` - ${percent.format(state?.job?.batches_complete / state?.job?.batches_total)} complete`}
                </span>
            );
            break;
        }
        case 'COMPLETE':
            status = 'Complete';
            break;
        case 'FAILED':
            status = 'Failed';
            break;
        case 'PENDING':
            status = 'Pending Final Review';
            break;
        default:
            status = 'Loading';
            break;
    }

    return (
        <>
            <Grid
                container
                direction="row"
                css={css`
                    justify-content: flex-end;
                    align-items: center;
                    background: white;
                    padding: 12px 30px;
                    box-shadow: 0px 0px 0px 1px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
                `}
            >
                <Grid
                    container
                    direction="row"
                    css={css`
                        justify-content: space-between;
                        align-items: center;
                    `}
                >
                    <Grid item direction="column">
                        <Grid
                            item
                            container
                            direction="row"
                            css={css`
                                align-items: center;
                            `}
                        >
                            <OnwardBreadcrumbInactive disabled>1. Create</OnwardBreadcrumbInactive>
                            <OnwardBreadcrumbSpacer />
                            <OnwardBreadcrumbActive>2. Review</OnwardBreadcrumbActive>
                        </Grid>
                    </Grid>

                    <Grid item direction="column">
                        <Grid
                            container
                            direction="row"
                            css={css`
                                justify-content: flex-end;
                                align-items: center;
                            `}
                        >
                            <Grid
                                item
                                direction="column"
                                css={css`
                                    margin-right: 2rem;
                                `}
                            >
                                {errorCount > 0 && (
                                    <TableHeader
                                        css={css`
                                            color: #d23e3e;
                                            margin-right: 32px;
                                        `}
                                    >
                                        Errors: {errorCount}
                                    </TableHeader>
                                )}
                                <TableHeader
                                    css={css`
                                        color: #8d8d8d;
                                    `}
                                >
                                    Orders: {state.job?.orders?.length}
                                </TableHeader>
                            </Grid>
                            <Status item direction="column" status={jobStatus}>
                                <TableHeader>{status}</TableHeader>
                            </Status>
                            {isValid && !isInternal && !circles?.[BIDDING_DISABLED] && (
                                <AuctionsPopover
                                    css={css`
                                        margin-left: 2rem;
                                    `}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <PageContent>
                <Grid
                    direction="row"
                    container
                    css={css`
                        align-items: center;
                        justify-content: space-between;
                        flex-basis: 0;
                    `}
                >
                    <Grid direction="column" item>
                        <PageTitle
                            css={css`
                                margin: 0;
                            `}
                        >
                            Review Order
                        </PageTitle>
                    </Grid>
                    <Grid direction="column" item>
                        <TextField
                            select
                            variant="outlined"
                            value={filter}
                            onChange={(e) => {
                                setFilterIdx(e.target.value);
                            }}
                            InputLabelProps={{
                                shrink: filter !== null,
                            }}
                            css={css`
                                width: 200px;
                            `}
                        >
                            {FILTERS.map((filter, idx) => (
                                <MenuItem key={idx} value={idx}>
                                    {filter.display}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </PageContent>

            <PageContent
                css={css`
                    margin-bottom: 100px;
                `}
            >
                {content}
            </PageContent>

            <CreditCardModal
                handleClose={callbacks.revert}
                onSuccess={callbacks.advance}
                open={state.current === MODALS.CREDIT_CARD}
            />
            <MarketplaceModal
                open={state.current === MODALS.MARKETPLACE}
                onClose={callbacks.revert}
                onSubmit={callbacks.advance}
                orders={state.job?.orders || []}
                listings={state.listings}
            />
            <EditOrderModal
                order={state.editing?.order}
                open={!!state.editing}
                initTab={state.editing?.tab}
                opt={{
                    disableGeocoding: true,
                    disableDistancingAndPricing: true,
                    readOnly: !state.editing?.order.oms && !!state.editing?.order.carrier_invoice_id,
                    enableNotesTab: true,
                }}
                callbacks={{
                    onSave: ({ order, items, tags, documents }) => {
                        callbacks.submitOrder({ ...order, itemsByOrderId: items, tags, documents });
                        callbacks.closeEditModal();
                    },
                    onClose: () => {
                        callbacks.closeEditModal();
                    },
                    onError: (e) => {
                        callbacks.setErrorMsg(e);
                    },
                }}
            />
            <EditOrderModal
                open={!!state.editingPickup}
                order={state.editingPickup?.orders?.[0]}
                tabs={[EDIT_ORDER_TABS.PICKUP]}
                initTab={EDIT_ORDER_TABS.PICKUP}
                requiredAttributes={getEditMultiPickupRequiredFields}
                opt={{
                    title: 'Pickup / Return Warehouse Information',
                    disableGeocoding: true,
                    disableDistancingAndPricing: true,
                    enablePickupDateEditing: true,
                    readOnly: !state.editingPickup?.oms && !!state.editingPickup?.carrier_invoice_id,
                    isInternal: isInternal,
                }}
                callbacks={{
                    onSave: ({ order: modified }) => {
                        const modifiedPickupFields = genAttributes(modified, true);
                        const modifiedGenericFields = [
                            'carrier_id',
                            'delivery_date',
                            'first_available_date',
                            'pickup_date',
                            'pickup_email',
                            'pickup_name',
                            'pickup_phone',
                            'pickup_window_end',
                            'shipper_id',
                        ];

                        callbacks.submitOrders(
                            state.editingPickup?.orders?.map((order) => {
                                const orderPickupFields = genAttributes(order, true);

                                return {
                                    ...order,
                                    ...Object.fromEntries(
                                        Object.keys(modifiedPickupFields).map((field) => [
                                            orderPickupFields[field],
                                            modified[modifiedPickupFields[field]],
                                        ])
                                    ),
                                    ...Object.fromEntries(
                                        modifiedGenericFields.map((field) => [field, modified[field]])
                                    ),
                                };
                            })
                        );
                        callbacks.closeEditPickupModal();
                    },
                    onClose: () => {
                        callbacks.closeEditPickupModal();
                    },
                    onError: (e) => {
                        callbacks.setErrorMsg(e);
                    },
                }}
            />
            <ListingModal
                listing={state.editingListing?.listing}
                order={state.editingListing?.order}
                open={isObject(state.editingListing)}
                callbacks={{
                    onSave: ({ listing }) => {
                        callbacks.setListings((prev) => ({ ...prev, [listing.order_id]: listing }));
                        callbacks.editListing(null);
                    },
                    onClose: () => {
                        callbacks.editListing(null);
                    },
                }}
            />
            <PODuplicateConfirmationModal
                open={state.current === MODALS.PO_DUPLICATE_CONFIRMATION}
                count={state?.job?.orders.filter((order) => order.order_status === 'duplicate').length || 0}
                dupes={state?.job?.orders.filter((order) => order.order_status === 'duplicate')}
                callbacks={{
                    onDelete: () => {
                        callbacks.clearDuplicates();
                    },
                    onSubmit: () => {
                        callbacks.overrideDuplicates();
                    },
                    onClose: () => {
                        callbacks.revert();
                    },
                }}
            />
            <Snackbar
                open={!!state.notification.message}
                {...state.notification}
                handleClose={callbacks.clearNotification}
            />

            <StickyFooter
                styles={{
                    margin: 0,
                    boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.25)',
                }}
            >
                <Grid
                    container
                    direction="row"
                    css={css`
                        justify-content: flex-end;
                    `}
                >
                    <Grid>
                        <PrimaryButton
                            css={css`
                                width: auto;
                            `}
                            onClick={callbacks.confirmOrders}
                            disabled={!isValid || state.isSubmitting}
                        >
                            {isInternal
                                ? 'Confirm & Submit to OMS'
                                : circles?.['saas-v1']
                                ? 'Confirm & Submit to Marketplace'
                                : 'Confirm & Submit'}
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </StickyFooter>
        </>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} />
        </ContextProvider>
    );

export default compose(withContext)(JobDetails);
