import React, { useEffect, useState } from 'react';

import { Grid, Typography } from '@material-ui/core';

import './style.css';
import UploadedFilesCard from '../UploadedFilesCard';
import orderStatusStrings from '@/constants/orderStatusStrings';
import CustomerSignature from '../CustomerSignature';
import SuperimposeSignatureModal from '../modals/SuperimposeSignatureModal';
import FileViewingCard from '../FileViewingCard';
function OrderImages(props) {
    const { order, onImgView, userType, editable, loadOrder } = props;

    return (
        <Grid container className="overflow-auto h-100">
            <Grid item sm={1}></Grid>
            <Grid container sm={10}>
                <Grid item sm={12} className="d-flex justify-content-between align-items-center mt-4">
                    <Typography variant="h1">{order.order_number}</Typography>
                    <Typography variant="subtitle2">{orderStatusStrings[order.order_status]}</Typography>
                </Grid>

                <UploadedFilesCard
                    loadOrder={loadOrder}
                    order={order}
                    firebase={props.firebase}
                    editable={editable}
                    userType={userType}
                    category="Warehouse"
                />
                {(order?.documents || []).some(docMapping => docMapping?.document?.type === 'Assembly') && (
                    <FileViewingCard
                        images={(order?.documents || []).filter(docMapping => docMapping?.document?.type === 'Assembly')}
                        category={"Assembly Instructions"}
                        order={order}
                    />
                )}
                <Grid item sm={12}>
                    <UploadedFilesCard
                        loadOrder={loadOrder}
                        order={order}
                        firebase={props.firebase}
                        editable={userType === 'shipper' || userType === 'admin'}
                        userType={userType}
                        category="BOL"
                    />
                    {order?.bol_file?.length && order?.customer_signature ? (
                        <SuperimposeSignatureModal order={order} editable={editable} />
                    ) : null}
                </Grid>

                <UploadedFilesCard
                    loadOrder={loadOrder}
                    order={order}
                    firebase={props.firebase}
                    editable={userType === 'shipper' || userType === 'admin'}
                    userType={userType}
                    category="Waybill"
                />
                <UploadedFilesCard
                    loadOrder={loadOrder}
                    order={order}
                    firebase={props.firebase}
                    editable={userType === 'shipper' || userType === 'admin'}
                    userType={userType}
                    category="Pickup Notification"
                />

                <UploadedFilesCard
                    loadOrder={loadOrder}
                    order={order}
                    firebase={props.firebase}
                    editable={editable}
                    userType={userType}
                    category="Proof of Pickup"
                />
                <UploadedFilesCard
                    loadOrder={loadOrder}
                    order={order}
                    firebase={props.firebase}
                    editable={editable}
                    userType={userType}
                    category="POD"
                />
                <UploadedFilesCard
                    loadOrder={loadOrder}
                    order={order}
                    firebase={props.firebase}
                    editable={editable}
                    userType={userType}
                    category="Final Delivery Photos"
                />
                <CustomerSignature order={order} firebase={props.firebase} editable={editable} />
            </Grid>
            <Grid item sm={1}></Grid>
        </Grid>
    );
}

export default OrderImages;
