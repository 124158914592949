import React, { useEffect, useState, useContext } from 'react';
import { useMutation, gql } from '@apollo/client';
import {
    Typography,
    Grid,
    Button,
    createTheme,
    ThemeProvider,
    Dialog,
    DialogActions,
    DialogTitle,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';

import Image from 'react-bootstrap/Image';
import getOrdersFilesAsPDF from '../../utilities/getOrdersFilesAsPDF';
import { OnwardCard } from './Card';

import { FileEarmarkPdf } from 'react-bootstrap-icons';
import { saveAs } from 'file-saver';

function FileViewingCard({ category, images, order }) {

    async function handleDownload() {
        const pdfDoc = await getOrdersFilesAsPDF({
            ...order,
            assembly_images: images.map(docMapping => {
                let doc = docMapping.document;

                return {
                    ...doc,
                    url: doc.link,
                }
            })
        }, 'assembly_images');

        if (pdfDoc) {
            saveAs(await pdfDoc.saveAsBase64({ dataUri: true }), order.order_number + ' - ' + category);
        }
    }

    const theme = createTheme({
        palette: {
            text: {
                secondary: '#4c4c4c',
            },
            primary: {
                main: '#59b863',
                contrastText: '#fff',
            },
            secondary: {
                main: '#4c4c4c',
                contrastText: '#fff',
            },
        },
        typography: {
            fontFamily: ['Montserrat', 'Roboto', 'Arial'].join(','),
            h6: {
                color: '#8d8d8d',
                fontWeight: '850',
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <div className="d-flex flex-row align-items-center mt-5">
                <Typography variant="h6" className="mt-3" color="secondary">
                    {category}
                </Typography>
            </div>

            <OnwardCard>
                <Grid container direction="column" spacing={1}>
                    <Grid container spacing={1}>
                        {!!images?.length &&
                            images.map((docMapping, i) => {
                                const fileUrl = docMapping?.document?.link;
                                const fileTypes = fileUrl.split('.');
                                if (fileTypes[1] === 'pdf') {
                                    return (
                                        <Grid item key={i} className="d-flex position-relative">
                                            <a href={fileUrl} target="_blank">
                                                <FileEarmarkPdf size={100} />
                                            </a>
                                        </Grid>
                                    );
                                } else
                                    return (
                                        <Grid item key={i} className="d-flex position-relative">
                                            <a href={fileUrl} target="_blank">
                                                <Image
                                                    style={{
                                                        height: '100px',
                                                        width: '100px',
                                                    }}
                                                    src={fileUrl}
                                                />
                                            </a>
                                        </Grid>
                                    );
                            })}
                    </Grid>

                    {images?.length > 0 && (
                        <Grid container className="mt-3">
                            <Grid item>
                                <Button
                                    style={{
                                        padding: '5px',
                                    }}
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    onClick={handleDownload}
                                >
                                    Download
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </OnwardCard>
        </ThemeProvider>
    );
}

export default FileViewingCard;
