import React, { useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { BodyText } from '@/components/CarrierAccountingOrders/blocks';

const dateNumeric = new Intl.DateTimeFormat('en-US', {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
});

export const useColumns = ({ callbacks, filter }) => {
    return useMemo(() => {
        return [
            {
                Header: 'Data',
                id: 'data-columns',
                columns: [
                    {
                        Header: 'Order',
                        id: 'order_number',
                        accessor: (note) => note?.order?.order_number || '--',
                    },
                    // {
                    //     Header: 'note content',
                    //     id: 'note_content',
                    //     accessor: (note) => note?.note || '--',
                    // },
                    {
                        Header: 'PO',
                        id: 'po_number',
                        accessor: (note) => note?.order?.po_number || '--',
                    },
                    {
                        Header: 'Customer',
                        id: 'dropoff_name',
                        accessor: (note) => note?.order?.dropoff_name || '--',
                    },
                    {
                        Header: 'Shipper',
                        id: 'order_shipper',
                        span: false,
                        accessor: (note) => note.order?.order_shipper?.business_name || '--',
                        Cell: ({ value }) => {
                            return (
                                <div
                                    css={css`
                                        overflow: hidden;
                                        height: 100%;
                                    `}
                                >
                                    <BodyText
                                        css={css`
                                            white-space: normal;
                                        `}
                                    >
                                        {value}
                                    </BodyText>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Carrier',
                        id: 'order_carrier',
                        span: false,
                        accessor: (note) => note.order?.order_carrier?.business_name || '--',
                        Cell: ({ value }) => {
                            return (
                                <div
                                    css={css`
                                        overflow: hidden;
                                        height: 100%;
                                    `}
                                >
                                    <BodyText
                                        css={css`
                                            white-space: normal;
                                        `}
                                    >
                                        {value}
                                    </BodyText>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Date',
                        id: 'date',
                        accessor: (note) => note.created_at,
                        Cell: ({ value }) => {
                            return dateNumeric.format(new Date(value));
                        },
                    },
                    {
                        Header: 'Shipper has Responded',
                        id: 'shipper_responded',
                        width: 225,
                        span: false,
                        accessor: (note) => note.acknowledged_by_shipper,
                        Cell: ({ value }) => {
                            return (
                                <span css={css`
                                    ${value ? `
                                        color: green;
                                    ` : `
                                        color: red;
                                    `}
                                `}>
                                    {value ? 'Yes' : 'No'}
                                </span>
                            )
                        },
                    },
                ],
            },
        ];
    }, [callbacks, filter]);
};
