import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { TABS, useColumns } from './constants';
import { useClientUser } from '@/hooks';
import { OnwardTab, OnwardTabContainer } from '../Tabs';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { PageTitle, PrimaryButton } from '@/styles/blocks';
import { format } from 'date-fns';
import { asUTCDate } from '@/utilities/convertToISO';
import Table, { TableLoader } from '../Table';
import { useNavigate } from 'react-router';
import { GET_DRIVER_PAYMENTS } from './graphql';
import { useQuery } from '@apollo/client';
import { captureException } from '@sentry/react';

const DriverPayments = () => {
    const [tab, setTab] = useState(Object.keys(TABS)[1]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [driver, setDriver] = useState(null);
    const [selected, setSelected] = useState({});

    const { user_id, drivers, settings } = useClientUser();
    const navigate = useNavigate();

    const where = useMemo(() => {
        const filters = [{ client_id: { _eq: user_id } }];

        if (TABS[tab].statuses) {
            filters.push({ status: { _in: TABS[tab].statuses } });
        }
        if (startDate) {
            filters.push({ pay_period_start: { _gte: new Date(startDate).toISOString() } });
        }
        if (endDate) {
            filters.push({ pay_period_end: { _lt: new Date(endDate).toISOString() } });
        }
        if (driver) {
            filters.push({ driver_id: { _eq: driver } });
        }

        return { _and: filters };
    }, [user_id, tab, startDate, endDate, driver]);

    const { data, loading: paymentsLoading } = useQuery(GET_DRIVER_PAYMENTS, {
        variables: { where },
        onError: (e) => {
            console.error(e);
            captureException(e);
        },
    });

    const payments = useMemo(() => {
        const raw = data?.driver_payments || [];
        const enriched = raw.map((payment) => {
            const [totalRevenue, totalCost] = (payment.orders || []).reduce(
                (acc, order) => {
                    const revenue = order.oms ? order.order_revenue : order.carrier_rate;
                    const cost = (order.carrier_costs || []).reduce((costAcc, cost) => {
                        return costAcc + (cost?.rate || 0);
                    }, 0);

                    return [acc[0] + (revenue || 0), acc[1] + cost];
                },
                [0, 0]
            );

            return {
                ...payment,
                total_revenue: totalRevenue,
                total_cost: totalCost,
            };
        });
        return enriched;
    }, [data]);

    const COLUMNS = useColumns({ settings });

    const onExport = () => {};

    const loading = paymentsLoading;

    return (
        <Grid
            container
            direction="column"
            css={css`
                height: 100%;
                flex-wrap: nowrap;
            `}
        >
            <Grid
                container
                css={css`
                    background-color: white;
                    justify-content: space-around;
                `}
            >
                <OnwardTabContainer value={tab} onChange={(_, t) => setTab(t)}>
                    {Object.entries(TABS).map(([tab, { label }]) => (
                        <OnwardTab key={tab} value={tab} label={label} />
                    ))}
                </OnwardTabContainer>
            </Grid>
            <Grid
                item
                css={css`
                    padding: 1rem 2rem;
                `}
            >
                <PageTitle>Driver Payments</PageTitle>
            </Grid>
            <Grid
                container
                css={css`
                    padding: 1rem 2rem;
                `}
            >
                <Grid
                    item
                    css={css`
                        width: 200px;
                        margin-right: 1rem;
                    `}
                >
                    <TextField
                        type="date"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="Start Date"
                        value={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
                        onChange={(e) => {
                            const next = e.target.value;
                            if (next) {
                                setStartDate(asUTCDate(e.target.value));
                            } else {
                                setStartDate(null);
                            }
                        }}
                        css={css`
                            background-color: white;
                        `}
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        width: 200px;
                        margin-right: 1rem;
                    `}
                >
                    <TextField
                        type="date"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="End Date"
                        value={endDate ? format(endDate, 'yyyy-MM-dd') : ''}
                        onChange={(e) => {
                            const next = e.target.value;
                            if (next) {
                                setEndDate(asUTCDate(e.target.value));
                            } else {
                                setEndDate(null);
                            }
                        }}
                        css={css`
                            background-color: white;
                        `}
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        width: 300px;
                        margin-right: 1rem;
                    `}
                >
                    <TextField
                        select
                        variant="outlined"
                        size="small"
                        fullWidth
                        label="Driver"
                        value={driver || 'ALL'}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            const next = e.target.value;
                            if (next && next !== 'ALL') {
                                setDriver(next);
                            } else {
                                setDriver(null);
                            }
                        }}
                        css={css`
                            background-color: white;
                        `}
                    >
                        <MenuItem value={'ALL'}>All Drivers</MenuItem>
                        {(drivers || []).map((teammate) => (
                            <MenuItem key={teammate.teammate_id} value={teammate.teammate_id}>
                                {teammate.username}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>
            <Grid
                container
                css={css`
                    flex-grow: 1;
                    padding: 1rem 2rem;
                `}
            >
                {loading ? (
                    <TableLoader />
                ) : (
                    <Table
                        data={payments}
                        columns={COLUMNS}
                        callbacks={{
                            getRowId: (payment) => payment.payment_id,
                            rowOnClick: (e, payment) => {
                                const href = `/driver-payments/${payment.payment_id}`;
                                e.preventDefault();
                                if (e.ctrlKey) {
                                    window.open(href, '_blank');
                                } else {
                                    navigate(href);
                                }
                            },
                            setSelectedIds: (selectedIds) => setSelected(selectedIds),
                        }}
                        options={{
                            select: true,
                        }}
                    />
                )}
            </Grid>
            <Grid
                container
                css={css`
                    background-color: white;
                    margin-top: 1rem;
                    padding: 1rem;
                    justify-content: space-around;
                `}
            >
                <PrimaryButton disabled={Object.values(selected).filter((x) => !!x).length === 0} onClick={onExport}>
                    Export
                </PrimaryButton>
            </Grid>
        </Grid>
    );
};

export default DriverPayments;
