import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useClientUser } from '@/hooks';
import { asUTCDate, asBrowserDate } from '@/utilities/convertToISO';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { captureException } from '@sentry/react';
import { addDays, format } from 'date-fns';
import { createContext, useMemo, useEffect, useState, useCallback } from 'react';
import { FIXED_CHARGES } from '@/components/Accessorials/constants';
import { GET_PRICING_OVERRIDES_BY_CLIENT_ID } from '@/graphql/queries/pricing_overrides';
import { INSERT_INVOICES, READY_TO_INVOICE } from './graphql/mutations';
import { ACCOUNTING_STAGES, ACCOUNTING_TABS, ACCOUNTING_SUBTABS } from './constants';
import { ACCOUNTING_ORDERS } from './graphql/queries';
import { useTableColumns } from './columns';

export const Context = createContext();

export const ContextProvider = ({ children }) => {
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const { user_id, roles, teammateRoles, circles, isImposter, shipping_partners } = useClientUser();

    const [stage, setStage] = useState(ACCOUNTING_STAGES.COMPLETED_ORDERS);
    const [modal, setModal] = useState(null);
    const [notification, setNotification] = useState({});
    const [podModalOrder, setPODModalOrder] = useState(null);
    const [invoiceOverrides, setInvoice] = useState({});
    const [selectedOrderIds, setSelectedOrderIds] = useState({});

    const [subTab, tab] = useMemo(() => {
        return [searchParams.get('subTab') ? parseInt(searchParams.get('subTab')) : 0, ACCOUNTING_TABS[0]];
    }, [searchParams.get('tab'), searchParams.get('subTab')]);

    const filters = useMemo(() => {
        const TODAY = new Date();

        return {
            completedFrom: format(
                searchParams.get('completedFrom')
                    ? new Date(parseInt(searchParams.get('completedFrom')))
                    : addDays(TODAY, -14),
                'yyyy-MM-dd'
            ),
            completedTo: format(
                searchParams.get('completedTo') ? new Date(parseInt(searchParams.get('completedTo'))) : TODAY,
                'yyyy-MM-dd'
            ),
            search: searchParams.get('search') || '',
            selectedShippers: searchParams.getAll('selectedShippers') || [],
        };
    }, [searchParams]);

    const [totalCount, setTotalCount] = useState(0);
    const SEARCHABLE = [
        'order_number',
        'po_number',
        'dropoff_name',
        'dropoff_state',
        'dropoff_city',
        'dropoff_zip',
        'pickup_state',
        'pickup_city',
        'pickup_zip',
    ];

    const where = useMemo(() => {
        const query = tab.query(user_id);

        if (filters.selectedShippers?.length > 0) {
            query.push({ shipper_id: { _in: filters.selectedShippers } });
        }
        if (filters.completedFrom) {
            const cutoff = asUTCDate(filters.completedFrom);
            query.push({ completion_time: { _gte: cutoff.toISOString() } });
        }
        if (filters.completedTo) {
            const cutoff = addDays(asUTCDate(filters.completedTo), 1);
            query.push({ completion_time: { _lt: cutoff.toISOString() } });
        }
        if (filters.search) {
            query.push({
                _or: SEARCHABLE.map((field) => ({ [field]: { _ilike: `%${filters.search}%` } })),
            });
        }

        return { _and: query };
    }, [tab, filters]);

    const [getOrders, { data, loading: initializing, refetch }] = useLazyQuery(ACCOUNTING_ORDERS, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setTotalCount(data?.orders_aggregate?.aggregate?.totalCount);
        },
        onError: (err) => {
            captureException(err);
        },
    });

    const { data: overrides } = useQuery(GET_PRICING_OVERRIDES_BY_CLIENT_ID, {
        variables: {
            client_id: user_id,
        },
    });

    const getOrdersDebounced = useMemo(
        () =>
            debounce((where) => {
                getOrders({ variables: { where } });
            }, 500),
        []
    );

    useEffect(() => {
        getOrdersDebounced(where);
    }, [where]);

    const orders = useMemo(() => {
        return data?.orders || [];
    }, [data]);

    const accessorialTypes = useMemo(() => {
        if (!overrides) {
            return {};
        }

        const { internal, mktplace } = overrides;
        const po = tab.label === 'Internal' ? internal : mktplace;
        const types = po.reduce((acc, override) => {
            if (override.partner_client_id) {
                acc[override.partner_client_id] = override.algo_type;
            } else {
                acc.default = override.algo_type;
            }

            return acc;
        }, {});

        return Object.fromEntries(
            orders.map((order) => {
                const partner_client_id = order.shipper_id && order.carrier_id ? order.shipper_id : null;
                return [order.order_id, types[partner_client_id] || types.default];
            })
        );
    }, [overrides, orders, tab]);

    const selectedOrders = useMemo(() => {
        const ordersByKey = Object.fromEntries(orders.map((order) => [order.order_id, order]));
        return Object.keys(selectedOrderIds)
            .map((order_id) => ordersByKey[order_id])
            .filter((order) => order);
    }, [orders, selectedOrderIds]);

    const [readyToInvoice, { loading: readyToInvoiceInflight }] = useMutation(READY_TO_INVOICE, {
        onCompleted: (data) => {
            setNotification({ message: 'Order(s) marked ready to invoice!', type: 'success' });
            refetch();
        },
        onError: (err) => {
            setNotification({ message: 'Error approving order(s), please contact support.', type: 'error' });
            console.log(err);
            captureException(err);
        },
    });

    const [insertInvoices, { loading: createInvoiceInflight }] = useMutation(INSERT_INVOICES, {
        onCompleted: ({ insert_carrier_invoices }) => {
            setNotification({
                severity: 'success',
                message: `Invoice(s) ${insert_carrier_invoices.returning
                    .map((invoice) => invoice.invoice_number)
                    .join(', ')} created!`,
            });
            setSelectedOrderIds({});
            setInvoice({});
            setStage(ACCOUNTING_STAGES.COMPLETED_ORDERS);
            refetch();
        },
        onError: (err) => {
            console.error(err);
            captureException(err);
            setNotification({
                severity: 'error',
                message: 'Error creating invoices, please contact support.',
            });
        },
    });

    const handleReadyToInvoice = (order_ids) => {
        readyToInvoice({
            variables: {
                order_ids: order_ids,
            },
        });
    };

    const handleSearch = (e) => {
        setSearchParams((prev) => {
            prev.set('search', e.target.value);

            return prev;
        });
    };

    const handleShipperChange = (shipper) => {
        setSearchParams((prev) => {
            const last = prev.getAll('selectedShippers') || [];

            const clone = [...last];
            if (clone.includes(shipper)) {
                clone.splice(clone.indexOf(shipper), 1);
            } else {
                clone.push(shipper);
            }

            prev.delete('selectedShippers');
            clone.forEach((value) => {
                prev.append('selectedShippers', value);
            });

            return prev;
        });
    };

    const filteredOrders = useMemo(() => {
        switch (subTab) {
            case 0:
                return orders.filter((order) => !order.ready_to_invoice && !order.carrier_invoice_id);
            case 1:
                return orders.filter((order) => order.ready_to_invoice && !order.carrier_invoice_id);
            case 2:
                return orders.filter(
                    (order) =>
                        order.carrier_invoice_id && ['UNPAID', 'APPROVED'].includes(order.carrier_invoice?.status)
                );
            default:
                return orders.filter((order) => order.carrier_invoice_id && order.carrier_invoice?.status === 'PAID');
        }
    }, [subTab, orders]);

    const invoice = useMemo(() => {
        return {
            due_date: format(
                addDays(filters?.completedTo ? asUTCDate(filters.completedTo) : new Date(), 30),
                'yyyy-MM-dd'
            ),
            description:
                filters?.completedTo && filters?.completedFrom
                    ? `Invoice for ${format(asBrowserDate(filters.completedFrom), 'MMM d, yyyy')} - ${format(
                          asBrowserDate(filters.completedTo),
                          'MMM d, yyyy'
                      )}`
                    : 'Invoice Description',
            ...invoiceOverrides,
        };
    }, [invoiceOverrides, filters]);

    const [subtotal, adjustments] = useMemo(() => {
        return (selectedOrders || []).reduce(
            ([subtotalAcc, adjustmentsAcc], order) => {
                const breakdown = tab.label === 'Internal' ? 'internalBreakdown' : 'carrierBreakdown';
                const subtotal = FIXED_CHARGES.map(({ key: attr }) => {
                    return order?.price_breakdown?.[breakdown]?.[attr] || 0;
                }).reduce((acc, val) => {
                    return acc + val;
                }, 0);

                const accessorials = (order?.price_breakdown?.[breakdown]?.accessorials || []).reduce(
                    (acc, { quantity, rate }) => {
                        return acc + quantity * rate;
                    },
                    0
                );

                return [subtotalAcc + subtotal, adjustmentsAcc + accessorials];
            },
            [0, 0]
        );
    }, [selectedOrders]);

    const createInvoices = useCallback(() => {
        const dbInvoice = {
            client_id: user_id,
            description: invoice.description,
            due_date: asUTCDate(invoice.due_date).toISOString(),
            pay_period_start: filters.completedFrom ? asUTCDate(filters.completedFrom).toISOString() : null,
            pay_period_end: filters.completedTo ? asUTCDate(filters.completedTo) : null,
            status: 'UNPAID',
            type: tab.label === 'Internal' ? 'INTERNAL' : 'PAYABLE',
        };

        const groupedOrders = selectedOrders.reduce((acc, order) => {
            const key = stage === ACCOUNTING_STAGES.CREATE_MULTI_INVOICES ? order.order_id : order.shipper_id;
            acc[key] = [...(acc[key] || []), order];

            return acc;
        }, {});

        insertInvoices({
            variables: {
                invoices: Object.values(groupedOrders).map((invoiceOrders) => ({
                    ...dbInvoice,
                    partner_client_id: invoiceOrders[0].shipper_id === user_id ? null : invoiceOrders[0].shipper_id,
                    orders: {
                        data: invoiceOrders.map((invoiceOrder) => ({
                            order_id: invoiceOrder.order_id,
                        })),
                        on_conflict: {
                            constraint: 'orders_pkey',
                            update_columns: ['carrier_invoice_id'],
                        },
                    },
                })),
            },
        });
    }, [user_id, invoice, filters, selectedOrders, tab]);

    const setFilters = useCallback(
        (callback) => {
            const next = callback(filters);

            setSearchParams((prev) => {
                Object.entries(next).forEach(([key, value]) => {
                    prev.set(key, value);
                });

                return prev;
            });
        },
        [filters]
    );

    const callbacks = {
        setTab: (tab) => {
            setSearchParams((prev) => {
                prev.set('tab', tab.label);

                return prev;
            });
        },
        setSelectedOrderIds,
        setFilters,
        setNotification,
        setModal,
        setStage,
        setPODModalOrder,
        setInvoice,
        setSubTab: (tab) => {
            setSearchParams((prev) => {
                prev.set('subTab', tab);

                return prev;
            });
        },
        refetch,
        handleReadyToInvoice,
        handleShipperChange,
        handleSearch,
        createInvoices,
        gotoAccessorial: (id) => {
            navigate(`/accounting/accessorials/${id}/${tab.label === 'Internal' ? 'internal' : 'carrier'}`);
        },
    };

    const columns = useTableColumns({
        internalTab: tab.label === 'Internal',
        shipping_partners,
        subTab,
        callbacks,
    });

    return (
        <Context.Provider
            value={{
                state: {
                    tabs: ACCOUNTING_TABS,
                    tab,
                    breakdown: tab.label === 'Internal' ? 'internalBreakdown' : 'carrierBreakdown',
                    orders,
                    filteredOrders,
                    shippers:
                        shipping_partners?.map(({ shipper_id, shipper }) => ({
                            label: shipper?.business_name,
                            key: shipper_id,
                        })) || [],
                    accessorialTypes,
                    selectedOrders,
                    totalCount,
                    selectedOrderIds,
                    filters,
                    invoice,
                    podModalOrder,
                    subtotal,
                    adjustments,
                    notification,
                    modal,
                    stage,
                    subTab,
                    subTabs: ACCOUNTING_SUBTABS,
                    columns,
                    invoiceAccess:
                        roles['ADMIN'] || roles['CARRIER'] || teammateRoles?.invoicing || isImposter ? true : false,
                },
                loading: {
                    init: initializing,
                    ready: readyToInvoiceInflight,
                    create: createInvoiceInflight,
                },
                callbacks,
            }}
        >
            {children}
        </Context.Provider>
    );
};
