import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { DRIVER_SETTINGS, IMPORT_SETTINGS } from '@/constants/settings';
import { Card, CardContent, Grid, MenuItem, TextField } from '@material-ui/core';
import { Body1, CardTitle } from '../blocks';
import { useClientUser } from '@/hooks';
import { OnwardSwitch } from '@/styles/blocks';
import { UPDATE_CLIENT } from '../CustomTags/graphql';
import { Mask } from '@/components/ShipmentForm/blocks';
import { useMutation } from '@apollo/client';

const Setting = ({ setting, value, onChange }) => {
    const [input, setInput] = useState(value ?? setting.default);
    const [debouncer, setDebouncer] = useState(null);

    useEffect(() => {
        setInput(value ?? setting.default);
    }, [value]);

    useEffect(() => {
        return () => {
            if (debouncer) {
                clearTimeout(debouncer);
            }
        };
    }, []);

    const debounceOnChange = (next) => {
        setInput(next);
        if (debouncer) {
            clearTimeout(debouncer);
        }
        setDebouncer(
            setTimeout(() => {
                onChange(next);
            }, 500)
        );
    };

    switch (setting.type) {
        case 'string':
            return (
                <TextField
                    select
                    variant="outlined"
                    value={input || ''}
                    onChange={(e) => debounceOnChange(e.target.value)}
                    css={css`
                        min-width: 200px;
                    `}
                >
                    {(setting.options || []).map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            );
        case 'number':
            return (
                <TextField
                    {...(setting.options ? { select: true } : {})}
                    type="number"
                    variant="outlined"
                    value={input || 0}
                    onChange={(e) => debounceOnChange(e.target.value)}
                >
                    {(setting.options || []).map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            );
        case 'boolean':
            return <OnwardSwitch checked={!!input} onChange={() => debounceOnChange(!input)} />;
    }
};

const Settings = ({ type }) => {
    const { settings, user_id, circles } = useClientUser();

    const [updateClient, { loading }] = useMutation(UPDATE_CLIENT);

    const onSave = (patch) => {
        updateClient({
            variables: {
                client_id: user_id,
                update: {
                    settings: {
                        ...(settings || {}),
                        [type]: {
                            ...(settings?.[type] || {}),
                            ...(patch || {}),
                        },
                    },
                },
            },
        });
    };

    let options = [],
        title = '';
    switch (type) {
        case 'import':
            options = IMPORT_SETTINGS;
            title = 'Import Settings';
            break;
        case 'driver':
            options = DRIVER_SETTINGS;
            title = 'Driver Settings';
            break;
    }
    options = options.filter((option) => !option.flag || circles?.[option.flag]);

    return (
        <Card
            css={css`
                margin-top: 30px;
                position: relative;
                opacity: ${loading ? 0.3 : 1};
            `}
        >
            {loading ? <Mask /> : null}
            <CardContent
                css={css`
                    padding: 2rem;
                `}
            >
                <CardTitle>{title}</CardTitle>
                {options.map((option) => (
                    <Grid
                        key={option.key}
                        container
                        direction="row"
                        css={css`
                            margin: 1rem 0;
                            flex-wrap: nowrap;
                            align-items: center;
                        `}
                    >
                        <Body1
                            css={css`
                                margin-right: 1rem;
                            `}
                        >
                            {option.label}:
                        </Body1>
                        <Setting
                            setting={option}
                            value={settings?.[type]?.[option.key]}
                            onChange={(value) => onSave({ [option.key]: value })}
                        />
                    </Grid>
                ))}
            </CardContent>
        </Card>
    );
};

export default Settings;
