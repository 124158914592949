import { gql } from '@apollo/client';

export const PARTNERSHIP_FIELDS = gql`
    fragment PartnershipFields on partnerships {
        carrier_id
        partnership_id
        retailer_id
        shipper_alias
        shipper_id
        status
        profile_img
        profile_img_color
    }
`;
