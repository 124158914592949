import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { Header1, Body1, Step, Tag, Anchor, SubStepDesktop, SubStepMobile } from './blocks';
import { formatInTimeZone } from 'date-fns-tz';
import { startCase } from 'lodash';

const defaultEvent = ({ order, before, after }) => {
    return (
        after || {
            order_id: order.order_id,
            action: `${before?.transition?.next || 'START'}:PICKING_UP`,
        }
    );
};

export default function CustomerConfirm({
    order,
    tz,
    isDesktop,
    isReceived,
    isCrossdocked,
    isLoading,
    warehouse,
    before,
    after,
    callbacks,
    readOnly,
}) {
    const STEPS = [
        {
            description: `1. Schedule ${order.order_type === 'return' ? 'pickup' : 'delivery'} timeframe with customer`,
            
            complete: ({ order, before, after }) => {
                return (
                    !!after &&
                    (order.order_type === 'return' ? order.pickup_date : order.delivery_date) &&
                    order?.del_window_start &&
                    order?.del_window_end
                );
            },
            error: ({ order, before, after }) => {
                return false;
            },
            ...(!readOnly ? {
                cta: 'Schedule Timeframe',
                callback: ({ order, before, after }) => {
                    callbacks.editTimeframe({
                        event: defaultEvent({ order, before, after }),
                    });
                },
            } : {})
        },
        {
            description: `2. Confirm ${order.order_type === 'return' ? 'pickup' : 'delivery'} timeframe with customer`,
            complete: ({ order, before, after }) => {
                return !!after && order?.delivery_time_confirmed === 'confirmed';
            },
            error: ({ order, before, after }) => {
                return !!after && order?.delivery_time_confirmed === 'reject';
            },
            ...(!readOnly ? {
                cta: 'Text Customer',
                secondaryCta: 'Confirm Manually',
                callback: ({ order, before, after }) => {
                    callbacks.sendConfirmationText();
                },
                secondaryCallback: ({ order, before, after }) => {
                    callbacks.confirmOrder();
                },
            } : {})
        },
    ];

    // const STEPS = [];

    const isComplete = useMemo(() => {
        return STEPS.map((step) => step.complete({ order, before, after }));
    });

    const isWarning = useMemo(() => {
        return STEPS.some((step) => step.error({ order, before, after }));
    });

    const inProgressIdx = useMemo(() => {
        return isComplete.findIndex((x) => !x);
    });

    return (
        <Step
            isDesktop={isDesktop}
            isComplete={isComplete.every((x) => x)}
            inProgress={!isComplete.every((x) => x) && after?.est_pickup_date && after?.est_delivery_date}
            isWarning={isWarning}
            label={() => {
                return (
                    <Grid
                        direction="row"
                        container
                        css={css`
                            align-items: center;
                        `}
                    >
                        <Grid
                            direction="column"
                            css={css`
                                margin-right: 8px;
                            `}
                        >
                            <Header1>Scheduled & Confirmed Timeframe</Header1>
                        </Grid>
                        {!!order?.del_window_start && !!order?.del_window_end && (
                            <Grid
                                direction="column"
                                css={css`
                                    margin-right: 8px;
                                `}
                            >
                                <Tag>
                                    <Body1
                                        css={css`
                                            margin-right: 20px;
                                        `}
                                    >{`Timeframe: ${formatInTimeZone(
                                        order.del_window_start,
                                        tz,
                                        'h:mm a zzz'
                                    )} - ${formatInTimeZone(order.del_window_end, tz, 'h:mm a zzz')}`}</Body1>
                                    {!readOnly && (
                                        <Anchor
                                            onClick={() =>
                                                callbacks.editTimeframe({ event: defaultEvent({ order, before, after }) })
                                            }
                                        >
                                            <Header1>Edit</Header1>
                                        </Anchor>
                                    )}
                                </Tag>
                            </Grid>
                        )}
                        {!!order?.delivery_time_confirmed && (
                            <Grid
                                direction="column"
                                css={css`
                                    margin-right: 8px;
                                `}
                            >
                                <Tag>
                                    <Body1>Status: {startCase(order.delivery_time_confirmed)}</Body1>
                                </Tag>
                            </Grid>
                        )}
                    </Grid>
                );
            }}
        >
            {() => (
                <>
                    {STEPS.map(({ description, cta, secondaryCta, callback, secondaryCallback }, idx) => {
                        const SubStep = isDesktop ? SubStepDesktop : SubStepMobile;
                        return (
                            <SubStep
                                isComplete={isComplete[idx]}
                                inProgress={inProgressIdx === idx}
                                isLoading={isLoading}
                                isLast={idx === STEPS.length - 1}
                                description={description}
                                cta={cta}
                                secondaryCta={secondaryCta}
                                callback={callback ? () => callback({ order, before, after }) : null}
                                secondaryCallback={
                                    secondaryCallback ? () => secondaryCallback({ order, before, after }) : null
                                }
                            />
                        );
                    })}
                </>
            )}
        </Step>
    );
}
