import { gql } from '@apollo/client';
import { ROUTE_FIELDS } from '../../graphql/queries/routes';
import { ORDER_FIELDS } from '../../graphql/queries/orders';
import { STOP_FIELDS } from '../../graphql/queries/stops';
import { CLIENT_FIELDS } from '../../graphql/queries/clients';
import { ITEM_FIELDS } from '../../graphql/queries/items';
import { EXCEPTION_FIELDS } from '@/graphql/queries/db_exceptions';
import { ORDER_ROUTE_MAPPINGS } from '@/graphql/queries/order_route_mappings';
import { RATING_FIELDS } from '@/graphql/queries/ratings';
import { WAREHOUSE_EVENT_FIELDS } from '@/graphql/queries/order_wh_events';

export const ROUTE_SUBSCRIPTION = gql`
    ${ROUTE_FIELDS}
    ${ORDER_FIELDS}
    ${STOP_FIELDS}
    ${CLIENT_FIELDS}
    ${ITEM_FIELDS}
    ${EXCEPTION_FIELDS}
    ${ORDER_ROUTE_MAPPINGS}
    ${RATING_FIELDS}
    subscription SubscribeToRoute($route_id: uuid = "") {
        routes_by_pk(route_id: $route_id) {
            ...RouteFields
            stopsByRouteId {
                ...StopFields
            }
            orders {
                ...OrderRouteMappings
                order {
                    ...OrderFields
                    itemsByOrderId {
                        ...ItemFields
                    }
                    order_shipper {
                        ...ClientFields
                    }
                    order_carrier {
                        ...ClientFields
                    }
                    ratings {
                        ...RatingFields
                    }
                }
            }
            teammateByDriverId {
                teammate_id
                username
                phone
            }
        }
    }
`;

export const START_ROUTE = gql`
    mutation RouteDetailsStartRoute($route_id: uuid!, $_set: routes_set_input = {}) {
        update_routes_by_pk(pk_columns: { route_id: $route_id }, _set: $_set) {
            route_id
            status
            route_number
        }
    }
`;

export const UPDATE_STOP_EVENTS_MANY = gql`
    ${WAREHOUSE_EVENT_FIELDS}
    ${ORDER_FIELDS}
    ${STOP_FIELDS}

    mutation UpdateStopEvents(
        $routeUpdates: [routes_updates!] = {}
        $orderUpdates: [orders_updates!] = {}
        $stopUpdates: [stops_updates!] = {}
        $eventUpdates: [order_wh_events_updates!] = {}
        $insertEvents: [order_wh_events_insert_input!] = {}
    ) {
        update_routes_many(updates: $routeUpdates) {
            affected_rows
            returning {
                route_id
                status
                route_number
            }
        }
        update_orders_many(updates: $orderUpdates) {
            affected_rows
            returning {
                ...OrderFields
            }
        }
        update_stops_many(updates: $stopUpdates) {
            affected_rows
            returning {
                ...StopFields
            }
        }
        update_order_wh_events_many(updates: $eventUpdates) {
            affected_rows
            returning {
                ...WarehouseEvents
            }
        }
        insert_order_wh_events(objects: $insertEvents) {
            affected_rows
            returning {
                ...WarehouseEvents
            }
        }
    }
`;

export const COMPLETE_ROUTE = gql`
    mutation RouteDetailsCompleteRoute($routeUpdates: [routes_updates!] = {}) {
        update_routes_many(updates: $routeUpdates) {
            affected_rows
            returning {
                route_id
                status
                route_number
            }
        }
    }
`;
