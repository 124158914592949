import { gql } from '@apollo/client';
import { CLIENT_FIELDS } from './clients';
import { LOCATION_FIELDS } from './locations';
import { SHIPPING_PARTNER_FIELDS } from './shipping_partners';
import { TEAMMATE_FIELDS } from './teammates';
import { PRICING_OVERRIDE_FIELDS } from '../fragments/pricing_overrides';
import { TRUCK_FIELDS } from './trucks';
import { PARTNERSHIP_FIELDS } from '../fragments/partnerships';
import { SERVICE_LEVEL_FIELDS } from '../fragments/service_levels';
import { SURVEY_QUESTION_FIELDS } from '../fragments/surveys';
import { TAG_FIELDS, TAG_MAPPING_FIELDS } from '../fragments/tags';
import { DOCUMENT_FIELDS } from './documents';

export const USER_FIELDS = gql`
    fragment UserFields on users {
        circles
        device_settings
        email
        geolocation
        geolocation_expiration
        legacy_user_id
        roles
        username
        last_modified_at
        created_at
        user_id
    }
`;

export const FULL_QUERY = gql`
    ${USER_FIELDS}

    query GetUsers {
        users {
            ...UserFields
        }
    }
`;

export const QUERY_BY_ID = gql`
    ${USER_FIELDS}
    ${CLIENT_FIELDS}
    ${LOCATION_FIELDS}
    ${TEAMMATE_FIELDS}
    ${TRUCK_FIELDS}
    ${SHIPPING_PARTNER_FIELDS}
    ${PRICING_OVERRIDE_FIELDS}
    ${PARTNERSHIP_FIELDS}
    ${SERVICE_LEVEL_FIELDS}
    ${SURVEY_QUESTION_FIELDS}
    ${TAG_FIELDS}
    ${TAG_MAPPING_FIELDS}
    ${DOCUMENT_FIELDS}

    query UserById($user_id: uuid!) {
        user: users_by_pk(user_id: $user_id) {
            ...UserFields
            client {
                ...ClientFields
                locations {
                    ...LocationFields
                }
                trucks {
                    ...TruckFields
                }
                documents {
                    ...DocumentFields
                }
                service_levels(order_by: { service_level: asc }) {
                    ...ServiceLevelFields
                    tags {
                        ...TagMappingFields
                    }
                }
                survey_questions {
                    ...SurveyQuestionFields
                }
                tags {
                    ...TagFields
                }
                carrier_partnerships(
                    order_by: { shipper: { business_name: asc }, retailer: { name: asc_nulls_first } }
                ) {
                    ...PartnershipFields
                    shipper {
                        ...ClientFields
                    }
                    retailer {
                        retailer_id
                        name
                    }
                }
                shipper_partnerships(
                    order_by: { carrier: { business_name: asc }, retailer: { name: asc_nulls_first } }
                ) {
                    ...PartnershipFields
                    carrier {
                        ...ClientFields
                        user {
                            ...UserFields
                            teammatesByLeaderId {
                                ...TeammateFields
                                user {
                                    ...UserFields
                                }
                            }
                        }
                    }
                }
            }
            teammate {
                ...TeammateFields
                userByTeamLeaderId {
                    ...UserFields
                    client {
                        ...ClientFields
                        locations {
                            ...LocationFields
                        }
                        trucks {
                            ...TruckFields
                        }
                        service_levels(order_by: { service_level: asc }) {
                            ...ServiceLevelFields
                        }
                        survey_questions {
                            ...SurveyQuestionFields
                        }
                        tags {
                            ...TagFields
                        }
                        carrier_partnerships(
                            order_by: { shipper: { business_name: asc }, retailer: { name: asc_nulls_first } }
                        ) {
                            ...PartnershipFields
                            shipper {
                                ...ClientFields
                            }
                            retailer {
                                retailer_id
                                name
                            }
                        }
                        shipper_partnerships(
                            order_by: { carrier: { business_name: asc }, retailer: { name: asc_nulls_first } }
                        ) {
                            ...PartnershipFields
                            carrier {
                                ...ClientFields
                                user {
                                    ...UserFields
                                    teammatesByLeaderId {
                                        ...TeammateFields
                                        user {
                                            ...UserFields
                                        }
                                    }
                                }
                            }
                        }
                    }
                    teammatesByLeaderId {
                        ...TeammateFields
                    }
                }
            }
            teammatesByLeaderId {
                ...TeammateFields
            }
        }
    }
`;

export const QUERY_BY_FIREBASE_ID = gql`
    ${USER_FIELDS}
    ${CLIENT_FIELDS}
    ${LOCATION_FIELDS}
    ${TEAMMATE_FIELDS}
    ${DOCUMENT_FIELDS}
    query users($legacy_user_id: String) {
        users(where: { legacy_user_id: { _eq: $legacy_user_id } }) {
            ...UserFields
            client {
                ...ClientFields
                locations {
                    ...LocationFields
                }
                documents {
                    ...DocumentFields
                }
            }
            teammate {
                teammate_id
                roles
                username
                phone
                userByTeamLeaderId {
                    ...UserFields
                    client {
                        ...ClientFields
                        locations {
                            ...LocationFields
                        }
                    }
                }
            }
            teammatesByLeaderId {
                ...TeammateFields
            }
        }
    }
`;

export const QUERY_BY_EMAIL = gql`
    ${USER_FIELDS}
    query UserByEmail($email: String) {
        users(where: { email: { _eq: $email } }) {
            ...UserFields
        }
    }
`;
