import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { Anchor, Body1, Header1, Step, SubStepDesktop, SubStepMobile, Tag } from './blocks';
import { Grid } from '@material-ui/core';
import { GreyBody } from '@/styles/blocks';

const defaultEvent = ({ order, before, after }) => {
    return (
        after || {
            order_id: order.order_id,
            action: `${before?.transition?.next || 'START'}:PICKING_UP`,
        }
    );
};

export default function Assigned({
    order,
    isDesktop,
    isReceived,
    isCrossdocked,
    isLoading,
    warehouse,
    before,
    after,
    callbacks,
    readOnly,
}) {
    const STEPS = [
        {
            description: `Assign a driver to this leg of the order`,
            complete: ({ order, before, after }) => {
                return (
                    ['inProgress', 'routedInProgress', 'complete', 'routedComplete'].includes(order?.order_status) ||
                    !!after?.driver ||
                    (after?.driver_name && after?.driver_phone)
                );
            },
            ...(!readOnly ? {
                cta: 'Assign Driver',
                callback: ({ order, before, after }) => {
                    callbacks.editAssigned({ event: defaultEvent({ order, before, after }) });
                },
            } : {})
        },
    ];

    const isComplete = useMemo(() => {
        return STEPS.map((step) => {
            return step.complete({ order, before, after });
        });
    }, [before, after, order]);

    const inProgressIdx = useMemo(() => {
        return isComplete.findIndex((x) => !x);
    }, [isComplete]);

    const driver = useMemo(() => {
        return after?.driver?.username || after?.driver_name;
    }, [after]);

    return (
        <Step
            isDesktop={isDesktop}
            isComplete={isComplete.every((x) => x)}
            inProgress={!isComplete.every((x) => x)}
            label={() => {
                return (
                    <Grid
                        direction="row"
                        container
                        css={css`
                            align-items: center;
                        `}
                    >
                        <Grid
                            direction="column"
                            css={css`
                                margin-right: 8px;
                            `}
                        >
                            <Header1>Assigned Driver</Header1>
                        </Grid>
                        {!!after && (
                            <>
                                <Grid
                                    direction="column"
                                    css={css`
                                        margin-right: 8px;
                                    `}
                                >
                                    <Tag>
                                        <Body1
                                            css={css`
                                                margin-right: 20px;
                                            `}
                                        >{`Driver: ${driver || 'Unassigned'}`}</Body1>
                                        {!readOnly && (
                                            <Anchor
                                                onClick={(e) => {
                                                    callbacks.editAssigned({ event: after });
                                                }}
                                            >
                                                <Header1>Edit</Header1>
                                            </Anchor>
                                        )}
                                    </Tag>
                                </Grid>
                                {!!driver && (
                                    <Grid
                                        direction="column"
                                        css={css`
                                            margin-right: 8px;
                                        `}
                                    >
                                        <Anchor
                                            onClick={(e) => {
                                                callbacks.sendWeblink(after);
                                            }}
                                        >
                                            <Header1>Text Driver</Header1>
                                        </Anchor>
                                    </Grid>
                                )}
                            </>
                        )}
                    </Grid>
                );
            }}
        >
            {() => (
                <>
                    {STEPS.map(({ description, cta, callback }, idx) => {
                        const SubStep = isDesktop ? SubStepDesktop : SubStepMobile;

                        return (
                            <SubStep
                                isComplete={isComplete[idx]}
                                inProgress={inProgressIdx === idx}
                                isLoading={isLoading}
                                isLast={idx === STEPS.length - 1}
                                description={description}
                                cta={cta}
                                secondaryCta={null}
                                callback={
                                    callback
                                        ? () => {
                                              callback({ before, after, order });
                                          }
                                        : null
                                }
                                secondaryCallback={null}
                            />
                        );
                    })}
                </>
            )}
        </Step>
    );
}
