import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { genAttributes, STEPS, useCrossdockingSteps } from '@onward-delivery/core';
import { Grid } from '@material-ui/core';
import Claimed from './Claimed';
import Assigned from './Assigned';
import Received from './Received';
import PickedUp from './PickedUp';
import Complete from './Complete';
import Crossdocked from './Crossdocked';
import CrossdockPickup from './CrossdockPickup';
import Attempt from './Attempt';
import { Header2 } from './blocks';
import { OnwardCard } from '../Card';
import CustomerConfirm from './CustomerConfirm';
import Scheduled from './Scheduled';
import zipcode_to_timezone from 'zipcode-to-timezone';

export default function CrossDockingCard({ order, isLoading, events, warehouse, callbacks, readOnly }) {
    const steps = useCrossdockingSteps(events);

    const tz = useMemo(() => {
        const { zip } = genAttributes(order);
        return (
            zipcode_to_timezone.lookup(order[zip]) ||
            Intl.DateTimeFormat().resolvedOptions().timeZone ||
            'America/New_York'
        );
    }, [order]);

    const params = {
        order,
        isLoading,
        warehouse,
        callbacks,
        isDesktop: true,
        isReceived: events.some((e) => e.action === 'START:RECEIVING'),
        isCrossdocked: events.some((e) => e.action.endsWith(':ADD_CD')),
        tz,
        readOnly,
    };

    const content = steps.map(([step, meta]) => {
        switch (step) {
            case STEPS.CLAIMED:
                return <Claimed {...meta} {...params} />;
            case STEPS.SCHEDULED:
                return <Scheduled {...meta} {...params} />;
            case STEPS.CUSTOMER_CONFIRMATION:
                return <CustomerConfirm {...meta} {...params} />;
            case STEPS.ASSIGNED:
                return <Assigned {...meta} {...params} />;
            case STEPS.RECEIVED:
                return <Received {...meta} {...params} />;
            case STEPS.PICKED_UP:
                return <PickedUp {...meta} {...params} />;
            case STEPS.COMPLETE:
                return <Complete {...meta} {...params} />;
            case STEPS.CROSS_DOCKED:
                return <Crossdocked {...meta} {...params} />;
            case STEPS.CROSS_DOCK_PICKUP:
                return <CrossdockPickup {...meta} {...params} />;
            case STEPS.ATTEMPT:
                return <Attempt {...meta} {...params} />;
        }
    });

    return (
        <OnwardCard>
            <Grid direction="column" container>
                <Grid
                    direction="row"
                    container
                    item
                    css={css`
                        margin-bottom: 32px;
                    `}
                >
                    <Header2>LTL Delivery Checklist - {order?.order_number}</Header2>
                </Grid>
                {content}
            </Grid>
        </OnwardCard>
    );
}
