import { gql } from '@apollo/client';
import { NOTE_FIELDS } from '@/graphql/queries/notes';

export const ADMIN_NOTES = gql`
    ${NOTE_FIELDS}

    query GetAdminOrderNotes($where: notes_bool_exp!) {
        notes(where: $where) {
            ...NoteFields
            order {
                order_id
                po_number
                dropoff_name
                order_number
                order_status
                order_shipper {
                    client_id
                    business_name
                }
                order_carrier {
                    client_id
                    business_name
                }
                notes {
                    ...NoteFields
                }
            }
        }
    }
`;

export const ADMIN_ACKNOWLEDGE_NOTES = gql`
    ${NOTE_FIELDS}
    mutation AdminAcknowledgeNotes($orderNoteIds: [uuid!], $notes: [notes_insert_input!] = {}) {
        update_notes(where: { note_id: { _in: $orderNoteIds } }, _set: { acknowledged_by_admin: true }) {
            returning {
                ...NoteFields
                order {
                    order_id
                    po_number
                    dropoff_name
                    order_number
                    order_status
                    order_shipper {
                        client_id
                        business_name
                    }
                    order_carrier {
                        client_id
                        business_name
                    }
                    notes {
                        ...NoteFields
                    }
                }
            }
        }
        insert_notes(objects: $notes) {
            returning {
                ...NoteFields
                order {
                    order_id
                    po_number
                    dropoff_name
                    order_number
                    order_status
                    order_shipper {
                        client_id
                        business_name
                    }
                    order_carrier {
                        client_id
                        business_name
                    }
                    notes {
                        ...NoteFields
                    }
                }
            }
        }
    }
`;

export const ADD_ADMIN_ORDER_NOTE = gql`
    ${NOTE_FIELDS}
    mutation AddAdminOrderNote($note: [notes_insert_input!] = {}) {
        insert_notes(objects: $note) {
            returning {
                ...NoteFields
                order {
                    order_id
                    po_number
                    dropoff_name
                    order_number
                    order_status
                    order_shipper {
                        client_id
                        business_name
                    }
                    order_carrier {
                        client_id
                        business_name
                    }
                    notes {
                        ...NoteFields
                    }
                }
            }
        }
    }
`;
