import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { genAttributes } from '@onward-delivery/core';
import { toNational, toE164 } from '@/utilities/formatPhoneNumber';
import { PICKUP_TYPES } from '@/components/ShipmentForm/constants/dropoffOptions';
import { useFormatters } from './hooks';
import { HR, Header1, Body1, Tag, Step, SubStepDesktop, SubStepMobile, Anchor } from './blocks';
import { Alert } from '@material-ui/lab';
import { OnwardLink } from '@/styles/blocks';

function WarehousePickup({ isDesktop, isLoading, warehouse, before, after, order, callbacks, readOnly }) {
    const { full_address, location, location_type } = genAttributes(order, true);

    const WAREHOUSE_PICKUP_STEPS = useMemo(() => {
        return [
            {
                description: isDesktop ? (
                    `1. Pick up items and update status to 'In-transit'`
                ) : (
                    <>
                        <Grid direction="row">
                            <Body1>{order.pickup_name}</Body1>
                        </Grid>
                        <Grid direction="row">
                            {order.pickup_phone ? (
                                <Anchor href={`tel:${toE164(order.pickup_phone)}`}>
                                    {toNational(order.pickup_phone)}
                                </Anchor>
                            ) : (
                                <Body1>--</Body1>
                            )}
                        </Grid>
                        <Grid direction="row">
                            <Body1>{order[full_address]}</Body1>
                        </Grid>
                        <Grid direction="row">
                            <Body1>{`${order[location]}, ${
                                PICKUP_TYPES[order[location]].find((o) => o.value === order[location_type])?.label
                            }`}</Body1>
                        </Grid>
                    </>
                ),
                complete: ({ order, before, after }) => {
                    return !!before?.ship_date;
                },
                ...(!readOnly ? {
                    cta: isDesktop ? 'Mark In-transit' : 'Arrived',
                    callback: ({ order, before, after }) => {
                        const NOW = new Date();
                        const { event_id, action, order_id } = before;

                        const prompt = isDesktop ? (
                            <>
                                <Grid
                                    direction="row"
                                    container
                                    css={css`
                                        margin-bottom: 12px;
                                    `}
                                >
                                    <Body1>Are you sure you want to mark the order as 'In-Transit'?</Body1>
                                </Grid>
                                <Grid direction="row" container>
                                    <Body1>This will let the customer and shipper know the order is on the way</Body1>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid
                                    direction="row"
                                    container
                                    css={css`
                                        margin-bottom: 12px;
                                    `}
                                >
                                    <Header1>Pick Up Order</Header1>
                                </Grid>
                            </>
                        );
                        const callback = (orderUpdates = {}) =>
                            callbacks.upsertEvent({
                                event: {
                                    event_id,
                                    action,
                                    order_id,
                                    ship_date: NOW.toISOString(),
                                },
                                order: {
                                    order_id,
                                    order_status: 'inProgress',
                                    ltl_pickup_completed: NOW.toISOString(),
                                    ...orderUpdates,
                                },
                            });

                        callbacks.openChecklist(
                            {
                                content: prompt,
                                documents: [{ field: 'proof_of_pickup', name: 'Proof of Pickup', required: false }],
                                signatures: [],
                                title: 'Pick Up Order',
                                itemsHelperText: 'Checkmark each item after loading.',
                            },
                            callback
                        );
                    },
                } : {})
            },
        ];
    }, [isDesktop, full_address, location, location_type, order]);

    const formatters = useFormatters(order);
    const isComplete = useMemo(() => {
        return WAREHOUSE_PICKUP_STEPS.map((step) => {
            return step.complete({ order, before, after, warehouse });
        });
    }, [before, after, order]);

    const inProgressIdx = useMemo(() => {
        return isComplete.findIndex((x) => !x);
    }, [isComplete]);

    const allComplete = isComplete.every((x) => x);

    return (
        <Step
            isDesktop={isDesktop}
            isComplete={allComplete}
            inProgress={!allComplete}
            label={() => {
                return (
                    <Grid
                        direction="row"
                        container
                        css={css`
                            align-items: center;
                        `}
                    >
                        <Grid
                            direction="column"
                            css={css`
                                margin-right: 8px;
                            `}
                        >
                            <Header1>Picked Up</Header1>
                        </Grid>

                        {isDesktop || allComplete ? (
                            <Grid
                                direction="column"
                                css={css`
                                    margint-right: 8px;
                                `}
                            >
                                <Tag>
                                    {isDesktop ? (
                                        <Body1
                                            css={css`
                                                margin-right: 20px;
                                            `}
                                        >{`Scheduled Pickup: ${
                                            before.est_ship_date
                                                ? formatters.dateShort.format(new Date(before.est_ship_date))
                                                : 'Pending'
                                        }`}</Body1>
                                    ) : null}
                                    <Body1
                                        css={css`
                                            margin-right: 20px;
                                        `}
                                    >{`Completed: ${
                                        before.ship_date
                                            ? formatters.dateShort.format(new Date(before.ship_date))
                                            : 'Pending'
                                    }`}</Body1>
                                    {isDesktop ? (!readOnly && (
                                        <Anchor
                                            onClick={() => {
                                                callbacks.editCrossdock({ event: before, stage: 'WAREHOUSE' });
                                            }}
                                        >
                                            <Header1>Edit</Header1>
                                        </Anchor>
                                    )) : null}
                                </Tag>
                            </Grid>
                        ) : null}
                    </Grid>
                );
            }}
        >
            {() => (
                <>
                    {WAREHOUSE_PICKUP_STEPS.map(({ description, cta, callback }, idx) => {
                        const SubStep = isDesktop ? SubStepDesktop : SubStepMobile;

                        return (
                            <SubStep
                                isComplete={isComplete[idx]}
                                inProgress={inProgressIdx === idx}
                                isLoading={isLoading}
                                isLast={idx === WAREHOUSE_PICKUP_STEPS.length - 1}
                                description={description}
                                cta={cta}
                                secondaryCta={null}
                                callback={
                                    callback
                                        ? () => {
                                              callback({ before, after, order });
                                          }
                                        : null
                                }
                                secondaryCallback={null}
                            />
                        );
                    })}
                </>
            )}
        </Step>
    );
}

function MidCrossdock({ isDesktop, isLoading, warehouse, before, after, order, callbacks, readOnly }) {
    const CROSSDOCK_STEPS = useMemo(() => {
        return [
            {
                description: isDesktop ? (
                    `Mark warehouse status as 'Received'`
                ) : (
                    <>
                        <Grid direction="row">
                            <Body1>{before.location ? before.location.location_name : '--'}</Body1>
                        </Grid>
                        <Grid direction="row">
                            <Body1>{before.location ? before.location.address : '--'}</Body1>
                        </Grid>
                        <Grid direction="row">
                            <Body1>
                                {`${before?.location?.location_type || '--'}, ${
                                    (PICKUP_TYPES[before?.location?.location_type] || []).find(
                                        (o) => o.value === before?.location?.location_info
                                    )?.label || '--'
                                }`}
                            </Body1>
                        </Grid>
                    </>
                ),
                complete: ({ order, warehouse, before, after }) => {
                    return after?.received_date && after?.status === 'RECEIVED';
                },
                ...(!readOnly ? {
                    cta: isDesktop ? 'Mark Received' : 'Arrived',
                    callback: ({ order, before, after }) => {
                        const NOW = new Date();
                        const prompt = (
                            <>
                                <Grid
                                    direction="row"
                                    container
                                    css={css`
                                        margin-bottom: 12px;
                                    `}
                                >
                                    <Body1>Are you sure you want to mark the warehouse status as 'Received'?</Body1>
                                </Grid>
                                <Grid
                                    direction="row"
                                    container
                                    css={css`
                                        margin-bottom: 12px;
                                    `}
                                >
                                    <Body1>
                                        This will let the shipper know the order has been received into your warehouse
                                    </Body1>
                                </Grid>
                                <Alert severity="warning">
                                    <Body1>
                                        {'Please report any overages, shortages, or damages to '}
                                        <OnwardLink href={'mailto:osd@onwarddelivery.com'}>
                                            osd@onwarddelivery.com
                                        </OnwardLink>
                                    </Body1>
                                </Alert>
                            </>
                        );
                        const callback = (orderUpdates = {}) =>
                            callbacks.upsertEvent({
                                event: {
                                    action: 'CD_PENDING_PO:PICKING_UP',
                                    order_id: order.order_id,
                                    location_id: before.location_id,

                                    received_date: NOW.toISOString(),
                                    est_pickup_date: before.est_pickup_date,
                                    status: 'RECEIVED',
                                },
                                order: {
                                    order_id: order.order_id,
                                    ...orderUpdates,
                                },
                            });

                        callbacks.openChecklist(
                            {
                                content: prompt,
                                documents: [],
                                signatures: [],
                                title: 'Drop Off Order',
                                itemsHelperText: 'Checkmark each item after unloading.',
                            },
                            callback
                        );
                    },
                } : {})
            },
            ...(order?.palletize_returns && order?.order_type !== 'delivery'
                ? [
                      {
                          description: `4. Palletize items and add dimensions'`,
                          complete: ({ order, warehouse, before, after }) => {
                              return ['L', 'W', 'H'].every((dimension) => order?.palletized_dimensions?.[dimension]);
                          },
                          ...(!readOnly ? {
                            cta: 'Add dimensions',
                            callback: () => {
                                callbacks.editPalletDimensions();
                            },
                        } : {})
                      },
                  ]
                : []),
        ];
    }, [isDesktop, before, order]);

    const formatters = useFormatters(order);
    const isComplete = useMemo(() => {
        return CROSSDOCK_STEPS.map((step) => {
            return step.complete({ order, before, after, warehouse });
        });
    }, [before, after, order, warehouse]);

    const inProgressIdx = useMemo(() => {
        return isComplete.findIndex((x) => !x);
    }, [isComplete]);

    const allComplete = isComplete.every((x) => x);

    return (
        <Step
            isDesktop={isDesktop}
            isComplete={allComplete}
            inProgress={!allComplete && !!before.ship_date}
            label={() => {
                return (
                    <Grid
                        direction="row"
                        container
                        css={css`
                            align-items: center;
                        `}
                    >
                        <Grid
                            direction="column"
                            css={css`
                                margin-right: 8px;
                            `}
                        >
                            <Header1>Cross-docked</Header1>
                        </Grid>
                        {isDesktop || allComplete ? (
                            <Grid
                                direction="column"
                                css={css`
                                    margin-right: 8px;
                                `}
                            >
                                <Tag>
                                    {isDesktop ? (
                                        <Body1
                                            css={css`
                                                margin-right: 20px;
                                            `}
                                        >{`Scheduled Dropoff: ${
                                            before.est_received_date
                                                ? formatters.dateShort.format(new Date(before.est_received_date))
                                                : 'Pending'
                                        }`}</Body1>
                                    ) : null}
                                    <Body1
                                        css={css`
                                            margin-right: 20px;
                                        `}
                                    >{`Completed: ${
                                        after?.received_date
                                            ? formatters.dateShort.format(new Date(after.received_date))
                                            : 'Pending'
                                    }`}</Body1>
                                    {!readOnly && (
                                        <Anchor
                                            onClick={() => {
                                                callbacks.editCrossdock({ event: before, stage: 'WAREHOUSE' });
                                            }}
                                        >
                                            <Header1>Edit</Header1>
                                        </Anchor>
                                    )}
                                </Tag>
                            </Grid>
                        ) : null}
                        {order?.palletized_dimensions?.L && (
                            <Tag>
                                <Body1
                                    css={css`
                                        margin-right: 20px;
                                    `}
                                >{`Pallet Dimensions: ${['L', 'W', 'H']
                                    .map((key) => `${order.palletized_dimensions?.[key]}${key}`)
                                    .join(', ')}`}</Body1>
                                {isDesktop ? (!readOnly && (
                                    <Anchor
                                        onClick={() => {
                                            callbacks.editPalletDimensions({
                                                event: {},
                                                order: order,
                                            });
                                        }}
                                    >
                                        <Header1>Edit</Header1>
                                    </Anchor>
                                )) : null}
                            </Tag>
                        )}
                    </Grid>
                );
            }}
        >
            {() => (
                <>
                    {CROSSDOCK_STEPS.map(({ description, cta, callback }, idx) => {
                        const SubStep = isDesktop ? SubStepDesktop : SubStepMobile;

                        return (
                            <SubStep
                                isComplete={isComplete[idx]}
                                inProgress={inProgressIdx === idx}
                                isLoading={isLoading}
                                isLast={idx === CROSSDOCK_STEPS.length - 1}
                                description={description}
                                cta={cta}
                                secondaryCta={null}
                                callback={
                                    callback
                                        ? () => {
                                              callback({ before, after, order });
                                          }
                                        : null
                                }
                                secondaryCallback={null}
                            />
                        );
                    })}
                </>
            )}
        </Step>
    );
}

export default function Crossdocked(props) {
    return (
        <>
            <WarehousePickup {...props} />
            <MidCrossdock {...props} />
        </>
    );
}
