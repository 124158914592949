import React, { useContext, useMemo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Grid, MenuItem } from '@material-ui/core';

import ItemDetails from './ItemDetails';
import { TextField } from '../../../blocks';
import { SectionSubtitle2, PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { ModalContext } from '..';
import { ITEM_LABELS_BY_FREIGHT } from '@/components/ShipmentForm/constants/freightTypes';

const HR = styled.div`
    margin-top: 32px;
    margin-bottom: 32px;
    border: 1px solid rgba(76, 76, 76, 0.5);
`;

function ItemsEditTab({ hasError, itemHasError, isDirty, opt }) {
    const { startDirty } = opt;
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { order } = modalState;

    return (
        <Grid container direction="column">
            {(order.itemsByOrderId || []).map((item, idx) => (
                <React.Fragment key={idx}>
                    <ItemDetails
                        order={order}
                        item={item}
                        index={idx}
                        callbacks={callbacks}
                        hasError={itemHasError[idx]}
                        isDirty={(isDirty.itemsByOrderId || [])?.[idx] || {}}
                        startDirty={startDirty}
                    />
                    <HR />
                </React.Fragment>
            ))}

            <Grid item>
                <PrimaryButton onClick={() => callbacks.addItem()}>{`Add ${
                    ITEM_LABELS_BY_FREIGHT[order.freight_type] || 'Item'
                }`}</PrimaryButton>
            </Grid>
        </Grid>
    );
}

export default ItemsEditTab;
