import React, { useCallback, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { genAttributes } from '@onward-delivery/core';
import { OnwardCard } from './Card';
import { toNational } from '@/utilities/formatPhoneNumber';
import { CardTitle, CardItem } from './blocks';
import { css } from '@emotion/react';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { formatInTimeZone } from 'date-fns-tz';
import { asDateInTZ } from '@/utilities/convertToISO';
import { delWindowToFormattedDateRange, delWindowToFormattedDateSingle } from '@/utilities/delWindowToFormattedDate';

export default function PickUpCard({ order, orderNotes, }) {
    const { full_address, address, unit, zip } = genAttributes(order, true);

    const timeframe = useMemo(() => {
        if (!order) return '-';
        return order.pickup_window_start && order.pickup_window_end
            ? delWindowToFormattedDateRange(order.pickup_window_start, order.pickup_window_end, order[zip])
            : '-';
    }, [order, zip]);

    const formatTimestamp = useCallback(
        (datestr) => {
            if (!datestr || !order || !zip) return 'N/A';
            const tz = zipcode_to_timezone.lookup(order[zip]) || Intl.DateTimeFormat().resolvedOptions().timeZone;
            return formatInTimeZone(asDateInTZ(datestr, tz), tz, 'EEE, MMM d, yyyy');
        },
        [order, zip]
    );

    const pickupLocationName = useMemo(() => {
        if (!order?.order_shipper?.locations) return '';

        const pickupLocation = order.order_shipper.locations.find(
            (location) => location.business_address === order.pickup_street_address
        );

        return pickupLocation?.location_name || '';
    });

    return (
        <OnwardCard
            css={css`
                height: 100%;
            `}
            contentProps={{ style: { height: '100%' } }}
        >
            <Grid container style={{ height: '100%', flexWrap: 'nowrap' }} direction="column">
                <CardTitle>{order.order_type === 'return' ? 'Warehouse Return' : 'Pick Up'}</CardTitle>
                {order.is_middle_mile && !order.carrier_id ? (
                    <>
                        <CardItem className="my-1">Contact information pending Carrier Warehouse selection</CardItem>
                    </>
                ) : (
                    <>
                        <CardItem className="my-1">
                            {order.pickup_name}
                            {['complete', 'routedComplete', 'inProgress', 'routedInProgress'].includes(
                                order.order_status
                            ) && <CheckCircleIcon style={{ color: '#59B863' }} className="float-right" />}
                        </CardItem>
                        <CardItem className="my-1">{toNational(order.pickup_phone)}</CardItem>
                        <CardItem className="my-1">{order.pickup_email || 'Email Not Provided'}</CardItem>
                        <CardItem className="my-1">
                            {order[full_address] || `${order[address]}${order[unit] ? `, Unit ${order[unit]}` : ''}`}
                        </CardItem>
                        {pickupLocationName && (
                            <CardItem className="my-1">Location Name: {pickupLocationName}</CardItem>
                        )}
                    </>
                )}
                <CardItem className="my-1">
                    First Available: {order?.first_available_date ? formatTimestamp(order.first_available_date) : 'TBD'}
                </CardItem>

                <CardItem className="my-1">
                    Scheduled Date:{' '}
                    {(order.order_type !== 'return' ? order.pickup_date_formatted : order.delivery_date_formatted) ||
                        'TBD'}
                </CardItem>
                <CardItem className="my-1">Scheduled Timeframe: {timeframe}</CardItem>
                <CardItem className="my-1">
                    Completion Time:{' '}
                    {order?.ltl_pickup_completed
                        ? formatTimestamp(order?.ltl_pickup_completed) +
                          ' ' +
                          delWindowToFormattedDateSingle(order?.ltl_pickup_completed, order[zip])
                        : 'N/A'}
                </CardItem>
                <CardItem className="mt-1 mb-4">
                    Comments: {!!orderNotes?.pickup?.length ? orderNotes?.pickup.map(n => n.note).join(' / ') : 'None'}
                </CardItem>
            </Grid>
        </OnwardCard>
    );
}
