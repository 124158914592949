import { gql } from '@apollo/client';

export const SERVICE_LEVEL_FIELDS = gql`
    fragment ServiceLevelFields on service_levels {
        service_level_id
        client_id
        service_level
        location_type
        instructions
        onward_tags
    }
`;
