import React, { useCallback, useMemo } from 'react';
import { useClientUser } from '@/hooks';

import { validate, genAttributes } from '@onward-delivery/core';
import { genAttributesPerTab } from './modal';
import {
    PROGRESSION_TYPES,
    SHIPMENT_FORM_MODAL_PROGRESSION,
    SHIPMENT_FORM_STATE,
    SHIPMENT_FORM_TYPES,
} from '../constants';
import { processOrdersFromCSV, processOrdersFromPDF, createOrder, mapPickupInfo } from '../utilities/processOrders';
import _ from 'lodash';

export const useOrderErrors = (state) => {
    return useMemo(() => {
        if (Object.keys(state.orders).length === 0) {
            return {};
        }

        return Object.fromEntries(
            Object.values(state.orders)
                .map((order) => {
                    const contact = genAttributes(order);
                    const pickup = genAttributes(order, true);
                    const fieldsByTab = genAttributesPerTab({
                        contact,
                        pickup,
                    });

                    const [validated, hasMissingField] = validate(order, [], {
                        disableGeocoding: true,
                        disablePricing: true,
                    });
                    if (hasMissingField?.length > 0) {
                        return [
                            validated.key,
                            Object.fromEntries(
                                Object.keys(fieldsByTab).map((tab) => {
                                    return [tab, fieldsByTab[tab].some((field) => hasMissingField.includes(field))];
                                })
                            ),
                        ];
                    }

                    return;
                })
                .filter((x) => !!x)
        );
    }, [state.orders]);
};

export const useShipmentFormCallbacks = (state, callbacks) => {
    const { user_id, circles, locations, email, businessName, businessPhone, service_levels } = useClientUser();

    const reset = useCallback(() => {
        callbacks.setFormType(null);
        callbacks.setOrders({});
        callbacks.setMultiPickup(false);
        callbacks.setPickupInfo({});
        callbacks.setMapping({});
        callbacks.setLoading(false);
        callbacks.setUploadedFile(null);
        callbacks.setState(SHIPMENT_FORM_STATE.CREATE);
        callbacks.setFreightType('household');
        callbacks.setProgression({
            current: 0,
            type: null,
        });
    }, []);

    const setRemaining = useCallback(
        (count) => {
            callbacks.setRemaining(count);
        },
        [callbacks]
    );

    const completeRemaining = useCallback(() => {
        callbacks.setRemaining((prev) => prev - 1);
    }, [callbacks]);

    const advance = useCallback(() => {
        if (state.progression.current === SHIPMENT_FORM_MODAL_PROGRESSION[state.progression.type].length - 1) {
            switch (state.progression.type) {
                case PROGRESSION_TYPES.PREPARE_PDF: {
                    callbacks
                        .processPdf({ client_id: user_id, filename: state.pdf.filename, file: state.pdf })
                        .then((resp) => {
                            const order = resp.data.order;
                            return processOrdersFromPDF({ order, freightType: state.freightType });
                        })
                        .then((res) => {
                            callbacks.setOrders(res.orders);
                            callbacks.setState(SHIPMENT_FORM_STATE.REVIEW);
                        });
                    break;
                }
                case PROGRESSION_TYPES.PREPARE_CSV_WITH_SAVE:
                case PROGRESSION_TYPES.PREPARE_CSV: {
                    callbacks.setState(SHIPMENT_FORM_STATE.REVIEW);
                    processOrdersFromCSV(
                        {
                            csvOptions: state.csvOptions,
                            csvFile: state.uploadedFile.data,
                            csvMapping: state.mapping,
                            pickupInfo: state.pickupInfo,
                            freightType: state.freightType,
                            multiPickup: state.multiPickup,
                            locations,
                            business: {
                                email,
                                name: businessName,
                                phone: businessPhone,
                            },
                            userId: user_id,
                            service_levels,
                            circles,
                        },
                        {
                            init: setRemaining,
                            complete: completeRemaining,
                        }
                    )
                        .then((res) => {
                            callbacks.setOrders(res.orders);
                        })
                        .catch((err) => {
                            console.error(err);
                            callbacks.setErrorMsg(`Error processing CSV`);
                            callbacks.setState(SHIPMENT_FORM_STATE.CREATE);
                        });
                    break;
                }
                case PROGRESSION_TYPES.PREPARE_MANUAL: {
                    callbacks.setState(SHIPMENT_FORM_STATE.REVIEW);

                    break;
                }
                case PROGRESSION_TYPES.EDIT_ORDER_PICKUP: {
                    const pickup = state.pickupInfo;
                    callbacks.setOrders((prev) => {
                        return Object.fromEntries(
                            Object.entries(prev).map(([key, order]) => {
                                const swappedAttrs = genAttributes(order, true);
                                const { state: dostate, zip: dozip, city: docity } = genAttributes(order);

                                const pickupInfo = mapPickupInfo(
                                    pickup.is_middle_mile
                                        ? {
                                              pickupcity: order[docity],
                                              pickupstate: order[dostate],
                                              pickupzip: order[dozip],
                                          }
                                        : pickup,
                                    user_id,
                                    swappedAttrs
                                );

                                return [
                                    key,
                                    {
                                        ...order,
                                        ...pickupInfo,
                                    },
                                ];
                            })
                        );
                    });
                }
            }

            callbacks.setProgression({
                current: 0,
                type: null,
            });
        } else {
            callbacks.setProgression((prev) => {
                return { ...prev, current: prev.current + 1 };
            });
        }
    }, [state, callbacks]);

    const revert = useCallback(() => {
        callbacks.setProgression((prev) => {
            if (prev.current > 0) {
                return { ...prev, current: prev.current - 1 };
            }

            return {
                current: 0,
                type: null,
            };
        });
    }, []);

    const addOrder = useCallback(
        (type) => {
            const { hasMissingField, geocodePartialMatch, geocodeFailed, ...created } = createOrder(
                state.pickupInfo,
                state.freightType,
                type,
                user_id,
                circles
            );
            const contact = genAttributes(created);
            const pickup = genAttributes(created, true);
            const fieldsByTab = genAttributesPerTab({
                contact,
                pickup,
            });

            callbacks.setOrders((prev) => ({ ...prev, [created.key]: created }));
            callbacks.setEditing({ order: created });
        },
        [state]
    );

    const selectFormType = useCallback((form) => {
        let type;
        switch (form) {
            case SHIPMENT_FORM_TYPES.CSV:
                type = PROGRESSION_TYPES.PREPARE_CSV;
                break;
            case SHIPMENT_FORM_TYPES.MANUAL:
                type = PROGRESSION_TYPES.PREPARE_MANUAL;
                break;
            case SHIPMENT_FORM_TYPES.PDF:
                type = PROGRESSION_TYPES.PREPARE_PDF;
                break;
        }

        callbacks.setFormType(form);
        callbacks.setProgression(() => ({
            type,
            current: 0,
        }));
    }, []);

    const toggleSaveTemplate = useCallback(
        (willSave) =>
            callbacks.setProgression((prev) => {
                return {
                    ...prev,
                    type: willSave ? PROGRESSION_TYPES.PREPARE_CSV_WITH_SAVE : PROGRESSION_TYPES.PREPARE_CSV,
                };
            }),
        []
    );

    const updatePickupInfo = useCallback((payload) => {
        callbacks.setPickupInfo((prevState) => ({ ...prevState, ...payload }));
    }, []);

    const editOrder = useCallback(
        (errors, order) => {
            callbacks.setOrders((prev) => ({ ...prev, [order.key]: order }));
        },
        [state, callbacks]
    );

    const deleteOrder = useCallback(
        (order) => {
            callbacks.setOrders((prev) => {
                const { [order.key]: _, ...remaining } = prev;
                return remaining;
            });
        },
        [state, callbacks]
    );

    const openEditModal = useCallback((order, tab) => {
        callbacks.setEditing({ order, tab, dirtyStart: true });
    }, []);

    const openEditPickupModal = useCallback((orders) => {
        callbacks.setEditingPickup({ orders, dirtyStart: true });
    }, []);

    const submit = useCallback(
        //initial creation of order
        ({ oms }) => {
            const orders = Object.entries(state.orders).map(([, order]) => ({
                ...order,
                oms,
                planning: false,
            }));

            const missingFieldsByOrder = {};
            const validated = orders.map((order) => {
                const contact = genAttributes(order);
                const pickup = genAttributes(order, true);
                const fieldsByTab = genAttributesPerTab({
                    contact,
                    pickup,
                });

                const [validated, hasMissingField] = validate(order, [], {
                    disableGeocoding: true,
                    disablePricing: true,
                });
                if (hasMissingField?.length > 0) {
                    missingFieldsByOrder[validated.key] = Object.fromEntries(
                        Object.keys(fieldsByTab).map((tab) => {
                            return [tab, fieldsByTab[tab].some((field) => hasMissingField.includes(field))];
                        })
                    );
                    callbacks.setErrorMsg('Missing fields must be filled in before submitting');
                }

                return validated;
            });

            callbacks.setOrders(Object.fromEntries(orders.map((order) => [order.key, order])));

            if (Object.keys(missingFieldsByOrder).length > 0) {
                return;
            }
            const finalized = validated.map((order) => {
                const {
                    key,
                    delivery_date_formatted,
                    alternative_delivery_dates_formatted,
                    preferred_delivery_date_formatted,
                    completion_date_formatted,
                    billing_completion_date_formatted,
                    estimated_delivery_range_end_formatted,
                    estimated_delivery_range_start_formatted,
                    ...remaining
                } = order;

                return {
                    ...remaining,
                    dropoff_phone: order.dropoff_phone === 'Missing Phone #' ? null : order.dropoff_phone,
                    shipper_id: order.shipper_id || user_id,
                    carrier_id: order.carrier_id || null,
                    source_form: state.formType === SHIPMENT_FORM_TYPES.CSV ? 'CSV UPLOAD' : 'MANUAL',
                    template_name: state.templateName || null,
                    order_status: 'processing',
                    ...(remaining?.order_type === 'delivery'
                        ? {
                              palletize_returns: false,
                          }
                        : {
                              palletize_returns: remaining.palletize_returns,
                          }),
                };
            });

            callbacks
                .submitOrders({
                    client_id: user_id,
                    orders: finalized,
                    filename: state.uploadedFile?.name || undefined,
                    type: state.formType === SHIPMENT_FORM_TYPES.CSV ? 'CSV' : 'MANUAL',
                })
                .then(() => {
                    reset();
                });
        },
        [state, callbacks]
    );

    return {
        advance,
        openEditModal,
        openEditPickupModal,
        remaining: { init: setRemaining, complete: completeRemaining },
        reset,
        revert,
        selectFormType,
        toggleSaveTemplate,
        updatePickupInfo,
        addOrder,
        editOrder,
        deleteOrder,
        submit,
    };
};
