import { gql } from '@apollo/client';

export const STOP_FIELDS = gql`
    fragment StopFields on stops {
        actions_completed
        address
        arrival_time
        del_window_buffer
        del_window_delay
        del_window_end
        del_window_rounded
        del_window_start
        driving_time
        driving_distance
        earliest_start
        end
        exchanges
        geo_fence_enter
        geo_fence_exit
        lat
        latest_end
        legacy_orders
        legacy_route_id
        lng
        ordering
        orders
        overnight
        returns
        route_id
        service_time
        start
        stop_id
        stop_completion_time
        stop_end_time
        stop_start_time
        type
        wait_time
    }
`;

export const FULL_QUERY = gql`
    ${STOP_FIELDS}

    query GetStops {
        stops {
            ...StopFields
        }
    }
`;

export const QUERY_BY_ID = gql`
    ${STOP_FIELDS}

    query teammates($stop_id: String) {
        stops(limit: 100, where: { stop_id: { _eq: $stop_id } }) {
            ...StopFields
        }
    }
`;
