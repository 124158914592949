import React, { useEffect, useState, useMemo, useContext } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { asBrowserDate } from '@/utilities/convertToISO';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { Grid, TextField, IconButton, InputAdornment, Popover } from '@material-ui/core';
import AvailabilityCalendar from '@/components/AvailabilityCalendar';

import { Body1 } from './blocks';

const dateShort = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
});

const CellContent = styled.div`
    padding: 6px;
    font-size: 14px;
    width: 24px;
    line-height: 24px;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 999px;

    ${(props) => css`
        color: ${props.selected ? colors.greens.primary : 'inherit'};
        border-color: ${props.selected ? colors.greens.primary : 'transparent'};
    `}
`;

const Availability = ({ order, availabilityDays, disabledDays, callbacks }) => {
    const [anchor, setAnchor] = useState(null);
    return (
        <>
            <Grid
                item
                css={css`
                    margin-bottom: 4px;
                `}
            >
                <Body1>Preferred Delivery dates (Pick 3)</Body1>
            </Grid>

            <Grid
                item
                css={css`
                    margin-top: 16px;
                    justify-content: center;
                `}
            >
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Preferred Delivery Dates"
                    value={
                        availabilityDays?.map((d) => {
                            const inLocal = asBrowserDate(d);

                            return dateShort.format(inLocal);
                        }) || []
                    }
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    css={css`
                                        padding: 8px;
                                    `}
                                    onClick={(e) => {
                                        setAnchor(e.target);
                                    }}
                                >
                                    <CalendarTodayIcon
                                        css={css`
                                            color: ${colors.greens.primary};
                                        `}
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    css={css`
                        margin-bottom: 20px;
                        background: ${colors.white.primary};
                    `}
                />
                <Popover
                    open={!!anchor}
                    anchorEl={anchor}
                    onClose={() => {
                        setAnchor(null);
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Grid
                        container
                        item
                        css={css`
                            padding: 12px;
                        `}
                    >
                        <AvailabilityCalendar
                            editable={true}
                            selectedDays={
                                availabilityDays?.map((d) => {
                                    const inLocal = asBrowserDate(d);
                                    return new Date(inLocal);
                                }) || []
                            }
                            handleDayClick={(d, mods) => {
                                if (mods.disabled) {
                                    return;
                                }

                                callbacks.setAvailabilityDays((prev) => {
                                    const added = callbacks.toDropoffTz(d.toISOString());

                                    if (prev.includes(added)) {
                                        return prev.filter((ts) => ts !== added);
                                    }

                                    if (prev.length === 3) {
                                        return [...prev.slice(1), added];
                                    }

                                    return [...prev, added];
                                });
                            }}
                            disabledDays={disabledDays}
                            month={order?.first_available_date ? new Date(order.first_available_date) : new Date()}
                            renderDay={(day, mod) => {
                                return <CellContent selected={mod.selected}>{day.getDate()}</CellContent>;
                            }}
                            modifiersStyles={{
                                selected: {
                                    color: colors.greens.primary,
                                    background: colors.white.primary,
                                },
                                outside: {
                                    visibility: 'hidden',
                                },
                            }}
                            css={css`
                                .DayPicker-Day {
                                    padding: 2px !important;
                                }
                            `}
                        />
                    </Grid>
                </Popover>
            </Grid>
        </>
    );
};

export default Availability;
