import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { useTable, useFlexLayout, useSortBy, useRowSelect } from 'react-table';
import { useNavigate } from 'react-router-dom';

import { TableContainer } from '@material-ui/core';
import { useColumns } from './columns';
import HeaderCell from '@/components/DispatchUnassigned/table/HeaderCell';
import BodyCell from '@/components/DispatchUnassigned/table/BodyCell';
import BodyRow from '@/components/DispatchUnassigned/table/BodyRow';
import { useClientUser } from '@/hooks';
import { useSticky } from 'react-table-sticky';
import { IndeterminateCheckbox } from '../DispatchUnassigned/table';

const RoutesTable = ({ routes, sortBy, handleSortBy, tabIndex, selectedRouteIds, setSelectedRouteIds }) => {
    const navigate = useNavigate();
    const TABLE_COLUMNS = useColumns({ routes, tabIndex });
    const { user_id } = useClientUser();

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, state } = useTable(
        {
            columns: TABLE_COLUMNS.filter((col) => !col.hidden),
            data: routes,
            getRowId: (route) => route.route_id,
            autoResetHiddenColumns: false,
            initialState: {
                selectedRowIds: selectedRouteIds,
                sortBy,
            },
            manualSortBy: true,
            enableRowSelection: true,
            enableMultiRowSelection: true,
            autoResetSelectedRows: true,
        },
        useSortBy,
        useRowSelect,
        useSticky,
        useFlexLayout,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: 'selection',
                    sticky: 'left',
                    width: 50,
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                    ),
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox
                            onClick={(e) => e.stopPropagation()}
                            {...row.getToggleRowSelectedProps()}
                        />
                    ),
                },
                ...columns,
            ]);
        }
    );

    useEffect(() => {
        if (state?.sortBy?.length) {
            handleSortBy(state.sortBy[0]);
        }
    }, [state?.sortBy]);

    useEffect(() => {
        setSelectedRouteIds(state?.selectedRowIds);
    }, [state?.selectedRowIds]);

    const onClick = (route) => {
        if (route.carrier_id === user_id) {
            navigate(`/carrier/routedetails/${route.route_id}`, { state: { source: 'My Routes' } });
        } else {
            navigate(`/shipper/routedetails/${route.route_id}`, { state: { source: 'My Routes' } });
        }
    };

    const Row = useCallback(
        ({ index, style }) => {
            //HEADERS
            if (index === 0) {
                const [group] = headerGroups;

                return (
                    <div {...group.getHeaderGroupProps({ style })}>
                        {group.headers.map((col) => (
                            <HeaderCell {...col.getHeaderProps()} key={col.id} cell={col} align={col.align} />
                        ))}
                    </div>
                );
            }

            //BODY
            const row = rows[index - 1];
            prepareRow(row);

            return (
                <BodyRow
                    {...row.getRowProps({
                        style,
                    })}
                    row={row}
                    onClick={() => onClick(row.original)}
                >
                    {row.cells.map((cell) => (
                        <BodyCell {...cell.getCellProps()} cell={cell} align={cell.column.align} />
                    ))}
                </BodyRow>
            );
        },
        [prepareRow, rows]
    );

    return (
        <TableContainer
            css={css`
                height: 100%;
                overflow: hidden;
            `}
        >
            <div
                {...getTableProps()}
                css={css`
                    height: 100%;
                    display: flex;
                    flex-grow: 1;
                    flex-direction: column;
                `}
            >
                <div
                    {...getTableBodyProps()}
                    css={css`
                        display: flex;
                        flex-grow: 1;
                    `}
                >
                    <AutoSizer>
                        {({ height, width }) => (
                            <FixedSizeList height={height} itemCount={rows.length + 1} itemSize={57} width={width}>
                                {Row}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </div>
            </div>
        </TableContainer>
    );
};

export default RoutesTable;
