import { TAG_FIELDS } from '@/graphql/fragments/tags';
import { CLIENT_FIELDS } from '@/graphql/queries/clients';
import { gql } from '@apollo/client';

export const UPDATE_CLIENT = gql`
    ${CLIENT_FIELDS}
    mutation UpdateClient($client_id: uuid!, $update: clients_set_input!) {
        update_clients_by_pk(pk_columns: { client_id: $client_id }, _set: $update) {
            ...ClientFields
        }
    }
`;

export const UPSERT_TAGS = gql`
    ${TAG_FIELDS}
    mutation UpsertTag($tags: [tags_insert_input!]!) {
        insert_tags(
            objects: $tags
            on_conflict: { constraint: tags_pkey, update_columns: [tag, route_tag, accessorial_tag] }
        ) {
            returning {
                ...TagFields
            }
        }
    }
`;

export const DELETE_TAG = gql`
    mutation DeleteTag($tag_id: uuid!) {
        delete_tags_by_pk(tag_id: $tag_id) {
            tag_id
        }
    }
`;
