import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useTotalWeight, useTotalCubes } from './hooks';
import { LocationOn, NotListedLocation, Warning as WarningIcon } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { aggregateEvents, genAttributes } from '@onward-delivery/core';
import { delWindowToFormattedDateRange } from '@/utilities/delWindowToFormattedDate';
import { useOrderCarrierPricing, useOrderShipperPricing } from '@/hooks';

import { BodyCellText } from './blocks';
import { useClientUser } from '@/hooks';
import { startCase } from 'lodash';
import { RECEIVED_STATUSES } from '@/constants/manifestStatuses';
import { colors } from '@/styles';
import { LOCATION_TYPES } from '@/constants/locationTypes';

const Placeholder = styled.div`
    width: 24px;
    height: 24px;
`;

export const integerFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const dateShort = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
});

const rate = (rate, override, listingRate) => {
    if (override || override === 0) return `$${override.toFixed(2)}`;
    if (listingRate) {
        return `$${listingRate.toFixed(2)}`;
    }
    return rate ? `$${rate.toFixed(2)}` : '-';
};

export const useColumns = () => {
    const dateSort = useMemo(() => (rowA, rowB, columnId) => {
        const a = rowA.values.deldate;
        const b = rowB.values.deldate;

        return a > b ? 1 : a < b ? -1 : 0;
    });

    const { circles, userType, email, user_id, isImposter } = useClientUser();

    const carrierBrokerColumns = [
        {
            Header: 'Shipper',
            id: 'shipper',
            disableSortBy: false,
            Cell: ({ row: order }) => {
                return order?.original?.order_shipper?.business_name || '-';
            },
        },
        {
            Header: 'Rates',
            id: 'rates',
            // width: 300,
            disableSortBy: false,
            Cell: ({ row }) => {
                const order = row.original;
                if (order.oms) {
                    return order.order_revenue ? `$${order.order_revenue.toFixed(2)}` : '-';
                } else if (!order.oms && user_id === order.shipper_id) {
                    return useOrderShipperPricing(order);
                } else if (!order.oms && user_id === order.carrier_id) {
                    return useOrderCarrierPricing(order);
                } else {
                    return '-';
                }
            },
        },
    ];

    return useMemo(() => {
        return [
            {
                id: 'ordernum',
                Header: 'Order',
                accessor: (order) => order.order_number,
                // disableSortBy: true,
            },
            {
                id: 'po',
                Header: 'PO',
                accessor: (order) => order.po_number,
                Cell: ({ value: numbers }) => {
                    if (numbers) {
                        let split = numbers.trim().split(', ');
                        return `${split.slice(0, 2).join(', ')}${
                            split.length > 2 ? `... and ${split.length - 2} more` : ''
                        }`;
                    }

                    return '-';
                },
                // disableSortBy: true,
                width: 200,
            },
            {
                id: 'route',
                Header: 'Route',
                accessor: (order) => {
                    const pickup = order.routes.find((mapping) => mapping.type === 'PICKUP')?.route;
                    const dropoff = order.routes.find((mapping) => mapping.type !== 'PICKUP')?.route;
                    return [...(pickup ? [pickup?.route_number] : []), dropoff?.route_number || '-'].join(' ');
                },
                width: 100,
                disableSortBy: true,
            },
            {
                id: 'service_type',
                Header: 'Service Type',
                accessor: (order) => {
                    const { location_type } = genAttributes(order);
                    return order.service_level || LOCATION_TYPES[order[location_type]] || '-';
                },
                width: 200,
            },
            {
                id: 'customer',
                Header: 'Customer',
                accessor: (order) => order.dropoff_name,
                width: 300,
                disableSortBy: true,
            },
            {
                id: 'pickup',
                Header: 'Pick Up',
                accessor: (order) => `${order.pickup_city}, ${order.pickup_state} ${order.pickup_zip || ''}`,
                width: 275,
                disableSortBy: true,
            },
            {
                id: 'dropoff',
                Header: 'Drop Off',
                accessor: (order) => `${order.dropoff_city}, ${order.dropoff_state} ${order.dropoff_zip || ''}`,
                width: 275,
            },
            {
                id: 'deldate',
                Header: 'Scheduled Delivery',
                accessor: (order) => {
                    return order?.delivery_date_formatted || '-';
                },
                width: 200,
            },
            {
                Header: 'Estimated Delivery Date Range',
                id: 'estimated_delivery_range',
                width: 300,
                disableSortBy: false,
                Cell: ({ row: order }) => {
                    if (!order.original.estimated_delivery_range_end && !order.original.estimated_delivery_range_end) {
                        return '-';
                    }
                    return `${order.original.estimated_delivery_range_start_formatted} - ${order.original.estimated_delivery_range_end_formatted}`;
                },
            },
            {
                id: 'available_dates',
                Header: 'Customer Availability',
                accessor: (order) => {
                    return (order?.alternative_delivery_dates_formatted || []).slice(0, 3).join(', ');
                },
                width: 200,
            },
            ...(userType === 'carrier' || circles?.['broker'] ? carrierBrokerColumns : []),
            {
                id: 'vol',
                Header: 'Cu. Ft.',
                Cell: ({ row: order }) => {
                    const cubes = useTotalCubes([order.original]);

                    return integerFormatter.format(cubes);
                },
                disableSortBy: true,
            },
            {
                id: 'weight',
                Header: 'Weight (lbs)',
                Cell: ({ row: order }) => {
                    const weight = useTotalWeight([order.original]);

                    return integerFormatter.format(weight);
                },
                disableSortBy: true,
            },
            {
                id: 'manufacturer',
                Header: 'Manufacturer',
                accessor: (order) => order?.manufacturer || '-',
                disableSortBy: true,
            },
            {
                id: 'driver',
                Header: 'Driver',
                accessor: (order) => {
                    const customerRoute = (order.routes || []).find((mapping) =>
                        ['FULL', ...(order.order_type === 'return' ? ['PICKUP'] : ['DROPOFF'])].includes(mapping.type)
                    );
                    return customerRoute?.route?.teammateByDriverId?.username;
                },
                Cell: ({ value: driver }) => {
                    if (driver) {
                        let nameArray = driver.trim().split(' ');
                        let modifiedNameArray = [nameArray[0]];
                        for (let i = 1; i < nameArray.length; i++) {
                            if (!nameArray[i]) continue;
                            modifiedNameArray.push(`${nameArray[i][0].toUpperCase()}.`);
                        }
                        return modifiedNameArray.join(' ');
                    }

                    return '-';
                },
                disableSortBy: true,
            },
            {
                id: 'delWindow',
                Header: 'Delivery Window',
                accessor: (order) => {
                    if (!order?.del_window_start) return 'TBD';

                    const delWindow = delWindowToFormattedDateRange(
                        order.del_window_start,
                        order.del_window_end,
                        order.dropoff_zip
                    );

                    if (!delWindow) return 'TBD';

                    if (delWindow.includes('\n')) {
                        const timeframes = delWindow.split('\n');
                        return (
                            <div
                                css={css`
                                    display: flex;
                                    flex-direction: column;
                                `}
                            >
                                <span>{timeframes[0]}</span>
                                <span>{timeframes[1]}</span>
                            </div>
                        );
                    } else {
                        return delWindow;
                    }
                },
                width: 250,
                disableSortBy: true,
            },
            {
                id: 'owner',
                Header: 'Owner',
                accessor: (order) => (order.oms ? 'Internal' : order.carrier_id === user_id ? 'Claimed' : 'Onward'),
                disableSortBy: true,
            },
            {
                id: 'type',
                Header: 'Type',
                accessor: (order) =>
                    order.order_type
                        ? order.order_type.charAt(0).toUpperCase() + order.order_type.slice(1)
                        : 'Delivery',
                disableSortBy: true,
            },
            {
                id: 'target_delivery_date',
                Header: 'Target Delivery Date',
                accessor: (order) => {
                    return new Date(order?.target_delivery_date);
                },
                Cell: ({ value }) => {
                    if (isNaN(value) || !value?.getTime()) {
                        return '-';
                    }

                    return dateShort.format(value);
                },
                width: 200,
                disableSortBy: true,
            },
            {
                id: 'reference_id',
                Header: 'Reference ID',
                accessor: (order) => order?.reference_id || '-',
                disableSortBy: true,
            },
            {
                id: 'status',
                Header: 'Status',
                Cell: ({ row: order }) => {
                    let fragment;
                    let label;
                    let unknown = false;
                    switch (order.original.order_status) {
                        case 'pending': {
                            label = 'Pending';
                            fragment = css`
                                color: #ff903d;
                            `;
                            break;
                        }
                        case 'claimed':
                        case 'routed':
                        case 'routedOpen':
                        case 'routedActive':
                        case 'open':
                        case 'active': {
                            label = 'Pre-transit';
                            fragment = css`
                                color: #4c4c4c;
                            `;
                            break;
                        }
                        case 'inProgress':
                        case 'routedInProgress': {
                            label = 'In-transit';
                            fragment = css`
                                color: #197ef3;
                            `;
                            break;
                        }
                        case 'complete':
                        case 'routedComplete': {
                            label = 'Complete';
                            fragment = css`
                                color: #59b863;
                            `;
                            break;
                        }
                        case 'rejected': {
                            label = 'Rejected';
                            fragment = css`
                                color: #d23e3e;
                            `;
                            break;
                        }
                        case 'cancelled': {
                            label = 'Cancelled';
                            fragment = css`
                                color: #d23e3e;
                            `;
                            break;
                        }
                        case 'rescheduled': {
                            label = 'Rescheduled';
                            fragment = css`
                                color: #ff903d;
                            `;
                            break;
                        }
                        default:
                            unknown = true;
                    }

                    return (
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                                white-space: nowrap;
                            `}
                        >
                            {unknown ? (
                                <NotListedLocation
                                    css={css`
                                        color: #000;
                                    `}
                                />
                            ) : (
                                <LocationOn
                                    css={css`
                                        ${fragment}
                                    `}
                                />
                            )}
                            <BodyCellText>{unknown ? 'Unknown' : label}</BodyCellText>
                        </div>
                    );
                },
                disableSortBy: true,
            },
            {
                id: 'middle_mile_integration_status',
                Header: 'Middle Mile Status',
                width: 200,
                accessor: (order) => {
                    return order.middle_mile_integration_status;
                },
                Cell: ({ row: order, value }) => {
                    return value ? startCase(value.toLowerCase()).replace('_', '-') : '--';
                },
                disableSortBy: true,
            },
            {
                id: 'middle_mile_integration_meta.tracking_number',
                Header: 'Tracking / Pro #',
                width: 200,
                accessor: (order) => {
                    return order.middle_mile_integration_meta?.tracking_number;
                },
                Cell: ({ row: order, value }) => {
                    return value || '-';
                },
                disableSortBy: true,
            },
            {
                id: 'warehousestatus',
                Header: 'Warehousing Status',
                accessor: (order) => {
                    const warehouse = aggregateEvents(order.wh_events);
                    return warehouse.status;
                },
                Cell: ({ row, value }) => {
                    let fragment;
                    let label;
                    let icon = null;
                    const order = row.original;
                    switch (value) {
                        case 'RECEIVED': {
                            label = 'Received';
                            fragment = css`
                                color: ${colors.greens.primary};
                            `;
                            break;
                        }
                        case 'NOT_DELIVERED':
                        default: {
                            const [received, total] = (order.itemsByOrderId || []).reduce(
                                ([recAcc, totAcc], item) => {
                                    return [
                                        recAcc +
                                            (RECEIVED_STATUSES.includes(item.pallet?.warehouse_status)
                                                ? item.quantity || 1
                                                : 0),
                                        totAcc + (item.quantity || 1),
                                    ];
                                },
                                [0, 0]
                            );

                            if (received > 0) {
                                label = `${received}/${total} Received`;
                                fragment = css`
                                    color: ${colors.blues[0]};
                                `;
                            } else {
                                label = 'Not Received';
                                fragment = css`
                                    color: ${colors.greys[3]};
                                `;
                            }
                            break;
                        }
                    }

                    return (
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                                white-space: nowrap;
                            `}
                        >
                            <BodyCellText
                                css={css`
                                    font-weight: 700;
                                    ${fragment};
                                `}
                            >
                                {label}
                            </BodyCellText>
                            {icon}
                        </div>
                    );
                },
                width: 200,
            },
            {
                id: 'est_ship_date',
                Header: 'Estimated Ship Date',
                accessor: (order) => {
                    const warehouse = aggregateEvents(order.wh_events);
                    return new Date(warehouse.est_ship_date);
                },
                Cell: ({ value }) => {
                    if (isNaN(value) || !value?.getTime()) {
                        return '-';
                    }

                    return dateShort.format(value);
                },
                width: 200,
            },
            {
                id: 'est_received_date',
                Header: 'Estimated Received Date',
                accessor: (order) => {
                    const warehouse = aggregateEvents(order.wh_events);
                    return new Date(warehouse.est_received_date);
                },
                Cell: ({ value }) => {
                    if (isNaN(value) || !value?.getTime()) {
                        return '-';
                    }

                    return dateShort.format(value);
                },
                width: 200,
            },
            {
                id: 'received_date',
                Header: 'Actual Received Date',
                accessor: (order) => {
                    const warehouse = aggregateEvents(order.wh_events);
                    return new Date(warehouse.received_date);
                },
                Cell: ({ value }) => {
                    if (isNaN(value) || !value?.getTime()) {
                        return '-';
                    }

                    return dateShort.format(value);
                },
                width: 200,
            },
        ];
    }, []);
};
