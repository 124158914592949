import { gql } from '@apollo/client';

import { PRICING_TARIFF_FIELDS } from '@/graphql/fragments/pricing_tariffs';
import { RATE_FIELDS } from '@/graphql/fragments/rates';

export const LIST_GLOBAL_TARIFFS = gql`
    ${PRICING_TARIFF_FIELDS}
    ${RATE_FIELDS}

    query ListGlobalTariffsByClientId {
        tariffs: pricing_tariffs(
            where: { client_id: { _is_null: true }, tariff_type: { _eq: "ORDER" } }
            order_by: [{ algo_type: desc }, { created_at: desc }]
        ) {
            ...PricingTariffFields

            rates {
                ...RateFields
            }
        }
    }
`;
