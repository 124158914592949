import React, { useState, useEffect, useMemo } from 'react';
import { compose } from 'recompose';
import { useParams } from 'react-router';
import { css } from '@emotion/react';
import { Grid, TextField, MenuItem, IconButton } from '@material-ui/core';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import { UPDATE_CLIENT_ADMIN_SHIPPER, DEACTIVATE_CLIENT_ADMIN } from '@/graphql/mutations/clients';
import { QUERY_BY_PK_WITH_USER } from '@/graphql/queries/clients';
import Blacklist from '../../Blacklist';
import { Rating } from '@material-ui/lab';
import * as Sentry from '@sentry/react';
import ShipperHours from './ShipperHours';
import './style.css';
import Snackbar from '../../Snackbar';
import withErrorBoundary from '../../ErrorBoundary';
import AdminFeatureFlags from '../AdminFeatureFlags/index.js';
import withAdminRights from '@/components/Auth/withAdminRights';
import { OnwardCheckbox } from '@/components/ShipmentForm/blocks';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { AGGREGATE_RATING_BY_REVIEWEE } from '@/graphql/queries/ratings';
import { toNational, toE164, validate } from '@/utilities/formatPhoneNumber';
import { DELETE_PRICING_OVERRIDE, UPSERT_PRICING_OVERRIDES } from '../../Account/Tariffs/graphql';
import { LIST_ADMIN_TARIFFS_BY_CLIENT_ID } from '@/graphql/queries/pricing_tariffs';
import { getTariffName } from '../../Account/Tariffs/utils';
import { useNavigate } from 'react-router-dom';
import EventSubscriptions from './EventSubscriptions';
import { PrimaryButton } from '@/styles/blocks';
import { v4 as uuid } from 'uuid';
import { DeleteForeverOutlined } from '@material-ui/icons';

const AdminShipperDetails = () => {
    const { shipper_id } = useParams();
    const [business, setBusiness] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [billingEmail, setBillingEmail] = useState('');
    const [address, setAddress] = useState('');
    const [billingAddress, setBillingAddress] = useState('');
    const [billingCity, setBillingCity] = useState('');
    const [billingState, setBillingState] = useState('');
    const [billingZip, setBillingZip] = useState('');
    const [paymentType, setPaymentType] = useState('');
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [rating, setRating] = useState(null);
    const [validated, setValidated] = useState(false);
    const [userObject, setUserObject] = useState({});
    const [blacklist, setBlacklist] = useState([]);
    const [shipper, setShipper] = useState({});
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();

    useQuery(QUERY_BY_PK_WITH_USER, {
        variables: { client_id: shipper_id },
        onCompleted: (data) => {
            const shipperInfo = data.clients_by_pk;
            setBusiness(shipperInfo.business_name);
            setUsername(shipperInfo.user.username);
            setEmail(shipperInfo.email);
            setAddress(shipperInfo.business_address);
            setBillingEmail(shipperInfo.billing_email);
            setBillingAddress(shipperInfo.billing_address);
            setBillingCity(shipperInfo.billing_city);
            setBillingState(shipperInfo.billing_state);
            setBillingZip(shipperInfo.billing_zip);
            setUserObject(shipperInfo.user);
            setPaymentType(shipperInfo.payment_type);
            setBlacklist(shipperInfo.blacklisted_clients);
            setShipper(shipperInfo);
            const _phone = shipperInfo.business_phone
                ? validate(shipperInfo.business_phone)
                    ? toNational(shipperInfo.business_phone)
                    : shipperInfo.business_phone
                : '';
            setPhone(_phone);
        },
        onError: (error) => {
            Sentry.captureException(error);
        },
    });

    useQuery(AGGREGATE_RATING_BY_REVIEWEE, {
        variables: {
            reviewee_id: shipper_id,
        },
        onCompleted: ({ results }) => {
            setRating((results?.aggregate?.avg?.rating || 0).toFixed(1));
        },
        onError: (error) => Sentry.captureException(error),
    });

    const [updateAccountInfo, { loading: submitting }] = useMutation(UPDATE_CLIENT_ADMIN_SHIPPER, {
        onError: (error) => {
            Sentry.captureException(error);
        },
    });

    const [deactivateAccount] = useMutation(DEACTIVATE_CLIENT_ADMIN, {
        onError: (error) => {
            Sentry.captureException(error);
        },
        refetchQueries: [{ query: QUERY_BY_PK_WITH_USER, variables: { client_id: shipper_id } }],
    });

    const handleSave = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === true) {
            updateAccountInfo({
                variables: {
                    client_id: shipper_id,
                    business_name: business,
                    username: username,
                    email: email,
                    business_phone: validate(phone) ? toE164(phone) : phone,
                    business_address: address,
                    billing_email: billingEmail,
                    billing_address: billingAddress,
                    billing_city: billingCity,
                    billing_state: billingState,
                    billing_zip: billingZip,
                    payment_type: paymentType,
                },
            });
        }

        setValidated(true);
    };

    const [fetchTariffs, { data: tariffsResp, loading }] = useLazyQuery(LIST_ADMIN_TARIFFS_BY_CLIENT_ID);

    const [tariffs, overrides] = useMemo(() => {
        if (tariffsResp) {
            const { tariffs, overrides } = tariffsResp;
            const grouped = tariffs.reduce((acc, tariff) => {
                acc[tariff.algo_type] = [...(acc[tariff.algo_type] || []), tariff];
                return acc;
            }, {});

            return [grouped, overrides];
        }

        return [[], []];
    }, [tariffsResp]);

    useEffect(() => {
        fetchTariffs({
            variables: {
                client_id: shipper_id,
            },
        });
    }, [shipper_id]);

    const [upsertPo] = useMutation(UPSERT_PRICING_OVERRIDES, {
        update: (cache, { data: { updated } }) => {
            const [update] = updated;
            cache.updateQuery(
                {
                    query: LIST_ADMIN_TARIFFS_BY_CLIENT_ID,
                    variables: {
                        client_id: shipper_id,
                    },
                },
                (data) => {
                    let { tariffs, overrides } = data;
                    let clone = [...overrides];

                    const idx = clone.findIndex((po) => po.pricing_override_id === update.pricing_override_id);
                    if (idx >= 0) {
                        clone[idx] = update;
                    } else {
                        clone = [...clone, update];
                    }

                    return {
                        tariffs,
                        overrides: clone,
                    };
                }
            );
        },
        onError: (e) => {
            Sentry.captureException(e);
        },
    });

    const [deletePo] = useMutation(DELETE_PRICING_OVERRIDE, {
        update: (cache, { data: { deleted } }) => {
            cache.updateQuery(
                {
                    query: LIST_ADMIN_TARIFFS_BY_CLIENT_ID,
                    variables: {
                        client_id: shipper_id,
                    },
                },
                (data) => {
                    let { tariffs, overrides } = data;
                    let clone = [...overrides];

                    clone = clone.filter((po) => po.pricing_override_id !== deleted.pricing_override_id);

                    return {
                        tariffs,
                        overrides: clone,
                    };
                }
            );
        },
        onError: (e) => {
            Sentry.captureException(e);
        },
    });

    const handleDeactivate = () => {
        setShowDeleteModal(false);
        deactivateAccount({
            variables: {
                client_id: shipper_id,
                deactivated: true,
            },
        })
            .then(() => {
                navigate('/admin/users');
            })
            .catch((err) => {
                setErrorMessage('Something went wrong while deactivating account, please contact support');
                console.error(err);
            });
    };

    return (
        <div className="mx-2">
            <EmailModal show={showEmailModal} onHide={() => setShowEmailModal(false)} />
            <DeleteModal
                handleDeactivate={handleDeactivate}
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
            />
            <h1>Admin Shipper Profile</h1>
            <hr />
            <div className="">
                <p className="">Database UID: {shipper_id}</p>
                <p className="">Firebase/Auth ID: {userObject.legacy_user_id}</p>

                <div className="d-flex">
                    <p className="mb-3">Rating: </p>
                    {rating && <Rating readOnly value={rating} precision={0.5} />}
                    <p className="ms-2">{rating || 'No ratings yet'}</p>
                </div>
                <Form noValidate validated={validated} onSubmit={handleSave}>
                    <Form.Group className="my-2 d-flex align-items-center flex-wrap">
                        <Form.Label className="Admin-label-width">Business:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Business"
                            value={business}
                            onChange={(e) => setBusiness(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid" width="100%">
                            A business name is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center flex-wrap">
                        <Form.Label className="Admin-label-width">Username:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid" width="100%">
                            A username is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center flex-wrap">
                        <Form.Label className="Admin-label-width">Email:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Email"
                            value={email}
                            onClick={() => setShowEmailModal(true)}
                        />
                        <Form.Control.Feedback type="invalid" width="100%">
                            An email is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center flex-wrap">
                        <Form.Label className="Admin-label-width">Phone:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid" width="100%">
                            A phone number is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center flex-wrap">
                        <Form.Label className="Admin-label-width">Address:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid" width="100%">
                            An address is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center">
                        <Form.Label className="Admin-label-width">Billing Email:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Billing Email"
                            value={billingEmail}
                            onChange={(e) => setBillingEmail(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center">
                        <Form.Label className="Admin-label-width">Billing Address:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Billing Address"
                            value={billingAddress}
                            onChange={(e) => setBillingAddress(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center">
                        <Form.Label className="Admin-label-width">Billing City:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Billing City"
                            value={billingCity}
                            onChange={(e) => setBillingCity(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center">
                        <Form.Label className="Admin-label-width">Billing State:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Billing State"
                            value={billingState}
                            onChange={(e) => setBillingState(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center">
                        <Form.Label className="Admin-label-width">Billing Zip:</Form.Label>
                        <Form.Control
                            className="flex-1"
                            type="text"
                            placeholder="Billing Zip"
                            value={billingZip}
                            onChange={(e) => setBillingZip(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="my-2 d-flex align-items-center" controlId="formHorizontalPhone">
                        <Row className="w-50">
                            <Form.Label className="Admin-label-width">Payment Type:</Form.Label>
                            <Col>
                                <Form.Control
                                    label="payment type"
                                    as="select"
                                    style={{
                                        width: '100%',
                                        border: 'solid',
                                        borderWidth: 'thin',
                                        borderRadius: 'unset',
                                        marginTop: 0,
                                        marginRight: '25%',
                                    }}
                                    className="form-select"
                                    value={paymentType}
                                    defaultValue={paymentType}
                                    placeholder="Payment Type"
                                    onChange={(e) => setPaymentType(e.target.value)}
                                >
                                    <option key="1" value="">
                                        --
                                    </option>
                                    <option key="2" value="Invoice">
                                        Invoice
                                    </option>
                                    <option key="3" value="CreditCard">
                                        Credit Card
                                    </option>
                                </Form.Control>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group
                        className="d-flex justify-content-center align-items-center"
                        style={{ marginBottom: '30px' }}
                    >
                        <Button type="submit" className="mx-3">
                            Save Changes
                        </Button>
                        <Button onClick={() => setShowDeleteModal(true)} variant="danger">
                            Delete Account
                        </Button>
                    </Form.Group>
                </Form>
                <hr></hr>

                <Grid direction="column" container>
                    <Grid
                        direction="row"
                        container
                        css={css`
                            margin-bottom: 8px;
                        `}
                    >
                        Marketplace Tariff(s)
                    </Grid>
                    {(overrides || [{}]).map((override) => {
                        return (
                            <Grid
                                direction="row"
                                container
                                css={css`
                                    margin-bottom: 12px;
                                `}
                            >
                                <Grid
                                    direction="column"
                                    container
                                    css={css`
                                        flex: 1;
                                        flex-basis: 0;
                                    `}
                                >
                                    <TextField
                                        fullWidth
                                        select
                                        variant="outlined"
                                        label="Type"
                                        size="small"
                                        value={override.algo_type || 'DEFAULT'}
                                        onChange={(e) => {
                                            upsertPo({
                                                variables: {
                                                    override: {
                                                        pricing_override_id: override.pricing_override_id,
                                                        client_id: shipper_id,
                                                        partner_client_id: null,
                                                        tariff_id: null,
                                                        algo_type: e.target.value,
                                                        oms: false,
                                                    },
                                                },
                                            });
                                        }}
                                    >
                                        {[
                                            'BUNGII',
                                            'FRAYT',
                                            'DEFAULT',
                                            'LIVING_SPACES',
                                            'ROCKET_SHIPPING',
                                            'ONWARD_CONSOLIDATED',
                                        ].map((type) => (
                                            <MenuItem key={type} value={type}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid
                                    direction="column"
                                    container
                                    css={css`
                                        flex: 1;
                                        flex-basis: 0;
                                    `}
                                >
                                    <TextField
                                        fullWidth
                                        select
                                        variant="outlined"
                                        label="Tariff"
                                        size="small"
                                        value={override.tariff_id || ''}
                                        onChange={(e) => {
                                            upsertPo({
                                                variables: {
                                                    override: {
                                                        pricing_override_id: override.pricing_override_id,
                                                        client_id: shipper_id,
                                                        partner_client_id: null,
                                                        tariff_id: e.target.value,
                                                        algo_type: override?.algo_type || 'DEFAULT',
                                                        oms: false,
                                                    },
                                                },
                                            });
                                        }}
                                    >
                                        {(tariffs[override.algo_type] || []).map((tariff, idx) => (
                                            <MenuItem key={tariff.tariff_id} value={tariff.tariff_id}>
                                                {getTariffName(tariff, idx)}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid
                                    direction="column"
                                    container
                                    css={css`
                                        flex: 1;
                                        flex-basis: 0;
                                    `}
                                >
                                    <OnwardCheckbox
                                        label={'Enable Fuel Surcharge'}
                                        checked={!!override.add_fuel_surcharge}
                                        onChange={(e) =>
                                            upsertPo({
                                                variables: {
                                                    override: {
                                                        pricing_override_id: override.pricing_override_id,
                                                        client_id: shipper_id,
                                                        partner_client_id: null,
                                                        algo_type: override?.algo_type || 'DEFAULT',
                                                        oms: false,
                                                        add_fuel_surcharge: e.target.checked,
                                                    },
                                                },
                                            })
                                        }
                                    />
                                </Grid>
                                <IconButton
                                    css={css`
                                        padding-top: 0;
                                        margin-top: 0;
                                    `}
                                    onClick={() => {
                                        deletePo({
                                            variables: {
                                                pricing_override_id: override.pricing_override_id,
                                            },
                                        });
                                    }}
                                >
                                    <DeleteForeverOutlined fontSize="large" color={'error'} />
                                </IconButton>
                            </Grid>
                        );
                    })}
                    <Grid
                        container
                        css={css`
                            margin-bottom: 24px;
                        `}
                    >
                        <PrimaryButton
                            disabled={overrides?.length > 0}
                            onClick={() =>
                                upsertPo({
                                    variables: {
                                        override: {
                                            pricing_override_id: uuid(),
                                            client_id: shipper_id,
                                            partner_client_id: null,
                                            algo_type: 'ONWARD_CONSOLIDATED',
                                            tariff_id: null,
                                            oms: false,
                                        },
                                    },
                                })
                            }
                        >
                            Add Tariff
                        </PrimaryButton>
                    </Grid>
                </Grid>

                <hr></hr>

                <AdminFeatureFlags
                    client={shipper}
                    uid={shipper_id}
                    userObject={userObject}
                    setUserObject={setUserObject}
                />

                <hr></hr>

                <ShipperHours shipper={shipper} shipperId={userObject.user_id} />
                <hr></hr>
                <h5 className="mt-3">Blacklist:</h5>
                <div>Choose companies to blacklist for this shipper</div>
                <div className="mb-3">
                    <Blacklist blacklistType="shipper" currentUser={shipper_id} clientBlacklist={blacklist} />
                </div>

                <hr />
                <h5>Partnered Carriers</h5>
                <div>
                    These carriers are currently partnered with this shipper. They may be unpartnered from the carrier's
                    details page.
                </div>
                {shipper?.partner_carriers?.map((carr) => (
                    <h6>{carr.business_name}</h6>
                ))}
                <hr />

                <EventSubscriptions client_id={shipper_id} />

                <hr />
            </div>

            <Snackbar
                open={!!errorMessage}
                handleClose={() => setErrorMessage('')}
                severity="error"
                message={errorMessage}
            />
        </div>
    );
};

const EmailModal = (props) => {
    const { show, onHide } = props;

    return (
        <Modal className="mt-5" show={show} onHide={onHide}>
            <Modal.Header>Email Change</Modal.Header>

            <Modal.Body>
                Changing the primary email will alter how the user logs in, and should only be done by the user.
            </Modal.Body>
        </Modal>
    );
};

const DeleteModal = (props) => {
    const { show, onHide, handleDeactivate } = props;

    return (
        <Modal className="mt-5" show={show} onHide={onHide}>
            <Modal.Header>Delete Account</Modal.Header>

            <Modal.Body>
                Please confirm the deletion of this account.
                <div className="d-flex justify-content-center" style={{ marginTop: '8px' }}>
                    <Button type="submit" className="mx-3" onClick={handleDeactivate}>
                        Confirm
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default compose(withAdminRights, withErrorBoundary)(AdminShipperDetails);
