export const MAX_ROUTES = 50;

export const SELECTED_COLOR = '#59B863';

export const UNASSIGNED_COLOR = '#2B2B2B';

const BASE_ROUTE_COLORS = [
    '#007BFF',
    '#FF903D',
    '#A54DFD',
    '#6ECAD6',
    '#FF000F',
    '#FDCB1B',
    '#01AD27',
    '#F94DFD',
    '#104BA4',
    '#8BE789',
];

const routeColors = [...BASE_ROUTE_COLORS];

// Limits RGB total of color being too high (or too close to white) or too low (too close to black)
const combinedFloor = 65;
const combinedCeiling = 735;

while (routeColors.length < MAX_ROUTES) {
    let red = Math.floor(Math.random() * 255);
    let blue = Math.floor(Math.random() * 255);

    let floor = Math.max(0, combinedFloor - red - blue);
    let ceiling = Math.min(255, combinedCeiling - red - blue);

    let green = Math.floor(Math.random() * (ceiling - floor) + floor);

    routeColors.push(`rgba(${red}, ${blue}, ${green}, 1.0)`);
}

export const ROUTE_COLORS = routeColors;

export const MODALS = {
    COMMUNICATIONS: 'COMMUNICATIONS',
    ORDER_EDIT: 'ORDER_EDIT',
    ORDER_OMS: 'ORDER_OMS',
    ORDER_MARKETPLACE: 'ORDER_MARKETPLACE',
    ORDER_RESCHEDULE: 'ORDER_RESCHEDULE',
    ORDER_UNASSIGNED_MARKETPLACE: 'ORDER_UNASSIGNED_MARKETPLACE',
    ORDER_VIEW: 'ORDER_VIEW',
    ROUTE_EXPORT: 'ROUTE_EXPORT',
    ROUTE_INTERNAL: 'ROUTE_INTERNAL',
    ROUTE_MARKETPLACE: 'ROUTE_MARKETPLACE',
    ROUTE_REMOVE: 'ROUTE_REMOVE',
    ROUTE_REMOVE_STOP: 'ROUTE_REMOVE_STOP',
    ROUTE_RESCHEDULE: 'ROUTE_RESCHEDULE',
    ROUTE_UNLOCK: 'ROUTE_UNLOCK',
    SENT_TO_ONWARD: 'SENT_TO_ONWARD',
    CLAIM_TC: 'CLAIM_TC',
    PLACE_BID: 'PLACE_BID',
    DRIVER_OVERLAP: 'DRIVER_OVERLAP',
    SETTINGS: 'SETTINGS',
    DRIVER_LUNCH: 'DRIVER_LUNCH',
    CUSTOM_TIMEFRAME: 'CUSTOM_TIMEFRAME',
    NOTES: 'NOTES',
    STOP_WINDOW: 'STOP_WINDOW',
    DAILY_TRUCKS_EXCEEDED: 'DAILY_TRUCKS_EXCEEDED',
    AWAITING_CD: 'AWAITING_CD',
};

export const TEMP_ROUTE = {
    orders: [],
    stopsByRouteId: [],
    route_id: '13133fcd-8316-45ec-bd63-7f356e71de44',
    bulk_bol: null,
    carrier_id: null,
    driver_id: null,
    helper_id: null,
    legacy_carrier_id: null,
    claimed_at: null,
    complete: null,
    completed: null,
    created_at: new Date().toISOString(),
    created_by: null,
    delivery_by: null,
    legacy_driver_id: null,
    estimated_driving_distance: null,
    estimated_driving_time: null,
    finish_returns_next_day: null,
    geo_timestamps: null,
    handle_orders_on_delete: null,
    is_new_route: null,
    last_modified_at: new Date().toISOString(),
    last_modified_by: null,
    last_modified_source: null,
    last_update_source: null,
    legacy_route_id: null,
    legacy_shipper_id: null,
    margin: null,
    need_to_optimize: true,
    need_to_submit: false,
    oms: null,
    pending_confirmations: null,
    pickup_time: null,
    planning: true,
    route_number: Infinity,
    scheduled_delivery: null,
    sending_to: null,
    source_form: 'PLANNING',
    start_time: null,
    status: 'planning',
    test_acc: null,
    total_carrier_rate: null,
    total_cubes: null,
    total_shipper_rate: null,
    truck_id: null,
    route_alias: null,
    driver_id_final_return: null,
    route_carrier: null,
    route_shipper: null,
    __typename: 'routes',
};
