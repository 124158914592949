import { DRIVER_PAYMENT_FIELDS } from '@/graphql/fragments/driver_payments';
import { ORDER_FIELDS } from '@/graphql/queries/orders';
import { gql } from '@apollo/client';

export const UPDATE_DRIVER_PAYMENT = gql`
    ${DRIVER_PAYMENT_FIELDS}

    mutation UpdateDriverPayment($payment_id: uuid!, $update: driver_payments_set_input!) {
        update_driver_payments_by_pk(pk_columns: { payment_id: $payment_id }, _set: $update) {
            ...DriverPaymentFields
        }
    }
`;

export const UPDATE_ORDERS = gql`
    mutation UpdateDriverCosts($orders: [orders_insert_input!] = []) {
        insert_orders(objects: $orders, on_conflict: { constraint: orders_pkey, update_columns: [carrier_costs] }) {
            affected_rows
            returning {
                order_id
                carrier_costs
            }
        }
    }
`;

export const GET_DRIVER_PAYMENT = gql`
    ${DRIVER_PAYMENT_FIELDS}
    ${ORDER_FIELDS}

    query GetDriverPayment($payment_id: uuid!) {
        driver_payment: driver_payments_by_pk(payment_id: $payment_id) {
            ...DriverPaymentFields
            orders {
                ...OrderFields
                routes {
                    mapping_id
                    route {
                        route_id
                        route_number
                        route_alias
                        estimated_driving_distance
                    }
                }
            }
        }
    }
`;
