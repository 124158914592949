import React, { useContext, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import { useClientUser } from '@/hooks/useClientUser';

import StickyFooter from '../../StickyFooter';
import { Context } from '../store';
import { SHIPMENT_FORM_STATE } from '../constants';

import { PrimaryButton, SecondaryButton } from '@/styles/blocks';

export default function Footer() {
    const { state, callbacks, errors } = useContext(Context);
    const { circles } = useClientUser();

    const isValid = useMemo(() => {
        return (
            Object.keys(state.orders).length > 0 &&
            Object.entries(errors.missingFieldsByOrder).every(([key, tabs]) => {
                return Object.entries(tabs).every(([tab, hasError]) => !hasError);
            })
        );
    }, [errors, state.orders]);

    return (
        <StickyFooter
            styles={{
                margin: 0,
                boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.25)',
            }}
        >
            <Grid
                container
                direction="row"
                css={css`
                    justify-content: space-between;
                `}
            >
                <Grid item>
                    <SecondaryButton
                        css={css`
                            margin-left: 15px;
                        `}
                        onClick={() => callbacks.reset()}
                        disabled={state.isSubmitting}
                    >
                        Cancel
                    </SecondaryButton>
                </Grid>

                <Grid item>
                    {circles?.['ltl-marketplace'] && !circles?.['saas-v1'] ? (
                        <PrimaryButton
                            css={css`
                                width: 195px;
                            `}
                            onClick={() => callbacks.submit({ oms: false })}
                            disabled={state.formState !== SHIPMENT_FORM_STATE.REVIEW || state.isSubmitting || !isValid}
                        >
                            Price & Review
                        </PrimaryButton>
                    ) : null}
                    {circles?.['ltl-marketplace'] && circles?.['saas-v1'] ? (
                        <PrimaryButton
                            css={css`
                                margin-right: 30px;
                                width: 213px;
                            `}
                            onClick={() => callbacks.submit({ oms: false })}
                            disabled={state.formState !== SHIPMENT_FORM_STATE.REVIEW || state.isSubmitting || !isValid}
                        >
                            Send to Marketplace
                        </PrimaryButton>
                    ) : null}
                    {circles?.['saas-v1'] || circles?.['broker'] ? (
                        <PrimaryButton
                            css={css`
                                width: 195px;
                            `}
                            onClick={() => {
                                callbacks.submit({ oms: true });
                            }}
                            disabled={
                                state.formState !== SHIPMENT_FORM_STATE.REVIEW ||
                                state.isSubmitting ||
                                !!state?.pickupInfo?.is_middle_mile ||
                                !!state?.pickupInfo?.is_middle_mile_origin ||
                                !isValid
                            }
                        >
                            Send to OMS
                        </PrimaryButton>
                    ) : null}
                </Grid>
            </Grid>
        </StickyFooter>
    );
}
