import React, { useState, useEffect, useContext, useMemo } from 'react';
import { css } from '@emotion/react';
import {
    TextField,
    Grid,
    Typography,
    Button,
    Popover,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    ListItemText,
    Paper,
} from '@material-ui/core';
import { FilterList as FilterListIcon } from '@material-ui/icons';
import { FilterHeader, CustomCheckbox, BodyText } from './blocks';
import { Context } from './store';
import { useClientUser } from '@/hooks';
import { LOCATION_TYPES } from '@/constants/locationTypes';

function Filter() {
    const {
        state: { filters, orders },
        callbacks: { setFilters },
    } = useContext(Context);

    const {
        selectedZips = [],
        scheduledFrom,
        scheduledTo,
        createdFrom,
        createdTo,
        ordersLoading,
        warehouseFrom,
        warehouseTo,
        serviceType,
        locationType,
    } = filters;
    const { userType, circles, accountType, service_levels } = useClientUser();

    const zipOptions = useMemo(() => {
        return Object.keys(
            (orders || []).reduce((zips, order) => {
                return {
                    ...zips,
                    [order.dropoff_zip]: true,
                };
            }, {})
        );
    }, [orders]);

    const [selectedZipsLocal, setSelectedZipsLocal] = useState([]);
    const [ownerTypeLocal, setOwnerTypeLocal] = useState('All');
    const [warehouseStatusLocal, setWarehouseStatusLocal] = useState('Any');
    const [scheduledToLocal, setScheduledToLocal] = useState('');
    const [scheduledFromLocal, setScheduledFromLocal] = useState('');
    const [warehouseFromLocal, setWarehouseFromLocal] = useState('');
    const [warehouseToLocal, setWarehouseToLocal] = useState('');
    const [createdToLocal, setCreatedToLocal] = useState('');
    const [createdFromLocal, setCreatedFromLocal] = useState('');
    const [prefDateToLocal, setPrefDateToLocal] = useState('');
    const [prefDateFromLocal, setPrefDateFromLocal] = useState('');
    const [hasPrefDateLocal, setHasPrefDateLocal] = useState(false);
    const [serviceTypeLocal, setServiceTypeLocal] = useState('');
    const [locationTypeLocal, setLocationTypeLocal] = useState('');

    const [anchorEl, setAnchorEl] = useState(null);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        getContentAnchorEl: null,
    };
    useEffect(() => {
        setSelectedZipsLocal(selectedZips);
    }, [selectedZips]);
    useEffect(() => {
        setScheduledToLocal(scheduledTo);
    }, [scheduledTo]);
    useEffect(() => {
        setCreatedToLocal(createdTo);
    }, [createdTo]);
    useEffect(() => {
        setScheduledFromLocal(scheduledFrom);
    }, [scheduledFrom]);
    useEffect(() => {
        setCreatedFromLocal(createdFrom);
    }, [createdFrom]);
    useEffect(() => {
        setWarehouseFromLocal(warehouseFrom);
    }, [warehouseFrom]);
    useEffect(() => {
        setWarehouseToLocal(warehouseTo);
    }, [warehouseTo]);
    useEffect(() => {
        setServiceTypeLocal(serviceType);
    }, [serviceType]);
    useEffect(() => {
        setLocationTypeLocal(locationType);
    }, [locationType]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFilter = () => {
        setAnchorEl(null);
        setFilters({
            scheduledFrom: scheduledFromLocal,
            scheduledTo: scheduledToLocal,
            createdFrom: createdFromLocal,
            createdTo: createdToLocal,
            prefDateFrom: prefDateFromLocal,
            prefDateTo: prefDateToLocal,
            selectedZips: selectedZipsLocal,
            ownerType: ownerTypeLocal,
            warehouseStatus: warehouseStatusLocal,
            hasPrefDate: hasPrefDateLocal,
            warehouseFrom: warehouseFromLocal,
            warehouseTo: warehouseToLocal,
            serviceType: serviceTypeLocal,
            locationType: locationTypeLocal,
        });
    };

    const handleClear = async () => {
        setAnchorEl(null);
        setFilters({
            scheduledFrom: '',
            scheduledTo: '',
            createdFrom: '',
            createdTo: '',
            prefDateFrom: '',
            prefDateTo: '',
            selectedZips: [],
            ownerType: 'All',
            warehouseStatus: 'Any',
            hasPrefDate: false,
            warehouseFrom: '',
            warehouseTo: '',
            serviceType: '',
            locationType: '',
        });
        setScheduledFromLocal('');
        setScheduledToLocal('');
        setCreatedFromLocal('');
        setCreatedToLocal('');
        setOwnerTypeLocal('All');
        setWarehouseStatusLocal('All');
        setHasPrefDateLocal(false);
        setPrefDateFromLocal('');
        setPrefDateToLocal('');
        setWarehouseFromLocal('');
        setWarehouseToLocal('');
        setServiceTypeLocal('');
        setLocationTypeLocal('');
    };

    const serviceTypes = useMemo(() => {
        return [
            ...Object.entries(LOCATION_TYPES).map(([value, label]) => ({ label, value })),
            ...service_levels.map(({ service_level }) => ({ label: service_level, value: service_level })),
        ];
    }, [service_levels]);

    const handleZipChange = (zip) => {
        let newSelections = [...selectedZipsLocal];
        if (newSelections.length > 0 && newSelections.includes(zip)) {
            newSelections.splice(newSelections.indexOf(zip), 1);
        } else {
            if (newSelections.length > 0) {
                newSelections.push(zip);
            } else newSelections = [zip];
        }
        setSelectedZipsLocal(newSelections);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'filterButton' : undefined;

    return (
        <>
            <Button
                variant="contained"
                aria-describedby={id}
                onClick={handleClick}
                color={ordersLoading ? 'secondary' : 'primary'}
                disabled={ordersLoading}
                css={css`
                    color: #fff;
                    height: 100%;
                `}
            >
                <FilterListIcon />
            </Button>
            <Popover
                open={open}
                id={id}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Grid
                    container
                    direction="column"
                    css={css`
                        margin: 16px;
                        width: 380px;
                        display: flex;
                        justify-content: center;
                    `}
                >
                    {accountType === 'carrier' && (
                        <Grid container item>
                            <FormControl
                                css={css`
                                    width: 100%;
                                    margin-bottom: 15px;
                                `}
                                variant="outlined"
                            >
                                <InputLabel
                                    shrink
                                    id="select-owner"
                                    css={css`
                                        background: #fff;
                                    `}
                                >
                                    Owner
                                </InputLabel>
                                <Select
                                    fullWidth
                                    css={css`
                                        max-height: 50px;
                                    `}
                                    labelId="select-owner"
                                    id="select-owner"
                                    value={ownerTypeLocal}
                                    MenuProps={MenuProps}
                                >
                                    {['All', 'Onward', 'Internal'].map((owner, i) => (
                                        <MenuItem key={i} value={owner}>
                                            <ListItemText
                                                primary={owner}
                                                onClick={() => {
                                                    setOwnerTypeLocal(owner);
                                                }}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    <Grid container item>
                        <FormControl
                            css={css`
                                width: 100%;
                            `}
                            variant="outlined"
                        >
                            <InputLabel
                                shrink
                                id="zip-checkbox-label"
                                css={css`
                                    background: #fff;
                                `}
                            >
                                Zip Codes
                            </InputLabel>
                            <Select
                                css={css`
                                    max-height: 50px;
                                `}
                                fullWidth
                                labelId="zip-checkbox-label"
                                id="zip-checkbox"
                                multiple
                                value={selectedZipsLocal.length > 0 ? selectedZipsLocal : []}
                                renderValue={(selectedZipsLocal) =>
                                    selectedZipsLocal.length > 0 ? selectedZipsLocal.join(', ') : ''
                                }
                                MenuProps={MenuProps}
                            >
                                {zipOptions.map((zip, i) => (
                                    <MenuItem key={zip + i} value={zip}>
                                        <CustomCheckbox
                                            checked={
                                                selectedZipsLocal.length > 0
                                                    ? selectedZipsLocal.indexOf(zip) > -1
                                                    : false
                                            }
                                            onClick={() => {
                                                handleZipChange(zip);
                                            }}
                                        />

                                        <ListItemText
                                            primary={zip}
                                            onClick={() => {
                                                handleZipChange(zip);
                                            }}
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid
                        container
                        item
                        css={css`
                            padding-top: 16px;
                        `}
                    >
                        <TextField
                            select
                            fullWidth
                            variant="outlined"
                            label="Service Type"
                            value={serviceTypeLocal || locationTypeLocal || ''}
                            onChange={(e) => {
                                const next = e.target.value;
                                if (LOCATION_TYPES[next]) {
                                    setServiceTypeLocal('');
                                    setLocationTypeLocal(next);
                                } else {
                                    setServiceTypeLocal(next);
                                    setLocationTypeLocal('');
                                }
                            }}
                        >
                            {serviceTypes.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            margin: 8px 0;
                        `}
                    >
                        <FilterHeader>Created</FilterHeader>
                    </Grid>
                    <Grid
                        container
                        item
                        css={css`
                            align-items: center;
                            justify-content: space-between;
                        `}
                    >
                        <Grid
                            container
                            item
                            direction="column"
                            css={css`
                                flex-basis: 0;
                            `}
                        >
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                name="createdFrom"
                                value={createdFromLocal}
                                onChange={(e) => setCreatedFromLocal(e.target.value)}
                            />
                        </Grid>
                        <Grid
                            container
                            item
                            direction="column"
                            css={css`
                                flex-basis: 0;
                            `}
                        >
                            <Typography className="mx-1"> - </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            direction="column"
                            css={css`
                                flex-basis: 0;
                            `}
                        >
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                name="createdTo"
                                value={createdToLocal}
                                onChange={(e) => setCreatedToLocal(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            margin: 10px 0 5px -8px;
                        `}
                    >
                        <CustomCheckbox
                            css={css`
                                height: 38px;
                                width: 38px;
                            `}
                            checked={hasPrefDateLocal}
                            onClick={() => {
                                setHasPrefDateLocal(!hasPrefDateLocal);
                            }}
                        />
                        <BodyText
                            css={css`
                                font-weight: 500;
                            `}
                        >
                            Has a preferred delivery date
                        </BodyText>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            margin: 5px 0;
                        `}
                    >
                        <FilterHeader>Preferred delivery date</FilterHeader>
                    </Grid>
                    <Grid
                        container
                        item
                        css={css`
                            align-items: center;
                            justify-content: space-between;
                        `}
                    >
                        <Grid
                            container
                            item
                            direction="column"
                            css={css`
                                flex-basis: 0;
                            `}
                        >
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                name="createdFrom"
                                value={prefDateFromLocal}
                                onChange={(e) => setPrefDateFromLocal(e.target.value)}
                            />
                        </Grid>
                        <Grid
                            container
                            item
                            direction="column"
                            css={css`
                                flex-basis: 0;
                            `}
                        >
                            <Typography className="mx-1"> - </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            direction="column"
                            css={css`
                                flex-basis: 0;
                            `}
                        >
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                name="createdTo"
                                value={prefDateToLocal}
                                onChange={(e) => setPrefDateToLocal(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        css={css`
                            margin: 5px 0;
                        `}
                    >
                        <FilterHeader>Scheduled</FilterHeader>
                    </Grid>
                    <Grid
                        container
                        item
                        css={css`
                            align-items: center;
                            justify-content: space-between;
                        `}
                    >
                        <Grid
                            container
                            item
                            direction="column"
                            css={css`
                                flex-basis: 0;
                            `}
                        >
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                name="scheduledFrom"
                                value={scheduledFromLocal}
                                onChange={(e) => setScheduledFromLocal(e.target.value)}
                            />
                        </Grid>
                        <Grid
                            container
                            item
                            direction="column"
                            css={css`
                                flex-basis: 0;
                            `}
                        >
                            <Typography className="mx-1"> - </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            direction="column"
                            css={css`
                                flex-basis: 0;
                            `}
                        >
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                name="scheduledTo"
                                value={scheduledToLocal}
                                onChange={(e) => setScheduledToLocal(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item>
                        <FormControl
                            css={css`
                                width: 100%;
                                margin-top: 15px;
                            `}
                            variant="outlined"
                        >
                            <InputLabel
                                shrink
                                id="select-owner"
                                css={css`
                                    background: #fff;
                                `}
                            >
                                Warehouse Status
                            </InputLabel>
                            <Select
                                css={css`
                                    max-height: 50px;
                                `}
                                fullWidth
                                // style={{ height: '75%' }}
                                labelId="select-wh-status"
                                id="select-wh-status"
                                value={warehouseStatusLocal}
                                MenuProps={MenuProps}
                            >
                                {['Any', 'Not Received', 'Received'].map((whStatus, i) => (
                                    <MenuItem key={i} value={whStatus}>
                                        <ListItemText
                                            primary={whStatus}
                                            onClick={() => {
                                                setWarehouseStatusLocal(whStatus);
                                            }}
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid
                        item
                        css={css`
                            margin: 5px 0;
                        `}
                    >
                        <FilterHeader>Warehouse Actual Recieved Date</FilterHeader>
                    </Grid>
                    <Grid
                        container
                        item
                        css={css`
                            margin-bottom: 24px;
                            align-items: center;
                            justify-content: space-between;
                        `}
                    >
                        <Grid
                            container
                            item
                            direction="column"
                            css={css`
                                flex-basis: 0;
                            `}
                        >
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                name="warehouseFrom"
                                value={warehouseFromLocal}
                                onChange={(e) => setWarehouseFromLocal(e.target.value)}
                            />
                        </Grid>
                        <Grid
                            container
                            item
                            direction="column"
                            css={css`
                                flex-basis: 0;
                            `}
                        >
                            <Typography className="mx-1"> - </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            direction="column"
                            css={css`
                                flex-basis: 0;
                            `}
                        >
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                name="warehouseTo"
                                value={warehouseToLocal}
                                onChange={(e) => setWarehouseToLocal(e.target.value)}
                            />
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" justifyContent="space-between" item>
                        <Button
                            variant="contained"
                            onClick={() => {
                                handleClear();
                            }}
                            css={css`
                                margin-right: 8px;
                                font-size: 18px;
                                background-color: white;
                                padding: 5px 30px 5px 30px;
                            `}
                        >
                            Clear
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                handleFilter();
                            }}
                            css={css`
                                font-size: 18px;
                                color: #fff;
                                padding: 5px 30px 5px 30px;
                            `}
                        >
                            Apply
                        </Button>
                    </Grid>
                </Grid>
            </Popover>
        </>
    );
}

export default Filter;
