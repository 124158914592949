import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import { calculateGoogleTimeframes } from './calculateGoogleTimeframes';
import { isOrderCancelled } from './isOrderCancelled';

export const routeIsMissingTimeframes = (route) => {
    return route?.stopsByRouteId?.some((stop) => {
        if (FTLStopHelpers.isFinalDropoff(stop) || stop.start || stop.end) {
            // pass check if stop is a final return stop, optimizeRoute does not create delwindow for final returns at the moment
            return false;
        }
        if (stop.overnight && FTLStopHelpers.isWarehousePickup(stop)) {
            return false;
        }
        return !stop.del_window_start || !stop.del_window_end;
    });
};

export const routeNeedsRecalculating = (route) => {
    const ordersByKey = Object.fromEntries((route.orders || []).map((mapping) => [mapping.order_id, mapping.order]));
    return (route.stopsByRouteId || []).some((stop) => {
        return (
            FTLStopHelpers.isCustomerStop(stop, route) &&
            (stop.orders || []).some((order_id) => ordersByKey[order_id]?.delivery_time_confirmed === 'recalculate')
        );
    });
};

export const routeIsConfirmed = (route) => {
    if (!route?.orders?.length || !route?.stopsByRouteId?.length || !route.sending_to) return false;
    const ordersByKey = Object.fromEntries((route.orders || []).map((mapping) => [mapping.order_id, mapping.order]));
    return (route.stopsByRouteId || []).every((s) => {
        if (!FTLStopHelpers.isCustomerStop(s, route)) {
            return true;
        }
        return (s.orders || []).every(
            (order_id) =>
                ordersByKey[order_id]?.order_status === 'cancelled' ||
                ordersByKey[order_id]?.delivery_time_confirmed === 'confirmed'
        );
    });
};

export { calculateGoogleTimeframes, isOrderCancelled };
