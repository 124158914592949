export const LOCATION_TYPES = [
    {
        value: 'Business',
        label: 'Business',
    },
    {
        value: 'Residence',
        label: 'Residence',
    },
];

export const DROPOFF_TYPES = {
    Business: [
        {
            value: 'dock',
            label: (pickUpOrDropoff) => (pickUpOrDropoff === 'pickUp' ? 'Dock Pick Up' : 'Dock Delivery'),
        },
        { value: 'inStore', label: () => 'In Store' },
        { value: 'rollUpDoor', label: () => 'Warehouse/Roll Up Door' },
    ],
    Residence: [
        {
            value: 'curbside',
            label: (pickUpOrDropoff) => (pickUpOrDropoff === 'pickUp' ? 'Curbside Pick Up' : 'Curbside Delivery'),
            tier: 0,
        },
        { value: 'firstDrySpace', label: () => 'First Dry Space', tier: 1 },
        {
            value: 'roomOfChoice',
            label: () => 'Room of Choice',
            condition: (order) => order.freight_type === 'household',
            tier: 2,
        },
        {
            value: 'whiteGlove',
            label: () => 'White Glove',
            condition: (order) => order.freight_type === 'household',
            tier: 3,
        },
    ],
};

export const PICKUP_TYPES = {
    Business: [
        {
            value: 'dock',
            label: (pickUpOrDropoff) => (pickUpOrDropoff === 'pickUp' ? 'Dock Pick Up' : 'Dock Delivery'),
        },
        { value: 'inStore', label: 'In Store' },
        { value: 'rollUpDoor', label: 'Warehouse/Roll Up Door' },
    ],
    Residence: [
        {
            value: 'whiteGlove',
            label: 'In Home Pickup',
        },
        { value: 'curbside', label: 'Curbside/Garage Pickup' },
    ],
};

export const RESIDENCE_TYPES = [
    { value: 'House', label: 'House' },
    { value: 'Apartment', label: 'Apartment' },
];
