import { gql } from '@apollo/client';

export const SURVEY_QUESTION_FIELDS = gql`
    fragment SurveyQuestionFields on survey_questions {
        survey_id
        client_id
        survey_type
        question
        response_type
        enabled
    }
`;

export const SURVEY_RESPONSE_FIELDS = gql`
    fragment SurveyResponseFields on survey_responses {
        survey_id
        order_id
        response_string
        response_boolean
        response_number
        created_at
    }
`;
