import { gql } from '@apollo/client';

export const DRIVER_PAYMENT_FIELDS = gql`
    fragment DriverPaymentFields on driver_payments {
        payment_id
        client_id
        pay_period_start
        pay_period_end
        status
        rate_overrides
        created_at
    }
`;
