import React, { useEffect, useMemo, useState, useContext } from 'react';
import { css } from '@emotion/react';
import { LocationOn, NotListedLocation } from '@material-ui/icons';
import { addDays, format, isAfter, subDays } from 'date-fns';
import { BodyCellText } from '@/components/MyOrders/blocks';
import { useClientUser, useOrderShipperPricing } from '@/hooks';
import { UserContext } from '@/components/App';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export const integerFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const useListingColumns = () => {
    const { circles, payment_type, isOnwardAdmin } = useClientUser();

    const typeLabels = {
        all: 'Bidding/Claim Now',
        auction: 'Bidding',
        claim_now: 'Claim Now',
    };
    const colWidth = 175;

    const getUnreadDetails = (listing) => {
        if (listing.listing_status !== 'open') return {};

        const lastViewed = listing.last_viewed_shipper || listing.created_at;

        const unreadBids = listing.bids.filter((bid) => isAfter(new Date(bid.created_at), new Date(lastViewed))).length;

        let unreadCounters = 0;

        listing.bids.forEach((bid) => {
            unreadCounters += bid.counter_offers.filter((counter) =>
                isAfter(new Date(counter.created_at), new Date(lastViewed))
            ).length;
        });

        return { unreadBids, unreadCounters };
    };

    return useMemo(() => {
        return [
            {
                id: 'badge',
                accessor: (listing) => listing,
                Cell: ({ value: listing }) => {
                    const { unreadBids, unreadCounters } = getUnreadDetails(listing);

                    if (unreadBids || unreadCounters) {
                        return <FiberManualRecordIcon sx={{ fontSize: 13, color: '#D23E3E', margin: 0, padding: 0 }} />;
                    } else return null;
                },
                disableSortBy: true,
                width: 60,
            },
            {
                id: 'ordernum',
                Header: 'Order',
                accessor: (listing) => listing?.order?.order_number,
                disableSortBy: true,
                width: colWidth,
            },
            {
                id: 'ponum',
                Header: 'PO #',
                accessor: (listing) => listing?.order?.po_number,
                disableSortBy: true,
                width: colWidth,
            },
            {
                id: 'pucity',
                Header: 'Start City',
                accessor: (listing) => listing?.order?.pickup_city,
                disableSortBy: true,
                width: colWidth,
            },
            {
                id: 'docity',
                Header: 'End City',
                accessor: (listing) => listing?.order?.dropoff_city,
                disableSortBy: true,
                width: colWidth,
            },
            {
                id: 'bids',
                Header: 'Bids',
                accessor: (listing) => listing,
                Cell: ({ value: listing }) => {
                    // Don't display pre-bids to shippers;
                    if (listing.listing_status === 'pending') return '-';

                    const { unreadBids, unreadCounters } = getUnreadDetails(listing);
                    if (listing?.bids?.length) {
                        return (
                            <>
                                <span>{listing.bids.length}</span>
                                {unreadBids && unreadCounters ? (
                                    <span
                                        style={{
                                            color: '#59b863',
                                            fontStyle: 'italic  ',
                                            fontSize: 13,
                                            fontWeight: 600,
                                        }}
                                    >{` (${unreadBids} new /${unreadCounters} counter)`}</span>
                                ) : unreadBids ? (
                                    <span
                                        style={{ color: '#59b863', fontStyle: 'italic', fontSize: 13, fontWeight: 600 }}
                                    >{` (${unreadBids} new)`}</span>
                                ) : unreadCounters ? (
                                    <span
                                        style={{
                                            color: '#59b863',
                                            fontStyle: 'italic',
                                            fontSize: 13,
                                            fontWeight: 600,
                                        }}
                                    >{` (${unreadCounters} counters)`}</span>
                                ) : null}
                            </>
                        );
                    }
                    return '-';
                },
                disableSortBy: true,
                width: colWidth,
            },
            {
                id: 'shipperexpiration',
                Header: 'Expiration Date',
                Cell: ({ row }) => {
                    const listing = row.original;
                    if (!listing) {
                        return '--';
                    }
                    const expDate = new Date(listing.expiration_date);
                    const currentDate = new Date();
                    const isPast = expDate < currentDate;

                    return (
                        <span style={{ color: isPast && listing.listing_status !== 'claimed' ? 'red' : 'inherit' }}>
                            {listing.expiration_date_formatted}
                        </span>
                    );
                },
                disableSortBy: true,
                width: colWidth,
            },
            {
                id: 'creationdate',
                Header: 'Created',
                Cell: ({ row }) => {
                    const listing = row.original;
                    if (!listing) {
                        return '--';
                    }

                    return (
                        <span>
                            {format(new Date(listing.created_at), 'EEE, MMM d, yyyy', { awareOfUnicodeTokens: true })}
                        </span>
                    );
                },
                disableSortBy: true,
                width: colWidth,
            },
            {
                id: 'refid',
                Header: 'Ref #',
                accessor: (listing) => listing?.order?.reference_id || 'N/A',
                disableSortBy: true,
                width: colWidth,
            },
            ...(isOnwardAdmin
                ? [
                      {
                          id: 'quoteSent',
                          Header: 'Quote Sent',
                          accessor: (listing) => (listing?.order?.quote_sent ? 'Yes' : 'No'),
                          disableSortBy: true,
                          width: colWidth,
                      },
                  ]
                : []),
            {
                sticky: 'right',
                Header: 'stickyStatus',
                id: 'sticky',
                columns: [
                    {
                        id: 'state',
                        width: colWidth,
                        Header: 'Status',
                        accessor: (listing) => listing?.listing_status,
                        Cell: ({ value }) => {
                            let fragment;
                            let label;
                            let unknown = false;

                            switch (value) {
                                case 'pending': {
                                    label = 'Pending';
                                    fragment = css`
                                        color: #ff903d;
                                    `;
                                    break;
                                }
                                case 'claimed': {
                                    label = 'Claimed';
                                    fragment = css`
                                        color: #4bb71a;
                                    `;
                                    break;
                                }
                                case 'open': {
                                    label = 'Open';
                                    fragment = css`
                                        color: #0961f6;
                                    `;
                                    break;
                                }
                                case 'expired': {
                                    label = 'Expired';
                                    fragment = css`
                                        color: #d23e3e;
                                    `;
                                    break;
                                }
                                case 'cancelled': {
                                    label = 'Cancelled';
                                    fragment = css`
                                        color: #d23e3e;
                                    `;
                                    break;
                                }
                                default:
                                    unknown = true;
                            }

                            return (
                                <div
                                    css={css`
                                        display: flex;
                                        align-items: center;
                                        white-space: nowrap;
                                    `}
                                >
                                    {unknown ? (
                                        <NotListedLocation
                                            css={css`
                                                color: #000;
                                            `}
                                        />
                                    ) : (
                                        <LocationOn
                                            css={css`
                                                ${fragment}
                                            `}
                                        />
                                    )}
                                    <BodyCellText>{unknown ? 'Unknown' : label}</BodyCellText>
                                </div>
                            );
                        },
                        disableSortBy: true,
                    },
                ],
            },
        ];
    }, [colWidth, isOnwardAdmin]);
};
