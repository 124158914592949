import { InMemoryCache } from '@apollo/client';
import zipcode_to_timezone from 'zipcode-to-timezone';
import dateFnsFormat from 'date-fns/format';
import { asBrowserDate, asDateInTZ, asUTCDate } from '@/utilities/convertToISO';
import { mergeArrays } from './util';
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';
import { subDays } from 'date-fns';

const instance = new InMemoryCache({
    typePolicies: {
        notes: {
            keyFields: ['note_id'],
        },
        notifications: {
            keyFields: ['notification_id'],
        },
        communications: {
            keyFields: ['communication_id'],
        },
        users: {
            keyFields: ['user_id'],
        },
        clients: {
            keyFields: ['client_id'],
        },
        overrides: {
            keyFields: ['override_id'],
        },
        shippers: {
            keyFields: ['shipper_id'],
        },
        carriers: {
            keyFields: ['carrier_id'],
        },
        corporate_admins: {
            keyFields: ['corporate_admin_id'],
        },
        routes: {
            keyFields: ['route_number', 'route_id'],
            fields: {
                stopsByRouteId: {
                    merge(existing = [], incoming = [], { readField }) {
                        const all = mergeArrays(existing, incoming);
                        const filtered = all.filter((stopRef) => {
                            const stopRouteId = readField('route_id', stopRef);
                            return !!stopRouteId;
                        });
                        return filtered;
                    },
                },
                scheduled_delivery_formatted: {
                    read(prev = null, { readField }) {
                        const [orders, deliveryDate] = ['orders', 'scheduled_delivery'].map((attr) =>
                            readField({ fieldName: attr })
                        );
                        if (!deliveryDate) {
                            return prev;
                        }

                        const order = orders?.length && readField('order', orders[0]);
                        const [orderType, pickupZip, dropoffZip] = ['order_type', 'pickup_zip', 'dropoff_zip'].map(
                            (attr) => order && readField(attr, order)
                        );
                        const zip = orderType === 'return' ? pickupZip : dropoffZip;
                        const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';

                        return formatInTimeZone(asDateInTZ(deliveryDate, tz), tz, 'MM/dd/yyyy');
                    },
                },
                completed_formatted: {
                    read(prev = null, { readField }) {
                        const [orders, completed] = ['orders', 'completed'].map((attr) =>
                            readField({ fieldName: attr })
                        );
                        if (!completed) {
                            return prev;
                        }

                        const order = orders?.length && readField('order', orders[0]);
                        const [orderType, pickupZip, dropoffZip] = ['order_type', 'pickup_zip', 'dropoff_zip'].map(
                            (attr) => order && readField(attr, order)
                        );
                        const zip = orderType === 'return' ? pickupZip : dropoffZip;
                        const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';

                        return formatInTimeZone(asDateInTZ(completed, tz), tz, 'MM/dd/yyyy');
                    },
                },
            },
        },
        orders: {
            keyFields: ['order_id'],
            fields: {
                returns: {
                    read(prev = [], { readField }) {
                        const items = readField({ fieldName: 'itemsByOrderId' });
                        if (!items || items.length === 0) {
                            return prev;
                        }

                        return items.filter((item) => readField('is_return', item));
                    },
                },
                items: {
                    read(prev = [], { readField }) {
                        const items = readField({ fieldName: 'itemsByOrderId' });
                        if (!items || items.length === 0) {
                            return prev;
                        }

                        return items.filter((item) => !readField('is_return', item));
                    },
                },
                first_available_date_formatted: {
                    read(prev = null, { readField }) {
                        const [orderType, deliveryDate, pickupZip, dropoffZip] = [
                            'order_type',
                            'first_available_date',
                            'pickup_zip',
                            'dropoff_zip',
                        ].map((attr) => readField({ fieldName: attr }));
                        if (!deliveryDate) {
                            return prev;
                        }

                        const zip = orderType === 'return' ? pickupZip : dropoffZip;
                        const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';

                        return formatInTimeZone(asDateInTZ(deliveryDate, tz), tz, 'EEE, MMM d, yyyy');
                    },
                },
                target_delivery_date_formatted: {
                    read(prev = null, { readField }) {
                        const [orderType, targetDeliveryDate, pickupZip, dropoffZip] = [
                            'order_type',
                            'target_delivery_date',
                            'pickup_zip',
                            'dropoff_zip',
                        ].map((attr) => readField({ fieldName: attr }));
                        if (!targetDeliveryDate) {
                            return prev;
                        }

                        const zip = orderType === 'return' ? pickupZip : dropoffZip;
                        const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';

                        return formatInTimeZone(asDateInTZ(targetDeliveryDate, tz), tz, 'EEE, MMM d, yyyy');
                    },
                },
                billing_completion_date_formatted: {
                    read(prev = null, { readField }) {
                        const [orderType, completionDate, pickupZip, dropoffZip] = [
                            'order_type',
                            'billing_completion_time',
                            'pickup_zip',
                            'dropoff_zip',
                        ].map((attr) => readField({ fieldName: attr }));
                        if (!completionDate) {
                            return prev;
                        }

                        const zip = orderType === 'return' ? pickupZip : dropoffZip;
                        const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';

                        return formatInTimeZone(asDateInTZ(completionDate, tz), tz, 'EEE, MMM d, yyyy');
                    },
                },
                delivery_date_formatted: {
                    read(prev = null, { readField }) {
                        const [orderType, deliveryDate, pickupZip, dropoffZip] = [
                            'order_type',
                            'delivery_date',
                            'pickup_zip',
                            'dropoff_zip',
                        ].map((attr) => readField({ fieldName: attr }));
                        if (!deliveryDate) {
                            return prev;
                        }

                        const zip = orderType === 'return' ? pickupZip : dropoffZip;
                        const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';

                        return formatInTimeZone(asDateInTZ(deliveryDate, tz), tz, 'EEE, MMM d, yyyy');
                    },
                },
                completion_date_formatted: {
                    read(prev = null, { readField }) {
                        const [orderType, completionDate, pickupZip, dropoffZip] = [
                            'order_type',
                            'completion_time',
                            'pickup_zip',
                            'dropoff_zip',
                        ].map((attr) => readField({ fieldName: attr }));
                        if (!completionDate) {
                            return prev;
                        }

                        const zip = orderType === 'return' ? pickupZip : dropoffZip;
                        const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';

                        return formatInTimeZone(asDateInTZ(completionDate, tz), tz, 'EEE, MMM d, yyyy');
                    },
                },
                pickup_date_formatted: {
                    read(prev = null, { readField }) {
                        const [orderType, pickupDate, pickupZip, dropoffZip] = [
                            'order_type',
                            'pickup_date',
                            'pickup_zip',
                            'dropoff_zip',
                        ].map((attr) => readField({ fieldName: attr }));
                        if (!pickupDate) {
                            return prev;
                        }

                        const zip = orderType === 'return' ? pickupZip : dropoffZip;
                        const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';

                        return formatInTimeZone(asDateInTZ(pickupDate, tz), tz, 'EEE, MMM d, yyyy');
                    },
                },
                estimated_delivery_range_start_formatted: {
                    read(prev = null, { readField }) {
                        const [orderType, date, pickupZip, dropoffZip] = [
                            'order_type',
                            'estimated_delivery_range_start',
                            'pickup_zip',
                            'dropoff_zip',
                        ].map((attr) => readField({ fieldName: attr }));
                        if (!date) {
                            return prev;
                        }

                        const zip = orderType === 'return' ? pickupZip : dropoffZip;
                        const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';

                        return formatInTimeZone(asDateInTZ(date, tz), tz, 'EEE, MMM d, yyyy');
                    },
                },
                estimated_delivery_range_end_formatted: {
                    read(prev = null, { readField }) {
                        const [orderType, date, pickupZip, dropoffZip] = [
                            'order_type',
                            'estimated_delivery_range_end',
                            'pickup_zip',
                            'dropoff_zip',
                        ].map((attr) => readField({ fieldName: attr }));
                        if (!date) {
                            return prev;
                        }

                        const zip = orderType === 'return' ? pickupZip : dropoffZip;
                        const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';

                        return formatInTimeZone(asDateInTZ(date, tz), tz, 'EEE, MMM d, yyyy');
                    },
                },
                preferred_delivery_date_formatted: {
                    read(prev = null, { readField }) {
                        const [orderType, prefDelDate, pickupZip, dropoffZip] = [
                            'order_type',
                            'preferred_delivery_date',
                            'pickup_zip',
                            'dropoff_zip',
                        ].map((attr) => readField({ fieldName: attr }));
                        if (!prefDelDate) {
                            return prev;
                        }

                        const zip = orderType === 'return' ? pickupZip : dropoffZip;
                        const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';

                        return formatInTimeZone(asDateInTZ(prefDelDate, tz), tz, 'EEE, MMM d, yyyy');
                    },
                },
                alternative_delivery_dates_formatted: {
                    read(prev = [], { readField }) {
                        const [orderType, altDelDates, pickupZip, dropoffZip] = [
                            'order_type',
                            'alternative_delivery_dates',
                            'pickup_zip',
                            'dropoff_zip',
                        ].map((attr) => readField({ fieldName: attr }));
                        if (!altDelDates) {
                            return prev;
                        }

                        const zip = orderType === 'return' ? pickupZip : dropoffZip;
                        const tz = zipcode_to_timezone.lookup(zip) || 'America/New_York';

                        const formatted = altDelDates.map((date) =>
                            formatInTimeZone(asDateInTZ(date, tz), tz, 'EEE, MMM d, yyyy')
                        );

                        return formatted;
                    },
                },
                order_exception: {
                    read(prev = null, { readField }) {
                        const exceptions = readField({ fieldName: 'exceptions' });
                        if (exceptions.length === 0) {
                            return prev;
                        }

                        const unresolved = exceptions
                            .filter((mapping) => {
                                return (
                                    readField('status', mapping.exception) !== 'RESOLVED' &&
                                    mapping.exception_type === 'ORDER'
                                );
                            })
                            .sort((l, r) => {
                                return new Date(r.created_at).getTime() - new Date(l.created_at).getTime();
                            });

                        return unresolved.length > 0 ? unresolved[0].exception : null;
                    },
                },
            },
        },
        order_wh_events: {
            keyFields: ['event_id'],
            fields: Object.fromEntries(
                [
                    'est_pickup_date',
                    'est_received_date',
                    'est_ship_date',
                    'pickup_date',
                    'received_date',
                    'ship_date',
                ].map((attr) => [
                    attr,
                    {
                        read(prev) {
                            if (!prev) {
                                return null;
                            }

                            return asUTCDate(prev).toISOString();
                        },
                    },
                ])
            ),
        },
        items: {
            keyFields: ['item_id'],
            fields: {
                item_exception: {
                    read(prev = null, { readField }) {
                        const exceptions = readField({ fieldName: 'exceptions' });
                        if (exceptions.length === 0) {
                            return prev;
                        }

                        const unresolved = exceptions
                            .filter((mapping) => {
                                return (
                                    readField('status', mapping.exception) !== 'RESOLVED' &&
                                    mapping.exception_type === 'ITEM'
                                );
                            })
                            .sort((l, r) => {
                                return new Date(r.created_at).getTime() - new Date(l.created_at).getTime();
                            });

                        return unresolved.length > 0 ? unresolved[0].exception : null;
                    },
                },
            },
        },
        teammates: {
            keyFields: ['teammate_id'],
        },
        ratings: {
            keyFields: ['rating_id'],
        },
        stops: {
            keyFields: ['stop_id'],
        },
        messages: {
            keyFields: ['message_id'],
            fields: {
                timestamp: {
                    read(_, { readField }) {
                        return (
                            readField({ fieldName: 'scheduled_timestamp' }) || readField({ fieldName: 'created_at' })
                        );
                    },
                },
            },
        },
        locations: {
            keyFields: ['location_id'],
        },
        exceptions: {
            keyFields: ['exception_id'],
        },
        invoices: {
            keyFields: ['invoice_id'],
        },
        jobs: {
            keyFields: ['job_id'],
        },
        activity_orders: {
            keyFields: ['order_id'],
        },
        activity_routes: {
            keyFields: ['route_id'],
        },
        activity_stops: {
            keyFields: ['stop_id'],
        },
        trucks: {
            keyFields: ['truck_id'],
        },
        listings: {
            keyFields: ['listing_id'],
            fields: {
                expiration_date_formatted: {
                    read(_, { readField }) {
                        return dateFnsFormat(
                            asBrowserDate(
                                subDays(new Date(readField({ fieldName: 'expiration_date' })), 1).toISOString()
                            ),
                            'EEE, MMM d, yyyy'
                        );
                    },
                },
            },
        },
        bids: {
            keyFields: ['bid_id'],
            fields: {
                expiration_date_formatted: {
                    read(_, { readField }) {
                        return dateFnsFormat(
                            asBrowserDate(
                                subDays(new Date(readField({ fieldName: 'expiration_date' })), 1).toISOString()
                            ),
                            'EEE, MMM d, yyyy'
                        );
                    },
                },
            },
        },
        partnerships: {
            keyFields: ['partnership_id'],
        },
        subregions: {
            keyFields: ['subregion_id'],
        },
        subregion_zip_mappings: {
            keyFields: ['mapping_id'],
        },
        subregion_carrier_mappings: {
            keyFields: ['mapping_id'],
        },
        shipping_partners: {
            keyFields: ['shipping_partner_id'],
        },
        resources: {
            keyFields: ['resource_id'],
            fields: {
                zips: {
                    read(prev = [], { readField }) {
                        const [subregions] = ['subregions'].map((attr) => readField({ fieldName: attr }));
                        if (!subregions) {
                            return [];
                        }

                        return subregions.reduce((acc, subregionRef) => {
                            const subregion = readField('subregion', subregionRef);
                            const zips = readField('zips', subregion);
                            acc = [
                                ...acc,
                                ...(zips || []).map((zipRef) => {
                                    const zip = readField('zip', zipRef);

                                    return zip;
                                }),
                            ];
                            return acc;
                        }, []);
                    },
                },
            },
        },
        resource_region_mappings: {
            keyFields: ['mapping_id'],
        },
        resource_rule_mappings: {
            keyFields: ['mapping_id'],
            fields: Object.fromEntries(
                ['override_end', 'override_start'].map((attr) => [
                    attr,
                    {
                        read(prev) {
                            if (!prev) {
                                return null;
                            }

                            return asUTCDate(prev).toISOString();
                        },
                    },
                ])
            ),
        },
        resource_override_mappings: {
            keyFields: ['mapping_id'],
            fields: Object.fromEntries(
                ['rule_end', 'rule_start'].map((attr) => [
                    attr,
                    {
                        read(prev) {
                            if (!prev) {
                                return null;
                            }

                            return asUTCDate(prev).toISOString();
                        },
                    },
                ])
            ),
        },
        carrier_invoices: {
            keyFields: ['carrier_invoice_id'],
        },
        driver_tariff_mappings: {
            keyFields: ['mapping_id'],
        },
        driver_payments: {
            keyFields: ['payment_id'],
        },
        pricing_overrides: {
            keyFields: ['pricing_override_id'],
        },
        pricing_tariffs: {
            keyFields: ['tariff_id'],
        },
        manifests: {
            keyFields: ['manifest_id'],
        },
        manifests_inbound_items: {
            keyFields: ['mapping_id'],
        },
        pallet_items: {
            keyFields: ['pallet_id'],
        },
        pallet_item_logs: {
            keyFields: ['log_id'],
        },
        exception_mapping: {
            keyFields: ['mapping_id'],
        },
        resource_shipping_partner_mappings: {
            keyFields: ['mapping_id'],
        },
        order_route_mappings: {
            keyFields: ['mapping_id'],
        },
        service_levels: {
            keyFields: ['service_level_id'],
        },
        survey_questions: {
            keyFields: ['survey_id'],
        },
        survey_responses: {
            keyFields: ['survey_id', 'order_id'],
        },
        tags: {
            keyFields: ['tag_id'],
        },
    },
});

export default instance;
