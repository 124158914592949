import { SURVEY_QUESTION_FIELDS } from '@/graphql/fragments/surveys';
import { gql } from '@apollo/client';

export const UPSERT_SURVEY_QUESTION = gql`
    ${SURVEY_QUESTION_FIELDS}
    mutation UpsertSurveyQuestion($survey_question: survey_questions_insert_input!) {
        insert_survey_questions_one(
            object: $survey_question
            on_conflict: { constraint: survey_questions_pkey, update_columns: [question, response_type, enabled] }
        ) {
            ...SurveyQuestionFields
        }
    }
`;

export const DELETE_SURVEY_QUESTION = gql`
    mutation DeleteSurveyQuestion($id: uuid!) {
        delete_survey_questions_by_pk(survey_id: $id) {
            survey_id
        }
    }
`;
