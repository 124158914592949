import React, { useState, useMemo, useContext } from 'react';
import { PrimaryButton, Row } from '@/styles/blocks';
import { css } from '@emotion/react';
import { useQuery } from '@apollo/client';
import { Grid, MenuItem, Tooltip } from '@material-ui/core';
import { UserContext } from '@/components/App';
import GoogleMap, { Marker } from '@/components/GoogleMap';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import AddressAutocomplete from '@/components/ShipmentForm/ModifiedAddressAutocomplete';
import { Body, SectionSubtitle } from '@/styles/blocks';
import { colors } from '@/styles';
import { toE164, toNational } from '@/utilities/formatPhoneNumber';
import { asBrowserDate } from '@/utilities/convertToISO';

import {
    addressSplit,
    sanitizeEnum,
    VALID_LOCATION_TYPES,
    VALID_DROPOFF_TYPES as VALID_PICKUP_TYPES,
} from '../../utilities/processOrders';
import { QUERY_CLIENTS_BY_USER, QUERY_LOCATIONS_BY_CLIENT_ID } from '../../graphql/queries';
import { useSwappedAttributes } from '../../hooks';
import { ErrorText, TextField, OnwardToggle, RadioLabelInfo, ToggleBlurb } from '../../blocks';
import { ModalContext } from './';
import { format } from 'date-fns';

import {
    SingleDatePicker,
    SecondaryContactBtn,
    StoreSelect,
    NewAddressCheckbox,
    LocationType,
    PickupType,
    StoreDetails,
} from '../InputFields';
import { useClientUser } from '@/hooks';
import { Info } from '@material-ui/icons';

const ContactFields = ({ order, attrs, hasError, isDirty, callbacks, opt }) => {
    const [name, phone, email] = attrs;

    return (
        <Row
            css={css`
                margin-top: 16px;
                align-items: center;
            `}
        >
            <Grid
                container
                item
                css={css`
                    flex-grow: 1;
                    margin-right: 16px;
                `}
            >
                <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={order[name]}
                    onBlur={() => callbacks.makeDirty([name])}
                    onChange={(e) => callbacks.modifyOrder({ [name]: e.target.value })}
                    error={hasError[name] && (opt.startDirty || isDirty[name])}
                />
            </Grid>
            <Grid
                container
                item
                css={css`
                    flex-grow: 1;
                    margin-right: 16px;
                `}
            >
                <TextField
                    fullWidth
                    label="Phone"
                    variant="outlined"
                    InputProps={{
                        inputComponent: PhoneNumberInput,
                    }}
                    value={toNational(order[phone])}
                    onBlur={() => callbacks.makeDirty([phone])}
                    onChange={(e) => callbacks.modifyOrder({ [phone]: toE164(e.target.value) })}
                    error={hasError[phone] && (opt.startDirty || isDirty[phone])}
                />
            </Grid>
            <Grid
                container
                item
                css={css`
                    flex-grow: 1;
                `}
            >
                <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    value={order[email]}
                    onBlur={() => callbacks.makeDirty([email])}
                    onChange={(e) => callbacks.modifyOrder({ [email]: e.target.value })}
                />
            </Grid>
        </Row>
    );
};

const OriginTab = ({ opt }) => {
    const { disableAddressEditing, disableGeocoding, startDirty, isInternal } = opt;
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { order, isDirty, hasError, errors } = modalState;
    const { user_id } = useClientUser();

    const [hasNewAddressOverride, setHasNewAddress] = useState(null);
    const [storeOverride, setStoreOverride] = useState(null);

    const { data: locationsData } = useQuery(QUERY_LOCATIONS_BY_CLIENT_ID, {
        variables: {
            client_id: order.shipper_id,
        },
        onError: (e) => {
            callbacks.onError(e);
        },
    });

    const locations = useMemo(() => {
        return locationsData?.locations || [];
    }, [locationsData]);

    const store = useMemo(() => {
        if (!storeOverride && locations.length) {
            console.log(locations);
            const match = locations.findIndex(
                (location) =>
                    location.address === order.middle_mile_origin_address ||
                    (location.location_name && location.location_name === order.chosen_store_name)
            );
            return match > -1 ? match : null;
        }
        return storeOverride;
    }, [storeOverride, order, locations]);

    const hasNewAddress = useMemo(() => {
        let hasNewAddress = false;
        if (hasNewAddressOverride !== null) {
            hasNewAddress = hasNewAddressOverride;
        } else {
            hasNewAddress =
                (locations?.length || 0) === 0 ||
                ((order.middle_mile_origin_address?.length || 0) > 0 && store === null);
        }

        return hasNewAddress;
    }, [hasNewAddressOverride, order, store, hasError, locations]);

    const handleStoreSelect = async (e) => {
        setStoreOverride(e.target.value);
        let _store = locations[e.target.value];

        const [address1, address2] = addressSplit(_store.business_address);
        callbacks.modifyOrder({
            middle_mile_origin_address: address1,
            middle_mile_origin_unit: address2,
            middle_mile_origin_city: _store.business_city,
            middle_mile_origin_state: _store.business_state,
            middle_mile_origin_zip: _store.business_zip,
            middle_mile_origin_location: sanitizeEnum(_store.location_type, VALID_PICKUP_TYPES) || 'Business',
            middle_mile_origin_location_type: sanitizeEnum(_store.location_info, VALID_LOCATION_TYPES) || 'dock',
        });

        callbacks.makeDirty([
            'middle_mile_origin_address',
            'middle_mile_origin_city',
            'middle_mile_origin_state',
            'middle_mile_origin_zip',
            'middle_mile_origin_location',
            'middle_mile_origin_location_type',
        ]);
    };

    let addressError = null;
    if (
        [
            'middle_mile_origin_address',
            'middle_mile_origin_city',
            'middle_mile_origin_state',
            'middle_mile_origin_zip',
        ].some((attr) => hasError[attr] && (startDirty || isDirty[attr]))
    ) {
        addressError = (
            <Grid
                css={css`
                    color: ${colors.reds[1]};
                    margin-bottom: 4px;
                `}
                container
                direction="row"
            >
                <ErrorText>Address is invalid</ErrorText>
            </Grid>
        );
    }

    return (
        <Grid container direction="column">
            <ContactFields
                order={order}
                isDirty={isDirty}
                attrs={['middle_mile_origin_name', 'middle_mile_origin_phone', 'middle_mile_origin_email']}
                hasError={hasError}
                callbacks={callbacks}
                opt={opt}
            />

            <Grid
                css={css`
                    margin-top: 16px;
                    margin-bottom: 24px;
                `}
                container
                direction="row"
            >
                <Grid
                    container
                    item
                    css={css`
                        flex-grow: 2;
                        flex-basis: 0;
                        margin-right: 16px;
                    `}
                >
                    <StoreSelect
                        value={store}
                        onChange={handleStoreSelect}
                        error={
                            hasError.middle_mile_origin_address &&
                            !hasNewAddress &&
                            (startDirty || isDirty.middle_mile_origin_address)
                        }
                        storeList={locations || []}
                    />
                </Grid>
            </Grid>

            {!hasNewAddress && store !== null ? (
                <Row>
                    <Grid container direction="column">
                        <StoreDetails
                            storeName={locations?.[store]?.location_name}
                            fullAddress={[
                                order.middle_mile_origin_address,
                                order.middle_mile_origin_unit.order.middle_mile_origin_city,
                                order.middle_mile_origin_state,
                                order.middle_mile_origin_zip,
                            ]
                                .filter((x) => x)
                                .join(', ')}
                            locationType={order.middle_mile_origin_location}
                            pickupType={order.middle_mile_origin_location_type}
                        />
                    </Grid>
                </Row>
            ) : null}

            <Row>
                <NewAddressCheckbox
                    hasNewAddress={hasNewAddress}
                    disabled={locations?.length === 0}
                    onChange={() => {
                        const next = !hasNewAddress;
                        setHasNewAddress(next);
                    }}
                />
            </Row>

            {addressError ? addressError : null}
            {hasNewAddress ? (
                <>
                    <Row>
                        <AddressAutocomplete
                            disabled={disableAddressEditing}
                            state={{
                                street: order.middle_mile_origin_address,
                                city: order.middle_mile_origin_city,
                                state: order.middle_mile_origin_state,
                                zip: order.middle_mile_origin_zip,
                            }}
                            handleAddressUpdate={(value, split) => {
                                callbacks.modifyOrder({
                                    middle_mile_origin_address: split.street,
                                    middle_mile_origin_city: split.city,
                                    middle_mile_origin_state: split.state,
                                    middle_mile_origin_zip: split.zip,
                                });

                                callbacks.makeDirty([
                                    'middle_mile_origin_address',
                                    'middle_mile_origin_city',
                                    'middle_mile_origin_state',
                                    'middle_mile_origin_zip',
                                ]);
                            }}
                            error={
                                hasNewAddress &&
                                [
                                    'middle_mile_origin_address',
                                    'middle_mile_origin_city',
                                    'middle_mile_origin_state',
                                    'middle_mile_origin_zip',
                                ].some((attr) => hasError[attr] && (startDirty || isDirty[attr]))
                            }
                        />
                    </Row>
                    <Row>
                        <TextField
                            variant="outlined"
                            color="primary"
                            label="Unit/Suite #"
                            value={order.middle_mile_origin_unit}
                            onChange={(e) => callbacks.modifyOrder({ middle_mile_origin_unit: e.target.value })}
                            fullWidth
                        />
                    </Row>
                    <Row
                        css={css`
                            margin-top: 16px;
                        `}
                    >
                        <Grid
                            container
                            item
                            css={css`
                                flex-grow: 1;
                                margin-right: 16px;
                            `}
                        >
                            <TextField
                                fullWidth
                                label="City"
                                variant="outlined"
                                color="primary"
                                value={order.middle_mile_origin_city}
                                error={
                                    hasError.middle_mile_origin_city && (startDirty || isDirty.middle_mile_origin_city)
                                }
                                disabled={true}
                                onBlur={() => callbacks.makeDirty(['middle_mile_origin_city'])}
                                onChange={(e) => callbacks.modifyOrder({ middle_mile_origin_city: e.target.value })}
                            />
                        </Grid>
                        <Grid
                            container
                            item
                            css={css`
                                flex-grow: 1;
                                margin-right: 16px;
                            `}
                        >
                            <TextField
                                fullWidth
                                variant="outlined"
                                color="primary"
                                label="State"
                                disabled={true}
                                error={
                                    hasError.middle_mile_origin_state &&
                                    (startDirty || isDirty.middle_mile_origin_state)
                                }
                                value={order.middle_mile_origin_state}
                                onBlur={() => callbacks.makeDirty(['middle_mile_origin_state'])}
                                onChange={(e) => callbacks.modifyOrder({ middle_mile_origin_state: e.target.value })}
                            />
                        </Grid>
                        <Grid
                            container
                            item
                            css={css`
                                flex-grow: 1;
                            `}
                        >
                            <TextField
                                fullWidth
                                variant="outlined"
                                color="primary"
                                label="Zip"
                                value={order.middle_mile_origin_zip}
                                error={
                                    hasError.middle_mile_origin_zip && (startDirty || isDirty.middle_mile_origin_zip)
                                }
                                onBlur={() => callbacks.makeDirty(['middle_mile_origin_zip'])}
                                onChange={(e) => callbacks.modifyOrder({ middle_mile_origin_zip: e.target.value })}
                            />
                        </Grid>
                    </Row>

                    <Grid
                        container
                        css={css`
                            margin-top: 32px;
                        `}
                        xs={12}
                    >
                        <Grid
                            container
                            item
                            xs={4}
                            css={css`
                                margin-right: 16px;
                            `}
                        >
                            <LocationType
                                value={order.middle_mile_origin_location}
                                onChange={(e) => {
                                    callbacks.modifyOrder({ middle_mile_origin_location: e.target.value });
                                    callbacks.makeDirty(['middle_mile_origin_location']);
                                }}
                                error={
                                    hasError.middle_mile_origin_location &&
                                    (startDirty || isDirty.middle_mile_origin_location)
                                }
                            />
                        </Grid>
                        <Grid container item xs={4}>
                            <PickupType
                                disabled={
                                    !order.middle_mile_origin_location || order.middle_mile_origin_location.length === 0
                                }
                                value={order.middle_mile_origin_location_type}
                                locationType={order.middle_mile_origin_location}
                                onChange={(e) => {
                                    callbacks.modifyOrder({ middle_mile_origin_location_type: e.target.value });
                                    callbacks.makeDirty(['middle_mile_origin_location_type']);
                                }}
                                error={
                                    hasError.middle_mile_origin_location_type &&
                                    (startDirty || isDirty.middle_mile_origin_location_type)
                                }
                            />
                        </Grid>
                    </Grid>
                </>
            ) : null}
        </Grid>
    );
};

export default OriginTab;
