import React, { useEffect, useMemo, useState, useRef, useContext, useCallback } from 'react';
import _, { uniq } from 'lodash';
import { Global, css } from '@emotion/react';
import GoogleMap, { DirectionsRenderer, InfoWindow } from '@/components/GoogleMap';
import { genAttributes } from '@onward-delivery/core';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import { ROUTE_COLORS, SELECTED_COLOR } from '../constants';
import MapStopTooltip from './MapStopTooltip';
import MarkerCluster from './MarkerCluster';
import {
    SquareSVG,
    CircleSVG,
    TriangleSVG,
    PentagonSVG,
    StarSVG,
    CrossSVG,
    RectangleSVG,
    DiamondSVG,
    HeartSVG,
    StartSVG,
    EndSVG,
    DefaultSVG as DropoffSVG,
    PickupSVG,
    OnwardMarker,
    Abstract1SVG,
    Abstract2SVG,
    Abstract3SVG,
    Abstract4SVG,
    Abstract5SVG,
    Abstract6SVG,
    Abstract7SVG,
    Abstract8SVG,
    Abstract9SVG,
    Abstract10SVG,
    Abstract11SVG,
    Abstract12SVG,
    Abstract13SVG,
} from './Marker';
import { useNetworkRoutesQuery, useCallbacks, useLazyRecommendations } from '../hooks';
import { PlanningContext } from '../context';
import { deliveryTypeCode } from '../util/deliveryTypeCode';
import { MarkerLegendControl } from '@/components/GoogleMap/controls/MarkerLegendControl';
import { LassoControl } from '@/components/GoogleMap/controls/LassoControl';
import { ClearOrderControl } from '@/components/GoogleMap/controls/ClearOrderControl';
import { useClientUser } from '@/hooks';
import { ShapeControl } from '@/components/GoogleMap/controls/ShapeControl';
import { MODALS } from '../constants';
import { RecommendationsControl } from '@/components/GoogleMap/controls/RecommendationsControl';
import { getNonSkippedStops } from '../util/getNonSkippedStops';
import { colors } from '@/styles';
import MarkerStyleHolder from '../util/MarkerStyleHolder';
const PICKUP_FLAG = 'plan-page-order-pickups';
const MARKER_CLUSTERING_FLAG = 'plan-page-marker-clustering';
const DEFAULT_CENTER = { lat: 39.74, lng: -104.99 };
const DEFAULT_ZOOM = 13;
const TOOLTIP_OPTIONS = {
    minWidth: 300,
    maxWidth: 700,
    disableAutoPan: true,
    pixelOffset: new window.google.maps.Size(0, -5),
    headerDisabled: true,
};
import { PLAN_PAGE_LOADBOARD } from '@/constants/featureFlags';

const PlanningMap = () => {
    const {
        selectedRoute,
        selectedOrders,
        showAllDirections,
        showUnscheduledOrders,
        setSelectedOrders,
        expandedOrders,
        setExpandedOrders,
        selectMultiOrders,
        setDirectionLoading,
        setNetworkDirectionLoading,
        showLoadBoardLoads,
        selectedLoads,
        selectMultiLoads,
        showNetworkRoutes,
        setActionableRoute,
        setActionableStop,
        setModalOpen,
        state: { orders, routes, loadBoardLoads },
    } = useContext(PlanningContext);

    const {
        circles,
        user_id,
        shipping_partners,
        payment_type,
        savedShapes,
        default_end_location,
        tags,
        locations,
        invoiceAccess,
        ...timeframeOptions
    } = useClientUser();
    const { networkRoutes } = useNetworkRoutesQuery();
    const { saveShapes } = useCallbacks();

    const mapRef = useRef();
    const selectedRef = useRef([]);

    const [lassoEnabled, setLasso] = useState(false);
    const [mapData, setMapData] = useState({});
    const [loadMapData, setLoadMapData] = useState({});
    const [networkMapData, setNetworkMapData] = useState({});
    const [selectedMarker, selectMarker] = useState(null);
    const [selectedStop, selectStop] = useState(null);
    const [selectedOrder, selectOrder] = useState(null);
    const [selectedLoad, selectLoad] = useState(null);
    const [shapesOverride, setShapesOverride] = useState([]);
    const [comboShapesOverride, setComboShapesOverride] = useState([]);
    const [shapeFieldType, setShapeFieldType] = useState('item_type');
    const [markerType, setMarkerType] = useState('service_type');
    const [lockShapeType, setLockShapeType] = useState(false);
    const [showComboShapes, setShowComboShapes] = useState(false);

    const getShape = (type, options, order) => {
        switch (type) {
            case 'shipper':
                return (
                    options?.find((shape) => shape.name === order.shipper_id) || options?.find((shape) => !shape.name)
                );
            default:
                return options?.find((shape) => shape.name === order[type]);
        }
    };

    const onSelectBound = useCallback(
        (bounds) => {
            const selected = orders.filter((order) => {
                const { lat, long } = genAttributes(order);
                const { lat: pulat, long: pulong } = genAttributes(order, true);

                return order.crossdock_leg === 'pickup'
                    ? bounds.contains({ lat: order[pulat], lng: order[pulong] })
                    : bounds.contains({ lat: order[lat], lng: order[long] });
            });
            setSelectedOrders((prev) => {
                const rowId = (order) => `${order.order_id}_${order.crossdock_leg}`;
                const someSelected = selected.some((order) => prev[rowId(order)]);
                const allSelected = selected.every((order) => prev[rowId(order)]);
                if (allSelected) {
                    return {
                        ...prev,
                        ...Object.fromEntries(selected.map((order) => [rowId(order), false])),
                    };
                } else if (someSelected) {
                    return {
                        ...prev,
                        ...Object.fromEntries(selected.map((order) => [rowId(order), true])),
                    };
                } else {
                    return {
                        ...prev,
                        ...Object.fromEntries(selected.map((order) => [rowId(order), !prev[rowId(order)]])),
                    };
                }
            });
        },
        [orders]
    );

    const {
        getOrderRecommendations,
        data: recommendations,
        loading: recommendationsLoading,
    } = useLazyRecommendations();

    const routesByID = useMemo(() => {
        return Object.fromEntries(routes.map((route) => [route.route_id, route]));
    }, [routes]);

    const ordersByID = useMemo(() => {
        return Object.fromEntries(orders.map((order) => [order.order_id, order]));
    }, [orders]);

    const ordersByRowId = useMemo(() => {
        return Object.fromEntries(orders.map((order) => [`${order.order_id}_${order.crossdock_leg}`, order]));
    }, [orders]);

    const shapeFieldTypeOptions = useMemo(() => {
        const options = new Set();
        orders.forEach((order) => {
            if (order.shipper_id !== user_id) {
                options.add('shipper');
            }
            if (order.template_name) {
                options.add('template_name');
            }
            if (order.manufacturer) {
                options.add('manufacturer');
            }
            if (order.item_type) {
                options.add('item_type');
            }
            if (order.custom_shape) {
                options.add('custom_shape');
            }
        });
        options.add('order_type');
        const optArr = [...options];
        if (!lockShapeType) {
            setShapeFieldType(optArr[0] || 'item_type');
        }
        return optArr;
    }, [orders, user_id]);

    const svgStyles = useMemo(() => {
        const styleHolder = new MarkerStyleHolder();
        return styleHolder;
    }, [orders, selectedRoute]);

    const loadsByID = useMemo(() => {
        if (!loadBoardLoads) return {};
        return Object.fromEntries(loadBoardLoads?.map((order) => [order.order_id, order]));
    }, [loadBoardLoads]);

    const fieldShapesSingle = useMemo(() => {
        let _fieldShapesSingle = [];
        let names = [];
        let shapes = [
            'default',
            'square',
            'circle',
            'triangle',
            'pentagon',
            'star',
            'cross',
            'rectangle',
            'diamond',
            'heart',
            'abstract1',
            'abstract2',
            'abstract3',
            'abstract4',
            'abstract5',
            'abstract6',
            'abstract7',
            'abstract8',
            'abstract9',
            'abstract10',
            'abstract11',
            'abstract12',
            'abstract13',
        ];

        switch (shapeFieldType) {
            case 'shipper':
                _fieldShapesSingle.push({
                    name: '',
                    label: 'None',
                    shape: shapes.shift(),
                });
                shipping_partners.forEach((partner) => {
                    const fromDb = savedShapes?.[shapeFieldType]?.find((el) => el.name === partner.shipper_id)?.shape;
                    _fieldShapesSingle.push({
                        name: partner.shipper_id,
                        label: partner.shipper_alias || partner.shipper.business_name,
                        shape: fromDb || shapes.shift(),
                    });
                });
                break;
            default:
                orders.forEach((order) => {
                    let shapeObj = {};
                    if (order[shapeFieldType] && !names.includes(order[shapeFieldType])) {
                        let fromDb = savedShapes?.[shapeFieldType]?.find((el) => el.name === order[shapeFieldType]);
                        names.push(order[shapeFieldType]);
                        shapeObj['name'] = order[shapeFieldType];
                        if (fromDb) {
                            //load saved shapes from db
                            const shapeIdx = shapes.indexOf(fromDb.shape);
                            shapes.splice(shapeIdx, 1);
                            shapeObj['shape'] = fromDb.shape;
                        } else {
                            //auto assign shapes if not already assigned
                            shapeObj['shape'] = shapes.shift();
                        }
                        _fieldShapesSingle = [..._fieldShapesSingle, shapeObj];
                    }
                });
                break;
        }

        return _fieldShapesSingle;
    }, [orders, savedShapes, shapeFieldType, showComboShapes, shipping_partners]);

    const comboPotentialChoices = useMemo(() => {
        let choices = {
            manufacturer: [],
            template_name: [],
            item_type: [],
            custom_shape: [],
            order_type: ['delivery', 'return', 'exchange'],
        };
        Object.keys(choices).forEach((category) => {
            orders.forEach((order) => {
                if (order[category] && !choices[category].includes(order[category])) {
                    choices[category].push(order[category]);
                }
            });
        });
        return choices;
    }, [orders]);

    useEffect(() => {
        setShapesOverride(fieldShapesSingle);
        setComboShapesOverride(savedShapes?.combinations || null);
    }, [fieldShapesSingle, savedShapes]);

    const tooltip = useMemo(() => {
        if (selectedStop) {
            const orders = (selectedStop.orders || []).map((key) => ordersByID[key]).filter((o) => o);

            return (
                <MapStopTooltip
                    orders={orders}
                    stop={selectedStop}
                    timeframeOptions={timeframeOptions}
                    user_id={user_id}
                    invoiceAccess={invoiceAccess}
                />
            );
        }

        if (selectedOrder) {
            const orders = selectedOrder.map((key) => ordersByRowId[key]).filter((o) => o);
            return (
                <MapStopTooltip
                    orders={orders}
                    timeframeOptions={timeframeOptions}
                    user_id={user_id}
                    invoiceAccess={invoiceAccess}
                />
            );
        }
        if (selectedLoad) {
            const orders = selectedLoad.map((key) => loadsByID[key]).filter((o) => o);
            return (
                <MapStopTooltip
                    orders={orders}
                    timeframeOptions={timeframeOptions}
                    user_id={user_id}
                    invoiceAccess={invoiceAccess}
                />
            );
        }

        return null;
    }, [selectedStop, selectedRoute, selectedOrder, selectedLoad, ordersByID, loadsByID, ordersByRowId]);

    const routesToRender = useMemo(() => {
        return routes.filter((route) => {
            const selected = route.route_id === selectedRoute;

            return showAllDirections && !selected && mapData[route.route_id];
        });
    }, [routes, mapData, showAllDirections, selectedRoute]);

    const networkRoutesToRender = useMemo(() => {
        return networkRoutes.filter((route) => {
            return showNetworkRoutes && networkMapData[route.route_id];
        });
    }, [networkRoutes, networkMapData, showNetworkRoutes]);

    const remainingOrders = useMemo(() => {
        const rendered = routesToRender.map((route) => route.route_id);

        return orders.filter((order) => {
            const orderRouteId = order.crossdock_leg === 'pickup' ? order.pickup_route_id : order.dropoff_route_id;
            const notRouted = !rendered.includes(orderRouteId) && !(selectedRoute && orderRouteId === selectedRoute);

            const isUnscheduled = order.crossdock_leg === 'pickup' ? !order.pickup_date : !order.delivery_date;

            return notRouted && (!isUnscheduled || showUnscheduledOrders);
        });
    }, [orders, routesToRender, routesByID, selectedRoute, showUnscheduledOrders]);

    const bounds = useMemo(() => {
        if (!orders || !mapData) {
            return;
        }

        if (selectedRoute) {
            // If route has default end location, and the only stop is the end location, skip this (i.e. do not automatically zoom in on end stop when route is selected).
            if (default_end_location && mapData[selectedRoute]?.stopSequence.length === 1) {
                return [];
            }

            return (
                mapData[selectedRoute]?.stopSequence?.map((stop) => ({
                    lat: stop.lat,
                    lng: stop.lng,
                })) || []
            );
        }

        if (routesToRender?.length || remainingOrders?.length) {
            return [
                ...routesToRender.reduce((acc, route) => {
                    const stops = mapData[route.route_id].stopSequence;
                    return [
                        ...acc,
                        ...(stops?.map((stop) => ({
                            lat: stop.lat,
                            lng: stop.lng,
                        })) || []),
                    ];
                }, []),
                ...remainingOrders.reduce((acc, order) => {
                    const { lat, long } = genAttributes(order);
                    const { lat: pulat, long: pulong } = genAttributes(order, true);
                    return [...acc, { lat: order[lat], lng: order[long] }, { lat: order[pulat], lng: order[pulong] }];
                }, []),
            ];
        }

        if (locations?.length) {
            return locations
                .filter((l) => l.lat && l.lng)
                .map((l) => ({
                    lat: l.lat,
                    lng: l.lng,
                }));
        }

        return [];
    }, [remainingOrders, selectedRoute, routesToRender, mapData, locations]);

    const useBounds = useMemo(() => {
        return (bounds && bounds.length > 0) || remainingOrders.length > 0;
    }, [bounds, remainingOrders]);

    const getDirections = async (route, stopSequence) => {
        if (route && route?.stopsByRouteId?.length) {
            if (!stopSequence.length) return { routeId: route.route_id };
            const routeDirections = await FTLStopHelpers.getGMapsDirections(stopSequence, new Date(route.start_time));
            if (_.isObject(routeDirections) && Object.keys(routeDirections).length) {
                return {
                    routeId: route.route_id,
                    stopSequence,
                    routeDirections,
                };
            }
        }
        return { routeId: route.route_id };
    };

    useEffect(() => {
        selectedRef.current.forEach((marker) => {
            marker.setMap(null);
        });

        if (mapRef.current) {
            const stops = routes
                .filter((r) => r.route_id === selectedRoute)
                .reduce((markers, route) => {
                    const idx = routes.findIndex((r) => r.route_id === route.route_id);
                    const stops = mapData[route.route_id]?.stopSequence || [];
                    const color = route.route_color ? route.route_color : idx >= 0 ? ROUTE_COLORS[idx] : '#000';

                    return [
                        ...markers,
                        ...stops.map((stop) => {
                            let type;
                            let callbacks = {};
                            let svg;
                            let label = ' ';

                            if (stop.start) {
                                type = 'start';
                                svg = StartSVG({ color });
                            } else if (stop.end) {
                                type = 'end';
                                svg = EndSVG({ color });
                            } else if (stop.type === 'DROPOFF' || (stop.type === 'PICKUP' && stop.returns?.length)) {
                                type = 'do';
                                label = `${stop.ordering}`;
                                const isSelected = stop.orders?.every((o) => selectedOrders[o]);

                                if (isSelected) {
                                    const styleFunction = svgStyles.addMarkerAndGetStyle(stop, stops);
                                    svg = styleFunction({
                                        color: SELECTED_COLOR,
                                        altColor: color,
                                    });
                                } else {
                                    svgStyles.turnMarkerStyleOff(stop);
                                    svg = DropoffSVG({
                                        color: color,
                                    });
                                }

                                callbacks = {
                                    onContextMenu: () => {
                                        setModalOpen(MODALS.ROUTE_REMOVE_STOP);
                                        setActionableRoute(route);
                                        setActionableStop({ ...stop, stopOrders: stop.orders });
                                    },
                                    onClick: () => {
                                        selectMultiOrders(
                                            stop.orders?.map(o => `${ordersByID[o].order_id}_${ordersByID[o].crossdock_leg}`) || [],
                                            !stop.orders?.map(o => `${ordersByID[o].order_id}_${ordersByID[o].crossdock_leg}`)?.every((o) => selectedOrders[o])
                                        );
                                    },
                                    onMouseOver: (ref) => {
                                        selectStop(stop);
                                        selectOrder(null);
                                        selectMarker(ref);
                                    },
                                    onMouseOut: () => {
                                        selectStop(null);
                                        selectOrder(null);
                                        selectMarker(null);
                                    },
                                };
                            } else if (stop.type === 'PICKUP' && !stop.returns?.length) {
                                type = 'pu';
                                if (stop.orders?.some((o) => selectedOrders[o])) {
                                    const styleFunction = svgStyles.addAndGetPickupMarkerStyle(stop);

                                    svg = styleFunction({
                                        color: SELECTED_COLOR,
                                        altColor: color,
                                    });
                                } else {
                                    svg = PickupSVG({
                                        color: color,
                                    });
                                }
                                callbacks = {
                                    onMouseOver: (ref) => {
                                        selectStop(stop);
                                        selectOrder(null);
                                        selectMarker(ref);
                                    },
                                    onMouseOut: () => {
                                        selectStop(null);
                                        selectOrder(null);
                                        selectMarker(null);
                                    },
                                };
                            }

                            return {
                                key: `${route.route_id}-${type}-${stop.ordering}`,
                                label,
                                position: { lat: stop.lat, lng: stop.lng },
                                svg,
                                callbacks,
                            };
                        }),
                    ];
                }, []);

            const overlaps = stops.reduce((acc, marker) => {
                const { lat, lng } = marker.position;

                acc[lat] = { ...acc[lat], [lng]: [...(acc?.[lat]?.[lng] || []), marker.key] };

                return acc;
            }, {});

            selectedRef.current = stops.map((props) => {
                const idx = overlaps[props.position.lat][props.position.lng].indexOf(props.key);
                return new OnwardMarker({ ...props, overlapIdx: idx, map: mapRef.current });
            });
        }
    }, [mapRef.current, routes, selectedRoute, mapData, selectedOrders]);

    const [regularMarkers, selectedMarkers, expandedMarkers] = useMemo(() => {
        const drawSVG = (shape, props) => {
            switch (shape) {
                case 'square':
                    return SquareSVG(props);
                case 'circle':
                    return CircleSVG(props);
                case 'triangle':
                    return TriangleSVG(props);
                case 'pentagon':
                    return PentagonSVG(props);
                case 'star':
                    return StarSVG(props);
                case 'cross':
                    return CrossSVG(props);
                case 'rectangle':
                    return RectangleSVG(props);
                case 'diamond':
                    return DiamondSVG(props);
                case 'heart':
                    return HeartSVG(props);
                case 'abstract1':
                    return Abstract1SVG(props);
                case 'abstract2':
                    return Abstract2SVG(props);
                case 'abstract3':
                    return Abstract3SVG(props);
                case 'abstract4':
                    return Abstract4SVG(props);
                case 'abstract5':
                    return Abstract5SVG(props);
                case 'abstract6':
                    return Abstract6SVG(props);
                case 'abstract7':
                    return Abstract7SVG(props);
                case 'abstract8':
                    return Abstract8SVG(props);
                case 'abstract9':
                    return Abstract9SVG(props);
                case 'abstract10':
                    return Abstract10SVG(props);
                case 'abstract11':
                    return Abstract11SVG(props);
                case 'abstract12':
                    return Abstract12SVG(props);
                case 'abstract13':
                    return Abstract13SVG(props);
                case 'default':
                    return `<svg width='27' height='33' viewBox='0 0 27 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <mask id='path-1-inside-1_1834_43062' fill='white'><path fill-rule='evenodd' clip-rule='evenodd' d='M26.0014 0H0V18.9304H0.00385723L12.6967 32.9996L26.0013 18.9304H26.0014V18.9303L26.0033 18.9283H26.0014V0Z'/>
                        </mask>
                        <path fill-rule='evenodd' clip-rule='evenodd' d='M26.0014 0H0V18.9304H0.00385723L12.6967 32.9996L26.0013 18.9304H26.0014V18.9303L26.0033 18.9283H26.0014V0Z' fill='${props.color}'/><path d='M0 0V-2H-2V0H0ZM26.0014 0H28.0014V-2H26.0014V0ZM0 18.9304H-2V20.9304H0V18.9304ZM0.00385723 18.9304L1.48884 17.5907L0.893144 16.9304H0.00385723V18.9304ZM12.6967 32.9996L11.2117 34.3394L12.6622 35.947L14.1499 34.3738L12.6967 32.9996ZM26.0013 18.9304V16.9304H25.14L24.5481 17.5563L26.0013 18.9304ZM26.0014 18.9304V20.9304H28.0014V18.9304H26.0014ZM26.0014 18.9303L24.5481 17.5563L24.0014 18.1346V18.9303H26.0014ZM26.0033 18.9283L27.4567 20.3023L30.6464 16.9283H26.0033V18.9283ZM26.0014 18.9283H24.0014V20.9283H26.0014V18.9283ZM0 2H26.0014V-2H0V2ZM2 18.9304V0H-2V18.9304H2ZM0.00385723 16.9304H0V20.9304H0.00385723V16.9304ZM14.1817 31.6599L1.48884 17.5907L-1.48112 20.2702L11.2117 34.3394L14.1817 31.6599ZM24.5481 17.5563L11.2436 31.6255L14.1499 34.3738L27.4544 20.3046L24.5481 17.5563ZM26.0014 16.9304H26.0013V20.9304H26.0014V16.9304ZM24.0014 18.9303V18.9304H28.0014V18.9303H24.0014ZM24.55 17.5543L24.5481 17.5563L27.4548 20.3043L27.4567 20.3023L24.55 17.5543ZM26.0014 20.9283H26.0033V16.9283H26.0014V20.9283ZM24.0014 0V18.9283H28.0014V0H24.0014Z' fill='white' mask='url(#path-1-inside-1_1834_43062)'/>
                    </svg>`;
                default:
                    return DropoffSVG(props);
            }
        };

        const raw = {
            dropoff: remainingOrders.reduce((omAcc, order) => {
                const rowId = `${order.order_id}_${order.crossdock_leg}`;
                const routeId = order.crossdock_leg === 'pickup' ? order.pickup_route_id : order.dropoff_route_id;
                const { lat, long } = genAttributes(order);
                const warehouse = order.wh_events?.findLast((e) => e.action.endsWith('ADD_CD'))?.location || {};
                const markerCoordinates =
                    order.crossdock_leg === 'pickup'
                        ? { lat: warehouse.lat, lng: warehouse.lng }
                        : { lat: order[lat], lng: order[long] };
                const markerKey = `${order.shipper_id}-${rowId}-do`;
                const shapeInfoSingle = getShape(shapeFieldType, shapesOverride, order);
                let shapeInfoCombo;
                if (savedShapes?.combinations) {
                    savedShapes.combinations.forEach((combination) => {
                        const isMatch = Object.keys(combination).every((key) => {
                            if (key !== 'shape') {
                                return order[key] === combination[key];
                            }
                            return true;
                        });
                        if (isMatch) {
                            shapeInfoCombo = combination.shape;
                        }
                    });
                }
                const orderRouteColor = routes.find((r) => r.route_id === routeId)?.route_color;

                if (omAcc[markerKey]) {
                    return {
                        ...omAcc,
                        [markerKey]: {
                            ...omAcc[markerKey],
                            orderKeys: [...omAcc[markerKey].orderKeys, rowId],
                        },
                    };
                } else {
                    return {
                        ...omAcc,
                        [markerKey]: {
                            orderKeys: [rowId],
                            color: selectedOrders[rowId]
                                ? SELECTED_COLOR
                                : orderRouteColor
                                ? orderRouteColor
                                : ROUTE_COLORS[routes.findIndex((r) => r.route_id === routeId)] || 'black',
                            position: markerCoordinates,
                            key: markerKey,
                            shape: showComboShapes ? shapeInfoCombo || null : shapeInfoSingle?.shape || null,
                        },
                    };
                }
            }, {}),
            pickup: remainingOrders.reduce((acc, order) => {
                const rowId = `${order.order_id}_${order.crossdock_leg}`;
                const { lat, long } = genAttributes(order, true);
                const warehouse = order.wh_events?.findLast((e) => e.action.endsWith('ADD_CD'))?.location || {};
                const markerCoordinates =
                    order.crossdock_leg === 'dropoff'
                        ? { lat: warehouse.lat, lng: warehouse.lng }
                        : { lat: order[lat], lng: order[long] };
                const markerKey = `${order.shipper_id}-${markerCoordinates.lat}-${markerCoordinates.lng}-pu`;

                if (acc[markerKey]) {
                    return {
                        ...acc,
                        [markerKey]: {
                            ...acc[markerKey],
                            orderKeys: [...acc[markerKey].orderKeys, rowId],
                            color: selectedOrders[rowId] ? SELECTED_COLOR : acc[markerKey]?.color || 'black',
                        },
                    };
                } else {
                    return {
                        ...acc,
                        [markerKey]: {
                            orderKeys: [rowId],
                            color: selectedOrders[rowId] ? SELECTED_COLOR : 'black',
                            position: markerCoordinates,
                            key: markerKey,
                        },
                    };
                }
            }, {}),
        };

        const markers = {
            dropoff: Object.entries(raw.dropoff).map(([key, orderMarker], idx) => {
                const codes = orderMarker?.orderKeys.map((key) => {
                    const order = ordersByRowId[key];
                    const { location_type } = genAttributes(order);

                    if (markerType === 'service_type') {
                        return order[location_type];
                    } else if (markerType === 'order_type') {
                        return order.order_type;
                    }

                    return null;
                });

                const isUnscheduled = orderMarker?.orderKeys.every((key) => {
                    const order = ordersByRowId[key];
                    return order.crossdock_leg === 'pickup' ? !order.pickup_date : !order.delivery_date;
                });

                const label = isUnscheduled ? 'U' : deliveryTypeCode(codes, markerType);
                const tags = uniq(
                    (orderMarker?.orderKeys || []).reduce((acc, key) => {
                        const orderTags = (ordersByRowId[key]?.tags || [])
                            .filter(({ tag }) => tag.route_tag)
                            .map(({ tag }) => tag.tag);
                        return {
                            ...acc,
                            ...orderTags,
                        };
                    }, [])
                );

                return {
                    orders: orderMarker.orderKeys,
                    key,
                    label,
                    tags: markerType === 'order_tags' ? tags : [],
                    position: orderMarker.position,
                    svg: drawSVG(orderMarker.shape, { color: orderMarker.color }),
                    shape: orderMarker.shape,
                    callbacks: {
                        onClick: () => {
                            selectMultiOrders(
                                orderMarker.orderKeys,
                                !orderMarker.orderKeys?.every((key) => selectedOrders[key])
                            );
                            selectMultiLoads(null);
                            selectLoad(null);
                        },
                        onMouseOver: (ref) => {
                            selectStop(null);
                            selectOrder(orderMarker.orderKeys);
                            selectMarker(ref);
                        },
                        onMouseOut: () => {
                            selectStop(null);
                            selectOrder(null);
                            selectMarker(null);
                        },
                    },
                };
            }),
            pickup: Object.entries(raw.pickup).map(([key, orderMarker], idx) => {
                return {
                    orders: orderMarker.orderKeys,
                    key,
                    position: orderMarker.position,
                    svg: PickupSVG({ color: orderMarker.color }),
                    callbacks: {},
                };
            }),
        };

        const routeStops = routesToRender.reduce((markers, route) => {
            const idx = routes.findIndex((r) => r.route_id === route.route_id);
            const stops = mapData[route.route_id]?.stopSequence || [];
            const color = route.route_color ? route.route_color : idx >= 0 ? ROUTE_COLORS[idx] : '#000';

            return [
                ...markers,
                ...stops.map((stop) => {
                    let type;
                    let callbacks = {};
                    let svg;
                    let label = ' ';

                    if (stop.start) {
                        type = 'start';
                        svg = StartSVG({ color });
                    } else if (stop.end) {
                        type = 'end';
                        svg = EndSVG({ color });
                    } else if (stop.type === 'DROPOFF' || (stop.type === 'PICKUP' && stop.returns?.length)) {
                        type = 'do';
                        label = `${stop.ordering}`;
                        svg = DropoffSVG({
                            color: color,
                            // color: stop.orders?.every((o) => selectedOrders[o]) ? SELECTED_COLOR : color,
                        });
                        callbacks = {
                            onContextMenu: () => {
                                setModalOpen(MODALS.ROUTE_REMOVE_STOP);
                                setActionableRoute(route);
                                setActionableStop({ ...stop, stopOrders: stop.orders });
                            },
                            onClick: () => {
                                // selectMultiOrders(stop.orders || [], !stop.orders?.every((o) => selectedOrders[o]));
                            },
                            onMouseOver: (ref) => {
                                selectStop(stop);
                                selectOrder(null);
                                selectMarker(ref);
                            },
                            onMouseOut: () => {
                                selectStop(null);
                                selectOrder(null);
                                selectMarker(null);
                            },
                        };
                    } else if (stop.type === 'PICKUP' && !stop.returns?.length) {
                        type = 'pu';
                        svg = PickupSVG({ color });
                        callbacks = {
                            onMouseOver: (ref) => {
                                selectStop(stop);
                                selectOrder(null);
                                selectMarker(ref);
                            },
                            onMouseOut: () => {
                                selectStop(null);
                                selectOrder(null);
                                selectMarker(null);
                            },
                        };
                    }

                    return {
                        orders: stop.orders,
                        key: `${route.route_id}-${type}-${stop.ordering}`,
                        label,
                        position: { lat: stop.lat, lng: stop.lng },
                        svg,
                        callbacks,
                    };
                }),
            ];
        }, []);

        const all = [...markers.dropoff, ...(circles?.[PICKUP_FLAG] ? markers.pickup : []), ...routeStops];
        const selected = all.filter((marker) => (marker?.orders || []).every((id) => selectedOrders[id]));
        const regular = all.filter(
            (marker) => !(marker?.orders || []).every((id) => selectedOrders[id] || expandedOrders[id])
        );
        const expanded = all.filter((marker) =>
            (marker?.orders || []).every((id) => expandedOrders[id] && !selectedOrders[id])
        );
        const overlaps = all.reduce((acc, marker) => {
            const { lat, lng } = marker.position;

            acc[lat] = { ...acc[lat], [lng]: [...(acc?.[lat]?.[lng] || []), marker.key] };

            return acc;
        }, {});

        return [
            regular.map((props) => {
                const idx = overlaps[props.position.lat][props.position.lng].indexOf(props.key);
                return new OnwardMarker({ ...props, overlapIdx: idx });
            }),
            selected.map((props) => {
                const idx = overlaps[props.position.lat][props.position.lng].indexOf(props.key);
                return new OnwardMarker({ ...props, overlapIdx: idx });
            }),
            expanded.map((props) => {
                const idx = overlaps[props.position.lat][props.position.lng].indexOf(props.key);
                return new OnwardMarker({ ...props, overlapIdx: idx });
            }),
        ];
    }, [remainingOrders, routesToRender, mapData, selectedOrders, expandedOrders, circles, shapesOverride, markerType]);

    const loadMarkers = useMemo(() => {
        if (!circles?.[PLAN_PAGE_LOADBOARD] || !showLoadBoardLoads) {
            return [];
        }

        const raw = {
            dropoff: loadBoardLoads.reduce((omAcc, order) => {
                const { lat, long } = genAttributes(order);
                const markerCoordinates = { lat: order[lat], lng: order[long] };
                const markerKey = `${order.shipper_id}-${order.order_id}-do`;

                if (omAcc[markerKey]) {
                    return {
                        ...omAcc,
                        [markerKey]: {
                            ...omAcc[markerKey],
                            orderKeys: [...omAcc[markerKey].orderKeys, order.order_id],
                        },
                    };
                } else {
                    return {
                        ...omAcc,
                        [markerKey]: {
                            orderKeys: [order.order_id],
                            color: selectedLoads[order.order_id] ? SELECTED_COLOR : 'darkgreen',
                            position: markerCoordinates,
                            key: markerKey,
                        },
                    };
                }
            }, {}),
            pickup: loadBoardLoads.reduce((acc, order) => {
                const { lat, long } = genAttributes(order, true);
                const markerCoordinates = { lat: order[lat], lng: order[long] };
                const markerKey = `${order.shipper_id}-${order.order_id}-pu`;

                if (acc[markerKey]) {
                    return {
                        ...acc,
                        [markerKey]: {
                            ...acc[markerKey],
                            orderKeys: [...acc[markerKey].orderKeys, order.order_id],
                            color: [...acc[markerKey].orderKeys, order.order_id]?.some(
                                (order_id) => selectedLoads[order_id]
                            )
                                ? SELECTED_COLOR
                                : 'darkgreen',
                        },
                    };
                } else {
                    return {
                        ...acc,
                        [markerKey]: {
                            orderKeys: [order.order_id],
                            color: selectedLoads[order.order_id] ? SELECTED_COLOR : 'darkgreen',
                            position: markerCoordinates,
                            key: markerKey,
                        },
                    };
                }
            }, {}),
        };

        const markers = {
            dropoff: Object.entries(raw.dropoff).map(([key, loadMarker]) => {
                return {
                    key,
                    label: deliveryTypeCode(['marketPlace'], 'service_type'),
                    position: loadMarker.position,
                    svg: DropoffSVG({ color: loadMarker.color }),
                    callbacks: {
                        onClick: () => {
                            selectMultiLoads(
                                loadMarker.orderKeys,
                                !loadMarker.orderKeys?.every((key) => selectedLoads[key])
                            );
                            selectMultiOrders(null);
                            selectOrder(null);
                        },
                        onMouseOver: (ref) => {
                            selectStop(null);
                            selectLoad(loadMarker.orderKeys);
                            selectOrder(null);
                            selectMarker(ref);
                        },
                        onMouseOut: () => {
                            selectStop(null);
                            selectLoad(null);
                            selectOrder(null);
                            selectMarker(null);
                        },
                    },
                };
            }),
            pickup: Object.entries(raw.pickup).map(([key, loadMarker], idx) => {
                return {
                    key,
                    position: loadMarker.position,
                    svg: PickupSVG({ color: loadMarker.color }),
                    callbacks: {},
                };
            }),
        };

        const all = [...markers.dropoff, ...markers.pickup];
        const overlaps = all.reduce((acc, marker) => {
            const { lat, lng } = marker.position;

            acc[lat] = { ...acc[lat], [lng]: [...(acc?.[lat]?.[lng] || []), marker.key] };

            return acc;
        }, {});

        return all.map((props) => {
            const idx = overlaps[props.position.lat][props.position.lng].indexOf(props.key);
            return new OnwardMarker({ ...props, overlapIdx: idx });
        });
    }, [loadBoardLoads, selectedLoads, showLoadBoardLoads]);

    const networkMarkers = useMemo(() => {
        const routeStops = networkRoutesToRender.reduce((markers, route) => {
            const stops = networkMapData[route.route_id]?.stopSequence || [];
            const color = 'midnightblue';

            return [
                ...markers,
                ...stops.map((stop) => {
                    let type;
                    let callbacks = {};
                    let svg;
                    let label = ' ';

                    if (stop.start) {
                        type = 'start';
                        svg = StartSVG({ color });
                    } else if (stop.end) {
                        type = 'end';
                        svg = EndSVG({ color });
                    }

                    return {
                        key: `${route.route_id}-${type}-${stop.ordering}`,
                        label,
                        position: { lat: stop.lat, lng: stop.lng },
                        svg,
                        callbacks,
                    };
                }),
            ];
        }, []);

        const all = routeStops;
        const overlaps = all.reduce((acc, marker) => {
            const { lat, lng } = marker.position;

            acc[lat] = { ...acc[lat], [lng]: [...(acc?.[lat]?.[lng] || []), marker.key] };

            return acc;
        }, {});

        return all.map((props) => {
            const idx = overlaps[props.position.lat][props.position.lng].indexOf(props.key);
            return new OnwardMarker({ ...props, overlapIdx: idx });
        });
    }, [networkRoutesToRender, networkMapData]);

    useEffect(() => {
        if (routes) {
            const rendered = routes.filter(({ route_id }) => showAllDirections || route_id === selectedRoute);

            rendered.forEach((route) => {
                const routeStops = getNonSkippedStops(FTLStopHelpers.getStopSequence(route.stopsByRouteId));
                if (routeStops.length === 0 && mapData[route.route_id]) {
                    setMapData((prev) => {
                        const { [route.route_id]: removed, ...remaining } = prev;

                        return remaining;
                    });
                } else if (
                    routeStops.length > 0 &&
                    (mapData[route.route_id]?.stopSequence?.length !== routeStops.length ||
                        !mapData[route.route_id]?.stopSequence?.every(
                            (stop, i) => stop.address === routeStops[i].address
                        ))
                ) {
                    getDirections(route, routeStops).then((result) => {
                        if (result.routeDirections) {
                            setMapData((prev) => ({
                                ...prev,
                                [result.routeId]: {
                                    stopSequence: result.stopSequence,
                                    routeDirections: result.routeDirections,
                                },
                            }));
                        }
                    });
                }
            });
        }
    }, [routes, mapData, selectedRoute, showAllDirections]);

    useEffect(() => {
        if (networkRoutes && showNetworkRoutes) {
            networkRoutes.forEach((route) => {
                const routeStops = getNonSkippedStops(FTLStopHelpers.getStopSequence(route.stopsByRouteId));
                if (routeStops.length === 0 && networkMapData[route.route_id]) {
                    setNetworkMapData((prev) => {
                        const { [route.route_id]: removed, ...remaining } = prev;

                        return remaining;
                    });
                } else if (
                    routeStops.length > 0 &&
                    (networkMapData[route.route_id]?.stopSequence?.length !== routeStops.length ||
                        !networkMapData[route.route_id]?.stopSequence?.every(
                            (stop, i) => stop.address === routeStops[i].address
                        ))
                ) {
                    getDirections(route, routeStops).then((result) => {
                        if (result.routeDirections) {
                            setNetworkMapData((prev) => ({
                                ...prev,
                                [result.routeId]: {
                                    stopSequence: result.stopSequence,
                                    routeDirections: result.routeDirections,
                                },
                            }));
                        }
                    });
                }
            });
        }
    }, [networkRoutes, networkMapData, showNetworkRoutes]);

    useEffect(() => {
        if (loadBoardLoads) {
            loadBoardLoads.forEach((load) => {
                if (loadMapData[load.order_id]) {
                    return;
                } else {
                    FTLStopHelpers.getLoadDirections(load).then((result) => {
                        if (result.status === 'OK') {
                            setLoadMapData((prev) => ({
                                ...prev,
                                [load.order_id]: {
                                    directions: result,
                                },
                            }));
                        }
                    });
                }
            });
        }
    }, [loadBoardLoads, loadMapData]);

    useEffect(() => {
        if (routes) {
            const rendered = routes.filter(({ route_id }) => showAllDirections || route_id === selectedRoute);
            const complete = rendered.filter(({ route_id }) => mapData[route_id]);

            setDirectionLoading({
                total: rendered.length,
                complete: complete.length,
            });
        }
    }, [routes, mapData]);
    useEffect(() => {
        if (networkRoutes && showNetworkRoutes) {
            const complete = networkRoutes.filter(({ route_id }) => networkMapData[route_id]);

            setNetworkDirectionLoading({
                total: networkRoutes.length,
                complete: complete.length,
            });
        }
    }, [routes, mapData]);

    const renderDirections = (routeId, routeDirections, color) => {
        return (
            <DirectionsRenderer
                key={`directions-${routeId}`}
                directions={routeDirections}
                options={{
                    suppressMarkers: true,
                    polylineOptions: {
                        strokeColor: color
                            ? color
                            : ROUTE_COLORS[routes.findIndex((r) => r.route_id === routeId)] || 'black',
                        strokeOpacity: 1,
                    },
                }}
            />
        );
    };

    return (
        <>
            <Global
                styles={css`
                    .gm-style .gm-style-iw-c {
                        & button[title='Close'] {
                            visibility: hidden;
                        }
                    }
                `}
            />
            <GoogleMap
                defaultMap={!!circles?.['plan-page-default-map']}
                ref={mapRef}
                style={{ height: '100%' }}
                bounds={bounds}
                center={!useBounds ? DEFAULT_CENTER : undefined}
                zoom={!useBounds ? DEFAULT_ZOOM : undefined}
            >
                {/* Directions for Selected Route */}
                {Object.keys(mapData).length && selectedRoute && (
                    <>
                        {renderDirections(
                            selectedRoute,
                            mapData[selectedRoute]?.routeDirections,
                            routesByID[selectedRoute]?.route_color
                        )}
                    </>
                )}

                {/* Directions for all routes if none selected */}
                {Object.keys(mapData).length && !selectedRoute && showAllDirections && (
                    <>
                        {routesToRender.map((route) =>
                            renderDirections(
                                route.route_id,
                                mapData[route.route_id].routeDirections || [],
                                route.route_color
                            )
                        )}
                    </>
                )}
                {Object.keys(networkMapData).length && showNetworkRoutes && (
                    <>
                        {networkRoutesToRender.map(({ route_id }) =>
                            renderDirections(route_id, networkMapData[route_id].routeDirections || [], 'midnightblue')
                        )}
                    </>
                )}

                {loadBoardLoads?.length && circles?.[PLAN_PAGE_LOADBOARD] && showLoadBoardLoads && (
                    <>
                        {loadBoardLoads.map((load) =>
                            renderDirections(load.order_id, loadMapData[load.order_id]?.directions || [], '#71b77a')
                        )}
                    </>
                )}
            </GoogleMap>

            <MarkerCluster
                map={mapRef}
                markers={regularMarkers}
                disabled={!circles?.[MARKER_CLUSTERING_FLAG] || regularMarkers?.length <= 100}
                callbacks={{
                    onClick: (e, cluster, map) => {
                        const ids = cluster.markers.reduce((acc, marker) => {
                            return [...acc, ...marker.ids];
                        }, []);

                        setExpandedOrders((prev) => {
                            const select = !ids.every((o) => prev[o]);

                            return {
                                ...prev,
                                ...Object.fromEntries(ids.map((id) => [id, select])),
                            };
                        });
                    },
                }}
            />
            <MarkerCluster
                map={mapRef}
                markers={expandedMarkers}
                disabled={!circles?.[MARKER_CLUSTERING_FLAG] || expandedMarkers?.length}
                callbacks={{
                    onClick: (e, cluster, map) => {
                        const ids = cluster.markers.reduce((acc, marker) => {
                            return [...acc, ...marker.ids];
                        }, []);
                        setSelectedOrders((prev) => {
                            const select = !ids.every((o) => prev[o]);
                            return {
                                ...prev,
                                ...Object.fromEntries(ids.map((id) => [id, select])),
                            };
                        });
                    },
                }}
            />
            <MarkerCluster
                map={mapRef}
                markers={selectedMarkers}
                disabled={!circles?.[MARKER_CLUSTERING_FLAG] || selectedMarkers?.length}
                color={colors.greens.primary}
                callbacks={{
                    onClick: (e, cluster, map) => {
                        const ids = cluster.markers.reduce((acc, marker) => {
                            return [...acc, ...marker.ids];
                        }, []);
                        setSelectedOrders((prev) => {
                            const select = !ids.every((o) => prev[o]);
                            return {
                                ...prev,
                                ...Object.fromEntries(ids.map((id) => [id, select])),
                            };
                        });
                    },
                }}
            />
            <MarkerCluster
                map={mapRef}
                markers={loadMarkers}
                disabled={!circles?.[MARKER_CLUSTERING_FLAG] || loadMarkers?.length <= 100}
                color="darkgreen"
                callbacks={{}}
            />
            <MarkerCluster
                map={mapRef}
                markers={networkMarkers}
                disabled={!circles?.[MARKER_CLUSTERING_FLAG] || networkMarkers?.length <= 100}
                color="midnightblue"
                callbacks={{}}
            />

            {circles?.['plan-page-recommendations'] && (
                <RecommendationsControl
                    mapRef={mapRef}
                    recommendations={recommendations}
                    loading={recommendationsLoading}
                    callbacks={{
                        getRecommendations: getOrderRecommendations,
                        selectRecommendation: (order_id) => {
                            selectMultiLoads([order_id]);
                        },
                    }}
                />
            )}
            <LassoControl
                mapRef={mapRef}
                isEnabled={lassoEnabled}
                callbacks={{
                    onSelect: onSelectBound,
                    toggleLasso: () => {
                        setLasso((prev) => !prev);
                    },
                }}
            />
            <ClearOrderControl
                mapRef={mapRef}
                callbacks={{
                    onClick: () => {
                        setSelectedOrders({});
                    },
                }}
            />
            <MarkerLegendControl
                mapRef={mapRef}
                type={markerType}
                callbacks={{
                    setMarkerType,
                }}
            />
            {shapeFieldTypeOptions.length && (
                <ShapeControl
                    mapRef={mapRef}
                    saveShapes={saveShapes}
                    savedShapes={savedShapes}
                    shapesOverride={shapesOverride}
                    setShapesOverride={setShapesOverride}
                    showComboShapes={showComboShapes}
                    setShowComboShapes={setShowComboShapes}
                    shapeFieldType={shapeFieldType}
                    setShapeFieldType={setShapeFieldType}
                    setLockShapeType={setLockShapeType}
                    shapeFieldTypeOptions={shapeFieldTypeOptions}
                    comboPotentialChoices={comboPotentialChoices}
                    setComboShapesOverride={setComboShapesOverride}
                    comboShapesOverride={comboShapesOverride}
                />
            )}

            <InfoWindow content={tooltip} map={mapRef} marker={selectedMarker} opts={TOOLTIP_OPTIONS} />
        </>
    );
};

export default PlanningMap;
