import { gql } from '@apollo/client';

import { ORDER_FIELDS } from '@/graphql/queries/orders';
import { ITEM_FIELDS } from '@/graphql/queries/items';
import { LISTING_FIELDS } from '@/graphql/queries/listings';
import { NOTE_FIELDS } from '@/graphql/queries/notes';

export const ADMIN_ORDERS = gql`
    ${ORDER_FIELDS}
    ${ITEM_FIELDS}
    ${LISTING_FIELDS}
    ${NOTE_FIELDS}
    query AdminOrders($where: orders_bool_exp!, $limit: Int!, $offset: Int!, $order_by: [orders_order_by!]) {
        orders_aggregate(where: $where) {
            aggregate {
                count
            }
        }
        orders(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
            ...OrderFields

            notes {
                ...NoteFields
            }

            order_shipper {
                client_id
                business_name
                email
                business_phone
                payment_type
                user {
                    user_id
                    circles
                }
            }

            order_carrier {
                client_id
                business_name
                email
                business_phone
                user {
                    user_id
                    circles
                }
            }

            routes(order_by: { created_at: desc }) {
                mapping_id
                order_id
                route_id
                type
                created_at
                route {
                    route_id
                    route_number
                }
            }

            itemsByOrderId {
                ...ItemFields
            }

            listing {
                ...ListingFields
                bids {
                    bid_id
                }
            }
        }
    }
`;

export const CLIENTS = gql`
    query GetClients($test: clients_bool_exp!) {
        shippers: clients(
            where: { _and: [{ user: { roles: { _has_key: "SHIPPER" } } }, { deactivated: { _eq: false } }, $test] }
            order_by: { business_name: asc_nulls_last }
        ) {
            client_id
            business_name
            email
            user {
                user_id
                roles
                circles
            }
        }
        carriers: clients(
            where: { _and: [{ user: { roles: { _has_key: "CARRIER" } } }, { deactivated: { _eq: false } }, $test] }
            order_by: { business_name: asc_nulls_last }
        ) {
            client_id
            business_name
            email
            user {
                user_id
                roles
                circles
            }
            locations {
                location_id
                business_zip
            }
        }
    }
`;
