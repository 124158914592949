import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { IconButton, Grid } from '@material-ui/core';
import { DeleteForeverOutlined } from '@material-ui/icons';
import { useClientUser } from '@/hooks';
import { useMutation } from '@apollo/client';
import {
    CONNECT_SHIPPING_PARTNER,
    CREATE_SHIPPING_PARTNER,
    DELETE_SHIPPING_PARTNER,
    UPDATE_SHIPPING_PARTNER,
    UPSERT_SHIPPING_PARTNER,
} from './mutations';
import { captureException } from '@sentry/react';
import { Body1, TabCard } from './blocks';
import Snackbar from '../Snackbar';
import { Body, PrimaryButton } from '@/styles/blocks';
import { QUERY_BY_ID } from '@/graphql/queries/users';
import { colors } from '@/styles';
import AddShipperModal from './ShippingPartners/AddShipperModal';
import { post } from '@/utilities/onwardClient';
import { INVITE_PARTNER } from '@/constants/apiRoutes';
import { EditShippingPartner } from './ShippingPartners/EditShippingPartner';

const ShippingPartner = ({ shipper, tariffs, overrides, callbacks, disabled }) => {
    const [overrideUpdates, updateOverride] = useState({});
    const [alias, setAlias] = useState(null);

    const tariffsById = useMemo(() => {
        return Object.fromEntries((tariffs || []).map((tariff) => [tariff.tariff_id, tariff]));
    }, [tariffs]);

    const override = useMemo(() => {
        return (
            overrides.find(
                (o) => o.oms && o.partner_client_id === shipper.shipper_id && o.retailer_id == shipper.retailer_id
            ) || {}
        );
    }, [overrides, shipper]);

    const updatedOverride = useMemo(() => {
        return {
            tariff_id: overrideUpdates.tariff_id || override.tariff_id,
            add_fuel_surcharge: Boolean(overrideUpdates.add_fuel_surcharge ?? override.add_fuel_surcharge),
        };
    }, [override, overrideUpdates]);

    const shipperName = useMemo(() => {
        return alias || shipper.shipper_alias || shipper.shipper.business_name;
    }, [alias, shipper]);

    const tariff = useMemo(() => {
        return tariffsById[updatedOverride.tariff_id];
    }, [tariffsById, updatedOverride]);

    const modified = Object.keys(overrideUpdates).length > 0 || !!alias;

    const statusColors = {
        PENDING: colors.golds.primary,
        APPROVED: colors.greens.primary,
        DENIED: colors.reds.primary,
    };

    const statusLabels = {
        PENDING: 'Invite Pending',
        APPROVED: 'Connected',
        DENIED: 'Not Connected',
    };

    return (
        <Grid
            container
            direction="column"
            css={css`
                padding: 1rem 0rem;
                border-bottom: 1px solid ${colors.greys.border};
            `}
        >
            <Grid
                item
                css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 74px;
                `}
            >
                <Grid
                    item
                    css={css`
                        margin-right: 12px;
                    `}
                >
                    <Body1>{shipperName}</Body1>
                </Grid>
                <Grid item>
                    <Body
                        css={css`
                            font-weight: 700;
                            font-size: 14px;
                            margin: 0 12px;
                            color: ${statusColors[shipper.status] || 'black'};
                        `}
                    >
                        {statusLabels[shipper.status] || 'Status Unknown'}
                    </Body>
                    {shipper.status === 'PENDING' ? (
                        <PrimaryButton
                            css={css`
                                margin: 0 12px;
                            `}
                            onClick={() => callbacks.onInvite(shipper)}
                        >
                            Resend Invite
                        </PrimaryButton>
                    ) : null}
                    <PrimaryButton onClick={() => callbacks.setEditingShipper(shipper)}>Edit</PrimaryButton>
                    <IconButton disabled={disabled} onClick={() => callbacks.onDelete(shipper)}>
                        <DeleteForeverOutlined fontSize="large" color={disabled ? 'disabled' : 'error'} />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

const ShippingPartners = ({ tariffs, overrides, callbacks }) => {
    const [notification, setNotification] = useState({});
    const [addingShipper, addShipper] = useState(false);
    const [editingShipper, setEditingShipper] = useState(null);
    const { user_id, shipping_partners, test_acc, businessName } = useClientUser();

    const [createShipper, { loading: createLoading }] = useMutation(CREATE_SHIPPING_PARTNER, {
        refetchQueries: [QUERY_BY_ID],
        awaitRefetchQueries: true,
        onError: (e) => {
            console.error(e);
            captureException(e);
            setNotification({
                severity: 'error',
                message: 'Error creating new shipper',
            });
        },
    });

    const [connectShipper, { loading: connectLoading }] = useMutation(CONNECT_SHIPPING_PARTNER, {
        refetchQueries: [QUERY_BY_ID],
        awaitRefetchQueries: true,
        onCompleted: ({ insert_partnerships_one }) => {
            setNotification({
                severity: 'success',
                message: `Successfully connected to ${insert_partnerships_one.shipper.business_name}!`,
            });
        },
        onError: (e) => {
            if (e?.message?.includes('partnerships_unique_idx')) {
                setNotification({
                    severity: 'warning',
                    message: 'You have already connected to this shipper',
                });
                return;
            }
            console.error(e);
            captureException(e);
            setNotification({
                severity: 'error',
                message: 'Error connecting to shipper',
            });
        },
    });

    const [updateShipper, { loading: updateLoading }] = useMutation(UPDATE_SHIPPING_PARTNER);

    const [deleteShipper, { loading: deleteLoading }] = useMutation(DELETE_SHIPPING_PARTNER, {
        onCompleted: () => {
            setNotification({
                severity: 'success',
                message: `Shipper removed`,
            });
        },
        update: (cache, { data: { delete_partnerships_by_pk } }) => {
            cache.evict(cache.identify(delete_partnerships_by_pk));
        },
    });

    const loading = createLoading || connectLoading || deleteLoading || updateLoading;

    return (
        <>
            {editingShipper ? (
                <EditShippingPartner
                    id={editingShipper.partnership_id}
                    shipper={editingShipper}
                    tariffs={tariffs}
                    overrides={overrides}
                    notification={notification}
                    callbacks={{
                        onSave: ({ ...override }, { partnership_id, ...partnership_update }) => {
                            return Promise.all([
                                callbacks.upsertPo({
                                    variables: {
                                        override: {
                                            client_id: user_id,
                                            oms: true,
                                            ...override,
                                        },
                                    },
                                }),
                                updateShipper({
                                    variables: {
                                        partnership_id,
                                        update: partnership_update,
                                    },
                                }),
                            ]);
                        },
                        setNotification: (notification) => setNotification(notification),
                        setEditingShipper: (shipper) => setEditingShipper(shipper),
                        onDelete: (partnership) =>
                            deleteShipper({
                                variables: { partnership_id: partnership.partnership_id },
                            }),
                        onInvite: (partnership) =>
                            post(INVITE_PARTNER, {
                                inviter_name: businessName,
                                inviter_type: 'carrier',
                                invitee_id: partnership.shipper_id,
                                invitee_name: partnership.shipper?.business_name,
                                invitee_email: partnership.shipper?.email,
                                invitee_type: 'shipper',
                            })
                                .then(() => {
                                    return setNotification({
                                        severity: 'success',
                                        message: 'Shipping partner invited via email!',
                                    });
                                })
                                .catch((err) => {
                                    console.error(err);
                                    return setNotification({
                                        severity: 'error',
                                        message: 'Error inviting partner. Please try again',
                                    });
                                }),
                    }}
                    disabled={loading}
                />
            ) : (
                <TabCard>
                    <Snackbar open={notification.message} handleClose={() => setNotification({})} {...notification} />
                    <AddShipperModal
                        open={addingShipper}
                        callbacks={{
                            onClose: () => addShipper(false),
                            onCreate: (shipper) => {
                                return createShipper({
                                    variables: {
                                        user: {
                                            username: shipper.business_name,
                                            email: shipper.business_email,
                                            roles: {
                                                SHIPPER: 'SHIPPER',
                                            },
                                            client: {
                                                data: {
                                                    ...shipper,
                                                    test_acc,
                                                    partnerships_enabled: true,
                                                    shipper_partnerships: {
                                                        data: [
                                                            {
                                                                carrier_id: user_id,
                                                                retailer_id: null,
                                                                status: 'APPROVED',
                                                                shipper_alias: shipper.business_name,
                                                            },
                                                        ],
                                                    },
                                                },
                                            },
                                        },
                                    },
                                }).then((response) => {
                                    const name = response?.data?.insert_users_one?.client?.business_name;
                                    return setNotification({
                                        severity: name ? 'success' : 'error',
                                        message: name
                                            ? `Successfully connected to ${name}!`
                                            : 'Failed to create shipper. Please try again.',
                                    });
                                });
                            },
                            onInvite: (shipper) => {
                                return createShipper({
                                    variables: {
                                        user: {
                                            username: shipper.business_name,
                                            email: shipper.business_email,
                                            roles: {
                                                SHIPPER: 'SHIPPER',
                                            },
                                            client: {
                                                data: {
                                                    ...shipper,
                                                    test_acc,
                                                    partnerships_enabled: true,
                                                    shipper_partnerships: {
                                                        data: [
                                                            {
                                                                carrier_id: user_id,
                                                                retailer_id: null,
                                                                status: 'PENDING',
                                                                shipper_alias: shipper.business_name,
                                                            },
                                                        ],
                                                    },
                                                },
                                            },
                                        },
                                    },
                                }).then((response) => {
                                    const client = response?.data?.insert_users_one?.client;
                                    if (!client) {
                                        setNotification({
                                            severity: 'warning',
                                            message: 'Error creating partnership, could not send invite.',
                                        });
                                        return;
                                    }

                                    return post(INVITE_PARTNER, {
                                        inviter_name: businessName,
                                        inviter_type: 'carrier',
                                        invitee_id: client.client_id,
                                        invitee_name: client.business_name,
                                        invitee_email: client.email,
                                        invitee_type: 'shipper',
                                    })
                                        .then(() => {
                                            return setNotification({
                                                severity: 'success',
                                                message: 'Shipping partner invited via email!',
                                            });
                                        })
                                        .catch((err) => {
                                            console.error(err);
                                            return setNotification({
                                                severity: 'error',
                                                message: 'Error inviting partner. Please try again',
                                            });
                                        });
                                });
                            },
                            onConnect: (shipper_id, importedLogoDetails) => {
                                return connectShipper({
                                    variables: {
                                        partnership: {
                                            shipper_id,
                                            retailer_id: null,
                                            carrier_id: user_id,
                                            status: 'APPROVED',
                                            ...importedLogoDetails,
                                        },
                                    },
                                });
                            },
                        }}
                    />
                    <div
                        css={css`
                            display: flex;
                            width: 100%;
                            flex-direction: column;
                            flex-wrap: nowrap;
                            padding: 1rem 2rem;
                        `}
                    >
                        <div>
                            <PrimaryButton
                                css={css`
                                    margin: 1rem 0rem;
                                `}
                                onClick={() => (editingShipper ? setEditingShipper(null) : addShipper(true))}
                            >
                                {editingShipper ? 'Back' : 'Add Shipper'}
                            </PrimaryButton>
                            {shipping_partners.map((shipper) => (
                                <ShippingPartner
                                    id={shipper.partnership_id}
                                    shipper={shipper}
                                    tariffs={tariffs}
                                    overrides={overrides}
                                    callbacks={{
                                        setEditingShipper: (shipper) => setEditingShipper(shipper),
                                        onSave: ({ ...override }, { partnership_id, ...partnership_update }) => {
                                            return Promise.all([
                                                callbacks.upsertPo({
                                                    variables: {
                                                        override: {
                                                            client_id: user_id,
                                                            oms: true,
                                                            ...override,
                                                        },
                                                    },
                                                }),
                                                updateShipper({
                                                    variables: {
                                                        partnership_id,
                                                        update: partnership_update,
                                                    },
                                                }),
                                            ]);
                                        },
                                        onDelete: (partnership) =>
                                            deleteShipper({
                                                variables: { partnership_id: partnership.partnership_id },
                                            }),
                                        onInvite: (partnership) =>
                                            post(INVITE_PARTNER, {
                                                inviter_name: businessName,
                                                inviter_type: 'carrier',
                                                invitee_id: partnership.shipper_id,
                                                invitee_name: partnership.shipper?.business_name,
                                                invitee_email: partnership.shipper?.email,
                                                invitee_type: 'shipper',
                                            })
                                                .then(() => {
                                                    return setNotification({
                                                        severity: 'success',
                                                        message: 'Shipping partner invited via email!',
                                                    });
                                                })
                                                .catch((err) => {
                                                    console.error(err);
                                                    return setNotification({
                                                        severity: 'error',
                                                        message: 'Error inviting partner. Please try again',
                                                    });
                                                }),
                                    }}
                                    disabled={loading}
                                />
                            ))}
                        </div>
                    </div>
                </TabCard>
            )}
        </>
    );
};

export default ShippingPartners;
