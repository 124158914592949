import { Typography } from '@material-ui/core';
import React, { useMemo, useEffect } from 'react';

import { useDropzone } from 'react-dropzone';

import { Publish } from '@material-ui/icons';

export default function FileDropZone({ disabled, handleFileAdd, customStyle, customLabel }) {
    const { acceptedFiles, getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive } = useDropzone({
        accept: 'image/*, .pdf',
    });

    useEffect(() => {
        const fileTypesMap = {
            'application/pdf': 'pdf',
            'image/png': 'png',
            'image/jpeg': 'jpg',
            'image/jpg': 'jpg',
        };

        if (acceptedFiles.length > 0) {
            const fileType = fileTypesMap[acceptedFiles[0].type];
            handleFileAdd(acceptedFiles[0], fileType);
        }
    }, [acceptedFiles]);

    const baseStyle = {
        height: '100px',
        width: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f4f4f4',
        borderRadius: '1px',
        outline: '1px solid #9d9d9d',
        fontFamily: 'Montserrat',
        cursor: 'pointer',
    };

    const style = useMemo(
        () => ({
            ...(customStyle ? customStyle : baseStyle),
        }),
        [isDragAccept, isDragReject, isDragActive]
    );
    return (
        <div {...getRootProps({ className: 'dropzone', style })}>
            <input {...getInputProps({ disabled: disabled })} />
            <div style={{ textAlign: 'center', padding: '10px 0' }}>
                <Typography style={{ fontSize: '14px', color: '#4c4c4c' }}>
                    {customLabel ? customLabel : 'Add Photo'}
                </Typography>
                <Typography style={{ fontSize: '12px', color: '#828282' }}>Click or Drag & Drop</Typography>
                <Publish style={{ color: '#4c4c4c' }} />
            </div>
        </div>
    );
}
