import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { compose } from 'recompose';
import { OnwardBreadcrumbActive, OnwardBreadcrumbInactive, OnwardBreadcrumbSpacer } from '../Breadcrumbs';
import Snackbar from '../Snackbar';
import { Context, withContext } from './store';
import { css } from '@emotion/react';
import { Body2, Status } from './blocks';
import ExceptionResolutionModal from '@/components/admin/AdminExceptions/modals/ExceptionResolutionModal';
import { PrimaryHeader } from '@/styles/typography';
import { colors } from '@/styles';
import { PrimaryButton } from '@/styles/blocks';
import ManifestCard from './cards/ManifestCard';
import OrderCard from './cards/OrderCard';
import EditManifest from './modals/EditManifest';
import StorePallets from './modals/StorePallets';
import PalletLogs from './modals/PalletLogs';
import ConsolidatePallets from './modals/ConsolidatePallets';
import StagePallets from './modals/StagePallets';
import PrintLabelsModal from './modals/PrintLabelsModal';
import { EXCEPTION_TYPES } from '@onward-delivery/core';
import ExceptionModal from '@/components/OrderDetailsPage/modals/Exceptions';
import { MANIFEST_STATUS_LABELS } from '@/constants/manifestStatuses';
import RenamePallet from './modals/RenamePallet';
import { useClientUser } from '@/hooks';

const Manifest = () => {
    const navigate = useNavigate();
    const { state, callbacks, loading } = useContext(Context);
    const { user_id } = useClientUser();

    const status = MANIFEST_STATUS_LABELS?.[state.manifest.status] || {
        label: 'Unknown',
        color: colors.greys[2],
    };

    const props = {
        loading,
        callbacks,
    };

    return (
        <Grid
            container
            direction="column"
            css={css`
                height: 100%;
                flex-wrap: nowrap;
            `}
        >
            <Snackbar
                open={!!state.notification.message}
                {...state.notification}
                handleClose={callbacks.clearNotification}
            />
            <EditManifest manifest={state.editingManifest} {...props} />
            <PrintLabelsModal printingManifest={state.printingLabels} {...props} />
            <StorePallets pallets={state.storingPallets} warehouseId={state.manifest.warehouse_id} {...props} />
            <PalletLogs pallet={state.palletLog} {...props} />
            <ConsolidatePallets pallets={state.consolidatingPallets} {...props} />
            <RenamePallet pallet={state.renamingPallet} {...props} />
            <ExceptionResolutionModal
                {...state.resolveExceptionParams}
                loading={loading.resolveExceptionLoading}
                callbacks={{
                    onClose: () => {
                        callbacks.setResolveExceptionParams({});
                    },
                    onResolve: ({ toSubmit, items = [], order = {}, exception = {}, event = {} }) => {
                        const toRemove = items.map((item) => item.item_id);
                        const { order_id, ...orderUpdates } = order;
                        const { event_id, ...eventUpdates } = event;
                        const { exception_id, ...exceptionUpdates } = exception;

                        return Promise.all([
                            callbacks.resolveException({
                                variables: {
                                    orders: order_id ? [order_id] : [],
                                    events: event_id ? [event_id] : [],
                                    exceptions: exception_id ? [exception_id] : [],
                                    items: toRemove,
                                    order_update: orderUpdates,
                                    event_update: eventUpdates,
                                    exception_update: exceptionUpdates,
                                    include_route_update: false,
                                },
                            }),
                            ...(toSubmit
                                ? [
                                      callbacks.submitOrders({
                                          client_id: toSubmit.shipper_id,
                                          orders: [toSubmit],
                                          filename: undefined,
                                          type: 'MANUAL',
                                      }),
                                  ]
                                : []),
                        ]).then(([, resp]) => {
                            if (resp?.data?.jobs?.length > 0) {
                                navigate(`/job/${resp.data.jobs[0]}`);
                            } else {
                                callbacks.setResolveExceptionParams({});
                            }
                        });
                    },
                }}
            />
            <StagePallets pallets={state.stagingPallets} {...props} />
            <ExceptionModal
                {...state.editingException}
                loading={loading.exceptionLoading}
                types={[EXCEPTION_TYPES.DAMAGED, EXCEPTION_TYPES.OVERAGE, EXCEPTION_TYPES.SHORTAGE]}
                callbacks={{
                    onError: (e) => {
                        callbacks.onError(e);
                    },
                    onSubmitCreateAnother: ({ exception }) => {
                        return callbacks.createException(exception);
                    },
                    onSubmit: ({ exception }) => {
                        return callbacks.createException(exception).then(() => {
                            callbacks.editException(null);
                        });
                    },
                    onClose: () => callbacks.editException(null),
                }}
            />
            <Grid
                container
                direction="row"
                css={css`
                    background-color: white;
                    padding: 1rem 2rem;
                    align-items: center;
                    justify-content: space-between;
                    box-shadow: 0px 1px 3px 0px #3f3f4426;
                    box-shadow: 0px 0px 0px 1px #3f3f440d;
                `}
            >
                <Grid item>
                    <OnwardBreadcrumbInactive href="/manifests">Warehousing</OnwardBreadcrumbInactive>
                    <OnwardBreadcrumbSpacer />
                    <OnwardBreadcrumbActive>Manifest {state.manifest.manifest_number}</OnwardBreadcrumbActive>
                </Grid>
                <Grid item>
                    <Body2>
                        {state.manifest.type === 'INBOUND'
                            ? `${state.manifest.received} / ${state.manifest.total} Items Received`
                            : `${state.manifest.staged} / ${state.manifest.total} Items Staged`}
                    </Body2>
                    {state.editable && (
                        <PrimaryButton
                            onClick={() => {
                                callbacks.printLabels(state.manifest);
                            }}
                            css={css`
                                margin-left: 2rem;
                            `}
                        >
                            Print Labels
                        </PrimaryButton>
                    )}
                </Grid>
            </Grid>
            <Grid
                container
                direction="column"
                css={css`
                    padding: 2rem max(12.5%, 6rem);
                `}
            >
                <Grid
                    container
                    direction="row"
                    css={css`
                        align-items: center;
                        justify-content: space-between;
                    `}
                >
                    <PrimaryHeader>Manifest {state.manifest.manifest_number}</PrimaryHeader>
                    <Status
                        css={css`
                            color: ${status.color};
                            border-color: ${status.color};
                        `}
                    >
                        {status.label}
                    </Status>
                </Grid>
                <ManifestCard manifest={state.manifest} orders={state.manifest.orders} {...props} />
                {(state.manifest.orders || [])
                    .filter((order) => state.editable || order.shipper_id === user_id)
                    .map((order) => (
                        <OrderCard manifest={state.manifest} order={order} {...props} />
                    ))}
            </Grid>
        </Grid>
    );
};

export default compose(withContext)(Manifest);
