import { LOCATION_TYPES } from '@/constants/locationTypes';
import { useItemQuantity, useTotalCubes, useTotalWeight } from '@/hooks';
import { toNational } from '@/utilities/formatPhoneNumber';
import useQuery from '@/utilities/useQuery';
import { genAttributes } from '@onward-delivery/core';
import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import { formatInTimeZone } from 'date-fns-tz';
import { startCase } from 'lodash';
import React, { useMemo, useState } from 'react';
import zipcode_to_timezone from 'zipcode-to-timezone';

const fragments = {
    col: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    line: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: '0.03125in',
    },
    item: {
        display: 'flex',
    },
    center: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        fontFamily: 'Montserrat',
    },
    body1: {
        fontFamily: 'Montserrat',
        fontSize: '10px',
        fontWeight: '700',
    },
    body2: {
        fontFamily: 'Montserrat',
        fontSize: '10px',
        fontWeight: '500',
    },
};

const TableHeader = ({ isReturn, isHaulaway }) => {
    return (
        <View
            style={{
                ...fragments.row,
                borderBottom: '1px solid black',
                paddingBottom: '0.0625in',
                paddingTop: '0.0625in',
                justifyContent: 'flex-end',
            }}
        >
            <View style={{ ...fragments.item, alignSelf: 'flex-start', width: '3in' }}>
                <Text style={fragments.body1}>
                    {isHaulaway ? 'Haulaway' : isReturn ? 'Return Item' : 'Item'} Description
                </Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.5in' }}>
                <Text style={fragments.body1}>Qty</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body1}>Length</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body1}>Width</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body1}>Height</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body1}>Cu Ft</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body1}>Weight</Text>
            </View>
        </View>
    );
};

const TableRow = ({ item, isReturn, isHaulaway }) => {
    return (
        <View
            style={{
                ...fragments.row,
                borderBottom: '1px solid black',
                paddingBottom: '0.0625in',
                paddingTop: '0.0625in',
            }}
        >
            <View style={{ ...fragments.item, alignSelf: 'flex-start', width: '3in' }}>
                <Text style={{ ...fragments.body2 }}>
                    {item.sku ? `${item.sku} - ` : ''}
                    {item.description || item.item_type_details}
                    {isHaulaway ? ' - DISPOSAL' : ''}
                </Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.5in' }}>
                <Text style={fragments.body2}>{parseInt(item.quantity || 1)}</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body2}>{parseInt(item.length || 12)}</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body2}>{parseInt(item.width || 12)}</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body2}>{parseInt(item.height || 12)}</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body2}>{parseInt(item.total_cubes || 1)}</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body2}>{parseInt(item.total_weight || 1)}</Text>
            </View>
        </View>
    );
};

const Table = ({ items, isHaulaway, isReturn }) => {
    const itemQty = useItemQuantity([{ items }]);
    const itemCubes = useTotalCubes([{ items }]);
    const itemWeight = useTotalWeight([{ items }]);

    return (
        <>
            <View
                style={{
                    ...fragments.row,
                    marginBottom: '0.125in',
                }}
            >
                <View
                    style={{
                        ...fragments.col,
                        borderTop: '1px solid black',
                    }}
                >
                    <TableHeader isReturn={isReturn} isHaulaway={isHaulaway} />
                    {(items || []).map((item) => (
                        <TableRow key={item.item_id} item={item} isReturn={isReturn} isHaulaway={isHaulaway} />
                    ))}
                </View>
            </View>
            <View
                style={{
                    ...fragments.row,
                    justifyContent: 'flex-end',
                    marginBottom: '0.25in',
                }}
            >
                <Text style={{ ...fragments.body1 }}>Total</Text>
                <Text style={{ ...fragments.body2, width: '2.5in', textAlign: 'right' }}>
                    {parseInt(itemQty)} items / {parseInt(itemCubes)} cu ft / {parseInt(itemWeight)} lbs
                </Text>
            </View>
        </>
    );
};

const Signature = ({ name }) => {
    return (
        <>
            <View
                style={{
                    ...fragments.row,
                    marginBottom: '0.03125in',
                    justifyContent: 'space-between',
                }}
            >
                <View style={{ ...fragments.item, width: '5in' }}>
                    <Text style={fragments.body2}>{name} Signature</Text>
                </View>
                <View style={{ ...fragments.item, width: '2in' }}>
                    <Text style={fragments.body2}>Date</Text>
                </View>
            </View>
            <View
                style={{
                    ...fragments.row,
                    marginBottom: '0.25in',
                    justifyContent: 'space-between',
                }}
            >
                <View style={{ ...fragments.item, height: '0.5in', width: '5in', border: '1px solid black' }} />
                <View style={{ ...fragments.item, height: '0.5in', width: '2in', border: '1px solid black' }} />
            </View>
        </>
    );
};

const DocumentBOL = ({ order, logoInfo }) => {
    Font.register({
        family: 'Montserrat',
        fonts: [
            {
                src: '/fonts/Montserrat-Regular.ttf',
            },
            {
                src: '/fonts/Montserrat-Bold.ttf',
                fontWeight: 700,
            },
            {
                src: '/fonts/Montserrat-Black.ttf',
                fontWeight: 900,
            },
        ],
    });
    Font.registerHyphenationCallback((word) => [word]);

    const carrier = order.order_carrier || order.order_shipper;

    const { street, unit, city, state, zip, location, location_info, location_type } = genAttributes(order);
    const {
        street: pu_street,
        unit: pu_unit,
        city: pu_city,
        state: pu_state,
        zip: pu_zip,
        location: pu_location,
        location_type: pu_location_type,
    } = genAttributes(order, true);

    const tz = useMemo(() => {
        return zipcode_to_timezone.lookup(order[zip]) || 'America/New_York';
    }, [order]);

    const { items, returns } = useMemo(() => {
        return (order.itemsByOrderId || []).reduce(
            (acc, item) => {
                return {
                    items: [...acc.items, ...(item.is_return ? [] : [item])],
                    returns: [...acc.returns, ...(item.is_return ? [item] : [])],
                };
            },
            { items: [], returns: [] }
        );
    }, [order]);

    const { logo, logoBackground } = useMemo(() => {
        return { logo: logoInfo.logo, logoBackground: logoInfo.logoBackground };
    }, [logoInfo]);

    const storeLocation = useMemo(() => {
        if (!order?.order_shipper?.locations) return null;

        return order.order_shipper.locations.find(
            (location) => location.business_address === order.pickup_street_address
        );
    });

    return (
        <Document>
            <Page>
                <View
                    style={{
                        ...fragments.col,
                        margin: '0.5in',
                        height: 'auto',
                    }}
                >
                    <View
                        style={{
                            ...fragments.row,
                            marginBottom: '0.25in',
                        }}
                    >
                        <Text
                            style={{
                                ...fragments.text,
                                fontSize: '14px',
                                fontWeight: '700',
                            }}
                        >
                            Bill of Lading
                        </Text>
                        <Image
                            src={logo ? logo : '/files/onward-logo.png'}
                            style={{
                                height: '20px',
                                backgroundColor: logoBackground,
                            }}
                        />
                    </View>
                    <View
                        style={{
                            ...fragments.row,
                            marginBottom: '0.25in',
                        }}
                    >
                        <View
                            style={{
                                ...fragments.col,
                                width: '50%',
                                paddingRight: '0.25in',
                            }}
                        >
                            <View style={fragments.line}>
                                <Text style={fragments.body1}>Order: </Text>
                                <Text style={fragments.body2}>{order.order_number}</Text>
                            </View>
                            <View style={fragments.line}>
                                <Text style={fragments.body1}>PO: </Text>
                                <Text style={fragments.body2}>{order.po_number}</Text>
                            </View>
                            <View style={fragments.line}>
                                <Text style={fragments.body1}>Manufacturer: </Text>
                                <Text style={fragments.body2}>{order.manufacturer || 'N/A'}</Text>
                            </View>
                        </View>
                        <View
                            style={{
                                ...fragments.col,
                                width: '50%',
                                paddingRight: '0.25in',
                            }}
                        >
                            <View style={fragments.line}>
                                <Text style={fragments.body1}>Carrier</Text>
                            </View>
                            <View style={fragments.line}>
                                <Text style={fragments.body2}>{carrier.business_name}</Text>
                            </View>
                            <View style={fragments.line}>
                                <Text style={fragments.body2}>{carrier.business_phone}</Text>
                            </View>
                            <View style={fragments.line}>
                                <Text style={fragments.body2}>{carrier.email}</Text>
                            </View>
                        </View>
                    </View>
                    <View
                        style={{
                            ...fragments.row,
                            marginBottom: '0.25in',
                        }}
                    >
                        <View
                            style={{
                                ...fragments.col,
                                width: '50%',
                                paddingRight: '0.25in',
                            }}
                        >
                            <View style={fragments.line}>
                                <Text style={fragments.body1}>
                                    {order.order_type === 'return' ? 'Warehouse Return' : 'Pickup'}
                                </Text>
                            </View>
                            {order.order_type !== 'return' && storeLocation && (
                                <>
                                    <View style={fragments.line}>
                                        <Text style={fragments.body2}>
                                            Location Name: {storeLocation.location_name}
                                        </Text>
                                    </View>
                                    {storeLocation.contact_phone && (
                                        <View style={fragments.line}>
                                            <Text style={fragments.body2}>
                                                Location Phone: {toNational(storeLocation.contact_phone)}
                                            </Text>
                                        </View>
                                    )}
                                    {storeLocation.contact_email && (
                                        <View style={fragments.line}>
                                            <Text style={fragments.body2}>
                                                Location Email: {storeLocation.contact_email}
                                            </Text>
                                        </View>
                                    )}
                                </>
                            )}
                            <View style={fragments.line}>
                                <Text style={fragments.body2}>
                                    {order.order_type !== 'return'
                                        ? `Company: ${order.pickup_name}`
                                        : order.pickup_name}
                                </Text>
                            </View>
                            <View style={fragments.line}>
                                <Text style={fragments.body2}>
                                    {order.pickup_phone ? toNational(order.pickup_phone) : 'No Phone Number'}
                                </Text>
                            </View>
                            <View style={fragments.line}>
                                <Text style={fragments.body2}>{order.pickup_email || 'No Email Address'}</Text>
                            </View>
                            <View style={fragments.line}>
                                <Text style={fragments.body2}>
                                    {order[pu_street]}
                                    {order[pu_unit] ? ` ${order[pu_unit]}` : ''}
                                </Text>
                            </View>
                            <View style={fragments.line}>
                                <Text
                                    style={fragments.body2}
                                >{`${order[pu_city]}, ${order[pu_state]}  ${order[pu_zip]}`}</Text>
                            </View>
                            <View style={fragments.line}>
                                <Text style={fragments.body2}>
                                    Location Type: {startCase(order[pu_location] || 'business')} -{' '}
                                    {LOCATION_TYPES[order[pu_location_type]]}
                                </Text>
                            </View>
                        </View>
                        <View
                            style={{
                                ...fragments.col,
                                width: '50%',
                                paddingRight: '0.25in',
                            }}
                        >
                            <View style={fragments.line}>
                                <Text style={fragments.body1}>
                                    {order.order_type === 'return' ? 'Return Pickup' : 'Dropoff'}
                                </Text>
                            </View>
                            <View style={fragments.line}>
                                <Text style={fragments.body2}>{order.dropoff_name}</Text>
                            </View>
                            <View style={fragments.line}>
                                <Text style={fragments.body2}>
                                    {order.dropoff_phone ? toNational(order.dropoff_phone) : 'No Phone Number'}
                                </Text>
                            </View>
                            <View style={fragments.line}>
                                <Text style={fragments.body2}>{order.dropoff_email || 'No Email Address'}</Text>
                            </View>
                            <View style={fragments.line}>
                                <Text style={fragments.body2}>
                                    {order[street]}
                                    {order[unit] ? ` ${order[unit]}` : ''}
                                </Text>
                            </View>
                            <View style={fragments.line}>
                                <Text style={fragments.body2}>{`${order[city]}, ${order[state]}  ${order[zip]}`}</Text>
                            </View>
                            <View style={fragments.line}>
                                <Text style={fragments.body2}>
                                    Location Type: {startCase(order[location] || 'residence')} -{' '}
                                    {startCase(order[location_info] || 'house')}
                                </Text>
                            </View>
                            <View style={fragments.line}>
                                <Text style={fragments.body2}>
                                    {order.order_type === 'return' ? 'Pickup' : 'Delivery'} Type:{' '}
                                    {LOCATION_TYPES[order[location_type]]}
                                </Text>
                            </View>
                            {order.order_type === 'return' ? (
                                <View style={fragments.line}>
                                    <Text style={fragments.body2}>
                                        Pickup Date:{' '}
                                        {order.pickup_date
                                            ? formatInTimeZone(new Date(order.pickup_date), tz, 'MM/dd/yyyy')
                                            : 'N/A'}
                                    </Text>
                                </View>
                            ) : (
                                <View style={fragments.line}>
                                    <Text style={fragments.body2}>
                                        Delivery Date:{' '}
                                        {order.delivery_date
                                            ? formatInTimeZone(new Date(order.delivery_date), tz, 'MM/dd/yyyy')
                                            : 'N/A'}
                                    </Text>
                                </View>
                            )}
                        </View>
                    </View>
                    {items.length > 0 && <Table items={items} />}
                    {returns.length > 0 && <Table items={returns} isReturn={true} />}
                    {order.haulaway_items?.length > 0 && <Table items={order.haulaway_items} isHaulaway={true} />}
                    <Signature name={'Driver'} />
                    {order.customer_signature ? (
                        <>
                            <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Text style={fragments.body2}>Consignee Signature:</Text>
                                <Image style={{ height: '1in', width: '3.5in' }} src={order.customer_signature} />
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Text style={fragments.body2}>Signed on: </Text>
                                <Text style={fragments.body2}>
                                    {order.customer_signature_meta?.date
                                        ? formatInTimeZone(
                                              new Date(order.customer_signature_meta.date),
                                              tz,
                                              'MM/dd/yyyy'
                                          )
                                        : 'Not Dated'}
                                </Text>
                            </View>
                        </>
                    ) : (
                        <Signature name={'Consignee'} />
                    )}

                    <View style={{ ...fragments.row, marginBottom: '0.03125in', marginTop: '0.25in' }}>
                        <Text style={fragments.body2}>
                            Consignee acknowledges that produce was received in good condition unless noted below:
                        </Text>
                    </View>
                    <View style={{ ...fragments.row, justifyContent: 'flex-start', marginBottom: '0.25in' }}>
                        {['Item Short', 'Item Damaged', 'Property Damaged'].map((type) => (
                            <View style={{ ...fragments.row, width: '2in', justifyContent: 'flex-start' }}>
                                <View
                                    style={{
                                        ...fragments.item,
                                        width: '0.25in',
                                        height: '0.25in',
                                        border: '1px solid black',
                                        marginRight: '0.125in',
                                    }}
                                />
                                <Text style={fragments.body2}>{type}</Text>
                            </View>
                        ))}
                    </View>
                    <View style={{ ...fragments.row, marginBottom: '0.5in' }}>
                        <Text style={fragments.body2}>Description of Damages:</Text>
                    </View>
                    <View style={{ ...fragments.row, borderBottom: '1px solid black' }} />
                    <View
                        style={{
                            ...fragments.row,
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Text
                            style={{
                                ...fragments.text,
                                fontSize: '10px',
                                fontWeight: '700',
                            }}
                        >
                            Powered By{' '}
                        </Text>
                        <Image
                            src="/files/onward-logo.png"
                            style={{
                                height: '8px',
                                width: 'auto',
                                objectFit: 'contain',
                            }}
                        />
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default DocumentBOL;
