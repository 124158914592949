import { Grid, IconButton, MenuItem, TextField } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { RESPONSE_TYPES } from './constants';
import { OnwardSwitch, PrimaryButton } from '@/styles/blocks';
import { Body1 } from '../blocks';
import { DeleteForeverOutlined, SaveOutlined } from '@material-ui/icons';

const Survey = ({ idx, survey, loading, callbacks }) => {
    const [updates, setUpdates] = useState({});

    const updated = useMemo(() => {
        return {
            ...(survey || {}),
            ...(updates || {}),
        };
    }, [survey, updates]);

    const isModified = Object.keys(updates).length > 0;

    return (
        <Grid
            container
            direction="column"
            css={css`
                flex-wrap: nowrap;
                ${idx === 0 ? `border-top: 1px solid ${colors.greys.border};` : ''}
                border-bottom: 1px solid ${colors.greys.border};
                padding: 1rem;
            `}
        >
            <Body1>Question {idx + 1}</Body1>
            <Grid
                container
                direction="row"
                css={css`
                    margin-bottom: 1rem;
                    margin-top: 1rem;
                    flex-wrap: nowrap;
                    align-items: center;
                `}
            >
                <TextField
                    select
                    fullWidth
                    label="Response Type"
                    variant="outlined"
                    value={updated.response_type || 'string'}
                    onChange={(e) => setUpdates((prev) => ({ ...prev, response_type: e.target.value }))}
                    css={css`
                        width: 200px;
                        margin-right: 1rem;
                    `}
                >
                    {Object.entries(RESPONSE_TYPES).map(([value, label]) => (
                        <MenuItem value={value} key={value}>
                            {label}
                        </MenuItem>
                    ))}
                </TextField>
                <OnwardSwitch
                    label={updated.enabled ? 'Enabled' : 'Disabled'}
                    checked={updated.enabled}
                    onChange={() => setUpdates((prev) => ({ ...prev, enabled: !prev.enabled }))}
                    css={css`
                        margin-right: 1rem;
                    `}
                />
                <IconButton
                    disabled={loading || !isModified}
                    onClick={() => callbacks.onSave(updated).then(() => setUpdates({}))}
                >
                    <SaveOutlined fontSize="large" color={loading || !isModified ? 'disabled' : 'primary'} />
                </IconButton>
                <IconButton disabled={loading} onClick={() => callbacks.onDelete(updated.survey_id)}>
                    <DeleteForeverOutlined fontSize="large" color={loading ? 'disabled' : 'error'} />
                </IconButton>
            </Grid>
            <TextField
                fullWidth
                multiline
                rows={3}
                label="Question"
                variant="outlined"
                value={updated.question || ''}
                onChange={(e) => setUpdates((prev) => ({ ...prev, question: e.target.value }))}
            />
        </Grid>
    );
};

export default Survey;
