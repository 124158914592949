import { gql } from '@apollo/client';
import { ORDER_FIELDS } from '@/graphql/queries/orders';
import { ROUTE_FIELDS } from '@/graphql/queries/routes';
import { NOTE_FIELDS } from '@/graphql/queries/notes';

export const UPDATE_ORDER_STATUS = gql`
    ${ORDER_FIELDS}
    mutation UpdateOrderStatus(
        $order_id: uuid!
        $order_status: String
        $completion_time: timestamptz
        $source: String
    ) {
        update_orders_by_pk(
            pk_columns: { order_id: $order_id }
            _set: {
                completion_source: $source
                order_status: $order_status
                planning: false
                completion_time: $completion_time
            }
        ) {
            ...OrderFields
        }
    }
`;

export const UPDATE_ORDER_DELIVERY_DATE = gql`
    ${ORDER_FIELDS}
    mutation UpdateOrderStatus($order_id: uuid!, $delivery_date: timestamptz) {
        update_orders_by_pk(pk_columns: { order_id: $order_id }, _set: { delivery_date: $delivery_date }) {
            ...OrderFields
        }
    }
`;

export const UPDATE_ORDER_TIME_MODAL = gql`
    ${ORDER_FIELDS}
    mutation UpdateOrderTimeModal($order_id: uuid!, $_set: orders_set_input = {}) {
        update_orders_by_pk(pk_columns: { order_id: $order_id }, _set: $_set) {
            ...OrderFields
        }
    }
`;

export const UPDATE_ORDER_BUDGET = gql`
    ${ORDER_FIELDS}
    mutation UpdateOrderStatus($order_id: uuid!, $customer_budget: float8) {
        update_orders_by_pk(pk_columns: { order_id: $order_id }, _set: { customer_budget: $customer_budget }) {
            ...OrderFields
        }
    }
`;

export const UPDATE_ORDER_DRIVER = gql`
    ${ORDER_FIELDS}
    mutation UpdateOrderDriver($order_id: uuid!, $driver_id: uuid!) {
        update_orders_by_pk(pk_columns: { order_id: $order_id }, _set: { driver_id: $driver_id }) {
            ...OrderFields
        }
    }
`;

export const UPDATE_ORDER_WAREHOUSE_INFO = gql`
    ${ORDER_FIELDS}
    mutation UpdateOrderWarehouseInfo($order_id: uuid!, $_set: orders_set_input = {}) {
        update_orders_by_pk(pk_columns: { order_id: $order_id }, _set: $_set) {
            ...OrderFields
        }
    }
`;

export const ADD_ORDER_NOTE = gql`
    ${NOTE_FIELDS}
    mutation InsertNotes($note: [notes_insert_input!] = {}) {
        insert_notes(objects: $note) {
            returning {
                ...NoteFields
            }
        }
    }
`;

export const CANCEL_ORDER = gql`
    ${ORDER_FIELDS}
    mutation CancelOrder(
        $order_id: uuid!
    ) {
        update_orders_by_pk(
            pk_columns: { order_id: $order_id }
            _set: {
                order_status: "cancelled"
            }
        ) {
            ...OrderFields
        }
    }
`;
