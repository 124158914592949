import { Grid, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { css } from '@emotion/react';
import { GridCard, PrimaryHeaderLabel } from './blocks';
import { ACCOUNTING_STAGES } from './constants';
import { addDays, format } from 'date-fns';
import { asBrowserDate, asUTCDate } from '@/utilities/convertToISO';
import { colors } from '@/styles';
import { PrimaryButton, SecondaryButton } from '@/styles/blocks';
import InvoiceOrdersTable from '../CarrierAccountingInvoices/InvoiceOrdersTable';
import { OnwardBreadcrumbActive, OnwardBreadcrumbInactive, OnwardBreadcrumbSpacer } from '../Breadcrumbs';
const LineItem = ({ label, value, lg = false, color = 'black' }) => {
    return (
        <Grid
            item
            css={css`
                width: 50%;
                min-width: 400px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            `}
        >
            <span
                css={css`
                    ${lg &&
                    `
                    font-size: 18px;
                    font-weight: 700;
                `}
                `}
            >
                {label}
            </span>
            <span
                css={css`
                    font-size: 16px;
                    font-weight: 700;
                    ${lg &&
                    `
                        font-size: 22px;
                    `}
                    color: ${color};
                `}
            >
                {value || value === 0 ? `$${value.toFixed(2)}` : '--'}
            </span>
        </Grid>
    );
};

const CreateInvoices = ({ state, loading, callbacks, tags }) => {
    const { breakdown, tab, stage, invoice, subtotal, adjustments, filters, selectedOrders, accessorialTypes } = state;
    const { setStage, setInvoice, createInvoices } = callbacks;

    const multi = stage === ACCOUNTING_STAGES.CREATE_MULTI_INVOICES;

    return (
        <>
            <Grid
                container
                sm={12}
                css={css`
                    padding: 0 40px;
                    background: #fff;
                    justify-content: space-between;
                    flex-basis: 40px !important;
                `}
            >
                <Grid item className="d-flex align-items-center">
                    <OnwardBreadcrumbInactive onClick={() => setStage(ACCOUNTING_STAGES.COMPLETED_ORDERS)}>
                        Completed Orders
                    </OnwardBreadcrumbInactive>
                    <OnwardBreadcrumbSpacer />
                    <OnwardBreadcrumbActive variant="body1" color="primary" className="ml-2">
                        Create Invoice
                    </OnwardBreadcrumbActive>
                </Grid>
            </Grid>
            <Grid
                item
                css={css`
                    padding: 2rem 2rem 1rem 2rem;
                `}
            >
                <PrimaryHeaderLabel>Create Invoice{multi ? `s (${selectedOrders.length})` : ''}</PrimaryHeaderLabel>
            </Grid>

            <Grid
                css={css`
                    padding: 1rem 2rem;
                `}
            >
                <GridCard
                    css={css`
                        display: flex;
                        flex-wrap: nowrap;
                        flex-direction: column;
                    `}
                >
                    <TextField
                        type="date"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        name="due_date"
                        label="Due date"
                        value={invoice.due_date}
                        disabled={tab.label !== 'Internal'}
                        onChange={(e) => setInvoice((prev) => ({ ...prev, due_date: e.target.value }))}
                        css={css`
                            width: 200px;
                        `}
                    />

                    {filters?.completedTo ? (
                        <span
                            css={css`
                                font-size: 12px;
                                font-weight: 500;
                                color: ${colors.greys.primary};
                                margin-top: 0.5rem;
                            `}
                        >
                            Based on Net 30 {multi ? 'these invoices are' : 'this invoice is'} due{' '}
                            {format(new Date(`${invoice.due_date}T00:00:00`), 'MMM d, yyyy')}
                        </span>
                    ) : null}

                    <TextField
                        type="text"
                        multiline
                        rows={3}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        name="description"
                        label="Description"
                        value={invoice.description}
                        onChange={(e) => setInvoice((prev) => ({ ...prev, description: e.target.value }))}
                        css={css`
                            margin-top: 2rem;
                            width: 50%;
                            min-width: 400px;
                        `}
                    />
                </GridCard>
            </Grid>

            <Grid
                css={css`
                    padding: 1rem 2rem;
                    flex-grow: 1;
                `}
            >
                <InvoiceOrdersTable
                    orders={selectedOrders}
                    types={accessorialTypes}
                    breakdown={breakdown}
                    tags={tags}
                />
            </Grid>

            <Grid
                css={css`
                    padding: 1rem 2rem;
                `}
            >
                <GridCard
                    css={css`
                        display: flex;
                        flex-wrap: nowrap;
                        flex-direction: column;
                        align-items: flex-end;
                    `}
                >
                    <LineItem label="Subtotal" value={subtotal} />
                    <LineItem label="Adjustments" value={adjustments} color={colors.greens.primary} />
                    <hr
                        css={css`
                            width: 50%;
                            min-width: 400px;
                            border-width: 2px;
                        `}
                    />
                    <LineItem label="Total" value={subtotal + adjustments} lg />
                </GridCard>
            </Grid>

            <Grid
                container
                css={css`
                    padding: 1rem 2rem;
                    justify-content: flex-end;
                `}
            >
                <SecondaryButton
                    onClick={() => {
                        setStage(ACCOUNTING_STAGES.COMPLETED_ORDERS);
                    }}
                    css={css`
                        margin-right: 1rem;
                    `}
                >
                    Cancel
                </SecondaryButton>
                <PrimaryButton onClick={createInvoices}>{loading.create ? '' : 'Create'}</PrimaryButton>
            </Grid>
        </>
    );
};

export default CreateInvoices;
