import { asDateInTZ } from '@/utilities/convertToISO';
import { genAttributes } from '@onward-delivery/core';
import { formatInTimeZone } from 'date-fns-tz';
import { parse } from 'date-fns';
import { startCase } from 'lodash';
import zipcode_to_timezone from 'zipcode-to-timezone';

export const FILTER_ATTRS = [
    'startDate',
    'endDate',
    'schedDelStart',
    'schedDelEnd',
    'estShipStart',
    'estShipEnd',
    'actualReceivedStart',
    'actualReceivedEnd',
    'warehouseStartDate',
    'warehouseEndDate',
    'warehouseStatus',
    'manufacturer',
    'orderType',
    'ownerChip',
    'pickup',
    'dropoff',
    'volume',
    'distance',
    'scheduledStatus',
    'shipper',
    'middleMileStatus',
    'serviceType',
    'locationType',
    'orderStatus',
];

const validDate = (datestring) => {
    return !datestring || !isNaN(new Date(datestring).valueOf());
};

export const CSV_BULK_UPDATE_COLUMNS = [
    {
        key: 'order_number',
        header: 'Order Number',
        value: (val) => val,
        valid: (row) => true,
        errorMsg: 'Either Order Number or PO Number is required',
    },
    {
        key: 'po_number',
        header: 'PO Number',
        value: (val) => val,
        valid: (row) => row.order_number || row.po_number,
        errorMsg: 'PO Number is required if Order Number not provided',
    },
    {
        key: 'received_date',
        header: 'Received Date',
        value: (val) => (val ? new Date(val).toISOString() : null),
        valid: (row) => validDate(row.received_date),
        errorMsg: 'Received Date must be a valid date',
    },
    {
        key: 'delivery_date',
        header: 'Delivery Date',
        value: (val) => (val ? new Date(val).toISOString() : null),
        valid: (row) => validDate(row.delivery_date),
        errorMsg: 'Delivery Date must be a valid date',
    },
    {
        key: 'del_window_start',
        header: 'Delivery Time Start',
        value: (val, row) =>
            val && row?.delivery_date ? parse(val, 'hh:mm a', new Date(row.delivery_date)).toISOString() : null,
        valid: (row) =>
            !row.del_window_start ||
            !row.del_window_end ||
            new Date(row.del_window_start) <= new Date(row.del_window_end),
        errorMsg: `Delivery Time Start must be a valid time in 'hh:mm a' format and before Delivery Time End`,
    },
    {
        key: 'del_window_end',
        header: 'Delivery Time End',
        value: (val, row) =>
            val && row?.delivery_date ? parse(val, 'hh:mm a', new Date(row.delivery_date)).toISOString() : null,
        valid: (row) =>
            !row.del_window_start ||
            !row.del_window_end ||
            new Date(row.del_window_end) >= new Date(row.del_window_start),
        errorMsg: `Delivery Time End must be a valid time in 'hh:mm a' format and after Delivery Time Start`,
    },
    {
        key: 'ltl_pickup_completed',
        header: 'Pickup Time',
        value: (val) => (val ? parse(val, 'MM/dd/yyyy HH:mm:ss', new Date()).toISOString() : null),
        valid: (row) => validDate(row.ltl_pickup_completed),
        errorMsg: `Pickup Time must be a valid date and time in 'MM/dd/yyyy HH:mm:ss' format`,
    },
    {
        key: 'ltl_dropoff_arrival',
        header: 'Arrival Time',
        value: (val) => (val ? parse(val, 'MM/dd/yyyy HH:mm:ss', new Date()).toISOString() : null),
        valid: (row) => validDate(row.ltl_dropoff_arrival),
        errorMsg: `Arrival Time must be a valid date and time in 'MM/dd/yyyy HH:mm:ss' format`,
    },
    {
        key: 'completion_time',
        header: 'Completion Time',
        value: (val) => (val ? parse(val, 'MM/dd/yyyy HH:mm:ss', new Date()).toISOString() : null),
        valid: (row) => validDate(row.completion_time),
        errorMsg: `Completion Time must be a valid date and time in 'MM/dd/yyyy HH:mm:ss' format`,
    },
];
