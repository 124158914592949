import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Grid, Button, createTheme, Dialog, DialogActions, DialogTitle, Typography } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import Image from 'react-bootstrap/Image';
import * as Sentry from '@sentry/react';
import { FileEarmarkPdf } from 'react-bootstrap-icons';
import uploadPhotoAsync from '@/utilities/uploadPhotoAsync';
import FileDropZone from '@/components/OrderDetailsPage/FileDropZone';
import { ModalContext } from '.';
import { css } from '@emotion/react';

const typeMap = {
    bol: {
        value: 'bol_file',
        text: 'BOL',
    },
    pickup_notification: {
        value: 'pickup_notification',
        text: 'Pickup Notification',
    },
    waybill: {
        value: 'waybill',
        text: 'Waybill',
    },
};

function UploadFile(props) {
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { order } = modalState;
    const [fileArray, setFileArray] = useState([]);
    const [fileArrayWithTypes, setFileArrayWithTypes] = useState([]);
    const [fileIndex, setFileIndex] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [fileName, setFileName] = useState('');

    const type = props?.type;

    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    const value = useMemo(() => {
        return typeMap?.[type]?.value;
    }, [order, type]);

    useEffect(() => {
        if (typeof order?.[value] === 'string' && order?.[value] !== '') {
            setFileArray([order[value]]);
        } else {
            setFileArray(order[value]);
        }
    }, [order, type, value]);

    useEffect(() => {
        if (fileArray) {
            addFileTypes();
        }
    }, [fileArray]);
    FileDropZone;

    const handleFileDelete = async () => {
        try {
            let updatedFileArray = [...fileArray];
            updatedFileArray.splice(fileIndex, 1);

            callbacks.modifyOrder({
                [value]: updatedFileArray,
            });
            setFileArray(updatedFileArray);
            setFileIndex(null);
        } catch (error) {
            Sentry.captureException(error);
            console.error(error);
        }
        handleClose();
    };

    const handleFileAdd = async (file, fileType) => {
        if (file) {
            try {
                const fileUrl = URL.createObjectURL(file);
                const fbFileUrl = await uploadPhotoAsync(fileUrl, value, fileType);
                const fbFile = fbFileUrl;
                const updatedFileArray = fileArray ? [...fileArray, fbFile] : [fbFile];

                callbacks.modifyOrder({
                    [value]: updatedFileArray,
                });

                setFileArray(updatedFileArray);
                setFileName(file?.name?.split('.')[0]);
            } catch (error) {
                Sentry.captureException(error);
                console.error(error);
            }
        }
    };

    const addFileTypes = async () => {
        let resultArray = [];

        for (const file of fileArray) {
            let type = await checkType(file);
            resultArray.push([file, type]);
        }

        setFileArrayWithTypes(resultArray);
    };

    const checkType = async (fileSource) => {
        try {
            const res = await fetch(fileSource.url ? fileSource.url : fileSource);
            const blob = await res.blob();

            if (blob.type === 'application/pdf') {
                return 'pdf';
            } else if (blob.type === 'image/jpeg') {
                return 'jpg';
            } else if (blob.type === 'image/png') {
                return 'png';
            } else {
                return blob.type;
            }
        } catch (err) {
            Sentry.captureException(err);
            console.error(err);
        }
    };

    function ConfirmDeleteDialog() {
        return (
            <div>
                <Dialog
                    open={dialogOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{'Are you sure you want to delete this file?'}</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleFileDelete} autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    const theme = createTheme({
        palette: {
            text: {
                secondary: '#4c4c4c',
            },
            primary: {
                main: '#59b863',
                contrastText: '#fff',
            },
            secondary: {
                main: '#4c4c4c',
                contrastText: '#fff',
            },
        },
        typography: {
            fontFamily: ['Montserrat', 'Roboto', 'Arial'].join(','),
            h6: {
                color: '#8d8d8d',
                fontWeight: '850',
            },
        },
    });

    return (
        <>
            <Grid container direction="column">
                <Grid item>
                    <Typography
                        css={css`
                            font-weight: 500;
                            font-size: 14px;
                            margin-bottom: 10px;
                        `}
                    >
                        {typeMap?.[type]?.text}
                    </Typography>
                </Grid>
                {fileArrayWithTypes &&
                    fileArrayWithTypes.map((file, i) => {
                        return (
                            <Grid item key={i} className="d-flex position-relative">
                                <div
                                    css={css`
                                        background-color: lightgray;
                                        border-radius: 4px;
                                        display: flex;
                                        padding: 10px;
                                        height: fit-content;
                                    `}
                                >
                                    <Typography
                                        css={css`
                                            font-weight: 500;
                                        `}
                                    >
                                        {fileName || `${typeMap?.[type]?.text} File`}
                                    </Typography>
                                    <div>
                                        <Clear
                                            onClick={() => {
                                                setFileIndex(i);
                                                handleClickOpen();
                                            }}
                                            style={{
                                                zIndex: 3,
                                                color: 'lightgray',
                                                cursor: 'pointer',
                                            }}
                                            css={css`
                                                border-radius: 12px;
                                                background-color: #2b2b2bcc;
                                                height: 24px;
                                                width: 24px;
                                                margin-left: 16px;
                                            `}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        );
                    })}

                {fileArrayWithTypes.length < 1 && (
                    <FileDropZone
                        customLabel={`Upload ${typeMap?.[type]?.text || ''}`}
                        customStyle={{
                            height: '100px',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'white',
                            borderRadius: '5px',
                            outline: '1px solid #c4c4c4',
                            fontFamily: 'Montserrat',
                            cursor: 'pointer',
                        }}
                        handleFileAdd={handleFileAdd}
                    />
                )}
            </Grid>
            <ConfirmDeleteDialog />
        </>
    );
}

export default UploadFile;
