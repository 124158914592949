import React, { useEffect, useMemo, useState, useContext } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { compose } from 'recompose';
import { Tooltip } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { UserContext } from '@/components/App';

import ShipperTeammateProfile from './ShipperTeammateProfile';
import CarrierTeammateProfile from './CarrierTeammateProfile';
import Team from './Team';
import Stores from '../ShipperAccount/Stores';
import Billing from '../ShipperAccount/Billing';
import Password from '../Password';
import Warehouse from '../CarrierAccount/Warehouse';
import Teammates from '../Teammates';
import AccountCardSetup from '../ShipperAccount/AccountCardSetup';
import * as ROLES from '../../../constants/roles';
import { AGGREGATE_RATING_BY_DRIVER } from '@/graphql/queries/ratings';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useClientUser } from '@/hooks';
import ProfileImageUpload from '../ProfileImageUpload';
import Trucks from '../Trucks';

import api from '@/utilities/api';
import LoadPreferences from '../CarrierAccount/LoadPreferences';
import ShippingPartners from '../ShippingPartners';
import { LIST_TARIFFS_BY_CLIENT_ID, UPSERT_DRIVER_TARIFFS, UPSERT_PRICING_OVERRIDES } from '../Tariffs/graphql';
import * as Sentry from '@sentry/react';
import CustomTags from '../CustomTags';
import ServiceLevels from '../ServiceLevels';
import TariffTable from '../TariffTable'
import CustomDocuments from '../CustomDocuments';

function TeammateAccountPage(props) {
    const { user, sidebarCollapsed, superUser } = useContext(UserContext);
    const { accountType, businessName, user_id, email } = useClientUser();

    const isShipperAdminTeammate = () => user?.teammate?.roles?.admin && accountType === 'shipper';
    const isShipperViewerTeammate = () => user?.teammate?.roles?.view && accountType === 'shipper';
    const isAdminTeammate = () => user?.teammate?.roles?.admin;

    const isCarrierAdminTeammate = () => user?.teammate?.roles?.admin && accountType === 'carrier';

    const [fetchOverallRating, { data: ratings }] = useLazyQuery(AGGREGATE_RATING_BY_DRIVER);

    const [fetchTariffs, { data }] = useLazyQuery(LIST_TARIFFS_BY_CLIENT_ID, {
        variables: {
            client_id: user_id,
        },
    });

    const [upsertDriverTariff] = useMutation(UPSERT_DRIVER_TARIFFS, {
        update: (cache, { data: { updated } }) => {
            cache.updateQuery(
                {
                    query: LIST_TARIFFS_BY_CLIENT_ID,
                    variables: {
                        client_id: user_id,
                    },
                },
                (data) => {
                    let { driver_tariffs, ...rest } = data;
                    let clone = [...driver_tariffs];
                    const update = updated.returning[0];

                    const idx = clone.findIndex((m) => m.mapping_id === update.mapping_id);
                    if (idx >= 0) {
                        clone[idx] = update;
                    } else {
                        clone = [...clone, update];
                    }

                    return {
                        ...rest,
                        driver_tariffs: clone,
                    };
                }
            );
        },
    });

    const [upsertPo] = useMutation(UPSERT_PRICING_OVERRIDES, {
        update: (cache, { data: { updated } }) => {
            cache.updateQuery(
                {
                    query: LIST_TARIFFS_BY_CLIENT_ID,
                    variables: {
                        client_id: user_id,
                    },
                },
                (data) => {
                    let { overrides, ...rest } = data;
                    let clone = [...overrides];
                    const [update] = updated;

                    const idx = clone.findIndex((po) => po.pricing_override_id === update.pricing_override_id);
                    if (idx >= 0) {
                        clone[idx] = update;
                    } else {
                        clone = [...clone, update];
                    }

                    return {
                        ...rest,
                        overrides: clone,
                    };
                }
            );
        },
        onError: (e) => {
            Sentry.captureException(e);
        },
    });

    useEffect(() => {
        if (user?.user_id) {
            fetchOverallRating({ variables: { driver_id: user.user_id } });
            fetchTariffs();
        }
    }, [user]);

    const [tariffs, overrides, driverMappings] = useMemo(() => {
        if (data) {
            const { tariffs, overrides, driver_tariffs } = data;
            return [tariffs, overrides, driver_tariffs];
        }

        return [[], [], []];
    }, [data]);

    const rating = useMemo(() => {
        if (ratings?.results?.aggregate?.avg?.rating) {
            return ratings?.results?.aggregate?.avg?.rating;
        }

        return 0;
    }, [ratings]);

    return (
        <div className={`${sidebarCollapsed && 'collapse-margin'} sidenav-margin-responsive`}>
            <div className="d-flex justify-content-between my-3">
                <h2 className="  accountwidth" style={{ fontWeight: '700' }}>
                    My Account
                </h2>
                {user?.teammate?.roles?.driver && rating >= 3 && (
                    <div className="align-self-center d-flex">
                        <h5 className="text-nowrap">My Rating: </h5>
                        <Tooltip
                            title={rating.toFixed(1) + ' / 5'}
                            arrow
                            PopperProps={{ style: { marginTop: '-10px' } }}
                        >
                            <div>
                                <Rating readOnly value={rating} precision={0.1} />
                            </div>
                        </Tooltip>
                    </div>
                )}
            </div>
            <div className="align-self-center d-flex">Team: {businessName}</div>
            <Tabs defaultActiveKey="Profile">
                <Tab tabClassName="tabLink" eventKey="Profile" title="Profile">
                    {accountType === 'shipper' && (
                        <ShipperTeammateProfile user={user} adminLoggedIn={Object.keys(superUser).length} />
                    )}

                    {isShipperAdminTeammate() && (
                        <ProfileImageUpload client={user?.teammate?.userByTeamLeaderId?.client} />
                    )}

                    {accountType === 'carrier' && (
                        <CarrierTeammateProfile user={user} adminLoggedIn={Object.keys(superUser).length} />
                    )}
                </Tab>

                {(isShipperAdminTeammate() || isShipperViewerTeammate()) && (
                    <Tab tabClassName="tabLink" eventKey="Stores" title="Stores">
                        <Stores
                            viewOnly={isShipperViewerTeammate()}
                            currentClientRef={user_id}
                            currentClient={user?.teammate?.userByTeamLeaderId?.client}
                        />
                    </Tab>
                )}
                {isCarrierAdminTeammate() && (
                    <Tab tabClassName="tabLink" eventKey="Warehouse" title="Warehouse Details">
                        <Stores
                            viewOnly={false}
                            currentClientRef={user_id}
                            currentClient={user?.teammate?.userByTeamLeaderId?.client}
                        />
                    </Tab>
                )}
                {isAdminTeammate && (
                    <Tab tabClassName="tabLink" eventKey="Trucks" title="Trucks">
                        <Trucks client={user?.teammate?.userByTeamLeaderId?.client} />
                    </Tab>
                )}

                {isCarrierAdminTeammate() && (
                    <Tab tabClassName="tabLink" eventKey="Load Preferences" title="Load Preferences">
                        <LoadPreferences currentCarrier={user?.teammate?.userByTeamLeaderId?.client} />
                    </Tab>
                )}

                {isCarrierAdminTeammate() && (
                    <Tab tabClassName="tabLink" eventKey="Shippers" title="Shippers">
                        <ShippingPartners
                            client={user?.teammate?.userByTeamLeaderId?.client}
                            tariffs={tariffs}
                            overrides={overrides}
                            callbacks={{ upsertPo }}
                        />
                    </Tab>
                )}

                {(isShipperAdminTeammate() || isShipperViewerTeammate()) && (
                    <Tab tabClassName="tabLink" eventKey="Billing" title="Billing">
                        <Billing currentShipper={user?.teammate?.userByTeamLeaderId?.client} />
                    </Tab>
                )}
                {isAdminTeammate() && (
                    <Tab tabClassName="tabLink" eventKey="Teammates" title="Teammates">
                        <Teammates
                            currentUser={{
                                business: businessName,
                                email: email,
                            }}
                            teamLeaderType={accountType}
                            userId={user_id}
                            tariffs={tariffs}
                            driverMappings={driverMappings}
                            callbacks={{ upsertDriverTariff }}
                        />
                    </Tab>
                )}

                {isCarrierAdminTeammate() && (
                    <Tab tabClassName="tabLink" eventKey="Tags" title="Tags">
                        <CustomTags />
                    </Tab>
                )}

                {isAdminTeammate() && (
                    <Tab tabClassName="tabLink" eventKey="Custom Documents" title="Custom Documents">
                        <CustomDocuments />
                    </Tab>
                )}

                {isCarrierAdminTeammate() && (
                    <Tab tabClassName="tabLink" eventKey="Service Types" title="Service Types">
                        <ServiceLevels />
                    </Tab>
                )}

                {isAdminTeammate() && (
                    <Tab tabClassName="tabLink" eventKey="Tariffs" title="Tariffs">
                        <TariffTable
                            client={user?.teammate?.userByTeamLeaderId?.client}
                            tariffs={tariffs}
                            overrides={overrides}
                            driverMappings={driverMappings}
                            callbacks={{ upsertPo, upsertDriverTariff }}
                        />
                    </Tab>
                )}

                {isCarrierAdminTeammate() && (
                    <Tab tabClassName="tabLink" eventKey="Billing" title="Billing">
                        <AccountCardSetup currentClient={user?.teammate?.userByTeamLeaderId?.client} />
                    </Tab>
                )}
                <Tab tabClassName="tabLink" eventKey="Team" title="Team">
                    <Team />
                </Tab>
                <Tab tabClassName="tabLink" eventKey="Password" title="Password">
                    <Password />
                </Tab>
            </Tabs>
        </div>
    );
}

export default TeammateAccountPage;
