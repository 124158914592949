import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { Grid, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useClientUser } from '@/hooks';
import { ModalActions, ModalContent, ModalTitle, PrimaryButton, StickyModalActions } from '@/styles/blocks';
import { Alert, AlertTitle } from '@material-ui/lab';

const Title1 = styled.span`
    color: ${colors.greys.secondary};
    font-size: 20px;
    font-weight: 900;
`;

const Title2 = styled.span`
    color: ${colors.greys.secondary};
    font-size: 18px;
    font-weight: 800;
`;

const Title3 = styled.span`
    color: ${colors.greys.secondary};
    font-size: 16px;
    font-weight: 700;
`;

const Body = styled.span`
    font-size: 14px;
    font-weight: 500;
`;

const Bold = styled.span`
    font-size: 14px;
    font-weight: 700;
`;

const Row = styled((props) => <Grid item {...props} />)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Link = styled.a`
    color: ${colors.greens.primary};
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline;
    white-space: nowrap;
`;

const Divider = styled((props) => <hr {...props} />)`
    color: ${colors.greys.primary};
    width: 100%;
`;

const PRICING_WARNING_MSGS = {
    miles: 'Distance between pick up and drop off could not be determined',
    delivery_type: 'Delivery type is not set',
    total_cubes: 'Total item volume could not be determined',
    total_weight: 'Total item weight could not be determined',
};

const MarketplaceModal = ({ open, onClose, onSubmit, orders = {}, pricingErrors = {}, listings = {} }) => {
    const { accountType, circles } = useClientUser();

    const [totalOrders, totalDue, pricingWarnings] = useMemo(() => {
        let due = Object.values(orders).reduce((acc, order) => acc + order?.shipper_rate || 0.0, 0.0);

        due = due ? `$${due.toFixed(2)}` : 'We will contact you';
        if (circles?.['hide-market-rates']) {
            due = 'Contract Rates';
        }

        const warnings = Object.entries(orders)
            .map(([key, order], idx) => {
                if (pricingErrors[key]) {
                    return [
                        `${order.order_number || `Order ${idx + 1}`}`,
                        pricingErrors[key].map((err) => PRICING_WARNING_MSGS[err]).join(', '),
                    ];
                }
                return null;
            })
            .filter((warn) => warn);

        return [Object.values(orders).length, due, warnings];
    }, [orders, listings, pricingErrors]);

    return (
        <NavResponsiveModal open={open} onClose={onClose}>
            <ModalTitle>
                <Row>
                    <Title1>Payment</Title1>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Row>
            </ModalTitle>
            <ModalContent
                css={css`
                    width: 100%;
                    border-top: 1px solid ${colors.greys.primary};
                    border-bottom: 1px solid ${colors.greys.primary};
                `}
            >
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex-wrap: nowrap;
                    `}
                >
                    <Row>
                        <Body>Total Orders</Body>
                        <Bold>{totalOrders}</Bold>
                    </Row>
                    <Row>
                        <Body>Total Due</Body>
                        <Bold>{totalDue}</Bold>
                    </Row>
                    <Row>
                        <Body>Orders up for bidding will be charged once a bid is accepted.</Body>
                    </Row>
                    <Row>
                        <Body
                            css={css`
                                color: ${colors.greys.primary};
                            `}
                        >
                            Amount due will be charged to the card saved on your account.
                        </Body>
                        <Link target="_blank" href={`/${accountType}/account`}>
                            View account
                        </Link>
                    </Row>
                    {pricingWarnings.length > 0 && (
                        <Row>
                            <Alert severity="error">
                                <AlertTitle>Onward price could not be calculated for these orders: </AlertTitle>
                                {pricingWarnings.map(([warnTitle, warnMsg]) => (
                                    <div key={warnTitle}>
                                        <Bold>
                                            {warnTitle}
                                            {': '}
                                        </Bold>
                                        <Body>{warnMsg}</Body>
                                    </div>
                                ))}
                            </Alert>
                        </Row>
                    )}
                    <Divider />
                    <Row>
                        <Title2>Please read full Terms and Conditions before accepting</Title2>
                    </Row>
                    <Row>
                        <Title3>Your Rights</Title3>
                    </Row>
                    <Row>
                        <Body>
                            By using the Onward Platform, Services and/or scheduling Carrier Services, you represent and
                            warrant that you have all right, title and permission to allow the loading, unloading,
                            transportation, moving, packing, or lifting of any such items and that the performance of
                            the Carrier Services by a Carrier and its representatives will not be a violation of any
                            third party’s ownership or privacy rights.
                        </Body>
                    </Row>
                    <Divider />
                    <Row>
                        <Title3>Payment</Title3>
                    </Row>
                    <Row>
                        <Body>
                            All fees due and payable under this Terms of Service are inclusive of taxes. All fees due
                            and payable by you to Onward hereunder must be paid in full without any deduction, set-off,
                            counterclaim or withholding of any kind unless required by law. Payments will be charged
                            upon completion of delivery.
                        </Body>
                    </Row>
                    <Divider />
                    <Row>
                        <Title3>Cancellation</Title3>
                    </Row>
                    <Row>
                        <Body>
                            Scheduled delivery services may only be canceled by calling Onward’s Customer Service Line
                            at (720)526-0068. Cancellation Fees are as follows:
                            <br />
                            A. There is no fee for deliveries canceled more than 2 business days in advance of pick up
                            <br />
                            B. If Shipper cancels a scheduled delivery within the 2 business day notice period described
                            above, but prior to the Carrier arriving to the scheduled pick-up location, Shipper may be
                            charged a cancellation fee. Please reference the tariff of the selected Carrier for
                            applicable cancellation fees.
                            <br />
                            C. If Shipper and/or Consignee cancels a delivery after the freight is loaded on the truck
                            or en route the day of the appointment, Shipper shall pay a cancellation fee equal to one
                            hundred percent (100%) of the Delivery Fees for the applicable canceled delivery.
                        </Body>
                    </Row>
                    <Divider />
                    <Row>
                        <Title3>Claims</Title3>
                    </Row>
                    <Row>
                        <Body>
                            Claims resolution is between the Carrier and Shipper if the relevant Carrier Services were
                            booked through the Onward Platform. Onward may elect to mediate a claim if a resolution
                            cannot be made.
                        </Body>
                    </Row>
                </Grid>
            </ModalContent>
            <StickyModalActions>
                <Link target="_blank" href={'https://www.onwarddelivery.com/terms-and-conditions'}>
                    View full Terms and Conditions
                </Link>
                <PrimaryButton onClick={onSubmit}>Accept and Submit</PrimaryButton>
            </StickyModalActions>
        </NavResponsiveModal>
    );
};

export default MarketplaceModal;
