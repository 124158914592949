import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { typography, colors } from '@/styles';
import { Button } from '@material-ui/core';

export const PrimaryButton = styled((props) => <Button {...props} />)`
    ${typography.bold}
    ${typography.sansSerif}

    background: ${colors.greens.primary};
    color: ${colors.white.primary};
    text-transform: none;

    &:hover {
        background: #3e8045;
    }
`;

export const H1 = styled.span`
    ${typography.sansSerif}

    font-weight: 800;
    font-size: 20px;
    line-height: 1.25;
`;

export const H2 = styled.span`
    ${typography.sansSerif}

    font-weight: 900;
    font-size: 20px;
    line-height: 1.25;
`;

export const H3 = styled.span`
    ${typography.sansSerif}

    color: #4c4c4c
    font-weight: 900;
    font-size: 24px;
    line-height: 1.25;
`;

export const H4 = styled.span`
    ${typography.sansSerif}

    font-weight: 600;
    font-size: 14px;
    line-height: 1.25;
`;

export const H5 = styled.span`
    ${typography.sansSerif}

    color: ${colors.greys.primary};
    font-weight: 600;
    font-size: 16px;
    line-height: 1.25;
`;
export const H6 = styled.span`
    ${typography.sansSerif}

    font-weight: 800;
    font-size: 16px;
    line-height: 1.25;
`;

export const Body1 = styled.span`
    ${typography.sansSerif}

    color: ${colors.greys.primary};
    font-weight: 500;
    font-size: 14px;
    line-height: 1.25;
`;

export const Body2 = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 14px;
    line-height: 1.25;
`;

export const FinalReturnBadge = styled((props) => <Body2 {...props} />)`
    border-radius: 15px;
    background-color: ${colors.golds[1]};
    color: white;
    padding: 0 0.5rem;
`;

export const Subtitle1 = styled.span`
    ${typography.sansSerif}

    font-weight: 700;
    font-size: 14px;
`;
export const Status = styled.div`
    ${typography.sansSerif}
    font-weight: 600;
    font-size: 14px;
    line-height: 1.25;
    margin-left: 5px;
    color: ${(props) => {
        if (props.status === 'On Time') {
            return colors.greens.primary;
        } else if (props.status === 'Early' || props.status === 'Ahead of Schedule') {
            return local.blue;
        } else if (props.status === 'Late' || props.status === 'Running Behind' || props.status === 'Did Not Finish') {
            return local.red;
        } else if (props.status === 'Pending') {
            return colors.greys.primary;
        }
        return colors.greys.primary;
    }};
`;
export const local = {
    grey: '#2b2b2b',
    gold: '#ff9f21',
    red: '#D23E3E',
    blue: '#008BD9',
};

export const fragments = {
    sideNavCollapsed: css`
        margin-left: 56px;
    `,
    sideNavExpanded: css`
        margin-left: 240px;
    `,
    column: css`
        padding-top: 8px;
        padding-left: 16px;
        padding-right: 16px;

        border-right: 1px solid #dee2e6;
    `,
    columnHeader: css`
        margin: 16px 0;
    `,
};
