import NavResponsiveModal, {
    ModalActions,
    ModalContent,
    ModalHeader,
} from '@/components/Navigation/NavResponsiveModal';
import { PrimaryButton, SecondaryButton } from '@/styles/blocks';
import React from 'react';
import { Body1 } from '../blocks';

const RemoveSurvey = ({ open, callbacks }) => {
    return (
        <NavResponsiveModal open={open} onClose={callbacks.onClose}>
            <ModalHeader title="Delete Survey Question?" onClose={callbacks.onClose} />
            <ModalContent>
                <Body1>Are you sure you want to delete this survey question? All responses will be lost.</Body1>
            </ModalContent>
            <ModalActions>
                <SecondaryButton onClick={callbacks.onClose}>Cancel</SecondaryButton>
                <PrimaryButton onClick={callbacks.onConfirm}>Delete</PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default RemoveSurvey;
