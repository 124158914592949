import { gql } from '@apollo/client';

export const DRIVER_TARIFF_FIELDS = gql`
    fragment DriverTariffFields on driver_tariff_mappings {
        mapping_id
        client_id
        driver_id
        tariff_id
    }
`;
