import React, { useCallback, useMemo, useEffect } from 'react';
import _ from 'lodash';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { asUTCDate, asDateInTZ } from '@/utilities/convertToISO';
import geocodeByLatLng from '@/utilities/geocodeByLatLng';

import {
    DEFAULT_ITEM,
    DEFAULT_HAUL_AWAY,
    EDIT_ORDER_TABS,
    ORDER_UPDATED_HASH_ATTRS,
    ITEM_UPDATED_HASH_ATTRS,
} from '../constants';
import { enrich, swapReturnAttr } from '../utilities/processOrders';

import { useSwappedAttributes } from '.';

export const useEditModalDynamicFields = (clone, modifyClone) => {
    const { is_custom, full_address, address, unit, location, location_info, street, city, state, zip } =
        useSwappedAttributes(clone);
    const {
        is_custom: pu_is_custom,
        full_address: pufull_address,
        address: puaddress,
        unit: puunit,
        location: pulocation,
        location_info: pulocation_info,
        street: pustreet,
        city: pucity,
        state: pustate,
        zip: puzip,
    } = useSwappedAttributes(clone, true);

    //order_updated_hash
    useEffect(() => {
        if (clone) {
            const hash = [
                ...ORDER_UPDATED_HASH_ATTRS.map((attr) => (clone[attr] || '').toString()),
                ...(clone.itemsByOrderId || []).reduce((acc, item) => {
                    return [...acc, ...ITEM_UPDATED_HASH_ATTRS.map((attr) => (item[attr] || '').toString())];
                }, []),
            ].join(':');

            modifyClone((prev) => {
                return { ...prev, order_updated_hash: btoa(encodeURIComponent(hash)) };
            });
        }
    }, [
        clone.itemsByOrderId,
        ...ORDER_UPDATED_HASH_ATTRS.map((attr) => {
            return clone[attr];
        }),
    ]);

    //location_info
    useEffect(() => {
        const calc = (location, unit) => {
            let rv = null;
            if (location === 'Residence') {
                if (unit?.length > 0) {
                    rv = 'Apartment';
                } else {
                    rv = 'House';
                }
            }

            return rv;
        };

        if (clone) {
            modifyClone((prev) => {
                let dropoff = calc(clone[location], clone[unit]);
                let pickup = calc(clone[pulocation], clone[puunit]);

                return { ...prev, [pulocation_info]: pickup, [location_info]: dropoff };
            });
        }
    }, [clone[unit], clone[location], clone[puunit], clone[pulocation], location_info, pulocation_info]);

    //is_middle_mile state/zip mod
    useEffect(() => {
        if (clone && clone.is_middle_mile) {
            modifyClone((prev) => {
                return {
                    ...prev,
                    [pucity]: clone[city],
                    [pustate]: clone[state],
                    [puzip]: clone[zip],
                };
            });
        }
    }, [clone.is_middle_mile, clone[state], clone[zip], clone[city], pucity, puzip, pustate]);

    //address / full_address
    useEffect(() => {
        const calc = (fields) => fields.filter((val) => val && val.length > 0).join(', ');

        if (clone) {
            modifyClone((prev) => {
                const custompu = calc([clone[pustreet], clone[pucity], clone[pustate], clone[puzip]]);
                const customdo = calc([clone[street], clone[city], clone[state], clone[zip]]);
                const fullpu = calc([clone[pustreet], clone[puunit], clone[pucity], clone[pustate], clone[puzip]]);
                const fulldo = calc([clone[street], clone[unit], clone[city], clone[state], clone[zip]]);

                return {
                    ...prev,
                    [pufull_address]: fullpu,
                    [full_address]: fulldo,
                    ...(clone[pu_is_custom]
                        ? {
                              [puaddress]: custompu,
                          }
                        : {}),
                    ...(clone[is_custom]
                        ? {
                              [address]: customdo,
                          }
                        : {}),
                };
            });
        }
    }, [
        clone[address],
        clone[city],
        clone[full_address],
        clone[is_custom],
        clone[pu_is_custom],
        clone[puaddress],
        clone[pucity],
        clone[pufull_address],
        clone[pustate],
        clone[pustreet],
        clone[puunit],
        clone[puzip],
        clone[state],
        clone[street],
        clone[unit],
        clone[zip],
    ]);

    //order_type
    useEffect(() => {
        if ((clone?.itemsByOrderId || []).length > 0) {
            let next_type = 'delivery';

            const [containsDelivery, containsReturn] = clone.itemsByOrderId.reduce(
                ([containsDelivery, containsReturn], item) => {
                    return [containsDelivery || !item.is_return, containsReturn || item.is_return];
                },
                [false, false]
            );

            if (containsDelivery && containsReturn) {
                next_type = 'exchange';
            } else if (containsReturn) {
                next_type = 'return';
            }

            modifyClone((prev) => {
                if (prev?.order_type === next_type) {
                    return prev;
                }

                let swapped = {};
                if ([next_type, prev?.order_type].includes('return')) {
                    swapped = swapReturnAttr(prev);
                }

                return { ...prev, ...swapped, order_type: next_type };
            });
        }
    }, [clone.itemsByOrderId]);

    //adjust respective date strings if zipcode changes
    const fixTz = (date) => {
        const utc = new Date(asUTCDate(date).setUTCHours(0, 0, 0, 0)).toISOString();
        const tz = clone[zip] ? zipcode_to_timezone.lookup(clone[zip]) : 'America/New_York';
        return asDateInTZ(utc, tz).toISOString();
    };

    useEffect(() => {
        if (clone) {
            let converted = {};
            modifyClone((prev) => {
                if (prev.delivery_date) {
                    converted.delivery_date = fixTz(prev.delivery_date);
                }
                if (prev.warehouse_estimated_delivery_date) {
                    converted.warehouse_estimated_delivery_date = fixTz(prev.warehouse_estimated_delivery_date);
                }
                if (prev.warehouse_estimated_ship_date) {
                    converted.warehouse_estimated_ship_date = fixTz(prev.warehouse_estimated_ship_date);
                }
                if (prev.preferred_delivery_date) {
                    converted.preferred_delivery_date = fixTz(prev.preferred_delivery_date);
                }
                if (prev.estimated_delivery_range_start) {
                    converted.estimated_delivery_range_start = fixTz(prev.estimated_delivery_range_start);
                }
                if (prev.estimated_delivery_range_end) {
                    converted.estimated_delivery_range_end = fixTz(prev.estimated_delivery_range_end);
                }
                if (prev.alternative_delivery_dates) {
                    converted.alternative_delivery_dates = prev.alternative_delivery_dates.map((date) => fixTz(date));
                }

                return { ...prev, ...converted };
            });
        }
    }, [clone[zip]]);
};

export const genAttributesPerTab = ({ pickup, contact }) => {
    return {
        [EDIT_ORDER_TABS.PICKUP]: [
            'pickup_name',
            'pickup_phone',
            pickup.address,
            pickup.city,
            pickup.state,
            pickup.street,
            pickup.zip,
            pickup.location_type,
            pickup.location,
            pickup.lat,
            pickup.long,
            'distance',
            'miles',
            'duration_seconds',
        ],
        [EDIT_ORDER_TABS.CUSTOMER]: [
            'dropoff_name',
            'dropoff_phone',
            'preferred_delivery_date',
            'alternative_delivery_dates',
            'estimated_delivery_range_start',
            'estimated_delivery_range_end',
            contact.address,
            contact.city,
            contact.state,
            contact.street,
            contact.zip,
            contact.lat,
            contact.long,
            'distance',
            'miles',
            'duration_seconds',
            contact.location_type,
            contact.location,
        ],
        [EDIT_ORDER_TABS.ORDER_DETAILS]: ['po_number'],
        [EDIT_ORDER_TABS.ITEMS]: ['itemsByOrderId'],
        [EDIT_ORDER_TABS.HAUL_AWAY]: [],
        [EDIT_ORDER_TABS.WAREHOUSE]: ['warehouse_delivery_status', 'warehouse_estimated_ship_date'],
    };
};

export const useEditModalTabFields = (order) => {
    const contact = useSwappedAttributes(order);
    const pickup = useSwappedAttributes(order, true);

    return useMemo(() => {
        return genAttributesPerTab({
            contact,
            pickup,
        });
    }, [contact, pickup]);
};

export const useEditModalCallbacks = (state, callbacks) => {
    const { is_custom, lat, long, address, city, state: do_state, street, zip } = useSwappedAttributes(state.clone);

    const makeDirty = useCallback((attr) => {
        callbacks.setDirty((prev) => ({ ...prev, ...Object.fromEntries(attr.map((attr) => [attr, true])) }));
    }, []);

    const makeItemDirty = useCallback((idx, attr) => {
        callbacks.setDirty((prev) => {
            const clone = [...(prev.itemsByOrderId || [])];
            clone[idx] = { ...(clone[idx] || []), ...Object.fromEntries(attr.map((attr) => [attr, true])) };

            return {
                ...prev,
                itemsByOrderId: clone,
            };
        });
    }, []);

    const modifyOrder = useCallback((changes) => {
        callbacks.modifyClone((prev) => ({ ...prev, ...changes }));
    }, []);

    const applyItemOperations = useCallback((idx, changes, opt = { type: 'item' }) => {
        callbacks.modifyClone((prev) => {
            const attr = opt.type === 'item' ? 'itemsByOrderId' : 'haulaway_items';
            const clone = [...prev[attr]];
            const replacement = {
                ...clone[idx],
                ...Object.fromEntries(Object.entries(changes).map(([attr, callback]) => [attr, callback(clone[idx])])),
            };
            clone.splice(idx, 1, replacement);

            return {
                ...prev,
                [attr]: clone,
            };
        });
    }, []);

    const applyOrderOperations = useCallback((changes) => {
        callbacks.modifyClone((prev) => {
            const modifications = Object.fromEntries(
                Object.entries(changes).map(([attr, callback]) => [attr, callback(prev)])
            );

            return {
                ...prev,
                ...modifications,
            };
        });
    }, []);

    const addItem = useCallback((opt = { type: 'item' }) => {
        callbacks.modifyClone((prev) => {
            const attr = opt.type === 'item' ? 'itemsByOrderId' : 'haulaway_items';
            const BASE = {
                item_type: prev.freight_type,
                ...(prev.order_id ? { order_id: prev.order_id } : {}),
                is_return: prev.order_type === 'return',
                ...(prev.freight_type === 'pallet'
                    ? {
                          description: 'Pallet',
                          length: 48,
                          width: 40,
                      }
                    : {}),
            };

            return {
                ...prev,
                [attr]: [
                    ...(prev[attr] || []),
                    opt.type === 'item' ? { ...DEFAULT_ITEM, ...BASE } : { ...DEFAULT_HAUL_AWAY, ...BASE },
                ],
            };
        });
    }, []);

    const copyItem = useCallback((item, opt = { type: 'item' }) => {
        callbacks.modifyClone((prev) => {
            const attr = opt.type === 'item' ? 'itemsByOrderId' : 'haulaway_items';
            const { item_id, ...restItem } = item;
            const newItem = {
                pallet: null,
                pallet_id: null,
                item_sku_status: null,
                exceptions: [],
                item_exception: null,
                ...restItem,
            };

            return {
                ...prev,
                [attr]: [...(prev[attr] || []), newItem],
            };
        });
    }, []);

    const editItem = useCallback((idx, item, opt = { type: 'item' }) => {
        callbacks.modifyClone((prev) => {
            const attr = opt.type === 'item' ? 'itemsByOrderId' : 'haulaway_items';
            const clone = [...(prev[attr] || [])];
            const replacement = { ...clone[idx], ...item };
            clone.splice(idx, 1, replacement);

            return {
                ...prev,
                [attr]: clone,
            };
        });
    }, []);

    const editPallet = useCallback((idx, pallet_id, update = {}) => {
        callbacks.modifyClone((prev) => {
            const updatedItems = [...(prev.itemsByOrderId || [])].map((item, i) => {
                if (idx === i || (item.pallet_id && item.pallet_id === pallet_id)) {
                    return {
                        ...item,
                        pallet: {
                            ...(item.pallet || {}),
                            ...(update || {}),
                        },
                    };
                }
                return item;
            });

            return {
                ...prev,
                itemsByOrderId: updatedItems,
            };
        });
    }, []);

    const editNote = useCallback((note) => {
        callbacks.modifyClone((prev) => {
            const oldNotes = [...(prev?.notes || [])];
            const replacement = { ...(oldNotes?.[0] || {}), ...note };
            const newNotes = [replacement];

            return {
                ...prev,
                notes: newNotes,
            };
        });
    }, []);

    const addNote = useCallback((note) => {
        callbacks.modifyClone((prev) => {
            return {
                ...prev,
                notes: [
                    ...(prev?.notes || []),
                    note,
                ],
            };
        });
    }, []);

    const deleteItem = useCallback((idx, opt = { type: 'item' }) => {
        callbacks.modifyClone((prev) => {
            const attr = opt.type === 'item' ? 'itemsByOrderId' : 'haulaway_items';
            const clone = [...prev[attr]];
            clone.splice(idx, 1);

            return {
                ...prev,
                [attr]: clone,
            };
        });
    }, []);

    const enrichOrder = useCallback(
        async (attr, address, pickup = false) => {
            const [{ geocodePartialMatch, geocodeFailed, ...enriched }, cache] = await enrich(
                { ...state.clone, [attr]: address },
                {},
                { pickup }
            );

            callbacks.modifyClone(enriched);
            callbacks.setGeocache(cache);
            callbacks.setErrors((prev) => ({ ...prev, geocodePartialMatch, geocodeFailed }));

            return { errors: { geocodePartialMatch, geocodeFailed } };
        },
        [state.clone, state.geoCache]
    );

    const geocodeLatLong = useCallback(
        async (event) => {
            if (event?.latLng) {
                const x = event.latLng.lat();
                const y = event.latLng.lng();

                try {
                    let geocodeResults = await geocodeByLatLng({ lat: x, lng: y });
                    geocodeResults = geocodeResults || {
                        address: '',
                        city: '',
                        state: '',
                        street: '',
                        zip: '',
                    };

                    callbacks.modifyClone((prev) => {
                        return {
                            ...prev,
                            [lat]: x,
                            [long]: y,
                            ...(state.clone[is_custom]
                                ? {}
                                : {
                                      [address]: geocodeResults.address,
                                      [city]: geocodeResults.city,
                                      [do_state]: geocodeResults.state,
                                      [street]: geocodeResults.street,
                                      [zip]: geocodeResults.zip,
                                  }),
                        };
                    });
                } catch (e) {
                    console.error(e);
                    callbacks.onError('Geocode failed. Please try again.');
                }
            }
        },
        [state.clone]
    );

    return {
        addItem,
        applyItemOperations,
        applyOrderOperations,
        deleteItem,
        editItem,
        enrichOrder,
        modifyOrder,
        geocodeLatLong,
        makeDirty,
        makeItemDirty,
        editPallet,
        copyItem,
        editNote,
        addNote,
    };
};
