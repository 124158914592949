import PhoneNumberInput from '@/components/PhoneNumberInput';
import { Row, SectionSubtitle2 } from '@/styles/blocks';
import { toE164, toNational } from '@/utilities/formatPhoneNumber';
import { Grid, TextField } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { SecondaryContactBtn } from '../../InputFields';
import { css } from '@emotion/react';

const ContactFields = ({ isDirty, order, attrs, hasError, callbacks, opt }) => {
    const [name, phone, email] = attrs;

    return (
        <Row
            css={css`
                margin-top: 16px;
                align-items: center;
            `}
        >
            <Grid
                container
                item
                css={css`
                    flex-grow: 1;
                    margin-right: 16px;
                `}
            >
                <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={order[name]}
                    onBlur={() => callbacks.makeDirty([name])}
                    onChange={(e) => callbacks.modifyOrder({ [name]: e.target.value })}
                    error={hasError[name] && (opt.startDirty || isDirty[name])}
                />
            </Grid>
            <Grid
                container
                item
                css={css`
                    flex-grow: 1;
                    margin-right: 16px;
                `}
            >
                <TextField
                    fullWidth
                    label="Phone"
                    variant="outlined"
                    InputProps={{
                        inputComponent: PhoneNumberInput,
                    }}
                    value={toNational(order[phone])}
                    onBlur={() => callbacks.makeDirty([phone])}
                    onChange={(e) => callbacks.modifyOrder({ [phone]: toE164(e.target.value) })}
                    error={hasError[phone] && (opt.startDirty || isDirty[phone])}
                />
            </Grid>
            <Grid
                container
                item
                css={css`
                    flex-grow: 1;
                `}
            >
                <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    value={order[email]}
                    onBlur={() => callbacks.makeDirty([phone])}
                    onChange={(e) => callbacks.modifyOrder({ [email]: e.target.value })}
                />
            </Grid>
        </Row>
    );
};

const Customer = ({ isDirty, order, hasError, callbacks, opt }) => {
    const [hasSecondaryContactOverride, setHasSecondaryContact] = useState(null);

    const hasSecondaryContact = useMemo(() => {
        return hasSecondaryContactOverride !== null
            ? hasSecondaryContactOverride
            : order?.secondary_dropoff_contact_name?.length > 0;
    }, [order, hasSecondaryContactOverride]);

    return (
        <>
            <Grid>
                <SectionSubtitle2>Customer</SectionSubtitle2>
            </Grid>
            <ContactFields
                isDirty={isDirty}
                order={order}
                attrs={['dropoff_name', 'dropoff_phone', 'dropoff_email']}
                hasError={hasError}
                callbacks={callbacks}
                opt={opt}
            />
            <Grid>
                <SecondaryContactBtn
                    hasSecondaryContact={hasSecondaryContact}
                    onClick={() => {
                        setHasSecondaryContact(!hasSecondaryContact);
                        callbacks.modifyOrder({
                            secondary_dropoff_contact_name: null,
                            secondary_dropoff_contact_phone: null,
                            secondary_dropoff_contact_email: null,
                        });
                    }}
                />
            </Grid>
            {hasSecondaryContact && (
                <ContactFields
                    isDirty={isDirty}
                    order={order}
                    attrs={[
                        'secondary_dropoff_contact_name',
                        'secondary_dropoff_contact_phone',
                        'secondary_dropoff_contact_email',
                    ]}
                    hasError={hasError}
                    callbacks={callbacks}
                    opt={opt}
                />
            )}
        </>
    );
};

export default Customer;
