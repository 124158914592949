const COMMON_STATUS_OPTIONS = [
    { value: 'cancelled', label: 'Cancelled' },
    { value: 'rejected', label: 'Rejected' },
    { value: 'deleted', label: 'Deleted' },
    { value: 'rescheduled', label: 'Rescheduled' },
    { value: 'on_hold_awaiting_payment', label: 'On Hold' },
];

export const ORDER_STATUS_OPTIONS = [
    { value: 'pending', label: 'Pending' },
    { value: 'open', label: 'Open' },
    { value: 'claimed', label: 'Claimed' },
    { value: 'inProgress', label: 'In Progress' },
    { value: 'complete', label: 'Complete' },
    ...COMMON_STATUS_OPTIONS,
];

export const ROUTED_ORDER_STATUS_OPTIONS = [
    { value: 'pending', label: 'Pending (Planning)' },
    { value: 'routed', label: 'Pending (Routed)' },
    { value: 'routedOpen', label: 'Open' },
    { value: 'routedActive', label: 'Active' },
    { value: 'routedInProgress', label: 'In Progress' },
    { value: 'routedComplete', label: 'Complete' },
    ...COMMON_STATUS_OPTIONS,
];

export const STICKY_STATUSES = ['deleted', 'rescheduled'];

export const COMPLETE_STATUSES = ['complete', 'routedComplete'];
