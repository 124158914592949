import React, { useState, useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { OnwardCard } from './Card';
import { CardTitle, CardItem } from './blocks';
import { toNational } from '@/utilities/formatPhoneNumber';
import { css } from '@emotion/react';
import { OWNERSHIP_TYPES, getOrderOwnership } from '@/components/Tracking/utils';
import { local, H4 } from '@/components/Tracking/blocks';
import { colors } from '@/styles';

export default function CarrierInfoCard(props) {
    const { carrier, order, user_id } = props;
    const [logo, setLogo] = useState(null);
    const [logoBackgroundColor, setLogoBackgroundColor] = useState(null);
    useEffect(() => {
        if (order?.shipper_rate_override && !order.oms) {
            setLogo(order?.shipper_rate_override?.assign?.profile_img || null);
            setLogoBackgroundColor(order?.shipper_rate_override?.assign?.profile_img_color || null);
        }
    }, [order]);

    const { label, fragment } = useMemo(() => {
        const type = getOrderOwnership({ order, user_id });
        let label;
        let color;

        switch (type) {
            case OWNERSHIP_TYPES.INTERNAL:
                label = 'Internal';
                color = local.gold;

                break;
            case OWNERSHIP_TYPES.ONWARD:
                label = 'Onward';
                color = colors.greens.primary;
                break;
        }

        return {
            label,
            fragment: css`
                color: ${color};
            `,
        };
    }, [order]);

    const CardRow = ({ children, ...rest }) => {
        return (
            <Grid
                container
                item
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ marginTop: '0.25rem', marginBottom: '0.25rem' }}
                {...rest}
            >
                {children}
            </Grid>
        );
    };

    return (
        <OnwardCard>
            <Grid
                container
                css={css`
                    position: relative;
                `}
            >
                <CardTitle style={{ display: 'block', marginBottom: '1.5rem', paddingRight: '5rem' }}>
                    Carrier Info
                </CardTitle>
                {logo ? (
                    <Grid
                        item
                        css={css`
                            position: absolute; /* Position absolutely */
                            right: 0;
                            top: 0;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            background-color: ${logoBackgroundColor};
                            max-width: 5rem; /* Reduced from 7rem */
                            height: 2.5rem; /* Fixed height instead of max-height */
                            border-radius: 5px;
                            padding: 2px; /* Add some padding inside the container */
                        `}
                    >
                        <img
                            src={logo}
                            alt="Carrier Logo"
                            css={css`
                                width: auto;
                                height: 100%;
                                max-width: 100%;
                                object-fit: contain;
                            `}
                        />
                    </Grid>
                ) : (
                    <Grid
                        item
                        css={css`
                            position: absolute; /* Position absolutely */
                            right: 0;
                            top: 0;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                        `}
                    >
                        <H4
                            css={css`
                                ${fragment}
                            `}
                        >
                            {label}
                        </H4>
                    </Grid>
                )}
            </Grid>
            <CardRow>
                <Grid item>
                    <CardItem>{carrier?.business_name}</CardItem>
                </Grid>
            </CardRow>

            {order.frayt_id || order.bungii_id ? (
                <CardRow>
                    <Grid item>
                        <CardItem>{`Ref #: ${order.frayt_id || order.bungii_id}`}</CardItem>
                    </Grid>
                </CardRow>
            ) : null}

            <CardRow>
                <Grid item>
                    <CardItem>{carrier.billing_address || 'No address on file'}</CardItem>
                </Grid>
            </CardRow>

            <CardRow>
                <Grid item>
                    <CardItem>{toNational(carrier?.business_phone)}</CardItem>
                </Grid>
            </CardRow>

            <CardRow>
                <Grid item>
                    <CardItem>{carrier?.email}</CardItem>
                </Grid>
            </CardRow>
        </OnwardCard>
    );
}
