const TYPES = [
    'SUNDAY_RATE',
    'MONDAY_RATE',
    'TUESDAY_RATE',
    'WEDNESDAY_RATE',
    'THURSDAY_RATE',
    'FRIDAY_RATE',
    'SATURDAY_RATE',
];

export const RATES = [
    {
        name: 'Daily Target Rate',
        xLabels: [''],
        yLabels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        buckets: [
            ...TYPES.map((type) => {
                return [0].map((idx) => {
                    return {
                        type,
                        bucket: idx,
                    };
                });
            }),
        ],
    },
];

export const DEFAULT_RATES = Object.fromEntries(TYPES.map((type) => [type, [{ min: 0, max: 'infinity' }]]));
