import React, { useEffect, useRef, useState } from 'react';
import { StylesProvider } from '@material-ui/core/styles';
import { FormControlLabel, Grid, Icon, Popover, Radio, RadioGroup } from '@material-ui/core';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '@/styles';
import { MARKER_CODES } from '@/components/DispatchPlan/util/deliveryTypeCode';
import { DefaultSVG } from '@/components/DispatchPlan/map/Marker';
import { OnwardToggle } from '@/components/ShipmentForm/blocks';
import { createRoot } from 'react-dom/client';

const controlContainer = document.createElement('div');
const id = 'marker-legend-control-container';
controlContainer.setAttribute('id', id);
const containerRoot = createRoot(controlContainer);

const MarkerIcon = ({ text, ...props }) => {
    return (
        <div
            css={css`
                position: relative;
            `}
        >
            <Icon
                {...props}
                css={css`
                    width: fit-content !important;
                    height: fit-content !important;
                `}
            >
                <img
                    alt
                    src={`data:image/svg+xml;base64,${btoa(DefaultSVG({ color: colors.greens.primary }))}`}
                    width={27}
                    height={33}
                />
            </Icon>
            <IconText
                css={css`
                    position: absolute;
                    left: 0px;
                    top: 5px;
                    width: 27px;
                    text-align: center;
                    font-size: ${text?.length === 1 ? '14px' : '11px'};
                    font-weight: ${text?.length === 1 ? '700' : '500'};
                `}
            >
                {text}
            </IconText>
        </div>
    );
};

const Body1 = styled.span`
    font-size: 14px;
    font-weight: 700;
`;

const Body2 = styled.span`
    font-size: 14px;
    font-weight: 500;
`;

const IconText = styled.span`
    font-size: 11px;
    font-weight: 700;
    color: white;
`;

const MarkerLegendControlContent = ({ isEnabled, type = 'service_type', callbacks }) => {
    const anchorEl = useRef(null);

    return (
        <StylesProvider injectFirst>
            <div
                ref={anchorEl}
                css={css`
                    margin: 10px;
                    width: 40px;
                    height: 40px;
                    background-color: ${isEnabled ? '#FAFAFA' : colors.greens.primary};
                    border-radius: 2px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                `}
                onClick={callbacks.onToggleOpen}
            >
                <Grid
                    container
                    css={css`
                        color: ${isEnabled ? colors.greens.primary : colors.white};
                    `}
                >
                    <Grid item xs={12}>
                        <div style={{ fontWeight: 800, textAlign: 'center', marginBottom: -2 }}>A</div>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ fontWeight: 800, textAlign: 'end', marginRight: 1 }}>B</div>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ fontWeight: 800, textAlign: 'start', marginLeft: 1 }}>C</div>
                    </Grid>
                </Grid>
                <Popover
                    id="marker-legend-popover"
                    open={isEnabled}
                    anchorEl={anchorEl?.current}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    onClose={callbacks.onClose}
                >
                    <div
                        css={css`
                            padding: 24px;
                            width: 300px;
                        `}
                    >
                        <Grid
                            container
                            css={css`
                                border-bottom: 1px solid #e2e2e2;
                                padding-bottom: 16px;
                                margin-bottom: 20px;
                                flex-wrap: nowrap;
                            `}
                        >
                            <Grid direction="column" item>
                                <RadioGroup
                                    color={colors.greens.primary}
                                    value={type}
                                    onChange={(e) => callbacks.setMarkerType(e.target.value)}
                                >
                                    <FormControlLabel
                                        value={'service_type'}
                                        control={
                                            <Radio
                                                css={css`
                                                    color: ${colors.greens.primary} !important;
                                                `}
                                            />
                                        }
                                        label="Service Type"
                                    />
                                    <FormControlLabel
                                        value={'order_type'}
                                        control={
                                            <Radio
                                                css={css`
                                                    color: ${colors.greens.primary} !important;
                                                `}
                                            />
                                        }
                                        label="Order Type"
                                    />
                                    <FormControlLabel
                                        value={'order_tags'}
                                        control={
                                            <Radio
                                                css={css`
                                                    color: ${colors.greens.primary} !important;
                                                `}
                                            />
                                        }
                                        label="Custom Tags"
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        {['service_type', 'order_type'].includes(type) ? (
                            <>
                                <Grid
                                    container
                                    css={css`
                                        justify-content: space-between;
                                        margin-bottom: 20px;
                                    `}
                                >
                                    <Body1>{type === 'delivery_type' ? 'Service Type' : 'Order Type'}</Body1>
                                    <Body1>Symbol</Body1>
                                </Grid>
                                {MARKER_CODES[type].map(({ key, full, display }, idx) => (
                                    <Grid
                                        key={key}
                                        container
                                        css={css`
                                            margin-top: ${idx === 0 ? 0 : '12px'};
                                        `}
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Body2>{full}</Body2>
                                        <MarkerIcon text={display} />
                                    </Grid>
                                ))}
                            </>
                        ) : null}
                    </div>
                </Popover>
            </div>
        </StylesProvider>
    );
};

export const MarkerLegendControl = ({ mapRef, type, callbacks }) => {
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        if (mapRef?.current) {
            const controls = mapRef.current.controls[window.google.maps.ControlPosition.RIGHT_TOP];

            controls.push(controlContainer);
            containerRoot.render(
                <MarkerLegendControlContent
                    isEnabled={isOpen}
                    type={type}
                    callbacks={{
                        ...callbacks,
                        onToggleOpen: () => {
                            setOpen((prev) => !prev);
                        },
                        onClose: (e) => {
                            setOpen(false);
                            e.stopPropagation();
                        },
                    }}
                />
            );

            return () => {
                const idx = controls.indexOf((el) => el.id === id);
                if (idx >= 0) {
                    controls.removeAt(idx);
                }
            };
        }
    }, [mapRef?.current]);

    useEffect(() => {
        if (mapRef?.current) {
            containerRoot.render(
                <MarkerLegendControlContent
                    isEnabled={isOpen}
                    type={type}
                    callbacks={{
                        ...callbacks,
                        onToggleOpen: () => {
                            setOpen(true);
                        },
                        onClose: (e) => {
                            setOpen(false);
                            e.stopPropagation();
                        },
                    }}
                />
            );
        }
    }, [isOpen, type]);

    return null;
};
