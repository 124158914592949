import { createContext, useMemo, useEffect, useState, useCallback } from 'react';

import { format, addDays, setDay, getWeek, setWeek, startOfWeek, endOfWeek } from 'date-fns';
import * as Sentry from '@sentry/react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { useClientUser } from '@/hooks';
import { useInvoiceExport } from '@/components/Accounting/Invoices/hooks';
import { GET_PENDING_ORDERS, GET_PRICING_OVERRIDES } from '@/components/Accounting/Invoices/graphql';

import { useTableColumns } from './columns';
import { CARRIER_INVOICE, CREATE_INVOICES, UPDATE_COMMENTS, SET_INVOICE_STATUS } from './graphql';
import { UserContext } from '@/components/App';
export const Context = createContext();
import { calcOrderSubtotal } from '@/utilities/calcOrderSubtotal';
import api from '@/utilities/api';
import { post } from '@/utilities/onwardClient';
import { SEND_TO_BILLCOM } from '@/constants/apiRoutes';

const TODAY = new Date(new Date().setHours(0, 0, 0, 0));
const thisWeek = getWeek(TODAY, { weekStartsOn: 1 });
const lastWeek = setWeek(TODAY, thisWeek - 1, { weekStartsOn: 1 });
const sow = startOfWeek(lastWeek, { weekStartsOn: 1 });

export const ContextProvider = ({ children }) => {
    const navigate = useNavigate();
    const [viewPod, viewOrderPod] = useState(null);
    const [newComment, setNewComment] = useState(null);
    const [billComStatus, setBillComStatus] = useState(null);
    const { perspective, invoice_id } = useParams();
    const { tags, user_id, roles, username, isOnwardAdmin } = useClientUser();

    const [getInvoices, { data, loading: initInflight }] = useLazyQuery(CARRIER_INVOICE, {
        onError: (err) => {
            console.error(err);
            Sentry.captureException(err);
        },
    });

    const [getPending, { data: pendingData, loading: pendingInflight }] = useLazyQuery(GET_PENDING_ORDERS, {
        onError: (err) => {
            console.error(err);
            Sentry.captureException(err);
        },
    });

    const [setInvoiceStatus, { loading: invoiceStatusInflight }] = useMutation(SET_INVOICE_STATUS, {
        onError: (err) => {
            console.error(err);
            Sentry.captureException(err);
        },
    });

    const clientID = useMemo(() => {
        return data?.result?.[0]?.client_id;
    }, [data]);

    const { data: overrides } = useQuery(GET_PRICING_OVERRIDES, {
        variables: { client_id: user_id },
        onError: (err) => {
            console.error(err);
            Sentry.captureException(err);
        },
    });

    const [submit, { loading: submitInflight }] = useMutation(CREATE_INVOICES, {
        onCompleted: ({ created }) => {
            if (created?.returning?.length > 0) {
                return navigate(`/carrier/accounting/marketplace/invoice/${created.returning[0].carrier_invoice_id}`);
            }
        },
        onError: (err) => {
            console.error(err);
            Sentry.captureException(err);
        },
    });

    const [updateComments, { loading: updateInflight }] = useMutation(UPDATE_COMMENTS, {
        onError: (err) => {
            console.error(err);
            Sentry.captureException(err);
        },
    });

    const invoice = useMemo(() => {
        return data?.result?.[0] || {};
    }, [data]);

    const invoiceComments = useMemo(() => {
        return invoice?.invoice_comments || [];
    }, [invoice]);

    const pending = useMemo(() => {
        return pendingData?.results || [];
    }, [pendingData]);

    const pendingInvoice = useMemo(() => {
        const invoices = pending.reduce((acc, order) => {
            const week = getWeek(new Date(order.billing_completion_time), { weekStartsOn: 1 });

            acc[week] = {
                ...(acc[week] || {
                    created_at: new Date().toISOString(),
                    carrier_invoice_id: `invoice-${week}`,
                    is_pending: true,
                    this_week: thisWeek === week,
                    week_number: week,
                    status: 'UNPAID',
                    type: 'PAYABLE',
                }),
                orders: [...(acc[week]?.orders || []), order],
            };

            return acc;
        }, {});

        return invoices[invoice_id === 'pending' ? thisWeek - 1 : thisWeek] || {};
    }, [pending]);

    const breakdown = useMemo(() => {
        return perspective === 'carrier' ? 'carrierBreakdown' : 'shipperBreakdown';
    }, [perspective]);

    const combined = useMemo(() => {
        return ['pending', 'current'].includes(invoice_id) ? pendingInvoice : invoice;
    }, [pendingInvoice, invoice, invoice_id]);

    const editsBy = useMemo(() => {
        if (combined.week_number) {
            let created = new Date(new Date().setHours(0, 0, 0, 0));

            const due = setWeek(created, combined.week_number + 1, { weekStartsOn: 1 });
            const thurs = setDay(due, 4);

            return thurs;
        }

        return null;
    }, [combined]);

    useEffect(() => {
        if (!['pending', 'current'].includes(invoice_id)) {
            getInvoices({
                variables: {
                    invoice_id,
                    client_id: user_id,
                },
            });
        }

        getPending({
            variables: {
                start: sow,
                client_id: user_id,
            },
        });
    }, [invoice_id]);

    const [{ selected }, { exportCsv }] = useInvoiceExport({
        invoices: [combined],
        selectedMap: { [combined.carrier_invoice_id]: true },
        overrides,
        breakdown,
        tags: invoice?.type === 'INTERNAL' ? tags : [],
    });

    const [total, subtotal, adjustments] = useMemo(() => {
        const attr = perspective === 'carrier' ? 'orders' : 'shipper_orders';
        return (combined?.[attr] || []).reduce(
            ([totalAcc, subtotalAcc, adjustmentsAcc], order) => {
                const subTotalAdj = calcOrderSubtotal(order);

                const accessorials = (order?.price_breakdown?.[breakdown]?.accessorials || []).reduce(
                    (acc, { quantity, rate }) => {
                        return acc + quantity * rate;
                    },
                    0
                );
                const total = subTotalAdj + accessorials;

                return [totalAcc + total, subtotalAcc + subTotalAdj, adjustmentsAcc + accessorials];
            },
            [0, 0, 0]
        );
    }, [combined, breakdown, perspective]);

    const orders = useMemo(() => {
        const attr = perspective === 'carrier' ? 'orders' : 'shipper_orders';
        const sortedOrders = (combined?.[attr] || []).slice().sort((a, b) => {
            if (a.dropoff_name < b.dropoff_name) return -1;
            if (a.dropoff_name > b.dropoff_name) return 1;
            return 0;
        });
        return sortedOrders;
    }, [combined, perspective]);

    const missingPOD = useMemo(() => {
        return !orders.every((order) => {
            const hasPhoto = order.delivery_photo?.length >= 1 || order.pod?.length >= 1;
            const hasSignature = order.customer_signature?.length >= 1;

            return hasPhoto || hasSignature;
        });
    }, [orders]);

    const sendToBillcom = async () => {
        const params = {
            invoice_id,
        };
        const invRes = await post(SEND_TO_BILLCOM, {
            data: params,
        });

        console.log(invRes);
        if (invRes.status === 200) {
            setBillComStatus('success');
        }

        return invRes;
    };

    const callbacks = {
        submit: (invoice) => {
            let TODAY = new Date(new Date().setHours(0, 0, 0, 0));
            const week = setWeek(TODAY, invoice.week_number, { weekStartsOn: 1 });
            const sow = startOfWeek(week, { weekStartsOn: 1 });
            const eow = endOfWeek(week, { weekStartsOn: 1 });
            const dueDate = addDays(TODAY, 30); // 30 days

            submit({
                variables: {
                    invoices: [
                        {
                            client_id: user_id,
                            week_number: invoice.week_number,
                            status: 'UNPAID',
                            type: 'PAYABLE',
                            description: `Invoice for ${format(sow, 'yyyy-MM-dd')} - ${format(eow, 'yyyy-MM-dd')}`,
                            due_date: dueDate.toISOString(),
                            pay_period_start: sow.toISOString(),
                            pay_period_end: eow.toISOString(),
                            orders: {
                                data: (invoice?.orders || []).map((order) => ({
                                    order_id: order.order_id,
                                })),
                                on_conflict: {
                                    constraint: 'orders_pkey',
                                    update_columns: ['carrier_invoice_id'],
                                },
                            },
                        },
                    ],
                },
            });
        },
        setPaid: () => {
            setInvoiceStatus({
                variables: {
                    ids: [invoice.carrier_invoice_id],
                    status: 'PAID',
                },
            });
        },
        setApproved: () => {
            setInvoiceStatus({
                variables: {
                    ids: [invoice.carrier_invoice_id],
                    status: 'APPROVED',
                },
            });
        },
        viewOrderPod,
        setNewComment,
        saveComments: () => {
            updateComments({
                variables: {
                    invoice_id: invoice_id,
                    client_id: clientID,
                    comment: {
                        comment: newComment,
                        created_by: username,
                        created_at: new Date().toISOString(),
                    },
                },
            })
                .then(() => {
                    setNewComment('');
                })
                .catch((error) => {
                    console.error('Error saving comments:', error);
                    Sentry.captureException(error);
                    // Optionally, you can add more error handling logic here, such as showing a notification to the user
                });
        },
        exportCsv,
        gotoAccessorial: (order, perspective) => {
            return window.open(
                `${window.location.origin}/accounting/accessorials/${order.order_id}/${perspective}`,
                '_blank'
            );
        },
        getRowId: (order) => {
            return order.order_id;
        },
        sendToBillcom,
    };

    const COLUMNS = useTableColumns({ perspective, isAdmin: roles['ADMIN'], callbacks });

    return (
        <Context.Provider
            value={{
                state: {
                    perspective,
                    editsBy,
                    viewPod,
                    total,
                    subtotal,
                    missingPOD,
                    adjustments,
                    isPending: 'pending' === invoice_id,
                    isCurrent: 'current' === invoice_id,
                    invoice: combined,
                    orders,
                    newComment,
                    invoiceComments,
                    columns: COLUMNS,
                    updateInflight,
                    clientID,
                    isOnwardAdmin,
                    billComStatus,
                },
                loading: {
                    init: initInflight || pendingInflight,
                    submit: submitInflight,
                    setInvoiceStatus: invoiceStatusInflight,
                },
                callbacks,
            }}
        >
            {children}
        </Context.Provider>
    );
};
