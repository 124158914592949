import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import helpers from '../../utilities/FTLStopHelpers';
import { Grid, Tooltip } from '@material-ui/core';
import CarrierReviewModal from '../CarrierReviewModal';
import { Rating } from '@material-ui/lab';
import { colors } from '@/styles';
import { H1, H3, Body1, Body2, local, PrimaryButton, GreyBody } from './blocks';
import FTLStopHelpers from '../../utilities/FTLStopHelpers';
import { useClientUser, useRouteRevenue } from '../../hooks';
import dateFnsFormat from 'date-fns/format';
import { Info } from '@material-ui/icons';
import { useInvalidEventStates } from '../DispatchPlan/hooks';
import OrdersAwaitingCDModal from '../DispatchPlan/modals/OrdersAwaitingCDModal';
const HOUR = 60 * 60 * 1000;
const MINUTE = 60 * 1000;

const minuteString = new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'minute',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const hourString = new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'hour',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
});

const RouteInfo = ({ route = {}, review = {} }) => {
    const { preferences_service_time, ...user } = useClientUser();
    const routeRevenue = useRouteRevenue(route);

    const [routeDistance, setRouteDistance] = useState('');
    const [routeDuration, setRouteDuration] = useState('');
    const [routeStatusBadge, setRouteStatusBadge] = useState({});
    const [carrierReviewOpen, setCarrierReviewOpen] = useState(false);
    const [driverInfo, setDriverInfo] = useState(null);
    const [openAwaitingCDModal, setOpenAwaitingCDModal] = useState();

    const uid = user?.user_id;

    useEffect(() => {
        getRouteStats();
        getRouteStatus();
        getDriverInfo();
    }, [route]);

    const getRouteStatus = () => {
        if (route && route.status) {
            switch (route.status) {
                case 'active':
                case 'pending':
                case 'open':
                    setRouteStatusBadge({
                        status: 'Pre-Transit',
                    });
                    break;
                case 'inProgress':
                    setRouteStatusBadge({
                        status: 'In Transit',
                    });
                    break;
                case 'complete':
                    setRouteStatusBadge({
                        status: 'Complete',
                    });
                    break;
            }
        }
    };

    const getRouteStats = async () => {
        if (route && route.stopsByRouteId) {
            if (route.estimated_driving_distance && route.start_time) {
                const start = new Date(route.start_time).getTime();
                const lastStop = FTLStopHelpers.getStopSequence(route.stopsByRouteId)
                    .reverse()
                    .find((stop) => !stop.end && !FTLStopHelpers.isFinalDropoff(stop));
                const lastStopServiceTime = lastStop.service_time || preferences_service_time * 60000;
                const end = new Date(lastStop.del_window_end).getTime() + lastStopServiceTime;
                const duration = end - start;
                const hour = duration / HOUR;
                const minute = duration / MINUTE;
                setRouteDistance(route.estimated_driving_distance);
                setRouteDuration(`${Math.floor(hour) > 0 ? hourString.format(hour) : minuteString.format(minute)}`);
            } else {
                const sequence = helpers.getStopSequence(route.stopsByRouteId);
                const directions = await helpers.getGMapsDirections(sequence);
                const results = await helpers.getTravelData(directions);

                setRouteDistance(results.distance.text);
                setRouteDuration(results.duration.text);
            }
        }
    };

    const finalRouteTime = useMemo(() => {
        if (route.status === 'complete') {
            const stopSequence = FTLStopHelpers.getStopSequence(route.stopsByRouteId);
            const start = new Date(stopSequence[0].arrival_time).getTime();
            const end = new Date(stopSequence[stopSequence.length - 1].stop_completion_time).getTime();
            const duration = end - start;
            const hour = duration / HOUR;
            const minute = duration / MINUTE;
            return `${Math.floor(hour) > 0 ? hourString.format(hour) : minuteString.format(minute)}`;
        }
        return null;
    }, [route]);

    const routeTotal = useMemo(() => {
        if (route?.orders && route?.carrier_id === uid) {
            return routeRevenue;
        } else if (route?.orders && route?.shipper_id === uid) {
            const totalShipperCost = route.orders.reduce((acc, orderMapping) => {
                return acc + (orderMapping?.order?.shipper_rate || 0);
            }, 0);
            return totalShipperCost?.toFixed(2);
        }
        return 0;
    }, [route, routeRevenue]);

    // Driver info should only display for Internal routes
    const getDriverInfo = async () => {
        if (!route.teammateByDriverId || (!route.oms && user?.userType === 'shipper')) return;
        setDriverInfo(route.teammateByDriverId);
    };

    const getTotalStops = (stops) => {
        if (stops) {
            // Only count stops that are not initial warehouse pickups for deliveries
            // And also are not final dropoff stops for returns / return haulaways
            let count = 0;
            stops.forEach((stop) => {
                if (!helpers.isFinalDropoff(stop) && !helpers.isWarehousePickup(stop)) count++;
            });
            return count;
        }
        return 'N/A';
    };

    const ordersAwaitingCD = useInvalidEventStates(route);

    const Status = styled.div`
        font-weight: 700;
        border: 3px solid;
        border-radius: 4px;
        align-items: center;
        justify-content: center;
        display: flex;
        height: fit-content;
        padding: 0px 8px;

        color: ${(prop) => {
            switch (prop.status) {
                case 'active':
                case 'pending':
                case 'open':
                    return '#FC8F00';
                case 'inProgress':
                    return local.blue;
                case 'complete':
                    return colors.greens.primary;
                default:
                    return colors.greys.primary;
            }
        }};
    `;

    const carrierReviewFlag = route?.carrier_id && uid !== route?.carrier_id;

    return (
        <Grid
            container
            css={css`
                padding: 20px 32px;
                background: ${colors.white.primary};
            `}
        >
            <Grid
                item
                xs={12}
                css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: fit-content;
                    padding-bottom: 18px;
                `}
            >
                <H1>{route.route_alias || `Route ${route.route_number}`}</H1>
                {review?.rating && user?.userType === 'shipper' && (
                    <Rating
                        value={review.rating}
                        readOnly
                        css={css`
                            margin-left: auto;
                            margin-right: 10px;
                        `}
                    />
                )}
                <Status status={route.status}>
                    <H3>{routeStatusBadge.status}</H3>
                </Status>
            </Grid>
            {user?.adminLoggedIn && (
                <Grid container direction="column" xs={6}>
                    <Body1>Shipper</Body1>
                    <Body1>Carrier</Body1>
                </Grid>
            )}
            {user?.adminLoggedIn && (
                <Grid container direction="column" alignItems="flex-end" xs={6}>
                    <Body2>{route?.route_shipper?.business_name || 'N/A'}</Body2>
                    <Body2>{route?.route_carrier?.business_name || 'TBD'}</Body2>
                </Grid>
            )}

            {route?.teammateByDriverId?.phone && (
                <Grid container>
                    <Grid item xs={12}>
                        <GreyBody>Driver: {route?.teammateByDriverId?.username}</GreyBody>
                    </Grid>
                    <Grid item xs={12}>
                        <GreyBody>Driver Phone: {route?.teammateByDriverId?.phone}</GreyBody>
                    </Grid>
                </Grid>
            )}
            <Grid container direction="column" xs={6}>
                <Body1>Stops</Body1>
                <Body1>Scheduled Delivery Date</Body1>
                <Body1>{route.status === 'complete' ? '' : 'Estimated'} Distance</Body1>
                <Body1>Estimated Trip Time</Body1>
                {finalRouteTime && <Body1>Final Route Time</Body1>}
                {driverInfo && <Body1>Driver</Body1>}
                <Grid container alignItems="center">
                    <Body1>Route {route.carrier_id === uid ? 'Revenue' : 'Cost'}</Body1>
                    <Tooltip
                        style={{ color: '#59B863', fontSize: 18 }}
                        title={
                            route.carrier_id === uid ? (
                                <Grid container direction="column">
                                    <Body1>
                                        Only counts revenue for orders that will interact with the end customer on this
                                        route. (cross-docked orders on their non-customer facing leg excluded).
                                    </Body1>
                                    <Body1
                                        css={css`
                                            margin-top: 15px;
                                        `}
                                    >
                                        For internal orders (orders you created or orders given to you by a partnered
                                        shipper), this value reflects the order revenue value that was submitted upon
                                        order creation. It is possible for this value to be zero if not specified.
                                    </Body1>
                                    <Body1
                                        css={css`
                                            margin-top: 15px;
                                        `}
                                    >
                                        For orders you claimed from the marketplace, this value reflects the marketplace
                                        rate.
                                    </Body1>
                                </Grid>
                            ) : (
                                <Grid container direction="column">
                                    <Body1>
                                        This number represents an estimate of the total cost of the route if sent to the
                                        Onward Marketplace
                                    </Body1>
                                </Grid>
                            )
                        }
                        placement="top"
                    >
                        <Info />
                    </Tooltip>
                </Grid>
                {route.route_alias && <Body1>Route Number:</Body1>}
            </Grid>
            <Grid container direction="column" alignItems="flex-end" xs={6}>
                <Body2>{getTotalStops(route.stopsByRouteId)}</Body2>
                <Body2>{route.scheduled_delivery ? route.scheduled_delivery_formatted : 'TBD'}</Body2>
                <Body2>{routeDistance || 'N/A'}</Body2>
                <Body2>{routeDuration || 'N/A'}</Body2>
                {finalRouteTime && <Body2>{finalRouteTime}</Body2>}
                {driverInfo && <Body2>{driverInfo.username}</Body2>}
                <Body2>{`$${routeTotal}` || 'N/A'}</Body2>
                {route.route_alias && <Body2>{route.route_number}</Body2>}
            </Grid>

            {ordersAwaitingCD && (
                <Body2
                    css={css`
                        color: ${colors.reds.primary};
                        white-space: normal;
                    `}
                    onClick={() => setOpenAwaitingCDModal(true)}
                >
                    Warning: This route has cross-docked orders with incomplete pickup legs.{' '}
                    <span
                        css={css`
                            text-decoration: underline;
                            cursor: pointer;
                        `}
                    >
                        More Info
                    </span>
                </Body2>
            )}

            {route.status === 'complete' &&
                user?.userType === 'shipper' &&
                carrierReviewFlag &&
                (review ? (
                    <PrimaryButton
                        variant="contained"
                        css={css`
                            width: 100%;
                            margin-top: 10px;
                        `}
                        onClick={() => setCarrierReviewOpen(true)}
                    >
                        Edit Carrier Review
                    </PrimaryButton>
                ) : (
                    <PrimaryButton
                        variant="contained"
                        onClick={() => setCarrierReviewOpen(true)}
                        css={css`
                            width: 100%;
                            margin-top: 10px;
                        `}
                    >
                        Leave Carrier Review
                    </PrimaryButton>
                ))}
            <CarrierReviewModal
                isOpen={carrierReviewOpen}
                onClose={() => setCarrierReviewOpen(false)}
                reviewObject={review}
                routeId={route.route_id}
                revieweeId={route.carrier_id ? route.carrier_id : route.shipper_id}
                revieweeType={route.carrier_id ? 'CARRIER' : 'SHIPPER'}
                driverId={route?.driver_id}
                callbacks={{
                    onClose: () => setCarrierReviewOpen(false),
                }}
            />
            <OrdersAwaitingCDModal
                open={openAwaitingCDModal}
                onClose={() => {
                    setOpenAwaitingCDModal(false);
                }}
                route={route}
            />
        </Grid>
    );
};

export default RouteInfo;
