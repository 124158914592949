export const AIRPORT_CODES = [
    'JFK',
    'BDL',
    'ALB',
    'PWM',
    'BGR',
    'BTV',
    'EWR',
    'PHL',
    'SYR',
    'BUF',
    'PIT',
    'CRW',
    'ERI',
    'AVP',
    'MDT',
    'IAD',
    'RIC',
    'CKB',
    'ROA',
    'TYS',
    'HTS',
    'CLE',
    'RDU',
    'CLT',
    'CAE',
    'CHS',
    'ATL',
    'CHA',
    'MCN',
    'SAV',
    'ABY',
    'AGS',
    'JAX',
    'TLH',
    'DHN',
    'MCO',
    'PNS',
    'TPA',
    'MIA',
    'RSW',
    'BHM',
    'MGM',
    'HSV',
    'MOB',
    'BNA',
    'MEM',
    'JAN',
    'BTR',
    'MSY',
    'SDF',
    'EVV',
    'LEX',
    'CVG',
    'CMH',
    'SBN',
    'DTW',
    'IND',
    'ORD',
    'TVC',
    'LAN',
    'GRR',
    'GRB',
    'DSM',
    'ALO',
    'OMA',
    'CID',
    'DBQ',
    'MSN',
    'MLI',
    'STL',
    'MKE',
    'MSP',
    'EAU',
    'DLH',
    'RST',
    'BWI',
    'FSD',
    'ABR',
    'RAP',
    'FAR',
    'GFK',
    'MOT',
    'BIS',
    'BIL',
    'HLN',
    'BTM',
    'GTF',
    'MSO',
    'BMI',
    'MCI',
    'SGF',
    'ICT',
    'COS',
    'DEN',
    'CYS',
    'LFT',
    'SHV',
    'LIT',
    'FSM',
    'SPZ',
    'OKC',
    'AUS',
    'TUL',
    'DFW',
    'IAH',
    'KIP',
    'ABI',
    'SAT',
    'MAF',
    'CRP',
    'LRD',
    'MFE',
    'ELP',
    'AMA',
    'LBB',
    'GJT',
    'DRO',
    'COD',
    'SLC',
    'PIH',
    'IDA',
    'BOI',
    'TWF',
    'GEG',
    'SGU',
    'PHX',
    'TUS',
    'FLG',
    'ABQ',
    'LAS',
    'RNO',
    'LAX',
    'SAN',
    'BFL',
    'SBP',
    'FAT',
    'SMF',
    'SFO',
    'RDD',
    'MFR',
    'RDM',
    'PDX',
    'EUG',
    'YKM',
    'SEA',
];

export const AIRPORT_CODES_BY_ZIP = {
    10001: 'JFK',
    10002: 'JFK',
    10003: 'JFK',
    10004: 'JFK',
    10005: 'JFK',
    10006: 'JFK',
    10007: 'JFK',
    10008: 'JFK',
    10009: 'JFK',
    10010: 'JFK',
    10011: 'JFK',
    10012: 'JFK',
    10013: 'JFK',
    10014: 'JFK',
    10015: 'EWR',
    10016: 'JFK',
    10017: 'JFK',
    10018: 'JFK',
    10019: 'JFK',
    10020: 'JFK',
    10021: 'JFK',
    10022: 'JFK',
    10023: 'JFK',
    10024: 'JFK',
    10025: 'JFK',
    10026: 'JFK',
    10027: 'JFK',
    10028: 'JFK',
    10029: 'JFK',
    10030: 'JFK',
    10031: 'JFK',
    10032: 'JFK',
    10033: 'JFK',
    10034: 'JFK',
    10035: 'JFK',
    10036: 'JFK',
    10037: 'JFK',
    10038: 'JFK',
    10039: 'JFK',
    10040: 'JFK',
    10041: 'JFK',
    10043: 'JFK',
    10044: 'JFK',
    10045: 'JFK',
    10046: 'EWR',
    10047: 'JFK',
    10048: 'JFK',
    10055: 'JFK',
    10060: 'EWR',
    10065: 'JFK',
    10069: 'JFK',
    10072: 'JFK',
    10075: 'JFK',
    10079: 'EWR',
    10080: 'JFK',
    10081: 'JFK',
    10082: 'JFK',
    10087: 'JFK',
    10090: 'EWR',
    10094: 'EWR',
    10095: 'EWR',
    10096: 'EWR',
    10098: 'EWR',
    10099: 'EWR',
    10101: 'JFK',
    10102: 'JFK',
    10103: 'JFK',
    10104: 'JFK',
    10105: 'JFK',
    10106: 'JFK',
    10107: 'JFK',
    10108: 'JFK',
    10109: 'JFK',
    10110: 'JFK',
    10111: 'JFK',
    10112: 'JFK',
    10113: 'JFK',
    10114: 'JFK',
    10115: 'JFK',
    10116: 'JFK',
    10117: 'JFK',
    10118: 'JFK',
    10119: 'JFK',
    10120: 'JFK',
    10121: 'JFK',
    10122: 'JFK',
    10123: 'JFK',
    10124: 'JFK',
    10125: 'JFK',
    10126: 'JFK',
    10128: 'JFK',
    10129: 'JFK',
    10130: 'JFK',
    10131: 'JFK',
    10132: 'JFK',
    10133: 'JFK',
    10138: 'JFK',
    10149: 'JFK',
    10150: 'JFK',
    10151: 'JFK',
    10152: 'JFK',
    10153: 'JFK',
    10154: 'JFK',
    10155: 'JFK',
    10156: 'JFK',
    10157: 'JFK',
    10158: 'JFK',
    10159: 'JFK',
    10160: 'JFK',
    10161: 'JFK',
    10162: 'JFK',
    10163: 'JFK',
    10164: 'JFK',
    10165: 'JFK',
    10166: 'JFK',
    10167: 'JFK',
    10168: 'JFK',
    10169: 'JFK',
    10170: 'JFK',
    10171: 'JFK',
    10172: 'JFK',
    10173: 'JFK',
    10174: 'JFK',
    10175: 'JFK',
    10176: 'JFK',
    10177: 'JFK',
    10178: 'JFK',
    10179: 'JFK',
    10184: 'EWR',
    10185: 'JFK',
    10196: 'EWR',
    10197: 'JFK',
    10199: 'JFK',
    10200: 'EWR',
    10203: 'EWR',
    10211: 'EWR',
    10212: 'EWR',
    10213: 'JFK',
    10242: 'JFK',
    10249: 'JFK',
    10256: 'JFK',
    10257: 'EWR',
    10258: 'EWR',
    10259: 'JFK',
    10260: 'JFK',
    10261: 'JFK',
    10265: 'JFK',
    10268: 'JFK',
    10269: 'JFK',
    10270: 'JFK',
    10271: 'JFK',
    10272: 'JFK',
    10273: 'JFK',
    10274: 'JFK',
    10275: 'JFK',
    10276: 'JFK',
    10277: 'JFK',
    10278: 'JFK',
    10279: 'JFK',
    10280: 'JFK',
    10281: 'JFK',
    10282: 'JFK',
    10285: 'JFK',
    10286: 'JFK',
    10292: 'JFK',
    10301: 'EWR',
    10302: 'EWR',
    10303: 'EWR',
    10304: 'EWR',
    10305: 'EWR',
    10306: 'EWR',
    10307: 'EWR',
    10308: 'EWR',
    10309: 'EWR',
    10310: 'EWR',
    10311: 'EWR',
    10312: 'EWR',
    10313: 'EWR',
    10314: 'EWR',
    10451: 'JFK',
    10452: 'JFK',
    10453: 'JFK',
    10454: 'JFK',
    10455: 'JFK',
    10456: 'JFK',
    10457: 'JFK',
    10458: 'JFK',
    10459: 'JFK',
    10460: 'JFK',
    10461: 'JFK',
    10462: 'JFK',
    10463: 'JFK',
    10464: 'JFK',
    10465: 'JFK',
    10466: 'JFK',
    10467: 'JFK',
    10468: 'JFK',
    10469: 'JFK',
    10470: 'JFK',
    10471: 'JFK',
    10472: 'JFK',
    10473: 'JFK',
    10474: 'JFK',
    10475: 'JFK',
    10499: 'JFK',
    10501: 'JFK',
    10502: 'JFK',
    10503: 'JFK',
    10504: 'JFK',
    10505: 'JFK',
    10506: 'JFK',
    10507: 'JFK',
    10509: 'JFK',
    10510: 'JFK',
    10511: 'JFK',
    10512: 'JFK',
    10514: 'JFK',
    10516: 'JFK',
    10517: 'JFK',
    10518: 'JFK',
    10519: 'JFK',
    10520: 'JFK',
    10521: 'JFK',
    10522: 'JFK',
    10523: 'JFK',
    10524: 'JFK',
    10526: 'JFK',
    10527: 'JFK',
    10528: 'JFK',
    10530: 'JFK',
    10532: 'JFK',
    10533: 'JFK',
    10535: 'JFK',
    10536: 'JFK',
    10537: 'JFK',
    10538: 'JFK',
    10540: 'JFK',
    10541: 'JFK',
    10542: 'JFK',
    10543: 'JFK',
    10545: 'JFK',
    10546: 'JFK',
    10547: 'JFK',
    10548: 'JFK',
    10549: 'JFK',
    10550: 'JFK',
    10551: 'JFK',
    10552: 'JFK',
    10553: 'JFK',
    10557: 'JFK',
    10558: 'JFK',
    10560: 'JFK',
    10562: 'JFK',
    10566: 'JFK',
    10567: 'JFK',
    10570: 'JFK',
    10571: 'JFK',
    10572: 'JFK',
    10573: 'JFK',
    10576: 'JFK',
    10577: 'JFK',
    10578: 'JFK',
    10579: 'JFK',
    10580: 'JFK',
    10583: 'JFK',
    10587: 'JFK',
    10588: 'JFK',
    10589: 'JFK',
    10590: 'JFK',
    10591: 'JFK',
    10594: 'JFK',
    10595: 'JFK',
    10596: 'JFK',
    10597: 'JFK',
    10598: 'JFK',
    10601: 'JFK',
    10602: 'JFK',
    10603: 'JFK',
    10604: 'JFK',
    10605: 'JFK',
    10606: 'JFK',
    10607: 'JFK',
    10610: 'JFK',
    10701: 'JFK',
    10702: 'JFK',
    10703: 'JFK',
    10704: 'JFK',
    10705: 'JFK',
    10706: 'JFK',
    10707: 'JFK',
    10708: 'JFK',
    10709: 'JFK',
    10710: 'JFK',
    10801: 'JFK',
    10802: 'JFK',
    10803: 'JFK',
    10804: 'JFK',
    10805: 'JFK',
    10901: 'EWR',
    10910: 'EWR',
    10911: 'JFK',
    10912: 'EWR',
    10913: 'EWR',
    10914: 'EWR',
    10915: 'EWR',
    10916: 'EWR',
    10917: 'JFK',
    10918: 'EWR',
    10919: 'EWR',
    10920: 'EWR',
    10921: 'EWR',
    10922: 'JFK',
    10923: 'EWR',
    10924: 'EWR',
    10925: 'EWR',
    10926: 'EWR',
    10927: 'EWR',
    10928: 'JFK',
    10930: 'JFK',
    10931: 'EWR',
    10932: 'EWR',
    10933: 'EWR',
    10940: 'EWR',
    10941: 'EWR',
    10943: 'EWR',
    10949: 'EWR',
    10950: 'EWR',
    10952: 'EWR',
    10953: 'JFK',
    10954: 'EWR',
    10956: 'EWR',
    10958: 'EWR',
    10959: 'EWR',
    10960: 'EWR',
    10962: 'EWR',
    10963: 'EWR',
    10964: 'EWR',
    10965: 'EWR',
    10968: 'EWR',
    10969: 'EWR',
    10970: 'EWR',
    10973: 'EWR',
    10974: 'EWR',
    10975: 'EWR',
    10976: 'EWR',
    10977: 'EWR',
    10979: 'EWR',
    10980: 'EWR',
    10981: 'EWR',
    10982: 'EWR',
    10983: 'EWR',
    10984: 'EWR',
    10985: 'EWR',
    10986: 'JFK',
    10987: 'EWR',
    10988: 'EWR',
    10989: 'EWR',
    10990: 'EWR',
    10992: 'EWR',
    10993: 'EWR',
    10994: 'EWR',
    10996: 'JFK',
    10997: 'JFK',
    10998: 'EWR',
    11001: 'JFK',
    11002: 'JFK',
    11003: 'JFK',
    11004: 'JFK',
    11005: 'JFK',
    11010: 'JFK',
    11020: 'JFK',
    11021: 'JFK',
    11022: 'JFK',
    11023: 'JFK',
    11024: 'JFK',
    11025: 'EWR',
    11026: 'EWR',
    11027: 'EWR',
    11030: 'JFK',
    11040: 'JFK',
    11041: 'JFK',
    11042: 'JFK',
    11043: 'EWR',
    11044: 'EWR',
    11050: 'JFK',
    11051: 'JFK',
    11052: 'JFK',
    11053: 'JFK',
    11054: 'JFK',
    11055: 'JFK',
    11096: 'JFK',
    11099: 'EWR',
    11101: 'JFK',
    11102: 'JFK',
    11103: 'JFK',
    11104: 'JFK',
    11105: 'JFK',
    11106: 'JFK',
    11109: 'JFK',
    11120: 'EWR',
    11201: 'JFK',
    11202: 'JFK',
    11203: 'JFK',
    11204: 'JFK',
    11205: 'JFK',
    11206: 'JFK',
    11207: 'JFK',
    11208: 'JFK',
    11209: 'JFK',
    11210: 'JFK',
    11211: 'JFK',
    11212: 'JFK',
    11213: 'JFK',
    11214: 'JFK',
    11215: 'JFK',
    11216: 'JFK',
    11217: 'JFK',
    11218: 'JFK',
    11219: 'JFK',
    11220: 'JFK',
    11221: 'JFK',
    11222: 'JFK',
    11223: 'JFK',
    11224: 'EWR',
    11225: 'JFK',
    11226: 'JFK',
    11228: 'JFK',
    11229: 'JFK',
    11230: 'JFK',
    11231: 'JFK',
    11232: 'JFK',
    11233: 'JFK',
    11234: 'JFK',
    11235: 'JFK',
    11236: 'JFK',
    11237: 'JFK',
    11238: 'JFK',
    11239: 'JFK',
    11240: 'EWR',
    11241: 'JFK',
    11242: 'JFK',
    11243: 'JFK',
    11244: 'EWR',
    11245: 'JFK',
    11247: 'JFK',
    11248: 'EWR',
    11249: 'JFK',
    11251: 'JFK',
    11252: 'JFK',
    11254: 'EWR',
    11255: 'EWR',
    11256: 'JFK',
    11351: 'JFK',
    11352: 'JFK',
    11354: 'JFK',
    11355: 'JFK',
    11356: 'JFK',
    11357: 'JFK',
    11358: 'JFK',
    11359: 'JFK',
    11360: 'JFK',
    11361: 'JFK',
    11362: 'JFK',
    11363: 'JFK',
    11364: 'JFK',
    11365: 'JFK',
    11366: 'JFK',
    11367: 'JFK',
    11368: 'JFK',
    11369: 'JFK',
    11370: 'JFK',
    11371: 'JFK',
    11372: 'JFK',
    11373: 'JFK',
    11374: 'JFK',
    11375: 'JFK',
    11377: 'JFK',
    11378: 'JFK',
    11379: 'JFK',
    11380: 'JFK',
    11381: 'JFK',
    11385: 'JFK',
    11386: 'JFK',
    11390: 'EWR',
    11405: 'JFK',
    11411: 'JFK',
    11412: 'JFK',
    11413: 'JFK',
    11414: 'JFK',
    11415: 'JFK',
    11416: 'JFK',
    11417: 'JFK',
    11418: 'JFK',
    11419: 'JFK',
    11420: 'JFK',
    11421: 'JFK',
    11422: 'JFK',
    11423: 'JFK',
    11424: 'JFK',
    11425: 'JFK',
    11426: 'JFK',
    11427: 'JFK',
    11428: 'JFK',
    11429: 'JFK',
    11430: 'JFK',
    11431: 'JFK',
    11432: 'JFK',
    11433: 'JFK',
    11434: 'JFK',
    11435: 'JFK',
    11436: 'JFK',
    11439: 'JFK',
    11451: 'JFK',
    11499: 'JFK',
    11501: 'JFK',
    11507: 'JFK',
    11509: 'JFK',
    11510: 'JFK',
    11514: 'JFK',
    11516: 'JFK',
    11518: 'JFK',
    11520: 'JFK',
    11530: 'JFK',
    11531: 'JFK',
    11535: 'JFK',
    11536: 'JFK',
    11542: 'JFK',
    11545: 'JFK',
    11547: 'JFK',
    11548: 'JFK',
    11549: 'JFK',
    11550: 'JFK',
    11551: 'JFK',
    11552: 'JFK',
    11553: 'JFK',
    11554: 'JFK',
    11555: 'JFK',
    11556: 'JFK',
    11557: 'JFK',
    11558: 'JFK',
    11559: 'JFK',
    11560: 'JFK',
    11561: 'JFK',
    11563: 'JFK',
    11565: 'JFK',
    11566: 'JFK',
    11568: 'JFK',
    11569: 'JFK',
    11570: 'JFK',
    11571: 'JFK',
    11572: 'JFK',
    11575: 'JFK',
    11576: 'JFK',
    11577: 'JFK',
    11579: 'JFK',
    11580: 'JFK',
    11581: 'JFK',
    11582: 'JFK',
    11590: 'JFK',
    11592: 'JFK',
    11594: 'JFK',
    11595: 'JFK',
    11596: 'JFK',
    11597: 'JFK',
    11598: 'JFK',
    11599: 'JFK',
    11690: 'JFK',
    11691: 'JFK',
    11692: 'JFK',
    11693: 'JFK',
    11694: 'JFK',
    11695: 'JFK',
    11697: 'EWR',
    11701: 'JFK',
    11702: 'JFK',
    11703: 'JFK',
    11704: 'JFK',
    11705: 'JFK',
    11706: 'JFK',
    11707: 'JFK',
    11708: 'JFK',
    11709: 'JFK',
    11710: 'JFK',
    11713: 'JFK',
    11714: 'JFK',
    11715: 'JFK',
    11716: 'JFK',
    11717: 'JFK',
    11718: 'JFK',
    11719: 'JFK',
    11720: 'JFK',
    11721: 'JFK',
    11722: 'JFK',
    11724: 'JFK',
    11725: 'JFK',
    11726: 'JFK',
    11727: 'JFK',
    11729: 'JFK',
    11730: 'JFK',
    11731: 'JFK',
    11732: 'JFK',
    11733: 'JFK',
    11735: 'JFK',
    11736: 'JFK',
    11737: 'JFK',
    11738: 'JFK',
    11739: 'JFK',
    11740: 'JFK',
    11741: 'JFK',
    11742: 'JFK',
    11743: 'JFK',
    11746: 'JFK',
    11747: 'JFK',
    11749: 'JFK',
    11750: 'JFK',
    11751: 'JFK',
    11752: 'JFK',
    11753: 'JFK',
    11754: 'JFK',
    11755: 'JFK',
    11756: 'JFK',
    11757: 'JFK',
    11758: 'JFK',
    11760: 'JFK',
    11762: 'JFK',
    11763: 'JFK',
    11764: 'JFK',
    11765: 'JFK',
    11766: 'JFK',
    11767: 'JFK',
    11768: 'JFK',
    11769: 'JFK',
    11770: 'JFK',
    11771: 'JFK',
    11772: 'JFK',
    11773: 'JFK',
    11774: 'JFK',
    11775: 'JFK',
    11776: 'JFK',
    11777: 'JFK',
    11778: 'JFK',
    11779: 'JFK',
    11780: 'JFK',
    11782: 'JFK',
    11783: 'JFK',
    11784: 'JFK',
    11786: 'JFK',
    11787: 'JFK',
    11788: 'JFK',
    11789: 'JFK',
    11790: 'JFK',
    11791: 'JFK',
    11792: 'JFK',
    11793: 'JFK',
    11794: 'JFK',
    11795: 'JFK',
    11796: 'JFK',
    11797: 'JFK',
    11798: 'JFK',
    11801: 'JFK',
    11802: 'JFK',
    11803: 'JFK',
    11804: 'JFK',
    11815: 'JFK',
    11819: 'EWR',
    11853: 'EWR',
    11854: 'JFK',
    11855: 'JFK',
    11901: 'JFK',
    11930: 'JFK',
    11931: 'JFK',
    11932: 'JFK',
    11933: 'JFK',
    11934: 'JFK',
    11935: 'JFK',
    11937: 'JFK',
    11939: 'JFK',
    11940: 'JFK',
    11941: 'JFK',
    11942: 'JFK',
    11944: 'JFK',
    11946: 'JFK',
    11947: 'JFK',
    11948: 'JFK',
    11949: 'JFK',
    11950: 'JFK',
    11951: 'JFK',
    11952: 'JFK',
    11953: 'JFK',
    11954: 'JFK',
    11955: 'JFK',
    11956: 'JFK',
    11957: 'JFK',
    11958: 'JFK',
    11959: 'JFK',
    11960: 'JFK',
    11961: 'JFK',
    11962: 'JFK',
    11963: 'JFK',
    11964: 'JFK',
    11965: 'JFK',
    11967: 'JFK',
    11968: 'JFK',
    11969: 'JFK',
    11970: 'JFK',
    11971: 'JFK',
    11972: 'JFK',
    11973: 'JFK',
    11975: 'JFK',
    11976: 'JFK',
    11977: 'JFK',
    11978: 'JFK',
    11980: 'JFK',
    12007: 'ALB',
    12008: 'ALB',
    12009: 'ALB',
    12010: 'ALB',
    12015: 'JFK',
    12016: 'ALB',
    12017: 'ALB',
    12018: 'ALB',
    12019: 'ALB',
    12020: 'ALB',
    12022: 'ALB',
    12023: 'ALB',
    12024: 'ALB',
    12025: 'ALB',
    12027: 'ALB',
    12028: 'ALB',
    12029: 'ALB',
    12031: 'SYR',
    12032: 'SYR',
    12033: 'ALB',
    12035: 'ALB',
    12036: 'ALB',
    12037: 'ALB',
    12040: 'ALB',
    12041: 'ALB',
    12042: 'ALB',
    12043: 'ALB',
    12045: 'ALB',
    12046: 'ALB',
    12047: 'ALB',
    12050: 'ALB',
    12051: 'ALB',
    12052: 'ALB',
    12053: 'ALB',
    12054: 'ALB',
    12055: 'ALB',
    12056: 'ALB',
    12057: 'ALB',
    12058: 'ALB',
    12059: 'ALB',
    12060: 'ALB',
    12061: 'ALB',
    12062: 'ALB',
    12063: 'ALB',
    12064: 'ALB',
    12065: 'ALB',
    12066: 'ALB',
    12067: 'ALB',
    12068: 'ALB',
    12069: 'ALB',
    12070: 'ALB',
    12071: 'ALB',
    12072: 'ALB',
    12073: 'ALB',
    12074: 'ALB',
    12075: 'ALB',
    12076: 'ALB',
    12077: 'ALB',
    12078: 'ALB',
    12082: 'ALB',
    12083: 'ALB',
    12084: 'ALB',
    12085: 'ALB',
    12086: 'ALB',
    12087: 'ALB',
    12089: 'ALB',
    12090: 'ALB',
    12092: 'ALB',
    12093: 'ALB',
    12094: 'ALB',
    12095: 'ALB',
    12106: 'ALB',
    12107: 'ALB',
    12108: 'ALB',
    12110: 'ALB',
    12111: 'ALB',
    12115: 'ALB',
    12116: 'SYR',
    12117: 'ALB',
    12118: 'ALB',
    12120: 'ALB',
    12121: 'ALB',
    12122: 'ALB',
    12123: 'ALB',
    12124: 'ALB',
    12125: 'ALB',
    12128: 'ALB',
    12130: 'ALB',
    12131: 'ALB',
    12132: 'ALB',
    12133: 'ALB',
    12134: 'ALB',
    12136: 'ALB',
    12137: 'ALB',
    12138: 'ALB',
    12139: 'SYR',
    12140: 'ALB',
    12141: 'ALB',
    12143: 'ALB',
    12144: 'ALB',
    12147: 'ALB',
    12148: 'ALB',
    12149: 'ALB',
    12150: 'ALB',
    12151: 'ALB',
    12153: 'ALB',
    12154: 'ALB',
    12155: 'SYR',
    12156: 'ALB',
    12157: 'ALB',
    12158: 'ALB',
    12159: 'ALB',
    12160: 'ALB',
    12161: 'ALB',
    12164: 'ALB',
    12165: 'ALB',
    12166: 'ALB',
    12167: 'ALB',
    12168: 'ALB',
    12169: 'ALB',
    12170: 'ALB',
    12172: 'JFK',
    12173: 'ALB',
    12174: 'ALB',
    12175: 'ALB',
    12176: 'ALB',
    12177: 'ALB',
    12179: 'ALB',
    12180: 'ALB',
    12181: 'ALB',
    12182: 'ALB',
    12183: 'ALB',
    12184: 'ALB',
    12185: 'ALB',
    12186: 'ALB',
    12187: 'ALB',
    12188: 'ALB',
    12189: 'ALB',
    12190: 'ALB',
    12192: 'ALB',
    12193: 'ALB',
    12194: 'ALB',
    12195: 'ALB',
    12196: 'ALB',
    12197: 'SYR',
    12198: 'ALB',
    12201: 'ALB',
    12202: 'ALB',
    12203: 'ALB',
    12204: 'ALB',
    12205: 'ALB',
    12206: 'ALB',
    12207: 'ALB',
    12208: 'ALB',
    12209: 'ALB',
    12210: 'ALB',
    12211: 'ALB',
    12212: 'ALB',
    12214: 'ALB',
    12220: 'ALB',
    12222: 'ALB',
    12223: 'ALB',
    12224: 'ALB',
    12225: 'ALB',
    12226: 'ALB',
    12227: 'ALB',
    12228: 'ALB',
    12229: 'ALB',
    12230: 'ALB',
    12231: 'ALB',
    12232: 'ALB',
    12233: 'ALB',
    12234: 'ALB',
    12235: 'ALB',
    12236: 'ALB',
    12237: 'ALB',
    12238: 'ALB',
    12239: 'ALB',
    12240: 'ALB',
    12241: 'ALB',
    12242: 'ALB',
    12243: 'ALB',
    12244: 'ALB',
    12245: 'ALB',
    12246: 'ALB',
    12247: 'ALB',
    12248: 'ALB',
    12249: 'ALB',
    12250: 'ALB',
    12252: 'ALB',
    12255: 'ALB',
    12256: 'ALB',
    12257: 'ALB',
    12260: 'ALB',
    12261: 'ALB',
    12262: 'ALB',
    12288: 'ALB',
    12301: 'ALB',
    12302: 'ALB',
    12303: 'ALB',
    12304: 'ALB',
    12305: 'ALB',
    12306: 'ALB',
    12307: 'ALB',
    12308: 'ALB',
    12309: 'ALB',
    12325: 'ALB',
    12345: 'EWR',
    12401: 'JFK',
    12402: 'JFK',
    12404: 'EWR',
    12405: 'ALB',
    12406: 'EWR',
    12407: 'EWR',
    12409: 'EWR',
    12410: 'EWR',
    12411: 'JFK',
    12412: 'EWR',
    12413: 'ALB',
    12414: 'JFK',
    12416: 'EWR',
    12417: 'JFK',
    12418: 'ALB',
    12419: 'EWR',
    12420: 'ALB',
    12421: 'EWR',
    12422: 'ALB',
    12423: 'ALB',
    12424: 'ALB',
    12427: 'EWR',
    12428: 'EWR',
    12429: 'JFK',
    12430: 'EWR',
    12431: 'ALB',
    12432: 'JFK',
    12433: 'EWR',
    12434: 'EWR',
    12435: 'EWR',
    12436: 'EWR',
    12438: 'ALB',
    12439: 'ALB',
    12440: 'EWR',
    12441: 'ALB',
    12442: 'EWR',
    12443: 'JFK',
    12444: 'ALB',
    12446: 'EWR',
    12448: 'EWR',
    12449: 'JFK',
    12450: 'EWR',
    12451: 'JFK',
    12452: 'EWR',
    12453: 'JFK',
    12454: 'ALB',
    12455: 'EWR',
    12456: 'JFK',
    12457: 'EWR',
    12458: 'EWR',
    12459: 'ALB',
    12460: 'ALB',
    12461: 'EWR',
    12463: 'JFK',
    12464: 'EWR',
    12465: 'EWR',
    12466: 'JFK',
    12468: 'EWR',
    12469: 'ALB',
    12470: 'JFK',
    12471: 'JFK',
    12472: 'JFK',
    12473: 'ALB',
    12474: 'EWR',
    12475: 'JFK',
    12477: 'JFK',
    12480: 'EWR',
    12481: 'EWR',
    12482: 'JFK',
    12483: 'EWR',
    12484: 'EWR',
    12485: 'EWR',
    12486: 'JFK',
    12487: 'JFK',
    12489: 'EWR',
    12490: 'JFK',
    12491: 'EWR',
    12492: 'EWR',
    12493: 'JFK',
    12494: 'EWR',
    12495: 'EWR',
    12496: 'ALB',
    12498: 'EWR',
    12501: 'JFK',
    12502: 'JFK',
    12503: 'JFK',
    12504: 'JFK',
    12506: 'JFK',
    12507: 'JFK',
    12508: 'JFK',
    12510: 'JFK',
    12511: 'JFK',
    12512: 'JFK',
    12513: 'JFK',
    12514: 'JFK',
    12515: 'JFK',
    12516: 'JFK',
    12517: 'EWR',
    12518: 'JFK',
    12520: 'JFK',
    12521: 'JFK',
    12522: 'JFK',
    12523: 'JFK',
    12524: 'JFK',
    12525: 'EWR',
    12526: 'JFK',
    12527: 'JFK',
    12528: 'JFK',
    12529: 'EWR',
    12530: 'JFK',
    12531: 'JFK',
    12533: 'JFK',
    12534: 'JFK',
    12537: 'JFK',
    12538: 'JFK',
    12540: 'JFK',
    12541: 'JFK',
    12542: 'JFK',
    12543: 'EWR',
    12544: 'EWR',
    12545: 'JFK',
    12546: 'JFK',
    12547: 'JFK',
    12548: 'JFK',
    12549: 'EWR',
    12550: 'JFK',
    12551: 'JFK',
    12552: 'JFK',
    12553: 'JFK',
    12555: 'JFK',
    12561: 'JFK',
    12563: 'JFK',
    12564: 'JFK',
    12565: 'EWR',
    12566: 'EWR',
    12567: 'JFK',
    12568: 'JFK',
    12569: 'JFK',
    12570: 'JFK',
    12571: 'JFK',
    12572: 'JFK',
    12574: 'JFK',
    12575: 'EWR',
    12577: 'JFK',
    12578: 'JFK',
    12580: 'JFK',
    12581: 'JFK',
    12582: 'JFK',
    12583: 'JFK',
    12584: 'JFK',
    12585: 'JFK',
    12586: 'EWR',
    12588: 'EWR',
    12589: 'EWR',
    12590: 'JFK',
    12592: 'JFK',
    12593: 'EWR',
    12594: 'JFK',
    12601: 'JFK',
    12602: 'JFK',
    12603: 'JFK',
    12604: 'JFK',
    12701: 'EWR',
    12719: 'EWR',
    12720: 'EWR',
    12721: 'EWR',
    12722: 'EWR',
    12723: 'EWR',
    12724: 'EWR',
    12725: 'EWR',
    12726: 'EWR',
    12727: 'EWR',
    12729: 'EWR',
    12732: 'EWR',
    12733: 'EWR',
    12734: 'EWR',
    12736: 'EWR',
    12737: 'EWR',
    12738: 'EWR',
    12740: 'EWR',
    12741: 'EWR',
    12742: 'EWR',
    12743: 'EWR',
    12745: 'EWR',
    12746: 'EWR',
    12747: 'EWR',
    12748: 'EWR',
    12749: 'EWR',
    12750: 'EWR',
    12751: 'EWR',
    12752: 'EWR',
    12754: 'EWR',
    12758: 'EWR',
    12759: 'EWR',
    12760: 'EWR',
    12762: 'EWR',
    12763: 'EWR',
    12764: 'EWR',
    12765: 'EWR',
    12766: 'EWR',
    12767: 'EWR',
    12768: 'EWR',
    12769: 'EWR',
    12770: 'EWR',
    12771: 'EWR',
    12775: 'EWR',
    12776: 'EWR',
    12777: 'EWR',
    12778: 'EWR',
    12779: 'EWR',
    12780: 'EWR',
    12781: 'EWR',
    12783: 'EWR',
    12784: 'EWR',
    12785: 'EWR',
    12786: 'EWR',
    12787: 'EWR',
    12788: 'EWR',
    12789: 'EWR',
    12790: 'EWR',
    12791: 'EWR',
    12792: 'ALB',
    12801: 'ALB',
    12803: 'ALB',
    12804: 'ALB',
    12808: 'BTV',
    12809: 'ALB',
    12810: 'ALB',
    12811: 'ALB',
    12812: 'BTV',
    12814: 'BTV',
    12815: 'BTV',
    12816: 'ALB',
    12817: 'BTV',
    12819: 'ALB',
    12820: 'ALB',
    12821: 'ALB',
    12822: 'ALB',
    12823: 'ALB',
    12824: 'ALB',
    12827: 'ALB',
    12828: 'ALB',
    12831: 'ALB',
    12832: 'ALB',
    12833: 'ALB',
    12834: 'ALB',
    12835: 'ALB',
    12836: 'BTV',
    12837: 'ALB',
    12838: 'ALB',
    12839: 'ALB',
    12841: 'BTV',
    12842: 'BTV',
    12843: 'ALB',
    12844: 'ALB',
    12845: 'ALB',
    12846: 'ALB',
    12847: 'BTV',
    12848: 'ALB',
    12849: 'ALB',
    12850: 'ALB',
    12851: 'BTV',
    12852: 'BTV',
    12853: 'BTV',
    12854: 'ALB',
    12855: 'BTV',
    12856: 'BTV',
    12857: 'BTV',
    12858: 'BTV',
    12859: 'ALB',
    12860: 'BTV',
    12861: 'BTV',
    12862: 'BTV',
    12863: 'ALB',
    12864: 'BTV',
    12865: 'ALB',
    12866: 'ALB',
    12870: 'BTV',
    12871: 'ALB',
    12872: 'BTV',
    12873: 'ALB',
    12874: 'BTV',
    12878: 'ALB',
    12879: 'BTV',
    12883: 'BTV',
    12884: 'ALB',
    12885: 'ALB',
    12886: 'BTV',
    12887: 'ALB',
    12901: 'BTV',
    12903: 'BTV',
    12910: 'BTV',
    12911: 'BTV',
    12912: 'BTV',
    12913: 'BTV',
    12914: 'BTV',
    12915: 'BTV',
    12916: 'BTV',
    12917: 'BTV',
    12918: 'BTV',
    12919: 'BTV',
    12920: 'BTV',
    12921: 'BTV',
    12922: 'BTV',
    12923: 'BTV',
    12924: 'BTV',
    12926: 'BTV',
    12927: 'BTV',
    12928: 'BTV',
    12929: 'BTV',
    12930: 'BTV',
    12932: 'BTV',
    12933: 'BTV',
    12934: 'BTV',
    12935: 'BTV',
    12936: 'BTV',
    12937: 'BTV',
    12939: 'BTV',
    12941: 'BTV',
    12942: 'BTV',
    12943: 'BTV',
    12944: 'BTV',
    12945: 'BTV',
    12946: 'BTV',
    12949: 'BTV',
    12950: 'BTV',
    12952: 'BTV',
    12953: 'BTV',
    12955: 'BTV',
    12956: 'BTV',
    12957: 'BTV',
    12958: 'BTV',
    12959: 'BTV',
    12960: 'BTV',
    12961: 'BTV',
    12962: 'BTV',
    12964: 'BTV',
    12965: 'BTV',
    12966: 'BTV',
    12967: 'BTV',
    12969: 'BTV',
    12970: 'BTV',
    12972: 'BTV',
    12973: 'BTV',
    12974: 'BTV',
    12975: 'BTV',
    12976: 'BTV',
    12977: 'BTV',
    12978: 'BTV',
    12979: 'BTV',
    12980: 'BTV',
    12981: 'BTV',
    12983: 'BTV',
    12985: 'BTV',
    12986: 'BTV',
    12987: 'BTV',
    12989: 'BTV',
    12992: 'BTV',
    12993: 'BTV',
    12995: 'BTV',
    12996: 'BTV',
    12997: 'BTV',
    12998: 'BTV',
    13020: 'SYR',
    13021: 'SYR',
    13022: 'SYR',
    13024: 'SYR',
    13026: 'SYR',
    13027: 'SYR',
    13028: 'SYR',
    13029: 'SYR',
    13030: 'SYR',
    13031: 'SYR',
    13032: 'SYR',
    13033: 'SYR',
    13034: 'SYR',
    13035: 'SYR',
    13036: 'SYR',
    13037: 'SYR',
    13039: 'SYR',
    13040: 'SYR',
    13041: 'SYR',
    13042: 'SYR',
    13043: 'SYR',
    13044: 'SYR',
    13045: 'SYR',
    13051: 'SYR',
    13052: 'SYR',
    13053: 'SYR',
    13054: 'SYR',
    13056: 'SYR',
    13057: 'SYR',
    13060: 'SYR',
    13061: 'SYR',
    13062: 'SYR',
    13063: 'SYR',
    13064: 'SYR',
    13065: 'SYR',
    13066: 'SYR',
    13068: 'SYR',
    13069: 'SYR',
    13071: 'SYR',
    13072: 'SYR',
    13073: 'SYR',
    13074: 'SYR',
    13076: 'SYR',
    13077: 'SYR',
    13078: 'SYR',
    13080: 'SYR',
    13081: 'SYR',
    13082: 'SYR',
    13083: 'SYR',
    13084: 'SYR',
    13087: 'SYR',
    13088: 'SYR',
    13089: 'SYR',
    13090: 'SYR',
    13092: 'SYR',
    13093: 'SYR',
    13101: 'SYR',
    13102: 'SYR',
    13103: 'SYR',
    13104: 'SYR',
    13107: 'SYR',
    13108: 'SYR',
    13110: 'SYR',
    13111: 'SYR',
    13112: 'SYR',
    13113: 'SYR',
    13114: 'SYR',
    13115: 'SYR',
    13116: 'SYR',
    13117: 'SYR',
    13118: 'SYR',
    13119: 'SYR',
    13120: 'SYR',
    13121: 'SYR',
    13122: 'SYR',
    13123: 'SYR',
    13124: 'SYR',
    13126: 'SYR',
    13129: 'SYR',
    13131: 'SYR',
    13132: 'SYR',
    13134: 'SYR',
    13135: 'SYR',
    13136: 'SYR',
    13137: 'SYR',
    13138: 'SYR',
    13139: 'SYR',
    13140: 'SYR',
    13141: 'SYR',
    13142: 'SYR',
    13143: 'SYR',
    13144: 'SYR',
    13145: 'SYR',
    13146: 'SYR',
    13147: 'SYR',
    13148: 'SYR',
    13152: 'SYR',
    13153: 'SYR',
    13154: 'BUF',
    13155: 'SYR',
    13156: 'SYR',
    13157: 'SYR',
    13158: 'SYR',
    13159: 'SYR',
    13160: 'SYR',
    13162: 'SYR',
    13163: 'SYR',
    13164: 'SYR',
    13165: 'SYR',
    13166: 'SYR',
    13167: 'SYR',
    13201: 'SYR',
    13202: 'SYR',
    13203: 'SYR',
    13204: 'SYR',
    13205: 'SYR',
    13206: 'SYR',
    13207: 'SYR',
    13208: 'SYR',
    13209: 'SYR',
    13210: 'SYR',
    13211: 'SYR',
    13212: 'SYR',
    13214: 'SYR',
    13215: 'SYR',
    13217: 'SYR',
    13218: 'SYR',
    13219: 'SYR',
    13220: 'SYR',
    13221: 'SYR',
    13224: 'SYR',
    13225: 'SYR',
    13235: 'SYR',
    13244: 'SYR',
    13250: 'SYR',
    13251: 'SYR',
    13252: 'SYR',
    13260: 'SYR',
    13261: 'SYR',
    13290: 'SYR',
    13301: 'SYR',
    13302: 'SYR',
    13303: 'SYR',
    13304: 'SYR',
    13305: 'SYR',
    13308: 'SYR',
    13309: 'SYR',
    13310: 'SYR',
    13312: 'SYR',
    13313: 'SYR',
    13314: 'SYR',
    13315: 'SYR',
    13316: 'SYR',
    13317: 'SYR',
    13318: 'SYR',
    13319: 'SYR',
    13320: 'SYR',
    13321: 'SYR',
    13322: 'SYR',
    13323: 'SYR',
    13324: 'SYR',
    13325: 'SYR',
    13326: 'SYR',
    13327: 'SYR',
    13328: 'SYR',
    13329: 'SYR',
    13331: 'SYR',
    13332: 'SYR',
    13333: 'SYR',
    13334: 'SYR',
    13335: 'SYR',
    13337: 'SYR',
    13338: 'SYR',
    13339: 'SYR',
    13340: 'SYR',
    13341: 'SYR',
    13342: 'SYR',
    13343: 'SYR',
    13345: 'SYR',
    13346: 'SYR',
    13348: 'SYR',
    13350: 'SYR',
    13352: 'SYR',
    13353: 'SYR',
    13354: 'SYR',
    13355: 'SYR',
    13357: 'SYR',
    13360: 'SYR',
    13361: 'SYR',
    13362: 'SYR',
    13363: 'SYR',
    13364: 'SYR',
    13365: 'SYR',
    13367: 'SYR',
    13368: 'SYR',
    13401: 'SYR',
    13402: 'SYR',
    13403: 'SYR',
    13404: 'SYR',
    13406: 'SYR',
    13407: 'SYR',
    13408: 'SYR',
    13409: 'SYR',
    13410: 'SYR',
    13411: 'SYR',
    13413: 'SYR',
    13415: 'SYR',
    13416: 'SYR',
    13417: 'SYR',
    13418: 'SYR',
    13420: 'SYR',
    13421: 'SYR',
    13424: 'SYR',
    13425: 'SYR',
    13426: 'SYR',
    13428: 'SYR',
    13431: 'SYR',
    13433: 'SYR',
    13435: 'SYR',
    13436: 'BTV',
    13437: 'SYR',
    13438: 'SYR',
    13439: 'SYR',
    13440: 'SYR',
    13441: 'SYR',
    13442: 'SYR',
    13449: 'SYR',
    13450: 'SYR',
    13452: 'SYR',
    13454: 'SYR',
    13455: 'SYR',
    13456: 'SYR',
    13457: 'SYR',
    13459: 'SYR',
    13460: 'SYR',
    13461: 'SYR',
    13464: 'SYR',
    13465: 'SYR',
    13468: 'SYR',
    13469: 'SYR',
    13470: 'SYR',
    13471: 'SYR',
    13472: 'SYR',
    13473: 'SYR',
    13475: 'SYR',
    13476: 'SYR',
    13477: 'SYR',
    13478: 'SYR',
    13479: 'SYR',
    13480: 'SYR',
    13482: 'SYR',
    13483: 'SYR',
    13484: 'SYR',
    13485: 'SYR',
    13486: 'SYR',
    13488: 'SYR',
    13489: 'SYR',
    13490: 'SYR',
    13491: 'SYR',
    13492: 'SYR',
    13493: 'SYR',
    13494: 'SYR',
    13495: 'SYR',
    13501: 'SYR',
    13502: 'SYR',
    13503: 'SYR',
    13504: 'SYR',
    13505: 'SYR',
    13599: 'SYR',
    13601: 'SYR',
    13602: 'SYR',
    13603: 'SYR',
    13605: 'SYR',
    13606: 'SYR',
    13607: 'SYR',
    13608: 'SYR',
    13611: 'SYR',
    13612: 'SYR',
    13613: 'SYR',
    13614: 'SYR',
    13615: 'SYR',
    13616: 'SYR',
    13617: 'SYR',
    13618: 'SYR',
    13619: 'SYR',
    13620: 'SYR',
    13621: 'SYR',
    13622: 'SYR',
    13623: 'SYR',
    13624: 'SYR',
    13625: 'SYR',
    13626: 'SYR',
    13627: 'SYR',
    13628: 'SYR',
    13630: 'SYR',
    13631: 'SYR',
    13632: 'SYR',
    13633: 'SYR',
    13634: 'SYR',
    13635: 'SYR',
    13636: 'SYR',
    13637: 'SYR',
    13638: 'SYR',
    13639: 'SYR',
    13640: 'SYR',
    13641: 'SYR',
    13642: 'SYR',
    13643: 'SYR',
    13645: 'SYR',
    13646: 'SYR',
    13647: 'SYR',
    13648: 'SYR',
    13649: 'SYR',
    13650: 'SYR',
    13651: 'SYR',
    13652: 'SYR',
    13654: 'SYR',
    13655: 'BTV',
    13656: 'SYR',
    13657: 'SYR',
    13658: 'SYR',
    13659: 'SYR',
    13660: 'SYR',
    13661: 'SYR',
    13662: 'SYR',
    13664: 'SYR',
    13665: 'SYR',
    13666: 'SYR',
    13667: 'SYR',
    13668: 'SYR',
    13669: 'SYR',
    13670: 'SYR',
    13671: 'SYR',
    13672: 'SYR',
    13673: 'SYR',
    13674: 'SYR',
    13675: 'SYR',
    13676: 'SYR',
    13677: 'SYR',
    13678: 'SYR',
    13679: 'SYR',
    13680: 'SYR',
    13681: 'SYR',
    13682: 'SYR',
    13683: 'BTV',
    13684: 'SYR',
    13685: 'SYR',
    13687: 'SYR',
    13688: 'SYR',
    13690: 'SYR',
    13691: 'SYR',
    13692: 'SYR',
    13693: 'SYR',
    13694: 'SYR',
    13695: 'SYR',
    13696: 'SYR',
    13697: 'SYR',
    13699: 'SYR',
    13730: 'SYR',
    13731: 'SYR',
    13732: 'SYR',
    13733: 'SYR',
    13734: 'SYR',
    13736: 'SYR',
    13737: 'SYR',
    13738: 'SYR',
    13739: 'SYR',
    13740: 'SYR',
    13743: 'SYR',
    13744: 'SYR',
    13745: 'SYR',
    13746: 'SYR',
    13747: 'SYR',
    13748: 'SYR',
    13749: 'SYR',
    13750: 'SYR',
    13751: 'SYR',
    13752: 'SYR',
    13753: 'SYR',
    13754: 'SYR',
    13755: 'SYR',
    13756: 'SYR',
    13757: 'SYR',
    13758: 'SYR',
    13760: 'SYR',
    13761: 'SYR',
    13762: 'SYR',
    13763: 'SYR',
    13774: 'SYR',
    13775: 'SYR',
    13776: 'SYR',
    13777: 'SYR',
    13778: 'SYR',
    13780: 'SYR',
    13782: 'SYR',
    13783: 'SYR',
    13784: 'SYR',
    13786: 'SYR',
    13787: 'SYR',
    13788: 'SYR',
    13790: 'SYR',
    13794: 'SYR',
    13795: 'SYR',
    13796: 'SYR',
    13797: 'SYR',
    13801: 'SYR',
    13802: 'SYR',
    13803: 'SYR',
    13804: 'SYR',
    13806: 'SYR',
    13807: 'SYR',
    13808: 'SYR',
    13809: 'SYR',
    13810: 'SYR',
    13811: 'SYR',
    13812: 'SYR',
    13813: 'SYR',
    13814: 'SYR',
    13815: 'SYR',
    13820: 'SYR',
    13825: 'SYR',
    13826: 'SYR',
    13827: 'SYR',
    13830: 'SYR',
    13832: 'SYR',
    13833: 'SYR',
    13834: 'SYR',
    13835: 'SYR',
    13837: 'SYR',
    13838: 'SYR',
    13839: 'SYR',
    13840: 'SYR',
    13841: 'SYR',
    13842: 'SYR',
    13843: 'SYR',
    13844: 'SYR',
    13845: 'SYR',
    13846: 'SYR',
    13847: 'SYR',
    13848: 'SYR',
    13849: 'SYR',
    13850: 'SYR',
    13851: 'SYR',
    13856: 'SYR',
    13859: 'SYR',
    13860: 'SYR',
    13861: 'SYR',
    13862: 'SYR',
    13863: 'SYR',
    13864: 'SYR',
    13865: 'SYR',
    13901: 'SYR',
    13902: 'SYR',
    13903: 'SYR',
    13904: 'SYR',
    13905: 'SYR',
    14001: 'BUF',
    14003: 'BUF',
    14004: 'BUF',
    14005: 'BUF',
    14006: 'BUF',
    14008: 'BUF',
    14009: 'BUF',
    14010: 'BUF',
    14011: 'BUF',
    14012: 'BUF',
    14013: 'BUF',
    14020: 'BUF',
    14021: 'BUF',
    14024: 'BUF',
    14025: 'BUF',
    14026: 'BUF',
    14027: 'BUF',
    14028: 'BUF',
    14029: 'BUF',
    14030: 'BUF',
    14031: 'BUF',
    14032: 'BUF',
    14033: 'BUF',
    14034: 'BUF',
    14035: 'BUF',
    14036: 'BUF',
    14037: 'BUF',
    14038: 'BUF',
    14039: 'BUF',
    14040: 'BUF',
    14041: 'BUF',
    14042: 'BUF',
    14043: 'BUF',
    14047: 'BUF',
    14048: 'BUF',
    14051: 'BUF',
    14052: 'BUF',
    14054: 'BUF',
    14055: 'BUF',
    14056: 'BUF',
    14057: 'BUF',
    14058: 'BUF',
    14059: 'BUF',
    14060: 'BUF',
    14061: 'BUF',
    14062: 'BUF',
    14063: 'BUF',
    14065: 'BUF',
    14066: 'BUF',
    14067: 'BUF',
    14068: 'BUF',
    14069: 'BUF',
    14070: 'BUF',
    14072: 'BUF',
    14075: 'BUF',
    14080: 'BUF',
    14081: 'BUF',
    14082: 'BUF',
    14083: 'BUF',
    14085: 'BUF',
    14086: 'BUF',
    14091: 'BUF',
    14092: 'BUF',
    14094: 'BUF',
    14095: 'BUF',
    14098: 'BUF',
    14101: 'BUF',
    14102: 'BUF',
    14103: 'BUF',
    14105: 'BUF',
    14107: 'BUF',
    14108: 'BUF',
    14109: 'BUF',
    14110: 'BUF',
    14111: 'BUF',
    14112: 'BUF',
    14113: 'BUF',
    14120: 'BUF',
    14125: 'BUF',
    14126: 'BUF',
    14127: 'BUF',
    14129: 'BUF',
    14130: 'BUF',
    14131: 'BUF',
    14132: 'BUF',
    14133: 'BUF',
    14134: 'BUF',
    14135: 'BUF',
    14136: 'BUF',
    14138: 'BUF',
    14139: 'BUF',
    14140: 'BUF',
    14141: 'BUF',
    14143: 'BUF',
    14144: 'BUF',
    14145: 'BUF',
    14150: 'BUF',
    14151: 'BUF',
    14166: 'BUF',
    14167: 'BUF',
    14168: 'BUF',
    14169: 'BUF',
    14170: 'BUF',
    14171: 'BUF',
    14172: 'BUF',
    14173: 'BUF',
    14174: 'BUF',
    14201: 'BUF',
    14202: 'BUF',
    14203: 'BUF',
    14204: 'BUF',
    14205: 'BUF',
    14206: 'BUF',
    14207: 'BUF',
    14208: 'BUF',
    14209: 'BUF',
    14210: 'BUF',
    14211: 'BUF',
    14212: 'BUF',
    14213: 'BUF',
    14214: 'BUF',
    14215: 'BUF',
    14216: 'BUF',
    14217: 'BUF',
    14218: 'BUF',
    14219: 'BUF',
    14220: 'BUF',
    14221: 'BUF',
    14222: 'BUF',
    14223: 'BUF',
    14224: 'BUF',
    14225: 'BUF',
    14226: 'BUF',
    14227: 'BUF',
    14228: 'BUF',
    14231: 'BUF',
    14233: 'BUF',
    14240: 'BUF',
    14241: 'BUF',
    14260: 'BUF',
    14261: 'BUF',
    14263: 'BUF',
    14264: 'BUF',
    14265: 'BUF',
    14267: 'BUF',
    14269: 'BUF',
    14270: 'BUF',
    14272: 'BUF',
    14273: 'BUF',
    14276: 'BUF',
    14280: 'BUF',
    14301: 'BUF',
    14302: 'BUF',
    14303: 'BUF',
    14304: 'BUF',
    14305: 'BUF',
    14410: 'BUF',
    14411: 'BUF',
    14413: 'BUF',
    14414: 'BUF',
    14415: 'BUF',
    14416: 'BUF',
    14418: 'BUF',
    14420: 'BUF',
    14422: 'BUF',
    14423: 'BUF',
    14424: 'BUF',
    14425: 'BUF',
    14427: 'BUF',
    14428: 'BUF',
    14429: 'BUF',
    14430: 'BUF',
    14432: 'BUF',
    14433: 'BUF',
    14435: 'BUF',
    14437: 'BUF',
    14441: 'BUF',
    14443: 'BUF',
    14445: 'BUF',
    14449: 'BUF',
    14450: 'BUF',
    14452: 'BUF',
    14453: 'BUF',
    14454: 'BUF',
    14456: 'BUF',
    14461: 'BUF',
    14462: 'BUF',
    14463: 'BUF',
    14464: 'BUF',
    14466: 'BUF',
    14467: 'BUF',
    14468: 'BUF',
    14469: 'BUF',
    14470: 'BUF',
    14471: 'BUF',
    14472: 'BUF',
    14475: 'BUF',
    14476: 'BUF',
    14477: 'BUF',
    14478: 'BUF',
    14479: 'BUF',
    14480: 'BUF',
    14481: 'BUF',
    14482: 'BUF',
    14485: 'BUF',
    14486: 'BUF',
    14487: 'BUF',
    14488: 'BUF',
    14489: 'BUF',
    14502: 'BUF',
    14504: 'BUF',
    14505: 'BUF',
    14506: 'BUF',
    14507: 'BUF',
    14508: 'BUF',
    14510: 'BUF',
    14511: 'BUF',
    14512: 'BUF',
    14513: 'BUF',
    14514: 'BUF',
    14515: 'BUF',
    14516: 'BUF',
    14517: 'BUF',
    14518: 'BUF',
    14519: 'BUF',
    14520: 'BUF',
    14521: 'SYR',
    14522: 'BUF',
    14525: 'BUF',
    14526: 'BUF',
    14527: 'BUF',
    14529: 'BUF',
    14530: 'BUF',
    14532: 'BUF',
    14533: 'BUF',
    14534: 'BUF',
    14536: 'BUF',
    14537: 'BUF',
    14538: 'BUF',
    14539: 'BUF',
    14541: 'SYR',
    14542: 'BUF',
    14543: 'BUF',
    14544: 'BUF',
    14545: 'BUF',
    14546: 'BUF',
    14547: 'BUF',
    14548: 'BUF',
    14549: 'BUF',
    14550: 'BUF',
    14551: 'BUF',
    14555: 'BUF',
    14556: 'BUF',
    14557: 'BUF',
    14558: 'BUF',
    14559: 'BUF',
    14560: 'BUF',
    14561: 'BUF',
    14563: 'BUF',
    14564: 'BUF',
    14568: 'BUF',
    14569: 'BUF',
    14571: 'BUF',
    14572: 'BUF',
    14580: 'BUF',
    14585: 'BUF',
    14586: 'BUF',
    14588: 'SYR',
    14589: 'BUF',
    14590: 'BUF',
    14591: 'BUF',
    14592: 'BUF',
    14601: 'BUF',
    14602: 'BUF',
    14603: 'BUF',
    14604: 'BUF',
    14605: 'BUF',
    14606: 'BUF',
    14607: 'BUF',
    14608: 'BUF',
    14609: 'BUF',
    14610: 'BUF',
    14611: 'BUF',
    14612: 'BUF',
    14613: 'BUF',
    14614: 'BUF',
    14615: 'BUF',
    14616: 'BUF',
    14617: 'BUF',
    14618: 'BUF',
    14619: 'BUF',
    14620: 'BUF',
    14621: 'BUF',
    14622: 'BUF',
    14623: 'BUF',
    14624: 'BUF',
    14625: 'BUF',
    14626: 'BUF',
    14627: 'BUF',
    14638: 'BUF',
    14639: 'BUF',
    14642: 'BUF',
    14643: 'BUF',
    14644: 'BUF',
    14645: 'BUF',
    14646: 'BUF',
    14647: 'BUF',
    14649: 'BUF',
    14650: 'BUF',
    14651: 'BUF',
    14652: 'BUF',
    14653: 'BUF',
    14660: 'BUF',
    14664: 'BUF',
    14673: 'BUF',
    14683: 'BUF',
    14692: 'BUF',
    14694: 'BUF',
    14701: 'BUF',
    14702: 'BUF',
    14703: 'BUF',
    14704: 'BUF',
    14706: 'BUF',
    14707: 'BUF',
    14708: 'BUF',
    14709: 'BUF',
    14710: 'BUF',
    14711: 'BUF',
    14712: 'BUF',
    14714: 'BUF',
    14715: 'BUF',
    14716: 'BUF',
    14717: 'BUF',
    14718: 'BUF',
    14719: 'BUF',
    14720: 'BUF',
    14721: 'BUF',
    14722: 'BUF',
    14723: 'BUF',
    14724: 'BUF',
    14726: 'BUF',
    14727: 'BUF',
    14728: 'BUF',
    14729: 'BUF',
    14730: 'BUF',
    14731: 'BUF',
    14732: 'BUF',
    14733: 'BUF',
    14735: 'BUF',
    14736: 'BUF',
    14737: 'BUF',
    14738: 'BUF',
    14739: 'BUF',
    14740: 'BUF',
    14741: 'BUF',
    14742: 'BUF',
    14743: 'BUF',
    14744: 'BUF',
    14745: 'BUF',
    14747: 'BUF',
    14748: 'BUF',
    14750: 'BUF',
    14751: 'BUF',
    14752: 'BUF',
    14753: 'BUF',
    14754: 'BUF',
    14755: 'BUF',
    14756: 'BUF',
    14757: 'BUF',
    14758: 'BUF',
    14760: 'BUF',
    14766: 'BUF',
    14767: 'BUF',
    14769: 'BUF',
    14770: 'BUF',
    14772: 'BUF',
    14774: 'BUF',
    14775: 'BUF',
    14777: 'BUF',
    14778: 'BUF',
    14779: 'BUF',
    14781: 'BUF',
    14782: 'BUF',
    14783: 'BUF',
    14784: 'BUF',
    14785: 'BUF',
    14786: 'BUF',
    14787: 'BUF',
    14788: 'BUF',
    14801: 'BUF',
    14802: 'BUF',
    14803: 'BUF',
    14804: 'BUF',
    14805: 'SYR',
    14806: 'BUF',
    14807: 'BUF',
    14808: 'BUF',
    14809: 'BUF',
    14810: 'BUF',
    14812: 'BUF',
    14813: 'BUF',
    14814: 'BUF',
    14815: 'BUF',
    14816: 'SYR',
    14817: 'BUF',
    14818: 'SYR',
    14819: 'BUF',
    14820: 'BUF',
    14821: 'BUF',
    14822: 'BUF',
    14823: 'BUF',
    14824: 'SYR',
    14825: 'SYR',
    14826: 'BUF',
    14827: 'BUF',
    14830: 'BUF',
    14831: 'BUF',
    14836: 'BUF',
    14837: 'BUF',
    14838: 'SYR',
    14839: 'BUF',
    14840: 'BUF',
    14841: 'SYR',
    14842: 'BUF',
    14843: 'BUF',
    14844: 'BUF',
    14845: 'BUF',
    14846: 'BUF',
    14847: 'SYR',
    14850: 'SYR',
    14851: 'SYR',
    14852: 'SYR',
    14853: 'SYR',
    14854: 'SYR',
    14855: 'BUF',
    14856: 'BUF',
    14857: 'BUF',
    14858: 'BUF',
    14859: 'SYR',
    14860: 'SYR',
    14861: 'SYR',
    14863: 'SYR',
    14864: 'BUF',
    14865: 'SYR',
    14867: 'SYR',
    14869: 'SYR',
    14870: 'BUF',
    14871: 'BUF',
    14872: 'BUF',
    14873: 'BUF',
    14874: 'BUF',
    14876: 'BUF',
    14877: 'BUF',
    14878: 'BUF',
    14879: 'BUF',
    14880: 'BUF',
    14881: 'BUF',
    14882: 'SYR',
    14883: 'SYR',
    14884: 'BUF',
    14885: 'BUF',
    14886: 'SYR',
    14887: 'BUF',
    14889: 'SYR',
    14891: 'BUF',
    14892: 'SYR',
    14893: 'BUF',
    14894: 'BUF',
    14895: 'BUF',
    14897: 'BUF',
    14898: 'BUF',
    14901: 'SYR',
    14902: 'BUF',
    14903: 'BUF',
    14904: 'BUF',
    14905: 'BUF',
    14925: 'PIT',
    15001: 'PIT',
    15003: 'PIT',
    15004: 'PIT',
    15005: 'PIT',
    15006: 'PIT',
    15007: 'PIT',
    15009: 'PIT',
    15010: 'PIT',
    15012: 'PIT',
    15014: 'PIT',
    15015: 'PIT',
    15017: 'PIT',
    15018: 'PIT',
    15019: 'PIT',
    15020: 'PIT',
    15021: 'PIT',
    15022: 'PIT',
    15024: 'PIT',
    15025: 'PIT',
    15026: 'PIT',
    15027: 'PIT',
    15028: 'PIT',
    15030: 'PIT',
    15031: 'PIT',
    15032: 'PIT',
    15033: 'PIT',
    15034: 'PIT',
    15035: 'PIT',
    15036: 'PIT',
    15037: 'PIT',
    15038: 'PIT',
    15042: 'PIT',
    15043: 'PIT',
    15044: 'PIT',
    15045: 'PIT',
    15046: 'PIT',
    15047: 'PIT',
    15049: 'PIT',
    15050: 'PIT',
    15051: 'PIT',
    15052: 'PIT',
    15053: 'PIT',
    15054: 'PIT',
    15055: 'PIT',
    15056: 'PIT',
    15057: 'PIT',
    15059: 'PIT',
    15060: 'PIT',
    15061: 'PIT',
    15062: 'PIT',
    15063: 'PIT',
    15064: 'PIT',
    15065: 'PIT',
    15066: 'PIT',
    15067: 'PIT',
    15068: 'PIT',
    15069: 'PIT',
    15071: 'PIT',
    15072: 'PIT',
    15074: 'PIT',
    15075: 'PIT',
    15076: 'PIT',
    15077: 'PIT',
    15078: 'PIT',
    15081: 'PIT',
    15082: 'PIT',
    15083: 'PIT',
    15084: 'PIT',
    15085: 'PIT',
    15086: 'PIT',
    15087: 'PIT',
    15088: 'PIT',
    15089: 'PIT',
    15090: 'PIT',
    15091: 'PIT',
    15095: 'PIT',
    15096: 'PIT',
    15101: 'PIT',
    15102: 'PIT',
    15104: 'PIT',
    15106: 'PIT',
    15108: 'PIT',
    15110: 'PIT',
    15112: 'PIT',
    15116: 'PIT',
    15120: 'PIT',
    15122: 'PIT',
    15123: 'PIT',
    15126: 'PIT',
    15127: 'PIT',
    15129: 'PIT',
    15130: 'PIT',
    15131: 'PIT',
    15132: 'PIT',
    15133: 'PIT',
    15134: 'PIT',
    15135: 'PIT',
    15136: 'PIT',
    15137: 'PIT',
    15139: 'PIT',
    15140: 'PIT',
    15142: 'PIT',
    15143: 'PIT',
    15144: 'PIT',
    15145: 'PIT',
    15146: 'PIT',
    15147: 'PIT',
    15148: 'PIT',
    15189: 'PIT',
    15201: 'PIT',
    15202: 'PIT',
    15203: 'PIT',
    15204: 'PIT',
    15205: 'PIT',
    15206: 'PIT',
    15207: 'PIT',
    15208: 'PIT',
    15209: 'PIT',
    15210: 'PIT',
    15211: 'PIT',
    15212: 'PIT',
    15213: 'PIT',
    15214: 'PIT',
    15215: 'PIT',
    15216: 'PIT',
    15217: 'PIT',
    15218: 'PIT',
    15219: 'PIT',
    15220: 'PIT',
    15221: 'PIT',
    15222: 'PIT',
    15223: 'PIT',
    15224: 'PIT',
    15225: 'PIT',
    15226: 'PIT',
    15227: 'PIT',
    15228: 'PIT',
    15229: 'PIT',
    15230: 'PIT',
    15231: 'PIT',
    15232: 'PIT',
    15233: 'PIT',
    15234: 'PIT',
    15235: 'PIT',
    15236: 'PIT',
    15237: 'PIT',
    15238: 'PIT',
    15239: 'PIT',
    15240: 'PIT',
    15241: 'PIT',
    15242: 'PIT',
    15243: 'PIT',
    15244: 'PIT',
    15250: 'PIT',
    15251: 'PIT',
    15252: 'PIT',
    15253: 'PIT',
    15254: 'PIT',
    15255: 'PIT',
    15257: 'PIT',
    15258: 'PIT',
    15259: 'PIT',
    15260: 'PIT',
    15261: 'PIT',
    15262: 'PIT',
    15263: 'PIT',
    15264: 'PIT',
    15265: 'PIT',
    15266: 'PIT',
    15267: 'PIT',
    15268: 'PIT',
    15270: 'PIT',
    15272: 'PIT',
    15273: 'PIT',
    15274: 'PIT',
    15275: 'PIT',
    15276: 'PIT',
    15277: 'PIT',
    15278: 'PIT',
    15279: 'PIT',
    15281: 'PIT',
    15282: 'PIT',
    15283: 'PIT',
    15285: 'PIT',
    15286: 'PIT',
    15288: 'PIT',
    15289: 'PIT',
    15290: 'PIT',
    15295: 'PIT',
    15301: 'PIT',
    15310: 'PIT',
    15311: 'PIT',
    15312: 'PIT',
    15313: 'PIT',
    15314: 'PIT',
    15315: 'PIT',
    15316: 'PIT',
    15317: 'PIT',
    15320: 'PIT',
    15321: 'PIT',
    15322: 'PIT',
    15323: 'PIT',
    15324: 'PIT',
    15325: 'PIT',
    15327: 'PIT',
    15329: 'PIT',
    15330: 'PIT',
    15331: 'PIT',
    15332: 'PIT',
    15333: 'PIT',
    15334: 'PIT',
    15336: 'PIT',
    15337: 'PIT',
    15338: 'PIT',
    15339: 'PIT',
    15340: 'PIT',
    15341: 'PIT',
    15342: 'PIT',
    15344: 'PIT',
    15345: 'PIT',
    15346: 'PIT',
    15347: 'PIT',
    15348: 'PIT',
    15349: 'PIT',
    15350: 'PIT',
    15351: 'PIT',
    15352: 'PIT',
    15353: 'PIT',
    15354: 'PIT',
    15357: 'PIT',
    15358: 'PIT',
    15359: 'PIT',
    15360: 'PIT',
    15361: 'PIT',
    15362: 'PIT',
    15363: 'PIT',
    15364: 'PIT',
    15365: 'PIT',
    15366: 'PIT',
    15367: 'PIT',
    15368: 'PIT',
    15370: 'PIT',
    15376: 'CRW',
    15377: 'PIT',
    15378: 'PIT',
    15379: 'PIT',
    15380: 'PIT',
    15401: 'PIT',
    15410: 'PIT',
    15411: 'PIT',
    15412: 'PIT',
    15413: 'PIT',
    15415: 'PIT',
    15416: 'PIT',
    15417: 'PIT',
    15419: 'PIT',
    15420: 'PIT',
    15421: 'PIT',
    15422: 'PIT',
    15423: 'PIT',
    15424: 'PIT',
    15425: 'PIT',
    15427: 'PIT',
    15428: 'PIT',
    15429: 'PIT',
    15430: 'PIT',
    15431: 'PIT',
    15432: 'PIT',
    15433: 'PIT',
    15434: 'PIT',
    15435: 'PIT',
    15436: 'PIT',
    15437: 'PIT',
    15438: 'PIT',
    15439: 'PIT',
    15440: 'PIT',
    15442: 'PIT',
    15443: 'PIT',
    15444: 'PIT',
    15445: 'PIT',
    15446: 'PIT',
    15447: 'PIT',
    15448: 'PIT',
    15449: 'PIT',
    15450: 'PIT',
    15451: 'PIT',
    15454: 'PIT',
    15455: 'PIT',
    15456: 'PIT',
    15458: 'PIT',
    15459: 'PIT',
    15460: 'PIT',
    15461: 'PIT',
    15462: 'PIT',
    15463: 'PIT',
    15464: 'PIT',
    15465: 'PIT',
    15466: 'PIT',
    15467: 'PIT',
    15468: 'PIT',
    15469: 'PIT',
    15470: 'PIT',
    15472: 'PIT',
    15473: 'PIT',
    15474: 'PIT',
    15475: 'PIT',
    15476: 'PIT',
    15477: 'PIT',
    15478: 'PIT',
    15479: 'PIT',
    15480: 'PIT',
    15482: 'PIT',
    15483: 'PIT',
    15484: 'PIT',
    15485: 'PIT',
    15486: 'PIT',
    15488: 'PIT',
    15489: 'PIT',
    15490: 'PIT',
    15492: 'PIT',
    15501: 'PIT',
    15502: 'PIT',
    15510: 'PIT',
    15520: 'PIT',
    15521: 'PIT',
    15522: 'PIT',
    15530: 'PIT',
    15531: 'PIT',
    15532: 'PIT',
    15533: 'PIT',
    15534: 'PIT',
    15535: 'PIT',
    15536: 'PIT',
    15537: 'PIT',
    15538: 'PIT',
    15539: 'PIT',
    15540: 'PIT',
    15541: 'PIT',
    15542: 'PIT',
    15544: 'PIT',
    15545: 'PIT',
    15546: 'PIT',
    15547: 'PIT',
    15548: 'PIT',
    15549: 'PIT',
    15550: 'PIT',
    15551: 'PIT',
    15552: 'PIT',
    15553: 'PIT',
    15554: 'PIT',
    15555: 'PIT',
    15557: 'PIT',
    15558: 'PIT',
    15559: 'PIT',
    15560: 'PIT',
    15561: 'PIT',
    15562: 'PIT',
    15563: 'PIT',
    15564: 'PIT',
    15565: 'PIT',
    15601: 'PIT',
    15605: 'PIT',
    15606: 'PIT',
    15610: 'PIT',
    15611: 'PIT',
    15612: 'PIT',
    15613: 'PIT',
    15615: 'PIT',
    15616: 'PIT',
    15617: 'PIT',
    15618: 'PIT',
    15619: 'PIT',
    15620: 'PIT',
    15621: 'PIT',
    15622: 'PIT',
    15623: 'PIT',
    15624: 'PIT',
    15625: 'PIT',
    15626: 'PIT',
    15627: 'PIT',
    15628: 'PIT',
    15629: 'PIT',
    15630: 'PIT',
    15631: 'PIT',
    15632: 'PIT',
    15633: 'PIT',
    15634: 'PIT',
    15635: 'PIT',
    15636: 'PIT',
    15637: 'PIT',
    15638: 'PIT',
    15639: 'PIT',
    15640: 'PIT',
    15641: 'PIT',
    15642: 'PIT',
    15644: 'PIT',
    15646: 'PIT',
    15647: 'PIT',
    15650: 'PIT',
    15655: 'PIT',
    15656: 'PIT',
    15658: 'PIT',
    15660: 'PIT',
    15661: 'PIT',
    15662: 'PIT',
    15663: 'PIT',
    15664: 'PIT',
    15665: 'PIT',
    15666: 'PIT',
    15668: 'PIT',
    15670: 'PIT',
    15671: 'PIT',
    15672: 'PIT',
    15673: 'PIT',
    15674: 'PIT',
    15675: 'PIT',
    15676: 'PIT',
    15677: 'PIT',
    15678: 'PIT',
    15679: 'PIT',
    15680: 'PIT',
    15681: 'PIT',
    15682: 'PIT',
    15683: 'PIT',
    15684: 'PIT',
    15685: 'PIT',
    15686: 'PIT',
    15687: 'PIT',
    15688: 'PIT',
    15689: 'PIT',
    15690: 'PIT',
    15691: 'PIT',
    15692: 'PIT',
    15693: 'PIT',
    15695: 'PIT',
    15696: 'PIT',
    15697: 'PIT',
    15698: 'PIT',
    15701: 'PIT',
    15705: 'PIT',
    15710: 'PIT',
    15711: 'PIT',
    15712: 'PIT',
    15713: 'PIT',
    15714: 'PIT',
    15715: 'PIT',
    15716: 'PIT',
    15717: 'PIT',
    15720: 'PIT',
    15721: 'PIT',
    15722: 'PIT',
    15723: 'PIT',
    15724: 'PIT',
    15725: 'PIT',
    15727: 'PIT',
    15728: 'PIT',
    15729: 'PIT',
    15730: 'PIT',
    15731: 'PIT',
    15732: 'PIT',
    15733: 'PIT',
    15734: 'PIT',
    15736: 'PIT',
    15737: 'PIT',
    15738: 'PIT',
    15739: 'PIT',
    15740: 'PIT',
    15741: 'PIT',
    15742: 'PIT',
    15744: 'PIT',
    15745: 'PIT',
    15746: 'PIT',
    15747: 'PIT',
    15748: 'PIT',
    15750: 'PIT',
    15751: 'PIT',
    15752: 'PIT',
    15753: 'PIT',
    15754: 'PIT',
    15756: 'PIT',
    15757: 'PIT',
    15758: 'PIT',
    15759: 'PIT',
    15760: 'PIT',
    15761: 'PIT',
    15762: 'PIT',
    15763: 'PIT',
    15764: 'PIT',
    15765: 'PIT',
    15767: 'PIT',
    15770: 'PIT',
    15771: 'PIT',
    15772: 'PIT',
    15773: 'PIT',
    15774: 'PIT',
    15775: 'PIT',
    15776: 'PIT',
    15777: 'PIT',
    15778: 'PIT',
    15779: 'PIT',
    15780: 'PIT',
    15781: 'PIT',
    15783: 'PIT',
    15784: 'PIT',
    15801: 'PIT',
    15821: 'ERI',
    15822: 'ERI',
    15823: 'PIT',
    15824: 'PIT',
    15825: 'PIT',
    15827: 'PIT',
    15828: 'ERI',
    15829: 'PIT',
    15831: 'ERI',
    15832: 'ERI',
    15834: 'ERI',
    15840: 'PIT',
    15841: 'PIT',
    15845: 'ERI',
    15846: 'ERI',
    15847: 'PIT',
    15848: 'PIT',
    15849: 'PIT',
    15851: 'PIT',
    15853: 'ERI',
    15856: 'PIT',
    15857: 'ERI',
    15860: 'ERI',
    15861: 'AVP',
    15863: 'PIT',
    15864: 'PIT',
    15865: 'PIT',
    15866: 'PIT',
    15868: 'ERI',
    15870: 'ERI',
    15901: 'PIT',
    15902: 'PIT',
    15904: 'PIT',
    15905: 'PIT',
    15906: 'PIT',
    15907: 'PIT',
    15909: 'PIT',
    15915: 'PIT',
    15920: 'PIT',
    15921: 'PIT',
    15922: 'PIT',
    15923: 'PIT',
    15924: 'PIT',
    15925: 'PIT',
    15926: 'PIT',
    15927: 'PIT',
    15928: 'PIT',
    15929: 'PIT',
    15930: 'PIT',
    15931: 'PIT',
    15934: 'PIT',
    15935: 'PIT',
    15936: 'PIT',
    15937: 'PIT',
    15938: 'PIT',
    15940: 'PIT',
    15942: 'PIT',
    15943: 'PIT',
    15944: 'PIT',
    15945: 'PIT',
    15946: 'PIT',
    15948: 'PIT',
    15949: 'PIT',
    15951: 'PIT',
    15952: 'PIT',
    15953: 'PIT',
    15954: 'PIT',
    15955: 'PIT',
    15956: 'PIT',
    15957: 'PIT',
    15958: 'PIT',
    15959: 'PIT',
    15960: 'PIT',
    15961: 'PIT',
    15962: 'PIT',
    15963: 'PIT',
    16001: 'PIT',
    16002: 'PIT',
    16003: 'PIT',
    16016: 'PIT',
    16017: 'PIT',
    16018: 'PIT',
    16020: 'PIT',
    16021: 'PIT',
    16022: 'PIT',
    16023: 'PIT',
    16024: 'PIT',
    16025: 'PIT',
    16027: 'PIT',
    16028: 'PIT',
    16029: 'PIT',
    16030: 'PIT',
    16033: 'PIT',
    16034: 'PIT',
    16035: 'PIT',
    16036: 'PIT',
    16037: 'PIT',
    16038: 'PIT',
    16039: 'PIT',
    16040: 'PIT',
    16041: 'PIT',
    16045: 'PIT',
    16046: 'PIT',
    16048: 'PIT',
    16049: 'PIT',
    16050: 'PIT',
    16051: 'PIT',
    16052: 'PIT',
    16053: 'PIT',
    16054: 'PIT',
    16055: 'PIT',
    16056: 'PIT',
    16057: 'PIT',
    16058: 'PIT',
    16059: 'PIT',
    16061: 'PIT',
    16063: 'PIT',
    16066: 'PIT',
    16101: 'PIT',
    16102: 'PIT',
    16103: 'PIT',
    16105: 'PIT',
    16107: 'PIT',
    16108: 'PIT',
    16110: 'ERI',
    16111: 'ERI',
    16112: 'PIT',
    16113: 'ERI',
    16114: 'ERI',
    16115: 'PIT',
    16116: 'PIT',
    16117: 'PIT',
    16120: 'PIT',
    16121: 'PIT',
    16123: 'PIT',
    16124: 'ERI',
    16125: 'ERI',
    16127: 'PIT',
    16130: 'ERI',
    16131: 'ERI',
    16132: 'PIT',
    16133: 'ERI',
    16134: 'ERI',
    16136: 'PIT',
    16137: 'PIT',
    16140: 'PIT',
    16141: 'PIT',
    16142: 'PIT',
    16143: 'PIT',
    16145: 'ERI',
    16146: 'PIT',
    16148: 'PIT',
    16150: 'PIT',
    16151: 'ERI',
    16153: 'ERI',
    16154: 'ERI',
    16155: 'PIT',
    16156: 'PIT',
    16157: 'PIT',
    16159: 'PIT',
    16160: 'PIT',
    16161: 'PIT',
    16172: 'PIT',
    16201: 'PIT',
    16210: 'PIT',
    16211: 'PIT',
    16212: 'PIT',
    16213: 'PIT',
    16214: 'PIT',
    16215: 'PIT',
    16216: 'ERI',
    16217: 'ERI',
    16218: 'PIT',
    16220: 'ERI',
    16221: 'PIT',
    16222: 'PIT',
    16223: 'PIT',
    16224: 'PIT',
    16225: 'PIT',
    16226: 'PIT',
    16228: 'PIT',
    16229: 'PIT',
    16230: 'PIT',
    16232: 'PIT',
    16233: 'ERI',
    16234: 'PIT',
    16235: 'ERI',
    16236: 'PIT',
    16238: 'PIT',
    16239: 'ERI',
    16240: 'PIT',
    16242: 'PIT',
    16244: 'PIT',
    16245: 'PIT',
    16246: 'PIT',
    16248: 'PIT',
    16249: 'PIT',
    16250: 'PIT',
    16253: 'PIT',
    16254: 'PIT',
    16255: 'PIT',
    16256: 'PIT',
    16257: 'ERI',
    16258: 'PIT',
    16259: 'PIT',
    16260: 'ERI',
    16261: 'PIT',
    16262: 'PIT',
    16263: 'PIT',
    16301: 'ERI',
    16311: 'ERI',
    16312: 'ERI',
    16313: 'ERI',
    16314: 'ERI',
    16316: 'ERI',
    16317: 'ERI',
    16319: 'ERI',
    16321: 'ERI',
    16322: 'ERI',
    16323: 'ERI',
    16326: 'ERI',
    16327: 'ERI',
    16328: 'ERI',
    16329: 'ERI',
    16331: 'PIT',
    16332: 'ERI',
    16333: 'ERI',
    16334: 'ERI',
    16335: 'ERI',
    16340: 'ERI',
    16341: 'ERI',
    16342: 'ERI',
    16343: 'ERI',
    16344: 'ERI',
    16345: 'ERI',
    16346: 'ERI',
    16347: 'ERI',
    16350: 'ERI',
    16351: 'ERI',
    16352: 'ERI',
    16353: 'ERI',
    16354: 'ERI',
    16360: 'ERI',
    16361: 'ERI',
    16362: 'ERI',
    16364: 'ERI',
    16365: 'ERI',
    16366: 'ERI',
    16367: 'ERI',
    16368: 'ERI',
    16369: 'ERI',
    16370: 'ERI',
    16371: 'ERI',
    16372: 'PIT',
    16373: 'PIT',
    16374: 'PIT',
    16375: 'PIT',
    16388: 'ERI',
    16401: 'ERI',
    16402: 'ERI',
    16403: 'ERI',
    16404: 'ERI',
    16405: 'ERI',
    16406: 'ERI',
    16407: 'ERI',
    16410: 'ERI',
    16411: 'ERI',
    16412: 'ERI',
    16413: 'ERI',
    16415: 'ERI',
    16416: 'ERI',
    16417: 'ERI',
    16420: 'ERI',
    16421: 'ERI',
    16422: 'ERI',
    16423: 'ERI',
    16424: 'ERI',
    16426: 'ERI',
    16427: 'ERI',
    16428: 'ERI',
    16430: 'ERI',
    16432: 'ERI',
    16433: 'ERI',
    16434: 'ERI',
    16435: 'ERI',
    16436: 'ERI',
    16438: 'ERI',
    16440: 'ERI',
    16441: 'ERI',
    16442: 'ERI',
    16443: 'ERI',
    16444: 'ERI',
    16475: 'ERI',
    16501: 'ERI',
    16502: 'ERI',
    16503: 'ERI',
    16504: 'ERI',
    16505: 'ERI',
    16506: 'ERI',
    16507: 'ERI',
    16508: 'ERI',
    16509: 'ERI',
    16510: 'ERI',
    16511: 'ERI',
    16512: 'ERI',
    16514: 'ERI',
    16515: 'ERI',
    16522: 'ERI',
    16530: 'ERI',
    16531: 'ERI',
    16532: 'ERI',
    16533: 'ERI',
    16534: 'ERI',
    16538: 'ERI',
    16541: 'ERI',
    16544: 'ERI',
    16546: 'ERI',
    16550: 'ERI',
    16553: 'ERI',
    16554: 'ERI',
    16563: 'ERI',
    16565: 'ERI',
    16601: 'PIT',
    16602: 'PIT',
    16603: 'PIT',
    16611: 'PIT',
    16613: 'PIT',
    16614: 'PIT',
    16616: 'PIT',
    16617: 'PIT',
    16619: 'PIT',
    16620: 'PIT',
    16621: 'PIT',
    16622: 'MDT',
    16623: 'MDT',
    16624: 'PIT',
    16625: 'PIT',
    16627: 'PIT',
    16629: 'PIT',
    16630: 'PIT',
    16631: 'PIT',
    16633: 'PIT',
    16634: 'PIT',
    16635: 'PIT',
    16636: 'PIT',
    16637: 'PIT',
    16638: 'PIT',
    16639: 'PIT',
    16640: 'PIT',
    16641: 'PIT',
    16644: 'PIT',
    16645: 'PIT',
    16646: 'PIT',
    16647: 'PIT',
    16648: 'PIT',
    16650: 'PIT',
    16651: 'PIT',
    16652: 'MDT',
    16654: 'MDT',
    16655: 'PIT',
    16656: 'PIT',
    16657: 'PIT',
    16659: 'PIT',
    16660: 'MDT',
    16661: 'PIT',
    16662: 'PIT',
    16663: 'PIT',
    16664: 'PIT',
    16665: 'PIT',
    16666: 'PIT',
    16667: 'PIT',
    16668: 'PIT',
    16669: 'MDT',
    16670: 'PIT',
    16671: 'PIT',
    16672: 'PIT',
    16673: 'PIT',
    16674: 'PIT',
    16675: 'PIT',
    16677: 'PIT',
    16678: 'PIT',
    16679: 'PIT',
    16680: 'PIT',
    16681: 'PIT',
    16682: 'PIT',
    16683: 'MDT',
    16684: 'PIT',
    16685: 'PIT',
    16686: 'PIT',
    16689: 'PIT',
    16691: 'PIT',
    16692: 'PIT',
    16693: 'PIT',
    16694: 'PIT',
    16695: 'PIT',
    16698: 'PIT',
    16699: 'PIT',
    16701: 'ERI',
    16720: 'AVP',
    16724: 'ERI',
    16725: 'ERI',
    16726: 'ERI',
    16727: 'ERI',
    16728: 'ERI',
    16729: 'ERI',
    16730: 'ERI',
    16731: 'ERI',
    16732: 'ERI',
    16733: 'ERI',
    16734: 'ERI',
    16735: 'ERI',
    16738: 'ERI',
    16740: 'ERI',
    16743: 'ERI',
    16744: 'ERI',
    16745: 'ERI',
    16746: 'ERI',
    16748: 'ERI',
    16749: 'ERI',
    16750: 'ERI',
    16801: 'MDT',
    16802: 'MDT',
    16803: 'MDT',
    16804: 'MDT',
    16805: 'MDT',
    16820: 'MDT',
    16821: 'PIT',
    16822: 'AVP',
    16823: 'MDT',
    16825: 'PIT',
    16826: 'MDT',
    16827: 'MDT',
    16828: 'MDT',
    16829: 'MDT',
    16830: 'PIT',
    16832: 'MDT',
    16833: 'PIT',
    16834: 'PIT',
    16835: 'MDT',
    16836: 'PIT',
    16837: 'PIT',
    16838: 'PIT',
    16839: 'PIT',
    16840: 'PIT',
    16841: 'MDT',
    16843: 'PIT',
    16844: 'MDT',
    16845: 'AVP',
    16847: 'PIT',
    16848: 'MDT',
    16849: 'PIT',
    16850: 'PIT',
    16851: 'MDT',
    16852: 'MDT',
    16853: 'MDT',
    16854: 'MDT',
    16855: 'PIT',
    16856: 'MDT',
    16858: 'PIT',
    16859: 'MDT',
    16860: 'PIT',
    16861: 'PIT',
    16863: 'PIT',
    16864: 'AVP',
    16865: 'MDT',
    16866: 'PIT',
    16868: 'MDT',
    16870: 'MDT',
    16871: 'AVP',
    16872: 'MDT',
    16873: 'PIT',
    16874: 'MDT',
    16875: 'MDT',
    16876: 'PIT',
    16877: 'PIT',
    16878: 'PIT',
    16879: 'PIT',
    16881: 'PIT',
    16882: 'MDT',
    16901: 'AVP',
    16910: 'AVP',
    16911: 'AVP',
    16912: 'AVP',
    16914: 'AVP',
    16915: 'AVP',
    16917: 'AVP',
    16918: 'AVP',
    16920: 'AVP',
    16921: 'AVP',
    16922: 'AVP',
    16923: 'AVP',
    16925: 'AVP',
    16926: 'AVP',
    16927: 'AVP',
    16928: 'AVP',
    16929: 'AVP',
    16930: 'AVP',
    16932: 'AVP',
    16933: 'AVP',
    16935: 'AVP',
    16936: 'AVP',
    16937: 'AVP',
    16938: 'AVP',
    16939: 'AVP',
    16940: 'AVP',
    16941: 'AVP',
    16942: 'AVP',
    16943: 'AVP',
    16945: 'AVP',
    16946: 'AVP',
    16947: 'AVP',
    16948: 'AVP',
    16950: 'AVP',
    17001: 'MDT',
    17002: 'MDT',
    17003: 'MDT',
    17004: 'MDT',
    17005: 'MDT',
    17006: 'MDT',
    17007: 'MDT',
    17008: 'MDT',
    17009: 'MDT',
    17010: 'MDT',
    17011: 'MDT',
    17012: 'MDT',
    17013: 'MDT',
    17014: 'MDT',
    17015: 'MDT',
    17016: 'MDT',
    17017: 'MDT',
    17018: 'MDT',
    17019: 'MDT',
    17020: 'MDT',
    17021: 'MDT',
    17022: 'MDT',
    17023: 'MDT',
    17024: 'MDT',
    17025: 'MDT',
    17026: 'MDT',
    17027: 'MDT',
    17028: 'MDT',
    17029: 'MDT',
    17030: 'MDT',
    17031: 'MDT',
    17032: 'MDT',
    17033: 'MDT',
    17034: 'MDT',
    17035: 'MDT',
    17036: 'MDT',
    17037: 'MDT',
    17038: 'MDT',
    17039: 'MDT',
    17040: 'MDT',
    17041: 'MDT',
    17042: 'MDT',
    17043: 'MDT',
    17044: 'MDT',
    17045: 'MDT',
    17046: 'MDT',
    17047: 'MDT',
    17048: 'MDT',
    17049: 'MDT',
    17050: 'MDT',
    17051: 'MDT',
    17052: 'MDT',
    17053: 'MDT',
    17054: 'MDT',
    17055: 'MDT',
    17056: 'MDT',
    17057: 'MDT',
    17058: 'MDT',
    17059: 'MDT',
    17060: 'MDT',
    17061: 'MDT',
    17062: 'MDT',
    17063: 'MDT',
    17064: 'MDT',
    17065: 'MDT',
    17066: 'MDT',
    17067: 'MDT',
    17068: 'MDT',
    17069: 'MDT',
    17070: 'MDT',
    17071: 'MDT',
    17072: 'MDT',
    17073: 'MDT',
    17074: 'MDT',
    17075: 'MDT',
    17076: 'MDT',
    17077: 'MDT',
    17078: 'MDT',
    17080: 'MDT',
    17081: 'MDT',
    17082: 'MDT',
    17083: 'MDT',
    17084: 'MDT',
    17085: 'MDT',
    17086: 'MDT',
    17087: 'MDT',
    17088: 'MDT',
    17089: 'MDT',
    17090: 'MDT',
    17091: 'MDT',
    17093: 'MDT',
    17094: 'MDT',
    17097: 'MDT',
    17098: 'MDT',
    17099: 'MDT',
    17101: 'MDT',
    17102: 'MDT',
    17103: 'MDT',
    17104: 'MDT',
    17105: 'MDT',
    17106: 'MDT',
    17107: 'MDT',
    17108: 'MDT',
    17109: 'MDT',
    17110: 'MDT',
    17111: 'MDT',
    17112: 'MDT',
    17113: 'MDT',
    17120: 'MDT',
    17121: 'MDT',
    17122: 'MDT',
    17123: 'MDT',
    17124: 'MDT',
    17125: 'MDT',
    17126: 'MDT',
    17127: 'MDT',
    17128: 'MDT',
    17129: 'MDT',
    17130: 'MDT',
    17140: 'MDT',
    17177: 'MDT',
    17201: 'MDT',
    17202: 'MDT',
    17210: 'MDT',
    17211: 'PIT',
    17212: 'MDT',
    17213: 'MDT',
    17214: 'MDT',
    17215: 'MDT',
    17217: 'MDT',
    17219: 'MDT',
    17220: 'MDT',
    17221: 'MDT',
    17222: 'MDT',
    17223: 'MDT',
    17224: 'MDT',
    17225: 'MDT',
    17228: 'MDT',
    17229: 'MDT',
    17231: 'MDT',
    17232: 'MDT',
    17233: 'MDT',
    17235: 'MDT',
    17236: 'MDT',
    17237: 'MDT',
    17238: 'PIT',
    17239: 'MDT',
    17240: 'MDT',
    17241: 'MDT',
    17243: 'MDT',
    17244: 'MDT',
    17246: 'MDT',
    17247: 'MDT',
    17249: 'MDT',
    17250: 'MDT',
    17251: 'MDT',
    17252: 'MDT',
    17253: 'MDT',
    17254: 'MDT',
    17255: 'MDT',
    17256: 'MDT',
    17257: 'MDT',
    17260: 'MDT',
    17261: 'MDT',
    17262: 'MDT',
    17263: 'MDT',
    17264: 'MDT',
    17265: 'MDT',
    17266: 'MDT',
    17267: 'PIT',
    17268: 'MDT',
    17270: 'MDT',
    17271: 'MDT',
    17272: 'MDT',
    17301: 'MDT',
    17302: 'MDT',
    17303: 'MDT',
    17304: 'MDT',
    17306: 'MDT',
    17307: 'MDT',
    17309: 'MDT',
    17310: 'MDT',
    17311: 'MDT',
    17312: 'MDT',
    17313: 'MDT',
    17314: 'MDT',
    17315: 'MDT',
    17316: 'MDT',
    17317: 'MDT',
    17318: 'MDT',
    17319: 'MDT',
    17320: 'MDT',
    17321: 'MDT',
    17322: 'MDT',
    17323: 'MDT',
    17324: 'MDT',
    17325: 'MDT',
    17326: 'MDT',
    17327: 'MDT',
    17329: 'MDT',
    17331: 'MDT',
    17332: 'MDT',
    17333: 'MDT',
    17334: 'MDT',
    17335: 'MDT',
    17337: 'MDT',
    17339: 'MDT',
    17340: 'MDT',
    17342: 'MDT',
    17343: 'MDT',
    17344: 'MDT',
    17345: 'MDT',
    17347: 'MDT',
    17349: 'MDT',
    17350: 'MDT',
    17352: 'MDT',
    17353: 'MDT',
    17354: 'MDT',
    17355: 'MDT',
    17356: 'MDT',
    17358: 'MDT',
    17360: 'MDT',
    17361: 'MDT',
    17362: 'MDT',
    17363: 'MDT',
    17364: 'MDT',
    17365: 'MDT',
    17366: 'MDT',
    17368: 'MDT',
    17370: 'MDT',
    17371: 'MDT',
    17372: 'MDT',
    17375: 'MDT',
    17401: 'MDT',
    17402: 'MDT',
    17403: 'MDT',
    17404: 'MDT',
    17405: 'MDT',
    17406: 'MDT',
    17407: 'MDT',
    17408: 'MDT',
    17415: 'MDT',
    17501: 'MDT',
    17502: 'MDT',
    17503: 'PHL',
    17504: 'MDT',
    17505: 'MDT',
    17506: 'PHL',
    17507: 'PHL',
    17508: 'MDT',
    17509: 'PHL',
    17512: 'MDT',
    17516: 'MDT',
    17517: 'PHL',
    17518: 'MDT',
    17519: 'PHL',
    17520: 'MDT',
    17521: 'MDT',
    17522: 'MDT',
    17527: 'PHL',
    17528: 'PHL',
    17529: 'PHL',
    17532: 'MDT',
    17533: 'MDT',
    17534: 'PHL',
    17535: 'PHL',
    17536: 'PHL',
    17537: 'MDT',
    17538: 'MDT',
    17540: 'MDT',
    17543: 'MDT',
    17545: 'MDT',
    17547: 'MDT',
    17549: 'MDT',
    17550: 'MDT',
    17551: 'MDT',
    17552: 'MDT',
    17554: 'MDT',
    17555: 'PHL',
    17557: 'PHL',
    17560: 'MDT',
    17562: 'PHL',
    17563: 'MDT',
    17564: 'MDT',
    17565: 'MDT',
    17566: 'MDT',
    17567: 'PHL',
    17568: 'MDT',
    17569: 'PHL',
    17570: 'MDT',
    17572: 'MDT',
    17573: 'MDT',
    17575: 'MDT',
    17576: 'MDT',
    17578: 'MDT',
    17579: 'MDT',
    17580: 'MDT',
    17581: 'PHL',
    17582: 'MDT',
    17583: 'MDT',
    17584: 'MDT',
    17585: 'MDT',
    17601: 'MDT',
    17602: 'MDT',
    17603: 'MDT',
    17604: 'MDT',
    17605: 'MDT',
    17606: 'MDT',
    17607: 'MDT',
    17608: 'MDT',
    17611: 'MDT',
    17622: 'MDT',
    17699: 'AVP',
    17701: 'AVP',
    17702: 'AVP',
    17703: 'AVP',
    17705: 'AVP',
    17720: 'AVP',
    17721: 'AVP',
    17722: 'AVP',
    17723: 'AVP',
    17724: 'AVP',
    17726: 'AVP',
    17727: 'AVP',
    17728: 'AVP',
    17729: 'AVP',
    17730: 'MDT',
    17731: 'AVP',
    17735: 'AVP',
    17737: 'AVP',
    17738: 'AVP',
    17739: 'AVP',
    17740: 'AVP',
    17742: 'AVP',
    17743: 'AVP',
    17744: 'AVP',
    17745: 'AVP',
    17747: 'MDT',
    17748: 'AVP',
    17749: 'MDT',
    17750: 'MDT',
    17751: 'MDT',
    17752: 'AVP',
    17754: 'AVP',
    17756: 'AVP',
    17758: 'AVP',
    17759: 'AVP',
    17760: 'AVP',
    17762: 'AVP',
    17763: 'AVP',
    17764: 'AVP',
    17765: 'AVP',
    17767: 'MDT',
    17768: 'AVP',
    17769: 'AVP',
    17771: 'AVP',
    17772: 'AVP',
    17773: 'MDT',
    17774: 'AVP',
    17776: 'AVP',
    17777: 'AVP',
    17778: 'AVP',
    17779: 'AVP',
    17801: 'MDT',
    17810: 'MDT',
    17812: 'MDT',
    17813: 'MDT',
    17814: 'AVP',
    17815: 'MDT',
    17820: 'MDT',
    17821: 'MDT',
    17822: 'MDT',
    17823: 'MDT',
    17824: 'MDT',
    17827: 'MDT',
    17828: 'MDT',
    17829: 'MDT',
    17830: 'MDT',
    17831: 'MDT',
    17832: 'MDT',
    17833: 'MDT',
    17834: 'MDT',
    17835: 'MDT',
    17836: 'MDT',
    17837: 'MDT',
    17839: 'MDT',
    17840: 'MDT',
    17841: 'MDT',
    17842: 'MDT',
    17843: 'MDT',
    17844: 'MDT',
    17845: 'MDT',
    17846: 'AVP',
    17847: 'MDT',
    17850: 'MDT',
    17851: 'MDT',
    17853: 'MDT',
    17855: 'MDT',
    17856: 'MDT',
    17857: 'MDT',
    17858: 'MDT',
    17859: 'MDT',
    17860: 'MDT',
    17861: 'MDT',
    17862: 'MDT',
    17864: 'MDT',
    17865: 'MDT',
    17866: 'MDT',
    17867: 'MDT',
    17868: 'MDT',
    17870: 'MDT',
    17872: 'MDT',
    17876: 'MDT',
    17877: 'MDT',
    17878: 'AVP',
    17880: 'MDT',
    17881: 'MDT',
    17882: 'MDT',
    17883: 'MDT',
    17884: 'MDT',
    17885: 'MDT',
    17886: 'MDT',
    17887: 'MDT',
    17888: 'MDT',
    17889: 'MDT',
    17901: 'MDT',
    17920: 'MDT',
    17921: 'MDT',
    17922: 'PHL',
    17923: 'MDT',
    17925: 'MDT',
    17929: 'MDT',
    17930: 'MDT',
    17931: 'MDT',
    17932: 'MDT',
    17933: 'MDT',
    17934: 'MDT',
    17935: 'MDT',
    17936: 'MDT',
    17938: 'MDT',
    17941: 'MDT',
    17942: 'MDT',
    17943: 'MDT',
    17944: 'MDT',
    17945: 'MDT',
    17946: 'MDT',
    17948: 'MDT',
    17949: 'MDT',
    17951: 'MDT',
    17952: 'MDT',
    17953: 'MDT',
    17954: 'MDT',
    17957: 'MDT',
    17959: 'MDT',
    17960: 'MDT',
    17961: 'MDT',
    17963: 'MDT',
    17964: 'MDT',
    17965: 'MDT',
    17966: 'MDT',
    17967: 'MDT',
    17968: 'MDT',
    17970: 'MDT',
    17972: 'MDT',
    17974: 'MDT',
    17976: 'MDT',
    17978: 'MDT',
    17979: 'MDT',
    17980: 'MDT',
    17981: 'MDT',
    17982: 'MDT',
    17983: 'MDT',
    17985: 'MDT',
    18001: 'PHL',
    18002: 'PHL',
    18003: 'PHL',
    18010: 'PHL',
    18011: 'PHL',
    18012: 'PHL',
    18013: 'PHL',
    18014: 'PHL',
    18015: 'PHL',
    18016: 'PHL',
    18017: 'PHL',
    18018: 'PHL',
    18020: 'PHL',
    18025: 'PHL',
    18030: 'PHL',
    18031: 'PHL',
    18032: 'PHL',
    18034: 'PHL',
    18035: 'PHL',
    18036: 'PHL',
    18037: 'PHL',
    18038: 'PHL',
    18039: 'PHL',
    18040: 'PHL',
    18041: 'PHL',
    18042: 'PHL',
    18043: 'PHL',
    18044: 'PHL',
    18045: 'PHL',
    18046: 'PHL',
    18049: 'PHL',
    18050: 'PHL',
    18051: 'PHL',
    18052: 'PHL',
    18053: 'PHL',
    18054: 'PHL',
    18055: 'PHL',
    18056: 'PHL',
    18058: 'PHL',
    18059: 'PHL',
    18060: 'PHL',
    18062: 'PHL',
    18063: 'PHL',
    18064: 'PHL',
    18065: 'PHL',
    18066: 'PHL',
    18067: 'PHL',
    18068: 'PHL',
    18069: 'PHL',
    18070: 'PHL',
    18071: 'PHL',
    18072: 'PHL',
    18073: 'PHL',
    18074: 'PHL',
    18076: 'PHL',
    18077: 'PHL',
    18078: 'PHL',
    18079: 'PHL',
    18080: 'PHL',
    18081: 'PHL',
    18083: 'PHL',
    18084: 'PHL',
    18085: 'PHL',
    18086: 'PHL',
    18087: 'PHL',
    18088: 'PHL',
    18091: 'PHL',
    18092: 'PHL',
    18098: 'PHL',
    18099: 'PHL',
    18101: 'MDT',
    18102: 'MDT',
    18103: 'MDT',
    18104: 'MDT',
    18105: 'MDT',
    18106: 'MDT',
    18109: 'MDT',
    18175: 'MDT',
    18195: 'MDT',
    18201: 'MDT',
    18202: 'MDT',
    18210: 'MDT',
    18211: 'MDT',
    18212: 'MDT',
    18214: 'MDT',
    18216: 'MDT',
    18218: 'MDT',
    18219: 'MDT',
    18220: 'MDT',
    18221: 'MDT',
    18222: 'MDT',
    18223: 'MDT',
    18224: 'MDT',
    18225: 'MDT',
    18229: 'MDT',
    18230: 'MDT',
    18231: 'MDT',
    18232: 'MDT',
    18234: 'MDT',
    18235: 'MDT',
    18237: 'MDT',
    18239: 'MDT',
    18240: 'MDT',
    18241: 'MDT',
    18242: 'MDT',
    18244: 'MDT',
    18245: 'MDT',
    18246: 'MDT',
    18247: 'MDT',
    18248: 'MDT',
    18249: 'MDT',
    18250: 'MDT',
    18251: 'MDT',
    18252: 'MDT',
    18254: 'MDT',
    18255: 'MDT',
    18256: 'MDT',
    18301: 'MDT',
    18302: 'MDT',
    18320: 'MDT',
    18321: 'MDT',
    18322: 'MDT',
    18323: 'AVP',
    18324: 'AVP',
    18325: 'AVP',
    18326: 'AVP',
    18327: 'AVP',
    18328: 'AVP',
    18330: 'AVP',
    18331: 'AVP',
    18332: 'AVP',
    18333: 'AVP',
    18334: 'AVP',
    18335: 'AVP',
    18336: 'AVP',
    18337: 'AVP',
    18340: 'AVP',
    18341: 'AVP',
    18342: 'AVP',
    18343: 'AVP',
    18344: 'AVP',
    18346: 'AVP',
    18347: 'AVP',
    18348: 'AVP',
    18349: 'AVP',
    18350: 'AVP',
    18351: 'AVP',
    18352: 'AVP',
    18353: 'AVP',
    18354: 'AVP',
    18355: 'AVP',
    18356: 'AVP',
    18357: 'AVP',
    18360: 'AVP',
    18370: 'AVP',
    18371: 'AVP',
    18372: 'AVP',
    18373: 'AVP',
    18403: 'AVP',
    18405: 'AVP',
    18407: 'AVP',
    18410: 'AVP',
    18411: 'AVP',
    18413: 'AVP',
    18414: 'AVP',
    18415: 'AVP',
    18416: 'AVP',
    18417: 'AVP',
    18419: 'AVP',
    18420: 'AVP',
    18421: 'AVP',
    18424: 'AVP',
    18425: 'AVP',
    18426: 'AVP',
    18427: 'AVP',
    18428: 'AVP',
    18430: 'AVP',
    18431: 'AVP',
    18433: 'AVP',
    18434: 'AVP',
    18435: 'AVP',
    18436: 'AVP',
    18437: 'AVP',
    18438: 'AVP',
    18439: 'AVP',
    18440: 'AVP',
    18441: 'AVP',
    18443: 'AVP',
    18444: 'AVP',
    18445: 'AVP',
    18446: 'AVP',
    18447: 'AVP',
    18448: 'AVP',
    18449: 'AVP',
    18451: 'AVP',
    18452: 'AVP',
    18453: 'AVP',
    18454: 'AVP',
    18455: 'AVP',
    18456: 'AVP',
    18457: 'AVP',
    18458: 'AVP',
    18459: 'AVP',
    18460: 'AVP',
    18461: 'AVP',
    18462: 'AVP',
    18463: 'AVP',
    18464: 'AVP',
    18465: 'AVP',
    18466: 'AVP',
    18469: 'AVP',
    18470: 'AVP',
    18471: 'AVP',
    18472: 'AVP',
    18473: 'AVP',
    18501: 'AVP',
    18502: 'AVP',
    18503: 'AVP',
    18504: 'AVP',
    18505: 'AVP',
    18507: 'AVP',
    18508: 'AVP',
    18509: 'AVP',
    18510: 'AVP',
    18512: 'AVP',
    18514: 'AVP',
    18515: 'AVP',
    18517: 'AVP',
    18518: 'AVP',
    18519: 'AVP',
    18522: 'AVP',
    18540: 'AVP',
    18577: 'AVP',
    18601: 'MDT',
    18602: 'AVP',
    18603: 'MDT',
    18610: 'MDT',
    18611: 'AVP',
    18612: 'AVP',
    18614: 'AVP',
    18615: 'AVP',
    18616: 'AVP',
    18617: 'AVP',
    18618: 'AVP',
    18619: 'AVP',
    18621: 'AVP',
    18622: 'AVP',
    18623: 'AVP',
    18624: 'AVP',
    18625: 'AVP',
    18626: 'AVP',
    18627: 'AVP',
    18628: 'AVP',
    18629: 'AVP',
    18630: 'AVP',
    18631: 'MDT',
    18632: 'AVP',
    18634: 'AVP',
    18635: 'MDT',
    18636: 'AVP',
    18640: 'AVP',
    18641: 'AVP',
    18642: 'AVP',
    18643: 'AVP',
    18644: 'AVP',
    18651: 'AVP',
    18653: 'AVP',
    18654: 'AVP',
    18655: 'AVP',
    18656: 'AVP',
    18657: 'AVP',
    18660: 'AVP',
    18661: 'AVP',
    18690: 'AVP',
    18701: 'AVP',
    18702: 'AVP',
    18703: 'AVP',
    18704: 'AVP',
    18705: 'AVP',
    18706: 'AVP',
    18707: 'AVP',
    18708: 'AVP',
    18709: 'AVP',
    18710: 'AVP',
    18711: 'AVP',
    18762: 'AVP',
    18764: 'AVP',
    18765: 'AVP',
    18766: 'AVP',
    18767: 'AVP',
    18769: 'AVP',
    18773: 'AVP',
    18801: 'AVP',
    18810: 'AVP',
    18812: 'AVP',
    18813: 'AVP',
    18814: 'AVP',
    18815: 'AVP',
    18816: 'AVP',
    18817: 'AVP',
    18818: 'AVP',
    18820: 'AVP',
    18821: 'AVP',
    18822: 'AVP',
    18823: 'AVP',
    18824: 'AVP',
    18825: 'AVP',
    18826: 'AVP',
    18827: 'AVP',
    18828: 'AVP',
    18829: 'AVP',
    18830: 'AVP',
    18831: 'AVP',
    18832: 'AVP',
    18833: 'AVP',
    18834: 'AVP',
    18837: 'AVP',
    18840: 'AVP',
    18842: 'AVP',
    18843: 'AVP',
    18844: 'AVP',
    18845: 'AVP',
    18846: 'AVP',
    18847: 'AVP',
    18848: 'AVP',
    18850: 'AVP',
    18851: 'AVP',
    18853: 'AVP',
    18854: 'AVP',
    18901: 'PHL',
    18902: 'PHL',
    18910: 'PHL',
    18911: 'PHL',
    18912: 'PHL',
    18913: 'PHL',
    18914: 'PHL',
    18915: 'PHL',
    18916: 'PHL',
    18917: 'PHL',
    18918: 'PHL',
    18920: 'PHL',
    18921: 'PHL',
    18922: 'PHL',
    18923: 'PHL',
    18924: 'PHL',
    18925: 'PHL',
    18926: 'PHL',
    18927: 'PHL',
    18928: 'PHL',
    18929: 'PHL',
    18930: 'PHL',
    18931: 'PHL',
    18932: 'PHL',
    18933: 'PHL',
    18934: 'PHL',
    18935: 'PHL',
    18936: 'PHL',
    18938: 'PHL',
    18940: 'PHL',
    18942: 'PHL',
    18943: 'PHL',
    18944: 'PHL',
    18946: 'PHL',
    18947: 'PHL',
    18949: 'PHL',
    18950: 'PHL',
    18951: 'PHL',
    18953: 'PHL',
    18954: 'PHL',
    18955: 'PHL',
    18956: 'PHL',
    18957: 'PHL',
    18958: 'PHL',
    18960: 'PHL',
    18962: 'PHL',
    18963: 'PHL',
    18964: 'PHL',
    18966: 'PHL',
    18968: 'PHL',
    18969: 'PHL',
    18970: 'PHL',
    18971: 'PHL',
    18972: 'PHL',
    18974: 'PHL',
    18976: 'PHL',
    18977: 'PHL',
    18979: 'PHL',
    18980: 'PHL',
    18981: 'PHL',
    18991: 'PHL',
    19001: 'PHL',
    19002: 'PHL',
    19003: 'PHL',
    19004: 'PHL',
    19006: 'PHL',
    19007: 'PHL',
    19008: 'PHL',
    19009: 'PHL',
    19010: 'PHL',
    19012: 'PHL',
    19013: 'PHL',
    19014: 'PHL',
    19015: 'PHL',
    19016: 'PHL',
    19017: 'PHL',
    19018: 'PHL',
    19019: 'PHL',
    19020: 'PHL',
    19021: 'PHL',
    19022: 'PHL',
    19023: 'PHL',
    19025: 'PHL',
    19026: 'PHL',
    19027: 'PHL',
    19028: 'PHL',
    19029: 'PHL',
    19030: 'PHL',
    19031: 'PHL',
    19032: 'PHL',
    19033: 'PHL',
    19034: 'PHL',
    19035: 'PHL',
    19036: 'PHL',
    19037: 'PHL',
    19038: 'PHL',
    19039: 'PHL',
    19040: 'PHL',
    19041: 'PHL',
    19043: 'PHL',
    19044: 'PHL',
    19046: 'PHL',
    19047: 'PHL',
    19048: 'PHL',
    19049: 'PHL',
    19050: 'PHL',
    19052: 'PHL',
    19053: 'PHL',
    19054: 'PHL',
    19055: 'PHL',
    19056: 'PHL',
    19057: 'PHL',
    19058: 'PHL',
    19060: 'PHL',
    19061: 'PHL',
    19063: 'PHL',
    19064: 'PHL',
    19065: 'PHL',
    19066: 'PHL',
    19067: 'PHL',
    19070: 'PHL',
    19072: 'PHL',
    19073: 'PHL',
    19074: 'PHL',
    19075: 'PHL',
    19076: 'PHL',
    19078: 'PHL',
    19079: 'PHL',
    19080: 'PHL',
    19081: 'PHL',
    19082: 'PHL',
    19083: 'PHL',
    19085: 'PHL',
    19086: 'PHL',
    19087: 'PHL',
    19088: 'PHL',
    19089: 'PHL',
    19090: 'PHL',
    19091: 'PHL',
    19092: 'PHL',
    19093: 'PHL',
    19094: 'PHL',
    19095: 'PHL',
    19096: 'PHL',
    19098: 'PHL',
    19099: 'PHL',
    19101: 'PHL',
    19102: 'PHL',
    19103: 'PHL',
    19104: 'PHL',
    19105: 'PHL',
    19106: 'PHL',
    19107: 'PHL',
    19108: 'PHL',
    19109: 'PHL',
    19110: 'PHL',
    19111: 'PHL',
    19112: 'PHL',
    19113: 'PHL',
    19114: 'PHL',
    19115: 'PHL',
    19116: 'PHL',
    19118: 'PHL',
    19119: 'PHL',
    19120: 'PHL',
    19121: 'PHL',
    19122: 'PHL',
    19123: 'PHL',
    19124: 'PHL',
    19125: 'PHL',
    19126: 'PHL',
    19127: 'PHL',
    19128: 'PHL',
    19129: 'PHL',
    19130: 'PHL',
    19131: 'PHL',
    19132: 'PHL',
    19133: 'PHL',
    19134: 'PHL',
    19135: 'PHL',
    19136: 'PHL',
    19137: 'PHL',
    19138: 'PHL',
    19139: 'PHL',
    19140: 'PHL',
    19141: 'PHL',
    19142: 'PHL',
    19143: 'PHL',
    19144: 'PHL',
    19145: 'PHL',
    19146: 'PHL',
    19147: 'PHL',
    19148: 'PHL',
    19149: 'PHL',
    19150: 'PHL',
    19151: 'PHL',
    19152: 'PHL',
    19153: 'PHL',
    19154: 'PHL',
    19155: 'PHL',
    19160: 'PHL',
    19161: 'PHL',
    19162: 'PHL',
    19170: 'PHL',
    19171: 'PHL',
    19172: 'PHL',
    19173: 'PHL',
    19175: 'PHL',
    19176: 'PHL',
    19177: 'PHL',
    19178: 'PHL',
    19179: 'PHL',
    19181: 'PHL',
    19182: 'PHL',
    19183: 'PHL',
    19184: 'PHL',
    19185: 'PHL',
    19187: 'PHL',
    19188: 'PHL',
    19190: 'PHL',
    19191: 'PHL',
    19192: 'PHL',
    19193: 'PHL',
    19194: 'PHL',
    19195: 'PHL',
    19196: 'PHL',
    19197: 'PHL',
    19244: 'PHL',
    19255: 'PHL',
    19301: 'PHL',
    19310: 'PHL',
    19311: 'PHL',
    19312: 'PHL',
    19316: 'PHL',
    19317: 'PHL',
    19318: 'PHL',
    19319: 'PHL',
    19320: 'PHL',
    19330: 'PHL',
    19331: 'PHL',
    19333: 'PHL',
    19335: 'PHL',
    19339: 'PHL',
    19340: 'PHL',
    19341: 'PHL',
    19342: 'PHL',
    19343: 'PHL',
    19344: 'PHL',
    19345: 'PHL',
    19346: 'PHL',
    19347: 'PHL',
    19348: 'PHL',
    19350: 'PHL',
    19351: 'PHL',
    19352: 'PHL',
    19353: 'PHL',
    19354: 'PHL',
    19355: 'PHL',
    19357: 'PHL',
    19358: 'PHL',
    19360: 'PHL',
    19362: 'PHL',
    19363: 'PHL',
    19365: 'PHL',
    19366: 'PHL',
    19367: 'PHL',
    19369: 'PHL',
    19371: 'PHL',
    19372: 'PHL',
    19373: 'PHL',
    19374: 'PHL',
    19375: 'PHL',
    19376: 'PHL',
    19380: 'PHL',
    19381: 'PHL',
    19382: 'PHL',
    19383: 'PHL',
    19388: 'PHL',
    19390: 'PHL',
    19395: 'PHL',
    19397: 'PHL',
    19398: 'PHL',
    19399: 'PHL',
    19401: 'PHL',
    19403: 'PHL',
    19404: 'PHL',
    19405: 'PHL',
    19406: 'PHL',
    19407: 'PHL',
    19408: 'PHL',
    19409: 'PHL',
    19415: 'PHL',
    19420: 'PHL',
    19421: 'PHL',
    19422: 'PHL',
    19423: 'PHL',
    19424: 'PHL',
    19425: 'PHL',
    19426: 'PHL',
    19428: 'PHL',
    19429: 'PHL',
    19430: 'PHL',
    19432: 'PHL',
    19435: 'PHL',
    19436: 'PHL',
    19437: 'PHL',
    19438: 'PHL',
    19440: 'PHL',
    19441: 'PHL',
    19442: 'PHL',
    19443: 'PHL',
    19444: 'PHL',
    19446: 'PHL',
    19450: 'PHL',
    19451: 'PHL',
    19453: 'PHL',
    19454: 'PHL',
    19455: 'PHL',
    19456: 'PHL',
    19457: 'PHL',
    19460: 'PHL',
    19462: 'PHL',
    19464: 'PHL',
    19465: 'PHL',
    19468: 'PHL',
    19470: 'PHL',
    19472: 'PHL',
    19473: 'PHL',
    19474: 'PHL',
    19475: 'PHL',
    19477: 'PHL',
    19478: 'PHL',
    19480: 'PHL',
    19481: 'PHL',
    19482: 'PHL',
    19483: 'PHL',
    19484: 'PHL',
    19485: 'PHL',
    19486: 'PHL',
    19487: 'PHL',
    19488: 'PHL',
    19489: 'PHL',
    19490: 'PHL',
    19492: 'PHL',
    19493: 'PHL',
    19494: 'PHL',
    19495: 'PHL',
    19496: 'PHL',
    19501: 'PHL',
    19503: 'PHL',
    19504: 'PHL',
    19505: 'PHL',
    19506: 'PHL',
    19507: 'MDT',
    19508: 'PHL',
    19510: 'PHL',
    19511: 'PHL',
    19512: 'PHL',
    19516: 'PHL',
    19518: 'PHL',
    19519: 'PHL',
    19520: 'PHL',
    19522: 'PHL',
    19523: 'PHL',
    19525: 'PHL',
    19526: 'PHL',
    19529: 'PHL',
    19530: 'PHL',
    19533: 'PHL',
    19534: 'PHL',
    19535: 'PHL',
    19536: 'PHL',
    19538: 'PHL',
    19539: 'PHL',
    19540: 'PHL',
    19541: 'PHL',
    19542: 'PHL',
    19543: 'PHL',
    19544: 'MDT',
    19545: 'PHL',
    19547: 'PHL',
    19548: 'PHL',
    19549: 'PHL',
    19550: 'MDT',
    19551: 'PHL',
    19554: 'PHL',
    19555: 'PHL',
    19559: 'MDT',
    19560: 'PHL',
    19562: 'PHL',
    19564: 'PHL',
    19565: 'PHL',
    19567: 'MDT',
    19601: 'PHL',
    19602: 'PHL',
    19603: 'PHL',
    19604: 'PHL',
    19605: 'PHL',
    19606: 'PHL',
    19607: 'PHL',
    19608: 'PHL',
    19609: 'PHL',
    19610: 'PHL',
    19611: 'PHL',
    19612: 'PHL',
    19640: 'PHL',
    19701: 'IAD',
    19702: 'IAD',
    19703: 'PHL',
    19706: 'IAD',
    19707: 'PHL',
    19708: 'IAD',
    19709: 'IAD',
    19710: 'PHL',
    19711: 'PHL',
    19712: 'IAD',
    19713: 'PHL',
    19714: 'IAD',
    19715: 'IAD',
    19716: 'IAD',
    19717: 'IAD',
    19718: 'PHL',
    19720: 'PHL',
    19721: 'PHL',
    19725: 'IAD',
    19726: 'IAD',
    19730: 'IAD',
    19731: 'IAD',
    19732: 'PHL',
    19733: 'IAD',
    19734: 'IAD',
    19735: 'PHL',
    19736: 'PHL',
    19801: 'PHL',
    19802: 'PHL',
    19803: 'PHL',
    19804: 'PHL',
    19805: 'PHL',
    19806: 'PHL',
    19807: 'PHL',
    19808: 'PHL',
    19809: 'PHL',
    19810: 'PHL',
    19850: 'PHL',
    19880: 'PHL',
    19884: 'PHL',
    19885: 'PHL',
    19886: 'PHL',
    19887: 'PHL',
    19889: 'PHL',
    19890: 'PHL',
    19891: 'PHL',
    19892: 'PHL',
    19893: 'PHL',
    19894: 'PHL',
    19895: 'PHL',
    19896: 'PHL',
    19897: 'PHL',
    19898: 'PHL',
    19899: 'PHL',
    19901: 'IAD',
    19902: 'IAD',
    19903: 'IAD',
    19904: 'IAD',
    19905: 'IAD',
    19906: 'IAD',
    19930: 'IAD',
    19931: 'IAD',
    19933: 'IAD',
    19934: 'IAD',
    19936: 'IAD',
    19938: 'IAD',
    19939: 'IAD',
    19940: 'IAD',
    19941: 'IAD',
    19943: 'IAD',
    19944: 'IAD',
    19945: 'IAD',
    19946: 'IAD',
    19947: 'IAD',
    19950: 'IAD',
    19951: 'IAD',
    19952: 'IAD',
    19953: 'IAD',
    19954: 'IAD',
    19955: 'IAD',
    19956: 'IAD',
    19958: 'IAD',
    19960: 'IAD',
    19961: 'IAD',
    19962: 'IAD',
    19963: 'IAD',
    19964: 'IAD',
    19966: 'IAD',
    19967: 'IAD',
    19968: 'IAD',
    19969: 'IAD',
    19970: 'IAD',
    19971: 'IAD',
    19973: 'IAD',
    19975: 'IAD',
    19977: 'IAD',
    19979: 'IAD',
    19980: 'IAD',
    20001: 'IAD',
    20002: 'IAD',
    20003: 'IAD',
    20004: 'IAD',
    20005: 'IAD',
    20006: 'IAD',
    20007: 'IAD',
    20008: 'IAD',
    20009: 'IAD',
    20010: 'IAD',
    20011: 'IAD',
    20012: 'IAD',
    20013: 'IAD',
    20015: 'IAD',
    20016: 'IAD',
    20017: 'IAD',
    20018: 'IAD',
    20019: 'IAD',
    20020: 'IAD',
    20022: 'IAD',
    20023: 'IAD',
    20024: 'IAD',
    20026: 'IAD',
    20027: 'IAD',
    20029: 'IAD',
    20030: 'IAD',
    20032: 'IAD',
    20033: 'IAD',
    20035: 'IAD',
    20036: 'IAD',
    20037: 'IAD',
    20038: 'IAD',
    20039: 'IAD',
    20040: 'IAD',
    20041: 'IAD',
    20042: 'IAD',
    20043: 'IAD',
    20044: 'IAD',
    20045: 'IAD',
    20046: 'IAD',
    20047: 'IAD',
    20049: 'IAD',
    20050: 'IAD',
    20051: 'IAD',
    20052: 'IAD',
    20053: 'IAD',
    20055: 'IAD',
    20056: 'IAD',
    20057: 'IAD',
    20058: 'IAD',
    20059: 'IAD',
    20060: 'IAD',
    20061: 'IAD',
    20062: 'IAD',
    20063: 'IAD',
    20064: 'IAD',
    20065: 'IAD',
    20066: 'IAD',
    20067: 'IAD',
    20068: 'IAD',
    20069: 'IAD',
    20070: 'IAD',
    20071: 'IAD',
    20073: 'IAD',
    20074: 'IAD',
    20075: 'IAD',
    20076: 'IAD',
    20077: 'IAD',
    20078: 'IAD',
    20080: 'IAD',
    20081: 'IAD',
    20082: 'IAD',
    20088: 'IAD',
    20090: 'IAD',
    20091: 'IAD',
    20097: 'IAD',
    20098: 'IAD',
    20099: 'IAD',
    20101: 'IAD',
    20102: 'IAD',
    20103: 'IAD',
    20104: 'IAD',
    20105: 'IAD',
    20106: 'IAD',
    20107: 'IAD',
    20108: 'IAD',
    20109: 'IAD',
    20110: 'IAD',
    20111: 'IAD',
    20112: 'IAD',
    20113: 'IAD',
    20115: 'IAD',
    20116: 'IAD',
    20117: 'IAD',
    20118: 'IAD',
    20119: 'IAD',
    20120: 'IAD',
    20121: 'IAD',
    20122: 'IAD',
    20124: 'IAD',
    20128: 'IAD',
    20129: 'IAD',
    20130: 'IAD',
    20131: 'IAD',
    20132: 'IAD',
    20134: 'IAD',
    20135: 'IAD',
    20136: 'IAD',
    20137: 'IAD',
    20138: 'IAD',
    20139: 'IAD',
    20140: 'IAD',
    20141: 'IAD',
    20142: 'IAD',
    20143: 'IAD',
    20144: 'IAD',
    20146: 'IAD',
    20147: 'IAD',
    20148: 'IAD',
    20149: 'IAD',
    20151: 'IAD',
    20152: 'IAD',
    20153: 'IAD',
    20155: 'IAD',
    20156: 'IAD',
    20158: 'IAD',
    20159: 'IAD',
    20160: 'IAD',
    20163: 'IAD',
    20164: 'IAD',
    20165: 'IAD',
    20166: 'IAD',
    20167: 'IAD',
    20168: 'IAD',
    20169: 'IAD',
    20170: 'IAD',
    20171: 'IAD',
    20172: 'IAD',
    20175: 'IAD',
    20176: 'IAD',
    20177: 'IAD',
    20178: 'IAD',
    20180: 'IAD',
    20181: 'IAD',
    20182: 'IAD',
    20184: 'IAD',
    20185: 'IAD',
    20186: 'IAD',
    20187: 'IAD',
    20188: 'IAD',
    20189: 'IAD',
    20190: 'IAD',
    20191: 'IAD',
    20192: 'IAD',
    20193: 'IAD',
    20194: 'IAD',
    20195: 'IAD',
    20196: 'IAD',
    20197: 'IAD',
    20198: 'IAD',
    20199: 'IAD',
    20201: 'IAD',
    20202: 'IAD',
    20203: 'IAD',
    20204: 'IAD',
    20206: 'IAD',
    20207: 'IAD',
    20208: 'IAD',
    20210: 'IAD',
    20211: 'IAD',
    20212: 'IAD',
    20213: 'IAD',
    20214: 'IAD',
    20215: 'IAD',
    20216: 'IAD',
    20217: 'IAD',
    20218: 'IAD',
    20219: 'IAD',
    20220: 'IAD',
    20221: 'IAD',
    20222: 'IAD',
    20223: 'IAD',
    20224: 'IAD',
    20226: 'IAD',
    20227: 'IAD',
    20228: 'IAD',
    20229: 'IAD',
    20230: 'IAD',
    20231: 'IAD',
    20232: 'IAD',
    20233: 'IAD',
    20235: 'IAD',
    20237: 'IAD',
    20238: 'IAD',
    20239: 'IAD',
    20240: 'IAD',
    20241: 'IAD',
    20242: 'IAD',
    20244: 'IAD',
    20245: 'IAD',
    20250: 'IAD',
    20251: 'IAD',
    20252: 'IAD',
    20254: 'IAD',
    20260: 'IAD',
    20261: 'IAD',
    20262: 'IAD',
    20265: 'IAD',
    20266: 'IAD',
    20268: 'IAD',
    20270: 'IAD',
    20277: 'IAD',
    20289: 'IAD',
    20299: 'IAD',
    20301: 'IAD',
    20303: 'IAD',
    20306: 'IAD',
    20307: 'IAD',
    20310: 'IAD',
    20314: 'IAD',
    20315: 'IAD',
    20317: 'IAD',
    20318: 'IAD',
    20319: 'IAD',
    20330: 'IAD',
    20332: 'IAD',
    20336: 'IAD',
    20337: 'IAD',
    20338: 'IAD',
    20340: 'IAD',
    20350: 'IAD',
    20355: 'IAD',
    20370: 'IAD',
    20372: 'IAD',
    20373: 'IAD',
    20374: 'IAD',
    20375: 'IAD',
    20376: 'IAD',
    20380: 'IAD',
    20388: 'IAD',
    20389: 'IAD',
    20390: 'IAD',
    20391: 'IAD',
    20392: 'IAD',
    20393: 'IAD',
    20394: 'IAD',
    20395: 'IAD',
    20398: 'IAD',
    20401: 'IAD',
    20402: 'IAD',
    20403: 'IAD',
    20404: 'IAD',
    20405: 'IAD',
    20406: 'IAD',
    20407: 'IAD',
    20408: 'IAD',
    20409: 'IAD',
    20410: 'IAD',
    20411: 'IAD',
    20412: 'IAD',
    20413: 'IAD',
    20414: 'IAD',
    20415: 'IAD',
    20416: 'IAD',
    20417: 'IAD',
    20418: 'IAD',
    20419: 'IAD',
    20420: 'IAD',
    20421: 'IAD',
    20422: 'IAD',
    20423: 'IAD',
    20424: 'IAD',
    20425: 'IAD',
    20426: 'IAD',
    20427: 'IAD',
    20428: 'IAD',
    20429: 'IAD',
    20431: 'IAD',
    20433: 'IAD',
    20434: 'IAD',
    20435: 'IAD',
    20436: 'IAD',
    20437: 'IAD',
    20439: 'IAD',
    20440: 'IAD',
    20441: 'IAD',
    20442: 'IAD',
    20444: 'IAD',
    20447: 'IAD',
    20451: 'IAD',
    20453: 'IAD',
    20456: 'IAD',
    20460: 'IAD',
    20463: 'IAD',
    20468: 'IAD',
    20469: 'IAD',
    20470: 'IAD',
    20472: 'IAD',
    20500: 'IAD',
    20501: 'IAD',
    20502: 'IAD',
    20503: 'IAD',
    20504: 'IAD',
    20505: 'IAD',
    20506: 'IAD',
    20507: 'IAD',
    20508: 'IAD',
    20509: 'IAD',
    20510: 'IAD',
    20511: 'IAD',
    20515: 'IAD',
    20520: 'IAD',
    20521: 'IAD',
    20522: 'IAD',
    20523: 'IAD',
    20524: 'IAD',
    20525: 'IAD',
    20526: 'IAD',
    20527: 'IAD',
    20528: 'IAD',
    20529: 'IAD',
    20530: 'IAD',
    20531: 'IAD',
    20532: 'IAD',
    20533: 'IAD',
    20534: 'IAD',
    20535: 'IAD',
    20536: 'IAD',
    20537: 'IAD',
    20538: 'IAD',
    20539: 'IAD',
    20540: 'IAD',
    20541: 'IAD',
    20542: 'IAD',
    20543: 'IAD',
    20544: 'IAD',
    20546: 'IAD',
    20547: 'IAD',
    20548: 'IAD',
    20549: 'IAD',
    20550: 'IAD',
    20551: 'IAD',
    20552: 'IAD',
    20553: 'IAD',
    20554: 'IAD',
    20555: 'IAD',
    20557: 'IAD',
    20558: 'IAD',
    20559: 'IAD',
    20560: 'IAD',
    20565: 'IAD',
    20566: 'IAD',
    20570: 'IAD',
    20571: 'IAD',
    20572: 'IAD',
    20573: 'IAD',
    20575: 'IAD',
    20576: 'IAD',
    20577: 'IAD',
    20578: 'IAD',
    20579: 'IAD',
    20580: 'IAD',
    20581: 'IAD',
    20585: 'IAD',
    20586: 'IAD',
    20588: 'IAD',
    20590: 'IAD',
    20591: 'IAD',
    20593: 'IAD',
    20594: 'IAD',
    20597: 'IAD',
    20598: 'IAD',
    20599: 'IAD',
    20601: 'IAD',
    20602: 'IAD',
    20603: 'IAD',
    20604: 'IAD',
    20606: 'IAD',
    20607: 'IAD',
    20608: 'IAD',
    20609: 'IAD',
    20610: 'IAD',
    20611: 'IAD',
    20612: 'IAD',
    20613: 'IAD',
    20615: 'IAD',
    20616: 'IAD',
    20617: 'IAD',
    20618: 'IAD',
    20619: 'IAD',
    20620: 'IAD',
    20621: 'IAD',
    20622: 'IAD',
    20623: 'IAD',
    20624: 'IAD',
    20625: 'IAD',
    20626: 'IAD',
    20627: 'IAD',
    20628: 'IAD',
    20629: 'IAD',
    20630: 'RIC',
    20632: 'IAD',
    20634: 'IAD',
    20635: 'IAD',
    20636: 'IAD',
    20637: 'IAD',
    20639: 'IAD',
    20640: 'IAD',
    20643: 'IAD',
    20645: 'IAD',
    20646: 'IAD',
    20650: 'IAD',
    20653: 'IAD',
    20656: 'IAD',
    20657: 'IAD',
    20658: 'IAD',
    20659: 'IAD',
    20660: 'IAD',
    20661: 'IAD',
    20662: 'IAD',
    20664: 'IAD',
    20667: 'IAD',
    20670: 'IAD',
    20674: 'RIC',
    20675: 'IAD',
    20676: 'IAD',
    20677: 'IAD',
    20678: 'IAD',
    20680: 'IAD',
    20682: 'IAD',
    20684: 'IAD',
    20685: 'IAD',
    20686: 'IAD',
    20687: 'IAD',
    20688: 'IAD',
    20689: 'IAD',
    20690: 'RIC',
    20692: 'RIC',
    20693: 'IAD',
    20695: 'IAD',
    20697: 'IAD',
    20701: 'IAD',
    20703: 'IAD',
    20704: 'IAD',
    20705: 'IAD',
    20706: 'IAD',
    20707: 'IAD',
    20708: 'IAD',
    20709: 'IAD',
    20710: 'IAD',
    20711: 'IAD',
    20712: 'IAD',
    20714: 'IAD',
    20715: 'IAD',
    20716: 'IAD',
    20717: 'IAD',
    20718: 'IAD',
    20719: 'IAD',
    20720: 'IAD',
    20721: 'IAD',
    20722: 'IAD',
    20723: 'IAD',
    20724: 'IAD',
    20725: 'IAD',
    20726: 'IAD',
    20731: 'IAD',
    20732: 'IAD',
    20733: 'IAD',
    20735: 'IAD',
    20736: 'IAD',
    20737: 'IAD',
    20738: 'IAD',
    20740: 'IAD',
    20741: 'IAD',
    20742: 'IAD',
    20743: 'IAD',
    20744: 'IAD',
    20745: 'IAD',
    20746: 'IAD',
    20747: 'IAD',
    20748: 'IAD',
    20749: 'IAD',
    20750: 'IAD',
    20751: 'IAD',
    20752: 'IAD',
    20753: 'IAD',
    20754: 'IAD',
    20755: 'IAD',
    20757: 'IAD',
    20758: 'IAD',
    20759: 'IAD',
    20762: 'IAD',
    20763: 'IAD',
    20764: 'IAD',
    20765: 'IAD',
    20768: 'IAD',
    20769: 'IAD',
    20770: 'IAD',
    20771: 'IAD',
    20772: 'IAD',
    20773: 'IAD',
    20774: 'IAD',
    20775: 'IAD',
    20776: 'IAD',
    20777: 'IAD',
    20778: 'IAD',
    20779: 'IAD',
    20781: 'IAD',
    20782: 'IAD',
    20783: 'IAD',
    20784: 'IAD',
    20785: 'IAD',
    20787: 'IAD',
    20788: 'IAD',
    20790: 'IAD',
    20791: 'IAD',
    20792: 'IAD',
    20794: 'IAD',
    20797: 'IAD',
    20799: 'IAD',
    20810: 'IAD',
    20811: 'IAD',
    20812: 'IAD',
    20813: 'IAD',
    20814: 'IAD',
    20815: 'IAD',
    20816: 'IAD',
    20817: 'IAD',
    20818: 'IAD',
    20824: 'IAD',
    20825: 'IAD',
    20827: 'IAD',
    20830: 'IAD',
    20832: 'IAD',
    20833: 'IAD',
    20837: 'IAD',
    20838: 'IAD',
    20839: 'IAD',
    20841: 'IAD',
    20842: 'IAD',
    20847: 'IAD',
    20848: 'IAD',
    20849: 'IAD',
    20850: 'IAD',
    20851: 'IAD',
    20852: 'IAD',
    20853: 'IAD',
    20854: 'IAD',
    20855: 'IAD',
    20857: 'IAD',
    20859: 'IAD',
    20860: 'IAD',
    20861: 'IAD',
    20862: 'IAD',
    20866: 'IAD',
    20868: 'IAD',
    20871: 'IAD',
    20872: 'IAD',
    20874: 'IAD',
    20875: 'IAD',
    20876: 'IAD',
    20877: 'IAD',
    20878: 'IAD',
    20879: 'IAD',
    20880: 'IAD',
    20882: 'IAD',
    20883: 'IAD',
    20884: 'IAD',
    20885: 'IAD',
    20886: 'IAD',
    20889: 'IAD',
    20891: 'IAD',
    20892: 'IAD',
    20894: 'IAD',
    20895: 'IAD',
    20896: 'IAD',
    20897: 'IAD',
    20898: 'IAD',
    20899: 'IAD',
    20901: 'IAD',
    20902: 'IAD',
    20903: 'IAD',
    20904: 'IAD',
    20905: 'IAD',
    20906: 'IAD',
    20907: 'IAD',
    20908: 'IAD',
    20910: 'IAD',
    20911: 'IAD',
    20912: 'IAD',
    20913: 'IAD',
    20914: 'IAD',
    20915: 'IAD',
    20916: 'IAD',
    20918: 'IAD',
    20993: 'IAD',
    20997: 'IAD',
    21001: 'IAD',
    21005: 'IAD',
    21009: 'IAD',
    21010: 'IAD',
    21012: 'IAD',
    21013: 'IAD',
    21014: 'IAD',
    21015: 'IAD',
    21017: 'IAD',
    21018: 'IAD',
    21020: 'IAD',
    21022: 'IAD',
    21023: 'IAD',
    21027: 'IAD',
    21028: 'IAD',
    21029: 'IAD',
    21030: 'IAD',
    21031: 'IAD',
    21032: 'IAD',
    21034: 'IAD',
    21035: 'IAD',
    21036: 'IAD',
    21037: 'IAD',
    21040: 'IAD',
    21041: 'IAD',
    21042: 'IAD',
    21043: 'IAD',
    21044: 'IAD',
    21045: 'IAD',
    21046: 'IAD',
    21047: 'IAD',
    21048: 'IAD',
    21050: 'IAD',
    21051: 'IAD',
    21052: 'IAD',
    21053: 'IAD',
    21054: 'IAD',
    21056: 'IAD',
    21057: 'IAD',
    21060: 'IAD',
    21061: 'IAD',
    21062: 'IAD',
    21065: 'IAD',
    21071: 'IAD',
    21074: 'IAD',
    21075: 'IAD',
    21076: 'IAD',
    21077: 'IAD',
    21078: 'IAD',
    21082: 'IAD',
    21084: 'IAD',
    21085: 'IAD',
    21087: 'IAD',
    21088: 'IAD',
    21090: 'IAD',
    21092: 'IAD',
    21093: 'IAD',
    21094: 'IAD',
    21098: 'IAD',
    21102: 'IAD',
    21104: 'IAD',
    21105: 'IAD',
    21106: 'IAD',
    21108: 'IAD',
    21111: 'IAD',
    21113: 'IAD',
    21114: 'IAD',
    21117: 'IAD',
    21120: 'IAD',
    21122: 'IAD',
    21123: 'IAD',
    21128: 'IAD',
    21130: 'IAD',
    21131: 'IAD',
    21132: 'IAD',
    21133: 'IAD',
    21136: 'IAD',
    21139: 'IAD',
    21140: 'IAD',
    21144: 'IAD',
    21146: 'IAD',
    21150: 'IAD',
    21152: 'IAD',
    21153: 'IAD',
    21154: 'IAD',
    21155: 'IAD',
    21156: 'IAD',
    21157: 'IAD',
    21158: 'IAD',
    21160: 'IAD',
    21161: 'IAD',
    21162: 'IAD',
    21163: 'IAD',
    21201: 'IAD',
    21202: 'IAD',
    21203: 'IAD',
    21204: 'IAD',
    21205: 'IAD',
    21206: 'IAD',
    21207: 'IAD',
    21208: 'IAD',
    21209: 'IAD',
    21210: 'IAD',
    21211: 'IAD',
    21212: 'IAD',
    21213: 'IAD',
    21214: 'IAD',
    21215: 'IAD',
    21216: 'IAD',
    21217: 'IAD',
    21218: 'IAD',
    21219: 'IAD',
    21220: 'IAD',
    21221: 'IAD',
    21222: 'IAD',
    21223: 'IAD',
    21224: 'IAD',
    21225: 'IAD',
    21226: 'IAD',
    21227: 'IAD',
    21228: 'IAD',
    21229: 'IAD',
    21230: 'IAD',
    21231: 'IAD',
    21233: 'IAD',
    21234: 'IAD',
    21235: 'IAD',
    21236: 'IAD',
    21237: 'IAD',
    21239: 'IAD',
    21240: 'IAD',
    21241: 'IAD',
    21244: 'IAD',
    21250: 'IAD',
    21251: 'IAD',
    21252: 'IAD',
    21260: 'IAD',
    21261: 'IAD',
    21263: 'IAD',
    21264: 'IAD',
    21265: 'IAD',
    21268: 'IAD',
    21270: 'IAD',
    21273: 'IAD',
    21274: 'IAD',
    21275: 'IAD',
    21278: 'IAD',
    21279: 'IAD',
    21280: 'IAD',
    21281: 'IAD',
    21282: 'IAD',
    21283: 'IAD',
    21284: 'IAD',
    21285: 'IAD',
    21286: 'IAD',
    21287: 'IAD',
    21288: 'IAD',
    21289: 'IAD',
    21290: 'IAD',
    21297: 'IAD',
    21298: 'IAD',
    21401: 'IAD',
    21402: 'IAD',
    21403: 'IAD',
    21404: 'IAD',
    21405: 'IAD',
    21409: 'IAD',
    21411: 'IAD',
    21412: 'IAD',
    21501: 'CKB',
    21502: 'CKB',
    21503: 'CKB',
    21504: 'CKB',
    21505: 'CKB',
    21520: 'CKB',
    21521: 'CKB',
    21522: 'CKB',
    21523: 'CKB',
    21524: 'CKB',
    21528: 'CKB',
    21529: 'IAD',
    21530: 'CKB',
    21531: 'CKB',
    21532: 'CKB',
    21536: 'CKB',
    21538: 'CKB',
    21539: 'CKB',
    21540: 'CKB',
    21541: 'CKB',
    21542: 'CKB',
    21543: 'CKB',
    21545: 'CKB',
    21550: 'CKB',
    21555: 'CKB',
    21556: 'CKB',
    21557: 'CKB',
    21560: 'CKB',
    21561: 'CKB',
    21562: 'CKB',
    21601: 'IAD',
    21606: 'IAD',
    21607: 'IAD',
    21609: 'IAD',
    21610: 'IAD',
    21612: 'IAD',
    21613: 'IAD',
    21617: 'IAD',
    21619: 'IAD',
    21620: 'IAD',
    21622: 'IAD',
    21623: 'IAD',
    21624: 'IAD',
    21625: 'IAD',
    21626: 'IAD',
    21627: 'IAD',
    21628: 'IAD',
    21629: 'IAD',
    21631: 'IAD',
    21632: 'IAD',
    21634: 'IAD',
    21635: 'IAD',
    21636: 'IAD',
    21638: 'IAD',
    21639: 'IAD',
    21640: 'IAD',
    21641: 'IAD',
    21643: 'IAD',
    21644: 'IAD',
    21645: 'IAD',
    21647: 'IAD',
    21648: 'IAD',
    21649: 'IAD',
    21650: 'IAD',
    21651: 'IAD',
    21652: 'IAD',
    21653: 'IAD',
    21654: 'IAD',
    21655: 'IAD',
    21656: 'IAD',
    21657: 'IAD',
    21658: 'IAD',
    21659: 'IAD',
    21660: 'IAD',
    21661: 'IAD',
    21662: 'IAD',
    21663: 'IAD',
    21664: 'IAD',
    21665: 'IAD',
    21666: 'IAD',
    21667: 'IAD',
    21668: 'IAD',
    21669: 'IAD',
    21670: 'IAD',
    21671: 'IAD',
    21672: 'IAD',
    21673: 'IAD',
    21675: 'IAD',
    21676: 'IAD',
    21677: 'IAD',
    21678: 'IAD',
    21679: 'IAD',
    21681: 'IAD',
    21682: 'IAD',
    21683: 'IAD',
    21684: 'IAD',
    21685: 'IAD',
    21686: 'IAD',
    21687: 'IAD',
    21688: 'IAD',
    21690: 'IAD',
    21701: 'IAD',
    21702: 'IAD',
    21703: 'IAD',
    21704: 'IAD',
    21705: 'IAD',
    21709: 'IAD',
    21710: 'IAD',
    21711: 'IAD',
    21713: 'IAD',
    21714: 'IAD',
    21715: 'IAD',
    21716: 'IAD',
    21717: 'IAD',
    21718: 'IAD',
    21719: 'IAD',
    21720: 'IAD',
    21721: 'IAD',
    21722: 'IAD',
    21723: 'IAD',
    21727: 'IAD',
    21733: 'IAD',
    21734: 'IAD',
    21737: 'IAD',
    21738: 'IAD',
    21740: 'IAD',
    21741: 'IAD',
    21742: 'IAD',
    21746: 'IAD',
    21747: 'IAD',
    21748: 'IAD',
    21749: 'IAD',
    21750: 'IAD',
    21754: 'IAD',
    21755: 'IAD',
    21756: 'IAD',
    21757: 'IAD',
    21758: 'IAD',
    21759: 'IAD',
    21762: 'IAD',
    21765: 'IAD',
    21766: 'CKB',
    21767: 'IAD',
    21769: 'IAD',
    21770: 'IAD',
    21771: 'IAD',
    21773: 'IAD',
    21774: 'IAD',
    21775: 'IAD',
    21776: 'IAD',
    21777: 'IAD',
    21778: 'IAD',
    21779: 'IAD',
    21780: 'IAD',
    21781: 'IAD',
    21782: 'IAD',
    21783: 'IAD',
    21784: 'IAD',
    21787: 'IAD',
    21788: 'IAD',
    21790: 'IAD',
    21791: 'IAD',
    21792: 'IAD',
    21793: 'IAD',
    21794: 'IAD',
    21795: 'IAD',
    21797: 'IAD',
    21798: 'IAD',
    21801: 'IAD',
    21802: 'IAD',
    21803: 'IAD',
    21804: 'IAD',
    21810: 'IAD',
    21811: 'IAD',
    21813: 'IAD',
    21814: 'IAD',
    21817: 'IAD',
    21821: 'IAD',
    21822: 'IAD',
    21824: 'IAD',
    21826: 'IAD',
    21829: 'IAD',
    21830: 'IAD',
    21835: 'IAD',
    21836: 'IAD',
    21837: 'IAD',
    21838: 'IAD',
    21840: 'IAD',
    21841: 'IAD',
    21842: 'IAD',
    21843: 'IAD',
    21849: 'IAD',
    21850: 'IAD',
    21851: 'IAD',
    21852: 'IAD',
    21853: 'IAD',
    21856: 'IAD',
    21857: 'IAD',
    21861: 'IAD',
    21862: 'IAD',
    21863: 'IAD',
    21864: 'IAD',
    21865: 'IAD',
    21866: 'IAD',
    21867: 'IAD',
    21869: 'IAD',
    21870: 'IAD',
    21871: 'IAD',
    21872: 'IAD',
    21874: 'IAD',
    21875: 'IAD',
    21890: 'IAD',
    21901: 'IAD',
    21902: 'IAD',
    21903: 'IAD',
    21904: 'IAD',
    21911: 'IAD',
    21912: 'IAD',
    21913: 'IAD',
    21914: 'IAD',
    21915: 'IAD',
    21916: 'IAD',
    21917: 'IAD',
    21918: 'IAD',
    21919: 'IAD',
    21920: 'IAD',
    21921: 'IAD',
    21922: 'IAD',
    21930: 'IAD',
    22003: 'IAD',
    22009: 'IAD',
    22015: 'IAD',
    22025: 'IAD',
    22026: 'IAD',
    22027: 'IAD',
    22030: 'IAD',
    22031: 'IAD',
    22032: 'IAD',
    22033: 'IAD',
    22034: 'IAD',
    22035: 'IAD',
    22036: 'IAD',
    22037: 'IAD',
    22038: 'IAD',
    22039: 'IAD',
    22040: 'IAD',
    22041: 'IAD',
    22042: 'IAD',
    22043: 'IAD',
    22044: 'IAD',
    22046: 'IAD',
    22047: 'IAD',
    22060: 'IAD',
    22066: 'IAD',
    22067: 'IAD',
    22079: 'IAD',
    22081: 'IAD',
    22082: 'IAD',
    22092: 'IAD',
    22093: 'IAD',
    22095: 'IAD',
    22096: 'IAD',
    22101: 'IAD',
    22102: 'IAD',
    22103: 'IAD',
    22106: 'IAD',
    22107: 'IAD',
    22108: 'IAD',
    22109: 'IAD',
    22116: 'IAD',
    22118: 'IAD',
    22119: 'IAD',
    22120: 'IAD',
    22121: 'IAD',
    22122: 'IAD',
    22124: 'IAD',
    22125: 'IAD',
    22134: 'IAD',
    22135: 'IAD',
    22150: 'IAD',
    22151: 'IAD',
    22152: 'IAD',
    22153: 'IAD',
    22156: 'IAD',
    22158: 'IAD',
    22159: 'IAD',
    22160: 'IAD',
    22161: 'IAD',
    22172: 'IAD',
    22180: 'IAD',
    22181: 'IAD',
    22182: 'IAD',
    22183: 'IAD',
    22184: 'IAD',
    22185: 'IAD',
    22191: 'IAD',
    22192: 'IAD',
    22193: 'IAD',
    22194: 'IAD',
    22195: 'IAD',
    22199: 'IAD',
    22201: 'IAD',
    22202: 'IAD',
    22203: 'IAD',
    22204: 'IAD',
    22205: 'IAD',
    22206: 'IAD',
    22207: 'IAD',
    22209: 'IAD',
    22210: 'IAD',
    22211: 'IAD',
    22212: 'IAD',
    22213: 'IAD',
    22214: 'IAD',
    22215: 'IAD',
    22216: 'IAD',
    22217: 'IAD',
    22218: 'IAD',
    22219: 'IAD',
    22222: 'IAD',
    22223: 'IAD',
    22225: 'IAD',
    22226: 'IAD',
    22227: 'IAD',
    22229: 'IAD',
    22230: 'IAD',
    22234: 'IAD',
    22240: 'IAD',
    22241: 'IAD',
    22242: 'IAD',
    22243: 'IAD',
    22244: 'IAD',
    22245: 'IAD',
    22246: 'IAD',
    22301: 'IAD',
    22302: 'IAD',
    22303: 'IAD',
    22304: 'IAD',
    22305: 'IAD',
    22306: 'IAD',
    22307: 'IAD',
    22308: 'IAD',
    22309: 'IAD',
    22310: 'IAD',
    22311: 'IAD',
    22312: 'IAD',
    22313: 'IAD',
    22314: 'IAD',
    22315: 'IAD',
    22320: 'IAD',
    22321: 'IAD',
    22331: 'IAD',
    22332: 'IAD',
    22333: 'IAD',
    22334: 'IAD',
    22336: 'IAD',
    22350: 'IAD',
    22401: 'IAD',
    22402: 'IAD',
    22403: 'IAD',
    22404: 'IAD',
    22405: 'IAD',
    22406: 'IAD',
    22407: 'IAD',
    22408: 'IAD',
    22412: 'IAD',
    22427: 'RIC',
    22428: 'IAD',
    22430: 'IAD',
    22432: 'RIC',
    22433: 'IAD',
    22435: 'RIC',
    22436: 'RIC',
    22437: 'RIC',
    22438: 'RIC',
    22442: 'RIC',
    22443: 'RIC',
    22446: 'RIC',
    22448: 'RIC',
    22451: 'RIC',
    22454: 'RIC',
    22456: 'RIC',
    22460: 'RIC',
    22463: 'IAD',
    22469: 'RIC',
    22471: 'IAD',
    22472: 'RIC',
    22473: 'RIC',
    22476: 'RIC',
    22480: 'RIC',
    22481: 'RIC',
    22482: 'RIC',
    22485: 'RIC',
    22488: 'RIC',
    22501: 'RIC',
    22503: 'RIC',
    22504: 'RIC',
    22507: 'RIC',
    22508: 'IAD',
    22509: 'RIC',
    22511: 'RIC',
    22513: 'RIC',
    22514: 'RIC',
    22517: 'RIC',
    22520: 'RIC',
    22523: 'RIC',
    22524: 'RIC',
    22526: 'RIC',
    22528: 'RIC',
    22529: 'RIC',
    22530: 'RIC',
    22534: 'RIC',
    22535: 'RIC',
    22538: 'RIC',
    22539: 'RIC',
    22542: 'IAD',
    22544: 'IAD',
    22545: 'IAD',
    22546: 'RIC',
    22547: 'RIC',
    22548: 'RIC',
    22551: 'RIC',
    22552: 'IAD',
    22553: 'IAD',
    22554: 'IAD',
    22555: 'IAD',
    22556: 'IAD',
    22558: 'RIC',
    22560: 'RIC',
    22565: 'RIC',
    22567: 'RIC',
    22570: 'RIC',
    22572: 'RIC',
    22576: 'RIC',
    22577: 'RIC',
    22578: 'RIC',
    22579: 'RIC',
    22580: 'RIC',
    22581: 'IAD',
    22601: 'IAD',
    22602: 'IAD',
    22603: 'IAD',
    22604: 'IAD',
    22610: 'IAD',
    22611: 'IAD',
    22620: 'IAD',
    22622: 'IAD',
    22623: 'IAD',
    22624: 'IAD',
    22625: 'IAD',
    22626: 'CKB',
    22627: 'IAD',
    22630: 'IAD',
    22637: 'IAD',
    22638: 'IAD',
    22639: 'IAD',
    22640: 'IAD',
    22641: 'CKB',
    22642: 'IAD',
    22643: 'IAD',
    22644: 'CKB',
    22645: 'IAD',
    22646: 'IAD',
    22649: 'IAD',
    22650: 'IAD',
    22652: 'CKB',
    22654: 'CKB',
    22655: 'IAD',
    22656: 'IAD',
    22657: 'CKB',
    22660: 'CKB',
    22663: 'IAD',
    22664: 'CKB',
    22701: 'IAD',
    22709: 'IAD',
    22711: 'IAD',
    22712: 'IAD',
    22713: 'IAD',
    22714: 'IAD',
    22715: 'IAD',
    22716: 'IAD',
    22718: 'IAD',
    22719: 'IAD',
    22720: 'IAD',
    22721: 'IAD',
    22722: 'IAD',
    22723: 'IAD',
    22724: 'IAD',
    22725: 'IAD',
    22726: 'IAD',
    22727: 'IAD',
    22728: 'IAD',
    22729: 'IAD',
    22730: 'IAD',
    22731: 'IAD',
    22732: 'IAD',
    22733: 'IAD',
    22734: 'IAD',
    22735: 'IAD',
    22736: 'IAD',
    22737: 'IAD',
    22738: 'IAD',
    22739: 'IAD',
    22740: 'IAD',
    22741: 'IAD',
    22742: 'IAD',
    22743: 'IAD',
    22746: 'IAD',
    22747: 'IAD',
    22748: 'IAD',
    22749: 'IAD',
    22801: 'CKB',
    22802: 'CKB',
    22803: 'CKB',
    22807: 'CKB',
    22810: 'CKB',
    22811: 'CKB',
    22812: 'CKB',
    22815: 'CKB',
    22820: 'CKB',
    22821: 'CKB',
    22824: 'CKB',
    22827: 'CKB',
    22830: 'CKB',
    22831: 'CKB',
    22832: 'CKB',
    22833: 'CKB',
    22834: 'CKB',
    22835: 'CKB',
    22840: 'CKB',
    22841: 'CKB',
    22842: 'CKB',
    22843: 'CKB',
    22844: 'CKB',
    22845: 'CKB',
    22846: 'CKB',
    22847: 'CKB',
    22848: 'CKB',
    22849: 'CKB',
    22850: 'CKB',
    22851: 'CKB',
    22853: 'CKB',
    22901: 'ROA',
    22902: 'ROA',
    22903: 'ROA',
    22904: 'ROA',
    22905: 'ROA',
    22906: 'ROA',
    22907: 'RIC',
    22908: 'ROA',
    22909: 'ROA',
    22910: 'ROA',
    22911: 'ROA',
    22920: 'ROA',
    22922: 'ROA',
    22923: 'ROA',
    22924: 'ROA',
    22931: 'ROA',
    22932: 'ROA',
    22935: 'CKB',
    22936: 'ROA',
    22937: 'ROA',
    22938: 'ROA',
    22939: 'ROA',
    22940: 'CKB',
    22942: 'ROA',
    22943: 'ROA',
    22945: 'ROA',
    22946: 'ROA',
    22947: 'ROA',
    22948: 'ROA',
    22949: 'ROA',
    22952: 'ROA',
    22953: 'ROA',
    22954: 'ROA',
    22957: 'ROA',
    22958: 'ROA',
    22959: 'ROA',
    22960: 'ROA',
    22963: 'ROA',
    22964: 'ROA',
    22965: 'CKB',
    22967: 'ROA',
    22968: 'CKB',
    22969: 'ROA',
    22971: 'ROA',
    22972: 'ROA',
    22973: 'CKB',
    22974: 'ROA',
    22976: 'ROA',
    22980: 'ROA',
    22987: 'ROA',
    22989: 'ROA',
    23001: 'ROA',
    23002: 'ROA',
    23003: 'ROA',
    23004: 'ROA',
    23005: 'ROA',
    23009: 'ROA',
    23011: 'ROA',
    23014: 'ROA',
    23015: 'ROA',
    23017: 'ROA',
    23018: 'ROA',
    23021: 'ROA',
    23022: 'ROA',
    23023: 'ROA',
    23024: 'ROA',
    23025: 'ROA',
    23027: 'ROA',
    23030: 'ROA',
    23031: 'ROA',
    23032: 'ROA',
    23035: 'ROA',
    23038: 'ROA',
    23039: 'ROA',
    23040: 'ROA',
    23043: 'ROA',
    23045: 'ROA',
    23047: 'ROA',
    23050: 'ROA',
    23054: 'ROA',
    23055: 'ROA',
    23056: 'ROA',
    23058: 'ROA',
    23059: 'ROA',
    23060: 'ROA',
    23061: 'ROA',
    23062: 'ROA',
    23063: 'ROA',
    23064: 'ROA',
    23065: 'ROA',
    23066: 'ROA',
    23067: 'ROA',
    23068: 'ROA',
    23069: 'ROA',
    23070: 'ROA',
    23071: 'ROA',
    23072: 'ROA',
    23075: 'ROA',
    23076: 'ROA',
    23079: 'ROA',
    23081: 'ROA',
    23083: 'ROA',
    23084: 'ROA',
    23085: 'ROA',
    23086: 'ROA',
    23089: 'ROA',
    23090: 'ROA',
    23091: 'ROA',
    23092: 'ROA',
    23093: 'ROA',
    23101: 'ROA',
    23102: 'ROA',
    23103: 'ROA',
    23105: 'ROA',
    23106: 'ROA',
    23107: 'ROA',
    23108: 'ROA',
    23109: 'ROA',
    23110: 'ROA',
    23111: 'ROA',
    23112: 'ROA',
    23113: 'ROA',
    23114: 'ROA',
    23115: 'ROA',
    23116: 'ROA',
    23117: 'ROA',
    23119: 'ROA',
    23120: 'ROA',
    23123: 'ROA',
    23124: 'ROA',
    23125: 'ROA',
    23126: 'ROA',
    23127: 'ROA',
    23128: 'ROA',
    23129: 'ROA',
    23130: 'ROA',
    23131: 'ROA',
    23138: 'ROA',
    23139: 'ROA',
    23140: 'ROA',
    23141: 'ROA',
    23146: 'ROA',
    23147: 'ROA',
    23148: 'ROA',
    23149: 'ROA',
    23150: 'ROA',
    23153: 'ROA',
    23154: 'ROA',
    23155: 'ROA',
    23156: 'ROA',
    23160: 'ROA',
    23161: 'ROA',
    23162: 'ROA',
    23163: 'ROA',
    23168: 'ROA',
    23169: 'ROA',
    23170: 'ROA',
    23173: 'ROA',
    23175: 'ROA',
    23176: 'ROA',
    23177: 'ROA',
    23178: 'ROA',
    23180: 'ROA',
    23181: 'ROA',
    23183: 'ROA',
    23184: 'ROA',
    23185: 'ROA',
    23186: 'ROA',
    23187: 'ROA',
    23188: 'ROA',
    23190: 'ROA',
    23191: 'ROA',
    23192: 'ROA',
    23218: 'ROA',
    23219: 'ROA',
    23220: 'ROA',
    23221: 'ROA',
    23222: 'ROA',
    23223: 'ROA',
    23224: 'ROA',
    23225: 'ROA',
    23226: 'ROA',
    23227: 'ROA',
    23228: 'ROA',
    23229: 'ROA',
    23230: 'ROA',
    23231: 'ROA',
    23232: 'ROA',
    23233: 'ROA',
    23234: 'ROA',
    23235: 'ROA',
    23236: 'ROA',
    23237: 'ROA',
    23238: 'ROA',
    23240: 'ROA',
    23241: 'ROA',
    23242: 'ROA',
    23249: 'ROA',
    23250: 'ROA',
    23255: 'ROA',
    23260: 'ROA',
    23261: 'ROA',
    23266: 'ROA',
    23269: 'ROA',
    23270: 'ROA',
    23272: 'ROA',
    23273: 'ROA',
    23274: 'ROA',
    23275: 'ROA',
    23276: 'ROA',
    23278: 'ROA',
    23279: 'ROA',
    23280: 'ROA',
    23282: 'ROA',
    23284: 'ROA',
    23285: 'ROA',
    23286: 'ROA',
    23288: 'ROA',
    23289: 'ROA',
    23290: 'ROA',
    23291: 'ROA',
    23292: 'ROA',
    23293: 'ROA',
    23294: 'ROA',
    23295: 'ROA',
    23297: 'ROA',
    23298: 'ROA',
    23301: 'ROA',
    23302: 'ROA',
    23303: 'ROA',
    23304: 'ROA',
    23306: 'ROA',
    23307: 'ROA',
    23308: 'ROA',
    23310: 'ROA',
    23313: 'ROA',
    23314: 'ROA',
    23315: 'ROA',
    23316: 'ROA',
    23320: 'ROA',
    23321: 'ROA',
    23322: 'ROA',
    23323: 'ROA',
    23324: 'ROA',
    23325: 'ROA',
    23326: 'ROA',
    23327: 'ROA',
    23328: 'ROA',
    23336: 'ROA',
    23337: 'ROA',
    23341: 'ROA',
    23345: 'ROA',
    23347: 'ROA',
    23350: 'ROA',
    23354: 'ROA',
    23356: 'ROA',
    23357: 'ROA',
    23358: 'ROA',
    23359: 'ROA',
    23389: 'ROA',
    23395: 'ROA',
    23396: 'ROA',
    23397: 'ROA',
    23398: 'ROA',
    23399: 'ROA',
    23401: 'ROA',
    23404: 'ROA',
    23405: 'ROA',
    23407: 'ROA',
    23408: 'ROA',
    23409: 'ROA',
    23410: 'ROA',
    23412: 'ROA',
    23413: 'ROA',
    23414: 'ROA',
    23415: 'ROA',
    23416: 'ROA',
    23417: 'ROA',
    23418: 'ROA',
    23419: 'ROA',
    23420: 'ROA',
    23421: 'ROA',
    23422: 'ROA',
    23423: 'ROA',
    23424: 'ROA',
    23426: 'ROA',
    23427: 'ROA',
    23429: 'ROA',
    23430: 'ROA',
    23431: 'ROA',
    23432: 'ROA',
    23433: 'ROA',
    23434: 'ROA',
    23435: 'ROA',
    23436: 'ROA',
    23437: 'ROA',
    23438: 'ROA',
    23439: 'ROA',
    23440: 'ROA',
    23441: 'ROA',
    23442: 'ROA',
    23443: 'ROA',
    23450: 'ROA',
    23451: 'ROA',
    23452: 'ROA',
    23453: 'ROA',
    23454: 'ROA',
    23455: 'ROA',
    23456: 'ROA',
    23457: 'ROA',
    23458: 'ROA',
    23459: 'ROA',
    23460: 'ROA',
    23461: 'ROA',
    23462: 'ROA',
    23463: 'ROA',
    23464: 'ROA',
    23465: 'ROA',
    23466: 'ROA',
    23467: 'ROA',
    23468: 'ROA',
    23471: 'ROA',
    23479: 'ROA',
    23480: 'ROA',
    23482: 'ROA',
    23483: 'ROA',
    23486: 'ROA',
    23487: 'ROA',
    23488: 'ROA',
    23501: 'ROA',
    23502: 'ROA',
    23503: 'ROA',
    23504: 'ROA',
    23505: 'ROA',
    23506: 'ROA',
    23507: 'ROA',
    23508: 'ROA',
    23509: 'ROA',
    23510: 'ROA',
    23511: 'ROA',
    23512: 'ROA',
    23513: 'ROA',
    23514: 'ROA',
    23515: 'ROA',
    23517: 'ROA',
    23518: 'ROA',
    23519: 'ROA',
    23520: 'ROA',
    23521: 'ROA',
    23523: 'ROA',
    23529: 'ROA',
    23530: 'ROA',
    23541: 'ROA',
    23551: 'ROA',
    23601: 'ROA',
    23602: 'ROA',
    23603: 'ROA',
    23604: 'ROA',
    23605: 'ROA',
    23606: 'ROA',
    23607: 'ROA',
    23608: 'ROA',
    23609: 'ROA',
    23612: 'ROA',
    23628: 'ROA',
    23630: 'ROA',
    23631: 'ROA',
    23651: 'ROA',
    23653: 'ROA',
    23661: 'ROA',
    23662: 'ROA',
    23663: 'ROA',
    23664: 'ROA',
    23665: 'ROA',
    23666: 'ROA',
    23667: 'ROA',
    23668: 'ROA',
    23669: 'ROA',
    23670: 'ROA',
    23681: 'ROA',
    23690: 'ROA',
    23691: 'ROA',
    23692: 'ROA',
    23693: 'ROA',
    23694: 'ROA',
    23696: 'ROA',
    23701: 'ROA',
    23702: 'ROA',
    23703: 'ROA',
    23704: 'ROA',
    23705: 'ROA',
    23707: 'ROA',
    23708: 'ROA',
    23709: 'ROA',
    23801: 'ROA',
    23803: 'ROA',
    23804: 'ROA',
    23805: 'ROA',
    23806: 'ROA',
    23821: 'ROA',
    23822: 'ROA',
    23824: 'ROA',
    23825: 'ROA',
    23827: 'ROA',
    23828: 'ROA',
    23829: 'ROA',
    23830: 'ROA',
    23831: 'ROA',
    23832: 'ROA',
    23833: 'ROA',
    23834: 'ROA',
    23836: 'ROA',
    23837: 'ROA',
    23838: 'ROA',
    23839: 'ROA',
    23840: 'ROA',
    23841: 'ROA',
    23842: 'ROA',
    23843: 'ROA',
    23844: 'ROA',
    23845: 'ROA',
    23846: 'ROA',
    23847: 'ROA',
    23850: 'ROA',
    23851: 'ROA',
    23856: 'ROA',
    23857: 'ROA',
    23860: 'ROA',
    23866: 'ROA',
    23867: 'ROA',
    23868: 'ROA',
    23870: 'ROA',
    23872: 'ROA',
    23873: 'ROA',
    23874: 'ROA',
    23875: 'ROA',
    23876: 'ROA',
    23878: 'ROA',
    23879: 'ROA',
    23881: 'ROA',
    23882: 'ROA',
    23883: 'ROA',
    23884: 'ROA',
    23885: 'ROA',
    23887: 'ROA',
    23888: 'ROA',
    23889: 'ROA',
    23890: 'ROA',
    23891: 'ROA',
    23893: 'ROA',
    23894: 'ROA',
    23897: 'ROA',
    23898: 'ROA',
    23899: 'ROA',
    23901: 'ROA',
    23909: 'ROA',
    23915: 'ROA',
    23917: 'ROA',
    23919: 'ROA',
    23920: 'ROA',
    23921: 'ROA',
    23922: 'ROA',
    23923: 'ROA',
    23924: 'ROA',
    23927: 'ROA',
    23930: 'ROA',
    23934: 'ROA',
    23936: 'ROA',
    23937: 'ROA',
    23938: 'ROA',
    23939: 'ROA',
    23941: 'ROA',
    23942: 'ROA',
    23943: 'ROA',
    23944: 'ROA',
    23947: 'ROA',
    23950: 'ROA',
    23952: 'ROA',
    23954: 'ROA',
    23955: 'ROA',
    23958: 'ROA',
    23959: 'ROA',
    23960: 'ROA',
    23962: 'ROA',
    23963: 'ROA',
    23964: 'ROA',
    23966: 'ROA',
    23967: 'ROA',
    23968: 'ROA',
    23970: 'ROA',
    23974: 'ROA',
    23976: 'ROA',
    24001: 'ROA',
    24002: 'ROA',
    24003: 'ROA',
    24004: 'ROA',
    24005: 'ROA',
    24006: 'ROA',
    24007: 'ROA',
    24008: 'ROA',
    24009: 'ROA',
    24010: 'ROA',
    24011: 'ROA',
    24012: 'ROA',
    24013: 'ROA',
    24014: 'ROA',
    24015: 'ROA',
    24016: 'ROA',
    24017: 'ROA',
    24018: 'ROA',
    24019: 'ROA',
    24020: 'ROA',
    24022: 'ROA',
    24023: 'ROA',
    24024: 'ROA',
    24025: 'ROA',
    24026: 'ROA',
    24027: 'ROA',
    24028: 'ROA',
    24029: 'ROA',
    24030: 'ROA',
    24031: 'ROA',
    24032: 'ROA',
    24033: 'ROA',
    24034: 'ROA',
    24035: 'ROA',
    24036: 'ROA',
    24037: 'ROA',
    24038: 'ROA',
    24040: 'ROA',
    24042: 'ROA',
    24043: 'ROA',
    24044: 'ROA',
    24045: 'ROA',
    24048: 'ROA',
    24050: 'ROA',
    24053: 'ROA',
    24054: 'ROA',
    24055: 'ROA',
    24058: 'ROA',
    24059: 'ROA',
    24060: 'ROA',
    24061: 'ROA',
    24062: 'ROA',
    24063: 'ROA',
    24064: 'ROA',
    24065: 'ROA',
    24066: 'ROA',
    24067: 'ROA',
    24068: 'ROA',
    24069: 'ROA',
    24070: 'ROA',
    24072: 'ROA',
    24073: 'ROA',
    24076: 'ROA',
    24077: 'ROA',
    24078: 'ROA',
    24079: 'ROA',
    24082: 'ROA',
    24083: 'ROA',
    24084: 'ROA',
    24085: 'ROA',
    24086: 'ROA',
    24087: 'ROA',
    24088: 'ROA',
    24089: 'ROA',
    24090: 'ROA',
    24091: 'ROA',
    24092: 'ROA',
    24093: 'ROA',
    24094: 'ROA',
    24095: 'ROA',
    24101: 'ROA',
    24102: 'ROA',
    24104: 'ROA',
    24105: 'ROA',
    24111: 'ROA',
    24112: 'ROA',
    24113: 'ROA',
    24114: 'ROA',
    24115: 'ROA',
    24120: 'ROA',
    24121: 'ROA',
    24122: 'ROA',
    24124: 'ROA',
    24126: 'ROA',
    24127: 'ROA',
    24128: 'ROA',
    24129: 'ROA',
    24130: 'ROA',
    24131: 'ROA',
    24132: 'ROA',
    24133: 'ROA',
    24134: 'ROA',
    24136: 'ROA',
    24137: 'ROA',
    24138: 'ROA',
    24139: 'ROA',
    24141: 'ROA',
    24142: 'ROA',
    24143: 'ROA',
    24146: 'ROA',
    24147: 'ROA',
    24148: 'ROA',
    24149: 'ROA',
    24150: 'ROA',
    24151: 'ROA',
    24153: 'ROA',
    24155: 'ROA',
    24156: 'ROA',
    24157: 'ROA',
    24161: 'ROA',
    24162: 'ROA',
    24165: 'ROA',
    24167: 'ROA',
    24168: 'ROA',
    24171: 'ROA',
    24174: 'ROA',
    24175: 'ROA',
    24176: 'ROA',
    24177: 'ROA',
    24178: 'ROA',
    24179: 'ROA',
    24184: 'ROA',
    24185: 'ROA',
    24201: 'TYS',
    24202: 'TYS',
    24203: 'TYS',
    24205: 'TYS',
    24209: 'TYS',
    24210: 'TYS',
    24211: 'TYS',
    24212: 'TYS',
    24215: 'TYS',
    24216: 'TYS',
    24217: 'TYS',
    24218: 'TYS',
    24219: 'TYS',
    24220: 'TYS',
    24221: 'TYS',
    24224: 'TYS',
    24225: 'TYS',
    24226: 'TYS',
    24228: 'TYS',
    24230: 'TYS',
    24236: 'TYS',
    24237: 'TYS',
    24239: 'TYS',
    24243: 'TYS',
    24244: 'TYS',
    24245: 'TYS',
    24246: 'TYS',
    24248: 'TYS',
    24250: 'TYS',
    24251: 'TYS',
    24256: 'TYS',
    24258: 'TYS',
    24260: 'TYS',
    24263: 'TYS',
    24265: 'TYS',
    24266: 'TYS',
    24269: 'TYS',
    24270: 'TYS',
    24271: 'TYS',
    24272: 'TYS',
    24273: 'TYS',
    24277: 'TYS',
    24279: 'TYS',
    24280: 'TYS',
    24281: 'TYS',
    24282: 'TYS',
    24283: 'TYS',
    24289: 'TYS',
    24290: 'TYS',
    24292: 'TYS',
    24293: 'TYS',
    24301: 'TYS',
    24311: 'TYS',
    24312: 'TYS',
    24313: 'TYS',
    24314: 'TYS',
    24315: 'TYS',
    24316: 'TYS',
    24317: 'TYS',
    24318: 'TYS',
    24319: 'TYS',
    24322: 'TYS',
    24323: 'TYS',
    24324: 'TYS',
    24325: 'TYS',
    24326: 'TYS',
    24327: 'TYS',
    24328: 'TYS',
    24330: 'TYS',
    24333: 'TYS',
    24340: 'TYS',
    24343: 'TYS',
    24347: 'TYS',
    24348: 'TYS',
    24350: 'TYS',
    24351: 'TYS',
    24352: 'TYS',
    24354: 'TYS',
    24360: 'TYS',
    24361: 'TYS',
    24363: 'TYS',
    24366: 'TYS',
    24368: 'TYS',
    24370: 'TYS',
    24373: 'TYS',
    24374: 'TYS',
    24375: 'TYS',
    24377: 'TYS',
    24378: 'TYS',
    24379: 'TYS',
    24380: 'TYS',
    24381: 'TYS',
    24382: 'TYS',
    24401: 'ROA',
    24402: 'ROA',
    24407: 'TYS',
    24411: 'ROA',
    24412: 'ROA',
    24413: 'CKB',
    24415: 'ROA',
    24416: 'ROA',
    24421: 'CKB',
    24422: 'ROA',
    24426: 'ROA',
    24430: 'ROA',
    24431: 'ROA',
    24432: 'ROA',
    24433: 'CKB',
    24435: 'ROA',
    24437: 'ROA',
    24438: 'ROA',
    24439: 'ROA',
    24440: 'ROA',
    24441: 'ROA',
    24442: 'CKB',
    24445: 'ROA',
    24448: 'ROA',
    24450: 'ROA',
    24457: 'ROA',
    24458: 'CKB',
    24459: 'ROA',
    24460: 'ROA',
    24463: 'ROA',
    24464: 'ROA',
    24465: 'CKB',
    24467: 'CKB',
    24468: 'CKB',
    24469: 'ROA',
    24471: 'CKB',
    24472: 'ROA',
    24473: 'ROA',
    24474: 'ROA',
    24476: 'ROA',
    24477: 'ROA',
    24479: 'ROA',
    24482: 'ROA',
    24483: 'ROA',
    24484: 'ROA',
    24485: 'CKB',
    24486: 'CKB',
    24487: 'ROA',
    24501: 'ROA',
    24502: 'ROA',
    24503: 'ROA',
    24504: 'ROA',
    24505: 'ROA',
    24506: 'ROA',
    24512: 'ROA',
    24513: 'ROA',
    24514: 'ROA',
    24515: 'ROA',
    24517: 'ROA',
    24520: 'ROA',
    24521: 'ROA',
    24522: 'ROA',
    24523: 'ROA',
    24526: 'ROA',
    24527: 'ROA',
    24528: 'ROA',
    24529: 'ROA',
    24530: 'ROA',
    24531: 'ROA',
    24533: 'ROA',
    24534: 'ROA',
    24535: 'ROA',
    24536: 'ROA',
    24538: 'ROA',
    24539: 'ROA',
    24540: 'ROA',
    24541: 'ROA',
    24543: 'ROA',
    24544: 'ROA',
    24549: 'ROA',
    24550: 'ROA',
    24551: 'ROA',
    24553: 'ROA',
    24554: 'ROA',
    24555: 'ROA',
    24556: 'ROA',
    24557: 'ROA',
    24558: 'ROA',
    24562: 'ROA',
    24563: 'ROA',
    24565: 'ROA',
    24566: 'ROA',
    24569: 'ROA',
    24570: 'ROA',
    24571: 'ROA',
    24572: 'ROA',
    24574: 'ROA',
    24576: 'ROA',
    24577: 'ROA',
    24578: 'ROA',
    24579: 'ROA',
    24580: 'ROA',
    24581: 'ROA',
    24585: 'TYS',
    24586: 'ROA',
    24588: 'ROA',
    24589: 'ROA',
    24590: 'ROA',
    24592: 'ROA',
    24593: 'ROA',
    24594: 'ROA',
    24595: 'ROA',
    24597: 'ROA',
    24598: 'ROA',
    24599: 'ROA',
    24601: 'TYS',
    24602: 'TYS',
    24603: 'TYS',
    24604: 'TYS',
    24605: 'TYS',
    24606: 'TYS',
    24607: 'TYS',
    24608: 'TYS',
    24609: 'TYS',
    24612: 'TYS',
    24613: 'TYS',
    24614: 'TYS',
    24618: 'TYS',
    24619: 'TYS',
    24620: 'TYS',
    24622: 'TYS',
    24624: 'TYS',
    24627: 'TYS',
    24628: 'TYS',
    24630: 'TYS',
    24631: 'TYS',
    24634: 'TYS',
    24635: 'TYS',
    24637: 'TYS',
    24639: 'TYS',
    24640: 'TYS',
    24641: 'TYS',
    24646: 'TYS',
    24647: 'TYS',
    24649: 'TYS',
    24651: 'TYS',
    24656: 'TYS',
    24657: 'TYS',
    24658: 'TYS',
    24701: 'TYS',
    24712: 'TYS',
    24714: 'TYS',
    24715: 'TYS',
    24716: 'TYS',
    24719: 'TYS',
    24724: 'TYS',
    24726: 'TYS',
    24729: 'TYS',
    24731: 'TYS',
    24732: 'TYS',
    24733: 'TYS',
    24736: 'TYS',
    24737: 'TYS',
    24738: 'TYS',
    24739: 'TYS',
    24740: 'TYS',
    24747: 'TYS',
    24751: 'TYS',
    24801: 'TYS',
    24808: 'TYS',
    24811: 'TYS',
    24813: 'TYS',
    24815: 'TYS',
    24816: 'TYS',
    24817: 'TYS',
    24818: 'TYS',
    24820: 'TYS',
    24821: 'TYS',
    24822: 'TYS',
    24823: 'TYS',
    24824: 'TYS',
    24825: 'TYS',
    24826: 'TYS',
    24827: 'TYS',
    24828: 'TYS',
    24829: 'TYS',
    24830: 'TYS',
    24831: 'TYS',
    24832: 'TYS',
    24834: 'TYS',
    24836: 'TYS',
    24839: 'TYS',
    24841: 'TYS',
    24842: 'TYS',
    24843: 'TYS',
    24844: 'TYS',
    24845: 'TYS',
    24846: 'TYS',
    24847: 'TYS',
    24848: 'TYS',
    24849: 'TYS',
    24850: 'TYS',
    24851: 'TYS',
    24852: 'TYS',
    24853: 'TYS',
    24854: 'TYS',
    24855: 'TYS',
    24856: 'TYS',
    24857: 'TYS',
    24859: 'TYS',
    24860: 'TYS',
    24861: 'TYS',
    24862: 'TYS',
    24866: 'TYS',
    24867: 'TYS',
    24868: 'TYS',
    24869: 'TYS',
    24870: 'TYS',
    24871: 'TYS',
    24872: 'TYS',
    24873: 'TYS',
    24874: 'TYS',
    24877: 'TYS',
    24878: 'TYS',
    24879: 'TYS',
    24880: 'TYS',
    24881: 'TYS',
    24882: 'TYS',
    24883: 'TYS',
    24884: 'TYS',
    24887: 'TYS',
    24888: 'TYS',
    24889: 'TYS',
    24892: 'TYS',
    24894: 'TYS',
    24895: 'ROA',
    24896: 'ROA',
    24897: 'ROA',
    24898: 'ROA',
    24899: 'ROA',
    24901: 'ROA',
    24902: 'ROA',
    24910: 'ROA',
    24915: 'CKB',
    24916: 'ROA',
    24917: 'ROA',
    24918: 'ROA',
    24919: 'ROA',
    24920: 'CKB',
    24924: 'ROA',
    24925: 'ROA',
    24927: 'CKB',
    24931: 'ROA',
    24934: 'CKB',
    24935: 'ROA',
    24936: 'ROA',
    24938: 'ROA',
    24941: 'ROA',
    24942: 'ROA',
    24943: 'ROA',
    24944: 'CKB',
    24945: 'ROA',
    24946: 'ROA',
    24950: 'ROA',
    24951: 'ROA',
    24954: 'CKB',
    24957: 'ROA',
    24958: 'ROA',
    24961: 'ROA',
    24962: 'ROA',
    24963: 'ROA',
    24966: 'ROA',
    24970: 'ROA',
    24974: 'ROA',
    24976: 'ROA',
    24977: 'ROA',
    24981: 'ROA',
    24983: 'ROA',
    24984: 'ROA',
    24985: 'HTS',
    24986: 'ROA',
    24991: 'HTS',
    24993: 'HTS',
    25002: 'HTS',
    25003: 'HTS',
    25004: 'HTS',
    25005: 'HTS',
    25007: 'HTS',
    25008: 'HTS',
    25009: 'HTS',
    25010: 'HTS',
    25011: 'HTS',
    25015: 'HTS',
    25018: 'HTS',
    25019: 'HTS',
    25021: 'HTS',
    25022: 'HTS',
    25024: 'HTS',
    25025: 'HTS',
    25026: 'HTS',
    25028: 'HTS',
    25030: 'HTS',
    25031: 'HTS',
    25033: 'HTS',
    25035: 'HTS',
    25036: 'HTS',
    25039: 'HTS',
    25040: 'HTS',
    25043: 'HTS',
    25044: 'HTS',
    25045: 'HTS',
    25046: 'HTS',
    25047: 'HTS',
    25048: 'HTS',
    25049: 'HTS',
    25051: 'HTS',
    25053: 'HTS',
    25054: 'HTS',
    25057: 'HTS',
    25059: 'HTS',
    25060: 'HTS',
    25061: 'HTS',
    25062: 'HTS',
    25063: 'HTS',
    25064: 'HTS',
    25067: 'HTS',
    25070: 'HTS',
    25071: 'HTS',
    25075: 'HTS',
    25076: 'HTS',
    25079: 'HTS',
    25081: 'HTS',
    25082: 'HTS',
    25083: 'HTS',
    25085: 'HTS',
    25086: 'HTS',
    25088: 'HTS',
    25090: 'HTS',
    25093: 'HTS',
    25095: 'CRW',
    25102: 'HTS',
    25103: 'HTS',
    25106: 'CRW',
    25107: 'HTS',
    25108: 'HTS',
    25109: 'HTS',
    25110: 'HTS',
    25111: 'HTS',
    25112: 'HTS',
    25113: 'HTS',
    25114: 'HTS',
    25115: 'HTS',
    25118: 'HTS',
    25119: 'HTS',
    25121: 'HTS',
    25122: 'HTS',
    25123: 'HTS',
    25124: 'HTS',
    25125: 'HTS',
    25126: 'HTS',
    25130: 'HTS',
    25132: 'HTS',
    25133: 'HTS',
    25134: 'HTS',
    25136: 'HTS',
    25139: 'HTS',
    25140: 'HTS',
    25141: 'HTS',
    25142: 'HTS',
    25143: 'HTS',
    25147: 'HTS',
    25148: 'HTS',
    25149: 'HTS',
    25150: 'HTS',
    25152: 'HTS',
    25154: 'HTS',
    25156: 'HTS',
    25159: 'HTS',
    25160: 'HTS',
    25161: 'HTS',
    25162: 'HTS',
    25164: 'HTS',
    25165: 'HTS',
    25168: 'HTS',
    25169: 'HTS',
    25173: 'HTS',
    25174: 'HTS',
    25177: 'HTS',
    25180: 'HTS',
    25181: 'HTS',
    25182: 'HTS',
    25183: 'HTS',
    25185: 'HTS',
    25186: 'HTS',
    25187: 'HTS',
    25193: 'HTS',
    25201: 'HTS',
    25202: 'HTS',
    25203: 'HTS',
    25204: 'HTS',
    25205: 'HTS',
    25206: 'HTS',
    25208: 'HTS',
    25209: 'HTS',
    25211: 'HTS',
    25213: 'HTS',
    25214: 'HTS',
    25231: 'HTS',
    25234: 'CRW',
    25235: 'HTS',
    25239: 'CRW',
    25241: 'CRW',
    25243: 'HTS',
    25244: 'CRW',
    25245: 'HTS',
    25247: 'CRW',
    25248: 'HTS',
    25250: 'HTS',
    25251: 'HTS',
    25252: 'CRW',
    25253: 'CRW',
    25256: 'CRW',
    25258: 'CRW',
    25259: 'HTS',
    25260: 'CRW',
    25261: 'CRW',
    25262: 'CRW',
    25264: 'CRW',
    25265: 'CRW',
    25266: 'HTS',
    25267: 'CRW',
    25268: 'HTS',
    25270: 'CRW',
    25271: 'CRW',
    25275: 'CRW',
    25276: 'CRW',
    25279: 'CRW',
    25281: 'CRW',
    25283: 'CRW',
    25285: 'HTS',
    25286: 'HTS',
    25287: 'CRW',
    25301: 'HTS',
    25302: 'HTS',
    25303: 'HTS',
    25304: 'HTS',
    25305: 'HTS',
    25306: 'HTS',
    25309: 'HTS',
    25311: 'HTS',
    25312: 'HTS',
    25313: 'HTS',
    25314: 'HTS',
    25315: 'HTS',
    25317: 'HTS',
    25320: 'HTS',
    25321: 'HTS',
    25322: 'HTS',
    25323: 'HTS',
    25324: 'HTS',
    25325: 'HTS',
    25326: 'HTS',
    25327: 'HTS',
    25328: 'HTS',
    25329: 'HTS',
    25330: 'HTS',
    25331: 'HTS',
    25332: 'HTS',
    25333: 'HTS',
    25334: 'HTS',
    25335: 'HTS',
    25336: 'HTS',
    25337: 'HTS',
    25338: 'HTS',
    25339: 'HTS',
    25350: 'HTS',
    25356: 'HTS',
    25357: 'HTS',
    25358: 'HTS',
    25360: 'HTS',
    25361: 'HTS',
    25362: 'HTS',
    25364: 'HTS',
    25365: 'HTS',
    25375: 'HTS',
    25387: 'HTS',
    25389: 'HTS',
    25392: 'HTS',
    25396: 'HTS',
    25401: 'IAD',
    25402: 'IAD',
    25403: 'IAD',
    25404: 'IAD',
    25405: 'IAD',
    25410: 'IAD',
    25411: 'IAD',
    25413: 'IAD',
    25414: 'IAD',
    25419: 'IAD',
    25420: 'IAD',
    25421: 'IAD',
    25422: 'IAD',
    25423: 'IAD',
    25425: 'IAD',
    25427: 'IAD',
    25428: 'IAD',
    25429: 'IAD',
    25430: 'IAD',
    25431: 'CKB',
    25432: 'IAD',
    25434: 'CKB',
    25437: 'CKB',
    25438: 'IAD',
    25440: 'IAD',
    25441: 'IAD',
    25442: 'IAD',
    25443: 'IAD',
    25444: 'CKB',
    25446: 'IAD',
    25501: 'HTS',
    25502: 'HTS',
    25503: 'HTS',
    25504: 'HTS',
    25505: 'HTS',
    25506: 'HTS',
    25507: 'HTS',
    25508: 'HTS',
    25510: 'HTS',
    25511: 'HTS',
    25512: 'HTS',
    25514: 'HTS',
    25515: 'HTS',
    25517: 'HTS',
    25519: 'HTS',
    25520: 'HTS',
    25521: 'HTS',
    25523: 'HTS',
    25524: 'HTS',
    25526: 'HTS',
    25529: 'HTS',
    25530: 'HTS',
    25534: 'HTS',
    25535: 'HTS',
    25537: 'HTS',
    25540: 'HTS',
    25541: 'HTS',
    25544: 'HTS',
    25545: 'HTS',
    25547: 'HTS',
    25550: 'CRW',
    25555: 'HTS',
    25557: 'HTS',
    25559: 'HTS',
    25560: 'HTS',
    25562: 'HTS',
    25564: 'HTS',
    25565: 'HTS',
    25567: 'HTS',
    25569: 'HTS',
    25570: 'HTS',
    25571: 'HTS',
    25572: 'HTS',
    25573: 'HTS',
    25601: 'HTS',
    25606: 'HTS',
    25607: 'HTS',
    25608: 'HTS',
    25611: 'HTS',
    25612: 'HTS',
    25614: 'HTS',
    25617: 'HTS',
    25621: 'HTS',
    25623: 'HTS',
    25624: 'HTS',
    25625: 'HTS',
    25628: 'HTS',
    25630: 'HTS',
    25632: 'HTS',
    25634: 'HTS',
    25635: 'HTS',
    25636: 'HTS',
    25637: 'HTS',
    25638: 'HTS',
    25639: 'HTS',
    25644: 'HTS',
    25645: 'HTS',
    25646: 'HTS',
    25647: 'HTS',
    25649: 'HTS',
    25650: 'HTS',
    25651: 'HTS',
    25652: 'HTS',
    25653: 'HTS',
    25654: 'HTS',
    25661: 'HTS',
    25665: 'HTS',
    25666: 'HTS',
    25667: 'HTS',
    25669: 'HTS',
    25670: 'HTS',
    25671: 'HTS',
    25672: 'HTS',
    25674: 'HTS',
    25676: 'HTS',
    25678: 'HTS',
    25682: 'HTS',
    25685: 'HTS',
    25686: 'HTS',
    25687: 'HTS',
    25688: 'HTS',
    25690: 'HTS',
    25691: 'HTS',
    25692: 'HTS',
    25694: 'HTS',
    25696: 'HTS',
    25697: 'HTS',
    25699: 'HTS',
    25701: 'HTS',
    25702: 'HTS',
    25703: 'HTS',
    25704: 'HTS',
    25705: 'HTS',
    25706: 'HTS',
    25707: 'HTS',
    25708: 'HTS',
    25709: 'HTS',
    25710: 'HTS',
    25711: 'HTS',
    25712: 'HTS',
    25713: 'HTS',
    25714: 'HTS',
    25715: 'HTS',
    25716: 'HTS',
    25717: 'HTS',
    25718: 'HTS',
    25719: 'HTS',
    25720: 'HTS',
    25721: 'HTS',
    25722: 'HTS',
    25723: 'HTS',
    25724: 'HTS',
    25725: 'HTS',
    25726: 'HTS',
    25727: 'HTS',
    25728: 'HTS',
    25729: 'HTS',
    25755: 'HTS',
    25770: 'HTS',
    25771: 'HTS',
    25772: 'HTS',
    25773: 'HTS',
    25774: 'HTS',
    25775: 'HTS',
    25776: 'HTS',
    25777: 'HTS',
    25778: 'HTS',
    25779: 'HTS',
    25801: 'HTS',
    25802: 'HTS',
    25810: 'HTS',
    25811: 'HTS',
    25812: 'HTS',
    25813: 'HTS',
    25816: 'HTS',
    25817: 'HTS',
    25818: 'HTS',
    25820: 'HTS',
    25823: 'HTS',
    25825: 'HTS',
    25826: 'HTS',
    25827: 'HTS',
    25831: 'HTS',
    25832: 'HTS',
    25833: 'HTS',
    25836: 'HTS',
    25837: 'HTS',
    25839: 'HTS',
    25840: 'HTS',
    25841: 'HTS',
    25843: 'HTS',
    25844: 'HTS',
    25845: 'HTS',
    25846: 'HTS',
    25847: 'HTS',
    25848: 'HTS',
    25849: 'HTS',
    25851: 'HTS',
    25853: 'HTS',
    25854: 'HTS',
    25855: 'HTS',
    25856: 'HTS',
    25857: 'HTS',
    25859: 'HTS',
    25860: 'HTS',
    25862: 'HTS',
    25864: 'HTS',
    25865: 'HTS',
    25866: 'HTS',
    25868: 'HTS',
    25870: 'HTS',
    25871: 'HTS',
    25873: 'HTS',
    25875: 'HTS',
    25876: 'HTS',
    25878: 'HTS',
    25879: 'HTS',
    25880: 'HTS',
    25882: 'HTS',
    25888: 'HTS',
    25901: 'HTS',
    25902: 'HTS',
    25904: 'HTS',
    25906: 'HTS',
    25907: 'HTS',
    25908: 'HTS',
    25909: 'HTS',
    25911: 'HTS',
    25912: 'HTS',
    25913: 'HTS',
    25914: 'HTS',
    25915: 'HTS',
    25916: 'HTS',
    25917: 'HTS',
    25918: 'HTS',
    25919: 'HTS',
    25920: 'HTS',
    25921: 'HTS',
    25922: 'HTS',
    25926: 'HTS',
    25927: 'HTS',
    25928: 'HTS',
    25931: 'HTS',
    25932: 'HTS',
    25934: 'HTS',
    25936: 'HTS',
    25938: 'HTS',
    25942: 'HTS',
    25943: 'HTS',
    25951: 'HTS',
    25958: 'HTS',
    25961: 'HTS',
    25962: 'HTS',
    25965: 'HTS',
    25966: 'HTS',
    25967: 'HTS',
    25969: 'HTS',
    25971: 'HTS',
    25972: 'HTS',
    25976: 'HTS',
    25977: 'HTS',
    25978: 'HTS',
    25979: 'HTS',
    25981: 'HTS',
    25984: 'HTS',
    25985: 'HTS',
    25986: 'HTS',
    25988: 'HTS',
    25989: 'HTS',
    26003: 'CRW',
    26030: 'CLE',
    26031: 'CRW',
    26032: 'CRW',
    26033: 'CRW',
    26034: 'CLE',
    26035: 'CLE',
    26036: 'CRW',
    26037: 'CLE',
    26038: 'CRW',
    26039: 'CRW',
    26040: 'CRW',
    26041: 'CRW',
    26047: 'CLE',
    26050: 'CLE',
    26055: 'CRW',
    26056: 'CLE',
    26058: 'CLE',
    26059: 'CRW',
    26060: 'CRW',
    26062: 'CLE',
    26070: 'CLE',
    26074: 'CRW',
    26075: 'CLE',
    26101: 'CRW',
    26102: 'CRW',
    26103: 'CRW',
    26104: 'CRW',
    26105: 'CRW',
    26106: 'CRW',
    26120: 'CRW',
    26121: 'CRW',
    26133: 'CRW',
    26134: 'CRW',
    26135: 'CRW',
    26136: 'CRW',
    26137: 'CRW',
    26138: 'CRW',
    26141: 'CRW',
    26142: 'CRW',
    26143: 'CRW',
    26146: 'CRW',
    26147: 'CRW',
    26148: 'CRW',
    26149: 'CRW',
    26150: 'CRW',
    26151: 'CRW',
    26152: 'CRW',
    26155: 'CRW',
    26159: 'CRW',
    26160: 'CRW',
    26161: 'CRW',
    26162: 'CRW',
    26164: 'CRW',
    26167: 'CRW',
    26169: 'CRW',
    26170: 'CRW',
    26173: 'CRW',
    26175: 'CRW',
    26178: 'CRW',
    26180: 'CRW',
    26181: 'CRW',
    26184: 'CRW',
    26186: 'CRW',
    26187: 'CRW',
    26201: 'CKB',
    26202: 'HTS',
    26203: 'HTS',
    26205: 'HTS',
    26206: 'CKB',
    26208: 'HTS',
    26209: 'CKB',
    26210: 'CKB',
    26215: 'CKB',
    26217: 'CKB',
    26218: 'CKB',
    26219: 'CKB',
    26222: 'CKB',
    26224: 'CKB',
    26228: 'CKB',
    26229: 'CKB',
    26230: 'CKB',
    26234: 'CKB',
    26236: 'CKB',
    26237: 'CKB',
    26238: 'CKB',
    26241: 'CKB',
    26250: 'CKB',
    26253: 'CKB',
    26254: 'CKB',
    26257: 'CKB',
    26259: 'CKB',
    26260: 'CKB',
    26261: 'HTS',
    26263: 'CKB',
    26264: 'CKB',
    26266: 'HTS',
    26267: 'CKB',
    26268: 'CKB',
    26269: 'CKB',
    26270: 'CKB',
    26271: 'CKB',
    26273: 'CKB',
    26275: 'CKB',
    26276: 'CKB',
    26278: 'CKB',
    26280: 'CKB',
    26282: 'CKB',
    26283: 'CKB',
    26285: 'CKB',
    26287: 'CKB',
    26288: 'CKB',
    26289: 'CKB',
    26291: 'CKB',
    26292: 'CKB',
    26293: 'CKB',
    26294: 'CKB',
    26296: 'CKB',
    26298: 'CKB',
    26301: 'CKB',
    26302: 'CKB',
    26306: 'CKB',
    26320: 'CRW',
    26321: 'CRW',
    26323: 'CKB',
    26325: 'CRW',
    26327: 'CRW',
    26328: 'CRW',
    26330: 'CKB',
    26332: 'CKB',
    26334: 'CKB',
    26335: 'CRW',
    26337: 'CRW',
    26338: 'CRW',
    26339: 'CRW',
    26342: 'CRW',
    26343: 'CKB',
    26346: 'CRW',
    26347: 'CKB',
    26348: 'CRW',
    26349: 'CKB',
    26350: 'CKB',
    26351: 'CRW',
    26354: 'CKB',
    26361: 'CKB',
    26362: 'CRW',
    26366: 'CKB',
    26369: 'CKB',
    26372: 'CKB',
    26374: 'CKB',
    26375: 'CKB',
    26376: 'HTS',
    26377: 'CRW',
    26378: 'CKB',
    26384: 'CRW',
    26385: 'CKB',
    26386: 'CKB',
    26404: 'CKB',
    26405: 'CKB',
    26407: 'CKB',
    26408: 'CKB',
    26410: 'CKB',
    26411: 'CRW',
    26412: 'CRW',
    26415: 'CRW',
    26416: 'CKB',
    26419: 'CRW',
    26421: 'CRW',
    26422: 'CKB',
    26424: 'CKB',
    26425: 'CKB',
    26426: 'CRW',
    26430: 'CRW',
    26431: 'CKB',
    26434: 'CRW',
    26435: 'CKB',
    26436: 'CRW',
    26437: 'CRW',
    26438: 'CKB',
    26440: 'CKB',
    26443: 'CRW',
    26444: 'CKB',
    26447: 'CRW',
    26448: 'CRW',
    26451: 'CKB',
    26452: 'CRW',
    26456: 'CRW',
    26461: 'CKB',
    26463: 'CKB',
    26501: 'CKB',
    26502: 'CKB',
    26503: 'CKB',
    26504: 'CKB',
    26505: 'CKB',
    26506: 'CKB',
    26507: 'CKB',
    26508: 'CKB',
    26519: 'CKB',
    26520: 'CKB',
    26521: 'CKB',
    26522: 'CKB',
    26524: 'CKB',
    26525: 'CKB',
    26527: 'CKB',
    26529: 'CKB',
    26531: 'CKB',
    26533: 'CKB',
    26534: 'CKB',
    26535: 'CKB',
    26537: 'CKB',
    26541: 'CKB',
    26542: 'CKB',
    26543: 'CKB',
    26544: 'CKB',
    26546: 'CKB',
    26547: 'CKB',
    26554: 'CKB',
    26555: 'CKB',
    26559: 'CKB',
    26560: 'CKB',
    26561: 'CRW',
    26562: 'CKB',
    26563: 'CKB',
    26566: 'CKB',
    26568: 'CKB',
    26570: 'CKB',
    26571: 'CKB',
    26572: 'CKB',
    26574: 'CKB',
    26575: 'CRW',
    26576: 'CKB',
    26578: 'CKB',
    26581: 'CRW',
    26582: 'CKB',
    26585: 'CKB',
    26586: 'CKB',
    26587: 'CKB',
    26588: 'CKB',
    26589: 'CKB',
    26590: 'CKB',
    26591: 'CKB',
    26601: 'HTS',
    26610: 'HTS',
    26611: 'CRW',
    26612: 'CRW',
    26615: 'CRW',
    26617: 'HTS',
    26618: 'HTS',
    26619: 'HTS',
    26621: 'HTS',
    26623: 'HTS',
    26624: 'HTS',
    26627: 'HTS',
    26629: 'HTS',
    26631: 'CRW',
    26634: 'CRW',
    26636: 'CRW',
    26638: 'CRW',
    26639: 'CRW',
    26641: 'CRW',
    26651: 'HTS',
    26656: 'HTS',
    26660: 'HTS',
    26662: 'HTS',
    26667: 'HTS',
    26671: 'HTS',
    26674: 'HTS',
    26675: 'HTS',
    26676: 'HTS',
    26678: 'HTS',
    26679: 'HTS',
    26680: 'HTS',
    26681: 'HTS',
    26684: 'HTS',
    26690: 'HTS',
    26691: 'HTS',
    26704: 'CKB',
    26705: 'CKB',
    26707: 'CKB',
    26710: 'CKB',
    26711: 'CKB',
    26714: 'CKB',
    26716: 'CKB',
    26717: 'CKB',
    26719: 'CKB',
    26720: 'CKB',
    26722: 'CKB',
    26726: 'CKB',
    26731: 'CKB',
    26734: 'CKB',
    26739: 'CKB',
    26743: 'CKB',
    26750: 'CKB',
    26753: 'CKB',
    26755: 'CKB',
    26757: 'CKB',
    26761: 'CKB',
    26763: 'CKB',
    26764: 'CKB',
    26767: 'CKB',
    26801: 'CKB',
    26802: 'CKB',
    26804: 'CKB',
    26807: 'CKB',
    26808: 'CKB',
    26810: 'CKB',
    26812: 'CKB',
    26814: 'CKB',
    26815: 'CKB',
    26817: 'CKB',
    26818: 'CKB',
    26823: 'CKB',
    26824: 'CKB',
    26833: 'CKB',
    26836: 'CKB',
    26838: 'CKB',
    26845: 'CKB',
    26847: 'CKB',
    26851: 'CKB',
    26852: 'CKB',
    26855: 'CKB',
    26865: 'CKB',
    26866: 'CKB',
    26884: 'CKB',
    26886: 'CKB',
    27006: 'RDU',
    27007: 'RDU',
    27009: 'RDU',
    27010: 'RDU',
    27011: 'RDU',
    27012: 'RDU',
    27013: 'RDU',
    27014: 'RDU',
    27016: 'RDU',
    27017: 'RDU',
    27018: 'RDU',
    27019: 'RDU',
    27020: 'RDU',
    27021: 'RDU',
    27022: 'RDU',
    27023: 'RDU',
    27024: 'RDU',
    27025: 'RDU',
    27027: 'RDU',
    27028: 'RDU',
    27030: 'RDU',
    27031: 'RDU',
    27040: 'RDU',
    27041: 'RDU',
    27042: 'RDU',
    27043: 'RDU',
    27045: 'RDU',
    27046: 'RDU',
    27047: 'RDU',
    27048: 'RDU',
    27049: 'RDU',
    27050: 'RDU',
    27051: 'RDU',
    27052: 'RDU',
    27053: 'RDU',
    27054: 'RDU',
    27055: 'RDU',
    27094: 'RDU',
    27098: 'RDU',
    27099: 'RDU',
    27101: 'RDU',
    27102: 'RDU',
    27103: 'RDU',
    27104: 'RDU',
    27105: 'RDU',
    27106: 'RDU',
    27107: 'RDU',
    27108: 'RDU',
    27109: 'RDU',
    27110: 'RDU',
    27111: 'RDU',
    27113: 'RDU',
    27114: 'RDU',
    27115: 'RDU',
    27116: 'RDU',
    27117: 'RDU',
    27120: 'RDU',
    27127: 'RDU',
    27130: 'RDU',
    27150: 'RDU',
    27151: 'RDU',
    27152: 'RDU',
    27155: 'RDU',
    27156: 'RDU',
    27157: 'RDU',
    27198: 'RDU',
    27199: 'RDU',
    27201: 'RDU',
    27202: 'RDU',
    27203: 'RDU',
    27204: 'RDU',
    27205: 'RDU',
    27207: 'RDU',
    27208: 'RDU',
    27209: 'RDU',
    27212: 'RDU',
    27213: 'RDU',
    27214: 'RDU',
    27215: 'RDU',
    27216: 'RDU',
    27217: 'RDU',
    27220: 'RDU',
    27228: 'RDU',
    27229: 'RDU',
    27230: 'RDU',
    27231: 'RDU',
    27233: 'RDU',
    27235: 'RDU',
    27237: 'RDU',
    27239: 'RDU',
    27242: 'RDU',
    27243: 'RDU',
    27244: 'RDU',
    27247: 'RDU',
    27248: 'RDU',
    27249: 'RDU',
    27252: 'RDU',
    27253: 'RDU',
    27256: 'RDU',
    27258: 'RDU',
    27259: 'RDU',
    27260: 'RDU',
    27261: 'RDU',
    27262: 'RDU',
    27263: 'RDU',
    27264: 'RDU',
    27265: 'RDU',
    27268: 'RDU',
    27278: 'RDU',
    27281: 'RDU',
    27282: 'RDU',
    27283: 'RDU',
    27284: 'RDU',
    27285: 'RDU',
    27288: 'RDU',
    27289: 'RDU',
    27291: 'RDU',
    27292: 'RDU',
    27293: 'RDU',
    27294: 'RDU',
    27295: 'RDU',
    27298: 'RDU',
    27299: 'RDU',
    27301: 'RDU',
    27302: 'RDU',
    27305: 'RDU',
    27306: 'RDU',
    27310: 'RDU',
    27311: 'RDU',
    27312: 'RDU',
    27313: 'RDU',
    27314: 'RDU',
    27315: 'RDU',
    27316: 'RDU',
    27317: 'RDU',
    27320: 'RDU',
    27321: 'RDU',
    27322: 'RDU',
    27323: 'RDU',
    27325: 'RDU',
    27326: 'RDU',
    27330: 'RDU',
    27331: 'RDU',
    27332: 'RDU',
    27340: 'RDU',
    27341: 'RDU',
    27342: 'RDU',
    27343: 'RDU',
    27344: 'RDU',
    27349: 'RDU',
    27350: 'RDU',
    27351: 'RDU',
    27355: 'RDU',
    27356: 'RDU',
    27357: 'RDU',
    27358: 'RDU',
    27359: 'RDU',
    27360: 'RDU',
    27361: 'RDU',
    27370: 'RDU',
    27371: 'RDU',
    27373: 'RDU',
    27374: 'RDU',
    27375: 'RDU',
    27376: 'RDU',
    27377: 'RDU',
    27379: 'RDU',
    27395: 'RDU',
    27401: 'RDU',
    27402: 'RDU',
    27403: 'RDU',
    27404: 'RDU',
    27405: 'RDU',
    27406: 'RDU',
    27407: 'RDU',
    27408: 'RDU',
    27409: 'RDU',
    27410: 'RDU',
    27411: 'RDU',
    27412: 'RDU',
    27413: 'RDU',
    27415: 'RDU',
    27416: 'RDU',
    27417: 'RDU',
    27419: 'RDU',
    27420: 'RDU',
    27425: 'RDU',
    27427: 'RDU',
    27429: 'RDU',
    27435: 'RDU',
    27438: 'RDU',
    27455: 'RDU',
    27480: 'RDU',
    27495: 'RDU',
    27497: 'RDU',
    27498: 'RDU',
    27499: 'RDU',
    27501: 'RDU',
    27502: 'RDU',
    27503: 'RDU',
    27504: 'RDU',
    27505: 'RDU',
    27506: 'RDU',
    27507: 'RDU',
    27508: 'RDU',
    27509: 'RDU',
    27510: 'RDU',
    27511: 'RDU',
    27512: 'RDU',
    27513: 'RDU',
    27514: 'RDU',
    27515: 'RDU',
    27516: 'RDU',
    27517: 'RDU',
    27518: 'RDU',
    27519: 'RDU',
    27520: 'RDU',
    27521: 'RDU',
    27522: 'RDU',
    27523: 'RDU',
    27524: 'RDU',
    27525: 'RDU',
    27526: 'RDU',
    27527: 'RDU',
    27528: 'RDU',
    27529: 'RDU',
    27530: 'RDU',
    27531: 'RDU',
    27532: 'RDU',
    27533: 'RDU',
    27534: 'RDU',
    27536: 'RDU',
    27537: 'RDU',
    27539: 'RDU',
    27540: 'RDU',
    27541: 'RDU',
    27542: 'RDU',
    27543: 'RDU',
    27544: 'RDU',
    27545: 'RDU',
    27546: 'RDU',
    27549: 'RDU',
    27551: 'RDU',
    27552: 'RDU',
    27553: 'RDU',
    27555: 'RDU',
    27556: 'RDU',
    27557: 'RDU',
    27559: 'RDU',
    27560: 'RDU',
    27562: 'RDU',
    27563: 'RDU',
    27564: 'RDU',
    27565: 'RDU',
    27568: 'RDU',
    27569: 'RDU',
    27570: 'RDU',
    27571: 'RDU',
    27572: 'RDU',
    27573: 'RDU',
    27574: 'RDU',
    27576: 'RDU',
    27577: 'RDU',
    27581: 'RDU',
    27582: 'RDU',
    27583: 'RDU',
    27584: 'RDU',
    27586: 'RDU',
    27587: 'RDU',
    27588: 'RDU',
    27589: 'RDU',
    27591: 'RDU',
    27592: 'RDU',
    27593: 'RDU',
    27594: 'RDU',
    27596: 'RDU',
    27597: 'RDU',
    27599: 'RDU',
    27601: 'RDU',
    27602: 'RDU',
    27603: 'RDU',
    27604: 'RDU',
    27605: 'RDU',
    27606: 'RDU',
    27607: 'RDU',
    27608: 'RDU',
    27609: 'RDU',
    27610: 'RDU',
    27611: 'RDU',
    27612: 'RDU',
    27613: 'RDU',
    27614: 'RDU',
    27615: 'RDU',
    27616: 'RDU',
    27617: 'RDU',
    27619: 'RDU',
    27620: 'RDU',
    27621: 'RDU',
    27622: 'RDU',
    27623: 'RDU',
    27624: 'RDU',
    27625: 'RDU',
    27626: 'RDU',
    27627: 'RDU',
    27628: 'RDU',
    27629: 'RDU',
    27634: 'RDU',
    27635: 'RDU',
    27636: 'RDU',
    27640: 'RDU',
    27650: 'RDU',
    27656: 'RDU',
    27658: 'RDU',
    27661: 'RDU',
    27668: 'RDU',
    27675: 'RDU',
    27676: 'RDU',
    27690: 'RDU',
    27695: 'RDU',
    27697: 'RDU',
    27698: 'RDU',
    27699: 'RDU',
    27701: 'RDU',
    27702: 'RDU',
    27703: 'RDU',
    27704: 'RDU',
    27705: 'RDU',
    27706: 'RDU',
    27707: 'RDU',
    27708: 'RDU',
    27709: 'RDU',
    27710: 'RDU',
    27711: 'RDU',
    27712: 'RDU',
    27713: 'RDU',
    27715: 'RDU',
    27717: 'RDU',
    27722: 'RDU',
    27801: 'RDU',
    27802: 'RDU',
    27803: 'RDU',
    27804: 'RDU',
    27805: 'RDU',
    27806: 'RDU',
    27807: 'RDU',
    27808: 'RDU',
    27809: 'RDU',
    27810: 'RDU',
    27811: 'RDU',
    27812: 'RDU',
    27813: 'RDU',
    27814: 'RDU',
    27815: 'RDU',
    27816: 'RDU',
    27817: 'RDU',
    27818: 'RDU',
    27819: 'RDU',
    27820: 'RDU',
    27821: 'RDU',
    27822: 'RDU',
    27823: 'RDU',
    27824: 'RDU',
    27825: 'RDU',
    27826: 'RDU',
    27827: 'RDU',
    27828: 'RDU',
    27829: 'RDU',
    27830: 'RDU',
    27831: 'RDU',
    27832: 'RDU',
    27833: 'RDU',
    27834: 'RDU',
    27835: 'RDU',
    27836: 'RDU',
    27837: 'RDU',
    27839: 'RDU',
    27840: 'RDU',
    27841: 'RDU',
    27842: 'RDU',
    27843: 'RDU',
    27844: 'RDU',
    27845: 'RDU',
    27846: 'RDU',
    27847: 'RDU',
    27849: 'RDU',
    27850: 'RDU',
    27851: 'RDU',
    27852: 'RDU',
    27853: 'RDU',
    27854: 'RDU',
    27855: 'RDU',
    27856: 'RDU',
    27857: 'RDU',
    27858: 'RDU',
    27860: 'RDU',
    27861: 'RDU',
    27862: 'RDU',
    27863: 'RDU',
    27864: 'RDU',
    27865: 'RDU',
    27866: 'RDU',
    27867: 'RDU',
    27868: 'RDU',
    27869: 'RDU',
    27870: 'RDU',
    27871: 'RDU',
    27872: 'RDU',
    27873: 'RDU',
    27874: 'RDU',
    27875: 'RDU',
    27876: 'RDU',
    27877: 'RDU',
    27878: 'RDU',
    27879: 'RDU',
    27880: 'RDU',
    27881: 'RDU',
    27882: 'RDU',
    27883: 'RDU',
    27884: 'RDU',
    27885: 'RDU',
    27886: 'RDU',
    27887: 'RDU',
    27888: 'RDU',
    27889: 'RDU',
    27890: 'RDU',
    27891: 'RDU',
    27892: 'RDU',
    27893: 'RDU',
    27894: 'RDU',
    27895: 'RDU',
    27896: 'RDU',
    27897: 'RDU',
    27906: 'RDU',
    27907: 'RDU',
    27909: 'RDU',
    27910: 'RDU',
    27915: 'RDU',
    27916: 'RDU',
    27917: 'RDU',
    27919: 'RDU',
    27920: 'RDU',
    27921: 'RDU',
    27922: 'RDU',
    27923: 'RDU',
    27924: 'RDU',
    27925: 'RDU',
    27926: 'RDU',
    27927: 'RDU',
    27928: 'RDU',
    27929: 'RDU',
    27930: 'RDU',
    27932: 'RDU',
    27935: 'RDU',
    27936: 'RDU',
    27937: 'RDU',
    27938: 'RDU',
    27939: 'RDU',
    27941: 'RDU',
    27942: 'RDU',
    27943: 'RDU',
    27944: 'RDU',
    27946: 'RDU',
    27947: 'RDU',
    27948: 'RDU',
    27949: 'RDU',
    27950: 'RDU',
    27953: 'RDU',
    27954: 'RDU',
    27956: 'RDU',
    27957: 'RDU',
    27958: 'RDU',
    27959: 'RDU',
    27960: 'RDU',
    27962: 'RDU',
    27964: 'RDU',
    27965: 'RDU',
    27966: 'RDU',
    27967: 'RDU',
    27968: 'RDU',
    27969: 'RDU',
    27970: 'RDU',
    27972: 'RDU',
    27973: 'RDU',
    27974: 'RDU',
    27976: 'RDU',
    27978: 'RDU',
    27979: 'RDU',
    27980: 'RDU',
    27981: 'RDU',
    27982: 'RDU',
    27983: 'RDU',
    27985: 'RDU',
    27986: 'RDU',
    28001: 'CLT',
    28002: 'CLT',
    28006: 'CLT',
    28007: 'CLT',
    28009: 'CLT',
    28010: 'CLT',
    28012: 'CLT',
    28016: 'CLT',
    28017: 'CLT',
    28018: 'CLT',
    28019: 'CLT',
    28020: 'CLT',
    28021: 'CLT',
    28023: 'CLT',
    28024: 'CLT',
    28025: 'CLT',
    28026: 'CLT',
    28027: 'CLT',
    28031: 'CLT',
    28032: 'CLT',
    28033: 'CLT',
    28034: 'CLT',
    28035: 'CLT',
    28036: 'CLT',
    28037: 'CLT',
    28038: 'CLT',
    28039: 'CLT',
    28040: 'CLT',
    28041: 'CLT',
    28042: 'CLT',
    28043: 'CLT',
    28052: 'CLT',
    28053: 'CLT',
    28054: 'CLT',
    28055: 'CLT',
    28056: 'CLT',
    28070: 'CLT',
    28071: 'CLT',
    28072: 'CLT',
    28073: 'CLT',
    28074: 'CLT',
    28075: 'CLT',
    28076: 'CLT',
    28077: 'CLT',
    28078: 'CLT',
    28079: 'CLT',
    28080: 'CLT',
    28081: 'CLT',
    28082: 'CLT',
    28083: 'CLT',
    28086: 'CLT',
    28088: 'CLT',
    28089: 'CLT',
    28090: 'CLT',
    28091: 'CLT',
    28092: 'CLT',
    28093: 'CLT',
    28097: 'CLT',
    28098: 'CLT',
    28101: 'CLT',
    28102: 'CLT',
    28103: 'CLT',
    28104: 'CLT',
    28105: 'CLT',
    28106: 'CLT',
    28107: 'CLT',
    28108: 'CLT',
    28109: 'CLT',
    28110: 'CLT',
    28111: 'CLT',
    28112: 'CLT',
    28114: 'CLT',
    28115: 'CLT',
    28117: 'CLT',
    28119: 'CLT',
    28120: 'CLT',
    28123: 'CLT',
    28124: 'CLT',
    28125: 'CLT',
    28126: 'CLT',
    28127: 'CLT',
    28128: 'CLT',
    28129: 'CLT',
    28130: 'CLT',
    28133: 'CLT',
    28134: 'CLT',
    28135: 'CLT',
    28136: 'CLT',
    28137: 'CLT',
    28138: 'CLT',
    28139: 'CLT',
    28144: 'CLT',
    28145: 'CLT',
    28146: 'CLT',
    28147: 'CLT',
    28150: 'CLT',
    28151: 'CLT',
    28152: 'CLT',
    28159: 'CLT',
    28160: 'CLT',
    28163: 'CLT',
    28164: 'CLT',
    28166: 'CLT',
    28167: 'CLT',
    28168: 'CLT',
    28169: 'CLT',
    28170: 'CLT',
    28173: 'CLT',
    28174: 'CLT',
    28201: 'CLT',
    28202: 'CLT',
    28203: 'CLT',
    28204: 'CLT',
    28205: 'CLT',
    28206: 'CLT',
    28207: 'CLT',
    28208: 'CLT',
    28209: 'CLT',
    28210: 'CLT',
    28211: 'CLT',
    28212: 'CLT',
    28213: 'CLT',
    28214: 'CLT',
    28215: 'CLT',
    28216: 'CLT',
    28217: 'CLT',
    28218: 'CLT',
    28219: 'CLT',
    28220: 'CLT',
    28221: 'CLT',
    28222: 'CLT',
    28223: 'CLT',
    28224: 'CLT',
    28225: 'CLT',
    28226: 'CLT',
    28227: 'CLT',
    28228: 'CLT',
    28229: 'CLT',
    28230: 'CLT',
    28231: 'CLT',
    28232: 'CLT',
    28233: 'CLT',
    28234: 'CLT',
    28235: 'CLT',
    28236: 'CLT',
    28237: 'CLT',
    28240: 'CLT',
    28241: 'CLT',
    28242: 'CLT',
    28243: 'CLT',
    28244: 'CLT',
    28246: 'CLT',
    28247: 'CLT',
    28250: 'CLT',
    28253: 'CLT',
    28254: 'CLT',
    28255: 'CLT',
    28256: 'CLT',
    28258: 'CLT',
    28260: 'CLT',
    28261: 'CLT',
    28262: 'CLT',
    28263: 'CLT',
    28265: 'CLT',
    28266: 'CLT',
    28269: 'CLT',
    28270: 'CLT',
    28271: 'CLT',
    28272: 'CLT',
    28273: 'CLT',
    28274: 'CLT',
    28275: 'CLT',
    28277: 'CLT',
    28278: 'CLT',
    28280: 'CLT',
    28281: 'CLT',
    28282: 'CLT',
    28283: 'CLT',
    28284: 'CLT',
    28285: 'CLT',
    28286: 'CLT',
    28287: 'CLT',
    28288: 'CLT',
    28289: 'CLT',
    28290: 'CLT',
    28296: 'CLT',
    28297: 'CLT',
    28299: 'RDU',
    28301: 'RDU',
    28302: 'RDU',
    28303: 'RDU',
    28304: 'RDU',
    28305: 'RDU',
    28306: 'RDU',
    28307: 'RDU',
    28308: 'RDU',
    28309: 'RDU',
    28310: 'RDU',
    28311: 'RDU',
    28312: 'RDU',
    28314: 'RDU',
    28315: 'RDU',
    28318: 'RDU',
    28319: 'RDU',
    28320: 'RDU',
    28323: 'RDU',
    28325: 'RDU',
    28326: 'RDU',
    28327: 'RDU',
    28328: 'RDU',
    28329: 'RDU',
    28330: 'RDU',
    28331: 'RDU',
    28332: 'RDU',
    28333: 'RDU',
    28334: 'RDU',
    28335: 'RDU',
    28337: 'RDU',
    28338: 'RDU',
    28339: 'RDU',
    28340: 'RDU',
    28341: 'RDU',
    28342: 'RDU',
    28343: 'RDU',
    28344: 'RDU',
    28345: 'RDU',
    28347: 'RDU',
    28348: 'RDU',
    28349: 'RDU',
    28350: 'RDU',
    28351: 'RDU',
    28352: 'RDU',
    28353: 'RDU',
    28355: 'RDU',
    28356: 'RDU',
    28357: 'RDU',
    28358: 'RDU',
    28359: 'RDU',
    28360: 'RDU',
    28361: 'RDU',
    28362: 'RDU',
    28363: 'RDU',
    28364: 'RDU',
    28365: 'RDU',
    28366: 'RDU',
    28367: 'RDU',
    28368: 'RDU',
    28369: 'RDU',
    28370: 'RDU',
    28371: 'RDU',
    28372: 'RDU',
    28373: 'RDU',
    28374: 'RDU',
    28375: 'RDU',
    28376: 'RDU',
    28377: 'RDU',
    28378: 'RDU',
    28379: 'RDU',
    28380: 'RDU',
    28382: 'RDU',
    28383: 'RDU',
    28384: 'RDU',
    28385: 'RDU',
    28386: 'RDU',
    28387: 'RDU',
    28388: 'RDU',
    28390: 'RDU',
    28391: 'RDU',
    28392: 'RDU',
    28393: 'RDU',
    28394: 'RDU',
    28395: 'RDU',
    28396: 'RDU',
    28398: 'RDU',
    28399: 'RDU',
    28401: 'RDU',
    28402: 'RDU',
    28403: 'RDU',
    28404: 'RDU',
    28405: 'RDU',
    28406: 'RDU',
    28407: 'RDU',
    28408: 'RDU',
    28409: 'RDU',
    28410: 'RDU',
    28411: 'RDU',
    28412: 'RDU',
    28420: 'RDU',
    28421: 'RDU',
    28422: 'RDU',
    28423: 'RDU',
    28424: 'RDU',
    28425: 'RDU',
    28428: 'RDU',
    28429: 'RDU',
    28430: 'RDU',
    28431: 'RDU',
    28432: 'RDU',
    28433: 'RDU',
    28434: 'RDU',
    28435: 'RDU',
    28436: 'RDU',
    28438: 'RDU',
    28439: 'RDU',
    28441: 'RDU',
    28442: 'RDU',
    28443: 'RDU',
    28444: 'RDU',
    28445: 'RDU',
    28446: 'RDU',
    28447: 'RDU',
    28448: 'RDU',
    28449: 'RDU',
    28450: 'RDU',
    28451: 'RDU',
    28452: 'RDU',
    28453: 'RDU',
    28454: 'RDU',
    28455: 'RDU',
    28456: 'RDU',
    28457: 'RDU',
    28458: 'RDU',
    28459: 'RDU',
    28460: 'RDU',
    28461: 'RDU',
    28462: 'RDU',
    28463: 'RDU',
    28464: 'RDU',
    28465: 'RDU',
    28466: 'RDU',
    28467: 'RDU',
    28468: 'RDU',
    28469: 'RDU',
    28470: 'RDU',
    28471: 'RDU',
    28472: 'RDU',
    28478: 'RDU',
    28479: 'RDU',
    28480: 'RDU',
    28501: 'RDU',
    28502: 'RDU',
    28503: 'RDU',
    28504: 'RDU',
    28508: 'RDU',
    28509: 'RDU',
    28510: 'RDU',
    28511: 'RDU',
    28512: 'RDU',
    28513: 'RDU',
    28515: 'RDU',
    28516: 'RDU',
    28518: 'RDU',
    28519: 'RDU',
    28520: 'RDU',
    28521: 'RDU',
    28522: 'RDU',
    28523: 'RDU',
    28524: 'RDU',
    28525: 'RDU',
    28526: 'RDU',
    28527: 'RDU',
    28528: 'RDU',
    28529: 'RDU',
    28530: 'RDU',
    28531: 'RDU',
    28532: 'RDU',
    28533: 'RDU',
    28537: 'RDU',
    28538: 'RDU',
    28539: 'RDU',
    28540: 'RDU',
    28541: 'RDU',
    28542: 'RDU',
    28543: 'RDU',
    28544: 'RDU',
    28545: 'RDU',
    28546: 'RDU',
    28547: 'RDU',
    28551: 'RDU',
    28552: 'RDU',
    28553: 'RDU',
    28554: 'RDU',
    28555: 'RDU',
    28556: 'RDU',
    28557: 'RDU',
    28560: 'RDU',
    28561: 'RDU',
    28562: 'RDU',
    28563: 'RDU',
    28564: 'RDU',
    28570: 'RDU',
    28571: 'RDU',
    28572: 'RDU',
    28573: 'RDU',
    28574: 'RDU',
    28575: 'RDU',
    28577: 'RDU',
    28578: 'RDU',
    28579: 'RDU',
    28580: 'RDU',
    28581: 'RDU',
    28582: 'RDU',
    28583: 'RDU',
    28584: 'RDU',
    28585: 'RDU',
    28586: 'RDU',
    28587: 'RDU',
    28589: 'RDU',
    28590: 'RDU',
    28594: 'CLT',
    28601: 'CLT',
    28602: 'CLT',
    28603: 'CLT',
    28604: 'CLT',
    28605: 'CLT',
    28606: 'CLT',
    28607: 'CLT',
    28608: 'CLT',
    28609: 'CLT',
    28610: 'CLT',
    28611: 'CLT',
    28612: 'CLT',
    28613: 'CLT',
    28615: 'CLT',
    28616: 'CLT',
    28617: 'CLT',
    28618: 'CLT',
    28619: 'CLT',
    28621: 'CLT',
    28622: 'TYS',
    28623: 'CLT',
    28624: 'CLT',
    28625: 'CLT',
    28626: 'CLT',
    28627: 'CLT',
    28628: 'CLT',
    28629: 'CLT',
    28630: 'CLT',
    28631: 'CLT',
    28633: 'CLT',
    28634: 'CLT',
    28635: 'CLT',
    28636: 'CLT',
    28637: 'CLT',
    28638: 'CLT',
    28640: 'CLT',
    28641: 'CLT',
    28642: 'CLT',
    28643: 'CLT',
    28644: 'CLT',
    28645: 'CLT',
    28646: 'CLT',
    28647: 'CLT',
    28649: 'CLT',
    28650: 'CLT',
    28651: 'CLT',
    28652: 'TYS',
    28653: 'CLT',
    28654: 'CLT',
    28655: 'CLT',
    28656: 'CLT',
    28657: 'TYS',
    28658: 'CLT',
    28659: 'CLT',
    28660: 'CLT',
    28661: 'CLT',
    28662: 'CLT',
    28663: 'CLT',
    28664: 'TYS',
    28665: 'CLT',
    28666: 'CLT',
    28667: 'CLT',
    28668: 'CLT',
    28669: 'CLT',
    28670: 'CLT',
    28671: 'CLT',
    28672: 'CLT',
    28673: 'CLT',
    28674: 'CLT',
    28675: 'CLT',
    28676: 'CLT',
    28677: 'CLT',
    28678: 'CLT',
    28679: 'TYS',
    28680: 'CLT',
    28681: 'CLT',
    28682: 'CLT',
    28683: 'CLT',
    28684: 'CLT',
    28685: 'CLT',
    28687: 'CLT',
    28688: 'CLT',
    28689: 'CLT',
    28690: 'CLT',
    28691: 'CLT',
    28692: 'CLT',
    28693: 'CLT',
    28694: 'CLT',
    28697: 'CLT',
    28698: 'CLT',
    28699: 'CLT',
    28701: 'TYS',
    28702: 'TYS',
    28704: 'TYS',
    28705: 'TYS',
    28707: 'TYS',
    28708: 'TYS',
    28709: 'TYS',
    28710: 'TYS',
    28711: 'TYS',
    28712: 'TYS',
    28713: 'TYS',
    28714: 'TYS',
    28715: 'TYS',
    28716: 'TYS',
    28717: 'TYS',
    28718: 'TYS',
    28719: 'TYS',
    28720: 'CLT',
    28721: 'TYS',
    28722: 'CLT',
    28723: 'TYS',
    28724: 'TYS',
    28725: 'TYS',
    28726: 'TYS',
    28727: 'TYS',
    28728: 'TYS',
    28729: 'TYS',
    28730: 'TYS',
    28731: 'TYS',
    28732: 'TYS',
    28733: 'TYS',
    28734: 'TYS',
    28735: 'TYS',
    28736: 'TYS',
    28737: 'CLT',
    28738: 'TYS',
    28739: 'TYS',
    28740: 'TYS',
    28741: 'TYS',
    28742: 'TYS',
    28743: 'TYS',
    28744: 'TYS',
    28745: 'TYS',
    28746: 'CLT',
    28747: 'TYS',
    28748: 'TYS',
    28749: 'TYS',
    28750: 'CLT',
    28751: 'TYS',
    28752: 'CLT',
    28753: 'TYS',
    28754: 'TYS',
    28755: 'TYS',
    28756: 'CLT',
    28757: 'TYS',
    28758: 'TYS',
    28759: 'TYS',
    28760: 'TYS',
    28761: 'CLT',
    28762: 'TYS',
    28763: 'TYS',
    28765: 'TYS',
    28766: 'TYS',
    28768: 'TYS',
    28770: 'TYS',
    28771: 'TYS',
    28772: 'TYS',
    28773: 'CLT',
    28774: 'TYS',
    28775: 'TYS',
    28776: 'TYS',
    28777: 'TYS',
    28778: 'TYS',
    28779: 'TYS',
    28781: 'TYS',
    28782: 'CLT',
    28783: 'TYS',
    28784: 'TYS',
    28785: 'TYS',
    28786: 'TYS',
    28787: 'TYS',
    28788: 'TYS',
    28789: 'TYS',
    28790: 'TYS',
    28791: 'TYS',
    28792: 'TYS',
    28793: 'TYS',
    28801: 'TYS',
    28802: 'TYS',
    28803: 'TYS',
    28804: 'TYS',
    28805: 'TYS',
    28806: 'TYS',
    28810: 'TYS',
    28813: 'TYS',
    28814: 'TYS',
    28815: 'TYS',
    28816: 'TYS',
    28901: 'TYS',
    28902: 'TYS',
    28903: 'CLT',
    28904: 'TYS',
    28905: 'TYS',
    28906: 'TYS',
    28909: 'TYS',
    29001: 'CAE',
    29002: 'CAE',
    29003: 'CAE',
    29006: 'CAE',
    29009: 'CAE',
    29010: 'CAE',
    29014: 'CAE',
    29015: 'CAE',
    29016: 'CAE',
    29018: 'CAE',
    29020: 'CAE',
    29021: 'CAE',
    29030: 'CAE',
    29031: 'CAE',
    29032: 'CAE',
    29033: 'CAE',
    29036: 'CAE',
    29037: 'CAE',
    29038: 'CAE',
    29039: 'CAE',
    29040: 'CAE',
    29041: 'CAE',
    29042: 'CAE',
    29044: 'CAE',
    29045: 'CAE',
    29046: 'CAE',
    29047: 'CAE',
    29048: 'CAE',
    29051: 'CAE',
    29052: 'CAE',
    29053: 'CAE',
    29054: 'CAE',
    29055: 'CAE',
    29056: 'CAE',
    29058: 'CAE',
    29059: 'CAE',
    29061: 'CAE',
    29062: 'CAE',
    29063: 'CAE',
    29065: 'CAE',
    29067: 'CAE',
    29069: 'CAE',
    29070: 'CAE',
    29071: 'CAE',
    29072: 'CAE',
    29073: 'CAE',
    29074: 'CAE',
    29075: 'CAE',
    29078: 'CAE',
    29079: 'CAE',
    29080: 'CAE',
    29081: 'CAE',
    29082: 'CAE',
    29101: 'CAE',
    29102: 'CAE',
    29104: 'CAE',
    29105: 'CAE',
    29106: 'CAE',
    29107: 'CAE',
    29108: 'CAE',
    29111: 'CAE',
    29112: 'CAE',
    29113: 'CAE',
    29114: 'CAE',
    29115: 'CAE',
    29116: 'CAE',
    29117: 'CAE',
    29118: 'CAE',
    29122: 'CAE',
    29123: 'CAE',
    29125: 'CAE',
    29126: 'CAE',
    29127: 'CAE',
    29128: 'CAE',
    29129: 'CAE',
    29130: 'CAE',
    29132: 'CAE',
    29133: 'CAE',
    29135: 'CAE',
    29137: 'CAE',
    29138: 'CAE',
    29142: 'CAE',
    29143: 'CAE',
    29145: 'CAE',
    29146: 'CAE',
    29147: 'CAE',
    29148: 'CAE',
    29150: 'CAE',
    29151: 'CAE',
    29152: 'CAE',
    29153: 'CAE',
    29154: 'CAE',
    29160: 'CAE',
    29161: 'CAE',
    29162: 'CAE',
    29163: 'CAE',
    29164: 'CAE',
    29166: 'CAE',
    29168: 'CAE',
    29169: 'CAE',
    29170: 'CAE',
    29171: 'CAE',
    29172: 'CAE',
    29175: 'CAE',
    29176: 'CAE',
    29177: 'CAE',
    29178: 'CAE',
    29180: 'CAE',
    29201: 'CAE',
    29202: 'CAE',
    29203: 'CAE',
    29204: 'CAE',
    29205: 'CAE',
    29206: 'CAE',
    29207: 'CAE',
    29208: 'CAE',
    29209: 'CAE',
    29210: 'CAE',
    29211: 'CAE',
    29212: 'CAE',
    29214: 'CAE',
    29215: 'CAE',
    29216: 'CAE',
    29217: 'CAE',
    29218: 'CAE',
    29219: 'CAE',
    29220: 'CAE',
    29221: 'CAE',
    29222: 'CAE',
    29223: 'CAE',
    29224: 'CAE',
    29225: 'CAE',
    29226: 'CAE',
    29227: 'CAE',
    29228: 'CAE',
    29229: 'CAE',
    29230: 'CAE',
    29240: 'CAE',
    29250: 'CAE',
    29260: 'CAE',
    29290: 'CAE',
    29292: 'CHS',
    29301: 'CHS',
    29302: 'CHS',
    29303: 'CHS',
    29304: 'CHS',
    29305: 'CHS',
    29306: 'CHS',
    29307: 'CHS',
    29316: 'CHS',
    29318: 'CHS',
    29319: 'CHS',
    29320: 'CHS',
    29321: 'CHS',
    29322: 'CHS',
    29323: 'CHS',
    29324: 'CHS',
    29325: 'CHS',
    29329: 'CHS',
    29330: 'CHS',
    29331: 'CHS',
    29332: 'CHS',
    29333: 'CHS',
    29334: 'CHS',
    29335: 'CHS',
    29336: 'CHS',
    29338: 'CHS',
    29340: 'CHS',
    29341: 'CHS',
    29342: 'CHS',
    29346: 'CHS',
    29348: 'CHS',
    29349: 'CHS',
    29351: 'CHS',
    29353: 'CHS',
    29355: 'CHS',
    29356: 'CHS',
    29360: 'CHS',
    29364: 'CHS',
    29365: 'CHS',
    29368: 'CHS',
    29369: 'CHS',
    29370: 'CHS',
    29372: 'CHS',
    29373: 'CHS',
    29374: 'CHS',
    29375: 'CHS',
    29376: 'CHS',
    29377: 'CHS',
    29378: 'CHS',
    29379: 'CHS',
    29384: 'CHS',
    29385: 'CHS',
    29386: 'CHS',
    29388: 'CHS',
    29390: 'CHS',
    29391: 'CHS',
    29395: 'CAE',
    29401: 'CAE',
    29402: 'CAE',
    29403: 'CAE',
    29404: 'CAE',
    29405: 'CAE',
    29406: 'CAE',
    29407: 'CAE',
    29409: 'CAE',
    29410: 'CAE',
    29412: 'CAE',
    29413: 'CAE',
    29414: 'CAE',
    29415: 'CAE',
    29416: 'CAE',
    29417: 'CAE',
    29418: 'CAE',
    29419: 'CAE',
    29420: 'CAE',
    29422: 'CAE',
    29423: 'CAE',
    29424: 'CAE',
    29425: 'CAE',
    29426: 'CAE',
    29429: 'CAE',
    29430: 'CAE',
    29431: 'CAE',
    29432: 'CAE',
    29433: 'CAE',
    29434: 'CAE',
    29435: 'CAE',
    29436: 'CAE',
    29437: 'CAE',
    29438: 'CAE',
    29439: 'CAE',
    29440: 'CAE',
    29442: 'CAE',
    29445: 'CAE',
    29446: 'CAE',
    29447: 'CAE',
    29448: 'CAE',
    29449: 'CAE',
    29450: 'CAE',
    29451: 'CAE',
    29452: 'CAE',
    29453: 'CAE',
    29455: 'CAE',
    29456: 'CAE',
    29457: 'CAE',
    29458: 'CAE',
    29461: 'CAE',
    29464: 'CAE',
    29465: 'CAE',
    29466: 'CAE',
    29468: 'CAE',
    29469: 'CAE',
    29470: 'CAE',
    29471: 'CAE',
    29472: 'CAE',
    29474: 'CAE',
    29475: 'CAE',
    29476: 'CAE',
    29477: 'CAE',
    29479: 'CAE',
    29481: 'CAE',
    29482: 'CAE',
    29483: 'CAE',
    29484: 'CAE',
    29485: 'CAE',
    29486: 'CAE',
    29487: 'CAE',
    29488: 'CAE',
    29492: 'CAE',
    29493: 'CAE',
    29501: 'CAE',
    29502: 'CAE',
    29503: 'CAE',
    29504: 'CAE',
    29505: 'CAE',
    29506: 'CAE',
    29510: 'CAE',
    29511: 'CAE',
    29512: 'CAE',
    29516: 'CAE',
    29518: 'CAE',
    29519: 'CHS',
    29520: 'CAE',
    29525: 'CAE',
    29526: 'CAE',
    29527: 'CAE',
    29528: 'CAE',
    29530: 'CAE',
    29532: 'CAE',
    29536: 'CAE',
    29540: 'CAE',
    29541: 'CAE',
    29542: 'CAE',
    29543: 'CAE',
    29544: 'CAE',
    29545: 'CAE',
    29546: 'CAE',
    29547: 'CAE',
    29550: 'CAE',
    29551: 'CAE',
    29554: 'CAE',
    29555: 'CAE',
    29556: 'CAE',
    29560: 'CAE',
    29563: 'CAE',
    29564: 'CAE',
    29565: 'CAE',
    29566: 'CAE',
    29567: 'CAE',
    29568: 'CAE',
    29569: 'CAE',
    29570: 'CAE',
    29571: 'CAE',
    29572: 'CHS',
    29573: 'CAE',
    29574: 'CAE',
    29575: 'CAE',
    29576: 'CAE',
    29577: 'CAE',
    29578: 'CAE',
    29579: 'CAE',
    29580: 'CAE',
    29581: 'CAE',
    29582: 'CAE',
    29583: 'CAE',
    29584: 'CAE',
    29585: 'CAE',
    29587: 'CAE',
    29588: 'CAE',
    29589: 'CAE',
    29590: 'CAE',
    29591: 'CAE',
    29592: 'CAE',
    29593: 'CAE',
    29594: 'CHS',
    29596: 'CAE',
    29597: 'CAE',
    29598: 'CHS',
    29601: 'CHS',
    29602: 'CHS',
    29603: 'CHS',
    29604: 'CHS',
    29605: 'CHS',
    29606: 'CHS',
    29607: 'CHS',
    29608: 'CHS',
    29609: 'CHS',
    29610: 'CHS',
    29611: 'CHS',
    29612: 'CHS',
    29613: 'CHS',
    29614: 'CHS',
    29615: 'CHS',
    29616: 'CHS',
    29617: 'CHS',
    29620: 'CHS',
    29621: 'CHS',
    29622: 'CHS',
    29623: 'CHS',
    29624: 'CHS',
    29625: 'CHS',
    29626: 'CHS',
    29627: 'CHS',
    29628: 'CHS',
    29630: 'CHS',
    29631: 'CHS',
    29632: 'CHS',
    29633: 'CHS',
    29634: 'CHS',
    29635: 'CHS',
    29636: 'CHS',
    29638: 'CHS',
    29639: 'CHS',
    29640: 'CHS',
    29641: 'CHS',
    29642: 'CHS',
    29643: 'CHS',
    29644: 'CHS',
    29645: 'CHS',
    29646: 'CHS',
    29647: 'CHS',
    29648: 'CHS',
    29649: 'CHS',
    29650: 'CHS',
    29651: 'CHS',
    29652: 'CHS',
    29653: 'CHS',
    29654: 'CHS',
    29655: 'CHS',
    29656: 'CHS',
    29657: 'CHS',
    29658: 'CHS',
    29659: 'CHS',
    29661: 'CHS',
    29662: 'CHS',
    29664: 'CHS',
    29665: 'CHS',
    29666: 'CHS',
    29667: 'CHS',
    29669: 'CHS',
    29670: 'CHS',
    29671: 'CHS',
    29672: 'CHS',
    29673: 'CHS',
    29675: 'CHS',
    29676: 'CHS',
    29677: 'CHS',
    29678: 'CHS',
    29679: 'CHS',
    29680: 'CHS',
    29681: 'CHS',
    29682: 'CHS',
    29683: 'CHS',
    29684: 'CHS',
    29685: 'CHS',
    29686: 'CHS',
    29687: 'CHS',
    29688: 'CHS',
    29689: 'CHS',
    29690: 'CHS',
    29691: 'CHS',
    29692: 'CHS',
    29693: 'CHS',
    29695: 'CHS',
    29696: 'CHS',
    29697: 'CHS',
    29698: 'CHS',
    29702: 'CHS',
    29703: 'CLT',
    29704: 'CLT',
    29706: 'CLT',
    29707: 'CLT',
    29708: 'CLT',
    29709: 'CLT',
    29710: 'CLT',
    29712: 'CLT',
    29714: 'CLT',
    29715: 'CLT',
    29716: 'CLT',
    29717: 'CLT',
    29718: 'CLT',
    29720: 'CLT',
    29721: 'CLT',
    29722: 'CLT',
    29724: 'CLT',
    29726: 'CLT',
    29727: 'CLT',
    29728: 'CLT',
    29729: 'CLT',
    29730: 'CLT',
    29731: 'CLT',
    29732: 'CLT',
    29733: 'CLT',
    29734: 'CLT',
    29741: 'CLT',
    29742: 'CLT',
    29743: 'CLT',
    29744: 'CLT',
    29745: 'CLT',
    29801: 'CAE',
    29802: 'CAE',
    29803: 'CAE',
    29804: 'CAE',
    29805: 'CAE',
    29808: 'CAE',
    29809: 'CAE',
    29810: 'CAE',
    29812: 'CAE',
    29813: 'CAE',
    29816: 'CAE',
    29817: 'CAE',
    29819: 'CAE',
    29821: 'CAE',
    29822: 'CAE',
    29824: 'CAE',
    29826: 'CAE',
    29827: 'CAE',
    29828: 'CAE',
    29829: 'CAE',
    29831: 'CAE',
    29832: 'CAE',
    29834: 'CAE',
    29835: 'CAE',
    29836: 'CAE',
    29838: 'CAE',
    29839: 'CAE',
    29840: 'CAE',
    29841: 'CAE',
    29842: 'CAE',
    29843: 'CAE',
    29844: 'CAE',
    29845: 'CAE',
    29846: 'CAE',
    29847: 'CAE',
    29848: 'CAE',
    29849: 'CAE',
    29850: 'CAE',
    29851: 'CAE',
    29853: 'CAE',
    29856: 'CAE',
    29860: 'CAE',
    29861: 'CAE',
    29899: 'CAE',
    29901: 'CAE',
    29902: 'CAE',
    29903: 'CAE',
    29904: 'CAE',
    29905: 'CAE',
    29906: 'CAE',
    29907: 'CAE',
    29909: 'CAE',
    29910: 'CAE',
    29911: 'CAE',
    29912: 'CAE',
    29913: 'CAE',
    29914: 'CAE',
    29915: 'CAE',
    29916: 'CAE',
    29918: 'CAE',
    29920: 'CAE',
    29921: 'CAE',
    29922: 'CAE',
    29923: 'CAE',
    29924: 'CAE',
    29925: 'CAE',
    29926: 'CAE',
    29927: 'CAE',
    29928: 'CAE',
    29929: 'CAE',
    29931: 'CAE',
    29932: 'CAE',
    29933: 'CAE',
    29934: 'CAE',
    29935: 'CAE',
    29936: 'CAE',
    29938: 'CAE',
    29939: 'CAE',
    29940: 'CAE',
    29941: 'CAE',
    29943: 'CAE',
    29944: 'CAE',
    29945: 'CAE',
    30002: 'ATL',
    30003: 'ATL',
    30004: 'ATL',
    30005: 'ATL',
    30006: 'ATL',
    30007: 'ATL',
    30008: 'ATL',
    30009: 'ATL',
    30010: 'ATL',
    30011: 'ATL',
    30012: 'ATL',
    30013: 'ATL',
    30014: 'ATL',
    30015: 'ATL',
    30016: 'ATL',
    30017: 'ATL',
    30018: 'ATL',
    30019: 'ATL',
    30021: 'ATL',
    30022: 'ATL',
    30023: 'ATL',
    30024: 'ATL',
    30025: 'ATL',
    30026: 'ATL',
    30028: 'ATL',
    30029: 'ATL',
    30030: 'ATL',
    30031: 'ATL',
    30032: 'ATL',
    30033: 'ATL',
    30034: 'ATL',
    30035: 'ATL',
    30036: 'ATL',
    30037: 'ATL',
    30038: 'ATL',
    30039: 'ATL',
    30040: 'ATL',
    30041: 'ATL',
    30042: 'ATL',
    30043: 'ATL',
    30044: 'ATL',
    30045: 'ATL',
    30046: 'ATL',
    30047: 'ATL',
    30048: 'ATL',
    30049: 'ATL',
    30052: 'ATL',
    30054: 'ATL',
    30055: 'ATL',
    30056: 'ATL',
    30058: 'ATL',
    30060: 'ATL',
    30061: 'ATL',
    30062: 'ATL',
    30063: 'ATL',
    30064: 'ATL',
    30065: 'ATL',
    30066: 'ATL',
    30067: 'ATL',
    30068: 'ATL',
    30069: 'ATL',
    30070: 'ATL',
    30071: 'ATL',
    30072: 'ATL',
    30073: 'ATL',
    30074: 'ATL',
    30075: 'ATL',
    30076: 'ATL',
    30077: 'ATL',
    30078: 'ATL',
    30079: 'ATL',
    30080: 'ATL',
    30081: 'ATL',
    30082: 'ATL',
    30083: 'ATL',
    30084: 'ATL',
    30085: 'ATL',
    30086: 'ATL',
    30087: 'ATL',
    30088: 'ATL',
    30090: 'ATL',
    30091: 'ATL',
    30092: 'ATL',
    30093: 'ATL',
    30094: 'ATL',
    30095: 'ATL',
    30096: 'ATL',
    30097: 'ATL',
    30098: 'ATL',
    30099: 'ATL',
    30101: 'ATL',
    30102: 'ATL',
    30103: 'ATL',
    30104: 'ATL',
    30105: 'CHA',
    30106: 'ATL',
    30107: 'ATL',
    30108: 'ATL',
    30109: 'ATL',
    30110: 'ATL',
    30111: 'ATL',
    30112: 'ATL',
    30113: 'ATL',
    30114: 'ATL',
    30115: 'ATL',
    30116: 'ATL',
    30117: 'ATL',
    30118: 'ATL',
    30119: 'ATL',
    30120: 'ATL',
    30121: 'ATL',
    30122: 'ATL',
    30123: 'ATL',
    30124: 'ATL',
    30125: 'ATL',
    30126: 'ATL',
    30127: 'ATL',
    30129: 'ATL',
    30132: 'ATL',
    30133: 'ATL',
    30134: 'ATL',
    30135: 'ATL',
    30137: 'ATL',
    30138: 'ATL',
    30139: 'CHA',
    30140: 'ATL',
    30141: 'ATL',
    30142: 'ATL',
    30143: 'CHA',
    30144: 'ATL',
    30145: 'ATL',
    30146: 'ATL',
    30147: 'ATL',
    30148: 'CHA',
    30149: 'ATL',
    30150: 'ATL',
    30151: 'ATL',
    30152: 'ATL',
    30153: 'ATL',
    30154: 'ATL',
    30156: 'ATL',
    30157: 'ATL',
    30160: 'ATL',
    30161: 'ATL',
    30162: 'ATL',
    30163: 'ATL',
    30164: 'ATL',
    30165: 'ATL',
    30168: 'ATL',
    30169: 'ATL',
    30170: 'ATL',
    30171: 'ATL',
    30172: 'ATL',
    30173: 'ATL',
    30175: 'CHA',
    30176: 'ATL',
    30177: 'CHA',
    30178: 'ATL',
    30179: 'ATL',
    30180: 'ATL',
    30182: 'ATL',
    30183: 'ATL',
    30184: 'ATL',
    30185: 'ATL',
    30187: 'ATL',
    30188: 'ATL',
    30189: 'ATL',
    30204: 'MCN',
    30205: 'MCN',
    30206: 'MCN',
    30212: 'MCN',
    30213: 'ATL',
    30214: 'ATL',
    30215: 'ATL',
    30216: 'MCN',
    30217: 'ATL',
    30218: 'MCN',
    30219: 'ATL',
    30220: 'MCN',
    30222: 'MCN',
    30223: 'MCN',
    30224: 'MCN',
    30228: 'ATL',
    30229: 'MCN',
    30230: 'MCN',
    30233: 'MCN',
    30234: 'MCN',
    30236: 'ATL',
    30237: 'ATL',
    30238: 'ATL',
    30240: 'MCN',
    30241: 'MCN',
    30248: 'ATL',
    30250: 'ATL',
    30251: 'MCN',
    30252: 'ATL',
    30253: 'ATL',
    30256: 'MCN',
    30257: 'MCN',
    30258: 'MCN',
    30259: 'MCN',
    30260: 'ATL',
    30261: 'MCN',
    30263: 'ATL',
    30264: 'ATL',
    30265: 'ATL',
    30266: 'MCN',
    30268: 'ATL',
    30269: 'ATL',
    30270: 'ATL',
    30271: 'ATL',
    30272: 'ATL',
    30273: 'ATL',
    30274: 'ATL',
    30275: 'ATL',
    30276: 'MCN',
    30277: 'ATL',
    30281: 'ATL',
    30284: 'ATL',
    30285: 'MCN',
    30286: 'MCN',
    30287: 'ATL',
    30288: 'ATL',
    30289: 'ATL',
    30290: 'ATL',
    30291: 'ATL',
    30292: 'MCN',
    30293: 'MCN',
    30294: 'ATL',
    30295: 'MCN',
    30296: 'ATL',
    30297: 'ATL',
    30298: 'ATL',
    30301: 'ATL',
    30302: 'ATL',
    30303: 'ATL',
    30304: 'ATL',
    30305: 'ATL',
    30306: 'ATL',
    30307: 'ATL',
    30308: 'ATL',
    30309: 'ATL',
    30310: 'ATL',
    30311: 'ATL',
    30312: 'ATL',
    30313: 'ATL',
    30314: 'ATL',
    30315: 'ATL',
    30316: 'ATL',
    30317: 'ATL',
    30318: 'ATL',
    30319: 'ATL',
    30320: 'ATL',
    30321: 'ATL',
    30322: 'ATL',
    30324: 'ATL',
    30325: 'ATL',
    30326: 'ATL',
    30327: 'ATL',
    30328: 'ATL',
    30329: 'ATL',
    30330: 'ATL',
    30331: 'ATL',
    30332: 'ATL',
    30333: 'ATL',
    30334: 'ATL',
    30336: 'ATL',
    30337: 'ATL',
    30338: 'ATL',
    30339: 'ATL',
    30340: 'ATL',
    30341: 'ATL',
    30342: 'ATL',
    30343: 'ATL',
    30344: 'ATL',
    30345: 'ATL',
    30346: 'ATL',
    30347: 'ATL',
    30348: 'ATL',
    30349: 'ATL',
    30350: 'ATL',
    30353: 'ATL',
    30354: 'ATL',
    30355: 'ATL',
    30356: 'ATL',
    30357: 'ATL',
    30358: 'ATL',
    30359: 'ATL',
    30360: 'ATL',
    30361: 'ATL',
    30362: 'ATL',
    30363: 'ATL',
    30364: 'ATL',
    30366: 'ATL',
    30368: 'ATL',
    30369: 'ATL',
    30370: 'ATL',
    30371: 'ATL',
    30374: 'ATL',
    30375: 'ATL',
    30376: 'ATL',
    30377: 'ATL',
    30378: 'ATL',
    30379: 'ATL',
    30380: 'ATL',
    30384: 'ATL',
    30385: 'ATL',
    30386: 'ATL',
    30387: 'ATL',
    30388: 'ATL',
    30389: 'ATL',
    30390: 'ATL',
    30392: 'ATL',
    30394: 'ATL',
    30396: 'ATL',
    30398: 'ATL',
    30399: 'ATL',
    30401: 'SAV',
    30410: 'SAV',
    30411: 'ABY',
    30412: 'SAV',
    30413: 'AGS',
    30414: 'SAV',
    30415: 'SAV',
    30417: 'SAV',
    30420: 'SAV',
    30421: 'SAV',
    30423: 'SAV',
    30424: 'SAV',
    30425: 'SAV',
    30426: 'AGS',
    30427: 'SAV',
    30428: 'SAV',
    30429: 'SAV',
    30434: 'AGS',
    30436: 'SAV',
    30438: 'SAV',
    30439: 'SAV',
    30441: 'AGS',
    30442: 'AGS',
    30445: 'SAV',
    30446: 'SAV',
    30447: 'SAV',
    30448: 'SAV',
    30449: 'SAV',
    30450: 'SAV',
    30451: 'SAV',
    30452: 'SAV',
    30453: 'SAV',
    30454: 'AGS',
    30455: 'SAV',
    30456: 'AGS',
    30457: 'SAV',
    30458: 'SAV',
    30459: 'SAV',
    30460: 'SAV',
    30461: 'SAV',
    30464: 'SAV',
    30467: 'SAV',
    30470: 'SAV',
    30471: 'SAV',
    30473: 'SAV',
    30474: 'SAV',
    30475: 'SAV',
    30477: 'AGS',
    30499: 'SAV',
    30501: 'ATL',
    30502: 'ATL',
    30503: 'ATL',
    30504: 'ATL',
    30506: 'ATL',
    30507: 'ATL',
    30510: 'ATL',
    30511: 'ATL',
    30512: 'CHA',
    30513: 'CHA',
    30514: 'CHA',
    30515: 'ATL',
    30516: 'ATL',
    30517: 'ATL',
    30518: 'ATL',
    30519: 'ATL',
    30520: 'ATL',
    30521: 'ATL',
    30522: 'CHA',
    30523: 'CHA',
    30525: 'CHA',
    30527: 'CHA',
    30528: 'CHA',
    30529: 'ATL',
    30530: 'ATL',
    30531: 'CHA',
    30533: 'CHA',
    30534: 'ATL',
    30535: 'CHA',
    30536: 'CHA',
    30537: 'CHA',
    30538: 'ATL',
    30539: 'CHA',
    30540: 'CHA',
    30541: 'CHA',
    30542: 'ATL',
    30543: 'ATL',
    30544: 'CHA',
    30545: 'CHA',
    30546: 'CHA',
    30547: 'ATL',
    30548: 'ATL',
    30549: 'ATL',
    30552: 'CHA',
    30553: 'ATL',
    30554: 'ATL',
    30555: 'CHA',
    30557: 'ATL',
    30558: 'ATL',
    30559: 'CHA',
    30560: 'CHA',
    30562: 'CHA',
    30563: 'CHA',
    30564: 'ATL',
    30565: 'ATL',
    30566: 'ATL',
    30567: 'ATL',
    30568: 'CHA',
    30571: 'CHA',
    30572: 'CHA',
    30573: 'CHA',
    30575: 'ATL',
    30576: 'CHA',
    30577: 'CHA',
    30580: 'CHA',
    30581: 'CHA',
    30582: 'CHA',
    30596: 'ATL',
    30597: 'CHA',
    30598: 'CHA',
    30599: 'ATL',
    30601: 'ATL',
    30602: 'ATL',
    30603: 'ATL',
    30604: 'ATL',
    30605: 'ATL',
    30606: 'ATL',
    30607: 'ATL',
    30608: 'ATL',
    30609: 'ATL',
    30612: 'ATL',
    30619: 'ATL',
    30620: 'ATL',
    30621: 'ATL',
    30622: 'ATL',
    30623: 'ATL',
    30624: 'ATL',
    30625: 'ATL',
    30627: 'ATL',
    30628: 'ATL',
    30629: 'ATL',
    30630: 'ATL',
    30631: 'AGS',
    30633: 'ATL',
    30634: 'ATL',
    30635: 'ATL',
    30638: 'ATL',
    30639: 'ATL',
    30641: 'ATL',
    30642: 'ATL',
    30643: 'ATL',
    30645: 'ATL',
    30646: 'ATL',
    30647: 'ATL',
    30648: 'ATL',
    30650: 'ATL',
    30655: 'ATL',
    30656: 'ATL',
    30660: 'ATL',
    30662: 'ATL',
    30663: 'ATL',
    30664: 'AGS',
    30665: 'AGS',
    30666: 'ATL',
    30667: 'ATL',
    30668: 'ATL',
    30669: 'ATL',
    30671: 'ATL',
    30673: 'ATL',
    30677: 'ATL',
    30678: 'AGS',
    30680: 'ATL',
    30683: 'ATL',
    30701: 'CHA',
    30703: 'CHA',
    30705: 'CHA',
    30707: 'CHA',
    30708: 'CHA',
    30710: 'CHA',
    30711: 'CHA',
    30719: 'CHA',
    30720: 'CHA',
    30721: 'CHA',
    30722: 'CHA',
    30724: 'CHA',
    30725: 'CHA',
    30726: 'CHA',
    30728: 'CHA',
    30730: 'CHA',
    30731: 'CHA',
    30732: 'CHA',
    30733: 'CHA',
    30734: 'CHA',
    30735: 'CHA',
    30736: 'CHA',
    30738: 'CHA',
    30739: 'CHA',
    30740: 'CHA',
    30741: 'CHA',
    30742: 'CHA',
    30746: 'CHA',
    30747: 'CHA',
    30750: 'CHA',
    30751: 'CHA',
    30752: 'CHA',
    30753: 'CHA',
    30755: 'CHA',
    30756: 'CHA',
    30757: 'CHA',
    30802: 'AGS',
    30803: 'AGS',
    30805: 'AGS',
    30806: 'AGS',
    30807: 'AGS',
    30808: 'AGS',
    30809: 'AGS',
    30810: 'AGS',
    30811: 'AGS',
    30812: 'AGS',
    30813: 'AGS',
    30814: 'AGS',
    30815: 'AGS',
    30816: 'AGS',
    30817: 'AGS',
    30818: 'AGS',
    30819: 'AGS',
    30820: 'AGS',
    30821: 'AGS',
    30822: 'AGS',
    30823: 'AGS',
    30824: 'AGS',
    30828: 'AGS',
    30830: 'AGS',
    30833: 'AGS',
    30901: 'AGS',
    30903: 'AGS',
    30904: 'AGS',
    30905: 'AGS',
    30906: 'AGS',
    30907: 'AGS',
    30909: 'AGS',
    30911: 'AGS',
    30912: 'AGS',
    30913: 'AGS',
    30914: 'AGS',
    30916: 'AGS',
    30917: 'AGS',
    30919: 'AGS',
    30999: 'AGS',
    31001: 'ABY',
    31002: 'AGS',
    31003: 'MCN',
    31004: 'MCN',
    31005: 'MCN',
    31006: 'MCN',
    31007: 'ABY',
    31008: 'MCN',
    31009: 'ABY',
    31010: 'ABY',
    31011: 'ABY',
    31012: 'MCN',
    31013: 'MCN',
    31014: 'MCN',
    31015: 'ABY',
    31016: 'MCN',
    31017: 'MCN',
    31018: 'AGS',
    31019: 'MCN',
    31020: 'MCN',
    31021: 'MCN',
    31022: 'MCN',
    31023: 'ABY',
    31024: 'MCN',
    31025: 'MCN',
    31026: 'MCN',
    31027: 'AGS',
    31028: 'MCN',
    31029: 'MCN',
    31030: 'MCN',
    31031: 'MCN',
    31032: 'MCN',
    31033: 'MCN',
    31034: 'MCN',
    31035: 'AGS',
    31036: 'ABY',
    31037: 'ABY',
    31038: 'MCN',
    31039: 'MCN',
    31040: 'MCN',
    31041: 'MCN',
    31042: 'MCN',
    31044: 'MCN',
    31045: 'AGS',
    31046: 'MCN',
    31047: 'MCN',
    31049: 'AGS',
    31050: 'MCN',
    31051: 'ABY',
    31052: 'MCN',
    31054: 'MCN',
    31055: 'ABY',
    31057: 'MCN',
    31058: 'MCN',
    31059: 'MCN',
    31060: 'ABY',
    31061: 'MCN',
    31062: 'MCN',
    31063: 'MCN',
    31064: 'MCN',
    31065: 'MCN',
    31066: 'MCN',
    31067: 'MCN',
    31068: 'ABY',
    31069: 'MCN',
    31070: 'ABY',
    31071: 'ABY',
    31072: 'ABY',
    31075: 'MCN',
    31076: 'MCN',
    31077: 'ABY',
    31078: 'MCN',
    31079: 'ABY',
    31081: 'MCN',
    31082: 'AGS',
    31083: 'ABY',
    31084: 'ABY',
    31085: 'MCN',
    31086: 'MCN',
    31087: 'AGS',
    31088: 'MCN',
    31089: 'AGS',
    31090: 'MCN',
    31091: 'ABY',
    31092: 'ABY',
    31093: 'MCN',
    31094: 'AGS',
    31095: 'MCN',
    31096: 'AGS',
    31097: 'MCN',
    31098: 'MCN',
    31099: 'MCN',
    31106: 'ATL',
    31107: 'ATL',
    31119: 'ATL',
    31120: 'ATL',
    31126: 'ATL',
    31131: 'ATL',
    31136: 'ATL',
    31139: 'ATL',
    31141: 'ATL',
    31144: 'ATL',
    31145: 'ATL',
    31146: 'ATL',
    31150: 'ATL',
    31156: 'ATL',
    31169: 'ATL',
    31191: 'ATL',
    31192: 'ATL',
    31193: 'ATL',
    31195: 'ATL',
    31196: 'ATL',
    31197: 'ATL',
    31198: 'ATL',
    31199: 'ATL',
    31201: 'MCN',
    31202: 'MCN',
    31203: 'MCN',
    31204: 'MCN',
    31205: 'MCN',
    31206: 'MCN',
    31207: 'MCN',
    31208: 'MCN',
    31209: 'MCN',
    31210: 'MCN',
    31211: 'MCN',
    31212: 'MCN',
    31213: 'MCN',
    31216: 'MCN',
    31217: 'MCN',
    31220: 'MCN',
    31221: 'MCN',
    31294: 'MCN',
    31295: 'MCN',
    31296: 'MCN',
    31297: 'MCN',
    31301: 'SAV',
    31302: 'SAV',
    31303: 'SAV',
    31304: 'SAV',
    31305: 'SAV',
    31307: 'SAV',
    31308: 'SAV',
    31309: 'SAV',
    31310: 'SAV',
    31312: 'SAV',
    31313: 'SAV',
    31314: 'SAV',
    31315: 'SAV',
    31316: 'SAV',
    31318: 'SAV',
    31319: 'SAV',
    31320: 'SAV',
    31321: 'SAV',
    31322: 'SAV',
    31323: 'SAV',
    31324: 'SAV',
    31326: 'SAV',
    31327: 'SAV',
    31328: 'SAV',
    31329: 'SAV',
    31331: 'SAV',
    31333: 'SAV',
    31401: 'SAV',
    31402: 'SAV',
    31403: 'SAV',
    31404: 'SAV',
    31405: 'SAV',
    31406: 'SAV',
    31407: 'SAV',
    31408: 'SAV',
    31409: 'SAV',
    31410: 'SAV',
    31411: 'SAV',
    31412: 'SAV',
    31414: 'SAV',
    31415: 'SAV',
    31416: 'SAV',
    31418: 'SAV',
    31419: 'SAV',
    31420: 'SAV',
    31421: 'SAV',
    31501: 'JAX',
    31502: 'JAX',
    31503: 'JAX',
    31510: 'ABY',
    31512: 'ABY',
    31513: 'SAV',
    31515: 'SAV',
    31516: 'JAX',
    31518: 'SAV',
    31519: 'ABY',
    31520: 'SAV',
    31521: 'SAV',
    31522: 'SAV',
    31523: 'SAV',
    31524: 'SAV',
    31525: 'SAV',
    31527: 'SAV',
    31532: 'ABY',
    31533: 'ABY',
    31534: 'ABY',
    31535: 'ABY',
    31537: 'JAX',
    31539: 'ABY',
    31542: 'JAX',
    31543: 'SAV',
    31544: 'ABY',
    31545: 'SAV',
    31546: 'SAV',
    31547: 'JAX',
    31548: 'JAX',
    31549: 'ABY',
    31550: 'JAX',
    31551: 'JAX',
    31552: 'ABY',
    31553: 'JAX',
    31554: 'ABY',
    31555: 'SAV',
    31556: 'SAV',
    31557: 'SAV',
    31558: 'JAX',
    31560: 'SAV',
    31561: 'SAV',
    31562: 'JAX',
    31563: 'SAV',
    31564: 'ABY',
    31565: 'JAX',
    31566: 'JAX',
    31567: 'ABY',
    31568: 'JAX',
    31569: 'JAX',
    31598: 'SAV',
    31599: 'SAV',
    31601: 'TLH',
    31602: 'TLH',
    31603: 'TLH',
    31604: 'TLH',
    31605: 'ABY',
    31606: 'TLH',
    31620: 'ABY',
    31622: 'ABY',
    31623: 'TLH',
    31624: 'ABY',
    31625: 'ABY',
    31626: 'TLH',
    31627: 'ABY',
    31629: 'TLH',
    31630: 'TLH',
    31631: 'TLH',
    31632: 'ABY',
    31634: 'TLH',
    31635: 'ABY',
    31636: 'TLH',
    31637: 'ABY',
    31638: 'ABY',
    31639: 'ABY',
    31641: 'TLH',
    31642: 'ABY',
    31643: 'TLH',
    31645: 'ABY',
    31646: 'ABY',
    31647: 'ABY',
    31648: 'TLH',
    31649: 'ABY',
    31650: 'ABY',
    31698: 'TLH',
    31699: 'ABY',
    31701: 'ABY',
    31702: 'ABY',
    31703: 'ABY',
    31704: 'ABY',
    31705: 'ABY',
    31706: 'ABY',
    31707: 'ABY',
    31708: 'ABY',
    31709: 'ABY',
    31710: 'ABY',
    31711: 'ABY',
    31712: 'ABY',
    31713: 'ABY',
    31714: 'ABY',
    31715: 'ABY',
    31716: 'ABY',
    31717: 'ABY',
    31718: 'ABY',
    31719: 'ABY',
    31720: 'TLH',
    31721: 'ABY',
    31722: 'ABY',
    31723: 'ABY',
    31724: 'ABY',
    31725: 'ABY',
    31726: 'ABY',
    31727: 'ABY',
    31728: 'ABY',
    31729: 'ABY',
    31730: 'ABY',
    31732: 'ABY',
    31733: 'ABY',
    31734: 'ABY',
    31735: 'ABY',
    31736: 'ABY',
    31737: 'ABY',
    31738: 'ABY',
    31739: 'ABY',
    31740: 'ABY',
    31741: 'ABY',
    31742: 'ABY',
    31743: 'ABY',
    31744: 'ABY',
    31745: 'ABY',
    31746: 'ABY',
    31747: 'ABY',
    31749: 'ABY',
    31750: 'ABY',
    31751: 'ABY',
    31752: 'ABY',
    31753: 'ABY',
    31754: 'ABY',
    31756: 'ABY',
    31757: 'TLH',
    31758: 'TLH',
    31759: 'TLH',
    31760: 'ABY',
    31761: 'ABY',
    31762: 'ABY',
    31763: 'ABY',
    31764: 'ABY',
    31765: 'ABY',
    31766: 'ABY',
    31767: 'ABY',
    31768: 'ABY',
    31769: 'ABY',
    31770: 'ABY',
    31771: 'ABY',
    31772: 'ABY',
    31773: 'ABY',
    31774: 'ABY',
    31775: 'ABY',
    31776: 'ABY',
    31777: 'ABY',
    31778: 'ABY',
    31779: 'ABY',
    31780: 'ABY',
    31781: 'ABY',
    31782: 'ABY',
    31783: 'ABY',
    31784: 'ABY',
    31785: 'ABY',
    31786: 'ABY',
    31787: 'ABY',
    31788: 'ABY',
    31789: 'ABY',
    31790: 'ABY',
    31791: 'ABY',
    31792: 'TLH',
    31793: 'ABY',
    31794: 'ABY',
    31795: 'ABY',
    31796: 'ABY',
    31797: 'ABY',
    31798: 'ABY',
    31799: 'TLH',
    31801: 'MCN',
    31803: 'MCN',
    31804: 'MCN',
    31805: 'ABY',
    31806: 'ABY',
    31807: 'MCN',
    31808: 'MCN',
    31810: 'MCN',
    31811: 'MCN',
    31812: 'MCN',
    31814: 'DHN',
    31815: 'DHN',
    31816: 'MCN',
    31820: 'MCN',
    31821: 'DHN',
    31822: 'MCN',
    31823: 'MCN',
    31824: 'ABY',
    31825: 'ABY',
    31826: 'MCN',
    31827: 'MCN',
    31829: 'MCN',
    31830: 'MCN',
    31831: 'MCN',
    31832: 'ABY',
    31833: 'MCN',
    31836: 'MCN',
    31901: 'MCN',
    31902: 'MCN',
    31903: 'MCN',
    31904: 'MCN',
    31905: 'MCN',
    31906: 'MCN',
    31907: 'MCN',
    31908: 'MCN',
    31909: 'MCN',
    31914: 'MCN',
    31917: 'MCN',
    31993: 'MCN',
    31995: 'MCN',
    31997: 'MCN',
    31998: 'MCN',
    31999: 'MCN',
    32003: 'JAX',
    32004: 'JAX',
    32006: 'JAX',
    32007: 'JAX',
    32008: 'TLH',
    32009: 'JAX',
    32011: 'JAX',
    32013: 'TLH',
    32024: 'JAX',
    32025: 'JAX',
    32026: 'JAX',
    32030: 'JAX',
    32033: 'JAX',
    32034: 'JAX',
    32035: 'JAX',
    32038: 'JAX',
    32040: 'JAX',
    32041: 'JAX',
    32042: 'JAX',
    32043: 'JAX',
    32044: 'JAX',
    32046: 'JAX',
    32050: 'JAX',
    32052: 'JAX',
    32053: 'JAX',
    32054: 'JAX',
    32055: 'JAX',
    32056: 'JAX',
    32058: 'JAX',
    32059: 'TLH',
    32060: 'JAX',
    32061: 'JAX',
    32062: 'JAX',
    32063: 'JAX',
    32064: 'JAX',
    32065: 'JAX',
    32066: 'TLH',
    32067: 'JAX',
    32068: 'JAX',
    32071: 'JAX',
    32072: 'JAX',
    32073: 'JAX',
    32079: 'JAX',
    32080: 'JAX',
    32081: 'JAX',
    32082: 'JAX',
    32083: 'JAX',
    32084: 'JAX',
    32085: 'JAX',
    32086: 'JAX',
    32087: 'JAX',
    32091: 'JAX',
    32092: 'JAX',
    32094: 'JAX',
    32095: 'JAX',
    32096: 'JAX',
    32097: 'JAX',
    32099: 'JAX',
    32102: 'MCO',
    32105: 'MCO',
    32110: 'JAX',
    32111: 'MCO',
    32112: 'JAX',
    32113: 'JAX',
    32114: 'MCO',
    32115: 'MCO',
    32116: 'MCO',
    32117: 'MCO',
    32118: 'MCO',
    32119: 'MCO',
    32120: 'MCO',
    32121: 'MCO',
    32122: 'MCO',
    32123: 'MCO',
    32124: 'MCO',
    32125: 'MCO',
    32126: 'MCO',
    32127: 'MCO',
    32128: 'MCO',
    32129: 'MCO',
    32130: 'MCO',
    32131: 'JAX',
    32132: 'MCO',
    32133: 'MCO',
    32134: 'JAX',
    32135: 'JAX',
    32136: 'JAX',
    32137: 'JAX',
    32138: 'JAX',
    32139: 'JAX',
    32140: 'JAX',
    32141: 'MCO',
    32142: 'JAX',
    32143: 'JAX',
    32145: 'JAX',
    32147: 'JAX',
    32148: 'JAX',
    32149: 'JAX',
    32157: 'JAX',
    32158: 'MCO',
    32159: 'MCO',
    32160: 'JAX',
    32162: 'MCO',
    32163: 'MCO',
    32164: 'JAX',
    32168: 'MCO',
    32169: 'MCO',
    32170: 'MCO',
    32173: 'MCO',
    32174: 'MCO',
    32175: 'MCO',
    32176: 'MCO',
    32177: 'JAX',
    32178: 'JAX',
    32179: 'MCO',
    32180: 'MCO',
    32181: 'JAX',
    32182: 'JAX',
    32183: 'MCO',
    32185: 'JAX',
    32187: 'JAX',
    32189: 'JAX',
    32190: 'JAX',
    32192: 'MCO',
    32193: 'JAX',
    32195: 'MCO',
    32198: 'MCO',
    32201: 'JAX',
    32202: 'JAX',
    32203: 'JAX',
    32204: 'JAX',
    32205: 'JAX',
    32206: 'JAX',
    32207: 'JAX',
    32208: 'JAX',
    32209: 'JAX',
    32210: 'JAX',
    32211: 'JAX',
    32212: 'JAX',
    32214: 'JAX',
    32215: 'JAX',
    32216: 'JAX',
    32217: 'JAX',
    32218: 'JAX',
    32219: 'JAX',
    32220: 'JAX',
    32221: 'JAX',
    32222: 'JAX',
    32223: 'JAX',
    32224: 'JAX',
    32225: 'JAX',
    32226: 'JAX',
    32227: 'JAX',
    32228: 'JAX',
    32229: 'JAX',
    32230: 'JAX',
    32231: 'JAX',
    32232: 'JAX',
    32233: 'JAX',
    32234: 'JAX',
    32235: 'JAX',
    32236: 'JAX',
    32237: 'JAX',
    32238: 'JAX',
    32239: 'JAX',
    32240: 'JAX',
    32241: 'JAX',
    32244: 'JAX',
    32245: 'JAX',
    32246: 'JAX',
    32247: 'JAX',
    32250: 'JAX',
    32254: 'JAX',
    32255: 'JAX',
    32256: 'JAX',
    32257: 'JAX',
    32258: 'JAX',
    32259: 'JAX',
    32260: 'JAX',
    32266: 'JAX',
    32267: 'JAX',
    32277: 'JAX',
    32290: 'JAX',
    32301: 'TLH',
    32302: 'TLH',
    32303: 'TLH',
    32304: 'TLH',
    32305: 'TLH',
    32306: 'TLH',
    32307: 'TLH',
    32308: 'TLH',
    32309: 'TLH',
    32310: 'TLH',
    32311: 'TLH',
    32312: 'TLH',
    32313: 'TLH',
    32314: 'TLH',
    32315: 'TLH',
    32316: 'TLH',
    32317: 'TLH',
    32318: 'TLH',
    32320: 'TLH',
    32321: 'TLH',
    32322: 'TLH',
    32323: 'TLH',
    32324: 'DHN',
    32326: 'TLH',
    32327: 'TLH',
    32328: 'TLH',
    32329: 'TLH',
    32330: 'TLH',
    32331: 'TLH',
    32332: 'TLH',
    32333: 'TLH',
    32334: 'TLH',
    32335: 'TLH',
    32336: 'TLH',
    32337: 'TLH',
    32340: 'TLH',
    32341: 'TLH',
    32343: 'TLH',
    32344: 'TLH',
    32345: 'TLH',
    32346: 'TLH',
    32347: 'TLH',
    32348: 'TLH',
    32350: 'TLH',
    32351: 'TLH',
    32352: 'TLH',
    32353: 'TLH',
    32355: 'TLH',
    32356: 'TLH',
    32357: 'TLH',
    32358: 'TLH',
    32359: 'TLH',
    32360: 'TLH',
    32361: 'TLH',
    32362: 'TLH',
    32395: 'TLH',
    32399: 'TLH',
    32401: 'DHN',
    32402: 'DHN',
    32403: 'DHN',
    32404: 'DHN',
    32405: 'DHN',
    32406: 'DHN',
    32407: 'DHN',
    32408: 'DHN',
    32409: 'DHN',
    32410: 'DHN',
    32411: 'DHN',
    32412: 'DHN',
    32413: 'DHN',
    32417: 'DHN',
    32420: 'DHN',
    32421: 'DHN',
    32422: 'DHN',
    32423: 'DHN',
    32424: 'DHN',
    32425: 'DHN',
    32426: 'DHN',
    32427: 'DHN',
    32428: 'DHN',
    32430: 'DHN',
    32431: 'DHN',
    32432: 'DHN',
    32433: 'DHN',
    32434: 'DHN',
    32435: 'DHN',
    32437: 'DHN',
    32438: 'DHN',
    32439: 'DHN',
    32440: 'DHN',
    32442: 'DHN',
    32443: 'DHN',
    32444: 'DHN',
    32445: 'DHN',
    32446: 'DHN',
    32447: 'DHN',
    32448: 'DHN',
    32449: 'DHN',
    32452: 'DHN',
    32454: 'DHN',
    32455: 'DHN',
    32456: 'TLH',
    32457: 'TLH',
    32459: 'DHN',
    32460: 'DHN',
    32461: 'DHN',
    32462: 'DHN',
    32463: 'DHN',
    32464: 'DHN',
    32465: 'TLH',
    32466: 'DHN',
    32501: 'PNS',
    32502: 'PNS',
    32503: 'PNS',
    32504: 'PNS',
    32505: 'PNS',
    32506: 'PNS',
    32507: 'PNS',
    32508: 'PNS',
    32509: 'PNS',
    32511: 'PNS',
    32512: 'PNS',
    32513: 'PNS',
    32514: 'PNS',
    32516: 'PNS',
    32520: 'PNS',
    32521: 'PNS',
    32522: 'PNS',
    32523: 'PNS',
    32524: 'PNS',
    32526: 'PNS',
    32530: 'PNS',
    32531: 'PNS',
    32533: 'PNS',
    32534: 'PNS',
    32535: 'PNS',
    32536: 'PNS',
    32537: 'PNS',
    32538: 'DHN',
    32539: 'DHN',
    32540: 'DHN',
    32541: 'DHN',
    32542: 'PNS',
    32544: 'PNS',
    32547: 'PNS',
    32548: 'PNS',
    32549: 'PNS',
    32550: 'DHN',
    32559: 'PNS',
    32560: 'PNS',
    32561: 'PNS',
    32562: 'PNS',
    32563: 'PNS',
    32564: 'PNS',
    32565: 'PNS',
    32566: 'PNS',
    32567: 'DHN',
    32568: 'PNS',
    32569: 'PNS',
    32570: 'PNS',
    32571: 'PNS',
    32572: 'PNS',
    32573: 'PNS',
    32574: 'PNS',
    32575: 'PNS',
    32576: 'PNS',
    32577: 'PNS',
    32578: 'DHN',
    32579: 'PNS',
    32580: 'PNS',
    32581: 'PNS',
    32582: 'PNS',
    32583: 'PNS',
    32588: 'DHN',
    32589: 'PNS',
    32590: 'PNS',
    32591: 'PNS',
    32592: 'JAX',
    32593: 'JAX',
    32594: 'JAX',
    32595: 'JAX',
    32596: 'JAX',
    32597: 'JAX',
    32598: 'JAX',
    32601: 'JAX',
    32602: 'JAX',
    32603: 'JAX',
    32604: 'JAX',
    32605: 'JAX',
    32606: 'JAX',
    32607: 'JAX',
    32608: 'JAX',
    32609: 'JAX',
    32610: 'JAX',
    32611: 'JAX',
    32612: 'JAX',
    32613: 'JAX',
    32614: 'JAX',
    32615: 'JAX',
    32616: 'JAX',
    32617: 'MCO',
    32618: 'TLH',
    32619: 'TLH',
    32621: 'TLH',
    32622: 'JAX',
    32625: 'TLH',
    32626: 'TLH',
    32627: 'JAX',
    32628: 'TLH',
    32631: 'JAX',
    32633: 'JAX',
    32634: 'JAX',
    32635: 'JAX',
    32639: 'TLH',
    32640: 'JAX',
    32641: 'JAX',
    32643: 'JAX',
    32644: 'TLH',
    32648: 'TLH',
    32653: 'JAX',
    32654: 'JAX',
    32655: 'JAX',
    32656: 'JAX',
    32658: 'JAX',
    32662: 'JAX',
    32663: 'TPA',
    32664: 'JAX',
    32666: 'JAX',
    32667: 'JAX',
    32668: 'TPA',
    32669: 'TLH',
    32680: 'TLH',
    32681: 'JAX',
    32683: 'TLH',
    32686: 'JAX',
    32692: 'TLH',
    32693: 'TLH',
    32694: 'JAX',
    32696: 'TLH',
    32697: 'JAX',
    32701: 'MCO',
    32702: 'MCO',
    32703: 'MCO',
    32704: 'MCO',
    32706: 'MCO',
    32707: 'MCO',
    32708: 'MCO',
    32709: 'MCO',
    32710: 'MCO',
    32712: 'MCO',
    32713: 'MCO',
    32714: 'MCO',
    32715: 'MCO',
    32716: 'MCO',
    32718: 'MCO',
    32719: 'MCO',
    32720: 'MCO',
    32721: 'MCO',
    32722: 'MCO',
    32723: 'MCO',
    32724: 'MCO',
    32725: 'MCO',
    32726: 'MCO',
    32727: 'MCO',
    32728: 'MCO',
    32730: 'MCO',
    32732: 'MCO',
    32733: 'MCO',
    32735: 'MCO',
    32736: 'MCO',
    32738: 'MCO',
    32739: 'MCO',
    32744: 'MCO',
    32745: 'MCO',
    32746: 'MCO',
    32747: 'MCO',
    32750: 'MCO',
    32751: 'MCO',
    32752: 'MCO',
    32753: 'MCO',
    32754: 'MCO',
    32756: 'MCO',
    32757: 'MCO',
    32759: 'MCO',
    32762: 'MCO',
    32763: 'MCO',
    32764: 'MCO',
    32765: 'MCO',
    32766: 'MCO',
    32767: 'MCO',
    32768: 'MCO',
    32771: 'MCO',
    32772: 'MCO',
    32773: 'MCO',
    32774: 'MCO',
    32775: 'MCO',
    32776: 'MCO',
    32777: 'MCO',
    32778: 'MCO',
    32779: 'MCO',
    32780: 'MCO',
    32781: 'MCO',
    32782: 'MCO',
    32783: 'MCO',
    32784: 'MCO',
    32789: 'MCO',
    32790: 'MCO',
    32791: 'MCO',
    32792: 'MCO',
    32793: 'MCO',
    32794: 'MCO',
    32795: 'MCO',
    32796: 'MCO',
    32798: 'MCO',
    32799: 'MCO',
    32801: 'MCO',
    32802: 'MCO',
    32803: 'MCO',
    32804: 'MCO',
    32805: 'MCO',
    32806: 'MCO',
    32807: 'MCO',
    32808: 'MCO',
    32809: 'MCO',
    32810: 'MCO',
    32811: 'MCO',
    32812: 'MCO',
    32814: 'MCO',
    32815: 'MCO',
    32816: 'MCO',
    32817: 'MCO',
    32818: 'MCO',
    32819: 'MCO',
    32820: 'MCO',
    32821: 'MCO',
    32822: 'MCO',
    32824: 'MCO',
    32825: 'MCO',
    32826: 'MCO',
    32827: 'MCO',
    32828: 'MCO',
    32829: 'MCO',
    32830: 'MCO',
    32831: 'MCO',
    32832: 'MCO',
    32833: 'MCO',
    32834: 'MCO',
    32835: 'MCO',
    32836: 'MCO',
    32837: 'MCO',
    32839: 'MCO',
    32853: 'MCO',
    32854: 'MCO',
    32855: 'MCO',
    32856: 'MCO',
    32857: 'MCO',
    32858: 'MCO',
    32859: 'MCO',
    32860: 'MCO',
    32861: 'MCO',
    32862: 'MCO',
    32867: 'MCO',
    32868: 'MCO',
    32869: 'MCO',
    32872: 'MCO',
    32877: 'MCO',
    32878: 'MCO',
    32885: 'MCO',
    32886: 'MCO',
    32887: 'MCO',
    32890: 'MCO',
    32891: 'MCO',
    32893: 'MCO',
    32896: 'MCO',
    32897: 'MCO',
    32898: 'MCO',
    32899: 'MCO',
    32901: 'MCO',
    32902: 'MCO',
    32903: 'MCO',
    32904: 'MCO',
    32905: 'MCO',
    32906: 'MCO',
    32907: 'MCO',
    32908: 'MCO',
    32909: 'MCO',
    32910: 'MCO',
    32911: 'MCO',
    32912: 'MCO',
    32919: 'MCO',
    32920: 'MCO',
    32922: 'MCO',
    32923: 'MCO',
    32924: 'MCO',
    32925: 'MCO',
    32926: 'MCO',
    32927: 'MCO',
    32931: 'MCO',
    32932: 'MCO',
    32934: 'MCO',
    32935: 'MCO',
    32936: 'MCO',
    32937: 'MCO',
    32940: 'MCO',
    32941: 'MCO',
    32948: 'MIA',
    32949: 'MCO',
    32950: 'MCO',
    32951: 'MCO',
    32952: 'MCO',
    32953: 'MCO',
    32954: 'MCO',
    32955: 'MCO',
    32956: 'MCO',
    32957: 'MIA',
    32958: 'MIA',
    32959: 'MCO',
    32960: 'MIA',
    32961: 'MIA',
    32962: 'MIA',
    32963: 'MIA',
    32964: 'MIA',
    32965: 'MIA',
    32966: 'MIA',
    32967: 'MIA',
    32968: 'MIA',
    32969: 'MIA',
    32970: 'MIA',
    32971: 'MIA',
    32976: 'MIA',
    32978: 'MIA',
    33001: 'MIA',
    33002: 'MIA',
    33004: 'MIA',
    33008: 'MIA',
    33009: 'MIA',
    33010: 'MIA',
    33011: 'MIA',
    33012: 'MIA',
    33013: 'MIA',
    33014: 'MIA',
    33015: 'MIA',
    33016: 'MIA',
    33017: 'MIA',
    33018: 'MIA',
    33019: 'MIA',
    33020: 'MIA',
    33021: 'MIA',
    33022: 'MIA',
    33023: 'MIA',
    33024: 'MIA',
    33025: 'MIA',
    33026: 'MIA',
    33027: 'MIA',
    33028: 'MIA',
    33029: 'MIA',
    33030: 'MIA',
    33031: 'MIA',
    33032: 'MIA',
    33033: 'MIA',
    33034: 'MIA',
    33035: 'MIA',
    33036: 'MIA',
    33037: 'MIA',
    33039: 'MIA',
    33040: 'MIA',
    33041: 'MIA',
    33042: 'MIA',
    33043: 'MIA',
    33045: 'MIA',
    33050: 'MIA',
    33051: 'MIA',
    33052: 'MIA',
    33054: 'MIA',
    33055: 'MIA',
    33056: 'MIA',
    33060: 'MIA',
    33061: 'MIA',
    33062: 'MIA',
    33063: 'MIA',
    33064: 'MIA',
    33065: 'MIA',
    33066: 'MIA',
    33067: 'MIA',
    33068: 'MIA',
    33069: 'MIA',
    33070: 'MIA',
    33071: 'MIA',
    33072: 'MIA',
    33073: 'MIA',
    33074: 'MIA',
    33075: 'MIA',
    33076: 'MIA',
    33077: 'MIA',
    33081: 'MIA',
    33082: 'MIA',
    33083: 'MIA',
    33084: 'MIA',
    33090: 'MIA',
    33092: 'MIA',
    33093: 'MIA',
    33097: 'MIA',
    33101: 'MIA',
    33102: 'MIA',
    33106: 'MIA',
    33107: 'MIA',
    33109: 'MIA',
    33110: 'MIA',
    33111: 'MIA',
    33112: 'MIA',
    33114: 'MIA',
    33116: 'MIA',
    33119: 'MIA',
    33121: 'MIA',
    33122: 'MIA',
    33124: 'MIA',
    33125: 'MIA',
    33126: 'MIA',
    33127: 'MIA',
    33128: 'MIA',
    33129: 'MIA',
    33130: 'MIA',
    33131: 'MIA',
    33132: 'MIA',
    33133: 'MIA',
    33134: 'MIA',
    33135: 'MIA',
    33136: 'MIA',
    33137: 'MIA',
    33138: 'MIA',
    33139: 'MIA',
    33140: 'MIA',
    33141: 'MIA',
    33142: 'MIA',
    33143: 'MIA',
    33144: 'MIA',
    33145: 'MIA',
    33146: 'MIA',
    33147: 'MIA',
    33148: 'MIA',
    33149: 'MIA',
    33150: 'MIA',
    33151: 'MIA',
    33152: 'MIA',
    33153: 'MIA',
    33154: 'MIA',
    33155: 'MIA',
    33156: 'MIA',
    33157: 'MIA',
    33158: 'MIA',
    33159: 'MIA',
    33160: 'MIA',
    33161: 'MIA',
    33162: 'MIA',
    33163: 'MIA',
    33164: 'MIA',
    33165: 'MIA',
    33166: 'MIA',
    33167: 'MIA',
    33168: 'MIA',
    33169: 'MIA',
    33170: 'MIA',
    33172: 'MIA',
    33173: 'MIA',
    33174: 'MIA',
    33175: 'MIA',
    33176: 'MIA',
    33177: 'MIA',
    33178: 'MIA',
    33179: 'MIA',
    33180: 'MIA',
    33181: 'MIA',
    33182: 'MIA',
    33183: 'MIA',
    33184: 'MIA',
    33185: 'MIA',
    33186: 'MIA',
    33187: 'MIA',
    33188: 'MIA',
    33189: 'MIA',
    33190: 'MIA',
    33191: 'MIA',
    33192: 'MIA',
    33193: 'MIA',
    33194: 'MIA',
    33195: 'MIA',
    33196: 'MIA',
    33197: 'MIA',
    33198: 'MIA',
    33199: 'MIA',
    33206: 'MIA',
    33222: 'MIA',
    33231: 'MIA',
    33233: 'MIA',
    33234: 'MIA',
    33238: 'MIA',
    33239: 'MIA',
    33242: 'MIA',
    33243: 'MIA',
    33245: 'MIA',
    33247: 'MIA',
    33255: 'MIA',
    33256: 'MIA',
    33257: 'MIA',
    33261: 'MIA',
    33265: 'MIA',
    33266: 'MIA',
    33269: 'MIA',
    33280: 'MIA',
    33283: 'MIA',
    33296: 'MIA',
    33299: 'MIA',
    33301: 'MIA',
    33302: 'MIA',
    33303: 'MIA',
    33304: 'MIA',
    33305: 'MIA',
    33306: 'MIA',
    33307: 'MIA',
    33308: 'MIA',
    33309: 'MIA',
    33310: 'MIA',
    33311: 'MIA',
    33312: 'MIA',
    33313: 'MIA',
    33314: 'MIA',
    33315: 'MIA',
    33316: 'MIA',
    33317: 'MIA',
    33318: 'MIA',
    33319: 'MIA',
    33320: 'MIA',
    33321: 'MIA',
    33322: 'MIA',
    33323: 'MIA',
    33324: 'MIA',
    33325: 'MIA',
    33326: 'MIA',
    33327: 'MIA',
    33328: 'MIA',
    33329: 'MIA',
    33330: 'MIA',
    33331: 'MIA',
    33332: 'MIA',
    33334: 'MIA',
    33335: 'MIA',
    33336: 'MIA',
    33337: 'MIA',
    33338: 'MIA',
    33339: 'MIA',
    33340: 'MIA',
    33345: 'MIA',
    33346: 'MIA',
    33348: 'MIA',
    33349: 'MIA',
    33351: 'MIA',
    33355: 'MIA',
    33359: 'MIA',
    33388: 'MIA',
    33394: 'MIA',
    33401: 'MIA',
    33402: 'MIA',
    33403: 'MIA',
    33404: 'MIA',
    33405: 'MIA',
    33406: 'MIA',
    33407: 'MIA',
    33408: 'MIA',
    33409: 'MIA',
    33410: 'MIA',
    33411: 'MIA',
    33412: 'MIA',
    33413: 'MIA',
    33414: 'MIA',
    33415: 'MIA',
    33416: 'MIA',
    33417: 'MIA',
    33418: 'MIA',
    33419: 'MIA',
    33420: 'MIA',
    33421: 'MIA',
    33422: 'MIA',
    33424: 'MIA',
    33425: 'MIA',
    33426: 'MIA',
    33427: 'MIA',
    33428: 'MIA',
    33429: 'MIA',
    33430: 'MIA',
    33431: 'MIA',
    33432: 'MIA',
    33433: 'MIA',
    33434: 'MIA',
    33435: 'MIA',
    33436: 'MIA',
    33437: 'MIA',
    33438: 'MIA',
    33439: 'MIA',
    33440: 'MIA',
    33441: 'MIA',
    33442: 'MIA',
    33443: 'MIA',
    33444: 'MIA',
    33445: 'MIA',
    33446: 'MIA',
    33447: 'MIA',
    33448: 'MIA',
    33449: 'MIA',
    33454: 'MIA',
    33455: 'MIA',
    33458: 'MIA',
    33459: 'MIA',
    33460: 'MIA',
    33461: 'MIA',
    33462: 'MIA',
    33463: 'MIA',
    33464: 'MIA',
    33465: 'MIA',
    33466: 'MIA',
    33467: 'MIA',
    33468: 'MIA',
    33469: 'MIA',
    33470: 'MIA',
    33471: 'RSW',
    33472: 'MIA',
    33473: 'MIA',
    33474: 'MIA',
    33475: 'MIA',
    33476: 'MIA',
    33477: 'MIA',
    33478: 'MIA',
    33480: 'MIA',
    33481: 'MIA',
    33482: 'MIA',
    33483: 'MIA',
    33484: 'MIA',
    33486: 'MIA',
    33487: 'MIA',
    33488: 'MIA',
    33493: 'MIA',
    33496: 'MIA',
    33497: 'MIA',
    33498: 'MIA',
    33499: 'MIA',
    33503: 'TPA',
    33508: 'TPA',
    33509: 'TPA',
    33510: 'TPA',
    33511: 'TPA',
    33513: 'TPA',
    33514: 'TPA',
    33521: 'TPA',
    33523: 'TPA',
    33524: 'TPA',
    33525: 'TPA',
    33526: 'TPA',
    33527: 'TPA',
    33530: 'TPA',
    33534: 'TPA',
    33537: 'TPA',
    33538: 'TPA',
    33539: 'TPA',
    33540: 'TPA',
    33541: 'TPA',
    33542: 'TPA',
    33543: 'TPA',
    33544: 'TPA',
    33545: 'TPA',
    33547: 'TPA',
    33548: 'TPA',
    33549: 'TPA',
    33550: 'TPA',
    33556: 'TPA',
    33558: 'TPA',
    33559: 'TPA',
    33563: 'TPA',
    33564: 'TPA',
    33565: 'TPA',
    33566: 'TPA',
    33567: 'TPA',
    33568: 'TPA',
    33569: 'TPA',
    33570: 'TPA',
    33571: 'TPA',
    33572: 'TPA',
    33573: 'TPA',
    33574: 'TPA',
    33575: 'TPA',
    33576: 'TPA',
    33578: 'TPA',
    33579: 'TPA',
    33583: 'TPA',
    33584: 'TPA',
    33585: 'TPA',
    33586: 'TPA',
    33587: 'TPA',
    33592: 'TPA',
    33593: 'TPA',
    33594: 'TPA',
    33595: 'TPA',
    33596: 'TPA',
    33597: 'TPA',
    33598: 'TPA',
    33601: 'TPA',
    33602: 'TPA',
    33603: 'TPA',
    33604: 'TPA',
    33605: 'TPA',
    33606: 'TPA',
    33607: 'TPA',
    33608: 'TPA',
    33609: 'TPA',
    33610: 'TPA',
    33611: 'TPA',
    33612: 'TPA',
    33613: 'TPA',
    33614: 'TPA',
    33615: 'TPA',
    33616: 'TPA',
    33617: 'TPA',
    33618: 'TPA',
    33619: 'TPA',
    33620: 'TPA',
    33621: 'TPA',
    33622: 'TPA',
    33623: 'TPA',
    33624: 'TPA',
    33625: 'TPA',
    33626: 'TPA',
    33629: 'TPA',
    33630: 'TPA',
    33631: 'TPA',
    33633: 'TPA',
    33634: 'TPA',
    33635: 'TPA',
    33637: 'TPA',
    33646: 'TPA',
    33647: 'TPA',
    33650: 'TPA',
    33651: 'TPA',
    33655: 'TPA',
    33660: 'TPA',
    33661: 'TPA',
    33662: 'TPA',
    33663: 'TPA',
    33664: 'TPA',
    33672: 'TPA',
    33673: 'TPA',
    33674: 'TPA',
    33675: 'TPA',
    33677: 'TPA',
    33679: 'TPA',
    33680: 'TPA',
    33681: 'TPA',
    33682: 'TPA',
    33684: 'TPA',
    33685: 'TPA',
    33686: 'TPA',
    33687: 'TPA',
    33688: 'TPA',
    33689: 'TPA',
    33690: 'TPA',
    33694: 'TPA',
    33701: 'TPA',
    33702: 'TPA',
    33703: 'TPA',
    33704: 'TPA',
    33705: 'TPA',
    33706: 'TPA',
    33707: 'TPA',
    33708: 'TPA',
    33709: 'TPA',
    33710: 'TPA',
    33711: 'TPA',
    33712: 'TPA',
    33713: 'TPA',
    33714: 'TPA',
    33715: 'TPA',
    33716: 'TPA',
    33729: 'TPA',
    33730: 'TPA',
    33731: 'TPA',
    33732: 'TPA',
    33733: 'TPA',
    33734: 'TPA',
    33736: 'TPA',
    33737: 'TPA',
    33738: 'TPA',
    33740: 'TPA',
    33741: 'TPA',
    33742: 'TPA',
    33743: 'TPA',
    33744: 'TPA',
    33747: 'TPA',
    33755: 'TPA',
    33756: 'TPA',
    33757: 'TPA',
    33758: 'TPA',
    33759: 'TPA',
    33760: 'TPA',
    33761: 'TPA',
    33762: 'TPA',
    33763: 'TPA',
    33764: 'TPA',
    33765: 'TPA',
    33766: 'TPA',
    33767: 'TPA',
    33769: 'TPA',
    33770: 'TPA',
    33771: 'TPA',
    33772: 'TPA',
    33773: 'TPA',
    33774: 'TPA',
    33775: 'TPA',
    33776: 'TPA',
    33777: 'TPA',
    33778: 'TPA',
    33779: 'TPA',
    33780: 'TPA',
    33781: 'TPA',
    33782: 'TPA',
    33784: 'TPA',
    33785: 'TPA',
    33786: 'TPA',
    33801: 'TPA',
    33802: 'TPA',
    33803: 'TPA',
    33804: 'TPA',
    33805: 'TPA',
    33806: 'TPA',
    33807: 'TPA',
    33809: 'TPA',
    33810: 'TPA',
    33811: 'TPA',
    33812: 'TPA',
    33813: 'TPA',
    33815: 'TPA',
    33820: 'TPA',
    33823: 'TPA',
    33825: 'MIA',
    33826: 'RSW',
    33827: 'TPA',
    33830: 'TPA',
    33831: 'TPA',
    33834: 'TPA',
    33835: 'TPA',
    33836: 'TPA',
    33837: 'TPA',
    33838: 'TPA',
    33839: 'TPA',
    33840: 'TPA',
    33841: 'TPA',
    33843: 'MIA',
    33844: 'TPA',
    33845: 'TPA',
    33846: 'TPA',
    33847: 'TPA',
    33848: 'MCO',
    33849: 'TPA',
    33850: 'TPA',
    33851: 'TPA',
    33852: 'RSW',
    33853: 'TPA',
    33854: 'TPA',
    33855: 'TPA',
    33856: 'TPA',
    33857: 'MIA',
    33858: 'MCO',
    33859: 'TPA',
    33860: 'TPA',
    33862: 'RSW',
    33863: 'TPA',
    33865: 'TPA',
    33867: 'TPA',
    33868: 'TPA',
    33870: 'MIA',
    33871: 'RSW',
    33872: 'RSW',
    33873: 'TPA',
    33875: 'RSW',
    33876: 'MIA',
    33877: 'TPA',
    33880: 'TPA',
    33881: 'TPA',
    33882: 'TPA',
    33883: 'TPA',
    33884: 'TPA',
    33885: 'TPA',
    33888: 'TPA',
    33890: 'RSW',
    33896: 'MCO',
    33897: 'MCO',
    33898: 'TPA',
    33900: 'RSW',
    33901: 'RSW',
    33902: 'RSW',
    33903: 'RSW',
    33904: 'RSW',
    33905: 'RSW',
    33906: 'RSW',
    33907: 'RSW',
    33908: 'RSW',
    33909: 'RSW',
    33910: 'RSW',
    33911: 'RSW',
    33912: 'RSW',
    33913: 'RSW',
    33914: 'RSW',
    33915: 'RSW',
    33916: 'RSW',
    33917: 'RSW',
    33918: 'RSW',
    33919: 'RSW',
    33920: 'RSW',
    33921: 'RSW',
    33922: 'RSW',
    33924: 'RSW',
    33927: 'RSW',
    33928: 'RSW',
    33929: 'RSW',
    33930: 'RSW',
    33931: 'RSW',
    33932: 'RSW',
    33935: 'RSW',
    33936: 'RSW',
    33938: 'RSW',
    33944: 'RSW',
    33945: 'RSW',
    33946: 'RSW',
    33947: 'RSW',
    33948: 'RSW',
    33949: 'RSW',
    33950: 'RSW',
    33951: 'RSW',
    33952: 'RSW',
    33953: 'RSW',
    33954: 'RSW',
    33955: 'RSW',
    33956: 'RSW',
    33957: 'RSW',
    33960: 'RSW',
    33965: 'RSW',
    33966: 'RSW',
    33967: 'RSW',
    33970: 'RSW',
    33971: 'RSW',
    33972: 'RSW',
    33973: 'RSW',
    33974: 'RSW',
    33975: 'RSW',
    33976: 'RSW',
    33980: 'RSW',
    33981: 'RSW',
    33982: 'RSW',
    33983: 'RSW',
    33990: 'RSW',
    33991: 'RSW',
    33993: 'RSW',
    33994: 'RSW',
    34101: 'RSW',
    34102: 'RSW',
    34103: 'RSW',
    34104: 'RSW',
    34105: 'RSW',
    34106: 'RSW',
    34107: 'RSW',
    34108: 'RSW',
    34109: 'RSW',
    34110: 'RSW',
    34112: 'RSW',
    34113: 'RSW',
    34114: 'RSW',
    34116: 'RSW',
    34117: 'RSW',
    34119: 'RSW',
    34120: 'RSW',
    34133: 'RSW',
    34134: 'RSW',
    34135: 'RSW',
    34136: 'RSW',
    34137: 'RSW',
    34138: 'RSW',
    34139: 'RSW',
    34140: 'RSW',
    34141: 'RSW',
    34142: 'RSW',
    34143: 'RSW',
    34145: 'RSW',
    34146: 'RSW',
    34201: 'TPA',
    34202: 'TPA',
    34203: 'TPA',
    34204: 'TPA',
    34205: 'TPA',
    34206: 'TPA',
    34207: 'TPA',
    34208: 'TPA',
    34209: 'TPA',
    34210: 'TPA',
    34211: 'TPA',
    34212: 'TPA',
    34215: 'TPA',
    34216: 'TPA',
    34217: 'TPA',
    34218: 'TPA',
    34219: 'TPA',
    34220: 'TPA',
    34221: 'TPA',
    34222: 'TPA',
    34223: 'RSW',
    34224: 'RSW',
    34228: 'TPA',
    34229: 'TPA',
    34230: 'TPA',
    34231: 'TPA',
    34232: 'TPA',
    34233: 'TPA',
    34234: 'TPA',
    34235: 'TPA',
    34236: 'TPA',
    34237: 'TPA',
    34238: 'TPA',
    34239: 'TPA',
    34240: 'TPA',
    34241: 'RSW',
    34242: 'TPA',
    34243: 'TPA',
    34249: 'TPA',
    34250: 'TPA',
    34251: 'TPA',
    34260: 'TPA',
    34264: 'TPA',
    34265: 'RSW',
    34266: 'RSW',
    34267: 'RSW',
    34268: 'RSW',
    34269: 'RSW',
    34270: 'TPA',
    34272: 'TPA',
    34274: 'TPA',
    34275: 'RSW',
    34276: 'TPA',
    34277: 'TPA',
    34278: 'TPA',
    34280: 'TPA',
    34281: 'TPA',
    34282: 'TPA',
    34284: 'TPA',
    34285: 'TPA',
    34286: 'RSW',
    34287: 'RSW',
    34288: 'RSW',
    34289: 'RSW',
    34290: 'RSW',
    34291: 'RSW',
    34292: 'RSW',
    34293: 'RSW',
    34295: 'RSW',
    34420: 'MCO',
    34421: 'MCO',
    34423: 'TPA',
    34428: 'TPA',
    34429: 'TPA',
    34430: 'TPA',
    34431: 'TPA',
    34432: 'TPA',
    34433: 'TPA',
    34434: 'TPA',
    34436: 'TPA',
    34441: 'TPA',
    34442: 'TPA',
    34445: 'TPA',
    34446: 'TPA',
    34447: 'TPA',
    34448: 'TPA',
    34449: 'TPA',
    34450: 'TPA',
    34451: 'TPA',
    34452: 'TPA',
    34453: 'TPA',
    34460: 'TPA',
    34461: 'TPA',
    34464: 'TPA',
    34465: 'TPA',
    34470: 'MCO',
    34471: 'MCO',
    34472: 'MCO',
    34473: 'TPA',
    34474: 'TPA',
    34475: 'MCO',
    34476: 'TPA',
    34477: 'MCO',
    34478: 'MCO',
    34479: 'MCO',
    34480: 'MCO',
    34481: 'TPA',
    34482: 'TPA',
    34483: 'MCO',
    34484: 'MCO',
    34487: 'TPA',
    34488: 'MCO',
    34489: 'MCO',
    34491: 'MCO',
    34492: 'MCO',
    34498: 'TPA',
    34601: 'TPA',
    34602: 'TPA',
    34603: 'TPA',
    34604: 'TPA',
    34605: 'TPA',
    34606: 'TPA',
    34607: 'TPA',
    34608: 'TPA',
    34609: 'TPA',
    34610: 'TPA',
    34611: 'TPA',
    34613: 'TPA',
    34614: 'TPA',
    34636: 'TPA',
    34637: 'TPA',
    34638: 'TPA',
    34639: 'TPA',
    34652: 'TPA',
    34653: 'TPA',
    34654: 'TPA',
    34655: 'TPA',
    34656: 'TPA',
    34660: 'TPA',
    34661: 'TPA',
    34667: 'TPA',
    34668: 'TPA',
    34669: 'TPA',
    34673: 'TPA',
    34674: 'TPA',
    34677: 'TPA',
    34679: 'TPA',
    34680: 'TPA',
    34681: 'TPA',
    34682: 'TPA',
    34683: 'TPA',
    34684: 'TPA',
    34685: 'TPA',
    34688: 'TPA',
    34689: 'TPA',
    34690: 'TPA',
    34691: 'TPA',
    34692: 'TPA',
    34695: 'TPA',
    34697: 'TPA',
    34698: 'TPA',
    34705: 'MCO',
    34711: 'MCO',
    34712: 'MCO',
    34713: 'MCO',
    34714: 'MCO',
    34715: 'MCO',
    34729: 'MCO',
    34731: 'MCO',
    34734: 'MCO',
    34736: 'TPA',
    34737: 'MCO',
    34739: 'MIA',
    34740: 'MCO',
    34741: 'MCO',
    34742: 'MCO',
    34743: 'MCO',
    34744: 'MCO',
    34745: 'MCO',
    34746: 'MCO',
    34747: 'MCO',
    34748: 'MCO',
    34749: 'MCO',
    34753: 'MCO',
    34755: 'MCO',
    34756: 'MCO',
    34758: 'MCO',
    34759: 'TPA',
    34760: 'MCO',
    34761: 'MCO',
    34762: 'MCO',
    34769: 'MCO',
    34770: 'MCO',
    34771: 'MCO',
    34772: 'MCO',
    34773: 'MCO',
    34777: 'MCO',
    34778: 'MCO',
    34785: 'MCO',
    34786: 'MCO',
    34787: 'MCO',
    34788: 'MCO',
    34789: 'MCO',
    34797: 'MCO',
    34945: 'MIA',
    34946: 'MIA',
    34947: 'MIA',
    34948: 'MIA',
    34949: 'MIA',
    34950: 'MIA',
    34951: 'MIA',
    34952: 'MIA',
    34953: 'MIA',
    34954: 'MIA',
    34956: 'MIA',
    34957: 'MIA',
    34958: 'MIA',
    34972: 'MIA',
    34973: 'MIA',
    34974: 'MIA',
    34979: 'MIA',
    34981: 'MIA',
    34982: 'MIA',
    34983: 'MIA',
    34984: 'MIA',
    34985: 'MIA',
    34986: 'MIA',
    34987: 'MIA',
    34988: 'MIA',
    34990: 'MIA',
    34991: 'MIA',
    34992: 'MIA',
    34994: 'MIA',
    34995: 'MIA',
    34996: 'MIA',
    34997: 'MIA',
    35004: 'BHM',
    35005: 'BHM',
    35006: 'BHM',
    35007: 'BHM',
    35010: 'MGM',
    35011: 'MGM',
    35013: 'BHM',
    35014: 'BHM',
    35015: 'BHM',
    35016: 'HSV',
    35019: 'BHM',
    35020: 'BHM',
    35021: 'BHM',
    35022: 'BHM',
    35023: 'BHM',
    35031: 'BHM',
    35032: 'BHM',
    35033: 'BHM',
    35034: 'MGM',
    35035: 'MGM',
    35036: 'BHM',
    35038: 'BHM',
    35040: 'MGM',
    35041: 'BHM',
    35042: 'MGM',
    35043: 'BHM',
    35044: 'BHM',
    35045: 'MGM',
    35046: 'MGM',
    35048: 'BHM',
    35049: 'BHM',
    35051: 'BHM',
    35052: 'BHM',
    35053: 'BHM',
    35054: 'BHM',
    35055: 'BHM',
    35056: 'BHM',
    35057: 'BHM',
    35058: 'BHM',
    35060: 'BHM',
    35061: 'BHM',
    35062: 'BHM',
    35063: 'BHM',
    35064: 'BHM',
    35068: 'BHM',
    35070: 'BHM',
    35071: 'BHM',
    35072: 'MGM',
    35073: 'BHM',
    35074: 'BHM',
    35077: 'BHM',
    35078: 'BHM',
    35079: 'BHM',
    35080: 'BHM',
    35082: 'MGM',
    35083: 'BHM',
    35085: 'MGM',
    35087: 'HSV',
    35089: 'MGM',
    35091: 'BHM',
    35094: 'BHM',
    35096: 'BHM',
    35097: 'BHM',
    35098: 'BHM',
    35111: 'BHM',
    35112: 'BHM',
    35114: 'BHM',
    35115: 'MGM',
    35116: 'BHM',
    35117: 'BHM',
    35118: 'BHM',
    35119: 'BHM',
    35120: 'BHM',
    35121: 'BHM',
    35123: 'BHM',
    35124: 'BHM',
    35125: 'BHM',
    35126: 'BHM',
    35127: 'BHM',
    35128: 'BHM',
    35130: 'BHM',
    35131: 'BHM',
    35133: 'BHM',
    35135: 'BHM',
    35136: 'MGM',
    35137: 'BHM',
    35139: 'BHM',
    35142: 'BHM',
    35143: 'MGM',
    35144: 'BHM',
    35146: 'BHM',
    35147: 'BHM',
    35148: 'BHM',
    35149: 'BHM',
    35150: 'BHM',
    35151: 'MGM',
    35160: 'BHM',
    35161: 'BHM',
    35171: 'MGM',
    35172: 'BHM',
    35173: 'BHM',
    35175: 'HSV',
    35176: 'BHM',
    35178: 'BHM',
    35179: 'BHM',
    35180: 'BHM',
    35181: 'BHM',
    35182: 'BHM',
    35183: 'MGM',
    35184: 'MGM',
    35185: 'BHM',
    35186: 'BHM',
    35187: 'MGM',
    35188: 'BHM',
    35201: 'BHM',
    35202: 'BHM',
    35203: 'BHM',
    35204: 'BHM',
    35205: 'BHM',
    35206: 'BHM',
    35207: 'BHM',
    35208: 'BHM',
    35209: 'BHM',
    35210: 'BHM',
    35211: 'BHM',
    35212: 'BHM',
    35213: 'BHM',
    35214: 'BHM',
    35215: 'BHM',
    35216: 'BHM',
    35217: 'BHM',
    35218: 'BHM',
    35219: 'BHM',
    35220: 'BHM',
    35221: 'BHM',
    35222: 'BHM',
    35223: 'BHM',
    35224: 'BHM',
    35225: 'BHM',
    35226: 'BHM',
    35228: 'BHM',
    35229: 'BHM',
    35230: 'BHM',
    35231: 'BHM',
    35232: 'BHM',
    35233: 'BHM',
    35234: 'BHM',
    35235: 'BHM',
    35236: 'BHM',
    35237: 'BHM',
    35238: 'BHM',
    35240: 'BHM',
    35242: 'BHM',
    35243: 'BHM',
    35244: 'BHM',
    35245: 'BHM',
    35246: 'BHM',
    35249: 'BHM',
    35253: 'BHM',
    35254: 'BHM',
    35255: 'BHM',
    35259: 'BHM',
    35260: 'BHM',
    35261: 'BHM',
    35263: 'BHM',
    35266: 'BHM',
    35270: 'BHM',
    35277: 'BHM',
    35278: 'BHM',
    35279: 'BHM',
    35280: 'BHM',
    35281: 'BHM',
    35282: 'BHM',
    35283: 'BHM',
    35285: 'BHM',
    35286: 'BHM',
    35287: 'BHM',
    35288: 'BHM',
    35289: 'BHM',
    35290: 'BHM',
    35291: 'BHM',
    35292: 'BHM',
    35293: 'BHM',
    35294: 'BHM',
    35295: 'BHM',
    35296: 'BHM',
    35297: 'BHM',
    35298: 'BHM',
    35299: 'BHM',
    35401: 'BHM',
    35402: 'BHM',
    35403: 'BHM',
    35404: 'BHM',
    35405: 'BHM',
    35406: 'BHM',
    35407: 'BHM',
    35440: 'BHM',
    35441: 'MGM',
    35442: 'BHM',
    35443: 'MGM',
    35444: 'BHM',
    35446: 'BHM',
    35447: 'BHM',
    35448: 'MGM',
    35449: 'BHM',
    35452: 'BHM',
    35453: 'BHM',
    35456: 'MGM',
    35457: 'BHM',
    35458: 'BHM',
    35459: 'MGM',
    35460: 'MGM',
    35461: 'BHM',
    35462: 'MGM',
    35463: 'MGM',
    35464: 'MGM',
    35466: 'BHM',
    35468: 'BHM',
    35469: 'MGM',
    35470: 'MGM',
    35471: 'BHM',
    35473: 'BHM',
    35474: 'MGM',
    35475: 'BHM',
    35476: 'BHM',
    35477: 'MGM',
    35478: 'BHM',
    35480: 'BHM',
    35481: 'BHM',
    35482: 'BHM',
    35485: 'BHM',
    35486: 'BHM',
    35487: 'BHM',
    35490: 'BHM',
    35491: 'MGM',
    35501: 'BHM',
    35502: 'BHM',
    35503: 'BHM',
    35504: 'BHM',
    35540: 'BHM',
    35541: 'BHM',
    35542: 'BHM',
    35543: 'BHM',
    35544: 'BHM',
    35545: 'BHM',
    35546: 'BHM',
    35548: 'BHM',
    35549: 'BHM',
    35550: 'BHM',
    35551: 'BHM',
    35552: 'BHM',
    35553: 'BHM',
    35554: 'BHM',
    35555: 'BHM',
    35559: 'BHM',
    35560: 'BHM',
    35563: 'BHM',
    35564: 'BHM',
    35565: 'BHM',
    35570: 'BHM',
    35571: 'HSV',
    35572: 'BHM',
    35573: 'BHM',
    35574: 'BHM',
    35575: 'BHM',
    35576: 'BHM',
    35577: 'BHM',
    35578: 'BHM',
    35579: 'BHM',
    35580: 'BHM',
    35581: 'HSV',
    35582: 'HSV',
    35584: 'BHM',
    35585: 'HSV',
    35586: 'BHM',
    35587: 'BHM',
    35592: 'BHM',
    35593: 'HSV',
    35594: 'BHM',
    35601: 'HSV',
    35602: 'HSV',
    35603: 'HSV',
    35609: 'HSV',
    35610: 'HSV',
    35611: 'HSV',
    35612: 'HSV',
    35613: 'HSV',
    35614: 'HSV',
    35615: 'HSV',
    35616: 'HSV',
    35617: 'HSV',
    35618: 'HSV',
    35619: 'HSV',
    35620: 'HSV',
    35621: 'HSV',
    35622: 'HSV',
    35630: 'HSV',
    35631: 'HSV',
    35632: 'HSV',
    35633: 'HSV',
    35634: 'HSV',
    35640: 'HSV',
    35643: 'HSV',
    35645: 'HSV',
    35646: 'HSV',
    35647: 'HSV',
    35648: 'HSV',
    35649: 'HSV',
    35650: 'HSV',
    35651: 'HSV',
    35652: 'HSV',
    35653: 'HSV',
    35654: 'HSV',
    35660: 'HSV',
    35661: 'HSV',
    35662: 'HSV',
    35670: 'HSV',
    35671: 'HSV',
    35672: 'HSV',
    35673: 'HSV',
    35674: 'HSV',
    35677: 'HSV',
    35699: 'HSV',
    35739: 'HSV',
    35740: 'CHA',
    35741: 'HSV',
    35742: 'HSV',
    35744: 'CHA',
    35745: 'HSV',
    35746: 'CHA',
    35747: 'HSV',
    35748: 'HSV',
    35749: 'HSV',
    35750: 'HSV',
    35751: 'HSV',
    35752: 'CHA',
    35754: 'HSV',
    35755: 'HSV',
    35756: 'HSV',
    35757: 'HSV',
    35758: 'HSV',
    35759: 'HSV',
    35760: 'HSV',
    35761: 'HSV',
    35762: 'HSV',
    35763: 'HSV',
    35764: 'HSV',
    35765: 'CHA',
    35766: 'HSV',
    35767: 'HSV',
    35768: 'HSV',
    35769: 'CHA',
    35771: 'CHA',
    35772: 'CHA',
    35773: 'HSV',
    35774: 'HSV',
    35775: 'HSV',
    35776: 'HSV',
    35801: 'HSV',
    35802: 'HSV',
    35803: 'HSV',
    35804: 'HSV',
    35805: 'HSV',
    35806: 'HSV',
    35807: 'HSV',
    35808: 'HSV',
    35809: 'HSV',
    35810: 'HSV',
    35811: 'HSV',
    35812: 'HSV',
    35813: 'HSV',
    35814: 'HSV',
    35815: 'HSV',
    35816: 'HSV',
    35824: 'HSV',
    35893: 'HSV',
    35894: 'HSV',
    35895: 'HSV',
    35896: 'HSV',
    35897: 'HSV',
    35898: 'HSV',
    35899: 'HSV',
    35901: 'BHM',
    35902: 'BHM',
    35903: 'BHM',
    35904: 'BHM',
    35905: 'BHM',
    35906: 'BHM',
    35907: 'BHM',
    35950: 'BHM',
    35951: 'HSV',
    35952: 'BHM',
    35953: 'BHM',
    35954: 'BHM',
    35956: 'BHM',
    35957: 'BHM',
    35958: 'CHA',
    35959: 'BHM',
    35960: 'BHM',
    35961: 'BHM',
    35962: 'BHM',
    35963: 'CHA',
    35964: 'BHM',
    35966: 'CHA',
    35967: 'CHA',
    35968: 'CHA',
    35971: 'CHA',
    35972: 'BHM',
    35973: 'BHM',
    35974: 'CHA',
    35975: 'CHA',
    35976: 'BHM',
    35978: 'CHA',
    35979: 'CHA',
    35980: 'BHM',
    35981: 'CHA',
    35983: 'BHM',
    35984: 'CHA',
    35986: 'CHA',
    35987: 'BHM',
    35988: 'CHA',
    35989: 'CHA',
    35990: 'BHM',
    36003: 'MGM',
    36005: 'MGM',
    36006: 'MGM',
    36008: 'MGM',
    36009: 'DHN',
    36010: 'DHN',
    36013: 'MGM',
    36015: 'PNS',
    36016: 'DHN',
    36017: 'DHN',
    36020: 'MGM',
    36022: 'MGM',
    36023: 'MGM',
    36024: 'MGM',
    36025: 'MGM',
    36026: 'MGM',
    36027: 'DHN',
    36028: 'DHN',
    36029: 'MGM',
    36030: 'MGM',
    36031: 'MGM',
    36032: 'MGM',
    36033: 'PNS',
    36034: 'DHN',
    36035: 'MGM',
    36036: 'MGM',
    36037: 'MGM',
    36038: 'DHN',
    36039: 'MGM',
    36040: 'MGM',
    36041: 'MGM',
    36042: 'MGM',
    36043: 'MGM',
    36045: 'MGM',
    36046: 'MGM',
    36047: 'MGM',
    36048: 'DHN',
    36049: 'MGM',
    36051: 'MGM',
    36052: 'MGM',
    36053: 'MGM',
    36054: 'MGM',
    36057: 'MGM',
    36061: 'MGM',
    36062: 'MGM',
    36064: 'MGM',
    36065: 'MGM',
    36066: 'MGM',
    36067: 'MGM',
    36068: 'MGM',
    36069: 'MGM',
    36071: 'MGM',
    36072: 'DHN',
    36075: 'MGM',
    36078: 'MGM',
    36079: 'MGM',
    36080: 'MGM',
    36081: 'MGM',
    36082: 'MGM',
    36083: 'MGM',
    36087: 'MGM',
    36088: 'MGM',
    36089: 'MGM',
    36091: 'MGM',
    36092: 'MGM',
    36093: 'MGM',
    36101: 'MGM',
    36102: 'MGM',
    36103: 'MGM',
    36104: 'MGM',
    36105: 'MGM',
    36106: 'MGM',
    36107: 'MGM',
    36108: 'MGM',
    36109: 'MGM',
    36110: 'MGM',
    36111: 'MGM',
    36112: 'MGM',
    36113: 'MGM',
    36114: 'MGM',
    36115: 'MGM',
    36116: 'MGM',
    36117: 'MGM',
    36118: 'MGM',
    36119: 'MGM',
    36120: 'MGM',
    36121: 'MGM',
    36123: 'MGM',
    36124: 'MGM',
    36125: 'MGM',
    36130: 'MGM',
    36131: 'MGM',
    36132: 'MGM',
    36133: 'MGM',
    36134: 'MGM',
    36135: 'MGM',
    36140: 'MGM',
    36141: 'MGM',
    36142: 'MGM',
    36177: 'MGM',
    36191: 'MGM',
    36201: 'BHM',
    36202: 'BHM',
    36203: 'BHM',
    36204: 'BHM',
    36205: 'BHM',
    36206: 'BHM',
    36207: 'BHM',
    36210: 'BHM',
    36250: 'BHM',
    36251: 'BHM',
    36253: 'BHM',
    36254: 'BHM',
    36255: 'MGM',
    36256: 'MGM',
    36257: 'BHM',
    36258: 'BHM',
    36260: 'BHM',
    36261: 'BHM',
    36262: 'BHM',
    36263: 'BHM',
    36264: 'BHM',
    36265: 'BHM',
    36266: 'BHM',
    36267: 'MGM',
    36268: 'BHM',
    36269: 'BHM',
    36271: 'BHM',
    36272: 'BHM',
    36273: 'BHM',
    36274: 'MGM',
    36275: 'BHM',
    36276: 'MGM',
    36277: 'BHM',
    36278: 'BHM',
    36279: 'BHM',
    36280: 'BHM',
    36301: 'DHN',
    36302: 'DHN',
    36303: 'DHN',
    36304: 'DHN',
    36305: 'DHN',
    36310: 'DHN',
    36311: 'DHN',
    36312: 'DHN',
    36313: 'DHN',
    36314: 'DHN',
    36316: 'DHN',
    36317: 'DHN',
    36318: 'DHN',
    36319: 'DHN',
    36320: 'DHN',
    36321: 'DHN',
    36322: 'DHN',
    36323: 'DHN',
    36330: 'DHN',
    36331: 'DHN',
    36340: 'DHN',
    36343: 'DHN',
    36344: 'DHN',
    36345: 'DHN',
    36346: 'DHN',
    36349: 'DHN',
    36350: 'DHN',
    36351: 'DHN',
    36352: 'DHN',
    36353: 'DHN',
    36360: 'DHN',
    36361: 'DHN',
    36362: 'DHN',
    36370: 'DHN',
    36371: 'DHN',
    36373: 'DHN',
    36374: 'DHN',
    36375: 'DHN',
    36376: 'DHN',
    36401: 'PNS',
    36420: 'DHN',
    36421: 'DHN',
    36425: 'MGM',
    36426: 'PNS',
    36427: 'PNS',
    36429: 'PNS',
    36431: 'PNS',
    36432: 'PNS',
    36435: 'MGM',
    36436: 'MGM',
    36439: 'PNS',
    36441: 'PNS',
    36442: 'DHN',
    36444: 'PNS',
    36445: 'PNS',
    36446: 'MGM',
    36449: 'PNS',
    36451: 'MOB',
    36453: 'DHN',
    36454: 'PNS',
    36455: 'DHN',
    36456: 'PNS',
    36457: 'PNS',
    36458: 'PNS',
    36460: 'PNS',
    36461: 'PNS',
    36462: 'PNS',
    36467: 'DHN',
    36470: 'PNS',
    36471: 'PNS',
    36473: 'PNS',
    36474: 'PNS',
    36475: 'PNS',
    36476: 'DHN',
    36477: 'DHN',
    36480: 'MOB',
    36481: 'MGM',
    36482: 'MOB',
    36483: 'PNS',
    36501: 'PNS',
    36502: 'PNS',
    36503: 'PNS',
    36504: 'PNS',
    36505: 'MOB',
    36507: 'MOB',
    36509: 'MOB',
    36511: 'MOB',
    36512: 'MOB',
    36513: 'MOB',
    36515: 'MOB',
    36518: 'MOB',
    36521: 'MOB',
    36522: 'MOB',
    36523: 'MOB',
    36524: 'MGM',
    36525: 'MOB',
    36526: 'MOB',
    36527: 'MOB',
    36528: 'MOB',
    36529: 'MOB',
    36530: 'MOB',
    36532: 'MOB',
    36533: 'MOB',
    36535: 'MOB',
    36536: 'MOB',
    36538: 'MOB',
    36539: 'MOB',
    36540: 'MOB',
    36541: 'MOB',
    36542: 'MOB',
    36543: 'PNS',
    36544: 'MOB',
    36545: 'MOB',
    36547: 'MOB',
    36548: 'MOB',
    36549: 'PNS',
    36550: 'MOB',
    36551: 'MOB',
    36553: 'MOB',
    36555: 'MOB',
    36556: 'MOB',
    36558: 'MOB',
    36559: 'MOB',
    36560: 'MOB',
    36561: 'MOB',
    36562: 'MOB',
    36564: 'MOB',
    36567: 'MOB',
    36568: 'MOB',
    36569: 'MOB',
    36570: 'MOB',
    36571: 'MOB',
    36572: 'MOB',
    36574: 'PNS',
    36575: 'MOB',
    36576: 'MOB',
    36577: 'MOB',
    36578: 'MOB',
    36579: 'MOB',
    36580: 'MOB',
    36581: 'MOB',
    36582: 'MOB',
    36583: 'MOB',
    36584: 'MOB',
    36585: 'MOB',
    36586: 'MOB',
    36587: 'MOB',
    36590: 'MOB',
    36601: 'MOB',
    36602: 'MOB',
    36603: 'MOB',
    36604: 'MOB',
    36605: 'MOB',
    36606: 'MOB',
    36607: 'MOB',
    36608: 'MOB',
    36609: 'MOB',
    36610: 'MOB',
    36611: 'MOB',
    36612: 'MOB',
    36613: 'MOB',
    36614: 'MOB',
    36615: 'MOB',
    36616: 'MOB',
    36617: 'MOB',
    36618: 'MOB',
    36619: 'MOB',
    36621: 'MOB',
    36622: 'MOB',
    36623: 'MOB',
    36625: 'MOB',
    36626: 'MOB',
    36628: 'MOB',
    36630: 'MOB',
    36631: 'MOB',
    36633: 'MOB',
    36640: 'MOB',
    36641: 'MOB',
    36644: 'MOB',
    36652: 'MOB',
    36660: 'MOB',
    36663: 'MOB',
    36670: 'MOB',
    36671: 'MOB',
    36675: 'MOB',
    36685: 'MOB',
    36688: 'MOB',
    36689: 'MOB',
    36690: 'MOB',
    36691: 'MOB',
    36693: 'MOB',
    36695: 'MOB',
    36701: 'MGM',
    36702: 'MGM',
    36703: 'MGM',
    36720: 'MGM',
    36721: 'MGM',
    36722: 'MGM',
    36723: 'MGM',
    36726: 'MGM',
    36727: 'MGM',
    36728: 'MGM',
    36732: 'MGM',
    36736: 'MGM',
    36738: 'MGM',
    36740: 'MGM',
    36741: 'MGM',
    36742: 'MGM',
    36744: 'MGM',
    36745: 'MGM',
    36748: 'MGM',
    36749: 'MGM',
    36750: 'MGM',
    36751: 'MGM',
    36752: 'MGM',
    36753: 'MGM',
    36754: 'MGM',
    36756: 'MGM',
    36758: 'MGM',
    36759: 'MGM',
    36761: 'MGM',
    36762: 'MGM',
    36763: 'MGM',
    36764: 'MGM',
    36765: 'MGM',
    36766: 'MGM',
    36767: 'MGM',
    36768: 'MGM',
    36769: 'MGM',
    36773: 'MGM',
    36775: 'MGM',
    36776: 'MGM',
    36782: 'MGM',
    36783: 'MGM',
    36784: 'MGM',
    36785: 'MGM',
    36786: 'MGM',
    36790: 'MGM',
    36792: 'MGM',
    36793: 'MGM',
    36801: 'MGM',
    36802: 'MGM',
    36803: 'MGM',
    36804: 'MGM',
    36830: 'MGM',
    36831: 'MGM',
    36832: 'MGM',
    36849: 'MGM',
    36850: 'MGM',
    36851: 'DHN',
    36852: 'MGM',
    36853: 'MGM',
    36854: 'MGM',
    36855: 'MGM',
    36856: 'DHN',
    36858: 'MGM',
    36859: 'MGM',
    36860: 'MGM',
    36861: 'MGM',
    36862: 'MGM',
    36863: 'MGM',
    36865: 'MGM',
    36866: 'MGM',
    36867: 'MGM',
    36868: 'MGM',
    36869: 'MGM',
    36870: 'MGM',
    36871: 'DHN',
    36872: 'MGM',
    36874: 'MGM',
    36875: 'MGM',
    36877: 'MGM',
    36879: 'MGM',
    36901: 'MGM',
    36904: 'MGM',
    36907: 'MGM',
    36908: 'MGM',
    36910: 'MGM',
    36912: 'MGM',
    36913: 'MGM',
    36915: 'MGM',
    36916: 'MGM',
    36919: 'MGM',
    36921: 'MGM',
    36922: 'MGM',
    36925: 'MGM',
    37010: 'BNA',
    37011: 'BNA',
    37012: 'BNA',
    37013: 'BNA',
    37014: 'BNA',
    37015: 'BNA',
    37016: 'BNA',
    37018: 'BNA',
    37019: 'HSV',
    37020: 'BNA',
    37022: 'BNA',
    37023: 'BNA',
    37024: 'BNA',
    37025: 'BNA',
    37026: 'BNA',
    37027: 'BNA',
    37028: 'BNA',
    37029: 'BNA',
    37030: 'BNA',
    37031: 'BNA',
    37032: 'BNA',
    37033: 'BNA',
    37034: 'BNA',
    37035: 'BNA',
    37036: 'BNA',
    37037: 'BNA',
    37040: 'BNA',
    37041: 'BNA',
    37042: 'BNA',
    37043: 'BNA',
    37044: 'BNA',
    37046: 'BNA',
    37047: 'HSV',
    37048: 'BNA',
    37049: 'BNA',
    37050: 'BNA',
    37051: 'BNA',
    37052: 'BNA',
    37055: 'BNA',
    37056: 'BNA',
    37057: 'BNA',
    37058: 'BNA',
    37059: 'BNA',
    37060: 'BNA',
    37061: 'BNA',
    37062: 'BNA',
    37063: 'BNA',
    37064: 'BNA',
    37065: 'BNA',
    37066: 'BNA',
    37067: 'BNA',
    37068: 'BNA',
    37069: 'BNA',
    37070: 'BNA',
    37071: 'BNA',
    37072: 'BNA',
    37073: 'BNA',
    37074: 'BNA',
    37075: 'BNA',
    37076: 'BNA',
    37077: 'BNA',
    37078: 'BNA',
    37079: 'BNA',
    37080: 'BNA',
    37082: 'BNA',
    37083: 'BNA',
    37085: 'BNA',
    37086: 'BNA',
    37087: 'BNA',
    37088: 'BNA',
    37089: 'BNA',
    37090: 'BNA',
    37091: 'BNA',
    37095: 'BNA',
    37096: 'BNA',
    37097: 'BNA',
    37098: 'BNA',
    37101: 'BNA',
    37110: 'BNA',
    37111: 'BNA',
    37115: 'BNA',
    37116: 'BNA',
    37118: 'BNA',
    37119: 'BNA',
    37121: 'BNA',
    37122: 'BNA',
    37127: 'BNA',
    37128: 'BNA',
    37129: 'BNA',
    37130: 'BNA',
    37131: 'BNA',
    37132: 'BNA',
    37133: 'BNA',
    37134: 'BNA',
    37135: 'BNA',
    37136: 'BNA',
    37137: 'BNA',
    37138: 'BNA',
    37140: 'BNA',
    37141: 'BNA',
    37142: 'BNA',
    37143: 'BNA',
    37144: 'HSV',
    37145: 'BNA',
    37146: 'BNA',
    37147: 'BNA',
    37148: 'BNA',
    37149: 'BNA',
    37150: 'BNA',
    37151: 'BNA',
    37152: 'BNA',
    37153: 'BNA',
    37155: 'BNA',
    37160: 'HSV',
    37161: 'BNA',
    37162: 'BNA',
    37165: 'BNA',
    37166: 'BNA',
    37167: 'BNA',
    37171: 'BNA',
    37172: 'BNA',
    37174: 'BNA',
    37175: 'BNA',
    37178: 'BNA',
    37179: 'BNA',
    37180: 'BNA',
    37181: 'BNA',
    37183: 'BNA',
    37184: 'BNA',
    37185: 'BNA',
    37186: 'BNA',
    37187: 'BNA',
    37188: 'BNA',
    37189: 'BNA',
    37190: 'BNA',
    37191: 'BNA',
    37201: 'BNA',
    37202: 'BNA',
    37203: 'BNA',
    37204: 'BNA',
    37205: 'BNA',
    37206: 'BNA',
    37207: 'BNA',
    37208: 'BNA',
    37209: 'BNA',
    37210: 'BNA',
    37211: 'BNA',
    37212: 'BNA',
    37213: 'BNA',
    37214: 'BNA',
    37215: 'BNA',
    37216: 'BNA',
    37217: 'BNA',
    37218: 'BNA',
    37219: 'BNA',
    37220: 'BNA',
    37221: 'BNA',
    37222: 'BNA',
    37224: 'BNA',
    37227: 'BNA',
    37228: 'BNA',
    37229: 'BNA',
    37230: 'BNA',
    37232: 'BNA',
    37234: 'BNA',
    37235: 'BNA',
    37236: 'BNA',
    37237: 'BNA',
    37238: 'BNA',
    37239: 'BNA',
    37240: 'BNA',
    37241: 'BNA',
    37242: 'BNA',
    37243: 'BNA',
    37244: 'BNA',
    37245: 'BNA',
    37246: 'BNA',
    37247: 'BNA',
    37248: 'BNA',
    37249: 'BNA',
    37250: 'BNA',
    37301: 'CHA',
    37302: 'CHA',
    37303: 'CHA',
    37304: 'CHA',
    37305: 'CHA',
    37306: 'HSV',
    37307: 'CHA',
    37308: 'CHA',
    37309: 'CHA',
    37310: 'CHA',
    37311: 'CHA',
    37312: 'CHA',
    37313: 'CHA',
    37314: 'TYS',
    37315: 'CHA',
    37316: 'CHA',
    37317: 'CHA',
    37318: 'CHA',
    37320: 'CHA',
    37321: 'CHA',
    37322: 'CHA',
    37323: 'CHA',
    37324: 'CHA',
    37325: 'CHA',
    37326: 'CHA',
    37327: 'CHA',
    37328: 'HSV',
    37329: 'CHA',
    37330: 'HSV',
    37331: 'CHA',
    37332: 'CHA',
    37333: 'TYS',
    37334: 'HSV',
    37335: 'HSV',
    37336: 'CHA',
    37337: 'BNA',
    37338: 'CHA',
    37339: 'CHA',
    37340: 'CHA',
    37341: 'CHA',
    37342: 'CHA',
    37343: 'CHA',
    37345: 'HSV',
    37347: 'CHA',
    37348: 'HSV',
    37349: 'BNA',
    37350: 'CHA',
    37351: 'CHA',
    37352: 'HSV',
    37353: 'CHA',
    37354: 'CHA',
    37355: 'BNA',
    37356: 'CHA',
    37357: 'BNA',
    37359: 'HSV',
    37360: 'BNA',
    37361: 'CHA',
    37362: 'CHA',
    37363: 'CHA',
    37364: 'CHA',
    37365: 'CHA',
    37366: 'CHA',
    37367: 'CHA',
    37369: 'CHA',
    37370: 'CHA',
    37371: 'CHA',
    37372: 'CHA',
    37373: 'CHA',
    37374: 'CHA',
    37375: 'CHA',
    37376: 'CHA',
    37377: 'CHA',
    37378: 'BNA',
    37379: 'CHA',
    37380: 'CHA',
    37381: 'CHA',
    37382: 'BNA',
    37383: 'CHA',
    37384: 'CHA',
    37385: 'TYS',
    37387: 'CHA',
    37388: 'HSV',
    37389: 'HSV',
    37391: 'CHA',
    37394: 'CHA',
    37395: 'CHA',
    37396: 'CHA',
    37397: 'CHA',
    37398: 'HSV',
    37401: 'CHA',
    37402: 'CHA',
    37403: 'CHA',
    37404: 'CHA',
    37405: 'CHA',
    37406: 'CHA',
    37407: 'CHA',
    37408: 'CHA',
    37409: 'CHA',
    37410: 'CHA',
    37411: 'CHA',
    37412: 'CHA',
    37414: 'CHA',
    37415: 'CHA',
    37416: 'CHA',
    37419: 'CHA',
    37421: 'CHA',
    37422: 'CHA',
    37424: 'CHA',
    37450: 'CHA',
    37501: 'MEM',
    37544: 'MEM',
    37601: 'TYS',
    37602: 'TYS',
    37604: 'TYS',
    37605: 'TYS',
    37614: 'TYS',
    37615: 'TYS',
    37616: 'TYS',
    37617: 'TYS',
    37618: 'TYS',
    37620: 'TYS',
    37621: 'TYS',
    37625: 'TYS',
    37640: 'TYS',
    37641: 'TYS',
    37642: 'TYS',
    37643: 'TYS',
    37644: 'TYS',
    37645: 'TYS',
    37650: 'TYS',
    37656: 'TYS',
    37657: 'TYS',
    37658: 'TYS',
    37659: 'TYS',
    37660: 'TYS',
    37662: 'TYS',
    37663: 'TYS',
    37664: 'TYS',
    37665: 'TYS',
    37669: 'TYS',
    37680: 'TYS',
    37681: 'TYS',
    37682: 'TYS',
    37683: 'TYS',
    37684: 'TYS',
    37686: 'TYS',
    37687: 'TYS',
    37688: 'TYS',
    37690: 'TYS',
    37691: 'TYS',
    37692: 'TYS',
    37694: 'TYS',
    37699: 'TYS',
    37701: 'TYS',
    37705: 'TYS',
    37707: 'TYS',
    37708: 'TYS',
    37709: 'TYS',
    37710: 'TYS',
    37711: 'TYS',
    37713: 'TYS',
    37714: 'TYS',
    37715: 'TYS',
    37716: 'TYS',
    37717: 'TYS',
    37719: 'TYS',
    37721: 'TYS',
    37722: 'TYS',
    37723: 'BNA',
    37724: 'TYS',
    37725: 'TYS',
    37726: 'BNA',
    37727: 'TYS',
    37729: 'TYS',
    37730: 'TYS',
    37731: 'TYS',
    37732: 'TYS',
    37733: 'BNA',
    37737: 'TYS',
    37738: 'TYS',
    37742: 'TYS',
    37743: 'TYS',
    37744: 'TYS',
    37745: 'TYS',
    37748: 'TYS',
    37752: 'TYS',
    37753: 'TYS',
    37754: 'TYS',
    37755: 'TYS',
    37756: 'TYS',
    37757: 'TYS',
    37760: 'TYS',
    37762: 'TYS',
    37763: 'TYS',
    37764: 'TYS',
    37765: 'TYS',
    37766: 'TYS',
    37769: 'TYS',
    37770: 'BNA',
    37771: 'TYS',
    37772: 'TYS',
    37773: 'TYS',
    37774: 'TYS',
    37777: 'TYS',
    37778: 'TYS',
    37779: 'TYS',
    37801: 'TYS',
    37802: 'TYS',
    37803: 'TYS',
    37804: 'TYS',
    37806: 'TYS',
    37807: 'TYS',
    37809: 'TYS',
    37810: 'TYS',
    37811: 'TYS',
    37813: 'TYS',
    37814: 'TYS',
    37815: 'TYS',
    37816: 'TYS',
    37818: 'TYS',
    37819: 'TYS',
    37820: 'TYS',
    37821: 'TYS',
    37822: 'TYS',
    37824: 'TYS',
    37825: 'TYS',
    37826: 'CHA',
    37828: 'TYS',
    37829: 'BNA',
    37830: 'TYS',
    37831: 'TYS',
    37840: 'TYS',
    37841: 'TYS',
    37842: 'TYS',
    37843: 'TYS',
    37845: 'TYS',
    37846: 'TYS',
    37847: 'TYS',
    37848: 'TYS',
    37849: 'TYS',
    37851: 'TYS',
    37852: 'TYS',
    37853: 'TYS',
    37854: 'BNA',
    37857: 'TYS',
    37860: 'TYS',
    37861: 'TYS',
    37862: 'TYS',
    37863: 'TYS',
    37864: 'TYS',
    37865: 'TYS',
    37866: 'TYS',
    37867: 'TYS',
    37868: 'TYS',
    37869: 'TYS',
    37870: 'TYS',
    37871: 'TYS',
    37872: 'BNA',
    37873: 'TYS',
    37874: 'CHA',
    37876: 'TYS',
    37877: 'TYS',
    37878: 'TYS',
    37879: 'TYS',
    37880: 'CHA',
    37881: 'TYS',
    37882: 'TYS',
    37885: 'TYS',
    37886: 'TYS',
    37887: 'TYS',
    37888: 'TYS',
    37890: 'TYS',
    37891: 'TYS',
    37892: 'TYS',
    37901: 'TYS',
    37902: 'TYS',
    37909: 'TYS',
    37912: 'TYS',
    37914: 'TYS',
    37915: 'TYS',
    37916: 'TYS',
    37917: 'TYS',
    37918: 'TYS',
    37919: 'TYS',
    37920: 'TYS',
    37921: 'TYS',
    37922: 'TYS',
    37923: 'TYS',
    37924: 'TYS',
    37927: 'TYS',
    37928: 'TYS',
    37929: 'TYS',
    37930: 'TYS',
    37931: 'TYS',
    37932: 'TYS',
    37933: 'TYS',
    37934: 'TYS',
    37938: 'TYS',
    37939: 'TYS',
    37940: 'TYS',
    37950: 'TYS',
    37955: 'TYS',
    37990: 'TYS',
    37995: 'TYS',
    37996: 'TYS',
    37997: 'TYS',
    37998: 'TYS',
    38001: 'MEM',
    38002: 'MEM',
    38004: 'MEM',
    38006: 'MEM',
    38007: 'MEM',
    38008: 'MEM',
    38010: 'MEM',
    38011: 'MEM',
    38012: 'MEM',
    38014: 'MEM',
    38015: 'MEM',
    38016: 'MEM',
    38017: 'MEM',
    38018: 'MEM',
    38019: 'MEM',
    38021: 'MEM',
    38023: 'MEM',
    38024: 'MEM',
    38025: 'MEM',
    38027: 'MEM',
    38028: 'MEM',
    38029: 'MEM',
    38030: 'MEM',
    38034: 'MEM',
    38036: 'MEM',
    38037: 'MEM',
    38039: 'MEM',
    38040: 'MEM',
    38041: 'MEM',
    38042: 'MEM',
    38044: 'MEM',
    38045: 'MEM',
    38046: 'MEM',
    38047: 'MEM',
    38048: 'MEM',
    38049: 'MEM',
    38050: 'MEM',
    38052: 'MEM',
    38053: 'MEM',
    38054: 'MEM',
    38055: 'MEM',
    38057: 'MEM',
    38058: 'MEM',
    38059: 'MEM',
    38060: 'MEM',
    38061: 'MEM',
    38063: 'MEM',
    38066: 'MEM',
    38067: 'MEM',
    38068: 'MEM',
    38069: 'MEM',
    38070: 'MEM',
    38071: 'MEM',
    38075: 'MEM',
    38076: 'MEM',
    38077: 'MEM',
    38079: 'MEM',
    38080: 'MEM',
    38083: 'MEM',
    38088: 'MEM',
    38101: 'MEM',
    38103: 'MEM',
    38104: 'MEM',
    38105: 'MEM',
    38106: 'MEM',
    38107: 'MEM',
    38108: 'MEM',
    38109: 'MEM',
    38110: 'MEM',
    38111: 'MEM',
    38112: 'MEM',
    38113: 'MEM',
    38114: 'MEM',
    38115: 'MEM',
    38116: 'MEM',
    38117: 'MEM',
    38118: 'MEM',
    38119: 'MEM',
    38120: 'MEM',
    38122: 'MEM',
    38124: 'MEM',
    38125: 'MEM',
    38126: 'MEM',
    38127: 'MEM',
    38128: 'MEM',
    38130: 'MEM',
    38131: 'MEM',
    38132: 'MEM',
    38133: 'MEM',
    38134: 'MEM',
    38135: 'MEM',
    38136: 'MEM',
    38137: 'MEM',
    38138: 'MEM',
    38139: 'MEM',
    38141: 'MEM',
    38142: 'MEM',
    38145: 'MEM',
    38147: 'MEM',
    38148: 'MEM',
    38150: 'MEM',
    38151: 'MEM',
    38152: 'MEM',
    38157: 'MEM',
    38159: 'MEM',
    38161: 'MEM',
    38163: 'MEM',
    38165: 'MEM',
    38166: 'MEM',
    38167: 'MEM',
    38168: 'MEM',
    38173: 'MEM',
    38174: 'MEM',
    38175: 'MEM',
    38177: 'MEM',
    38181: 'MEM',
    38182: 'MEM',
    38183: 'MEM',
    38184: 'MEM',
    38186: 'MEM',
    38187: 'MEM',
    38188: 'MEM',
    38190: 'MEM',
    38193: 'MEM',
    38194: 'MEM',
    38197: 'MEM',
    38201: 'MEM',
    38220: 'MEM',
    38221: 'MEM',
    38222: 'MEM',
    38223: 'MEM',
    38224: 'MEM',
    38225: 'MEM',
    38226: 'MEM',
    38227: 'MEM',
    38229: 'MEM',
    38230: 'MEM',
    38231: 'MEM',
    38232: 'MEM',
    38233: 'MEM',
    38235: 'MEM',
    38236: 'MEM',
    38237: 'MEM',
    38238: 'MEM',
    38240: 'MEM',
    38241: 'MEM',
    38242: 'MEM',
    38251: 'MEM',
    38253: 'MEM',
    38254: 'MEM',
    38255: 'MEM',
    38256: 'MEM',
    38257: 'MEM',
    38258: 'MEM',
    38259: 'MEM',
    38260: 'MEM',
    38261: 'MEM',
    38271: 'MEM',
    38281: 'MEM',
    38301: 'MEM',
    38302: 'MEM',
    38303: 'MEM',
    38305: 'MEM',
    38308: 'MEM',
    38310: 'MEM',
    38311: 'MEM',
    38313: 'MEM',
    38314: 'MEM',
    38315: 'MEM',
    38316: 'MEM',
    38317: 'MEM',
    38318: 'MEM',
    38320: 'MEM',
    38321: 'MEM',
    38324: 'MEM',
    38326: 'MEM',
    38327: 'MEM',
    38328: 'MEM',
    38329: 'MEM',
    38330: 'MEM',
    38331: 'MEM',
    38332: 'MEM',
    38333: 'MEM',
    38334: 'MEM',
    38336: 'MEM',
    38337: 'MEM',
    38338: 'MEM',
    38339: 'MEM',
    38340: 'MEM',
    38341: 'MEM',
    38342: 'MEM',
    38343: 'MEM',
    38344: 'MEM',
    38345: 'MEM',
    38346: 'MEM',
    38347: 'MEM',
    38348: 'MEM',
    38351: 'MEM',
    38352: 'MEM',
    38355: 'MEM',
    38356: 'MEM',
    38357: 'MEM',
    38358: 'MEM',
    38359: 'MEM',
    38361: 'MEM',
    38362: 'MEM',
    38363: 'MEM',
    38365: 'MEM',
    38366: 'MEM',
    38367: 'MEM',
    38368: 'MEM',
    38369: 'MEM',
    38370: 'MEM',
    38371: 'MEM',
    38372: 'MEM',
    38374: 'MEM',
    38375: 'MEM',
    38376: 'MEM',
    38377: 'MEM',
    38378: 'MEM',
    38379: 'MEM',
    38380: 'MEM',
    38381: 'MEM',
    38382: 'MEM',
    38387: 'MEM',
    38388: 'MEM',
    38389: 'MEM',
    38390: 'MEM',
    38391: 'MEM',
    38392: 'MEM',
    38393: 'MEM',
    38401: 'BNA',
    38402: 'BNA',
    38425: 'HSV',
    38449: 'HSV',
    38450: 'HSV',
    38451: 'BNA',
    38452: 'HSV',
    38453: 'HSV',
    38454: 'BNA',
    38455: 'HSV',
    38456: 'HSV',
    38457: 'HSV',
    38459: 'HSV',
    38460: 'HSV',
    38461: 'BNA',
    38462: 'BNA',
    38463: 'HSV',
    38464: 'HSV',
    38468: 'HSV',
    38469: 'HSV',
    38471: 'HSV',
    38472: 'HSV',
    38473: 'HSV',
    38474: 'BNA',
    38475: 'HSV',
    38476: 'BNA',
    38477: 'HSV',
    38478: 'HSV',
    38481: 'HSV',
    38482: 'BNA',
    38483: 'HSV',
    38485: 'HSV',
    38486: 'HSV',
    38487: 'BNA',
    38488: 'HSV',
    38501: 'BNA',
    38502: 'BNA',
    38503: 'BNA',
    38504: 'BNA',
    38505: 'BNA',
    38506: 'BNA',
    38541: 'BNA',
    38542: 'BNA',
    38543: 'BNA',
    38544: 'BNA',
    38545: 'BNA',
    38547: 'BNA',
    38548: 'BNA',
    38549: 'BNA',
    38550: 'BNA',
    38551: 'BNA',
    38552: 'BNA',
    38553: 'BNA',
    38554: 'BNA',
    38555: 'BNA',
    38556: 'BNA',
    38557: 'BNA',
    38558: 'BNA',
    38559: 'BNA',
    38560: 'BNA',
    38562: 'BNA',
    38563: 'BNA',
    38564: 'BNA',
    38565: 'BNA',
    38567: 'BNA',
    38568: 'BNA',
    38569: 'BNA',
    38570: 'BNA',
    38571: 'BNA',
    38572: 'BNA',
    38573: 'BNA',
    38574: 'BNA',
    38575: 'BNA',
    38577: 'BNA',
    38578: 'BNA',
    38579: 'BNA',
    38580: 'BNA',
    38581: 'BNA',
    38582: 'BNA',
    38583: 'BNA',
    38585: 'BNA',
    38587: 'BNA',
    38588: 'BNA',
    38589: 'BNA',
    38601: 'MEM',
    38602: 'MEM',
    38603: 'MEM',
    38606: 'MEM',
    38609: 'MEM',
    38610: 'MEM',
    38611: 'MEM',
    38614: 'MEM',
    38617: 'MEM',
    38618: 'MEM',
    38619: 'MEM',
    38620: 'MEM',
    38621: 'MEM',
    38622: 'MEM',
    38623: 'MEM',
    38625: 'MEM',
    38626: 'MEM',
    38627: 'MEM',
    38628: 'MEM',
    38629: 'MEM',
    38630: 'MEM',
    38631: 'MEM',
    38632: 'MEM',
    38633: 'MEM',
    38634: 'MEM',
    38635: 'MEM',
    38637: 'MEM',
    38638: 'MEM',
    38639: 'MEM',
    38641: 'MEM',
    38642: 'MEM',
    38643: 'MEM',
    38644: 'MEM',
    38645: 'MEM',
    38646: 'MEM',
    38647: 'MEM',
    38649: 'MEM',
    38650: 'MEM',
    38651: 'MEM',
    38652: 'MEM',
    38654: 'MEM',
    38655: 'MEM',
    38658: 'MEM',
    38659: 'MEM',
    38661: 'MEM',
    38663: 'MEM',
    38664: 'MEM',
    38665: 'MEM',
    38666: 'MEM',
    38668: 'MEM',
    38669: 'MEM',
    38670: 'MEM',
    38671: 'MEM',
    38672: 'MEM',
    38673: 'MEM',
    38674: 'MEM',
    38675: 'MEM',
    38676: 'MEM',
    38677: 'MEM',
    38679: 'MEM',
    38680: 'MEM',
    38683: 'MEM',
    38685: 'MEM',
    38686: 'MEM',
    38701: 'JAN',
    38702: 'JAN',
    38703: 'JAN',
    38704: 'JAN',
    38720: 'MEM',
    38721: 'JAN',
    38722: 'JAN',
    38723: 'JAN',
    38725: 'JAN',
    38726: 'MEM',
    38730: 'MEM',
    38731: 'JAN',
    38732: 'MEM',
    38733: 'MEM',
    38736: 'MEM',
    38737: 'MEM',
    38738: 'MEM',
    38739: 'MEM',
    38740: 'MEM',
    38744: 'JAN',
    38745: 'JAN',
    38746: 'MEM',
    38748: 'JAN',
    38749: 'JAN',
    38751: 'JAN',
    38753: 'JAN',
    38754: 'JAN',
    38756: 'JAN',
    38758: 'JAN',
    38759: 'MEM',
    38760: 'JAN',
    38761: 'JAN',
    38762: 'MEM',
    38764: 'MEM',
    38765: 'JAN',
    38767: 'MEM',
    38768: 'MEM',
    38769: 'MEM',
    38771: 'MEM',
    38772: 'JAN',
    38773: 'MEM',
    38774: 'MEM',
    38776: 'JAN',
    38778: 'MEM',
    38780: 'JAN',
    38781: 'MEM',
    38782: 'JAN',
    38801: 'MEM',
    38802: 'MEM',
    38803: 'MEM',
    38804: 'MEM',
    38820: 'MEM',
    38821: 'JAN',
    38824: 'MEM',
    38825: 'MEM',
    38826: 'MEM',
    38827: 'MEM',
    38828: 'MEM',
    38829: 'MEM',
    38833: 'MEM',
    38834: 'MEM',
    38835: 'MEM',
    38838: 'MEM',
    38839: 'MEM',
    38841: 'MEM',
    38843: 'MEM',
    38844: 'JAN',
    38846: 'MEM',
    38847: 'MEM',
    38848: 'JAN',
    38849: 'MEM',
    38850: 'MEM',
    38851: 'MEM',
    38852: 'MEM',
    38854: 'MEM',
    38855: 'MEM',
    38856: 'MEM',
    38857: 'MEM',
    38858: 'MEM',
    38859: 'MEM',
    38860: 'MEM',
    38862: 'MEM',
    38863: 'MEM',
    38864: 'MEM',
    38865: 'MEM',
    38866: 'MEM',
    38868: 'MEM',
    38869: 'MEM',
    38870: 'JAN',
    38871: 'MEM',
    38873: 'MEM',
    38874: 'MEM',
    38875: 'MEM',
    38876: 'JAN',
    38877: 'MEM',
    38878: 'MEM',
    38879: 'MEM',
    38880: 'MEM',
    38901: 'MEM',
    38902: 'MEM',
    38912: 'MEM',
    38913: 'MEM',
    38914: 'MEM',
    38915: 'MEM',
    38916: 'MEM',
    38917: 'MEM',
    38920: 'MEM',
    38921: 'MEM',
    38922: 'MEM',
    38923: 'JAN',
    38924: 'JAN',
    38925: 'MEM',
    38926: 'MEM',
    38927: 'MEM',
    38928: 'MEM',
    38929: 'MEM',
    38930: 'MEM',
    38935: 'MEM',
    38940: 'MEM',
    38941: 'JAN',
    38943: 'MEM',
    38944: 'MEM',
    38945: 'MEM',
    38946: 'JAN',
    38947: 'MEM',
    38948: 'MEM',
    38949: 'MEM',
    38950: 'MEM',
    38951: 'MEM',
    38952: 'MEM',
    38953: 'MEM',
    38954: 'JAN',
    38955: 'MEM',
    38957: 'MEM',
    38958: 'MEM',
    38959: 'JAN',
    38960: 'MEM',
    38961: 'MEM',
    38962: 'MEM',
    38963: 'MEM',
    38964: 'MEM',
    38965: 'MEM',
    38966: 'MEM',
    38967: 'MEM',
    39038: 'JAN',
    39039: 'JAN',
    39040: 'JAN',
    39041: 'JAN',
    39042: 'JAN',
    39043: 'JAN',
    39044: 'JAN',
    39045: 'JAN',
    39046: 'JAN',
    39047: 'JAN',
    39051: 'JAN',
    39054: 'JAN',
    39056: 'JAN',
    39057: 'JAN',
    39058: 'JAN',
    39059: 'JAN',
    39060: 'JAN',
    39061: 'JAN',
    39062: 'JAN',
    39063: 'JAN',
    39066: 'JAN',
    39067: 'JAN',
    39069: 'JAN',
    39071: 'JAN',
    39072: 'JAN',
    39073: 'JAN',
    39074: 'JAN',
    39077: 'JAN',
    39078: 'JAN',
    39079: 'JAN',
    39080: 'JAN',
    39081: 'JAN',
    39082: 'JAN',
    39083: 'JAN',
    39086: 'JAN',
    39087: 'JAN',
    39088: 'JAN',
    39090: 'JAN',
    39092: 'JAN',
    39094: 'JAN',
    39095: 'JAN',
    39096: 'JAN',
    39097: 'JAN',
    39098: 'JAN',
    39107: 'JAN',
    39108: 'JAN',
    39109: 'JAN',
    39110: 'JAN',
    39111: 'JAN',
    39112: 'JAN',
    39113: 'JAN',
    39114: 'JAN',
    39115: 'JAN',
    39116: 'JAN',
    39117: 'JAN',
    39119: 'JAN',
    39120: 'BTR',
    39121: 'BTR',
    39122: 'BTR',
    39130: 'JAN',
    39140: 'JAN',
    39144: 'JAN',
    39145: 'JAN',
    39146: 'JAN',
    39148: 'JAN',
    39149: 'JAN',
    39150: 'JAN',
    39151: 'JAN',
    39152: 'JAN',
    39153: 'JAN',
    39154: 'JAN',
    39156: 'JAN',
    39157: 'JAN',
    39158: 'JAN',
    39159: 'JAN',
    39160: 'JAN',
    39161: 'JAN',
    39162: 'JAN',
    39163: 'JAN',
    39165: 'BTR',
    39166: 'JAN',
    39167: 'JAN',
    39168: 'JAN',
    39169: 'JAN',
    39170: 'JAN',
    39171: 'JAN',
    39173: 'JAN',
    39174: 'JAN',
    39175: 'JAN',
    39176: 'JAN',
    39177: 'JAN',
    39179: 'JAN',
    39180: 'JAN',
    39181: 'JAN',
    39182: 'JAN',
    39183: 'JAN',
    39189: 'JAN',
    39190: 'BTR',
    39191: 'JAN',
    39192: 'JAN',
    39193: 'JAN',
    39194: 'JAN',
    39201: 'JAN',
    39202: 'JAN',
    39203: 'JAN',
    39204: 'JAN',
    39205: 'JAN',
    39206: 'JAN',
    39207: 'JAN',
    39208: 'JAN',
    39209: 'JAN',
    39210: 'JAN',
    39211: 'JAN',
    39212: 'JAN',
    39213: 'JAN',
    39215: 'JAN',
    39216: 'JAN',
    39217: 'JAN',
    39218: 'JAN',
    39225: 'JAN',
    39232: 'JAN',
    39235: 'JAN',
    39236: 'JAN',
    39250: 'JAN',
    39269: 'JAN',
    39271: 'JAN',
    39272: 'JAN',
    39282: 'JAN',
    39283: 'JAN',
    39284: 'JAN',
    39286: 'JAN',
    39288: 'JAN',
    39289: 'JAN',
    39296: 'JAN',
    39298: 'JAN',
    39301: 'JAN',
    39302: 'JAN',
    39303: 'JAN',
    39304: 'JAN',
    39305: 'JAN',
    39307: 'JAN',
    39309: 'JAN',
    39320: 'JAN',
    39322: 'JAN',
    39323: 'JAN',
    39324: 'JAN',
    39325: 'JAN',
    39326: 'JAN',
    39327: 'JAN',
    39328: 'JAN',
    39330: 'JAN',
    39332: 'JAN',
    39335: 'JAN',
    39336: 'JAN',
    39337: 'JAN',
    39338: 'JAN',
    39339: 'JAN',
    39341: 'JAN',
    39342: 'JAN',
    39345: 'JAN',
    39346: 'JAN',
    39347: 'JAN',
    39348: 'JAN',
    39350: 'JAN',
    39352: 'JAN',
    39354: 'JAN',
    39355: 'JAN',
    39356: 'JAN',
    39358: 'JAN',
    39359: 'JAN',
    39360: 'JAN',
    39361: 'JAN',
    39362: 'MSY',
    39363: 'JAN',
    39364: 'JAN',
    39365: 'JAN',
    39366: 'JAN',
    39367: 'JAN',
    39401: 'MSY',
    39402: 'MSY',
    39403: 'MSY',
    39404: 'MSY',
    39406: 'MSY',
    39407: 'MSY',
    39421: 'JAN',
    39422: 'JAN',
    39423: 'MSY',
    39425: 'MSY',
    39426: 'MSY',
    39427: 'JAN',
    39428: 'JAN',
    39429: 'MSY',
    39436: 'MSY',
    39437: 'JAN',
    39439: 'JAN',
    39440: 'JAN',
    39441: 'JAN',
    39442: 'JAN',
    39443: 'JAN',
    39451: 'MSY',
    39452: 'MSY',
    39455: 'MSY',
    39456: 'MSY',
    39457: 'MSY',
    39459: 'JAN',
    39460: 'JAN',
    39461: 'MSY',
    39462: 'MSY',
    39463: 'MSY',
    39464: 'JAN',
    39465: 'MSY',
    39466: 'MSY',
    39470: 'MSY',
    39474: 'JAN',
    39475: 'MSY',
    39476: 'MSY',
    39477: 'JAN',
    39478: 'MSY',
    39479: 'JAN',
    39480: 'JAN',
    39481: 'JAN',
    39482: 'MSY',
    39483: 'MSY',
    39501: 'MSY',
    39502: 'MSY',
    39503: 'MSY',
    39505: 'MSY',
    39506: 'MSY',
    39507: 'MSY',
    39520: 'MSY',
    39521: 'MSY',
    39522: 'MSY',
    39525: 'MSY',
    39529: 'MSY',
    39530: 'MSY',
    39531: 'MSY',
    39532: 'MSY',
    39533: 'MSY',
    39534: 'MSY',
    39535: 'MSY',
    39540: 'MSY',
    39552: 'MSY',
    39553: 'MSY',
    39555: 'MSY',
    39556: 'MSY',
    39558: 'MSY',
    39560: 'MSY',
    39561: 'MSY',
    39562: 'MSY',
    39563: 'MSY',
    39564: 'MSY',
    39565: 'MSY',
    39566: 'MSY',
    39567: 'MSY',
    39568: 'MSY',
    39569: 'MSY',
    39571: 'MSY',
    39572: 'MSY',
    39573: 'MSY',
    39574: 'MSY',
    39576: 'MSY',
    39577: 'MSY',
    39581: 'MSY',
    39595: 'MSY',
    39601: 'JAN',
    39602: 'JAN',
    39603: 'JAN',
    39629: 'MSY',
    39630: 'BTR',
    39631: 'BTR',
    39632: 'MSY',
    39633: 'BTR',
    39635: 'MSY',
    39638: 'BTR',
    39641: 'MSY',
    39643: 'MSY',
    39645: 'BTR',
    39647: 'JAN',
    39648: 'MSY',
    39649: 'MSY',
    39652: 'MSY',
    39653: 'BTR',
    39654: 'JAN',
    39656: 'JAN',
    39657: 'MSY',
    39661: 'BTR',
    39662: 'MSY',
    39663: 'JAN',
    39664: 'BTR',
    39665: 'JAN',
    39666: 'MSY',
    39667: 'MSY',
    39668: 'JAN',
    39669: 'BTR',
    39701: 'JAN',
    39702: 'JAN',
    39703: 'JAN',
    39704: 'JAN',
    39705: 'JAN',
    39710: 'JAN',
    39730: 'JAN',
    39735: 'JAN',
    39736: 'JAN',
    39737: 'JAN',
    39739: 'JAN',
    39740: 'JAN',
    39741: 'JAN',
    39743: 'JAN',
    39744: 'MEM',
    39745: 'JAN',
    39746: 'JAN',
    39747: 'JAN',
    39750: 'JAN',
    39751: 'MEM',
    39752: 'JAN',
    39753: 'JAN',
    39754: 'JAN',
    39755: 'JAN',
    39756: 'JAN',
    39759: 'JAN',
    39760: 'JAN',
    39762: 'JAN',
    39766: 'JAN',
    39767: 'JAN',
    39769: 'JAN',
    39771: 'MEM',
    39772: 'JAN',
    39773: 'JAN',
    39776: 'MEM',
    39813: 'DHN',
    39815: 'TLH',
    39817: 'DHN',
    39818: 'DHN',
    39819: 'TLH',
    39823: 'DHN',
    39824: 'DHN',
    39825: 'DHN',
    39826: 'ABY',
    39827: 'ABY',
    39828: 'TLH',
    39829: 'TLH',
    39832: 'DHN',
    39834: 'TLH',
    39836: 'DHN',
    39837: 'DHN',
    39840: 'DHN',
    39841: 'DHN',
    39842: 'ABY',
    39845: 'DHN',
    39846: 'DHN',
    39851: 'DHN',
    39852: 'TLH',
    39854: 'DHN',
    39859: 'DHN',
    39861: 'DHN',
    39862: 'ABY',
    39866: 'ABY',
    39867: 'DHN',
    39870: 'ABY',
    39877: 'ABY',
    39885: 'ABY',
    39886: 'ABY',
    39897: 'TLH',
    39901: 'ATL',
    40003: 'SDF',
    40004: 'SDF',
    40006: 'SDF',
    40007: 'SDF',
    40008: 'SDF',
    40009: 'SDF',
    40010: 'SDF',
    40011: 'SDF',
    40012: 'SDF',
    40013: 'SDF',
    40014: 'SDF',
    40018: 'SDF',
    40019: 'SDF',
    40020: 'SDF',
    40022: 'SDF',
    40023: 'SDF',
    40025: 'SDF',
    40026: 'SDF',
    40027: 'SDF',
    40031: 'SDF',
    40032: 'SDF',
    40033: 'SDF',
    40036: 'SDF',
    40037: 'SDF',
    40040: 'SDF',
    40041: 'SDF',
    40045: 'SDF',
    40046: 'SDF',
    40047: 'SDF',
    40048: 'SDF',
    40049: 'SDF',
    40050: 'SDF',
    40051: 'SDF',
    40052: 'SDF',
    40055: 'SDF',
    40056: 'SDF',
    40057: 'SDF',
    40058: 'SDF',
    40059: 'SDF',
    40060: 'SDF',
    40061: 'SDF',
    40062: 'SDF',
    40063: 'SDF',
    40065: 'SDF',
    40066: 'SDF',
    40067: 'SDF',
    40068: 'SDF',
    40069: 'SDF',
    40070: 'SDF',
    40071: 'SDF',
    40075: 'SDF',
    40076: 'SDF',
    40077: 'SDF',
    40078: 'SDF',
    40104: 'EVV',
    40107: 'SDF',
    40108: 'EVV',
    40109: 'SDF',
    40110: 'SDF',
    40111: 'EVV',
    40115: 'EVV',
    40117: 'EVV',
    40118: 'SDF',
    40119: 'EVV',
    40121: 'EVV',
    40122: 'EVV',
    40129: 'SDF',
    40140: 'EVV',
    40142: 'EVV',
    40143: 'EVV',
    40144: 'EVV',
    40145: 'EVV',
    40146: 'EVV',
    40150: 'SDF',
    40152: 'EVV',
    40153: 'EVV',
    40155: 'EVV',
    40157: 'EVV',
    40159: 'EVV',
    40160: 'EVV',
    40161: 'EVV',
    40162: 'EVV',
    40164: 'EVV',
    40165: 'SDF',
    40166: 'SDF',
    40170: 'EVV',
    40171: 'EVV',
    40175: 'EVV',
    40176: 'EVV',
    40177: 'SDF',
    40178: 'EVV',
    40201: 'SDF',
    40202: 'SDF',
    40203: 'SDF',
    40204: 'SDF',
    40205: 'SDF',
    40206: 'SDF',
    40207: 'SDF',
    40208: 'SDF',
    40209: 'SDF',
    40210: 'SDF',
    40211: 'SDF',
    40212: 'SDF',
    40213: 'SDF',
    40214: 'SDF',
    40215: 'SDF',
    40216: 'SDF',
    40217: 'SDF',
    40218: 'SDF',
    40219: 'SDF',
    40220: 'SDF',
    40221: 'SDF',
    40222: 'SDF',
    40223: 'SDF',
    40224: 'SDF',
    40225: 'SDF',
    40228: 'SDF',
    40229: 'SDF',
    40231: 'SDF',
    40232: 'SDF',
    40233: 'SDF',
    40241: 'SDF',
    40242: 'SDF',
    40243: 'SDF',
    40245: 'SDF',
    40250: 'SDF',
    40251: 'SDF',
    40252: 'SDF',
    40253: 'SDF',
    40255: 'SDF',
    40256: 'SDF',
    40257: 'SDF',
    40258: 'SDF',
    40259: 'SDF',
    40261: 'SDF',
    40266: 'SDF',
    40268: 'SDF',
    40269: 'SDF',
    40270: 'SDF',
    40272: 'SDF',
    40280: 'SDF',
    40281: 'SDF',
    40282: 'SDF',
    40283: 'SDF',
    40285: 'SDF',
    40287: 'SDF',
    40289: 'SDF',
    40290: 'SDF',
    40291: 'SDF',
    40292: 'SDF',
    40293: 'SDF',
    40294: 'SDF',
    40295: 'SDF',
    40296: 'SDF',
    40297: 'SDF',
    40298: 'SDF',
    40299: 'SDF',
    40310: 'SDF',
    40311: 'SDF',
    40312: 'SDF',
    40313: 'HTS',
    40316: 'HTS',
    40317: 'HTS',
    40319: 'HTS',
    40320: 'HTS',
    40322: 'HTS',
    40324: 'SDF',
    40328: 'SDF',
    40329: 'SDF',
    40330: 'SDF',
    40334: 'SDF',
    40336: 'SDF',
    40337: 'SDF',
    40339: 'SDF',
    40340: 'SDF',
    40342: 'SDF',
    40346: 'SDF',
    40347: 'SDF',
    40348: 'SDF',
    40350: 'SDF',
    40351: 'HTS',
    40353: 'SDF',
    40355: 'SDF',
    40356: 'SDF',
    40357: 'SDF',
    40358: 'SDF',
    40359: 'SDF',
    40360: 'SDF',
    40361: 'SDF',
    40362: 'SDF',
    40363: 'SDF',
    40366: 'SDF',
    40370: 'SDF',
    40371: 'HTS',
    40372: 'SDF',
    40374: 'SDF',
    40376: 'SDF',
    40379: 'SDF',
    40380: 'SDF',
    40383: 'SDF',
    40384: 'SDF',
    40385: 'SDF',
    40386: 'SDF',
    40387: 'HTS',
    40390: 'SDF',
    40391: 'SDF',
    40392: 'SDF',
    40402: 'LEX',
    40403: 'SDF',
    40404: 'SDF',
    40405: 'SDF',
    40409: 'LEX',
    40410: 'SDF',
    40419: 'SDF',
    40421: 'SDF',
    40422: 'SDF',
    40423: 'SDF',
    40434: 'LEX',
    40437: 'LEX',
    40440: 'SDF',
    40442: 'LEX',
    40444: 'SDF',
    40445: 'LEX',
    40446: 'LEX',
    40447: 'SDF',
    40448: 'SDF',
    40452: 'SDF',
    40456: 'LEX',
    40460: 'LEX',
    40461: 'SDF',
    40464: 'SDF',
    40467: 'SDF',
    40468: 'SDF',
    40472: 'SDF',
    40473: 'LEX',
    40475: 'SDF',
    40476: 'SDF',
    40481: 'SDF',
    40484: 'SDF',
    40486: 'LEX',
    40488: 'SDF',
    40489: 'LEX',
    40492: 'SDF',
    40495: 'SDF',
    40502: 'SDF',
    40503: 'SDF',
    40504: 'SDF',
    40505: 'SDF',
    40506: 'SDF',
    40507: 'SDF',
    40508: 'SDF',
    40509: 'SDF',
    40510: 'SDF',
    40511: 'SDF',
    40512: 'SDF',
    40513: 'SDF',
    40514: 'SDF',
    40515: 'SDF',
    40516: 'SDF',
    40517: 'SDF',
    40522: 'SDF',
    40523: 'SDF',
    40524: 'SDF',
    40526: 'SDF',
    40533: 'SDF',
    40536: 'SDF',
    40544: 'SDF',
    40546: 'SDF',
    40550: 'SDF',
    40555: 'SDF',
    40574: 'SDF',
    40575: 'SDF',
    40576: 'SDF',
    40577: 'SDF',
    40578: 'SDF',
    40579: 'SDF',
    40580: 'SDF',
    40581: 'SDF',
    40582: 'SDF',
    40583: 'SDF',
    40584: 'SDF',
    40585: 'SDF',
    40586: 'SDF',
    40587: 'SDF',
    40588: 'SDF',
    40589: 'SDF',
    40590: 'SDF',
    40591: 'SDF',
    40592: 'SDF',
    40593: 'SDF',
    40594: 'SDF',
    40595: 'SDF',
    40596: 'SDF',
    40598: 'SDF',
    40601: 'SDF',
    40602: 'SDF',
    40603: 'SDF',
    40604: 'SDF',
    40618: 'SDF',
    40619: 'SDF',
    40620: 'SDF',
    40621: 'SDF',
    40622: 'SDF',
    40701: 'LEX',
    40702: 'LEX',
    40724: 'LEX',
    40729: 'LEX',
    40730: 'LEX',
    40734: 'LEX',
    40737: 'LEX',
    40740: 'LEX',
    40741: 'LEX',
    40742: 'LEX',
    40743: 'LEX',
    40744: 'LEX',
    40745: 'LEX',
    40750: 'LEX',
    40751: 'LEX',
    40754: 'LEX',
    40755: 'LEX',
    40759: 'LEX',
    40763: 'LEX',
    40769: 'LEX',
    40771: 'LEX',
    40801: 'LEX',
    40803: 'LEX',
    40806: 'LEX',
    40807: 'TYS',
    40808: 'LEX',
    40810: 'LEX',
    40813: 'LEX',
    40815: 'LEX',
    40816: 'LEX',
    40818: 'LEX',
    40819: 'LEX',
    40820: 'LEX',
    40823: 'LEX',
    40824: 'LEX',
    40826: 'TYS',
    40827: 'LEX',
    40828: 'LEX',
    40829: 'LEX',
    40830: 'LEX',
    40831: 'LEX',
    40840: 'LEX',
    40843: 'TYS',
    40844: 'LEX',
    40845: 'LEX',
    40847: 'LEX',
    40849: 'LEX',
    40854: 'LEX',
    40855: 'LEX',
    40856: 'LEX',
    40858: 'LEX',
    40862: 'LEX',
    40863: 'LEX',
    40865: 'LEX',
    40868: 'LEX',
    40870: 'LEX',
    40873: 'LEX',
    40874: 'LEX',
    40902: 'LEX',
    40903: 'LEX',
    40906: 'LEX',
    40913: 'LEX',
    40914: 'LEX',
    40915: 'LEX',
    40921: 'LEX',
    40923: 'LEX',
    40927: 'TYS',
    40930: 'LEX',
    40931: 'LEX',
    40932: 'LEX',
    40935: 'LEX',
    40939: 'LEX',
    40940: 'LEX',
    40941: 'LEX',
    40943: 'LEX',
    40944: 'LEX',
    40946: 'LEX',
    40949: 'LEX',
    40951: 'LEX',
    40953: 'LEX',
    40955: 'LEX',
    40958: 'LEX',
    40962: 'LEX',
    40964: 'LEX',
    40965: 'LEX',
    40972: 'LEX',
    40977: 'LEX',
    40979: 'LEX',
    40981: 'LEX',
    40982: 'LEX',
    40983: 'LEX',
    40988: 'LEX',
    40995: 'LEX',
    40997: 'LEX',
    40999: 'LEX',
    41001: 'CVG',
    41002: 'CVG',
    41003: 'SDF',
    41004: 'CVG',
    41005: 'SDF',
    41006: 'CVG',
    41007: 'CVG',
    41008: 'SDF',
    41010: 'SDF',
    41011: 'CVG',
    41012: 'CVG',
    41014: 'CVG',
    41015: 'CVG',
    41016: 'CVG',
    41017: 'CVG',
    41018: 'CVG',
    41019: 'CVG',
    41021: 'CVG',
    41022: 'CVG',
    41025: 'CVG',
    41030: 'CVG',
    41031: 'SDF',
    41033: 'CVG',
    41034: 'CVG',
    41035: 'CVG',
    41037: 'SDF',
    41039: 'SDF',
    41040: 'CVG',
    41041: 'SDF',
    41042: 'CVG',
    41043: 'CVG',
    41044: 'CVG',
    41045: 'SDF',
    41046: 'SDF',
    41048: 'CVG',
    41049: 'SDF',
    41051: 'CVG',
    41052: 'SDF',
    41053: 'CVG',
    41054: 'CVG',
    41055: 'SDF',
    41056: 'CVG',
    41059: 'CVG',
    41061: 'CVG',
    41062: 'CVG',
    41063: 'CVG',
    41064: 'SDF',
    41065: 'HTS',
    41071: 'CVG',
    41072: 'CVG',
    41073: 'CVG',
    41074: 'CVG',
    41075: 'CVG',
    41076: 'CVG',
    41080: 'SDF',
    41081: 'HTS',
    41083: 'SDF',
    41085: 'CVG',
    41086: 'SDF',
    41091: 'SDF',
    41092: 'CVG',
    41093: 'HTS',
    41094: 'CVG',
    41095: 'SDF',
    41096: 'CVG',
    41097: 'CVG',
    41098: 'SDF',
    41099: 'CVG',
    41101: 'HTS',
    41102: 'HTS',
    41105: 'HTS',
    41114: 'HTS',
    41121: 'HTS',
    41124: 'HTS',
    41127: 'HTS',
    41128: 'HTS',
    41129: 'HTS',
    41132: 'HTS',
    41135: 'HTS',
    41137: 'HTS',
    41139: 'HTS',
    41141: 'HTS',
    41142: 'HTS',
    41143: 'HTS',
    41144: 'HTS',
    41146: 'HTS',
    41149: 'HTS',
    41150: 'HTS',
    41156: 'HTS',
    41159: 'HTS',
    41160: 'HTS',
    41164: 'HTS',
    41166: 'CVG',
    41168: 'HTS',
    41169: 'HTS',
    41170: 'HTS',
    41171: 'HTS',
    41173: 'HTS',
    41174: 'CVG',
    41175: 'CVG',
    41179: 'HTS',
    41180: 'HTS',
    41181: 'HTS',
    41183: 'HTS',
    41189: 'HTS',
    41201: 'HTS',
    41203: 'HTS',
    41204: 'HTS',
    41214: 'HTS',
    41215: 'HTS',
    41216: 'HTS',
    41219: 'HTS',
    41222: 'HTS',
    41224: 'HTS',
    41226: 'HTS',
    41228: 'HTS',
    41230: 'HTS',
    41231: 'HTS',
    41232: 'HTS',
    41234: 'HTS',
    41238: 'HTS',
    41240: 'HTS',
    41250: 'HTS',
    41254: 'HTS',
    41255: 'HTS',
    41256: 'HTS',
    41257: 'HTS',
    41260: 'HTS',
    41262: 'HTS',
    41263: 'HTS',
    41264: 'HTS',
    41265: 'HTS',
    41267: 'HTS',
    41268: 'HTS',
    41271: 'HTS',
    41274: 'HTS',
    41301: 'HTS',
    41307: 'HTS',
    41310: 'HTS',
    41311: 'SDF',
    41313: 'HTS',
    41314: 'HTS',
    41317: 'HTS',
    41332: 'HTS',
    41333: 'SDF',
    41338: 'LEX',
    41339: 'HTS',
    41342: 'HTS',
    41344: 'HTS',
    41347: 'HTS',
    41348: 'HTS',
    41351: 'LEX',
    41352: 'HTS',
    41360: 'HTS',
    41362: 'HTS',
    41364: 'LEX',
    41365: 'SDF',
    41366: 'HTS',
    41367: 'LEX',
    41368: 'LEX',
    41377: 'LEX',
    41385: 'HTS',
    41386: 'SDF',
    41390: 'SDF',
    41397: 'SDF',
    41408: 'HTS',
    41410: 'HTS',
    41413: 'HTS',
    41419: 'HTS',
    41421: 'HTS',
    41422: 'HTS',
    41425: 'HTS',
    41426: 'HTS',
    41433: 'HTS',
    41444: 'HTS',
    41451: 'HTS',
    41459: 'HTS',
    41464: 'HTS',
    41465: 'HTS',
    41472: 'HTS',
    41477: 'HTS',
    41501: 'HTS',
    41502: 'HTS',
    41503: 'HTS',
    41512: 'HTS',
    41513: 'HTS',
    41514: 'HTS',
    41517: 'HTS',
    41519: 'HTS',
    41520: 'HTS',
    41522: 'HTS',
    41524: 'HTS',
    41526: 'HTS',
    41527: 'HTS',
    41528: 'HTS',
    41531: 'HTS',
    41534: 'HTS',
    41535: 'HTS',
    41537: 'HTS',
    41538: 'HTS',
    41539: 'HTS',
    41540: 'HTS',
    41542: 'HTS',
    41543: 'HTS',
    41544: 'HTS',
    41546: 'HTS',
    41547: 'HTS',
    41548: 'HTS',
    41549: 'HTS',
    41553: 'HTS',
    41554: 'HTS',
    41555: 'HTS',
    41557: 'HTS',
    41558: 'HTS',
    41559: 'HTS',
    41560: 'HTS',
    41561: 'HTS',
    41562: 'HTS',
    41563: 'HTS',
    41564: 'HTS',
    41566: 'HTS',
    41567: 'HTS',
    41568: 'HTS',
    41569: 'HTS',
    41571: 'HTS',
    41572: 'HTS',
    41601: 'HTS',
    41602: 'HTS',
    41603: 'HTS',
    41604: 'HTS',
    41605: 'HTS',
    41606: 'HTS',
    41607: 'HTS',
    41612: 'HTS',
    41615: 'HTS',
    41616: 'HTS',
    41619: 'HTS',
    41621: 'HTS',
    41622: 'HTS',
    41630: 'HTS',
    41631: 'HTS',
    41632: 'HTS',
    41635: 'HTS',
    41636: 'HTS',
    41640: 'HTS',
    41642: 'HTS',
    41643: 'HTS',
    41645: 'HTS',
    41647: 'HTS',
    41649: 'HTS',
    41650: 'HTS',
    41651: 'HTS',
    41653: 'HTS',
    41655: 'HTS',
    41659: 'HTS',
    41660: 'HTS',
    41663: 'HTS',
    41666: 'HTS',
    41667: 'HTS',
    41669: 'HTS',
    41701: 'LEX',
    41702: 'LEX',
    41712: 'LEX',
    41713: 'LEX',
    41714: 'LEX',
    41719: 'LEX',
    41721: 'LEX',
    41722: 'LEX',
    41723: 'LEX',
    41725: 'LEX',
    41727: 'LEX',
    41729: 'LEX',
    41730: 'LEX',
    41731: 'LEX',
    41735: 'LEX',
    41736: 'LEX',
    41739: 'LEX',
    41740: 'LEX',
    41743: 'LEX',
    41745: 'LEX',
    41746: 'LEX',
    41747: 'LEX',
    41749: 'LEX',
    41751: 'LEX',
    41754: 'LEX',
    41759: 'LEX',
    41760: 'LEX',
    41762: 'LEX',
    41763: 'LEX',
    41764: 'LEX',
    41766: 'LEX',
    41772: 'LEX',
    41773: 'LEX',
    41774: 'LEX',
    41775: 'LEX',
    41776: 'LEX',
    41777: 'LEX',
    41778: 'LEX',
    41804: 'LEX',
    41810: 'HTS',
    41812: 'HTS',
    41815: 'HTS',
    41817: 'LEX',
    41819: 'LEX',
    41821: 'LEX',
    41822: 'LEX',
    41824: 'LEX',
    41825: 'HTS',
    41826: 'LEX',
    41828: 'HTS',
    41831: 'LEX',
    41832: 'LEX',
    41833: 'LEX',
    41834: 'LEX',
    41835: 'HTS',
    41836: 'LEX',
    41837: 'HTS',
    41838: 'HTS',
    41839: 'HTS',
    41840: 'HTS',
    41843: 'LEX',
    41844: 'LEX',
    41845: 'LEX',
    41847: 'LEX',
    41848: 'LEX',
    41849: 'HTS',
    41855: 'HTS',
    41858: 'LEX',
    41859: 'LEX',
    41861: 'LEX',
    41862: 'HTS',
    41901: 'BNA',
    41902: 'BNA',
    41903: 'BNA',
    41904: 'BNA',
    41905: 'BNA',
    41906: 'BNA',
    42001: 'BNA',
    42002: 'BNA',
    42003: 'BNA',
    42020: 'BNA',
    42021: 'BNA',
    42022: 'BNA',
    42023: 'BNA',
    42024: 'BNA',
    42025: 'BNA',
    42027: 'BNA',
    42028: 'BNA',
    42029: 'BNA',
    42031: 'BNA',
    42032: 'BNA',
    42033: 'BNA',
    42035: 'BNA',
    42036: 'BNA',
    42037: 'BNA',
    42038: 'BNA',
    42039: 'BNA',
    42040: 'BNA',
    42041: 'BNA',
    42044: 'BNA',
    42045: 'BNA',
    42046: 'BNA',
    42047: 'BNA',
    42048: 'BNA',
    42049: 'BNA',
    42050: 'BNA',
    42051: 'BNA',
    42053: 'BNA',
    42054: 'BNA',
    42055: 'BNA',
    42056: 'BNA',
    42058: 'BNA',
    42060: 'BNA',
    42061: 'BNA',
    42063: 'BNA',
    42064: 'BNA',
    42066: 'BNA',
    42069: 'BNA',
    42070: 'BNA',
    42071: 'BNA',
    42076: 'BNA',
    42078: 'BNA',
    42079: 'BNA',
    42081: 'BNA',
    42082: 'BNA',
    42083: 'BNA',
    42084: 'BNA',
    42085: 'BNA',
    42086: 'BNA',
    42087: 'BNA',
    42088: 'BNA',
    42101: 'BNA',
    42102: 'BNA',
    42103: 'BNA',
    42104: 'BNA',
    42120: 'BNA',
    42122: 'BNA',
    42123: 'BNA',
    42124: 'LEX',
    42127: 'BNA',
    42128: 'BNA',
    42129: 'LEX',
    42130: 'LEX',
    42131: 'BNA',
    42133: 'BNA',
    42134: 'BNA',
    42135: 'BNA',
    42140: 'LEX',
    42141: 'BNA',
    42142: 'BNA',
    42150: 'BNA',
    42151: 'LEX',
    42152: 'LEX',
    42153: 'BNA',
    42154: 'LEX',
    42156: 'BNA',
    42157: 'LEX',
    42159: 'BNA',
    42160: 'BNA',
    42163: 'BNA',
    42164: 'BNA',
    42166: 'LEX',
    42167: 'LEX',
    42170: 'BNA',
    42171: 'BNA',
    42201: 'BNA',
    42202: 'BNA',
    42203: 'BNA',
    42204: 'BNA',
    42206: 'BNA',
    42207: 'BNA',
    42209: 'BNA',
    42210: 'BNA',
    42211: 'BNA',
    42214: 'LEX',
    42215: 'BNA',
    42216: 'BNA',
    42217: 'BNA',
    42219: 'BNA',
    42220: 'BNA',
    42221: 'BNA',
    42223: 'BNA',
    42232: 'BNA',
    42234: 'BNA',
    42235: 'BNA',
    42236: 'BNA',
    42240: 'BNA',
    42241: 'BNA',
    42251: 'BNA',
    42252: 'BNA',
    42254: 'BNA',
    42256: 'BNA',
    42257: 'BNA',
    42259: 'BNA',
    42261: 'BNA',
    42262: 'BNA',
    42265: 'BNA',
    42266: 'BNA',
    42267: 'BNA',
    42270: 'BNA',
    42273: 'BNA',
    42274: 'BNA',
    42275: 'BNA',
    42276: 'BNA',
    42280: 'BNA',
    42283: 'BNA',
    42285: 'BNA',
    42286: 'BNA',
    42287: 'BNA',
    42288: 'BNA',
    42301: 'EVV',
    42302: 'EVV',
    42303: 'EVV',
    42304: 'EVV',
    42320: 'BNA',
    42321: 'BNA',
    42322: 'EVV',
    42323: 'BNA',
    42324: 'BNA',
    42325: 'BNA',
    42326: 'BNA',
    42327: 'EVV',
    42328: 'BNA',
    42330: 'BNA',
    42332: 'BNA',
    42333: 'BNA',
    42334: 'EVV',
    42337: 'BNA',
    42338: 'EVV',
    42339: 'BNA',
    42343: 'EVV',
    42344: 'BNA',
    42345: 'BNA',
    42347: 'EVV',
    42348: 'EVV',
    42349: 'BNA',
    42350: 'BNA',
    42351: 'EVV',
    42352: 'EVV',
    42354: 'BNA',
    42355: 'EVV',
    42356: 'EVV',
    42361: 'EVV',
    42364: 'EVV',
    42365: 'EVV',
    42366: 'EVV',
    42367: 'BNA',
    42368: 'EVV',
    42369: 'BNA',
    42370: 'BNA',
    42371: 'EVV',
    42372: 'BNA',
    42374: 'BNA',
    42375: 'EVV',
    42376: 'EVV',
    42377: 'EVV',
    42378: 'EVV',
    42402: 'EVV',
    42403: 'EVV',
    42404: 'EVV',
    42406: 'EVV',
    42408: 'BNA',
    42409: 'EVV',
    42410: 'BNA',
    42411: 'BNA',
    42413: 'BNA',
    42419: 'EVV',
    42420: 'EVV',
    42431: 'BNA',
    42436: 'BNA',
    42437: 'EVV',
    42440: 'BNA',
    42441: 'BNA',
    42442: 'BNA',
    42444: 'EVV',
    42445: 'BNA',
    42450: 'BNA',
    42451: 'EVV',
    42452: 'EVV',
    42453: 'BNA',
    42455: 'EVV',
    42456: 'EVV',
    42457: 'EVV',
    42458: 'EVV',
    42459: 'EVV',
    42460: 'EVV',
    42461: 'EVV',
    42462: 'EVV',
    42463: 'EVV',
    42464: 'BNA',
    42501: 'LEX',
    42502: 'LEX',
    42503: 'LEX',
    42516: 'LEX',
    42518: 'LEX',
    42519: 'LEX',
    42528: 'LEX',
    42533: 'LEX',
    42539: 'LEX',
    42541: 'LEX',
    42544: 'LEX',
    42553: 'LEX',
    42558: 'LEX',
    42564: 'LEX',
    42565: 'LEX',
    42566: 'LEX',
    42567: 'LEX',
    42602: 'LEX',
    42603: 'LEX',
    42629: 'LEX',
    42631: 'LEX',
    42632: 'LEX',
    42633: 'LEX',
    42634: 'LEX',
    42635: 'LEX',
    42638: 'LEX',
    42642: 'LEX',
    42647: 'LEX',
    42649: 'LEX',
    42653: 'LEX',
    42701: 'SDF',
    42702: 'SDF',
    42711: 'SDF',
    42712: 'EVV',
    42713: 'LEX',
    42715: 'LEX',
    42716: 'SDF',
    42717: 'LEX',
    42718: 'LEX',
    42719: 'LEX',
    42720: 'LEX',
    42721: 'BNA',
    42722: 'LEX',
    42724: 'EVV',
    42726: 'BNA',
    42728: 'LEX',
    42729: 'BNA',
    42731: 'LEX',
    42732: 'EVV',
    42733: 'LEX',
    42735: 'LEX',
    42740: 'EVV',
    42741: 'LEX',
    42742: 'LEX',
    42743: 'LEX',
    42746: 'LEX',
    42748: 'SDF',
    42749: 'BNA',
    42753: 'LEX',
    42754: 'EVV',
    42755: 'EVV',
    42757: 'LEX',
    42758: 'LEX',
    42759: 'LEX',
    42761: 'LEX',
    42762: 'EVV',
    42764: 'LEX',
    42765: 'BNA',
    42776: 'EVV',
    42782: 'LEX',
    42783: 'LEX',
    42784: 'EVV',
    42786: 'EVV',
    42788: 'EVV',
    43001: 'CMH',
    43002: 'CMH',
    43003: 'CMH',
    43004: 'CMH',
    43005: 'CLE',
    43006: 'CLE',
    43007: 'CMH',
    43008: 'CMH',
    43009: 'CMH',
    43010: 'CMH',
    43011: 'CMH',
    43013: 'CMH',
    43014: 'CLE',
    43015: 'CMH',
    43016: 'CMH',
    43017: 'CMH',
    43018: 'CMH',
    43019: 'CMH',
    43021: 'CMH',
    43022: 'CMH',
    43023: 'CMH',
    43025: 'CMH',
    43026: 'CMH',
    43027: 'CMH',
    43028: 'CLE',
    43029: 'CMH',
    43030: 'CMH',
    43031: 'CMH',
    43032: 'CMH',
    43033: 'CMH',
    43035: 'CMH',
    43036: 'CMH',
    43037: 'CMH',
    43040: 'CMH',
    43041: 'CMH',
    43044: 'CMH',
    43045: 'CMH',
    43046: 'CMH',
    43047: 'CMH',
    43048: 'CMH',
    43050: 'CMH',
    43054: 'CMH',
    43055: 'CMH',
    43056: 'CMH',
    43058: 'CMH',
    43060: 'CMH',
    43061: 'CMH',
    43062: 'CMH',
    43064: 'CMH',
    43065: 'CMH',
    43066: 'CMH',
    43067: 'CMH',
    43068: 'CMH',
    43069: 'CMH',
    43070: 'CMH',
    43071: 'CMH',
    43072: 'CMH',
    43073: 'CMH',
    43074: 'CMH',
    43076: 'CMH',
    43077: 'CMH',
    43078: 'CMH',
    43080: 'CMH',
    43081: 'CMH',
    43082: 'CMH',
    43083: 'CMH',
    43084: 'CMH',
    43085: 'CMH',
    43086: 'CMH',
    43093: 'CMH',
    43098: 'CMH',
    43101: 'CRW',
    43102: 'CMH',
    43103: 'CMH',
    43105: 'CMH',
    43106: 'CMH',
    43107: 'CMH',
    43109: 'CMH',
    43110: 'CMH',
    43111: 'CRW',
    43112: 'CMH',
    43113: 'CMH',
    43115: 'CVG',
    43116: 'CMH',
    43117: 'CMH',
    43119: 'CMH',
    43123: 'CMH',
    43125: 'CMH',
    43126: 'CMH',
    43127: 'CRW',
    43128: 'CMH',
    43130: 'CMH',
    43135: 'CRW',
    43136: 'CMH',
    43137: 'CMH',
    43138: 'CRW',
    43140: 'CMH',
    43142: 'CMH',
    43143: 'CMH',
    43144: 'CRW',
    43145: 'CMH',
    43146: 'CMH',
    43147: 'CMH',
    43148: 'CMH',
    43149: 'CRW',
    43150: 'CMH',
    43151: 'CMH',
    43152: 'CRW',
    43153: 'CMH',
    43154: 'CMH',
    43155: 'CMH',
    43156: 'CRW',
    43157: 'CMH',
    43158: 'CRW',
    43160: 'CMH',
    43162: 'CMH',
    43163: 'CMH',
    43164: 'CMH',
    43194: 'CMH',
    43195: 'CMH',
    43196: 'CMH',
    43198: 'CMH',
    43199: 'CMH',
    43201: 'CMH',
    43202: 'CMH',
    43203: 'CMH',
    43204: 'CMH',
    43205: 'CMH',
    43206: 'CMH',
    43207: 'CMH',
    43209: 'CMH',
    43210: 'CMH',
    43211: 'CMH',
    43212: 'CMH',
    43213: 'CMH',
    43214: 'CMH',
    43215: 'CMH',
    43216: 'CMH',
    43217: 'CMH',
    43218: 'CMH',
    43219: 'CMH',
    43220: 'CMH',
    43221: 'CMH',
    43222: 'CMH',
    43223: 'CMH',
    43224: 'CMH',
    43226: 'CMH',
    43227: 'CMH',
    43228: 'CMH',
    43229: 'CMH',
    43230: 'CMH',
    43231: 'CMH',
    43232: 'CMH',
    43234: 'CMH',
    43235: 'CMH',
    43236: 'CMH',
    43240: 'CMH',
    43251: 'CMH',
    43252: 'CMH',
    43254: 'CMH',
    43256: 'CMH',
    43257: 'CMH',
    43259: 'CMH',
    43260: 'CMH',
    43261: 'CMH',
    43265: 'CMH',
    43266: 'CMH',
    43267: 'CMH',
    43268: 'CMH',
    43270: 'CMH',
    43271: 'CMH',
    43272: 'CMH',
    43279: 'CMH',
    43281: 'CMH',
    43282: 'CMH',
    43287: 'CMH',
    43291: 'CMH',
    43297: 'CMH',
    43299: 'CMH',
    43301: 'CMH',
    43302: 'CMH',
    43306: 'CMH',
    43307: 'CMH',
    43310: 'CMH',
    43311: 'CMH',
    43314: 'CMH',
    43315: 'CMH',
    43316: 'CMH',
    43317: 'CMH',
    43318: 'CMH',
    43319: 'CMH',
    43320: 'CMH',
    43321: 'CMH',
    43322: 'CMH',
    43323: 'CMH',
    43324: 'CMH',
    43325: 'CMH',
    43326: 'CMH',
    43330: 'CMH',
    43331: 'CMH',
    43332: 'CMH',
    43333: 'CMH',
    43334: 'CMH',
    43335: 'CMH',
    43336: 'CMH',
    43337: 'CMH',
    43338: 'CMH',
    43340: 'CMH',
    43341: 'CMH',
    43342: 'CMH',
    43343: 'CMH',
    43344: 'CMH',
    43345: 'CMH',
    43346: 'CMH',
    43347: 'CMH',
    43348: 'CMH',
    43349: 'CMH',
    43350: 'CMH',
    43351: 'CMH',
    43356: 'CMH',
    43357: 'CMH',
    43358: 'CMH',
    43359: 'CMH',
    43360: 'CMH',
    43402: 'CMH',
    43403: 'CMH',
    43406: 'CMH',
    43407: 'CMH',
    43408: 'CMH',
    43410: 'CLE',
    43412: 'CMH',
    43413: 'CMH',
    43414: 'CMH',
    43416: 'CMH',
    43420: 'CLE',
    43430: 'CMH',
    43431: 'CMH',
    43432: 'CMH',
    43433: 'CLE',
    43434: 'CMH',
    43435: 'CMH',
    43436: 'CMH',
    43437: 'CMH',
    43438: 'CLE',
    43439: 'CLE',
    43440: 'CLE',
    43441: 'CMH',
    43442: 'CMH',
    43443: 'CMH',
    43445: 'CMH',
    43446: 'CLE',
    43447: 'CMH',
    43449: 'CMH',
    43450: 'CMH',
    43451: 'CMH',
    43452: 'CLE',
    43456: 'CLE',
    43457: 'CMH',
    43458: 'CMH',
    43460: 'CMH',
    43462: 'CMH',
    43463: 'CMH',
    43464: 'CLE',
    43465: 'CMH',
    43466: 'CMH',
    43467: 'CMH',
    43468: 'CMH',
    43469: 'CMH',
    43501: 'SBN',
    43502: 'SBN',
    43504: 'SBN',
    43505: 'SBN',
    43506: 'SBN',
    43510: 'SBN',
    43511: 'CMH',
    43512: 'SBN',
    43515: 'SBN',
    43516: 'CMH',
    43517: 'SBN',
    43518: 'SBN',
    43519: 'SBN',
    43520: 'SBN',
    43521: 'SBN',
    43522: 'CMH',
    43523: 'CMH',
    43524: 'CMH',
    43525: 'CMH',
    43526: 'SBN',
    43527: 'SBN',
    43528: 'SBN',
    43529: 'CMH',
    43530: 'SBN',
    43531: 'SBN',
    43532: 'CMH',
    43533: 'SBN',
    43534: 'CMH',
    43535: 'CMH',
    43536: 'SBN',
    43537: 'CMH',
    43540: 'SBN',
    43541: 'CMH',
    43542: 'CMH',
    43543: 'SBN',
    43545: 'CMH',
    43547: 'CMH',
    43548: 'CMH',
    43549: 'SBN',
    43550: 'CMH',
    43551: 'CMH',
    43552: 'CMH',
    43553: 'CMH',
    43554: 'SBN',
    43555: 'CMH',
    43556: 'SBN',
    43557: 'SBN',
    43558: 'SBN',
    43560: 'SBN',
    43565: 'CMH',
    43566: 'CMH',
    43567: 'SBN',
    43569: 'CMH',
    43570: 'SBN',
    43571: 'CMH',
    43601: 'DTW',
    43602: 'DTW',
    43603: 'DTW',
    43604: 'DTW',
    43605: 'DTW',
    43606: 'DTW',
    43607: 'DTW',
    43608: 'DTW',
    43609: 'DTW',
    43610: 'DTW',
    43611: 'DTW',
    43612: 'DTW',
    43613: 'DTW',
    43614: 'DTW',
    43615: 'DTW',
    43616: 'DTW',
    43617: 'DTW',
    43618: 'DTW',
    43619: 'DTW',
    43620: 'DTW',
    43623: 'DTW',
    43624: 'DTW',
    43635: 'DTW',
    43652: 'DTW',
    43654: 'DTW',
    43656: 'DTW',
    43657: 'DTW',
    43659: 'DTW',
    43660: 'DTW',
    43661: 'DTW',
    43666: 'DTW',
    43667: 'DTW',
    43681: 'DTW',
    43682: 'DTW',
    43697: 'DTW',
    43699: 'DTW',
    43701: 'CRW',
    43702: 'CRW',
    43711: 'CRW',
    43713: 'CRW',
    43716: 'CRW',
    43717: 'CRW',
    43718: 'CRW',
    43719: 'CRW',
    43720: 'CRW',
    43721: 'CRW',
    43722: 'CRW',
    43723: 'CRW',
    43724: 'CRW',
    43725: 'CRW',
    43727: 'CRW',
    43728: 'CRW',
    43730: 'CRW',
    43731: 'CRW',
    43732: 'CRW',
    43733: 'CRW',
    43734: 'CRW',
    43735: 'CRW',
    43736: 'CRW',
    43738: 'CRW',
    43739: 'CMH',
    43740: 'CRW',
    43746: 'CRW',
    43747: 'CRW',
    43748: 'CMH',
    43749: 'CRW',
    43750: 'CRW',
    43752: 'CRW',
    43754: 'CRW',
    43755: 'CRW',
    43756: 'CRW',
    43757: 'CRW',
    43758: 'CRW',
    43759: 'CRW',
    43760: 'CRW',
    43761: 'CRW',
    43762: 'CRW',
    43764: 'CRW',
    43766: 'CRW',
    43767: 'CRW',
    43768: 'CRW',
    43771: 'CRW',
    43772: 'CRW',
    43773: 'CRW',
    43777: 'CRW',
    43778: 'CRW',
    43779: 'CRW',
    43780: 'CRW',
    43782: 'CRW',
    43783: 'CMH',
    43786: 'CRW',
    43787: 'CRW',
    43788: 'CRW',
    43789: 'CRW',
    43791: 'CRW',
    43793: 'CRW',
    43802: 'CRW',
    43803: 'CLE',
    43804: 'CLE',
    43805: 'CLE',
    43811: 'CRW',
    43812: 'CLE',
    43821: 'CRW',
    43822: 'CRW',
    43824: 'CLE',
    43828: 'CLE',
    43830: 'CRW',
    43832: 'CLE',
    43836: 'CRW',
    43837: 'CLE',
    43840: 'CLE',
    43842: 'CRW',
    43843: 'CLE',
    43844: 'CLE',
    43845: 'CLE',
    43901: 'CRW',
    43902: 'CRW',
    43903: 'CLE',
    43905: 'CRW',
    43906: 'CRW',
    43907: 'CLE',
    43908: 'CLE',
    43909: 'CRW',
    43910: 'CLE',
    43912: 'CRW',
    43913: 'CLE',
    43914: 'CRW',
    43915: 'CRW',
    43916: 'CRW',
    43917: 'CRW',
    43920: 'CLE',
    43925: 'CLE',
    43926: 'CLE',
    43927: 'CRW',
    43928: 'CRW',
    43930: 'CLE',
    43931: 'CRW',
    43932: 'CLE',
    43933: 'CRW',
    43934: 'CRW',
    43935: 'CRW',
    43937: 'CRW',
    43938: 'CLE',
    43939: 'CRW',
    43940: 'CRW',
    43941: 'CLE',
    43942: 'CRW',
    43943: 'CLE',
    43944: 'CLE',
    43945: 'CLE',
    43946: 'CRW',
    43947: 'CRW',
    43948: 'CLE',
    43950: 'CRW',
    43951: 'CRW',
    43952: 'CLE',
    43953: 'CLE',
    43961: 'CLE',
    43962: 'CLE',
    43963: 'CRW',
    43964: 'CLE',
    43966: 'CLE',
    43967: 'CRW',
    43968: 'CLE',
    43970: 'CLE',
    43971: 'CRW',
    43972: 'CRW',
    43973: 'CRW',
    43974: 'CLE',
    43976: 'CLE',
    43977: 'CRW',
    43981: 'CLE',
    43983: 'CRW',
    43984: 'CLE',
    43985: 'CRW',
    43986: 'CLE',
    43988: 'CLE',
    44001: 'CLE',
    44003: 'CLE',
    44004: 'CLE',
    44005: 'CLE',
    44010: 'CLE',
    44011: 'CLE',
    44012: 'CLE',
    44017: 'CLE',
    44021: 'CLE',
    44022: 'CLE',
    44023: 'CLE',
    44024: 'CLE',
    44026: 'CLE',
    44028: 'CLE',
    44030: 'CLE',
    44032: 'CLE',
    44033: 'CLE',
    44035: 'CLE',
    44036: 'CLE',
    44039: 'CLE',
    44040: 'CLE',
    44041: 'CLE',
    44044: 'CLE',
    44045: 'CLE',
    44046: 'CLE',
    44047: 'CLE',
    44048: 'CLE',
    44049: 'CLE',
    44050: 'CLE',
    44052: 'CLE',
    44053: 'CLE',
    44054: 'CLE',
    44055: 'CLE',
    44056: 'CLE',
    44057: 'CLE',
    44060: 'CLE',
    44061: 'CLE',
    44062: 'CLE',
    44064: 'CLE',
    44065: 'CLE',
    44067: 'CLE',
    44068: 'CLE',
    44070: 'CLE',
    44072: 'CLE',
    44073: 'CLE',
    44074: 'CLE',
    44076: 'CLE',
    44077: 'CLE',
    44080: 'CLE',
    44081: 'CLE',
    44082: 'CLE',
    44084: 'CLE',
    44085: 'CLE',
    44086: 'CLE',
    44087: 'CLE',
    44088: 'CLE',
    44089: 'CLE',
    44090: 'CLE',
    44092: 'CLE',
    44093: 'CLE',
    44094: 'CLE',
    44095: 'CLE',
    44096: 'CLE',
    44097: 'CLE',
    44099: 'CLE',
    44101: 'CLE',
    44102: 'CLE',
    44103: 'CLE',
    44104: 'CLE',
    44105: 'CLE',
    44106: 'CLE',
    44107: 'CLE',
    44108: 'CLE',
    44109: 'CLE',
    44110: 'CLE',
    44111: 'CLE',
    44112: 'CLE',
    44113: 'CLE',
    44114: 'CLE',
    44115: 'CLE',
    44116: 'CLE',
    44117: 'CLE',
    44118: 'CLE',
    44119: 'CLE',
    44120: 'CLE',
    44121: 'CLE',
    44122: 'CLE',
    44123: 'CLE',
    44124: 'CLE',
    44125: 'CLE',
    44126: 'CLE',
    44127: 'CLE',
    44128: 'CLE',
    44129: 'CLE',
    44130: 'CLE',
    44131: 'CLE',
    44132: 'CLE',
    44133: 'CLE',
    44134: 'CLE',
    44135: 'CLE',
    44136: 'CLE',
    44137: 'CLE',
    44138: 'CLE',
    44139: 'CLE',
    44140: 'CLE',
    44141: 'CLE',
    44142: 'CLE',
    44143: 'CLE',
    44144: 'CLE',
    44145: 'CLE',
    44146: 'CLE',
    44147: 'CLE',
    44149: 'CLE',
    44178: 'CLE',
    44181: 'CLE',
    44185: 'CLE',
    44188: 'CLE',
    44189: 'CLE',
    44190: 'CLE',
    44191: 'CLE',
    44192: 'CLE',
    44193: 'CLE',
    44194: 'CLE',
    44195: 'CLE',
    44197: 'CLE',
    44198: 'CLE',
    44199: 'CLE',
    44201: 'CLE',
    44202: 'CLE',
    44203: 'CLE',
    44210: 'CLE',
    44211: 'CLE',
    44212: 'CLE',
    44214: 'CLE',
    44215: 'CLE',
    44216: 'CLE',
    44217: 'CLE',
    44221: 'CLE',
    44222: 'CLE',
    44223: 'CLE',
    44224: 'CLE',
    44230: 'CLE',
    44231: 'CLE',
    44232: 'CLE',
    44233: 'CLE',
    44234: 'CLE',
    44235: 'CLE',
    44236: 'CLE',
    44237: 'CLE',
    44240: 'CLE',
    44241: 'CLE',
    44242: 'CLE',
    44243: 'CLE',
    44250: 'CLE',
    44251: 'CLE',
    44253: 'CLE',
    44254: 'CLE',
    44255: 'CLE',
    44256: 'CLE',
    44258: 'CLE',
    44260: 'CLE',
    44262: 'CLE',
    44264: 'CLE',
    44265: 'CLE',
    44266: 'CLE',
    44270: 'CLE',
    44272: 'CLE',
    44273: 'CLE',
    44274: 'CLE',
    44275: 'CLE',
    44276: 'CLE',
    44278: 'CLE',
    44280: 'CLE',
    44281: 'CLE',
    44282: 'CLE',
    44285: 'CLE',
    44286: 'CLE',
    44287: 'CLE',
    44288: 'CLE',
    44301: 'CLE',
    44302: 'CLE',
    44303: 'CLE',
    44304: 'CLE',
    44305: 'CLE',
    44306: 'CLE',
    44307: 'CLE',
    44308: 'CLE',
    44309: 'CLE',
    44310: 'CLE',
    44311: 'CLE',
    44312: 'CLE',
    44313: 'CLE',
    44314: 'CLE',
    44315: 'CLE',
    44316: 'CLE',
    44317: 'CLE',
    44319: 'CLE',
    44320: 'CLE',
    44321: 'CLE',
    44322: 'CLE',
    44325: 'CLE',
    44326: 'CLE',
    44328: 'CLE',
    44333: 'CLE',
    44334: 'CLE',
    44372: 'CLE',
    44393: 'CLE',
    44396: 'CLE',
    44398: 'CLE',
    44399: 'CLE',
    44401: 'CLE',
    44402: 'CLE',
    44403: 'CLE',
    44404: 'CLE',
    44405: 'CLE',
    44406: 'CLE',
    44408: 'CLE',
    44410: 'CLE',
    44411: 'CLE',
    44412: 'CLE',
    44413: 'CLE',
    44415: 'CLE',
    44416: 'CLE',
    44417: 'CLE',
    44418: 'CLE',
    44420: 'CLE',
    44422: 'CLE',
    44423: 'CLE',
    44424: 'CLE',
    44425: 'CLE',
    44427: 'CLE',
    44428: 'CLE',
    44429: 'CLE',
    44430: 'CLE',
    44431: 'CLE',
    44432: 'CLE',
    44436: 'CLE',
    44437: 'CLE',
    44438: 'CLE',
    44439: 'CLE',
    44440: 'CLE',
    44441: 'CLE',
    44442: 'CLE',
    44443: 'CLE',
    44444: 'CLE',
    44445: 'CLE',
    44446: 'CLE',
    44449: 'CLE',
    44450: 'CLE',
    44451: 'CLE',
    44452: 'CLE',
    44453: 'CLE',
    44454: 'CLE',
    44455: 'CLE',
    44460: 'CLE',
    44470: 'CLE',
    44471: 'CLE',
    44473: 'CLE',
    44481: 'CLE',
    44482: 'CLE',
    44483: 'CLE',
    44484: 'CLE',
    44485: 'CLE',
    44486: 'CLE',
    44487: 'CLE',
    44488: 'CLE',
    44490: 'CLE',
    44491: 'CLE',
    44492: 'CLE',
    44493: 'CLE',
    44501: 'CLE',
    44502: 'CLE',
    44503: 'CLE',
    44504: 'CLE',
    44505: 'CLE',
    44506: 'CLE',
    44507: 'CLE',
    44509: 'CLE',
    44510: 'CLE',
    44511: 'CLE',
    44512: 'CLE',
    44513: 'CLE',
    44514: 'CLE',
    44515: 'CLE',
    44555: 'CLE',
    44598: 'CLE',
    44599: 'CLE',
    44601: 'CLE',
    44606: 'CLE',
    44607: 'CLE',
    44608: 'CLE',
    44609: 'CLE',
    44610: 'CLE',
    44611: 'CLE',
    44612: 'CLE',
    44613: 'CLE',
    44614: 'CLE',
    44615: 'CLE',
    44617: 'CLE',
    44618: 'CLE',
    44619: 'CLE',
    44620: 'CLE',
    44621: 'CLE',
    44622: 'CLE',
    44624: 'CLE',
    44625: 'CLE',
    44626: 'CLE',
    44627: 'CLE',
    44628: 'CLE',
    44629: 'CLE',
    44630: 'CLE',
    44631: 'CLE',
    44632: 'CLE',
    44633: 'CLE',
    44634: 'CLE',
    44636: 'CLE',
    44637: 'CLE',
    44638: 'CLE',
    44639: 'CLE',
    44640: 'CLE',
    44641: 'CLE',
    44643: 'CLE',
    44644: 'CLE',
    44645: 'CLE',
    44646: 'CLE',
    44647: 'CLE',
    44648: 'CLE',
    44650: 'CLE',
    44651: 'CLE',
    44652: 'CLE',
    44653: 'CLE',
    44654: 'CLE',
    44656: 'CLE',
    44657: 'CLE',
    44659: 'CLE',
    44660: 'CLE',
    44661: 'CLE',
    44662: 'CLE',
    44663: 'CLE',
    44665: 'CLE',
    44666: 'CLE',
    44667: 'CLE',
    44669: 'CLE',
    44670: 'CLE',
    44671: 'CLE',
    44672: 'CLE',
    44675: 'CLE',
    44676: 'CLE',
    44677: 'CLE',
    44678: 'CLE',
    44679: 'CLE',
    44680: 'CLE',
    44681: 'CLE',
    44682: 'CLE',
    44683: 'CLE',
    44685: 'CLE',
    44687: 'CLE',
    44688: 'CLE',
    44689: 'CLE',
    44690: 'CLE',
    44691: 'CLE',
    44693: 'CLE',
    44695: 'CLE',
    44697: 'CLE',
    44699: 'CLE',
    44701: 'CLE',
    44702: 'CLE',
    44703: 'CLE',
    44704: 'CLE',
    44705: 'CLE',
    44706: 'CLE',
    44707: 'CLE',
    44708: 'CLE',
    44709: 'CLE',
    44710: 'CLE',
    44711: 'CLE',
    44712: 'CLE',
    44714: 'CLE',
    44718: 'CLE',
    44720: 'CLE',
    44721: 'CLE',
    44730: 'CLE',
    44735: 'CLE',
    44750: 'CLE',
    44760: 'CLE',
    44767: 'CLE',
    44798: 'CLE',
    44799: 'CLE',
    44802: 'CMH',
    44804: 'CMH',
    44805: 'CLE',
    44807: 'CLE',
    44809: 'CMH',
    44811: 'CLE',
    44813: 'CMH',
    44814: 'CLE',
    44815: 'CMH',
    44816: 'CLE',
    44817: 'CMH',
    44818: 'CLE',
    44820: 'CMH',
    44822: 'CMH',
    44824: 'CLE',
    44825: 'CLE',
    44826: 'CLE',
    44827: 'CLE',
    44828: 'CLE',
    44830: 'CMH',
    44833: 'CMH',
    44836: 'CLE',
    44837: 'CLE',
    44838: 'CLE',
    44839: 'CLE',
    44840: 'CLE',
    44841: 'CMH',
    44842: 'CLE',
    44843: 'CMH',
    44844: 'CMH',
    44845: 'CLE',
    44846: 'CLE',
    44847: 'CLE',
    44848: 'CLE',
    44849: 'CMH',
    44850: 'CLE',
    44851: 'CLE',
    44853: 'CMH',
    44854: 'CLE',
    44855: 'CLE',
    44856: 'CMH',
    44857: 'CLE',
    44859: 'CLE',
    44860: 'CMH',
    44861: 'CLE',
    44862: 'CMH',
    44864: 'CMH',
    44865: 'CLE',
    44866: 'CLE',
    44867: 'CLE',
    44870: 'CLE',
    44871: 'CLE',
    44874: 'CLE',
    44875: 'CLE',
    44878: 'CLE',
    44880: 'CLE',
    44881: 'CMH',
    44882: 'CMH',
    44883: 'CLE',
    44887: 'CLE',
    44888: 'CLE',
    44889: 'CLE',
    44890: 'CLE',
    44901: 'CMH',
    44902: 'CMH',
    44903: 'CLE',
    44904: 'CMH',
    44905: 'CLE',
    44906: 'CMH',
    44907: 'CMH',
    44999: 'CMH',
    45001: 'CVG',
    45002: 'CVG',
    45003: 'CMH',
    45004: 'CVG',
    45005: 'CVG',
    45011: 'CVG',
    45012: 'CVG',
    45013: 'CVG',
    45014: 'CVG',
    45015: 'CVG',
    45018: 'CVG',
    45025: 'CVG',
    45026: 'CVG',
    45030: 'CVG',
    45032: 'CVG',
    45033: 'CVG',
    45034: 'CVG',
    45036: 'CVG',
    45039: 'CVG',
    45040: 'CVG',
    45041: 'CVG',
    45042: 'CMH',
    45043: 'CVG',
    45044: 'CVG',
    45050: 'CVG',
    45051: 'CVG',
    45052: 'CVG',
    45053: 'CVG',
    45054: 'CVG',
    45055: 'CVG',
    45056: 'CVG',
    45061: 'CVG',
    45062: 'CVG',
    45063: 'CVG',
    45064: 'CMH',
    45065: 'CVG',
    45066: 'CMH',
    45067: 'CVG',
    45068: 'CVG',
    45069: 'CVG',
    45070: 'CMH',
    45071: 'CVG',
    45073: 'CVG',
    45099: 'CVG',
    45101: 'CVG',
    45102: 'CVG',
    45103: 'CVG',
    45105: 'CVG',
    45106: 'CVG',
    45107: 'CVG',
    45110: 'CVG',
    45111: 'CVG',
    45112: 'CVG',
    45113: 'CVG',
    45114: 'CVG',
    45115: 'CVG',
    45118: 'CVG',
    45119: 'CVG',
    45120: 'CVG',
    45121: 'CVG',
    45122: 'CVG',
    45123: 'CVG',
    45130: 'CVG',
    45131: 'CVG',
    45132: 'CVG',
    45133: 'CVG',
    45135: 'CVG',
    45138: 'CVG',
    45140: 'CVG',
    45142: 'CVG',
    45144: 'CVG',
    45145: 'CVG',
    45146: 'CVG',
    45147: 'CVG',
    45148: 'CVG',
    45150: 'CVG',
    45152: 'CVG',
    45153: 'CVG',
    45154: 'CVG',
    45155: 'CVG',
    45156: 'CVG',
    45157: 'CVG',
    45158: 'CVG',
    45159: 'CVG',
    45160: 'CVG',
    45162: 'CVG',
    45164: 'CVG',
    45165: 'CVG',
    45166: 'CVG',
    45167: 'CVG',
    45168: 'CVG',
    45169: 'CVG',
    45171: 'CVG',
    45172: 'CVG',
    45174: 'CVG',
    45176: 'CVG',
    45177: 'CVG',
    45201: 'CVG',
    45202: 'CVG',
    45203: 'CVG',
    45204: 'CVG',
    45205: 'CVG',
    45206: 'CVG',
    45207: 'CVG',
    45208: 'CVG',
    45209: 'CVG',
    45210: 'CVG',
    45211: 'CVG',
    45212: 'CVG',
    45213: 'CVG',
    45214: 'CVG',
    45215: 'CVG',
    45216: 'CVG',
    45217: 'CVG',
    45218: 'CVG',
    45219: 'CVG',
    45220: 'CVG',
    45221: 'CVG',
    45222: 'CVG',
    45223: 'CVG',
    45224: 'CVG',
    45225: 'CVG',
    45226: 'CVG',
    45227: 'CVG',
    45228: 'CVG',
    45229: 'CVG',
    45230: 'CVG',
    45231: 'CVG',
    45232: 'CVG',
    45233: 'CVG',
    45234: 'CVG',
    45235: 'CVG',
    45236: 'CVG',
    45237: 'CVG',
    45238: 'CVG',
    45239: 'CVG',
    45240: 'CVG',
    45241: 'CVG',
    45242: 'CVG',
    45243: 'CVG',
    45244: 'CVG',
    45245: 'CVG',
    45246: 'CVG',
    45247: 'CVG',
    45248: 'CVG',
    45249: 'CVG',
    45250: 'CVG',
    45251: 'CVG',
    45252: 'CVG',
    45253: 'CVG',
    45254: 'CVG',
    45255: 'CVG',
    45258: 'CVG',
    45262: 'CVG',
    45263: 'CVG',
    45264: 'CVG',
    45267: 'CVG',
    45268: 'CVG',
    45269: 'CVG',
    45270: 'CVG',
    45271: 'CVG',
    45273: 'CVG',
    45274: 'CVG',
    45275: 'CVG',
    45277: 'CVG',
    45280: 'CVG',
    45296: 'CVG',
    45298: 'CVG',
    45299: 'CVG',
    45301: 'CMH',
    45302: 'CMH',
    45303: 'CMH',
    45304: 'CMH',
    45305: 'CMH',
    45306: 'CMH',
    45307: 'CMH',
    45308: 'CMH',
    45309: 'CMH',
    45310: 'CMH',
    45311: 'CMH',
    45312: 'CMH',
    45314: 'CMH',
    45315: 'CMH',
    45316: 'CMH',
    45317: 'CMH',
    45318: 'CMH',
    45319: 'CMH',
    45320: 'CMH',
    45321: 'CMH',
    45322: 'CMH',
    45323: 'CMH',
    45324: 'CMH',
    45325: 'CMH',
    45326: 'CMH',
    45327: 'CMH',
    45328: 'CMH',
    45330: 'CMH',
    45331: 'CMH',
    45332: 'CMH',
    45333: 'CMH',
    45334: 'CMH',
    45335: 'CMH',
    45336: 'CMH',
    45337: 'CMH',
    45338: 'CMH',
    45339: 'CMH',
    45340: 'CMH',
    45341: 'CMH',
    45342: 'CMH',
    45343: 'CMH',
    45344: 'CMH',
    45345: 'CMH',
    45346: 'CMH',
    45347: 'CMH',
    45348: 'CMH',
    45349: 'CMH',
    45350: 'CMH',
    45351: 'CMH',
    45352: 'CMH',
    45353: 'CMH',
    45354: 'CMH',
    45356: 'CMH',
    45358: 'CMH',
    45359: 'CMH',
    45360: 'CMH',
    45361: 'CMH',
    45362: 'CMH',
    45363: 'CMH',
    45365: 'CMH',
    45367: 'CMH',
    45368: 'CMH',
    45369: 'CMH',
    45370: 'CMH',
    45371: 'CMH',
    45372: 'CMH',
    45373: 'CMH',
    45374: 'CMH',
    45377: 'CMH',
    45378: 'CMH',
    45380: 'CMH',
    45381: 'CMH',
    45382: 'CMH',
    45383: 'CMH',
    45384: 'CMH',
    45385: 'CMH',
    45387: 'CMH',
    45388: 'CMH',
    45389: 'CMH',
    45390: 'CMH',
    45400: 'CMH',
    45401: 'CMH',
    45402: 'CMH',
    45403: 'CMH',
    45404: 'CMH',
    45405: 'CMH',
    45406: 'CMH',
    45408: 'CMH',
    45409: 'CMH',
    45410: 'CMH',
    45412: 'CMH',
    45413: 'CMH',
    45414: 'CMH',
    45415: 'CMH',
    45416: 'CMH',
    45417: 'CMH',
    45418: 'CMH',
    45419: 'CMH',
    45420: 'CMH',
    45422: 'CMH',
    45423: 'CMH',
    45424: 'CMH',
    45426: 'CMH',
    45427: 'CMH',
    45428: 'CMH',
    45429: 'CMH',
    45430: 'CMH',
    45431: 'CMH',
    45432: 'CMH',
    45433: 'CMH',
    45434: 'CMH',
    45435: 'CMH',
    45437: 'CMH',
    45439: 'CMH',
    45440: 'CMH',
    45441: 'CMH',
    45448: 'CMH',
    45449: 'CMH',
    45454: 'CMH',
    45458: 'CMH',
    45459: 'CMH',
    45463: 'CMH',
    45469: 'CMH',
    45470: 'CMH',
    45475: 'CMH',
    45479: 'CMH',
    45481: 'CMH',
    45482: 'CMH',
    45490: 'CMH',
    45501: 'CMH',
    45502: 'CMH',
    45503: 'CMH',
    45504: 'CMH',
    45505: 'CMH',
    45506: 'CMH',
    45601: 'CRW',
    45612: 'CVG',
    45613: 'CRW',
    45614: 'CRW',
    45616: 'CVG',
    45617: 'CVG',
    45618: 'CVG',
    45619: 'HTS',
    45620: 'CRW',
    45621: 'CRW',
    45622: 'CRW',
    45623: 'HTS',
    45624: 'CVG',
    45628: 'CVG',
    45629: 'HTS',
    45630: 'CVG',
    45631: 'CRW',
    45633: 'CRW',
    45634: 'CRW',
    45636: 'HTS',
    45638: 'HTS',
    45640: 'CRW',
    45642: 'CVG',
    45643: 'CRW',
    45644: 'CRW',
    45645: 'HTS',
    45646: 'CVG',
    45647: 'CRW',
    45648: 'CVG',
    45650: 'CVG',
    45651: 'CRW',
    45652: 'CVG',
    45653: 'CRW',
    45654: 'CRW',
    45656: 'CRW',
    45657: 'CVG',
    45658: 'HTS',
    45659: 'HTS',
    45660: 'CVG',
    45661: 'CVG',
    45662: 'CRW',
    45663: 'CVG',
    45669: 'HTS',
    45671: 'CVG',
    45672: 'CRW',
    45673: 'CRW',
    45674: 'CRW',
    45675: 'HTS',
    45677: 'CRW',
    45678: 'HTS',
    45679: 'CVG',
    45680: 'HTS',
    45681: 'CVG',
    45682: 'CRW',
    45683: 'CRW',
    45684: 'CVG',
    45685: 'CRW',
    45686: 'CRW',
    45687: 'CVG',
    45688: 'HTS',
    45690: 'CVG',
    45692: 'CRW',
    45693: 'CVG',
    45694: 'HTS',
    45695: 'CRW',
    45696: 'HTS',
    45697: 'CVG',
    45698: 'CRW',
    45699: 'CRW',
    45701: 'CRW',
    45710: 'CRW',
    45711: 'CRW',
    45712: 'CRW',
    45713: 'CRW',
    45714: 'CRW',
    45715: 'CRW',
    45716: 'CRW',
    45717: 'CRW',
    45719: 'CRW',
    45720: 'CRW',
    45721: 'CRW',
    45723: 'CRW',
    45724: 'CRW',
    45727: 'CRW',
    45729: 'CRW',
    45732: 'CRW',
    45734: 'CRW',
    45735: 'CRW',
    45739: 'CRW',
    45740: 'CRW',
    45741: 'CRW',
    45742: 'CRW',
    45743: 'CRW',
    45744: 'CRW',
    45745: 'CRW',
    45746: 'CRW',
    45750: 'CRW',
    45760: 'CRW',
    45761: 'CRW',
    45764: 'CRW',
    45766: 'CRW',
    45767: 'CRW',
    45768: 'CRW',
    45769: 'CRW',
    45770: 'CRW',
    45771: 'CRW',
    45772: 'CRW',
    45773: 'CRW',
    45775: 'CRW',
    45776: 'CRW',
    45777: 'CRW',
    45778: 'CRW',
    45779: 'CRW',
    45780: 'CRW',
    45782: 'CRW',
    45783: 'CRW',
    45784: 'CRW',
    45786: 'CRW',
    45787: 'CRW',
    45788: 'CRW',
    45789: 'CRW',
    45801: 'CMH',
    45802: 'CMH',
    45804: 'CMH',
    45805: 'CMH',
    45806: 'CMH',
    45807: 'CMH',
    45808: 'CMH',
    45809: 'CMH',
    45810: 'CMH',
    45812: 'CMH',
    45813: 'SBN',
    45814: 'CMH',
    45815: 'CMH',
    45816: 'CMH',
    45817: 'CMH',
    45819: 'CMH',
    45820: 'CMH',
    45821: 'SBN',
    45822: 'CMH',
    45826: 'CMH',
    45827: 'CMH',
    45828: 'CMH',
    45830: 'CMH',
    45831: 'SBN',
    45832: 'CMH',
    45833: 'CMH',
    45835: 'CMH',
    45836: 'CMH',
    45837: 'CMH',
    45838: 'CMH',
    45839: 'CMH',
    45840: 'CMH',
    45841: 'CMH',
    45843: 'CMH',
    45844: 'CMH',
    45845: 'CMH',
    45846: 'CMH',
    45848: 'CMH',
    45849: 'CMH',
    45850: 'CMH',
    45851: 'CMH',
    45853: 'CMH',
    45854: 'CMH',
    45855: 'SBN',
    45856: 'CMH',
    45858: 'SBN',
    45859: 'CMH',
    45860: 'CMH',
    45861: 'SBN',
    45862: 'CMH',
    45863: 'CMH',
    45864: 'CMH',
    45865: 'CMH',
    45866: 'CMH',
    45867: 'CMH',
    45868: 'CMH',
    45869: 'CMH',
    45870: 'CMH',
    45871: 'CMH',
    45872: 'CMH',
    45873: 'SBN',
    45874: 'CMH',
    45875: 'CMH',
    45876: 'CMH',
    45877: 'CMH',
    45879: 'SBN',
    45880: 'SBN',
    45881: 'CMH',
    45882: 'CMH',
    45883: 'CMH',
    45884: 'CMH',
    45885: 'CMH',
    45886: 'CMH',
    45887: 'CMH',
    45888: 'CMH',
    45889: 'CMH',
    45890: 'CMH',
    45891: 'CMH',
    45893: 'CMH',
    45894: 'CMH',
    45895: 'CMH',
    45896: 'CMH',
    45897: 'CMH',
    45898: 'CMH',
    45899: 'CMH',
    45944: 'CMH',
    45950: 'CMH',
    45999: 'CVG',
    46001: 'IND',
    46011: 'IND',
    46012: 'IND',
    46013: 'IND',
    46014: 'IND',
    46015: 'IND',
    46016: 'IND',
    46017: 'IND',
    46018: 'IND',
    46030: 'IND',
    46031: 'IND',
    46032: 'IND',
    46033: 'IND',
    46034: 'IND',
    46035: 'IND',
    46036: 'IND',
    46037: 'IND',
    46038: 'IND',
    46039: 'IND',
    46040: 'IND',
    46041: 'IND',
    46044: 'IND',
    46045: 'IND',
    46047: 'IND',
    46048: 'IND',
    46049: 'IND',
    46050: 'IND',
    46051: 'IND',
    46052: 'IND',
    46055: 'IND',
    46056: 'IND',
    46057: 'IND',
    46058: 'IND',
    46060: 'IND',
    46061: 'IND',
    46062: 'IND',
    46063: 'IND',
    46064: 'IND',
    46065: 'IND',
    46067: 'IND',
    46068: 'IND',
    46069: 'IND',
    46070: 'IND',
    46071: 'IND',
    46072: 'IND',
    46074: 'IND',
    46075: 'IND',
    46076: 'IND',
    46077: 'IND',
    46082: 'IND',
    46085: 'IND',
    46102: 'IND',
    46103: 'IND',
    46104: 'IND',
    46105: 'IND',
    46106: 'IND',
    46107: 'IND',
    46110: 'IND',
    46111: 'IND',
    46112: 'IND',
    46113: 'IND',
    46115: 'IND',
    46117: 'IND',
    46118: 'IND',
    46120: 'IND',
    46121: 'IND',
    46122: 'IND',
    46123: 'IND',
    46124: 'IND',
    46125: 'IND',
    46126: 'IND',
    46127: 'IND',
    46128: 'IND',
    46129: 'IND',
    46130: 'IND',
    46131: 'IND',
    46133: 'IND',
    46135: 'IND',
    46140: 'IND',
    46142: 'IND',
    46143: 'IND',
    46144: 'IND',
    46146: 'IND',
    46147: 'IND',
    46148: 'IND',
    46149: 'IND',
    46150: 'IND',
    46151: 'IND',
    46154: 'IND',
    46155: 'IND',
    46156: 'IND',
    46157: 'IND',
    46158: 'IND',
    46160: 'IND',
    46161: 'IND',
    46162: 'IND',
    46163: 'IND',
    46164: 'IND',
    46165: 'IND',
    46166: 'IND',
    46167: 'IND',
    46168: 'IND',
    46170: 'IND',
    46171: 'IND',
    46172: 'IND',
    46173: 'IND',
    46175: 'IND',
    46176: 'IND',
    46180: 'IND',
    46181: 'IND',
    46182: 'IND',
    46183: 'IND',
    46184: 'IND',
    46186: 'IND',
    46197: 'IND',
    46201: 'IND',
    46202: 'IND',
    46203: 'IND',
    46204: 'IND',
    46205: 'IND',
    46206: 'IND',
    46207: 'IND',
    46208: 'IND',
    46209: 'IND',
    46210: 'IND',
    46211: 'IND',
    46213: 'IND',
    46214: 'IND',
    46216: 'IND',
    46217: 'IND',
    46218: 'IND',
    46219: 'IND',
    46220: 'IND',
    46221: 'IND',
    46222: 'IND',
    46223: 'IND',
    46224: 'IND',
    46225: 'IND',
    46226: 'IND',
    46227: 'IND',
    46228: 'IND',
    46229: 'IND',
    46230: 'IND',
    46231: 'IND',
    46234: 'IND',
    46235: 'IND',
    46236: 'IND',
    46237: 'IND',
    46239: 'IND',
    46240: 'IND',
    46241: 'IND',
    46242: 'IND',
    46244: 'IND',
    46247: 'IND',
    46249: 'IND',
    46250: 'IND',
    46251: 'IND',
    46253: 'IND',
    46254: 'IND',
    46255: 'IND',
    46256: 'IND',
    46259: 'IND',
    46260: 'IND',
    46262: 'IND',
    46266: 'IND',
    46268: 'IND',
    46274: 'IND',
    46275: 'IND',
    46277: 'IND',
    46278: 'IND',
    46280: 'IND',
    46282: 'IND',
    46283: 'IND',
    46285: 'IND',
    46288: 'IND',
    46290: 'IND',
    46291: 'IND',
    46295: 'IND',
    46296: 'IND',
    46298: 'IND',
    46301: 'ORD',
    46302: 'ORD',
    46303: 'ORD',
    46304: 'ORD',
    46307: 'ORD',
    46308: 'SBN',
    46310: 'ORD',
    46311: 'ORD',
    46312: 'ORD',
    46319: 'ORD',
    46320: 'ORD',
    46321: 'ORD',
    46322: 'ORD',
    46323: 'ORD',
    46324: 'ORD',
    46325: 'ORD',
    46327: 'ORD',
    46340: 'ORD',
    46341: 'ORD',
    46342: 'ORD',
    46345: 'ORD',
    46346: 'ORD',
    46347: 'ORD',
    46348: 'SBN',
    46349: 'ORD',
    46350: 'ORD',
    46352: 'ORD',
    46355: 'ORD',
    46356: 'ORD',
    46360: 'ORD',
    46361: 'ORD',
    46365: 'ORD',
    46366: 'ORD',
    46368: 'ORD',
    46371: 'SBN',
    46372: 'ORD',
    46373: 'ORD',
    46374: 'ORD',
    46375: 'ORD',
    46376: 'ORD',
    46377: 'SBN',
    46379: 'SBN',
    46380: 'SBN',
    46381: 'IND',
    46382: 'ORD',
    46383: 'ORD',
    46384: 'ORD',
    46385: 'ORD',
    46390: 'ORD',
    46391: 'ORD',
    46392: 'ORD',
    46393: 'ORD',
    46394: 'ORD',
    46401: 'ORD',
    46402: 'ORD',
    46403: 'ORD',
    46404: 'ORD',
    46405: 'ORD',
    46406: 'ORD',
    46407: 'ORD',
    46408: 'ORD',
    46409: 'ORD',
    46410: 'ORD',
    46411: 'SBN',
    46501: 'SBN',
    46502: 'SBN',
    46504: 'SBN',
    46506: 'SBN',
    46507: 'SBN',
    46508: 'SBN',
    46510: 'SBN',
    46511: 'SBN',
    46513: 'SBN',
    46514: 'SBN',
    46515: 'SBN',
    46516: 'SBN',
    46517: 'SBN',
    46524: 'SBN',
    46526: 'SBN',
    46527: 'SBN',
    46528: 'SBN',
    46530: 'SBN',
    46531: 'SBN',
    46532: 'SBN',
    46534: 'SBN',
    46536: 'SBN',
    46537: 'SBN',
    46538: 'SBN',
    46539: 'SBN',
    46540: 'SBN',
    46542: 'SBN',
    46543: 'SBN',
    46544: 'SBN',
    46545: 'SBN',
    46546: 'SBN',
    46550: 'SBN',
    46552: 'SBN',
    46553: 'SBN',
    46554: 'SBN',
    46555: 'SBN',
    46556: 'SBN',
    46561: 'SBN',
    46562: 'SBN',
    46563: 'SBN',
    46565: 'SBN',
    46566: 'SBN',
    46567: 'SBN',
    46570: 'SBN',
    46571: 'SBN',
    46572: 'SBN',
    46573: 'SBN',
    46574: 'SBN',
    46580: 'SBN',
    46581: 'SBN',
    46582: 'SBN',
    46590: 'SBN',
    46595: 'SBN',
    46601: 'SBN',
    46604: 'SBN',
    46612: 'SBN',
    46613: 'SBN',
    46614: 'SBN',
    46615: 'SBN',
    46616: 'SBN',
    46617: 'SBN',
    46619: 'SBN',
    46620: 'SBN',
    46624: 'SBN',
    46626: 'SBN',
    46628: 'SBN',
    46629: 'SBN',
    46634: 'SBN',
    46635: 'SBN',
    46637: 'SBN',
    46660: 'SBN',
    46680: 'SBN',
    46699: 'SBN',
    46701: 'SBN',
    46702: 'IND',
    46703: 'SBN',
    46704: 'SBN',
    46705: 'SBN',
    46706: 'SBN',
    46710: 'SBN',
    46711: 'IND',
    46713: 'IND',
    46714: 'IND',
    46720: 'IND',
    46721: 'SBN',
    46723: 'SBN',
    46725: 'SBN',
    46730: 'SBN',
    46731: 'IND',
    46732: 'SBN',
    46733: 'IND',
    46737: 'SBN',
    46738: 'SBN',
    46740: 'IND',
    46741: 'SBN',
    46742: 'SBN',
    46743: 'SBN',
    46745: 'IND',
    46746: 'SBN',
    46747: 'SBN',
    46748: 'SBN',
    46750: 'IND',
    46755: 'SBN',
    46759: 'IND',
    46760: 'SBN',
    46761: 'SBN',
    46763: 'SBN',
    46764: 'SBN',
    46765: 'SBN',
    46766: 'IND',
    46767: 'SBN',
    46769: 'IND',
    46770: 'IND',
    46771: 'SBN',
    46772: 'IND',
    46773: 'IND',
    46774: 'SBN',
    46776: 'SBN',
    46777: 'IND',
    46778: 'IND',
    46779: 'SBN',
    46780: 'IND',
    46781: 'IND',
    46782: 'IND',
    46783: 'IND',
    46784: 'SBN',
    46785: 'SBN',
    46786: 'SBN',
    46787: 'SBN',
    46788: 'SBN',
    46789: 'SBN',
    46791: 'IND',
    46792: 'IND',
    46793: 'SBN',
    46794: 'SBN',
    46795: 'SBN',
    46796: 'SBN',
    46797: 'SBN',
    46798: 'IND',
    46799: 'IND',
    46801: 'SBN',
    46802: 'SBN',
    46803: 'SBN',
    46804: 'SBN',
    46805: 'SBN',
    46806: 'SBN',
    46807: 'SBN',
    46808: 'SBN',
    46809: 'IND',
    46814: 'SBN',
    46815: 'SBN',
    46816: 'IND',
    46818: 'SBN',
    46819: 'IND',
    46825: 'SBN',
    46835: 'SBN',
    46845: 'SBN',
    46850: 'SBN',
    46851: 'SBN',
    46852: 'SBN',
    46853: 'SBN',
    46854: 'SBN',
    46855: 'SBN',
    46856: 'SBN',
    46857: 'SBN',
    46858: 'SBN',
    46859: 'SBN',
    46860: 'SBN',
    46861: 'SBN',
    46862: 'SBN',
    46863: 'SBN',
    46864: 'SBN',
    46865: 'SBN',
    46866: 'SBN',
    46867: 'SBN',
    46868: 'SBN',
    46869: 'SBN',
    46885: 'SBN',
    46895: 'SBN',
    46896: 'SBN',
    46897: 'IND',
    46898: 'SBN',
    46899: 'IND',
    46901: 'IND',
    46902: 'IND',
    46903: 'IND',
    46904: 'IND',
    46910: 'IND',
    46911: 'IND',
    46912: 'SBN',
    46913: 'IND',
    46914: 'IND',
    46915: 'IND',
    46916: 'IND',
    46917: 'IND',
    46919: 'IND',
    46920: 'IND',
    46921: 'IND',
    46922: 'SBN',
    46923: 'IND',
    46926: 'IND',
    46928: 'IND',
    46929: 'IND',
    46930: 'IND',
    46931: 'IND',
    46932: 'IND',
    46933: 'IND',
    46935: 'IND',
    46936: 'IND',
    46937: 'IND',
    46938: 'IND',
    46939: 'IND',
    46940: 'IND',
    46941: 'IND',
    46942: 'IND',
    46943: 'IND',
    46945: 'SBN',
    46946: 'IND',
    46947: 'IND',
    46950: 'IND',
    46951: 'IND',
    46952: 'IND',
    46953: 'IND',
    46957: 'IND',
    46958: 'IND',
    46959: 'IND',
    46960: 'SBN',
    46961: 'IND',
    46962: 'IND',
    46965: 'IND',
    46967: 'IND',
    46968: 'SBN',
    46970: 'IND',
    46971: 'IND',
    46974: 'IND',
    46975: 'SBN',
    46977: 'IND',
    46978: 'IND',
    46979: 'IND',
    46980: 'IND',
    46982: 'SBN',
    46984: 'IND',
    46985: 'IND',
    46986: 'IND',
    46987: 'IND',
    46988: 'IND',
    46989: 'IND',
    46990: 'IND',
    46991: 'IND',
    46992: 'IND',
    46994: 'IND',
    46995: 'IND',
    46996: 'SBN',
    46998: 'IND',
    47001: 'IND',
    47003: 'IND',
    47006: 'IND',
    47010: 'IND',
    47011: 'SDF',
    47012: 'IND',
    47016: 'IND',
    47017: 'SDF',
    47018: 'SDF',
    47019: 'SDF',
    47020: 'SDF',
    47021: 'SDF',
    47022: 'IND',
    47023: 'SDF',
    47024: 'IND',
    47025: 'IND',
    47030: 'IND',
    47031: 'IND',
    47032: 'IND',
    47033: 'IND',
    47034: 'IND',
    47035: 'IND',
    47036: 'IND',
    47037: 'IND',
    47038: 'SDF',
    47039: 'IND',
    47040: 'SDF',
    47041: 'IND',
    47042: 'SDF',
    47043: 'SDF',
    47060: 'IND',
    47102: 'SDF',
    47104: 'SDF',
    47106: 'SDF',
    47107: 'EVV',
    47108: 'EVV',
    47110: 'EVV',
    47111: 'SDF',
    47112: 'EVV',
    47114: 'EVV',
    47115: 'EVV',
    47116: 'EVV',
    47117: 'EVV',
    47118: 'EVV',
    47119: 'SDF',
    47120: 'EVV',
    47122: 'EVV',
    47123: 'EVV',
    47124: 'EVV',
    47125: 'EVV',
    47126: 'SDF',
    47129: 'SDF',
    47130: 'SDF',
    47131: 'SDF',
    47132: 'SDF',
    47133: 'SDF',
    47134: 'SDF',
    47135: 'EVV',
    47136: 'EVV',
    47137: 'EVV',
    47138: 'SDF',
    47139: 'SDF',
    47140: 'EVV',
    47141: 'SDF',
    47142: 'EVV',
    47143: 'SDF',
    47144: 'SDF',
    47145: 'EVV',
    47146: 'SDF',
    47147: 'SDF',
    47150: 'SDF',
    47151: 'SDF',
    47160: 'EVV',
    47161: 'EVV',
    47162: 'SDF',
    47163: 'SDF',
    47164: 'EVV',
    47165: 'EVV',
    47166: 'EVV',
    47167: 'EVV',
    47170: 'SDF',
    47172: 'SDF',
    47174: 'EVV',
    47175: 'EVV',
    47177: 'SDF',
    47190: 'SDF',
    47199: 'SDF',
    47201: 'IND',
    47202: 'IND',
    47203: 'IND',
    47220: 'EVV',
    47223: 'SDF',
    47224: 'SDF',
    47225: 'IND',
    47226: 'IND',
    47227: 'SDF',
    47228: 'EVV',
    47229: 'SDF',
    47230: 'SDF',
    47231: 'SDF',
    47232: 'IND',
    47234: 'IND',
    47235: 'EVV',
    47236: 'IND',
    47240: 'IND',
    47243: 'SDF',
    47244: 'IND',
    47245: 'SDF',
    47246: 'IND',
    47247: 'SDF',
    47249: 'EVV',
    47250: 'SDF',
    47260: 'EVV',
    47261: 'IND',
    47263: 'IND',
    47264: 'EVV',
    47265: 'SDF',
    47270: 'SDF',
    47272: 'IND',
    47273: 'IND',
    47274: 'SDF',
    47280: 'IND',
    47281: 'EVV',
    47282: 'SDF',
    47283: 'IND',
    47302: 'IND',
    47303: 'IND',
    47304: 'IND',
    47305: 'IND',
    47306: 'IND',
    47307: 'IND',
    47308: 'IND',
    47320: 'IND',
    47322: 'IND',
    47324: 'IND',
    47325: 'IND',
    47326: 'IND',
    47327: 'IND',
    47330: 'IND',
    47331: 'IND',
    47334: 'IND',
    47335: 'IND',
    47336: 'IND',
    47337: 'IND',
    47338: 'IND',
    47339: 'IND',
    47340: 'IND',
    47341: 'IND',
    47342: 'IND',
    47344: 'IND',
    47345: 'IND',
    47346: 'IND',
    47348: 'IND',
    47351: 'IND',
    47352: 'IND',
    47353: 'IND',
    47354: 'IND',
    47355: 'IND',
    47356: 'IND',
    47357: 'IND',
    47358: 'IND',
    47359: 'IND',
    47360: 'IND',
    47361: 'IND',
    47362: 'IND',
    47366: 'IND',
    47367: 'IND',
    47368: 'IND',
    47369: 'IND',
    47370: 'IND',
    47371: 'IND',
    47373: 'IND',
    47374: 'IND',
    47375: 'IND',
    47380: 'IND',
    47381: 'IND',
    47382: 'IND',
    47383: 'IND',
    47384: 'IND',
    47385: 'IND',
    47386: 'IND',
    47387: 'IND',
    47388: 'IND',
    47390: 'IND',
    47392: 'IND',
    47393: 'IND',
    47394: 'IND',
    47396: 'IND',
    47401: 'IND',
    47402: 'IND',
    47403: 'IND',
    47404: 'IND',
    47405: 'IND',
    47406: 'IND',
    47407: 'IND',
    47408: 'IND',
    47420: 'EVV',
    47421: 'EVV',
    47424: 'EVV',
    47426: 'IND',
    47427: 'IND',
    47429: 'IND',
    47430: 'EVV',
    47431: 'IND',
    47432: 'EVV',
    47433: 'IND',
    47434: 'EVV',
    47435: 'IND',
    47436: 'EVV',
    47437: 'EVV',
    47438: 'IND',
    47439: 'EVV',
    47441: 'IND',
    47443: 'EVV',
    47445: 'IND',
    47446: 'EVV',
    47448: 'IND',
    47449: 'EVV',
    47451: 'EVV',
    47452: 'EVV',
    47453: 'EVV',
    47454: 'EVV',
    47455: 'IND',
    47456: 'IND',
    47457: 'EVV',
    47458: 'IND',
    47459: 'IND',
    47460: 'IND',
    47462: 'EVV',
    47463: 'IND',
    47464: 'IND',
    47465: 'EVV',
    47467: 'EVV',
    47468: 'IND',
    47469: 'EVV',
    47470: 'EVV',
    47471: 'IND',
    47490: 'IND',
    47501: 'EVV',
    47512: 'EVV',
    47513: 'EVV',
    47514: 'EVV',
    47515: 'EVV',
    47516: 'EVV',
    47519: 'EVV',
    47520: 'EVV',
    47521: 'EVV',
    47522: 'EVV',
    47523: 'EVV',
    47524: 'EVV',
    47525: 'EVV',
    47527: 'EVV',
    47528: 'EVV',
    47529: 'EVV',
    47531: 'EVV',
    47532: 'EVV',
    47535: 'EVV',
    47536: 'EVV',
    47537: 'EVV',
    47541: 'EVV',
    47542: 'EVV',
    47545: 'EVV',
    47546: 'EVV',
    47547: 'EVV',
    47549: 'EVV',
    47550: 'EVV',
    47551: 'EVV',
    47552: 'EVV',
    47553: 'EVV',
    47556: 'EVV',
    47557: 'EVV',
    47558: 'EVV',
    47561: 'EVV',
    47562: 'EVV',
    47564: 'EVV',
    47567: 'EVV',
    47568: 'EVV',
    47573: 'EVV',
    47574: 'EVV',
    47575: 'EVV',
    47576: 'EVV',
    47577: 'EVV',
    47578: 'EVV',
    47579: 'EVV',
    47580: 'EVV',
    47581: 'EVV',
    47584: 'EVV',
    47585: 'EVV',
    47586: 'EVV',
    47588: 'EVV',
    47590: 'EVV',
    47591: 'EVV',
    47596: 'EVV',
    47597: 'EVV',
    47598: 'EVV',
    47601: 'EVV',
    47610: 'EVV',
    47611: 'EVV',
    47612: 'EVV',
    47613: 'EVV',
    47614: 'EVV',
    47615: 'EVV',
    47616: 'EVV',
    47617: 'EVV',
    47618: 'EVV',
    47619: 'EVV',
    47620: 'EVV',
    47629: 'EVV',
    47630: 'EVV',
    47631: 'EVV',
    47633: 'EVV',
    47634: 'EVV',
    47635: 'EVV',
    47637: 'EVV',
    47638: 'EVV',
    47639: 'EVV',
    47640: 'EVV',
    47647: 'EVV',
    47648: 'EVV',
    47649: 'EVV',
    47654: 'EVV',
    47660: 'EVV',
    47665: 'EVV',
    47666: 'EVV',
    47670: 'EVV',
    47683: 'EVV',
    47701: 'EVV',
    47702: 'EVV',
    47703: 'EVV',
    47704: 'EVV',
    47705: 'EVV',
    47706: 'EVV',
    47708: 'EVV',
    47710: 'EVV',
    47711: 'EVV',
    47712: 'EVV',
    47713: 'EVV',
    47714: 'EVV',
    47715: 'EVV',
    47716: 'EVV',
    47719: 'EVV',
    47720: 'EVV',
    47721: 'EVV',
    47722: 'EVV',
    47724: 'EVV',
    47725: 'EVV',
    47727: 'EVV',
    47728: 'EVV',
    47730: 'EVV',
    47731: 'EVV',
    47732: 'EVV',
    47733: 'EVV',
    47734: 'EVV',
    47735: 'EVV',
    47736: 'EVV',
    47737: 'EVV',
    47739: 'EVV',
    47740: 'EVV',
    47741: 'EVV',
    47744: 'EVV',
    47747: 'EVV',
    47750: 'EVV',
    47801: 'IND',
    47802: 'IND',
    47803: 'IND',
    47804: 'IND',
    47805: 'IND',
    47807: 'IND',
    47808: 'IND',
    47809: 'IND',
    47811: 'IND',
    47812: 'IND',
    47813: 'IND',
    47814: 'IND',
    47830: 'IND',
    47831: 'IND',
    47832: 'IND',
    47833: 'IND',
    47834: 'IND',
    47836: 'IND',
    47837: 'IND',
    47838: 'EVV',
    47840: 'IND',
    47841: 'IND',
    47842: 'IND',
    47845: 'IND',
    47846: 'IND',
    47847: 'IND',
    47848: 'EVV',
    47849: 'IND',
    47850: 'IND',
    47851: 'IND',
    47852: 'IND',
    47853: 'IND',
    47854: 'IND',
    47855: 'IND',
    47856: 'IND',
    47857: 'IND',
    47858: 'IND',
    47859: 'IND',
    47860: 'IND',
    47861: 'IND',
    47862: 'IND',
    47863: 'IND',
    47864: 'IND',
    47865: 'EVV',
    47866: 'IND',
    47868: 'IND',
    47869: 'IND',
    47870: 'IND',
    47871: 'IND',
    47872: 'IND',
    47874: 'IND',
    47875: 'IND',
    47876: 'IND',
    47878: 'IND',
    47879: 'IND',
    47880: 'IND',
    47881: 'IND',
    47882: 'IND',
    47884: 'IND',
    47885: 'IND',
    47901: 'IND',
    47902: 'IND',
    47903: 'IND',
    47904: 'IND',
    47905: 'IND',
    47906: 'IND',
    47907: 'IND',
    47909: 'IND',
    47916: 'IND',
    47917: 'IND',
    47918: 'IND',
    47920: 'IND',
    47921: 'IND',
    47922: 'IND',
    47923: 'IND',
    47924: 'IND',
    47925: 'IND',
    47926: 'IND',
    47928: 'IND',
    47929: 'IND',
    47930: 'IND',
    47932: 'IND',
    47933: 'IND',
    47934: 'IND',
    47935: 'IND',
    47936: 'IND',
    47937: 'IND',
    47938: 'IND',
    47939: 'IND',
    47940: 'IND',
    47941: 'IND',
    47942: 'IND',
    47943: 'SBN',
    47944: 'IND',
    47946: 'IND',
    47948: 'IND',
    47949: 'IND',
    47950: 'IND',
    47951: 'IND',
    47952: 'IND',
    47954: 'IND',
    47955: 'IND',
    47957: 'SBN',
    47958: 'IND',
    47959: 'IND',
    47960: 'IND',
    47962: 'IND',
    47963: 'IND',
    47964: 'IND',
    47965: 'IND',
    47966: 'IND',
    47967: 'IND',
    47968: 'IND',
    47969: 'IND',
    47970: 'IND',
    47971: 'IND',
    47974: 'IND',
    47975: 'IND',
    47977: 'IND',
    47978: 'IND',
    47980: 'IND',
    47981: 'IND',
    47982: 'IND',
    47983: 'IND',
    47984: 'IND',
    47986: 'IND',
    47987: 'IND',
    47988: 'IND',
    47989: 'IND',
    47990: 'IND',
    47991: 'IND',
    47992: 'IND',
    47993: 'IND',
    47994: 'IND',
    47995: 'IND',
    47996: 'IND',
    47997: 'IND',
    48001: 'DTW',
    48002: 'DTW',
    48003: 'DTW',
    48004: 'DTW',
    48005: 'DTW',
    48006: 'DTW',
    48007: 'DTW',
    48009: 'DTW',
    48012: 'DTW',
    48014: 'DTW',
    48015: 'DTW',
    48017: 'DTW',
    48021: 'DTW',
    48022: 'DTW',
    48023: 'DTW',
    48025: 'DTW',
    48026: 'DTW',
    48027: 'DTW',
    48028: 'DTW',
    48030: 'DTW',
    48032: 'DTW',
    48033: 'DTW',
    48034: 'DTW',
    48035: 'DTW',
    48036: 'DTW',
    48037: 'DTW',
    48038: 'DTW',
    48039: 'DTW',
    48040: 'DTW',
    48041: 'DTW',
    48042: 'DTW',
    48043: 'DTW',
    48044: 'DTW',
    48045: 'DTW',
    48046: 'DTW',
    48047: 'DTW',
    48048: 'DTW',
    48049: 'DTW',
    48050: 'DTW',
    48051: 'DTW',
    48054: 'DTW',
    48059: 'DTW',
    48060: 'DTW',
    48061: 'DTW',
    48062: 'DTW',
    48063: 'DTW',
    48064: 'DTW',
    48065: 'DTW',
    48066: 'DTW',
    48067: 'DTW',
    48068: 'DTW',
    48069: 'DTW',
    48070: 'DTW',
    48071: 'DTW',
    48072: 'DTW',
    48073: 'DTW',
    48074: 'DTW',
    48075: 'DTW',
    48076: 'DTW',
    48079: 'DTW',
    48080: 'DTW',
    48081: 'DTW',
    48082: 'DTW',
    48083: 'DTW',
    48084: 'DTW',
    48085: 'DTW',
    48086: 'DTW',
    48088: 'DTW',
    48089: 'DTW',
    48090: 'DTW',
    48091: 'DTW',
    48092: 'DTW',
    48093: 'DTW',
    48094: 'DTW',
    48095: 'DTW',
    48096: 'DTW',
    48097: 'DTW',
    48098: 'DTW',
    48099: 'DTW',
    48101: 'DTW',
    48102: 'DTW',
    48103: 'DTW',
    48104: 'DTW',
    48105: 'DTW',
    48106: 'DTW',
    48107: 'DTW',
    48108: 'DTW',
    48109: 'DTW',
    48110: 'DTW',
    48111: 'DTW',
    48112: 'DTW',
    48113: 'DTW',
    48114: 'DTW',
    48115: 'DTW',
    48116: 'DTW',
    48117: 'DTW',
    48118: 'DTW',
    48120: 'DTW',
    48121: 'DTW',
    48122: 'DTW',
    48123: 'DTW',
    48124: 'DTW',
    48125: 'DTW',
    48126: 'DTW',
    48127: 'DTW',
    48128: 'DTW',
    48130: 'DTW',
    48131: 'DTW',
    48133: 'DTW',
    48134: 'DTW',
    48135: 'DTW',
    48136: 'DTW',
    48137: 'DTW',
    48138: 'DTW',
    48139: 'DTW',
    48140: 'DTW',
    48141: 'DTW',
    48143: 'DTW',
    48144: 'DTW',
    48145: 'DTW',
    48146: 'DTW',
    48150: 'DTW',
    48151: 'DTW',
    48152: 'DTW',
    48153: 'DTW',
    48154: 'DTW',
    48157: 'DTW',
    48158: 'DTW',
    48159: 'DTW',
    48160: 'DTW',
    48161: 'DTW',
    48162: 'DTW',
    48164: 'DTW',
    48165: 'DTW',
    48166: 'DTW',
    48167: 'DTW',
    48168: 'DTW',
    48169: 'DTW',
    48170: 'DTW',
    48173: 'DTW',
    48174: 'DTW',
    48175: 'DTW',
    48176: 'DTW',
    48177: 'DTW',
    48178: 'DTW',
    48179: 'DTW',
    48180: 'DTW',
    48182: 'DTW',
    48183: 'DTW',
    48184: 'DTW',
    48185: 'DTW',
    48186: 'DTW',
    48187: 'DTW',
    48188: 'DTW',
    48189: 'DTW',
    48190: 'DTW',
    48191: 'DTW',
    48192: 'DTW',
    48193: 'DTW',
    48195: 'DTW',
    48197: 'DTW',
    48198: 'DTW',
    48201: 'DTW',
    48202: 'DTW',
    48203: 'DTW',
    48204: 'DTW',
    48205: 'DTW',
    48206: 'DTW',
    48207: 'DTW',
    48208: 'DTW',
    48209: 'DTW',
    48210: 'DTW',
    48211: 'DTW',
    48212: 'DTW',
    48213: 'DTW',
    48214: 'DTW',
    48215: 'DTW',
    48216: 'DTW',
    48217: 'DTW',
    48218: 'DTW',
    48219: 'DTW',
    48220: 'DTW',
    48221: 'DTW',
    48222: 'DTW',
    48223: 'DTW',
    48224: 'DTW',
    48225: 'DTW',
    48226: 'DTW',
    48227: 'DTW',
    48228: 'DTW',
    48229: 'DTW',
    48230: 'DTW',
    48231: 'DTW',
    48232: 'DTW',
    48233: 'DTW',
    48234: 'DTW',
    48235: 'DTW',
    48236: 'DTW',
    48237: 'DTW',
    48238: 'DTW',
    48239: 'DTW',
    48240: 'DTW',
    48242: 'DTW',
    48243: 'DTW',
    48244: 'DTW',
    48255: 'DTW',
    48260: 'DTW',
    48264: 'DTW',
    48265: 'DTW',
    48266: 'DTW',
    48267: 'DTW',
    48268: 'DTW',
    48269: 'DTW',
    48272: 'DTW',
    48275: 'DTW',
    48277: 'DTW',
    48278: 'DTW',
    48279: 'DTW',
    48288: 'DTW',
    48301: 'DTW',
    48302: 'DTW',
    48303: 'DTW',
    48304: 'DTW',
    48306: 'DTW',
    48307: 'DTW',
    48308: 'DTW',
    48309: 'DTW',
    48310: 'DTW',
    48311: 'DTW',
    48312: 'DTW',
    48313: 'DTW',
    48314: 'DTW',
    48315: 'DTW',
    48316: 'DTW',
    48317: 'DTW',
    48318: 'DTW',
    48320: 'DTW',
    48321: 'DTW',
    48322: 'DTW',
    48323: 'DTW',
    48324: 'DTW',
    48325: 'DTW',
    48326: 'DTW',
    48327: 'DTW',
    48328: 'DTW',
    48329: 'DTW',
    48330: 'DTW',
    48331: 'DTW',
    48332: 'DTW',
    48333: 'DTW',
    48334: 'DTW',
    48335: 'DTW',
    48336: 'DTW',
    48340: 'DTW',
    48341: 'DTW',
    48342: 'DTW',
    48343: 'DTW',
    48346: 'DTW',
    48347: 'DTW',
    48348: 'DTW',
    48350: 'DTW',
    48353: 'DTW',
    48356: 'DTW',
    48357: 'DTW',
    48359: 'DTW',
    48360: 'DTW',
    48361: 'DTW',
    48362: 'DTW',
    48363: 'DTW',
    48366: 'DTW',
    48367: 'DTW',
    48370: 'DTW',
    48371: 'DTW',
    48374: 'DTW',
    48375: 'DTW',
    48376: 'DTW',
    48377: 'DTW',
    48380: 'DTW',
    48381: 'DTW',
    48382: 'DTW',
    48383: 'DTW',
    48386: 'DTW',
    48387: 'DTW',
    48390: 'DTW',
    48391: 'DTW',
    48393: 'DTW',
    48397: 'DTW',
    48398: 'DTW',
    48401: 'DTW',
    48410: 'DTW',
    48411: 'DTW',
    48412: 'DTW',
    48413: 'DTW',
    48414: 'DTW',
    48415: 'DTW',
    48416: 'DTW',
    48417: 'DTW',
    48418: 'DTW',
    48419: 'DTW',
    48420: 'DTW',
    48421: 'DTW',
    48422: 'DTW',
    48423: 'DTW',
    48426: 'DTW',
    48427: 'DTW',
    48428: 'DTW',
    48429: 'DTW',
    48430: 'DTW',
    48432: 'DTW',
    48433: 'DTW',
    48434: 'DTW',
    48435: 'DTW',
    48436: 'DTW',
    48437: 'DTW',
    48438: 'DTW',
    48439: 'DTW',
    48440: 'DTW',
    48441: 'DTW',
    48442: 'DTW',
    48444: 'DTW',
    48445: 'DTW',
    48446: 'DTW',
    48449: 'DTW',
    48450: 'DTW',
    48451: 'DTW',
    48453: 'DTW',
    48454: 'DTW',
    48455: 'DTW',
    48456: 'DTW',
    48457: 'DTW',
    48458: 'DTW',
    48460: 'DTW',
    48461: 'DTW',
    48462: 'DTW',
    48463: 'DTW',
    48464: 'DTW',
    48465: 'DTW',
    48466: 'DTW',
    48467: 'DTW',
    48468: 'DTW',
    48469: 'DTW',
    48470: 'DTW',
    48471: 'DTW',
    48472: 'DTW',
    48473: 'DTW',
    48475: 'DTW',
    48476: 'DTW',
    48480: 'DTW',
    48501: 'DTW',
    48502: 'DTW',
    48503: 'DTW',
    48504: 'DTW',
    48505: 'DTW',
    48506: 'DTW',
    48507: 'DTW',
    48509: 'DTW',
    48519: 'DTW',
    48529: 'DTW',
    48531: 'DTW',
    48532: 'DTW',
    48550: 'DTW',
    48551: 'DTW',
    48552: 'DTW',
    48553: 'DTW',
    48554: 'DTW',
    48555: 'DTW',
    48556: 'DTW',
    48557: 'DTW',
    48559: 'DTW',
    48601: 'DTW',
    48602: 'DTW',
    48603: 'DTW',
    48604: 'DTW',
    48605: 'DTW',
    48606: 'DTW',
    48607: 'DTW',
    48608: 'DTW',
    48609: 'DTW',
    48610: 'TVC',
    48611: 'DTW',
    48612: 'LAN',
    48613: 'DTW',
    48614: 'LAN',
    48615: 'LAN',
    48616: 'DTW',
    48617: 'LAN',
    48618: 'LAN',
    48619: 'TVC',
    48620: 'LAN',
    48621: 'TVC',
    48622: 'LAN',
    48623: 'DTW',
    48624: 'LAN',
    48625: 'LAN',
    48626: 'LAN',
    48627: 'TVC',
    48628: 'LAN',
    48629: 'TVC',
    48630: 'TVC',
    48631: 'DTW',
    48632: 'LAN',
    48633: 'LAN',
    48634: 'DTW',
    48635: 'TVC',
    48636: 'TVC',
    48637: 'LAN',
    48638: 'DTW',
    48640: 'LAN',
    48641: 'LAN',
    48642: 'LAN',
    48647: 'TVC',
    48649: 'LAN',
    48650: 'DTW',
    48651: 'TVC',
    48652: 'LAN',
    48653: 'TVC',
    48654: 'TVC',
    48655: 'LAN',
    48656: 'TVC',
    48657: 'LAN',
    48658: 'DTW',
    48659: 'DTW',
    48661: 'TVC',
    48662: 'LAN',
    48663: 'DTW',
    48667: 'LAN',
    48670: 'LAN',
    48674: 'LAN',
    48686: 'LAN',
    48701: 'DTW',
    48703: 'DTW',
    48705: 'TVC',
    48706: 'DTW',
    48707: 'DTW',
    48708: 'DTW',
    48710: 'DTW',
    48720: 'DTW',
    48721: 'TVC',
    48722: 'DTW',
    48723: 'DTW',
    48724: 'DTW',
    48725: 'DTW',
    48726: 'DTW',
    48727: 'DTW',
    48728: 'TVC',
    48729: 'DTW',
    48730: 'TVC',
    48731: 'DTW',
    48732: 'DTW',
    48733: 'DTW',
    48734: 'DTW',
    48735: 'DTW',
    48736: 'DTW',
    48737: 'TVC',
    48738: 'TVC',
    48739: 'TVC',
    48740: 'TVC',
    48741: 'DTW',
    48742: 'TVC',
    48743: 'TVC',
    48744: 'DTW',
    48745: 'TVC',
    48746: 'DTW',
    48747: 'DTW',
    48748: 'TVC',
    48749: 'DTW',
    48750: 'TVC',
    48754: 'DTW',
    48755: 'DTW',
    48756: 'TVC',
    48757: 'DTW',
    48758: 'DTW',
    48759: 'DTW',
    48760: 'DTW',
    48761: 'TVC',
    48762: 'TVC',
    48763: 'TVC',
    48764: 'TVC',
    48765: 'TVC',
    48766: 'TVC',
    48767: 'DTW',
    48768: 'DTW',
    48769: 'DTW',
    48770: 'TVC',
    48787: 'DTW',
    48801: 'LAN',
    48802: 'LAN',
    48804: 'LAN',
    48805: 'DTW',
    48806: 'LAN',
    48807: 'LAN',
    48808: 'DTW',
    48809: 'GRR',
    48811: 'LAN',
    48812: 'LAN',
    48813: 'DTW',
    48815: 'GRR',
    48816: 'GRR',
    48817: 'DTW',
    48818: 'LAN',
    48819: 'DTW',
    48820: 'DTW',
    48821: 'DTW',
    48822: 'DTW',
    48823: 'DTW',
    48824: 'DTW',
    48825: 'DTW',
    48826: 'DTW',
    48827: 'DTW',
    48829: 'LAN',
    48830: 'LAN',
    48831: 'LAN',
    48832: 'LAN',
    48833: 'LAN',
    48834: 'LAN',
    48835: 'LAN',
    48836: 'DTW',
    48837: 'DTW',
    48838: 'GRR',
    48840: 'DTW',
    48841: 'LAN',
    48842: 'DTW',
    48843: 'DTW',
    48844: 'DTW',
    48845: 'LAN',
    48846: 'LAN',
    48847: 'LAN',
    48848: 'DTW',
    48849: 'GRR',
    48850: 'GRR',
    48851: 'LAN',
    48852: 'LAN',
    48853: 'LAN',
    48854: 'DTW',
    48855: 'DTW',
    48856: 'LAN',
    48857: 'DTW',
    48858: 'LAN',
    48859: 'LAN',
    48860: 'LAN',
    48861: 'DTW',
    48862: 'LAN',
    48863: 'DTW',
    48864: 'DTW',
    48865: 'GRR',
    48866: 'LAN',
    48867: 'LAN',
    48870: 'LAN',
    48871: 'LAN',
    48872: 'DTW',
    48873: 'LAN',
    48874: 'LAN',
    48875: 'DTW',
    48876: 'DTW',
    48877: 'LAN',
    48878: 'LAN',
    48879: 'LAN',
    48880: 'LAN',
    48881: 'GRR',
    48882: 'DTW',
    48883: 'LAN',
    48884: 'LAN',
    48885: 'GRR',
    48886: 'GRR',
    48887: 'GRR',
    48888: 'GRR',
    48889: 'LAN',
    48890: 'DTW',
    48891: 'LAN',
    48892: 'DTW',
    48893: 'LAN',
    48894: 'DTW',
    48895: 'DTW',
    48896: 'LAN',
    48897: 'GRR',
    48901: 'DTW',
    48906: 'DTW',
    48908: 'DTW',
    48909: 'DTW',
    48910: 'DTW',
    48911: 'DTW',
    48912: 'DTW',
    48913: 'DTW',
    48915: 'DTW',
    48916: 'DTW',
    48917: 'DTW',
    48918: 'DTW',
    48919: 'DTW',
    48921: 'DTW',
    48922: 'DTW',
    48924: 'DTW',
    48929: 'DTW',
    48930: 'DTW',
    48933: 'DTW',
    48937: 'DTW',
    48950: 'DTW',
    48951: 'DTW',
    48956: 'DTW',
    48980: 'DTW',
    49001: 'GRR',
    49002: 'SBN',
    49003: 'GRR',
    49004: 'GRR',
    49005: 'GRR',
    49006: 'GRR',
    49007: 'GRR',
    49008: 'GRR',
    49009: 'GRR',
    49010: 'GRR',
    49011: 'SBN',
    49012: 'GRR',
    49013: 'GRR',
    49014: 'GRR',
    49015: 'GRR',
    49016: 'GRR',
    49017: 'GRR',
    49018: 'GRR',
    49019: 'GRR',
    49020: 'GRR',
    49021: 'DTW',
    49022: 'SBN',
    49023: 'SBN',
    49024: 'SBN',
    49026: 'GRR',
    49027: 'GRR',
    49028: 'SBN',
    49029: 'SBN',
    49030: 'SBN',
    49031: 'SBN',
    49032: 'SBN',
    49033: 'SBN',
    49034: 'GRR',
    49035: 'GRR',
    49036: 'SBN',
    49037: 'GRR',
    49038: 'SBN',
    49039: 'SBN',
    49040: 'SBN',
    49041: 'GRR',
    49042: 'SBN',
    49043: 'GRR',
    49045: 'SBN',
    49046: 'GRR',
    49047: 'SBN',
    49048: 'GRR',
    49050: 'GRR',
    49051: 'SBN',
    49052: 'SBN',
    49053: 'GRR',
    49055: 'GRR',
    49056: 'GRR',
    49057: 'SBN',
    49058: 'GRR',
    49060: 'GRR',
    49061: 'SBN',
    49062: 'GRR',
    49063: 'GRR',
    49064: 'SBN',
    49065: 'SBN',
    49066: 'SBN',
    49067: 'SBN',
    49068: 'DTW',
    49069: 'DTW',
    49070: 'GRR',
    49071: 'GRR',
    49072: 'SBN',
    49073: 'GRR',
    49074: 'GRR',
    49075: 'SBN',
    49076: 'DTW',
    49077: 'GRR',
    49078: 'GRR',
    49079: 'GRR',
    49080: 'GRR',
    49081: 'SBN',
    49082: 'SBN',
    49083: 'GRR',
    49084: 'GRR',
    49085: 'SBN',
    49087: 'SBN',
    49088: 'SBN',
    49089: 'SBN',
    49090: 'GRR',
    49091: 'SBN',
    49092: 'SBN',
    49093: 'SBN',
    49094: 'SBN',
    49095: 'SBN',
    49096: 'DTW',
    49097: 'SBN',
    49098: 'SBN',
    49099: 'SBN',
    49101: 'SBN',
    49102: 'SBN',
    49103: 'SBN',
    49104: 'SBN',
    49106: 'SBN',
    49107: 'SBN',
    49111: 'SBN',
    49112: 'SBN',
    49113: 'SBN',
    49115: 'SBN',
    49116: 'SBN',
    49117: 'SBN',
    49119: 'SBN',
    49120: 'SBN',
    49121: 'SBN',
    49125: 'SBN',
    49126: 'SBN',
    49127: 'SBN',
    49128: 'SBN',
    49129: 'SBN',
    49130: 'SBN',
    49201: 'DTW',
    49202: 'DTW',
    49203: 'DTW',
    49204: 'DTW',
    49220: 'SBN',
    49221: 'SBN',
    49224: 'DTW',
    49227: 'SBN',
    49228: 'SBN',
    49229: 'SBN',
    49230: 'SBN',
    49232: 'SBN',
    49233: 'SBN',
    49234: 'SBN',
    49235: 'SBN',
    49236: 'DTW',
    49237: 'SBN',
    49238: 'SBN',
    49239: 'SBN',
    49240: 'DTW',
    49241: 'SBN',
    49242: 'SBN',
    49245: 'SBN',
    49246: 'SBN',
    49247: 'SBN',
    49248: 'SBN',
    49249: 'SBN',
    49250: 'SBN',
    49251: 'DTW',
    49252: 'SBN',
    49253: 'SBN',
    49254: 'SBN',
    49255: 'SBN',
    49256: 'SBN',
    49257: 'SBN',
    49258: 'SBN',
    49259: 'DTW',
    49261: 'SBN',
    49262: 'SBN',
    49263: 'SBN',
    49264: 'DTW',
    49265: 'SBN',
    49266: 'SBN',
    49267: 'SBN',
    49268: 'SBN',
    49269: 'DTW',
    49270: 'DTW',
    49271: 'SBN',
    49272: 'DTW',
    49274: 'SBN',
    49276: 'SBN',
    49277: 'DTW',
    49279: 'DTW',
    49281: 'SBN',
    49282: 'SBN',
    49283: 'SBN',
    49284: 'DTW',
    49285: 'DTW',
    49286: 'DTW',
    49287: 'DTW',
    49288: 'SBN',
    49289: 'SBN',
    49301: 'GRR',
    49302: 'GRR',
    49303: 'GRR',
    49304: 'GRR',
    49305: 'GRR',
    49306: 'GRR',
    49307: 'GRR',
    49309: 'GRR',
    49310: 'LAN',
    49311: 'GRR',
    49312: 'GRR',
    49314: 'GRR',
    49315: 'GRR',
    49316: 'GRR',
    49317: 'GRR',
    49318: 'GRR',
    49319: 'GRR',
    49320: 'GRR',
    49321: 'GRR',
    49322: 'GRR',
    49323: 'GRR',
    49325: 'GRR',
    49326: 'GRR',
    49327: 'GRR',
    49328: 'GRR',
    49329: 'GRR',
    49330: 'GRR',
    49331: 'GRR',
    49332: 'GRR',
    49333: 'GRR',
    49335: 'GRR',
    49336: 'GRR',
    49337: 'GRR',
    49338: 'GRR',
    49339: 'GRR',
    49340: 'GRR',
    49341: 'GRR',
    49342: 'GRR',
    49343: 'GRR',
    49344: 'GRR',
    49345: 'GRR',
    49346: 'GRR',
    49347: 'GRR',
    49348: 'GRR',
    49349: 'GRR',
    49351: 'GRR',
    49355: 'GRR',
    49356: 'GRR',
    49357: 'GRR',
    49401: 'GRR',
    49402: 'GRR',
    49403: 'GRR',
    49404: 'GRR',
    49405: 'GRR',
    49406: 'GRR',
    49408: 'GRR',
    49409: 'GRR',
    49410: 'GRR',
    49411: 'TVC',
    49412: 'GRR',
    49413: 'GRR',
    49415: 'GRR',
    49416: 'GRR',
    49417: 'GRR',
    49418: 'GRR',
    49419: 'GRR',
    49420: 'GRR',
    49421: 'GRR',
    49422: 'GRR',
    49423: 'GRR',
    49424: 'GRR',
    49425: 'GRR',
    49426: 'GRR',
    49427: 'GRR',
    49428: 'GRR',
    49429: 'GRR',
    49430: 'GRR',
    49431: 'GRR',
    49434: 'GRR',
    49435: 'GRR',
    49436: 'GRR',
    49437: 'GRR',
    49440: 'GRR',
    49441: 'GRR',
    49442: 'GRR',
    49443: 'GRR',
    49444: 'GRR',
    49445: 'GRR',
    49446: 'GRR',
    49448: 'GRR',
    49449: 'GRR',
    49450: 'GRR',
    49451: 'GRR',
    49452: 'GRR',
    49453: 'GRR',
    49454: 'GRR',
    49455: 'GRR',
    49456: 'GRR',
    49457: 'GRR',
    49458: 'GRR',
    49459: 'GRR',
    49460: 'GRR',
    49461: 'GRR',
    49463: 'GRR',
    49464: 'GRR',
    49468: 'GRR',
    49501: 'GRR',
    49502: 'GRR',
    49503: 'GRR',
    49504: 'GRR',
    49505: 'GRR',
    49506: 'GRR',
    49507: 'GRR',
    49508: 'GRR',
    49509: 'GRR',
    49510: 'GRR',
    49512: 'GRR',
    49514: 'GRR',
    49515: 'GRR',
    49516: 'GRR',
    49518: 'GRR',
    49519: 'GRR',
    49523: 'GRR',
    49525: 'GRR',
    49528: 'GRR',
    49530: 'GRR',
    49534: 'GRR',
    49544: 'GRR',
    49546: 'GRR',
    49548: 'GRR',
    49550: 'GRR',
    49555: 'GRR',
    49560: 'GRR',
    49588: 'GRR',
    49599: 'GRR',
    49601: 'TVC',
    49610: 'TVC',
    49611: 'TVC',
    49612: 'TVC',
    49613: 'TVC',
    49614: 'TVC',
    49615: 'TVC',
    49616: 'TVC',
    49617: 'TVC',
    49618: 'TVC',
    49619: 'TVC',
    49620: 'TVC',
    49621: 'TVC',
    49622: 'TVC',
    49623: 'GRR',
    49625: 'TVC',
    49626: 'TVC',
    49627: 'TVC',
    49628: 'TVC',
    49629: 'TVC',
    49630: 'TVC',
    49631: 'GRR',
    49632: 'TVC',
    49633: 'TVC',
    49634: 'TVC',
    49635: 'TVC',
    49636: 'TVC',
    49637: 'TVC',
    49638: 'TVC',
    49639: 'GRR',
    49640: 'TVC',
    49642: 'GRR',
    49643: 'TVC',
    49644: 'TVC',
    49645: 'TVC',
    49646: 'TVC',
    49648: 'TVC',
    49649: 'TVC',
    49650: 'TVC',
    49651: 'TVC',
    49653: 'TVC',
    49654: 'TVC',
    49655: 'GRR',
    49656: 'TVC',
    49657: 'TVC',
    49659: 'TVC',
    49660: 'TVC',
    49663: 'TVC',
    49664: 'TVC',
    49665: 'TVC',
    49666: 'TVC',
    49667: 'TVC',
    49668: 'TVC',
    49670: 'TVC',
    49673: 'TVC',
    49674: 'TVC',
    49675: 'TVC',
    49676: 'TVC',
    49677: 'GRR',
    49679: 'GRR',
    49680: 'TVC',
    49682: 'TVC',
    49683: 'TVC',
    49684: 'TVC',
    49685: 'TVC',
    49686: 'TVC',
    49688: 'TVC',
    49689: 'TVC',
    49690: 'TVC',
    49696: 'TVC',
    49701: 'TVC',
    49705: 'TVC',
    49706: 'TVC',
    49707: 'TVC',
    49709: 'TVC',
    49710: 'TVC',
    49711: 'TVC',
    49712: 'TVC',
    49713: 'TVC',
    49715: 'TVC',
    49716: 'TVC',
    49717: 'TVC',
    49718: 'TVC',
    49719: 'TVC',
    49720: 'TVC',
    49721: 'TVC',
    49722: 'TVC',
    49723: 'TVC',
    49724: 'TVC',
    49725: 'TVC',
    49726: 'TVC',
    49727: 'TVC',
    49728: 'TVC',
    49729: 'TVC',
    49730: 'TVC',
    49733: 'TVC',
    49734: 'TVC',
    49735: 'TVC',
    49736: 'TVC',
    49737: 'TVC',
    49738: 'TVC',
    49739: 'TVC',
    49740: 'TVC',
    49743: 'TVC',
    49744: 'TVC',
    49745: 'TVC',
    49746: 'TVC',
    49747: 'TVC',
    49748: 'TVC',
    49749: 'TVC',
    49751: 'TVC',
    49752: 'TVC',
    49753: 'TVC',
    49755: 'TVC',
    49756: 'TVC',
    49757: 'TVC',
    49759: 'TVC',
    49760: 'TVC',
    49761: 'TVC',
    49762: 'TVC',
    49764: 'TVC',
    49765: 'TVC',
    49766: 'TVC',
    49768: 'TVC',
    49769: 'TVC',
    49770: 'TVC',
    49774: 'TVC',
    49775: 'TVC',
    49776: 'TVC',
    49777: 'TVC',
    49779: 'TVC',
    49780: 'TVC',
    49781: 'TVC',
    49782: 'TVC',
    49783: 'TVC',
    49784: 'TVC',
    49785: 'TVC',
    49786: 'TVC',
    49788: 'TVC',
    49790: 'TVC',
    49791: 'TVC',
    49792: 'TVC',
    49793: 'TVC',
    49795: 'TVC',
    49796: 'TVC',
    49797: 'TVC',
    49799: 'TVC',
    49801: 'GRB',
    49802: 'GRB',
    49805: 'GRB',
    49806: 'GRB',
    49807: 'GRB',
    49808: 'GRB',
    49812: 'GRB',
    49814: 'GRB',
    49815: 'GRB',
    49816: 'GRB',
    49817: 'GRB',
    49818: 'GRB',
    49819: 'GRB',
    49820: 'TVC',
    49821: 'GRB',
    49822: 'GRB',
    49825: 'GRB',
    49826: 'GRB',
    49827: 'TVC',
    49829: 'GRB',
    49831: 'GRB',
    49833: 'GRB',
    49834: 'GRB',
    49835: 'GRB',
    49836: 'TVC',
    49837: 'GRB',
    49838: 'TVC',
    49839: 'TVC',
    49840: 'TVC',
    49841: 'GRB',
    49845: 'GRB',
    49847: 'GRB',
    49848: 'GRB',
    49849: 'GRB',
    49852: 'GRB',
    49853: 'TVC',
    49854: 'GRB',
    49855: 'GRB',
    49858: 'GRB',
    49861: 'GRB',
    49862: 'GRB',
    49863: 'GRB',
    49864: 'GRB',
    49865: 'GRB',
    49866: 'GRB',
    49868: 'TVC',
    49870: 'GRB',
    49871: 'GRB',
    49872: 'GRB',
    49873: 'GRB',
    49874: 'GRB',
    49876: 'GRB',
    49877: 'GRB',
    49878: 'GRB',
    49879: 'GRB',
    49880: 'GRB',
    49881: 'GRB',
    49883: 'TVC',
    49884: 'GRB',
    49885: 'GRB',
    49886: 'GRB',
    49887: 'GRB',
    49891: 'GRB',
    49892: 'GRB',
    49893: 'GRB',
    49894: 'GRB',
    49895: 'GRB',
    49896: 'GRB',
    49901: 'GRB',
    49902: 'GRB',
    49903: 'GRB',
    49905: 'GRB',
    49908: 'GRB',
    49910: 'GRB',
    49911: 'GRB',
    49912: 'GRB',
    49913: 'GRB',
    49915: 'GRB',
    49916: 'GRB',
    49917: 'GRB',
    49918: 'GRB',
    49919: 'GRB',
    49920: 'GRB',
    49921: 'GRB',
    49922: 'GRB',
    49925: 'GRB',
    49927: 'GRB',
    49929: 'GRB',
    49930: 'GRB',
    49931: 'GRB',
    49934: 'GRB',
    49935: 'GRB',
    49938: 'GRB',
    49942: 'GRB',
    49945: 'GRB',
    49946: 'GRB',
    49947: 'GRB',
    49948: 'GRB',
    49950: 'GRB',
    49952: 'GRB',
    49953: 'GRB',
    49955: 'GRB',
    49958: 'GRB',
    49959: 'GRB',
    49960: 'GRB',
    49961: 'GRB',
    49962: 'GRB',
    49963: 'GRB',
    49964: 'GRB',
    49965: 'GRB',
    49967: 'GRB',
    49968: 'GRB',
    49969: 'GRB',
    49970: 'GRB',
    49971: 'GRB',
    50001: 'DSM',
    50002: 'DSM',
    50003: 'DSM',
    50005: 'ALO',
    50006: 'ALO',
    50007: 'DSM',
    50008: 'DSM',
    50009: 'DSM',
    50010: 'ALO',
    50011: 'DSM',
    50012: 'DSM',
    50013: 'DSM',
    50014: 'DSM',
    50015: 'DSM',
    50020: 'DSM',
    50021: 'DSM',
    50022: 'OMA',
    50023: 'DSM',
    50025: 'DSM',
    50026: 'DSM',
    50027: 'CID',
    50028: 'DSM',
    50029: 'DSM',
    50031: 'ALO',
    50032: 'DSM',
    50033: 'DSM',
    50034: 'ALO',
    50035: 'DSM',
    50036: 'ALO',
    50037: 'ALO',
    50038: 'DSM',
    50039: 'DSM',
    50040: 'ALO',
    50041: 'ALO',
    50042: 'DSM',
    50043: 'DSM',
    50044: 'DSM',
    50046: 'DSM',
    50047: 'DSM',
    50048: 'DSM',
    50049: 'DSM',
    50050: 'ALO',
    50051: 'ALO',
    50052: 'DSM',
    50054: 'DSM',
    50055: 'DSM',
    50056: 'DSM',
    50057: 'DSM',
    50058: 'DSM',
    50059: 'DSM',
    50060: 'DSM',
    50061: 'DSM',
    50062: 'DSM',
    50063: 'DSM',
    50064: 'ALO',
    50065: 'DSM',
    50066: 'DSM',
    50067: 'DSM',
    50068: 'DSM',
    50069: 'DSM',
    50070: 'DSM',
    50071: 'ALO',
    50072: 'DSM',
    50073: 'DSM',
    50074: 'DSM',
    50075: 'ALO',
    50076: 'DSM',
    50078: 'DSM',
    50099: 'ALO',
    50101: 'ALO',
    50102: 'ALO',
    50103: 'DSM',
    50104: 'CID',
    50105: 'ALO',
    50106: 'DSM',
    50107: 'ALO',
    50108: 'DSM',
    50109: 'DSM',
    50110: 'DSM',
    50111: 'DSM',
    50112: 'DSM',
    50115: 'DSM',
    50116: 'DSM',
    50117: 'DSM',
    50118: 'DSM',
    50119: 'DSM',
    50120: 'DSM',
    50122: 'ALO',
    50123: 'DSM',
    50124: 'DSM',
    50125: 'DSM',
    50126: 'ALO',
    50127: 'DSM',
    50128: 'DSM',
    50129: 'ALO',
    50130: 'ALO',
    50131: 'DSM',
    50132: 'ALO',
    50133: 'DSM',
    50134: 'DSM',
    50135: 'DSM',
    50136: 'CID',
    50137: 'DSM',
    50138: 'DSM',
    50139: 'DSM',
    50140: 'DSM',
    50141: 'DSM',
    50142: 'DSM',
    50143: 'DSM',
    50144: 'DSM',
    50145: 'DSM',
    50146: 'DSM',
    50147: 'DSM',
    50148: 'ALO',
    50149: 'DSM',
    50150: 'DSM',
    50151: 'DSM',
    50152: 'DSM',
    50153: 'DSM',
    50154: 'ALO',
    50155: 'DSM',
    50156: 'DSM',
    50157: 'DSM',
    50158: 'ALO',
    50160: 'DSM',
    50161: 'DSM',
    50162: 'DSM',
    50163: 'DSM',
    50164: 'DSM',
    50165: 'DSM',
    50166: 'DSM',
    50167: 'DSM',
    50168: 'DSM',
    50169: 'DSM',
    50170: 'DSM',
    50171: 'DSM',
    50173: 'DSM',
    50174: 'DSM',
    50201: 'DSM',
    50206: 'ALO',
    50207: 'DSM',
    50208: 'DSM',
    50210: 'DSM',
    50211: 'DSM',
    50212: 'ALO',
    50213: 'DSM',
    50214: 'DSM',
    50216: 'DSM',
    50217: 'ALO',
    50218: 'DSM',
    50219: 'DSM',
    50220: 'DSM',
    50222: 'DSM',
    50223: 'ALO',
    50225: 'DSM',
    50226: 'DSM',
    50227: 'ALO',
    50228: 'DSM',
    50229: 'DSM',
    50230: 'ALO',
    50231: 'ALO',
    50232: 'DSM',
    50233: 'DSM',
    50234: 'DSM',
    50235: 'DSM',
    50236: 'ALO',
    50237: 'DSM',
    50238: 'DSM',
    50239: 'ALO',
    50240: 'DSM',
    50241: 'DSM',
    50242: 'DSM',
    50243: 'DSM',
    50244: 'DSM',
    50246: 'ALO',
    50247: 'DSM',
    50248: 'ALO',
    50249: 'ALO',
    50250: 'DSM',
    50251: 'DSM',
    50252: 'DSM',
    50254: 'DSM',
    50255: 'CID',
    50256: 'DSM',
    50257: 'DSM',
    50258: 'ALO',
    50259: 'ALO',
    50261: 'DSM',
    50262: 'DSM',
    50263: 'DSM',
    50264: 'DSM',
    50265: 'DSM',
    50266: 'DSM',
    50268: 'CID',
    50269: 'ALO',
    50271: 'ALO',
    50272: 'DSM',
    50273: 'DSM',
    50274: 'DSM',
    50275: 'DSM',
    50276: 'DSM',
    50277: 'DSM',
    50278: 'ALO',
    50301: 'DSM',
    50302: 'DSM',
    50303: 'DSM',
    50304: 'DSM',
    50305: 'DSM',
    50306: 'DSM',
    50307: 'DSM',
    50308: 'DSM',
    50309: 'DSM',
    50310: 'DSM',
    50311: 'DSM',
    50312: 'DSM',
    50313: 'DSM',
    50314: 'DSM',
    50315: 'DSM',
    50316: 'DSM',
    50317: 'DSM',
    50318: 'DSM',
    50319: 'DSM',
    50320: 'DSM',
    50321: 'DSM',
    50322: 'DSM',
    50323: 'DSM',
    50324: 'DSM',
    50325: 'DSM',
    50327: 'DSM',
    50328: 'DSM',
    50329: 'DSM',
    50330: 'DSM',
    50331: 'DSM',
    50332: 'DSM',
    50333: 'DSM',
    50334: 'DSM',
    50335: 'DSM',
    50336: 'DSM',
    50339: 'DSM',
    50340: 'DSM',
    50347: 'DSM',
    50359: 'DSM',
    50360: 'DSM',
    50361: 'DSM',
    50362: 'DSM',
    50363: 'DSM',
    50364: 'DSM',
    50367: 'DSM',
    50368: 'DSM',
    50369: 'DSM',
    50380: 'DSM',
    50381: 'DSM',
    50391: 'DSM',
    50392: 'DSM',
    50393: 'DSM',
    50394: 'DSM',
    50395: 'DSM',
    50396: 'DSM',
    50397: 'DSM',
    50398: 'DSM',
    50401: 'ALO',
    50402: 'ALO',
    50420: 'ALO',
    50421: 'ALO',
    50423: 'ALO',
    50424: 'ALO',
    50426: 'ALO',
    50427: 'ALO',
    50428: 'ALO',
    50430: 'ALO',
    50431: 'ALO',
    50432: 'ALO',
    50433: 'ALO',
    50434: 'ALO',
    50435: 'ALO',
    50436: 'ALO',
    50438: 'ALO',
    50439: 'ALO',
    50440: 'ALO',
    50441: 'ALO',
    50444: 'ALO',
    50446: 'ALO',
    50447: 'ALO',
    50448: 'ALO',
    50449: 'ALO',
    50450: 'ALO',
    50451: 'ALO',
    50452: 'ALO',
    50453: 'ALO',
    50454: 'ALO',
    50455: 'ALO',
    50456: 'ALO',
    50457: 'ALO',
    50458: 'ALO',
    50459: 'ALO',
    50460: 'ALO',
    50461: 'ALO',
    50464: 'ALO',
    50465: 'ALO',
    50466: 'ALO',
    50467: 'ALO',
    50468: 'ALO',
    50469: 'ALO',
    50470: 'ALO',
    50471: 'ALO',
    50472: 'ALO',
    50473: 'ALO',
    50475: 'ALO',
    50476: 'ALO',
    50477: 'ALO',
    50478: 'ALO',
    50479: 'ALO',
    50480: 'ALO',
    50481: 'ALO',
    50482: 'ALO',
    50483: 'ALO',
    50484: 'ALO',
    50501: 'ALO',
    50510: 'ALO',
    50511: 'ALO',
    50514: 'ALO',
    50515: 'ALO',
    50516: 'ALO',
    50517: 'ALO',
    50518: 'ALO',
    50519: 'ALO',
    50520: 'ALO',
    50521: 'ALO',
    50522: 'ALO',
    50523: 'ALO',
    50524: 'ALO',
    50525: 'ALO',
    50526: 'ALO',
    50527: 'ALO',
    50528: 'ALO',
    50529: 'ALO',
    50530: 'ALO',
    50531: 'ALO',
    50532: 'ALO',
    50533: 'ALO',
    50535: 'OMA',
    50536: 'ALO',
    50538: 'ALO',
    50539: 'ALO',
    50540: 'ALO',
    50541: 'ALO',
    50542: 'ALO',
    50543: 'ALO',
    50544: 'ALO',
    50545: 'ALO',
    50546: 'ALO',
    50548: 'ALO',
    50551: 'ALO',
    50552: 'ALO',
    50554: 'ALO',
    50556: 'ALO',
    50557: 'ALO',
    50558: 'ALO',
    50559: 'ALO',
    50560: 'ALO',
    50561: 'ALO',
    50562: 'ALO',
    50563: 'ALO',
    50565: 'ALO',
    50566: 'ALO',
    50567: 'OMA',
    50568: 'ALO',
    50569: 'ALO',
    50570: 'ALO',
    50571: 'ALO',
    50573: 'ALO',
    50574: 'ALO',
    50575: 'ALO',
    50576: 'ALO',
    50577: 'ALO',
    50578: 'ALO',
    50579: 'ALO',
    50581: 'ALO',
    50582: 'ALO',
    50583: 'ALO',
    50585: 'ALO',
    50586: 'ALO',
    50588: 'ALO',
    50590: 'ALO',
    50591: 'ALO',
    50592: 'ALO',
    50593: 'ALO',
    50594: 'ALO',
    50595: 'ALO',
    50597: 'ALO',
    50598: 'ALO',
    50599: 'ALO',
    50601: 'ALO',
    50602: 'ALO',
    50603: 'ALO',
    50604: 'ALO',
    50605: 'ALO',
    50606: 'ALO',
    50607: 'ALO',
    50608: 'ALO',
    50609: 'ALO',
    50611: 'ALO',
    50612: 'ALO',
    50613: 'ALO',
    50614: 'ALO',
    50616: 'ALO',
    50619: 'ALO',
    50620: 'ALO',
    50621: 'ALO',
    50622: 'ALO',
    50623: 'ALO',
    50624: 'ALO',
    50625: 'ALO',
    50626: 'ALO',
    50627: 'ALO',
    50628: 'ALO',
    50629: 'ALO',
    50630: 'ALO',
    50631: 'ALO',
    50632: 'ALO',
    50633: 'ALO',
    50634: 'ALO',
    50635: 'ALO',
    50636: 'ALO',
    50638: 'ALO',
    50641: 'ALO',
    50642: 'ALO',
    50643: 'ALO',
    50644: 'ALO',
    50645: 'ALO',
    50647: 'ALO',
    50648: 'ALO',
    50649: 'ALO',
    50650: 'ALO',
    50651: 'ALO',
    50652: 'ALO',
    50653: 'ALO',
    50654: 'ALO',
    50655: 'ALO',
    50657: 'ALO',
    50658: 'ALO',
    50659: 'ALO',
    50660: 'ALO',
    50661: 'ALO',
    50662: 'ALO',
    50664: 'ALO',
    50665: 'ALO',
    50666: 'ALO',
    50667: 'ALO',
    50668: 'ALO',
    50669: 'ALO',
    50670: 'ALO',
    50671: 'ALO',
    50672: 'ALO',
    50673: 'ALO',
    50674: 'ALO',
    50675: 'ALO',
    50676: 'ALO',
    50677: 'ALO',
    50680: 'ALO',
    50681: 'ALO',
    50682: 'ALO',
    50701: 'ALO',
    50702: 'ALO',
    50703: 'ALO',
    50704: 'ALO',
    50706: 'ALO',
    50707: 'ALO',
    50801: 'DSM',
    50830: 'DSM',
    50831: 'DSM',
    50833: 'DSM',
    50835: 'DSM',
    50836: 'DSM',
    50837: 'DSM',
    50839: 'DSM',
    50840: 'DSM',
    50841: 'DSM',
    50842: 'DSM',
    50843: 'DSM',
    50845: 'DSM',
    50846: 'DSM',
    50847: 'OMA',
    50848: 'DSM',
    50849: 'DSM',
    50851: 'DSM',
    50853: 'DSM',
    50854: 'DSM',
    50857: 'DSM',
    50858: 'DSM',
    50859: 'DSM',
    50860: 'DSM',
    50861: 'DSM',
    50862: 'DSM',
    50863: 'DSM',
    50864: 'OMA',
    50936: 'DSM',
    50940: 'DSM',
    50947: 'DSM',
    50950: 'DSM',
    50980: 'DSM',
    50981: 'DSM',
    50982: 'DSM',
    50983: 'DSM',
    51001: 'ALO',
    51002: 'ALO',
    51003: 'ALO',
    51004: 'OMA',
    51005: 'ALO',
    51006: 'OMA',
    51007: 'OMA',
    51008: 'ALO',
    51009: 'ALO',
    51010: 'OMA',
    51011: 'ALO',
    51012: 'ALO',
    51014: 'ALO',
    51015: 'OMA',
    51016: 'OMA',
    51018: 'OMA',
    51019: 'OMA',
    51020: 'OMA',
    51022: 'ALO',
    51023: 'ALO',
    51024: 'ALO',
    51025: 'OMA',
    51026: 'OMA',
    51027: 'ALO',
    51028: 'ALO',
    51029: 'ALO',
    51030: 'ALO',
    51031: 'ALO',
    51033: 'ALO',
    51034: 'OMA',
    51035: 'ALO',
    51036: 'ALO',
    51037: 'ALO',
    51038: 'ALO',
    51039: 'OMA',
    51040: 'OMA',
    51041: 'ALO',
    51044: 'OMA',
    51045: 'ALO',
    51046: 'ALO',
    51047: 'ALO',
    51048: 'ALO',
    51049: 'ALO',
    51050: 'ALO',
    51051: 'OMA',
    51052: 'OMA',
    51053: 'OMA',
    51054: 'OMA',
    51055: 'OMA',
    51056: 'OMA',
    51058: 'ALO',
    51060: 'OMA',
    51061: 'ALO',
    51062: 'ALO',
    51063: 'OMA',
    51101: 'ALO',
    51102: 'ALO',
    51103: 'ALO',
    51104: 'ALO',
    51105: 'ALO',
    51106: 'ALO',
    51108: 'ALO',
    51109: 'ALO',
    51111: 'OMA',
    51201: 'ALO',
    51230: 'ALO',
    51231: 'ALO',
    51232: 'ALO',
    51234: 'ALO',
    51235: 'ALO',
    51237: 'ALO',
    51238: 'ALO',
    51239: 'ALO',
    51240: 'ALO',
    51241: 'ALO',
    51242: 'ALO',
    51243: 'ALO',
    51244: 'ALO',
    51245: 'ALO',
    51246: 'ALO',
    51247: 'ALO',
    51248: 'ALO',
    51249: 'ALO',
    51250: 'ALO',
    51301: 'ALO',
    51331: 'ALO',
    51333: 'ALO',
    51334: 'ALO',
    51338: 'ALO',
    51340: 'ALO',
    51341: 'ALO',
    51342: 'ALO',
    51343: 'ALO',
    51344: 'ALO',
    51345: 'ALO',
    51346: 'ALO',
    51347: 'ALO',
    51349: 'ALO',
    51350: 'ALO',
    51351: 'ALO',
    51354: 'ALO',
    51355: 'ALO',
    51357: 'ALO',
    51358: 'ALO',
    51360: 'ALO',
    51363: 'ALO',
    51364: 'ALO',
    51365: 'ALO',
    51366: 'ALO',
    51401: 'ALO',
    51430: 'OMA',
    51431: 'OMA',
    51432: 'OMA',
    51433: 'ALO',
    51436: 'ALO',
    51439: 'OMA',
    51440: 'DSM',
    51441: 'OMA',
    51442: 'OMA',
    51443: 'ALO',
    51444: 'DSM',
    51445: 'OMA',
    51446: 'OMA',
    51447: 'OMA',
    51448: 'OMA',
    51449: 'ALO',
    51450: 'ALO',
    51451: 'ALO',
    51452: 'ALO',
    51453: 'ALO',
    51454: 'OMA',
    51455: 'OMA',
    51458: 'OMA',
    51459: 'ALO',
    51460: 'OMA',
    51461: 'OMA',
    51462: 'ALO',
    51463: 'DSM',
    51465: 'OMA',
    51466: 'OMA',
    51467: 'OMA',
    51501: 'OMA',
    51502: 'OMA',
    51503: 'OMA',
    51510: 'OMA',
    51520: 'OMA',
    51521: 'OMA',
    51523: 'OMA',
    51525: 'OMA',
    51526: 'OMA',
    51527: 'OMA',
    51528: 'OMA',
    51529: 'OMA',
    51530: 'OMA',
    51531: 'OMA',
    51532: 'OMA',
    51533: 'OMA',
    51534: 'OMA',
    51535: 'OMA',
    51536: 'OMA',
    51537: 'OMA',
    51540: 'OMA',
    51541: 'OMA',
    51542: 'OMA',
    51543: 'OMA',
    51544: 'OMA',
    51545: 'OMA',
    51546: 'OMA',
    51548: 'OMA',
    51549: 'OMA',
    51550: 'OMA',
    51551: 'OMA',
    51552: 'OMA',
    51553: 'OMA',
    51554: 'OMA',
    51555: 'OMA',
    51556: 'OMA',
    51557: 'OMA',
    51558: 'OMA',
    51559: 'OMA',
    51560: 'OMA',
    51561: 'OMA',
    51562: 'OMA',
    51563: 'OMA',
    51564: 'OMA',
    51565: 'OMA',
    51566: 'OMA',
    51570: 'OMA',
    51571: 'OMA',
    51572: 'OMA',
    51573: 'OMA',
    51575: 'OMA',
    51576: 'OMA',
    51577: 'OMA',
    51578: 'OMA',
    51579: 'OMA',
    51591: 'OMA',
    51593: 'OMA',
    51601: 'OMA',
    51602: 'OMA',
    51603: 'OMA',
    51630: 'OMA',
    51631: 'OMA',
    51632: 'OMA',
    51636: 'OMA',
    51637: 'OMA',
    51638: 'OMA',
    51639: 'OMA',
    51640: 'OMA',
    51645: 'OMA',
    51646: 'DSM',
    51647: 'OMA',
    51648: 'OMA',
    51649: 'OMA',
    51650: 'OMA',
    51651: 'OMA',
    51652: 'OMA',
    51653: 'OMA',
    51654: 'OMA',
    51656: 'OMA',
    52001: 'DBQ',
    52002: 'DBQ',
    52003: 'DBQ',
    52004: 'DBQ',
    52030: 'DBQ',
    52031: 'DBQ',
    52032: 'DBQ',
    52033: 'DBQ',
    52035: 'DBQ',
    52036: 'ALO',
    52037: 'DBQ',
    52038: 'ALO',
    52039: 'DBQ',
    52040: 'ALO',
    52041: 'ALO',
    52042: 'ALO',
    52043: 'ALO',
    52044: 'ALO',
    52045: 'DBQ',
    52046: 'DBQ',
    52047: 'ALO',
    52048: 'ALO',
    52049: 'ALO',
    52050: 'ALO',
    52052: 'ALO',
    52053: 'DBQ',
    52054: 'DBQ',
    52056: 'DBQ',
    52057: 'ALO',
    52060: 'DBQ',
    52064: 'DBQ',
    52065: 'DBQ',
    52066: 'MSN',
    52068: 'DBQ',
    52069: 'DBQ',
    52070: 'DBQ',
    52071: 'DBQ',
    52072: 'ALO',
    52073: 'DBQ',
    52074: 'DBQ',
    52075: 'DBQ',
    52076: 'ALO',
    52077: 'ALO',
    52078: 'DBQ',
    52079: 'DBQ',
    52099: 'DBQ',
    52101: 'ALO',
    52132: 'ALO',
    52133: 'ALO',
    52134: 'ALO',
    52135: 'ALO',
    52136: 'ALO',
    52140: 'ALO',
    52141: 'ALO',
    52142: 'ALO',
    52144: 'ALO',
    52146: 'ALO',
    52147: 'ALO',
    52149: 'ALO',
    52151: 'ALO',
    52154: 'ALO',
    52155: 'ALO',
    52156: 'ALO',
    52157: 'ALO',
    52158: 'ALO',
    52159: 'ALO',
    52160: 'ALO',
    52161: 'ALO',
    52162: 'ALO',
    52163: 'ALO',
    52164: 'ALO',
    52165: 'ALO',
    52166: 'ALO',
    52168: 'ALO',
    52169: 'ALO',
    52170: 'ALO',
    52171: 'ALO',
    52172: 'ALO',
    52175: 'ALO',
    52201: 'CID',
    52202: 'ALO',
    52203: 'CID',
    52204: 'CID',
    52205: 'ALO',
    52206: 'CID',
    52207: 'DBQ',
    52208: 'CID',
    52209: 'CID',
    52210: 'ALO',
    52211: 'CID',
    52212: 'DBQ',
    52213: 'ALO',
    52214: 'ALO',
    52215: 'CID',
    52216: 'MLI',
    52217: 'CID',
    52218: 'ALO',
    52219: 'ALO',
    52220: 'CID',
    52221: 'CID',
    52222: 'CID',
    52223: 'ALO',
    52224: 'ALO',
    52225: 'CID',
    52227: 'CID',
    52228: 'CID',
    52229: 'ALO',
    52231: 'CID',
    52232: 'CID',
    52233: 'CID',
    52235: 'CID',
    52236: 'CID',
    52237: 'ALO',
    52240: 'CID',
    52241: 'CID',
    52242: 'CID',
    52243: 'CID',
    52244: 'CID',
    52245: 'CID',
    52246: 'CID',
    52247: 'CID',
    52248: 'CID',
    52249: 'CID',
    52251: 'CID',
    52252: 'ALO',
    52253: 'CID',
    52254: 'DBQ',
    52255: 'MLI',
    52257: 'CID',
    52301: 'CID',
    52302: 'CID',
    52305: 'CID',
    52306: 'CID',
    52307: 'CID',
    52308: 'CID',
    52309: 'DBQ',
    52310: 'ALO',
    52312: 'CID',
    52313: 'ALO',
    52314: 'CID',
    52315: 'CID',
    52316: 'CID',
    52317: 'CID',
    52318: 'CID',
    52319: 'CID',
    52320: 'CID',
    52321: 'DBQ',
    52322: 'CID',
    52323: 'DBQ',
    52324: 'CID',
    52325: 'CID',
    52326: 'ALO',
    52327: 'CID',
    52328: 'CID',
    52329: 'ALO',
    52330: 'ALO',
    52332: 'ALO',
    52333: 'CID',
    52334: 'CID',
    52335: 'CID',
    52336: 'CID',
    52337: 'CID',
    52338: 'CID',
    52339: 'DSM',
    52340: 'CID',
    52341: 'ALO',
    52342: 'ALO',
    52344: 'ALO',
    52345: 'ALO',
    52346: 'CID',
    52347: 'CID',
    52348: 'CID',
    52349: 'ALO',
    52350: 'CID',
    52351: 'CID',
    52352: 'ALO',
    52353: 'CID',
    52354: 'CID',
    52355: 'CID',
    52356: 'CID',
    52358: 'CID',
    52359: 'CID',
    52361: 'CID',
    52362: 'DBQ',
    52401: 'CID',
    52402: 'CID',
    52403: 'CID',
    52404: 'CID',
    52405: 'CID',
    52406: 'CID',
    52407: 'CID',
    52408: 'CID',
    52409: 'CID',
    52410: 'CID',
    52411: 'CID',
    52497: 'CID',
    52498: 'CID',
    52499: 'CID',
    52501: 'CID',
    52530: 'CID',
    52531: 'DSM',
    52533: 'CID',
    52534: 'DSM',
    52535: 'CID',
    52536: 'DSM',
    52537: 'CID',
    52538: 'CID',
    52540: 'CID',
    52542: 'CID',
    52543: 'DSM',
    52544: 'DSM',
    52548: 'DSM',
    52549: 'DSM',
    52550: 'CID',
    52551: 'CID',
    52552: 'DSM',
    52553: 'DSM',
    52554: 'CID',
    52555: 'DSM',
    52556: 'CID',
    52557: 'CID',
    52560: 'CID',
    52561: 'CID',
    52562: 'CID',
    52563: 'CID',
    52565: 'CID',
    52566: 'CID',
    52567: 'CID',
    52568: 'CID',
    52569: 'DSM',
    52570: 'CID',
    52571: 'DSM',
    52572: 'DSM',
    52573: 'CID',
    52574: 'DSM',
    52576: 'CID',
    52577: 'DSM',
    52580: 'CID',
    52581: 'DSM',
    52583: 'DSM',
    52584: 'CID',
    52585: 'CID',
    52586: 'CID',
    52588: 'CID',
    52590: 'DSM',
    52591: 'CID',
    52593: 'DSM',
    52594: 'DSM',
    52595: 'DSM',
    52601: 'CID',
    52619: 'STL',
    52620: 'CID',
    52621: 'CID',
    52623: 'CID',
    52624: 'CID',
    52625: 'CID',
    52626: 'CID',
    52627: 'CID',
    52630: 'CID',
    52631: 'CID',
    52632: 'STL',
    52635: 'CID',
    52637: 'CID',
    52638: 'CID',
    52639: 'STL',
    52640: 'CID',
    52641: 'CID',
    52642: 'CID',
    52644: 'CID',
    52645: 'CID',
    52646: 'MLI',
    52647: 'CID',
    52648: 'CID',
    52649: 'CID',
    52650: 'CID',
    52651: 'CID',
    52652: 'CID',
    52653: 'CID',
    52654: 'CID',
    52655: 'CID',
    52656: 'CID',
    52657: 'CID',
    52658: 'CID',
    52659: 'CID',
    52660: 'CID',
    52701: 'DBQ',
    52720: 'CID',
    52721: 'MLI',
    52722: 'MLI',
    52726: 'MLI',
    52727: 'DBQ',
    52728: 'MLI',
    52729: 'MLI',
    52730: 'MLI',
    52731: 'DBQ',
    52732: 'MLI',
    52733: 'MLI',
    52734: 'MLI',
    52736: 'MLI',
    52737: 'CID',
    52738: 'CID',
    52739: 'CID',
    52742: 'MLI',
    52745: 'MLI',
    52746: 'MLI',
    52747: 'MLI',
    52748: 'MLI',
    52749: 'CID',
    52750: 'MLI',
    52751: 'MLI',
    52752: 'CID',
    52753: 'MLI',
    52754: 'CID',
    52755: 'CID',
    52756: 'MLI',
    52757: 'MLI',
    52758: 'MLI',
    52759: 'MLI',
    52760: 'MLI',
    52761: 'MLI',
    52765: 'MLI',
    52766: 'CID',
    52767: 'MLI',
    52768: 'MLI',
    52769: 'MLI',
    52771: 'MLI',
    52772: 'CID',
    52773: 'MLI',
    52774: 'MLI',
    52776: 'CID',
    52777: 'MLI',
    52778: 'MLI',
    52801: 'MLI',
    52802: 'MLI',
    52803: 'MLI',
    52804: 'MLI',
    52805: 'MLI',
    52806: 'MLI',
    52807: 'MLI',
    52808: 'MLI',
    52809: 'MLI',
    53001: 'MKE',
    53002: 'MKE',
    53003: 'MKE',
    53004: 'MKE',
    53005: 'MKE',
    53006: 'MKE',
    53007: 'MKE',
    53008: 'MKE',
    53010: 'MKE',
    53011: 'MKE',
    53012: 'MKE',
    53013: 'MKE',
    53014: 'MKE',
    53015: 'MKE',
    53016: 'MKE',
    53017: 'MKE',
    53018: 'MKE',
    53019: 'MKE',
    53020: 'MKE',
    53021: 'MKE',
    53022: 'MKE',
    53023: 'MKE',
    53024: 'MKE',
    53026: 'MKE',
    53027: 'MKE',
    53029: 'MKE',
    53031: 'MKE',
    53032: 'MKE',
    53033: 'MKE',
    53034: 'MKE',
    53035: 'MKE',
    53036: 'MKE',
    53037: 'MKE',
    53038: 'MKE',
    53039: 'MKE',
    53040: 'MKE',
    53042: 'MKE',
    53044: 'MKE',
    53045: 'MKE',
    53046: 'MKE',
    53047: 'MKE',
    53048: 'MKE',
    53049: 'MKE',
    53050: 'MKE',
    53051: 'MKE',
    53052: 'MKE',
    53056: 'MKE',
    53057: 'MKE',
    53058: 'MKE',
    53059: 'MKE',
    53060: 'MKE',
    53061: 'MKE',
    53062: 'MKE',
    53063: 'MKE',
    53064: 'MKE',
    53065: 'MKE',
    53066: 'MKE',
    53069: 'MKE',
    53070: 'MKE',
    53072: 'MKE',
    53073: 'MKE',
    53074: 'MKE',
    53075: 'MKE',
    53076: 'MKE',
    53078: 'MKE',
    53079: 'MKE',
    53080: 'MKE',
    53081: 'MKE',
    53082: 'MKE',
    53083: 'MKE',
    53085: 'MKE',
    53086: 'MKE',
    53088: 'MKE',
    53089: 'MKE',
    53090: 'MKE',
    53091: 'MKE',
    53092: 'MKE',
    53093: 'MKE',
    53094: 'MKE',
    53095: 'MKE',
    53097: 'MKE',
    53098: 'MKE',
    53099: 'MKE',
    53101: 'MKE',
    53102: 'MKE',
    53103: 'MKE',
    53104: 'MKE',
    53105: 'MKE',
    53108: 'MKE',
    53109: 'MKE',
    53110: 'MKE',
    53114: 'MKE',
    53115: 'MKE',
    53118: 'MKE',
    53119: 'MKE',
    53120: 'MKE',
    53121: 'MKE',
    53122: 'MKE',
    53125: 'MKE',
    53126: 'MKE',
    53127: 'MKE',
    53128: 'MKE',
    53129: 'MKE',
    53130: 'MKE',
    53132: 'MKE',
    53137: 'MKE',
    53138: 'MKE',
    53139: 'MKE',
    53140: 'MKE',
    53141: 'MKE',
    53142: 'MKE',
    53143: 'MKE',
    53144: 'MKE',
    53146: 'MKE',
    53147: 'MKE',
    53148: 'MKE',
    53149: 'MKE',
    53150: 'MKE',
    53151: 'MKE',
    53152: 'MKE',
    53153: 'MKE',
    53154: 'MKE',
    53156: 'MKE',
    53157: 'MKE',
    53158: 'MKE',
    53159: 'MKE',
    53167: 'MKE',
    53168: 'MKE',
    53170: 'MKE',
    53171: 'MKE',
    53172: 'MKE',
    53176: 'MKE',
    53177: 'MKE',
    53178: 'MKE',
    53179: 'MKE',
    53181: 'MKE',
    53182: 'MKE',
    53183: 'MKE',
    53184: 'MKE',
    53185: 'MKE',
    53186: 'MKE',
    53187: 'MKE',
    53188: 'MKE',
    53189: 'MKE',
    53190: 'MKE',
    53191: 'MKE',
    53192: 'MKE',
    53194: 'MKE',
    53195: 'MKE',
    53199: 'MKE',
    53201: 'MKE',
    53202: 'MKE',
    53203: 'MKE',
    53204: 'MKE',
    53205: 'MKE',
    53206: 'MKE',
    53207: 'MKE',
    53208: 'MKE',
    53209: 'MKE',
    53210: 'MKE',
    53211: 'MKE',
    53212: 'MKE',
    53213: 'MKE',
    53214: 'MKE',
    53215: 'MKE',
    53216: 'MKE',
    53217: 'MKE',
    53218: 'MKE',
    53219: 'MKE',
    53220: 'MKE',
    53221: 'MKE',
    53222: 'MKE',
    53223: 'MKE',
    53224: 'MKE',
    53225: 'MKE',
    53226: 'MKE',
    53227: 'MKE',
    53228: 'MKE',
    53233: 'MKE',
    53234: 'MKE',
    53235: 'MKE',
    53237: 'MKE',
    53244: 'MKE',
    53259: 'MKE',
    53263: 'MKE',
    53267: 'MKE',
    53268: 'MKE',
    53274: 'MKE',
    53278: 'MKE',
    53288: 'MKE',
    53290: 'MKE',
    53293: 'MKE',
    53295: 'MKE',
    53401: 'MKE',
    53402: 'MKE',
    53403: 'MKE',
    53404: 'MKE',
    53405: 'MKE',
    53406: 'MKE',
    53407: 'MKE',
    53408: 'MKE',
    53490: 'MKE',
    53501: 'MSN',
    53502: 'MSN',
    53503: 'MSN',
    53504: 'MSN',
    53505: 'MSN',
    53506: 'MSN',
    53507: 'MSN',
    53508: 'MSN',
    53510: 'MSN',
    53511: 'MSN',
    53512: 'MSN',
    53515: 'MSN',
    53516: 'MSN',
    53517: 'MSN',
    53518: 'MSN',
    53520: 'MSN',
    53521: 'MSN',
    53522: 'DBQ',
    53523: 'MSN',
    53525: 'MSN',
    53526: 'MSN',
    53527: 'MSN',
    53528: 'MSN',
    53529: 'MSN',
    53530: 'MSN',
    53531: 'MSN',
    53532: 'MSN',
    53533: 'MSN',
    53534: 'MSN',
    53535: 'MSN',
    53536: 'MSN',
    53537: 'MSN',
    53538: 'MSN',
    53540: 'MSN',
    53541: 'DBQ',
    53542: 'MSN',
    53543: 'MSN',
    53544: 'MSN',
    53545: 'MSN',
    53546: 'MSN',
    53547: 'MSN',
    53548: 'MSN',
    53549: 'MSN',
    53550: 'DBQ',
    53551: 'MSN',
    53553: 'MSN',
    53554: 'MSN',
    53555: 'MSN',
    53556: 'MSN',
    53557: 'MSN',
    53558: 'MSN',
    53559: 'MSN',
    53560: 'MSN',
    53561: 'MSN',
    53562: 'MSN',
    53563: 'MSN',
    53565: 'MSN',
    53566: 'DBQ',
    53569: 'MSN',
    53570: 'MSN',
    53571: 'MSN',
    53572: 'MSN',
    53573: 'MSN',
    53574: 'MSN',
    53575: 'MSN',
    53576: 'MSN',
    53577: 'MSN',
    53578: 'MSN',
    53579: 'MSN',
    53580: 'MSN',
    53581: 'MSN',
    53582: 'MSN',
    53583: 'MSN',
    53584: 'MSN',
    53585: 'MKE',
    53586: 'DBQ',
    53587: 'DBQ',
    53588: 'MSN',
    53589: 'MSN',
    53590: 'MSN',
    53593: 'MSN',
    53594: 'MSN',
    53595: 'MSN',
    53596: 'MSN',
    53597: 'MSN',
    53598: 'MSN',
    53599: 'DBQ',
    53701: 'MSN',
    53702: 'MSN',
    53703: 'MSN',
    53704: 'MSN',
    53705: 'MSN',
    53706: 'MSN',
    53707: 'MSN',
    53708: 'MSN',
    53711: 'MSN',
    53713: 'MSN',
    53714: 'MSN',
    53715: 'MSN',
    53716: 'MSN',
    53717: 'MSN',
    53718: 'MSN',
    53719: 'MSN',
    53725: 'MSN',
    53726: 'MSN',
    53744: 'MSN',
    53774: 'MSN',
    53777: 'MSN',
    53778: 'MSN',
    53779: 'MSN',
    53782: 'MSN',
    53783: 'MSN',
    53784: 'MSN',
    53785: 'MSN',
    53786: 'MSN',
    53788: 'MSN',
    53789: 'MSN',
    53790: 'MSN',
    53791: 'MSN',
    53792: 'MSN',
    53793: 'MSN',
    53794: 'MSN',
    53801: 'MSN',
    53802: 'MSN',
    53803: 'DBQ',
    53804: 'MSN',
    53805: 'MSN',
    53806: 'MSN',
    53807: 'DBQ',
    53808: 'DBQ',
    53809: 'MSN',
    53810: 'MSN',
    53811: 'DBQ',
    53812: 'DBQ',
    53813: 'MSN',
    53816: 'MSN',
    53817: 'MSN',
    53818: 'MSN',
    53820: 'MSN',
    53821: 'MSN',
    53824: 'DBQ',
    53825: 'MSN',
    53826: 'MSN',
    53827: 'MSN',
    53901: 'MSN',
    53910: 'MSN',
    53911: 'MSN',
    53913: 'MSN',
    53916: 'MSN',
    53919: 'MSN',
    53920: 'MSN',
    53922: 'MKE',
    53923: 'MSN',
    53924: 'MSN',
    53925: 'MSN',
    53926: 'MSN',
    53927: 'MSN',
    53928: 'MSN',
    53929: 'MSN',
    53930: 'MSN',
    53931: 'MSN',
    53932: 'MSN',
    53933: 'MSN',
    53934: 'MSN',
    53935: 'MSN',
    53936: 'MSN',
    53937: 'MSN',
    53939: 'MSN',
    53940: 'MSN',
    53941: 'MSN',
    53942: 'MSN',
    53943: 'MSN',
    53944: 'MSN',
    53946: 'MSN',
    53947: 'MSN',
    53948: 'MSN',
    53949: 'MSN',
    53950: 'MSN',
    53951: 'MSN',
    53952: 'MSN',
    53953: 'MSN',
    53954: 'MSN',
    53955: 'MSN',
    53956: 'MSN',
    53957: 'MSN',
    53958: 'MSN',
    53959: 'MSN',
    53960: 'MSN',
    53961: 'MSN',
    53962: 'MSN',
    53963: 'MKE',
    53964: 'MSN',
    53965: 'MSN',
    53968: 'MSN',
    53969: 'MSN',
    54001: 'MSP',
    54002: 'MSP',
    54003: 'MSP',
    54004: 'EAU',
    54005: 'MSP',
    54006: 'MSP',
    54007: 'MSP',
    54009: 'MSP',
    54010: 'MSP',
    54011: 'MSP',
    54013: 'MSP',
    54014: 'MSP',
    54015: 'MSP',
    54016: 'MSP',
    54017: 'MSP',
    54020: 'MSP',
    54021: 'MSP',
    54022: 'MSP',
    54023: 'MSP',
    54024: 'MSP',
    54025: 'MSP',
    54026: 'MSP',
    54027: 'MSP',
    54028: 'MSP',
    54082: 'MSP',
    54101: 'GRB',
    54102: 'GRB',
    54103: 'GRB',
    54104: 'GRB',
    54106: 'GRB',
    54107: 'GRB',
    54110: 'GRB',
    54111: 'GRB',
    54112: 'GRB',
    54113: 'GRB',
    54114: 'GRB',
    54115: 'GRB',
    54119: 'GRB',
    54120: 'GRB',
    54121: 'GRB',
    54123: 'GRB',
    54124: 'GRB',
    54125: 'GRB',
    54126: 'GRB',
    54127: 'GRB',
    54128: 'GRB',
    54129: 'MKE',
    54130: 'GRB',
    54131: 'GRB',
    54135: 'GRB',
    54136: 'GRB',
    54137: 'GRB',
    54138: 'GRB',
    54139: 'GRB',
    54140: 'GRB',
    54141: 'GRB',
    54143: 'GRB',
    54149: 'GRB',
    54150: 'GRB',
    54151: 'GRB',
    54152: 'GRB',
    54153: 'GRB',
    54154: 'GRB',
    54155: 'GRB',
    54156: 'GRB',
    54157: 'GRB',
    54159: 'GRB',
    54160: 'MKE',
    54161: 'GRB',
    54162: 'GRB',
    54165: 'GRB',
    54166: 'GRB',
    54169: 'GRB',
    54170: 'GRB',
    54171: 'GRB',
    54173: 'GRB',
    54174: 'GRB',
    54175: 'GRB',
    54177: 'GRB',
    54180: 'GRB',
    54182: 'GRB',
    54201: 'GRB',
    54202: 'GRB',
    54204: 'GRB',
    54205: 'GRB',
    54207: 'MKE',
    54208: 'GRB',
    54209: 'GRB',
    54210: 'GRB',
    54211: 'GRB',
    54212: 'GRB',
    54213: 'GRB',
    54214: 'GRB',
    54215: 'GRB',
    54216: 'GRB',
    54217: 'GRB',
    54220: 'MKE',
    54221: 'MKE',
    54226: 'GRB',
    54227: 'GRB',
    54228: 'GRB',
    54229: 'GRB',
    54230: 'GRB',
    54232: 'MKE',
    54234: 'GRB',
    54235: 'GRB',
    54240: 'GRB',
    54241: 'GRB',
    54245: 'MKE',
    54246: 'GRB',
    54247: 'GRB',
    54301: 'GRB',
    54302: 'GRB',
    54303: 'GRB',
    54304: 'GRB',
    54305: 'GRB',
    54306: 'GRB',
    54307: 'GRB',
    54308: 'GRB',
    54311: 'GRB',
    54313: 'GRB',
    54324: 'GRB',
    54344: 'GRB',
    54401: 'GRB',
    54402: 'GRB',
    54403: 'GRB',
    54404: 'GRB',
    54405: 'GRB',
    54406: 'GRB',
    54407: 'GRB',
    54408: 'GRB',
    54409: 'GRB',
    54410: 'GRB',
    54411: 'GRB',
    54412: 'GRB',
    54413: 'GRB',
    54414: 'GRB',
    54415: 'GRB',
    54416: 'GRB',
    54417: 'GRB',
    54418: 'GRB',
    54420: 'GRB',
    54421: 'GRB',
    54422: 'GRB',
    54423: 'GRB',
    54424: 'GRB',
    54425: 'GRB',
    54426: 'GRB',
    54427: 'GRB',
    54428: 'GRB',
    54429: 'GRB',
    54430: 'GRB',
    54432: 'GRB',
    54433: 'EAU',
    54434: 'EAU',
    54435: 'GRB',
    54436: 'GRB',
    54437: 'EAU',
    54439: 'EAU',
    54440: 'GRB',
    54441: 'GRB',
    54442: 'GRB',
    54443: 'GRB',
    54446: 'GRB',
    54447: 'EAU',
    54448: 'GRB',
    54449: 'GRB',
    54450: 'GRB',
    54451: 'GRB',
    54452: 'GRB',
    54454: 'GRB',
    54455: 'GRB',
    54456: 'EAU',
    54457: 'GRB',
    54458: 'GRB',
    54459: 'GRB',
    54460: 'EAU',
    54462: 'GRB',
    54463: 'GRB',
    54464: 'GRB',
    54465: 'GRB',
    54466: 'GRB',
    54467: 'GRB',
    54469: 'GRB',
    54470: 'GRB',
    54471: 'GRB',
    54472: 'GRB',
    54473: 'GRB',
    54474: 'GRB',
    54475: 'GRB',
    54476: 'GRB',
    54479: 'GRB',
    54480: 'GRB',
    54481: 'GRB',
    54482: 'GRB',
    54484: 'GRB',
    54485: 'GRB',
    54486: 'GRB',
    54487: 'GRB',
    54488: 'GRB',
    54489: 'GRB',
    54490: 'GRB',
    54491: 'GRB',
    54492: 'GRB',
    54493: 'EAU',
    54494: 'GRB',
    54495: 'GRB',
    54498: 'EAU',
    54499: 'GRB',
    54501: 'GRB',
    54511: 'GRB',
    54512: 'GRB',
    54513: 'GRB',
    54514: 'DLH',
    54515: 'EAU',
    54517: 'DLH',
    54519: 'GRB',
    54520: 'GRB',
    54521: 'GRB',
    54524: 'GRB',
    54525: 'GRB',
    54526: 'EAU',
    54527: 'DLH',
    54529: 'GRB',
    54530: 'EAU',
    54531: 'GRB',
    54532: 'GRB',
    54534: 'GRB',
    54536: 'GRB',
    54537: 'EAU',
    54538: 'GRB',
    54539: 'GRB',
    54540: 'GRB',
    54541: 'GRB',
    54542: 'GRB',
    54543: 'GRB',
    54545: 'GRB',
    54546: 'DLH',
    54547: 'GRB',
    54548: 'GRB',
    54550: 'GRB',
    54552: 'GRB',
    54554: 'GRB',
    54555: 'GRB',
    54556: 'GRB',
    54557: 'GRB',
    54558: 'GRB',
    54559: 'GRB',
    54560: 'GRB',
    54561: 'GRB',
    54562: 'GRB',
    54563: 'EAU',
    54564: 'GRB',
    54565: 'GRB',
    54566: 'GRB',
    54568: 'GRB',
    54601: 'MSN',
    54602: 'RST',
    54603: 'RST',
    54610: 'EAU',
    54611: 'EAU',
    54612: 'RST',
    54613: 'MSN',
    54614: 'MSN',
    54615: 'EAU',
    54616: 'RST',
    54618: 'MSN',
    54619: 'MSN',
    54620: 'MSN',
    54621: 'MSN',
    54622: 'RST',
    54623: 'MSN',
    54624: 'MSN',
    54625: 'RST',
    54626: 'MSN',
    54627: 'RST',
    54628: 'MSN',
    54629: 'RST',
    54630: 'RST',
    54631: 'MSN',
    54632: 'MSN',
    54634: 'MSN',
    54635: 'EAU',
    54636: 'RST',
    54637: 'MSN',
    54638: 'MSN',
    54639: 'MSN',
    54640: 'MSN',
    54641: 'GRB',
    54642: 'MSN',
    54643: 'EAU',
    54644: 'MSN',
    54645: 'MSN',
    54646: 'MSN',
    54648: 'MSN',
    54649: 'MSN',
    54650: 'RST',
    54651: 'MSN',
    54652: 'MSN',
    54653: 'MSN',
    54654: 'MSN',
    54655: 'MSN',
    54656: 'MSN',
    54657: 'MSN',
    54658: 'RST',
    54659: 'EAU',
    54660: 'MSN',
    54661: 'RST',
    54662: 'MSN',
    54664: 'MSN',
    54665: 'MSN',
    54666: 'GRB',
    54667: 'MSN',
    54669: 'MSN',
    54670: 'MSN',
    54701: 'EAU',
    54702: 'EAU',
    54703: 'EAU',
    54720: 'EAU',
    54721: 'EAU',
    54722: 'EAU',
    54723: 'MSP',
    54724: 'EAU',
    54725: 'EAU',
    54726: 'EAU',
    54727: 'EAU',
    54728: 'EAU',
    54729: 'EAU',
    54730: 'EAU',
    54731: 'EAU',
    54732: 'EAU',
    54733: 'EAU',
    54734: 'EAU',
    54735: 'EAU',
    54736: 'EAU',
    54737: 'EAU',
    54738: 'EAU',
    54739: 'EAU',
    54740: 'MSP',
    54741: 'EAU',
    54742: 'EAU',
    54743: 'EAU',
    54745: 'EAU',
    54746: 'EAU',
    54747: 'EAU',
    54748: 'EAU',
    54749: 'EAU',
    54750: 'MSP',
    54751: 'EAU',
    54754: 'EAU',
    54755: 'EAU',
    54756: 'EAU',
    54757: 'EAU',
    54758: 'EAU',
    54759: 'EAU',
    54760: 'EAU',
    54761: 'MSP',
    54762: 'EAU',
    54763: 'EAU',
    54764: 'EAU',
    54765: 'EAU',
    54766: 'EAU',
    54767: 'MSP',
    54768: 'EAU',
    54769: 'MSP',
    54770: 'EAU',
    54771: 'EAU',
    54772: 'EAU',
    54773: 'EAU',
    54774: 'EAU',
    54801: 'DLH',
    54805: 'EAU',
    54806: 'DLH',
    54810: 'MSP',
    54812: 'EAU',
    54813: 'DLH',
    54814: 'DLH',
    54816: 'DLH',
    54817: 'DLH',
    54818: 'EAU',
    54819: 'EAU',
    54820: 'DLH',
    54821: 'DLH',
    54822: 'EAU',
    54824: 'MSP',
    54826: 'EAU',
    54827: 'DLH',
    54828: 'DLH',
    54829: 'EAU',
    54830: 'MSP',
    54832: 'DLH',
    54834: 'DLH',
    54835: 'DLH',
    54836: 'DLH',
    54837: 'MSP',
    54838: 'DLH',
    54839: 'DLH',
    54840: 'MSP',
    54841: 'EAU',
    54842: 'DLH',
    54843: 'DLH',
    54844: 'DLH',
    54845: 'DLH',
    54846: 'DLH',
    54847: 'DLH',
    54848: 'EAU',
    54849: 'DLH',
    54850: 'DLH',
    54853: 'MSP',
    54854: 'DLH',
    54855: 'DLH',
    54856: 'DLH',
    54857: 'EAU',
    54858: 'MSP',
    54859: 'DLH',
    54861: 'DLH',
    54862: 'DLH',
    54864: 'DLH',
    54865: 'DLH',
    54867: 'DLH',
    54868: 'EAU',
    54870: 'DLH',
    54871: 'DLH',
    54872: 'MSP',
    54873: 'DLH',
    54874: 'DLH',
    54875: 'DLH',
    54876: 'DLH',
    54880: 'DLH',
    54888: 'DLH',
    54889: 'MSP',
    54890: 'DLH',
    54891: 'DLH',
    54893: 'MSP',
    54895: 'EAU',
    54896: 'DLH',
    54901: 'MKE',
    54902: 'MKE',
    54903: 'MKE',
    54904: 'MKE',
    54906: 'MKE',
    54909: 'GRB',
    54911: 'GRB',
    54912: 'GRB',
    54913: 'GRB',
    54914: 'GRB',
    54915: 'GRB',
    54919: 'GRB',
    54921: 'GRB',
    54922: 'GRB',
    54923: 'MSN',
    54926: 'GRB',
    54927: 'MKE',
    54928: 'GRB',
    54929: 'GRB',
    54930: 'MSN',
    54931: 'GRB',
    54932: 'MKE',
    54933: 'GRB',
    54934: 'MSN',
    54935: 'MKE',
    54936: 'MKE',
    54937: 'MKE',
    54940: 'GRB',
    54941: 'MSN',
    54942: 'GRB',
    54943: 'MSN',
    54944: 'GRB',
    54945: 'GRB',
    54946: 'GRB',
    54947: 'GRB',
    54948: 'GRB',
    54949: 'GRB',
    54950: 'GRB',
    54952: 'GRB',
    54956: 'GRB',
    54957: 'GRB',
    54960: 'MSN',
    54961: 'GRB',
    54962: 'GRB',
    54963: 'MSN',
    54964: 'MKE',
    54965: 'GRB',
    54966: 'GRB',
    54967: 'MSN',
    54968: 'MSN',
    54969: 'GRB',
    54970: 'MSN',
    54971: 'MSN',
    54974: 'MKE',
    54976: 'GRB',
    54977: 'GRB',
    54978: 'GRB',
    54979: 'MKE',
    54980: 'MKE',
    54981: 'GRB',
    54982: 'MSN',
    54983: 'GRB',
    54984: 'GRB',
    54985: 'MKE',
    54986: 'MSN',
    54990: 'GRB',
    55001: 'MSP',
    55002: 'MSP',
    55003: 'MSP',
    55005: 'MSP',
    55006: 'MSP',
    55007: 'MSP',
    55008: 'MSP',
    55009: 'MSP',
    55010: 'MSP',
    55011: 'MSP',
    55012: 'MSP',
    55013: 'MSP',
    55014: 'MSP',
    55016: 'MSP',
    55017: 'MSP',
    55018: 'MSP',
    55019: 'MSP',
    55020: 'MSP',
    55021: 'MSP',
    55024: 'MSP',
    55025: 'MSP',
    55026: 'MSP',
    55027: 'MSP',
    55029: 'MSP',
    55030: 'MSP',
    55031: 'MSP',
    55032: 'MSP',
    55033: 'MSP',
    55036: 'MSP',
    55037: 'MSP',
    55038: 'MSP',
    55040: 'MSP',
    55041: 'MSP',
    55042: 'MSP',
    55043: 'MSP',
    55044: 'MSP',
    55045: 'MSP',
    55046: 'MSP',
    55047: 'MSP',
    55049: 'RST',
    55051: 'MSP',
    55052: 'RST',
    55053: 'MSP',
    55054: 'MSP',
    55055: 'MSP',
    55056: 'MSP',
    55057: 'MSP',
    55060: 'RST',
    55063: 'MSP',
    55065: 'MSP',
    55066: 'MSP',
    55067: 'MSP',
    55068: 'MSP',
    55069: 'MSP',
    55070: 'MSP',
    55071: 'MSP',
    55072: 'MSP',
    55073: 'MSP',
    55074: 'MSP',
    55075: 'MSP',
    55076: 'MSP',
    55077: 'MSP',
    55078: 'MSP',
    55079: 'MSP',
    55080: 'MSP',
    55082: 'MSP',
    55083: 'MSP',
    55084: 'MSP',
    55085: 'MSP',
    55087: 'RST',
    55088: 'MSP',
    55089: 'MSP',
    55090: 'MSP',
    55092: 'MSP',
    55101: 'MSP',
    55102: 'MSP',
    55103: 'MSP',
    55104: 'MSP',
    55105: 'MSP',
    55106: 'MSP',
    55107: 'MSP',
    55108: 'MSP',
    55109: 'MSP',
    55110: 'MSP',
    55111: 'MSP',
    55112: 'MSP',
    55113: 'MSP',
    55114: 'MSP',
    55115: 'MSP',
    55116: 'MSP',
    55117: 'MSP',
    55118: 'MSP',
    55119: 'MSP',
    55120: 'MSP',
    55121: 'MSP',
    55122: 'MSP',
    55123: 'MSP',
    55124: 'MSP',
    55125: 'MSP',
    55126: 'MSP',
    55127: 'MSP',
    55128: 'MSP',
    55129: 'MSP',
    55130: 'MSP',
    55133: 'MSP',
    55144: 'MSP',
    55145: 'MSP',
    55146: 'MSP',
    55150: 'MSP',
    55155: 'MSP',
    55161: 'MSP',
    55164: 'MSP',
    55165: 'MSP',
    55166: 'MSP',
    55168: 'MSP',
    55169: 'MSP',
    55170: 'MSP',
    55171: 'MSP',
    55172: 'MSP',
    55175: 'MSP',
    55177: 'MSP',
    55187: 'MSP',
    55188: 'MSP',
    55191: 'MSP',
    55301: 'MSP',
    55302: 'MSP',
    55303: 'MSP',
    55304: 'MSP',
    55305: 'MSP',
    55306: 'MSP',
    55307: 'MSP',
    55308: 'MSP',
    55309: 'MSP',
    55310: 'MSP',
    55311: 'MSP',
    55312: 'MSP',
    55313: 'MSP',
    55314: 'MSP',
    55315: 'MSP',
    55316: 'MSP',
    55317: 'MSP',
    55318: 'MSP',
    55319: 'MSP',
    55320: 'MSP',
    55321: 'MSP',
    55322: 'MSP',
    55323: 'MSP',
    55324: 'MSP',
    55325: 'MSP',
    55327: 'MSP',
    55328: 'MSP',
    55329: 'MSP',
    55330: 'MSP',
    55331: 'MSP',
    55332: 'MSP',
    55333: 'MSP',
    55334: 'MSP',
    55335: 'MSP',
    55336: 'MSP',
    55337: 'MSP',
    55338: 'MSP',
    55339: 'MSP',
    55340: 'MSP',
    55341: 'MSP',
    55342: 'MSP',
    55343: 'MSP',
    55344: 'MSP',
    55345: 'MSP',
    55346: 'MSP',
    55347: 'MSP',
    55348: 'MSP',
    55349: 'MSP',
    55350: 'MSP',
    55352: 'MSP',
    55353: 'MSP',
    55354: 'MSP',
    55355: 'MSP',
    55356: 'MSP',
    55357: 'MSP',
    55358: 'MSP',
    55359: 'MSP',
    55360: 'MSP',
    55361: 'MSP',
    55362: 'MSP',
    55363: 'MSP',
    55364: 'MSP',
    55365: 'MSP',
    55366: 'MSP',
    55367: 'MSP',
    55368: 'MSP',
    55369: 'MSP',
    55370: 'MSP',
    55371: 'MSP',
    55372: 'MSP',
    55373: 'MSP',
    55374: 'MSP',
    55375: 'MSP',
    55376: 'MSP',
    55377: 'MSP',
    55378: 'MSP',
    55379: 'MSP',
    55380: 'MSP',
    55381: 'MSP',
    55382: 'MSP',
    55383: 'MSP',
    55384: 'MSP',
    55385: 'MSP',
    55386: 'MSP',
    55387: 'MSP',
    55388: 'MSP',
    55389: 'MSP',
    55390: 'MSP',
    55391: 'MSP',
    55392: 'MSP',
    55393: 'MSP',
    55394: 'MSP',
    55395: 'MSP',
    55396: 'MSP',
    55397: 'MSP',
    55398: 'MSP',
    55399: 'MSP',
    55401: 'MSP',
    55402: 'MSP',
    55403: 'MSP',
    55404: 'MSP',
    55405: 'MSP',
    55406: 'MSP',
    55407: 'MSP',
    55408: 'MSP',
    55409: 'MSP',
    55410: 'MSP',
    55411: 'MSP',
    55412: 'MSP',
    55413: 'MSP',
    55414: 'MSP',
    55415: 'MSP',
    55416: 'MSP',
    55417: 'MSP',
    55418: 'MSP',
    55419: 'MSP',
    55420: 'MSP',
    55421: 'MSP',
    55422: 'MSP',
    55423: 'MSP',
    55424: 'MSP',
    55425: 'MSP',
    55426: 'MSP',
    55427: 'MSP',
    55428: 'MSP',
    55429: 'MSP',
    55430: 'MSP',
    55431: 'MSP',
    55432: 'MSP',
    55433: 'MSP',
    55434: 'MSP',
    55435: 'MSP',
    55436: 'MSP',
    55437: 'MSP',
    55438: 'MSP',
    55439: 'MSP',
    55440: 'MSP',
    55441: 'MSP',
    55442: 'MSP',
    55443: 'MSP',
    55444: 'MSP',
    55445: 'MSP',
    55446: 'MSP',
    55447: 'MSP',
    55448: 'MSP',
    55449: 'MSP',
    55450: 'MSP',
    55454: 'MSP',
    55455: 'MSP',
    55458: 'MSP',
    55459: 'MSP',
    55460: 'MSP',
    55467: 'MSP',
    55468: 'MSP',
    55470: 'MSP',
    55472: 'MSP',
    55473: 'MSP',
    55474: 'MSP',
    55478: 'MSP',
    55479: 'MSP',
    55480: 'MSP',
    55483: 'MSP',
    55484: 'MSP',
    55485: 'MSP',
    55486: 'MSP',
    55487: 'MSP',
    55488: 'MSP',
    55550: 'MSP',
    55551: 'MSP',
    55552: 'MSP',
    55553: 'MSP',
    55554: 'MSP',
    55555: 'MSP',
    55556: 'MSP',
    55557: 'MSP',
    55558: 'MSP',
    55559: 'MSP',
    55560: 'MSP',
    55561: 'MSP',
    55562: 'MSP',
    55563: 'MSP',
    55564: 'MSP',
    55565: 'MSP',
    55566: 'MSP',
    55567: 'MSP',
    55568: 'MSP',
    55569: 'MSP',
    55570: 'MSP',
    55571: 'MSP',
    55572: 'MSP',
    55573: 'MSP',
    55574: 'MSP',
    55575: 'MSP',
    55576: 'MSP',
    55577: 'MSP',
    55578: 'MSP',
    55579: 'MSP',
    55580: 'MSP',
    55581: 'MSP',
    55582: 'MSP',
    55583: 'MSP',
    55584: 'MSP',
    55585: 'MSP',
    55586: 'MSP',
    55587: 'MSP',
    55588: 'MSP',
    55589: 'MSP',
    55590: 'MSP',
    55591: 'MSP',
    55592: 'MSP',
    55593: 'MSP',
    55594: 'MSP',
    55595: 'MSP',
    55596: 'MSP',
    55597: 'MSP',
    55598: 'MSP',
    55599: 'MSP',
    55601: 'DLH',
    55602: 'DLH',
    55603: 'DLH',
    55604: 'DLH',
    55605: 'DLH',
    55606: 'DLH',
    55607: 'DLH',
    55609: 'DLH',
    55612: 'DLH',
    55613: 'DLH',
    55614: 'DLH',
    55615: 'DLH',
    55616: 'DLH',
    55701: 'DLH',
    55702: 'DLH',
    55703: 'DLH',
    55704: 'MSP',
    55705: 'DLH',
    55706: 'DLH',
    55707: 'DLH',
    55708: 'DLH',
    55709: 'MSP',
    55710: 'DLH',
    55711: 'DLH',
    55712: 'MSP',
    55713: 'DLH',
    55716: 'MSP',
    55717: 'DLH',
    55718: 'DLH',
    55719: 'DLH',
    55720: 'DLH',
    55721: 'MSP',
    55722: 'MSP',
    55723: 'DLH',
    55724: 'DLH',
    55725: 'DLH',
    55726: 'DLH',
    55730: 'MSP',
    55731: 'DLH',
    55732: 'DLH',
    55733: 'DLH',
    55734: 'DLH',
    55735: 'MSP',
    55736: 'DLH',
    55738: 'DLH',
    55741: 'DLH',
    55742: 'DLH',
    55744: 'MSP',
    55745: 'MSP',
    55746: 'DLH',
    55747: 'DLH',
    55748: 'MSP',
    55749: 'DLH',
    55750: 'DLH',
    55751: 'DLH',
    55752: 'DLH',
    55753: 'DLH',
    55756: 'DLH',
    55757: 'DLH',
    55758: 'DLH',
    55760: 'MSP',
    55763: 'DLH',
    55764: 'MSP',
    55765: 'DLH',
    55766: 'DLH',
    55767: 'DLH',
    55768: 'DLH',
    55769: 'DLH',
    55771: 'DLH',
    55772: 'DLH',
    55775: 'DLH',
    55777: 'DLH',
    55779: 'DLH',
    55780: 'DLH',
    55781: 'DLH',
    55782: 'DLH',
    55783: 'DLH',
    55784: 'DLH',
    55785: 'MSP',
    55786: 'MSP',
    55787: 'DLH',
    55790: 'DLH',
    55791: 'DLH',
    55792: 'DLH',
    55793: 'DLH',
    55795: 'MSP',
    55796: 'DLH',
    55797: 'DLH',
    55798: 'DLH',
    55801: 'DLH',
    55802: 'DLH',
    55803: 'DLH',
    55804: 'DLH',
    55805: 'DLH',
    55806: 'DLH',
    55807: 'DLH',
    55808: 'DLH',
    55810: 'DLH',
    55811: 'DLH',
    55812: 'DLH',
    55814: 'DLH',
    55815: 'DLH',
    55816: 'DLH',
    55901: 'RST',
    55902: 'RST',
    55903: 'RST',
    55904: 'RST',
    55905: 'RST',
    55906: 'RST',
    55909: 'RST',
    55910: 'RST',
    55912: 'RST',
    55917: 'RST',
    55918: 'RST',
    55919: 'RST',
    55920: 'RST',
    55921: 'RST',
    55922: 'RST',
    55923: 'RST',
    55924: 'RST',
    55925: 'RST',
    55926: 'RST',
    55927: 'RST',
    55929: 'RST',
    55931: 'RST',
    55932: 'RST',
    55933: 'RST',
    55934: 'RST',
    55935: 'RST',
    55936: 'RST',
    55939: 'RST',
    55940: 'RST',
    55941: 'RST',
    55942: 'RST',
    55943: 'RST',
    55944: 'RST',
    55945: 'RST',
    55946: 'RST',
    55947: 'RST',
    55949: 'RST',
    55950: 'RST',
    55951: 'RST',
    55952: 'RST',
    55953: 'RST',
    55954: 'RST',
    55955: 'RST',
    55956: 'RST',
    55957: 'RST',
    55959: 'RST',
    55960: 'RST',
    55961: 'RST',
    55962: 'RST',
    55963: 'RST',
    55964: 'RST',
    55965: 'RST',
    55967: 'RST',
    55968: 'MSP',
    55969: 'RST',
    55970: 'RST',
    55971: 'RST',
    55972: 'RST',
    55973: 'RST',
    55974: 'RST',
    55975: 'RST',
    55976: 'RST',
    55977: 'RST',
    55979: 'RST',
    55981: 'RST',
    55982: 'RST',
    55983: 'RST',
    55985: 'RST',
    55987: 'RST',
    55988: 'RST',
    55990: 'RST',
    55991: 'RST',
    55992: 'RST',
    56001: 'RST',
    56002: 'RST',
    56003: 'RST',
    56006: 'RST',
    56007: 'RST',
    56009: 'RST',
    56010: 'RST',
    56011: 'MSP',
    56013: 'RST',
    56014: 'RST',
    56016: 'RST',
    56017: 'MSP',
    56019: 'RST',
    56020: 'RST',
    56021: 'RST',
    56022: 'RST',
    56023: 'RST',
    56024: 'RST',
    56025: 'RST',
    56026: 'RST',
    56027: 'RST',
    56028: 'RST',
    56029: 'RST',
    56030: 'RST',
    56031: 'RST',
    56032: 'RST',
    56033: 'RST',
    56034: 'RST',
    56035: 'RST',
    56036: 'RST',
    56037: 'RST',
    56039: 'RST',
    56041: 'RST',
    56042: 'RST',
    56043: 'RST',
    56044: 'MSP',
    56045: 'RST',
    56046: 'RST',
    56047: 'RST',
    56048: 'RST',
    56050: 'RST',
    56051: 'RST',
    56052: 'MSP',
    56054: 'MSP',
    56055: 'RST',
    56056: 'RST',
    56057: 'MSP',
    56058: 'MSP',
    56060: 'RST',
    56062: 'RST',
    56063: 'RST',
    56065: 'RST',
    56068: 'RST',
    56069: 'MSP',
    56071: 'MSP',
    56072: 'RST',
    56073: 'RST',
    56074: 'MSP',
    56075: 'RST',
    56078: 'RST',
    56080: 'RST',
    56081: 'RST',
    56082: 'RST',
    56083: 'RST',
    56084: 'RST',
    56085: 'RST',
    56087: 'RST',
    56088: 'RST',
    56089: 'RST',
    56090: 'RST',
    56091: 'RST',
    56093: 'RST',
    56096: 'RST',
    56097: 'RST',
    56098: 'RST',
    56101: 'RST',
    56110: 'RST',
    56111: 'RST',
    56113: 'MSP',
    56114: 'RST',
    56115: 'RST',
    56116: 'RST',
    56117: 'RST',
    56118: 'RST',
    56119: 'RST',
    56120: 'RST',
    56121: 'RST',
    56122: 'RST',
    56123: 'RST',
    56125: 'RST',
    56127: 'RST',
    56128: 'RST',
    56129: 'RST',
    56131: 'RST',
    56132: 'RST',
    56134: 'RST',
    56136: 'MSP',
    56137: 'RST',
    56138: 'RST',
    56139: 'RST',
    56140: 'RST',
    56141: 'RST',
    56142: 'MSP',
    56143: 'RST',
    56144: 'RST',
    56145: 'RST',
    56146: 'RST',
    56147: 'RST',
    56149: 'MSP',
    56150: 'RST',
    56151: 'RST',
    56152: 'RST',
    56153: 'RST',
    56155: 'RST',
    56156: 'RST',
    56157: 'MSP',
    56158: 'RST',
    56159: 'RST',
    56160: 'RST',
    56161: 'RST',
    56162: 'RST',
    56164: 'RST',
    56165: 'RST',
    56166: 'RST',
    56167: 'RST',
    56168: 'RST',
    56169: 'MSP',
    56170: 'RST',
    56171: 'RST',
    56172: 'RST',
    56173: 'RST',
    56174: 'RST',
    56175: 'RST',
    56176: 'RST',
    56177: 'RST',
    56178: 'MSP',
    56180: 'RST',
    56181: 'RST',
    56183: 'RST',
    56185: 'RST',
    56186: 'RST',
    56187: 'RST',
    56201: 'MSP',
    56207: 'MSP',
    56208: 'MSP',
    56209: 'MSP',
    56210: 'MSP',
    56211: 'MSP',
    56212: 'MSP',
    56214: 'MSP',
    56215: 'MSP',
    56216: 'MSP',
    56218: 'MSP',
    56219: 'MSP',
    56220: 'MSP',
    56221: 'MSP',
    56222: 'MSP',
    56223: 'MSP',
    56224: 'MSP',
    56225: 'MSP',
    56226: 'MSP',
    56227: 'MSP',
    56228: 'MSP',
    56229: 'MSP',
    56230: 'MSP',
    56231: 'MSP',
    56232: 'MSP',
    56235: 'MSP',
    56236: 'MSP',
    56237: 'MSP',
    56239: 'MSP',
    56240: 'MSP',
    56241: 'MSP',
    56243: 'MSP',
    56244: 'MSP',
    56245: 'MSP',
    56248: 'MSP',
    56249: 'MSP',
    56251: 'MSP',
    56252: 'MSP',
    56253: 'MSP',
    56255: 'MSP',
    56256: 'MSP',
    56257: 'MSP',
    56258: 'MSP',
    56260: 'MSP',
    56262: 'MSP',
    56263: 'MSP',
    56264: 'MSP',
    56265: 'MSP',
    56266: 'MSP',
    56267: 'MSP',
    56270: 'MSP',
    56271: 'MSP',
    56273: 'MSP',
    56274: 'MSP',
    56276: 'MSP',
    56277: 'MSP',
    56278: 'MSP',
    56279: 'MSP',
    56280: 'MSP',
    56281: 'MSP',
    56282: 'MSP',
    56283: 'MSP',
    56284: 'MSP',
    56285: 'MSP',
    56287: 'MSP',
    56288: 'MSP',
    56289: 'MSP',
    56291: 'MSP',
    56292: 'MSP',
    56293: 'MSP',
    56294: 'MSP',
    56295: 'MSP',
    56296: 'MSP',
    56297: 'MSP',
    56301: 'MSP',
    56302: 'MSP',
    56303: 'MSP',
    56304: 'MSP',
    56307: 'MSP',
    56308: 'MSP',
    56309: 'MSP',
    56310: 'MSP',
    56311: 'MSP',
    56312: 'MSP',
    56313: 'MSP',
    56314: 'MSP',
    56315: 'MSP',
    56316: 'MSP',
    56317: 'MSP',
    56318: 'MSP',
    56319: 'MSP',
    56320: 'MSP',
    56321: 'MSP',
    56323: 'MSP',
    56324: 'MSP',
    56325: 'MSP',
    56326: 'MSP',
    56327: 'MSP',
    56328: 'MSP',
    56329: 'MSP',
    56330: 'MSP',
    56331: 'MSP',
    56332: 'MSP',
    56333: 'MSP',
    56334: 'MSP',
    56335: 'MSP',
    56336: 'MSP',
    56338: 'MSP',
    56339: 'MSP',
    56340: 'MSP',
    56341: 'MSP',
    56342: 'MSP',
    56343: 'MSP',
    56344: 'MSP',
    56345: 'MSP',
    56347: 'MSP',
    56349: 'MSP',
    56350: 'MSP',
    56352: 'MSP',
    56353: 'MSP',
    56354: 'MSP',
    56355: 'MSP',
    56356: 'MSP',
    56357: 'MSP',
    56358: 'MSP',
    56359: 'MSP',
    56360: 'MSP',
    56361: 'MSP',
    56362: 'MSP',
    56363: 'MSP',
    56364: 'MSP',
    56367: 'MSP',
    56368: 'MSP',
    56369: 'MSP',
    56371: 'MSP',
    56372: 'MSP',
    56373: 'MSP',
    56374: 'MSP',
    56375: 'MSP',
    56376: 'MSP',
    56377: 'MSP',
    56378: 'MSP',
    56379: 'MSP',
    56381: 'MSP',
    56382: 'MSP',
    56384: 'MSP',
    56385: 'MSP',
    56386: 'MSP',
    56387: 'MSP',
    56388: 'MSP',
    56389: 'MSP',
    56393: 'MSP',
    56395: 'MSP',
    56396: 'MSP',
    56397: 'MSP',
    56398: 'MSP',
    56399: 'MSP',
    56401: 'MSP',
    56425: 'MSP',
    56430: 'MSP',
    56431: 'MSP',
    56433: 'MSP',
    56434: 'MSP',
    56435: 'MSP',
    56436: 'MSP',
    56437: 'MSP',
    56438: 'MSP',
    56440: 'MSP',
    56441: 'MSP',
    56442: 'MSP',
    56443: 'MSP',
    56444: 'MSP',
    56446: 'MSP',
    56447: 'MSP',
    56448: 'MSP',
    56449: 'MSP',
    56450: 'MSP',
    56452: 'MSP',
    56453: 'MSP',
    56455: 'MSP',
    56456: 'MSP',
    56458: 'MSP',
    56459: 'MSP',
    56461: 'MSP',
    56464: 'MSP',
    56465: 'MSP',
    56466: 'MSP',
    56467: 'MSP',
    56468: 'MSP',
    56469: 'MSP',
    56470: 'MSP',
    56472: 'MSP',
    56473: 'MSP',
    56474: 'MSP',
    56475: 'MSP',
    56477: 'MSP',
    56478: 'MSP',
    56479: 'MSP',
    56481: 'MSP',
    56482: 'MSP',
    56484: 'MSP',
    56501: 'MSP',
    56502: 'MSP',
    56510: 'MSP',
    56511: 'MSP',
    56514: 'MSP',
    56515: 'MSP',
    56516: 'MSP',
    56517: 'MSP',
    56518: 'MSP',
    56519: 'MSP',
    56520: 'MSP',
    56521: 'MSP',
    56522: 'MSP',
    56523: 'MSP',
    56524: 'MSP',
    56525: 'MSP',
    56527: 'MSP',
    56528: 'MSP',
    56529: 'MSP',
    56531: 'MSP',
    56533: 'MSP',
    56534: 'MSP',
    56535: 'MSP',
    56536: 'MSP',
    56537: 'MSP',
    56538: 'MSP',
    56540: 'MSP',
    56541: 'MSP',
    56542: 'MSP',
    56543: 'MSP',
    56544: 'MSP',
    56545: 'MSP',
    56546: 'MSP',
    56547: 'MSP',
    56548: 'MSP',
    56549: 'MSP',
    56550: 'MSP',
    56551: 'MSP',
    56552: 'MSP',
    56553: 'MSP',
    56554: 'MSP',
    56556: 'MSP',
    56557: 'MSP',
    56560: 'MSP',
    56561: 'MSP',
    56562: 'MSP',
    56563: 'MSP',
    56565: 'MSP',
    56566: 'MSP',
    56567: 'MSP',
    56568: 'MSP',
    56569: 'MSP',
    56570: 'MSP',
    56571: 'MSP',
    56572: 'MSP',
    56573: 'MSP',
    56574: 'MSP',
    56575: 'MSP',
    56576: 'MSP',
    56577: 'MSP',
    56578: 'MSP',
    56579: 'MSP',
    56580: 'MSP',
    56581: 'MSP',
    56583: 'MSP',
    56584: 'MSP',
    56585: 'MSP',
    56586: 'MSP',
    56587: 'MSP',
    56588: 'MSP',
    56589: 'MSP',
    56590: 'MSP',
    56591: 'MSP',
    56592: 'MSP',
    56593: 'MSP',
    56594: 'MSP',
    56601: 'MSP',
    56619: 'MSP',
    56621: 'MSP',
    56623: 'MSP',
    56626: 'MSP',
    56627: 'MSP',
    56628: 'MSP',
    56629: 'MSP',
    56630: 'MSP',
    56631: 'MSP',
    56633: 'MSP',
    56634: 'MSP',
    56636: 'MSP',
    56637: 'MSP',
    56639: 'MSP',
    56641: 'MSP',
    56644: 'MSP',
    56646: 'MSP',
    56647: 'MSP',
    56649: 'DLH',
    56650: 'MSP',
    56651: 'MSP',
    56652: 'MSP',
    56653: 'MSP',
    56654: 'MSP',
    56655: 'MSP',
    56657: 'MSP',
    56658: 'MSP',
    56659: 'MSP',
    56660: 'MSP',
    56661: 'MSP',
    56662: 'MSP',
    56663: 'MSP',
    56666: 'MSP',
    56667: 'MSP',
    56668: 'MSP',
    56669: 'DLH',
    56670: 'MSP',
    56671: 'MSP',
    56672: 'MSP',
    56673: 'MSP',
    56676: 'MSP',
    56678: 'MSP',
    56679: 'MSP',
    56680: 'MSP',
    56681: 'MSP',
    56682: 'MSP',
    56683: 'MSP',
    56684: 'MSP',
    56685: 'MSP',
    56686: 'MSP',
    56687: 'MSP',
    56688: 'MSP',
    56701: 'MSP',
    56710: 'MSP',
    56711: 'MSP',
    56713: 'MSP',
    56714: 'MSP',
    56715: 'MSP',
    56716: 'MSP',
    56720: 'MSP',
    56721: 'MSP',
    56722: 'MSP',
    56723: 'MSP',
    56724: 'MSP',
    56725: 'MSP',
    56726: 'MSP',
    56727: 'MSP',
    56728: 'MSP',
    56729: 'MSP',
    56731: 'MSP',
    56732: 'MSP',
    56733: 'MSP',
    56734: 'MSP',
    56735: 'MSP',
    56736: 'MSP',
    56737: 'MSP',
    56738: 'MSP',
    56740: 'MSP',
    56741: 'MSP',
    56742: 'MSP',
    56744: 'MSP',
    56748: 'MSP',
    56750: 'MSP',
    56751: 'MSP',
    56754: 'MSP',
    56755: 'MSP',
    56756: 'MSP',
    56757: 'MSP',
    56758: 'MSP',
    56759: 'MSP',
    56760: 'MSP',
    56761: 'MSP',
    56762: 'MSP',
    56763: 'MSP',
    56901: 'IAD',
    56902: 'IAD',
    56904: 'IAD',
    56908: 'IAD',
    56915: 'IAD',
    56920: 'IAD',
    56933: 'IAD',
    56935: 'IAD',
    56944: 'IAD',
    56945: 'IAD',
    56950: 'IAD',
    56965: 'IAD',
    56967: 'IAD',
    56972: 'IAD',
    56998: 'IAD',
    56999: 'BWI',
    57001: 'FSD',
    57002: 'FSD',
    57003: 'FSD',
    57004: 'FSD',
    57005: 'FSD',
    57006: 'FSD',
    57007: 'FSD',
    57010: 'FSD',
    57012: 'FSD',
    57013: 'FSD',
    57014: 'FSD',
    57015: 'FSD',
    57016: 'FSD',
    57017: 'FSD',
    57018: 'FSD',
    57020: 'FSD',
    57021: 'FSD',
    57022: 'FSD',
    57024: 'FSD',
    57025: 'FSD',
    57026: 'FSD',
    57027: 'FSD',
    57028: 'FSD',
    57029: 'FSD',
    57030: 'FSD',
    57031: 'FSD',
    57032: 'FSD',
    57033: 'FSD',
    57034: 'FSD',
    57035: 'FSD',
    57036: 'FSD',
    57037: 'FSD',
    57038: 'FSD',
    57039: 'FSD',
    57040: 'FSD',
    57041: 'FSD',
    57042: 'FSD',
    57043: 'FSD',
    57045: 'FSD',
    57046: 'FSD',
    57047: 'FSD',
    57048: 'FSD',
    57049: 'FSD',
    57050: 'FSD',
    57051: 'FSD',
    57052: 'FSD',
    57053: 'FSD',
    57054: 'FSD',
    57055: 'FSD',
    57056: 'FSD',
    57057: 'FSD',
    57058: 'FSD',
    57059: 'FSD',
    57061: 'FSD',
    57062: 'FSD',
    57063: 'FSD',
    57064: 'FSD',
    57065: 'FSD',
    57066: 'FSD',
    57067: 'FSD',
    57068: 'FSD',
    57069: 'FSD',
    57070: 'FSD',
    57071: 'FSD',
    57072: 'FSD',
    57073: 'FSD',
    57075: 'FSD',
    57076: 'FSD',
    57077: 'FSD',
    57078: 'FSD',
    57079: 'FSD',
    57101: 'FSD',
    57103: 'FSD',
    57104: 'FSD',
    57105: 'FSD',
    57106: 'FSD',
    57107: 'FSD',
    57108: 'FSD',
    57109: 'FSD',
    57110: 'FSD',
    57117: 'FSD',
    57118: 'FSD',
    57186: 'FSD',
    57188: 'FSD',
    57189: 'FSD',
    57192: 'FSD',
    57193: 'FSD',
    57194: 'FSD',
    57195: 'FSD',
    57196: 'FSD',
    57197: 'FSD',
    57198: 'FSD',
    57201: 'ABR',
    57212: 'FSD',
    57213: 'ABR',
    57214: 'ABR',
    57216: 'ABR',
    57217: 'ABR',
    57218: 'ABR',
    57219: 'ABR',
    57220: 'ABR',
    57221: 'ABR',
    57223: 'ABR',
    57224: 'ABR',
    57225: 'ABR',
    57226: 'ABR',
    57227: 'ABR',
    57231: 'FSD',
    57232: 'ABR',
    57233: 'ABR',
    57234: 'ABR',
    57235: 'ABR',
    57236: 'ABR',
    57237: 'ABR',
    57238: 'ABR',
    57239: 'ABR',
    57241: 'ABR',
    57242: 'ABR',
    57243: 'ABR',
    57245: 'ABR',
    57246: 'ABR',
    57247: 'ABR',
    57248: 'ABR',
    57249: 'FSD',
    57251: 'ABR',
    57252: 'ABR',
    57253: 'ABR',
    57255: 'ABR',
    57256: 'ABR',
    57257: 'ABR',
    57258: 'ABR',
    57259: 'ABR',
    57260: 'ABR',
    57261: 'ABR',
    57262: 'ABR',
    57263: 'ABR',
    57264: 'ABR',
    57265: 'ABR',
    57266: 'ABR',
    57268: 'ABR',
    57269: 'ABR',
    57270: 'ABR',
    57271: 'ABR',
    57272: 'ABR',
    57273: 'ABR',
    57274: 'ABR',
    57276: 'ABR',
    57278: 'ABR',
    57279: 'ABR',
    57301: 'FSD',
    57311: 'FSD',
    57312: 'FSD',
    57313: 'FSD',
    57314: 'FSD',
    57315: 'FSD',
    57317: 'FSD',
    57319: 'FSD',
    57321: 'FSD',
    57322: 'ABR',
    57323: 'FSD',
    57324: 'FSD',
    57325: 'FSD',
    57326: 'FSD',
    57328: 'FSD',
    57329: 'FSD',
    57330: 'FSD',
    57331: 'FSD',
    57332: 'FSD',
    57334: 'FSD',
    57335: 'FSD',
    57337: 'FSD',
    57339: 'FSD',
    57340: 'FSD',
    57341: 'FSD',
    57342: 'FSD',
    57344: 'FSD',
    57345: 'ABR',
    57346: 'ABR',
    57348: 'ABR',
    57349: 'FSD',
    57350: 'ABR',
    57353: 'FSD',
    57354: 'FSD',
    57355: 'FSD',
    57356: 'FSD',
    57358: 'FSD',
    57359: 'FSD',
    57361: 'FSD',
    57362: 'ABR',
    57363: 'FSD',
    57364: 'FSD',
    57365: 'FSD',
    57366: 'FSD',
    57367: 'FSD',
    57368: 'FSD',
    57369: 'FSD',
    57370: 'FSD',
    57371: 'FSD',
    57373: 'ABR',
    57374: 'FSD',
    57375: 'FSD',
    57376: 'FSD',
    57379: 'FSD',
    57380: 'FSD',
    57381: 'ABR',
    57382: 'FSD',
    57383: 'FSD',
    57384: 'FSD',
    57385: 'FSD',
    57386: 'ABR',
    57399: 'ABR',
    57401: 'ABR',
    57402: 'ABR',
    57420: 'ABR',
    57421: 'ABR',
    57422: 'ABR',
    57424: 'ABR',
    57426: 'ABR',
    57427: 'ABR',
    57428: 'ABR',
    57429: 'ABR',
    57430: 'ABR',
    57432: 'ABR',
    57433: 'ABR',
    57434: 'ABR',
    57435: 'ABR',
    57436: 'ABR',
    57437: 'ABR',
    57438: 'ABR',
    57439: 'ABR',
    57440: 'ABR',
    57441: 'ABR',
    57442: 'ABR',
    57445: 'ABR',
    57446: 'ABR',
    57448: 'ABR',
    57449: 'ABR',
    57450: 'ABR',
    57451: 'ABR',
    57452: 'ABR',
    57454: 'ABR',
    57455: 'ABR',
    57456: 'ABR',
    57457: 'ABR',
    57460: 'ABR',
    57461: 'ABR',
    57465: 'ABR',
    57466: 'ABR',
    57467: 'ABR',
    57468: 'ABR',
    57469: 'ABR',
    57470: 'ABR',
    57471: 'ABR',
    57472: 'ABR',
    57473: 'ABR',
    57474: 'ABR',
    57475: 'ABR',
    57476: 'ABR',
    57477: 'ABR',
    57479: 'ABR',
    57481: 'ABR',
    57501: 'FSD',
    57520: 'ABR',
    57521: 'RAP',
    57522: 'ABR',
    57523: 'FSD',
    57528: 'FSD',
    57529: 'FSD',
    57531: 'RAP',
    57532: 'RAP',
    57533: 'FSD',
    57534: 'FSD',
    57536: 'FSD',
    57537: 'RAP',
    57538: 'FSD',
    57540: 'ABR',
    57541: 'FSD',
    57543: 'RAP',
    57544: 'FSD',
    57547: 'RAP',
    57548: 'FSD',
    57551: 'RAP',
    57552: 'RAP',
    57553: 'RAP',
    57555: 'RAP',
    57559: 'RAP',
    57560: 'RAP',
    57562: 'RAP',
    57563: 'RAP',
    57564: 'ABR',
    57566: 'RAP',
    57567: 'RAP',
    57568: 'FSD',
    57569: 'FSD',
    57570: 'RAP',
    57571: 'FSD',
    57572: 'RAP',
    57574: 'RAP',
    57576: 'FSD',
    57577: 'RAP',
    57579: 'RAP',
    57580: 'FSD',
    57584: 'FSD',
    57585: 'RAP',
    57601: 'ABR',
    57620: 'RAP',
    57621: 'RAP',
    57622: 'RAP',
    57623: 'RAP',
    57625: 'RAP',
    57626: 'RAP',
    57630: 'RAP',
    57631: 'ABR',
    57632: 'ABR',
    57633: 'RAP',
    57634: 'RAP',
    57636: 'RAP',
    57638: 'RAP',
    57639: 'RAP',
    57640: 'RAP',
    57641: 'RAP',
    57642: 'RAP',
    57644: 'RAP',
    57645: 'RAP',
    57646: 'ABR',
    57648: 'ABR',
    57649: 'RAP',
    57650: 'RAP',
    57651: 'RAP',
    57652: 'RAP',
    57656: 'RAP',
    57657: 'RAP',
    57658: 'RAP',
    57659: 'RAP',
    57660: 'RAP',
    57661: 'RAP',
    57701: 'RAP',
    57702: 'RAP',
    57703: 'RAP',
    57706: 'RAP',
    57709: 'RAP',
    57714: 'RAP',
    57716: 'RAP',
    57717: 'RAP',
    57718: 'RAP',
    57719: 'RAP',
    57720: 'RAP',
    57722: 'RAP',
    57724: 'RAP',
    57725: 'RAP',
    57730: 'RAP',
    57732: 'RAP',
    57735: 'RAP',
    57737: 'RAP',
    57738: 'RAP',
    57741: 'RAP',
    57744: 'RAP',
    57745: 'RAP',
    57747: 'RAP',
    57748: 'RAP',
    57750: 'RAP',
    57751: 'RAP',
    57752: 'RAP',
    57754: 'RAP',
    57755: 'RAP',
    57756: 'RAP',
    57758: 'RAP',
    57759: 'RAP',
    57760: 'RAP',
    57761: 'RAP',
    57762: 'RAP',
    57763: 'RAP',
    57764: 'RAP',
    57766: 'RAP',
    57767: 'RAP',
    57769: 'RAP',
    57770: 'RAP',
    57772: 'RAP',
    57773: 'RAP',
    57775: 'RAP',
    57776: 'RAP',
    57779: 'RAP',
    57780: 'RAP',
    57782: 'RAP',
    57783: 'RAP',
    57785: 'RAP',
    57787: 'RAP',
    57788: 'RAP',
    57790: 'RAP',
    57791: 'RAP',
    57792: 'RAP',
    57793: 'RAP',
    57794: 'RAP',
    57799: 'RAP',
    58001: 'FAR',
    58002: 'FAR',
    58004: 'FAR',
    58005: 'FAR',
    58006: 'FAR',
    58007: 'FAR',
    58008: 'FAR',
    58009: 'FAR',
    58011: 'FAR',
    58012: 'FAR',
    58013: 'FAR',
    58015: 'FAR',
    58016: 'FAR',
    58017: 'FAR',
    58018: 'FAR',
    58021: 'FAR',
    58027: 'FAR',
    58029: 'FAR',
    58030: 'FAR',
    58031: 'FAR',
    58032: 'FAR',
    58033: 'FAR',
    58035: 'FAR',
    58036: 'FAR',
    58038: 'FAR',
    58040: 'FAR',
    58041: 'FAR',
    58042: 'FAR',
    58043: 'FAR',
    58045: 'FAR',
    58046: 'FAR',
    58047: 'FAR',
    58048: 'FAR',
    58049: 'FAR',
    58051: 'FAR',
    58052: 'FAR',
    58053: 'FAR',
    58054: 'FAR',
    58056: 'FAR',
    58057: 'FAR',
    58058: 'FAR',
    58059: 'FAR',
    58060: 'FAR',
    58061: 'FAR',
    58062: 'FAR',
    58063: 'FAR',
    58064: 'FAR',
    58065: 'FAR',
    58067: 'FAR',
    58068: 'FAR',
    58069: 'FAR',
    58071: 'FAR',
    58072: 'FAR',
    58074: 'FAR',
    58075: 'FAR',
    58076: 'FAR',
    58077: 'FAR',
    58078: 'FAR',
    58079: 'FAR',
    58081: 'FAR',
    58102: 'FAR',
    58103: 'FAR',
    58104: 'FAR',
    58105: 'FAR',
    58106: 'FAR',
    58107: 'FAR',
    58108: 'FAR',
    58109: 'FAR',
    58121: 'FAR',
    58122: 'FAR',
    58124: 'FAR',
    58125: 'FAR',
    58126: 'FAR',
    58201: 'GFK',
    58202: 'GFK',
    58203: 'GFK',
    58204: 'GFK',
    58205: 'GFK',
    58206: 'GFK',
    58207: 'GFK',
    58208: 'GFK',
    58210: 'GFK',
    58212: 'GFK',
    58214: 'GFK',
    58216: 'GFK',
    58218: 'GFK',
    58219: 'GFK',
    58220: 'GFK',
    58222: 'GFK',
    58223: 'GFK',
    58224: 'GFK',
    58225: 'GFK',
    58227: 'GFK',
    58228: 'GFK',
    58229: 'GFK',
    58230: 'GFK',
    58231: 'GFK',
    58233: 'GFK',
    58235: 'GFK',
    58236: 'GFK',
    58237: 'GFK',
    58238: 'GFK',
    58239: 'GFK',
    58240: 'GFK',
    58241: 'GFK',
    58243: 'GFK',
    58244: 'GFK',
    58249: 'GFK',
    58250: 'GFK',
    58251: 'GFK',
    58254: 'GFK',
    58255: 'GFK',
    58256: 'GFK',
    58257: 'GFK',
    58258: 'GFK',
    58259: 'GFK',
    58260: 'GFK',
    58261: 'GFK',
    58262: 'GFK',
    58265: 'GFK',
    58266: 'GFK',
    58267: 'GFK',
    58269: 'GFK',
    58270: 'GFK',
    58271: 'GFK',
    58272: 'GFK',
    58273: 'GFK',
    58274: 'GFK',
    58275: 'GFK',
    58276: 'GFK',
    58277: 'GFK',
    58278: 'GFK',
    58281: 'GFK',
    58282: 'GFK',
    58301: 'GFK',
    58310: 'MOT',
    58311: 'GFK',
    58313: 'MOT',
    58316: 'MOT',
    58317: 'MOT',
    58318: 'MOT',
    58319: 'MOT',
    58321: 'GFK',
    58323: 'GFK',
    58324: 'GFK',
    58325: 'GFK',
    58327: 'GFK',
    58329: 'MOT',
    58330: 'GFK',
    58331: 'GFK',
    58332: 'MOT',
    58335: 'GFK',
    58338: 'GFK',
    58339: 'MOT',
    58341: 'BIS',
    58343: 'MOT',
    58344: 'GFK',
    58345: 'GFK',
    58346: 'MOT',
    58348: 'MOT',
    58351: 'MOT',
    58352: 'GFK',
    58353: 'MOT',
    58355: 'GFK',
    58356: 'GFK',
    58357: 'GFK',
    58361: 'GFK',
    58362: 'GFK',
    58363: 'MOT',
    58365: 'GFK',
    58366: 'MOT',
    58367: 'MOT',
    58368: 'MOT',
    58369: 'MOT',
    58370: 'GFK',
    58372: 'GFK',
    58374: 'GFK',
    58377: 'GFK',
    58379: 'GFK',
    58380: 'GFK',
    58381: 'GFK',
    58382: 'GFK',
    58384: 'MOT',
    58385: 'MOT',
    58386: 'MOT',
    58401: 'FAR',
    58402: 'FAR',
    58405: 'FAR',
    58413: 'BIS',
    58415: 'FAR',
    58416: 'GFK',
    58418: 'BIS',
    58420: 'FAR',
    58421: 'GFK',
    58422: 'BIS',
    58423: 'BIS',
    58424: 'FAR',
    58425: 'GFK',
    58426: 'FAR',
    58428: 'BIS',
    58429: 'FAR',
    58430: 'BIS',
    58431: 'FAR',
    58433: 'FAR',
    58436: 'FAR',
    58438: 'BIS',
    58439: 'FAR',
    58440: 'FAR',
    58441: 'FAR',
    58442: 'FAR',
    58443: 'GFK',
    58444: 'BIS',
    58445: 'GFK',
    58448: 'FAR',
    58451: 'BIS',
    58452: 'GFK',
    58454: 'FAR',
    58455: 'FAR',
    58456: 'FAR',
    58458: 'FAR',
    58460: 'BIS',
    58461: 'FAR',
    58463: 'BIS',
    58464: 'GFK',
    58466: 'FAR',
    58467: 'BIS',
    58472: 'FAR',
    58474: 'FAR',
    58475: 'BIS',
    58476: 'FAR',
    58477: 'BIS',
    58478: 'BIS',
    58479: 'FAR',
    58480: 'FAR',
    58481: 'FAR',
    58482: 'BIS',
    58483: 'BIS',
    58484: 'FAR',
    58486: 'BIS',
    58487: 'BIS',
    58488: 'BIS',
    58490: 'FAR',
    58492: 'FAR',
    58494: 'BIS',
    58495: 'BIS',
    58496: 'BIS',
    58497: 'FAR',
    58501: 'BIS',
    58502: 'BIS',
    58503: 'BIS',
    58504: 'BIS',
    58505: 'BIS',
    58506: 'BIS',
    58507: 'BIS',
    58520: 'BIS',
    58521: 'BIS',
    58523: 'BIS',
    58524: 'BIS',
    58528: 'BIS',
    58529: 'BIS',
    58530: 'BIS',
    58531: 'BIS',
    58532: 'BIS',
    58533: 'BIS',
    58535: 'BIS',
    58538: 'BIS',
    58540: 'MOT',
    58541: 'BIS',
    58542: 'BIS',
    58544: 'BIS',
    58545: 'BIS',
    58549: 'BIS',
    58552: 'BIS',
    58554: 'BIS',
    58558: 'BIS',
    58559: 'BIS',
    58560: 'BIS',
    58561: 'BIS',
    58562: 'BIS',
    58563: 'BIS',
    58564: 'BIS',
    58565: 'BIS',
    58566: 'BIS',
    58568: 'BIS',
    58569: 'BIS',
    58570: 'BIS',
    58571: 'BIS',
    58572: 'BIS',
    58573: 'BIS',
    58575: 'BIS',
    58576: 'BIS',
    58577: 'BIS',
    58579: 'BIS',
    58580: 'BIS',
    58581: 'BIS',
    58601: 'BIS',
    58602: 'BIS',
    58620: 'BIS',
    58621: 'BIS',
    58622: 'BIS',
    58623: 'BIS',
    58625: 'BIS',
    58626: 'BIS',
    58627: 'BIS',
    58630: 'BIS',
    58631: 'BIS',
    58632: 'BIS',
    58634: 'BIS',
    58636: 'BIS',
    58638: 'BIS',
    58639: 'BIS',
    58640: 'BIS',
    58641: 'BIS',
    58642: 'BIS',
    58643: 'BIS',
    58644: 'BIS',
    58645: 'BIS',
    58646: 'BIS',
    58647: 'BIS',
    58649: 'BIS',
    58650: 'BIS',
    58651: 'BIS',
    58652: 'BIS',
    58653: 'BIS',
    58654: 'BIS',
    58655: 'BIS',
    58656: 'BIS',
    58701: 'MOT',
    58702: 'MOT',
    58703: 'MOT',
    58704: 'MOT',
    58705: 'MOT',
    58707: 'MOT',
    58710: 'MOT',
    58711: 'MOT',
    58712: 'MOT',
    58713: 'MOT',
    58716: 'BIS',
    58718: 'MOT',
    58721: 'MOT',
    58722: 'MOT',
    58723: 'BIS',
    58725: 'MOT',
    58727: 'MOT',
    58730: 'MOT',
    58731: 'MOT',
    58733: 'MOT',
    58734: 'MOT',
    58735: 'MOT',
    58736: 'MOT',
    58737: 'MOT',
    58740: 'MOT',
    58741: 'MOT',
    58744: 'MOT',
    58746: 'MOT',
    58748: 'MOT',
    58750: 'MOT',
    58752: 'MOT',
    58755: 'MOT',
    58756: 'MOT',
    58757: 'BIS',
    58758: 'BIS',
    58759: 'MOT',
    58760: 'MOT',
    58761: 'MOT',
    58762: 'MOT',
    58763: 'MOT',
    58765: 'MOT',
    58768: 'MOT',
    58769: 'MOT',
    58770: 'MOT',
    58771: 'MOT',
    58772: 'MOT',
    58773: 'MOT',
    58775: 'MOT',
    58776: 'MOT',
    58778: 'BIS',
    58779: 'MOT',
    58781: 'MOT',
    58782: 'MOT',
    58783: 'MOT',
    58784: 'MOT',
    58785: 'MOT',
    58787: 'MOT',
    58788: 'MOT',
    58789: 'MOT',
    58790: 'MOT',
    58792: 'MOT',
    58793: 'MOT',
    58794: 'MOT',
    58795: 'MOT',
    58801: 'MOT',
    58802: 'MOT',
    58803: 'MOT',
    58830: 'MOT',
    58831: 'BIS',
    58833: 'MOT',
    58835: 'BIS',
    58838: 'BIS',
    58843: 'MOT',
    58844: 'MOT',
    58845: 'MOT',
    58847: 'BIS',
    58849: 'MOT',
    58852: 'MOT',
    58853: 'MOT',
    58854: 'BIS',
    58856: 'MOT',
    59001: 'BIL',
    59002: 'BIL',
    59003: 'BIL',
    59004: 'BIL',
    59006: 'BIL',
    59007: 'BIL',
    59008: 'BIL',
    59010: 'BIL',
    59011: 'HLN',
    59012: 'BIL',
    59013: 'BIL',
    59014: 'BIL',
    59015: 'BIL',
    59016: 'BIL',
    59018: 'BTM',
    59019: 'BIL',
    59020: 'BTM',
    59022: 'BIL',
    59024: 'BIL',
    59025: 'BIL',
    59026: 'BIL',
    59027: 'BTM',
    59028: 'BTM',
    59029: 'BIL',
    59030: 'BTM',
    59031: 'BIL',
    59032: 'GTF',
    59033: 'GTF',
    59034: 'BIL',
    59035: 'BIL',
    59036: 'HLN',
    59037: 'BIL',
    59038: 'BIL',
    59039: 'BIL',
    59041: 'BIL',
    59043: 'BIL',
    59044: 'BIL',
    59046: 'HLN',
    59047: 'BTM',
    59050: 'BIL',
    59052: 'BTM',
    59053: 'HLN',
    59054: 'BIL',
    59055: 'HLN',
    59057: 'BIL',
    59058: 'BIL',
    59059: 'BIL',
    59061: 'BTM',
    59062: 'BIL',
    59063: 'BIL',
    59064: 'BIL',
    59065: 'BTM',
    59066: 'BIL',
    59067: 'BIL',
    59068: 'BIL',
    59069: 'BIL',
    59070: 'BIL',
    59071: 'BIL',
    59072: 'BIL',
    59073: 'BIL',
    59074: 'HLN',
    59075: 'BIL',
    59076: 'BIL',
    59077: 'BIL',
    59078: 'HLN',
    59079: 'BIL',
    59081: 'BTM',
    59082: 'BTM',
    59083: 'BIL',
    59084: 'GTF',
    59085: 'HLN',
    59086: 'BTM',
    59087: 'GTF',
    59088: 'BIL',
    59089: 'BIL',
    59101: 'BIL',
    59102: 'BIL',
    59103: 'BIL',
    59104: 'BIL',
    59105: 'BIL',
    59106: 'BIL',
    59107: 'BIL',
    59108: 'BIL',
    59111: 'BIL',
    59112: 'BIL',
    59114: 'BIL',
    59115: 'BIL',
    59116: 'BIL',
    59117: 'BIL',
    59201: 'BIL',
    59211: 'BIL',
    59212: 'BIL',
    59213: 'BIL',
    59214: 'BIL',
    59215: 'BIL',
    59217: 'BIL',
    59218: 'BIL',
    59219: 'BIL',
    59221: 'BIL',
    59222: 'BIL',
    59223: 'GTF',
    59225: 'BIL',
    59226: 'BIL',
    59230: 'GTF',
    59231: 'GTF',
    59240: 'BIL',
    59241: 'GTF',
    59242: 'BIL',
    59243: 'BIL',
    59244: 'BIL',
    59247: 'BIL',
    59248: 'BIL',
    59250: 'BIL',
    59252: 'BIL',
    59253: 'BIL',
    59254: 'BIL',
    59255: 'BIL',
    59256: 'BIL',
    59257: 'BIL',
    59258: 'BIL',
    59259: 'BIL',
    59260: 'BIL',
    59261: 'GTF',
    59262: 'BIL',
    59263: 'BIL',
    59270: 'BIL',
    59273: 'GTF',
    59274: 'BIL',
    59275: 'BIL',
    59276: 'BIL',
    59301: 'BIL',
    59311: 'BIL',
    59312: 'BIL',
    59313: 'BIL',
    59314: 'BIL',
    59315: 'BIL',
    59316: 'BIL',
    59317: 'BIL',
    59318: 'GTF',
    59319: 'BIL',
    59322: 'BIL',
    59323: 'BIL',
    59324: 'BIL',
    59326: 'BIL',
    59327: 'BIL',
    59330: 'BIL',
    59332: 'BIL',
    59333: 'BIL',
    59336: 'BIL',
    59337: 'BIL',
    59338: 'BIL',
    59339: 'BIL',
    59341: 'BIL',
    59343: 'BIL',
    59344: 'BIL',
    59345: 'BIL',
    59347: 'BIL',
    59349: 'BIL',
    59351: 'BIL',
    59353: 'BIL',
    59354: 'BIL',
    59401: 'GTF',
    59402: 'GTF',
    59403: 'GTF',
    59404: 'GTF',
    59405: 'GTF',
    59406: 'GTF',
    59410: 'GTF',
    59411: 'MSO',
    59412: 'GTF',
    59414: 'GTF',
    59416: 'GTF',
    59417: 'MSO',
    59418: 'HLN',
    59419: 'GTF',
    59420: 'GTF',
    59421: 'GTF',
    59422: 'GTF',
    59424: 'GTF',
    59425: 'GTF',
    59427: 'GTF',
    59430: 'GTF',
    59432: 'GTF',
    59433: 'GTF',
    59434: 'MSO',
    59435: 'GTF',
    59436: 'GTF',
    59440: 'GTF',
    59441: 'HLN',
    59442: 'GTF',
    59443: 'GTF',
    59444: 'GTF',
    59446: 'GTF',
    59447: 'GTF',
    59448: 'GTF',
    59450: 'GTF',
    59451: 'GTF',
    59452: 'HLN',
    59453: 'HLN',
    59454: 'GTF',
    59456: 'GTF',
    59457: 'GTF',
    59460: 'GTF',
    59461: 'GTF',
    59462: 'GTF',
    59463: 'HLN',
    59464: 'HLN',
    59465: 'HLN',
    59466: 'GTF',
    59467: 'GTF',
    59468: 'GTF',
    59469: 'GTF',
    59471: 'GTF',
    59472: 'GTF',
    59474: 'GTF',
    59477: 'GTF',
    59479: 'GTF',
    59480: 'HLN',
    59482: 'GTF',
    59483: 'GTF',
    59484: 'GTF',
    59485: 'GTF',
    59486: 'GTF',
    59487: 'GTF',
    59489: 'GTF',
    59501: 'GTF',
    59520: 'GTF',
    59521: 'GTF',
    59522: 'GTF',
    59523: 'GTF',
    59524: 'GTF',
    59525: 'GTF',
    59526: 'GTF',
    59527: 'GTF',
    59528: 'GTF',
    59529: 'GTF',
    59530: 'GTF',
    59531: 'GTF',
    59532: 'GTF',
    59535: 'GTF',
    59537: 'GTF',
    59538: 'GTF',
    59540: 'GTF',
    59542: 'GTF',
    59544: 'GTF',
    59545: 'GTF',
    59546: 'GTF',
    59547: 'GTF',
    59601: 'HLN',
    59602: 'HLN',
    59604: 'HLN',
    59620: 'HLN',
    59623: 'HLN',
    59624: 'HLN',
    59625: 'HLN',
    59626: 'HLN',
    59631: 'HLN',
    59632: 'HLN',
    59633: 'HLN',
    59634: 'HLN',
    59635: 'HLN',
    59636: 'HLN',
    59638: 'HLN',
    59639: 'GTF',
    59640: 'HLN',
    59641: 'BTM',
    59642: 'HLN',
    59643: 'HLN',
    59644: 'HLN',
    59645: 'HLN',
    59647: 'HLN',
    59648: 'HLN',
    59701: 'BTM',
    59702: 'BTM',
    59703: 'BTM',
    59707: 'BTM',
    59710: 'BTM',
    59711: 'MSO',
    59713: 'HLN',
    59714: 'BTM',
    59715: 'BTM',
    59716: 'BTM',
    59717: 'BTM',
    59718: 'BTM',
    59719: 'BTM',
    59720: 'BTM',
    59721: 'BTM',
    59722: 'MSO',
    59724: 'BTM',
    59725: 'BTM',
    59727: 'BTM',
    59728: 'HLN',
    59729: 'BTM',
    59730: 'BTM',
    59731: 'HLN',
    59732: 'BTM',
    59733: 'MSO',
    59735: 'BTM',
    59736: 'BTM',
    59739: 'BTM',
    59740: 'BTM',
    59741: 'BTM',
    59743: 'BTM',
    59745: 'BTM',
    59746: 'BTM',
    59747: 'BTM',
    59748: 'MSO',
    59749: 'BTM',
    59750: 'BTM',
    59751: 'BTM',
    59752: 'BTM',
    59754: 'BTM',
    59755: 'BTM',
    59756: 'MSO',
    59758: 'BTM',
    59759: 'BTM',
    59760: 'BTM',
    59761: 'BTM',
    59762: 'BTM',
    59771: 'BTM',
    59772: 'BTM',
    59773: 'BTM',
    59801: 'MSO',
    59802: 'MSO',
    59803: 'MSO',
    59804: 'MSO',
    59806: 'MSO',
    59807: 'MSO',
    59808: 'MSO',
    59812: 'MSO',
    59820: 'MSO',
    59821: 'MSO',
    59823: 'MSO',
    59824: 'MSO',
    59825: 'MSO',
    59826: 'MSO',
    59827: 'BTM',
    59828: 'MSO',
    59829: 'BTM',
    59830: 'MSO',
    59831: 'MSO',
    59832: 'MSO',
    59833: 'MSO',
    59834: 'MSO',
    59835: 'MSO',
    59837: 'MSO',
    59840: 'MSO',
    59841: 'MSO',
    59842: 'MSO',
    59843: 'HLN',
    59844: 'MSO',
    59845: 'MSO',
    59846: 'MSO',
    59847: 'MSO',
    59848: 'MSO',
    59851: 'MSO',
    59853: 'MSO',
    59854: 'GTF',
    59855: 'MSO',
    59856: 'MSO',
    59858: 'MSO',
    59859: 'MSO',
    59860: 'MSO',
    59863: 'MSO',
    59864: 'MSO',
    59865: 'MSO',
    59866: 'MSO',
    59867: 'MSO',
    59868: 'MSO',
    59870: 'MSO',
    59871: 'BTM',
    59872: 'MSO',
    59873: 'MSO',
    59874: 'MSO',
    59875: 'MSO',
    59901: 'GTF',
    59903: 'MSO',
    59904: 'MSO',
    59910: 'MSO',
    59911: 'MSO',
    59912: 'MSO',
    59913: 'MSO',
    59914: 'MSO',
    59915: 'MSO',
    59916: 'MSO',
    59917: 'MSO',
    59918: 'MSO',
    59919: 'MSO',
    59920: 'MSO',
    59921: 'MSO',
    59922: 'MSO',
    59923: 'MSO',
    59925: 'MSO',
    59926: 'MSO',
    59927: 'MSO',
    59928: 'MSO',
    59929: 'MSO',
    59930: 'MSO',
    59931: 'MSO',
    59932: 'MSO',
    59933: 'MSO',
    59934: 'MSO',
    59935: 'MSO',
    59936: 'MSO',
    59937: 'MSO',
    60001: 'ORD',
    60002: 'ORD',
    60004: 'ORD',
    60005: 'ORD',
    60006: 'ORD',
    60007: 'ORD',
    60008: 'ORD',
    60009: 'ORD',
    60010: 'ORD',
    60011: 'ORD',
    60012: 'ORD',
    60013: 'ORD',
    60014: 'ORD',
    60015: 'ORD',
    60016: 'ORD',
    60017: 'ORD',
    60018: 'ORD',
    60019: 'ORD',
    60020: 'ORD',
    60021: 'ORD',
    60022: 'ORD',
    60025: 'ORD',
    60026: 'ORD',
    60029: 'ORD',
    60030: 'ORD',
    60031: 'ORD',
    60033: 'ORD',
    60034: 'ORD',
    60035: 'ORD',
    60037: 'ORD',
    60038: 'ORD',
    60039: 'ORD',
    60040: 'ORD',
    60041: 'ORD',
    60042: 'ORD',
    60043: 'ORD',
    60044: 'ORD',
    60045: 'ORD',
    60046: 'ORD',
    60047: 'ORD',
    60048: 'ORD',
    60049: 'ORD',
    60050: 'ORD',
    60051: 'ORD',
    60053: 'ORD',
    60055: 'ORD',
    60056: 'ORD',
    60060: 'ORD',
    60061: 'ORD',
    60062: 'ORD',
    60064: 'ORD',
    60065: 'ORD',
    60067: 'ORD',
    60068: 'ORD',
    60069: 'ORD',
    60070: 'ORD',
    60071: 'ORD',
    60072: 'ORD',
    60073: 'ORD',
    60074: 'ORD',
    60075: 'MKE',
    60076: 'ORD',
    60077: 'ORD',
    60078: 'ORD',
    60079: 'ORD',
    60081: 'ORD',
    60082: 'ORD',
    60083: 'ORD',
    60084: 'ORD',
    60085: 'ORD',
    60086: 'ORD',
    60087: 'ORD',
    60088: 'ORD',
    60089: 'ORD',
    60090: 'ORD',
    60091: 'ORD',
    60092: 'ORD',
    60093: 'ORD',
    60094: 'ORD',
    60095: 'ORD',
    60096: 'ORD',
    60097: 'ORD',
    60098: 'ORD',
    60099: 'ORD',
    60101: 'ORD',
    60102: 'ORD',
    60103: 'ORD',
    60104: 'ORD',
    60105: 'ORD',
    60106: 'ORD',
    60107: 'ORD',
    60108: 'ORD',
    60109: 'ORD',
    60110: 'ORD',
    60111: 'ORD',
    60112: 'ORD',
    60113: 'ORD',
    60115: 'ORD',
    60116: 'ORD',
    60117: 'ORD',
    60118: 'ORD',
    60119: 'ORD',
    60120: 'ORD',
    60121: 'ORD',
    60122: 'ORD',
    60123: 'ORD',
    60124: 'ORD',
    60125: 'ORD',
    60126: 'ORD',
    60128: 'ORD',
    60129: 'ORD',
    60130: 'ORD',
    60131: 'ORD',
    60132: 'ORD',
    60133: 'ORD',
    60134: 'ORD',
    60135: 'ORD',
    60136: 'ORD',
    60137: 'ORD',
    60138: 'ORD',
    60139: 'ORD',
    60140: 'ORD',
    60141: 'ORD',
    60142: 'ORD',
    60143: 'ORD',
    60144: 'ORD',
    60145: 'ORD',
    60146: 'ORD',
    60147: 'ORD',
    60148: 'ORD',
    60150: 'ORD',
    60151: 'ORD',
    60152: 'ORD',
    60153: 'ORD',
    60154: 'ORD',
    60155: 'ORD',
    60156: 'ORD',
    60157: 'ORD',
    60159: 'ORD',
    60160: 'ORD',
    60161: 'ORD',
    60162: 'ORD',
    60163: 'ORD',
    60164: 'ORD',
    60165: 'ORD',
    60168: 'ORD',
    60169: 'ORD',
    60170: 'ORD',
    60171: 'ORD',
    60172: 'ORD',
    60173: 'ORD',
    60174: 'ORD',
    60175: 'ORD',
    60176: 'ORD',
    60177: 'ORD',
    60178: 'ORD',
    60179: 'ORD',
    60180: 'ORD',
    60181: 'ORD',
    60183: 'ORD',
    60184: 'ORD',
    60185: 'ORD',
    60186: 'ORD',
    60187: 'ORD',
    60188: 'ORD',
    60189: 'ORD',
    60190: 'ORD',
    60191: 'ORD',
    60192: 'ORD',
    60193: 'ORD',
    60194: 'ORD',
    60195: 'ORD',
    60196: 'ORD',
    60197: 'ORD',
    60199: 'ORD',
    60201: 'ORD',
    60202: 'ORD',
    60203: 'ORD',
    60204: 'ORD',
    60208: 'ORD',
    60209: 'ORD',
    60290: 'ORD',
    60301: 'ORD',
    60302: 'ORD',
    60303: 'ORD',
    60304: 'ORD',
    60305: 'ORD',
    60399: 'ORD',
    60401: 'ORD',
    60402: 'ORD',
    60403: 'ORD',
    60404: 'ORD',
    60406: 'ORD',
    60407: 'ORD',
    60408: 'ORD',
    60409: 'ORD',
    60410: 'ORD',
    60411: 'ORD',
    60412: 'ORD',
    60415: 'ORD',
    60416: 'ORD',
    60417: 'ORD',
    60418: 'ORD',
    60419: 'ORD',
    60420: 'ORD',
    60421: 'ORD',
    60422: 'ORD',
    60423: 'ORD',
    60424: 'ORD',
    60425: 'ORD',
    60426: 'ORD',
    60428: 'ORD',
    60429: 'ORD',
    60430: 'ORD',
    60431: 'ORD',
    60432: 'ORD',
    60433: 'ORD',
    60434: 'ORD',
    60435: 'ORD',
    60436: 'ORD',
    60437: 'ORD',
    60438: 'ORD',
    60439: 'ORD',
    60440: 'ORD',
    60441: 'ORD',
    60442: 'ORD',
    60443: 'ORD',
    60444: 'ORD',
    60445: 'ORD',
    60446: 'ORD',
    60447: 'ORD',
    60448: 'ORD',
    60449: 'ORD',
    60450: 'ORD',
    60451: 'ORD',
    60452: 'ORD',
    60453: 'ORD',
    60454: 'ORD',
    60455: 'ORD',
    60456: 'ORD',
    60457: 'ORD',
    60458: 'ORD',
    60459: 'ORD',
    60460: 'ORD',
    60461: 'ORD',
    60462: 'ORD',
    60463: 'ORD',
    60464: 'ORD',
    60465: 'ORD',
    60466: 'ORD',
    60467: 'ORD',
    60468: 'ORD',
    60469: 'ORD',
    60470: 'ORD',
    60471: 'ORD',
    60472: 'ORD',
    60473: 'ORD',
    60474: 'ORD',
    60475: 'ORD',
    60476: 'ORD',
    60477: 'ORD',
    60478: 'ORD',
    60479: 'ORD',
    60480: 'ORD',
    60481: 'ORD',
    60482: 'ORD',
    60484: 'ORD',
    60487: 'ORD',
    60490: 'ORD',
    60491: 'ORD',
    60499: 'ORD',
    60501: 'ORD',
    60502: 'ORD',
    60503: 'ORD',
    60504: 'ORD',
    60505: 'ORD',
    60506: 'ORD',
    60507: 'ORD',
    60510: 'ORD',
    60511: 'ORD',
    60512: 'ORD',
    60513: 'ORD',
    60514: 'ORD',
    60515: 'ORD',
    60516: 'ORD',
    60517: 'ORD',
    60518: 'ORD',
    60519: 'ORD',
    60520: 'ORD',
    60521: 'ORD',
    60522: 'ORD',
    60523: 'ORD',
    60525: 'ORD',
    60526: 'ORD',
    60527: 'ORD',
    60530: 'ORD',
    60531: 'ORD',
    60532: 'ORD',
    60534: 'ORD',
    60536: 'ORD',
    60537: 'ORD',
    60538: 'ORD',
    60539: 'ORD',
    60540: 'ORD',
    60541: 'ORD',
    60542: 'ORD',
    60543: 'ORD',
    60544: 'ORD',
    60545: 'ORD',
    60546: 'ORD',
    60548: 'ORD',
    60549: 'ORD',
    60550: 'ORD',
    60551: 'ORD',
    60552: 'ORD',
    60553: 'ORD',
    60554: 'ORD',
    60555: 'ORD',
    60556: 'ORD',
    60557: 'ORD',
    60558: 'ORD',
    60559: 'ORD',
    60560: 'ORD',
    60561: 'ORD',
    60563: 'ORD',
    60564: 'ORD',
    60565: 'ORD',
    60566: 'ORD',
    60567: 'ORD',
    60568: 'ORD',
    60569: 'ORD',
    60570: 'ORD',
    60572: 'ORD',
    60585: 'ORD',
    60586: 'ORD',
    60597: 'ORD',
    60598: 'ORD',
    60599: 'ORD',
    60601: 'ORD',
    60602: 'ORD',
    60603: 'ORD',
    60604: 'ORD',
    60605: 'ORD',
    60606: 'ORD',
    60607: 'ORD',
    60608: 'ORD',
    60609: 'ORD',
    60610: 'ORD',
    60611: 'ORD',
    60612: 'ORD',
    60613: 'ORD',
    60614: 'ORD',
    60615: 'ORD',
    60616: 'ORD',
    60617: 'ORD',
    60618: 'ORD',
    60619: 'ORD',
    60620: 'ORD',
    60621: 'ORD',
    60622: 'ORD',
    60623: 'ORD',
    60624: 'ORD',
    60625: 'ORD',
    60626: 'ORD',
    60628: 'ORD',
    60629: 'ORD',
    60630: 'ORD',
    60631: 'ORD',
    60632: 'ORD',
    60633: 'ORD',
    60634: 'ORD',
    60636: 'ORD',
    60637: 'ORD',
    60638: 'ORD',
    60639: 'ORD',
    60640: 'ORD',
    60641: 'ORD',
    60642: 'ORD',
    60643: 'ORD',
    60644: 'ORD',
    60645: 'ORD',
    60646: 'ORD',
    60647: 'ORD',
    60649: 'ORD',
    60651: 'ORD',
    60652: 'ORD',
    60653: 'ORD',
    60654: 'ORD',
    60655: 'ORD',
    60656: 'ORD',
    60657: 'ORD',
    60659: 'ORD',
    60660: 'ORD',
    60661: 'ORD',
    60663: 'ORD',
    60664: 'ORD',
    60666: 'ORD',
    60668: 'ORD',
    60669: 'ORD',
    60670: 'ORD',
    60673: 'ORD',
    60674: 'ORD',
    60675: 'ORD',
    60677: 'ORD',
    60678: 'ORD',
    60679: 'ORD',
    60680: 'ORD',
    60681: 'ORD',
    60682: 'ORD',
    60684: 'ORD',
    60685: 'ORD',
    60686: 'ORD',
    60687: 'ORD',
    60688: 'ORD',
    60689: 'ORD',
    60690: 'ORD',
    60691: 'ORD',
    60693: 'ORD',
    60694: 'ORD',
    60695: 'ORD',
    60696: 'ORD',
    60697: 'ORD',
    60699: 'ORD',
    60701: 'ORD',
    60706: 'ORD',
    60707: 'ORD',
    60712: 'ORD',
    60714: 'ORD',
    60803: 'ORD',
    60804: 'ORD',
    60805: 'ORD',
    60827: 'ORD',
    60901: 'ORD',
    60910: 'ORD',
    60911: 'ORD',
    60912: 'ORD',
    60913: 'ORD',
    60914: 'ORD',
    60915: 'ORD',
    60917: 'ORD',
    60918: 'BMI',
    60919: 'ORD',
    60920: 'ORD',
    60921: 'BMI',
    60922: 'ORD',
    60924: 'BMI',
    60926: 'BMI',
    60927: 'ORD',
    60928: 'BMI',
    60929: 'ORD',
    60930: 'BMI',
    60931: 'ORD',
    60932: 'BMI',
    60933: 'BMI',
    60934: 'ORD',
    60935: 'ORD',
    60936: 'BMI',
    60938: 'BMI',
    60939: 'BMI',
    60940: 'ORD',
    60941: 'ORD',
    60942: 'BMI',
    60944: 'ORD',
    60945: 'BMI',
    60946: 'ORD',
    60948: 'BMI',
    60949: 'BMI',
    60950: 'ORD',
    60951: 'ORD',
    60952: 'BMI',
    60953: 'BMI',
    60954: 'ORD',
    60955: 'BMI',
    60956: 'ORD',
    60957: 'BMI',
    60958: 'BMI',
    60959: 'BMI',
    60960: 'BMI',
    60961: 'ORD',
    60962: 'BMI',
    60963: 'BMI',
    60964: 'ORD',
    60966: 'BMI',
    60967: 'BMI',
    60968: 'BMI',
    60969: 'ORD',
    60970: 'BMI',
    60973: 'BMI',
    60974: 'BMI',
    61001: 'DBQ',
    61006: 'ORD',
    61007: 'DBQ',
    61008: 'ORD',
    61010: 'ORD',
    61011: 'ORD',
    61012: 'ORD',
    61013: 'DBQ',
    61014: 'DBQ',
    61015: 'ORD',
    61016: 'ORD',
    61018: 'DBQ',
    61019: 'DBQ',
    61020: 'ORD',
    61021: 'MLI',
    61024: 'ORD',
    61025: 'DBQ',
    61027: 'DBQ',
    61028: 'DBQ',
    61030: 'DBQ',
    61031: 'ORD',
    61032: 'DBQ',
    61036: 'DBQ',
    61037: 'MLI',
    61038: 'ORD',
    61039: 'DBQ',
    61041: 'DBQ',
    61042: 'MLI',
    61043: 'ORD',
    61044: 'DBQ',
    61046: 'DBQ',
    61047: 'ORD',
    61048: 'DBQ',
    61049: 'ORD',
    61050: 'DBQ',
    61051: 'DBQ',
    61052: 'ORD',
    61053: 'DBQ',
    61054: 'DBQ',
    61057: 'ORD',
    61059: 'DBQ',
    61060: 'DBQ',
    61061: 'ORD',
    61062: 'DBQ',
    61063: 'ORD',
    61064: 'DBQ',
    61065: 'ORD',
    61067: 'DBQ',
    61068: 'ORD',
    61070: 'DBQ',
    61071: 'MLI',
    61072: 'ORD',
    61073: 'ORD',
    61074: 'DBQ',
    61075: 'DBQ',
    61077: 'ORD',
    61078: 'DBQ',
    61079: 'ORD',
    61080: 'ORD',
    61081: 'MLI',
    61084: 'ORD',
    61085: 'DBQ',
    61087: 'DBQ',
    61088: 'ORD',
    61089: 'DBQ',
    61091: 'MLI',
    61101: 'ORD',
    61102: 'ORD',
    61103: 'ORD',
    61104: 'ORD',
    61105: 'ORD',
    61106: 'ORD',
    61107: 'ORD',
    61108: 'ORD',
    61109: 'ORD',
    61110: 'ORD',
    61111: 'ORD',
    61112: 'ORD',
    61114: 'ORD',
    61115: 'ORD',
    61125: 'ORD',
    61126: 'ORD',
    61130: 'ORD',
    61131: 'ORD',
    61132: 'ORD',
    61201: 'MLI',
    61204: 'MLI',
    61230: 'MLI',
    61231: 'MLI',
    61232: 'MLI',
    61233: 'MLI',
    61234: 'MLI',
    61235: 'MLI',
    61236: 'MLI',
    61237: 'MLI',
    61238: 'MLI',
    61239: 'MLI',
    61240: 'MLI',
    61241: 'MLI',
    61242: 'MLI',
    61243: 'MLI',
    61244: 'MLI',
    61250: 'MLI',
    61251: 'MLI',
    61252: 'MLI',
    61254: 'MLI',
    61256: 'MLI',
    61257: 'MLI',
    61258: 'MLI',
    61259: 'MLI',
    61260: 'MLI',
    61261: 'MLI',
    61262: 'MLI',
    61263: 'MLI',
    61264: 'MLI',
    61265: 'MLI',
    61266: 'MLI',
    61270: 'MLI',
    61272: 'MLI',
    61273: 'MLI',
    61274: 'MLI',
    61275: 'MLI',
    61276: 'MLI',
    61277: 'MLI',
    61278: 'MLI',
    61279: 'MLI',
    61281: 'MLI',
    61282: 'MLI',
    61283: 'MLI',
    61284: 'MLI',
    61285: 'DBQ',
    61299: 'MLI',
    61301: 'ORD',
    61310: 'ORD',
    61311: 'ORD',
    61312: 'ORD',
    61313: 'ORD',
    61314: 'MLI',
    61315: 'ORD',
    61316: 'ORD',
    61317: 'ORD',
    61318: 'ORD',
    61319: 'ORD',
    61320: 'ORD',
    61321: 'ORD',
    61322: 'ORD',
    61323: 'ORD',
    61324: 'ORD',
    61325: 'ORD',
    61326: 'ORD',
    61327: 'ORD',
    61328: 'MLI',
    61329: 'ORD',
    61330: 'ORD',
    61331: 'ORD',
    61332: 'ORD',
    61333: 'ORD',
    61334: 'ORD',
    61335: 'ORD',
    61336: 'ORD',
    61337: 'ORD',
    61338: 'MLI',
    61340: 'ORD',
    61341: 'ORD',
    61342: 'ORD',
    61344: 'MLI',
    61345: 'MLI',
    61346: 'MLI',
    61348: 'ORD',
    61349: 'ORD',
    61350: 'ORD',
    61353: 'ORD',
    61354: 'ORD',
    61356: 'ORD',
    61358: 'ORD',
    61359: 'ORD',
    61360: 'ORD',
    61361: 'MLI',
    61362: 'ORD',
    61363: 'ORD',
    61364: 'ORD',
    61367: 'ORD',
    61368: 'MLI',
    61369: 'ORD',
    61370: 'ORD',
    61371: 'ORD',
    61372: 'ORD',
    61373: 'ORD',
    61374: 'ORD',
    61375: 'ORD',
    61376: 'MLI',
    61377: 'ORD',
    61378: 'ORD',
    61379: 'MLI',
    61401: 'MLI',
    61402: 'MLI',
    61410: 'MLI',
    61411: 'BMI',
    61412: 'MLI',
    61413: 'MLI',
    61414: 'MLI',
    61415: 'BMI',
    61416: 'BMI',
    61417: 'MLI',
    61418: 'MLI',
    61419: 'MLI',
    61420: 'BMI',
    61421: 'MLI',
    61422: 'BMI',
    61423: 'MLI',
    61424: 'MLI',
    61425: 'MLI',
    61426: 'MLI',
    61427: 'BMI',
    61428: 'MLI',
    61430: 'MLI',
    61431: 'BMI',
    61432: 'BMI',
    61433: 'BMI',
    61434: 'MLI',
    61435: 'MLI',
    61436: 'MLI',
    61437: 'MLI',
    61438: 'BMI',
    61439: 'MLI',
    61440: 'BMI',
    61441: 'BMI',
    61442: 'MLI',
    61443: 'MLI',
    61447: 'MLI',
    61448: 'MLI',
    61449: 'MLI',
    61450: 'STL',
    61451: 'MLI',
    61452: 'BMI',
    61453: 'MLI',
    61454: 'MLI',
    61455: 'BMI',
    61458: 'MLI',
    61459: 'BMI',
    61460: 'MLI',
    61462: 'MLI',
    61465: 'MLI',
    61466: 'MLI',
    61467: 'MLI',
    61468: 'MLI',
    61469: 'MLI',
    61470: 'BMI',
    61471: 'MLI',
    61472: 'MLI',
    61473: 'MLI',
    61474: 'MLI',
    61475: 'BMI',
    61476: 'MLI',
    61477: 'BMI',
    61478: 'MLI',
    61479: 'MLI',
    61480: 'MLI',
    61482: 'BMI',
    61483: 'MLI',
    61484: 'BMI',
    61485: 'MLI',
    61486: 'MLI',
    61488: 'MLI',
    61489: 'MLI',
    61490: 'MLI',
    61491: 'MLI',
    61501: 'BMI',
    61516: 'BMI',
    61517: 'MLI',
    61519: 'BMI',
    61520: 'BMI',
    61523: 'MLI',
    61524: 'BMI',
    61525: 'MLI',
    61526: 'MLI',
    61528: 'MLI',
    61529: 'MLI',
    61530: 'BMI',
    61531: 'BMI',
    61532: 'BMI',
    61533: 'BMI',
    61534: 'BMI',
    61535: 'BMI',
    61536: 'MLI',
    61537: 'MLI',
    61539: 'BMI',
    61540: 'ORD',
    61541: 'ORD',
    61542: 'BMI',
    61543: 'BMI',
    61544: 'BMI',
    61545: 'ORD',
    61546: 'BMI',
    61547: 'BMI',
    61548: 'BMI',
    61550: 'BMI',
    61552: 'MLI',
    61553: 'BMI',
    61554: 'BMI',
    61555: 'BMI',
    61558: 'BMI',
    61559: 'MLI',
    61560: 'ORD',
    61561: 'BMI',
    61562: 'MLI',
    61563: 'BMI',
    61564: 'BMI',
    61565: 'MLI',
    61567: 'BMI',
    61568: 'BMI',
    61569: 'MLI',
    61570: 'ORD',
    61571: 'BMI',
    61572: 'MLI',
    61601: 'MLI',
    61602: 'BMI',
    61603: 'MLI',
    61604: 'MLI',
    61605: 'BMI',
    61606: 'MLI',
    61607: 'BMI',
    61610: 'BMI',
    61611: 'BMI',
    61612: 'MLI',
    61613: 'MLI',
    61614: 'MLI',
    61615: 'MLI',
    61616: 'MLI',
    61625: 'MLI',
    61629: 'MLI',
    61630: 'BMI',
    61633: 'MLI',
    61634: 'MLI',
    61635: 'MLI',
    61636: 'MLI',
    61637: 'MLI',
    61638: 'MLI',
    61639: 'MLI',
    61641: 'BMI',
    61643: 'MLI',
    61650: 'MLI',
    61651: 'MLI',
    61652: 'MLI',
    61653: 'MLI',
    61654: 'MLI',
    61655: 'MLI',
    61656: 'MLI',
    61701: 'BMI',
    61702: 'BMI',
    61704: 'BMI',
    61705: 'BMI',
    61709: 'BMI',
    61710: 'BMI',
    61720: 'BMI',
    61721: 'BMI',
    61722: 'BMI',
    61723: 'BMI',
    61724: 'BMI',
    61725: 'BMI',
    61726: 'BMI',
    61727: 'BMI',
    61728: 'BMI',
    61729: 'BMI',
    61730: 'BMI',
    61731: 'BMI',
    61732: 'BMI',
    61733: 'BMI',
    61734: 'BMI',
    61735: 'BMI',
    61736: 'BMI',
    61737: 'BMI',
    61738: 'BMI',
    61739: 'BMI',
    61740: 'ORD',
    61741: 'BMI',
    61742: 'BMI',
    61743: 'ORD',
    61744: 'BMI',
    61745: 'BMI',
    61747: 'BMI',
    61748: 'BMI',
    61749: 'BMI',
    61750: 'BMI',
    61751: 'BMI',
    61752: 'BMI',
    61753: 'BMI',
    61754: 'BMI',
    61755: 'BMI',
    61756: 'BMI',
    61758: 'BMI',
    61759: 'BMI',
    61760: 'ORD',
    61761: 'BMI',
    61764: 'ORD',
    61769: 'ORD',
    61770: 'BMI',
    61771: 'BMI',
    61772: 'BMI',
    61773: 'BMI',
    61774: 'BMI',
    61775: 'BMI',
    61776: 'BMI',
    61777: 'BMI',
    61778: 'BMI',
    61790: 'BMI',
    61791: 'BMI',
    61799: 'BMI',
    61801: 'BMI',
    61802: 'BMI',
    61803: 'BMI',
    61810: 'BMI',
    61811: 'BMI',
    61812: 'BMI',
    61813: 'BMI',
    61814: 'BMI',
    61815: 'BMI',
    61816: 'BMI',
    61817: 'BMI',
    61818: 'BMI',
    61820: 'BMI',
    61821: 'BMI',
    61822: 'BMI',
    61824: 'BMI',
    61825: 'BMI',
    61826: 'BMI',
    61830: 'BMI',
    61831: 'BMI',
    61832: 'BMI',
    61833: 'BMI',
    61834: 'BMI',
    61839: 'BMI',
    61840: 'BMI',
    61841: 'BMI',
    61842: 'BMI',
    61843: 'BMI',
    61844: 'BMI',
    61845: 'BMI',
    61846: 'BMI',
    61847: 'BMI',
    61848: 'BMI',
    61849: 'BMI',
    61850: 'BMI',
    61851: 'BMI',
    61852: 'BMI',
    61853: 'BMI',
    61854: 'BMI',
    61855: 'BMI',
    61856: 'BMI',
    61857: 'BMI',
    61858: 'BMI',
    61859: 'BMI',
    61862: 'BMI',
    61863: 'BMI',
    61864: 'BMI',
    61865: 'BMI',
    61866: 'BMI',
    61870: 'BMI',
    61871: 'BMI',
    61872: 'BMI',
    61873: 'BMI',
    61874: 'BMI',
    61875: 'BMI',
    61876: 'BMI',
    61877: 'BMI',
    61878: 'BMI',
    61880: 'BMI',
    61882: 'BMI',
    61883: 'BMI',
    61884: 'BMI',
    61910: 'BMI',
    61911: 'BMI',
    61912: 'BMI',
    61913: 'BMI',
    61914: 'BMI',
    61917: 'BMI',
    61919: 'BMI',
    61920: 'BMI',
    61924: 'BMI',
    61925: 'BMI',
    61928: 'BMI',
    61929: 'BMI',
    61930: 'BMI',
    61931: 'BMI',
    61932: 'BMI',
    61933: 'BMI',
    61936: 'BMI',
    61937: 'BMI',
    61938: 'BMI',
    61940: 'BMI',
    61941: 'BMI',
    61942: 'BMI',
    61943: 'BMI',
    61944: 'BMI',
    61949: 'BMI',
    61951: 'BMI',
    61953: 'BMI',
    61955: 'BMI',
    61956: 'BMI',
    61957: 'BMI',
    62001: 'STL',
    62002: 'STL',
    62006: 'STL',
    62009: 'STL',
    62010: 'STL',
    62011: 'STL',
    62012: 'STL',
    62013: 'STL',
    62014: 'STL',
    62015: 'STL',
    62016: 'STL',
    62017: 'STL',
    62018: 'STL',
    62019: 'STL',
    62021: 'STL',
    62022: 'STL',
    62023: 'STL',
    62024: 'STL',
    62025: 'STL',
    62026: 'STL',
    62027: 'STL',
    62028: 'STL',
    62030: 'STL',
    62031: 'STL',
    62032: 'STL',
    62033: 'STL',
    62034: 'STL',
    62035: 'STL',
    62036: 'STL',
    62037: 'STL',
    62040: 'STL',
    62044: 'STL',
    62045: 'STL',
    62046: 'STL',
    62047: 'STL',
    62048: 'STL',
    62049: 'STL',
    62050: 'STL',
    62051: 'STL',
    62052: 'STL',
    62053: 'STL',
    62054: 'STL',
    62056: 'STL',
    62058: 'STL',
    62059: 'STL',
    62060: 'STL',
    62061: 'STL',
    62062: 'STL',
    62063: 'STL',
    62065: 'STL',
    62067: 'STL',
    62069: 'STL',
    62070: 'STL',
    62071: 'STL',
    62074: 'STL',
    62075: 'STL',
    62076: 'STL',
    62077: 'STL',
    62078: 'STL',
    62079: 'STL',
    62080: 'STL',
    62081: 'STL',
    62082: 'STL',
    62083: 'STL',
    62084: 'STL',
    62085: 'STL',
    62086: 'STL',
    62087: 'STL',
    62088: 'STL',
    62089: 'STL',
    62090: 'STL',
    62091: 'STL',
    62092: 'STL',
    62093: 'STL',
    62094: 'STL',
    62095: 'STL',
    62097: 'STL',
    62098: 'STL',
    62201: 'STL',
    62202: 'STL',
    62203: 'STL',
    62204: 'STL',
    62205: 'STL',
    62206: 'STL',
    62207: 'STL',
    62208: 'STL',
    62214: 'STL',
    62215: 'STL',
    62216: 'STL',
    62217: 'STL',
    62218: 'STL',
    62219: 'STL',
    62220: 'STL',
    62221: 'STL',
    62222: 'STL',
    62223: 'STL',
    62224: 'STL',
    62225: 'STL',
    62226: 'STL',
    62230: 'STL',
    62231: 'STL',
    62232: 'STL',
    62233: 'STL',
    62234: 'STL',
    62236: 'STL',
    62237: 'STL',
    62238: 'STL',
    62239: 'STL',
    62240: 'STL',
    62241: 'STL',
    62242: 'STL',
    62243: 'STL',
    62244: 'STL',
    62245: 'STL',
    62246: 'STL',
    62247: 'STL',
    62248: 'STL',
    62249: 'STL',
    62250: 'STL',
    62252: 'STL',
    62253: 'STL',
    62254: 'STL',
    62255: 'STL',
    62256: 'STL',
    62257: 'STL',
    62258: 'STL',
    62259: 'STL',
    62260: 'STL',
    62261: 'STL',
    62262: 'STL',
    62263: 'STL',
    62264: 'STL',
    62265: 'STL',
    62266: 'STL',
    62268: 'STL',
    62269: 'STL',
    62271: 'STL',
    62272: 'STL',
    62273: 'STL',
    62274: 'STL',
    62275: 'STL',
    62277: 'STL',
    62278: 'STL',
    62279: 'STL',
    62280: 'STL',
    62281: 'STL',
    62282: 'STL',
    62284: 'STL',
    62285: 'STL',
    62286: 'STL',
    62288: 'STL',
    62289: 'STL',
    62292: 'STL',
    62293: 'STL',
    62294: 'STL',
    62295: 'STL',
    62297: 'STL',
    62298: 'STL',
    62301: 'STL',
    62305: 'STL',
    62306: 'STL',
    62311: 'STL',
    62312: 'STL',
    62313: 'STL',
    62314: 'STL',
    62316: 'STL',
    62319: 'STL',
    62320: 'STL',
    62321: 'STL',
    62323: 'STL',
    62324: 'STL',
    62325: 'STL',
    62326: 'STL',
    62329: 'STL',
    62330: 'STL',
    62334: 'STL',
    62336: 'STL',
    62338: 'STL',
    62339: 'STL',
    62340: 'STL',
    62341: 'STL',
    62343: 'STL',
    62344: 'STL',
    62345: 'STL',
    62346: 'STL',
    62347: 'STL',
    62348: 'STL',
    62349: 'STL',
    62351: 'STL',
    62352: 'STL',
    62353: 'STL',
    62354: 'STL',
    62355: 'STL',
    62356: 'STL',
    62357: 'STL',
    62358: 'STL',
    62359: 'STL',
    62360: 'STL',
    62361: 'STL',
    62362: 'STL',
    62363: 'STL',
    62365: 'STL',
    62366: 'STL',
    62367: 'STL',
    62370: 'STL',
    62373: 'STL',
    62374: 'STL',
    62375: 'STL',
    62376: 'STL',
    62378: 'STL',
    62379: 'STL',
    62380: 'STL',
    62401: 'STL',
    62410: 'STL',
    62411: 'STL',
    62413: 'STL',
    62414: 'STL',
    62417: 'STL',
    62418: 'STL',
    62419: 'STL',
    62420: 'STL',
    62421: 'STL',
    62422: 'STL',
    62423: 'BMI',
    62424: 'STL',
    62425: 'STL',
    62426: 'STL',
    62427: 'STL',
    62428: 'STL',
    62431: 'STL',
    62432: 'STL',
    62433: 'STL',
    62434: 'STL',
    62435: 'STL',
    62436: 'STL',
    62438: 'STL',
    62439: 'STL',
    62440: 'STL',
    62441: 'STL',
    62442: 'STL',
    62443: 'STL',
    62444: 'STL',
    62445: 'STL',
    62446: 'STL',
    62447: 'STL',
    62448: 'STL',
    62449: 'STL',
    62450: 'STL',
    62451: 'STL',
    62452: 'STL',
    62454: 'STL',
    62458: 'STL',
    62459: 'STL',
    62460: 'STL',
    62461: 'STL',
    62462: 'STL',
    62463: 'STL',
    62464: 'STL',
    62465: 'STL',
    62466: 'STL',
    62467: 'STL',
    62468: 'STL',
    62469: 'STL',
    62471: 'STL',
    62473: 'STL',
    62474: 'STL',
    62475: 'STL',
    62476: 'STL',
    62477: 'STL',
    62478: 'STL',
    62479: 'STL',
    62480: 'STL',
    62481: 'STL',
    62501: 'BMI',
    62510: 'BMI',
    62512: 'BMI',
    62513: 'BMI',
    62514: 'BMI',
    62515: 'BMI',
    62517: 'BMI',
    62518: 'BMI',
    62519: 'BMI',
    62520: 'BMI',
    62521: 'BMI',
    62522: 'BMI',
    62523: 'BMI',
    62524: 'BMI',
    62525: 'BMI',
    62526: 'BMI',
    62530: 'STL',
    62531: 'BMI',
    62532: 'BMI',
    62533: 'STL',
    62534: 'BMI',
    62535: 'BMI',
    62536: 'BMI',
    62537: 'BMI',
    62538: 'STL',
    62539: 'BMI',
    62540: 'BMI',
    62541: 'BMI',
    62543: 'BMI',
    62544: 'BMI',
    62545: 'BMI',
    62546: 'STL',
    62547: 'BMI',
    62548: 'BMI',
    62549: 'BMI',
    62550: 'BMI',
    62551: 'BMI',
    62553: 'STL',
    62554: 'BMI',
    62555: 'STL',
    62556: 'STL',
    62557: 'STL',
    62558: 'BMI',
    62560: 'STL',
    62561: 'BMI',
    62563: 'BMI',
    62565: 'BMI',
    62567: 'BMI',
    62568: 'BMI',
    62570: 'BMI',
    62571: 'STL',
    62572: 'STL',
    62573: 'BMI',
    62601: 'BMI',
    62610: 'STL',
    62611: 'BMI',
    62612: 'BMI',
    62613: 'BMI',
    62615: 'STL',
    62617: 'BMI',
    62618: 'BMI',
    62621: 'STL',
    62622: 'BMI',
    62624: 'BMI',
    62625: 'BMI',
    62626: 'STL',
    62627: 'BMI',
    62628: 'STL',
    62629: 'BMI',
    62630: 'STL',
    62631: 'BMI',
    62633: 'BMI',
    62634: 'BMI',
    62635: 'BMI',
    62638: 'STL',
    62639: 'BMI',
    62640: 'STL',
    62642: 'BMI',
    62643: 'BMI',
    62644: 'BMI',
    62649: 'STL',
    62650: 'BMI',
    62651: 'BMI',
    62655: 'BMI',
    62656: 'BMI',
    62659: 'BMI',
    62660: 'BMI',
    62661: 'BMI',
    62662: 'STL',
    62663: 'STL',
    62664: 'BMI',
    62665: 'STL',
    62666: 'BMI',
    62667: 'STL',
    62668: 'STL',
    62670: 'BMI',
    62671: 'BMI',
    62672: 'STL',
    62673: 'BMI',
    62674: 'STL',
    62675: 'BMI',
    62677: 'BMI',
    62681: 'BMI',
    62682: 'BMI',
    62683: 'STL',
    62684: 'BMI',
    62685: 'STL',
    62688: 'BMI',
    62689: 'STL',
    62690: 'STL',
    62691: 'BMI',
    62692: 'STL',
    62693: 'BMI',
    62694: 'STL',
    62695: 'STL',
    62701: 'BMI',
    62702: 'BMI',
    62703: 'BMI',
    62704: 'BMI',
    62705: 'BMI',
    62706: 'BMI',
    62707: 'BMI',
    62708: 'BMI',
    62711: 'BMI',
    62712: 'BMI',
    62713: 'BMI',
    62715: 'BMI',
    62716: 'BMI',
    62719: 'BMI',
    62721: 'BMI',
    62722: 'BMI',
    62723: 'BMI',
    62726: 'BMI',
    62736: 'BMI',
    62739: 'BMI',
    62746: 'BMI',
    62756: 'BMI',
    62757: 'BMI',
    62761: 'BMI',
    62762: 'BMI',
    62763: 'BMI',
    62764: 'BMI',
    62765: 'BMI',
    62766: 'BMI',
    62767: 'BMI',
    62769: 'BMI',
    62776: 'BMI',
    62777: 'BMI',
    62781: 'BMI',
    62786: 'BMI',
    62791: 'BMI',
    62794: 'BMI',
    62796: 'BMI',
    62801: 'STL',
    62803: 'STL',
    62805: 'STL',
    62806: 'STL',
    62807: 'STL',
    62808: 'STL',
    62809: 'STL',
    62810: 'STL',
    62811: 'STL',
    62812: 'STL',
    62814: 'STL',
    62815: 'STL',
    62816: 'STL',
    62817: 'STL',
    62818: 'STL',
    62819: 'STL',
    62820: 'STL',
    62821: 'STL',
    62822: 'STL',
    62823: 'STL',
    62824: 'STL',
    62825: 'STL',
    62827: 'STL',
    62828: 'STL',
    62829: 'STL',
    62830: 'STL',
    62831: 'STL',
    62832: 'STL',
    62833: 'STL',
    62834: 'STL',
    62835: 'STL',
    62836: 'STL',
    62837: 'STL',
    62838: 'STL',
    62839: 'STL',
    62840: 'STL',
    62841: 'STL',
    62842: 'STL',
    62843: 'STL',
    62844: 'STL',
    62846: 'STL',
    62848: 'STL',
    62849: 'STL',
    62850: 'STL',
    62851: 'STL',
    62852: 'STL',
    62853: 'STL',
    62854: 'STL',
    62855: 'STL',
    62856: 'STL',
    62857: 'STL',
    62858: 'STL',
    62859: 'STL',
    62860: 'STL',
    62861: 'STL',
    62862: 'STL',
    62863: 'STL',
    62864: 'STL',
    62865: 'STL',
    62866: 'STL',
    62867: 'STL',
    62868: 'STL',
    62869: 'STL',
    62870: 'STL',
    62871: 'STL',
    62872: 'STL',
    62874: 'STL',
    62875: 'STL',
    62876: 'STL',
    62877: 'STL',
    62878: 'STL',
    62879: 'STL',
    62880: 'STL',
    62881: 'STL',
    62882: 'STL',
    62883: 'STL',
    62884: 'STL',
    62885: 'STL',
    62886: 'STL',
    62887: 'STL',
    62888: 'STL',
    62889: 'STL',
    62890: 'STL',
    62891: 'STL',
    62892: 'STL',
    62893: 'STL',
    62894: 'STL',
    62895: 'STL',
    62896: 'STL',
    62897: 'STL',
    62898: 'STL',
    62899: 'STL',
    62901: 'STL',
    62902: 'STL',
    62903: 'STL',
    62905: 'STL',
    62906: 'STL',
    62907: 'STL',
    62908: 'STL',
    62909: 'STL',
    62910: 'STL',
    62912: 'STL',
    62914: 'STL',
    62915: 'STL',
    62916: 'STL',
    62917: 'STL',
    62918: 'STL',
    62919: 'STL',
    62920: 'STL',
    62921: 'STL',
    62922: 'STL',
    62923: 'STL',
    62924: 'STL',
    62926: 'STL',
    62927: 'STL',
    62928: 'STL',
    62930: 'STL',
    62931: 'STL',
    62932: 'STL',
    62933: 'STL',
    62934: 'STL',
    62935: 'STL',
    62938: 'STL',
    62939: 'STL',
    62940: 'STL',
    62941: 'STL',
    62942: 'STL',
    62943: 'STL',
    62946: 'STL',
    62947: 'STL',
    62948: 'STL',
    62949: 'STL',
    62950: 'STL',
    62951: 'STL',
    62952: 'STL',
    62953: 'STL',
    62954: 'STL',
    62955: 'STL',
    62956: 'STL',
    62957: 'STL',
    62958: 'STL',
    62959: 'STL',
    62960: 'STL',
    62961: 'STL',
    62962: 'STL',
    62963: 'STL',
    62964: 'STL',
    62965: 'STL',
    62966: 'STL',
    62967: 'STL',
    62969: 'STL',
    62970: 'STL',
    62971: 'STL',
    62972: 'STL',
    62973: 'STL',
    62974: 'STL',
    62975: 'STL',
    62976: 'STL',
    62977: 'STL',
    62979: 'STL',
    62982: 'STL',
    62983: 'STL',
    62984: 'STL',
    62985: 'STL',
    62987: 'STL',
    62988: 'STL',
    62990: 'STL',
    62992: 'STL',
    62993: 'STL',
    62994: 'STL',
    62995: 'STL',
    62996: 'STL',
    62997: 'STL',
    62998: 'STL',
    62999: 'STL',
    63001: 'STL',
    63005: 'STL',
    63006: 'STL',
    63010: 'STL',
    63011: 'STL',
    63012: 'STL',
    63013: 'STL',
    63014: 'STL',
    63015: 'STL',
    63016: 'STL',
    63017: 'STL',
    63019: 'STL',
    63020: 'STL',
    63021: 'STL',
    63022: 'STL',
    63023: 'STL',
    63024: 'STL',
    63025: 'STL',
    63026: 'STL',
    63028: 'STL',
    63030: 'STL',
    63031: 'STL',
    63032: 'STL',
    63033: 'STL',
    63034: 'STL',
    63036: 'STL',
    63037: 'STL',
    63038: 'STL',
    63039: 'STL',
    63040: 'STL',
    63041: 'STL',
    63042: 'STL',
    63043: 'STL',
    63044: 'STL',
    63045: 'STL',
    63047: 'STL',
    63048: 'STL',
    63049: 'STL',
    63050: 'STL',
    63051: 'STL',
    63052: 'STL',
    63053: 'STL',
    63055: 'STL',
    63056: 'STL',
    63057: 'STL',
    63060: 'STL',
    63061: 'STL',
    63065: 'STL',
    63066: 'STL',
    63068: 'STL',
    63069: 'STL',
    63070: 'STL',
    63071: 'STL',
    63072: 'STL',
    63073: 'STL',
    63074: 'STL',
    63077: 'STL',
    63079: 'STL',
    63080: 'STL',
    63084: 'STL',
    63087: 'STL',
    63088: 'STL',
    63089: 'STL',
    63090: 'STL',
    63091: 'STL',
    63099: 'STL',
    63101: 'STL',
    63102: 'STL',
    63103: 'STL',
    63104: 'STL',
    63105: 'STL',
    63106: 'STL',
    63107: 'STL',
    63108: 'STL',
    63109: 'STL',
    63110: 'STL',
    63111: 'STL',
    63112: 'STL',
    63113: 'STL',
    63114: 'STL',
    63115: 'STL',
    63116: 'STL',
    63117: 'STL',
    63118: 'STL',
    63119: 'STL',
    63120: 'STL',
    63121: 'STL',
    63122: 'STL',
    63123: 'STL',
    63124: 'STL',
    63125: 'STL',
    63126: 'STL',
    63127: 'STL',
    63128: 'STL',
    63129: 'STL',
    63130: 'STL',
    63131: 'STL',
    63132: 'STL',
    63133: 'STL',
    63134: 'STL',
    63135: 'STL',
    63136: 'STL',
    63137: 'STL',
    63138: 'STL',
    63139: 'STL',
    63140: 'STL',
    63141: 'STL',
    63143: 'STL',
    63144: 'STL',
    63145: 'STL',
    63146: 'STL',
    63147: 'STL',
    63150: 'STL',
    63151: 'STL',
    63155: 'STL',
    63156: 'STL',
    63157: 'STL',
    63158: 'STL',
    63160: 'STL',
    63163: 'STL',
    63164: 'STL',
    63166: 'STL',
    63167: 'STL',
    63169: 'STL',
    63171: 'STL',
    63177: 'STL',
    63178: 'STL',
    63179: 'STL',
    63180: 'STL',
    63182: 'STL',
    63188: 'STL',
    63190: 'STL',
    63195: 'STL',
    63196: 'STL',
    63197: 'STL',
    63198: 'STL',
    63199: 'STL',
    63301: 'STL',
    63302: 'STL',
    63303: 'STL',
    63304: 'STL',
    63330: 'STL',
    63332: 'STL',
    63333: 'STL',
    63334: 'STL',
    63336: 'STL',
    63338: 'STL',
    63339: 'STL',
    63341: 'STL',
    63342: 'STL',
    63343: 'STL',
    63344: 'STL',
    63345: 'STL',
    63346: 'STL',
    63347: 'STL',
    63348: 'STL',
    63349: 'STL',
    63350: 'STL',
    63351: 'STL',
    63352: 'STL',
    63353: 'STL',
    63357: 'STL',
    63359: 'STL',
    63361: 'STL',
    63362: 'STL',
    63363: 'STL',
    63365: 'STL',
    63366: 'STL',
    63367: 'STL',
    63368: 'STL',
    63369: 'STL',
    63370: 'STL',
    63373: 'STL',
    63376: 'STL',
    63377: 'STL',
    63378: 'STL',
    63379: 'STL',
    63380: 'STL',
    63381: 'STL',
    63382: 'STL',
    63383: 'STL',
    63384: 'STL',
    63385: 'STL',
    63386: 'STL',
    63387: 'STL',
    63388: 'STL',
    63389: 'STL',
    63390: 'STL',
    63401: 'STL',
    63430: 'STL',
    63431: 'MCI',
    63432: 'STL',
    63433: 'STL',
    63434: 'STL',
    63435: 'STL',
    63436: 'STL',
    63437: 'MCI',
    63438: 'STL',
    63439: 'STL',
    63440: 'STL',
    63441: 'STL',
    63442: 'STL',
    63443: 'STL',
    63445: 'STL',
    63446: 'STL',
    63447: 'STL',
    63448: 'STL',
    63450: 'MCI',
    63451: 'MCI',
    63452: 'STL',
    63453: 'STL',
    63454: 'STL',
    63456: 'STL',
    63457: 'STL',
    63458: 'STL',
    63459: 'STL',
    63460: 'MCI',
    63461: 'STL',
    63462: 'STL',
    63463: 'STL',
    63464: 'MCI',
    63465: 'STL',
    63466: 'STL',
    63467: 'STL',
    63468: 'MCI',
    63469: 'MCI',
    63471: 'STL',
    63472: 'STL',
    63473: 'STL',
    63474: 'STL',
    63501: 'MCI',
    63530: 'MCI',
    63531: 'MCI',
    63532: 'MCI',
    63533: 'MCI',
    63534: 'MCI',
    63535: 'MCI',
    63536: 'MCI',
    63537: 'MCI',
    63538: 'MCI',
    63539: 'MCI',
    63540: 'MCI',
    63541: 'MCI',
    63543: 'STL',
    63544: 'MCI',
    63545: 'MCI',
    63546: 'MCI',
    63547: 'MCI',
    63548: 'MCI',
    63549: 'MCI',
    63551: 'MCI',
    63552: 'MCI',
    63555: 'MCI',
    63556: 'MCI',
    63557: 'MCI',
    63558: 'MCI',
    63559: 'MCI',
    63560: 'MCI',
    63561: 'MCI',
    63563: 'MCI',
    63565: 'MCI',
    63566: 'MCI',
    63567: 'MCI',
    63601: 'STL',
    63620: 'STL',
    63621: 'STL',
    63622: 'STL',
    63623: 'STL',
    63624: 'STL',
    63625: 'SGF',
    63626: 'STL',
    63627: 'STL',
    63628: 'STL',
    63629: 'SGF',
    63630: 'STL',
    63631: 'STL',
    63632: 'STL',
    63633: 'SGF',
    63636: 'STL',
    63637: 'STL',
    63638: 'SGF',
    63640: 'STL',
    63645: 'STL',
    63648: 'STL',
    63650: 'STL',
    63651: 'STL',
    63653: 'STL',
    63654: 'STL',
    63655: 'STL',
    63656: 'STL',
    63660: 'STL',
    63662: 'STL',
    63663: 'STL',
    63664: 'STL',
    63665: 'SGF',
    63666: 'SGF',
    63670: 'STL',
    63673: 'STL',
    63674: 'STL',
    63675: 'STL',
    63701: 'STL',
    63702: 'STL',
    63703: 'STL',
    63730: 'STL',
    63732: 'STL',
    63735: 'STL',
    63736: 'STL',
    63737: 'STL',
    63738: 'STL',
    63739: 'STL',
    63740: 'STL',
    63742: 'STL',
    63743: 'STL',
    63744: 'STL',
    63745: 'STL',
    63746: 'STL',
    63747: 'STL',
    63748: 'STL',
    63750: 'STL',
    63751: 'STL',
    63752: 'STL',
    63755: 'STL',
    63758: 'STL',
    63760: 'STL',
    63763: 'STL',
    63764: 'STL',
    63766: 'STL',
    63767: 'STL',
    63769: 'STL',
    63770: 'STL',
    63771: 'STL',
    63774: 'STL',
    63775: 'STL',
    63776: 'STL',
    63779: 'STL',
    63780: 'STL',
    63781: 'STL',
    63782: 'STL',
    63783: 'STL',
    63784: 'STL',
    63785: 'STL',
    63787: 'STL',
    63801: 'STL',
    63820: 'STL',
    63821: 'STL',
    63822: 'STL',
    63823: 'STL',
    63824: 'STL',
    63825: 'STL',
    63826: 'STL',
    63827: 'STL',
    63828: 'STL',
    63829: 'STL',
    63830: 'STL',
    63833: 'STL',
    63834: 'STL',
    63837: 'STL',
    63839: 'STL',
    63840: 'STL',
    63841: 'STL',
    63845: 'STL',
    63846: 'STL',
    63847: 'STL',
    63848: 'STL',
    63849: 'STL',
    63850: 'STL',
    63851: 'STL',
    63852: 'STL',
    63853: 'STL',
    63855: 'STL',
    63857: 'STL',
    63860: 'STL',
    63862: 'STL',
    63863: 'STL',
    63866: 'STL',
    63867: 'STL',
    63868: 'STL',
    63869: 'STL',
    63870: 'STL',
    63873: 'STL',
    63874: 'STL',
    63875: 'STL',
    63876: 'STL',
    63877: 'STL',
    63878: 'STL',
    63879: 'STL',
    63880: 'STL',
    63881: 'STL',
    63882: 'STL',
    63901: 'STL',
    63902: 'STL',
    63931: 'SGF',
    63932: 'STL',
    63933: 'STL',
    63934: 'STL',
    63935: 'SGF',
    63936: 'STL',
    63937: 'STL',
    63938: 'STL',
    63939: 'STL',
    63940: 'STL',
    63941: 'SGF',
    63942: 'SGF',
    63943: 'STL',
    63944: 'STL',
    63945: 'STL',
    63950: 'STL',
    63951: 'STL',
    63952: 'STL',
    63953: 'STL',
    63954: 'STL',
    63955: 'STL',
    63956: 'STL',
    63957: 'STL',
    63960: 'STL',
    63961: 'STL',
    63962: 'STL',
    63963: 'STL',
    63964: 'STL',
    63965: 'SGF',
    63966: 'STL',
    63967: 'STL',
    64001: 'MCI',
    64002: 'MCI',
    64011: 'MCI',
    64012: 'MCI',
    64013: 'MCI',
    64014: 'MCI',
    64015: 'MCI',
    64016: 'MCI',
    64017: 'MCI',
    64018: 'MCI',
    64019: 'MCI',
    64020: 'MCI',
    64021: 'MCI',
    64022: 'MCI',
    64024: 'MCI',
    64028: 'MCI',
    64029: 'MCI',
    64030: 'MCI',
    64034: 'MCI',
    64035: 'MCI',
    64036: 'MCI',
    64037: 'MCI',
    64040: 'MCI',
    64048: 'MCI',
    64050: 'MCI',
    64051: 'MCI',
    64052: 'MCI',
    64053: 'MCI',
    64054: 'MCI',
    64055: 'MCI',
    64056: 'MCI',
    64057: 'MCI',
    64058: 'MCI',
    64060: 'MCI',
    64061: 'MCI',
    64062: 'MCI',
    64063: 'MCI',
    64064: 'MCI',
    64065: 'MCI',
    64066: 'MCI',
    64067: 'MCI',
    64068: 'MCI',
    64069: 'MCI',
    64070: 'MCI',
    64071: 'MCI',
    64072: 'MCI',
    64073: 'MCI',
    64074: 'MCI',
    64075: 'MCI',
    64076: 'MCI',
    64077: 'MCI',
    64078: 'MCI',
    64079: 'MCI',
    64080: 'MCI',
    64081: 'MCI',
    64082: 'MCI',
    64083: 'MCI',
    64084: 'MCI',
    64085: 'MCI',
    64086: 'MCI',
    64088: 'MCI',
    64089: 'MCI',
    64090: 'MCI',
    64092: 'MCI',
    64093: 'MCI',
    64096: 'MCI',
    64097: 'MCI',
    64098: 'MCI',
    64101: 'MCI',
    64102: 'MCI',
    64105: 'MCI',
    64106: 'MCI',
    64108: 'MCI',
    64109: 'MCI',
    64110: 'MCI',
    64111: 'MCI',
    64112: 'MCI',
    64113: 'MCI',
    64114: 'MCI',
    64116: 'MCI',
    64117: 'MCI',
    64118: 'MCI',
    64119: 'MCI',
    64120: 'MCI',
    64121: 'MCI',
    64123: 'MCI',
    64124: 'MCI',
    64125: 'MCI',
    64126: 'MCI',
    64127: 'MCI',
    64128: 'MCI',
    64129: 'MCI',
    64130: 'MCI',
    64131: 'MCI',
    64132: 'MCI',
    64133: 'MCI',
    64134: 'MCI',
    64136: 'MCI',
    64137: 'MCI',
    64138: 'MCI',
    64139: 'MCI',
    64141: 'MCI',
    64144: 'MCI',
    64145: 'MCI',
    64146: 'MCI',
    64147: 'MCI',
    64148: 'MCI',
    64149: 'MCI',
    64150: 'MCI',
    64151: 'MCI',
    64152: 'MCI',
    64153: 'MCI',
    64154: 'MCI',
    64155: 'MCI',
    64156: 'MCI',
    64157: 'MCI',
    64158: 'MCI',
    64161: 'MCI',
    64162: 'MCI',
    64163: 'MCI',
    64164: 'MCI',
    64165: 'MCI',
    64166: 'MCI',
    64167: 'MCI',
    64168: 'MCI',
    64170: 'MCI',
    64171: 'MCI',
    64172: 'MCI',
    64179: 'MCI',
    64180: 'MCI',
    64183: 'MCI',
    64184: 'MCI',
    64185: 'MCI',
    64187: 'MCI',
    64188: 'MCI',
    64190: 'MCI',
    64191: 'MCI',
    64192: 'MCI',
    64193: 'MCI',
    64194: 'MCI',
    64195: 'MCI',
    64196: 'MCI',
    64197: 'MCI',
    64198: 'MCI',
    64199: 'MCI',
    64401: 'MCI',
    64402: 'MCI',
    64420: 'MCI',
    64421: 'MCI',
    64422: 'MCI',
    64423: 'MCI',
    64424: 'MCI',
    64426: 'MCI',
    64427: 'MCI',
    64428: 'MCI',
    64429: 'MCI',
    64430: 'MCI',
    64431: 'MCI',
    64432: 'MCI',
    64433: 'MCI',
    64434: 'MCI',
    64436: 'MCI',
    64437: 'MCI',
    64438: 'MCI',
    64439: 'MCI',
    64440: 'MCI',
    64441: 'MCI',
    64442: 'MCI',
    64443: 'MCI',
    64444: 'MCI',
    64445: 'MCI',
    64446: 'MCI',
    64447: 'MCI',
    64448: 'MCI',
    64449: 'MCI',
    64451: 'MCI',
    64453: 'MCI',
    64454: 'MCI',
    64455: 'MCI',
    64456: 'MCI',
    64457: 'MCI',
    64458: 'MCI',
    64459: 'MCI',
    64461: 'MCI',
    64463: 'MCI',
    64465: 'MCI',
    64466: 'MCI',
    64467: 'MCI',
    64468: 'MCI',
    64469: 'MCI',
    64470: 'MCI',
    64471: 'MCI',
    64473: 'MCI',
    64474: 'MCI',
    64475: 'MCI',
    64476: 'MCI',
    64477: 'MCI',
    64479: 'MCI',
    64480: 'MCI',
    64481: 'MCI',
    64482: 'MCI',
    64483: 'MCI',
    64484: 'MCI',
    64485: 'MCI',
    64486: 'MCI',
    64487: 'MCI',
    64489: 'MCI',
    64490: 'MCI',
    64491: 'MCI',
    64492: 'MCI',
    64493: 'MCI',
    64494: 'MCI',
    64496: 'MCI',
    64497: 'MCI',
    64498: 'MCI',
    64499: 'MCI',
    64501: 'MCI',
    64502: 'MCI',
    64503: 'MCI',
    64504: 'MCI',
    64505: 'MCI',
    64506: 'MCI',
    64507: 'MCI',
    64508: 'MCI',
    64601: 'MCI',
    64620: 'MCI',
    64622: 'MCI',
    64623: 'MCI',
    64624: 'MCI',
    64625: 'MCI',
    64628: 'MCI',
    64630: 'MCI',
    64631: 'MCI',
    64632: 'MCI',
    64633: 'MCI',
    64635: 'MCI',
    64636: 'MCI',
    64637: 'MCI',
    64638: 'MCI',
    64639: 'MCI',
    64640: 'MCI',
    64641: 'MCI',
    64642: 'MCI',
    64643: 'MCI',
    64644: 'MCI',
    64645: 'MCI',
    64646: 'MCI',
    64647: 'MCI',
    64648: 'MCI',
    64649: 'MCI',
    64650: 'MCI',
    64651: 'MCI',
    64652: 'MCI',
    64653: 'MCI',
    64654: 'MCI',
    64655: 'MCI',
    64656: 'MCI',
    64657: 'MCI',
    64658: 'MCI',
    64659: 'MCI',
    64660: 'MCI',
    64661: 'MCI',
    64664: 'MCI',
    64667: 'MCI',
    64668: 'MCI',
    64670: 'MCI',
    64671: 'MCI',
    64672: 'MCI',
    64673: 'MCI',
    64674: 'MCI',
    64676: 'MCI',
    64679: 'MCI',
    64680: 'MCI',
    64681: 'MCI',
    64682: 'MCI',
    64683: 'MCI',
    64686: 'MCI',
    64688: 'MCI',
    64689: 'MCI',
    64701: 'MCI',
    64720: 'MCI',
    64722: 'MCI',
    64723: 'MCI',
    64724: 'MCI',
    64725: 'MCI',
    64726: 'MCI',
    64728: 'SGF',
    64730: 'MCI',
    64733: 'MCI',
    64734: 'MCI',
    64735: 'MCI',
    64738: 'MCI',
    64739: 'MCI',
    64740: 'MCI',
    64741: 'MCI',
    64742: 'MCI',
    64743: 'MCI',
    64744: 'MCI',
    64745: 'MCI',
    64746: 'MCI',
    64747: 'MCI',
    64748: 'SGF',
    64750: 'MCI',
    64752: 'MCI',
    64755: 'SGF',
    64756: 'SGF',
    64759: 'SGF',
    64761: 'MCI',
    64762: 'SGF',
    64763: 'MCI',
    64765: 'MCI',
    64766: 'SGF',
    64767: 'SGF',
    64769: 'SGF',
    64770: 'MCI',
    64771: 'SGF',
    64772: 'MCI',
    64776: 'MCI',
    64778: 'MCI',
    64779: 'MCI',
    64780: 'MCI',
    64781: 'MCI',
    64783: 'MCI',
    64784: 'SGF',
    64788: 'MCI',
    64789: 'MCI',
    64790: 'MCI',
    64801: 'SGF',
    64802: 'SGF',
    64803: 'SGF',
    64804: 'SGF',
    64830: 'SGF',
    64831: 'SGF',
    64832: 'SGF',
    64833: 'SGF',
    64834: 'SGF',
    64835: 'SGF',
    64836: 'SGF',
    64840: 'SGF',
    64841: 'SGF',
    64842: 'SGF',
    64843: 'SGF',
    64844: 'SGF',
    64847: 'SGF',
    64848: 'SGF',
    64849: 'SGF',
    64850: 'SGF',
    64853: 'SGF',
    64854: 'SGF',
    64855: 'SGF',
    64856: 'SGF',
    64857: 'SGF',
    64858: 'SGF',
    64859: 'SGF',
    64861: 'SGF',
    64862: 'SGF',
    64863: 'SGF',
    64864: 'SGF',
    64865: 'SGF',
    64866: 'SGF',
    64867: 'SGF',
    64868: 'SGF',
    64869: 'SGF',
    64870: 'SGF',
    64873: 'SGF',
    64874: 'SGF',
    64944: 'MCI',
    64999: 'MCI',
    65001: 'MCI',
    65010: 'MCI',
    65011: 'MCI',
    65013: 'STL',
    65014: 'STL',
    65016: 'STL',
    65017: 'MCI',
    65018: 'MCI',
    65020: 'MCI',
    65023: 'MCI',
    65024: 'STL',
    65025: 'MCI',
    65026: 'MCI',
    65032: 'MCI',
    65034: 'MCI',
    65035: 'STL',
    65036: 'STL',
    65037: 'MCI',
    65038: 'MCI',
    65039: 'MCI',
    65040: 'MCI',
    65041: 'STL',
    65042: 'MCI',
    65043: 'MCI',
    65046: 'MCI',
    65047: 'MCI',
    65048: 'MCI',
    65049: 'MCI',
    65050: 'MCI',
    65051: 'STL',
    65052: 'MCI',
    65053: 'MCI',
    65054: 'STL',
    65055: 'MCI',
    65058: 'MCI',
    65059: 'STL',
    65061: 'STL',
    65062: 'STL',
    65063: 'MCI',
    65064: 'MCI',
    65065: 'MCI',
    65066: 'STL',
    65067: 'STL',
    65068: 'MCI',
    65069: 'STL',
    65072: 'MCI',
    65074: 'MCI',
    65075: 'MCI',
    65076: 'MCI',
    65077: 'STL',
    65078: 'MCI',
    65079: 'MCI',
    65080: 'STL',
    65081: 'MCI',
    65082: 'MCI',
    65083: 'MCI',
    65084: 'MCI',
    65085: 'MCI',
    65101: 'MCI',
    65102: 'MCI',
    65103: 'MCI',
    65104: 'MCI',
    65105: 'MCI',
    65106: 'MCI',
    65107: 'MCI',
    65108: 'MCI',
    65109: 'MCI',
    65110: 'MCI',
    65111: 'MCI',
    65201: 'MCI',
    65202: 'MCI',
    65203: 'MCI',
    65205: 'MCI',
    65211: 'MCI',
    65212: 'MCI',
    65215: 'MCI',
    65216: 'MCI',
    65217: 'MCI',
    65218: 'MCI',
    65230: 'MCI',
    65231: 'STL',
    65232: 'STL',
    65233: 'MCI',
    65236: 'MCI',
    65237: 'MCI',
    65239: 'MCI',
    65240: 'MCI',
    65243: 'MCI',
    65244: 'MCI',
    65246: 'MCI',
    65247: 'MCI',
    65248: 'MCI',
    65250: 'MCI',
    65251: 'STL',
    65254: 'MCI',
    65255: 'MCI',
    65256: 'MCI',
    65257: 'MCI',
    65258: 'MCI',
    65259: 'MCI',
    65260: 'MCI',
    65261: 'MCI',
    65262: 'STL',
    65263: 'MCI',
    65264: 'STL',
    65265: 'STL',
    65270: 'MCI',
    65274: 'MCI',
    65275: 'MCI',
    65276: 'MCI',
    65278: 'MCI',
    65279: 'MCI',
    65280: 'STL',
    65281: 'MCI',
    65282: 'STL',
    65283: 'STL',
    65284: 'MCI',
    65285: 'MCI',
    65286: 'MCI',
    65287: 'MCI',
    65299: 'MCI',
    65301: 'MCI',
    65302: 'MCI',
    65305: 'MCI',
    65320: 'MCI',
    65321: 'MCI',
    65322: 'MCI',
    65323: 'MCI',
    65324: 'MCI',
    65325: 'MCI',
    65326: 'MCI',
    65327: 'MCI',
    65329: 'MCI',
    65330: 'MCI',
    65332: 'MCI',
    65333: 'MCI',
    65334: 'MCI',
    65335: 'MCI',
    65336: 'MCI',
    65337: 'MCI',
    65338: 'MCI',
    65339: 'MCI',
    65340: 'MCI',
    65344: 'MCI',
    65345: 'MCI',
    65347: 'MCI',
    65348: 'MCI',
    65349: 'MCI',
    65350: 'MCI',
    65351: 'MCI',
    65354: 'MCI',
    65355: 'MCI',
    65360: 'MCI',
    65401: 'STL',
    65402: 'STL',
    65409: 'STL',
    65436: 'SGF',
    65438: 'SGF',
    65439: 'SGF',
    65440: 'SGF',
    65441: 'STL',
    65443: 'MCI',
    65444: 'SGF',
    65446: 'STL',
    65449: 'STL',
    65452: 'MCI',
    65453: 'STL',
    65456: 'STL',
    65457: 'MCI',
    65459: 'MCI',
    65461: 'SGF',
    65462: 'SGF',
    65463: 'MCI',
    65464: 'SGF',
    65466: 'SGF',
    65468: 'SGF',
    65470: 'SGF',
    65473: 'SGF',
    65479: 'SGF',
    65483: 'SGF',
    65484: 'SGF',
    65486: 'MCI',
    65501: 'SGF',
    65529: 'STL',
    65532: 'SGF',
    65534: 'SGF',
    65535: 'STL',
    65536: 'SGF',
    65541: 'SGF',
    65542: 'SGF',
    65543: 'SGF',
    65546: 'SGF',
    65548: 'SGF',
    65550: 'STL',
    65552: 'SGF',
    65555: 'SGF',
    65556: 'MCI',
    65557: 'SGF',
    65559: 'STL',
    65560: 'SGF',
    65564: 'SGF',
    65565: 'STL',
    65566: 'STL',
    65567: 'MCI',
    65570: 'SGF',
    65571: 'SGF',
    65580: 'STL',
    65582: 'STL',
    65583: 'MCI',
    65584: 'MCI',
    65586: 'STL',
    65588: 'SGF',
    65589: 'SGF',
    65590: 'SGF',
    65591: 'MCI',
    65601: 'SGF',
    65603: 'SGF',
    65604: 'SGF',
    65605: 'SGF',
    65606: 'SGF',
    65607: 'MCI',
    65608: 'SGF',
    65609: 'SGF',
    65610: 'SGF',
    65611: 'SGF',
    65612: 'SGF',
    65613: 'SGF',
    65614: 'SGF',
    65615: 'SGF',
    65616: 'SGF',
    65617: 'SGF',
    65618: 'SGF',
    65619: 'SGF',
    65620: 'SGF',
    65622: 'SGF',
    65623: 'SGF',
    65624: 'SGF',
    65625: 'SGF',
    65626: 'SGF',
    65627: 'SGF',
    65629: 'SGF',
    65630: 'SGF',
    65631: 'SGF',
    65632: 'SGF',
    65633: 'SGF',
    65634: 'MCI',
    65635: 'SGF',
    65636: 'SGF',
    65637: 'SGF',
    65638: 'SGF',
    65640: 'SGF',
    65641: 'SGF',
    65644: 'SGF',
    65645: 'SGF',
    65646: 'SGF',
    65647: 'SGF',
    65648: 'SGF',
    65649: 'SGF',
    65650: 'SGF',
    65652: 'SGF',
    65653: 'SGF',
    65654: 'SGF',
    65655: 'SGF',
    65656: 'SGF',
    65657: 'SGF',
    65658: 'SGF',
    65660: 'SGF',
    65661: 'SGF',
    65662: 'SGF',
    65663: 'SGF',
    65664: 'SGF',
    65666: 'SGF',
    65667: 'SGF',
    65668: 'MCI',
    65669: 'SGF',
    65672: 'SGF',
    65673: 'SGF',
    65674: 'MCI',
    65675: 'SGF',
    65676: 'SGF',
    65679: 'SGF',
    65680: 'SGF',
    65681: 'SGF',
    65682: 'SGF',
    65685: 'SGF',
    65686: 'SGF',
    65688: 'SGF',
    65689: 'SGF',
    65690: 'SGF',
    65692: 'SGF',
    65701: 'SGF',
    65702: 'SGF',
    65704: 'SGF',
    65705: 'SGF',
    65706: 'SGF',
    65707: 'SGF',
    65708: 'SGF',
    65710: 'SGF',
    65711: 'SGF',
    65712: 'SGF',
    65713: 'SGF',
    65714: 'SGF',
    65715: 'SGF',
    65717: 'SGF',
    65720: 'SGF',
    65721: 'SGF',
    65722: 'SGF',
    65723: 'SGF',
    65724: 'MCI',
    65725: 'SGF',
    65726: 'SGF',
    65727: 'SGF',
    65728: 'SGF',
    65729: 'SGF',
    65730: 'SGF',
    65731: 'SGF',
    65732: 'MCI',
    65733: 'SGF',
    65734: 'SGF',
    65735: 'MCI',
    65737: 'SGF',
    65738: 'SGF',
    65739: 'SGF',
    65740: 'SGF',
    65741: 'SGF',
    65742: 'SGF',
    65744: 'SGF',
    65745: 'SGF',
    65746: 'SGF',
    65747: 'SGF',
    65752: 'SGF',
    65753: 'SGF',
    65754: 'SGF',
    65755: 'SGF',
    65756: 'SGF',
    65757: 'SGF',
    65759: 'SGF',
    65760: 'SGF',
    65761: 'SGF',
    65762: 'SGF',
    65764: 'MCI',
    65765: 'SGF',
    65766: 'SGF',
    65767: 'MCI',
    65768: 'SGF',
    65769: 'SGF',
    65770: 'SGF',
    65771: 'SGF',
    65772: 'SGF',
    65773: 'SGF',
    65774: 'MCI',
    65775: 'SGF',
    65776: 'SGF',
    65777: 'SGF',
    65778: 'SGF',
    65779: 'MCI',
    65781: 'SGF',
    65783: 'SGF',
    65784: 'SGF',
    65785: 'SGF',
    65786: 'MCI',
    65787: 'MCI',
    65788: 'SGF',
    65789: 'SGF',
    65790: 'SGF',
    65791: 'SGF',
    65793: 'SGF',
    65801: 'SGF',
    65802: 'SGF',
    65803: 'SGF',
    65804: 'SGF',
    65805: 'SGF',
    65806: 'SGF',
    65807: 'SGF',
    65808: 'SGF',
    65809: 'SGF',
    65810: 'SGF',
    65814: 'SGF',
    65817: 'SGF',
    65890: 'SGF',
    65897: 'SGF',
    65898: 'SGF',
    65899: 'SGF',
    66002: 'MCI',
    66006: 'MCI',
    66007: 'MCI',
    66008: 'MCI',
    66010: 'MCI',
    66012: 'MCI',
    66013: 'MCI',
    66014: 'MCI',
    66015: 'MCI',
    66016: 'MCI',
    66017: 'MCI',
    66018: 'MCI',
    66019: 'MCI',
    66020: 'MCI',
    66021: 'MCI',
    66023: 'MCI',
    66024: 'MCI',
    66025: 'MCI',
    66026: 'MCI',
    66027: 'MCI',
    66030: 'MCI',
    66031: 'MCI',
    66032: 'MCI',
    66033: 'MCI',
    66035: 'MCI',
    66036: 'MCI',
    66039: 'MCI',
    66040: 'MCI',
    66041: 'MCI',
    66042: 'MCI',
    66043: 'MCI',
    66044: 'MCI',
    66045: 'MCI',
    66046: 'MCI',
    66047: 'MCI',
    66048: 'MCI',
    66049: 'MCI',
    66050: 'MCI',
    66051: 'MCI',
    66052: 'MCI',
    66053: 'MCI',
    66054: 'MCI',
    66056: 'MCI',
    66058: 'MCI',
    66060: 'MCI',
    66061: 'MCI',
    66062: 'MCI',
    66063: 'MCI',
    66064: 'MCI',
    66066: 'MCI',
    66067: 'MCI',
    66070: 'MCI',
    66071: 'MCI',
    66072: 'MCI',
    66073: 'MCI',
    66075: 'MCI',
    66076: 'MCI',
    66077: 'MCI',
    66078: 'MCI',
    66079: 'MCI',
    66080: 'MCI',
    66083: 'MCI',
    66085: 'MCI',
    66086: 'MCI',
    66087: 'MCI',
    66088: 'MCI',
    66090: 'MCI',
    66091: 'MCI',
    66092: 'MCI',
    66093: 'MCI',
    66094: 'MCI',
    66095: 'MCI',
    66097: 'MCI',
    66101: 'MCI',
    66102: 'MCI',
    66103: 'MCI',
    66104: 'MCI',
    66105: 'MCI',
    66106: 'MCI',
    66109: 'MCI',
    66110: 'MCI',
    66111: 'MCI',
    66112: 'MCI',
    66113: 'MCI',
    66115: 'MCI',
    66117: 'MCI',
    66118: 'MCI',
    66119: 'MCI',
    66160: 'MCI',
    66201: 'MCI',
    66202: 'MCI',
    66203: 'MCI',
    66204: 'MCI',
    66205: 'MCI',
    66206: 'MCI',
    66207: 'MCI',
    66208: 'MCI',
    66209: 'MCI',
    66210: 'MCI',
    66211: 'MCI',
    66212: 'MCI',
    66213: 'MCI',
    66214: 'MCI',
    66215: 'MCI',
    66216: 'MCI',
    66217: 'MCI',
    66218: 'MCI',
    66219: 'MCI',
    66220: 'MCI',
    66221: 'MCI',
    66222: 'MCI',
    66223: 'MCI',
    66224: 'MCI',
    66225: 'MCI',
    66226: 'MCI',
    66227: 'MCI',
    66250: 'MCI',
    66251: 'MCI',
    66276: 'MCI',
    66279: 'MCI',
    66282: 'MCI',
    66283: 'MCI',
    66285: 'MCI',
    66286: 'MCI',
    66401: 'MCI',
    66402: 'MCI',
    66403: 'MCI',
    66404: 'MCI',
    66406: 'MCI',
    66407: 'MCI',
    66408: 'MCI',
    66409: 'MCI',
    66411: 'MCI',
    66412: 'MCI',
    66413: 'MCI',
    66414: 'MCI',
    66415: 'MCI',
    66416: 'MCI',
    66417: 'MCI',
    66418: 'MCI',
    66419: 'MCI',
    66420: 'MCI',
    66422: 'MCI',
    66423: 'MCI',
    66424: 'MCI',
    66425: 'MCI',
    66426: 'MCI',
    66427: 'MCI',
    66428: 'MCI',
    66429: 'MCI',
    66431: 'MCI',
    66432: 'MCI',
    66434: 'MCI',
    66436: 'MCI',
    66438: 'MCI',
    66439: 'MCI',
    66440: 'MCI',
    66441: 'MCI',
    66442: 'MCI',
    66449: 'MCI',
    66451: 'MCI',
    66501: 'MCI',
    66502: 'MCI',
    66503: 'MCI',
    66505: 'MCI',
    66506: 'MCI',
    66507: 'MCI',
    66508: 'MCI',
    66509: 'MCI',
    66510: 'MCI',
    66512: 'MCI',
    66514: 'MCI',
    66515: 'MCI',
    66516: 'MCI',
    66517: 'MCI',
    66518: 'MCI',
    66520: 'MCI',
    66521: 'MCI',
    66522: 'MCI',
    66523: 'MCI',
    66524: 'MCI',
    66526: 'MCI',
    66527: 'MCI',
    66528: 'MCI',
    66531: 'MCI',
    66532: 'MCI',
    66533: 'MCI',
    66534: 'MCI',
    66535: 'MCI',
    66536: 'MCI',
    66537: 'MCI',
    66538: 'MCI',
    66539: 'MCI',
    66540: 'MCI',
    66541: 'MCI',
    66542: 'MCI',
    66543: 'MCI',
    66544: 'MCI',
    66546: 'MCI',
    66547: 'MCI',
    66548: 'MCI',
    66549: 'MCI',
    66550: 'MCI',
    66552: 'MCI',
    66554: 'MCI',
    66601: 'MCI',
    66603: 'MCI',
    66604: 'MCI',
    66605: 'MCI',
    66606: 'MCI',
    66607: 'MCI',
    66608: 'MCI',
    66609: 'MCI',
    66610: 'MCI',
    66611: 'MCI',
    66612: 'MCI',
    66614: 'MCI',
    66615: 'MCI',
    66616: 'MCI',
    66617: 'MCI',
    66618: 'MCI',
    66619: 'MCI',
    66620: 'MCI',
    66621: 'MCI',
    66622: 'MCI',
    66624: 'MCI',
    66625: 'MCI',
    66626: 'MCI',
    66628: 'MCI',
    66629: 'MCI',
    66630: 'MCI',
    66636: 'MCI',
    66637: 'MCI',
    66642: 'MCI',
    66647: 'MCI',
    66652: 'MCI',
    66653: 'MCI',
    66667: 'MCI',
    66675: 'MCI',
    66683: 'MCI',
    66692: 'MCI',
    66699: 'MCI',
    66701: 'ICT',
    66710: 'ICT',
    66711: 'ICT',
    66712: 'ICT',
    66713: 'MCI',
    66714: 'ICT',
    66716: 'ICT',
    66717: 'ICT',
    66720: 'ICT',
    66724: 'ICT',
    66725: 'ICT',
    66728: 'ICT',
    66732: 'ICT',
    66733: 'ICT',
    66734: 'ICT',
    66735: 'ICT',
    66736: 'ICT',
    66738: 'ICT',
    66739: 'ICT',
    66740: 'ICT',
    66741: 'MCI',
    66742: 'ICT',
    66743: 'ICT',
    66746: 'ICT',
    66748: 'ICT',
    66749: 'ICT',
    66751: 'ICT',
    66753: 'ICT',
    66754: 'ICT',
    66755: 'ICT',
    66756: 'ICT',
    66757: 'ICT',
    66758: 'ICT',
    66759: 'ICT',
    66760: 'MCI',
    66761: 'ICT',
    66762: 'ICT',
    66763: 'ICT',
    66767: 'MCI',
    66769: 'ICT',
    66770: 'ICT',
    66771: 'ICT',
    66772: 'ICT',
    66773: 'ICT',
    66775: 'ICT',
    66776: 'ICT',
    66777: 'ICT',
    66778: 'MCI',
    66779: 'ICT',
    66780: 'ICT',
    66781: 'ICT',
    66782: 'ICT',
    66783: 'ICT',
    66801: 'MCI',
    66830: 'MCI',
    66833: 'MCI',
    66834: 'MCI',
    66835: 'MCI',
    66838: 'MCI',
    66839: 'MCI',
    66840: 'MCI',
    66842: 'MCI',
    66843: 'MCI',
    66845: 'MCI',
    66846: 'MCI',
    66849: 'MCI',
    66850: 'MCI',
    66851: 'MCI',
    66852: 'MCI',
    66853: 'ICT',
    66854: 'MCI',
    66855: 'MCI',
    66856: 'MCI',
    66857: 'MCI',
    66858: 'MCI',
    66859: 'MCI',
    66860: 'MCI',
    66861: 'MCI',
    66862: 'MCI',
    66863: 'ICT',
    66864: 'MCI',
    66865: 'MCI',
    66866: 'MCI',
    66868: 'MCI',
    66869: 'MCI',
    66870: 'ICT',
    66871: 'MCI',
    66872: 'MCI',
    66873: 'MCI',
    66901: 'ICT',
    66930: 'ICT',
    66932: 'ICT',
    66933: 'MCI',
    66935: 'ICT',
    66936: 'ICT',
    66937: 'MCI',
    66938: 'ICT',
    66939: 'ICT',
    66940: 'ICT',
    66941: 'ICT',
    66942: 'ICT',
    66943: 'MCI',
    66944: 'MCI',
    66945: 'MCI',
    66946: 'MCI',
    66948: 'ICT',
    66949: 'ICT',
    66951: 'ICT',
    66952: 'ICT',
    66953: 'MCI',
    66955: 'MCI',
    66956: 'ICT',
    66958: 'MCI',
    66959: 'ICT',
    66960: 'ICT',
    66961: 'ICT',
    66962: 'MCI',
    66963: 'ICT',
    66964: 'ICT',
    66966: 'ICT',
    66967: 'ICT',
    66968: 'MCI',
    66970: 'ICT',
    67001: 'ICT',
    67002: 'ICT',
    67003: 'ICT',
    67004: 'ICT',
    67005: 'ICT',
    67008: 'ICT',
    67009: 'ICT',
    67010: 'ICT',
    67012: 'ICT',
    67013: 'ICT',
    67016: 'ICT',
    67017: 'ICT',
    67018: 'ICT',
    67019: 'ICT',
    67020: 'ICT',
    67021: 'ICT',
    67022: 'ICT',
    67023: 'ICT',
    67024: 'ICT',
    67025: 'ICT',
    67026: 'ICT',
    67028: 'ICT',
    67029: 'ICT',
    67030: 'ICT',
    67031: 'ICT',
    67035: 'ICT',
    67036: 'ICT',
    67037: 'ICT',
    67038: 'ICT',
    67039: 'ICT',
    67041: 'ICT',
    67042: 'ICT',
    67045: 'ICT',
    67047: 'ICT',
    67049: 'ICT',
    67050: 'ICT',
    67051: 'ICT',
    67052: 'ICT',
    67053: 'ICT',
    67054: 'ICT',
    67055: 'ICT',
    67056: 'ICT',
    67057: 'ICT',
    67058: 'ICT',
    67059: 'ICT',
    67060: 'ICT',
    67061: 'ICT',
    67062: 'ICT',
    67063: 'ICT',
    67065: 'ICT',
    67066: 'ICT',
    67067: 'ICT',
    67068: 'ICT',
    67070: 'ICT',
    67071: 'ICT',
    67072: 'ICT',
    67073: 'ICT',
    67074: 'ICT',
    67101: 'ICT',
    67102: 'ICT',
    67103: 'ICT',
    67104: 'ICT',
    67105: 'ICT',
    67106: 'ICT',
    67107: 'ICT',
    67108: 'ICT',
    67109: 'ICT',
    67110: 'ICT',
    67111: 'ICT',
    67112: 'ICT',
    67114: 'ICT',
    67117: 'ICT',
    67118: 'ICT',
    67119: 'ICT',
    67120: 'ICT',
    67122: 'ICT',
    67123: 'ICT',
    67124: 'ICT',
    67127: 'ICT',
    67131: 'ICT',
    67132: 'ICT',
    67133: 'ICT',
    67134: 'ICT',
    67135: 'ICT',
    67137: 'ICT',
    67138: 'ICT',
    67140: 'ICT',
    67142: 'ICT',
    67143: 'ICT',
    67144: 'ICT',
    67146: 'ICT',
    67147: 'ICT',
    67149: 'ICT',
    67150: 'ICT',
    67151: 'ICT',
    67152: 'ICT',
    67154: 'ICT',
    67155: 'ICT',
    67156: 'ICT',
    67159: 'ICT',
    67201: 'ICT',
    67202: 'ICT',
    67203: 'ICT',
    67204: 'ICT',
    67205: 'ICT',
    67206: 'ICT',
    67207: 'ICT',
    67208: 'ICT',
    67209: 'ICT',
    67210: 'ICT',
    67211: 'ICT',
    67212: 'ICT',
    67213: 'ICT',
    67214: 'ICT',
    67215: 'ICT',
    67216: 'ICT',
    67217: 'ICT',
    67218: 'ICT',
    67219: 'ICT',
    67220: 'ICT',
    67221: 'ICT',
    67223: 'ICT',
    67226: 'ICT',
    67227: 'ICT',
    67228: 'ICT',
    67230: 'ICT',
    67232: 'ICT',
    67235: 'ICT',
    67260: 'ICT',
    67275: 'ICT',
    67276: 'ICT',
    67277: 'ICT',
    67278: 'ICT',
    67301: 'ICT',
    67330: 'ICT',
    67332: 'ICT',
    67333: 'ICT',
    67334: 'ICT',
    67335: 'ICT',
    67336: 'ICT',
    67337: 'ICT',
    67340: 'ICT',
    67341: 'ICT',
    67342: 'ICT',
    67344: 'ICT',
    67345: 'ICT',
    67346: 'ICT',
    67347: 'ICT',
    67349: 'ICT',
    67351: 'ICT',
    67352: 'ICT',
    67353: 'ICT',
    67354: 'ICT',
    67355: 'ICT',
    67356: 'ICT',
    67357: 'ICT',
    67360: 'ICT',
    67361: 'ICT',
    67363: 'ICT',
    67364: 'ICT',
    67401: 'ICT',
    67402: 'ICT',
    67410: 'MCI',
    67416: 'ICT',
    67417: 'ICT',
    67418: 'ICT',
    67420: 'ICT',
    67422: 'ICT',
    67423: 'ICT',
    67425: 'ICT',
    67427: 'ICT',
    67428: 'ICT',
    67430: 'ICT',
    67431: 'MCI',
    67432: 'MCI',
    67436: 'ICT',
    67437: 'ICT',
    67438: 'ICT',
    67439: 'ICT',
    67441: 'MCI',
    67442: 'ICT',
    67443: 'ICT',
    67444: 'ICT',
    67445: 'ICT',
    67446: 'ICT',
    67447: 'MCI',
    67448: 'ICT',
    67449: 'MCI',
    67450: 'ICT',
    67451: 'MCI',
    67452: 'ICT',
    67454: 'ICT',
    67455: 'ICT',
    67456: 'ICT',
    67457: 'ICT',
    67458: 'MCI',
    67459: 'ICT',
    67460: 'ICT',
    67464: 'ICT',
    67466: 'ICT',
    67467: 'ICT',
    67468: 'MCI',
    67470: 'ICT',
    67473: 'ICT',
    67474: 'ICT',
    67475: 'MCI',
    67476: 'ICT',
    67478: 'ICT',
    67480: 'ICT',
    67481: 'ICT',
    67482: 'MCI',
    67483: 'ICT',
    67484: 'ICT',
    67485: 'ICT',
    67487: 'MCI',
    67490: 'ICT',
    67491: 'ICT',
    67492: 'MCI',
    67501: 'ICT',
    67502: 'ICT',
    67504: 'ICT',
    67505: 'ICT',
    67510: 'ICT',
    67511: 'ICT',
    67512: 'ICT',
    67513: 'COS',
    67514: 'ICT',
    67515: 'COS',
    67516: 'COS',
    67518: 'COS',
    67519: 'ICT',
    67520: 'ICT',
    67521: 'COS',
    67522: 'ICT',
    67523: 'COS',
    67524: 'ICT',
    67525: 'ICT',
    67526: 'ICT',
    67529: 'ICT',
    67530: 'ICT',
    67543: 'ICT',
    67544: 'ICT',
    67545: 'ICT',
    67546: 'ICT',
    67547: 'COS',
    67548: 'COS',
    67550: 'ICT',
    67552: 'ICT',
    67553: 'ICT',
    67554: 'ICT',
    67556: 'COS',
    67557: 'ICT',
    67559: 'COS',
    67560: 'COS',
    67561: 'ICT',
    67563: 'COS',
    67564: 'ICT',
    67565: 'ICT',
    67566: 'ICT',
    67567: 'ICT',
    67568: 'ICT',
    67570: 'ICT',
    67572: 'COS',
    67573: 'ICT',
    67574: 'COS',
    67575: 'ICT',
    67576: 'ICT',
    67578: 'ICT',
    67579: 'ICT',
    67581: 'ICT',
    67583: 'ICT',
    67584: 'COS',
    67585: 'ICT',
    67601: 'ICT',
    67621: 'ICT',
    67622: 'DEN',
    67623: 'ICT',
    67625: 'DEN',
    67626: 'ICT',
    67627: 'ICT',
    67628: 'ICT',
    67629: 'DEN',
    67631: 'DEN',
    67632: 'DEN',
    67634: 'ICT',
    67635: 'DEN',
    67637: 'DEN',
    67638: 'ICT',
    67639: 'ICT',
    67640: 'ICT',
    67642: 'DEN',
    67643: 'DEN',
    67644: 'ICT',
    67645: 'DEN',
    67646: 'DEN',
    67647: 'DEN',
    67648: 'ICT',
    67649: 'ICT',
    67650: 'DEN',
    67651: 'ICT',
    67653: 'DEN',
    67654: 'DEN',
    67656: 'DEN',
    67657: 'DEN',
    67658: 'ICT',
    67659: 'DEN',
    67660: 'ICT',
    67661: 'DEN',
    67663: 'ICT',
    67664: 'DEN',
    67665: 'ICT',
    67667: 'DEN',
    67669: 'DEN',
    67671: 'ICT',
    67672: 'DEN',
    67673: 'ICT',
    67674: 'ICT',
    67675: 'ICT',
    67701: 'DEN',
    67730: 'DEN',
    67731: 'DEN',
    67732: 'DEN',
    67733: 'DEN',
    67734: 'DEN',
    67735: 'DEN',
    67736: 'COS',
    67737: 'DEN',
    67738: 'COS',
    67739: 'DEN',
    67740: 'DEN',
    67741: 'DEN',
    67743: 'DEN',
    67744: 'DEN',
    67745: 'DEN',
    67747: 'COS',
    67748: 'COS',
    67749: 'DEN',
    67751: 'DEN',
    67752: 'COS',
    67753: 'DEN',
    67756: 'DEN',
    67757: 'DEN',
    67758: 'COS',
    67761: 'COS',
    67762: 'COS',
    67764: 'COS',
    67801: 'COS',
    67831: 'COS',
    67834: 'COS',
    67835: 'COS',
    67836: 'COS',
    67837: 'COS',
    67838: 'COS',
    67839: 'COS',
    67840: 'COS',
    67841: 'COS',
    67842: 'COS',
    67843: 'COS',
    67844: 'COS',
    67846: 'COS',
    67849: 'COS',
    67850: 'COS',
    67851: 'COS',
    67853: 'COS',
    67854: 'COS',
    67855: 'COS',
    67857: 'COS',
    67859: 'COS',
    67860: 'COS',
    67861: 'COS',
    67862: 'COS',
    67863: 'COS',
    67864: 'COS',
    67865: 'COS',
    67867: 'COS',
    67868: 'COS',
    67869: 'COS',
    67870: 'COS',
    67871: 'COS',
    67876: 'COS',
    67877: 'COS',
    67878: 'COS',
    67879: 'COS',
    67880: 'COS',
    67882: 'COS',
    67901: 'COS',
    67905: 'COS',
    67950: 'COS',
    67951: 'COS',
    67952: 'COS',
    67953: 'COS',
    67954: 'COS',
    68001: 'OMA',
    68002: 'OMA',
    68003: 'OMA',
    68004: 'OMA',
    68005: 'OMA',
    68007: 'OMA',
    68008: 'OMA',
    68009: 'OMA',
    68010: 'OMA',
    68014: 'OMA',
    68015: 'OMA',
    68016: 'OMA',
    68017: 'OMA',
    68018: 'OMA',
    68019: 'OMA',
    68020: 'OMA',
    68022: 'OMA',
    68023: 'OMA',
    68025: 'OMA',
    68026: 'OMA',
    68028: 'OMA',
    68029: 'OMA',
    68030: 'OMA',
    68031: 'OMA',
    68033: 'OMA',
    68034: 'OMA',
    68036: 'OMA',
    68037: 'OMA',
    68038: 'OMA',
    68039: 'OMA',
    68040: 'OMA',
    68041: 'OMA',
    68042: 'OMA',
    68044: 'OMA',
    68045: 'OMA',
    68046: 'OMA',
    68047: 'OMA',
    68048: 'OMA',
    68050: 'OMA',
    68055: 'OMA',
    68056: 'OMA',
    68057: 'OMA',
    68058: 'OMA',
    68059: 'OMA',
    68061: 'OMA',
    68062: 'OMA',
    68063: 'OMA',
    68064: 'OMA',
    68065: 'OMA',
    68066: 'OMA',
    68067: 'OMA',
    68068: 'OMA',
    68069: 'OMA',
    68070: 'OMA',
    68071: 'OMA',
    68072: 'OMA',
    68073: 'OMA',
    68101: 'OMA',
    68102: 'OMA',
    68103: 'OMA',
    68104: 'OMA',
    68105: 'OMA',
    68106: 'OMA',
    68107: 'OMA',
    68108: 'OMA',
    68109: 'OMA',
    68110: 'OMA',
    68111: 'OMA',
    68112: 'OMA',
    68113: 'OMA',
    68114: 'OMA',
    68116: 'OMA',
    68117: 'OMA',
    68118: 'OMA',
    68119: 'OMA',
    68120: 'OMA',
    68122: 'OMA',
    68123: 'OMA',
    68124: 'OMA',
    68127: 'OMA',
    68128: 'OMA',
    68130: 'OMA',
    68131: 'OMA',
    68132: 'OMA',
    68133: 'OMA',
    68134: 'OMA',
    68135: 'OMA',
    68136: 'OMA',
    68137: 'OMA',
    68138: 'OMA',
    68139: 'OMA',
    68142: 'OMA',
    68144: 'OMA',
    68145: 'OMA',
    68147: 'OMA',
    68152: 'OMA',
    68154: 'OMA',
    68155: 'OMA',
    68157: 'OMA',
    68164: 'OMA',
    68172: 'OMA',
    68175: 'OMA',
    68176: 'OMA',
    68178: 'OMA',
    68179: 'OMA',
    68180: 'OMA',
    68181: 'OMA',
    68182: 'OMA',
    68183: 'OMA',
    68197: 'OMA',
    68198: 'OMA',
    68301: 'OMA',
    68303: 'OMA',
    68304: 'OMA',
    68305: 'OMA',
    68307: 'OMA',
    68309: 'OMA',
    68310: 'OMA',
    68313: 'OMA',
    68314: 'OMA',
    68315: 'OMA',
    68316: 'OMA',
    68317: 'OMA',
    68318: 'OMA',
    68319: 'OMA',
    68320: 'OMA',
    68321: 'OMA',
    68322: 'OMA',
    68323: 'OMA',
    68324: 'OMA',
    68325: 'OMA',
    68326: 'OMA',
    68327: 'OMA',
    68328: 'OMA',
    68329: 'OMA',
    68330: 'OMA',
    68331: 'OMA',
    68332: 'OMA',
    68333: 'OMA',
    68335: 'OMA',
    68336: 'OMA',
    68337: 'OMA',
    68338: 'OMA',
    68339: 'OMA',
    68340: 'OMA',
    68341: 'OMA',
    68342: 'OMA',
    68343: 'OMA',
    68344: 'OMA',
    68345: 'OMA',
    68346: 'OMA',
    68347: 'OMA',
    68348: 'OMA',
    68349: 'OMA',
    68350: 'OMA',
    68351: 'OMA',
    68352: 'OMA',
    68354: 'OMA',
    68355: 'OMA',
    68357: 'OMA',
    68358: 'OMA',
    68359: 'OMA',
    68360: 'OMA',
    68361: 'OMA',
    68362: 'OMA',
    68364: 'OMA',
    68365: 'OMA',
    68366: 'OMA',
    68367: 'OMA',
    68368: 'OMA',
    68370: 'OMA',
    68371: 'OMA',
    68372: 'OMA',
    68375: 'OMA',
    68376: 'OMA',
    68377: 'OMA',
    68378: 'OMA',
    68379: 'OMA',
    68380: 'OMA',
    68381: 'OMA',
    68382: 'OMA',
    68401: 'OMA',
    68402: 'OMA',
    68403: 'OMA',
    68404: 'OMA',
    68405: 'OMA',
    68406: 'OMA',
    68407: 'OMA',
    68409: 'OMA',
    68410: 'OMA',
    68413: 'OMA',
    68414: 'OMA',
    68415: 'OMA',
    68416: 'OMA',
    68417: 'OMA',
    68418: 'OMA',
    68419: 'OMA',
    68420: 'OMA',
    68421: 'OMA',
    68422: 'OMA',
    68423: 'OMA',
    68424: 'OMA',
    68428: 'OMA',
    68429: 'OMA',
    68430: 'OMA',
    68431: 'MCI',
    68433: 'MCI',
    68434: 'OMA',
    68436: 'OMA',
    68437: 'OMA',
    68438: 'OMA',
    68439: 'OMA',
    68440: 'OMA',
    68441: 'OMA',
    68442: 'OMA',
    68443: 'OMA',
    68444: 'OMA',
    68445: 'OMA',
    68446: 'OMA',
    68447: 'OMA',
    68448: 'OMA',
    68450: 'OMA',
    68452: 'OMA',
    68453: 'OMA',
    68454: 'OMA',
    68455: 'OMA',
    68456: 'OMA',
    68457: 'OMA',
    68458: 'OMA',
    68460: 'OMA',
    68461: 'OMA',
    68462: 'OMA',
    68463: 'OMA',
    68464: 'OMA',
    68465: 'OMA',
    68466: 'OMA',
    68467: 'OMA',
    68501: 'OMA',
    68502: 'OMA',
    68503: 'OMA',
    68504: 'OMA',
    68505: 'OMA',
    68506: 'OMA',
    68507: 'OMA',
    68508: 'OMA',
    68509: 'OMA',
    68510: 'OMA',
    68512: 'OMA',
    68514: 'OMA',
    68516: 'OMA',
    68517: 'OMA',
    68520: 'OMA',
    68521: 'OMA',
    68522: 'OMA',
    68523: 'OMA',
    68524: 'OMA',
    68526: 'OMA',
    68527: 'OMA',
    68528: 'OMA',
    68529: 'OMA',
    68531: 'OMA',
    68532: 'OMA',
    68542: 'OMA',
    68544: 'OMA',
    68583: 'OMA',
    68588: 'OMA',
    68601: 'OMA',
    68602: 'OMA',
    68620: 'OMA',
    68621: 'OMA',
    68622: 'OMA',
    68623: 'OMA',
    68624: 'OMA',
    68626: 'OMA',
    68627: 'OMA',
    68628: 'OMA',
    68629: 'OMA',
    68631: 'OMA',
    68632: 'OMA',
    68633: 'OMA',
    68634: 'OMA',
    68635: 'OMA',
    68636: 'OMA',
    68637: 'OMA',
    68638: 'OMA',
    68640: 'OMA',
    68641: 'OMA',
    68642: 'OMA',
    68643: 'OMA',
    68644: 'OMA',
    68647: 'OMA',
    68648: 'OMA',
    68649: 'OMA',
    68651: 'OMA',
    68652: 'OMA',
    68653: 'OMA',
    68654: 'OMA',
    68655: 'OMA',
    68658: 'OMA',
    68659: 'OMA',
    68660: 'OMA',
    68661: 'OMA',
    68662: 'OMA',
    68663: 'OMA',
    68664: 'OMA',
    68665: 'OMA',
    68666: 'OMA',
    68667: 'OMA',
    68669: 'OMA',
    68701: 'OMA',
    68702: 'OMA',
    68710: 'OMA',
    68711: 'OMA',
    68713: 'OMA',
    68714: 'OMA',
    68715: 'OMA',
    68716: 'OMA',
    68717: 'OMA',
    68718: 'OMA',
    68719: 'OMA',
    68720: 'OMA',
    68722: 'OMA',
    68723: 'OMA',
    68724: 'OMA',
    68725: 'OMA',
    68726: 'OMA',
    68727: 'OMA',
    68728: 'OMA',
    68729: 'OMA',
    68730: 'OMA',
    68731: 'OMA',
    68732: 'OMA',
    68733: 'OMA',
    68734: 'OMA',
    68735: 'OMA',
    68736: 'OMA',
    68738: 'OMA',
    68739: 'OMA',
    68740: 'OMA',
    68741: 'OMA',
    68742: 'OMA',
    68743: 'OMA',
    68745: 'OMA',
    68746: 'OMA',
    68747: 'OMA',
    68748: 'OMA',
    68749: 'OMA',
    68751: 'OMA',
    68752: 'OMA',
    68753: 'OMA',
    68755: 'OMA',
    68756: 'OMA',
    68757: 'OMA',
    68758: 'OMA',
    68759: 'OMA',
    68760: 'OMA',
    68761: 'OMA',
    68763: 'OMA',
    68764: 'OMA',
    68765: 'OMA',
    68766: 'OMA',
    68767: 'OMA',
    68768: 'OMA',
    68769: 'OMA',
    68770: 'OMA',
    68771: 'OMA',
    68773: 'OMA',
    68774: 'OMA',
    68776: 'OMA',
    68777: 'OMA',
    68778: 'OMA',
    68779: 'OMA',
    68780: 'OMA',
    68781: 'OMA',
    68783: 'OMA',
    68784: 'OMA',
    68785: 'OMA',
    68786: 'OMA',
    68787: 'OMA',
    68788: 'OMA',
    68789: 'OMA',
    68790: 'OMA',
    68791: 'OMA',
    68792: 'OMA',
    68801: 'OMA',
    68802: 'OMA',
    68803: 'OMA',
    68810: 'OMA',
    68812: 'OMA',
    68813: 'OMA',
    68814: 'OMA',
    68815: 'OMA',
    68816: 'OMA',
    68817: 'OMA',
    68818: 'OMA',
    68820: 'OMA',
    68821: 'OMA',
    68822: 'OMA',
    68823: 'OMA',
    68824: 'OMA',
    68825: 'OMA',
    68826: 'OMA',
    68827: 'OMA',
    68828: 'OMA',
    68831: 'OMA',
    68832: 'OMA',
    68833: 'CYS',
    68834: 'OMA',
    68835: 'OMA',
    68836: 'OMA',
    68837: 'OMA',
    68838: 'OMA',
    68840: 'OMA',
    68841: 'OMA',
    68842: 'OMA',
    68843: 'OMA',
    68844: 'OMA',
    68845: 'OMA',
    68846: 'OMA',
    68847: 'OMA',
    68848: 'OMA',
    68849: 'OMA',
    68850: 'OMA',
    68852: 'OMA',
    68853: 'OMA',
    68854: 'OMA',
    68855: 'OMA',
    68856: 'OMA',
    68858: 'OMA',
    68859: 'OMA',
    68860: 'OMA',
    68861: 'OMA',
    68862: 'OMA',
    68863: 'OMA',
    68864: 'OMA',
    68865: 'OMA',
    68866: 'OMA',
    68869: 'OMA',
    68870: 'OMA',
    68871: 'OMA',
    68872: 'OMA',
    68873: 'OMA',
    68874: 'OMA',
    68875: 'OMA',
    68876: 'OMA',
    68878: 'OMA',
    68879: 'OMA',
    68881: 'OMA',
    68882: 'OMA',
    68883: 'OMA',
    68901: 'OMA',
    68902: 'OMA',
    68920: 'OMA',
    68922: 'DEN',
    68923: 'OMA',
    68924: 'OMA',
    68925: 'OMA',
    68926: 'DEN',
    68927: 'OMA',
    68928: 'OMA',
    68929: 'OMA',
    68930: 'OMA',
    68932: 'OMA',
    68933: 'OMA',
    68934: 'OMA',
    68935: 'OMA',
    68936: 'DEN',
    68937: 'OMA',
    68938: 'OMA',
    68939: 'OMA',
    68940: 'OMA',
    68941: 'OMA',
    68942: 'OMA',
    68943: 'OMA',
    68944: 'OMA',
    68945: 'OMA',
    68946: 'DEN',
    68947: 'OMA',
    68948: 'DEN',
    68949: 'OMA',
    68950: 'OMA',
    68952: 'OMA',
    68954: 'OMA',
    68955: 'OMA',
    68956: 'OMA',
    68957: 'OMA',
    68958: 'OMA',
    68959: 'OMA',
    68960: 'OMA',
    68961: 'OMA',
    68964: 'OMA',
    68966: 'OMA',
    68967: 'DEN',
    68969: 'OMA',
    68970: 'OMA',
    68971: 'OMA',
    68972: 'OMA',
    68973: 'OMA',
    68974: 'OMA',
    68975: 'OMA',
    68976: 'OMA',
    68977: 'OMA',
    68978: 'OMA',
    68979: 'OMA',
    68980: 'OMA',
    68981: 'OMA',
    68982: 'OMA',
    69001: 'DEN',
    69020: 'DEN',
    69021: 'DEN',
    69022: 'DEN',
    69023: 'DEN',
    69024: 'DEN',
    69025: 'DEN',
    69026: 'DEN',
    69027: 'DEN',
    69028: 'DEN',
    69029: 'CYS',
    69030: 'DEN',
    69032: 'DEN',
    69033: 'DEN',
    69034: 'DEN',
    69036: 'DEN',
    69037: 'DEN',
    69038: 'DEN',
    69039: 'DEN',
    69040: 'DEN',
    69041: 'DEN',
    69042: 'DEN',
    69043: 'DEN',
    69044: 'DEN',
    69045: 'DEN',
    69046: 'DEN',
    69101: 'CYS',
    69103: 'CYS',
    69120: 'CYS',
    69121: 'CYS',
    69122: 'CYS',
    69123: 'CYS',
    69125: 'CYS',
    69127: 'CYS',
    69128: 'CYS',
    69129: 'CYS',
    69130: 'CYS',
    69131: 'CYS',
    69132: 'CYS',
    69133: 'CYS',
    69134: 'CYS',
    69135: 'CYS',
    69138: 'CYS',
    69140: 'CYS',
    69141: 'CYS',
    69142: 'CYS',
    69143: 'CYS',
    69144: 'CYS',
    69145: 'CYS',
    69146: 'CYS',
    69147: 'CYS',
    69148: 'CYS',
    69149: 'CYS',
    69150: 'CYS',
    69151: 'CYS',
    69152: 'CYS',
    69153: 'CYS',
    69154: 'CYS',
    69155: 'CYS',
    69156: 'CYS',
    69157: 'CYS',
    69160: 'CYS',
    69161: 'CYS',
    69162: 'CYS',
    69163: 'CYS',
    69165: 'CYS',
    69166: 'CYS',
    69167: 'CYS',
    69168: 'CYS',
    69169: 'CYS',
    69170: 'CYS',
    69171: 'CYS',
    69190: 'CYS',
    69201: 'CYS',
    69210: 'CYS',
    69211: 'CYS',
    69212: 'CYS',
    69214: 'CYS',
    69216: 'CYS',
    69217: 'CYS',
    69218: 'CYS',
    69219: 'CYS',
    69220: 'CYS',
    69221: 'CYS',
    69301: 'CYS',
    69331: 'CYS',
    69333: 'CYS',
    69334: 'CYS',
    69335: 'CYS',
    69336: 'CYS',
    69337: 'CYS',
    69339: 'CYS',
    69340: 'CYS',
    69341: 'CYS',
    69343: 'CYS',
    69345: 'CYS',
    69346: 'CYS',
    69347: 'CYS',
    69348: 'CYS',
    69350: 'CYS',
    69351: 'CYS',
    69352: 'CYS',
    69353: 'CYS',
    69354: 'CYS',
    69355: 'CYS',
    69356: 'CYS',
    69357: 'CYS',
    69358: 'CYS',
    69360: 'CYS',
    69361: 'CYS',
    69363: 'CYS',
    69365: 'CYS',
    69366: 'CYS',
    69367: 'CYS',
    70001: 'MSY',
    70002: 'MSY',
    70003: 'MSY',
    70004: 'MSY',
    70005: 'MSY',
    70006: 'MSY',
    70009: 'MSY',
    70010: 'MSY',
    70011: 'MSY',
    70030: 'MSY',
    70031: 'MSY',
    70032: 'MSY',
    70033: 'MSY',
    70036: 'MSY',
    70037: 'MSY',
    70038: 'MSY',
    70039: 'MSY',
    70040: 'MSY',
    70041: 'MSY',
    70042: 'MSY',
    70043: 'MSY',
    70044: 'MSY',
    70046: 'MSY',
    70047: 'MSY',
    70049: 'MSY',
    70050: 'MSY',
    70051: 'MSY',
    70052: 'MSY',
    70053: 'MSY',
    70054: 'MSY',
    70055: 'MSY',
    70056: 'MSY',
    70057: 'MSY',
    70058: 'MSY',
    70059: 'MSY',
    70060: 'MSY',
    70062: 'MSY',
    70063: 'MSY',
    70064: 'MSY',
    70065: 'MSY',
    70066: 'MSY',
    70067: 'MSY',
    70068: 'MSY',
    70069: 'MSY',
    70070: 'MSY',
    70071: 'MSY',
    70072: 'MSY',
    70073: 'MSY',
    70075: 'MSY',
    70076: 'MSY',
    70078: 'MSY',
    70079: 'MSY',
    70080: 'MSY',
    70081: 'MSY',
    70082: 'MSY',
    70083: 'MSY',
    70084: 'MSY',
    70085: 'MSY',
    70086: 'MSY',
    70087: 'MSY',
    70090: 'MSY',
    70091: 'MSY',
    70092: 'MSY',
    70093: 'MSY',
    70094: 'MSY',
    70096: 'MSY',
    70097: 'MSY',
    70112: 'MSY',
    70113: 'MSY',
    70114: 'MSY',
    70115: 'MSY',
    70116: 'MSY',
    70117: 'MSY',
    70118: 'MSY',
    70119: 'MSY',
    70121: 'MSY',
    70122: 'MSY',
    70123: 'MSY',
    70124: 'MSY',
    70125: 'MSY',
    70126: 'MSY',
    70127: 'MSY',
    70128: 'MSY',
    70129: 'MSY',
    70130: 'MSY',
    70131: 'MSY',
    70139: 'MSY',
    70140: 'MSY',
    70141: 'MSY',
    70142: 'MSY',
    70143: 'MSY',
    70145: 'MSY',
    70146: 'MSY',
    70148: 'MSY',
    70149: 'MSY',
    70150: 'MSY',
    70151: 'MSY',
    70152: 'MSY',
    70153: 'MSY',
    70154: 'MSY',
    70156: 'MSY',
    70157: 'MSY',
    70158: 'MSY',
    70159: 'MSY',
    70160: 'MSY',
    70161: 'MSY',
    70162: 'MSY',
    70163: 'MSY',
    70164: 'MSY',
    70165: 'MSY',
    70166: 'MSY',
    70167: 'MSY',
    70170: 'MSY',
    70172: 'MSY',
    70174: 'MSY',
    70175: 'MSY',
    70176: 'MSY',
    70177: 'MSY',
    70178: 'MSY',
    70179: 'MSY',
    70181: 'MSY',
    70182: 'MSY',
    70183: 'MSY',
    70184: 'MSY',
    70185: 'MSY',
    70186: 'MSY',
    70187: 'MSY',
    70189: 'MSY',
    70190: 'MSY',
    70195: 'MSY',
    70301: 'MSY',
    70302: 'MSY',
    70310: 'MSY',
    70339: 'MSY',
    70340: 'MSY',
    70341: 'MSY',
    70342: 'MSY',
    70343: 'MSY',
    70344: 'MSY',
    70345: 'MSY',
    70346: 'MSY',
    70352: 'MSY',
    70353: 'MSY',
    70354: 'MSY',
    70355: 'MSY',
    70356: 'MSY',
    70357: 'MSY',
    70358: 'MSY',
    70359: 'MSY',
    70360: 'MSY',
    70361: 'MSY',
    70363: 'MSY',
    70364: 'MSY',
    70371: 'MSY',
    70372: 'MSY',
    70373: 'MSY',
    70374: 'MSY',
    70375: 'MSY',
    70376: 'MSY',
    70377: 'MSY',
    70380: 'MSY',
    70381: 'MSY',
    70390: 'MSY',
    70391: 'MSY',
    70392: 'MSY',
    70393: 'MSY',
    70394: 'MSY',
    70395: 'MSY',
    70397: 'MSY',
    70401: 'MSY',
    70402: 'MSY',
    70403: 'MSY',
    70404: 'MSY',
    70420: 'MSY',
    70421: 'MSY',
    70422: 'MSY',
    70426: 'MSY',
    70427: 'MSY',
    70429: 'MSY',
    70431: 'MSY',
    70433: 'MSY',
    70434: 'MSY',
    70435: 'MSY',
    70436: 'MSY',
    70437: 'MSY',
    70438: 'MSY',
    70441: 'BTR',
    70442: 'MSY',
    70443: 'MSY',
    70444: 'MSY',
    70445: 'MSY',
    70446: 'MSY',
    70447: 'MSY',
    70448: 'MSY',
    70449: 'MSY',
    70450: 'MSY',
    70451: 'MSY',
    70452: 'MSY',
    70453: 'BTR',
    70454: 'MSY',
    70455: 'MSY',
    70456: 'MSY',
    70457: 'MSY',
    70458: 'MSY',
    70459: 'MSY',
    70460: 'MSY',
    70461: 'MSY',
    70462: 'MSY',
    70463: 'MSY',
    70464: 'MSY',
    70465: 'MSY',
    70466: 'MSY',
    70467: 'MSY',
    70469: 'MSY',
    70470: 'MSY',
    70471: 'MSY',
    70500: 'LFT',
    70501: 'LFT',
    70502: 'LFT',
    70503: 'LFT',
    70504: 'LFT',
    70505: 'LFT',
    70506: 'LFT',
    70507: 'LFT',
    70508: 'LFT',
    70509: 'LFT',
    70510: 'LFT',
    70511: 'LFT',
    70512: 'LFT',
    70513: 'LFT',
    70514: 'MSY',
    70515: 'LFT',
    70516: 'LFT',
    70517: 'LFT',
    70518: 'LFT',
    70519: 'LFT',
    70520: 'LFT',
    70521: 'LFT',
    70522: 'MSY',
    70523: 'MSY',
    70524: 'LFT',
    70525: 'LFT',
    70526: 'LFT',
    70527: 'LFT',
    70528: 'LFT',
    70529: 'LFT',
    70531: 'LFT',
    70532: 'LFT',
    70533: 'LFT',
    70534: 'LFT',
    70535: 'LFT',
    70537: 'LFT',
    70538: 'MSY',
    70540: 'MSY',
    70541: 'LFT',
    70542: 'LFT',
    70543: 'LFT',
    70544: 'MSY',
    70546: 'LFT',
    70548: 'LFT',
    70549: 'LFT',
    70550: 'LFT',
    70551: 'LFT',
    70552: 'BTR',
    70554: 'LFT',
    70555: 'LFT',
    70556: 'LFT',
    70558: 'LFT',
    70559: 'LFT',
    70560: 'LFT',
    70562: 'LFT',
    70563: 'BTR',
    70569: 'LFT',
    70570: 'LFT',
    70571: 'LFT',
    70575: 'LFT',
    70576: 'LFT',
    70577: 'LFT',
    70578: 'LFT',
    70580: 'LFT',
    70581: 'LFT',
    70582: 'BTR',
    70583: 'LFT',
    70584: 'LFT',
    70585: 'LFT',
    70586: 'LFT',
    70589: 'LFT',
    70591: 'LFT',
    70592: 'LFT',
    70593: 'LFT',
    70595: 'LFT',
    70596: 'LFT',
    70598: 'LFT',
    70601: 'LFT',
    70602: 'LFT',
    70605: 'LFT',
    70606: 'LFT',
    70607: 'LFT',
    70609: 'LFT',
    70611: 'LFT',
    70612: 'LFT',
    70615: 'LFT',
    70616: 'LFT',
    70629: 'LFT',
    70630: 'LFT',
    70631: 'LFT',
    70632: 'LFT',
    70633: 'LFT',
    70634: 'LFT',
    70637: 'LFT',
    70638: 'LFT',
    70639: 'LFT',
    70640: 'LFT',
    70642: 'LFT',
    70643: 'LFT',
    70644: 'LFT',
    70645: 'LFT',
    70646: 'LFT',
    70647: 'LFT',
    70648: 'LFT',
    70650: 'LFT',
    70651: 'LFT',
    70652: 'LFT',
    70653: 'LFT',
    70654: 'LFT',
    70655: 'LFT',
    70656: 'LFT',
    70657: 'LFT',
    70658: 'LFT',
    70659: 'LFT',
    70660: 'LFT',
    70661: 'LFT',
    70662: 'LFT',
    70663: 'LFT',
    70664: 'LFT',
    70665: 'LFT',
    70668: 'LFT',
    70669: 'LFT',
    70704: 'BTR',
    70706: 'BTR',
    70707: 'BTR',
    70710: 'BTR',
    70711: 'MSY',
    70712: 'BTR',
    70714: 'BTR',
    70715: 'BTR',
    70716: 'BTR',
    70717: 'BTR',
    70718: 'BTR',
    70719: 'BTR',
    70720: 'BTR',
    70721: 'BTR',
    70722: 'BTR',
    70723: 'MSY',
    70725: 'MSY',
    70726: 'BTR',
    70727: 'BTR',
    70728: 'BTR',
    70729: 'BTR',
    70730: 'BTR',
    70732: 'BTR',
    70733: 'MSY',
    70734: 'BTR',
    70736: 'BTR',
    70737: 'BTR',
    70738: 'BTR',
    70739: 'BTR',
    70740: 'BTR',
    70743: 'MSY',
    70744: 'MSY',
    70747: 'BTR',
    70748: 'BTR',
    70749: 'BTR',
    70750: 'BTR',
    70751: 'BTR',
    70752: 'BTR',
    70753: 'BTR',
    70754: 'MSY',
    70755: 'BTR',
    70756: 'BTR',
    70757: 'BTR',
    70759: 'BTR',
    70760: 'BTR',
    70761: 'BTR',
    70762: 'BTR',
    70763: 'MSY',
    70764: 'BTR',
    70765: 'BTR',
    70767: 'BTR',
    70769: 'BTR',
    70770: 'BTR',
    70772: 'BTR',
    70773: 'BTR',
    70774: 'MSY',
    70775: 'BTR',
    70776: 'BTR',
    70777: 'BTR',
    70778: 'MSY',
    70780: 'BTR',
    70781: 'BTR',
    70782: 'BTR',
    70783: 'BTR',
    70784: 'BTR',
    70785: 'BTR',
    70786: 'BTR',
    70787: 'BTR',
    70788: 'BTR',
    70789: 'BTR',
    70791: 'BTR',
    70792: 'MSY',
    70801: 'BTR',
    70802: 'BTR',
    70803: 'BTR',
    70804: 'BTR',
    70805: 'BTR',
    70806: 'BTR',
    70807: 'BTR',
    70808: 'BTR',
    70809: 'BTR',
    70810: 'BTR',
    70811: 'BTR',
    70812: 'BTR',
    70813: 'BTR',
    70814: 'BTR',
    70815: 'BTR',
    70816: 'BTR',
    70817: 'BTR',
    70818: 'BTR',
    70819: 'BTR',
    70820: 'BTR',
    70821: 'BTR',
    70822: 'BTR',
    70823: 'BTR',
    70825: 'BTR',
    70826: 'BTR',
    70827: 'BTR',
    70831: 'BTR',
    70833: 'BTR',
    70835: 'BTR',
    70836: 'BTR',
    70837: 'BTR',
    70873: 'BTR',
    70874: 'BTR',
    70879: 'BTR',
    70883: 'BTR',
    70884: 'BTR',
    70891: 'BTR',
    70892: 'BTR',
    70893: 'BTR',
    70894: 'BTR',
    70895: 'BTR',
    70896: 'BTR',
    70898: 'BTR',
    71001: 'SHV',
    71002: 'SHV',
    71003: 'SHV',
    71004: 'SHV',
    71006: 'SHV',
    71007: 'SHV',
    71008: 'SHV',
    71009: 'SHV',
    71016: 'SHV',
    71018: 'SHV',
    71019: 'SHV',
    71021: 'SHV',
    71023: 'SHV',
    71024: 'SHV',
    71025: 'SHV',
    71027: 'SHV',
    71028: 'SHV',
    71029: 'SHV',
    71030: 'SHV',
    71031: 'SHV',
    71032: 'SHV',
    71033: 'SHV',
    71034: 'SHV',
    71036: 'SHV',
    71037: 'SHV',
    71038: 'SHV',
    71039: 'SHV',
    71040: 'SHV',
    71043: 'SHV',
    71044: 'SHV',
    71045: 'SHV',
    71046: 'SHV',
    71047: 'SHV',
    71048: 'SHV',
    71049: 'SHV',
    71050: 'SHV',
    71051: 'SHV',
    71052: 'SHV',
    71055: 'SHV',
    71058: 'SHV',
    71060: 'SHV',
    71061: 'SHV',
    71063: 'SHV',
    71064: 'SHV',
    71065: 'SHV',
    71066: 'SHV',
    71067: 'SHV',
    71068: 'SHV',
    71069: 'SHV',
    71070: 'SHV',
    71071: 'SHV',
    71072: 'SHV',
    71073: 'SHV',
    71075: 'SHV',
    71078: 'SHV',
    71079: 'SHV',
    71080: 'SHV',
    71082: 'SHV',
    71101: 'SHV',
    71102: 'SHV',
    71103: 'SHV',
    71104: 'SHV',
    71105: 'SHV',
    71106: 'SHV',
    71107: 'SHV',
    71108: 'SHV',
    71109: 'SHV',
    71110: 'SHV',
    71111: 'SHV',
    71112: 'SHV',
    71113: 'SHV',
    71115: 'SHV',
    71118: 'SHV',
    71119: 'SHV',
    71120: 'SHV',
    71129: 'SHV',
    71130: 'SHV',
    71133: 'SHV',
    71134: 'SHV',
    71135: 'SHV',
    71136: 'SHV',
    71137: 'SHV',
    71138: 'SHV',
    71148: 'SHV',
    71149: 'SHV',
    71150: 'SHV',
    71151: 'SHV',
    71152: 'SHV',
    71153: 'SHV',
    71154: 'SHV',
    71156: 'SHV',
    71161: 'SHV',
    71162: 'SHV',
    71163: 'SHV',
    71164: 'SHV',
    71165: 'SHV',
    71166: 'SHV',
    71171: 'SHV',
    71172: 'SHV',
    71201: 'SHV',
    71202: 'SHV',
    71203: 'SHV',
    71207: 'SHV',
    71208: 'SHV',
    71209: 'SHV',
    71210: 'SHV',
    71211: 'SHV',
    71212: 'SHV',
    71213: 'SHV',
    71217: 'SHV',
    71218: 'SHV',
    71219: 'SHV',
    71220: 'SHV',
    71221: 'SHV',
    71222: 'SHV',
    71223: 'SHV',
    71225: 'SHV',
    71226: 'SHV',
    71227: 'SHV',
    71229: 'SHV',
    71230: 'SHV',
    71232: 'SHV',
    71233: 'JAN',
    71234: 'SHV',
    71235: 'SHV',
    71237: 'SHV',
    71238: 'SHV',
    71240: 'SHV',
    71241: 'SHV',
    71242: 'SHV',
    71243: 'SHV',
    71245: 'SHV',
    71247: 'SHV',
    71249: 'SHV',
    71250: 'SHV',
    71251: 'SHV',
    71253: 'SHV',
    71254: 'SHV',
    71256: 'SHV',
    71259: 'SHV',
    71260: 'SHV',
    71261: 'SHV',
    71263: 'SHV',
    71264: 'SHV',
    71266: 'SHV',
    71268: 'SHV',
    71269: 'SHV',
    71270: 'SHV',
    71272: 'SHV',
    71273: 'SHV',
    71275: 'SHV',
    71276: 'SHV',
    71277: 'SHV',
    71279: 'SHV',
    71280: 'SHV',
    71281: 'SHV',
    71282: 'SHV',
    71284: 'SHV',
    71286: 'SHV',
    71291: 'SHV',
    71292: 'SHV',
    71294: 'SHV',
    71295: 'SHV',
    71301: 'LFT',
    71302: 'LFT',
    71303: 'SHV',
    71306: 'SHV',
    71307: 'LFT',
    71309: 'SHV',
    71315: 'SHV',
    71316: 'BTR',
    71320: 'LFT',
    71322: 'LFT',
    71323: 'LFT',
    71324: 'SHV',
    71325: 'LFT',
    71326: 'SHV',
    71327: 'LFT',
    71328: 'SHV',
    71329: 'LFT',
    71330: 'LFT',
    71331: 'LFT',
    71333: 'LFT',
    71334: 'SHV',
    71336: 'SHV',
    71339: 'LFT',
    71340: 'SHV',
    71341: 'LFT',
    71342: 'SHV',
    71343: 'SHV',
    71345: 'LFT',
    71346: 'LFT',
    71348: 'SHV',
    71350: 'LFT',
    71351: 'LFT',
    71353: 'BTR',
    71354: 'BTR',
    71355: 'BTR',
    71356: 'LFT',
    71357: 'SHV',
    71358: 'LFT',
    71359: 'SHV',
    71360: 'SHV',
    71361: 'SHV',
    71362: 'LFT',
    71363: 'SHV',
    71365: 'LFT',
    71366: 'SHV',
    71367: 'LFT',
    71368: 'SHV',
    71369: 'LFT',
    71371: 'SHV',
    71373: 'BTR',
    71375: 'SHV',
    71377: 'SHV',
    71378: 'SHV',
    71401: 'SHV',
    71403: 'SHV',
    71404: 'SHV',
    71405: 'SHV',
    71406: 'SHV',
    71407: 'SHV',
    71409: 'SHV',
    71410: 'SHV',
    71411: 'SHV',
    71414: 'SHV',
    71415: 'SHV',
    71416: 'SHV',
    71417: 'SHV',
    71418: 'SHV',
    71419: 'SHV',
    71422: 'SHV',
    71423: 'SHV',
    71424: 'LFT',
    71425: 'SHV',
    71426: 'SHV',
    71427: 'SHV',
    71428: 'SHV',
    71429: 'SHV',
    71430: 'LFT',
    71431: 'SHV',
    71432: 'SHV',
    71433: 'LFT',
    71434: 'SHV',
    71435: 'SHV',
    71438: 'LFT',
    71439: 'SHV',
    71440: 'SHV',
    71441: 'SHV',
    71443: 'SHV',
    71446: 'LFT',
    71447: 'SHV',
    71448: 'LFT',
    71449: 'SHV',
    71450: 'SHV',
    71452: 'SHV',
    71454: 'SHV',
    71455: 'SHV',
    71456: 'SHV',
    71457: 'SHV',
    71458: 'SHV',
    71459: 'LFT',
    71460: 'SHV',
    71461: 'LFT',
    71462: 'SHV',
    71463: 'LFT',
    71465: 'SHV',
    71466: 'LFT',
    71467: 'SHV',
    71468: 'SHV',
    71469: 'SHV',
    71471: 'SHV',
    71472: 'LFT',
    71473: 'SHV',
    71474: 'SHV',
    71475: 'SHV',
    71477: 'SHV',
    71479: 'SHV',
    71480: 'SHV',
    71481: 'SHV',
    71483: 'SHV',
    71485: 'LFT',
    71486: 'SHV',
    71496: 'LFT',
    71497: 'SHV',
    71601: 'LIT',
    71602: 'LIT',
    71603: 'LIT',
    71611: 'LIT',
    71612: 'LIT',
    71613: 'LIT',
    71630: 'LIT',
    71631: 'LIT',
    71635: 'SHV',
    71638: 'LIT',
    71639: 'LIT',
    71640: 'SHV',
    71642: 'LIT',
    71643: 'LIT',
    71644: 'LIT',
    71646: 'SHV',
    71647: 'LIT',
    71649: 'LIT',
    71651: 'LIT',
    71652: 'LIT',
    71653: 'SHV',
    71654: 'LIT',
    71655: 'LIT',
    71656: 'LIT',
    71657: 'LIT',
    71658: 'SHV',
    71659: 'LIT',
    71660: 'LIT',
    71661: 'SHV',
    71662: 'LIT',
    71663: 'SHV',
    71665: 'LIT',
    71666: 'LIT',
    71667: 'LIT',
    71670: 'LIT',
    71671: 'LIT',
    71674: 'LIT',
    71675: 'LIT',
    71676: 'SHV',
    71677: 'LIT',
    71678: 'LIT',
    71701: 'LIT',
    71711: 'LIT',
    71720: 'LIT',
    71721: 'LIT',
    71722: 'LIT',
    71724: 'LIT',
    71725: 'LIT',
    71726: 'LIT',
    71728: 'LIT',
    71730: 'LIT',
    71731: 'LIT',
    71740: 'SHV',
    71742: 'LIT',
    71743: 'LIT',
    71744: 'LIT',
    71745: 'LIT',
    71747: 'SHV',
    71748: 'LIT',
    71749: 'SHV',
    71750: 'LIT',
    71751: 'LIT',
    71752: 'LIT',
    71753: 'LIT',
    71754: 'LIT',
    71758: 'LIT',
    71759: 'LIT',
    71762: 'LIT',
    71763: 'LIT',
    71764: 'LIT',
    71765: 'SHV',
    71766: 'LIT',
    71768: 'LIT',
    71769: 'LIT',
    71770: 'LIT',
    71772: 'LIT',
    71801: 'LIT',
    71802: 'LIT',
    71820: 'LIT',
    71822: 'LIT',
    71823: 'LIT',
    71825: 'LIT',
    71826: 'SHV',
    71827: 'LIT',
    71828: 'LIT',
    71831: 'LIT',
    71832: 'LIT',
    71833: 'LIT',
    71834: 'SHV',
    71835: 'LIT',
    71836: 'LIT',
    71837: 'LIT',
    71838: 'LIT',
    71839: 'LIT',
    71840: 'LIT',
    71841: 'FSM',
    71842: 'LIT',
    71844: 'LIT',
    71845: 'LIT',
    71846: 'LIT',
    71847: 'LIT',
    71851: 'LIT',
    71852: 'LIT',
    71853: 'LIT',
    71854: 'LIT',
    71855: 'LIT',
    71857: 'LIT',
    71858: 'LIT',
    71859: 'LIT',
    71860: 'LIT',
    71861: 'SHV',
    71862: 'LIT',
    71864: 'LIT',
    71865: 'LIT',
    71866: 'LIT',
    71901: 'LIT',
    71902: 'LIT',
    71903: 'LIT',
    71909: 'LIT',
    71910: 'LIT',
    71913: 'LIT',
    71914: 'LIT',
    71920: 'LIT',
    71921: 'LIT',
    71922: 'LIT',
    71923: 'LIT',
    71929: 'LIT',
    71932: 'FSM',
    71933: 'LIT',
    71935: 'FSM',
    71937: 'FSM',
    71940: 'LIT',
    71941: 'LIT',
    71942: 'LIT',
    71943: 'LIT',
    71944: 'FSM',
    71945: 'FSM',
    71946: 'LIT',
    71949: 'LIT',
    71950: 'LIT',
    71951: 'LIT',
    71952: 'FSM',
    71953: 'FSM',
    71956: 'LIT',
    71957: 'FSM',
    71958: 'LIT',
    71959: 'LIT',
    71960: 'FSM',
    71961: 'FSM',
    71962: 'LIT',
    71964: 'LIT',
    71965: 'FSM',
    71966: 'FSM',
    71968: 'LIT',
    71969: 'FSM',
    71970: 'FSM',
    71971: 'FSM',
    71972: 'FSM',
    71973: 'FSM',
    71998: 'LIT',
    71999: 'LIT',
    72001: 'LIT',
    72002: 'LIT',
    72003: 'LIT',
    72004: 'LIT',
    72005: 'MEM',
    72006: 'LIT',
    72007: 'LIT',
    72010: 'LIT',
    72011: 'LIT',
    72012: 'LIT',
    72013: 'FSM',
    72014: 'MEM',
    72015: 'LIT',
    72016: 'LIT',
    72017: 'LIT',
    72018: 'LIT',
    72019: 'LIT',
    72020: 'LIT',
    72021: 'MEM',
    72022: 'LIT',
    72023: 'LIT',
    72024: 'LIT',
    72025: 'LIT',
    72026: 'MEM',
    72027: 'FSM',
    72028: 'FSM',
    72029: 'MEM',
    72030: 'FSM',
    72031: 'FSM',
    72032: 'LIT',
    72033: 'LIT',
    72034: 'LIT',
    72035: 'LIT',
    72036: 'MEM',
    72037: 'LIT',
    72038: 'MEM',
    72039: 'FSM',
    72040: 'LIT',
    72041: 'LIT',
    72042: 'MEM',
    72043: 'MEM',
    72044: 'FSM',
    72045: 'LIT',
    72046: 'LIT',
    72047: 'FSM',
    72048: 'MEM',
    72051: 'FSM',
    72052: 'LIT',
    72053: 'LIT',
    72055: 'LIT',
    72057: 'LIT',
    72058: 'FSM',
    72059: 'LIT',
    72060: 'LIT',
    72061: 'FSM',
    72063: 'FSM',
    72064: 'LIT',
    72065: 'LIT',
    72066: 'LIT',
    72067: 'FSM',
    72068: 'LIT',
    72069: 'MEM',
    72070: 'LIT',
    72071: 'LIT',
    72072: 'LIT',
    72073: 'LIT',
    72074: 'MEM',
    72075: 'LIT',
    72076: 'LIT',
    72078: 'LIT',
    72079: 'LIT',
    72080: 'FSM',
    72081: 'FSM',
    72082: 'FSM',
    72083: 'LIT',
    72084: 'LIT',
    72085: 'FSM',
    72086: 'LIT',
    72087: 'LIT',
    72088: 'FSM',
    72089: 'LIT',
    72099: 'LIT',
    72101: 'MEM',
    72102: 'LIT',
    72103: 'LIT',
    72104: 'LIT',
    72105: 'LIT',
    72106: 'LIT',
    72107: 'LIT',
    72108: 'MEM',
    72110: 'LIT',
    72111: 'FSM',
    72112: 'MEM',
    72113: 'LIT',
    72114: 'LIT',
    72115: 'LIT',
    72116: 'LIT',
    72117: 'LIT',
    72118: 'LIT',
    72119: 'LIT',
    72120: 'LIT',
    72121: 'FSM',
    72122: 'LIT',
    72123: 'MEM',
    72124: 'LIT',
    72125: 'LIT',
    72126: 'LIT',
    72127: 'LIT',
    72128: 'LIT',
    72129: 'LIT',
    72130: 'FSM',
    72131: 'FSM',
    72132: 'LIT',
    72133: 'LIT',
    72134: 'LIT',
    72135: 'LIT',
    72136: 'FSM',
    72137: 'FSM',
    72139: 'FSM',
    72140: 'MEM',
    72141: 'FSM',
    72142: 'LIT',
    72143: 'FSM',
    72145: 'LIT',
    72149: 'LIT',
    72150: 'LIT',
    72152: 'LIT',
    72153: 'FSM',
    72156: 'FSM',
    72157: 'FSM',
    72158: 'FSM',
    72160: 'LIT',
    72164: 'LIT',
    72165: 'MEM',
    72166: 'LIT',
    72167: 'LIT',
    72168: 'LIT',
    72169: 'MEM',
    72170: 'LIT',
    72173: 'LIT',
    72175: 'LIT',
    72176: 'LIT',
    72178: 'LIT',
    72179: 'FSM',
    72180: 'LIT',
    72181: 'LIT',
    72182: 'LIT',
    72183: 'LIT',
    72189: 'MEM',
    72190: 'LIT',
    72198: 'LIT',
    72199: 'LIT',
    72201: 'LIT',
    72202: 'LIT',
    72203: 'LIT',
    72204: 'LIT',
    72205: 'LIT',
    72206: 'LIT',
    72207: 'LIT',
    72209: 'LIT',
    72210: 'LIT',
    72211: 'LIT',
    72212: 'LIT',
    72214: 'LIT',
    72215: 'LIT',
    72216: 'LIT',
    72217: 'LIT',
    72219: 'LIT',
    72221: 'LIT',
    72222: 'LIT',
    72223: 'LIT',
    72225: 'LIT',
    72227: 'LIT',
    72231: 'LIT',
    72255: 'LIT',
    72260: 'LIT',
    72295: 'LIT',
    72301: 'MEM',
    72303: 'MEM',
    72310: 'MEM',
    72311: 'MEM',
    72312: 'MEM',
    72313: 'MEM',
    72314: 'MEM',
    72315: 'MEM',
    72316: 'MEM',
    72319: 'MEM',
    72320: 'MEM',
    72321: 'MEM',
    72322: 'MEM',
    72324: 'MEM',
    72325: 'MEM',
    72326: 'MEM',
    72327: 'MEM',
    72328: 'MEM',
    72329: 'MEM',
    72330: 'MEM',
    72331: 'MEM',
    72332: 'MEM',
    72333: 'MEM',
    72335: 'MEM',
    72336: 'MEM',
    72338: 'MEM',
    72339: 'MEM',
    72340: 'MEM',
    72341: 'MEM',
    72342: 'MEM',
    72346: 'MEM',
    72347: 'MEM',
    72348: 'MEM',
    72350: 'MEM',
    72351: 'MEM',
    72352: 'MEM',
    72353: 'MEM',
    72354: 'MEM',
    72355: 'MEM',
    72358: 'MEM',
    72359: 'MEM',
    72360: 'MEM',
    72364: 'MEM',
    72365: 'MEM',
    72366: 'MEM',
    72367: 'MEM',
    72368: 'MEM',
    72369: 'MEM',
    72370: 'MEM',
    72372: 'MEM',
    72373: 'MEM',
    72374: 'MEM',
    72376: 'MEM',
    72377: 'MEM',
    72379: 'MEM',
    72383: 'MEM',
    72384: 'MEM',
    72386: 'MEM',
    72387: 'MEM',
    72389: 'MEM',
    72390: 'MEM',
    72391: 'MEM',
    72392: 'MEM',
    72394: 'MEM',
    72395: 'MEM',
    72396: 'MEM',
    72401: 'MEM',
    72402: 'MEM',
    72403: 'MEM',
    72404: 'MEM',
    72405: 'MEM',
    72410: 'MEM',
    72411: 'MEM',
    72412: 'MEM',
    72413: 'MEM',
    72414: 'MEM',
    72415: 'MEM',
    72416: 'MEM',
    72417: 'MEM',
    72419: 'MEM',
    72421: 'MEM',
    72422: 'MEM',
    72424: 'MEM',
    72425: 'MEM',
    72426: 'MEM',
    72427: 'MEM',
    72428: 'MEM',
    72429: 'MEM',
    72430: 'MEM',
    72431: 'MEM',
    72432: 'MEM',
    72433: 'MEM',
    72434: 'MEM',
    72435: 'MEM',
    72436: 'MEM',
    72437: 'MEM',
    72438: 'MEM',
    72439: 'MEM',
    72440: 'MEM',
    72441: 'MEM',
    72442: 'MEM',
    72443: 'MEM',
    72444: 'MEM',
    72445: 'MEM',
    72447: 'MEM',
    72449: 'MEM',
    72450: 'MEM',
    72451: 'MEM',
    72453: 'MEM',
    72454: 'MEM',
    72455: 'MEM',
    72456: 'MEM',
    72457: 'MEM',
    72458: 'MEM',
    72459: 'SPZ',
    72460: 'SPZ',
    72461: 'MEM',
    72462: 'MEM',
    72464: 'MEM',
    72465: 'MEM',
    72466: 'MEM',
    72467: 'MEM',
    72469: 'SPZ',
    72470: 'MEM',
    72471: 'MEM',
    72472: 'MEM',
    72473: 'MEM',
    72474: 'MEM',
    72475: 'MEM',
    72476: 'MEM',
    72478: 'MEM',
    72479: 'MEM',
    72482: 'SPZ',
    72501: 'SPZ',
    72503: 'SPZ',
    72512: 'SPZ',
    72513: 'SPZ',
    72515: 'SPZ',
    72516: 'SPZ',
    72517: 'SPZ',
    72519: 'SPZ',
    72520: 'SPZ',
    72521: 'SPZ',
    72522: 'SPZ',
    72523: 'SPZ',
    72524: 'MEM',
    72525: 'SPZ',
    72526: 'SPZ',
    72527: 'SPZ',
    72528: 'SPZ',
    72529: 'SPZ',
    72530: 'SPZ',
    72531: 'SPZ',
    72532: 'SPZ',
    72533: 'SPZ',
    72534: 'SPZ',
    72536: 'SPZ',
    72537: 'SPZ',
    72538: 'SPZ',
    72539: 'SPZ',
    72540: 'SPZ',
    72542: 'SPZ',
    72543: 'FSM',
    72544: 'SPZ',
    72545: 'FSM',
    72546: 'FSM',
    72550: 'SPZ',
    72553: 'SPZ',
    72554: 'SPZ',
    72555: 'SPZ',
    72556: 'SPZ',
    72557: 'SPZ',
    72560: 'SPZ',
    72561: 'SPZ',
    72562: 'SPZ',
    72564: 'SPZ',
    72565: 'SPZ',
    72566: 'SPZ',
    72567: 'SPZ',
    72568: 'SPZ',
    72569: 'SPZ',
    72571: 'SPZ',
    72572: 'MEM',
    72573: 'SPZ',
    72575: 'SPZ',
    72576: 'SPZ',
    72577: 'SPZ',
    72578: 'SPZ',
    72579: 'SPZ',
    72581: 'FSM',
    72583: 'SPZ',
    72584: 'SPZ',
    72585: 'SPZ',
    72587: 'SPZ',
    72601: 'SPZ',
    72602: 'SPZ',
    72610: 'SPZ',
    72611: 'SPZ',
    72613: 'SPZ',
    72615: 'SPZ',
    72616: 'SPZ',
    72617: 'SPZ',
    72619: 'SPZ',
    72623: 'SPZ',
    72624: 'SPZ',
    72626: 'SPZ',
    72628: 'SPZ',
    72629: 'FSM',
    72630: 'SPZ',
    72631: 'SPZ',
    72632: 'SPZ',
    72633: 'SPZ',
    72634: 'SPZ',
    72635: 'SPZ',
    72636: 'SPZ',
    72638: 'SPZ',
    72639: 'SPZ',
    72640: 'SPZ',
    72641: 'SPZ',
    72642: 'SPZ',
    72644: 'SPZ',
    72645: 'SPZ',
    72648: 'SPZ',
    72650: 'SPZ',
    72651: 'SPZ',
    72653: 'SPZ',
    72654: 'SPZ',
    72655: 'SPZ',
    72657: 'SPZ',
    72658: 'SPZ',
    72659: 'SPZ',
    72660: 'SPZ',
    72661: 'SPZ',
    72662: 'SPZ',
    72663: 'SPZ',
    72666: 'SPZ',
    72668: 'SPZ',
    72669: 'SPZ',
    72670: 'SPZ',
    72672: 'SPZ',
    72675: 'SPZ',
    72677: 'SPZ',
    72679: 'FSM',
    72680: 'SPZ',
    72682: 'SPZ',
    72683: 'SPZ',
    72685: 'SPZ',
    72686: 'SPZ',
    72687: 'SPZ',
    72701: 'SPZ',
    72702: 'SPZ',
    72703: 'SPZ',
    72704: 'SPZ',
    72711: 'SPZ',
    72712: 'SPZ',
    72713: 'SPZ',
    72714: 'SPZ',
    72715: 'SPZ',
    72716: 'SPZ',
    72717: 'SPZ',
    72718: 'SPZ',
    72719: 'SPZ',
    72721: 'SPZ',
    72722: 'SPZ',
    72727: 'SPZ',
    72728: 'SPZ',
    72729: 'SPZ',
    72730: 'SPZ',
    72732: 'SPZ',
    72733: 'SPZ',
    72734: 'SPZ',
    72735: 'SPZ',
    72736: 'SPZ',
    72737: 'SPZ',
    72738: 'SPZ',
    72739: 'SPZ',
    72740: 'SPZ',
    72741: 'SPZ',
    72742: 'SPZ',
    72744: 'SPZ',
    72745: 'SPZ',
    72747: 'SPZ',
    72749: 'SPZ',
    72751: 'SPZ',
    72752: 'SPZ',
    72753: 'SPZ',
    72756: 'SPZ',
    72757: 'SPZ',
    72758: 'SPZ',
    72760: 'SPZ',
    72761: 'SPZ',
    72762: 'SPZ',
    72764: 'SPZ',
    72765: 'SPZ',
    72766: 'SPZ',
    72768: 'SPZ',
    72769: 'SPZ',
    72770: 'SPZ',
    72773: 'SPZ',
    72774: 'SPZ',
    72776: 'SPZ',
    72801: 'FSM',
    72802: 'FSM',
    72811: 'FSM',
    72812: 'FSM',
    72820: 'FSM',
    72821: 'FSM',
    72823: 'FSM',
    72824: 'FSM',
    72826: 'FSM',
    72827: 'FSM',
    72828: 'FSM',
    72829: 'FSM',
    72830: 'FSM',
    72832: 'FSM',
    72833: 'FSM',
    72834: 'FSM',
    72835: 'FSM',
    72837: 'FSM',
    72838: 'FSM',
    72839: 'FSM',
    72840: 'FSM',
    72841: 'FSM',
    72842: 'FSM',
    72843: 'FSM',
    72845: 'FSM',
    72846: 'FSM',
    72847: 'FSM',
    72851: 'FSM',
    72852: 'FSM',
    72853: 'FSM',
    72854: 'FSM',
    72855: 'FSM',
    72856: 'FSM',
    72857: 'FSM',
    72858: 'FSM',
    72860: 'FSM',
    72863: 'FSM',
    72865: 'FSM',
    72901: 'FSM',
    72902: 'FSM',
    72903: 'FSM',
    72904: 'FSM',
    72905: 'FSM',
    72906: 'FSM',
    72908: 'FSM',
    72913: 'FSM',
    72914: 'FSM',
    72916: 'FSM',
    72917: 'FSM',
    72918: 'FSM',
    72919: 'FSM',
    72921: 'FSM',
    72923: 'FSM',
    72924: 'FSM',
    72926: 'FSM',
    72927: 'FSM',
    72928: 'FSM',
    72930: 'FSM',
    72932: 'FSM',
    72933: 'FSM',
    72934: 'FSM',
    72935: 'FSM',
    72936: 'FSM',
    72937: 'FSM',
    72938: 'FSM',
    72940: 'FSM',
    72941: 'FSM',
    72943: 'FSM',
    72944: 'FSM',
    72945: 'FSM',
    72946: 'FSM',
    72947: 'FSM',
    72948: 'FSM',
    72949: 'FSM',
    72950: 'FSM',
    72951: 'FSM',
    72952: 'FSM',
    72955: 'FSM',
    72956: 'FSM',
    72957: 'FSM',
    72958: 'FSM',
    72959: 'SPZ',
    73001: 'OKC',
    73002: 'OKC',
    73003: 'OKC',
    73004: 'OKC',
    73005: 'OKC',
    73006: 'OKC',
    73007: 'OKC',
    73008: 'OKC',
    73009: 'OKC',
    73010: 'OKC',
    73011: 'OKC',
    73012: 'OKC',
    73013: 'OKC',
    73014: 'OKC',
    73015: 'OKC',
    73016: 'OKC',
    73017: 'OKC',
    73018: 'OKC',
    73019: 'OKC',
    73020: 'OKC',
    73021: 'OKC',
    73022: 'OKC',
    73023: 'OKC',
    73024: 'OKC',
    73025: 'OKC',
    73026: 'OKC',
    73027: 'OKC',
    73028: 'OKC',
    73029: 'OKC',
    73030: 'OKC',
    73031: 'OKC',
    73032: 'OKC',
    73033: 'OKC',
    73034: 'OKC',
    73036: 'OKC',
    73038: 'OKC',
    73039: 'OKC',
    73040: 'OKC',
    73041: 'OKC',
    73042: 'OKC',
    73043: 'OKC',
    73044: 'OKC',
    73045: 'OKC',
    73047: 'OKC',
    73048: 'OKC',
    73049: 'OKC',
    73050: 'OKC',
    73051: 'OKC',
    73052: 'OKC',
    73053: 'OKC',
    73054: 'OKC',
    73055: 'OKC',
    73056: 'OKC',
    73057: 'OKC',
    73058: 'OKC',
    73059: 'OKC',
    73061: 'OKC',
    73062: 'OKC',
    73063: 'OKC',
    73064: 'OKC',
    73065: 'OKC',
    73066: 'OKC',
    73067: 'OKC',
    73068: 'OKC',
    73069: 'OKC',
    73070: 'OKC',
    73071: 'OKC',
    73072: 'OKC',
    73073: 'OKC',
    73074: 'OKC',
    73075: 'OKC',
    73077: 'OKC',
    73078: 'OKC',
    73079: 'OKC',
    73080: 'OKC',
    73082: 'OKC',
    73083: 'OKC',
    73084: 'OKC',
    73085: 'OKC',
    73086: 'OKC',
    73089: 'OKC',
    73090: 'OKC',
    73092: 'OKC',
    73093: 'OKC',
    73094: 'OKC',
    73095: 'OKC',
    73096: 'OKC',
    73097: 'OKC',
    73098: 'OKC',
    73099: 'OKC',
    73101: 'OKC',
    73102: 'OKC',
    73103: 'OKC',
    73104: 'OKC',
    73105: 'OKC',
    73106: 'OKC',
    73107: 'OKC',
    73108: 'OKC',
    73109: 'OKC',
    73110: 'OKC',
    73111: 'OKC',
    73112: 'OKC',
    73113: 'OKC',
    73114: 'OKC',
    73115: 'OKC',
    73116: 'OKC',
    73117: 'OKC',
    73118: 'OKC',
    73119: 'OKC',
    73120: 'OKC',
    73121: 'OKC',
    73122: 'OKC',
    73123: 'OKC',
    73124: 'OKC',
    73125: 'OKC',
    73126: 'OKC',
    73127: 'OKC',
    73128: 'OKC',
    73129: 'OKC',
    73130: 'OKC',
    73131: 'OKC',
    73132: 'OKC',
    73134: 'OKC',
    73135: 'OKC',
    73136: 'OKC',
    73137: 'OKC',
    73139: 'OKC',
    73140: 'OKC',
    73141: 'OKC',
    73142: 'OKC',
    73143: 'OKC',
    73144: 'OKC',
    73145: 'OKC',
    73146: 'OKC',
    73147: 'OKC',
    73148: 'OKC',
    73149: 'OKC',
    73150: 'OKC',
    73151: 'OKC',
    73152: 'OKC',
    73153: 'OKC',
    73154: 'OKC',
    73155: 'OKC',
    73156: 'OKC',
    73157: 'OKC',
    73159: 'OKC',
    73160: 'OKC',
    73162: 'OKC',
    73163: 'OKC',
    73164: 'OKC',
    73165: 'OKC',
    73167: 'OKC',
    73169: 'OKC',
    73170: 'OKC',
    73172: 'OKC',
    73173: 'OKC',
    73178: 'OKC',
    73179: 'OKC',
    73184: 'OKC',
    73185: 'OKC',
    73189: 'OKC',
    73190: 'OKC',
    73193: 'OKC',
    73194: 'OKC',
    73195: 'OKC',
    73196: 'OKC',
    73197: 'OKC',
    73198: 'OKC',
    73199: 'OKC',
    73301: 'AUS',
    73344: 'AUS',
    73401: 'OKC',
    73402: 'OKC',
    73403: 'OKC',
    73425: 'OKC',
    73430: 'OKC',
    73432: 'TUL',
    73433: 'OKC',
    73434: 'OKC',
    73435: 'OKC',
    73436: 'OKC',
    73437: 'OKC',
    73438: 'OKC',
    73439: 'TUL',
    73440: 'OKC',
    73441: 'OKC',
    73442: 'OKC',
    73443: 'OKC',
    73444: 'OKC',
    73446: 'OKC',
    73447: 'OKC',
    73448: 'OKC',
    73449: 'TUL',
    73450: 'TUL',
    73451: 'OKC',
    73453: 'OKC',
    73454: 'OKC',
    73455: 'OKC',
    73456: 'OKC',
    73458: 'OKC',
    73459: 'OKC',
    73460: 'TUL',
    73461: 'TUL',
    73463: 'OKC',
    73481: 'OKC',
    73487: 'OKC',
    73488: 'OKC',
    73491: 'OKC',
    73501: 'OKC',
    73502: 'OKC',
    73503: 'OKC',
    73505: 'OKC',
    73506: 'OKC',
    73507: 'OKC',
    73520: 'OKC',
    73521: 'OKC',
    73522: 'OKC',
    73523: 'OKC',
    73526: 'OKC',
    73527: 'OKC',
    73528: 'OKC',
    73529: 'OKC',
    73530: 'OKC',
    73531: 'OKC',
    73532: 'OKC',
    73533: 'OKC',
    73534: 'OKC',
    73536: 'OKC',
    73537: 'OKC',
    73538: 'OKC',
    73539: 'OKC',
    73540: 'OKC',
    73541: 'OKC',
    73542: 'OKC',
    73543: 'OKC',
    73544: 'OKC',
    73546: 'OKC',
    73547: 'OKC',
    73548: 'OKC',
    73549: 'OKC',
    73550: 'OKC',
    73551: 'OKC',
    73552: 'OKC',
    73553: 'OKC',
    73554: 'OKC',
    73555: 'OKC',
    73556: 'OKC',
    73557: 'OKC',
    73558: 'OKC',
    73559: 'OKC',
    73560: 'OKC',
    73561: 'OKC',
    73562: 'OKC',
    73564: 'OKC',
    73565: 'OKC',
    73566: 'OKC',
    73567: 'OKC',
    73568: 'OKC',
    73569: 'OKC',
    73570: 'OKC',
    73571: 'OKC',
    73572: 'OKC',
    73573: 'OKC',
    73601: 'OKC',
    73620: 'OKC',
    73622: 'OKC',
    73624: 'OKC',
    73625: 'OKC',
    73626: 'OKC',
    73627: 'OKC',
    73628: 'OKC',
    73632: 'OKC',
    73638: 'OKC',
    73639: 'OKC',
    73641: 'OKC',
    73642: 'OKC',
    73644: 'OKC',
    73645: 'OKC',
    73646: 'OKC',
    73647: 'OKC',
    73648: 'OKC',
    73650: 'OKC',
    73651: 'OKC',
    73654: 'OKC',
    73655: 'OKC',
    73658: 'OKC',
    73659: 'OKC',
    73660: 'OKC',
    73661: 'OKC',
    73662: 'OKC',
    73663: 'OKC',
    73664: 'OKC',
    73666: 'OKC',
    73667: 'OKC',
    73668: 'OKC',
    73669: 'OKC',
    73673: 'OKC',
    73701: 'OKC',
    73702: 'OKC',
    73703: 'OKC',
    73705: 'OKC',
    73706: 'OKC',
    73716: 'OKC',
    73717: 'OKC',
    73718: 'OKC',
    73719: 'OKC',
    73720: 'OKC',
    73722: 'OKC',
    73724: 'OKC',
    73726: 'OKC',
    73727: 'OKC',
    73728: 'OKC',
    73729: 'OKC',
    73730: 'OKC',
    73731: 'OKC',
    73733: 'OKC',
    73734: 'OKC',
    73735: 'OKC',
    73736: 'OKC',
    73737: 'OKC',
    73738: 'OKC',
    73739: 'OKC',
    73741: 'OKC',
    73742: 'OKC',
    73743: 'OKC',
    73744: 'OKC',
    73746: 'OKC',
    73747: 'OKC',
    73749: 'OKC',
    73750: 'OKC',
    73753: 'OKC',
    73754: 'OKC',
    73755: 'OKC',
    73756: 'OKC',
    73757: 'OKC',
    73758: 'OKC',
    73759: 'OKC',
    73760: 'OKC',
    73761: 'OKC',
    73762: 'OKC',
    73763: 'OKC',
    73764: 'OKC',
    73766: 'OKC',
    73768: 'OKC',
    73770: 'OKC',
    73771: 'OKC',
    73772: 'OKC',
    73773: 'OKC',
    73801: 'OKC',
    73802: 'OKC',
    73832: 'OKC',
    73834: 'OKC',
    73835: 'OKC',
    73838: 'OKC',
    73840: 'OKC',
    73841: 'OKC',
    73842: 'OKC',
    73843: 'OKC',
    73844: 'OKC',
    73848: 'OKC',
    73851: 'OKC',
    73852: 'OKC',
    73853: 'OKC',
    73855: 'OKC',
    73857: 'OKC',
    73858: 'OKC',
    73859: 'OKC',
    73860: 'OKC',
    73901: 'OKC',
    73931: 'OKC',
    73932: 'OKC',
    73933: 'OKC',
    73937: 'OKC',
    73938: 'OKC',
    73939: 'OKC',
    73942: 'OKC',
    73944: 'OKC',
    73945: 'OKC',
    73946: 'OKC',
    73947: 'OKC',
    73949: 'OKC',
    73950: 'OKC',
    73951: 'OKC',
    73960: 'DFW',
    74001: 'TUL',
    74002: 'TUL',
    74003: 'TUL',
    74004: 'TUL',
    74005: 'TUL',
    74006: 'TUL',
    74008: 'TUL',
    74010: 'TUL',
    74011: 'TUL',
    74012: 'TUL',
    74013: 'TUL',
    74014: 'TUL',
    74015: 'TUL',
    74016: 'TUL',
    74017: 'TUL',
    74018: 'TUL',
    74019: 'TUL',
    74020: 'TUL',
    74021: 'TUL',
    74022: 'TUL',
    74023: 'TUL',
    74026: 'OKC',
    74027: 'TUL',
    74028: 'TUL',
    74029: 'TUL',
    74030: 'TUL',
    74031: 'TUL',
    74032: 'OKC',
    74033: 'TUL',
    74034: 'TUL',
    74035: 'TUL',
    74036: 'TUL',
    74037: 'TUL',
    74038: 'TUL',
    74039: 'TUL',
    74041: 'TUL',
    74042: 'TUL',
    74043: 'TUL',
    74044: 'TUL',
    74045: 'TUL',
    74046: 'TUL',
    74047: 'TUL',
    74048: 'TUL',
    74050: 'TUL',
    74051: 'TUL',
    74052: 'TUL',
    74053: 'TUL',
    74054: 'TUL',
    74055: 'TUL',
    74056: 'TUL',
    74058: 'OKC',
    74059: 'OKC',
    74060: 'TUL',
    74061: 'TUL',
    74062: 'OKC',
    74063: 'TUL',
    74066: 'TUL',
    74067: 'TUL',
    74068: 'TUL',
    74070: 'TUL',
    74071: 'TUL',
    74072: 'TUL',
    74073: 'TUL',
    74074: 'OKC',
    74075: 'OKC',
    74076: 'OKC',
    74077: 'OKC',
    74078: 'OKC',
    74079: 'TUL',
    74080: 'TUL',
    74081: 'TUL',
    74082: 'TUL',
    74083: 'TUL',
    74084: 'TUL',
    74085: 'TUL',
    74101: 'TUL',
    74102: 'TUL',
    74103: 'TUL',
    74104: 'TUL',
    74105: 'TUL',
    74106: 'TUL',
    74107: 'TUL',
    74108: 'TUL',
    74110: 'TUL',
    74112: 'TUL',
    74114: 'TUL',
    74115: 'TUL',
    74116: 'TUL',
    74117: 'TUL',
    74119: 'TUL',
    74120: 'TUL',
    74121: 'TUL',
    74126: 'TUL',
    74127: 'TUL',
    74128: 'TUL',
    74129: 'TUL',
    74130: 'TUL',
    74131: 'TUL',
    74132: 'TUL',
    74133: 'TUL',
    74134: 'TUL',
    74135: 'TUL',
    74136: 'TUL',
    74137: 'TUL',
    74141: 'TUL',
    74145: 'TUL',
    74146: 'TUL',
    74147: 'TUL',
    74148: 'TUL',
    74149: 'TUL',
    74150: 'TUL',
    74152: 'TUL',
    74153: 'TUL',
    74155: 'TUL',
    74156: 'TUL',
    74157: 'TUL',
    74158: 'TUL',
    74159: 'TUL',
    74169: 'TUL',
    74170: 'TUL',
    74171: 'TUL',
    74172: 'TUL',
    74182: 'TUL',
    74183: 'TUL',
    74184: 'TUL',
    74186: 'TUL',
    74187: 'TUL',
    74189: 'TUL',
    74192: 'TUL',
    74193: 'TUL',
    74194: 'TUL',
    74301: 'TUL',
    74330: 'TUL',
    74331: 'TUL',
    74332: 'TUL',
    74333: 'TUL',
    74335: 'TUL',
    74337: 'TUL',
    74338: 'TUL',
    74339: 'TUL',
    74340: 'TUL',
    74342: 'TUL',
    74343: 'TUL',
    74344: 'TUL',
    74345: 'TUL',
    74346: 'TUL',
    74347: 'TUL',
    74349: 'TUL',
    74350: 'TUL',
    74352: 'TUL',
    74354: 'TUL',
    74355: 'TUL',
    74358: 'TUL',
    74359: 'TUL',
    74360: 'TUL',
    74361: 'TUL',
    74362: 'TUL',
    74363: 'TUL',
    74364: 'TUL',
    74365: 'TUL',
    74366: 'TUL',
    74367: 'TUL',
    74368: 'TUL',
    74369: 'TUL',
    74370: 'TUL',
    74401: 'TUL',
    74402: 'TUL',
    74403: 'TUL',
    74421: 'TUL',
    74422: 'TUL',
    74423: 'TUL',
    74425: 'TUL',
    74426: 'TUL',
    74427: 'TUL',
    74428: 'TUL',
    74429: 'TUL',
    74430: 'TUL',
    74431: 'TUL',
    74432: 'TUL',
    74434: 'TUL',
    74435: 'TUL',
    74436: 'TUL',
    74437: 'TUL',
    74438: 'TUL',
    74439: 'TUL',
    74440: 'TUL',
    74441: 'TUL',
    74442: 'TUL',
    74444: 'TUL',
    74445: 'TUL',
    74446: 'TUL',
    74447: 'TUL',
    74450: 'TUL',
    74451: 'TUL',
    74452: 'TUL',
    74454: 'TUL',
    74455: 'TUL',
    74456: 'TUL',
    74457: 'TUL',
    74458: 'TUL',
    74459: 'TUL',
    74460: 'TUL',
    74461: 'TUL',
    74462: 'TUL',
    74463: 'TUL',
    74464: 'TUL',
    74465: 'TUL',
    74467: 'TUL',
    74468: 'TUL',
    74469: 'TUL',
    74470: 'TUL',
    74471: 'TUL',
    74472: 'TUL',
    74477: 'TUL',
    74501: 'TUL',
    74502: 'TUL',
    74521: 'TUL',
    74522: 'TUL',
    74523: 'TUL',
    74525: 'TUL',
    74528: 'TUL',
    74529: 'TUL',
    74530: 'TUL',
    74531: 'TUL',
    74533: 'TUL',
    74534: 'TUL',
    74535: 'TUL',
    74536: 'TUL',
    74538: 'TUL',
    74540: 'TUL',
    74542: 'TUL',
    74543: 'TUL',
    74545: 'TUL',
    74546: 'TUL',
    74547: 'TUL',
    74549: 'TUL',
    74552: 'TUL',
    74553: 'TUL',
    74554: 'TUL',
    74555: 'TUL',
    74556: 'TUL',
    74557: 'TUL',
    74558: 'TUL',
    74559: 'TUL',
    74560: 'TUL',
    74561: 'TUL',
    74562: 'TUL',
    74563: 'TUL',
    74565: 'TUL',
    74567: 'TUL',
    74569: 'TUL',
    74570: 'TUL',
    74571: 'TUL',
    74572: 'TUL',
    74574: 'TUL',
    74576: 'TUL',
    74577: 'TUL',
    74578: 'TUL',
    74601: 'OKC',
    74602: 'OKC',
    74604: 'OKC',
    74630: 'OKC',
    74631: 'OKC',
    74632: 'OKC',
    74633: 'TUL',
    74636: 'OKC',
    74637: 'TUL',
    74640: 'OKC',
    74641: 'OKC',
    74643: 'OKC',
    74644: 'OKC',
    74646: 'OKC',
    74647: 'OKC',
    74650: 'OKC',
    74651: 'OKC',
    74652: 'TUL',
    74653: 'OKC',
    74701: 'TUL',
    74702: 'TUL',
    74720: 'TUL',
    74721: 'TUL',
    74722: 'TUL',
    74723: 'TUL',
    74724: 'TUL',
    74726: 'TUL',
    74727: 'TUL',
    74728: 'TUL',
    74729: 'TUL',
    74730: 'TUL',
    74731: 'TUL',
    74733: 'TUL',
    74734: 'TUL',
    74735: 'TUL',
    74736: 'TUL',
    74737: 'TUL',
    74738: 'TUL',
    74740: 'TUL',
    74741: 'TUL',
    74743: 'TUL',
    74745: 'TUL',
    74747: 'TUL',
    74748: 'TUL',
    74750: 'TUL',
    74752: 'TUL',
    74753: 'TUL',
    74754: 'TUL',
    74755: 'TUL',
    74756: 'TUL',
    74759: 'TUL',
    74760: 'TUL',
    74761: 'TUL',
    74764: 'TUL',
    74766: 'TUL',
    74801: 'OKC',
    74802: 'OKC',
    74804: 'OKC',
    74818: 'TUL',
    74820: 'OKC',
    74821: 'TUL',
    74824: 'OKC',
    74825: 'TUL',
    74826: 'OKC',
    74827: 'TUL',
    74829: 'TUL',
    74830: 'TUL',
    74831: 'OKC',
    74832: 'OKC',
    74833: 'TUL',
    74834: 'OKC',
    74836: 'TUL',
    74837: 'TUL',
    74839: 'TUL',
    74840: 'OKC',
    74842: 'TUL',
    74843: 'OKC',
    74844: 'TUL',
    74845: 'TUL',
    74848: 'TUL',
    74849: 'TUL',
    74850: 'TUL',
    74851: 'OKC',
    74852: 'OKC',
    74854: 'OKC',
    74855: 'OKC',
    74856: 'OKC',
    74857: 'OKC',
    74859: 'TUL',
    74860: 'TUL',
    74864: 'TUL',
    74865: 'OKC',
    74866: 'OKC',
    74867: 'TUL',
    74868: 'TUL',
    74869: 'OKC',
    74871: 'TUL',
    74872: 'OKC',
    74873: 'OKC',
    74875: 'OKC',
    74878: 'OKC',
    74880: 'TUL',
    74881: 'OKC',
    74883: 'TUL',
    74884: 'TUL',
    74901: 'TUL',
    74902: 'TUL',
    74930: 'TUL',
    74931: 'TUL',
    74932: 'TUL',
    74935: 'TUL',
    74936: 'TUL',
    74937: 'TUL',
    74939: 'TUL',
    74940: 'TUL',
    74941: 'TUL',
    74942: 'TUL',
    74943: 'TUL',
    74944: 'TUL',
    74945: 'TUL',
    74946: 'TUL',
    74947: 'TUL',
    74948: 'TUL',
    74949: 'TUL',
    74951: 'TUL',
    74953: 'TUL',
    74954: 'TUL',
    74955: 'TUL',
    74956: 'TUL',
    74957: 'TUL',
    74959: 'TUL',
    74960: 'TUL',
    74962: 'TUL',
    74963: 'TUL',
    74964: 'TUL',
    74965: 'TUL',
    74966: 'TUL',
    75001: 'DFW',
    75002: 'DFW',
    75006: 'DFW',
    75007: 'DFW',
    75009: 'DFW',
    75010: 'DFW',
    75011: 'DFW',
    75013: 'DFW',
    75014: 'DFW',
    75015: 'DFW',
    75016: 'DFW',
    75017: 'DFW',
    75019: 'DFW',
    75020: 'DFW',
    75021: 'DFW',
    75022: 'DFW',
    75023: 'DFW',
    75024: 'DFW',
    75025: 'DFW',
    75026: 'DFW',
    75027: 'DFW',
    75028: 'DFW',
    75029: 'DFW',
    75030: 'DFW',
    75032: 'DFW',
    75033: 'DFW',
    75034: 'DFW',
    75035: 'DFW',
    75036: 'DFW',
    75037: 'DFW',
    75038: 'DFW',
    75039: 'DFW',
    75040: 'DFW',
    75041: 'DFW',
    75042: 'DFW',
    75043: 'DFW',
    75044: 'DFW',
    75045: 'DFW',
    75046: 'DFW',
    75047: 'DFW',
    75048: 'DFW',
    75049: 'DFW',
    75050: 'DFW',
    75051: 'DFW',
    75052: 'DFW',
    75053: 'DFW',
    75054: 'DFW',
    75056: 'DFW',
    75057: 'DFW',
    75058: 'DFW',
    75060: 'DFW',
    75061: 'DFW',
    75062: 'DFW',
    75063: 'DFW',
    75064: 'DFW',
    75065: 'DFW',
    75067: 'DFW',
    75068: 'DFW',
    75069: 'DFW',
    75070: 'DFW',
    75071: 'DFW',
    75072: 'DFW',
    75074: 'DFW',
    75075: 'DFW',
    75076: 'DFW',
    75077: 'DFW',
    75078: 'DFW',
    75080: 'DFW',
    75081: 'DFW',
    75082: 'DFW',
    75083: 'DFW',
    75085: 'DFW',
    75086: 'DFW',
    75087: 'DFW',
    75088: 'DFW',
    75089: 'DFW',
    75090: 'DFW',
    75091: 'DFW',
    75092: 'DFW',
    75093: 'DFW',
    75094: 'DFW',
    75097: 'DFW',
    75098: 'DFW',
    75099: 'DFW',
    75101: 'DFW',
    75102: 'DFW',
    75103: 'DFW',
    75104: 'DFW',
    75105: 'DFW',
    75106: 'DFW',
    75109: 'DFW',
    75110: 'DFW',
    75114: 'DFW',
    75115: 'DFW',
    75116: 'DFW',
    75117: 'DFW',
    75118: 'DFW',
    75119: 'DFW',
    75120: 'DFW',
    75121: 'DFW',
    75123: 'DFW',
    75124: 'DFW',
    75125: 'DFW',
    75126: 'DFW',
    75127: 'DFW',
    75132: 'DFW',
    75134: 'DFW',
    75135: 'DFW',
    75137: 'DFW',
    75138: 'DFW',
    75140: 'DFW',
    75141: 'DFW',
    75142: 'DFW',
    75143: 'DFW',
    75144: 'DFW',
    75146: 'DFW',
    75147: 'DFW',
    75148: 'DFW',
    75149: 'DFW',
    75150: 'DFW',
    75151: 'DFW',
    75152: 'DFW',
    75153: 'DFW',
    75154: 'DFW',
    75155: 'DFW',
    75156: 'DFW',
    75157: 'DFW',
    75158: 'DFW',
    75159: 'DFW',
    75160: 'DFW',
    75161: 'DFW',
    75163: 'DFW',
    75164: 'DFW',
    75165: 'DFW',
    75166: 'DFW',
    75167: 'DFW',
    75168: 'DFW',
    75169: 'DFW',
    75172: 'DFW',
    75173: 'DFW',
    75180: 'DFW',
    75181: 'DFW',
    75182: 'DFW',
    75185: 'DFW',
    75187: 'DFW',
    75189: 'DFW',
    75201: 'DFW',
    75202: 'DFW',
    75203: 'DFW',
    75204: 'DFW',
    75205: 'DFW',
    75206: 'DFW',
    75207: 'DFW',
    75208: 'DFW',
    75209: 'DFW',
    75210: 'DFW',
    75211: 'DFW',
    75212: 'DFW',
    75214: 'DFW',
    75215: 'DFW',
    75216: 'DFW',
    75217: 'DFW',
    75218: 'DFW',
    75219: 'DFW',
    75220: 'DFW',
    75221: 'DFW',
    75222: 'DFW',
    75223: 'DFW',
    75224: 'DFW',
    75225: 'DFW',
    75226: 'DFW',
    75227: 'DFW',
    75228: 'DFW',
    75229: 'DFW',
    75230: 'DFW',
    75231: 'DFW',
    75232: 'DFW',
    75233: 'DFW',
    75234: 'DFW',
    75235: 'DFW',
    75236: 'DFW',
    75237: 'DFW',
    75238: 'DFW',
    75240: 'DFW',
    75241: 'DFW',
    75242: 'DFW',
    75243: 'DFW',
    75244: 'DFW',
    75245: 'DFW',
    75246: 'DFW',
    75247: 'DFW',
    75248: 'DFW',
    75249: 'DFW',
    75250: 'DFW',
    75251: 'DFW',
    75252: 'DFW',
    75253: 'DFW',
    75254: 'DFW',
    75258: 'DFW',
    75260: 'DFW',
    75261: 'DFW',
    75262: 'DFW',
    75263: 'DFW',
    75264: 'DFW',
    75265: 'DFW',
    75266: 'DFW',
    75267: 'DFW',
    75270: 'DFW',
    75275: 'DFW',
    75277: 'DFW',
    75283: 'DFW',
    75284: 'DFW',
    75285: 'DFW',
    75286: 'DFW',
    75287: 'DFW',
    75301: 'DFW',
    75303: 'DFW',
    75310: 'DFW',
    75312: 'DFW',
    75313: 'DFW',
    75315: 'DFW',
    75320: 'DFW',
    75323: 'DFW',
    75326: 'DFW',
    75334: 'DFW',
    75336: 'DFW',
    75339: 'DFW',
    75340: 'DFW',
    75342: 'DFW',
    75343: 'DFW',
    75344: 'DFW',
    75353: 'DFW',
    75354: 'DFW',
    75355: 'DFW',
    75356: 'DFW',
    75357: 'DFW',
    75358: 'DFW',
    75359: 'DFW',
    75360: 'DFW',
    75363: 'DFW',
    75364: 'DFW',
    75367: 'DFW',
    75368: 'DFW',
    75370: 'DFW',
    75371: 'DFW',
    75372: 'DFW',
    75373: 'DFW',
    75374: 'DFW',
    75376: 'DFW',
    75378: 'DFW',
    75379: 'DFW',
    75380: 'DFW',
    75381: 'DFW',
    75382: 'DFW',
    75386: 'DFW',
    75387: 'DFW',
    75388: 'DFW',
    75389: 'DFW',
    75390: 'DFW',
    75391: 'DFW',
    75392: 'DFW',
    75393: 'DFW',
    75394: 'DFW',
    75395: 'DFW',
    75396: 'DFW',
    75397: 'DFW',
    75398: 'DFW',
    75401: 'DFW',
    75402: 'DFW',
    75403: 'DFW',
    75404: 'DFW',
    75407: 'DFW',
    75409: 'DFW',
    75410: 'DFW',
    75411: 'DFW',
    75412: 'DFW',
    75413: 'DFW',
    75414: 'DFW',
    75415: 'DFW',
    75416: 'DFW',
    75417: 'DFW',
    75418: 'DFW',
    75420: 'DFW',
    75421: 'DFW',
    75422: 'DFW',
    75423: 'DFW',
    75424: 'DFW',
    75425: 'DFW',
    75426: 'DFW',
    75428: 'DFW',
    75429: 'DFW',
    75431: 'DFW',
    75432: 'DFW',
    75433: 'DFW',
    75434: 'DFW',
    75435: 'DFW',
    75436: 'DFW',
    75437: 'DFW',
    75438: 'DFW',
    75439: 'DFW',
    75440: 'DFW',
    75441: 'DFW',
    75442: 'DFW',
    75443: 'DFW',
    75444: 'DFW',
    75446: 'DFW',
    75447: 'DFW',
    75448: 'DFW',
    75449: 'DFW',
    75450: 'DFW',
    75451: 'DFW',
    75452: 'DFW',
    75453: 'DFW',
    75454: 'DFW',
    75455: 'DFW',
    75456: 'DFW',
    75457: 'DFW',
    75458: 'DFW',
    75459: 'DFW',
    75460: 'DFW',
    75461: 'DFW',
    75462: 'DFW',
    75468: 'DFW',
    75469: 'DFW',
    75470: 'DFW',
    75471: 'DFW',
    75472: 'DFW',
    75473: 'DFW',
    75474: 'DFW',
    75475: 'DFW',
    75476: 'DFW',
    75477: 'DFW',
    75478: 'DFW',
    75479: 'DFW',
    75480: 'DFW',
    75481: 'DFW',
    75482: 'DFW',
    75483: 'DFW',
    75485: 'DFW',
    75486: 'DFW',
    75487: 'DFW',
    75488: 'DFW',
    75489: 'DFW',
    75490: 'DFW',
    75491: 'DFW',
    75492: 'DFW',
    75493: 'DFW',
    75494: 'DFW',
    75495: 'DFW',
    75496: 'DFW',
    75497: 'DFW',
    75501: 'DFW',
    75503: 'DFW',
    75504: 'DFW',
    75505: 'DFW',
    75507: 'DFW',
    75550: 'DFW',
    75551: 'DFW',
    75554: 'DFW',
    75555: 'DFW',
    75556: 'DFW',
    75558: 'DFW',
    75559: 'DFW',
    75560: 'DFW',
    75561: 'DFW',
    75562: 'DFW',
    75563: 'DFW',
    75564: 'DFW',
    75565: 'DFW',
    75566: 'DFW',
    75567: 'DFW',
    75568: 'DFW',
    75569: 'DFW',
    75570: 'DFW',
    75571: 'DFW',
    75572: 'DFW',
    75573: 'DFW',
    75574: 'DFW',
    75599: 'DFW',
    75601: 'DFW',
    75602: 'DFW',
    75603: 'DFW',
    75604: 'DFW',
    75605: 'DFW',
    75606: 'DFW',
    75607: 'DFW',
    75608: 'DFW',
    75615: 'DFW',
    75630: 'DFW',
    75631: 'DFW',
    75633: 'DFW',
    75636: 'DFW',
    75637: 'DFW',
    75638: 'DFW',
    75639: 'DFW',
    75640: 'DFW',
    75641: 'DFW',
    75642: 'DFW',
    75643: 'DFW',
    75644: 'DFW',
    75645: 'DFW',
    75647: 'DFW',
    75650: 'DFW',
    75651: 'DFW',
    75652: 'DFW',
    75653: 'DFW',
    75654: 'DFW',
    75656: 'DFW',
    75657: 'DFW',
    75658: 'DFW',
    75659: 'DFW',
    75660: 'DFW',
    75661: 'DFW',
    75662: 'DFW',
    75663: 'DFW',
    75666: 'DFW',
    75667: 'DFW',
    75668: 'DFW',
    75669: 'DFW',
    75670: 'DFW',
    75671: 'DFW',
    75672: 'DFW',
    75680: 'DFW',
    75681: 'IAH',
    75682: 'DFW',
    75683: 'DFW',
    75684: 'DFW',
    75685: 'DFW',
    75686: 'DFW',
    75687: 'DFW',
    75688: 'DFW',
    75689: 'DFW',
    75691: 'DFW',
    75692: 'DFW',
    75693: 'DFW',
    75694: 'DFW',
    75701: 'DFW',
    75702: 'DFW',
    75703: 'DFW',
    75704: 'DFW',
    75705: 'DFW',
    75706: 'DFW',
    75707: 'DFW',
    75708: 'DFW',
    75709: 'DFW',
    75710: 'DFW',
    75711: 'DFW',
    75712: 'DFW',
    75713: 'DFW',
    75750: 'DFW',
    75751: 'DFW',
    75752: 'DFW',
    75754: 'DFW',
    75755: 'DFW',
    75756: 'DFW',
    75757: 'DFW',
    75758: 'DFW',
    75759: 'DFW',
    75760: 'IAH',
    75762: 'DFW',
    75763: 'DFW',
    75764: 'DFW',
    75765: 'DFW',
    75766: 'DFW',
    75770: 'DFW',
    75771: 'DFW',
    75772: 'IAH',
    75773: 'DFW',
    75778: 'DFW',
    75779: 'DFW',
    75780: 'DFW',
    75782: 'DFW',
    75783: 'DFW',
    75784: 'DFW',
    75785: 'IAH',
    75788: 'IAH',
    75789: 'DFW',
    75790: 'DFW',
    75791: 'DFW',
    75792: 'DFW',
    75797: 'DFW',
    75798: 'DFW',
    75799: 'DFW',
    75801: 'DFW',
    75802: 'DFW',
    75803: 'DFW',
    75831: 'IAH',
    75832: 'DFW',
    75833: 'IAH',
    75834: 'IAH',
    75835: 'IAH',
    75838: 'IAH',
    75839: 'IAH',
    75840: 'DFW',
    75844: 'IAH',
    75845: 'IAH',
    75846: 'IAH',
    75847: 'IAH',
    75848: 'DFW',
    75849: 'IAH',
    75850: 'IAH',
    75851: 'IAH',
    75852: 'IAH',
    75853: 'DFW',
    75855: 'IAH',
    75856: 'IAH',
    75858: 'IAH',
    75859: 'DFW',
    75860: 'DFW',
    75861: 'DFW',
    75862: 'IAH',
    75865: 'IAH',
    75880: 'DFW',
    75882: 'DFW',
    75884: 'DFW',
    75886: 'DFW',
    75901: 'IAH',
    75902: 'IAH',
    75903: 'IAH',
    75904: 'IAH',
    75915: 'IAH',
    75925: 'IAH',
    75926: 'IAH',
    75928: 'IAH',
    75929: 'IAH',
    75930: 'IAH',
    75931: 'IAH',
    75932: 'IAH',
    75933: 'IAH',
    75934: 'IAH',
    75935: 'IAH',
    75936: 'IAH',
    75937: 'IAH',
    75938: 'IAH',
    75939: 'IAH',
    75941: 'IAH',
    75942: 'IAH',
    75943: 'IAH',
    75944: 'IAH',
    75946: 'IAH',
    75948: 'IAH',
    75949: 'IAH',
    75951: 'IAH',
    75954: 'IAH',
    75956: 'IAH',
    75958: 'IAH',
    75959: 'IAH',
    75960: 'IAH',
    75961: 'IAH',
    75962: 'IAH',
    75963: 'IAH',
    75964: 'IAH',
    75965: 'IAH',
    75966: 'IAH',
    75968: 'IAH',
    75969: 'IAH',
    75972: 'IAH',
    75973: 'IAH',
    75974: 'IAH',
    75975: 'IAH',
    75976: 'IAH',
    75977: 'IAH',
    75978: 'IAH',
    75979: 'IAH',
    75980: 'IAH',
    75990: 'IAH',
    76001: 'DFW',
    76002: 'DFW',
    76003: 'DFW',
    76004: 'DFW',
    76005: 'DFW',
    76006: 'DFW',
    76007: 'DFW',
    76008: 'DFW',
    76009: 'DFW',
    76010: 'DFW',
    76011: 'DFW',
    76012: 'DFW',
    76013: 'DFW',
    76014: 'DFW',
    76015: 'DFW',
    76016: 'DFW',
    76017: 'DFW',
    76018: 'DFW',
    76019: 'DFW',
    76020: 'DFW',
    76021: 'DFW',
    76022: 'DFW',
    76023: 'DFW',
    76028: 'DFW',
    76031: 'DFW',
    76033: 'DFW',
    76034: 'DFW',
    76035: 'DFW',
    76036: 'DFW',
    76039: 'DFW',
    76040: 'DFW',
    76041: 'DFW',
    76043: 'DFW',
    76044: 'DFW',
    76048: 'DFW',
    76049: 'DFW',
    76050: 'DFW',
    76051: 'DFW',
    76052: 'DFW',
    76053: 'DFW',
    76054: 'DFW',
    76055: 'DFW',
    76058: 'DFW',
    76059: 'DFW',
    76060: 'DFW',
    76061: 'DFW',
    76063: 'DFW',
    76064: 'DFW',
    76065: 'DFW',
    76066: 'DFW',
    76067: 'DFW',
    76068: 'DFW',
    76070: 'DFW',
    76071: 'DFW',
    76073: 'DFW',
    76077: 'DFW',
    76078: 'DFW',
    76082: 'DFW',
    76084: 'DFW',
    76085: 'DFW',
    76086: 'DFW',
    76087: 'DFW',
    76088: 'DFW',
    76092: 'DFW',
    76093: 'DFW',
    76094: 'DFW',
    76095: 'DFW',
    76096: 'DFW',
    76097: 'DFW',
    76098: 'DFW',
    76099: 'DFW',
    76101: 'DFW',
    76102: 'DFW',
    76103: 'DFW',
    76104: 'DFW',
    76105: 'DFW',
    76106: 'DFW',
    76107: 'DFW',
    76108: 'DFW',
    76109: 'DFW',
    76110: 'DFW',
    76111: 'DFW',
    76112: 'DFW',
    76113: 'DFW',
    76114: 'DFW',
    76115: 'DFW',
    76116: 'DFW',
    76117: 'DFW',
    76118: 'DFW',
    76119: 'DFW',
    76120: 'DFW',
    76121: 'DFW',
    76122: 'DFW',
    76123: 'DFW',
    76124: 'DFW',
    76126: 'DFW',
    76127: 'DFW',
    76129: 'DFW',
    76130: 'DFW',
    76131: 'DFW',
    76132: 'DFW',
    76133: 'DFW',
    76134: 'DFW',
    76135: 'DFW',
    76136: 'DFW',
    76137: 'DFW',
    76140: 'DFW',
    76147: 'DFW',
    76148: 'DFW',
    76150: 'DFW',
    76155: 'DFW',
    76161: 'DFW',
    76162: 'DFW',
    76163: 'DFW',
    76164: 'DFW',
    76166: 'DFW',
    76177: 'DFW',
    76179: 'DFW',
    76180: 'DFW',
    76181: 'DFW',
    76182: 'DFW',
    76185: 'DFW',
    76190: 'DFW',
    76191: 'DFW',
    76192: 'DFW',
    76193: 'DFW',
    76195: 'DFW',
    76196: 'DFW',
    76197: 'DFW',
    76198: 'DFW',
    76199: 'DFW',
    76201: 'DFW',
    76202: 'DFW',
    76203: 'DFW',
    76204: 'DFW',
    76205: 'DFW',
    76206: 'DFW',
    76207: 'DFW',
    76208: 'DFW',
    76209: 'DFW',
    76210: 'DFW',
    76225: 'DFW',
    76226: 'DFW',
    76227: 'DFW',
    76228: 'KIP',
    76230: 'DFW',
    76233: 'DFW',
    76234: 'DFW',
    76238: 'DFW',
    76239: 'DFW',
    76240: 'DFW',
    76241: 'DFW',
    76244: 'DFW',
    76245: 'DFW',
    76246: 'DFW',
    76247: 'DFW',
    76248: 'DFW',
    76249: 'DFW',
    76250: 'DFW',
    76251: 'KIP',
    76252: 'DFW',
    76253: 'DFW',
    76255: 'KIP',
    76258: 'DFW',
    76259: 'DFW',
    76261: 'KIP',
    76262: 'DFW',
    76263: 'DFW',
    76264: 'DFW',
    76265: 'KIP',
    76266: 'DFW',
    76267: 'DFW',
    76268: 'DFW',
    76270: 'DFW',
    76271: 'DFW',
    76272: 'DFW',
    76273: 'DFW',
    76299: 'DFW',
    76301: 'KIP',
    76302: 'KIP',
    76305: 'KIP',
    76306: 'KIP',
    76307: 'KIP',
    76308: 'KIP',
    76309: 'KIP',
    76310: 'KIP',
    76311: 'KIP',
    76351: 'KIP',
    76352: 'KIP',
    76354: 'KIP',
    76357: 'KIP',
    76360: 'KIP',
    76363: 'KIP',
    76364: 'KIP',
    76365: 'KIP',
    76366: 'KIP',
    76367: 'KIP',
    76369: 'KIP',
    76370: 'KIP',
    76371: 'KIP',
    76372: 'KIP',
    76373: 'KIP',
    76374: 'KIP',
    76377: 'KIP',
    76379: 'KIP',
    76380: 'KIP',
    76384: 'KIP',
    76385: 'KIP',
    76388: 'KIP',
    76389: 'KIP',
    76401: 'DFW',
    76402: 'DFW',
    76424: 'ABI',
    76426: 'DFW',
    76427: 'ABI',
    76429: 'ABI',
    76430: 'ABI',
    76431: 'DFW',
    76432: 'ABI',
    76433: 'DFW',
    76435: 'ABI',
    76436: 'DFW',
    76437: 'ABI',
    76439: 'DFW',
    76442: 'ABI',
    76443: 'ABI',
    76444: 'ABI',
    76445: 'ABI',
    76446: 'ABI',
    76448: 'ABI',
    76449: 'ABI',
    76450: 'ABI',
    76452: 'ABI',
    76453: 'ABI',
    76454: 'ABI',
    76455: 'ABI',
    76457: 'DFW',
    76458: 'KIP',
    76459: 'ABI',
    76460: 'KIP',
    76461: 'ABI',
    76462: 'DFW',
    76463: 'ABI',
    76464: 'ABI',
    76465: 'DFW',
    76466: 'ABI',
    76467: 'DFW',
    76468: 'ABI',
    76469: 'ABI',
    76470: 'ABI',
    76471: 'ABI',
    76472: 'DFW',
    76474: 'ABI',
    76475: 'ABI',
    76476: 'DFW',
    76481: 'ABI',
    76483: 'KIP',
    76484: 'ABI',
    76485: 'DFW',
    76486: 'DFW',
    76487: 'DFW',
    76490: 'DFW',
    76491: 'ABI',
    76501: 'AUS',
    76502: 'AUS',
    76503: 'AUS',
    76504: 'AUS',
    76505: 'AUS',
    76508: 'AUS',
    76511: 'AUS',
    76513: 'AUS',
    76518: 'AUS',
    76519: 'AUS',
    76520: 'AUS',
    76522: 'AUS',
    76523: 'AUS',
    76524: 'AUS',
    76525: 'AUS',
    76526: 'AUS',
    76527: 'AUS',
    76528: 'AUS',
    76530: 'AUS',
    76531: 'AUS',
    76533: 'AUS',
    76534: 'AUS',
    76537: 'AUS',
    76538: 'AUS',
    76539: 'AUS',
    76540: 'AUS',
    76541: 'AUS',
    76542: 'AUS',
    76543: 'AUS',
    76544: 'AUS',
    76545: 'AUS',
    76546: 'AUS',
    76547: 'AUS',
    76548: 'AUS',
    76549: 'AUS',
    76550: 'AUS',
    76554: 'AUS',
    76556: 'AUS',
    76557: 'AUS',
    76558: 'AUS',
    76559: 'AUS',
    76561: 'AUS',
    76564: 'AUS',
    76565: 'ABI',
    76566: 'AUS',
    76567: 'AUS',
    76569: 'AUS',
    76570: 'AUS',
    76571: 'AUS',
    76573: 'AUS',
    76574: 'AUS',
    76577: 'AUS',
    76578: 'AUS',
    76579: 'AUS',
    76596: 'AUS',
    76597: 'AUS',
    76598: 'AUS',
    76599: 'AUS',
    76621: 'DFW',
    76622: 'DFW',
    76623: 'DFW',
    76624: 'AUS',
    76626: 'DFW',
    76627: 'DFW',
    76628: 'DFW',
    76629: 'AUS',
    76630: 'AUS',
    76631: 'DFW',
    76632: 'AUS',
    76633: 'AUS',
    76634: 'DFW',
    76635: 'DFW',
    76636: 'DFW',
    76637: 'DFW',
    76638: 'AUS',
    76639: 'DFW',
    76640: 'AUS',
    76641: 'DFW',
    76642: 'DFW',
    76643: 'AUS',
    76644: 'DFW',
    76645: 'DFW',
    76648: 'DFW',
    76649: 'DFW',
    76650: 'DFW',
    76651: 'DFW',
    76652: 'DFW',
    76653: 'AUS',
    76654: 'DFW',
    76655: 'AUS',
    76656: 'AUS',
    76657: 'AUS',
    76660: 'DFW',
    76661: 'AUS',
    76664: 'DFW',
    76665: 'DFW',
    76666: 'DFW',
    76667: 'DFW',
    76670: 'DFW',
    76671: 'DFW',
    76673: 'DFW',
    76676: 'DFW',
    76678: 'DFW',
    76679: 'DFW',
    76680: 'AUS',
    76681: 'DFW',
    76682: 'AUS',
    76684: 'DFW',
    76685: 'AUS',
    76686: 'DFW',
    76687: 'IAH',
    76689: 'AUS',
    76690: 'DFW',
    76691: 'DFW',
    76692: 'DFW',
    76693: 'DFW',
    76701: 'AUS',
    76702: 'AUS',
    76703: 'AUS',
    76704: 'AUS',
    76705: 'AUS',
    76706: 'AUS',
    76707: 'AUS',
    76708: 'AUS',
    76710: 'AUS',
    76711: 'AUS',
    76712: 'AUS',
    76714: 'AUS',
    76715: 'AUS',
    76716: 'AUS',
    76795: 'AUS',
    76797: 'AUS',
    76798: 'AUS',
    76799: 'AUS',
    76801: 'ABI',
    76802: 'ABI',
    76803: 'ABI',
    76804: 'ABI',
    76820: 'AUS',
    76821: 'ABI',
    76823: 'ABI',
    76824: 'AUS',
    76825: 'ABI',
    76827: 'ABI',
    76828: 'ABI',
    76831: 'AUS',
    76832: 'AUS',
    76834: 'ABI',
    76836: 'ABI',
    76837: 'ABI',
    76841: 'ABI',
    76842: 'ABI',
    76844: 'AUS',
    76845: 'ABI',
    76848: 'ABI',
    76849: 'SAT',
    76852: 'ABI',
    76853: 'AUS',
    76854: 'ABI',
    76855: 'ABI',
    76856: 'ABI',
    76857: 'ABI',
    76858: 'ABI',
    76859: 'ABI',
    76861: 'MAF',
    76862: 'ABI',
    76864: 'ABI',
    76865: 'MAF',
    76866: 'ABI',
    76869: 'AUS',
    76870: 'ABI',
    76871: 'AUS',
    76872: 'ABI',
    76873: 'ABI',
    76874: 'SAT',
    76875: 'ABI',
    76877: 'AUS',
    76878: 'ABI',
    76880: 'AUS',
    76882: 'ABI',
    76883: 'SAT',
    76884: 'ABI',
    76885: 'AUS',
    76886: 'MAF',
    76887: 'ABI',
    76888: 'ABI',
    76890: 'ABI',
    76901: 'MAF',
    76902: 'MAF',
    76903: 'MAF',
    76904: 'MAF',
    76905: 'MAF',
    76906: 'MAF',
    76908: 'MAF',
    76909: 'MAF',
    76930: 'MAF',
    76932: 'MAF',
    76933: 'MAF',
    76934: 'MAF',
    76935: 'MAF',
    76936: 'MAF',
    76937: 'ABI',
    76939: 'MAF',
    76940: 'MAF',
    76941: 'MAF',
    76943: 'SAT',
    76945: 'MAF',
    76949: 'MAF',
    76950: 'SAT',
    76951: 'MAF',
    76953: 'MAF',
    76955: 'MAF',
    76957: 'MAF',
    76958: 'MAF',
    77001: 'IAH',
    77002: 'IAH',
    77003: 'IAH',
    77004: 'IAH',
    77005: 'IAH',
    77006: 'IAH',
    77007: 'IAH',
    77008: 'IAH',
    77009: 'IAH',
    77010: 'IAH',
    77011: 'IAH',
    77012: 'IAH',
    77013: 'IAH',
    77014: 'IAH',
    77015: 'IAH',
    77016: 'IAH',
    77017: 'IAH',
    77018: 'IAH',
    77019: 'IAH',
    77020: 'IAH',
    77021: 'IAH',
    77022: 'IAH',
    77023: 'IAH',
    77024: 'IAH',
    77025: 'IAH',
    77026: 'IAH',
    77027: 'IAH',
    77028: 'IAH',
    77029: 'IAH',
    77030: 'IAH',
    77031: 'IAH',
    77032: 'IAH',
    77033: 'IAH',
    77034: 'IAH',
    77035: 'IAH',
    77036: 'IAH',
    77037: 'IAH',
    77038: 'IAH',
    77039: 'IAH',
    77040: 'IAH',
    77041: 'IAH',
    77042: 'IAH',
    77043: 'IAH',
    77044: 'IAH',
    77045: 'IAH',
    77046: 'IAH',
    77047: 'IAH',
    77048: 'IAH',
    77049: 'IAH',
    77050: 'IAH',
    77051: 'IAH',
    77052: 'IAH',
    77053: 'IAH',
    77054: 'IAH',
    77055: 'IAH',
    77056: 'IAH',
    77057: 'IAH',
    77058: 'IAH',
    77059: 'IAH',
    77060: 'IAH',
    77061: 'IAH',
    77062: 'IAH',
    77063: 'IAH',
    77064: 'IAH',
    77065: 'IAH',
    77066: 'IAH',
    77067: 'IAH',
    77068: 'IAH',
    77069: 'IAH',
    77070: 'IAH',
    77071: 'IAH',
    77072: 'IAH',
    77073: 'IAH',
    77074: 'IAH',
    77075: 'IAH',
    77076: 'IAH',
    77077: 'IAH',
    77078: 'IAH',
    77079: 'IAH',
    77080: 'IAH',
    77081: 'IAH',
    77082: 'IAH',
    77083: 'IAH',
    77084: 'IAH',
    77085: 'IAH',
    77086: 'IAH',
    77087: 'IAH',
    77088: 'IAH',
    77089: 'IAH',
    77090: 'IAH',
    77091: 'IAH',
    77092: 'IAH',
    77093: 'IAH',
    77094: 'IAH',
    77095: 'IAH',
    77096: 'IAH',
    77097: 'IAH',
    77098: 'IAH',
    77099: 'IAH',
    77201: 'IAH',
    77202: 'IAH',
    77203: 'IAH',
    77204: 'IAH',
    77205: 'IAH',
    77206: 'IAH',
    77207: 'IAH',
    77208: 'IAH',
    77209: 'IAH',
    77210: 'IAH',
    77212: 'IAH',
    77213: 'IAH',
    77215: 'IAH',
    77216: 'IAH',
    77217: 'IAH',
    77218: 'IAH',
    77219: 'IAH',
    77220: 'IAH',
    77221: 'IAH',
    77222: 'IAH',
    77223: 'IAH',
    77224: 'IAH',
    77225: 'IAH',
    77226: 'IAH',
    77227: 'IAH',
    77228: 'IAH',
    77229: 'IAH',
    77230: 'IAH',
    77231: 'IAH',
    77233: 'IAH',
    77234: 'IAH',
    77235: 'IAH',
    77236: 'IAH',
    77237: 'IAH',
    77238: 'IAH',
    77240: 'IAH',
    77241: 'IAH',
    77242: 'IAH',
    77243: 'IAH',
    77244: 'IAH',
    77245: 'IAH',
    77246: 'IAH',
    77247: 'IAH',
    77248: 'IAH',
    77249: 'IAH',
    77250: 'IAH',
    77251: 'IAH',
    77252: 'IAH',
    77253: 'IAH',
    77254: 'IAH',
    77255: 'IAH',
    77256: 'IAH',
    77257: 'IAH',
    77258: 'IAH',
    77259: 'IAH',
    77260: 'IAH',
    77261: 'IAH',
    77262: 'IAH',
    77263: 'IAH',
    77265: 'IAH',
    77266: 'IAH',
    77267: 'IAH',
    77268: 'IAH',
    77269: 'IAH',
    77270: 'IAH',
    77271: 'IAH',
    77272: 'IAH',
    77273: 'IAH',
    77274: 'IAH',
    77275: 'IAH',
    77276: 'IAH',
    77277: 'IAH',
    77278: 'IAH',
    77279: 'IAH',
    77280: 'IAH',
    77282: 'IAH',
    77284: 'IAH',
    77285: 'IAH',
    77286: 'IAH',
    77287: 'IAH',
    77288: 'IAH',
    77289: 'IAH',
    77290: 'IAH',
    77291: 'IAH',
    77292: 'IAH',
    77293: 'IAH',
    77294: 'IAH',
    77296: 'IAH',
    77297: 'IAH',
    77298: 'IAH',
    77299: 'IAH',
    77301: 'IAH',
    77302: 'IAH',
    77303: 'IAH',
    77304: 'IAH',
    77305: 'IAH',
    77306: 'IAH',
    77315: 'IAH',
    77316: 'IAH',
    77318: 'IAH',
    77320: 'IAH',
    77325: 'IAH',
    77326: 'IAH',
    77327: 'IAH',
    77328: 'IAH',
    77331: 'IAH',
    77332: 'IAH',
    77333: 'IAH',
    77334: 'IAH',
    77335: 'IAH',
    77336: 'IAH',
    77337: 'IAH',
    77338: 'IAH',
    77339: 'IAH',
    77340: 'IAH',
    77341: 'IAH',
    77342: 'IAH',
    77343: 'IAH',
    77344: 'IAH',
    77345: 'IAH',
    77346: 'IAH',
    77347: 'IAH',
    77348: 'IAH',
    77349: 'IAH',
    77350: 'IAH',
    77351: 'IAH',
    77353: 'IAH',
    77354: 'IAH',
    77355: 'IAH',
    77356: 'IAH',
    77357: 'IAH',
    77358: 'IAH',
    77359: 'IAH',
    77360: 'IAH',
    77362: 'IAH',
    77363: 'IAH',
    77364: 'IAH',
    77365: 'IAH',
    77367: 'IAH',
    77368: 'IAH',
    77369: 'IAH',
    77371: 'IAH',
    77372: 'IAH',
    77373: 'IAH',
    77374: 'IAH',
    77375: 'IAH',
    77376: 'IAH',
    77377: 'IAH',
    77378: 'IAH',
    77379: 'IAH',
    77380: 'IAH',
    77381: 'IAH',
    77382: 'IAH',
    77383: 'IAH',
    77384: 'IAH',
    77385: 'IAH',
    77386: 'IAH',
    77387: 'IAH',
    77388: 'IAH',
    77389: 'IAH',
    77391: 'IAH',
    77393: 'IAH',
    77396: 'IAH',
    77399: 'IAH',
    77401: 'IAH',
    77402: 'IAH',
    77404: 'SAT',
    77406: 'IAH',
    77407: 'IAH',
    77410: 'IAH',
    77411: 'IAH',
    77412: 'AUS',
    77413: 'IAH',
    77414: 'IAH',
    77415: 'IAH',
    77417: 'IAH',
    77418: 'AUS',
    77419: 'SAT',
    77420: 'IAH',
    77422: 'IAH',
    77423: 'IAH',
    77426: 'AUS',
    77428: 'SAT',
    77429: 'IAH',
    77430: 'IAH',
    77431: 'IAH',
    77432: 'IAH',
    77433: 'IAH',
    77434: 'IAH',
    77435: 'IAH',
    77436: 'IAH',
    77437: 'IAH',
    77440: 'SAT',
    77441: 'IAH',
    77442: 'IAH',
    77443: 'IAH',
    77444: 'IAH',
    77445: 'IAH',
    77446: 'IAH',
    77447: 'IAH',
    77448: 'IAH',
    77449: 'IAH',
    77450: 'IAH',
    77451: 'IAH',
    77452: 'AUS',
    77453: 'IAH',
    77454: 'IAH',
    77455: 'IAH',
    77456: 'SAT',
    77457: 'SAT',
    77458: 'SAT',
    77459: 'IAH',
    77460: 'IAH',
    77461: 'IAH',
    77463: 'IAH',
    77464: 'IAH',
    77465: 'SAT',
    77466: 'IAH',
    77467: 'IAH',
    77468: 'IAH',
    77469: 'IAH',
    77470: 'AUS',
    77471: 'IAH',
    77473: 'IAH',
    77474: 'IAH',
    77475: 'AUS',
    77476: 'IAH',
    77477: 'IAH',
    77478: 'IAH',
    77479: 'IAH',
    77480: 'IAH',
    77481: 'IAH',
    77482: 'IAH',
    77483: 'SAT',
    77484: 'IAH',
    77485: 'IAH',
    77486: 'IAH',
    77487: 'IAH',
    77488: 'IAH',
    77489: 'IAH',
    77491: 'IAH',
    77492: 'IAH',
    77493: 'IAH',
    77494: 'IAH',
    77496: 'IAH',
    77497: 'IAH',
    77498: 'IAH',
    77501: 'IAH',
    77502: 'IAH',
    77503: 'IAH',
    77504: 'IAH',
    77505: 'IAH',
    77506: 'IAH',
    77507: 'IAH',
    77508: 'IAH',
    77510: 'IAH',
    77511: 'IAH',
    77512: 'IAH',
    77514: 'IAH',
    77515: 'IAH',
    77516: 'IAH',
    77517: 'IAH',
    77518: 'IAH',
    77519: 'IAH',
    77520: 'IAH',
    77521: 'IAH',
    77522: 'IAH',
    77523: 'IAH',
    77530: 'IAH',
    77531: 'IAH',
    77532: 'IAH',
    77533: 'IAH',
    77534: 'IAH',
    77535: 'IAH',
    77536: 'IAH',
    77538: 'IAH',
    77539: 'IAH',
    77541: 'IAH',
    77542: 'IAH',
    77545: 'IAH',
    77546: 'IAH',
    77547: 'IAH',
    77549: 'IAH',
    77550: 'IAH',
    77551: 'IAH',
    77552: 'IAH',
    77553: 'IAH',
    77554: 'IAH',
    77555: 'IAH',
    77560: 'IAH',
    77561: 'IAH',
    77562: 'IAH',
    77563: 'IAH',
    77564: 'IAH',
    77565: 'IAH',
    77566: 'IAH',
    77568: 'IAH',
    77571: 'IAH',
    77572: 'IAH',
    77573: 'IAH',
    77574: 'IAH',
    77575: 'IAH',
    77577: 'IAH',
    77578: 'IAH',
    77580: 'IAH',
    77581: 'IAH',
    77582: 'IAH',
    77583: 'IAH',
    77584: 'IAH',
    77585: 'IAH',
    77586: 'IAH',
    77587: 'IAH',
    77588: 'IAH',
    77590: 'IAH',
    77591: 'IAH',
    77592: 'IAH',
    77597: 'IAH',
    77598: 'IAH',
    77611: 'IAH',
    77612: 'IAH',
    77613: 'IAH',
    77614: 'IAH',
    77615: 'IAH',
    77616: 'IAH',
    77617: 'IAH',
    77619: 'IAH',
    77622: 'IAH',
    77623: 'IAH',
    77624: 'IAH',
    77625: 'IAH',
    77626: 'IAH',
    77627: 'IAH',
    77629: 'IAH',
    77630: 'IAH',
    77631: 'IAH',
    77632: 'IAH',
    77639: 'IAH',
    77640: 'IAH',
    77641: 'IAH',
    77642: 'IAH',
    77643: 'IAH',
    77650: 'IAH',
    77651: 'IAH',
    77655: 'IAH',
    77656: 'IAH',
    77657: 'IAH',
    77659: 'IAH',
    77660: 'IAH',
    77661: 'IAH',
    77662: 'IAH',
    77663: 'IAH',
    77664: 'IAH',
    77665: 'IAH',
    77670: 'IAH',
    77701: 'IAH',
    77702: 'IAH',
    77703: 'IAH',
    77704: 'IAH',
    77705: 'IAH',
    77706: 'IAH',
    77707: 'IAH',
    77708: 'IAH',
    77709: 'IAH',
    77710: 'IAH',
    77713: 'IAH',
    77720: 'IAH',
    77725: 'IAH',
    77726: 'IAH',
    77801: 'AUS',
    77802: 'AUS',
    77803: 'AUS',
    77805: 'AUS',
    77806: 'AUS',
    77807: 'AUS',
    77808: 'AUS',
    77830: 'IAH',
    77831: 'IAH',
    77833: 'AUS',
    77834: 'AUS',
    77835: 'AUS',
    77836: 'AUS',
    77837: 'AUS',
    77838: 'AUS',
    77840: 'AUS',
    77841: 'AUS',
    77842: 'AUS',
    77843: 'AUS',
    77844: 'AUS',
    77845: 'AUS',
    77850: 'IAH',
    77852: 'AUS',
    77853: 'AUS',
    77855: 'IAH',
    77856: 'AUS',
    77857: 'AUS',
    77859: 'AUS',
    77861: 'IAH',
    77862: 'AUS',
    77863: 'AUS',
    77864: 'IAH',
    77865: 'IAH',
    77866: 'AUS',
    77867: 'AUS',
    77868: 'IAH',
    77869: 'IAH',
    77870: 'AUS',
    77871: 'IAH',
    77872: 'IAH',
    77873: 'IAH',
    77875: 'IAH',
    77876: 'IAH',
    77878: 'AUS',
    77879: 'AUS',
    77880: 'AUS',
    77881: 'AUS',
    77882: 'AUS',
    77901: 'SAT',
    77902: 'SAT',
    77903: 'SAT',
    77904: 'SAT',
    77905: 'SAT',
    77950: 'CRP',
    77951: 'SAT',
    77954: 'SAT',
    77957: 'SAT',
    77960: 'SAT',
    77961: 'SAT',
    77962: 'SAT',
    77963: 'CRP',
    77964: 'AUS',
    77967: 'SAT',
    77968: 'SAT',
    77969: 'SAT',
    77970: 'SAT',
    77971: 'SAT',
    77973: 'CRP',
    77974: 'SAT',
    77975: 'AUS',
    77976: 'SAT',
    77977: 'SAT',
    77978: 'SAT',
    77979: 'CRP',
    77982: 'CRP',
    77983: 'CRP',
    77984: 'SAT',
    77986: 'AUS',
    77987: 'SAT',
    77988: 'SAT',
    77989: 'SAT',
    77990: 'CRP',
    77991: 'SAT',
    77993: 'SAT',
    77994: 'SAT',
    77995: 'SAT',
    78001: 'LRD',
    78002: 'SAT',
    78003: 'SAT',
    78004: 'SAT',
    78005: 'SAT',
    78006: 'SAT',
    78007: 'CRP',
    78008: 'SAT',
    78009: 'SAT',
    78010: 'SAT',
    78011: 'SAT',
    78012: 'SAT',
    78013: 'SAT',
    78014: 'LRD',
    78015: 'SAT',
    78016: 'SAT',
    78017: 'SAT',
    78019: 'LRD',
    78021: 'CRP',
    78022: 'CRP',
    78023: 'SAT',
    78024: 'SAT',
    78025: 'SAT',
    78026: 'SAT',
    78027: 'SAT',
    78028: 'SAT',
    78029: 'SAT',
    78039: 'SAT',
    78040: 'LRD',
    78041: 'LRD',
    78042: 'LRD',
    78043: 'LRD',
    78044: 'LRD',
    78045: 'LRD',
    78046: 'LRD',
    78049: 'LRD',
    78050: 'SAT',
    78052: 'SAT',
    78054: 'SAT',
    78055: 'SAT',
    78056: 'SAT',
    78057: 'SAT',
    78058: 'SAT',
    78059: 'SAT',
    78060: 'CRP',
    78061: 'SAT',
    78062: 'SAT',
    78063: 'SAT',
    78064: 'SAT',
    78065: 'SAT',
    78066: 'SAT',
    78067: 'SAT',
    78069: 'SAT',
    78070: 'SAT',
    78071: 'CRP',
    78072: 'CRP',
    78073: 'SAT',
    78074: 'SAT',
    78075: 'CRP',
    78076: 'MFE',
    78101: 'SAT',
    78102: 'CRP',
    78104: 'CRP',
    78107: 'CRP',
    78108: 'SAT',
    78109: 'SAT',
    78111: 'SAT',
    78112: 'SAT',
    78113: 'SAT',
    78114: 'SAT',
    78115: 'SAT',
    78116: 'SAT',
    78117: 'SAT',
    78118: 'SAT',
    78119: 'SAT',
    78121: 'SAT',
    78122: 'SAT',
    78123: 'SAT',
    78124: 'SAT',
    78125: 'CRP',
    78130: 'SAT',
    78131: 'SAT',
    78132: 'SAT',
    78133: 'SAT',
    78135: 'SAT',
    78140: 'SAT',
    78141: 'SAT',
    78142: 'CRP',
    78143: 'SAT',
    78144: 'SAT',
    78145: 'CRP',
    78146: 'CRP',
    78147: 'SAT',
    78148: 'SAT',
    78150: 'SAT',
    78151: 'SAT',
    78152: 'SAT',
    78154: 'SAT',
    78155: 'SAT',
    78156: 'SAT',
    78159: 'SAT',
    78160: 'SAT',
    78161: 'SAT',
    78162: 'CRP',
    78163: 'SAT',
    78164: 'SAT',
    78201: 'SAT',
    78202: 'SAT',
    78203: 'SAT',
    78204: 'SAT',
    78205: 'SAT',
    78206: 'SAT',
    78207: 'SAT',
    78208: 'SAT',
    78209: 'SAT',
    78210: 'SAT',
    78211: 'SAT',
    78212: 'SAT',
    78213: 'SAT',
    78214: 'SAT',
    78215: 'SAT',
    78216: 'SAT',
    78217: 'SAT',
    78218: 'SAT',
    78219: 'SAT',
    78220: 'SAT',
    78221: 'SAT',
    78222: 'SAT',
    78223: 'SAT',
    78224: 'SAT',
    78225: 'SAT',
    78226: 'SAT',
    78227: 'SAT',
    78228: 'SAT',
    78229: 'SAT',
    78230: 'SAT',
    78231: 'SAT',
    78232: 'SAT',
    78233: 'SAT',
    78234: 'SAT',
    78235: 'SAT',
    78236: 'SAT',
    78237: 'SAT',
    78238: 'SAT',
    78239: 'SAT',
    78240: 'SAT',
    78241: 'SAT',
    78242: 'SAT',
    78243: 'SAT',
    78244: 'SAT',
    78245: 'SAT',
    78246: 'SAT',
    78247: 'SAT',
    78248: 'SAT',
    78249: 'SAT',
    78250: 'SAT',
    78251: 'SAT',
    78252: 'SAT',
    78253: 'SAT',
    78254: 'SAT',
    78255: 'SAT',
    78256: 'SAT',
    78257: 'SAT',
    78258: 'SAT',
    78259: 'SAT',
    78260: 'SAT',
    78261: 'SAT',
    78262: 'SAT',
    78263: 'SAT',
    78264: 'SAT',
    78265: 'SAT',
    78266: 'SAT',
    78268: 'SAT',
    78269: 'SAT',
    78270: 'SAT',
    78275: 'SAT',
    78278: 'SAT',
    78279: 'SAT',
    78280: 'SAT',
    78283: 'SAT',
    78284: 'SAT',
    78285: 'SAT',
    78286: 'SAT',
    78287: 'SAT',
    78288: 'SAT',
    78289: 'SAT',
    78291: 'SAT',
    78292: 'SAT',
    78293: 'SAT',
    78294: 'SAT',
    78295: 'SAT',
    78296: 'SAT',
    78297: 'SAT',
    78298: 'SAT',
    78299: 'SAT',
    78330: 'CRP',
    78332: 'CRP',
    78333: 'CRP',
    78335: 'CRP',
    78336: 'CRP',
    78338: 'MFE',
    78339: 'CRP',
    78340: 'CRP',
    78341: 'LRD',
    78342: 'CRP',
    78343: 'CRP',
    78344: 'LRD',
    78347: 'CRP',
    78349: 'LRD',
    78350: 'CRP',
    78351: 'CRP',
    78352: 'CRP',
    78353: 'MFE',
    78355: 'LRD',
    78357: 'LRD',
    78358: 'CRP',
    78359: 'CRP',
    78360: 'MFE',
    78361: 'LRD',
    78362: 'CRP',
    78363: 'CRP',
    78364: 'CRP',
    78368: 'CRP',
    78369: 'LRD',
    78370: 'CRP',
    78371: 'LRD',
    78372: 'CRP',
    78373: 'CRP',
    78374: 'CRP',
    78375: 'LRD',
    78376: 'LRD',
    78377: 'CRP',
    78379: 'CRP',
    78380: 'CRP',
    78381: 'CRP',
    78382: 'CRP',
    78383: 'CRP',
    78384: 'LRD',
    78385: 'CRP',
    78387: 'CRP',
    78389: 'CRP',
    78390: 'CRP',
    78391: 'CRP',
    78393: 'CRP',
    78401: 'CRP',
    78402: 'CRP',
    78403: 'CRP',
    78404: 'CRP',
    78405: 'CRP',
    78406: 'CRP',
    78407: 'CRP',
    78408: 'CRP',
    78409: 'CRP',
    78410: 'CRP',
    78411: 'CRP',
    78412: 'CRP',
    78413: 'CRP',
    78414: 'CRP',
    78415: 'CRP',
    78416: 'CRP',
    78417: 'CRP',
    78418: 'CRP',
    78419: 'CRP',
    78426: 'CRP',
    78427: 'CRP',
    78460: 'CRP',
    78461: 'CRP',
    78463: 'CRP',
    78465: 'CRP',
    78466: 'CRP',
    78467: 'CRP',
    78468: 'CRP',
    78469: 'CRP',
    78470: 'CRP',
    78471: 'CRP',
    78472: 'CRP',
    78473: 'CRP',
    78474: 'CRP',
    78475: 'CRP',
    78476: 'CRP',
    78477: 'CRP',
    78478: 'CRP',
    78480: 'CRP',
    78501: 'MFE',
    78502: 'MFE',
    78503: 'MFE',
    78504: 'MFE',
    78505: 'MFE',
    78516: 'MFE',
    78520: 'MFE',
    78521: 'MFE',
    78522: 'MFE',
    78523: 'MFE',
    78526: 'MFE',
    78535: 'MFE',
    78536: 'MFE',
    78537: 'MFE',
    78538: 'MFE',
    78539: 'MFE',
    78540: 'MFE',
    78541: 'MFE',
    78542: 'MFE',
    78543: 'MFE',
    78545: 'MFE',
    78547: 'MFE',
    78548: 'MFE',
    78549: 'MFE',
    78550: 'MFE',
    78551: 'MFE',
    78552: 'MFE',
    78553: 'MFE',
    78557: 'MFE',
    78558: 'MFE',
    78559: 'MFE',
    78560: 'MFE',
    78561: 'MFE',
    78562: 'MFE',
    78563: 'MFE',
    78564: 'MFE',
    78565: 'MFE',
    78566: 'MFE',
    78567: 'MFE',
    78568: 'MFE',
    78569: 'MFE',
    78570: 'MFE',
    78572: 'MFE',
    78573: 'MFE',
    78574: 'MFE',
    78575: 'MFE',
    78576: 'MFE',
    78577: 'MFE',
    78578: 'MFE',
    78579: 'MFE',
    78580: 'MFE',
    78582: 'MFE',
    78583: 'MFE',
    78584: 'MFE',
    78585: 'MFE',
    78586: 'MFE',
    78588: 'MFE',
    78589: 'MFE',
    78590: 'MFE',
    78591: 'MFE',
    78592: 'MFE',
    78593: 'MFE',
    78594: 'MFE',
    78595: 'MFE',
    78596: 'MFE',
    78597: 'MFE',
    78598: 'MFE',
    78599: 'MFE',
    78602: 'AUS',
    78604: 'SAT',
    78605: 'AUS',
    78606: 'AUS',
    78607: 'AUS',
    78608: 'AUS',
    78609: 'AUS',
    78610: 'AUS',
    78611: 'AUS',
    78612: 'AUS',
    78613: 'AUS',
    78614: 'SAT',
    78615: 'AUS',
    78616: 'AUS',
    78617: 'AUS',
    78618: 'ABI',
    78619: 'AUS',
    78620: 'AUS',
    78621: 'AUS',
    78622: 'SAT',
    78623: 'AUS',
    78624: 'AUS',
    78626: 'AUS',
    78627: 'AUS',
    78628: 'AUS',
    78629: 'SAT',
    78630: 'AUS',
    78631: 'SAT',
    78632: 'AUS',
    78633: 'AUS',
    78634: 'AUS',
    78635: 'AUS',
    78636: 'AUS',
    78638: 'SAT',
    78639: 'AUS',
    78640: 'AUS',
    78641: 'AUS',
    78642: 'AUS',
    78643: 'AUS',
    78644: 'AUS',
    78645: 'AUS',
    78646: 'AUS',
    78648: 'AUS',
    78650: 'AUS',
    78651: 'AUS',
    78652: 'AUS',
    78653: 'AUS',
    78654: 'AUS',
    78655: 'AUS',
    78656: 'AUS',
    78657: 'AUS',
    78658: 'SAT',
    78659: 'AUS',
    78660: 'AUS',
    78661: 'SAT',
    78662: 'AUS',
    78663: 'AUS',
    78664: 'AUS',
    78665: 'AUS',
    78666: 'AUS',
    78667: 'AUS',
    78669: 'AUS',
    78670: 'SAT',
    78671: 'AUS',
    78672: 'AUS',
    78673: 'AUS',
    78674: 'AUS',
    78675: 'AUS',
    78676: 'AUS',
    78677: 'SAT',
    78680: 'AUS',
    78681: 'AUS',
    78682: 'AUS',
    78683: 'AUS',
    78691: 'AUS',
    78701: 'AUS',
    78702: 'AUS',
    78703: 'AUS',
    78704: 'AUS',
    78705: 'AUS',
    78708: 'AUS',
    78709: 'AUS',
    78710: 'AUS',
    78711: 'AUS',
    78712: 'AUS',
    78713: 'AUS',
    78714: 'AUS',
    78715: 'AUS',
    78716: 'AUS',
    78717: 'AUS',
    78718: 'AUS',
    78719: 'AUS',
    78720: 'AUS',
    78721: 'AUS',
    78722: 'AUS',
    78723: 'AUS',
    78724: 'AUS',
    78725: 'AUS',
    78726: 'AUS',
    78727: 'AUS',
    78728: 'AUS',
    78729: 'AUS',
    78730: 'AUS',
    78731: 'AUS',
    78732: 'AUS',
    78733: 'AUS',
    78734: 'AUS',
    78735: 'AUS',
    78736: 'AUS',
    78737: 'AUS',
    78738: 'AUS',
    78739: 'AUS',
    78741: 'AUS',
    78742: 'AUS',
    78744: 'AUS',
    78745: 'AUS',
    78746: 'AUS',
    78747: 'AUS',
    78748: 'AUS',
    78749: 'AUS',
    78750: 'AUS',
    78751: 'AUS',
    78752: 'AUS',
    78753: 'AUS',
    78754: 'AUS',
    78755: 'AUS',
    78756: 'AUS',
    78757: 'AUS',
    78758: 'AUS',
    78759: 'AUS',
    78760: 'AUS',
    78761: 'AUS',
    78762: 'AUS',
    78763: 'AUS',
    78764: 'AUS',
    78765: 'AUS',
    78766: 'AUS',
    78767: 'AUS',
    78768: 'AUS',
    78769: 'AUS',
    78772: 'AUS',
    78773: 'AUS',
    78774: 'AUS',
    78778: 'AUS',
    78779: 'AUS',
    78780: 'AUS',
    78781: 'AUS',
    78783: 'AUS',
    78785: 'AUS',
    78786: 'AUS',
    78788: 'AUS',
    78789: 'AUS',
    78798: 'AUS',
    78799: 'AUS',
    78801: 'SAT',
    78802: 'SAT',
    78827: 'LRD',
    78828: 'SAT',
    78829: 'SAT',
    78830: 'LRD',
    78832: 'SAT',
    78833: 'SAT',
    78834: 'LRD',
    78836: 'LRD',
    78837: 'SAT',
    78838: 'SAT',
    78839: 'LRD',
    78840: 'SAT',
    78841: 'SAT',
    78842: 'SAT',
    78843: 'SAT',
    78847: 'SAT',
    78850: 'SAT',
    78851: 'ELP',
    78852: 'LRD',
    78853: 'LRD',
    78860: 'LRD',
    78861: 'SAT',
    78870: 'SAT',
    78871: 'SAT',
    78872: 'SAT',
    78873: 'SAT',
    78877: 'LRD',
    78879: 'SAT',
    78880: 'SAT',
    78881: 'SAT',
    78883: 'SAT',
    78884: 'SAT',
    78885: 'SAT',
    78886: 'SAT',
    78931: 'AUS',
    78932: 'AUS',
    78933: 'AUS',
    78934: 'AUS',
    78935: 'AUS',
    78938: 'AUS',
    78940: 'AUS',
    78941: 'AUS',
    78942: 'AUS',
    78943: 'AUS',
    78944: 'AUS',
    78945: 'AUS',
    78946: 'AUS',
    78947: 'AUS',
    78948: 'AUS',
    78949: 'AUS',
    78950: 'AUS',
    78951: 'AUS',
    78952: 'AUS',
    78953: 'AUS',
    78954: 'AUS',
    78956: 'AUS',
    78957: 'AUS',
    78959: 'AUS',
    78960: 'AUS',
    78961: 'AUS',
    78962: 'AUS',
    78963: 'AUS',
    79001: 'AMA',
    79002: 'AMA',
    79003: 'AMA',
    79005: 'AMA',
    79007: 'AMA',
    79008: 'AMA',
    79009: 'LBB',
    79010: 'AMA',
    79011: 'AMA',
    79012: 'AMA',
    79013: 'AMA',
    79014: 'AMA',
    79015: 'AMA',
    79016: 'AMA',
    79018: 'AMA',
    79019: 'AMA',
    79021: 'LBB',
    79022: 'AMA',
    79024: 'AMA',
    79025: 'AMA',
    79027: 'LBB',
    79029: 'AMA',
    79031: 'LBB',
    79032: 'LBB',
    79033: 'AMA',
    79034: 'AMA',
    79035: 'AMA',
    79036: 'AMA',
    79039: 'AMA',
    79040: 'AMA',
    79041: 'LBB',
    79042: 'AMA',
    79043: 'LBB',
    79044: 'AMA',
    79045: 'AMA',
    79046: 'AMA',
    79051: 'AMA',
    79052: 'LBB',
    79053: 'LBB',
    79054: 'AMA',
    79056: 'AMA',
    79057: 'AMA',
    79058: 'AMA',
    79059: 'AMA',
    79061: 'AMA',
    79062: 'AMA',
    79063: 'LBB',
    79064: 'LBB',
    79065: 'AMA',
    79066: 'AMA',
    79068: 'AMA',
    79070: 'AMA',
    79072: 'LBB',
    79073: 'LBB',
    79077: 'AMA',
    79078: 'AMA',
    79079: 'AMA',
    79080: 'AMA',
    79081: 'AMA',
    79082: 'LBB',
    79083: 'AMA',
    79084: 'AMA',
    79085: 'AMA',
    79086: 'AMA',
    79087: 'AMA',
    79088: 'LBB',
    79091: 'AMA',
    79092: 'AMA',
    79093: 'AMA',
    79094: 'AMA',
    79095: 'AMA',
    79096: 'AMA',
    79097: 'AMA',
    79098: 'AMA',
    79101: 'AMA',
    79102: 'AMA',
    79103: 'AMA',
    79104: 'AMA',
    79105: 'AMA',
    79106: 'AMA',
    79107: 'AMA',
    79108: 'AMA',
    79109: 'AMA',
    79110: 'AMA',
    79111: 'AMA',
    79114: 'AMA',
    79116: 'AMA',
    79117: 'AMA',
    79118: 'AMA',
    79119: 'AMA',
    79120: 'AMA',
    79121: 'AMA',
    79124: 'AMA',
    79159: 'AMA',
    79166: 'AMA',
    79168: 'AMA',
    79172: 'AMA',
    79174: 'AMA',
    79178: 'AMA',
    79185: 'AMA',
    79187: 'AMA',
    79189: 'AMA',
    79201: 'KIP',
    79220: 'LBB',
    79221: 'LBB',
    79223: 'KIP',
    79225: 'KIP',
    79226: 'AMA',
    79227: 'KIP',
    79229: 'LBB',
    79230: 'AMA',
    79231: 'LBB',
    79233: 'KIP',
    79234: 'LBB',
    79235: 'LBB',
    79236: 'KIP',
    79237: 'AMA',
    79239: 'AMA',
    79240: 'AMA',
    79241: 'LBB',
    79243: 'LBB',
    79244: 'LBB',
    79245: 'AMA',
    79247: 'KIP',
    79248: 'KIP',
    79250: 'LBB',
    79251: 'AMA',
    79252: 'KIP',
    79255: 'LBB',
    79256: 'LBB',
    79257: 'LBB',
    79258: 'LBB',
    79259: 'KIP',
    79261: 'LBB',
    79311: 'LBB',
    79312: 'LBB',
    79313: 'LBB',
    79314: 'LBB',
    79316: 'LBB',
    79320: 'LBB',
    79322: 'LBB',
    79323: 'LBB',
    79324: 'LBB',
    79325: 'LBB',
    79326: 'LBB',
    79329: 'LBB',
    79330: 'LBB',
    79331: 'MAF',
    79336: 'LBB',
    79338: 'LBB',
    79339: 'LBB',
    79342: 'LBB',
    79343: 'LBB',
    79344: 'LBB',
    79345: 'LBB',
    79346: 'LBB',
    79347: 'LBB',
    79350: 'LBB',
    79351: 'LBB',
    79353: 'LBB',
    79355: 'LBB',
    79356: 'LBB',
    79357: 'LBB',
    79358: 'LBB',
    79359: 'LBB',
    79360: 'MAF',
    79363: 'LBB',
    79364: 'LBB',
    79366: 'LBB',
    79367: 'LBB',
    79369: 'LBB',
    79370: 'LBB',
    79371: 'LBB',
    79372: 'LBB',
    79373: 'LBB',
    79376: 'LBB',
    79377: 'LBB',
    79378: 'LBB',
    79379: 'LBB',
    79380: 'LBB',
    79381: 'LBB',
    79382: 'LBB',
    79383: 'LBB',
    79401: 'LBB',
    79402: 'LBB',
    79403: 'LBB',
    79404: 'LBB',
    79405: 'LBB',
    79406: 'LBB',
    79407: 'LBB',
    79408: 'LBB',
    79409: 'LBB',
    79410: 'LBB',
    79411: 'LBB',
    79412: 'LBB',
    79413: 'LBB',
    79414: 'LBB',
    79415: 'LBB',
    79416: 'LBB',
    79423: 'LBB',
    79424: 'LBB',
    79430: 'LBB',
    79452: 'LBB',
    79453: 'LBB',
    79457: 'LBB',
    79464: 'LBB',
    79490: 'LBB',
    79491: 'LBB',
    79493: 'LBB',
    79499: 'LBB',
    79501: 'ABI',
    79502: 'KIP',
    79503: 'ABI',
    79504: 'ABI',
    79505: 'KIP',
    79506: 'MAF',
    79508: 'ABI',
    79510: 'ABI',
    79511: 'MAF',
    79512: 'MAF',
    79516: 'MAF',
    79517: 'LBB',
    79518: 'LBB',
    79519: 'ABI',
    79520: 'KIP',
    79521: 'KIP',
    79525: 'ABI',
    79526: 'MAF',
    79527: 'MAF',
    79528: 'LBB',
    79529: 'KIP',
    79530: 'ABI',
    79532: 'MAF',
    79533: 'ABI',
    79534: 'MAF',
    79535: 'MAF',
    79536: 'ABI',
    79537: 'MAF',
    79538: 'ABI',
    79539: 'KIP',
    79540: 'KIP',
    79541: 'ABI',
    79543: 'MAF',
    79544: 'KIP',
    79545: 'MAF',
    79546: 'KIP',
    79547: 'KIP',
    79548: 'KIP',
    79549: 'LBB',
    79550: 'MAF',
    79553: 'ABI',
    79556: 'MAF',
    79560: 'MAF',
    79561: 'ABI',
    79562: 'ABI',
    79563: 'ABI',
    79565: 'MAF',
    79566: 'MAF',
    79567: 'ABI',
    79601: 'ABI',
    79602: 'ABI',
    79603: 'ABI',
    79604: 'ABI',
    79605: 'ABI',
    79606: 'ABI',
    79607: 'ABI',
    79608: 'ABI',
    79697: 'ABI',
    79698: 'ABI',
    79699: 'ABI',
    79701: 'MAF',
    79702: 'MAF',
    79703: 'MAF',
    79704: 'MAF',
    79705: 'MAF',
    79706: 'MAF',
    79707: 'MAF',
    79708: 'MAF',
    79710: 'MAF',
    79711: 'MAF',
    79712: 'MAF',
    79713: 'MAF',
    79714: 'MAF',
    79718: 'ELP',
    79719: 'ELP',
    79720: 'MAF',
    79721: 'MAF',
    79730: 'MAF',
    79731: 'MAF',
    79733: 'MAF',
    79734: 'ELP',
    79735: 'ELP',
    79738: 'MAF',
    79739: 'MAF',
    79740: 'MAF',
    79741: 'MAF',
    79742: 'MAF',
    79743: 'MAF',
    79744: 'MAF',
    79745: 'MAF',
    79748: 'MAF',
    79749: 'MAF',
    79752: 'MAF',
    79754: 'ELP',
    79755: 'MAF',
    79756: 'MAF',
    79758: 'MAF',
    79759: 'MAF',
    79760: 'MAF',
    79761: 'MAF',
    79762: 'MAF',
    79763: 'MAF',
    79764: 'MAF',
    79765: 'MAF',
    79766: 'MAF',
    79768: 'MAF',
    79769: 'MAF',
    79770: 'ELP',
    79772: 'ELP',
    79776: 'MAF',
    79777: 'ELP',
    79778: 'MAF',
    79780: 'ELP',
    79781: 'SAT',
    79782: 'MAF',
    79783: 'MAF',
    79785: 'ELP',
    79786: 'ELP',
    79788: 'MAF',
    79789: 'ELP',
    79821: 'ELP',
    79830: 'ELP',
    79831: 'ELP',
    79832: 'ELP',
    79834: 'ELP',
    79835: 'ELP',
    79836: 'ELP',
    79837: 'ELP',
    79838: 'ELP',
    79839: 'ELP',
    79842: 'ELP',
    79843: 'ELP',
    79845: 'ELP',
    79846: 'ELP',
    79847: 'ELP',
    79848: 'ELP',
    79849: 'ELP',
    79851: 'ELP',
    79852: 'ELP',
    79853: 'ELP',
    79854: 'ELP',
    79855: 'ELP',
    79901: 'ELP',
    79902: 'ELP',
    79903: 'ELP',
    79904: 'ELP',
    79905: 'ELP',
    79906: 'ELP',
    79907: 'ELP',
    79908: 'ELP',
    79910: 'ELP',
    79911: 'ELP',
    79912: 'ELP',
    79913: 'ELP',
    79914: 'ELP',
    79915: 'ELP',
    79916: 'ELP',
    79917: 'ELP',
    79918: 'ELP',
    79920: 'ELP',
    79922: 'ELP',
    79923: 'ELP',
    79924: 'ELP',
    79925: 'ELP',
    79926: 'ELP',
    79927: 'ELP',
    79928: 'ELP',
    79929: 'ELP',
    79930: 'ELP',
    79931: 'ELP',
    79932: 'ELP',
    79934: 'ELP',
    79935: 'ELP',
    79936: 'ELP',
    79937: 'ELP',
    79938: 'ELP',
    79940: 'ELP',
    79941: 'ELP',
    79942: 'ELP',
    79943: 'ELP',
    79944: 'ELP',
    79945: 'ELP',
    79946: 'ELP',
    79947: 'ELP',
    79948: 'ELP',
    79949: 'ELP',
    79950: 'ELP',
    79951: 'ELP',
    79952: 'ELP',
    79953: 'ELP',
    79954: 'ELP',
    79955: 'ELP',
    79958: 'ELP',
    79960: 'ELP',
    79961: 'ELP',
    79968: 'ELP',
    79976: 'ELP',
    79978: 'ELP',
    79980: 'ELP',
    79990: 'ELP',
    79995: 'ELP',
    79996: 'ELP',
    79997: 'ELP',
    79998: 'ELP',
    79999: 'ELP',
    80001: 'DEN',
    80002: 'DEN',
    80003: 'DEN',
    80004: 'DEN',
    80005: 'DEN',
    80006: 'DEN',
    80007: 'DEN',
    80010: 'DEN',
    80011: 'DEN',
    80012: 'DEN',
    80013: 'DEN',
    80014: 'DEN',
    80015: 'DEN',
    80016: 'DEN',
    80017: 'DEN',
    80018: 'DEN',
    80019: 'DEN',
    80020: 'DEN',
    80021: 'DEN',
    80022: 'DEN',
    80023: 'DEN',
    80024: 'DEN',
    80025: 'DEN',
    80026: 'DEN',
    80027: 'DEN',
    80028: 'DEN',
    80030: 'DEN',
    80031: 'DEN',
    80033: 'DEN',
    80034: 'DEN',
    80035: 'DEN',
    80036: 'DEN',
    80037: 'DEN',
    80038: 'DEN',
    80040: 'DEN',
    80041: 'DEN',
    80042: 'DEN',
    80044: 'DEN',
    80045: 'DEN',
    80046: 'DEN',
    80047: 'DEN',
    80101: 'DEN',
    80102: 'DEN',
    80103: 'DEN',
    80104: 'COS',
    80105: 'DEN',
    80106: 'COS',
    80107: 'DEN',
    80108: 'DEN',
    80109: 'DEN',
    80110: 'DEN',
    80111: 'DEN',
    80112: 'DEN',
    80113: 'DEN',
    80116: 'DEN',
    80117: 'DEN',
    80118: 'COS',
    80120: 'DEN',
    80121: 'DEN',
    80122: 'DEN',
    80123: 'DEN',
    80124: 'DEN',
    80125: 'COS',
    80126: 'DEN',
    80127: 'DEN',
    80128: 'DEN',
    80129: 'DEN',
    80130: 'DEN',
    80131: 'DEN',
    80132: 'COS',
    80133: 'COS',
    80134: 'DEN',
    80135: 'COS',
    80136: 'DEN',
    80137: 'DEN',
    80138: 'DEN',
    80150: 'DEN',
    80151: 'DEN',
    80155: 'DEN',
    80160: 'DEN',
    80161: 'DEN',
    80162: 'DEN',
    80163: 'DEN',
    80165: 'DEN',
    80166: 'DEN',
    80201: 'DEN',
    80202: 'DEN',
    80203: 'DEN',
    80204: 'DEN',
    80205: 'DEN',
    80206: 'DEN',
    80207: 'DEN',
    80208: 'DEN',
    80209: 'DEN',
    80210: 'DEN',
    80211: 'DEN',
    80212: 'DEN',
    80214: 'DEN',
    80215: 'DEN',
    80216: 'DEN',
    80217: 'DEN',
    80218: 'DEN',
    80219: 'DEN',
    80220: 'DEN',
    80221: 'DEN',
    80222: 'DEN',
    80223: 'DEN',
    80224: 'DEN',
    80225: 'DEN',
    80226: 'DEN',
    80227: 'DEN',
    80228: 'DEN',
    80229: 'DEN',
    80230: 'DEN',
    80231: 'DEN',
    80232: 'DEN',
    80233: 'DEN',
    80234: 'DEN',
    80235: 'DEN',
    80236: 'DEN',
    80237: 'DEN',
    80238: 'DEN',
    80239: 'DEN',
    80241: 'DEN',
    80243: 'DEN',
    80244: 'DEN',
    80246: 'DEN',
    80247: 'DEN',
    80248: 'DEN',
    80249: 'DEN',
    80250: 'DEN',
    80251: 'DEN',
    80252: 'DEN',
    80256: 'DEN',
    80257: 'DEN',
    80259: 'DEN',
    80260: 'DEN',
    80261: 'DEN',
    80262: 'DEN',
    80263: 'DEN',
    80264: 'DEN',
    80265: 'DEN',
    80266: 'DEN',
    80271: 'DEN',
    80273: 'DEN',
    80274: 'DEN',
    80279: 'DEN',
    80280: 'DEN',
    80281: 'DEN',
    80290: 'DEN',
    80291: 'DEN',
    80293: 'DEN',
    80294: 'DEN',
    80295: 'DEN',
    80299: 'DEN',
    80301: 'DEN',
    80302: 'DEN',
    80303: 'DEN',
    80304: 'DEN',
    80305: 'DEN',
    80306: 'DEN',
    80307: 'DEN',
    80308: 'DEN',
    80309: 'DEN',
    80310: 'DEN',
    80314: 'DEN',
    80321: 'DEN',
    80322: 'DEN',
    80323: 'DEN',
    80328: 'DEN',
    80329: 'DEN',
    80401: 'DEN',
    80402: 'DEN',
    80403: 'DEN',
    80419: 'DEN',
    80420: 'COS',
    80421: 'COS',
    80422: 'DEN',
    80423: 'GJT',
    80424: 'COS',
    80425: 'COS',
    80426: 'GJT',
    80427: 'DEN',
    80428: 'GJT',
    80429: 'GJT',
    80430: 'GJT',
    80432: 'COS',
    80433: 'COS',
    80434: 'DEN',
    80435: 'COS',
    80436: 'DEN',
    80437: 'DEN',
    80438: 'DEN',
    80439: 'DEN',
    80440: 'COS',
    80442: 'DEN',
    80443: 'GJT',
    80444: 'COS',
    80446: 'DEN',
    80447: 'DEN',
    80448: 'COS',
    80449: 'COS',
    80451: 'DEN',
    80452: 'DEN',
    80453: 'DEN',
    80454: 'DEN',
    80455: 'DEN',
    80456: 'COS',
    80457: 'DEN',
    80459: 'GJT',
    80461: 'GJT',
    80463: 'GJT',
    80465: 'DEN',
    80466: 'DEN',
    80467: 'GJT',
    80468: 'DEN',
    80469: 'GJT',
    80470: 'COS',
    80471: 'DEN',
    80473: 'DEN',
    80474: 'DEN',
    80475: 'COS',
    80476: 'COS',
    80477: 'GJT',
    80478: 'DEN',
    80479: 'GJT',
    80480: 'DEN',
    80481: 'DEN',
    80482: 'DEN',
    80483: 'GJT',
    80487: 'GJT',
    80488: 'GJT',
    80497: 'GJT',
    80498: 'GJT',
    80501: 'DEN',
    80502: 'DEN',
    80503: 'DEN',
    80504: 'DEN',
    80510: 'DEN',
    80511: 'DEN',
    80512: 'DEN',
    80513: 'DEN',
    80514: 'DEN',
    80515: 'DEN',
    80516: 'DEN',
    80517: 'DEN',
    80520: 'DEN',
    80521: 'DEN',
    80522: 'DEN',
    80523: 'DEN',
    80524: 'DEN',
    80525: 'DEN',
    80526: 'DEN',
    80527: 'DEN',
    80528: 'DEN',
    80530: 'DEN',
    80532: 'DEN',
    80533: 'DEN',
    80534: 'DEN',
    80535: 'DEN',
    80536: 'DEN',
    80537: 'DEN',
    80538: 'DEN',
    80539: 'DEN',
    80540: 'DEN',
    80541: 'DEN',
    80542: 'DEN',
    80543: 'DEN',
    80544: 'DEN',
    80545: 'DEN',
    80546: 'DEN',
    80547: 'DEN',
    80549: 'DEN',
    80550: 'DEN',
    80551: 'DEN',
    80553: 'DEN',
    80601: 'DEN',
    80602: 'DEN',
    80603: 'DEN',
    80610: 'DEN',
    80611: 'DEN',
    80612: 'DEN',
    80614: 'DEN',
    80615: 'DEN',
    80620: 'DEN',
    80621: 'DEN',
    80622: 'DEN',
    80623: 'DEN',
    80624: 'DEN',
    80631: 'DEN',
    80632: 'DEN',
    80633: 'DEN',
    80634: 'DEN',
    80638: 'DEN',
    80639: 'DEN',
    80640: 'DEN',
    80642: 'DEN',
    80643: 'DEN',
    80644: 'DEN',
    80645: 'DEN',
    80646: 'DEN',
    80648: 'DEN',
    80649: 'DEN',
    80650: 'DEN',
    80651: 'DEN',
    80652: 'DEN',
    80653: 'DEN',
    80654: 'DEN',
    80701: 'DEN',
    80705: 'DEN',
    80720: 'DEN',
    80721: 'DEN',
    80722: 'DEN',
    80723: 'DEN',
    80726: 'DEN',
    80727: 'DEN',
    80728: 'DEN',
    80729: 'DEN',
    80731: 'DEN',
    80732: 'DEN',
    80733: 'DEN',
    80734: 'DEN',
    80735: 'DEN',
    80736: 'DEN',
    80737: 'CYS',
    80740: 'DEN',
    80741: 'DEN',
    80742: 'DEN',
    80743: 'DEN',
    80744: 'CYS',
    80745: 'CYS',
    80746: 'CYS',
    80747: 'CYS',
    80749: 'CYS',
    80750: 'DEN',
    80751: 'DEN',
    80754: 'DEN',
    80755: 'DEN',
    80757: 'DEN',
    80758: 'DEN',
    80759: 'DEN',
    80801: 'DEN',
    80802: 'COS',
    80804: 'DEN',
    80805: 'DEN',
    80807: 'DEN',
    80808: 'COS',
    80809: 'COS',
    80810: 'COS',
    80812: 'DEN',
    80813: 'COS',
    80814: 'COS',
    80815: 'DEN',
    80816: 'COS',
    80817: 'COS',
    80818: 'DEN',
    80819: 'COS',
    80820: 'COS',
    80821: 'COS',
    80822: 'DEN',
    80823: 'COS',
    80824: 'DEN',
    80825: 'COS',
    80826: 'COS',
    80827: 'COS',
    80828: 'DEN',
    80829: 'COS',
    80830: 'COS',
    80831: 'COS',
    80832: 'COS',
    80833: 'COS',
    80834: 'DEN',
    80835: 'COS',
    80836: 'DEN',
    80840: 'COS',
    80841: 'COS',
    80860: 'COS',
    80861: 'DEN',
    80862: 'COS',
    80863: 'COS',
    80864: 'COS',
    80866: 'COS',
    80901: 'COS',
    80902: 'COS',
    80903: 'COS',
    80904: 'COS',
    80905: 'COS',
    80906: 'COS',
    80907: 'COS',
    80908: 'COS',
    80909: 'COS',
    80910: 'COS',
    80911: 'COS',
    80912: 'COS',
    80913: 'COS',
    80914: 'COS',
    80915: 'COS',
    80916: 'COS',
    80917: 'COS',
    80918: 'COS',
    80919: 'COS',
    80920: 'COS',
    80921: 'COS',
    80922: 'COS',
    80923: 'COS',
    80924: 'COS',
    80925: 'COS',
    80926: 'COS',
    80927: 'COS',
    80928: 'COS',
    80929: 'COS',
    80930: 'COS',
    80931: 'COS',
    80932: 'COS',
    80933: 'COS',
    80934: 'COS',
    80935: 'COS',
    80936: 'COS',
    80937: 'COS',
    80938: 'COS',
    80939: 'COS',
    80940: 'COS',
    80941: 'COS',
    80942: 'COS',
    80943: 'COS',
    80944: 'COS',
    80945: 'COS',
    80946: 'COS',
    80947: 'COS',
    80949: 'COS',
    80950: 'COS',
    80951: 'COS',
    80960: 'COS',
    80962: 'COS',
    80970: 'COS',
    80977: 'COS',
    80995: 'COS',
    80997: 'COS',
    81001: 'COS',
    81002: 'COS',
    81003: 'COS',
    81004: 'COS',
    81005: 'COS',
    81006: 'COS',
    81007: 'COS',
    81008: 'COS',
    81009: 'COS',
    81010: 'COS',
    81011: 'COS',
    81012: 'COS',
    81019: 'COS',
    81020: 'COS',
    81021: 'COS',
    81022: 'COS',
    81023: 'COS',
    81024: 'DRO',
    81025: 'COS',
    81027: 'COS',
    81029: 'COS',
    81030: 'COS',
    81033: 'COS',
    81034: 'COS',
    81036: 'COS',
    81038: 'COS',
    81039: 'COS',
    81040: 'DRO',
    81041: 'COS',
    81043: 'COS',
    81044: 'COS',
    81045: 'COS',
    81046: 'COS',
    81047: 'COS',
    81049: 'COS',
    81050: 'COS',
    81052: 'COS',
    81054: 'COS',
    81055: 'COS',
    81057: 'COS',
    81058: 'COS',
    81059: 'COS',
    81062: 'COS',
    81063: 'COS',
    81064: 'COS',
    81067: 'COS',
    81069: 'COS',
    81071: 'COS',
    81073: 'COS',
    81075: 'COS',
    81076: 'COS',
    81077: 'COS',
    81081: 'COS',
    81082: 'COS',
    81084: 'COS',
    81087: 'COS',
    81089: 'COS',
    81090: 'COS',
    81091: 'DRO',
    81092: 'COS',
    81101: 'DRO',
    81102: 'DRO',
    81120: 'DRO',
    81121: 'DRO',
    81122: 'DRO',
    81123: 'DRO',
    81124: 'DRO',
    81125: 'DRO',
    81126: 'DRO',
    81127: 'DRO',
    81128: 'DRO',
    81129: 'DRO',
    81130: 'DRO',
    81131: 'DRO',
    81132: 'DRO',
    81133: 'DRO',
    81134: 'DRO',
    81135: 'DRO',
    81136: 'DRO',
    81137: 'DRO',
    81138: 'DRO',
    81140: 'DRO',
    81141: 'DRO',
    81143: 'DRO',
    81144: 'DRO',
    81146: 'DRO',
    81147: 'DRO',
    81148: 'DRO',
    81149: 'DRO',
    81151: 'DRO',
    81152: 'DRO',
    81153: 'DRO',
    81154: 'DRO',
    81155: 'DRO',
    81157: 'DRO',
    81201: 'DRO',
    81210: 'DRO',
    81211: 'COS',
    81212: 'COS',
    81215: 'COS',
    81220: 'DRO',
    81221: 'COS',
    81222: 'DRO',
    81223: 'COS',
    81224: 'GJT',
    81225: 'GJT',
    81226: 'COS',
    81227: 'DRO',
    81228: 'GJT',
    81230: 'DRO',
    81231: 'DRO',
    81232: 'DRO',
    81233: 'DRO',
    81235: 'DRO',
    81236: 'DRO',
    81237: 'DRO',
    81239: 'DRO',
    81240: 'COS',
    81241: 'DRO',
    81242: 'DRO',
    81243: 'DRO',
    81244: 'COS',
    81247: 'DRO',
    81248: 'DRO',
    81251: 'GJT',
    81252: 'DRO',
    81253: 'COS',
    81290: 'COS',
    81301: 'DRO',
    81302: 'DRO',
    81303: 'DRO',
    81320: 'DRO',
    81321: 'DRO',
    81323: 'DRO',
    81324: 'DRO',
    81325: 'DRO',
    81326: 'DRO',
    81327: 'DRO',
    81328: 'DRO',
    81329: 'DRO',
    81330: 'DRO',
    81331: 'DRO',
    81332: 'DRO',
    81334: 'DRO',
    81335: 'DRO',
    81401: 'DRO',
    81402: 'DRO',
    81403: 'DRO',
    81410: 'DRO',
    81411: 'DRO',
    81413: 'GJT',
    81414: 'DRO',
    81415: 'DRO',
    81416: 'DRO',
    81418: 'GJT',
    81419: 'GJT',
    81420: 'DRO',
    81422: 'DRO',
    81423: 'DRO',
    81424: 'DRO',
    81425: 'DRO',
    81426: 'DRO',
    81427: 'DRO',
    81428: 'GJT',
    81429: 'DRO',
    81430: 'DRO',
    81431: 'DRO',
    81432: 'DRO',
    81433: 'DRO',
    81434: 'GJT',
    81435: 'GJT',
    81501: 'GJT',
    81502: 'GJT',
    81503: 'GJT',
    81504: 'GJT',
    81505: 'GJT',
    81506: 'GJT',
    81507: 'GJT',
    81520: 'GJT',
    81521: 'GJT',
    81522: 'GJT',
    81523: 'GJT',
    81524: 'GJT',
    81525: 'GJT',
    81526: 'GJT',
    81527: 'GJT',
    81601: 'GJT',
    81602: 'GJT',
    81610: 'GJT',
    81611: 'GJT',
    81612: 'GJT',
    81615: 'GJT',
    81620: 'GJT',
    81621: 'GJT',
    81623: 'GJT',
    81624: 'GJT',
    81625: 'GJT',
    81626: 'GJT',
    81630: 'GJT',
    81631: 'GJT',
    81632: 'GJT',
    81633: 'GJT',
    81635: 'GJT',
    81636: 'GJT',
    81637: 'GJT',
    81638: 'GJT',
    81639: 'GJT',
    81640: 'GJT',
    81641: 'GJT',
    81642: 'GJT',
    81643: 'GJT',
    81645: 'GJT',
    81646: 'GJT',
    81647: 'GJT',
    81648: 'GJT',
    81649: 'GJT',
    81650: 'GJT',
    81652: 'GJT',
    81653: 'GJT',
    81654: 'GJT',
    81655: 'GJT',
    81656: 'GJT',
    81657: 'GJT',
    81658: 'GJT',
    82001: 'CYS',
    82002: 'CYS',
    82003: 'CYS',
    82005: 'CYS',
    82006: 'CYS',
    82007: 'CYS',
    82008: 'CYS',
    82009: 'CYS',
    82010: 'CYS',
    82050: 'CYS',
    82051: 'CYS',
    82052: 'CYS',
    82053: 'CYS',
    82054: 'CYS',
    82055: 'CYS',
    82058: 'CRP',
    82059: 'CYS',
    82060: 'CYS',
    82061: 'CYS',
    82063: 'CYS',
    82070: 'CYS',
    82071: 'CYS',
    82072: 'CYS',
    82073: 'CYS',
    82081: 'CYS',
    82082: 'CYS',
    82083: 'CYS',
    82084: 'CYS',
    82190: 'COD',
    82201: 'CRP',
    82210: 'CYS',
    82212: 'CRP',
    82213: 'CRP',
    82214: 'CRP',
    82215: 'CRP',
    82217: 'CYS',
    82218: 'CYS',
    82219: 'CRP',
    82221: 'CYS',
    82222: 'CRP',
    82223: 'CRP',
    82224: 'CRP',
    82225: 'CRP',
    82227: 'CRP',
    82229: 'CRP',
    82240: 'CRP',
    82242: 'CRP',
    82243: 'CRP',
    82244: 'CYS',
    82301: 'CRP',
    82310: 'CRP',
    82321: 'CYS',
    82322: 'CRP',
    82323: 'CYS',
    82324: 'CYS',
    82325: 'CYS',
    82327: 'CRP',
    82329: 'CRP',
    82331: 'CYS',
    82332: 'CYS',
    82334: 'CYS',
    82335: 'CYS',
    82336: 'CYS',
    82401: 'COD',
    82410: 'COD',
    82411: 'COD',
    82412: 'COD',
    82414: 'COD',
    82420: 'COD',
    82421: 'COD',
    82422: 'COD',
    82423: 'COD',
    82426: 'COD',
    82428: 'COD',
    82430: 'COD',
    82431: 'COD',
    82432: 'COD',
    82433: 'COD',
    82434: 'COD',
    82435: 'COD',
    82440: 'COD',
    82441: 'COD',
    82442: 'RAP',
    82443: 'COD',
    82450: 'COD',
    82501: 'CRP',
    82510: 'CRP',
    82512: 'COD',
    82513: 'COD',
    82514: 'CRP',
    82515: 'CRP',
    82516: 'CRP',
    82520: 'CRP',
    82523: 'COD',
    82524: 'CRP',
    82601: 'CRP',
    82602: 'CRP',
    82604: 'CRP',
    82605: 'CRP',
    82609: 'CRP',
    82615: 'CYS',
    82620: 'CRP',
    82630: 'CRP',
    82633: 'CRP',
    82635: 'CRP',
    82636: 'CRP',
    82637: 'CRP',
    82638: 'CRP',
    82639: 'RAP',
    82640: 'RAP',
    82642: 'CRP',
    82643: 'CRP',
    82644: 'CRP',
    82646: 'CRP',
    82648: 'CRP',
    82649: 'CRP',
    82701: 'RAP',
    82710: 'RAP',
    82711: 'RAP',
    82712: 'RAP',
    82714: 'RAP',
    82715: 'RAP',
    82716: 'RAP',
    82717: 'RAP',
    82718: 'RAP',
    82720: 'RAP',
    82721: 'RAP',
    82723: 'RAP',
    82725: 'RAP',
    82727: 'RAP',
    82729: 'RAP',
    82730: 'RAP',
    82731: 'RAP',
    82732: 'RAP',
    82801: 'RAP',
    82831: 'RAP',
    82832: 'RAP',
    82833: 'RAP',
    82834: 'RAP',
    82835: 'RAP',
    82836: 'RAP',
    82837: 'RAP',
    82838: 'RAP',
    82839: 'RAP',
    82840: 'RAP',
    82842: 'RAP',
    82844: 'RAP',
    82845: 'RAP',
    82901: 'CYS',
    82902: 'SLC',
    82922: 'COD',
    82923: 'PIH',
    82925: 'PIH',
    82929: 'SLC',
    82930: 'SLC',
    82931: 'SLC',
    82932: 'SLC',
    82933: 'SLC',
    82934: 'SLC',
    82935: 'SLC',
    82936: 'SLC',
    82937: 'SLC',
    82938: 'SLC',
    82939: 'SLC',
    82941: 'PIH',
    82942: 'SLC',
    82943: 'SLC',
    82944: 'SLC',
    82945: 'CYS',
    83001: 'COD',
    83002: 'COD',
    83011: 'COD',
    83012: 'COD',
    83013: 'COD',
    83014: 'COD',
    83025: 'COD',
    83101: 'SLC',
    83110: 'PIH',
    83111: 'PIH',
    83112: 'PIH',
    83113: 'PIH',
    83114: 'PIH',
    83115: 'PIH',
    83116: 'SLC',
    83118: 'PIH',
    83119: 'PIH',
    83120: 'PIH',
    83121: 'SLC',
    83122: 'PIH',
    83123: 'PIH',
    83124: 'SLC',
    83126: 'PIH',
    83127: 'PIH',
    83128: 'PIH',
    83201: 'PIH',
    83202: 'PIH',
    83203: 'PIH',
    83204: 'PIH',
    83205: 'PIH',
    83206: 'PIH',
    83209: 'PIH',
    83210: 'PIH',
    83211: 'PIH',
    83212: 'PIH',
    83213: 'IDA',
    83214: 'PIH',
    83215: 'IDA',
    83217: 'PIH',
    83218: 'IDA',
    83220: 'PIH',
    83221: 'IDA',
    83223: 'PIH',
    83226: 'BOI',
    83227: 'BOI',
    83228: 'PIH',
    83229: 'BOI',
    83230: 'PIH',
    83232: 'PIH',
    83233: 'PIH',
    83234: 'PIH',
    83235: 'BOI',
    83236: 'PIH',
    83237: 'PIH',
    83238: 'PIH',
    83239: 'PIH',
    83241: 'PIH',
    83243: 'PIH',
    83244: 'IDA',
    83245: 'PIH',
    83246: 'PIH',
    83250: 'PIH',
    83251: 'BOI',
    83252: 'PIH',
    83253: 'BOI',
    83254: 'PIH',
    83255: 'IDA',
    83256: 'PIH',
    83261: 'PIH',
    83262: 'PIH',
    83263: 'PIH',
    83271: 'PIH',
    83272: 'PIH',
    83274: 'PIH',
    83276: 'PIH',
    83277: 'PIH',
    83278: 'BOI',
    83281: 'PIH',
    83283: 'PIH',
    83285: 'PIH',
    83286: 'PIH',
    83287: 'PIH',
    83301: 'TWF',
    83302: 'TWF',
    83303: 'TWF',
    83311: 'PIH',
    83312: 'TWF',
    83313: 'TWF',
    83314: 'TWF',
    83316: 'TWF',
    83318: 'TWF',
    83320: 'TWF',
    83321: 'TWF',
    83322: 'TWF',
    83323: 'PIH',
    83324: 'TWF',
    83325: 'TWF',
    83327: 'TWF',
    83328: 'TWF',
    83330: 'TWF',
    83332: 'TWF',
    83333: 'TWF',
    83334: 'TWF',
    83335: 'TWF',
    83336: 'TWF',
    83337: 'TWF',
    83338: 'TWF',
    83340: 'TWF',
    83341: 'TWF',
    83342: 'PIH',
    83343: 'TWF',
    83344: 'TWF',
    83346: 'TWF',
    83347: 'TWF',
    83348: 'TWF',
    83349: 'TWF',
    83350: 'TWF',
    83352: 'TWF',
    83353: 'TWF',
    83354: 'TWF',
    83355: 'TWF',
    83401: 'IDA',
    83402: 'IDA',
    83403: 'IDA',
    83404: 'IDA',
    83405: 'IDA',
    83406: 'IDA',
    83414: 'COD',
    83415: 'IDA',
    83420: 'IDA',
    83421: 'IDA',
    83422: 'IDA',
    83423: 'IDA',
    83424: 'IDA',
    83425: 'IDA',
    83427: 'IDA',
    83428: 'IDA',
    83429: 'IDA',
    83431: 'IDA',
    83433: 'IDA',
    83434: 'IDA',
    83435: 'IDA',
    83436: 'IDA',
    83438: 'IDA',
    83440: 'IDA',
    83441: 'IDA',
    83442: 'IDA',
    83443: 'IDA',
    83444: 'IDA',
    83445: 'IDA',
    83446: 'IDA',
    83448: 'IDA',
    83449: 'IDA',
    83450: 'IDA',
    83451: 'IDA',
    83452: 'IDA',
    83454: 'IDA',
    83455: 'IDA',
    83460: 'IDA',
    83462: 'BOI',
    83463: 'BOI',
    83464: 'IDA',
    83465: 'IDA',
    83466: 'BOI',
    83467: 'BOI',
    83468: 'BOI',
    83469: 'BOI',
    83501: 'GEG',
    83520: 'GEG',
    83522: 'BOI',
    83523: 'GEG',
    83524: 'GEG',
    83525: 'GEG',
    83526: 'GEG',
    83530: 'BOI',
    83531: 'BOI',
    83533: 'GEG',
    83535: 'GEG',
    83536: 'GEG',
    83537: 'GEG',
    83539: 'GEG',
    83540: 'GEG',
    83541: 'GEG',
    83542: 'BOI',
    83543: 'GEG',
    83544: 'GEG',
    83545: 'GEG',
    83546: 'GEG',
    83547: 'BOI',
    83548: 'GEG',
    83549: 'BOI',
    83552: 'BOI',
    83553: 'GEG',
    83554: 'BOI',
    83555: 'GEG',
    83601: 'TWF',
    83602: 'BOI',
    83604: 'TWF',
    83605: 'BOI',
    83606: 'BOI',
    83607: 'BOI',
    83610: 'BOI',
    83611: 'BOI',
    83612: 'BOI',
    83615: 'BOI',
    83616: 'BOI',
    83617: 'BOI',
    83619: 'BOI',
    83622: 'BOI',
    83623: 'TWF',
    83624: 'TWF',
    83626: 'BOI',
    83627: 'TWF',
    83628: 'BOI',
    83629: 'BOI',
    83630: 'BOI',
    83631: 'BOI',
    83632: 'BOI',
    83633: 'TWF',
    83634: 'BOI',
    83635: 'BOI',
    83636: 'BOI',
    83637: 'BOI',
    83638: 'BOI',
    83639: 'BOI',
    83641: 'BOI',
    83642: 'BOI',
    83643: 'BOI',
    83644: 'BOI',
    83645: 'BOI',
    83646: 'BOI',
    83647: 'TWF',
    83648: 'TWF',
    83650: 'BOI',
    83651: 'BOI',
    83652: 'BOI',
    83653: 'BOI',
    83654: 'BOI',
    83655: 'BOI',
    83656: 'BOI',
    83657: 'BOI',
    83660: 'BOI',
    83661: 'BOI',
    83666: 'BOI',
    83669: 'BOI',
    83670: 'BOI',
    83671: 'BOI',
    83672: 'BOI',
    83676: 'BOI',
    83677: 'BOI',
    83680: 'BOI',
    83686: 'BOI',
    83687: 'BOI',
    83701: 'BOI',
    83702: 'BOI',
    83703: 'BOI',
    83704: 'BOI',
    83705: 'BOI',
    83706: 'BOI',
    83707: 'BOI',
    83708: 'BOI',
    83709: 'BOI',
    83711: 'BOI',
    83712: 'BOI',
    83713: 'BOI',
    83714: 'BOI',
    83715: 'BOI',
    83716: 'BOI',
    83717: 'BOI',
    83719: 'BOI',
    83720: 'BOI',
    83721: 'BOI',
    83722: 'BOI',
    83724: 'BOI',
    83725: 'BOI',
    83726: 'BOI',
    83727: 'BOI',
    83728: 'BOI',
    83729: 'BOI',
    83730: 'BOI',
    83731: 'BOI',
    83732: 'BOI',
    83733: 'BOI',
    83735: 'BOI',
    83756: 'BOI',
    83757: 'BOI',
    83799: 'BOI',
    83801: 'GEG',
    83802: 'GEG',
    83803: 'GEG',
    83804: 'GEG',
    83805: 'GEG',
    83806: 'GEG',
    83808: 'GEG',
    83809: 'GEG',
    83810: 'GEG',
    83811: 'GEG',
    83812: 'GEG',
    83813: 'GEG',
    83814: 'GEG',
    83815: 'GEG',
    83816: 'GEG',
    83821: 'GEG',
    83822: 'GEG',
    83823: 'GEG',
    83824: 'GEG',
    83825: 'GEG',
    83826: 'GEG',
    83827: 'GEG',
    83830: 'GEG',
    83832: 'GEG',
    83833: 'GEG',
    83834: 'GEG',
    83835: 'GEG',
    83836: 'GEG',
    83837: 'GEG',
    83839: 'GEG',
    83840: 'GEG',
    83841: 'GEG',
    83842: 'GEG',
    83843: 'GEG',
    83844: 'GEG',
    83845: 'GEG',
    83846: 'GEG',
    83847: 'GEG',
    83848: 'GEG',
    83849: 'GEG',
    83850: 'GEG',
    83851: 'GEG',
    83852: 'GEG',
    83853: 'GEG',
    83854: 'GEG',
    83855: 'GEG',
    83856: 'GEG',
    83857: 'GEG',
    83858: 'GEG',
    83860: 'GEG',
    83861: 'GEG',
    83864: 'GEG',
    83865: 'GEG',
    83866: 'GEG',
    83867: 'GEG',
    83868: 'GEG',
    83869: 'GEG',
    83870: 'GEG',
    83871: 'GEG',
    83872: 'GEG',
    83873: 'GEG',
    83874: 'GEG',
    83876: 'GEG',
    83877: 'GEG',
    84001: 'SLC',
    84002: 'SLC',
    84003: 'SLC',
    84004: 'SLC',
    84005: 'SLC',
    84006: 'SLC',
    84007: 'SLC',
    84008: 'SLC',
    84009: 'SLC',
    84010: 'SLC',
    84011: 'SLC',
    84013: 'SLC',
    84014: 'SLC',
    84015: 'SLC',
    84016: 'SLC',
    84017: 'SLC',
    84018: 'SLC',
    84020: 'SLC',
    84021: 'SLC',
    84022: 'SLC',
    84023: 'SLC',
    84024: 'SLC',
    84025: 'SLC',
    84026: 'SLC',
    84027: 'SLC',
    84028: 'SLC',
    84029: 'SLC',
    84031: 'SLC',
    84032: 'SLC',
    84033: 'SLC',
    84034: 'SLC',
    84035: 'SLC',
    84036: 'SLC',
    84037: 'SLC',
    84038: 'SLC',
    84039: 'SLC',
    84040: 'SLC',
    84041: 'SLC',
    84042: 'SLC',
    84043: 'SLC',
    84044: 'SLC',
    84045: 'SLC',
    84046: 'SLC',
    84047: 'SLC',
    84049: 'SLC',
    84050: 'SLC',
    84051: 'SLC',
    84052: 'SLC',
    84053: 'SLC',
    84054: 'SLC',
    84055: 'SLC',
    84056: 'SLC',
    84057: 'SLC',
    84058: 'SLC',
    84059: 'SLC',
    84060: 'SLC',
    84061: 'SLC',
    84062: 'SLC',
    84063: 'SLC',
    84064: 'SLC',
    84065: 'SLC',
    84066: 'SLC',
    84067: 'SLC',
    84068: 'SLC',
    84069: 'SLC',
    84070: 'SLC',
    84071: 'SLC',
    84072: 'SLC',
    84073: 'SLC',
    84074: 'SLC',
    84075: 'SLC',
    84076: 'SLC',
    84078: 'SLC',
    84079: 'SLC',
    84080: 'SLC',
    84081: 'SLC',
    84082: 'SLC',
    84083: 'SLC',
    84084: 'SLC',
    84085: 'SLC',
    84086: 'SLC',
    84087: 'SLC',
    84088: 'SLC',
    84089: 'SLC',
    84090: 'SLC',
    84091: 'SLC',
    84092: 'SLC',
    84093: 'SLC',
    84094: 'SLC',
    84095: 'SLC',
    84096: 'SLC',
    84097: 'SLC',
    84098: 'SLC',
    84101: 'SLC',
    84102: 'SLC',
    84103: 'SLC',
    84104: 'SLC',
    84105: 'SLC',
    84106: 'SLC',
    84107: 'SLC',
    84108: 'SLC',
    84109: 'SLC',
    84110: 'SLC',
    84111: 'SLC',
    84112: 'SLC',
    84113: 'SLC',
    84114: 'SLC',
    84115: 'SLC',
    84116: 'SLC',
    84117: 'SLC',
    84118: 'SLC',
    84119: 'SLC',
    84120: 'SLC',
    84121: 'SLC',
    84122: 'SLC',
    84123: 'SLC',
    84124: 'SLC',
    84125: 'SLC',
    84126: 'SLC',
    84127: 'SLC',
    84128: 'SLC',
    84129: 'SLC',
    84130: 'SLC',
    84131: 'SLC',
    84132: 'SLC',
    84133: 'SLC',
    84134: 'SLC',
    84136: 'SLC',
    84138: 'SLC',
    84139: 'SLC',
    84141: 'SLC',
    84143: 'SLC',
    84144: 'SLC',
    84145: 'SLC',
    84147: 'SLC',
    84148: 'SLC',
    84150: 'SLC',
    84151: 'SLC',
    84152: 'SLC',
    84157: 'SLC',
    84158: 'SLC',
    84165: 'SLC',
    84170: 'SLC',
    84171: 'SLC',
    84180: 'SLC',
    84184: 'SLC',
    84189: 'SLC',
    84190: 'SLC',
    84199: 'SLC',
    84201: 'SLC',
    84244: 'SLC',
    84301: 'SLC',
    84302: 'SLC',
    84304: 'SLC',
    84305: 'SLC',
    84306: 'SLC',
    84307: 'SLC',
    84308: 'SLC',
    84309: 'SLC',
    84310: 'SLC',
    84311: 'SLC',
    84312: 'SLC',
    84313: 'SLC',
    84314: 'SLC',
    84315: 'SLC',
    84316: 'SLC',
    84317: 'SLC',
    84318: 'SLC',
    84319: 'SLC',
    84320: 'SLC',
    84321: 'SLC',
    84322: 'SLC',
    84323: 'SLC',
    84324: 'SLC',
    84325: 'SLC',
    84326: 'SLC',
    84327: 'SLC',
    84328: 'SLC',
    84329: 'SLC',
    84330: 'SLC',
    84331: 'SLC',
    84332: 'SLC',
    84333: 'SLC',
    84334: 'SLC',
    84335: 'SLC',
    84336: 'SLC',
    84337: 'SLC',
    84338: 'SLC',
    84339: 'SLC',
    84340: 'SLC',
    84341: 'SLC',
    84401: 'SLC',
    84402: 'SLC',
    84403: 'SLC',
    84404: 'SLC',
    84405: 'SLC',
    84407: 'SLC',
    84408: 'SLC',
    84409: 'SLC',
    84412: 'SLC',
    84414: 'SLC',
    84415: 'SLC',
    84501: 'SLC',
    84510: 'SGU',
    84511: 'SGU',
    84512: 'SGU',
    84513: 'SLC',
    84515: 'SLC',
    84516: 'SLC',
    84518: 'SLC',
    84520: 'SLC',
    84521: 'SLC',
    84522: 'SLC',
    84523: 'SLC',
    84525: 'SLC',
    84526: 'SLC',
    84528: 'SLC',
    84529: 'SLC',
    84530: 'SLC',
    84531: 'SGU',
    84532: 'SLC',
    84533: 'SGU',
    84534: 'SGU',
    84535: 'SGU',
    84536: 'SGU',
    84537: 'SLC',
    84539: 'SLC',
    84540: 'SLC',
    84542: 'SLC',
    84601: 'SLC',
    84602: 'SLC',
    84603: 'SLC',
    84604: 'SLC',
    84605: 'SLC',
    84606: 'SLC',
    84620: 'SLC',
    84621: 'SLC',
    84622: 'SLC',
    84623: 'SLC',
    84624: 'SLC',
    84626: 'SLC',
    84627: 'SLC',
    84628: 'SLC',
    84629: 'SLC',
    84630: 'SLC',
    84631: 'SLC',
    84632: 'SLC',
    84633: 'SLC',
    84634: 'SLC',
    84635: 'SLC',
    84636: 'SLC',
    84637: 'SLC',
    84638: 'SLC',
    84639: 'SLC',
    84640: 'SLC',
    84642: 'SLC',
    84643: 'SLC',
    84644: 'SLC',
    84645: 'SLC',
    84646: 'SLC',
    84647: 'SLC',
    84648: 'SLC',
    84649: 'SLC',
    84651: 'SLC',
    84652: 'SLC',
    84653: 'SLC',
    84654: 'SLC',
    84655: 'SLC',
    84656: 'SLC',
    84657: 'SLC',
    84660: 'SLC',
    84662: 'SLC',
    84663: 'SLC',
    84664: 'SLC',
    84665: 'SLC',
    84667: 'SLC',
    84701: 'SLC',
    84710: 'SGU',
    84711: 'SLC',
    84712: 'SGU',
    84713: 'SLC',
    84714: 'SGU',
    84715: 'SLC',
    84716: 'SGU',
    84717: 'SGU',
    84718: 'SGU',
    84719: 'SGU',
    84720: 'SGU',
    84721: 'SGU',
    84722: 'SGU',
    84723: 'SLC',
    84724: 'SLC',
    84725: 'SGU',
    84726: 'SGU',
    84728: 'SLC',
    84729: 'SGU',
    84730: 'SLC',
    84731: 'SLC',
    84732: 'SLC',
    84733: 'SGU',
    84734: 'SLC',
    84735: 'SGU',
    84736: 'SGU',
    84737: 'SGU',
    84738: 'SGU',
    84739: 'SLC',
    84740: 'SLC',
    84741: 'SGU',
    84742: 'SGU',
    84743: 'SLC',
    84744: 'SLC',
    84745: 'SGU',
    84746: 'SGU',
    84747: 'SLC',
    84749: 'SLC',
    84750: 'SLC',
    84751: 'SLC',
    84752: 'SLC',
    84753: 'SGU',
    84754: 'SLC',
    84755: 'SGU',
    84756: 'SGU',
    84757: 'SGU',
    84758: 'SGU',
    84759: 'SGU',
    84760: 'SGU',
    84761: 'SGU',
    84762: 'SGU',
    84763: 'SGU',
    84764: 'SGU',
    84765: 'SGU',
    84766: 'SLC',
    84767: 'SGU',
    84770: 'SGU',
    84771: 'SGU',
    84772: 'SGU',
    84773: 'SLC',
    84774: 'SGU',
    84775: 'SLC',
    84776: 'SGU',
    84779: 'SGU',
    84780: 'SGU',
    84781: 'SGU',
    84782: 'SGU',
    84783: 'SGU',
    84784: 'SGU',
    84790: 'SGU',
    84791: 'SGU',
    85001: 'PHX',
    85002: 'PHX',
    85003: 'PHX',
    85004: 'PHX',
    85005: 'PHX',
    85006: 'PHX',
    85007: 'PHX',
    85008: 'PHX',
    85009: 'PHX',
    85010: 'PHX',
    85011: 'PHX',
    85012: 'PHX',
    85013: 'PHX',
    85014: 'PHX',
    85015: 'PHX',
    85016: 'PHX',
    85017: 'PHX',
    85018: 'PHX',
    85019: 'PHX',
    85020: 'PHX',
    85021: 'PHX',
    85022: 'PHX',
    85023: 'PHX',
    85024: 'PHX',
    85025: 'PHX',
    85026: 'PHX',
    85027: 'PHX',
    85028: 'PHX',
    85029: 'PHX',
    85030: 'PHX',
    85031: 'PHX',
    85032: 'PHX',
    85033: 'PHX',
    85034: 'PHX',
    85035: 'PHX',
    85036: 'PHX',
    85037: 'PHX',
    85038: 'PHX',
    85039: 'PHX',
    85040: 'PHX',
    85041: 'PHX',
    85042: 'PHX',
    85043: 'PHX',
    85044: 'PHX',
    85045: 'PHX',
    85046: 'PHX',
    85048: 'PHX',
    85050: 'PHX',
    85051: 'PHX',
    85053: 'PHX',
    85054: 'PHX',
    85055: 'PHX',
    85060: 'PHX',
    85061: 'PHX',
    85062: 'PHX',
    85063: 'PHX',
    85064: 'PHX',
    85065: 'PHX',
    85066: 'PHX',
    85067: 'PHX',
    85068: 'PHX',
    85069: 'PHX',
    85070: 'PHX',
    85071: 'PHX',
    85072: 'PHX',
    85073: 'PHX',
    85074: 'PHX',
    85075: 'PHX',
    85076: 'PHX',
    85077: 'PHX',
    85078: 'PHX',
    85079: 'PHX',
    85080: 'PHX',
    85082: 'PHX',
    85083: 'PHX',
    85085: 'PHX',
    85086: 'PHX',
    85087: 'PHX',
    85096: 'PHX',
    85097: 'PHX',
    85098: 'PHX',
    85099: 'PHX',
    85117: 'PHX',
    85118: 'PHX',
    85119: 'PHX',
    85120: 'PHX',
    85121: 'PHX',
    85122: 'PHX',
    85123: 'PHX',
    85127: 'PHX',
    85128: 'PHX',
    85130: 'PHX',
    85131: 'PHX',
    85132: 'PHX',
    85135: 'PHX',
    85137: 'PHX',
    85138: 'PHX',
    85139: 'PHX',
    85140: 'PHX',
    85141: 'PHX',
    85142: 'PHX',
    85143: 'PHX',
    85145: 'PHX',
    85147: 'PHX',
    85172: 'PHX',
    85173: 'PHX',
    85178: 'PHX',
    85190: 'PHX',
    85191: 'PHX',
    85192: 'PHX',
    85193: 'PHX',
    85194: 'PHX',
    85201: 'PHX',
    85202: 'PHX',
    85203: 'PHX',
    85204: 'PHX',
    85205: 'PHX',
    85206: 'PHX',
    85207: 'PHX',
    85208: 'PHX',
    85209: 'PHX',
    85210: 'PHX',
    85211: 'PHX',
    85212: 'PHX',
    85213: 'PHX',
    85214: 'PHX',
    85215: 'PHX',
    85216: 'PHX',
    85217: 'PHX',
    85218: 'PHX',
    85219: 'PHX',
    85220: 'PHX',
    85221: 'PHX',
    85222: 'PHX',
    85223: 'PHX',
    85224: 'PHX',
    85225: 'PHX',
    85226: 'PHX',
    85227: 'PHX',
    85228: 'PHX',
    85230: 'PHX',
    85231: 'TUS',
    85232: 'PHX',
    85233: 'PHX',
    85234: 'PHX',
    85235: 'PHX',
    85236: 'PHX',
    85237: 'PHX',
    85238: 'PHX',
    85239: 'PHX',
    85240: 'PHX',
    85241: 'TUS',
    85242: 'PHX',
    85243: 'PHX',
    85244: 'PHX',
    85245: 'TUS',
    85246: 'PHX',
    85247: 'PHX',
    85248: 'PHX',
    85249: 'PHX',
    85250: 'PHX',
    85251: 'PHX',
    85252: 'PHX',
    85253: 'PHX',
    85254: 'PHX',
    85255: 'PHX',
    85256: 'PHX',
    85257: 'PHX',
    85258: 'PHX',
    85259: 'PHX',
    85260: 'PHX',
    85261: 'PHX',
    85262: 'PHX',
    85263: 'PHX',
    85264: 'PHX',
    85266: 'PHX',
    85267: 'PHX',
    85268: 'PHX',
    85269: 'PHX',
    85271: 'PHX',
    85272: 'PHX',
    85273: 'PHX',
    85274: 'PHX',
    85275: 'PHX',
    85277: 'PHX',
    85278: 'PHX',
    85279: 'PHX',
    85280: 'PHX',
    85281: 'PHX',
    85282: 'PHX',
    85283: 'PHX',
    85284: 'PHX',
    85285: 'PHX',
    85286: 'PHX',
    85287: 'PHX',
    85289: 'PHX',
    85290: 'PHX',
    85291: 'PHX',
    85292: 'PHX',
    85293: 'TUS',
    85294: 'PHX',
    85295: 'PHX',
    85296: 'PHX',
    85297: 'PHX',
    85298: 'PHX',
    85299: 'PHX',
    85301: 'PHX',
    85302: 'PHX',
    85303: 'PHX',
    85304: 'PHX',
    85305: 'PHX',
    85306: 'PHX',
    85307: 'PHX',
    85308: 'PHX',
    85309: 'PHX',
    85310: 'PHX',
    85311: 'PHX',
    85312: 'PHX',
    85313: 'PHX',
    85318: 'PHX',
    85320: 'PHX',
    85321: 'PHX',
    85322: 'PHX',
    85323: 'PHX',
    85324: 'PHX',
    85325: 'PHX',
    85326: 'PHX',
    85327: 'PHX',
    85328: 'PHX',
    85329: 'PHX',
    85331: 'PHX',
    85332: 'PHX',
    85333: 'PHX',
    85334: 'PHX',
    85335: 'PHX',
    85336: 'PHX',
    85337: 'PHX',
    85338: 'PHX',
    85339: 'PHX',
    85340: 'PHX',
    85341: 'PHX',
    85342: 'PHX',
    85343: 'PHX',
    85344: 'PHX',
    85345: 'PHX',
    85346: 'PHX',
    85347: 'PHX',
    85348: 'PHX',
    85349: 'PHX',
    85350: 'PHX',
    85351: 'PHX',
    85352: 'PHX',
    85353: 'PHX',
    85354: 'PHX',
    85355: 'PHX',
    85356: 'PHX',
    85357: 'PHX',
    85358: 'PHX',
    85359: 'PHX',
    85360: 'PHX',
    85361: 'PHX',
    85362: 'PHX',
    85363: 'PHX',
    85364: 'PHX',
    85365: 'PHX',
    85366: 'PHX',
    85367: 'PHX',
    85369: 'PHX',
    85371: 'PHX',
    85372: 'PHX',
    85373: 'PHX',
    85374: 'PHX',
    85375: 'PHX',
    85376: 'PHX',
    85377: 'PHX',
    85378: 'PHX',
    85379: 'PHX',
    85380: 'PHX',
    85381: 'PHX',
    85382: 'PHX',
    85383: 'PHX',
    85385: 'PHX',
    85387: 'PHX',
    85388: 'PHX',
    85390: 'PHX',
    85392: 'PHX',
    85395: 'PHX',
    85396: 'PHX',
    85501: 'PHX',
    85502: 'PHX',
    85530: 'PHX',
    85531: 'PHX',
    85532: 'PHX',
    85533: 'PHX',
    85534: 'TUS',
    85535: 'PHX',
    85536: 'PHX',
    85539: 'PHX',
    85540: 'PHX',
    85541: 'PHX',
    85542: 'PHX',
    85543: 'PHX',
    85544: 'PHX',
    85545: 'PHX',
    85546: 'TUS',
    85547: 'PHX',
    85548: 'PHX',
    85550: 'PHX',
    85551: 'PHX',
    85552: 'TUS',
    85553: 'PHX',
    85554: 'PHX',
    85601: 'TUS',
    85602: 'TUS',
    85603: 'TUS',
    85605: 'TUS',
    85606: 'TUS',
    85607: 'TUS',
    85608: 'TUS',
    85609: 'TUS',
    85610: 'TUS',
    85611: 'TUS',
    85613: 'TUS',
    85614: 'TUS',
    85615: 'TUS',
    85616: 'TUS',
    85617: 'TUS',
    85618: 'TUS',
    85619: 'TUS',
    85620: 'TUS',
    85621: 'TUS',
    85622: 'TUS',
    85623: 'TUS',
    85624: 'TUS',
    85625: 'TUS',
    85626: 'TUS',
    85627: 'TUS',
    85628: 'TUS',
    85629: 'TUS',
    85630: 'TUS',
    85631: 'PHX',
    85632: 'TUS',
    85633: 'TUS',
    85634: 'TUS',
    85635: 'TUS',
    85636: 'TUS',
    85637: 'TUS',
    85638: 'TUS',
    85639: 'TUS',
    85640: 'TUS',
    85641: 'TUS',
    85643: 'TUS',
    85644: 'TUS',
    85645: 'TUS',
    85646: 'TUS',
    85648: 'TUS',
    85650: 'TUS',
    85652: 'TUS',
    85653: 'TUS',
    85654: 'TUS',
    85655: 'TUS',
    85658: 'TUS',
    85662: 'TUS',
    85670: 'TUS',
    85671: 'TUS',
    85701: 'TUS',
    85702: 'TUS',
    85703: 'TUS',
    85704: 'TUS',
    85705: 'TUS',
    85706: 'TUS',
    85707: 'TUS',
    85708: 'TUS',
    85709: 'TUS',
    85710: 'TUS',
    85711: 'TUS',
    85712: 'TUS',
    85713: 'TUS',
    85714: 'TUS',
    85715: 'TUS',
    85716: 'TUS',
    85717: 'TUS',
    85718: 'TUS',
    85719: 'TUS',
    85720: 'TUS',
    85721: 'TUS',
    85722: 'TUS',
    85723: 'TUS',
    85724: 'TUS',
    85725: 'TUS',
    85726: 'TUS',
    85728: 'TUS',
    85730: 'TUS',
    85731: 'TUS',
    85732: 'TUS',
    85733: 'TUS',
    85734: 'TUS',
    85735: 'TUS',
    85736: 'TUS',
    85737: 'TUS',
    85738: 'TUS',
    85739: 'TUS',
    85740: 'TUS',
    85741: 'TUS',
    85742: 'TUS',
    85743: 'TUS',
    85744: 'TUS',
    85745: 'TUS',
    85746: 'TUS',
    85747: 'TUS',
    85748: 'TUS',
    85749: 'TUS',
    85750: 'TUS',
    85751: 'TUS',
    85752: 'TUS',
    85754: 'TUS',
    85755: 'TUS',
    85756: 'TUS',
    85757: 'TUS',
    85775: 'TUS',
    85777: 'TUS',
    85901: 'PHX',
    85902: 'PHX',
    85911: 'PHX',
    85912: 'PHX',
    85920: 'PHX',
    85922: 'PHX',
    85923: 'PHX',
    85924: 'PHX',
    85925: 'PHX',
    85926: 'PHX',
    85927: 'PHX',
    85928: 'PHX',
    85929: 'PHX',
    85930: 'PHX',
    85931: 'PHX',
    85932: 'PHX',
    85933: 'PHX',
    85934: 'PHX',
    85935: 'PHX',
    85936: 'FLG',
    85937: 'FLG',
    85938: 'PHX',
    85939: 'PHX',
    85940: 'PHX',
    85941: 'PHX',
    85942: 'FLG',
    86001: 'FLG',
    86002: 'FLG',
    86003: 'FLG',
    86004: 'FLG',
    86005: 'FLG',
    86011: 'FLG',
    86015: 'FLG',
    86016: 'FLG',
    86017: 'FLG',
    86018: 'FLG',
    86020: 'FLG',
    86021: 'FLG',
    86022: 'FLG',
    86023: 'FLG',
    86024: 'FLG',
    86025: 'FLG',
    86028: 'FLG',
    86029: 'FLG',
    86030: 'FLG',
    86031: 'FLG',
    86032: 'FLG',
    86033: 'FLG',
    86034: 'FLG',
    86035: 'FLG',
    86036: 'FLG',
    86038: 'FLG',
    86039: 'FLG',
    86040: 'FLG',
    86042: 'FLG',
    86043: 'FLG',
    86044: 'FLG',
    86045: 'FLG',
    86046: 'FLG',
    86047: 'FLG',
    86052: 'FLG',
    86053: 'FLG',
    86054: 'FLG',
    86301: 'FLG',
    86302: 'FLG',
    86303: 'FLG',
    86304: 'FLG',
    86305: 'FLG',
    86312: 'FLG',
    86313: 'FLG',
    86314: 'FLG',
    86315: 'FLG',
    86320: 'FLG',
    86321: 'FLG',
    86322: 'PHX',
    86323: 'FLG',
    86324: 'FLG',
    86325: 'FLG',
    86326: 'FLG',
    86327: 'PHX',
    86329: 'FLG',
    86330: 'FLG',
    86331: 'FLG',
    86332: 'PHX',
    86333: 'PHX',
    86334: 'FLG',
    86335: 'FLG',
    86336: 'FLG',
    86337: 'FLG',
    86338: 'FLG',
    86339: 'FLG',
    86340: 'FLG',
    86341: 'FLG',
    86342: 'FLG',
    86343: 'PHX',
    86351: 'FLG',
    86401: 'FLG',
    86402: 'FLG',
    86403: 'PHX',
    86404: 'PHX',
    86405: 'PHX',
    86406: 'PHX',
    86409: 'FLG',
    86411: 'FLG',
    86412: 'FLG',
    86413: 'FLG',
    86426: 'FLG',
    86427: 'FLG',
    86429: 'FLG',
    86430: 'FLG',
    86431: 'FLG',
    86432: 'FLG',
    86433: 'FLG',
    86434: 'FLG',
    86435: 'FLG',
    86436: 'FLG',
    86437: 'FLG',
    86438: 'FLG',
    86439: 'FLG',
    86440: 'FLG',
    86441: 'FLG',
    86442: 'FLG',
    86443: 'FLG',
    86444: 'FLG',
    86445: 'FLG',
    86446: 'FLG',
    86502: 'FLG',
    86503: 'FLG',
    86504: 'FLG',
    86505: 'FLG',
    86506: 'FLG',
    86507: 'FLG',
    86508: 'FLG',
    86510: 'FLG',
    86511: 'FLG',
    86512: 'FLG',
    86514: 'FLG',
    86515: 'FLG',
    86520: 'FLG',
    86535: 'FLG',
    86538: 'FLG',
    86540: 'FLG',
    86544: 'FLG',
    86545: 'FLG',
    86547: 'FLG',
    86555: 'FLG',
    86556: 'FLG',
    87001: 'ABQ',
    87002: 'ABQ',
    87004: 'ABQ',
    87005: 'ABQ',
    87006: 'ABQ',
    87007: 'ABQ',
    87008: 'ABQ',
    87009: 'ABQ',
    87010: 'ABQ',
    87011: 'ABQ',
    87012: 'ABQ',
    87013: 'ABQ',
    87014: 'ABQ',
    87015: 'ABQ',
    87016: 'ABQ',
    87017: 'ABQ',
    87018: 'ABQ',
    87020: 'ABQ',
    87021: 'ABQ',
    87022: 'ABQ',
    87023: 'ABQ',
    87024: 'ABQ',
    87025: 'ABQ',
    87026: 'ABQ',
    87027: 'ABQ',
    87028: 'ABQ',
    87029: 'ABQ',
    87031: 'ABQ',
    87032: 'ABQ',
    87034: 'ABQ',
    87035: 'ABQ',
    87036: 'ABQ',
    87037: 'ABQ',
    87038: 'ABQ',
    87040: 'ABQ',
    87041: 'ABQ',
    87042: 'ABQ',
    87043: 'ABQ',
    87044: 'ABQ',
    87045: 'ABQ',
    87046: 'ABQ',
    87047: 'ABQ',
    87048: 'ABQ',
    87049: 'ABQ',
    87051: 'ABQ',
    87052: 'ABQ',
    87053: 'ABQ',
    87056: 'ABQ',
    87059: 'ABQ',
    87060: 'ABQ',
    87061: 'ABQ',
    87062: 'ABQ',
    87063: 'ABQ',
    87064: 'ABQ',
    87068: 'ABQ',
    87070: 'ABQ',
    87072: 'ABQ',
    87083: 'ABQ',
    87101: 'ABQ',
    87102: 'ABQ',
    87103: 'ABQ',
    87104: 'ABQ',
    87105: 'ABQ',
    87106: 'ABQ',
    87107: 'ABQ',
    87108: 'ABQ',
    87109: 'ABQ',
    87110: 'ABQ',
    87111: 'ABQ',
    87112: 'ABQ',
    87113: 'ABQ',
    87114: 'ABQ',
    87115: 'ABQ',
    87116: 'ABQ',
    87117: 'ABQ',
    87119: 'ABQ',
    87120: 'ABQ',
    87121: 'ABQ',
    87122: 'ABQ',
    87123: 'ABQ',
    87124: 'ABQ',
    87125: 'ABQ',
    87131: 'ABQ',
    87144: 'ABQ',
    87151: 'ABQ',
    87153: 'ABQ',
    87154: 'ABQ',
    87158: 'ABQ',
    87165: 'ABQ',
    87174: 'ABQ',
    87176: 'ABQ',
    87181: 'ABQ',
    87184: 'ABQ',
    87185: 'ABQ',
    87187: 'ABQ',
    87190: 'ABQ',
    87191: 'ABQ',
    87192: 'ABQ',
    87193: 'ABQ',
    87194: 'ABQ',
    87195: 'ABQ',
    87196: 'ABQ',
    87197: 'ABQ',
    87198: 'ABQ',
    87199: 'ABQ',
    87301: 'ABQ',
    87302: 'ABQ',
    87305: 'ABQ',
    87310: 'ABQ',
    87311: 'ABQ',
    87312: 'ABQ',
    87313: 'ABQ',
    87315: 'ABQ',
    87316: 'ABQ',
    87317: 'ABQ',
    87319: 'ABQ',
    87320: 'ABQ',
    87321: 'ABQ',
    87322: 'ABQ',
    87323: 'ABQ',
    87325: 'ABQ',
    87326: 'ABQ',
    87327: 'ABQ',
    87328: 'ABQ',
    87347: 'ABQ',
    87357: 'ABQ',
    87364: 'ABQ',
    87365: 'ABQ',
    87375: 'ABQ',
    87401: 'ABQ',
    87402: 'ABQ',
    87410: 'ABQ',
    87412: 'ABQ',
    87413: 'ABQ',
    87415: 'ABQ',
    87416: 'ABQ',
    87417: 'ABQ',
    87418: 'ABQ',
    87419: 'ABQ',
    87420: 'ABQ',
    87421: 'ABQ',
    87455: 'ABQ',
    87461: 'ABQ',
    87499: 'ABQ',
    87501: 'ABQ',
    87502: 'ABQ',
    87503: 'ABQ',
    87504: 'ABQ',
    87505: 'ABQ',
    87506: 'ABQ',
    87507: 'ABQ',
    87508: 'ABQ',
    87509: 'ABQ',
    87510: 'ABQ',
    87511: 'ABQ',
    87512: 'ABQ',
    87513: 'ABQ',
    87514: 'ABQ',
    87515: 'ABQ',
    87516: 'ABQ',
    87517: 'ABQ',
    87518: 'ABQ',
    87519: 'ABQ',
    87520: 'ABQ',
    87521: 'ABQ',
    87522: 'ABQ',
    87523: 'ABQ',
    87524: 'ABQ',
    87525: 'ABQ',
    87527: 'ABQ',
    87528: 'ABQ',
    87529: 'ABQ',
    87530: 'ABQ',
    87531: 'ABQ',
    87532: 'ABQ',
    87533: 'ABQ',
    87535: 'ABQ',
    87537: 'ABQ',
    87538: 'ABQ',
    87539: 'ABQ',
    87540: 'ABQ',
    87543: 'ABQ',
    87544: 'ABQ',
    87545: 'ABQ',
    87547: 'ABQ',
    87548: 'ABQ',
    87549: 'ABQ',
    87551: 'ABQ',
    87552: 'ABQ',
    87553: 'ABQ',
    87554: 'ABQ',
    87556: 'ABQ',
    87557: 'ABQ',
    87558: 'ABQ',
    87560: 'ABQ',
    87562: 'ABQ',
    87564: 'ABQ',
    87565: 'ABQ',
    87566: 'ABQ',
    87567: 'ABQ',
    87569: 'ABQ',
    87571: 'ABQ',
    87573: 'ABQ',
    87574: 'ABQ',
    87575: 'ABQ',
    87576: 'ABQ',
    87577: 'ABQ',
    87578: 'ABQ',
    87579: 'ABQ',
    87580: 'ABQ',
    87581: 'ABQ',
    87582: 'ABQ',
    87583: 'ABQ',
    87592: 'ABQ',
    87594: 'ABQ',
    87654: 'ABQ',
    87701: 'ABQ',
    87710: 'ABQ',
    87711: 'ABQ',
    87712: 'ABQ',
    87713: 'ABQ',
    87714: 'ABQ',
    87715: 'ABQ',
    87718: 'ABQ',
    87722: 'ABQ',
    87723: 'ABQ',
    87724: 'ABQ',
    87728: 'ABQ',
    87729: 'ABQ',
    87730: 'ABQ',
    87731: 'ABQ',
    87732: 'ABQ',
    87733: 'ABQ',
    87734: 'ABQ',
    87735: 'ABQ',
    87736: 'ABQ',
    87740: 'ABQ',
    87742: 'ABQ',
    87743: 'ABQ',
    87745: 'ABQ',
    87746: 'ABQ',
    87747: 'ABQ',
    87749: 'ABQ',
    87750: 'ABQ',
    87752: 'ABQ',
    87753: 'ABQ',
    87801: 'ABQ',
    87820: 'ABQ',
    87821: 'ABQ',
    87823: 'ABQ',
    87824: 'ABQ',
    87825: 'ABQ',
    87827: 'ABQ',
    87828: 'ABQ',
    87829: 'ABQ',
    87830: 'ABQ',
    87831: 'ABQ',
    87832: 'ABQ',
    87901: 'ABQ',
    87930: 'ABQ',
    87931: 'ABQ',
    87933: 'ABQ',
    87935: 'ABQ',
    87936: 'ABQ',
    87937: 'ABQ',
    87939: 'ABQ',
    87940: 'ABQ',
    87941: 'ABQ',
    87942: 'ABQ',
    87943: 'ABQ',
    88001: 'ABQ',
    88002: 'ABQ',
    88003: 'ABQ',
    88004: 'ABQ',
    88005: 'ABQ',
    88006: 'ABQ',
    88007: 'ABQ',
    88008: 'ABQ',
    88009: 'ABQ',
    88011: 'ABQ',
    88012: 'ABQ',
    88013: 'ABQ',
    88020: 'ABQ',
    88021: 'ABQ',
    88022: 'ABQ',
    88023: 'ABQ',
    88024: 'ABQ',
    88025: 'ABQ',
    88026: 'ABQ',
    88027: 'ABQ',
    88028: 'ABQ',
    88029: 'ABQ',
    88030: 'ABQ',
    88031: 'ABQ',
    88032: 'ABQ',
    88033: 'ABQ',
    88034: 'ABQ',
    88036: 'ABQ',
    88038: 'ABQ',
    88039: 'ABQ',
    88040: 'ABQ',
    88041: 'ABQ',
    88042: 'ABQ',
    88043: 'ABQ',
    88044: 'ABQ',
    88045: 'ABQ',
    88046: 'ABQ',
    88047: 'ABQ',
    88048: 'ABQ',
    88049: 'ABQ',
    88051: 'ABQ',
    88052: 'ABQ',
    88053: 'ABQ',
    88054: 'ABQ',
    88055: 'ABQ',
    88056: 'ABQ',
    88058: 'ABQ',
    88061: 'ABQ',
    88062: 'ABQ',
    88063: 'ABQ',
    88065: 'ABQ',
    88072: 'ABQ',
    88081: 'ABQ',
    88101: 'ABQ',
    88102: 'ABQ',
    88103: 'ABQ',
    88112: 'ABQ',
    88113: 'ABQ',
    88114: 'ABQ',
    88115: 'ABQ',
    88116: 'ABQ',
    88118: 'ABQ',
    88119: 'ABQ',
    88120: 'ABQ',
    88121: 'ABQ',
    88122: 'ABQ',
    88123: 'ABQ',
    88124: 'ABQ',
    88125: 'ABQ',
    88126: 'ABQ',
    88130: 'ABQ',
    88132: 'ABQ',
    88133: 'ABQ',
    88134: 'ABQ',
    88135: 'ABQ',
    88136: 'ABQ',
    88201: 'ABQ',
    88202: 'ABQ',
    88203: 'ABQ',
    88210: 'ABQ',
    88211: 'ABQ',
    88213: 'ABQ',
    88220: 'ABQ',
    88221: 'ABQ',
    88230: 'ABQ',
    88231: 'ABQ',
    88232: 'ABQ',
    88240: 'ABQ',
    88241: 'ABQ',
    88242: 'ABQ',
    88244: 'ABQ',
    88250: 'ABQ',
    88252: 'ABQ',
    88253: 'ABQ',
    88254: 'ABQ',
    88255: 'ABQ',
    88256: 'ABQ',
    88260: 'ABQ',
    88262: 'ABQ',
    88263: 'ABQ',
    88264: 'ABQ',
    88265: 'ABQ',
    88267: 'ABQ',
    88268: 'ABQ',
    88301: 'ABQ',
    88310: 'ABQ',
    88311: 'ABQ',
    88312: 'ABQ',
    88314: 'ABQ',
    88316: 'ABQ',
    88317: 'ABQ',
    88318: 'ABQ',
    88321: 'ABQ',
    88323: 'ABQ',
    88324: 'ABQ',
    88325: 'ABQ',
    88330: 'ABQ',
    88336: 'ABQ',
    88337: 'ABQ',
    88338: 'ABQ',
    88339: 'ABQ',
    88340: 'ABQ',
    88341: 'ABQ',
    88342: 'ABQ',
    88343: 'ABQ',
    88344: 'ABQ',
    88345: 'ABQ',
    88346: 'ABQ',
    88347: 'ABQ',
    88348: 'ABQ',
    88349: 'ABQ',
    88350: 'ABQ',
    88351: 'ABQ',
    88352: 'ABQ',
    88353: 'ABQ',
    88354: 'ABQ',
    88355: 'ABQ',
    88401: 'ABQ',
    88410: 'ABQ',
    88411: 'ABQ',
    88414: 'ABQ',
    88415: 'ABQ',
    88416: 'ABQ',
    88417: 'ABQ',
    88418: 'ABQ',
    88419: 'ABQ',
    88421: 'ABQ',
    88422: 'ABQ',
    88424: 'ABQ',
    88426: 'ABQ',
    88427: 'ABQ',
    88430: 'ABQ',
    88431: 'ABQ',
    88433: 'ABQ',
    88434: 'ABQ',
    88435: 'ABQ',
    88436: 'ABQ',
    88439: 'ABQ',
    88510: 'ELP',
    88511: 'ELP',
    88512: 'ELP',
    88513: 'ELP',
    88514: 'ELP',
    88515: 'ELP',
    88516: 'ELP',
    88517: 'ELP',
    88518: 'ELP',
    88519: 'ELP',
    88520: 'ELP',
    88521: 'ELP',
    88523: 'ELP',
    88524: 'ELP',
    88525: 'ELP',
    88526: 'ELP',
    88527: 'ELP',
    88528: 'ELP',
    88529: 'ELP',
    88530: 'ELP',
    88531: 'ELP',
    88532: 'ELP',
    88533: 'ELP',
    88534: 'ELP',
    88535: 'ELP',
    88536: 'ELP',
    88538: 'ELP',
    88539: 'ELP',
    88540: 'ELP',
    88541: 'ELP',
    88542: 'ELP',
    88543: 'ELP',
    88544: 'ELP',
    88545: 'ELP',
    88546: 'ELP',
    88547: 'ELP',
    88548: 'ELP',
    88549: 'ELP',
    88550: 'ELP',
    88553: 'ELP',
    88554: 'ELP',
    88555: 'ELP',
    88556: 'ELP',
    88557: 'ELP',
    88558: 'ELP',
    88559: 'ELP',
    88560: 'ELP',
    88561: 'ELP',
    88562: 'ELP',
    88563: 'ELP',
    88565: 'ELP',
    88566: 'ELP',
    88567: 'ELP',
    88568: 'ELP',
    88569: 'ELP',
    88570: 'ELP',
    88571: 'ELP',
    88572: 'ELP',
    88573: 'ELP',
    88574: 'ELP',
    88575: 'ELP',
    88576: 'ELP',
    88577: 'ELP',
    88578: 'ELP',
    88579: 'ELP',
    88580: 'ELP',
    88581: 'ELP',
    88582: 'ELP',
    88583: 'ELP',
    88584: 'ELP',
    88585: 'ELP',
    88586: 'ELP',
    88587: 'ELP',
    88588: 'ELP',
    88589: 'ELP',
    88590: 'ELP',
    88595: 'ELP',
    88901: 'LAS',
    88905: 'LAS',
    89001: 'LAS',
    89002: 'LAS',
    89003: 'LAS',
    89004: 'LAS',
    89005: 'LAS',
    89006: 'LAS',
    89007: 'LAS',
    89008: 'LAS',
    89009: 'LAS',
    89010: 'LAS',
    89011: 'LAS',
    89012: 'LAS',
    89013: 'LAS',
    89014: 'LAS',
    89015: 'LAS',
    89016: 'LAS',
    89017: 'LAS',
    89018: 'LAS',
    89019: 'LAS',
    89020: 'LAS',
    89021: 'LAS',
    89022: 'LAS',
    89023: 'LAS',
    89024: 'LAS',
    89025: 'LAS',
    89026: 'LAS',
    89027: 'LAS',
    89028: 'LAS',
    89029: 'LAS',
    89030: 'LAS',
    89031: 'LAS',
    89032: 'LAS',
    89033: 'LAS',
    89034: 'LAS',
    89036: 'LAS',
    89037: 'LAS',
    89039: 'LAS',
    89040: 'LAS',
    89041: 'LAS',
    89042: 'LAS',
    89043: 'LAS',
    89044: 'LAS',
    89045: 'RNO',
    89046: 'LAS',
    89047: 'RNO',
    89048: 'LAS',
    89049: 'LAS',
    89052: 'LAS',
    89053: 'LAS',
    89054: 'LAS',
    89060: 'LAS',
    89061: 'LAS',
    89067: 'LAS',
    89070: 'LAS',
    89074: 'LAS',
    89077: 'LAS',
    89081: 'LAS',
    89084: 'LAS',
    89085: 'LAS',
    89086: 'LAS',
    89087: 'LAS',
    89101: 'LAS',
    89102: 'LAS',
    89103: 'LAS',
    89104: 'LAS',
    89105: 'LAS',
    89106: 'LAS',
    89107: 'LAS',
    89108: 'LAS',
    89109: 'LAS',
    89110: 'LAS',
    89111: 'LAS',
    89112: 'LAS',
    89113: 'LAS',
    89114: 'LAS',
    89115: 'LAS',
    89116: 'LAS',
    89117: 'LAS',
    89118: 'LAS',
    89119: 'LAS',
    89120: 'LAS',
    89121: 'LAS',
    89122: 'LAS',
    89123: 'LAS',
    89124: 'LAS',
    89125: 'LAS',
    89126: 'LAS',
    89127: 'LAS',
    89128: 'LAS',
    89129: 'LAS',
    89130: 'LAS',
    89131: 'LAS',
    89132: 'LAS',
    89133: 'LAS',
    89134: 'LAS',
    89135: 'LAS',
    89136: 'LAS',
    89137: 'LAS',
    89138: 'LAS',
    89139: 'LAS',
    89140: 'LAS',
    89141: 'LAS',
    89142: 'LAS',
    89143: 'LAS',
    89144: 'LAS',
    89145: 'LAS',
    89146: 'LAS',
    89147: 'LAS',
    89148: 'LAS',
    89149: 'LAS',
    89150: 'LAS',
    89151: 'LAS',
    89152: 'LAS',
    89153: 'LAS',
    89154: 'LAS',
    89155: 'LAS',
    89156: 'LAS',
    89157: 'LAS',
    89158: 'LAS',
    89159: 'LAS',
    89160: 'LAS',
    89161: 'LAS',
    89162: 'LAS',
    89163: 'LAS',
    89164: 'LAS',
    89165: 'LAS',
    89166: 'LAS',
    89169: 'LAS',
    89170: 'LAS',
    89173: 'LAS',
    89177: 'LAS',
    89178: 'LAS',
    89179: 'LAS',
    89180: 'LAS',
    89183: 'LAS',
    89185: 'LAS',
    89191: 'LAS',
    89193: 'LAS',
    89195: 'LAS',
    89199: 'LAS',
    89301: 'SLC',
    89310: 'SLC',
    89311: 'SLC',
    89314: 'SLC',
    89315: 'SLC',
    89316: 'SLC',
    89317: 'SLC',
    89318: 'SLC',
    89319: 'RNO',
    89402: 'RNO',
    89403: 'RNO',
    89404: 'SLC',
    89405: 'RNO',
    89406: 'RNO',
    89407: 'RNO',
    89408: 'RNO',
    89409: 'RNO',
    89410: 'RNO',
    89411: 'RNO',
    89412: 'RNO',
    89413: 'RNO',
    89414: 'SLC',
    89415: 'RNO',
    89418: 'SLC',
    89419: 'RNO',
    89420: 'RNO',
    89421: 'SLC',
    89422: 'RNO',
    89423: 'RNO',
    89424: 'RNO',
    89425: 'SLC',
    89426: 'SLC',
    89427: 'RNO',
    89428: 'RNO',
    89429: 'RNO',
    89430: 'RNO',
    89431: 'RNO',
    89432: 'RNO',
    89433: 'RNO',
    89434: 'RNO',
    89435: 'RNO',
    89436: 'RNO',
    89437: 'RNO',
    89438: 'RNO',
    89439: 'RNO',
    89440: 'RNO',
    89441: 'RNO',
    89442: 'RNO',
    89444: 'RNO',
    89445: 'SLC',
    89446: 'RNO',
    89447: 'RNO',
    89448: 'RNO',
    89449: 'RNO',
    89450: 'RNO',
    89451: 'RNO',
    89452: 'RNO',
    89460: 'RNO',
    89496: 'RNO',
    89501: 'RNO',
    89502: 'RNO',
    89503: 'RNO',
    89504: 'RNO',
    89505: 'RNO',
    89506: 'RNO',
    89507: 'RNO',
    89508: 'RNO',
    89509: 'RNO',
    89510: 'RNO',
    89511: 'RNO',
    89512: 'RNO',
    89513: 'RNO',
    89515: 'RNO',
    89519: 'RNO',
    89520: 'RNO',
    89521: 'RNO',
    89523: 'RNO',
    89533: 'RNO',
    89555: 'RNO',
    89557: 'RNO',
    89570: 'RNO',
    89595: 'RNO',
    89599: 'RNO',
    89701: 'RNO',
    89702: 'RNO',
    89703: 'RNO',
    89704: 'RNO',
    89705: 'RNO',
    89706: 'RNO',
    89711: 'RNO',
    89712: 'RNO',
    89713: 'RNO',
    89714: 'RNO',
    89721: 'RNO',
    89801: 'SLC',
    89802: 'SLC',
    89803: 'SLC',
    89815: 'SLC',
    89820: 'SLC',
    89821: 'SLC',
    89822: 'SLC',
    89823: 'SLC',
    89824: 'SLC',
    89825: 'SLC',
    89826: 'SLC',
    89828: 'SLC',
    89830: 'SLC',
    89831: 'SLC',
    89832: 'SLC',
    89833: 'SLC',
    89834: 'SLC',
    89835: 'SLC',
    89883: 'SLC',
    90001: 'LAX',
    90002: 'LAX',
    90003: 'LAX',
    90004: 'LAX',
    90005: 'LAX',
    90006: 'LAX',
    90007: 'LAX',
    90008: 'LAX',
    90009: 'LAX',
    90010: 'LAX',
    90011: 'LAX',
    90012: 'LAX',
    90013: 'LAX',
    90014: 'LAX',
    90015: 'LAX',
    90016: 'LAX',
    90017: 'LAX',
    90018: 'LAX',
    90019: 'LAX',
    90020: 'LAX',
    90021: 'LAX',
    90022: 'LAX',
    90023: 'LAX',
    90024: 'LAX',
    90025: 'LAX',
    90026: 'LAX',
    90027: 'LAX',
    90028: 'LAX',
    90029: 'LAX',
    90030: 'LAX',
    90031: 'LAX',
    90032: 'LAX',
    90033: 'LAX',
    90034: 'LAX',
    90035: 'LAX',
    90036: 'LAX',
    90037: 'LAX',
    90038: 'LAX',
    90039: 'LAX',
    90040: 'LAX',
    90041: 'LAX',
    90042: 'LAX',
    90043: 'LAX',
    90044: 'LAX',
    90045: 'LAX',
    90046: 'LAX',
    90047: 'LAX',
    90048: 'LAX',
    90049: 'LAX',
    90050: 'LAX',
    90051: 'LAX',
    90052: 'LAX',
    90053: 'LAX',
    90054: 'LAX',
    90055: 'LAX',
    90056: 'LAX',
    90057: 'LAX',
    90058: 'LAX',
    90059: 'LAX',
    90060: 'LAX',
    90061: 'LAX',
    90062: 'LAX',
    90063: 'LAX',
    90064: 'LAX',
    90065: 'LAX',
    90066: 'LAX',
    90067: 'LAX',
    90068: 'LAX',
    90069: 'LAX',
    90070: 'LAX',
    90071: 'LAX',
    90072: 'LAX',
    90073: 'LAX',
    90074: 'LAX',
    90075: 'LAX',
    90076: 'LAX',
    90077: 'LAX',
    90078: 'LAX',
    90079: 'LAX',
    90080: 'LAX',
    90081: 'LAX',
    90082: 'LAX',
    90083: 'LAX',
    90084: 'LAX',
    90086: 'LAX',
    90087: 'LAX',
    90088: 'LAX',
    90089: 'LAX',
    90090: 'LAX',
    90091: 'LAX',
    90093: 'LAX',
    90094: 'LAX',
    90095: 'LAX',
    90096: 'LAX',
    90099: 'LAX',
    90101: 'LAX',
    90102: 'LAX',
    90103: 'LAX',
    90189: 'LAX',
    90201: 'LAX',
    90202: 'LAX',
    90209: 'LAX',
    90210: 'LAX',
    90211: 'LAX',
    90212: 'LAX',
    90213: 'LAX',
    90220: 'LAX',
    90221: 'LAX',
    90222: 'LAX',
    90223: 'LAX',
    90224: 'LAX',
    90230: 'LAX',
    90231: 'LAX',
    90232: 'LAX',
    90233: 'LAX',
    90239: 'LAX',
    90240: 'LAX',
    90241: 'LAX',
    90242: 'LAX',
    90245: 'LAX',
    90247: 'LAX',
    90248: 'LAX',
    90249: 'LAX',
    90250: 'LAX',
    90251: 'LAX',
    90254: 'LAX',
    90255: 'LAX',
    90260: 'LAX',
    90261: 'LAX',
    90262: 'LAX',
    90263: 'LAX',
    90264: 'LAX',
    90265: 'LAX',
    90266: 'LAX',
    90267: 'LAX',
    90270: 'LAX',
    90272: 'LAX',
    90274: 'LAX',
    90275: 'LAX',
    90277: 'LAX',
    90278: 'LAX',
    90280: 'LAX',
    90290: 'LAX',
    90291: 'LAX',
    90292: 'LAX',
    90293: 'LAX',
    90294: 'LAX',
    90295: 'LAX',
    90296: 'LAX',
    90301: 'LAX',
    90302: 'LAX',
    90303: 'LAX',
    90304: 'LAX',
    90305: 'LAX',
    90306: 'LAX',
    90307: 'LAX',
    90308: 'LAX',
    90309: 'LAX',
    90310: 'LAX',
    90311: 'LAX',
    90312: 'LAX',
    90313: 'LAX',
    90397: 'LAX',
    90398: 'LAX',
    90401: 'LAX',
    90402: 'LAX',
    90403: 'LAX',
    90404: 'LAX',
    90405: 'LAX',
    90406: 'LAX',
    90407: 'LAX',
    90408: 'LAX',
    90409: 'LAX',
    90410: 'LAX',
    90411: 'LAX',
    90501: 'LAX',
    90502: 'LAX',
    90503: 'LAX',
    90504: 'LAX',
    90505: 'LAX',
    90506: 'LAX',
    90507: 'LAX',
    90508: 'LAX',
    90509: 'LAX',
    90510: 'LAX',
    90601: 'LAX',
    90602: 'LAX',
    90603: 'LAX',
    90604: 'LAX',
    90605: 'LAX',
    90606: 'LAX',
    90607: 'LAX',
    90608: 'LAX',
    90609: 'LAX',
    90610: 'LAX',
    90612: 'LAX',
    90620: 'LAX',
    90621: 'LAX',
    90622: 'LAX',
    90623: 'LAX',
    90624: 'LAX',
    90630: 'LAX',
    90631: 'LAX',
    90632: 'LAX',
    90633: 'LAX',
    90637: 'LAX',
    90638: 'LAX',
    90639: 'LAX',
    90640: 'LAX',
    90650: 'LAX',
    90651: 'LAX',
    90652: 'LAX',
    90659: 'LAX',
    90660: 'LAX',
    90661: 'LAX',
    90662: 'LAX',
    90670: 'LAX',
    90671: 'LAX',
    90680: 'LAX',
    90701: 'LAX',
    90702: 'LAX',
    90703: 'LAX',
    90704: 'LAX',
    90706: 'LAX',
    90707: 'LAX',
    90710: 'LAX',
    90711: 'LAX',
    90712: 'LAX',
    90713: 'LAX',
    90714: 'LAX',
    90715: 'LAX',
    90716: 'LAX',
    90717: 'LAX',
    90720: 'LAX',
    90721: 'LAX',
    90723: 'LAX',
    90731: 'LAX',
    90732: 'LAX',
    90733: 'LAX',
    90734: 'LAX',
    90740: 'LAX',
    90742: 'LAX',
    90743: 'LAX',
    90744: 'LAX',
    90745: 'LAX',
    90746: 'LAX',
    90747: 'LAX',
    90748: 'LAX',
    90749: 'LAX',
    90755: 'LAX',
    90801: 'LAX',
    90802: 'LAX',
    90803: 'LAX',
    90804: 'LAX',
    90805: 'LAX',
    90806: 'LAX',
    90807: 'LAX',
    90808: 'LAX',
    90809: 'LAX',
    90810: 'LAX',
    90813: 'LAX',
    90814: 'LAX',
    90815: 'LAX',
    90822: 'LAX',
    90831: 'LAX',
    90832: 'LAX',
    90833: 'LAX',
    90834: 'LAX',
    90835: 'LAX',
    90840: 'LAX',
    90842: 'LAX',
    90844: 'LAX',
    90845: 'LAX',
    90846: 'LAX',
    90847: 'LAX',
    90848: 'LAX',
    90853: 'LAX',
    90888: 'LAX',
    90895: 'LAX',
    90899: 'LAX',
    91001: 'LAX',
    91003: 'LAX',
    91006: 'LAX',
    91007: 'LAX',
    91008: 'LAX',
    91009: 'LAX',
    91010: 'LAX',
    91011: 'LAX',
    91012: 'LAX',
    91016: 'LAX',
    91017: 'LAX',
    91020: 'LAX',
    91021: 'LAX',
    91023: 'LAX',
    91024: 'LAX',
    91025: 'LAX',
    91030: 'LAX',
    91031: 'LAX',
    91040: 'LAX',
    91041: 'LAX',
    91042: 'LAX',
    91043: 'LAX',
    91046: 'LAX',
    91066: 'LAX',
    91077: 'LAX',
    91101: 'LAX',
    91102: 'LAX',
    91103: 'LAX',
    91104: 'LAX',
    91105: 'LAX',
    91106: 'LAX',
    91107: 'LAX',
    91108: 'LAX',
    91109: 'LAX',
    91110: 'LAX',
    91114: 'LAX',
    91115: 'LAX',
    91116: 'LAX',
    91117: 'LAX',
    91118: 'LAX',
    91121: 'LAX',
    91123: 'LAX',
    91124: 'LAX',
    91125: 'LAX',
    91126: 'LAX',
    91129: 'LAX',
    91131: 'LAX',
    91182: 'LAX',
    91184: 'LAX',
    91185: 'LAX',
    91188: 'LAX',
    91189: 'LAX',
    91191: 'LAX',
    91199: 'LAX',
    91201: 'LAX',
    91202: 'LAX',
    91203: 'LAX',
    91204: 'LAX',
    91205: 'LAX',
    91206: 'LAX',
    91207: 'LAX',
    91208: 'LAX',
    91209: 'LAX',
    91210: 'LAX',
    91214: 'LAX',
    91221: 'LAX',
    91222: 'LAX',
    91224: 'LAX',
    91225: 'LAX',
    91226: 'LAX',
    91301: 'LAX',
    91302: 'LAX',
    91303: 'LAX',
    91304: 'LAX',
    91305: 'LAX',
    91306: 'LAX',
    91307: 'LAX',
    91308: 'LAX',
    91309: 'LAX',
    91310: 'LAX',
    91311: 'LAX',
    91313: 'LAX',
    91316: 'LAX',
    91319: 'LAX',
    91320: 'LAX',
    91321: 'LAX',
    91322: 'LAX',
    91324: 'LAX',
    91325: 'LAX',
    91326: 'LAX',
    91327: 'LAX',
    91328: 'LAX',
    91329: 'LAX',
    91330: 'LAX',
    91331: 'LAX',
    91333: 'LAX',
    91334: 'LAX',
    91335: 'LAX',
    91337: 'LAX',
    91340: 'LAX',
    91341: 'LAX',
    91342: 'LAX',
    91343: 'LAX',
    91344: 'LAX',
    91345: 'LAX',
    91346: 'LAX',
    91350: 'LAX',
    91351: 'LAX',
    91352: 'LAX',
    91353: 'LAX',
    91354: 'LAX',
    91355: 'LAX',
    91356: 'LAX',
    91357: 'LAX',
    91358: 'LAX',
    91359: 'LAX',
    91360: 'LAX',
    91361: 'LAX',
    91362: 'LAX',
    91363: 'LAX',
    91364: 'LAX',
    91365: 'LAX',
    91367: 'LAX',
    91371: 'LAX',
    91372: 'LAX',
    91376: 'LAX',
    91377: 'LAX',
    91380: 'LAX',
    91381: 'LAX',
    91382: 'LAX',
    91383: 'LAX',
    91384: 'LAX',
    91385: 'LAX',
    91386: 'LAX',
    91387: 'LAX',
    91388: 'LAX',
    91390: 'LAX',
    91392: 'LAX',
    91393: 'LAX',
    91394: 'LAX',
    91395: 'LAX',
    91396: 'LAX',
    91399: 'LAX',
    91401: 'LAX',
    91402: 'LAX',
    91403: 'LAX',
    91404: 'LAX',
    91405: 'LAX',
    91406: 'LAX',
    91407: 'LAX',
    91408: 'LAX',
    91409: 'LAX',
    91410: 'LAX',
    91411: 'LAX',
    91412: 'LAX',
    91413: 'LAX',
    91416: 'LAX',
    91423: 'LAX',
    91426: 'LAX',
    91436: 'LAX',
    91470: 'LAX',
    91482: 'LAX',
    91495: 'LAX',
    91496: 'LAX',
    91497: 'LAX',
    91499: 'LAX',
    91501: 'LAX',
    91502: 'LAX',
    91503: 'LAX',
    91504: 'LAX',
    91505: 'LAX',
    91506: 'LAX',
    91507: 'LAX',
    91508: 'LAX',
    91510: 'LAX',
    91521: 'LAX',
    91522: 'LAX',
    91523: 'LAX',
    91526: 'LAX',
    91601: 'LAX',
    91602: 'LAX',
    91603: 'LAX',
    91604: 'LAX',
    91605: 'LAX',
    91606: 'LAX',
    91607: 'LAX',
    91608: 'LAX',
    91609: 'LAX',
    91610: 'LAX',
    91611: 'LAX',
    91612: 'LAX',
    91614: 'LAX',
    91615: 'LAX',
    91616: 'LAX',
    91617: 'LAX',
    91618: 'LAX',
    91701: 'LAX',
    91702: 'LAX',
    91706: 'LAX',
    91708: 'LAX',
    91709: 'LAX',
    91710: 'LAX',
    91711: 'LAX',
    91714: 'LAX',
    91715: 'LAX',
    91716: 'LAX',
    91722: 'LAX',
    91723: 'LAX',
    91724: 'LAX',
    91729: 'LAX',
    91730: 'LAX',
    91731: 'LAX',
    91732: 'LAX',
    91733: 'LAX',
    91734: 'LAX',
    91735: 'LAX',
    91737: 'LAX',
    91739: 'LAX',
    91740: 'LAX',
    91741: 'LAX',
    91743: 'LAX',
    91744: 'LAX',
    91745: 'LAX',
    91746: 'LAX',
    91747: 'LAX',
    91748: 'LAX',
    91749: 'LAX',
    91750: 'LAX',
    91752: 'LAX',
    91754: 'LAX',
    91755: 'LAX',
    91756: 'LAX',
    91758: 'LAX',
    91759: 'LAX',
    91761: 'LAX',
    91762: 'LAX',
    91763: 'LAX',
    91764: 'LAX',
    91765: 'LAX',
    91766: 'LAX',
    91767: 'LAX',
    91768: 'LAX',
    91769: 'LAX',
    91770: 'LAX',
    91771: 'LAX',
    91772: 'LAX',
    91773: 'LAX',
    91775: 'LAX',
    91776: 'LAX',
    91778: 'LAX',
    91780: 'LAX',
    91784: 'LAX',
    91785: 'LAX',
    91786: 'LAX',
    91788: 'LAX',
    91789: 'LAX',
    91790: 'LAX',
    91791: 'LAX',
    91792: 'LAX',
    91793: 'LAX',
    91795: 'LAX',
    91797: 'LAX',
    91798: 'LAX',
    91799: 'LAX',
    91801: 'LAX',
    91802: 'LAX',
    91803: 'LAX',
    91804: 'LAX',
    91841: 'LAX',
    91896: 'LAX',
    91899: 'LAX',
    91901: 'SAN',
    91902: 'SAN',
    91903: 'SAN',
    91905: 'SAN',
    91906: 'SAN',
    91908: 'SAN',
    91909: 'SAN',
    91910: 'SAN',
    91911: 'SAN',
    91912: 'SAN',
    91913: 'SAN',
    91914: 'SAN',
    91915: 'SAN',
    91916: 'SAN',
    91917: 'SAN',
    91921: 'SAN',
    91931: 'SAN',
    91932: 'SAN',
    91933: 'SAN',
    91934: 'SAN',
    91935: 'SAN',
    91941: 'SAN',
    91942: 'SAN',
    91943: 'SAN',
    91944: 'SAN',
    91945: 'SAN',
    91946: 'SAN',
    91947: 'SAN',
    91948: 'SAN',
    91950: 'SAN',
    91951: 'SAN',
    91962: 'SAN',
    91963: 'SAN',
    91976: 'SAN',
    91977: 'SAN',
    91978: 'SAN',
    91979: 'SAN',
    91980: 'SAN',
    91987: 'SAN',
    91990: 'SAN',
    92003: 'SAN',
    92004: 'SAN',
    92007: 'SAN',
    92008: 'SAN',
    92009: 'SAN',
    92010: 'SAN',
    92011: 'SAN',
    92013: 'SAN',
    92014: 'SAN',
    92018: 'SAN',
    92019: 'SAN',
    92020: 'SAN',
    92021: 'SAN',
    92022: 'SAN',
    92023: 'SAN',
    92024: 'SAN',
    92025: 'SAN',
    92026: 'SAN',
    92027: 'SAN',
    92028: 'LAX',
    92029: 'SAN',
    92030: 'SAN',
    92033: 'SAN',
    92036: 'SAN',
    92037: 'SAN',
    92038: 'SAN',
    92039: 'SAN',
    92040: 'SAN',
    92046: 'SAN',
    92049: 'SAN',
    92051: 'SAN',
    92052: 'SAN',
    92054: 'SAN',
    92055: 'LAX',
    92056: 'SAN',
    92057: 'SAN',
    92058: 'SAN',
    92059: 'SAN',
    92060: 'SAN',
    92061: 'SAN',
    92064: 'SAN',
    92065: 'SAN',
    92066: 'SAN',
    92067: 'SAN',
    92068: 'SAN',
    92069: 'SAN',
    92070: 'SAN',
    92071: 'SAN',
    92072: 'SAN',
    92074: 'SAN',
    92075: 'SAN',
    92078: 'SAN',
    92079: 'SAN',
    92081: 'SAN',
    92082: 'SAN',
    92083: 'SAN',
    92084: 'SAN',
    92085: 'SAN',
    92086: 'SAN',
    92088: 'SAN',
    92090: 'SAN',
    92091: 'SAN',
    92092: 'SAN',
    92093: 'SAN',
    92096: 'SAN',
    92101: 'SAN',
    92102: 'SAN',
    92103: 'SAN',
    92104: 'SAN',
    92105: 'SAN',
    92106: 'SAN',
    92107: 'SAN',
    92108: 'SAN',
    92109: 'SAN',
    92110: 'SAN',
    92111: 'SAN',
    92112: 'SAN',
    92113: 'SAN',
    92114: 'SAN',
    92115: 'SAN',
    92116: 'SAN',
    92117: 'SAN',
    92118: 'SAN',
    92119: 'SAN',
    92120: 'SAN',
    92121: 'SAN',
    92122: 'SAN',
    92123: 'SAN',
    92124: 'SAN',
    92126: 'SAN',
    92127: 'SAN',
    92128: 'SAN',
    92129: 'SAN',
    92130: 'SAN',
    92131: 'SAN',
    92132: 'SAN',
    92133: 'SAN',
    92134: 'SAN',
    92135: 'SAN',
    92136: 'SAN',
    92137: 'SAN',
    92138: 'SAN',
    92139: 'SAN',
    92140: 'SAN',
    92142: 'SAN',
    92143: 'SAN',
    92145: 'SAN',
    92147: 'SAN',
    92149: 'SAN',
    92150: 'SAN',
    92152: 'SAN',
    92153: 'SAN',
    92154: 'SAN',
    92155: 'SAN',
    92158: 'SAN',
    92159: 'SAN',
    92160: 'SAN',
    92161: 'SAN',
    92162: 'SAN',
    92163: 'SAN',
    92164: 'SAN',
    92165: 'SAN',
    92166: 'SAN',
    92167: 'SAN',
    92168: 'SAN',
    92169: 'SAN',
    92170: 'SAN',
    92171: 'SAN',
    92172: 'SAN',
    92173: 'SAN',
    92174: 'SAN',
    92175: 'SAN',
    92176: 'SAN',
    92177: 'SAN',
    92178: 'SAN',
    92179: 'SAN',
    92182: 'SAN',
    92184: 'SAN',
    92186: 'SAN',
    92187: 'SAN',
    92190: 'SAN',
    92191: 'SAN',
    92192: 'SAN',
    92193: 'SAN',
    92194: 'SAN',
    92195: 'SAN',
    92196: 'SAN',
    92197: 'SAN',
    92198: 'SAN',
    92199: 'SAN',
    92201: 'SAN',
    92202: 'SAN',
    92203: 'SAN',
    92210: 'SAN',
    92211: 'SAN',
    92220: 'LAX',
    92222: 'SAN',
    92223: 'LAX',
    92225: 'SAN',
    92226: 'SAN',
    92227: 'SAN',
    92230: 'LAX',
    92231: 'SAN',
    92232: 'SAN',
    92233: 'SAN',
    92234: 'SAN',
    92235: 'SAN',
    92236: 'SAN',
    92239: 'SAN',
    92240: 'LAX',
    92241: 'SAN',
    92242: 'SAN',
    92243: 'SAN',
    92244: 'SAN',
    92247: 'SAN',
    92248: 'SAN',
    92249: 'SAN',
    92250: 'SAN',
    92251: 'SAN',
    92252: 'LAX',
    92253: 'SAN',
    92254: 'SAN',
    92255: 'SAN',
    92256: 'LAX',
    92257: 'SAN',
    92258: 'LAX',
    92259: 'SAN',
    92260: 'SAN',
    92261: 'SAN',
    92262: 'LAX',
    92263: 'LAX',
    92264: 'SAN',
    92266: 'SAN',
    92267: 'SAN',
    92268: 'LAX',
    92270: 'SAN',
    92273: 'SAN',
    92274: 'SAN',
    92275: 'SAN',
    92276: 'SAN',
    92277: 'LAX',
    92278: 'LAX',
    92280: 'SAN',
    92281: 'SAN',
    92282: 'LAX',
    92283: 'SAN',
    92284: 'LAX',
    92285: 'LAX',
    92286: 'LAX',
    92292: 'LAX',
    92301: 'LAX',
    92304: 'LAX',
    92305: 'LAX',
    92307: 'LAX',
    92308: 'LAX',
    92309: 'LAX',
    92310: 'LAX',
    92311: 'LAX',
    92312: 'LAX',
    92313: 'LAX',
    92314: 'LAX',
    92315: 'LAX',
    92316: 'LAX',
    92317: 'LAX',
    92318: 'LAX',
    92320: 'LAX',
    92321: 'LAX',
    92322: 'LAX',
    92323: 'LAX',
    92324: 'LAX',
    92325: 'LAX',
    92326: 'LAX',
    92327: 'LAX',
    92328: 'BFL',
    92329: 'LAX',
    92331: 'LAX',
    92332: 'LAX',
    92333: 'LAX',
    92334: 'LAX',
    92335: 'LAX',
    92336: 'LAX',
    92337: 'LAX',
    92338: 'LAX',
    92339: 'LAX',
    92340: 'LAX',
    92341: 'LAX',
    92342: 'LAX',
    92344: 'LAX',
    92345: 'LAX',
    92346: 'LAX',
    92347: 'LAX',
    92350: 'LAX',
    92352: 'LAX',
    92354: 'LAX',
    92356: 'LAX',
    92357: 'LAX',
    92358: 'LAX',
    92359: 'LAX',
    92363: 'FLG',
    92364: 'LAX',
    92365: 'LAX',
    92366: 'LAX',
    92368: 'LAX',
    92369: 'LAX',
    92371: 'LAX',
    92372: 'LAX',
    92373: 'LAX',
    92374: 'LAX',
    92375: 'LAX',
    92376: 'LAX',
    92377: 'LAX',
    92378: 'LAX',
    92382: 'LAX',
    92384: 'LAX',
    92385: 'LAX',
    92386: 'LAX',
    92389: 'LAX',
    92391: 'LAX',
    92392: 'LAX',
    92393: 'LAX',
    92394: 'LAX',
    92395: 'LAX',
    92397: 'LAX',
    92398: 'LAX',
    92399: 'LAX',
    92401: 'LAX',
    92402: 'LAX',
    92403: 'LAX',
    92404: 'LAX',
    92405: 'LAX',
    92406: 'LAX',
    92407: 'LAX',
    92408: 'LAX',
    92410: 'LAX',
    92411: 'LAX',
    92412: 'LAX',
    92413: 'LAX',
    92414: 'LAX',
    92415: 'LAX',
    92418: 'LAX',
    92423: 'LAX',
    92424: 'LAX',
    92427: 'LAX',
    92501: 'LAX',
    92502: 'LAX',
    92503: 'LAX',
    92504: 'LAX',
    92505: 'LAX',
    92506: 'LAX',
    92507: 'LAX',
    92508: 'LAX',
    92509: 'LAX',
    92513: 'LAX',
    92514: 'LAX',
    92515: 'LAX',
    92516: 'LAX',
    92517: 'LAX',
    92518: 'LAX',
    92519: 'LAX',
    92521: 'LAX',
    92522: 'LAX',
    92530: 'LAX',
    92531: 'LAX',
    92532: 'LAX',
    92536: 'SAN',
    92539: 'SAN',
    92543: 'LAX',
    92544: 'LAX',
    92545: 'LAX',
    92546: 'LAX',
    92548: 'LAX',
    92549: 'LAX',
    92551: 'LAX',
    92552: 'LAX',
    92553: 'LAX',
    92554: 'LAX',
    92555: 'LAX',
    92556: 'LAX',
    92557: 'LAX',
    92561: 'SAN',
    92562: 'LAX',
    92563: 'LAX',
    92564: 'LAX',
    92567: 'LAX',
    92570: 'LAX',
    92571: 'LAX',
    92572: 'LAX',
    92581: 'LAX',
    92582: 'LAX',
    92583: 'LAX',
    92584: 'LAX',
    92585: 'LAX',
    92586: 'LAX',
    92587: 'LAX',
    92589: 'LAX',
    92590: 'LAX',
    92591: 'LAX',
    92592: 'LAX',
    92593: 'LAX',
    92595: 'LAX',
    92596: 'LAX',
    92599: 'LAX',
    92602: 'LAX',
    92603: 'LAX',
    92604: 'LAX',
    92605: 'LAX',
    92606: 'LAX',
    92607: 'LAX',
    92609: 'LAX',
    92610: 'LAX',
    92612: 'LAX',
    92614: 'LAX',
    92615: 'LAX',
    92616: 'LAX',
    92617: 'LAX',
    92618: 'LAX',
    92619: 'LAX',
    92620: 'LAX',
    92623: 'LAX',
    92624: 'LAX',
    92625: 'LAX',
    92626: 'LAX',
    92627: 'LAX',
    92628: 'LAX',
    92629: 'LAX',
    92630: 'LAX',
    92637: 'LAX',
    92646: 'LAX',
    92647: 'LAX',
    92648: 'LAX',
    92649: 'LAX',
    92650: 'LAX',
    92651: 'LAX',
    92652: 'LAX',
    92653: 'LAX',
    92654: 'LAX',
    92655: 'LAX',
    92656: 'LAX',
    92657: 'LAX',
    92658: 'LAX',
    92659: 'LAX',
    92660: 'LAX',
    92661: 'LAX',
    92662: 'LAX',
    92663: 'LAX',
    92672: 'LAX',
    92673: 'LAX',
    92674: 'LAX',
    92675: 'LAX',
    92676: 'LAX',
    92677: 'LAX',
    92678: 'LAX',
    92679: 'LAX',
    92683: 'LAX',
    92684: 'LAX',
    92685: 'LAX',
    92688: 'LAX',
    92690: 'LAX',
    92691: 'LAX',
    92692: 'LAX',
    92693: 'LAX',
    92694: 'LAX',
    92697: 'LAX',
    92698: 'LAX',
    92701: 'LAX',
    92702: 'LAX',
    92703: 'LAX',
    92704: 'LAX',
    92705: 'LAX',
    92706: 'LAX',
    92707: 'LAX',
    92708: 'LAX',
    92709: 'LAX',
    92710: 'LAX',
    92711: 'LAX',
    92712: 'LAX',
    92725: 'LAX',
    92728: 'LAX',
    92735: 'LAX',
    92780: 'LAX',
    92781: 'LAX',
    92782: 'LAX',
    92799: 'LAX',
    92801: 'LAX',
    92802: 'LAX',
    92803: 'LAX',
    92804: 'LAX',
    92805: 'LAX',
    92806: 'LAX',
    92807: 'LAX',
    92808: 'LAX',
    92809: 'LAX',
    92811: 'LAX',
    92812: 'LAX',
    92814: 'LAX',
    92815: 'LAX',
    92816: 'LAX',
    92817: 'LAX',
    92821: 'LAX',
    92822: 'LAX',
    92823: 'LAX',
    92825: 'LAX',
    92831: 'LAX',
    92832: 'LAX',
    92833: 'LAX',
    92834: 'LAX',
    92835: 'LAX',
    92836: 'LAX',
    92837: 'LAX',
    92838: 'LAX',
    92840: 'LAX',
    92841: 'LAX',
    92842: 'LAX',
    92843: 'LAX',
    92844: 'LAX',
    92845: 'LAX',
    92846: 'LAX',
    92850: 'LAX',
    92856: 'LAX',
    92857: 'LAX',
    92859: 'LAX',
    92860: 'LAX',
    92861: 'LAX',
    92862: 'LAX',
    92863: 'LAX',
    92864: 'LAX',
    92865: 'LAX',
    92866: 'LAX',
    92867: 'LAX',
    92868: 'LAX',
    92869: 'LAX',
    92870: 'LAX',
    92871: 'LAX',
    92877: 'LAX',
    92878: 'LAX',
    92879: 'LAX',
    92880: 'LAX',
    92881: 'LAX',
    92882: 'LAX',
    92883: 'LAX',
    92885: 'LAX',
    92886: 'LAX',
    92887: 'LAX',
    92899: 'LAX',
    93001: 'LAX',
    93002: 'LAX',
    93003: 'LAX',
    93004: 'LAX',
    93005: 'LAX',
    93006: 'LAX',
    93007: 'LAX',
    93009: 'LAX',
    93010: 'LAX',
    93011: 'LAX',
    93012: 'LAX',
    93013: 'SBP',
    93014: 'SBP',
    93015: 'LAX',
    93016: 'LAX',
    93020: 'LAX',
    93021: 'LAX',
    93022: 'LAX',
    93023: 'LAX',
    93024: 'LAX',
    93030: 'LAX',
    93031: 'LAX',
    93032: 'LAX',
    93033: 'LAX',
    93034: 'LAX',
    93035: 'LAX',
    93036: 'LAX',
    93040: 'LAX',
    93041: 'LAX',
    93042: 'LAX',
    93043: 'LAX',
    93044: 'LAX',
    93060: 'LAX',
    93061: 'LAX',
    93062: 'LAX',
    93063: 'LAX',
    93064: 'LAX',
    93065: 'LAX',
    93066: 'LAX',
    93067: 'SBP',
    93093: 'SBP',
    93094: 'LAX',
    93099: 'LAX',
    93101: 'SBP',
    93102: 'SBP',
    93103: 'SBP',
    93105: 'SBP',
    93106: 'SBP',
    93107: 'SBP',
    93108: 'SBP',
    93109: 'SBP',
    93110: 'SBP',
    93111: 'SBP',
    93116: 'SBP',
    93117: 'SBP',
    93118: 'SBP',
    93120: 'SBP',
    93121: 'SBP',
    93130: 'SBP',
    93140: 'SBP',
    93150: 'SBP',
    93160: 'SBP',
    93190: 'SBP',
    93199: 'SBP',
    93201: 'BFL',
    93202: 'FAT',
    93203: 'BFL',
    93204: 'SBP',
    93205: 'BFL',
    93206: 'SBP',
    93207: 'BFL',
    93208: 'BFL',
    93210: 'SBP',
    93212: 'BFL',
    93215: 'BFL',
    93216: 'BFL',
    93218: 'BFL',
    93219: 'BFL',
    93220: 'BFL',
    93221: 'FAT',
    93222: 'BFL',
    93223: 'FAT',
    93224: 'SBP',
    93225: 'BFL',
    93226: 'BFL',
    93227: 'FAT',
    93230: 'FAT',
    93232: 'FAT',
    93234: 'FAT',
    93235: 'FAT',
    93237: 'FAT',
    93238: 'BFL',
    93239: 'FAT',
    93240: 'BFL',
    93241: 'BFL',
    93242: 'FAT',
    93243: 'BFL',
    93244: 'FAT',
    93245: 'FAT',
    93246: 'FAT',
    93247: 'BFL',
    93249: 'SBP',
    93250: 'BFL',
    93251: 'SBP',
    93252: 'SBP',
    93254: 'SBP',
    93255: 'BFL',
    93256: 'BFL',
    93257: 'BFL',
    93258: 'BFL',
    93260: 'BFL',
    93261: 'BFL',
    93262: 'FAT',
    93263: 'BFL',
    93265: 'BFL',
    93266: 'FAT',
    93267: 'BFL',
    93268: 'SBP',
    93270: 'BFL',
    93271: 'FAT',
    93272: 'BFL',
    93274: 'BFL',
    93275: 'BFL',
    93276: 'BFL',
    93277: 'FAT',
    93278: 'FAT',
    93279: 'FAT',
    93280: 'BFL',
    93282: 'BFL',
    93283: 'BFL',
    93285: 'BFL',
    93286: 'FAT',
    93287: 'BFL',
    93290: 'FAT',
    93291: 'FAT',
    93292: 'FAT',
    93301: 'BFL',
    93302: 'BFL',
    93303: 'BFL',
    93304: 'BFL',
    93305: 'BFL',
    93306: 'BFL',
    93307: 'BFL',
    93308: 'BFL',
    93309: 'BFL',
    93311: 'BFL',
    93312: 'BFL',
    93313: 'BFL',
    93314: 'BFL',
    93380: 'BFL',
    93381: 'BFL',
    93382: 'BFL',
    93383: 'BFL',
    93384: 'BFL',
    93385: 'BFL',
    93386: 'BFL',
    93387: 'BFL',
    93388: 'BFL',
    93389: 'BFL',
    93390: 'BFL',
    93401: 'SBP',
    93402: 'SBP',
    93403: 'SBP',
    93405: 'SBP',
    93406: 'SBP',
    93407: 'SBP',
    93408: 'SBP',
    93409: 'SBP',
    93410: 'SBP',
    93412: 'SBP',
    93420: 'SBP',
    93421: 'SBP',
    93422: 'SBP',
    93423: 'SBP',
    93424: 'SBP',
    93426: 'SBP',
    93427: 'SBP',
    93428: 'SBP',
    93429: 'SBP',
    93430: 'SBP',
    93432: 'SBP',
    93433: 'SBP',
    93434: 'SBP',
    93435: 'SBP',
    93436: 'SBP',
    93437: 'SBP',
    93438: 'SBP',
    93440: 'SBP',
    93441: 'SBP',
    93442: 'SBP',
    93443: 'SBP',
    93444: 'SBP',
    93445: 'SBP',
    93446: 'SBP',
    93447: 'SBP',
    93448: 'SBP',
    93449: 'SBP',
    93450: 'SBP',
    93451: 'SBP',
    93452: 'SBP',
    93453: 'SBP',
    93454: 'SBP',
    93455: 'SBP',
    93456: 'SBP',
    93457: 'SBP',
    93458: 'SBP',
    93460: 'SBP',
    93461: 'SBP',
    93463: 'SBP',
    93464: 'SBP',
    93465: 'SBP',
    93475: 'SBP',
    93483: 'SBP',
    93501: 'LAX',
    93502: 'LAX',
    93504: 'LAX',
    93505: 'LAX',
    93510: 'LAX',
    93512: 'FAT',
    93513: 'FAT',
    93514: 'FAT',
    93515: 'FAT',
    93516: 'LAX',
    93517: 'RNO',
    93518: 'BFL',
    93519: 'LAX',
    93522: 'BFL',
    93523: 'LAX',
    93524: 'LAX',
    93526: 'BFL',
    93527: 'BFL',
    93528: 'LAX',
    93529: 'SMF',
    93530: 'BFL',
    93531: 'BFL',
    93532: 'LAX',
    93534: 'LAX',
    93535: 'LAX',
    93536: 'LAX',
    93539: 'LAX',
    93541: 'RNO',
    93542: 'BFL',
    93543: 'LAX',
    93544: 'LAX',
    93545: 'BFL',
    93546: 'SMF',
    93549: 'BFL',
    93550: 'LAX',
    93551: 'LAX',
    93552: 'LAX',
    93553: 'LAX',
    93554: 'LAX',
    93555: 'LAX',
    93556: 'BFL',
    93558: 'BFL',
    93560: 'BFL',
    93561: 'BFL',
    93562: 'LAX',
    93563: 'LAX',
    93581: 'BFL',
    93584: 'LAX',
    93586: 'LAX',
    93590: 'LAX',
    93591: 'LAX',
    93592: 'BFL',
    93596: 'LAX',
    93599: 'LAX',
    93601: 'SMF',
    93602: 'FAT',
    93603: 'FAT',
    93604: 'FAT',
    93605: 'FAT',
    93606: 'FAT',
    93607: 'FAT',
    93608: 'FAT',
    93609: 'FAT',
    93610: 'FAT',
    93611: 'FAT',
    93612: 'FAT',
    93613: 'FAT',
    93614: 'FAT',
    93615: 'FAT',
    93616: 'FAT',
    93618: 'FAT',
    93619: 'FAT',
    93620: 'SFO',
    93621: 'FAT',
    93622: 'SFO',
    93623: 'SMF',
    93624: 'FAT',
    93625: 'FAT',
    93626: 'FAT',
    93627: 'FAT',
    93628: 'FAT',
    93630: 'FAT',
    93631: 'FAT',
    93633: 'FAT',
    93634: 'FAT',
    93635: 'SFO',
    93636: 'FAT',
    93637: 'FAT',
    93638: 'FAT',
    93639: 'FAT',
    93640: 'SBP',
    93641: 'FAT',
    93642: 'FAT',
    93643: 'FAT',
    93644: 'SMF',
    93645: 'FAT',
    93646: 'FAT',
    93647: 'FAT',
    93648: 'FAT',
    93649: 'FAT',
    93650: 'FAT',
    93651: 'FAT',
    93652: 'FAT',
    93653: 'FAT',
    93654: 'FAT',
    93656: 'FAT',
    93657: 'FAT',
    93660: 'FAT',
    93661: 'FAT',
    93662: 'FAT',
    93664: 'FAT',
    93665: 'FAT',
    93666: 'FAT',
    93667: 'FAT',
    93668: 'FAT',
    93669: 'FAT',
    93670: 'FAT',
    93673: 'FAT',
    93675: 'FAT',
    93701: 'FAT',
    93702: 'FAT',
    93703: 'FAT',
    93704: 'FAT',
    93705: 'FAT',
    93706: 'FAT',
    93707: 'FAT',
    93708: 'FAT',
    93709: 'FAT',
    93710: 'FAT',
    93711: 'FAT',
    93712: 'FAT',
    93714: 'FAT',
    93715: 'FAT',
    93716: 'FAT',
    93717: 'FAT',
    93718: 'FAT',
    93720: 'FAT',
    93721: 'FAT',
    93722: 'FAT',
    93723: 'FAT',
    93724: 'FAT',
    93725: 'FAT',
    93726: 'FAT',
    93727: 'FAT',
    93728: 'FAT',
    93729: 'FAT',
    93730: 'FAT',
    93737: 'FAT',
    93740: 'FAT',
    93741: 'FAT',
    93744: 'FAT',
    93745: 'FAT',
    93747: 'FAT',
    93750: 'FAT',
    93755: 'FAT',
    93760: 'FAT',
    93761: 'FAT',
    93764: 'FAT',
    93765: 'FAT',
    93771: 'FAT',
    93772: 'FAT',
    93773: 'FAT',
    93774: 'FAT',
    93775: 'FAT',
    93776: 'FAT',
    93777: 'FAT',
    93778: 'FAT',
    93779: 'FAT',
    93780: 'FAT',
    93784: 'FAT',
    93786: 'FAT',
    93790: 'FAT',
    93791: 'FAT',
    93792: 'FAT',
    93793: 'FAT',
    93794: 'FAT',
    93844: 'FAT',
    93888: 'SFO',
    93901: 'SFO',
    93902: 'SFO',
    93905: 'SFO',
    93906: 'SFO',
    93907: 'SFO',
    93908: 'SFO',
    93912: 'SFO',
    93915: 'SBP',
    93920: 'SBP',
    93921: 'SFO',
    93922: 'SFO',
    93923: 'SBP',
    93924: 'SBP',
    93925: 'SBP',
    93926: 'SBP',
    93927: 'SBP',
    93928: 'SBP',
    93930: 'SBP',
    93932: 'SBP',
    93933: 'SFO',
    93940: 'SFO',
    93942: 'SFO',
    93943: 'SFO',
    93944: 'SFO',
    93950: 'SFO',
    93953: 'SBP',
    93954: 'SFO',
    93955: 'SBP',
    93960: 'SBP',
    93962: 'SFO',
    94002: 'SFO',
    94005: 'SFO',
    94010: 'SFO',
    94011: 'SFO',
    94013: 'SFO',
    94014: 'SFO',
    94015: 'SFO',
    94016: 'SFO',
    94017: 'SFO',
    94018: 'SFO',
    94019: 'SFO',
    94020: 'SFO',
    94021: 'SFO',
    94022: 'SFO',
    94023: 'SFO',
    94024: 'SFO',
    94025: 'SFO',
    94026: 'SFO',
    94027: 'SFO',
    94028: 'SFO',
    94030: 'SFO',
    94035: 'SFO',
    94037: 'SFO',
    94038: 'SFO',
    94039: 'SFO',
    94040: 'SFO',
    94041: 'SFO',
    94042: 'SFO',
    94043: 'SFO',
    94044: 'SFO',
    94060: 'SFO',
    94061: 'SFO',
    94062: 'SFO',
    94063: 'SFO',
    94064: 'SFO',
    94065: 'SFO',
    94066: 'SFO',
    94070: 'SFO',
    94074: 'SFO',
    94080: 'SFO',
    94083: 'SFO',
    94085: 'SFO',
    94086: 'SFO',
    94087: 'SFO',
    94088: 'SFO',
    94089: 'SFO',
    94101: 'SFO',
    94102: 'SFO',
    94103: 'SFO',
    94104: 'SFO',
    94105: 'SFO',
    94106: 'SFO',
    94107: 'SFO',
    94108: 'SFO',
    94109: 'SFO',
    94110: 'SFO',
    94111: 'SFO',
    94112: 'SFO',
    94114: 'SFO',
    94115: 'SFO',
    94116: 'SFO',
    94117: 'SFO',
    94118: 'SFO',
    94119: 'SFO',
    94120: 'SFO',
    94121: 'SFO',
    94122: 'SFO',
    94123: 'SFO',
    94124: 'SFO',
    94125: 'SFO',
    94126: 'SFO',
    94127: 'SFO',
    94128: 'SFO',
    94129: 'SFO',
    94130: 'SFO',
    94131: 'SFO',
    94132: 'SFO',
    94133: 'SFO',
    94134: 'SFO',
    94135: 'SFO',
    94136: 'SFO',
    94137: 'SFO',
    94138: 'SFO',
    94139: 'SFO',
    94140: 'SFO',
    94141: 'SFO',
    94142: 'SFO',
    94143: 'SFO',
    94144: 'SFO',
    94145: 'SFO',
    94146: 'SFO',
    94147: 'SFO',
    94150: 'SFO',
    94151: 'SFO',
    94152: 'SFO',
    94153: 'SFO',
    94154: 'SFO',
    94155: 'SFO',
    94156: 'SFO',
    94158: 'SFO',
    94159: 'SFO',
    94160: 'SFO',
    94161: 'SFO',
    94162: 'SFO',
    94163: 'SFO',
    94164: 'SFO',
    94171: 'SFO',
    94172: 'SFO',
    94175: 'SFO',
    94177: 'SFO',
    94188: 'SFO',
    94199: 'SMF',
    94203: 'SMF',
    94204: 'SMF',
    94205: 'SMF',
    94206: 'SMF',
    94207: 'SMF',
    94208: 'SMF',
    94209: 'SMF',
    94211: 'SMF',
    94229: 'SMF',
    94230: 'SMF',
    94232: 'SMF',
    94234: 'SMF',
    94235: 'SMF',
    94236: 'SMF',
    94237: 'SMF',
    94239: 'SMF',
    94240: 'SMF',
    94244: 'SMF',
    94245: 'SFO',
    94246: 'SMF',
    94247: 'SMF',
    94248: 'SMF',
    94249: 'SMF',
    94250: 'SMF',
    94252: 'SMF',
    94254: 'SMF',
    94256: 'SMF',
    94257: 'SMF',
    94258: 'SMF',
    94259: 'SMF',
    94261: 'SMF',
    94262: 'SMF',
    94263: 'SMF',
    94267: 'SMF',
    94268: 'SMF',
    94269: 'SMF',
    94271: 'SMF',
    94273: 'SMF',
    94274: 'SMF',
    94277: 'SMF',
    94278: 'SMF',
    94279: 'SMF',
    94280: 'SMF',
    94282: 'SMF',
    94283: 'SMF',
    94284: 'SMF',
    94285: 'SMF',
    94286: 'SMF',
    94287: 'SMF',
    94288: 'SMF',
    94289: 'SMF',
    94290: 'SMF',
    94291: 'SMF',
    94293: 'SMF',
    94294: 'SMF',
    94295: 'SMF',
    94296: 'SMF',
    94297: 'SMF',
    94298: 'SMF',
    94299: 'SFO',
    94301: 'SFO',
    94302: 'SFO',
    94303: 'SFO',
    94304: 'SFO',
    94305: 'SFO',
    94306: 'SFO',
    94309: 'SFO',
    94401: 'SFO',
    94402: 'SFO',
    94403: 'SFO',
    94404: 'SFO',
    94497: 'SFO',
    94501: 'SFO',
    94502: 'SFO',
    94503: 'SFO',
    94505: 'SFO',
    94506: 'SFO',
    94507: 'SFO',
    94508: 'SFO',
    94509: 'SFO',
    94510: 'SFO',
    94511: 'SFO',
    94512: 'SFO',
    94513: 'SFO',
    94514: 'SFO',
    94515: 'SFO',
    94516: 'SFO',
    94517: 'SFO',
    94518: 'SFO',
    94519: 'SFO',
    94520: 'SFO',
    94521: 'SFO',
    94522: 'SFO',
    94523: 'SFO',
    94524: 'SFO',
    94525: 'SFO',
    94526: 'SFO',
    94527: 'SFO',
    94528: 'SFO',
    94529: 'SFO',
    94530: 'SFO',
    94531: 'SFO',
    94533: 'SFO',
    94534: 'SFO',
    94535: 'SFO',
    94536: 'SFO',
    94537: 'SFO',
    94538: 'SFO',
    94539: 'SFO',
    94540: 'SFO',
    94541: 'SFO',
    94542: 'SFO',
    94543: 'SFO',
    94544: 'SFO',
    94545: 'SFO',
    94546: 'SFO',
    94547: 'SFO',
    94548: 'SFO',
    94549: 'SFO',
    94550: 'SFO',
    94551: 'SFO',
    94552: 'SFO',
    94553: 'SFO',
    94555: 'SFO',
    94556: 'SFO',
    94557: 'SFO',
    94558: 'SFO',
    94559: 'SFO',
    94560: 'SFO',
    94561: 'SFO',
    94562: 'SFO',
    94563: 'SFO',
    94564: 'SFO',
    94565: 'SFO',
    94566: 'SFO',
    94567: 'SFO',
    94568: 'SFO',
    94569: 'SFO',
    94570: 'SFO',
    94571: 'SFO',
    94572: 'SFO',
    94573: 'SFO',
    94574: 'SFO',
    94575: 'SFO',
    94576: 'SFO',
    94577: 'SFO',
    94578: 'SFO',
    94579: 'SFO',
    94580: 'SFO',
    94581: 'SFO',
    94582: 'SFO',
    94583: 'SFO',
    94585: 'SFO',
    94586: 'SFO',
    94587: 'SFO',
    94588: 'SFO',
    94589: 'SFO',
    94590: 'SFO',
    94591: 'SFO',
    94592: 'SFO',
    94595: 'SFO',
    94596: 'SFO',
    94597: 'SFO',
    94598: 'SFO',
    94599: 'SFO',
    94601: 'SFO',
    94602: 'SFO',
    94603: 'SFO',
    94604: 'SFO',
    94605: 'SFO',
    94606: 'SFO',
    94607: 'SFO',
    94608: 'SFO',
    94609: 'SFO',
    94610: 'SFO',
    94611: 'SFO',
    94612: 'SFO',
    94613: 'SFO',
    94614: 'SFO',
    94615: 'SFO',
    94617: 'SFO',
    94618: 'SFO',
    94619: 'SFO',
    94620: 'SFO',
    94621: 'SFO',
    94622: 'SFO',
    94623: 'SFO',
    94624: 'SFO',
    94625: 'SFO',
    94649: 'SFO',
    94659: 'SFO',
    94660: 'SFO',
    94661: 'SFO',
    94662: 'SFO',
    94666: 'SFO',
    94701: 'SFO',
    94702: 'SFO',
    94703: 'SFO',
    94704: 'SFO',
    94705: 'SFO',
    94706: 'SFO',
    94707: 'SFO',
    94708: 'SFO',
    94709: 'SFO',
    94710: 'SFO',
    94712: 'SFO',
    94720: 'SFO',
    94801: 'SFO',
    94802: 'SFO',
    94803: 'SFO',
    94804: 'SFO',
    94805: 'SFO',
    94806: 'SFO',
    94807: 'SFO',
    94808: 'SFO',
    94820: 'SFO',
    94850: 'SFO',
    94901: 'SFO',
    94903: 'SFO',
    94904: 'SFO',
    94912: 'SFO',
    94913: 'SFO',
    94914: 'SFO',
    94915: 'SFO',
    94920: 'SFO',
    94922: 'SFO',
    94923: 'SFO',
    94924: 'SFO',
    94925: 'SFO',
    94926: 'SFO',
    94927: 'SFO',
    94928: 'SFO',
    94929: 'SFO',
    94930: 'SFO',
    94931: 'SFO',
    94933: 'SFO',
    94937: 'SFO',
    94938: 'SFO',
    94939: 'SFO',
    94940: 'SFO',
    94941: 'SFO',
    94942: 'SFO',
    94945: 'SFO',
    94946: 'SFO',
    94947: 'SFO',
    94948: 'SFO',
    94949: 'SFO',
    94950: 'SFO',
    94951: 'SFO',
    94952: 'SFO',
    94953: 'SFO',
    94954: 'SFO',
    94955: 'SFO',
    94956: 'SFO',
    94957: 'SFO',
    94960: 'SFO',
    94963: 'SFO',
    94964: 'SFO',
    94965: 'SFO',
    94966: 'SFO',
    94970: 'SFO',
    94971: 'SFO',
    94972: 'SFO',
    94973: 'SFO',
    94974: 'SFO',
    94975: 'SFO',
    94976: 'SFO',
    94977: 'SFO',
    94978: 'SFO',
    94979: 'SFO',
    94998: 'SFO',
    94999: 'SFO',
    95001: 'SFO',
    95002: 'SFO',
    95003: 'SFO',
    95004: 'SFO',
    95005: 'SFO',
    95006: 'SFO',
    95007: 'SFO',
    95008: 'SFO',
    95009: 'SFO',
    95010: 'SFO',
    95011: 'SFO',
    95012: 'SFO',
    95013: 'SFO',
    95014: 'SFO',
    95015: 'SFO',
    95017: 'SFO',
    95018: 'SFO',
    95019: 'SFO',
    95020: 'SFO',
    95021: 'SFO',
    95023: 'SFO',
    95024: 'SFO',
    95026: 'SFO',
    95030: 'SFO',
    95031: 'SFO',
    95032: 'SFO',
    95033: 'SFO',
    95035: 'SFO',
    95036: 'SFO',
    95037: 'SFO',
    95038: 'SFO',
    95039: 'SFO',
    95041: 'SFO',
    95042: 'SFO',
    95043: 'SBP',
    95044: 'SFO',
    95045: 'SFO',
    95046: 'SFO',
    95050: 'SFO',
    95051: 'SFO',
    95052: 'SFO',
    95053: 'SFO',
    95054: 'SFO',
    95055: 'SFO',
    95056: 'SFO',
    95060: 'SFO',
    95061: 'SFO',
    95062: 'SFO',
    95063: 'SFO',
    95064: 'SFO',
    95065: 'SFO',
    95066: 'SFO',
    95067: 'SFO',
    95070: 'SFO',
    95071: 'SFO',
    95073: 'SFO',
    95075: 'SFO',
    95076: 'SFO',
    95077: 'SFO',
    95101: 'SFO',
    95103: 'SFO',
    95106: 'SFO',
    95108: 'SFO',
    95109: 'SFO',
    95110: 'SFO',
    95111: 'SFO',
    95112: 'SFO',
    95113: 'SFO',
    95115: 'SFO',
    95116: 'SFO',
    95117: 'SFO',
    95118: 'SFO',
    95119: 'SFO',
    95120: 'SFO',
    95121: 'SFO',
    95122: 'SFO',
    95123: 'SFO',
    95124: 'SFO',
    95125: 'SFO',
    95126: 'SFO',
    95127: 'SFO',
    95128: 'SFO',
    95129: 'SFO',
    95130: 'SFO',
    95131: 'SFO',
    95132: 'SFO',
    95133: 'SFO',
    95134: 'SFO',
    95135: 'SFO',
    95136: 'SFO',
    95138: 'SFO',
    95139: 'SFO',
    95140: 'SFO',
    95141: 'SFO',
    95148: 'SFO',
    95150: 'SFO',
    95151: 'SFO',
    95152: 'SFO',
    95153: 'SFO',
    95154: 'SFO',
    95155: 'SFO',
    95156: 'SFO',
    95157: 'SFO',
    95158: 'SFO',
    95159: 'SFO',
    95160: 'SFO',
    95161: 'SFO',
    95164: 'SFO',
    95170: 'SFO',
    95172: 'SFO',
    95173: 'SFO',
    95190: 'SFO',
    95191: 'SFO',
    95192: 'SFO',
    95193: 'SFO',
    95194: 'SFO',
    95196: 'SFO',
    95201: 'SFO',
    95202: 'SFO',
    95203: 'SFO',
    95204: 'SFO',
    95205: 'SFO',
    95206: 'SFO',
    95207: 'SFO',
    95208: 'SFO',
    95209: 'SFO',
    95210: 'SFO',
    95211: 'SFO',
    95212: 'SFO',
    95213: 'SFO',
    95214: 'SFO',
    95215: 'SFO',
    95219: 'SMF',
    95220: 'SMF',
    95221: 'SMF',
    95222: 'SMF',
    95223: 'SMF',
    95224: 'SMF',
    95225: 'SMF',
    95226: 'SMF',
    95227: 'SMF',
    95228: 'SMF',
    95229: 'SMF',
    95230: 'SMF',
    95231: 'SFO',
    95232: 'SMF',
    95233: 'SMF',
    95234: 'SFO',
    95236: 'SMF',
    95237: 'SMF',
    95240: 'SMF',
    95241: 'SMF',
    95242: 'SMF',
    95245: 'SMF',
    95246: 'SMF',
    95247: 'SMF',
    95248: 'SMF',
    95249: 'SMF',
    95250: 'SMF',
    95251: 'SMF',
    95252: 'SMF',
    95253: 'SMF',
    95254: 'SMF',
    95255: 'SMF',
    95257: 'SMF',
    95258: 'SMF',
    95267: 'SFO',
    95269: 'SFO',
    95296: 'SFO',
    95297: 'SBP',
    95301: 'SFO',
    95303: 'SFO',
    95304: 'SFO',
    95305: 'SMF',
    95306: 'SFO',
    95307: 'SFO',
    95309: 'SMF',
    95310: 'SMF',
    95311: 'SMF',
    95312: 'SFO',
    95313: 'SFO',
    95314: 'SMF',
    95315: 'SFO',
    95316: 'SFO',
    95317: 'SFO',
    95318: 'SMF',
    95319: 'SFO',
    95320: 'SFO',
    95321: 'SMF',
    95322: 'SFO',
    95323: 'SFO',
    95324: 'SFO',
    95325: 'SFO',
    95326: 'SFO',
    95327: 'SMF',
    95328: 'SFO',
    95329: 'SMF',
    95330: 'SFO',
    95333: 'SFO',
    95334: 'SFO',
    95335: 'SMF',
    95336: 'SFO',
    95337: 'SFO',
    95338: 'SMF',
    95340: 'SFO',
    95341: 'SFO',
    95343: 'SFO',
    95344: 'SFO',
    95345: 'SMF',
    95346: 'SMF',
    95347: 'FAT',
    95348: 'SFO',
    95350: 'SFO',
    95351: 'SFO',
    95352: 'SFO',
    95353: 'SFO',
    95354: 'SFO',
    95355: 'SFO',
    95356: 'SFO',
    95357: 'SFO',
    95358: 'SFO',
    95360: 'SFO',
    95361: 'SFO',
    95363: 'SFO',
    95364: 'SMF',
    95365: 'SFO',
    95366: 'SFO',
    95367: 'SFO',
    95368: 'SFO',
    95369: 'SFO',
    95370: 'SMF',
    95372: 'SMF',
    95373: 'SMF',
    95374: 'SFO',
    95375: 'SFO',
    95376: 'SFO',
    95377: 'SFO',
    95378: 'SFO',
    95379: 'SMF',
    95380: 'SFO',
    95381: 'SFO',
    95382: 'SFO',
    95383: 'SMF',
    95385: 'SFO',
    95386: 'SFO',
    95387: 'SFO',
    95388: 'SFO',
    95389: 'SMF',
    95391: 'SFO',
    95397: 'SFO',
    95401: 'SFO',
    95402: 'SFO',
    95403: 'SFO',
    95404: 'SFO',
    95405: 'SFO',
    95406: 'SFO',
    95407: 'SFO',
    95409: 'SFO',
    95410: 'RDD',
    95412: 'SFO',
    95415: 'RDD',
    95416: 'SFO',
    95417: 'RDD',
    95418: 'RDD',
    95419: 'SFO',
    95420: 'RDD',
    95421: 'SFO',
    95422: 'SMF',
    95423: 'RDD',
    95424: 'SMF',
    95425: 'SFO',
    95426: 'SMF',
    95427: 'RDD',
    95428: 'RDD',
    95429: 'RDD',
    95430: 'SFO',
    95431: 'SFO',
    95432: 'RDD',
    95433: 'SFO',
    95435: 'RDD',
    95436: 'SFO',
    95437: 'RDD',
    95439: 'SFO',
    95441: 'SFO',
    95442: 'SFO',
    95443: 'SMF',
    95444: 'SFO',
    95445: 'RDD',
    95446: 'SFO',
    95448: 'SFO',
    95449: 'RDD',
    95450: 'SFO',
    95451: 'SMF',
    95452: 'SFO',
    95453: 'RDD',
    95454: 'RDD',
    95456: 'RDD',
    95457: 'SMF',
    95458: 'SMF',
    95459: 'RDD',
    95460: 'RDD',
    95461: 'SMF',
    95462: 'SFO',
    95463: 'RDD',
    95464: 'SMF',
    95465: 'SFO',
    95466: 'RDD',
    95467: 'SMF',
    95468: 'RDD',
    95469: 'RDD',
    95470: 'RDD',
    95471: 'SFO',
    95472: 'SFO',
    95473: 'SFO',
    95476: 'SFO',
    95480: 'SFO',
    95481: 'RDD',
    95482: 'RDD',
    95485: 'RDD',
    95486: 'SFO',
    95487: 'SFO',
    95488: 'RDD',
    95490: 'RDD',
    95492: 'SFO',
    95493: 'RDD',
    95494: 'RDD',
    95497: 'SFO',
    95501: 'RDD',
    95502: 'RDD',
    95503: 'RDD',
    95511: 'RDD',
    95514: 'RDD',
    95518: 'SFO',
    95519: 'SFO',
    95521: 'RDD',
    95524: 'RDD',
    95525: 'MFR',
    95526: 'RDD',
    95527: 'MFR',
    95528: 'RDD',
    95531: 'MFR',
    95532: 'MFR',
    95534: 'RDD',
    95536: 'RDD',
    95537: 'RDD',
    95538: 'MFR',
    95540: 'RDD',
    95542: 'RDD',
    95543: 'MFR',
    95545: 'RDD',
    95546: 'MFR',
    95547: 'RDD',
    95548: 'MFR',
    95549: 'RDD',
    95550: 'RDD',
    95551: 'RDD',
    95552: 'RDD',
    95553: 'RDD',
    95554: 'RDD',
    95555: 'MFR',
    95556: 'MFR',
    95558: 'RDD',
    95559: 'RDD',
    95560: 'RDD',
    95562: 'RDD',
    95563: 'RDD',
    95564: 'RDD',
    95565: 'RDD',
    95567: 'MFR',
    95568: 'MFR',
    95569: 'RDD',
    95570: 'MFR',
    95571: 'RDD',
    95573: 'MFR',
    95585: 'RDD',
    95587: 'RDD',
    95589: 'RDD',
    95595: 'RDD',
    95601: 'SMF',
    95602: 'SMF',
    95603: 'SMF',
    95604: 'SMF',
    95605: 'SMF',
    95606: 'SMF',
    95607: 'SMF',
    95608: 'SMF',
    95609: 'SMF',
    95610: 'SMF',
    95611: 'SMF',
    95612: 'SMF',
    95613: 'SMF',
    95614: 'SMF',
    95615: 'SMF',
    95616: 'SMF',
    95617: 'SMF',
    95618: 'SMF',
    95619: 'SMF',
    95620: 'SMF',
    95621: 'SMF',
    95623: 'SMF',
    95624: 'SMF',
    95625: 'SMF',
    95626: 'SMF',
    95627: 'SMF',
    95628: 'SMF',
    95629: 'SMF',
    95630: 'SMF',
    95631: 'SMF',
    95632: 'SMF',
    95633: 'SMF',
    95634: 'SMF',
    95635: 'SMF',
    95636: 'SMF',
    95637: 'SMF',
    95638: 'SMF',
    95639: 'SMF',
    95640: 'SMF',
    95641: 'SMF',
    95642: 'SMF',
    95644: 'SMF',
    95645: 'SMF',
    95646: 'SMF',
    95648: 'SMF',
    95650: 'SMF',
    95651: 'SMF',
    95652: 'SMF',
    95653: 'SMF',
    95654: 'SMF',
    95655: 'SMF',
    95656: 'SMF',
    95658: 'SMF',
    95659: 'SMF',
    95660: 'SMF',
    95661: 'SMF',
    95662: 'SMF',
    95663: 'SMF',
    95664: 'SMF',
    95665: 'SMF',
    95666: 'SMF',
    95667: 'SMF',
    95668: 'SMF',
    95669: 'SMF',
    95670: 'SMF',
    95671: 'SMF',
    95672: 'SMF',
    95673: 'SMF',
    95674: 'SMF',
    95675: 'SMF',
    95676: 'SMF',
    95677: 'SMF',
    95678: 'SMF',
    95679: 'SMF',
    95680: 'SMF',
    95681: 'SMF',
    95682: 'SMF',
    95683: 'SMF',
    95684: 'SMF',
    95685: 'SMF',
    95686: 'SMF',
    95687: 'SMF',
    95688: 'SMF',
    95689: 'SMF',
    95690: 'SMF',
    95691: 'SMF',
    95692: 'SMF',
    95693: 'SMF',
    95694: 'SMF',
    95695: 'SMF',
    95696: 'SFO',
    95697: 'SMF',
    95698: 'SMF',
    95699: 'SMF',
    95701: 'SMF',
    95703: 'SMF',
    95709: 'SMF',
    95712: 'SMF',
    95713: 'SMF',
    95714: 'SMF',
    95715: 'MFR',
    95717: 'SMF',
    95720: 'SMF',
    95721: 'SMF',
    95722: 'SMF',
    95724: 'MFR',
    95726: 'SMF',
    95728: 'MFR',
    95735: 'SMF',
    95736: 'SMF',
    95741: 'SMF',
    95742: 'SMF',
    95746: 'SMF',
    95747: 'SMF',
    95757: 'SMF',
    95758: 'SMF',
    95759: 'SMF',
    95762: 'SMF',
    95763: 'SMF',
    95765: 'SMF',
    95776: 'SMF',
    95798: 'SMF',
    95799: 'SMF',
    95811: 'SMF',
    95812: 'SMF',
    95813: 'SMF',
    95814: 'SMF',
    95815: 'SMF',
    95816: 'SMF',
    95817: 'SMF',
    95818: 'SMF',
    95819: 'SMF',
    95820: 'SMF',
    95821: 'SMF',
    95822: 'SMF',
    95823: 'SMF',
    95824: 'SMF',
    95825: 'SMF',
    95826: 'SMF',
    95827: 'SMF',
    95828: 'SMF',
    95829: 'SMF',
    95830: 'SMF',
    95831: 'SMF',
    95832: 'SMF',
    95833: 'SMF',
    95834: 'SMF',
    95835: 'SMF',
    95836: 'SMF',
    95837: 'SMF',
    95838: 'SMF',
    95840: 'SMF',
    95841: 'SMF',
    95842: 'SMF',
    95843: 'SMF',
    95851: 'SMF',
    95852: 'SMF',
    95853: 'SMF',
    95860: 'SMF',
    95864: 'SMF',
    95865: 'SMF',
    95866: 'SMF',
    95867: 'SMF',
    95887: 'SMF',
    95894: 'SMF',
    95899: 'SMF',
    95901: 'SMF',
    95903: 'SMF',
    95910: 'MFR',
    95912: 'SMF',
    95913: 'MFR',
    95914: 'MFR',
    95915: 'MFR',
    95916: 'MFR',
    95917: 'MFR',
    95918: 'SMF',
    95919: 'MFR',
    95920: 'MFR',
    95922: 'MFR',
    95923: 'MFR',
    95924: 'SMF',
    95925: 'MFR',
    95926: 'MFR',
    95927: 'MFR',
    95928: 'MFR',
    95929: 'MFR',
    95930: 'MFR',
    95932: 'SMF',
    95934: 'MFR',
    95935: 'MFR',
    95936: 'MFR',
    95937: 'SMF',
    95938: 'MFR',
    95939: 'RDD',
    95940: 'MFR',
    95941: 'MFR',
    95942: 'MFR',
    95943: 'MFR',
    95944: 'MFR',
    95945: 'SMF',
    95946: 'SMF',
    95947: 'MFR',
    95948: 'SMF',
    95949: 'SMF',
    95950: 'SMF',
    95951: 'MFR',
    95953: 'SMF',
    95954: 'MFR',
    95955: 'MFR',
    95956: 'MFR',
    95957: 'SMF',
    95958: 'MFR',
    95959: 'MFR',
    95960: 'SMF',
    95961: 'SMF',
    95962: 'MFR',
    95963: 'MFR',
    95965: 'MFR',
    95966: 'MFR',
    95967: 'MFR',
    95968: 'MFR',
    95969: 'MFR',
    95970: 'MFR',
    95971: 'MFR',
    95972: 'MFR',
    95973: 'MFR',
    95974: 'MFR',
    95975: 'SMF',
    95976: 'MFR',
    95977: 'SMF',
    95978: 'MFR',
    95979: 'SMF',
    95980: 'MFR',
    95981: 'MFR',
    95982: 'SMF',
    95983: 'MFR',
    95984: 'MFR',
    95986: 'MFR',
    95987: 'SMF',
    95988: 'MFR',
    95991: 'SMF',
    95992: 'SMF',
    95993: 'SMF',
    96001: 'MFR',
    96002: 'MFR',
    96003: 'MFR',
    96006: 'MFR',
    96007: 'MFR',
    96008: 'MFR',
    96009: 'MFR',
    96010: 'MFR',
    96011: 'MFR',
    96013: 'MFR',
    96014: 'MFR',
    96015: 'MFR',
    96016: 'MFR',
    96017: 'MFR',
    96019: 'MFR',
    96020: 'MFR',
    96021: 'MFR',
    96022: 'MFR',
    96023: 'MFR',
    96024: 'MFR',
    96025: 'MFR',
    96027: 'MFR',
    96028: 'MFR',
    96029: 'MFR',
    96031: 'MFR',
    96032: 'MFR',
    96033: 'MFR',
    96034: 'MFR',
    96035: 'MFR',
    96037: 'MFR',
    96038: 'MFR',
    96039: 'MFR',
    96040: 'MFR',
    96041: 'MFR',
    96044: 'MFR',
    96046: 'RDD',
    96047: 'MFR',
    96048: 'MFR',
    96049: 'MFR',
    96050: 'MFR',
    96051: 'MFR',
    96052: 'MFR',
    96054: 'MFR',
    96055: 'MFR',
    96056: 'MFR',
    96057: 'MFR',
    96058: 'MFR',
    96059: 'MFR',
    96061: 'MFR',
    96062: 'MFR',
    96063: 'MFR',
    96064: 'MFR',
    96065: 'MFR',
    96067: 'MFR',
    96068: 'MFR',
    96069: 'MFR',
    96070: 'MFR',
    96071: 'MFR',
    96073: 'MFR',
    96074: 'MFR',
    96075: 'MFR',
    96076: 'MFR',
    96078: 'MFR',
    96079: 'MFR',
    96080: 'MFR',
    96084: 'MFR',
    96085: 'MFR',
    96086: 'MFR',
    96087: 'MFR',
    96088: 'MFR',
    96089: 'MFR',
    96090: 'MFR',
    96091: 'MFR',
    96092: 'MFR',
    96093: 'MFR',
    96094: 'MFR',
    96095: 'MFR',
    96096: 'MFR',
    96097: 'MFR',
    96099: 'MFR',
    96101: 'MFR',
    96103: 'MFR',
    96104: 'MFR',
    96105: 'RNO',
    96106: 'MFR',
    96107: 'SMF',
    96108: 'MFR',
    96109: 'RNO',
    96110: 'MFR',
    96111: 'RNO',
    96112: 'MFR',
    96113: 'RNO',
    96114: 'MFR',
    96115: 'MFR',
    96116: 'MFR',
    96117: 'MFR',
    96118: 'RNO',
    96119: 'MFR',
    96120: 'SMF',
    96121: 'MFR',
    96122: 'MFR',
    96123: 'MFR',
    96124: 'MFR',
    96125: 'MFR',
    96126: 'RNO',
    96127: 'MFR',
    96128: 'MFR',
    96129: 'MFR',
    96130: 'MFR',
    96132: 'MFR',
    96133: 'RNO',
    96134: 'MFR',
    96135: 'RNO',
    96136: 'MFR',
    96137: 'MFR',
    96140: 'RNO',
    96141: 'RNO',
    96142: 'RNO',
    96143: 'RNO',
    96145: 'RNO',
    96146: 'RNO',
    96148: 'RNO',
    96150: 'RNO',
    96151: 'RNO',
    96152: 'RNO',
    96154: 'RNO',
    96155: 'RNO',
    96156: 'RNO',
    96157: 'RNO',
    96158: 'RNO',
    96160: 'RNO',
    96161: 'RNO',
    96162: 'RNO',
    97001: 'RDM',
    97002: 'PDX',
    97003: 'PDX',
    97004: 'PDX',
    97005: 'PDX',
    97006: 'PDX',
    97007: 'PDX',
    97008: 'PDX',
    97009: 'PDX',
    97010: 'PDX',
    97011: 'PDX',
    97013: 'PDX',
    97014: 'PDX',
    97015: 'PDX',
    97016: 'PDX',
    97017: 'PDX',
    97018: 'PDX',
    97019: 'PDX',
    97020: 'PDX',
    97021: 'PDX',
    97022: 'PDX',
    97023: 'PDX',
    97024: 'PDX',
    97026: 'PDX',
    97027: 'PDX',
    97028: 'PDX',
    97029: 'PDX',
    97030: 'PDX',
    97031: 'PDX',
    97032: 'PDX',
    97033: 'PDX',
    97034: 'PDX',
    97035: 'PDX',
    97036: 'PDX',
    97037: 'RDM',
    97038: 'PDX',
    97039: 'PDX',
    97040: 'PDX',
    97041: 'PDX',
    97042: 'PDX',
    97044: 'PDX',
    97045: 'PDX',
    97048: 'PDX',
    97049: 'PDX',
    97050: 'PDX',
    97051: 'PDX',
    97053: 'PDX',
    97054: 'PDX',
    97055: 'PDX',
    97056: 'PDX',
    97057: 'PDX',
    97058: 'PDX',
    97060: 'PDX',
    97062: 'PDX',
    97063: 'PDX',
    97064: 'PDX',
    97065: 'PDX',
    97067: 'PDX',
    97068: 'PDX',
    97070: 'PDX',
    97071: 'PDX',
    97075: 'PDX',
    97076: 'PDX',
    97077: 'PDX',
    97078: 'PDX',
    97079: 'PDX',
    97080: 'PDX',
    97086: 'PDX',
    97089: 'PDX',
    97101: 'PDX',
    97102: 'PDX',
    97103: 'PDX',
    97106: 'PDX',
    97107: 'PDX',
    97108: 'PDX',
    97109: 'PDX',
    97110: 'PDX',
    97111: 'PDX',
    97112: 'PDX',
    97113: 'PDX',
    97114: 'PDX',
    97115: 'PDX',
    97116: 'PDX',
    97117: 'PDX',
    97118: 'PDX',
    97119: 'PDX',
    97121: 'PDX',
    97122: 'PDX',
    97123: 'PDX',
    97124: 'PDX',
    97125: 'PDX',
    97127: 'PDX',
    97128: 'PDX',
    97129: 'PDX',
    97130: 'PDX',
    97131: 'PDX',
    97132: 'PDX',
    97133: 'PDX',
    97134: 'PDX',
    97135: 'PDX',
    97136: 'PDX',
    97137: 'PDX',
    97138: 'PDX',
    97140: 'PDX',
    97141: 'PDX',
    97143: 'PDX',
    97144: 'PDX',
    97145: 'PDX',
    97146: 'PDX',
    97147: 'PDX',
    97148: 'PDX',
    97149: 'PDX',
    97201: 'PDX',
    97202: 'PDX',
    97203: 'PDX',
    97204: 'PDX',
    97205: 'PDX',
    97206: 'PDX',
    97207: 'PDX',
    97208: 'PDX',
    97209: 'PDX',
    97210: 'PDX',
    97211: 'PDX',
    97212: 'PDX',
    97213: 'PDX',
    97214: 'PDX',
    97215: 'PDX',
    97216: 'PDX',
    97217: 'PDX',
    97218: 'PDX',
    97219: 'PDX',
    97220: 'PDX',
    97221: 'PDX',
    97222: 'PDX',
    97223: 'PDX',
    97224: 'PDX',
    97225: 'PDX',
    97227: 'PDX',
    97228: 'PDX',
    97229: 'PDX',
    97230: 'PDX',
    97231: 'PDX',
    97232: 'PDX',
    97233: 'PDX',
    97236: 'PDX',
    97238: 'PDX',
    97239: 'PDX',
    97240: 'PDX',
    97242: 'PDX',
    97250: 'PDX',
    97251: 'PDX',
    97252: 'PDX',
    97253: 'PDX',
    97254: 'PDX',
    97255: 'PDX',
    97256: 'PDX',
    97258: 'PDX',
    97259: 'PDX',
    97266: 'PDX',
    97267: 'PDX',
    97268: 'PDX',
    97269: 'PDX',
    97271: 'PDX',
    97272: 'PDX',
    97280: 'PDX',
    97281: 'PDX',
    97282: 'PDX',
    97283: 'PDX',
    97286: 'PDX',
    97290: 'PDX',
    97291: 'PDX',
    97292: 'PDX',
    97293: 'PDX',
    97294: 'PDX',
    97296: 'PDX',
    97298: 'PDX',
    97299: 'PDX',
    97301: 'PDX',
    97302: 'PDX',
    97303: 'PDX',
    97304: 'PDX',
    97305: 'PDX',
    97306: 'PDX',
    97307: 'PDX',
    97308: 'PDX',
    97309: 'PDX',
    97310: 'PDX',
    97311: 'PDX',
    97312: 'PDX',
    97313: 'PDX',
    97314: 'PDX',
    97317: 'PDX',
    97321: 'EUG',
    97322: 'EUG',
    97324: 'EUG',
    97325: 'PDX',
    97326: 'EUG',
    97327: 'EUG',
    97329: 'EUG',
    97330: 'EUG',
    97331: 'EUG',
    97333: 'EUG',
    97335: 'EUG',
    97336: 'EUG',
    97338: 'PDX',
    97339: 'EUG',
    97341: 'PDX',
    97342: 'RDM',
    97343: 'EUG',
    97344: 'PDX',
    97345: 'EUG',
    97346: 'RDM',
    97347: 'PDX',
    97348: 'EUG',
    97350: 'RDM',
    97351: 'PDX',
    97352: 'PDX',
    97355: 'EUG',
    97357: 'PDX',
    97358: 'PDX',
    97360: 'EUG',
    97361: 'PDX',
    97362: 'PDX',
    97364: 'PDX',
    97365: 'EUG',
    97366: 'EUG',
    97367: 'PDX',
    97368: 'PDX',
    97369: 'EUG',
    97370: 'EUG',
    97371: 'PDX',
    97372: 'PDX',
    97373: 'PDX',
    97374: 'EUG',
    97375: 'PDX',
    97376: 'EUG',
    97377: 'EUG',
    97378: 'PDX',
    97380: 'PDX',
    97381: 'PDX',
    97383: 'PDX',
    97384: 'PDX',
    97385: 'PDX',
    97386: 'EUG',
    97388: 'PDX',
    97389: 'EUG',
    97390: 'EUG',
    97391: 'EUG',
    97392: 'PDX',
    97394: 'EUG',
    97396: 'PDX',
    97401: 'EUG',
    97402: 'EUG',
    97403: 'EUG',
    97404: 'EUG',
    97405: 'EUG',
    97406: 'PDX',
    97407: 'EUG',
    97408: 'EUG',
    97409: 'EUG',
    97410: 'PDX',
    97411: 'PDX',
    97412: 'EUG',
    97413: 'EUG',
    97414: 'PDX',
    97415: 'PDX',
    97416: 'PDX',
    97417: 'PDX',
    97419: 'EUG',
    97420: 'EUG',
    97423: 'EUG',
    97424: 'EUG',
    97425: 'EUG',
    97426: 'EUG',
    97427: 'EUG',
    97428: 'EUG',
    97429: 'PDX',
    97430: 'EUG',
    97431: 'EUG',
    97432: 'PDX',
    97434: 'EUG',
    97435: 'EUG',
    97436: 'EUG',
    97437: 'EUG',
    97438: 'EUG',
    97439: 'EUG',
    97440: 'EUG',
    97441: 'EUG',
    97442: 'PDX',
    97443: 'PDX',
    97444: 'PDX',
    97446: 'EUG',
    97447: 'EUG',
    97448: 'EUG',
    97449: 'EUG',
    97450: 'PDX',
    97451: 'EUG',
    97452: 'EUG',
    97453: 'EUG',
    97454: 'EUG',
    97455: 'EUG',
    97456: 'EUG',
    97457: 'PDX',
    97458: 'PDX',
    97459: 'EUG',
    97461: 'EUG',
    97462: 'EUG',
    97463: 'EUG',
    97464: 'PDX',
    97465: 'PDX',
    97466: 'PDX',
    97467: 'EUG',
    97469: 'PDX',
    97470: 'EUG',
    97471: 'EUG',
    97472: 'EUG',
    97473: 'EUG',
    97475: 'EUG',
    97476: 'PDX',
    97477: 'EUG',
    97478: 'EUG',
    97479: 'EUG',
    97480: 'EUG',
    97481: 'PDX',
    97482: 'PDX',
    97484: 'PDX',
    97486: 'EUG',
    97487: 'EUG',
    97488: 'EUG',
    97489: 'EUG',
    97490: 'EUG',
    97491: 'PDX',
    97492: 'EUG',
    97493: 'EUG',
    97494: 'EUG',
    97495: 'EUG',
    97496: 'PDX',
    97497: 'PDX',
    97498: 'EUG',
    97499: 'EUG',
    97501: 'PDX',
    97502: 'PDX',
    97503: 'PDX',
    97504: 'PDX',
    97520: 'PDX',
    97522: 'PDX',
    97523: 'PDX',
    97524: 'PDX',
    97525: 'PDX',
    97526: 'PDX',
    97527: 'PDX',
    97528: 'PDX',
    97530: 'PDX',
    97531: 'PDX',
    97532: 'PDX',
    97533: 'PDX',
    97534: 'PDX',
    97535: 'PDX',
    97536: 'PDX',
    97537: 'PDX',
    97538: 'PDX',
    97539: 'PDX',
    97540: 'PDX',
    97541: 'PDX',
    97543: 'PDX',
    97544: 'PDX',
    97601: 'PDX',
    97602: 'PDX',
    97603: 'PDX',
    97604: 'PDX',
    97620: 'PDX',
    97621: 'PDX',
    97622: 'PDX',
    97623: 'PDX',
    97624: 'PDX',
    97625: 'PDX',
    97626: 'PDX',
    97627: 'PDX',
    97630: 'PDX',
    97632: 'PDX',
    97633: 'PDX',
    97634: 'PDX',
    97635: 'PDX',
    97636: 'PDX',
    97637: 'PDX',
    97638: 'PDX',
    97639: 'PDX',
    97640: 'PDX',
    97641: 'PDX',
    97701: 'RDM',
    97702: 'RDM',
    97703: 'RDM',
    97707: 'RDM',
    97708: 'RDM',
    97709: 'RDM',
    97710: 'BOI',
    97711: 'RDM',
    97712: 'RDM',
    97720: 'BOI',
    97721: 'BOI',
    97722: 'BOI',
    97730: 'RDM',
    97731: 'PDX',
    97732: 'BOI',
    97733: 'EUG',
    97734: 'RDM',
    97735: 'RDM',
    97736: 'BOI',
    97737: 'PDX',
    97738: 'BOI',
    97739: 'RDM',
    97741: 'RDM',
    97750: 'RDM',
    97751: 'RDM',
    97752: 'RDM',
    97753: 'RDM',
    97754: 'RDM',
    97756: 'RDM',
    97758: 'BOI',
    97759: 'RDM',
    97760: 'RDM',
    97761: 'RDM',
    97801: 'YKM',
    97810: 'YKM',
    97812: 'PDX',
    97813: 'YKM',
    97814: 'BOI',
    97817: 'BOI',
    97818: 'YKM',
    97819: 'BOI',
    97820: 'BOI',
    97823: 'PDX',
    97824: 'BOI',
    97825: 'BOI',
    97826: 'YKM',
    97827: 'BOI',
    97828: 'BOI',
    97830: 'PDX',
    97833: 'BOI',
    97834: 'BOI',
    97835: 'YKM',
    97836: 'YKM',
    97837: 'BOI',
    97838: 'YKM',
    97839: 'YKM',
    97840: 'BOI',
    97841: 'BOI',
    97842: 'BOI',
    97843: 'YKM',
    97844: 'YKM',
    97845: 'BOI',
    97846: 'BOI',
    97848: 'BOI',
    97850: 'BOI',
    97856: 'BOI',
    97857: 'BOI',
    97859: 'YKM',
    97861: 'YKM',
    97862: 'BOI',
    97864: 'YKM',
    97865: 'BOI',
    97867: 'BOI',
    97868: 'YKM',
    97869: 'BOI',
    97870: 'BOI',
    97873: 'BOI',
    97874: 'BOI',
    97875: 'YKM',
    97876: 'BOI',
    97877: 'BOI',
    97880: 'YKM',
    97882: 'YKM',
    97883: 'BOI',
    97884: 'BOI',
    97885: 'BOI',
    97886: 'BOI',
    97901: 'BOI',
    97902: 'BOI',
    97903: 'BOI',
    97904: 'BOI',
    97905: 'BOI',
    97906: 'BOI',
    97907: 'BOI',
    97908: 'BOI',
    97909: 'BOI',
    97910: 'BOI',
    97911: 'BOI',
    97913: 'BOI',
    97914: 'BOI',
    97917: 'BOI',
    97918: 'BOI',
    97920: 'BOI',
    98001: 'SEA',
    98002: 'SEA',
    98003: 'SEA',
    98004: 'SEA',
    98005: 'SEA',
    98006: 'SEA',
    98007: 'SEA',
    98008: 'SEA',
    98009: 'SEA',
    98010: 'SEA',
    98011: 'SEA',
    98012: 'SEA',
    98013: 'SEA',
    98014: 'SEA',
    98015: 'SEA',
    98019: 'SEA',
    98020: 'SEA',
    98021: 'SEA',
    98022: 'SEA',
    98023: 'SEA',
    98024: 'SEA',
    98025: 'SEA',
    98026: 'SEA',
    98027: 'SEA',
    98028: 'SEA',
    98029: 'SEA',
    98030: 'SEA',
    98031: 'SEA',
    98032: 'SEA',
    98033: 'SEA',
    98034: 'SEA',
    98035: 'SEA',
    98036: 'SEA',
    98037: 'SEA',
    98038: 'SEA',
    98039: 'SEA',
    98040: 'SEA',
    98041: 'SEA',
    98042: 'SEA',
    98043: 'SEA',
    98045: 'SEA',
    98046: 'SEA',
    98047: 'SEA',
    98050: 'SEA',
    98051: 'SEA',
    98052: 'SEA',
    98053: 'SEA',
    98054: 'SEA',
    98055: 'SEA',
    98056: 'SEA',
    98057: 'SEA',
    98058: 'SEA',
    98059: 'SEA',
    98061: 'SEA',
    98062: 'SEA',
    98063: 'SEA',
    98064: 'SEA',
    98065: 'SEA',
    98068: 'SEA',
    98070: 'SEA',
    98071: 'SEA',
    98072: 'SEA',
    98073: 'SEA',
    98074: 'SEA',
    98075: 'SEA',
    98077: 'SEA',
    98082: 'SEA',
    98083: 'SEA',
    98087: 'SEA',
    98089: 'SEA',
    98092: 'SEA',
    98093: 'SEA',
    98101: 'SEA',
    98102: 'SEA',
    98103: 'SEA',
    98104: 'SEA',
    98105: 'SEA',
    98106: 'SEA',
    98107: 'SEA',
    98108: 'SEA',
    98109: 'SEA',
    98110: 'SEA',
    98111: 'SEA',
    98112: 'SEA',
    98113: 'SEA',
    98114: 'SEA',
    98115: 'SEA',
    98116: 'SEA',
    98117: 'SEA',
    98118: 'SEA',
    98119: 'SEA',
    98121: 'SEA',
    98122: 'SEA',
    98124: 'SEA',
    98125: 'SEA',
    98126: 'SEA',
    98127: 'SEA',
    98129: 'SEA',
    98131: 'SEA',
    98132: 'SEA',
    98133: 'SEA',
    98134: 'SEA',
    98136: 'SEA',
    98138: 'SEA',
    98139: 'SEA',
    98141: 'SEA',
    98144: 'SEA',
    98145: 'SEA',
    98146: 'SEA',
    98148: 'SEA',
    98151: 'SEA',
    98154: 'SEA',
    98155: 'SEA',
    98158: 'SEA',
    98160: 'SEA',
    98161: 'SEA',
    98164: 'SEA',
    98165: 'SEA',
    98166: 'SEA',
    98168: 'SEA',
    98170: 'SEA',
    98171: 'SEA',
    98174: 'SEA',
    98175: 'SEA',
    98177: 'SEA',
    98178: 'SEA',
    98181: 'SEA',
    98184: 'SEA',
    98185: 'SEA',
    98188: 'SEA',
    98189: 'SEA',
    98190: 'SEA',
    98191: 'SEA',
    98194: 'SEA',
    98195: 'SEA',
    98198: 'SEA',
    98199: 'SEA',
    98201: 'SEA',
    98203: 'SEA',
    98204: 'SEA',
    98205: 'SEA',
    98206: 'SEA',
    98207: 'SEA',
    98208: 'SEA',
    98213: 'SEA',
    98220: 'SEA',
    98221: 'SEA',
    98222: 'SEA',
    98223: 'SEA',
    98224: 'SEA',
    98225: 'SEA',
    98226: 'SEA',
    98227: 'SEA',
    98228: 'SEA',
    98229: 'SEA',
    98230: 'SEA',
    98231: 'SEA',
    98232: 'SEA',
    98233: 'SEA',
    98235: 'SEA',
    98236: 'SEA',
    98237: 'SEA',
    98238: 'SEA',
    98239: 'SEA',
    98240: 'SEA',
    98241: 'SEA',
    98243: 'SEA',
    98244: 'SEA',
    98245: 'SEA',
    98247: 'SEA',
    98248: 'SEA',
    98249: 'SEA',
    98250: 'SEA',
    98251: 'SEA',
    98252: 'SEA',
    98253: 'SEA',
    98255: 'SEA',
    98256: 'SEA',
    98257: 'SEA',
    98258: 'SEA',
    98259: 'SEA',
    98260: 'SEA',
    98261: 'SEA',
    98262: 'SEA',
    98263: 'SEA',
    98264: 'SEA',
    98266: 'SEA',
    98267: 'SEA',
    98270: 'SEA',
    98271: 'SEA',
    98272: 'SEA',
    98273: 'SEA',
    98274: 'SEA',
    98275: 'SEA',
    98276: 'SEA',
    98277: 'SEA',
    98278: 'SEA',
    98279: 'SEA',
    98280: 'SEA',
    98281: 'SEA',
    98282: 'SEA',
    98283: 'SEA',
    98284: 'SEA',
    98286: 'SEA',
    98287: 'SEA',
    98288: 'SEA',
    98290: 'SEA',
    98291: 'SEA',
    98292: 'SEA',
    98293: 'SEA',
    98294: 'SEA',
    98295: 'SEA',
    98296: 'SEA',
    98297: 'SEA',
    98303: 'SEA',
    98304: 'SEA',
    98305: 'SEA',
    98310: 'SEA',
    98311: 'SEA',
    98312: 'SEA',
    98314: 'SEA',
    98315: 'SEA',
    98320: 'SEA',
    98321: 'SEA',
    98322: 'SEA',
    98323: 'SEA',
    98324: 'SEA',
    98325: 'SEA',
    98326: 'SEA',
    98327: 'SEA',
    98328: 'SEA',
    98329: 'SEA',
    98330: 'SEA',
    98331: 'SEA',
    98332: 'SEA',
    98333: 'SEA',
    98335: 'SEA',
    98336: 'SEA',
    98337: 'SEA',
    98338: 'SEA',
    98339: 'SEA',
    98340: 'SEA',
    98342: 'SEA',
    98343: 'SEA',
    98344: 'SEA',
    98345: 'SEA',
    98346: 'SEA',
    98348: 'SEA',
    98349: 'SEA',
    98350: 'SEA',
    98351: 'SEA',
    98352: 'SEA',
    98353: 'SEA',
    98354: 'SEA',
    98355: 'SEA',
    98356: 'SEA',
    98357: 'SEA',
    98358: 'SEA',
    98359: 'SEA',
    98360: 'SEA',
    98361: 'SEA',
    98362: 'SEA',
    98363: 'SEA',
    98364: 'SEA',
    98365: 'SEA',
    98366: 'SEA',
    98367: 'SEA',
    98368: 'SEA',
    98370: 'SEA',
    98371: 'SEA',
    98372: 'SEA',
    98373: 'SEA',
    98374: 'SEA',
    98375: 'SEA',
    98376: 'SEA',
    98377: 'SEA',
    98378: 'SEA',
    98380: 'SEA',
    98381: 'SEA',
    98382: 'SEA',
    98383: 'SEA',
    98384: 'SEA',
    98385: 'SEA',
    98386: 'SEA',
    98387: 'SEA',
    98388: 'SEA',
    98390: 'SEA',
    98391: 'SEA',
    98392: 'SEA',
    98393: 'SEA',
    98394: 'SEA',
    98395: 'SEA',
    98396: 'SEA',
    98397: 'SEA',
    98398: 'SEA',
    98401: 'SEA',
    98402: 'SEA',
    98403: 'SEA',
    98404: 'SEA',
    98405: 'SEA',
    98406: 'SEA',
    98407: 'SEA',
    98408: 'SEA',
    98409: 'SEA',
    98411: 'SEA',
    98412: 'SEA',
    98413: 'SEA',
    98415: 'SEA',
    98416: 'SEA',
    98417: 'SEA',
    98418: 'SEA',
    98419: 'SEA',
    98421: 'SEA',
    98422: 'SEA',
    98424: 'SEA',
    98430: 'SEA',
    98431: 'SEA',
    98433: 'SEA',
    98438: 'SEA',
    98439: 'SEA',
    98442: 'SEA',
    98443: 'SEA',
    98444: 'SEA',
    98445: 'SEA',
    98446: 'SEA',
    98447: 'SEA',
    98448: 'SEA',
    98450: 'SEA',
    98455: 'SEA',
    98460: 'SEA',
    98464: 'SEA',
    98465: 'SEA',
    98466: 'SEA',
    98467: 'SEA',
    98471: 'SEA',
    98477: 'SEA',
    98481: 'SEA',
    98490: 'SEA',
    98492: 'SEA',
    98493: 'SEA',
    98496: 'SEA',
    98497: 'SEA',
    98498: 'SEA',
    98499: 'SEA',
    98501: 'SEA',
    98502: 'SEA',
    98503: 'SEA',
    98504: 'SEA',
    98505: 'SEA',
    98506: 'SEA',
    98507: 'SEA',
    98508: 'SEA',
    98509: 'SEA',
    98511: 'SEA',
    98512: 'SEA',
    98513: 'SEA',
    98516: 'SEA',
    98520: 'SEA',
    98522: 'SEA',
    98524: 'SEA',
    98526: 'SEA',
    98527: 'SEA',
    98528: 'SEA',
    98530: 'SEA',
    98531: 'SEA',
    98532: 'SEA',
    98533: 'SEA',
    98535: 'SEA',
    98536: 'SEA',
    98537: 'SEA',
    98538: 'SEA',
    98539: 'SEA',
    98540: 'SEA',
    98541: 'SEA',
    98542: 'SEA',
    98544: 'SEA',
    98546: 'SEA',
    98547: 'SEA',
    98548: 'SEA',
    98550: 'SEA',
    98552: 'SEA',
    98554: 'SEA',
    98555: 'SEA',
    98556: 'SEA',
    98557: 'SEA',
    98558: 'SEA',
    98559: 'SEA',
    98560: 'SEA',
    98561: 'SEA',
    98562: 'SEA',
    98563: 'SEA',
    98564: 'SEA',
    98565: 'SEA',
    98566: 'SEA',
    98568: 'SEA',
    98569: 'SEA',
    98570: 'SEA',
    98571: 'SEA',
    98572: 'SEA',
    98575: 'SEA',
    98576: 'SEA',
    98577: 'SEA',
    98579: 'SEA',
    98580: 'SEA',
    98581: 'SEA',
    98582: 'SEA',
    98583: 'SEA',
    98584: 'SEA',
    98585: 'SEA',
    98586: 'SEA',
    98587: 'SEA',
    98588: 'SEA',
    98589: 'SEA',
    98590: 'SEA',
    98591: 'SEA',
    98592: 'SEA',
    98593: 'SEA',
    98595: 'SEA',
    98596: 'SEA',
    98597: 'SEA',
    98599: 'SEA',
    98601: 'PDX',
    98602: 'PDX',
    98603: 'PDX',
    98604: 'PDX',
    98605: 'PDX',
    98606: 'PDX',
    98607: 'PDX',
    98609: 'PDX',
    98610: 'PDX',
    98611: 'PDX',
    98612: 'PDX',
    98613: 'PDX',
    98614: 'PDX',
    98616: 'PDX',
    98617: 'PDX',
    98619: 'PDX',
    98620: 'YKM',
    98621: 'PDX',
    98622: 'PDX',
    98623: 'PDX',
    98624: 'PDX',
    98625: 'PDX',
    98626: 'PDX',
    98628: 'PDX',
    98629: 'PDX',
    98631: 'PDX',
    98632: 'PDX',
    98635: 'PDX',
    98637: 'PDX',
    98638: 'PDX',
    98639: 'PDX',
    98640: 'PDX',
    98641: 'PDX',
    98642: 'PDX',
    98643: 'PDX',
    98644: 'PDX',
    98645: 'PDX',
    98647: 'PDX',
    98648: 'PDX',
    98649: 'PDX',
    98650: 'PDX',
    98651: 'PDX',
    98660: 'PDX',
    98661: 'PDX',
    98662: 'PDX',
    98663: 'PDX',
    98664: 'PDX',
    98665: 'PDX',
    98666: 'PDX',
    98667: 'PDX',
    98668: 'PDX',
    98670: 'PDX',
    98671: 'PDX',
    98672: 'PDX',
    98673: 'PDX',
    98674: 'PDX',
    98675: 'PDX',
    98682: 'PDX',
    98683: 'PDX',
    98684: 'PDX',
    98685: 'PDX',
    98686: 'PDX',
    98687: 'PDX',
    98801: 'SEA',
    98802: 'SEA',
    98807: 'SEA',
    98811: 'SEA',
    98812: 'SEA',
    98813: 'SEA',
    98814: 'SEA',
    98815: 'SEA',
    98816: 'SEA',
    98817: 'SEA',
    98819: 'SEA',
    98821: 'SEA',
    98822: 'SEA',
    98823: 'YKM',
    98824: 'YKM',
    98826: 'SEA',
    98827: 'SEA',
    98828: 'YKM',
    98829: 'SEA',
    98830: 'GEG',
    98831: 'SEA',
    98832: 'GEG',
    98833: 'SEA',
    98834: 'SEA',
    98836: 'SEA',
    98837: 'YKM',
    98840: 'SEA',
    98841: 'GEG',
    98843: 'SEA',
    98844: 'GEG',
    98845: 'SEA',
    98846: 'SEA',
    98847: 'SEA',
    98848: 'YKM',
    98849: 'SEA',
    98850: 'SEA',
    98851: 'GEG',
    98852: 'SEA',
    98853: 'GEG',
    98855: 'GEG',
    98856: 'SEA',
    98857: 'YKM',
    98858: 'SEA',
    98859: 'GEG',
    98860: 'GEG',
    98862: 'SEA',
    98901: 'YKM',
    98902: 'YKM',
    98903: 'YKM',
    98904: 'YKM',
    98907: 'YKM',
    98908: 'YKM',
    98909: 'YKM',
    98920: 'YKM',
    98921: 'YKM',
    98922: 'SEA',
    98923: 'YKM',
    98925: 'SEA',
    98926: 'YKM',
    98929: 'YKM',
    98930: 'YKM',
    98932: 'YKM',
    98933: 'YKM',
    98934: 'YKM',
    98935: 'YKM',
    98936: 'YKM',
    98937: 'YKM',
    98938: 'YKM',
    98939: 'YKM',
    98940: 'SEA',
    98941: 'SEA',
    98942: 'YKM',
    98943: 'SEA',
    98944: 'YKM',
    98946: 'YKM',
    98947: 'YKM',
    98948: 'YKM',
    98950: 'YKM',
    98951: 'YKM',
    98952: 'YKM',
    98953: 'YKM',
    99001: 'GEG',
    99003: 'GEG',
    99004: 'GEG',
    99005: 'GEG',
    99006: 'GEG',
    99008: 'GEG',
    99009: 'GEG',
    99011: 'GEG',
    99012: 'GEG',
    99013: 'GEG',
    99014: 'GEG',
    99015: 'GEG',
    99016: 'GEG',
    99017: 'GEG',
    99018: 'GEG',
    99019: 'GEG',
    99020: 'GEG',
    99021: 'GEG',
    99022: 'GEG',
    99023: 'GEG',
    99025: 'GEG',
    99026: 'GEG',
    99027: 'GEG',
    99029: 'GEG',
    99030: 'GEG',
    99031: 'GEG',
    99033: 'GEG',
    99034: 'GEG',
    99036: 'GEG',
    99037: 'GEG',
    99039: 'GEG',
    99040: 'GEG',
    99101: 'GEG',
    99102: 'GEG',
    99103: 'GEG',
    99104: 'GEG',
    99105: 'GEG',
    99107: 'GEG',
    99109: 'GEG',
    99110: 'GEG',
    99111: 'GEG',
    99113: 'GEG',
    99114: 'GEG',
    99115: 'GEG',
    99116: 'GEG',
    99117: 'GEG',
    99118: 'GEG',
    99119: 'GEG',
    99121: 'GEG',
    99122: 'GEG',
    99123: 'GEG',
    99124: 'GEG',
    99125: 'GEG',
    99126: 'GEG',
    99127: 'GEG',
    99128: 'GEG',
    99129: 'GEG',
    99130: 'GEG',
    99131: 'GEG',
    99133: 'GEG',
    99134: 'GEG',
    99135: 'GEG',
    99136: 'GEG',
    99137: 'GEG',
    99138: 'GEG',
    99139: 'GEG',
    99140: 'GEG',
    99141: 'GEG',
    99143: 'GEG',
    99144: 'GEG',
    99146: 'GEG',
    99147: 'GEG',
    99148: 'GEG',
    99149: 'GEG',
    99150: 'GEG',
    99151: 'GEG',
    99152: 'GEG',
    99153: 'GEG',
    99154: 'GEG',
    99155: 'GEG',
    99156: 'GEG',
    99157: 'GEG',
    99158: 'GEG',
    99159: 'GEG',
    99160: 'GEG',
    99161: 'GEG',
    99163: 'GEG',
    99164: 'GEG',
    99165: 'GEG',
    99166: 'GEG',
    99167: 'GEG',
    99169: 'GEG',
    99170: 'GEG',
    99171: 'GEG',
    99173: 'GEG',
    99174: 'GEG',
    99176: 'GEG',
    99179: 'GEG',
    99180: 'GEG',
    99181: 'GEG',
    99185: 'GEG',
    99201: 'GEG',
    99202: 'GEG',
    99203: 'GEG',
    99204: 'GEG',
    99205: 'GEG',
    99206: 'GEG',
    99207: 'GEG',
    99208: 'GEG',
    99209: 'GEG',
    99210: 'GEG',
    99211: 'GEG',
    99212: 'GEG',
    99213: 'GEG',
    99214: 'GEG',
    99215: 'GEG',
    99216: 'GEG',
    99217: 'GEG',
    99218: 'GEG',
    99219: 'GEG',
    99220: 'GEG',
    99223: 'GEG',
    99224: 'GEG',
    99228: 'GEG',
    99251: 'GEG',
    99252: 'GEG',
    99256: 'GEG',
    99258: 'GEG',
    99260: 'GEG',
    99299: 'GEG',
    99301: 'YKM',
    99302: 'YKM',
    99320: 'YKM',
    99321: 'YKM',
    99322: 'YKM',
    99323: 'YKM',
    99324: 'GEG',
    99326: 'GEG',
    99327: 'GEG',
    99328: 'GEG',
    99329: 'GEG',
    99330: 'YKM',
    99332: 'YKM',
    99333: 'GEG',
    99335: 'GEG',
    99336: 'YKM',
    99337: 'YKM',
    99338: 'YKM',
    99341: 'GEG',
    99343: 'YKM',
    99344: 'YKM',
    99345: 'YKM',
    99346: 'YKM',
    99347: 'GEG',
    99348: 'GEG',
    99349: 'YKM',
    99350: 'YKM',
    99352: 'YKM',
    99353: 'YKM',
    99354: 'YKM',
    99356: 'YKM',
    99357: 'YKM',
    99359: 'GEG',
    99360: 'YKM',
    99361: 'GEG',
    99362: 'GEG',
    99363: 'YKM',
    99371: 'GEG',
    99401: 'GEG',
    99402: 'GEG',
    99403: 'GEG',
    '00501': 'JFK',
    '00544': 'JFK',
    '01001': 'BDL',
    '01002': 'BDL',
    '01003': 'BDL',
    '01004': 'BDL',
    '01005': 'BDL',
    '01007': 'BDL',
    '01008': 'ALB',
    '01009': 'BDL',
    '01010': 'BDL',
    '01011': 'ALB',
    '01012': 'ALB',
    '01013': 'BDL',
    '01014': 'BDL',
    '01020': 'BDL',
    '01021': 'BDL',
    '01022': 'BDL',
    '01026': 'ALB',
    '01027': 'ALB',
    '01028': 'BDL',
    '01029': 'ALB',
    '01030': 'BDL',
    '01031': 'BDL',
    '01032': 'ALB',
    '01033': 'BDL',
    '01034': 'ALB',
    '01035': 'ALB',
    '01036': 'BDL',
    '01037': 'BDL',
    '01038': 'ALB',
    '01039': 'ALB',
    '01040': 'BDL',
    '01041': 'BDL',
    '01050': 'ALB',
    '01053': 'ALB',
    '01054': 'BDL',
    '01056': 'BDL',
    '01057': 'BDL',
    '01059': 'BDL',
    '01060': 'ALB',
    '01061': 'ALB',
    '01062': 'ALB',
    '01063': 'ALB',
    '01066': 'BDL',
    '01068': 'BDL',
    '01069': 'BDL',
    '01070': 'ALB',
    '01071': 'ALB',
    '01072': 'BDL',
    '01073': 'ALB',
    '01074': 'BDL',
    '01075': 'BDL',
    '01077': 'BDL',
    '01079': 'BDL',
    '01080': 'BDL',
    '01081': 'BDL',
    '01082': 'BDL',
    '01083': 'BDL',
    '01084': 'ALB',
    '01085': 'ALB',
    '01086': 'ALB',
    '01088': 'ALB',
    '01089': 'BDL',
    '01090': 'BDL',
    '01092': 'BDL',
    '01093': 'BDL',
    '01094': 'BDL',
    '01095': 'BDL',
    '01096': 'ALB',
    '01097': 'ALB',
    '01098': 'ALB',
    '01101': 'BDL',
    '01102': 'BDL',
    '01103': 'BDL',
    '01104': 'BDL',
    '01105': 'BDL',
    '01106': 'BDL',
    '01107': 'BDL',
    '01108': 'BDL',
    '01109': 'BDL',
    '01111': 'BDL',
    '01115': 'BDL',
    '01116': 'BDL',
    '01118': 'BDL',
    '01119': 'BDL',
    '01128': 'BDL',
    '01129': 'BDL',
    '01133': 'BDL',
    '01138': 'BDL',
    '01139': 'BDL',
    '01144': 'BDL',
    '01151': 'BDL',
    '01152': 'BDL',
    '01195': 'BDL',
    '01199': 'BDL',
    '01201': 'ALB',
    '01202': 'ALB',
    '01203': 'ALB',
    '01220': 'ALB',
    '01222': 'ALB',
    '01223': 'ALB',
    '01224': 'ALB',
    '01225': 'ALB',
    '01226': 'ALB',
    '01227': 'ALB',
    '01229': 'ALB',
    '01230': 'ALB',
    '01235': 'ALB',
    '01236': 'ALB',
    '01237': 'ALB',
    '01238': 'ALB',
    '01240': 'ALB',
    '01242': 'ALB',
    '01243': 'ALB',
    '01244': 'ALB',
    '01245': 'ALB',
    '01247': 'ALB',
    '01252': 'ALB',
    '01253': 'ALB',
    '01254': 'ALB',
    '01255': 'ALB',
    '01256': 'ALB',
    '01257': 'ALB',
    '01258': 'ALB',
    '01259': 'ALB',
    '01260': 'ALB',
    '01262': 'ALB',
    '01263': 'ALB',
    '01264': 'ALB',
    '01266': 'ALB',
    '01267': 'ALB',
    '01270': 'ALB',
    '01301': 'ALB',
    '01302': 'ALB',
    '01324': 'BDL',
    '01330': 'ALB',
    '01331': 'BDL',
    '01337': 'ALB',
    '01338': 'ALB',
    '01339': 'ALB',
    '01340': 'ALB',
    '01341': 'ALB',
    '01342': 'ALB',
    '01343': 'ALB',
    '01344': 'BDL',
    '01346': 'ALB',
    '01347': 'ALB',
    '01349': 'ALB',
    '01350': 'ALB',
    '01351': 'ALB',
    '01354': 'ALB',
    '01355': 'BDL',
    '01360': 'BDL',
    '01364': 'BDL',
    '01366': 'BDL',
    '01367': 'ALB',
    '01368': 'BDL',
    '01370': 'ALB',
    '01373': 'ALB',
    '01375': 'ALB',
    '01376': 'ALB',
    '01378': 'BDL',
    '01379': 'BDL',
    '01380': 'BDL',
    '01420': 'BDL',
    '01430': 'BDL',
    '01431': 'BDL',
    '01432': 'BDL',
    '01434': 'BDL',
    '01436': 'BDL',
    '01438': 'BDL',
    '01440': 'BDL',
    '01441': 'BDL',
    '01450': 'BDL',
    '01451': 'BDL',
    '01452': 'BDL',
    '01453': 'BDL',
    '01460': 'BDL',
    '01462': 'BDL',
    '01463': 'BDL',
    '01464': 'BDL',
    '01467': 'BDL',
    '01468': 'BDL',
    '01469': 'BDL',
    '01470': 'BDL',
    '01471': 'BDL',
    '01472': 'BDL',
    '01473': 'BDL',
    '01474': 'BDL',
    '01475': 'BDL',
    '01477': 'BDL',
    '01501': 'BDL',
    '01503': 'BDL',
    '01504': 'BDL',
    '01505': 'BDL',
    '01506': 'BDL',
    '01507': 'BDL',
    '01508': 'BDL',
    '01509': 'BDL',
    '01510': 'BDL',
    '01515': 'BDL',
    '01516': 'BDL',
    '01517': 'BDL',
    '01518': 'BDL',
    '01519': 'BDL',
    '01520': 'BDL',
    '01521': 'BDL',
    '01522': 'BDL',
    '01523': 'BDL',
    '01524': 'BDL',
    '01525': 'BDL',
    '01526': 'BDL',
    '01527': 'BDL',
    '01529': 'BDL',
    '01531': 'BDL',
    '01532': 'BDL',
    '01534': 'BDL',
    '01535': 'BDL',
    '01536': 'BDL',
    '01537': 'BDL',
    '01538': 'BDL',
    '01540': 'BDL',
    '01541': 'BDL',
    '01542': 'BDL',
    '01543': 'BDL',
    '01545': 'BDL',
    '01546': 'BDL',
    '01550': 'BDL',
    '01560': 'BDL',
    '01561': 'BDL',
    '01562': 'BDL',
    '01564': 'BDL',
    '01566': 'BDL',
    '01568': 'BDL',
    '01569': 'BDL',
    '01570': 'BDL',
    '01571': 'BDL',
    '01580': 'BDL',
    '01581': 'BDL',
    '01582': 'BDL',
    '01583': 'BDL',
    '01585': 'BDL',
    '01586': 'BDL',
    '01588': 'BDL',
    '01590': 'BDL',
    '01601': 'BDL',
    '01602': 'BDL',
    '01603': 'BDL',
    '01604': 'BDL',
    '01605': 'BDL',
    '01606': 'BDL',
    '01607': 'BDL',
    '01608': 'BDL',
    '01609': 'BDL',
    '01610': 'BDL',
    '01611': 'BDL',
    '01612': 'BDL',
    '01613': 'BDL',
    '01614': 'BDL',
    '01615': 'BDL',
    '01653': 'BDL',
    '01654': 'BDL',
    '01655': 'BDL',
    '01701': 'BDL',
    '01702': 'BDL',
    '01703': 'BDL',
    '01704': 'BDL',
    '01705': 'BDL',
    '01718': 'BDL',
    '01719': 'BDL',
    '01720': 'BDL',
    '01721': 'BDL',
    '01730': 'BDL',
    '01731': 'BDL',
    '01740': 'BDL',
    '01741': 'BDL',
    '01742': 'BDL',
    '01745': 'BDL',
    '01746': 'BDL',
    '01747': 'BDL',
    '01748': 'BDL',
    '01749': 'BDL',
    '01752': 'BDL',
    '01754': 'BDL',
    '01756': 'BDL',
    '01757': 'BDL',
    '01760': 'BDL',
    '01770': 'BDL',
    '01772': 'BDL',
    '01773': 'BDL',
    '01775': 'BDL',
    '01776': 'BDL',
    '01778': 'BDL',
    '01784': 'BDL',
    '01801': 'BDL',
    '01803': 'BDL',
    '01805': 'BDL',
    '01806': 'BDL',
    '01807': 'BDL',
    '01808': 'BDL',
    '01810': 'BDL',
    '01812': 'BDL',
    '01813': 'BDL',
    '01815': 'BDL',
    '01821': 'BDL',
    '01822': 'BDL',
    '01824': 'BDL',
    '01826': 'BDL',
    '01827': 'BDL',
    '01830': 'BDL',
    '01831': 'BDL',
    '01832': 'BDL',
    '01833': 'BDL',
    '01834': 'BDL',
    '01835': 'BDL',
    '01840': 'BDL',
    '01841': 'BDL',
    '01842': 'BDL',
    '01843': 'BDL',
    '01844': 'BDL',
    '01845': 'BDL',
    '01850': 'BDL',
    '01851': 'BDL',
    '01852': 'BDL',
    '01853': 'BDL',
    '01854': 'BDL',
    '01860': 'BDL',
    '01862': 'BDL',
    '01863': 'BDL',
    '01864': 'BDL',
    '01865': 'BDL',
    '01866': 'BDL',
    '01867': 'BDL',
    '01876': 'BDL',
    '01879': 'BDL',
    '01880': 'BDL',
    '01885': 'BDL',
    '01886': 'BDL',
    '01887': 'BDL',
    '01888': 'BDL',
    '01889': 'BDL',
    '01890': 'BDL',
    '01899': 'BDL',
    '01901': 'BDL',
    '01902': 'BDL',
    '01903': 'BDL',
    '01904': 'BDL',
    '01905': 'BDL',
    '01906': 'BDL',
    '01907': 'BDL',
    '01908': 'BDL',
    '01910': 'BDL',
    '01913': 'BDL',
    '01915': 'BDL',
    '01921': 'BDL',
    '01922': 'BDL',
    '01923': 'BDL',
    '01929': 'BDL',
    '01930': 'BDL',
    '01931': 'BDL',
    '01936': 'BDL',
    '01937': 'BDL',
    '01938': 'BDL',
    '01940': 'BDL',
    '01944': 'BDL',
    '01945': 'BDL',
    '01949': 'BDL',
    '01950': 'BDL',
    '01951': 'BDL',
    '01952': 'BDL',
    '01960': 'BDL',
    '01961': 'BDL',
    '01965': 'BDL',
    '01966': 'BDL',
    '01969': 'BDL',
    '01970': 'BDL',
    '01971': 'BDL',
    '01982': 'BDL',
    '01983': 'BDL',
    '01984': 'BDL',
    '01985': 'BDL',
    '02018': 'BDL',
    '02019': 'BDL',
    '02020': 'BDL',
    '02021': 'BDL',
    '02025': 'BDL',
    '02026': 'BDL',
    '02027': 'BDL',
    '02030': 'BDL',
    '02031': 'BDL',
    '02032': 'BDL',
    '02035': 'BDL',
    '02038': 'BDL',
    '02040': 'BDL',
    '02041': 'BDL',
    '02043': 'BDL',
    '02044': 'BDL',
    '02045': 'BDL',
    '02047': 'BDL',
    '02048': 'BDL',
    '02050': 'BDL',
    '02051': 'BDL',
    '02052': 'BDL',
    '02053': 'BDL',
    '02054': 'BDL',
    '02055': 'BDL',
    '02056': 'BDL',
    '02059': 'BDL',
    '02060': 'BDL',
    '02061': 'BDL',
    '02062': 'BDL',
    '02065': 'BDL',
    '02066': 'BDL',
    '02067': 'BDL',
    '02070': 'BDL',
    '02071': 'BDL',
    '02072': 'BDL',
    '02081': 'BDL',
    '02090': 'BDL',
    '02093': 'BDL',
    '02108': 'BDL',
    '02109': 'BDL',
    '02110': 'BDL',
    '02111': 'BDL',
    '02112': 'BDL',
    '02113': 'BDL',
    '02114': 'BDL',
    '02115': 'BDL',
    '02116': 'BDL',
    '02117': 'BDL',
    '02118': 'BDL',
    '02119': 'BDL',
    '02120': 'BDL',
    '02121': 'BDL',
    '02122': 'BDL',
    '02123': 'BDL',
    '02124': 'BDL',
    '02125': 'BDL',
    '02126': 'BDL',
    '02127': 'BDL',
    '02128': 'BDL',
    '02129': 'BDL',
    '02130': 'BDL',
    '02131': 'BDL',
    '02132': 'BDL',
    '02133': 'BDL',
    '02134': 'BDL',
    '02135': 'BDL',
    '02136': 'BDL',
    '02137': 'BDL',
    '02138': 'BDL',
    '02139': 'BDL',
    '02140': 'BDL',
    '02141': 'BDL',
    '02142': 'BDL',
    '02143': 'BDL',
    '02144': 'BDL',
    '02145': 'BDL',
    '02148': 'BDL',
    '02149': 'BDL',
    '02150': 'BDL',
    '02151': 'BDL',
    '02152': 'BDL',
    '02153': 'BDL',
    '02155': 'BDL',
    '02156': 'BDL',
    '02163': 'BDL',
    '02169': 'BDL',
    '02170': 'BDL',
    '02171': 'BDL',
    '02176': 'BDL',
    '02180': 'BDL',
    '02184': 'BDL',
    '02185': 'BDL',
    '02186': 'BDL',
    '02187': 'BDL',
    '02188': 'BDL',
    '02189': 'BDL',
    '02190': 'BDL',
    '02191': 'BDL',
    '02196': 'BDL',
    '02199': 'BDL',
    '02201': 'BDL',
    '02203': 'BDL',
    '02204': 'BDL',
    '02205': 'BDL',
    '02206': 'BDL',
    '02207': 'BDL',
    '02210': 'BDL',
    '02211': 'BDL',
    '02212': 'BDL',
    '02215': 'BDL',
    '02216': 'BDL',
    '02217': 'BDL',
    '02222': 'BDL',
    '02228': 'BDL',
    '02238': 'BDL',
    '02239': 'BDL',
    '02241': 'BDL',
    '02266': 'BDL',
    '02269': 'BDL',
    '02283': 'BDL',
    '02284': 'BDL',
    '02293': 'BDL',
    '02295': 'BDL',
    '02297': 'BDL',
    '02298': 'BDL',
    '02301': 'BDL',
    '02302': 'BDL',
    '02303': 'BDL',
    '02304': 'BDL',
    '02305': 'BDL',
    '02322': 'BDL',
    '02324': 'BDL',
    '02325': 'BDL',
    '02327': 'BDL',
    '02330': 'BDL',
    '02331': 'BDL',
    '02332': 'BDL',
    '02333': 'BDL',
    '02334': 'BDL',
    '02337': 'BDL',
    '02338': 'BDL',
    '02339': 'BDL',
    '02340': 'BDL',
    '02341': 'BDL',
    '02343': 'BDL',
    '02344': 'BDL',
    '02345': 'BDL',
    '02346': 'BDL',
    '02347': 'BDL',
    '02348': 'BDL',
    '02349': 'BDL',
    '02350': 'BDL',
    '02351': 'BDL',
    '02355': 'BDL',
    '02356': 'BDL',
    '02357': 'BDL',
    '02358': 'BDL',
    '02359': 'BDL',
    '02360': 'BDL',
    '02361': 'BDL',
    '02362': 'BDL',
    '02364': 'BDL',
    '02366': 'BDL',
    '02367': 'BDL',
    '02368': 'BDL',
    '02370': 'BDL',
    '02375': 'BDL',
    '02379': 'BDL',
    '02381': 'BDL',
    '02382': 'BDL',
    '02420': 'BDL',
    '02421': 'BDL',
    '02445': 'BDL',
    '02446': 'BDL',
    '02447': 'BDL',
    '02451': 'BDL',
    '02452': 'BDL',
    '02453': 'BDL',
    '02454': 'BDL',
    '02455': 'BDL',
    '02456': 'BDL',
    '02457': 'BDL',
    '02458': 'BDL',
    '02459': 'BDL',
    '02460': 'BDL',
    '02461': 'BDL',
    '02462': 'BDL',
    '02464': 'BDL',
    '02465': 'BDL',
    '02466': 'BDL',
    '02467': 'BDL',
    '02468': 'BDL',
    '02471': 'BDL',
    '02472': 'BDL',
    '02474': 'BDL',
    '02475': 'BDL',
    '02476': 'BDL',
    '02477': 'BDL',
    '02478': 'BDL',
    '02479': 'BDL',
    '02481': 'BDL',
    '02482': 'BDL',
    '02492': 'BDL',
    '02493': 'BDL',
    '02494': 'BDL',
    '02495': 'BDL',
    '02532': 'BDL',
    '02534': 'BDL',
    '02535': 'BDL',
    '02536': 'BDL',
    '02537': 'BDL',
    '02538': 'BDL',
    '02539': 'BDL',
    '02540': 'BDL',
    '02541': 'BDL',
    '02542': 'BDL',
    '02543': 'BDL',
    '02552': 'BDL',
    '02553': 'BDL',
    '02554': 'BDL',
    '02556': 'BDL',
    '02557': 'BDL',
    '02558': 'BDL',
    '02559': 'BDL',
    '02561': 'BDL',
    '02562': 'BDL',
    '02563': 'BDL',
    '02564': 'BDL',
    '02565': 'BDL',
    '02568': 'BDL',
    '02571': 'BDL',
    '02573': 'BDL',
    '02574': 'BDL',
    '02575': 'BDL',
    '02576': 'BDL',
    '02584': 'BDL',
    '02601': 'BDL',
    '02630': 'BDL',
    '02631': 'BDL',
    '02632': 'BDL',
    '02633': 'BDL',
    '02634': 'BDL',
    '02635': 'BDL',
    '02636': 'BDL',
    '02637': 'BDL',
    '02638': 'BDL',
    '02639': 'BDL',
    '02641': 'BDL',
    '02642': 'BDL',
    '02643': 'BDL',
    '02644': 'BDL',
    '02645': 'BDL',
    '02646': 'BDL',
    '02647': 'BDL',
    '02648': 'BDL',
    '02649': 'BDL',
    '02650': 'BDL',
    '02651': 'BDL',
    '02652': 'BDL',
    '02653': 'BDL',
    '02655': 'BDL',
    '02657': 'BDL',
    '02659': 'BDL',
    '02660': 'BDL',
    '02661': 'BDL',
    '02662': 'BDL',
    '02663': 'BDL',
    '02664': 'BDL',
    '02666': 'BDL',
    '02667': 'BDL',
    '02668': 'BDL',
    '02669': 'BDL',
    '02670': 'BDL',
    '02671': 'BDL',
    '02672': 'BDL',
    '02673': 'BDL',
    '02675': 'BDL',
    '02702': 'BDL',
    '02703': 'BDL',
    '02712': 'BDL',
    '02713': 'BDL',
    '02714': 'BDL',
    '02715': 'BDL',
    '02717': 'BDL',
    '02718': 'BDL',
    '02719': 'BDL',
    '02720': 'BDL',
    '02721': 'BDL',
    '02722': 'BDL',
    '02723': 'BDL',
    '02724': 'BDL',
    '02725': 'BDL',
    '02726': 'BDL',
    '02738': 'BDL',
    '02739': 'BDL',
    '02740': 'BDL',
    '02741': 'BDL',
    '02742': 'BDL',
    '02743': 'BDL',
    '02744': 'BDL',
    '02745': 'BDL',
    '02746': 'BDL',
    '02747': 'BDL',
    '02748': 'BDL',
    '02760': 'BDL',
    '02761': 'BDL',
    '02762': 'BDL',
    '02763': 'BDL',
    '02764': 'BDL',
    '02766': 'BDL',
    '02767': 'BDL',
    '02768': 'BDL',
    '02769': 'BDL',
    '02770': 'BDL',
    '02771': 'BDL',
    '02777': 'BDL',
    '02779': 'BDL',
    '02780': 'BDL',
    '02783': 'BDL',
    '02790': 'BDL',
    '02791': 'BDL',
    '02801': 'BDL',
    '02802': 'BDL',
    '02804': 'BDL',
    '02806': 'BDL',
    '02807': 'JFK',
    '02808': 'BDL',
    '02809': 'BDL',
    '02812': 'BDL',
    '02813': 'BDL',
    '02814': 'BDL',
    '02815': 'BDL',
    '02816': 'BDL',
    '02817': 'BDL',
    '02818': 'BDL',
    '02822': 'BDL',
    '02823': 'BDL',
    '02824': 'BDL',
    '02825': 'BDL',
    '02826': 'BDL',
    '02827': 'BDL',
    '02828': 'BDL',
    '02829': 'BDL',
    '02830': 'BDL',
    '02831': 'BDL',
    '02832': 'BDL',
    '02833': 'BDL',
    '02835': 'BDL',
    '02836': 'BDL',
    '02837': 'BDL',
    '02838': 'BDL',
    '02839': 'BDL',
    '02840': 'BDL',
    '02841': 'BDL',
    '02842': 'BDL',
    '02852': 'BDL',
    '02854': 'BDL',
    '02857': 'BDL',
    '02858': 'BDL',
    '02859': 'BDL',
    '02860': 'BDL',
    '02861': 'BDL',
    '02862': 'BDL',
    '02863': 'BDL',
    '02864': 'BDL',
    '02865': 'BDL',
    '02871': 'BDL',
    '02872': 'BDL',
    '02873': 'BDL',
    '02874': 'BDL',
    '02875': 'BDL',
    '02876': 'BDL',
    '02877': 'BDL',
    '02878': 'BDL',
    '02879': 'BDL',
    '02880': 'BDL',
    '02881': 'BDL',
    '02882': 'BDL',
    '02883': 'BDL',
    '02885': 'BDL',
    '02886': 'BDL',
    '02887': 'BDL',
    '02888': 'BDL',
    '02889': 'BDL',
    '02891': 'BDL',
    '02892': 'BDL',
    '02893': 'BDL',
    '02894': 'BDL',
    '02895': 'BDL',
    '02896': 'BDL',
    '02898': 'BDL',
    '02901': 'BDL',
    '02902': 'BDL',
    '02903': 'BDL',
    '02904': 'BDL',
    '02905': 'BDL',
    '02906': 'BDL',
    '02907': 'BDL',
    '02908': 'BDL',
    '02909': 'BDL',
    '02910': 'BDL',
    '02911': 'BDL',
    '02912': 'BDL',
    '02914': 'BDL',
    '02915': 'BDL',
    '02916': 'BDL',
    '02917': 'BDL',
    '02918': 'BDL',
    '02919': 'BDL',
    '02920': 'BDL',
    '02921': 'BDL',
    '02940': 'PWM',
    '03031': 'PWM',
    '03032': 'PWM',
    '03033': 'PWM',
    '03034': 'PWM',
    '03036': 'PWM',
    '03037': 'PWM',
    '03038': 'PWM',
    '03040': 'PWM',
    '03041': 'PWM',
    '03042': 'PWM',
    '03043': 'PWM',
    '03044': 'PWM',
    '03045': 'PWM',
    '03046': 'PWM',
    '03047': 'PWM',
    '03048': 'PWM',
    '03049': 'PWM',
    '03051': 'PWM',
    '03052': 'PWM',
    '03053': 'PWM',
    '03054': 'PWM',
    '03055': 'PWM',
    '03057': 'PWM',
    '03060': 'PWM',
    '03061': 'PWM',
    '03062': 'PWM',
    '03063': 'PWM',
    '03064': 'PWM',
    '03070': 'PWM',
    '03071': 'PWM',
    '03073': 'PWM',
    '03076': 'PWM',
    '03077': 'PWM',
    '03079': 'PWM',
    '03082': 'PWM',
    '03084': 'PWM',
    '03086': 'PWM',
    '03087': 'PWM',
    '03101': 'PWM',
    '03102': 'PWM',
    '03103': 'PWM',
    '03104': 'PWM',
    '03105': 'PWM',
    '03106': 'PWM',
    '03107': 'PWM',
    '03108': 'PWM',
    '03109': 'PWM',
    '03110': 'PWM',
    '03111': 'PWM',
    '03215': 'PWM',
    '03216': 'PWM',
    '03217': 'PWM',
    '03218': 'PWM',
    '03220': 'PWM',
    '03221': 'PWM',
    '03222': 'PWM',
    '03223': 'PWM',
    '03224': 'PWM',
    '03225': 'PWM',
    '03226': 'PWM',
    '03227': 'PWM',
    '03229': 'PWM',
    '03230': 'PWM',
    '03231': 'PWM',
    '03232': 'PWM',
    '03233': 'PWM',
    '03234': 'PWM',
    '03235': 'PWM',
    '03237': 'PWM',
    '03238': 'PWM',
    '03240': 'PWM',
    '03241': 'PWM',
    '03242': 'PWM',
    '03243': 'PWM',
    '03244': 'PWM',
    '03245': 'PWM',
    '03246': 'PWM',
    '03247': 'PWM',
    '03249': 'PWM',
    '03251': 'PWM',
    '03252': 'PWM',
    '03253': 'PWM',
    '03254': 'PWM',
    '03255': 'PWM',
    '03256': 'PWM',
    '03257': 'PWM',
    '03258': 'PWM',
    '03259': 'PWM',
    '03260': 'PWM',
    '03261': 'PWM',
    '03262': 'PWM',
    '03263': 'PWM',
    '03264': 'PWM',
    '03266': 'PWM',
    '03268': 'PWM',
    '03269': 'PWM',
    '03272': 'PWM',
    '03273': 'PWM',
    '03274': 'PWM',
    '03275': 'PWM',
    '03276': 'PWM',
    '03278': 'PWM',
    '03279': 'PWM',
    '03280': 'PWM',
    '03281': 'PWM',
    '03282': 'PWM',
    '03284': 'PWM',
    '03285': 'PWM',
    '03287': 'PWM',
    '03289': 'PWM',
    '03290': 'PWM',
    '03291': 'PWM',
    '03293': 'PWM',
    '03298': 'PWM',
    '03299': 'PWM',
    '03301': 'PWM',
    '03302': 'PWM',
    '03303': 'PWM',
    '03304': 'PWM',
    '03305': 'PWM',
    '03307': 'PWM',
    '03431': 'PWM',
    '03435': 'PWM',
    '03440': 'PWM',
    '03441': 'PWM',
    '03442': 'PWM',
    '03443': 'PWM',
    '03444': 'PWM',
    '03445': 'PWM',
    '03446': 'PWM',
    '03447': 'PWM',
    '03448': 'PWM',
    '03449': 'PWM',
    '03450': 'PWM',
    '03451': 'PWM',
    '03452': 'PWM',
    '03455': 'PWM',
    '03456': 'PWM',
    '03457': 'PWM',
    '03458': 'PWM',
    '03461': 'PWM',
    '03462': 'PWM',
    '03464': 'PWM',
    '03465': 'PWM',
    '03466': 'PWM',
    '03467': 'PWM',
    '03468': 'PWM',
    '03469': 'PWM',
    '03470': 'PWM',
    '03561': 'PWM',
    '03570': 'PWM',
    '03574': 'PWM',
    '03575': 'PWM',
    '03576': 'PWM',
    '03579': 'PWM',
    '03580': 'PWM',
    '03581': 'PWM',
    '03582': 'PWM',
    '03583': 'PWM',
    '03584': 'PWM',
    '03585': 'PWM',
    '03586': 'PWM',
    '03587': 'PWM',
    '03588': 'PWM',
    '03589': 'PWM',
    '03590': 'PWM',
    '03592': 'PWM',
    '03593': 'PWM',
    '03595': 'PWM',
    '03597': 'PWM',
    '03598': 'PWM',
    '03601': 'PWM',
    '03602': 'PWM',
    '03603': 'PWM',
    '03604': 'PWM',
    '03605': 'PWM',
    '03607': 'PWM',
    '03608': 'PWM',
    '03609': 'PWM',
    '03740': 'PWM',
    '03741': 'PWM',
    '03743': 'PWM',
    '03745': 'PWM',
    '03746': 'PWM',
    '03748': 'PWM',
    '03749': 'PWM',
    '03750': 'PWM',
    '03751': 'PWM',
    '03752': 'PWM',
    '03753': 'PWM',
    '03754': 'PWM',
    '03755': 'PWM',
    '03756': 'PWM',
    '03765': 'PWM',
    '03766': 'PWM',
    '03768': 'PWM',
    '03769': 'PWM',
    '03770': 'PWM',
    '03771': 'PWM',
    '03773': 'PWM',
    '03774': 'PWM',
    '03777': 'PWM',
    '03779': 'PWM',
    '03780': 'PWM',
    '03781': 'PWM',
    '03782': 'PWM',
    '03784': 'PWM',
    '03785': 'PWM',
    '03801': 'PWM',
    '03802': 'PWM',
    '03803': 'PWM',
    '03804': 'PWM',
    '03805': 'PWM',
    '03809': 'PWM',
    '03810': 'PWM',
    '03811': 'PWM',
    '03812': 'PWM',
    '03813': 'PWM',
    '03814': 'PWM',
    '03815': 'PWM',
    '03816': 'PWM',
    '03817': 'PWM',
    '03818': 'PWM',
    '03819': 'PWM',
    '03820': 'PWM',
    '03821': 'PWM',
    '03822': 'PWM',
    '03823': 'PWM',
    '03824': 'PWM',
    '03825': 'PWM',
    '03826': 'PWM',
    '03827': 'PWM',
    '03830': 'PWM',
    '03832': 'PWM',
    '03833': 'PWM',
    '03835': 'PWM',
    '03836': 'PWM',
    '03837': 'PWM',
    '03838': 'PWM',
    '03839': 'PWM',
    '03840': 'PWM',
    '03841': 'PWM',
    '03842': 'PWM',
    '03843': 'PWM',
    '03844': 'PWM',
    '03845': 'PWM',
    '03846': 'PWM',
    '03847': 'PWM',
    '03848': 'PWM',
    '03849': 'PWM',
    '03850': 'PWM',
    '03851': 'PWM',
    '03852': 'PWM',
    '03853': 'PWM',
    '03854': 'PWM',
    '03855': 'PWM',
    '03856': 'PWM',
    '03857': 'PWM',
    '03858': 'PWM',
    '03859': 'PWM',
    '03860': 'PWM',
    '03861': 'PWM',
    '03862': 'PWM',
    '03864': 'PWM',
    '03865': 'PWM',
    '03866': 'PWM',
    '03867': 'PWM',
    '03868': 'PWM',
    '03869': 'PWM',
    '03870': 'PWM',
    '03871': 'PWM',
    '03872': 'PWM',
    '03873': 'PWM',
    '03874': 'PWM',
    '03875': 'PWM',
    '03878': 'PWM',
    '03882': 'PWM',
    '03883': 'PWM',
    '03884': 'PWM',
    '03885': 'PWM',
    '03886': 'PWM',
    '03887': 'PWM',
    '03890': 'PWM',
    '03894': 'PWM',
    '03896': 'PWM',
    '03897': 'PWM',
    '03901': 'PWM',
    '03902': 'PWM',
    '03903': 'PWM',
    '03904': 'PWM',
    '03905': 'PWM',
    '03906': 'PWM',
    '03907': 'PWM',
    '03908': 'PWM',
    '03909': 'PWM',
    '03910': 'PWM',
    '03911': 'PWM',
    '04001': 'PWM',
    '04002': 'PWM',
    '04003': 'PWM',
    '04004': 'PWM',
    '04005': 'PWM',
    '04006': 'PWM',
    '04007': 'PWM',
    '04008': 'PWM',
    '04009': 'PWM',
    '04010': 'PWM',
    '04011': 'PWM',
    '04013': 'PWM',
    '04014': 'PWM',
    '04015': 'PWM',
    '04016': 'PWM',
    '04017': 'PWM',
    '04019': 'PWM',
    '04020': 'PWM',
    '04021': 'PWM',
    '04022': 'PWM',
    '04024': 'PWM',
    '04027': 'PWM',
    '04028': 'PWM',
    '04029': 'PWM',
    '04030': 'PWM',
    '04032': 'PWM',
    '04033': 'PWM',
    '04034': 'PWM',
    '04037': 'PWM',
    '04038': 'PWM',
    '04039': 'PWM',
    '04040': 'PWM',
    '04041': 'PWM',
    '04042': 'PWM',
    '04043': 'PWM',
    '04046': 'PWM',
    '04047': 'PWM',
    '04048': 'PWM',
    '04049': 'PWM',
    '04050': 'PWM',
    '04051': 'PWM',
    '04053': 'PWM',
    '04054': 'PWM',
    '04055': 'PWM',
    '04056': 'PWM',
    '04057': 'PWM',
    '04061': 'PWM',
    '04062': 'PWM',
    '04063': 'PWM',
    '04064': 'PWM',
    '04066': 'PWM',
    '04068': 'PWM',
    '04069': 'PWM',
    '04070': 'PWM',
    '04071': 'PWM',
    '04072': 'PWM',
    '04073': 'PWM',
    '04074': 'PWM',
    '04075': 'PWM',
    '04076': 'PWM',
    '04077': 'PWM',
    '04078': 'PWM',
    '04079': 'PWM',
    '04081': 'PWM',
    '04082': 'PWM',
    '04083': 'PWM',
    '04084': 'PWM',
    '04085': 'PWM',
    '04086': 'PWM',
    '04087': 'PWM',
    '04088': 'PWM',
    '04090': 'PWM',
    '04091': 'PWM',
    '04092': 'PWM',
    '04093': 'PWM',
    '04094': 'PWM',
    '04095': 'PWM',
    '04096': 'PWM',
    '04097': 'PWM',
    '04098': 'PWM',
    '04101': 'PWM',
    '04102': 'PWM',
    '04103': 'PWM',
    '04104': 'PWM',
    '04105': 'PWM',
    '04106': 'PWM',
    '04107': 'PWM',
    '04108': 'PWM',
    '04109': 'PWM',
    '04110': 'PWM',
    '04112': 'PWM',
    '04116': 'PWM',
    '04122': 'PWM',
    '04123': 'PWM',
    '04124': 'PWM',
    '04210': 'PWM',
    '04211': 'PWM',
    '04212': 'PWM',
    '04216': 'PWM',
    '04217': 'PWM',
    '04219': 'PWM',
    '04220': 'PWM',
    '04221': 'PWM',
    '04222': 'PWM',
    '04223': 'PWM',
    '04224': 'PWM',
    '04225': 'PWM',
    '04226': 'PWM',
    '04227': 'PWM',
    '04228': 'PWM',
    '04230': 'PWM',
    '04231': 'PWM',
    '04234': 'PWM',
    '04236': 'PWM',
    '04237': 'PWM',
    '04238': 'PWM',
    '04239': 'PWM',
    '04240': 'PWM',
    '04241': 'PWM',
    '04243': 'PWM',
    '04250': 'PWM',
    '04252': 'PWM',
    '04253': 'PWM',
    '04254': 'PWM',
    '04255': 'PWM',
    '04256': 'PWM',
    '04257': 'PWM',
    '04258': 'PWM',
    '04259': 'PWM',
    '04260': 'PWM',
    '04261': 'PWM',
    '04262': 'PWM',
    '04263': 'PWM',
    '04265': 'PWM',
    '04266': 'PWM',
    '04267': 'PWM',
    '04268': 'PWM',
    '04270': 'PWM',
    '04271': 'PWM',
    '04274': 'PWM',
    '04275': 'PWM',
    '04276': 'PWM',
    '04278': 'PWM',
    '04280': 'PWM',
    '04281': 'PWM',
    '04282': 'PWM',
    '04283': 'PWM',
    '04284': 'PWM',
    '04285': 'PWM',
    '04286': 'PWM',
    '04287': 'PWM',
    '04288': 'PWM',
    '04289': 'PWM',
    '04290': 'PWM',
    '04291': 'PWM',
    '04292': 'PWM',
    '04294': 'PWM',
    '04330': 'PWM',
    '04332': 'PWM',
    '04333': 'PWM',
    '04336': 'PWM',
    '04338': 'PWM',
    '04341': 'PWM',
    '04342': 'PWM',
    '04343': 'PWM',
    '04344': 'PWM',
    '04345': 'PWM',
    '04346': 'PWM',
    '04347': 'PWM',
    '04348': 'PWM',
    '04349': 'PWM',
    '04350': 'PWM',
    '04351': 'PWM',
    '04352': 'PWM',
    '04353': 'PWM',
    '04354': 'PWM',
    '04355': 'PWM',
    '04357': 'PWM',
    '04358': 'PWM',
    '04359': 'PWM',
    '04360': 'PWM',
    '04363': 'PWM',
    '04364': 'PWM',
    '04401': 'PWM',
    '04402': 'PWM',
    '04406': 'PWM',
    '04408': 'PWM',
    '04410': 'PWM',
    '04411': 'PWM',
    '04412': 'PWM',
    '04413': 'PWM',
    '04414': 'PWM',
    '04415': 'PWM',
    '04416': 'PWM',
    '04417': 'PWM',
    '04418': 'PWM',
    '04419': 'PWM',
    '04420': 'PWM',
    '04421': 'PWM',
    '04422': 'PWM',
    '04423': 'PWM',
    '04424': 'PWM',
    '04426': 'PWM',
    '04427': 'PWM',
    '04428': 'PWM',
    '04429': 'PWM',
    '04430': 'PWM',
    '04431': 'PWM',
    '04434': 'PWM',
    '04435': 'PWM',
    '04438': 'PWM',
    '04441': 'PWM',
    '04442': 'PWM',
    '04443': 'PWM',
    '04444': 'PWM',
    '04448': 'PWM',
    '04449': 'PWM',
    '04450': 'PWM',
    '04451': 'PWM',
    '04453': 'PWM',
    '04454': 'PWM',
    '04455': 'PWM',
    '04456': 'PWM',
    '04457': 'PWM',
    '04459': 'PWM',
    '04460': 'PWM',
    '04461': 'PWM',
    '04462': 'PWM',
    '04463': 'PWM',
    '04464': 'PWM',
    '04467': 'PWM',
    '04468': 'PWM',
    '04469': 'PWM',
    '04471': 'PWM',
    '04472': 'PWM',
    '04473': 'PWM',
    '04474': 'PWM',
    '04475': 'PWM',
    '04476': 'PWM',
    '04478': 'PWM',
    '04479': 'PWM',
    '04481': 'PWM',
    '04485': 'PWM',
    '04487': 'PWM',
    '04488': 'PWM',
    '04489': 'PWM',
    '04490': 'PWM',
    '04491': 'PWM',
    '04492': 'PWM',
    '04493': 'PWM',
    '04495': 'PWM',
    '04496': 'PWM',
    '04497': 'PWM',
    '04530': 'PWM',
    '04535': 'PWM',
    '04536': 'PWM',
    '04537': 'PWM',
    '04538': 'PWM',
    '04539': 'PWM',
    '04541': 'PWM',
    '04543': 'PWM',
    '04544': 'PWM',
    '04547': 'PWM',
    '04548': 'PWM',
    '04549': 'PWM',
    '04551': 'PWM',
    '04552': 'PWM',
    '04553': 'PWM',
    '04554': 'PWM',
    '04555': 'PWM',
    '04556': 'PWM',
    '04558': 'PWM',
    '04562': 'PWM',
    '04563': 'PWM',
    '04564': 'PWM',
    '04565': 'PWM',
    '04567': 'PWM',
    '04568': 'PWM',
    '04570': 'PWM',
    '04571': 'PWM',
    '04572': 'PWM',
    '04573': 'PWM',
    '04574': 'PWM',
    '04575': 'PWM',
    '04576': 'PWM',
    '04578': 'PWM',
    '04579': 'PWM',
    '04605': 'PWM',
    '04606': 'PWM',
    '04607': 'PWM',
    '04609': 'PWM',
    '04611': 'PWM',
    '04612': 'PWM',
    '04613': 'PWM',
    '04614': 'PWM',
    '04615': 'PWM',
    '04616': 'PWM',
    '04617': 'PWM',
    '04619': 'PWM',
    '04622': 'PWM',
    '04623': 'PWM',
    '04624': 'PWM',
    '04625': 'PWM',
    '04626': 'PWM',
    '04627': 'PWM',
    '04628': 'PWM',
    '04629': 'PWM',
    '04630': 'PWM',
    '04631': 'PWM',
    '04634': 'PWM',
    '04635': 'PWM',
    '04637': 'PWM',
    '04640': 'PWM',
    '04642': 'PWM',
    '04643': 'PWM',
    '04644': 'PWM',
    '04645': 'PWM',
    '04646': 'PWM',
    '04648': 'PWM',
    '04649': 'PWM',
    '04650': 'PWM',
    '04652': 'PWM',
    '04653': 'PWM',
    '04654': 'PWM',
    '04655': 'PWM',
    '04656': 'PWM',
    '04657': 'PWM',
    '04658': 'PWM',
    '04660': 'PWM',
    '04662': 'PWM',
    '04664': 'PWM',
    '04665': 'PWM',
    '04666': 'PWM',
    '04667': 'PWM',
    '04668': 'PWM',
    '04669': 'PWM',
    '04671': 'PWM',
    '04672': 'PWM',
    '04673': 'PWM',
    '04674': 'PWM',
    '04675': 'PWM',
    '04676': 'PWM',
    '04677': 'PWM',
    '04679': 'PWM',
    '04680': 'PWM',
    '04681': 'PWM',
    '04683': 'PWM',
    '04684': 'PWM',
    '04685': 'PWM',
    '04686': 'PWM',
    '04690': 'PWM',
    '04691': 'PWM',
    '04693': 'PWM',
    '04694': 'PWM',
    '04730': 'PWM',
    '04732': 'PWM',
    '04733': 'PWM',
    '04734': 'PWM',
    '04735': 'PWM',
    '04736': 'PWM',
    '04737': 'PWM',
    '04738': 'PWM',
    '04739': 'PWM',
    '04740': 'PWM',
    '04741': 'PWM',
    '04742': 'PWM',
    '04743': 'PWM',
    '04744': 'PWM',
    '04745': 'PWM',
    '04746': 'PWM',
    '04747': 'PWM',
    '04750': 'PWM',
    '04751': 'PWM',
    '04756': 'PWM',
    '04757': 'PWM',
    '04758': 'PWM',
    '04759': 'PWM',
    '04760': 'PWM',
    '04761': 'PWM',
    '04762': 'PWM',
    '04763': 'PWM',
    '04764': 'PWM',
    '04765': 'PWM',
    '04766': 'PWM',
    '04768': 'PWM',
    '04769': 'PWM',
    '04770': 'PWM',
    '04772': 'PWM',
    '04773': 'PWM',
    '04774': 'PWM',
    '04775': 'PWM',
    '04776': 'PWM',
    '04777': 'PWM',
    '04779': 'PWM',
    '04780': 'PWM',
    '04781': 'PWM',
    '04782': 'PWM',
    '04783': 'PWM',
    '04785': 'PWM',
    '04786': 'PWM',
    '04787': 'PWM',
    '04788': 'PWM',
    '04841': 'PWM',
    '04843': 'PWM',
    '04846': 'PWM',
    '04847': 'PWM',
    '04848': 'PWM',
    '04849': 'PWM',
    '04850': 'PWM',
    '04851': 'PWM',
    '04852': 'PWM',
    '04853': 'PWM',
    '04854': 'PWM',
    '04855': 'PWM',
    '04856': 'PWM',
    '04858': 'PWM',
    '04859': 'PWM',
    '04860': 'PWM',
    '04861': 'PWM',
    '04862': 'PWM',
    '04863': 'PWM',
    '04864': 'PWM',
    '04865': 'PWM',
    '04901': 'PWM',
    '04903': 'PWM',
    '04910': 'PWM',
    '04911': 'PWM',
    '04912': 'PWM',
    '04915': 'PWM',
    '04917': 'PWM',
    '04918': 'PWM',
    '04920': 'PWM',
    '04921': 'PWM',
    '04922': 'PWM',
    '04923': 'PWM',
    '04924': 'PWM',
    '04925': 'PWM',
    '04926': 'PWM',
    '04927': 'PWM',
    '04928': 'PWM',
    '04929': 'PWM',
    '04930': 'PWM',
    '04932': 'PWM',
    '04933': 'PWM',
    '04935': 'PWM',
    '04936': 'PWM',
    '04937': 'PWM',
    '04938': 'PWM',
    '04939': 'PWM',
    '04940': 'PWM',
    '04941': 'PWM',
    '04942': 'PWM',
    '04943': 'PWM',
    '04944': 'PWM',
    '04945': 'PWM',
    '04947': 'PWM',
    '04949': 'PWM',
    '04950': 'PWM',
    '04951': 'PWM',
    '04952': 'PWM',
    '04953': 'PWM',
    '04954': 'PWM',
    '04955': 'PWM',
    '04956': 'PWM',
    '04957': 'PWM',
    '04958': 'PWM',
    '04961': 'PWM',
    '04962': 'PWM',
    '04963': 'PWM',
    '04964': 'PWM',
    '04965': 'PWM',
    '04966': 'PWM',
    '04967': 'PWM',
    '04969': 'PWM',
    '04970': 'PWM',
    '04971': 'PWM',
    '04972': 'PWM',
    '04973': 'PWM',
    '04974': 'PWM',
    '04975': 'PWM',
    '04976': 'PWM',
    '04978': 'PWM',
    '04979': 'PWM',
    '04981': 'PWM',
    '04982': 'PWM',
    '04983': 'PWM',
    '04984': 'PWM',
    '04985': 'PWM',
    '04986': 'PWM',
    '04987': 'PWM',
    '04988': 'PWM',
    '04989': 'PWM',
    '04992': 'BGR',
    '05001': 'BTV',
    '05009': 'BTV',
    '05030': 'ALB',
    '05031': 'BTV',
    '05032': 'BTV',
    '05033': 'BTV',
    '05034': 'BTV',
    '05035': 'BTV',
    '05036': 'BTV',
    '05037': 'ALB',
    '05038': 'BTV',
    '05039': 'BTV',
    '05040': 'BTV',
    '05041': 'BTV',
    '05042': 'BTV',
    '05043': 'BTV',
    '05045': 'BTV',
    '05046': 'BTV',
    '05047': 'BTV',
    '05048': 'BTV',
    '05049': 'BTV',
    '05050': 'BTV',
    '05051': 'BTV',
    '05052': 'BTV',
    '05053': 'BTV',
    '05054': 'BTV',
    '05055': 'BTV',
    '05056': 'BTV',
    '05058': 'BTV',
    '05059': 'BTV',
    '05060': 'BTV',
    '05061': 'BTV',
    '05062': 'ALB',
    '05065': 'BTV',
    '05067': 'BTV',
    '05068': 'BTV',
    '05069': 'BTV',
    '05070': 'BTV',
    '05071': 'BTV',
    '05072': 'BTV',
    '05073': 'BTV',
    '05074': 'BTV',
    '05075': 'BTV',
    '05076': 'BTV',
    '05077': 'BTV',
    '05079': 'BTV',
    '05081': 'BTV',
    '05083': 'BTV',
    '05084': 'BTV',
    '05085': 'BTV',
    '05086': 'BTV',
    '05088': 'BTV',
    '05089': 'ALB',
    '05091': 'BTV',
    '05101': 'ALB',
    '05141': 'ALB',
    '05142': 'ALB',
    '05143': 'ALB',
    '05144': 'ALB',
    '05146': 'ALB',
    '05148': 'ALB',
    '05149': 'ALB',
    '05150': 'ALB',
    '05151': 'ALB',
    '05152': 'ALB',
    '05153': 'ALB',
    '05154': 'ALB',
    '05155': 'ALB',
    '05156': 'ALB',
    '05158': 'ALB',
    '05159': 'ALB',
    '05161': 'ALB',
    '05201': 'ALB',
    '05250': 'ALB',
    '05251': 'ALB',
    '05252': 'ALB',
    '05253': 'ALB',
    '05254': 'ALB',
    '05255': 'ALB',
    '05257': 'ALB',
    '05260': 'ALB',
    '05261': 'ALB',
    '05262': 'ALB',
    '05301': 'ALB',
    '05302': 'ALB',
    '05303': 'ALB',
    '05304': 'ALB',
    '05340': 'ALB',
    '05341': 'ALB',
    '05342': 'ALB',
    '05343': 'ALB',
    '05344': 'ALB',
    '05345': 'ALB',
    '05346': 'ALB',
    '05350': 'ALB',
    '05351': 'ALB',
    '05352': 'ALB',
    '05353': 'ALB',
    '05354': 'ALB',
    '05355': 'ALB',
    '05356': 'ALB',
    '05357': 'ALB',
    '05358': 'ALB',
    '05359': 'ALB',
    '05360': 'ALB',
    '05361': 'ALB',
    '05362': 'ALB',
    '05363': 'ALB',
    '05401': 'BTV',
    '05402': 'BTV',
    '05403': 'BTV',
    '05404': 'BTV',
    '05405': 'BTV',
    '05406': 'BTV',
    '05407': 'BTV',
    '05408': 'BTV',
    '05439': 'BTV',
    '05440': 'BTV',
    '05441': 'BTV',
    '05442': 'BTV',
    '05443': 'BTV',
    '05444': 'BTV',
    '05445': 'BTV',
    '05446': 'BTV',
    '05447': 'BTV',
    '05448': 'BTV',
    '05449': 'BTV',
    '05450': 'BTV',
    '05451': 'BTV',
    '05452': 'BTV',
    '05453': 'BTV',
    '05454': 'BTV',
    '05455': 'BTV',
    '05456': 'BTV',
    '05457': 'BTV',
    '05458': 'BTV',
    '05459': 'BTV',
    '05460': 'BTV',
    '05461': 'BTV',
    '05462': 'BTV',
    '05463': 'BTV',
    '05464': 'BTV',
    '05465': 'BTV',
    '05466': 'BTV',
    '05468': 'BTV',
    '05469': 'BTV',
    '05470': 'BTV',
    '05471': 'BTV',
    '05472': 'BTV',
    '05473': 'BTV',
    '05474': 'BTV',
    '05476': 'BTV',
    '05477': 'BTV',
    '05478': 'BTV',
    '05479': 'BTV',
    '05481': 'BTV',
    '05482': 'BTV',
    '05483': 'BTV',
    '05485': 'BTV',
    '05486': 'BTV',
    '05487': 'BTV',
    '05488': 'BTV',
    '05489': 'BTV',
    '05490': 'BTV',
    '05491': 'BTV',
    '05492': 'BTV',
    '05494': 'BTV',
    '05495': 'BTV',
    '05501': 'BDL',
    '05544': 'BTV',
    '05601': 'BTV',
    '05602': 'BTV',
    '05603': 'BTV',
    '05604': 'BTV',
    '05609': 'BTV',
    '05620': 'BTV',
    '05633': 'BTV',
    '05640': 'BTV',
    '05641': 'BTV',
    '05647': 'BTV',
    '05648': 'BTV',
    '05649': 'BTV',
    '05650': 'BTV',
    '05651': 'BTV',
    '05652': 'BTV',
    '05653': 'BTV',
    '05654': 'BTV',
    '05655': 'BTV',
    '05656': 'BTV',
    '05657': 'BTV',
    '05658': 'BTV',
    '05660': 'BTV',
    '05661': 'BTV',
    '05662': 'BTV',
    '05663': 'BTV',
    '05664': 'BTV',
    '05665': 'BTV',
    '05666': 'BTV',
    '05667': 'BTV',
    '05669': 'BTV',
    '05670': 'BTV',
    '05671': 'BTV',
    '05672': 'BTV',
    '05673': 'BTV',
    '05674': 'BTV',
    '05675': 'BTV',
    '05676': 'BTV',
    '05677': 'BTV',
    '05678': 'BTV',
    '05679': 'BTV',
    '05680': 'BTV',
    '05681': 'BTV',
    '05682': 'BTV',
    '05701': 'BTV',
    '05702': 'BTV',
    '05730': 'ALB',
    '05731': 'BTV',
    '05732': 'BTV',
    '05733': 'BTV',
    '05734': 'BTV',
    '05735': 'BTV',
    '05736': 'BTV',
    '05737': 'BTV',
    '05738': 'BTV',
    '05739': 'ALB',
    '05740': 'BTV',
    '05741': 'ALB',
    '05742': 'ALB',
    '05743': 'BTV',
    '05744': 'BTV',
    '05745': 'BTV',
    '05746': 'BTV',
    '05747': 'BTV',
    '05748': 'BTV',
    '05750': 'BTV',
    '05751': 'BTV',
    '05753': 'BTV',
    '05757': 'ALB',
    '05758': 'ALB',
    '05759': 'BTV',
    '05760': 'BTV',
    '05761': 'ALB',
    '05762': 'BTV',
    '05763': 'BTV',
    '05764': 'ALB',
    '05765': 'BTV',
    '05766': 'BTV',
    '05767': 'BTV',
    '05768': 'ALB',
    '05769': 'BTV',
    '05770': 'BTV',
    '05772': 'BTV',
    '05773': 'ALB',
    '05774': 'ALB',
    '05775': 'ALB',
    '05776': 'ALB',
    '05777': 'BTV',
    '05778': 'BTV',
    '05819': 'BTV',
    '05820': 'BTV',
    '05821': 'BTV',
    '05822': 'BTV',
    '05823': 'BTV',
    '05824': 'BTV',
    '05825': 'BTV',
    '05826': 'BTV',
    '05827': 'BTV',
    '05828': 'BTV',
    '05829': 'BTV',
    '05830': 'BTV',
    '05832': 'BTV',
    '05833': 'BTV',
    '05836': 'BTV',
    '05837': 'BTV',
    '05838': 'BTV',
    '05839': 'BTV',
    '05840': 'BTV',
    '05841': 'BTV',
    '05842': 'BTV',
    '05843': 'BTV',
    '05845': 'BTV',
    '05846': 'BTV',
    '05847': 'BTV',
    '05848': 'BTV',
    '05849': 'BTV',
    '05850': 'BTV',
    '05851': 'BTV',
    '05853': 'BTV',
    '05855': 'BTV',
    '05857': 'BTV',
    '05858': 'BTV',
    '05859': 'BTV',
    '05860': 'BTV',
    '05861': 'BTV',
    '05862': 'BTV',
    '05863': 'BTV',
    '05866': 'BTV',
    '05867': 'BTV',
    '05868': 'BTV',
    '05871': 'BTV',
    '05872': 'BTV',
    '05873': 'BTV',
    '05874': 'BTV',
    '05875': 'BTV',
    '05901': 'BTV',
    '05902': 'BTV',
    '05903': 'BTV',
    '05904': 'BTV',
    '05905': 'BTV',
    '05906': 'BTV',
    '05907': 'BTV',
    '06001': 'BDL',
    '06002': 'BDL',
    '06006': 'BDL',
    '06010': 'BDL',
    '06011': 'BDL',
    '06013': 'BDL',
    '06016': 'BDL',
    '06018': 'BDL',
    '06019': 'BDL',
    '06020': 'BDL',
    '06021': 'BDL',
    '06022': 'BDL',
    '06023': 'BDL',
    '06024': 'BDL',
    '06025': 'BDL',
    '06026': 'BDL',
    '06027': 'BDL',
    '06028': 'BDL',
    '06029': 'BDL',
    '06030': 'BDL',
    '06031': 'BDL',
    '06032': 'BDL',
    '06033': 'BDL',
    '06034': 'BDL',
    '06035': 'BDL',
    '06037': 'BDL',
    '06039': 'BDL',
    '06040': 'BDL',
    '06041': 'BDL',
    '06042': 'BDL',
    '06043': 'BDL',
    '06045': 'BDL',
    '06050': 'BDL',
    '06051': 'BDL',
    '06052': 'BDL',
    '06053': 'BDL',
    '06057': 'BDL',
    '06058': 'BDL',
    '06059': 'BDL',
    '06060': 'BDL',
    '06061': 'BDL',
    '06062': 'BDL',
    '06063': 'BDL',
    '06064': 'BDL',
    '06065': 'BDL',
    '06066': 'BDL',
    '06067': 'BDL',
    '06068': 'BDL',
    '06069': 'BDL',
    '06070': 'BDL',
    '06071': 'BDL',
    '06072': 'BDL',
    '06073': 'BDL',
    '06074': 'BDL',
    '06075': 'BDL',
    '06076': 'BDL',
    '06077': 'BDL',
    '06078': 'BDL',
    '06079': 'BDL',
    '06080': 'BDL',
    '06081': 'BDL',
    '06082': 'BDL',
    '06083': 'BDL',
    '06084': 'BDL',
    '06085': 'BDL',
    '06087': 'BDL',
    '06088': 'BDL',
    '06089': 'BDL',
    '06090': 'BDL',
    '06091': 'BDL',
    '06092': 'BDL',
    '06093': 'BDL',
    '06094': 'BDL',
    '06095': 'BDL',
    '06096': 'BDL',
    '06098': 'BDL',
    '06101': 'BDL',
    '06102': 'BDL',
    '06103': 'BDL',
    '06104': 'BDL',
    '06105': 'BDL',
    '06106': 'BDL',
    '06107': 'BDL',
    '06108': 'BDL',
    '06109': 'BDL',
    '06110': 'BDL',
    '06111': 'BDL',
    '06112': 'BDL',
    '06114': 'BDL',
    '06115': 'BDL',
    '06117': 'BDL',
    '06118': 'BDL',
    '06119': 'BDL',
    '06120': 'BDL',
    '06123': 'BDL',
    '06126': 'BDL',
    '06127': 'BDL',
    '06128': 'BDL',
    '06129': 'BDL',
    '06131': 'BDL',
    '06132': 'BDL',
    '06133': 'BDL',
    '06134': 'BDL',
    '06137': 'BDL',
    '06138': 'BDL',
    '06140': 'BDL',
    '06141': 'BDL',
    '06142': 'BDL',
    '06143': 'BDL',
    '06144': 'BDL',
    '06145': 'BDL',
    '06146': 'BDL',
    '06147': 'BDL',
    '06150': 'BDL',
    '06151': 'BDL',
    '06152': 'BDL',
    '06153': 'BDL',
    '06154': 'BDL',
    '06155': 'BDL',
    '06156': 'BDL',
    '06160': 'BDL',
    '06161': 'BDL',
    '06167': 'BDL',
    '06176': 'BDL',
    '06180': 'BDL',
    '06183': 'BDL',
    '06199': 'BDL',
    '06226': 'BDL',
    '06230': 'BDL',
    '06231': 'BDL',
    '06232': 'BDL',
    '06233': 'BDL',
    '06234': 'BDL',
    '06235': 'BDL',
    '06237': 'BDL',
    '06238': 'BDL',
    '06239': 'BDL',
    '06241': 'BDL',
    '06242': 'BDL',
    '06243': 'BDL',
    '06244': 'BDL',
    '06245': 'BDL',
    '06246': 'BDL',
    '06247': 'BDL',
    '06248': 'BDL',
    '06249': 'BDL',
    '06250': 'BDL',
    '06251': 'BDL',
    '06254': 'BDL',
    '06255': 'BDL',
    '06256': 'BDL',
    '06258': 'BDL',
    '06259': 'BDL',
    '06260': 'BDL',
    '06262': 'BDL',
    '06263': 'BDL',
    '06264': 'BDL',
    '06265': 'BDL',
    '06266': 'BDL',
    '06267': 'BDL',
    '06268': 'BDL',
    '06269': 'BDL',
    '06277': 'BDL',
    '06278': 'BDL',
    '06279': 'BDL',
    '06280': 'BDL',
    '06281': 'BDL',
    '06282': 'BDL',
    '06320': 'BDL',
    '06330': 'BDL',
    '06331': 'BDL',
    '06332': 'BDL',
    '06333': 'BDL',
    '06334': 'BDL',
    '06335': 'BDL',
    '06336': 'BDL',
    '06338': 'BDL',
    '06339': 'BDL',
    '06340': 'BDL',
    '06349': 'BDL',
    '06350': 'BDL',
    '06351': 'BDL',
    '06353': 'BDL',
    '06354': 'BDL',
    '06355': 'BDL',
    '06357': 'BDL',
    '06359': 'BDL',
    '06360': 'BDL',
    '06365': 'BDL',
    '06370': 'BDL',
    '06371': 'BDL',
    '06372': 'BDL',
    '06373': 'BDL',
    '06374': 'BDL',
    '06375': 'BDL',
    '06376': 'BDL',
    '06377': 'BDL',
    '06378': 'BDL',
    '06379': 'BDL',
    '06380': 'BDL',
    '06382': 'BDL',
    '06383': 'BDL',
    '06384': 'BDL',
    '06385': 'BDL',
    '06386': 'BDL',
    '06387': 'BDL',
    '06388': 'BDL',
    '06389': 'BDL',
    '06390': 'BDL',
    '06401': 'BDL',
    '06403': 'BDL',
    '06404': 'BDL',
    '06405': 'BDL',
    '06408': 'BDL',
    '06409': 'BDL',
    '06410': 'BDL',
    '06411': 'BDL',
    '06412': 'BDL',
    '06413': 'BDL',
    '06414': 'BDL',
    '06415': 'BDL',
    '06416': 'BDL',
    '06417': 'BDL',
    '06418': 'BDL',
    '06419': 'BDL',
    '06420': 'BDL',
    '06422': 'BDL',
    '06423': 'BDL',
    '06424': 'BDL',
    '06426': 'BDL',
    '06437': 'BDL',
    '06438': 'BDL',
    '06439': 'BDL',
    '06440': 'BDL',
    '06441': 'BDL',
    '06442': 'BDL',
    '06443': 'BDL',
    '06444': 'BDL',
    '06447': 'BDL',
    '06450': 'BDL',
    '06451': 'BDL',
    '06454': 'BDL',
    '06455': 'BDL',
    '06456': 'BDL',
    '06457': 'BDL',
    '06459': 'BDL',
    '06460': 'BDL',
    '06461': 'BDL',
    '06467': 'BDL',
    '06468': 'BDL',
    '06469': 'BDL',
    '06470': 'BDL',
    '06471': 'BDL',
    '06472': 'BDL',
    '06473': 'BDL',
    '06474': 'BDL',
    '06475': 'BDL',
    '06477': 'BDL',
    '06478': 'BDL',
    '06479': 'BDL',
    '06480': 'BDL',
    '06481': 'BDL',
    '06482': 'BDL',
    '06483': 'BDL',
    '06484': 'BDL',
    '06487': 'BDL',
    '06488': 'BDL',
    '06489': 'BDL',
    '06491': 'BDL',
    '06492': 'BDL',
    '06493': 'BDL',
    '06494': 'BDL',
    '06495': 'BDL',
    '06497': 'BDL',
    '06498': 'BDL',
    '06501': 'BDL',
    '06502': 'BDL',
    '06503': 'BDL',
    '06504': 'BDL',
    '06505': 'BDL',
    '06506': 'BDL',
    '06507': 'BDL',
    '06508': 'BDL',
    '06509': 'BDL',
    '06510': 'BDL',
    '06511': 'BDL',
    '06512': 'BDL',
    '06513': 'BDL',
    '06514': 'BDL',
    '06515': 'BDL',
    '06516': 'BDL',
    '06517': 'BDL',
    '06518': 'BDL',
    '06519': 'BDL',
    '06520': 'BDL',
    '06521': 'BDL',
    '06524': 'BDL',
    '06525': 'BDL',
    '06530': 'BDL',
    '06531': 'BDL',
    '06532': 'BDL',
    '06533': 'BDL',
    '06534': 'BDL',
    '06535': 'BDL',
    '06536': 'BDL',
    '06537': 'BDL',
    '06538': 'BDL',
    '06540': 'BDL',
    '06601': 'BDL',
    '06602': 'BDL',
    '06604': 'BDL',
    '06605': 'BDL',
    '06606': 'BDL',
    '06607': 'BDL',
    '06608': 'BDL',
    '06610': 'BDL',
    '06611': 'BDL',
    '06612': 'BDL',
    '06614': 'BDL',
    '06615': 'BDL',
    '06650': 'BDL',
    '06673': 'BDL',
    '06699': 'BDL',
    '06701': 'BDL',
    '06702': 'BDL',
    '06703': 'BDL',
    '06704': 'BDL',
    '06705': 'BDL',
    '06706': 'BDL',
    '06708': 'BDL',
    '06710': 'BDL',
    '06712': 'BDL',
    '06716': 'BDL',
    '06720': 'BDL',
    '06721': 'BDL',
    '06722': 'BDL',
    '06723': 'BDL',
    '06724': 'BDL',
    '06725': 'BDL',
    '06726': 'BDL',
    '06749': 'BDL',
    '06750': 'BDL',
    '06751': 'BDL',
    '06752': 'BDL',
    '06753': 'BDL',
    '06754': 'BDL',
    '06755': 'BDL',
    '06756': 'BDL',
    '06757': 'BDL',
    '06758': 'BDL',
    '06759': 'BDL',
    '06762': 'BDL',
    '06763': 'BDL',
    '06770': 'BDL',
    '06776': 'BDL',
    '06777': 'BDL',
    '06778': 'BDL',
    '06779': 'BDL',
    '06781': 'BDL',
    '06782': 'BDL',
    '06783': 'BDL',
    '06784': 'BDL',
    '06785': 'BDL',
    '06786': 'BDL',
    '06787': 'BDL',
    '06790': 'BDL',
    '06791': 'BDL',
    '06792': 'BDL',
    '06793': 'BDL',
    '06794': 'BDL',
    '06795': 'BDL',
    '06796': 'BDL',
    '06798': 'BDL',
    '06801': 'BDL',
    '06804': 'BDL',
    '06807': 'JFK',
    '06810': 'BDL',
    '06811': 'BDL',
    '06812': 'BDL',
    '06813': 'BDL',
    '06814': 'BDL',
    '06816': 'BDL',
    '06817': 'BDL',
    '06820': 'JFK',
    '06824': 'BDL',
    '06825': 'BDL',
    '06828': 'BDL',
    '06829': 'JFK',
    '06830': 'JFK',
    '06831': 'JFK',
    '06832': 'BDL',
    '06836': 'JFK',
    '06838': 'JFK',
    '06840': 'JFK',
    '06842': 'JFK',
    '06850': 'JFK',
    '06851': 'JFK',
    '06852': 'JFK',
    '06853': 'JFK',
    '06854': 'JFK',
    '06855': 'JFK',
    '06856': 'JFK',
    '06857': 'JFK',
    '06858': 'JFK',
    '06859': 'JFK',
    '06860': 'JFK',
    '06870': 'JFK',
    '06875': 'BDL',
    '06876': 'BDL',
    '06877': 'JFK',
    '06878': 'JFK',
    '06879': 'JFK',
    '06880': 'JFK',
    '06881': 'JFK',
    '06883': 'BDL',
    '06888': 'JFK',
    '06889': 'JFK',
    '06890': 'BDL',
    '06896': 'BDL',
    '06897': 'JFK',
    '06901': 'JFK',
    '06902': 'JFK',
    '06903': 'JFK',
    '06904': 'JFK',
    '06905': 'JFK',
    '06906': 'JFK',
    '06907': 'JFK',
    '06910': 'JFK',
    '06911': 'JFK',
    '06912': 'JFK',
    '06913': 'JFK',
    '06914': 'BDL',
    '06920': 'JFK',
    '06921': 'JFK',
    '06922': 'JFK',
    '06925': 'JFK',
    '06926': 'JFK',
    '06927': 'JFK',
    '06928': 'JFK',
    '07001': 'EWR',
    '07002': 'EWR',
    '07003': 'EWR',
    '07004': 'EWR',
    '07005': 'EWR',
    '07006': 'EWR',
    '07007': 'EWR',
    '07008': 'EWR',
    '07009': 'EWR',
    '07010': 'EWR',
    '07011': 'EWR',
    '07012': 'EWR',
    '07013': 'EWR',
    '07014': 'EWR',
    '07015': 'EWR',
    '07016': 'EWR',
    '07017': 'EWR',
    '07018': 'EWR',
    '07019': 'EWR',
    '07020': 'EWR',
    '07021': 'EWR',
    '07022': 'EWR',
    '07023': 'EWR',
    '07024': 'EWR',
    '07026': 'EWR',
    '07027': 'EWR',
    '07028': 'EWR',
    '07029': 'EWR',
    '07030': 'EWR',
    '07031': 'EWR',
    '07032': 'EWR',
    '07033': 'EWR',
    '07034': 'EWR',
    '07035': 'EWR',
    '07036': 'EWR',
    '07039': 'EWR',
    '07040': 'EWR',
    '07041': 'EWR',
    '07042': 'EWR',
    '07043': 'EWR',
    '07044': 'EWR',
    '07045': 'EWR',
    '07046': 'EWR',
    '07047': 'EWR',
    '07050': 'EWR',
    '07051': 'EWR',
    '07052': 'EWR',
    '07054': 'EWR',
    '07055': 'EWR',
    '07057': 'EWR',
    '07058': 'EWR',
    '07059': 'EWR',
    '07060': 'EWR',
    '07061': 'EWR',
    '07062': 'EWR',
    '07063': 'EWR',
    '07064': 'EWR',
    '07065': 'EWR',
    '07066': 'EWR',
    '07067': 'EWR',
    '07068': 'EWR',
    '07069': 'EWR',
    '07070': 'EWR',
    '07071': 'EWR',
    '07072': 'EWR',
    '07073': 'EWR',
    '07074': 'EWR',
    '07075': 'EWR',
    '07076': 'EWR',
    '07077': 'EWR',
    '07078': 'EWR',
    '07079': 'EWR',
    '07080': 'EWR',
    '07081': 'EWR',
    '07082': 'EWR',
    '07083': 'EWR',
    '07086': 'EWR',
    '07087': 'EWR',
    '07088': 'EWR',
    '07090': 'EWR',
    '07091': 'EWR',
    '07092': 'EWR',
    '07093': 'EWR',
    '07094': 'EWR',
    '07095': 'EWR',
    '07096': 'EWR',
    '07097': 'EWR',
    '07099': 'EWR',
    '07101': 'EWR',
    '07102': 'EWR',
    '07103': 'EWR',
    '07104': 'EWR',
    '07105': 'EWR',
    '07106': 'EWR',
    '07107': 'EWR',
    '07108': 'EWR',
    '07109': 'EWR',
    '07110': 'EWR',
    '07111': 'EWR',
    '07112': 'EWR',
    '07114': 'EWR',
    '07175': 'EWR',
    '07182': 'EWR',
    '07184': 'EWR',
    '07188': 'EWR',
    '07189': 'EWR',
    '07191': 'EWR',
    '07192': 'EWR',
    '07193': 'EWR',
    '07194': 'EWR',
    '07195': 'EWR',
    '07198': 'EWR',
    '07199': 'EWR',
    '07201': 'EWR',
    '07202': 'EWR',
    '07203': 'EWR',
    '07204': 'EWR',
    '07205': 'EWR',
    '07206': 'EWR',
    '07207': 'EWR',
    '07208': 'EWR',
    '07302': 'EWR',
    '07303': 'EWR',
    '07304': 'EWR',
    '07305': 'EWR',
    '07306': 'EWR',
    '07307': 'EWR',
    '07308': 'EWR',
    '07309': 'EWR',
    '07310': 'EWR',
    '07311': 'EWR',
    '07395': 'EWR',
    '07399': 'EWR',
    '07401': 'EWR',
    '07403': 'EWR',
    '07405': 'EWR',
    '07407': 'EWR',
    '07410': 'EWR',
    '07416': 'EWR',
    '07417': 'EWR',
    '07418': 'EWR',
    '07419': 'EWR',
    '07420': 'EWR',
    '07421': 'EWR',
    '07422': 'EWR',
    '07423': 'EWR',
    '07424': 'EWR',
    '07428': 'EWR',
    '07430': 'EWR',
    '07432': 'EWR',
    '07435': 'EWR',
    '07436': 'EWR',
    '07438': 'EWR',
    '07439': 'EWR',
    '07440': 'EWR',
    '07442': 'EWR',
    '07444': 'EWR',
    '07446': 'EWR',
    '07450': 'EWR',
    '07451': 'EWR',
    '07452': 'EWR',
    '07456': 'EWR',
    '07457': 'EWR',
    '07458': 'EWR',
    '07460': 'EWR',
    '07461': 'EWR',
    '07462': 'EWR',
    '07463': 'EWR',
    '07465': 'EWR',
    '07470': 'EWR',
    '07474': 'EWR',
    '07477': 'EWR',
    '07480': 'EWR',
    '07481': 'EWR',
    '07495': 'EWR',
    '07501': 'EWR',
    '07502': 'EWR',
    '07503': 'EWR',
    '07504': 'EWR',
    '07505': 'EWR',
    '07506': 'EWR',
    '07507': 'EWR',
    '07508': 'EWR',
    '07509': 'EWR',
    '07510': 'EWR',
    '07511': 'EWR',
    '07512': 'EWR',
    '07513': 'EWR',
    '07514': 'EWR',
    '07522': 'EWR',
    '07524': 'EWR',
    '07533': 'EWR',
    '07538': 'EWR',
    '07543': 'EWR',
    '07544': 'EWR',
    '07601': 'EWR',
    '07602': 'EWR',
    '07603': 'EWR',
    '07604': 'EWR',
    '07605': 'EWR',
    '07606': 'EWR',
    '07607': 'EWR',
    '07608': 'EWR',
    '07620': 'EWR',
    '07621': 'EWR',
    '07624': 'EWR',
    '07626': 'EWR',
    '07627': 'EWR',
    '07628': 'EWR',
    '07630': 'EWR',
    '07631': 'EWR',
    '07632': 'EWR',
    '07640': 'EWR',
    '07641': 'EWR',
    '07642': 'EWR',
    '07643': 'EWR',
    '07644': 'EWR',
    '07645': 'EWR',
    '07646': 'EWR',
    '07647': 'EWR',
    '07648': 'EWR',
    '07649': 'EWR',
    '07650': 'EWR',
    '07652': 'EWR',
    '07653': 'EWR',
    '07656': 'EWR',
    '07657': 'EWR',
    '07660': 'EWR',
    '07661': 'EWR',
    '07662': 'EWR',
    '07663': 'EWR',
    '07666': 'EWR',
    '07670': 'EWR',
    '07675': 'EWR',
    '07676': 'EWR',
    '07677': 'EWR',
    '07699': 'EWR',
    '07701': 'EWR',
    '07702': 'EWR',
    '07703': 'EWR',
    '07704': 'EWR',
    '07709': 'EWR',
    '07710': 'EWR',
    '07711': 'EWR',
    '07712': 'EWR',
    '07715': 'EWR',
    '07716': 'EWR',
    '07717': 'EWR',
    '07718': 'EWR',
    '07719': 'EWR',
    '07720': 'EWR',
    '07721': 'EWR',
    '07722': 'EWR',
    '07723': 'EWR',
    '07724': 'EWR',
    '07726': 'EWR',
    '07727': 'EWR',
    '07728': 'EWR',
    '07730': 'EWR',
    '07731': 'EWR',
    '07732': 'EWR',
    '07733': 'EWR',
    '07734': 'EWR',
    '07735': 'EWR',
    '07737': 'EWR',
    '07738': 'EWR',
    '07739': 'EWR',
    '07740': 'EWR',
    '07746': 'EWR',
    '07747': 'EWR',
    '07748': 'EWR',
    '07750': 'EWR',
    '07751': 'EWR',
    '07752': 'EWR',
    '07753': 'EWR',
    '07754': 'EWR',
    '07755': 'EWR',
    '07756': 'EWR',
    '07757': 'EWR',
    '07758': 'EWR',
    '07760': 'EWR',
    '07762': 'EWR',
    '07763': 'EWR',
    '07764': 'EWR',
    '07765': 'EWR',
    '07799': 'EWR',
    '07801': 'EWR',
    '07802': 'EWR',
    '07803': 'EWR',
    '07806': 'EWR',
    '07820': 'EWR',
    '07821': 'EWR',
    '07822': 'EWR',
    '07823': 'EWR',
    '07825': 'EWR',
    '07826': 'EWR',
    '07827': 'EWR',
    '07828': 'EWR',
    '07829': 'EWR',
    '07830': 'EWR',
    '07831': 'EWR',
    '07832': 'EWR',
    '07833': 'EWR',
    '07834': 'EWR',
    '07836': 'EWR',
    '07837': 'EWR',
    '07838': 'EWR',
    '07839': 'EWR',
    '07840': 'EWR',
    '07842': 'EWR',
    '07843': 'EWR',
    '07844': 'EWR',
    '07845': 'EWR',
    '07846': 'EWR',
    '07847': 'EWR',
    '07848': 'EWR',
    '07849': 'EWR',
    '07850': 'EWR',
    '07851': 'EWR',
    '07852': 'EWR',
    '07853': 'EWR',
    '07855': 'EWR',
    '07856': 'EWR',
    '07857': 'EWR',
    '07860': 'EWR',
    '07863': 'EWR',
    '07865': 'EWR',
    '07866': 'EWR',
    '07869': 'EWR',
    '07870': 'EWR',
    '07871': 'EWR',
    '07874': 'EWR',
    '07875': 'EWR',
    '07876': 'EWR',
    '07877': 'EWR',
    '07878': 'EWR',
    '07879': 'EWR',
    '07880': 'EWR',
    '07881': 'EWR',
    '07882': 'EWR',
    '07885': 'EWR',
    '07890': 'EWR',
    '07901': 'EWR',
    '07902': 'EWR',
    '07920': 'EWR',
    '07921': 'EWR',
    '07922': 'EWR',
    '07924': 'EWR',
    '07926': 'EWR',
    '07927': 'EWR',
    '07928': 'EWR',
    '07930': 'EWR',
    '07931': 'EWR',
    '07932': 'EWR',
    '07933': 'EWR',
    '07934': 'EWR',
    '07935': 'EWR',
    '07936': 'EWR',
    '07938': 'EWR',
    '07939': 'EWR',
    '07940': 'EWR',
    '07945': 'EWR',
    '07946': 'EWR',
    '07950': 'EWR',
    '07960': 'EWR',
    '07961': 'EWR',
    '07962': 'EWR',
    '07963': 'EWR',
    '07970': 'EWR',
    '07974': 'EWR',
    '07976': 'EWR',
    '07977': 'EWR',
    '07978': 'EWR',
    '07979': 'EWR',
    '07980': 'EWR',
    '07981': 'EWR',
    '07983': 'EWR',
    '07999': 'PHL',
    '08001': 'PHL',
    '08002': 'PHL',
    '08003': 'PHL',
    '08004': 'PHL',
    '08005': 'PHL',
    '08006': 'PHL',
    '08007': 'PHL',
    '08008': 'PHL',
    '08009': 'PHL',
    '08010': 'PHL',
    '08011': 'PHL',
    '08012': 'PHL',
    '08014': 'PHL',
    '08015': 'PHL',
    '08016': 'PHL',
    '08018': 'PHL',
    '08019': 'PHL',
    '08020': 'PHL',
    '08021': 'PHL',
    '08022': 'PHL',
    '08023': 'PHL',
    '08025': 'PHL',
    '08026': 'PHL',
    '08027': 'PHL',
    '08028': 'PHL',
    '08029': 'PHL',
    '08030': 'PHL',
    '08031': 'PHL',
    '08032': 'PHL',
    '08033': 'PHL',
    '08034': 'PHL',
    '08035': 'PHL',
    '08036': 'PHL',
    '08037': 'PHL',
    '08038': 'PHL',
    '08039': 'PHL',
    '08041': 'PHL',
    '08042': 'PHL',
    '08043': 'PHL',
    '08045': 'PHL',
    '08046': 'PHL',
    '08048': 'PHL',
    '08049': 'PHL',
    '08050': 'PHL',
    '08051': 'PHL',
    '08052': 'PHL',
    '08053': 'PHL',
    '08054': 'PHL',
    '08055': 'PHL',
    '08056': 'PHL',
    '08057': 'PHL',
    '08059': 'PHL',
    '08060': 'PHL',
    '08061': 'PHL',
    '08062': 'PHL',
    '08063': 'PHL',
    '08064': 'PHL',
    '08065': 'PHL',
    '08066': 'PHL',
    '08067': 'PHL',
    '08068': 'PHL',
    '08069': 'PHL',
    '08070': 'PHL',
    '08071': 'PHL',
    '08072': 'PHL',
    '08073': 'PHL',
    '08074': 'PHL',
    '08075': 'PHL',
    '08076': 'PHL',
    '08077': 'PHL',
    '08078': 'PHL',
    '08079': 'PHL',
    '08080': 'PHL',
    '08081': 'PHL',
    '08083': 'PHL',
    '08084': 'PHL',
    '08085': 'PHL',
    '08086': 'PHL',
    '08087': 'PHL',
    '08088': 'PHL',
    '08089': 'PHL',
    '08090': 'PHL',
    '08091': 'PHL',
    '08092': 'PHL',
    '08093': 'PHL',
    '08094': 'PHL',
    '08095': 'PHL',
    '08096': 'PHL',
    '08097': 'PHL',
    '08098': 'PHL',
    '08099': 'PHL',
    '08101': 'PHL',
    '08102': 'PHL',
    '08103': 'PHL',
    '08104': 'PHL',
    '08105': 'PHL',
    '08106': 'PHL',
    '08107': 'PHL',
    '08108': 'PHL',
    '08109': 'PHL',
    '08110': 'PHL',
    '08201': 'PHL',
    '08202': 'PHL',
    '08203': 'PHL',
    '08204': 'PHL',
    '08205': 'PHL',
    '08210': 'PHL',
    '08212': 'PHL',
    '08213': 'PHL',
    '08214': 'PHL',
    '08215': 'PHL',
    '08217': 'PHL',
    '08218': 'PHL',
    '08219': 'PHL',
    '08220': 'PHL',
    '08221': 'PHL',
    '08223': 'PHL',
    '08224': 'PHL',
    '08225': 'PHL',
    '08226': 'PHL',
    '08230': 'PHL',
    '08231': 'PHL',
    '08232': 'PHL',
    '08234': 'PHL',
    '08240': 'PHL',
    '08241': 'PHL',
    '08242': 'PHL',
    '08243': 'PHL',
    '08244': 'PHL',
    '08245': 'PHL',
    '08246': 'PHL',
    '08247': 'PHL',
    '08248': 'PHL',
    '08250': 'PHL',
    '08251': 'PHL',
    '08252': 'PHL',
    '08260': 'PHL',
    '08270': 'PHL',
    '08302': 'PHL',
    '08310': 'PHL',
    '08311': 'PHL',
    '08312': 'PHL',
    '08313': 'PHL',
    '08314': 'PHL',
    '08315': 'PHL',
    '08316': 'PHL',
    '08317': 'PHL',
    '08318': 'PHL',
    '08319': 'PHL',
    '08320': 'PHL',
    '08321': 'PHL',
    '08322': 'PHL',
    '08323': 'PHL',
    '08324': 'PHL',
    '08326': 'PHL',
    '08327': 'PHL',
    '08328': 'PHL',
    '08329': 'PHL',
    '08330': 'PHL',
    '08332': 'PHL',
    '08340': 'PHL',
    '08341': 'PHL',
    '08342': 'PHL',
    '08343': 'PHL',
    '08344': 'PHL',
    '08345': 'PHL',
    '08346': 'PHL',
    '08347': 'PHL',
    '08348': 'PHL',
    '08349': 'PHL',
    '08350': 'PHL',
    '08352': 'PHL',
    '08353': 'PHL',
    '08360': 'PHL',
    '08361': 'PHL',
    '08362': 'PHL',
    '08401': 'PHL',
    '08402': 'PHL',
    '08403': 'PHL',
    '08404': 'PHL',
    '08405': 'EWR',
    '08406': 'PHL',
    '08501': 'EWR',
    '08502': 'EWR',
    '08504': 'EWR',
    '08505': 'PHL',
    '08510': 'EWR',
    '08511': 'PHL',
    '08512': 'EWR',
    '08514': 'EWR',
    '08515': 'PHL',
    '08518': 'PHL',
    '08520': 'EWR',
    '08525': 'EWR',
    '08526': 'EWR',
    '08527': 'EWR',
    '08528': 'EWR',
    '08530': 'EWR',
    '08533': 'PHL',
    '08534': 'EWR',
    '08535': 'EWR',
    '08536': 'EWR',
    '08540': 'EWR',
    '08541': 'EWR',
    '08542': 'EWR',
    '08543': 'EWR',
    '08544': 'EWR',
    '08550': 'EWR',
    '08551': 'EWR',
    '08553': 'EWR',
    '08554': 'PHL',
    '08555': 'EWR',
    '08556': 'EWR',
    '08557': 'EWR',
    '08558': 'EWR',
    '08559': 'EWR',
    '08560': 'EWR',
    '08561': 'EWR',
    '08562': 'PHL',
    '08601': 'EWR',
    '08602': 'EWR',
    '08603': 'EWR',
    '08604': 'EWR',
    '08605': 'EWR',
    '08606': 'EWR',
    '08607': 'EWR',
    '08608': 'EWR',
    '08609': 'EWR',
    '08610': 'EWR',
    '08611': 'EWR',
    '08618': 'EWR',
    '08619': 'EWR',
    '08620': 'EWR',
    '08625': 'EWR',
    '08628': 'EWR',
    '08629': 'EWR',
    '08638': 'EWR',
    '08640': 'PHL',
    '08641': 'PHL',
    '08644': 'EWR',
    '08645': 'EWR',
    '08646': 'EWR',
    '08647': 'EWR',
    '08648': 'EWR',
    '08650': 'EWR',
    '08666': 'EWR',
    '08690': 'EWR',
    '08691': 'EWR',
    '08695': 'EWR',
    '08701': 'EWR',
    '08720': 'EWR',
    '08721': 'PHL',
    '08722': 'PHL',
    '08723': 'EWR',
    '08724': 'EWR',
    '08730': 'EWR',
    '08731': 'PHL',
    '08732': 'PHL',
    '08733': 'PHL',
    '08734': 'PHL',
    '08735': 'PHL',
    '08736': 'EWR',
    '08738': 'EWR',
    '08739': 'EWR',
    '08740': 'PHL',
    '08741': 'PHL',
    '08742': 'EWR',
    '08750': 'EWR',
    '08751': 'PHL',
    '08752': 'PHL',
    '08753': 'PHL',
    '08754': 'PHL',
    '08755': 'PHL',
    '08756': 'PHL',
    '08757': 'PHL',
    '08758': 'PHL',
    '08759': 'PHL',
    '08801': 'EWR',
    '08802': 'EWR',
    '08803': 'EWR',
    '08804': 'EWR',
    '08805': 'EWR',
    '08807': 'EWR',
    '08808': 'EWR',
    '08809': 'EWR',
    '08810': 'EWR',
    '08812': 'EWR',
    '08816': 'EWR',
    '08817': 'EWR',
    '08818': 'EWR',
    '08820': 'EWR',
    '08821': 'EWR',
    '08822': 'EWR',
    '08823': 'EWR',
    '08824': 'EWR',
    '08825': 'EWR',
    '08826': 'EWR',
    '08827': 'EWR',
    '08828': 'EWR',
    '08829': 'EWR',
    '08830': 'EWR',
    '08831': 'EWR',
    '08832': 'EWR',
    '08833': 'EWR',
    '08834': 'EWR',
    '08835': 'EWR',
    '08836': 'EWR',
    '08837': 'EWR',
    '08840': 'EWR',
    '08844': 'EWR',
    '08846': 'EWR',
    '08848': 'EWR',
    '08850': 'EWR',
    '08852': 'EWR',
    '08853': 'EWR',
    '08854': 'EWR',
    '08855': 'EWR',
    '08857': 'EWR',
    '08858': 'EWR',
    '08859': 'EWR',
    '08861': 'EWR',
    '08862': 'EWR',
    '08863': 'EWR',
    '08865': 'EWR',
    '08867': 'EWR',
    '08868': 'EWR',
    '08869': 'EWR',
    '08870': 'EWR',
    '08871': 'EWR',
    '08872': 'EWR',
    '08873': 'EWR',
    '08875': 'EWR',
    '08876': 'EWR',
    '08879': 'EWR',
    '08880': 'EWR',
    '08882': 'EWR',
    '08884': 'EWR',
    '08885': 'EWR',
    '08886': 'EWR',
    '08887': 'EWR',
    '08888': 'EWR',
    '08889': 'EWR',
    '08890': 'EWR',
    '08899': 'EWR',
    '08901': 'EWR',
    '08902': 'EWR',
    '08903': 'EWR',
    '08904': 'EWR',
    '08905': 'EWR',
    '08906': 'EWR',
    '08922': 'EWR',
    '08933': 'EWR',
    '08988': 'EWR',
    '08989': 'EWR',
    '09034': 'EWR',
    '09063': 'EWR',
};
