import { firstLetterUppercase } from '@/utilities/firstLetterUppercase';
import { Switch } from '@material-ui/core';

export const SEARCHABLE = ['user.email', 'user.username', 'business_name', 'user.user_id'];

export const TABS = [
    {
        header: 'Shippers',
        data: 'shippers',
    },
    {
        header: 'Carriers',
        data: 'carriers',
    },
    {
        header: 'Teammates',
        data: 'teammates',
    },
].map((tab, index) => ({ ...tab, index }));

export const COLUMNS = [
    {
        label: 'Email',
        value: (entity) => entity.user.email,
    },
    {
        label: 'Username',
        value: (entity) => entity.user.username,
    },
    {
        label: 'Business',
        value: (entity) => entity.business_name,
        tabs: ['Shippers', 'Carriers'],
    },
    {
        label: 'Team Type',
        value: (entity) => firstLetterUppercase(entity.team_leader_type),
        tabs: ['Teammates'],
    },
    {
        label: 'UID',
        value: (entity) => entity.user.user_id,
    },
    {
        label: 'Airport Codes',
        value: (entity) => {
            const codes = entity.airportCodes;
            if (codes.length <= 3) {
                return codes.join(', ');
            }
            return `${codes.slice(0, 3).join(', ')}, ...and ${codes.length - 3} more`;
        },
        tabs: ['Carriers'],
    },
    {
        label: 'Status',
        value: (entity, loading, callbacks) => (
            <Switch
                color="primary"
                checked={entity.approved}
                onChange={() => callbacks.updateClient(entity.client_id, { approved: !entity.approved })}
                disabled={loading}
            />
        ),
        tabs: ['Shippers', 'Carriers'],
    },
    {
        label: 'Test Account',
        value: (entity, loading, callbacks) => (
            <Switch
                color="primary"
                checked={entity.test_acc}
                onChange={() => callbacks.updateClient(entity.client_id, { test_acc: !entity.test_acc })}
                disabled={loading}
            />
        ),
        tabs: ['Shippers', 'Carriers'],
    },
    {
        label: 'Partnerships Enabled',
        value: (entity, loading, callbacks) => (
            <Switch
                color="primary"
                checked={entity.partnerships_enabled}
                onChange={() =>
                    callbacks.updateClient(entity.client_id, { partnerships_enabled: !entity.partnerships_enabled })
                }
                disabled={loading}
            />
        ),
        tabs: ['Shippers', 'Carriers'],
    },
];
