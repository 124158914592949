import { gql } from '@apollo/client';

export const CSV_EXPORT_TEMPLATE_FIELDS = gql`
    fragment CsvExportTemplateFields on csv_export_templates {
        template_id
        user_id
        created_at
        export_type
        template
        name
    }
`;
