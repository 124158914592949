const TYPES = [
    'SUNDAY_RATE',
    'MONDAY_RATE',
    'TUESDAY_RATE',
    'WEDNESDAY_RATE',
    'THURSDAY_RATE',
    'FRIDAY_RATE',
    'SATURDAY_RATE',
];

export const RATES = [
    {
        name: 'Daily Target Rate',
        xLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76 - 100 miles', '100 + miles'],
        yLabels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        buckets: [
            ...TYPES.map((type) => {
                return [0, 1, 2, 3, 4].map((idx) => {
                    return {
                        type,
                        bucket: idx,
                    };
                });
            }),
        ],
    },
];

export const DEFAULT_RATES = Object.fromEntries(
    TYPES.map((type) => [
        type,
        [
            { min: 0, max: 25 },
            { min: 25, max: 50 },
            { min: 50, max: 75 },
            { min: 75, max: 100 },
            { min: 100, max: 'infinity' },
        ],
    ])
);
