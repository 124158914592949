import React, { useContext, useState } from 'react';
import { compose } from 'recompose';
import Snackbar from '@/components/Snackbar';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import withFeatureFlag from '../Auth/withFeatureFlag';
import { Context, ContextProvider } from './store';
import { ACCOUNTING_STAGES } from './constants';
import CreateInvoices from './CreateInvoices';
import CompletedOrders from './CompletedOrders';
import Footer from './Footer';
import OrderPODModal from './modals/OrderPODModal';
import { useClientUser } from '@/hooks';

export const CarrierAccountingOrders = ({ context, Footer }) => {
    const { state, loading, callbacks } = useContext(context);
    const { tags } = useClientUser();

    return (
        <Grid container direction="column" wrap="nowrap" className="h-100">
            <OrderPODModal
                order={state.podModalOrder}
                callbacks={{
                    onClose: () => {
                        callbacks.setPODModalOrder(null);
                    },
                }}
            />
            <Snackbar
                open={state.notification.message}
                handleClose={() => callbacks.setNotification({})}
                {...state.notification}
            />
            {[ACCOUNTING_STAGES.CREATE_INVOICE, ACCOUNTING_STAGES.CREATE_MULTI_INVOICES].includes(state.stage) ? (
                <CreateInvoices loading={loading} state={state} callbacks={callbacks} tags={tags} />
            ) : (
                <CompletedOrders loading={loading} state={state} callbacks={callbacks} Footer={Footer} />
            )}
        </Grid>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} context={Context} Footer={Footer} />
        </ContextProvider>
    );

export default compose(withContext)(CarrierAccountingOrders);
