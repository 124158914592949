import React, { useEffect, useState, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { useSwappedAttributes } from '@/components/ShipmentForm/hooks';
import CarrierReviewModal from '@/components/CarrierReviewModal';
import { QUERY_BY_ORDER as GET_RATING } from '@/graphql/queries/ratings';
import { Grid } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { QUERY_BY_ID } from './graphql/queries';
import { Container, Header1, PrimaryButton, local } from './blocks';
import Loading from './Loading';
import { UserContext } from '../App';
import { compose } from 'recompose';
import withTokenAuth from '../Auth/withTokenAuth';
import { PARTNERSHIP_BY_CLIENTS } from '@/graphql/queries/partnerships';
import * as Sentry from '@sentry/react';

function RatingPage({ jwt, claims = {} }) {
    const navigate = useNavigate();
    const [modalOpen, setModal] = useState(false);
    const { setCustomHeader, setCustomColor } = useContext(UserContext);

    const { order_id } = claims;

    const {
        data,
        loading: isFetchingOrder,
        error: orderError,
    } = useQuery(QUERY_BY_ID, {
        variables: {
            order_id,
        },
        onCompleted: ({ order }) => {
            if (!order) {
                navigate('/');
            }
        },
        onError: (err) => {
            console.log(err);
        },
    });

    const order = useMemo(() => {
        return data?.order || null;
    }, [data]);

    const [findPartnership] = useLazyQuery(PARTNERSHIP_BY_CLIENTS, {
        onError: (error) => {
            console.error(error);
            Sentry.captureException(error);
        },
    });

    useEffect(() => {
        if (order?.order_shipper?.profile_img) {
            setCustomHeader(order?.order_shipper?.profile_img);
            if (order?.order_shipper?.profile_img_color) {
                setCustomColor(order?.order_shipper?.profile_img_color);
            }
        }

        if (order?.oms && order?.carrier_id) {
            findPartnership({
                variables: {
                    shipperId: order.shipper_id,
                    carrierId: order.carrier_id,
                },
                onCompleted: ({ partnerships }) => {
                    if (partnerships[0]?.profile_img) {
                        setCustomHeader(partnerships[0]?.profile_img);
                        if (partnerships[0]?.profile_img_color) {
                            setCustomColor(partnerships[0]?.profile_img_color);
                        }
                    }
                },
            });
        }
    }, [order]);

    const { data: ratings, loading: isFetchingRatings } = useQuery(GET_RATING, {
        variables: { order_id },
    });

    const firstReview = useMemo(() => {
        if (ratings?.results?.length > 0) {
            return ratings.results[0];
        }
    }, [ratings]);

    const { address } = useSwappedAttributes(order);
    const isLoading = isFetchingOrder || !order || isFetchingRatings;

    const copy = useMemo(() => {
        if (order && claims) {
            switch (claims?.reviewer?.type) {
                case 'SHIPPER': {
                    return `Congratulations on the completed order! Please rate your delivery on ${order.delivery_date_formatted} performed by ${order.order_carrier.business_name} to ${order.dropoff_name} in ${order[address]}`;
                }
                case 'CARRIER': {
                    return `Congratulations on the completed order! Please rate your delivery on ${order.delivery_date_formatted} with ${order.order_shipper.business_name} to ${order.dropoff_name} in ${order[address]}`;
                }
                default: {
                    return `We hope you are enjoying your new items! Please rate the delivery for ${order.dropoff_name} on ${order.delivery_date_formatted}. We look forward to serving you again!`;
                }
            }
        }

        return null;
    }, [claims, address, order]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Container>
            <Grid
                container
                direction="column"
                css={css`
                    margin: 0 12px;
                `}
            >
                <Grid
                    item
                    css={css`
                        margin-bottom: 32px;
                    `}
                >
                    <Header1
                        css={css`
                            color: ${local.greys[1]};
                        `}
                    >
                        {copy}
                    </Header1>
                </Grid>
                <Grid container item>
                    {firstReview ? (
                        <>
                            <Grid
                                container
                                item
                                direction="row"
                                css={css`
                                    justify-content: center;
                                `}
                            >
                                <Rating
                                    value={firstReview.rating}
                                    readOnly
                                    css={css`
                                        font-size: 40px;
                                    `}
                                />
                            </Grid>
                            <Grid container item direction="row">
                                <PrimaryButton
                                    variant="contained"
                                    css={css`
                                        flex: 1;
                                        margin-top: 32px;
                                    `}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setModal(true);
                                    }}
                                >
                                    Edit Review
                                </PrimaryButton>
                            </Grid>
                        </>
                    ) : (
                        <PrimaryButton
                            variant="contained"
                            css={css`
                                flex: 1;
                                margin-top: 10px;
                            `}
                            onClick={(e) => {
                                e.stopPropagation();
                                setModal(true);
                            }}
                        >
                            Leave Review
                        </PrimaryButton>
                    )}
                </Grid>
            </Grid>

            <CarrierReviewModal
                isOpen={modalOpen}
                callbacks={{
                    onClose: () => setModal(false),
                }}
                reviewObject={firstReview}
                orderId={order.order_id}
                routeId={
                    order?.routes?.find((mapping) =>
                        (order.order_type === 'return' ? ['PICKUP', 'FULL'] : ['DROPOFF', 'FULL']).includes(
                            mapping.type
                        )
                    )?.route_id
                }
                revieweeId={order.carrier_id ? order.carrier_id : order.shipper_id}
                revieweeType={order.carrier_id ? 'CARRIER' : 'SHIPPER'}
            />
        </Container>
    );
}

export default compose(withTokenAuth({ role: 'customer' }))(RatingPage);
