import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useClientUser } from '@/hooks';
import { OnwardTab, OnwardTabContainer } from '../Tabs';
import { Grid, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { PageTitle, PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { addDays, format } from 'date-fns';
import { asUTCDate } from '@/utilities/convertToISO';
import Table, { TableLoader } from '../Table';
import { useParams } from 'react-router';
import { GET_DRIVER_PAYMENT, UPDATE_DRIVER_PAYMENT, UPDATE_ORDERS } from './graphql';
import { useMutation, useQuery } from '@apollo/client';
import { captureException } from '@sentry/react';
import { GET_DRIVER_TARIFFS } from '../DriverPayments/graphql';
import { StatusBadge } from '../Accounting/Invoice/blocks';
import { startCase } from 'lodash';
import { colors, typography } from '@/styles';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import { formatInTimeZone } from 'date-fns-tz';
import { post } from '@/utilities/onwardClient';
import { RECALCULATE_DRIVER_PAY } from '@/constants/apiRoutes';

const Body1 = styled.span`
    ${typography.sansSerif}

    font-size: 14px;
    font-weight: 500;
`;

const Body2 = styled.span`
    ${typography.sansSerif}

    font-size: 14px;
    font-weight: 700;
`;

const Total = styled.span`
    ${typography.sansSerif}

    font-size: 20px;
    font-weight: 700;
`;

const GridCard = styled((props) => <Grid container {...props} />)`
    background-color: white;
    border-radius: 5px;
    border: 1px solid ${colors.greys.border};
    padding: 1rem;
`;

const COLUMNS = [
    { label: 'Order', width: 2 },
    { label: 'Description', width: 4 },
    { label: 'Base', width: 2 },
    { label: 'Rate', width: 2 },
    { label: 'Total', width: 2 },
];

const DriverPayment = () => {
    const { user_id, drivers, settings } = useClientUser();
    const { payment_id } = useParams();
    const [overrides, setOverrides] = useState({});
    const [recalculating, setRecalculating] = useState(false);

    const {
        data,
        loading: paymentsLoading,
        refetch,
    } = useQuery(GET_DRIVER_PAYMENT, {
        variables: { payment_id },
        onError: (e) => {
            console.error(e);
            captureException(e);
        },
    });

    const [updatePayment, { loading: updateLoading }] = useMutation(UPDATE_DRIVER_PAYMENT);

    const [updateOrders, { loading: updateOrdersLoading }] = useMutation(UPDATE_ORDERS, {
        refetchQueries: [GET_DRIVER_PAYMENT],
        awaitRefetchQueries: true,
    });

    const { data: tariffData, loading: tariffsLoading } = useQuery(GET_DRIVER_TARIFFS, {
        variables: { client_id: user_id },
    });

    const [tariffsByDriver, defaultTariff] = useMemo(() => {
        return (tariffData?.driver_tariffs || []).reduce(
            ([tariffsAcc, defaultAcc], mapping) => {
                let defaultTariff = defaultAcc;

                if (mapping.driver_id) {
                    tariffsAcc[mapping.driver_id] = mapping.tariff;
                } else {
                    defaultTariff = mapping.tariff;
                }

                return [tariffsAcc, defaultTariff];
            },
            [{}, null]
        );
    }, [tariffData]);

    const enriched = useMemo(() => {
        const payment = data?.driver_payment;
        if (!payment) return {};

        const [totalRevenue, totalCost] = (payment.orders || []).reduce(
            (acc, order) => {
                const orderRevenue = (order.oms ? order.order_revenue : order.carrier_rate) || 0;
                const orderOverrides = overrides[order.order_id];
                const orderCost = (orderOverrides || order.carrier_costs || []).reduce((orderAcc, cost) => {
                    return orderAcc + (cost?.rate || 0);
                }, 0);
                return [acc[0] + orderRevenue, acc[1] + orderCost];
            },
            [0, 0]
        );

        console.log(totalRevenue);

        return {
            ...payment,
            total_revenue: totalRevenue,
            total_cost: totalCost,
        };
    }, [data, overrides]);

    const validDrivers = useMemo(() => {
        return (drivers || [])
            .filter((driver) => {
                return (enriched.orders || []).some(
                    (order) => !!(order.carrier_costs || []).find((cost) => cost.driver_id === driver.teammate_id)
                );
            })
            .sort((a, b) => a.username.localeCompare(b.username));
    }, [drivers, enriched]);

    const dateRange = useMemo(() => {
        let range = [];
        if (enriched.pay_period_start) {
            range.push(new Date(enriched.pay_period_start));
        }
        if (enriched.pay_period_end) {
            const padded = addDays(new Date(enriched.pay_period_end), -1);
            range.push(padded);
        }
        return range;
    }, [enriched]);

    const onExport = () => {};

    const onRecalculate = () => {
        setRecalculating(true);
        post(RECALCULATE_DRIVER_PAY, { payment_id })
            .catch((e) => {
                console.error(e);
            })
            .finally(() => {
                refetch().finally(() => {
                    setRecalculating(false);
                });
            });
    };

    const getRate = (order, driver) => {
        const rates = overrides[order.order_id] || order.carrier_costs;
        const rate = rates?.find((rate) => rate.driver_id === driver.teammate_id)?.rate;
        return rate || 0;
    };

    const getPct = (order, driver) => {
        const rate = getRate(order, driver);
        const revenue = order.oms ? order.order_revenue : order.carrier_rate;
        const pct = rate / revenue;
        return isFinite(pct) ? 100 * pct : 0;
    };

    const getTotalPay = (driver) => {
        const total = (enriched.orders || []).reduce((acc, order) => {
            const rates = overrides[order.order_id] || order.carrier_costs;
            return acc + (rates?.find((rate) => rate.driver_id === driver.teammate_id)?.rate || 0);
        }, 0);
        return `$${total.toFixed(2)}`;
    };

    const loading = paymentsLoading || updateLoading || updateOrdersLoading || tariffsLoading || recalculating;
    const isModified = Object.keys(overrides).length > 0;

    return (
        <Grid
            container
            direction="column"
            css={css`
                height: 100%;
                flex-wrap: nowrap;
            `}
        >
            <Grid
                container
                css={css`
                    padding: 1rem 2rem;
                    justify-content: space-between;
                    align-items: center;
                `}
            >
                <Grid
                    item
                    css={css`
                        flex-grow: 1;
                    `}
                >
                    <PageTitle>
                        {dateRange
                            .map((date) =>
                                formatInTimeZone(
                                    date,
                                    settings?.driver?.payPeriodTZ || 'America/New_York',
                                    'EEE, MMM d, yyyy'
                                )
                            )
                            .join(' - ')}
                    </PageTitle>
                </Grid>
                <Grid item>
                    <SecondaryButton
                        disabled={loading}
                        onClick={onRecalculate}
                        css={css`
                            margin-right: 1rem;
                        `}
                    >
                        Recalculate
                    </SecondaryButton>
                </Grid>
                <Grid item>
                    <SecondaryButton
                        disabled={loading}
                        onClick={onExport}
                        css={css`
                            margin-right: 1rem;
                        `}
                    >
                        Export
                    </SecondaryButton>
                </Grid>

                {isModified ? (
                    <Grid item>
                        <PrimaryButton
                            disabled={loading}
                            onClick={() =>
                                updateOrders({
                                    variables: {
                                        orders: Object.entries(overrides).map(([order_id, carrier_costs]) => ({
                                            order_id,
                                            carrier_costs,
                                        })),
                                    },
                                }).then(() => setOverrides({}))
                            }
                            css={css`
                                margin-right: 1rem;
                            `}
                        >
                            Save Changes
                        </PrimaryButton>
                    </Grid>
                ) : null}

                {enriched.status === 'UNPAID' ? (
                    <Grid item>
                        <PrimaryButton
                            disabled={loading}
                            onClick={() =>
                                updatePayment({
                                    variables: {
                                        payment_id,
                                        update: { status: 'PAID' },
                                    },
                                })
                            }
                            css={css`
                                margin-right: 1rem;
                            `}
                        >
                            Mark as Paid
                        </PrimaryButton>
                    </Grid>
                ) : null}

                <Grid item>
                    <span
                        css={css`
                            border-radius: 5px;
                            border: 2px solid
                                ${enriched.status === 'UNPAID' ? colors.reds.primary : colors.greens.primary};
                            font-size: 14px;
                            font-weight: 700;
                            color: ${enriched.status === 'UNPAID' ? colors.reds.primary : colors.greens.primary};
                            padding: 4px 12px;
                        `}
                    >
                        {startCase((enriched.status || '').toLowerCase())}
                    </span>
                </Grid>
            </Grid>

            <Grid
                container
                css={css`
                    flex-grow: 1;
                    padding: 1rem 2rem;
                `}
            >
                {loading ? (
                    <TableLoader />
                ) : (
                    <GridCard direction="column">
                        <Grid container>
                            <Grid
                                item
                                css={css`
                                    width: 300px;
                                `}
                            >
                                <Body2>Order</Body2>
                            </Grid>
                            <Grid
                                item
                                css={css`
                                    width: 200px;
                                `}
                            >
                                <Body2>Service Date</Body2>
                            </Grid>
                            <Grid
                                item
                                css={css`
                                    width: 150px;
                                `}
                            >
                                <Body2>Revenue</Body2>
                            </Grid>
                            <Grid
                                item
                                css={css`
                                    flex-grow: 1;
                                `}
                            />
                            {validDrivers.map((driver) => (
                                <React.Fragment key={driver.teammate_id}>
                                    <Grid
                                        item
                                        css={css`
                                            width: 150px;
                                        `}
                                    >
                                        <Body2>{driver.username} Rate</Body2>
                                    </Grid>
                                    <Grid
                                        item
                                        css={css`
                                            width: 150px;
                                        `}
                                    >
                                        <Body2>{driver.username} Pay</Body2>
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </Grid>
                        {(enriched.orders || []).map((order) => (
                            <Grid
                                key={order.order_id}
                                container
                                css={css`
                                    height: 40px;
                                    border-top: 1px solid ${colors.greys.border};
                                    align-items: center;
                                `}
                            >
                                <Grid
                                    item
                                    css={css`
                                        width: 300px;
                                    `}
                                >
                                    <Body1>
                                        {[order.order_number, order.po_number, order.reference_id]
                                            .filter((x) => !!x)
                                            .join(' / ')}
                                    </Body1>
                                </Grid>
                                <Grid
                                    item
                                    css={css`
                                        width: 200px;
                                    `}
                                >
                                    <Body1>{order.delivery_date_formatted || '-'}</Body1>
                                </Grid>
                                <Grid
                                    item
                                    css={css`
                                        width: 150px;
                                    `}
                                >
                                    <Body1>
                                        {order.oms
                                            ? isFinite(order.order_revenue)
                                                ? `$${order.order_revenue.toFixed(2)}`
                                                : '-'
                                            : isFinite(order.carrier_rate)
                                            ? `$${order.carrier_rate.toFixed(2)}`
                                            : '-'}
                                    </Body1>
                                </Grid>
                                <Grid
                                    item
                                    css={css`
                                        flex-grow: 1;
                                    `}
                                />
                                {validDrivers.map((driver) => (
                                    <React.Fragment key={driver.teammate_id}>
                                        <Grid
                                            item
                                            css={css`
                                                width: 150px;
                                                padding-right: 25px;
                                            `}
                                        >
                                            <CurrencyTextField
                                                size="small"
                                                value={getPct(order, driver)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">{'%'}</InputAdornment>
                                                    ),
                                                }}
                                                onBlur={(e) => {
                                                    const rate = parseFloat(e.target.value || 0) / 100;

                                                    const base =
                                                        (order.oms ? order.order_revenue : order.carrier_rate) || 0;
                                                    let clone = [
                                                        ...(overrides[order.order_id] || order.carrier_costs || []),
                                                    ];
                                                    const idx = clone.findIndex(
                                                        (rate) => rate.driver_id === driver.teammate_id
                                                    );
                                                    if (idx >= 0) {
                                                        clone[idx] = {
                                                            ...clone[idx],
                                                            rate: base * rate,
                                                        };
                                                    } else {
                                                        clone = [
                                                            ...clone,
                                                            { type: 'DRIVER_PAY', driver_id: driver.teammate_id, rate },
                                                        ];
                                                    }
                                                    setOverrides((prev) => ({
                                                        ...prev,
                                                        [order.order_id]: clone,
                                                    }));
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            css={css`
                                                width: 150px;
                                                padding-right: 25px;
                                            `}
                                        >
                                            <CurrencyTextField
                                                size="small"
                                                value={getRate(order, driver)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">{'$'}</InputAdornment>
                                                    ),
                                                }}
                                                onBlur={(e) => {
                                                    const rate = parseFloat(e.target.value || 0);
                                                    let clone = [
                                                        ...(overrides[order.order_id] || order.carrier_costs || []),
                                                    ];
                                                    const idx = clone.findIndex(
                                                        (rate) => rate.driver_id === driver.teammate_id
                                                    );
                                                    if (idx >= 0) {
                                                        clone[idx] = {
                                                            ...clone[idx],
                                                            rate: rate,
                                                        };
                                                    } else {
                                                        clone = [
                                                            ...clone,
                                                            { type: 'DRIVER_PAY', driver_id: driver.teammate_id, rate },
                                                        ];
                                                    }

                                                    setOverrides((prev) => ({
                                                        ...prev,
                                                        [order.order_id]: clone,
                                                    }));
                                                }}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                ))}
                            </Grid>
                        ))}
                        <Grid
                            container
                            css={css`
                                height: 40px;
                                border-top: 1px solid ${colors.greys.border};
                                align-items: center;
                            `}
                        >
                            <Grid
                                item
                                css={css`
                                    width: 500px;
                                `}
                            >
                                <Body2>Totals</Body2>
                            </Grid>
                            <Grid
                                item
                                css={css`
                                    width: 150px;
                                `}
                            >
                                <Body2>{`$${enriched.total_revenue}`}</Body2>
                            </Grid>
                            <Grid
                                item
                                css={css`
                                    flex-grow: 1;
                                `}
                            />
                            {validDrivers.map((driver) => (
                                <React.Fragment key={driver.teammate_id}>
                                    <Grid
                                        item
                                        css={css`
                                            width: 150px;
                                        `}
                                    />
                                    <Grid
                                        item
                                        css={css`
                                            width: 150px;
                                        `}
                                    >
                                        <Body2>{getTotalPay(driver)}</Body2>
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </Grid>
                    </GridCard>
                )}
            </Grid>
            <Grid
                container
                css={css`
                    padding: 1rem 2rem 5rem 2rem;
                `}
            >
                <GridCard>
                    <Grid xs={9} />
                    <Grid xs={3} container direction="column">
                        <Grid
                            container
                            css={css`
                                justify-content: space-between;
                            `}
                        >
                            <Body1>Total Revenue</Body1>
                            <Body2>
                                {isFinite(enriched.total_revenue) ? `$${enriched.total_revenue.toFixed(2)}` : '-'}
                            </Body2>
                        </Grid>
                        <hr />
                        <Grid
                            container
                            css={css`
                                justify-content: space-between;
                            `}
                        >
                            <Body1>Total Payments</Body1>
                            <Body2>{isFinite(enriched.total_cost) ? `$${enriched.total_cost.toFixed(2)}` : '-'}</Body2>
                        </Grid>
                        <hr />
                        <Grid
                            container
                            css={css`
                                justify-content: space-between;
                            `}
                        >
                            <Body1>Profit</Body1>
                            <Body2
                                css={css`
                                    color: ${enriched.total_revenue - enriched.total_cost >= 0
                                        ? colors.greens.primary
                                        : colors.reds.primary};
                                `}
                            >
                                {isFinite(enriched.total_revenue) && isFinite(enriched.total_cost)
                                    ? `$${(enriched.total_revenue - enriched.total_cost).toFixed(2)}`
                                    : '-'}
                            </Body2>
                        </Grid>
                        <Grid
                            container
                            css={css`
                                justify-content: space-between;
                            `}
                        >
                            <Body1>Margin</Body1>
                            <Body2
                                css={css`
                                    color: ${enriched.total_revenue - enriched.total_cost >= 0
                                        ? colors.greens.primary
                                        : colors.reds.primary};
                                `}
                            >
                                {isFinite(enriched.total_revenue) && isFinite(enriched.total_cost)
                                    ? `${(
                                          (100 * (enriched.total_revenue - enriched.total_cost)) /
                                          enriched.total_revenue
                                      ).toFixed(2)} %`
                                    : '-'}
                            </Body2>
                        </Grid>
                    </Grid>
                </GridCard>
            </Grid>
        </Grid>
    );
};

export default DriverPayment;
