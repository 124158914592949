import React from 'react';
import { asDateInTZ, asUTCDate } from '@/utilities/convertToISO';
import { colors } from '@/styles';
import { LOCATION_TYPES } from '@/constants/locationTypes';
import { css } from '@emotion/react';
import { genAttributes } from '@onward-delivery/core';
import { IconButton } from '@material-ui/core';
import { toNational } from '@/utilities/formatPhoneNumber';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { Body1 } from './blocks';
import { OnwardLink } from '@/styles/blocks';

const dateShort = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
});

const TextCell = ({ value }) => {
    return (
        <div
            css={css`
                overflow: hidden;
                height: 100%;
            `}
        >
            <Body1
                css={css`
                    white-space: normal;
                `}
            >
                {value}
            </Body1>
        </div>
    );
};

export const COLUMNS = [
    {
        Header: 'Data',
        id: 'data-columns',
        columns: [
            {
                Header: 'PO #',
                id: 'po_number',
                width: 200,
                accessor: (order) => order.po_number || '--',
                Cell: ({ value, row }) => {
                    const { order_id } = row.original;
                    return (
                        <OnwardLink href={`/order/${order_id}`} css={css``}>
                            {value}
                        </OnwardLink>
                    );
                },
            },
            {
                Header: 'Customer',
                id: 'dropoff_name',
                width: 150,
                accessor: (order) => order.dropoff_name,
                Cell: TextCell,
            },
            {
                Header: 'Service Type',
                id: 'service_type',
                width: 150,
                accessor: (order) => {
                    const { location_type } = genAttributes(order);

                    return order.service_level || LOCATION_TYPES[order[location_type]] || '--';
                },
                Cell: TextCell,
            },
            {
                Header: 'Address',
                id: 'full_address',
                width: 200,
                span: false,
                accessor: (order) => {
                    const { full_address } = genAttributes(order);
                    return order[full_address];
                },
                Cell: TextCell,
            },
            {
                Header: 'Phone',
                id: 'phone',
                width: 150,
                Cell: ({ row }) => {
                    const order = row.original;

                    const sent = order.messages.filter((msg) => msg.status !== 'QUEUED');
                    let callStatus = 'Not called',
                        callError = false;
                    let textStatus = 'Not sent',
                        textError = false;

                    // TODO: update with call status
                    // add message_type to differentiate text vs call vs voicemail
                    for (const msg of sent) {
                        switch (msg.status) {
                            case 'FAILED':
                                textStatus = 'Undeliverable';
                                textError = true;
                                break;
                            case 'SUCCESS':
                                textStatus = 'Sent';
                                textError = false;
                                break;
                            default:
                                textStatus = 'Pending';
                                break;
                        }
                    }

                    return (
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                                overflow: hidden;
                                height: 100%;
                            `}
                        >
                            <Body1
                                css={css`
                                    white-space: normal;
                                `}
                            >
                                {toNational(order.dropoff_phone)}
                            </Body1>
                            <Body1
                                css={css`
                                    white-space: normal;
                                    color: ${callError ? colors.reds.primary : 'black'};
                                `}
                            >
                                Call: {callStatus}
                            </Body1>
                            <Body1
                                css={css`
                                    white-space: normal;
                                    color: ${textError ? colors.reds.primary : 'black'};
                                `}
                            >
                                Text: {textStatus}
                            </Body1>
                        </div>
                    );
                },
            },
            {
                Header: 'Invitation Sent',
                id: 'last_invitation',
                width: 150,
                accessor: (order) => {
                    const sent = order.messages.filter((message) => message.status !== 'QUEUED');
                    if (sent.length === 0) {
                        return '--';
                    }

                    return dateShort.format(new Date(sent[0].created_at));
                },
            },
            {
                Header: 'Times Sent',
                id: 'invitation_times',
                width: 100,
                accessor: (order) => {
                    if (order.messages.length === 0) {
                        return '--';
                    }

                    return order.messages.length;
                },
            },
            {
                Header: 'Resource',
                id: 'resources',
                accessor: (order) => {
                    return order.resources || [];
                },
                Cell: ({ value }) => {
                    if (value.length === 0) {
                        return (
                            <Body1
                                css={css`
                                    color: #f44336;
                                `}
                            >
                                No Resource
                            </Body1>
                        );
                    }

                    return value.map((resource) => resource.name).join(', ');
                },
            },
        ],
    },
    {
        sticky: 'right',
        Header: 'Sticky',
        id: 'sticky-group',
        columns: [
            {
                Header: '',
                id: 'schedule-action',
                width: 80,
                Cell: ({ row, callbacks }) => {
                    const order = row.original;

                    const valid = order.resources?.length > 0;

                    return (
                        <IconButton
                            disabled={!valid}
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                callbacks.scheduleOrder([row.original]);
                            }}
                            css={css`
                                padding: 8px;
                                color: ${colors.greens.primary};

                                &.Mui-disabled {
                                    color: ${colors.greys[0]};
                                    background: rgba(206, 217, 224, 0.5);
                                }
                            `}
                        >
                            <CalendarTodayIcon />
                        </IconButton>
                    );
                },
                disableSortBy: true,
            },
        ],
    },
];
