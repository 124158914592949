import { useMemo } from 'react';

export const useOrderCarrierPricing = (orders, backupText = 'We will contact you') => {
    return useMemo(() => {
        if (!orders) return;

        // If an order's shipper has the 'hide-market-rates' flag enable, then we should also hide the market rates for the carrier.
        // This is because most (90%+) of the shippers that have market rate contracts, also have the contract with a carrier for market rates.
        // See this discussion in bug reports: https://onwardteamworkspace.slack.com/archives/C027LRZCYGJ/p1690225765197839

        if (Array.isArray(orders)) {
            let totalRate;

            if (orders.every((o) => o.admin_carrier_rate_override || o.admin_carrier_rate_override === 0)) {
                totalRate = orders.reduce((total, o) => total + o.admin_carrier_rate_override, 0);
            } else if (orders.some((o) => o?.order_shipper?.user?.circles?.['hide-market-rates'])) {
                return 'Contract Rates';
            } else {
                totalRate = orders.reduce((total, o) => {
                    const override = o.admin_carrier_rate_override || o.admin_carrier_rate_override === 0;
                    const odRate = override
                        ? o.admin_carrier_rate_override
                        : o.listing?.final_accepted_carrier_rate ||
                          o.listing?.bids?.find(
                              (bid) => bid.carrier_id === o.carrier_id && bid.bid_status === 'succeeded'
                          )?.carrier_rate ||
                          o.carrier_rate ||
                          0;
                    return total + odRate;
                }, 0);
            }

            return totalRate ? `$${totalRate.toFixed(2)}` : backupText;
        } else {
            if (orders?.admin_carrier_rate_override) {
                return `$${orders.admin_carrier_rate_override.toFixed(2)}`;
            }

            if (orders?.order_shipper?.user?.circles?.['hide-market-rates']) {
                return 'Contract Rates';
            }
            if (
                orders?.listing?.bids.find(
                    (bid) => bid.carrier_id === orders.carrier_id && bid.bid_status === 'succeeded'
                )
            ) {
                return `$${
                    orders.listing.final_accepted_carrier_rate?.toFixed(2) ||
                    orders.listing.bids
                        .find((bid) => bid.carrier_id === orders.carrier_id && bid.bid_status === 'succeeded')
                        ?.carrier_rate?.toFixed(2)
                }`;
            }

            return orders?.carrier_rate ? `$${orders?.carrier_rate?.toFixed(2)}` : backupText;
        }
    }, [orders]);
};
