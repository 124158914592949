import { gql } from '@apollo/client';
import { PARTNERSHIP_FIELDS } from '../fragments/partnerships';

export const PARTNERSHIP_BY_CLIENTS = gql`
    ${PARTNERSHIP_FIELDS}
    query PartnershipByClients(
        $shipperId: uuid = "00000000-0000-0000-0000-000000000000"
        $carrierId: uuid = "00000000-0000-0000-0000-000000000000"
    ) {
        partnerships(where: { _and: { carrier_id: { _eq: $carrierId }, shipper_id: { _eq: $shipperId } } }) {
            ...PartnershipFields
        }
    }
`;
