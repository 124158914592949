import { capitalize } from 'lodash';
import { useMemo } from 'react';

export const useOrderNotes = (orders, userType) => {
    return useMemo(() => {
        const allNotes = {
            pickup: [],
            delivery: [],
            warehouse: [],
            all: [],
        };

        orders.forEach((order) => {
            const orderNotes = {
                pickup: [],
                delivery: [],
                warehouse: [],
                all: [],
            };

            (order?.notes || []).forEach((note) => {
                if (note?.private_to && note.private_to !== capitalize(userType)) {
                    return;
                }
                orderNotes.all.push(note);
                if (note?.type === 'Pickup') {
                    orderNotes.pickup.push(note);
                }
                if (note?.type === 'Delivery') {
                    orderNotes.delivery.push(note);
                }
                if (note?.type === 'Warehouse') {
                    orderNotes.warehouse.push(note);
                }
            });

            allNotes.pickup.push(...orderNotes.pickup);
            allNotes.delivery.push(...orderNotes.delivery);
            allNotes.warehouse.push(...orderNotes.warehouse);
            allNotes.all.push(...orderNotes.all);
        });

        return allNotes;
    }, [orders, userType]);
};
