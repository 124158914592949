import React, { useState, useContext, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { QUERY_ROUTES_WITH_DRIVERS_BY_CARRIER_ID_OR_SHIPPER_ID, QUERY_DISTINCT_DRIVERS } from './grahpql';

import { createTheme, Box, Grid, ThemeProvider, TextField, InputAdornment, LinearProgress } from '@material-ui/core';
import { Search } from '@material-ui/icons';

import FilterPopover from './FilterPopover';

import RoutesTable from './RoutesTable';
import { OnwardTabContainer, OnwardTab } from '../Tabs';
import { UserContext } from '../App';
import { useClientUser } from '@/hooks';
import '../MyOrders/styles.css';
import _ from 'lodash';
import add from 'date-fns/add';
import formatISO from 'date-fns/formatISO';
import { captureException } from '@sentry/react';
import { Pagination } from '@material-ui/lab';
import { colors } from '@/styles';
import { css } from '@emotion/react';
import { PrimaryHeaderContainer, PrimaryHeaderLabel } from '../MyOrders/blocks';
import { parseDriverUsernames } from './utils';
import { PrimaryButton } from '@/styles/blocks';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { calcOrderOwner, calcOrderPricing } from '@/utilities/calcOrderPricing';
import ExportCsvTemplateModal from '../ExportCsvTemplateModal';
import { ORDER_EXPORT_COLUMNS, ROUTE_EXPORT_COLUMNS, STOP_EXPORT_COLUMNS } from '../ExportCsvTemplateModal/constants';
import { INSERT_CSV_EXPORT_TEMPLATE } from '@/graphql/mutations/csv_export_templates';
import csvDownload from 'json-to-csv-export';

const theme = createTheme({
    typography: {
        fontFamily: ['Montserrat', 'Roboto', 'Arial'].join(','),
        h2: {
            fontWeight: 800,
            fontSize: '16px',
            lineHeight: 1.25,
            color: '#2B2B2B',
        },
        h3: {
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: 1.25,
            color: '#2B2B2B',
        },
    },
    palette: {
        text: {
            secondary: '#4c4c4c',
        },
        primary: {
            main: '#59b863',
            contrastText: '#fff',
        },
    },
});

const searchableTerms = ['route_alias', 'route_number'];
const subSearchableTerms = {
    truck: ['truck_name'],
    teammateByDriverId: ['username'],
};

const addOneDay = (date) => {
    const alteredDate = add(new Date(date), { days: 2 });
    return formatISO(alteredDate, { representation: 'date' });
};

const MyRoutes = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [search, setSearch] = useState('');
    const [filters, setFilters] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [debounceTimer, setDebounceTimer] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [driverId, setDriverId] = useState(null);
    const [sortBy, setSortBy] = useState({ id: 'deldate', desc: true });
    const [totalCount, setTotalCount] = useState(0);
    const [selectedRouteIds, setSelectedRouteIds] = useState({});
    const [openExportModal, setOpenExportModal] = useState();
    const { user, sidebarCollapsed } = useContext(UserContext);
    const { user_id, accountType, circles } = useClientUser();
    const allStatuses = [
        'active',
        'pending',
        'planning',
        'inProgress',
        'complete_final_return',
        'complete',
        'cancelled',
    ];

    const tabFilters = [
        [{ status: { _in: allStatuses } }],
        [{ status: { _in: ['pending', 'planning', 'active'] } }],
        [{ status: { _in: ['inProgress', 'complete_final_return'] } }],
        [{ status: { _in: ['complete'] } }],
        [{ status: { _in: ['cancelled'] } }],
    ];

    const where = useMemo(() => {
        const whereFilters = [
            { _or: [{ carrier_id: { _eq: user_id } }, { shipper_id: { _eq: user_id } }] },
            ...tabFilters[tabIndex],
        ];

        if (startDate) {
            whereFilters.push({ scheduled_delivery: { _gte: startDate } });
        }

        if (endDate) {
            whereFilters.push({ scheduled_delivery: { _lt: addOneDay(endDate) } });
        }

        if (searchTerm) {
            const searchables = [];
            searchables.push(
                ...searchableTerms.map((searchField) => {
                    if (searchField === 'route_number') {
                        return { [searchField]: { _eq: parseInt(searchTerm) } };
                    } else {
                        return { [searchField]: { _ilike: `%${searchTerm}%` } };
                    }
                })
            );

            Object.keys(subSearchableTerms).forEach((association) => {
                const subSearchTerms = subSearchableTerms[association].map((searchField) => {
                    let term = {
                        [association]: { [searchField]: { _ilike: `%${searchTerm}%` } },
                    };
                    return term;
                });
                searchables.push(...subSearchTerms);
            });

            whereFilters.push({
                _or: searchables,
            });
        }

        if (filters?.length) {
            whereFilters.push({ _and: filters });
        }

        return { _and: whereFilters };
    }, [tabIndex, searchTerm, startDate, endDate, driverId, filters]);

    const order_by = useMemo(() => {
        const ascOrDesc = sortBy?.desc ? 'desc_nulls_last' : 'asc_nulls_last';
        switch (sortBy?.id) {
            case 'route_name':
                return [{ route_alias: ascOrDesc }, { route_number: ascOrDesc }];
            case 'scheduled_delivery':
                return [{ scheduled_delivery: ascOrDesc }];
            default:
                return [{ scheduled_delivery: 'desc_nulls_last' }, { route_number: 'desc_nulls_last' }];
        }
    }, [sortBy]);

    const PAGE_SIZE = 20;
    const [page, setPage] = useState(0);

    const { loading: loadingRoutes, data } = useQuery(QUERY_ROUTES_WITH_DRIVERS_BY_CARRIER_ID_OR_SHIPPER_ID, {
        variables: {
            limit: PAGE_SIZE,
            offset: page * PAGE_SIZE,
            where,
            order_by,
        },
        onCompleted: (data) => {
            setTotalCount(data.routes_aggregate.aggregate.totalCount);
        },
        onError: (error) => {
            captureException(error);
            console.log(error);
        },
    });

    const allRoutes = useMemo(() => {
        if (data?.routes?.length) {
            // Attach additional search properties to route objects
            const routes = data.routes.reduce((parsed, route) => {
                if (route?.teammateByDriverId?.username || route?.truck?.truck_name) {
                    let objCopy = _.cloneDeep(route);

                    route?.teammateByDriverId?.username && (objCopy.driver_name = route.teammateByDriverId.username);
                    route?.truck?.truck_name && (objCopy.truck_name = route.truck.truck_name);
                    parsed.push(objCopy);
                } else {
                    parsed.push(route);
                }
                return parsed;
            }, []);

            return routes;
        }
        return [];
    }, [data]);

    const exportOrderRevenue = (order) => {
        return calcOrderPricing(order, user_id, circles);
    };

    const exportOrderOwner = (order) => {
        return calcOrderOwner(order, user_id);
    };

    const getOwner = (route) => {
        return route.oms && route.shipper_id == user_id && (route.carrier_id === user_id || route.carrier_id === null)
            ? 'Internal'
            : (!route.oms && route.carrier_id === user_id) ||
              (route.shipper_id !== user_id && route.carrier_id === user_id)
            ? 'Claimed'
            : 'Onward';
    };

    const normalizeRange = (metric) => {
        if (!metric) return 0;
        return Array.isArray(metric) ? Math.max(...metric.map((weight) => parseFloat(weight))) : parseFloat(metric);
    };

    const getTotalCubes = (orders) => {
        const fields = ['items', 'returns'];
        if (orders?.length > 0) {
            return orders.reduce((totalCubes, order) => {
                let cubes = normalizeRange(order.items_total_cubes) + normalizeRange(order?.totalCubes);

                if (!cubes) {
                    cubes = 0;
                    fields.forEach((field) => {
                        if (order[field]?.length > 0) {
                            order[field].forEach((item) => {
                                const itemCubes =
                                    normalizeRange(item.total_cubes) || normalizeRange(item.cubes_per_unit);
                                cubes += itemCubes;
                            });
                        }
                    });
                }

                return totalCubes + cubes;
            }, 0);
        }
    };

    const getTotalWeight = (orders) => {
        const fields = ['items', 'returns'];
        if (orders?.length > 0) {
            return orders.reduce((totalWeight, order) => {
                let weight = normalizeRange(order.items_total_weight) + normalizeRange(order?.totalWeight);

                if (!weight) {
                    weight = 0;
                    fields.forEach((field) => {
                        if (order[field]?.length > 0) {
                            order[field].forEach((item) => {
                                const itemWeight = normalizeRange(item.total_weight) || normalizeRange(item.weight);
                                weight += itemWeight;
                            });
                        }
                    });
                }

                return totalWeight + weight;
            }, 0);
        }
    };

    const csvRowData = useMemo(() => {
        const routesById = Object.fromEntries((allRoutes || []).map((route) => [route.route_id, route]));
        const stopData = [];
        let selectedRouteOrders = [];
        const routeData = Object.entries(selectedRouteIds)
            .filter(([_, selected]) => selected)
            .reduce((acc, [route_id]) => {
                const route = routesById[route_id];
                if (!route) return acc;
                const routeOrders = route.orders.map((orderMapping) => {
                    return { ...orderMapping.order, routeLabel: route.route_alias || route.route_number };
                });
                selectedRouteOrders = [...selectedRouteOrders, ...routeOrders];
                const routeTotalCubes = getTotalCubes(routeOrders);
                const routeTotalWeight = getTotalWeight(routeOrders);
                route.stopsByRouteId.forEach((stop) => {
                    const stopOrders = routeOrders.filter((order) => stop.orders.includes(order.order_id));
                    const stopTotalCubes = getTotalCubes(stopOrders);
                    const stopTotalWeight = getTotalWeight(stopOrders);
                    stopData.push({
                        ...stop,
                        routeLabel: route.route_alias || route.route_number,
                        stopOrders: stopOrders,
                        totalWeight: stopTotalWeight,
                        totalCubes: stopTotalCubes,
                    });
                });
                return [
                    ...acc,
                    { ...route, owner: getOwner(route), totalCubes: routeTotalCubes, totalWeight: routeTotalWeight },
                ];
            }, []);
        const orderData = selectedRouteOrders.reduce((acc, order) => {
            if (!order) return acc;

            let revenue = exportOrderRevenue(order);
            let owner = exportOrderOwner(order);

            const rows = order.itemsByOrderId.map((item) => {
                return { order, item, revenue, owner };
            });
            return [...acc, ...rows];
        }, []);
        return { routeData: routeData, stopData: stopData, orderData: orderData };
    }, [selectedRouteIds, allRoutes]);

    const { loading: loadingDrivers, data: driversData } = useQuery(QUERY_DISTINCT_DRIVERS, {
        variables: {
            where,
        },
        onError: (error) => {
            captureException(error);
            console.log(error);
        },
    });

    const [insertTemplate, { loading: insertTemplateLoading }] = useMutation(INSERT_CSV_EXPORT_TEMPLATE, {
        onError: (error) => {
            captureException(error);
            console.log(error.message);
        },
    });

    const driverOptions = useMemo(() => {
        if (driversData?.routes?.length) {
            return driversData.routes.reduce((options, r) => {
                const _driver = r?.teammateByDriverId;
                if (_driver) {
                    const driverOptionName = parseDriverUsernames(_driver?.username);
                    options.push({
                        value: _driver?.teammate_id,
                        label: driverOptionName,
                    });
                }
                return options;
            }, []);
        }
        return [];
    }, [driversData]);

    const handleSearch = (e) => {
        const searchText = e.target.value;
        setSearch(searchText);
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        setDebounceTimer(
            setTimeout(() => {
                setSearchTerm(searchText);
            }, 300)
        );
    };

    const handleTabChange = (e, tab) => {
        setTabIndex(tab);
    };

    const handleSortBy = (newSortBy) => {
        if (newSortBy?.id !== sortBy.id || newSortBy.desc !== sortBy.desc) {
            setSortBy(newSortBy);
            setPage(0);
        }
    };

    const handlePage = (_, p) => {
        const pageIdx = p - 1;
        setPage(pageIdx);
    };

    const exportSelectedRoutes = () => {
        setOpenExportModal(true);
    };

    const csvExportColumns = {
        Route: ROUTE_EXPORT_COLUMNS,
        Stop: STOP_EXPORT_COLUMNS,
        Order: ORDER_EXPORT_COLUMNS,
    };

    return (
        <ThemeProvider theme={theme}>
            <Box
                className={`${sidebarCollapsed && 'collapse-margin'}  table-height`}
                sx={{ flexGrow: 1, padding: 0, height: 'calc(100vh - 50px)' }}
            >
                <Grid container direction="column" wrap="nowrap" className="h-100">
                    <Grid container justifyContent="center" className="bg-white border-bottom">
                        <Grid item>
                            <OnwardTabContainer
                                value={tabIndex}
                                onChange={handleTabChange}
                                textColor="primary"
                                indicatorColor="primary"
                            >
                                <OnwardTab label="All" value={0} style={{ outline: 0 }} />
                                <OnwardTab label="Pre-Transit" value={1} style={{ outline: 0 }} />
                                <OnwardTab label="In-Transit" value={2} style={{ outline: 0 }} />
                                <OnwardTab label="Complete" value={3} style={{ outline: 0 }} />
                                <OnwardTab label="Cancelled" value={4} style={{ outline: 0 }} />
                            </OnwardTabContainer>
                        </Grid>
                    </Grid>
                    <PrimaryHeaderContainer item>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <PrimaryHeaderLabel>My Routes</PrimaryHeaderLabel>
                            </Grid>
                            <Grid item>
                                <TextField
                                    value={search}
                                    placeholder="Search routes"
                                    onChange={handleSearch}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    className="me-3"
                                    InputProps={{
                                        style: { backgroundColor: 'white' },
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <FilterPopover
                                    applyFilters={(filters) => setFilters(filters)}
                                    email={user?.email}
                                    userType={accountType}
                                    startDate={startDate}
                                    endDate={endDate}
                                    setEndDate={setEndDate}
                                    setStartDate={setStartDate}
                                    driverId={driverId}
                                    setDriverId={setDriverId}
                                    user_id={user_id}
                                    driverOptions={driverOptions}
                                />
                                <PrimaryButton
                                    variant="contained"
                                    color="primary"
                                    onClick={exportSelectedRoutes}
                                    disabled={!csvRowData?.routeData.length}
                                    css={css`
                                        margin-left: 1rem;
                                        height: 36px;
                                    `}
                                    endIcon={<FileDownloadIcon />}
                                >
                                    Export
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    </PrimaryHeaderContainer>
                    {loadingRoutes && (
                        <Grid item className="ms-5 me-5">
                            <LinearProgress
                                color="primary"
                                css={css`
                                    width: 100%;
                                `}
                            />
                        </Grid>
                    )}
                    <Grid item className="ms-5 me-5 bg-white" style={{ overflow: 'hidden', flexGrow: 1 }}>
                        <RoutesTable
                            routes={allRoutes}
                            sortBy={[sortBy]}
                            handleSortBy={handleSortBy}
                            setSelectedRouteIds={setSelectedRouteIds}
                            selectedRouteIds={selectedRouteIds}
                            tabIndex={tabIndex}
                        />
                    </Grid>
                    <Grid container className="m-5" justifyContent="center">
                        <Pagination
                            variant="outlined"
                            shape="rounded"
                            css={css`
                                .Mui-selected {
                                    background-color: ${colors.greens.primary};
                                    color: white;
                                }
                            `}
                            count={Math.ceil(totalCount / PAGE_SIZE)}
                            page={page + 1}
                            onChange={handlePage}
                        />
                    </Grid>
                </Grid>
            </Box>
            <ExportCsvTemplateModal
                open={openExportModal}
                setOpen={setOpenExportModal}
                exportType={'ROUTE'}
                csvExportColumns={csvExportColumns}
                callbacks={{
                    onExport: async (selectedColumns, templateName, exportType) => {
                        if (templateName) {
                            insertTemplate({
                                variables: {
                                    object: {
                                        name: templateName,
                                        user_id: user_id,
                                        export_type: exportType,
                                        template: selectedColumns,
                                    },
                                },
                            });
                        }

                        Object.keys(csvExportColumns).forEach((colType) => {
                            const finalExportCols = csvExportColumns[colType].filter(
                                (col) => selectedColumns[colType][col.header]
                            );
                            if (finalExportCols.length > 0) {
                                csvDownload({
                                    headers: finalExportCols.map((col) => col.header),
                                    data: csvRowData[`${colType.toLowerCase()}Data`].map((row) =>
                                        finalExportCols.map((col) => col.value(row))
                                    ),
                                    filename: `onward-${colType}Export-${new Date().toISOString()}.csv`,
                                    delimiter: ',',
                                });
                            }
                        });
                    },
                }}
            />
        </ThemeProvider>
    );
};

export default MyRoutes;
