import { useMemo, useEffect } from 'react';

import { genAccessorials } from '@/components/Account/Tariffs/utils';
import { useExport } from '@/components/admin/AdminFinancials/payables/hooks';
import { QUICKBOOKS_CSV_COLUMNS } from '@/components/CarrierAccountingInvoice/csvColumns';
import { uniq } from 'lodash';

export const useInvoiceExport = ({ invoices, breakdown, selectedMap, overrides, tags }) => {
    const selected = useMemo(() => {
        return Object.keys(selectedMap).filter((attr) => selectedMap[attr]);
    }, [selectedMap]);

    const invoicesMap = useMemo(() => {
        return Object.fromEntries(invoices.map((invoice) => [invoice.carrier_invoice_id, invoice]));
    }, [invoices]);

    const types = useMemo(() => {
        if (!overrides) {
            return ['DEFAULT'];
        }

        const { mktplace, internal } = overrides;
        return uniq([
            'DEFAULT',
            ...(mktplace || []).map((o) => o.algo_type),
            ...(internal || []).map((o) => o.algo_type),
        ]);
    }, [overrides]);

    const accessorials = useMemo(() => {
        return types.reduce((acc, type) => {
            return [...acc, ...genAccessorials(type, tags)];
        }, []);
    }, [types, tags]);

    const selectedObj = useMemo(() => {
        return selected.map((id) => invoicesMap[id]);
    }, [selected, invoicesMap]);

    const { exportSelected } = useExport({
        accessorials,
        invoices: selectedObj,
        columns: QUICKBOOKS_CSV_COLUMNS,
        breakdown,
    });

    return [
        {
            selected,
        },
        {
            exportCsv: exportSelected,
        },
    ];
};
