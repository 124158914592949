import React, { useEffect, useContext, useState, useMemo, useCallback } from 'react';
import { compose } from 'recompose';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { css } from '@emotion/react';
import { Button, Grid } from '@material-ui/core';
import track from '@/utilities/track';
import { QUERY_BY_ROUTE as GET_RATING } from '@/graphql/queries/ratings';
import { toNational, toE164 } from '@/utilities/formatPhoneNumber';
import { typography, colors } from '@/styles';
import ExceptionsModal from './ExceptionsModal.js';
import ContextProvider, { Context } from './context';
import { QUERY_BY_ID as GET_ORDER, DRIVER_BY_ID } from './graphql/queries';
import PODModal from './PODModal';
import Loading from './Loading';
import TrackingMap from './TrackingMap';
import CarrierReviewModal from '../CarrierReviewModal';
import { Container, Hr, OrderNumbers } from './blocks';
import OrderSummary from './OrderSummary';
import OrderStatus from './OrderStatus';
import ItemsCard from './ItemsCard';
import OrderExtra from './OrderExtra';
import { UserContext } from '../App.js';
import withTokenAuth from '../Auth/withTokenAuth.js';
import PricingInfo from './PricingInfo.js';
import ClientInfo from './ClientInfo.js';
import * as Sentry from '@sentry/react';
import { postUnauth } from '@/utilities/onwardClient.js';
import { CUSTOMER_SHIPMENT_TRACKING } from '@/constants/apiRoutes.js';
import Payment from './Payment.js';
import AddUpgradeDialog from './UpgradeDialogs/AddUpgradeDialog.js';
import { PARTNERSHIP_BY_CLIENTS } from '@/graphql/queries/partnerships.js';

const CustomerTrack = ({ jwt, claims = {} }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isDesktop = useMediaQuery('(min-width: 600px)');
    const { carrierReviewOpenOverride, closeCarrierReviewModal, openCarrierReviewModal, upgradeDialogOpen } =
        useContext(Context);
    const { setCustomHeader, setCustomColor } = useContext(UserContext);
    const [paymentInfo, setPaymentInfo] = useState({});
    const [isPaymentLoading, setPaymentLoading] = useState(false);
    const isTrackByPO = location.pathname.startsWith('/customer/trackbypo');
    const poNumber = new URLSearchParams(location.search).get('po_number');
    const shipperId = new URLSearchParams(location.search).get('shipper_id');

    const [getDriver, { data: driver }] = useLazyQuery(DRIVER_BY_ID, {
        onError: (error) => {
            console.error(error);
            Sentry.captureException(error);
        },
    });

    const [fetchRatings, { data: ratings }] = useLazyQuery(GET_RATING, {
        fetchPolicy: 'network-only',
        onError: (err) => {
            console.error(err);
        },
    });

    const [findPartnership] = useLazyQuery(PARTNERSHIP_BY_CLIENTS, {
        onError: (error) => {
            console.error(error);
            Sentry.captureException(error);
        },
    });

    const {
        data: ordersData,
        loading: isFetchingOrder,
        error: orderError,
        refetch: ordersRefetch,
    } = useQuery(GET_ORDER, {
        variables: {
            where: isTrackByPO
                ? { _and: { shipper_id: { _eq: shipperId }, po_number: { _eq: poNumber } } }
                : { order_id: { _in: (claims?.orderIds || '').split(',') } },
        },
        skip: !isTrackByPO && !claims?.orderIds,
        onCompleted: ({ results }) => {
            if (!(results?.length > 0)) {
                navigate('/');
            }
        },
        onError: (err) => {
            console.log(err);
        },
    });

    const orders = useMemo(() => {
        return ordersData?.results || [];
    }, [ordersData]);

    const isIndividualLTL = useMemo(() => {
        return !claims?.routeId;
    }, [claims]);

    const firstReview = useMemo(() => {
        if (ratings?.results?.length > 0) {
            return ratings.results[0];
        }
    }, [ratings]);

    const order = useMemo(() => {
        if (orders?.length > 0) {
            return orders[0];
        }
        return null;
    }, [orders]);

    const route = useMemo(() => {
        if (order?.routes?.length) {
            return order.routes.find((mapping) =>
                ['FULL', order?.order_type === 'return' ? 'PICKUP' : 'DROPOFF'].includes(mapping.type)
            )?.route;
        }
        return null;
    }, [order]);

    const carrierReviewOpen = useMemo(() => {
        if (!order) {
            return false;
        }

        if (carrierReviewOpenOverride !== null) {
            return carrierReviewOpenOverride;
        }

        return ['routedComplete', 'complete'].includes(order.order_status);
    }, [order, carrierReviewOpenOverride]);

    useEffect(() => {
        if (order?.order_shipper?.profile_img) {
            setCustomHeader(order?.order_shipper?.profile_img);
            if (order?.order_shipper?.profile_img_color) {
                setCustomColor(order?.order_shipper?.profile_img_color);
            }
        }
        if (order?.oms && order?.carrier_id) {
            findPartnership({
                variables: {
                    shipperId: order.shipper_id,
                    carrierId: order.carrier_id,
                },
                onCompleted: ({ partnerships }) => {
                    if (partnerships[0]?.profile_img) {
                        setCustomHeader(partnerships[0]?.profile_img);
                        if (partnerships[0]?.profile_img_color) {
                            setCustomColor(partnerships[0]?.profile_img_color);
                        }
                    }
                },
            });
        }
    }, [order]);

    useEffect(() => {
        if (order?.source_form === 'CUSTOMER' && order.order_status === 'on_hold_awaiting_payment') {
            postUnauth(CUSTOMER_SHIPMENT_TRACKING, { token: jwt }).then(({ data }) => {
                setPaymentInfo(data);
            });
        }
    }, [order]);

    useEffect(() => {
        if (order) {
            track('Customer tracking page opened', {
                customer: order.dropoff_name,
                customerEmail: order.dropoff_email,
                shipper: order.order_shipper.business_name,
                shipperEmail: order.order_shipper.email,
                carrier: order?.order_carrier?.business_name || 'no carrier assigned',
                carrierEmail: order?.order_carrier?.email || 'no carrier assigned',
                orderNumber: order.order_number,
            });
        }
    }, [order]);

    useEffect(() => {
        if (route) {
            fetchRatings({ variables: { orders: route.orders.map((order) => order.order_id) } });
        } else if (isIndividualLTL && order) {
            fetchRatings({ variables: { orders: [order.order_id] } });
        }
    }, [route, order, isIndividualLTL]);

    useEffect(() => {
        if (route?.driver_id) {
            getDriver({ variables: { driver_id: route.driver_id } });
        } else if (order?.driver_id) {
            getDriver({ variables: { driver_id: order.driver_id } });
        }
    }, [route, order]);

    const contactPhone = useMemo(() => {
        if (!order) return;
        if (order?.order_carrier) {
            return order?.order_carrier?.support_phone &&
                !order?.order_carrier?.user?.circles?.['hide-tracking-link-phone']
                ? order?.order_carrier?.support_phone
                : '';
        } else {
            return order?.order_shipper?.support_phone &&
                !order?.order_shipper?.user?.circles?.['hide-tracking-link-phone']
                ? order?.order_shipper?.support_phone
                : '';
        }
    }, [order]);

    const hideDriverPhone = useMemo(() => {
        return (
            order?.order_carrier?.user?.circles?.['hide-driver-phone-number'] ||
            order?.order_shipper?.user?.circles?.['hide-driver-phone-number'] ||
            false
        );
    }, [order]);

    const confirmPayment = useCallback(async ({ stripe, elements }) => {
        if (!stripe || !elements) return;

        setPaymentLoading(true);
        const result = await stripe.confirmPayment({
            elements,
            redirect: 'if_required',
            confirmParams: {},
        });

        if (result.error) {
            console.error(result.error.message);
            setPaymentLoading(false);
        } else {
            setTimeout(() => {
                ordersRefetch().then(() => {
                    setPaymentLoading(false);
                });
            }, 5000);
        }
    }, []);

    const isLoading = isFetchingOrder || !order || (claims?.routeId && !route);

    if (isLoading) {
        return <Loading />;
    }

    const shipperName = order.order_shipper.business_name || '';
    const orderComplete = ['routedComplete', 'complete'].includes(order.order_status);
    const orderIds = orders?.map((o) => o.order_id);

    const ShipperInfo = () => (
        <Grid
            container
            css={css`
                ${typography.sansSerif}
                font-size: 12px;
                font-weight: 500;
                color: ${colors.greys.primary};
                padding: 12px 0;
            `}
        >
            <Grid item xs={12}>
                <span>{`Your ${shipperName} delivery is powered by Onward.`}</span>
            </Grid>
            {contactPhone && (
                <Grid item xs={12}>
                    <span>
                        {`To speak with a representative about your delivery, please call `}
                        <a href={`tel:${toE164(contactPhone)}`}>{toNational(contactPhone)}</a>
                    </span>
                </Grid>
            )}
        </Grid>
    );

    return (
        <Container>
            <Grid container direction="row">
                {!isDesktop ? <ShipperInfo /> : null}
                <Grid
                    container
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                        justify-content: flex-end;
                        padding: 0px 20px;

                        @media only screen and (min-width: 600px) {
                            height: calc(100vh - 50px);
                            overflow: auto;
                        }
                    `}
                >
                    <Grid>
                        {isDesktop ? (
                            <ShipperInfo />
                        ) : (
                            <Grid
                                container
                                css={css`
                                    height: 62vh;
                                    margin-bottom: 24px;
                                `}
                            >
                                <TrackingMap route={route} order={order} />
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <OrderNumbers orderNumbers={orders?.map((o) => o.order_number)} />
                        </Grid>

                        {order?.order_status === 'on_hold_awaiting_payment' ? (
                            <>
                                <Payment
                                    isDesktop={isDesktop}
                                    state={{ order, paymentInfo, isLoading: isPaymentLoading }}
                                    callbacks={{
                                        confirmPayment,
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                {orderComplete && (
                                    <Grid
                                        item
                                        xs={12}
                                        css={css`
                                            margin: 20px 0;
                                        `}
                                    >
                                        <Button
                                            variant="contained"
                                            style={{
                                                backgroundColor: '#59B863',
                                                color: 'white',
                                                marginLeft: 'auto',
                                            }}
                                            onClick={openCarrierReviewModal}
                                        >
                                            {`${firstReview ? 'Edit' : 'Leave'} Carrier Review`}
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <OrderSummary order={order} route={route} />
                                </Grid>
                                <Hr />
                                <Grid item xs={12}>
                                    <OrderStatus order={order} route={route} />
                                </Grid>
                                <Hr />
                                {route?.orders && (
                                    <Grid item xs={12}>
                                        <ItemsCard
                                            orders={route.orders
                                                .filter((mapping) => orderIds.includes(mapping.order_id))
                                                .map((mapping) => mapping.order)}
                                        />
                                    </Grid>
                                )}
                                {isIndividualLTL && order && (
                                    <Grid item xs={12}>
                                        <ItemsCard orders={[order]} />
                                    </Grid>
                                )}
                                <Hr />
                                <Grid item xs={12}>
                                    <ClientInfo
                                        order={order}
                                        driver={driver?.teammates_by_pk}
                                        hideDriverPhone={hideDriverPhone}
                                    />
                                </Grid>
                                <Hr />
                                <Grid item xs={12}>
                                    <OrderExtra order={order} />
                                </Grid>
                                {order?.source_form === 'CUSTOMER' && (
                                    <>
                                        <Hr />
                                        <Grid item xs={12}>
                                            <PricingInfo order={order} />
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>
                {isDesktop ? (
                    <Grid
                        container
                        css={css`
                            flex: 1;
                            flex-basis: 0;
                        `}
                    >
                        <TrackingMap route={route} order={order} />
                    </Grid>
                ) : null}
            </Grid>

            {order && (
                <>
                    <ExceptionsModal order={order} />
                    <PODModal order={order} />
                    <CarrierReviewModal
                        isOpen={carrierReviewOpen}
                        callbacks={{
                            onClose: closeCarrierReviewModal,
                        }}
                        orderId={order.order_id}
                        reviewObject={firstReview}
                        carrierId={order.carrier_id}
                        revieweeId={
                            isIndividualLTL
                                ? order.carrier_id
                                    ? order.carrier_id
                                    : order.shipper_id
                                : route.carrier_id
                                ? route.carrier_id
                                : route.shipper_id
                        }
                        revieweeType={
                            isIndividualLTL
                                ? order.carrier_id
                                    ? 'CARRIER'
                                    : 'SHIPPER'
                                : route.carrier_id
                                ? 'CARRIER'
                                : 'SHIPPER'
                        }
                        driverId={route?.driver_id}
                    />
                </>
            )}

            <AddUpgradeDialog order={order} ordersRefetch={ordersRefetch} />
        </Container>
    );
};

const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} />
        </ContextProvider>
    );
export default compose(
    withContext,
    withTokenAuth({ role: 'customer', redirectLocation: '/track_order' })
)(CustomerTrack);
