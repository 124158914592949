import React, { useMemo } from 'react';
import {
    Body2,
    GridItemRow,
    ModalActions,
    ModalContent,
    ModalTitle,
    PrimaryButton,
    SecondaryButton,
} from '../DispatchPlan/blocks';
import { useMutation } from '@apollo/client';
import { UPDATE_ROUTE_STOPS } from '../DispatchPlan/graphql/mutations';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import * as Sentry from '@sentry/react';
import { cloneDeep } from 'lodash';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { useClientUser } from '@/hooks';
import { CANCEL_ORDER } from './mutations';

const CancelOrderModal = ({ route, open, handleClose, order, refetchOrder, setNotification, isOnRoute }) => {
    const { user_id, default_end_location } = useClientUser();

    const [updateStops, { loading }] = useMutation(UPDATE_ROUTE_STOPS, {});

    const [cancelOrder, { loading: cancelLoading }] = useMutation(CANCEL_ORDER, {});

    const handleRemoveOrder = async () => {
        let newStops = cloneDeep(route?.stopsByRouteId || []);
        newStops = await FTLStopHelpers.removeStop(order, { ...route, stopsByRouteId: newStops }, default_end_location);

        const pickups = order.type === 'PICKUP' ? [order] : [];
        const deliveries = order.type !== 'PICKUP' ? [order] : [];

        updateStops({
            variables: {
                route_id: route?.route_id,
                route_update: {
                    need_to_optimize: true,
                },
                events: [
                    ...pickups.map((order) => ({
                        order_id: order.order_id,
                        action: `${order.event_state}:REMOVE_PU`,
                        notes: `Removed route ${route.route_number}`,
                    })),
                    ...deliveries.map((order) => ({
                        order_id: order.order_id,
                        action: `${order.event_state}:${order.event_state === 'PICKED_UP' ? 'REMOVE' : 'REMOVE_DO'}`,
                        notes: `Removed route ${route.route_number}`,
                    })),
                ],
                order_updates:
                    deliveries?.map((order) => ({
                        where: { order_id: { _eq: order.order_id } },
                        _set: {
                            delivery_time_confirmed: null,
                            del_window_start: null,
                            del_window_end: null,
                            original_del_window_start: null,
                            original_del_window_end: null,
                            order_status: user_id === order.carrier_id ? 'rejected' : 'cancelled',
                        },
                    })) || [],
                ...FTLStopHelpers.gqlStopUpdates(newStops, route),
            },
            onError: (error) => {
                setNotification({
                    severity: 'error',
                    message: 'There was an error when attempting to remove the order from the route.',
                });
                Sentry.captureException(error);
                console.error(error);
            },
            onCompleted: () => {
                handleClose();
                refetchOrder();
            },
        });
    };

    const handleCancelOrder = async () => {
        cancelOrder({
            variables: {
                order_id: order.order_id,
            },
            onError: (error) => {
                setNotification({
                    severity: 'error',
                    message: 'There was an error when attempting to cancel the order.',
                });
                Sentry.captureException(error);
                console.error(error);
            },
            onCompleted: () => {
                handleClose();
                refetchOrder();
            },
        });
    };

    return (
        <NavResponsiveModal open={open} onClose={handleClose}>
            <ModalTitle>
                {isOnRoute
                    ? `Remove order ${order.order_number} from route ${route?.route_number} and cancel order`
                    : `Cancel order ${order.order_number}`}
            </ModalTitle>
            <ModalContent>
                <GridItemRow>
                    <Body2>
                        {isOnRoute
                            ? 'This order is currently on a route in the Planning page. By canceling this order and removing this stop from the route, the timeframes of the stops after this will be effected. Confirm to recalculate these timeframes.'
                            : `This order is currently scheduled for ${order.delivery_date_formatted}. Are you sure you want to cancel this order? This cannot be undone.`}
                    </Body2>
                </GridItemRow>
            </ModalContent>
            <ModalActions>
                <SecondaryButton disabled={loading || cancelLoading} onClick={handleClose}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton
                    disabled={loading || cancelLoading}
                    onClick={isOnRoute ? handleRemoveOrder : handleCancelOrder}
                >
                    Confirm
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default CancelOrderModal;
