export const calcOrderPricing = (order, user_id, circles) => {
    let owner = calcOrderOwner(order, user_id);
    if (owner === 'Internal') {
        return '$' + order?.order_revenue?.toFixed(2) || 0;
    } else if (owner === 'Carrier') {
        return calcOrderCarrierPricing([order]);
    } else if (owner === 'Shipper') {
        return calcOrderShipperPricing([order], circles);
    }
    return 0;
};

export const calcOrderOwner = (order, user_id) => {
    if (order.oms) {
        return 'Internal';
    } else if (!order.oms && order.shipper_id !== order.carrier_id && order.carrier_id === user_id) {
        return 'Carrier';
    } else if (!order.oms && order.shipper_id !== order.carrier_id && order.shipper_id === user_id) {
        return 'Shipper';
    }
    return '';
};

export const calcOrderCarrierPricing = (orders, backupText = 'We will contact you') => {
    if (!orders) return;

    let list = [];
    if (Array.isArray(orders)) {
        list = orders;
    } else {
        list = [orders];
    }

    const total = list.reduce((acc, order) => {
        return acc + (order.carrier_rate || 0);
    }, 0);

    // If all orders have base override, return total regardless of hide-market-rates
    if (list.every((order) => !!order.admin_carrier_rate_override)) {
        return `$${total.toFixed(2)}`;
    }

    // If any shippers have market rates hidden, also hide for carrier
    if (list.some((order) => order?.order_shipper?.user?.circles?.['hide-market-rates'])) {
        return 'Contract Rates';
    }

    return total ? `$${total.toFixed(2)}` : backupText;
};

export const calcOrderShipperPricing = (orders, circles, backupText = 'We will contact you') => {
    if (!orders) return;

    let list = [];
    if (Array.isArray(orders)) {
        list = orders;
    } else {
        list = [orders];
    }

    const total = list.reduce((acc, order) => {
        return acc + (order.shipper_rate || 0);
    }, 0);

    if (list.some((order) => !!order.admin_shipper_rate_override)) {
        return `$${total.toFixed(2)}`;
    }

    if (circles?.['hide-market-rates']) {
        return 'Contract Rates';
    }

    return total ? `$${total.toFixed(2)}` : backupText;
};
