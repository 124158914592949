import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Grid, Checkbox } from '@material-ui/core';
import { typography, colors } from '@/styles';

export const Circle = styled.div`
    width: 55px;
    height: 55px;
    border-radius: 999px;
`;

export const Label = styled.span`
    ${typography.sansSerif}

    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
`;

const SubTitle = styled.span`
    ${typography.sansSerif}

    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
`;

const rowStyles = (props) => {
    return css`
        background: ${props.selected ? '#CFCFCF' : '#e5e5e5'};
        cursor: ${props.selected ? 'default' : 'pointer'};
    `;
};

const borderRed = (props) => {
    return css`
        border: ${props.error ? '2px solid #d23e3e' : '2px solid transparent'};
    `;
};

export const ErrorContainer = styled(({ children, ...props }) => (
    <Grid container direction="column" {...props}>
        {children}
    </Grid>
))`
    width: 100%;
    border-radius: 5px;

    ${borderRed}
`;

const OptionRow = styled(({ children, ...props }) => (
    <Grid container direction="row" {...props}>
        {children}
    </Grid>
))`
    ${rowStyles}
    padding: 16px 12px;

    &:not(:last-child) {
        border-bottom: 1px solid #c0c0c0;
    }

    &:first-of-type {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    &:last-of-type {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
`;

export const Option = ({ isSelected, subtext, label, price, logo, ...rest }) => {
    return (
        <OptionRow selected={isSelected} className="option" {...rest}>
            <Grid
                container
                direction="column"
                css={css`
                    flex: 0;
                    flex-basis: 0;
                    justify-content: center;
                    margin-right: 8px;
                `}
            >
                <Checkbox color="primary" checked={isSelected} disabled={isSelected} />
            </Grid>
            <Grid
                container
                direction="column"
                css={css`
                    flex: 0;
                    flex-basis: 0;
                    justify-content: center;
                    margin-right: 20px;
                `}
            >
                <Circle
                    css={css`
                        background: ${colors.white.primary};

                        display: flex;
                        justify-content: center;
                        align-items: center;
                    `}
                >
                    <img
                        css={css`
                            object-fit: cover;
                            display: flex;
                            height: 35px;
                        `}
                        src={logo}
                    />
                </Circle>
            </Grid>
            <Grid
                container
                direction="column"
                css={css`
                    flex: 1;
                    flex-basis: 0;
                    justify-content: center;
                `}
            >
                <Grid
                    container
                    direction="row"
                    css={css`
                        margin-bottom: 4px;
                    `}
                >
                    <Label
                        css={css`
                            color: #2b2b2b;
                        `}
                    >
                        {label}
                    </Label>
                </Grid>
                <Grid container direction="row">
                    <SubTitle
                        css={css`
                            color: #2b2b2b;
                        `}
                    >
                        {subtext}
                    </SubTitle>
                </Grid>
            </Grid>
            <Grid
                container
                direction="column"
                css={css`
                    flex: 0;
                    flex-basis: 0;
                    justify-content: center;
                `}
            >
                <Label
                    css={css`
                        color: #2b2b2b;
                        white-space: nowrap;
                    `}
                >
                    {`Price: ${price}`}
                </Label>
            </Grid>
        </OptionRow>
    );
};
