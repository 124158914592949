import React, { useState, useEffect } from 'react';
import {
    ResponsiveSidebarDialog,
    ModalTitle,
    ModalContent,
    StickyModalActions,
    PrimaryButton,
    SecondaryButton,
} from '../../../styles/blocks';
import { OnwardCheckbox, TextField } from '../../ShipmentForm/blocks';
import { css } from '@emotion/react';
import {
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Popover,
    Typography,
    Grid,
    TextField as MuiTextField,
} from '@material-ui/core';

export const modalContent = (
    <>
        
    </>
)

const AddNoteModal = ({ state, setState, handleSave, client_id, order, }) => {
    return (
        <ResponsiveSidebarDialog
                    open={state.open}
                    onClose={() =>
                        setState((prevState) => ({
                            ...prevState,
                            open: false,
                        }))
                    }
                >
                    <ModalTitle
                        title="Add Note"
                        onClose={() =>
                            setState((prevState) => ({
                                ...prevState,
                                open: false,
                            }))
                        }
                    />
                    <ModalContent width={700}>
                        <Grid className="mb-3" alignItems="center" container spacing={2}>
                            <Grid container direction="column">
                                <Grid item>
                                    <TextField
                                        select
                                        variant="outlined"
                                        label="Type"
                                        size="small"
                                        value={state.type || null}
                                        css={css`
                                            width: 50%;
                                            margin-bottom: 20px;
                                            font-weight: 500;
                                        `}
                                        InputProps={{
                                            style: {
                                                fontWeight: 500,
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 500,
                                            },
                                        }}
                                        onChange={(e) => {
                                            setState((prevState) => ({
                                                ...prevState,
                                                type: e.target.value,
                                            }))
                                        }}
                                    >
                                        <MenuItem 
                                            value="Pickup" 
                                            css={css`
                                                font-weight: 500;    
                                            `}
                                        >
                                            Pickup
                                        </MenuItem>
                                        <MenuItem 
                                            value="Delivery" 
                                            css={css`
                                                font-weight: 500;    
                                            `}
                                        >
                                            Delivery
                                        </MenuItem>
                                        <MenuItem 
                                            value="Warehouse" 
                                            css={css`
                                                font-weight: 500;    
                                            `}
                                            >
                                                Warehouse
                                            </MenuItem>    
                                    </TextField>
                                </Grid>

                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        name="desc"
                                        fullWidth
                                        value={state.text || null}
                                        onChange={(e) =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                text: e.target.value,
                                            }))
                                        }
                                        multiline
                                        rows={5}
                                        css={css`
                                            textarea {
                                                font-weight: 500;
                                            }
                                        `}
                                    />
                                </Grid>

                                {/* Can only alert Onward admins if the user is a carrier and the order is not an internal order */}
                                {client_id === order?.carrier_id && client_id !== order?.shipper_id && (
                                    <>
                                        <Grid item>
                                            <OnwardCheckbox
                                                label="Private Note: Visible only to Onward admins and reporting account."
                                                checked={state?.private}
                                                onChange={() =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        private: !prevState?.private,
                                                    }))
                                                }
                                                labelPlacement="end"
                                            />
                                        </Grid>

                                        <Grid item>
                                            <OnwardCheckbox
                                                label="Notify Onward admin and shipper"
                                                checked={state?.notify}
                                                onChange={() =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        notify: !prevState?.notify,
                                                    }))
                                                }
                                                labelPlacement="end"
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </ModalContent>
                    <StickyModalActions>
                        <SecondaryButton
                            onClick={() =>
                                setState((prevState) => ({
                                    ...prevState,
                                    open: false,
                                }))
                            }
                        >
                            Cancel
                        </SecondaryButton>
                        <PrimaryButton 
                            disabled={!state.text}
                            onClick={handleSave}
                        >Save</PrimaryButton>
                    </StickyModalActions>
                </ResponsiveSidebarDialog>
    )
}

export default AddNoteModal;