import { DRIVER_PAYMENT_FIELDS } from '@/graphql/fragments/driver_payments';
import { DRIVER_TARIFF_FIELDS } from '@/graphql/fragments/driver_tariffs';
import { PRICING_TARIFF_FIELDS } from '@/graphql/fragments/pricing_tariffs';
import { RATE_FIELDS } from '@/graphql/fragments/rates';
import { gql } from '@apollo/client';

export const GET_DRIVER_TARIFFS = gql`
    ${DRIVER_TARIFF_FIELDS}
    ${PRICING_TARIFF_FIELDS}
    ${RATE_FIELDS}

    query GetDriverTariffs($client_id: uuid!) {
        driver_tariffs: driver_tariff_mappings(where: { client_id: { _eq: $client_id } }) {
            ...DriverTariffFields
            tariff {
                ...PricingTariffFields
                rates {
                    ...RateFields
                }
            }
        }
    }
`;

export const GET_DRIVER_PAYMENTS = gql`
    ${DRIVER_PAYMENT_FIELDS}

    query GetDriverPayments($where: driver_payments_bool_exp!) {
        driver_payments(where: $where, order_by: [{ created_at: desc }], limit: 25) {
            ...DriverPaymentFields
            orders {
                order_id
                po_number
                order_number
                oms
                price_breakdown
                carrier_rate
                order_revenue
                carrier_costs
                routes {
                    mapping_id
                    route {
                        route_id
                        route_number
                        route_alias
                        estimated_driving_distance
                    }
                }
            }
        }
    }
`;
