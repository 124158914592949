import React, { useContext, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import { PrimaryButton } from '@/styles/blocks';

function Footer({ state, loading, callbacks }) {
    return (
        <>
            <Grid
                container
                css={css`
                    flex: 0;
                    flex-basis: 0;
                    padding: 16px;
                    background-color: white;
                    justify-content: center;
                    align-items: center;
                `}
            >
                <Grid
                    item
                    css={css`
                        margin-right: 20px;
                    `}
                >
                    <PrimaryButton
                        onClick={() => {
                            callbacks.scheduleOrder(state.selected.map((id) => state.ordersById[id]));
                        }}
                        disabled={state.selected.length !== 1 && state.selectedResources.length === 0}
                    >
                        Assign Delivery Date
                    </PrimaryButton>
                </Grid>
                <Grid item>
                    <PrimaryButton
                        css={css`
                            margin-right: 20px;
                        `}
                        onClick={() => {
                            callbacks.retryMessages(state.selected);
                        }}
                        disabled={state.selected.length === 0}
                        loading={loading.retry}
                    >
                        Text Customer
                    </PrimaryButton>
                </Grid>
                <Grid item>
                    <PrimaryButton
                        onClick={() => {
                            callbacks.scheduleCall(state.selected);
                        }}
                        disabled={state.selected.length === 0}
                        loading={loading.call}
                    >
                        Call Customer
                    </PrimaryButton>
                </Grid>
            </Grid>
        </>
    );
}

export default Footer;
