import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { startCase } from 'lodash';
import { colors } from '@/styles';
import { css } from '@emotion/react';
import { IconButton } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Send as SendIcon } from '@material-ui/icons';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {
    EXCEPTION_DISPLAY,
    EXCEPTION_TYPE_DISPLAY_MAPPING,
    EXCEPTION_STATUS_DISPLAY_MAPPING,
} from '@onward-delivery/core';
import { BodyText } from '@/components/CarrierAccountingOrders/blocks';

const dateShort = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
});

export const COLUMNS = [
    {
        Header: 'Data',
        id: 'data-columns',
        columns: [
            {
                Header: 'Order',
                id: 'order_number',
                accessor: (exception) => exception.order.order_number,
            },
            {
                Header: 'PO #',
                id: 'po_number',
                accessor: (exception) => exception.order.po_number,
            },
            {
                Header: 'Items',
                id: 'sku',
                accessor: (exception) => {
                    return exception?.item?.length > 0 ? exception.item.map((item) => item.sku).join(', ') : '--';
                },
            },
            {
                Header: 'Item Descriptions',
                id: 'description',
                accessor: (exception) => {
                    return exception?.item?.length > 0
                        ? exception.item.map((item) => item.description).join(', ')
                        : '--';
                },
            },
            {
                Header: 'Quantity',
                id: 'quantity',
                align: 'right',
                accessor: (exception) => {
                    return exception?.item?.length > 0
                        ? exception.item.reduce((acc, item) => {
                              acc += item.quantity;
                              return acc;
                          }, 0)
                        : '';
                },
            },
            {
                Header: 'Shipper',
                id: 'shipper_name',
                accessor: (exception) => exception?.order?.order_shipper?.business_name || '--',
            },
            {
                Header: 'Carrier',
                id: 'carrier_name',
                accessor: (exception) => exception?.order?.order_carrier?.business_name || '--',
            },
            {
                Header: 'Exception Entity',
                id: 'exception_entity',
                accessor: (exception) => EXCEPTION_TYPE_DISPLAY_MAPPING[exception?.exception_type] || '--',
            },
            {
                Header: 'Type',
                id: 'exception_type',
                accessor: (exception) => EXCEPTION_DISPLAY[exception?.exception?.type] || '--',
            },
            {
                Header: 'Occurred',
                id: 'exception_occured_at',
                accessor: (exception) => exception?.exception?.reported_at || '--',
            },
            {
                Header: 'Reported At',
                id: 'exception_time',
                accessor: (exception) => new Date(exception?.created_at).getTime(),
                Cell: ({ value }) => {
                    return value ? dateShort.format(new Date(value)) : '--';
                },
            },
            {
                Header: 'Order Type',
                id: 'order_type',
                accessor: (exception) => (exception?.order?.oms ? 'Internal' : 'Marketplace'),
            },
            {
                Header: 'Notes',
                id: 'onward_notes',
                accessor: (exception) => exception.order.notes.reduce((accum, n) => {
                    if (n.private_to === 'Admin') accum.push(n.note)
                    return accum;
                }, []).join(', '),
            },
        ],
    },
    {
        sticky: 'right',
        Header: 'Sticky',
        id: 'sticky-group',
        columns: [
            {
                Header: 'Status',
                id: 'exception_status',
                Cell: ({ row }) => {
                    const exception = row.original;
                    const label = EXCEPTION_STATUS_DISPLAY_MAPPING[exception.exception.status];

                    let fragment = css`
                        color: ${colors.greens.primary};
                    `;
                    let icon = (
                        <CheckCircleIcon
                            css={css`
                                margin-right: 8px;
                                ${fragment}
                            `}
                        />
                    );
                    if (exception.exception.status !== 'RESOLVED') {
                        fragment = css`
                            color: #d23e3e;
                        `;
                        icon = (
                            <HighlightOffIcon
                                css={css`
                                    margin-right: 8px;
                                    ${fragment}
                                `}
                            />
                        );
                    }

                    return (
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                                white-space: nowrap;
                            `}
                        >
                            {icon}
                            <BodyText
                                css={css`
                                    font-weight: 700;
                                    ${fragment};
                                `}
                            >
                                {label}
                            </BodyText>
                        </div>
                    );
                },
                canSort: false,
                span: false,
            },
            {
                Header: 'Details',
                id: 'details',
                width: 80,
                Cell: ({ row, callbacks }) => (
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            callbacks.gotoDetails(row.original);
                        }}
                        css={css`
                            padding: 8px;
                        `}
                    >
                        <SendIcon
                            css={css`
                                color: ${colors.greens.primary};
                            `}
                        />
                    </IconButton>
                ),
                disableSortBy: true,
            },
        ],
    },
];
