import React, { useMemo, useState, useContext } from 'react';
import { compose } from 'recompose';
import { AppBar, Toolbar, Menu, MenuItem, Badge, Drawer } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { withStyles } from '@material-ui/core/styles';
import * as ROUTES from '../../constants/routes';
import * as Sentry from '@sentry/react';
import { getAuth, signOut } from 'firebase/auth';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { useSubscription, useApolloClient } from '@apollo/client';
import { NOTIFICATIONS_SUBSCRIPTION } from '../../graphql/subscriptions/notifications';
import { useNavigate, useParams } from 'react-router-dom';
import { useClientUser } from '@/hooks';
import NotificationsModal from '../NotificationsModal';
import sub from 'date-fns/sub';
import formatISO from 'date-fns/formatISO';
import { asUTCDate } from '@/utilities/convertToISO';
import { addDays, format } from 'date-fns';
import { UserContext } from '../App';
import { startCase } from 'lodash';

const styled = withStyles({
    toolbar: {
        backgroundColor: '#FFFFFF',
        display: 'flex',
        justifyContent: 'flex-end',
        minHeight: '50px',
        height: '50px',
        maxHeight: '50px',
        color: '#4c4c4c',
        fontWeight: '500',
        fontFamily: ['Montserrat', 'Roboto', 'Arial'].join(','),
    },
    username: {
        marginRight: '0.5rem',
    },
    badge: {
        marginRight: '1rem',
        cursor: 'pointer',
    },
    notification: {
        color: 'black',
        '&:hover': {
            color: '#28a745',
        },
        backgroundColor: 'transparent',
    },
    drawer: {
        width: '50vw',
    },
});

function Header(props) {
    const { authUser, classes } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const { user_id, roles, accountType } = useClientUser();
    const [notifications, setNotifications] = useState([]);
    const client = useApolloClient();
    const { setSuperUser } = useContext(UserContext);

    const start = useMemo(() => {
        return addDays(asUTCDate(format(new Date(), 'yyyy-MM-dd')), -7);
    }, []);

    const { loading, error, data } = useSubscription(NOTIFICATIONS_SUBSCRIPTION, {
        variables: {
            user_id: user_id,
            from_date: start,
        },
        onData: ({ data }) => {
            const notificationList = data?.data?.notifications || [];
            setNotifications(notificationList);
        },
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
    });

    const doSignOut = async () => {
        window?.sessionStorage?.removeItem('admin_user_object');
        await setSuperUser({});
        await client.clearStore();
        signOut(getAuth());
    };

    const [openDrawer, setOpenDrawer] = useState(false);

    const navigate = useNavigate();

    const role = useMemo(() => {
        if (roles?.CORPORATE_ADMIN) {
            return 'Corporate Admin';
        }

        if (roles?.TEAMMATE) {
            return `${startCase(accountType)} Teammate`;
        }

        if (roles?.ADMIN) {
            return 'Onward Admin';
        }

        return `${startCase(accountType)}`;
    }, [roles, accountType]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickNotification = () => {
        setOpenDrawer(true);
    };

    const handleCloseNotification = () => {
        setOpenDrawer(false);
    };

    const getFormattedUserRole = () => {
        if (role?.length) {
            return `${role.charAt(0).toUpperCase()}${role.slice(1).toLowerCase()}`;
        }
        return '';
    };

    const handleProfileRedirect = () => {
        navigate(ROUTES.ACCOUNT);
        handleClose();
    };

    return (
        <header>
            <AppBar position="sticky">
                <Toolbar className={classes.toolbar}>
                    <Badge
                        overlap="rectangular"
                        className={classes.badge}
                        badgeContent={notifications?.filter((n) => n.notification_status === 'UNRESOLVED')?.length || 0}
                        color="error"
                        onClick={handleClickNotification}
                    >
                        <NotificationsNoneIcon className={classes.notification} />
                    </Badge>
                    <Drawer
                        className={classes.drawer}
                        anchor="right"
                        open={openDrawer}
                        onClose={handleCloseNotification}
                    >
                        <NotificationsModal
                            handleCloseNotification={handleCloseNotification}
                            notifications={notifications}
                        />
                    </Drawer>
                    <span className={classes.username}>
                        {authUser.username} ({getFormattedUserRole()})
                    </span>
                    <AccountCircleIcon onClick={handleClick} />

                    <Menu
                        anchorEl={anchorEl}
                        getContentAnchorEl={null}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <MenuItem onClick={handleProfileRedirect}>Account Settings</MenuItem>
                        <MenuItem onClick={doSignOut}>Logout</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        </header>
    );
}

export default compose(styled)(Header);
