import { gql } from '@apollo/client';

export const DOCUMENT_FIELDS = gql`
    fragment DocumentFields on documents {
        document_id
        client_id
        name
        type
        link
        created_at
    }
`;
