import React, { useMemo } from 'react';
import { ACCESSORIALS as DEFAULT_ACCESSORIALS } from './types/default';
import { ACCESSORIALS as LIVING_SPACES_ACCESSORIALS } from './types/livingSpaces';
import { ACCESSORIALS as AF_ACCESSORIALS } from './types/americanFreight';
import { ACCESSORIALS as ONWARD_CONSOLIDATED_ACCESSORIALS, genCustomAccessorials } from './types/onwardConsolidated';

export const getTariffName = (tariff, idx) => {
    return tariff.name || `Tariff ${idx + 1}`;
};

export const genAccessorials = (type, tags) => {
    const customAccessorials = genCustomAccessorials(tags)
    switch (type) {
        case 'LIVING_SPACES':
            return [...LIVING_SPACES_ACCESSORIALS, ...customAccessorials];
        case 'SHIPPER_GLOBAL_DEFAULT':
        case 'CARRIER_GLOBAL_DEFAULT':
        case 'DEFAULT':
            return [...DEFAULT_ACCESSORIALS, ...customAccessorials];
        case 'ROCKET_SHIPPING':
            return [...AF_ACCESSORIALS, ...customAccessorials];
        case 'ONWARD_CONSOLIDATED':
            return [...ONWARD_CONSOLIDATED_ACCESSORIALS, ...customAccessorials];
        default:
            return [];
    }
};

export const useAccessorials = (type, tags) => {
    return useMemo(() => {
        return genAccessorials(type, tags);
    }, [type, tags]);
};
