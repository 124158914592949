import React, { useContext, useMemo, useState, useEffect } from 'react';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { css } from '@emotion/react';
import { SectionSubtitle } from '@/styles/blocks';
import StartTimeSelect from '@/components/misc/StartTimeSelect';
import { useSwappedAttributes } from '../../hooks';
import { Body1, Incrementor, OnwardCheckbox, TextField } from '../../blocks';
import { ModalContext } from './';
import { SectionSubtitle2, GreyBody } from '@/styles/blocks';
import { useClientUser, useOrderNotes } from '@/hooks';
import { capitalize } from 'lodash';
import { UserContext } from '@/components/App';
import { greys } from '@/styles/colors';
import { colors } from '@/styles';
import { PrimaryButton } from '@/styles/blocks';
import { MenuItem, Typography, Grid } from '@material-ui/core';
import { HR } from '@/components/OrderDetailsPage/Notes/Notes';

const dateShort = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
});

const timestamp = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
});

const Note = (note, isLast, accountTypeCapital, username, email) => {
    // Notes for invoices
    if (note?.invoice_id) {
        return (
            <>
                <Grid container direction="row">
                    <Grid container item direction="column" xs={4}>
                        <Grid item>
                            <Typography
                                css={css`
                                    font-weight: 500;
                                    line-break: anywhere;
                                `}
                            >
                                System
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Typography
                                css={css`
                                    color: ${greys.primary};
                                    font-weight: 500;
                                `}
                            >
                                {dateShort.format(new Date(note.created_at || Date.now()))}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                css={css`
                                    color: ${greys.primary};
                                    font-weight: 500;
                                `}
                            >
                                {timestamp.format(new Date(note.created_at || Date.now()))}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={8}>
                        <Typography
                            css={css`
                                font-weight: 500;
                                font-style: italic;
                            `}
                        >
                            <a
                                href={`${window.location.origin}/carrier/accounting/marketplace/invoice/${note?.invoice_id}`}
                                target="_blank"
                            >
                                An invoice was added to this order
                            </a>
                        </Typography>
                    </Grid>
                </Grid>

                {!isLast && <HR />}
            </>
        );
    }

    const source_user_type = note?.source_user_type;

    return (
        <>
            <Grid container direction="row">
                <Grid container item direction="column" xs={4}>
                    {note?.private_to === accountTypeCapital && (
                        <Grid item>
                            <Typography
                                css={css`
                                    color: orange;
                                    font-weight: 500;
                                `}
                            >
                                Private
                            </Typography>
                        </Grid>
                    )}

                    {note?.requires_acknowledgement && (
                        <Grid item>
                            <Typography
                                css={css`
                                    color: ${colors.greens.primary};
                                    font-weight: 500;
                                `}
                            >
                                Onward/Shipper Notified
                            </Typography>
                        </Grid>
                    )}

                    <Grid item>
                        <Typography
                            css={css`
                                font-weight: 500;
                                line-break: anywhere;
                            `}
                        >
                            {source_user_type
                                ? source_user_type === 'Admin'
                                    ? 'Onward'
                                    : `(${source_user_type})`
                                : 'System'}
                        </Typography>
                    </Grid>

                    {note.source_user_type !== 'Admin' && (
                        <Grid item>
                            <Typography
                                css={css`
                                    color: ${greys.primary};
                                    font-weight: 500;
                                    line-break: anywhere;
                                `}
                            >
                                {email}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <Typography
                            css={css`
                                color: ${greys.primary};
                                font-weight: 500;
                            `}
                        >
                            {dateShort.format(new Date(note.created_at || Date.now()))}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            css={css`
                                color: ${greys.primary};
                                font-weight: 500;
                            `}
                        >
                            {timestamp.format(new Date(note.created_at || Date.now()))}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container direction="column" item xs={8}>
                    {note?.type && (
                        <Grid item>
                            <Typography
                                css={css`
                                    font-weight: 500;
                                `}
                            >
                                {note?.type}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <Typography
                            css={css`
                                font-weight: 500;
                                ${note?.is_acknowledgement &&
                                `
                                    font-style: italic;    
                                `}
                            `}
                        >
                            {note?.is_acknowledgement ? `Acknowledged by ${note?.source_user_type}` : note.note}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            {!isLast && <HR />}
        </>
    );
};

const initNote = {
    private: false,
    notify: false,
    note: '',
};

const NotesTab = () => {
    const { state: modalState, callbacks } = useContext(ModalContext);
    const { user_id, userType, client_id } = useClientUser();
    const { order } = modalState;
    const { username, email } = useContext(UserContext);
    const isImport = window.location.pathname === '/import' || window.location.pathname.includes('job');

    const [noteInfo, setNoteInfo] = useState(initNote);

    const handleAddNote = () => {
        let source_user_type = 'Shipper';
        if (!isImport && order?.carrier_id && client_id !== order?.shipper_id) {
            source_user_type = 'Carrier';
        }

        callbacks.addNote({
            note: noteInfo.note,
            source_user_type: source_user_type,
            source_user_id: user_id,
            is_acknowledgement: false,
            requires_acknowledgement: noteInfo.notify,
            acknowledged_by_shipper: false,
            acknowledged_by_admin: false,
            private_to: noteInfo.private ? capitalize(userType) : null,
            type: noteInfo.type,
        });
        setNoteInfo({
            ...initNote,
        });
    };

    const orderNotes = useOrderNotes([order], userType);

    return (
        <Grid container direction="column">
            <Grid item>
                <TextField
                    select
                    variant="outlined"
                    label="Type"
                    size="small"
                    value={noteInfo.type || null}
                    css={css`
                        width: 50%;
                        margin-bottom: 20px;
                        font-weight: 500;
                    `}
                    InputProps={{
                        style: {
                            fontWeight: 500,
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            fontWeight: 500,
                        },
                    }}
                    onChange={(e) => {
                        setNoteInfo((prevState) => ({
                            ...prevState,
                            type: e.target.value,
                        }));
                    }}
                >
                    <MenuItem
                        value="Pickup"
                        css={css`
                            font-weight: 500;
                        `}
                    >
                        Pickup
                    </MenuItem>
                    <MenuItem
                        value="Delivery"
                        css={css`
                            font-weight: 500;
                        `}
                    >
                        Delivery
                    </MenuItem>
                    <MenuItem
                        value="Warehouse"
                        css={css`
                            font-weight: 500;
                        `}
                    >
                        Warehouse
                    </MenuItem>
                </TextField>
            </Grid>

            <Grid item>
                <TextField
                    variant="outlined"
                    name="desc"
                    fullWidth
                    value={noteInfo.note || ''}
                    onChange={(e) =>
                        setNoteInfo((prevState) => ({
                            ...prevState,
                            note: e.target.value,
                        }))
                    }
                    multiline
                    rows={5}
                    css={css`
                        textarea {
                            font-weight: 500;
                        }
                    `}
                />
            </Grid>

            <Grid item>
                <OnwardCheckbox
                    label="Private Note: Visible only to Onward admins and reporting account."
                    checked={noteInfo?.private}
                    onChange={() =>
                        setNoteInfo((prevState) => ({
                            ...prevState,
                            private: !prevState?.private,
                        }))
                    }
                    labelPlacement="end"
                />
            </Grid>

            {/* Can only alert Onward admins if the user is a carrier and the order is not an internal order */}
            {!isImport && client_id === order?.carrier_id && client_id !== order?.shipper_id && (
                <Grid item>
                    <OnwardCheckbox
                        label="Notify Onward admin and shipper"
                        checked={noteInfo?.notify}
                        onChange={() =>
                            setNoteInfo((prevState) => ({
                                ...prevState,
                                notify: !prevState?.notify,
                            }))
                        }
                        labelPlacement="end"
                    />
                </Grid>
            )}

            <Grid
                item
                css={css`
                    margin-top: 20px;
                `}
            >
                <PrimaryButton disabled={!noteInfo.note} onClick={handleAddNote}>
                    Add Note
                </PrimaryButton>
            </Grid>

            <hr
                css={css`
                    margin-bottom: 20px;
                    margin-top: 30px;
                `}
            />

            <Grid item>
                <Typography
                    css={css`
                        font-weight: 700;
                        margin-bottom: 20px;
                    `}
                >
                    Notes / Comments
                </Typography>
            </Grid>

            {!orderNotes?.all?.length ? (
                <Grid item>
                    <Typography>No notes yet</Typography>
                </Grid>
            ) : (
                orderNotes.all.map((note, idx, arr) =>
                    Note(note, arr.length - 1 === idx, capitalize(userType), username, email)
                )
            )}
        </Grid>
    );
};

export default NotesTab;
