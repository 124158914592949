import { gql } from '@apollo/client';
import { RESOURCE_OVERRIDE_MAPPING_FIELDS } from './resource_override_mappings';
import { RESOURCE_RULE_MAPPING_FIELDS } from './resource_rule_mappings';
import { RESOURCE_REGION_MAPPING_FIELDS } from './resource_region_mappings';
import { RESOURCE_SHIPPING_PARTNER_MAPPING_FIELDS } from '../fragments/resource_shipping_partner_mappings';
import { CLIENT_FIELDS } from './clients';

export const RESOURCE_FIELDS = gql`
    fragment ResourceFields on resources {
        capacity_per_truck
        client_id
        name
        resource_id
        trucks
        timeframe_end
        timeframe_start
    }
`;

export const QUERY_BY_CLIENT = gql`
    ${RESOURCE_OVERRIDE_MAPPING_FIELDS}
    ${RESOURCE_RULE_MAPPING_FIELDS}
    ${RESOURCE_REGION_MAPPING_FIELDS}
    ${RESOURCE_SHIPPING_PARTNER_MAPPING_FIELDS}
    ${RESOURCE_FIELDS}
    ${CLIENT_FIELDS}

    query GetResourcesByClient($client_id: uuid, $cutoff: timestamptz) {
        results: resources(where: { client_id: { _eq: $client_id } }) {
            ...ResourceFields

            rules(where: { _or: [{ rule_end: { _gte: $cutoff } }, { rule_end: { _is_null: true } }] }) {
                ...ResourceRuleMappingFields
            }

            overrides(where: { _or: [{ override_end: { _gte: $cutoff } }, { override_end: { _is_null: true } }] }) {
                ...ResourceOverrideMappingFields
            }

            subregions {
                ...ResourceRegionMappingFields
            }

            shipping_partners(where: { enabled: { _eq: true } }) {
                ...ResourceShippingPartnerMappingFields
                partner_client {
                    ...ClientFields
                }
            }
        }
    }
`;
