export const SAAS_V1 = 'saas-v1';
export const BIDDING_DISABLED = 'bidding-disabled';
export const DELIVERY_SLA = 'delivery-sla';
export const DISABLE_AVAILABILIY = 'disable-customer-availability';
export const LTL_MARKETPLACE = 'ltl-marketplace';
export const SHIPPER_BIDDING_V1 = 'shipper-bidding-v1';
export const BROKER = 'broker';
export const ACCOUNTING_V2 = 'accounting-v2';
export const ADVANCED_SCHEDULING = 'advanced-scheduling';
export const PDF_IMPORT_V1 = 'pdf-import-v1';
export const PLAN_PAGE_LOADBOARD = 'plan-page-load-board';
export const PLAN_PAGE_NETWORK_ROUTES = 'plan-page-network-routes';
export const CDS_TIMEFRAME_EXPORT = 'cds-timeframe-export';
export const CUSTOM_CUSTOMER_COMMS_RANGE = 'custom-customer-comms-range';
export const ROCKET_MM_INTEGRATION = 'rocket-middle-mile-integration';
export const WMS = 'wms';
export const ONWARD_HANDLED_QUOTES = 'onward-handled-quotes';
export const DRIVER_PAYMENTS = 'driver-payments';
