import { CheckOutlined, HighlightOffOutlined } from '@material-ui/icons';
import { addDays, format } from 'date-fns';
import { isFinite, startCase } from 'lodash';
import { css } from '@emotion/react';
import { useMemo } from 'react';
import { formatInTimeZone } from 'date-fns-tz';

export const TABS = {
    ALL: {
        label: 'All',
    },
    UNPAID: {
        label: 'Unpaid',
        statuses: ['UNPAID'],
    },
    PAID: {
        label: 'Paid',
        statuses: ['PAID'],
    },
};

export const useColumns = ({ settings }) => {
    return useMemo(() => {
        return [
            {
                Header: 'Group',
                id: 'group',
                columns: [
                    {
                        Header: 'Dates',
                        id: 'pay_period',
                        width: 200,
                        accessor: (payment) => [
                            payment?.pay_period_start || '',
                            payment?.pay_period_end ? addDays(new Date(payment.pay_period_end), -1) : '',
                        ],
                        Cell: ({ value }) => {
                            return value
                                .map((val) =>
                                    val
                                        ? formatInTimeZone(
                                              new Date(val),
                                              settings?.driver?.payPeriodTZ || 'America/New_York',
                                              'EEE, MMM d, yyyy'
                                          )
                                        : ''
                                )
                                .join(' - ');
                        },
                    },
                    {
                        Header: 'Status',
                        id: 'status',
                        width: 150,
                        accessor: (payment) => payment.status || '',
                        Cell: ({ value }) => {
                            let icon;
                            switch (value) {
                                case 'PAID':
                                    icon = <CheckOutlined color="primary" />;
                                    break;
                                case 'UNPAID':
                                    icon = <HighlightOffOutlined color="error" />;
                            }

                            return (
                                <div
                                    css={css`
                                        display: flex;
                                        align-items: center;
                                    `}
                                >
                                    {icon}
                                    {startCase(value.toLowerCase())}
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Revenue',
                        id: 'total_revenue',
                        width: 150,
                        accessor: (payment) => payment.total_revenue,
                        Cell: ({ value }) => {
                            return value ? `$${value.toFixed(2)}` : '-';
                        },
                    },
                    {
                        Header: 'Cost',
                        id: 'total_cost',
                        width: 150,
                        accessor: (payment) => payment.total_cost,
                        Cell: ({ value }) => {
                            return value ? `$${value.toFixed(2)}` : '-';
                        },
                    },
                    {
                        Header: 'Profit',
                        id: 'profit',
                        width: 150,
                        accessor: (payment) => payment.total_revenue - payment.total_cost,
                        Cell: ({ value }) => {
                            return isFinite(value) ? `$${value.toFixed(2)}` : '-';
                        },
                    },
                    {
                        Header: 'Margin',
                        id: 'margin',
                        width: 150,
                        accessor: (payment) =>
                            (100 * (payment.total_revenue - payment.total_cost)) / payment.total_revenue,
                        Cell: ({ value }) => {
                            return isFinite(value) ? `${value.toFixed(2)} %` : '-';
                        },
                    },
                ],
            },
        ];
    }, [settings]);
};

[
    {
        Header: 'Group',
        id: 'group',
        columns: [
            {
                Header: 'Dates',
                id: 'pay_period',
                width: 200,
                accessor: (payment) => [payment?.pay_period_start || '', payment?.pay_period_end],
                Cell: ({ value }) => {
                    return value.map((val) => (val ? format(new Date(val), 'EEE, MMM d, yyyy') : '')).join(' - ');
                },
            },
            {
                Header: 'Status',
                id: 'status',
                width: 150,
                accessor: (payment) => payment.status || '',
                Cell: ({ value }) => {
                    let icon;
                    switch (value) {
                        case 'PAID':
                            icon = <CheckOutlined color="primary" />;
                            break;
                        case 'UNPAID':
                            icon = <HighlightOffOutlined color="error" />;
                    }

                    return (
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                            `}
                        >
                            {icon}
                            {startCase(value.toLowerCase())}
                        </div>
                    );
                },
            },
            {
                Header: 'Revenue',
                id: 'total_revenue',
                width: 150,
                accessor: (payment) => payment.total_revenue,
                Cell: ({ value }) => {
                    return value ? `$${value.toFixed(2)}` : '-';
                },
            },
            {
                Header: 'Cost',
                id: 'total_cost',
                width: 150,
                accessor: (payment) => payment.total_cost,
                Cell: ({ value }) => {
                    return value ? `$${value.toFixed(2)}` : '-';
                },
            },
            {
                Header: 'Profit',
                id: 'profit',
                width: 150,
                accessor: (payment) => payment.total_revenue - payment.total_cost,
                Cell: ({ value }) => {
                    return isFinite(value) ? `$${value.toFixed(2)}` : '-';
                },
            },
            {
                Header: 'Margin',
                id: 'margin',
                width: 150,
                accessor: (payment) => (100 * (payment.total_revenue - payment.total_cost)) / payment.total_revenue,
                Cell: ({ value }) => {
                    return isFinite(value) ? `${value.toFixed(2)} %` : '-';
                },
            },
        ],
    },
];
