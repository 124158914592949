import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { css } from '@emotion/react';
import { useClientUser } from '@/hooks';
import { PrimaryButton } from '@/styles/blocks';
import AwardBidModal from './modals/AwardBidModal';
import NewCounterModal from './modals/NewCounterModal';

function CounterBids({ bid, listing, setNotification, isOnwardHandledQuoteShipper }) {
    const { user_id, isOnwardAdmin } = useClientUser();
    const [awardBidModalOpen, setAwardBidModalOpen] = useState(false);
    const [newCounterModalOpen, setNewCounterModalOpen] = useState(false);
    const [selectedCounter, setSelectedCounter] = useState(null);

    const populateButtons = (counter, i) => {
        if (listing.listing_status !== 'open') return;
        if (i === 0 && counter.client_id !== user_id) {
            return (
                <>
                    <PrimaryButton
                        css={css`
                            margin-right: 15px;
                        `}
                        onClick={() => setNewCounterModalOpen(true)}
                    >
                        Counter
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => {
                            setSelectedCounter(counter);
                            setAwardBidModalOpen(true);
                        }}
                    >
                        Accept
                    </PrimaryButton>
                </>
            );
        } else if (i === 1 && counter.client_id !== user_id) {
            return (
                <PrimaryButton
                    onClick={() => {
                        setSelectedCounter(counter);
                        setAwardBidModalOpen(true);
                    }}
                >
                    Accept
                </PrimaryButton>
            );
        }
    };

    return (
        <Grid container>
            {bid.counterSequence.length > 0 &&
                bid.counterSequence.map((counter, i) => (
                    <Grid
                        container
                        key={i}
                        css={css`
                            border-bottom: 1px solid #e2e2e2;
                        `}
                        sm={12}
                        direction="row"
                        className="justify-content-around py-3"
                    >
                        <Grid item sm={4}>
                            <Typography variant="body1">
                                {user_id === counter.client_id
                                    ? `Your counter`
                                    : `${bid.isCarrier ? 'Shipper' : 'Carrier'} counter`}
                            </Typography>
                        </Grid>
                        <Grid item sm={5}>
                            <Typography variant="body2">
                                {new Date(counter.created_at).toLocaleDateString(undefined, {
                                    weekday: 'short',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                }) +
                                    ' @ ' +
                                    new Date(counter.created_at).toLocaleTimeString()}
                            </Typography>
                        </Grid>
                        <Grid item sm={3} className="d-flex justify-content-end">
                            <Typography variant={i === 0 ? 'body1' : 'body2'}>
                                {user_id === bid.carrier_id || (isOnwardHandledQuoteShipper && isOnwardAdmin)
                                    ? `$${counter.carrier_rate.toFixed(2)}`
                                    : `$${counter.shipper_rate.toFixed(2)}`}
                            </Typography>
                        </Grid>
                        <Grid item sm={12} className="d-flex justify-content-end align-items-center mt-1">
                            {populateButtons(counter, i)}
                        </Grid>
                    </Grid>
                ))}
            <Grid item sm={12} className="d-flex justify-content-between align-items-center pt-3">
                <Grid item sm={4}>
                    <Typography variant="body1">
                        {user_id === bid.carrier_id ? `Your starting bid` : `Carrier Starting Bid`}
                    </Typography>
                </Grid>
                <Grid item sm={5}>
                    <Typography variant="body2">
                        {new Date(bid.created_at).toLocaleDateString(undefined, {
                            weekday: 'short',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                        }) +
                            ' @ ' +
                            new Date(bid.created_at).toLocaleTimeString()}
                    </Typography>
                </Grid>
                <Grid item sm={3} className="d-flex justify-content-end">
                    <Typography variant="body2">
                        {user_id === bid.carrier_id || (isOnwardHandledQuoteShipper && isOnwardAdmin)
                            ? `$${bid.carrier_rate.toFixed(2)}`
                            : `$${bid.shipper_rate.toFixed(2)}`}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item sm={12} className="d-flex justify-content-end align-items-center mt-1">
                {listing.listing_status === 'open' && !bid.isCarrier && bid.counterSequence.length === 0 && (
                    <>
                        <PrimaryButton
                            css={css`
                                margin-right: 15px;
                            `}
                            onClick={() => setNewCounterModalOpen(true)}
                        >
                            Counter
                        </PrimaryButton>
                        <PrimaryButton
                            onClick={() => {
                                setAwardBidModalOpen(true);
                            }}
                        >
                            Accept
                        </PrimaryButton>
                    </>
                )}
                {listing.listing_status === 'open' && !bid.isCarrier && bid.counterSequence.length === 1 && (
                    <>
                        <PrimaryButton
                            onClick={() => {
                                setAwardBidModalOpen(true);
                            }}
                        >
                            Accept
                        </PrimaryButton>
                    </>
                )}
            </Grid>
            <AwardBidModal
                bid={bid}
                listing={listing}
                onClose={() => {
                    setSelectedCounter(null);
                    setAwardBidModalOpen(false);
                }}
                open={awardBidModalOpen}
                selectedCounter={selectedCounter}
                isOnwardHandledQuoteShipper={isOnwardHandledQuoteShipper}
            />
            <NewCounterModal
                open={newCounterModalOpen}
                onClose={() => setNewCounterModalOpen(false)}
                bidDetails={bid}
                setNotification={setNotification}
                isOnwardHandledQuoteShipper={isOnwardHandledQuoteShipper}
            />
        </Grid>
    );
}

export default CounterBids;
