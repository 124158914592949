import React from 'react';
import { Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import { PrimaryButton } from '@/styles/blocks';
import { FILTERS } from './constants';

function Footer({ state, loading, callbacks }) {
    return (
        <>
            <Grid
                container
                css={css`
                    padding: 1rem;
                    background-color: white;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0px -4px 4px 0px #37373752;
                `}
            >
                {state.filter.status === FILTERS.UNACKNOWLEDGED && (
                    <Grid item>
                    <PrimaryButton
                        loading={loading.acknowledgementLoading}
                        onClick={callbacks.acknowledgeOrderNotes}
                        disabled={state.selected.length === 0}
                    >
                        Acknowledge
                    </PrimaryButton>
                </Grid>
                )} 
            </Grid>
        </>
    );
}

export default Footer;
